import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import React, { ChangeEvent, FC, useMemo, useState } from 'react';

import Analytics from '../../components/widgets/SportingEvents/Analytics';
import Calendar from '../../components/widgets/SportingEvents/Calendar';
import CompetitionResults from '../../components/widgets/SportingEvents/CompetitionResults';
import Documents from '../../components/widgets/SportingEvents/Documents';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainInfo from '../../components/widgets/SportingEvents/MainInfo';
import ProgramTypes from '../../components/widgets/SportingEvents/ProgramTypes';
import Referees from '../../components/widgets/SportingEvents/Referees';
import Rules from '../../components/widgets/SportingEvents/Rules';
import TeamInfo from '../../components/widgets/SportingEvents/TeamInfo';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';
import onExpand from '../../utils/onExpand';
import { usePermission } from '../../hooks/usePermission';
import { useRouteMatch } from 'react-router-dom';
import { useSportingEventStartInfoQuery } from '../../api';
import userRights from '../../config/userRights';
import CallAthleteNotify from '../../components/widgets/SportingEvents/CallAthleteNotify';

type AccordionTypes =
  | 'MainInfo'
  | 'Calendar'
  | 'TeamInfo'
  | 'Rules'
  | 'ProgramTypes'
  | 'CompetitionResults'
  | 'Referees'
  | 'Documents'
  | 'Analytics'
  | 'CallAthleteNotify'
  | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary) as any;

const Single: FC<{}> = () => {
  const { canChangeSportingEventStatus, hasSportEventLimAccess, isGskReferee } = userRights();

  const {
    params: { id },
  } = useRouteMatch();

  const access = usePermission('directory', 'sporting_events');

  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');

  const { data, refetch } = useSportingEventStartInfoQuery({
    skip: !id,
    variables: { id },
    fetchPolicy: 'no-cache',
    returnPartialData: true,
  });
  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };
  const status = useMemo(
    () =>
      data?.dirSportingEvent?.statuses && data?.dirSportingEvent?.statuses?.length
        ? data?.dirSportingEvent?.statuses[0]
        : null,
    [data],
  );

  const isUniverciad = useMemo(
    () =>
      data?.dirSportingEvent?.dirCalendar?.clsCalendarCategory &&
      data?.dirSportingEvent?.dirCalendar?.clsCalendarCategory?.value === 100003375,
    [data],
  );
  const readOnly = id !== 'new' && status && [3, 4, 5].includes(status?.value?.value) ? true : !access.includes(2);
  const hasLimAccess = id && id !== 'new' && ![1, 2, 3].includes(status?.value?.value || 0);
  const limAccessROStatus = hasSportEventLimAccess ? hasLimAccess : readOnly;
  const limAccessRO = hasSportEventLimAccess ? hasLimAccess : !access.includes(2);
  const cpResultAccess = isGskReferee ? hasLimAccess : limAccessRO;

  const OptionTitleArchive = data?.dirSportingEvent?.archive
    ? ` (В архиве с ${moment(data?.dirSportingEvent?.archive?.date).format('DD.MM.YYYY')})`
    : '';

  return (
    <MainTemplate
      key={id}
      pageName=""
      url=""
      title={id === 'new' ? 'Добавить новое мероприятие' : `Мероприятие: ${data?.dirSportingEvent?.name}`}
    >
      <ContentWrapperWidget>
        {id === 'new' ? (
          <h2>Добавить новое мероприятие</h2>
        ) : (
          <h2>
            Мероприятие: {data?.dirSportingEvent?.name}
            {OptionTitleArchive}
          </h2>
        )}
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'MainInfo') && id && (
              <MainInfo id={id} readonly={readOnly} onStatusUpdated={() => refetch()} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'CallAthleteNotify'}
          disabled={id === 'new'}
          onChange={handleChangeAccordion('CallAthleteNotify')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Вызов спортсменов для уведомления</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'CallAthleteNotify') && id && <CallAthleteNotify id={id} readonly={readOnly} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Calendar'}
          onChange={handleChangeAccordion('Calendar')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Программа соревнования</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'Calendar') && id && <Calendar id={id} readonly={isGskReferee ? false : limAccessRO} />}
          </AccordionDetails>
        </Accordion>
        <Accordion disabled={id === 'new'} expanded={expanded === 'Rules'} onChange={handleChangeAccordion('Rules')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Требования к участникам</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'Rules') && id && <Rules id={id} readonly={readOnly} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'TeamInfo'}
          onChange={handleChangeAccordion('TeamInfo')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Состав участников</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'TeamInfo') && id && (
              <TeamInfo
                event={data?.dirSportingEvent}
                id={id}
                readonly={readOnly}
                status={status}
                isUniverciad={isUniverciad}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'ProgramTypes'}
          onChange={handleChangeAccordion('ProgramTypes')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Виды программ</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'ProgramTypes') && id && <ProgramTypes id={id} readonly={limAccessROStatus} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'CompetitionResults'}
          onChange={handleChangeAccordion('CompetitionResults')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Результаты соревнований</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'CompetitionResults') && id && (
              <CompetitionResults id={id} isUniverciad={isUniverciad} readonly={cpResultAccess} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Referees'}
          onChange={handleChangeAccordion('Referees')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Судейская коллегия</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'Referees') && id && <Referees id={id} readonly={limAccessRO} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Analytics'}
          onChange={handleChangeAccordion('Analytics')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Статистика</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'Analytics') && id && <Analytics id={id} eventName={data?.dirSportingEvent?.name} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Documents'}
          onChange={handleChangeAccordion('Documents')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Отчетные документы</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'Documents') && id && <Documents id={id} readonly={limAccessRO} />}
          </AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
