import React, { FC, useEffect, useMemo, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import _ from 'lodash';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Documents from './components/ProgramTypeDocument';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useSnackbar } from 'notistack';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';
import { Input } from '../../Inputs';
import AthletesInTeam from '../../../module/AthletesInTeam';
import getMessage from '../../../messages';
import {
  Scalars,
  DirAthlete,
  UpdateDirAthleteMutationHookResult,
  useUpdateDirAthleteMutation,
  useSportingEventCompetitionsResultsQuery,
  useAthleteCompetitionsResultsArrayLazyQuery,
  useAthleteTeamListLazyQuery,
  useAthleteFullListQuery,
  useGenderAndAgeGroupsQuery,
} from '../../../api';
import useSortSportDisciplines from '../../../hooks/useSortSportDisciplines';
import ConfirmDialog from '../../ConfirmDialog';
import EventTeamsList from '../../../module/EventTeamsList';
import LoadingWall from '../../LoadingWall/LoadingWall';
import BtnReport from '../../BtnReport/BtnReport';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  row: {
    display: 'flex',
  },
});

interface formState {
  point?: string | number;
  pointTo?: string | number;
  score?: string;
  result?: string;
  dirAthlete?: DirAthlete;
  dirNationalTeam?: {
    label: string;
    value: string;
  };
}

interface rowProps {
  row: any;
  teams: any;
  id: Scalars['UUID'];
  programId: Scalars['UUID'];
  updated: Function;
  readonly?: boolean;
  event?: any;
  isUniverciad?: boolean;
}

function Row(props: rowProps) {
  const { row, id, event, updated, teams, programId, readonly, isUniverciad } = props;
  const defaultState: formState = {
    dirAthlete: undefined,
    point: undefined,
    pointTo: undefined,
    score: undefined,
    result: undefined,
  };

  const [deleteItem, setDeleteItem] = useState<any>(null);

  const [deniedAthletes, setDeniedAthletes] = useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const [showWall, setShowWall] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isFullTeam, setIsFullTeam] = useState(false);
  const [currentRequest, setCurrentRequest] = useState('');
  const [programTypeId, setProgramTypeId] = useState<Scalars['UUID']>('');
  const [competitionId, setCompetitionId] = useState<Scalars['UUID']>('');
  const [athleteToUpdateId, setAthleteToUpdateId] = useState<Scalars['UUID']>('');
  const classes = useRowStyles();

  const [updateDirAthlete]: UpdateDirAthleteMutationHookResult = useUpdateDirAthleteMutation();

  const { data: fullListData } = useAthleteFullListQuery({
    variables: {
      id,
      programTypeId: programId,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: genderAndAgeData } = useGenderAndAgeGroupsQuery({
    variables: {
      filter: {
        clsAgeGroups: {
          id: _.get(row, 'clsAgeGroups[0].value', ''),
        },
      },
    },
    fetchPolicy: 'no-cache',
    skip: _.get(row, 'clsAgeGroups[0].value', '') === '',
  });

  const genderIsMale = useMemo(() => _.get(genderAndAgeData, 'dirGenderAndAgeGroupses[0].isMale', undefined), [
    genderAndAgeData,
  ]);

  const [fetch, { loading, refetch, data }] = useAthleteCompetitionsResultsArrayLazyQuery({
    returnPartialData: true,
  });

  const [fetchAthleteList, { loading: athletesDataLoading, data: athletesData }] = useAthleteTeamListLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (programId && open) {
      fetch({ variables: { filter: { sportingEventProgramType: { id: programId } } } });
    }
  }, [fetch, open, programId]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const { handleSubmit, control, errors, reset, formState, watch } = useForm({
    defaultValues: defaultState,
  });

  const currentTeam = watch('dirNationalTeam');

  const fullList = fullListData?.dirAthleteCompetitionResults?.map((r) => r?.dirAthlete?.id);

  const isAthleteApt = (athleteId) => !fullList?.includes(athleteId);

  const handleSetFullTeam = (event: any) => setIsFullTeam(event.target.checked);

  const addAthlete = async (values: formState, isUpdate = false, athleteCustomId?: string) => {
    try {
      const { dirAthlete, point, pointTo, result, score } = values;
      if (isUpdate) {
        await updateDirAthlete({
          variables: {
            id: athleteToUpdateId,
            data: {
              competetionResult: {
                update: [
                  {
                    where: {
                      id: competitionId,
                    },
                    data: {
                      point: Number(point),
                      pointTo: Number(pointTo),
                      score: score || '',
                      result,
                      sportingEventProgramType: {
                        connect: {
                          id: programId,
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
        });
        return true;
      }
      if (isAthleteApt(athleteCustomId || dirAthlete?.id)) {
        await updateDirAthlete({
          variables: {
            id: athleteCustomId || dirAthlete?.id,
            data: {
              competetionResult: {
                create: [
                  {
                    point: Number(point),
                    pointTo: Number(pointTo),
                    score: score || '',
                    result: result || '',
                    sportingEventProgramType: {
                      connect: {
                        id: programId,
                      },
                    },
                  },
                ],
              },
            },
          },
        });
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const athleteList = useMemo(
    () =>
      (athletesData &&
        _.uniq(
          _.flattenDeep(
            athletesData?.dirNationalTeams?.map((team) =>
              team?.athleteGroups?.map((g) => g?.athletes?.map((a) => a?.dirAthlete?.id)),
            ),
          ),
        )) ||
      [],
    [athletesDataLoading, athletesData],
  );

  const onSubmit = async (values: formState) => {
    const isUpdate = currentRequest === 'update';
    let isFullField = false;
    try {
      if (isFullTeam) {
        await setShowWall(true);
        isFullField = await (await Promise.all(athleteList.map(async (i) => await addAthlete(values, false, i)))).some(
          (t) => t,
        );
        await setShowWall(false);
      } else {
        isFullField = (await addAthlete(values, isUpdate)) ?? false;
      }

      setOpenDialog(false);
      setIsFullTeam(false);
      setDeniedAthletes([]);
      reset(defaultState);
      if (currentRequest === 'update') {
        setProgramTypeId('');
        setAthleteToUpdateId('');
        setCompetitionId('');
        setCurrentRequest('');
      }
      isFullField && handleSnackBar('success', isUpdate ? 'Результат успешно обновлен' : 'Результат успешно добавлен');
      refetch?.({ filter: { sportingEventProgramType: { id: programId } } });
      updated();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteResult = async ({ athleteId, competId }: { athleteId: Scalars['UUID']; competId: Scalars['UUID'] }) => {
    try {
      await updateDirAthlete({
        variables: {
          id: athleteId,
          data: {
            competetionResult: {
              delete: [
                {
                  id: competId,
                },
              ],
            },
          },
        },
      });

      handleSnackBar('success', 'результат успешно удалено');
      refetch?.({ filter: { sportingEventProgramType: { id: programId } } });
      updated();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  useEffect(() => {
    if (currentTeam) {
      fetchAthleteList({
        variables: {
          id: currentTeam.value,
        },
      });
    }
  }, [currentTeam]);

  if (loading) return <LinearProgress />;

  return (
    <React.Fragment>
      {showWall && <LoadingWall label="Добавление спортсменов, не закрывайте страницу!" />}
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.clsAgeGroups && row?.clsAgeGroups[0]?.label}
        </TableCell>
        <TableCell>{row?.minAge}</TableCell>
        <TableCell>{row?.maxAge}</TableCell>
        <TableCell>{row?.discipline?.label}</TableCell>
        <TableCell>{row?.start}</TableCell>
        <TableCell>
          <Documents
            id={id}
            programId={row?.id}
            readonly={readonly}
            documents={row?.resultDocuments}
            onUpdateDocument={() => updated()}
          />
        </TableCell>
        <TableCell>
          <BtnReport
            label="Протокол"
            isLink
            lazyQuery={useAthleteCompetitionsResultsArrayLazyQuery}
            fileName="Результат соревнований (полная выгрузка).xlsx"
            serviceApi="sportingEventCompetitionReport"
            filter={{
              api: { sportingEventProgramType: { id: row?.id } },
            }}
            otherProps={{
              teams,
              isUniverciad,
              event,
              programType: row,
            }}
          />
        </TableCell>
        <TableCell>
          {!readonly && (
            <Tooltip title="добавить" placement="top-start">
              <IconButton
                color="primary"
                onClick={() => {
                  setDeniedAthletes(
                    data?.dirAthleteCompetitionResults
                      ?.map((r: any) => _.get(r, 'dirAthlete.id', null))
                      .filter((n) => n) || [],
                  );
                  reset(defaultState);
                  setProgramTypeId(row?.id);
                  setOpenDialog(true);
                }}
              >
                <AddBoxIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto">
            {teams.length > 0 && (
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Место</TableCell>
                      <TableCell>Реестровый номер</TableCell>
                      <TableCell colSpan={2}>ФИО</TableCell>
                      <TableCell>ДР</TableCell>
                      <TableCell>Регион</TableCell>
                      <TableCell>Результат</TableCell>
                      <TableCell>Очки</TableCell>

                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.dirAthleteCompetitionResults?.map((result: any) => (
                      <TableRow key={result?.id}>
                        <TableCell component="th" scope="row">
                          {result?.point}
                          {(result?.pointTo && ` - ${result?.pointTo}`) || ''}
                        </TableCell>
                        <TableCell>{result?.dirAthlete?.dirPerson?.registryNumber}</TableCell>
                        <TableCell colSpan={2}>
                          {`${result?.dirAthlete?.dirPerson?.lastname} ${result?.dirAthlete?.dirPerson?.firstname} ${
                            result?.dirAthlete?.dirPerson?.patronymic || ''
                          }`}
                        </TableCell>
                        <TableCell>{moment(result?.dirAthlete?.dirPerson?.birthday).format('DD.MM.YY')}</TableCell>

                        <TableCell>
                          {
                            teams?.find((t: any) =>
                              t?.athletesArray?.find((a: DirAthlete) => a?.id === result?.dirAthlete?.id),
                            )?.region?.label
                          }
                        </TableCell>
                        <TableCell>{result?.result}</TableCell>
                        <TableCell>{result?.score || ''}</TableCell>
                        <TableCell>
                          {!readonly && (
                            <div className={classes.row}>
                              <Tooltip title="изменить" placement="top-start">
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    setCurrentRequest('update');

                                    reset({
                                      ...result,
                                    });
                                    setAthleteToUpdateId(result?.dirAthlete?.id);
                                    setCompetitionId(result?.id);
                                    setProgramTypeId(row?.id);
                                    setOpenDialog(true);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="подробности" placement="top-start">
                                <IconButton color="secondary" onClick={() => setDeleteItem(result?.dirAthlete?.id)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                              <ConfirmDialog
                                title={`Удаление результата спортсмена`}
                                text={`Вы уверены что хотите удалить результаты?`}
                                btnVariant="outlined"
                                open={deleteItem === result?.dirAthlete?.id}
                                saveBtnText="да"
                                cancelBtnText="отмена"
                                saveBtnColor="secondary"
                                cancelBtnColor="primary"
                                onCancel={() => setDeleteItem(null)}
                                onSave={() => {
                                  deleteResult({
                                    athleteId: result?.dirAthlete?.id,
                                    competId: result?.id,
                                  }).then(() => setDeleteItem(null));
                                }}
                              />
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        onClose={() => {
          setOpenDialog(false);
          setIsFullTeam(false);
          setCurrentRequest('');
          reset(defaultState);
        }}
        aria-labelledby="add-calendar-dialog"
        open={openDialog}
        maxWidth="xs"
      >
        <DialogTitle id="add-calendar-dialog">Результат</DialogTitle>
        {!currentRequest && (
          <Box style={{ padding: '0 10px' }}>
            <FormControlLabel
              onChange={handleSetFullTeam}
              value={isFullTeam}
              labelPlacement="start"
              control={<Switch size="small" color="secondary" />}
              label="Добавить всю сборную"
            />
          </Box>
        )}
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              {isFullTeam ? (
                <Grid item xs={12}>
                  <EventTeamsList
                    id={id}
                    label="Сборная региона РФ"
                    control={control}
                    error={!!errors['dirNationalTeam']}
                    name="dirNationalTeam"
                    rules={{ required: true }}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} key={_.get(genderAndAgeData, 'dirGenderAndAgeGroupses.length', 0)}>
                  {!currentRequest && (
                    <AthletesInTeam
                      eventId={id}
                      isMale={genderIsMale}
                      deniedAthletes={deniedAthletes}
                      label="Спортсмен"
                      control={control}
                      error={!!errors['dirAthlete']}
                      name="dirAthlete"
                      rules={{ required: true }}
                    />
                  )}
                </Grid>
              )}

              <Grid item container justify="space-between" spacing={2}>
                <Grid item xs={!isFullTeam ? 3 : 6}>
                  <Input
                    label="Место"
                    control={control}
                    error={!!errors['point']}
                    name="point"
                    rules={{ required: true }}
                  />
                </Grid>
                {!isFullTeam && (
                  <Grid item xs={3}>
                    <Input label="Место по" control={control} error={!!errors['pointTo']} name="pointTo" />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Input
                    label="Результат"
                    control={control}
                    error={!!errors['result']}
                    name="result"
                    rules={{ required: true }}
                  />
                </Grid>
              </Grid>
              <Grid item container justify="space-between" spacing={2}>
                <Grid item xs={6}>
                  {!isFullTeam && <Input label="Очки" control={control} error={!!errors['score']} name="score" />}
                </Grid>
                <Grid item container alignContent="flex-end" justify="flex-end" xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                    type="submit"
                    disabled={!formState.isDirty}
                  >
                    {(currentRequest === 'update' && 'Сохранить') || 'Добавить'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

const CompetitionResults: FC<{ readonly?: boolean; isUniverciad?: boolean; id?: string }> = ({
  readonly,
  id,
  isUniverciad,
}) => {
  // const { refetch: refetchResult, data: resultsData } = useAthleteCompetitionsResultsArrayQuery({
  //   returnPartialData: true,
  // });

  const { loading, refetch, data } = useSportingEventCompetitionsResultsQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });

  const [programTypes, dirNationalTeams] = [
    data?.dirSportingEvent?.programTypes,
    data?.dirSportingEvent?.dirNationalTeams,
  ];

  // const handleUpdate = async () => {
  //   refetch().then((resp) => refetchResult());
  // };
  const programTypesMemoizedValue = useSortSportDisciplines(programTypes);

  if (loading) return <LinearProgress />;

  return (
    (programTypesMemoizedValue && programTypesMemoizedValue.length > 0 && (
      <TableContainer component={Paper} style={{ marginTop: 15 }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Возрастные группы</TableCell>
              <TableCell>Возраст с</TableCell>
              <TableCell>Возраст по&nbsp;</TableCell>
              <TableCell>Дисциплина&nbsp;</TableCell>
              <TableCell>Старт&nbsp;</TableCell>
              <TableCell>Документы&nbsp;</TableCell>
              <TableCell>Отчеты&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {programTypesMemoizedValue?.map((program: any) => (
              <Row
                key={program?.id}
                programId={program?.id}
                row={program}
                isUniverciad={isUniverciad}
                event={data?.dirSportingEvent}
                teams={dirNationalTeams?.map((team) => ({
                  region: team?.dirRegion,
                  university: team?.university,
                  athletesArray: _.flattenDeep(team?.athleteGroups?.map((g) => g?.athletes?.map((a) => a?.dirAthlete))),
                }))}
                // results={resultsData?.dirAthleteCompetitionResults?.filter(
                //   (r) => r?.sportingEventProgramType?.id === program?.id,
                // )}
                id={id}
                readonly={readonly}
                updated={() => refetch()}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )) ||
    null
  );
};

export default CompetitionResults;
