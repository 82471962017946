import { Button, Divider, Grid } from '@material-ui/core';
import {
  MainInfoFragment,
  TypeSportArchiveMutationHookResult,
  TypeSportCreateMutationHookResult,
  TypeSportUnArchiveMutationHookResult,
  TypeSportUpdateMutationHookResult,
  useFetchTypeSportByIdMainInfoQuery,
  useTypeSportArchiveMutation,
  useTypeSportCreateMutation,
  useTypeSportUnArchiveMutation,
  useTypeSportUpdateMutation,
} from '../../../api';
import React, { FC, useEffect } from 'react';

import ArchiveWidget from '../Shared/Archive';
import { ContentWrapperWidget } from '../../layouts';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';

const MainInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const { data, refetch } = useFetchTypeSportByIdMainInfoQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: data?.dirSport as MainInfoFragment,
  });
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [create]: TypeSportCreateMutationHookResult = useTypeSportCreateMutation();
  const [archive]: TypeSportArchiveMutationHookResult = useTypeSportArchiveMutation();
  const [unarchive]: TypeSportUnArchiveMutationHookResult = useTypeSportUnArchiveMutation();
  const [update]: TypeSportUpdateMutationHookResult = useTypeSportUpdateMutation();

  const onSubmit = async (values: MainInfoFragment) => {
    try {
      if (id !== 'new') {
        await update({
          variables: {
            id,
            fullName: values.fullName,
            shortName: values.shortName,
            evsk: values.evsk,
          },
        });
        enqueueSnackbar(`Вид спорта "${values?.fullName}" успешно обновлен`, { variant: 'success' });
      } else {
        const { data } = await create({
          variables: {
            fullName: values.fullName as string,
            shortName: values.shortName as string,
            evsk: values.evsk as string,
          },
        });
        if (data?.createDirSport && data?.createDirSport?.id) {
          enqueueSnackbar(`Вид спорта "${values?.fullName}" успешно добавлен`, { variant: 'success' });
          push(`/type_sports/${data?.createDirSport?.id}`);
        }
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data?.dirSport) {
      reset(data?.dirSport);
    }
  }, [data, reset]);

  return (
    <>
      <Grid container>
        <Grid item lg={6} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <Input
                    label="Полное название"
                    control={control}
                    error={!!errors['fullName']}
                    name="fullName"
                    disabled={readonly}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Краткое название"
                    control={control}
                    error={!!errors['shortName']}
                    name="shortName"
                    disabled={readonly}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Реестровый номер ЕВСК"
                    control={control}
                    error={!!errors['evsk']}
                    name="evsk"
                    disabled={readonly}
                  />
                </Grid>
                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                    disabled={!formState.isDirty}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
        {id && id !== 'new' && !readonly && (
          <Grid item lg={12} md={12} xs={12}>
            <ArchiveWidget
              archive={data?.dirSport?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
