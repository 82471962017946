import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UUID: any;
  DateTime: any;
  Json: any;
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  user?: Maybe<User>;
  users: Array<User>;
  userRole?: Maybe<UserRole>;
  userRoles: Array<UserRole>;
  regularUser?: Maybe<RegularUser>;
  regularUsers: Array<RegularUser>;
  systemUser?: Maybe<SystemUser>;
  systemUsers: Array<SystemUser>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfile>;
  regionalMinsportProfiles: Array<RegionalMinsportProfile>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfile>;
  regionalSchoolProfiles: Array<RegionalSchoolProfile>;
  notificationProtocol?: Maybe<NotificationProtocol>;
  notificationProtocols: Array<NotificationProtocol>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfile>;
  regionalAdministratorProfiles: Array<RegionalAdministratorProfile>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfile>;
  roivSpecialistProfiles: Array<RoivSpecialistProfile>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfile>;
  fcpsrSportingEventOrganizerProfiles: Array<FcpsrSportingEventOrganizerProfile>;
  dirSportingEventRegulationStatusValue?: Maybe<DirSportingEventRegulationStatusValue>;
  dirSportingEventRegulationStatusValues: Array<DirSportingEventRegulationStatusValue>;
  dirSportingEventRegulationStatus?: Maybe<DirSportingEventRegulationStatus>;
  dirSportingEventRegulationStatuses: Array<DirSportingEventRegulationStatus>;
  dirRefusedRegion?: Maybe<DirRefusedRegion>;
  dirRefusedRegions: Array<DirRefusedRegion>;
  dirReturnedRegion?: Maybe<DirReturnedRegion>;
  dirReturnedRegions: Array<DirReturnedRegion>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulations>;
  dirSportEventRegulationses: Array<DirSportEventRegulations>;
  gskRefereeProfile?: Maybe<GskRefereeProfile>;
  gskRefereeProfiles: Array<GskRefereeProfile>;
  userSupportRequest?: Maybe<UserSupportRequest>;
  userSupportRequests: Array<UserSupportRequest>;
  userSupportRequestType?: Maybe<UserSupportRequestType>;
  userSupportRequestTypes: Array<UserSupportRequestType>;
  fieldHistory?: Maybe<FieldHistory>;
  fieldHistories: Array<FieldHistory>;
  archive?: Maybe<Archive>;
  archives: Array<Archive>;
  classifier?: Maybe<Classifier>;
  classifiers: Array<Classifier>;
  classifierValue?: Maybe<ClassifierValue>;
  classifierValues: Array<ClassifierValue>;
  dirRegion?: Maybe<DirRegion>;
  dirRegions: Array<DirRegion>;
  dirFederalDistrict?: Maybe<DirFederalDistrict>;
  dirFederalDistricts: Array<DirFederalDistrict>;
  dirForeignCity?: Maybe<DirForeignCity>;
  dirForeignCities: Array<DirForeignCity>;
  dirCountry?: Maybe<DirCountry>;
  dirCountries: Array<DirCountry>;
  dirOrganization?: Maybe<DirOrganization>;
  dirOrganizations: Array<DirOrganization>;
  dirOrganizationSport?: Maybe<DirOrganizationSport>;
  dirOrganizationSports: Array<DirOrganizationSport>;
  dirOrganizationAddress?: Maybe<DirOrganizationAddress>;
  dirOrganizationAddresses: Array<DirOrganizationAddress>;
  dirOrganizationPhone?: Maybe<DirOrganizationPhone>;
  dirOrganizationPhones: Array<DirOrganizationPhone>;
  dirOrganizationEmail?: Maybe<DirOrganizationEmail>;
  dirOrganizationEmails: Array<DirOrganizationEmail>;
  dirPerson?: Maybe<DirPerson>;
  dirPersons: Array<DirPerson>;
  dirPersonPhone?: Maybe<DirPersonPhone>;
  dirPersonPhones: Array<DirPersonPhone>;
  dirPersonConfirmStatus?: Maybe<DirPersonConfirmStatus>;
  dirPersonConfirmStatuses: Array<DirPersonConfirmStatus>;
  dirPersonAdditionalRegion?: Maybe<DirPersonAdditionalRegion>;
  dirPersonAdditionalRegions: Array<DirPersonAdditionalRegion>;
  dirPersonMergeTask?: Maybe<DirPersonMergeTask>;
  dirPersonMergeTasks: Array<DirPersonMergeTask>;
  dirPersonAddress?: Maybe<DirPersonAddress>;
  dirPersonAddresses: Array<DirPersonAddress>;
  dirSport?: Maybe<DirSport>;
  dirSports: Array<DirSport>;
  dirSportDiscipline?: Maybe<DirSportDiscipline>;
  dirSportDisciplines: Array<DirSportDiscipline>;
  dirSportDisciplineGroup?: Maybe<DirSportDisciplineGroup>;
  dirSportDisciplineGroups: Array<DirSportDisciplineGroup>;
  dirSportAgeGroup?: Maybe<DirSportAgeGroup>;
  dirSportAgeGroups: Array<DirSportAgeGroup>;
  dirSportAgeGroupParticipationRequirements?: Maybe<DirSportAgeGroupParticipationRequirements>;
  dirSportAgeGroupParticipationRequirementses: Array<DirSportAgeGroupParticipationRequirements>;
  dirSportTrainingStage?: Maybe<DirSportTrainingStage>;
  dirSportTrainingStages: Array<DirSportTrainingStage>;
  dirSportBaseRegion?: Maybe<DirSportBaseRegion>;
  dirSportBaseRegions: Array<DirSportBaseRegion>;
  dirAthlete?: Maybe<DirAthlete>;
  dirAthletes: Array<DirAthlete>;
  dirAthleteSport?: Maybe<DirAthleteSport>;
  dirAthleteSports: Array<DirAthleteSport>;
  dirAthleteSportOrganization?: Maybe<DirAthleteSportOrganization>;
  dirAthleteSportOrganizations: Array<DirAthleteSportOrganization>;
  dirAthleteSportRankAndAward?: Maybe<DirAthleteSportRankAndAward>;
  dirAthleteSportRankAndAwards: Array<DirAthleteSportRankAndAward>;
  dirAthleteMedCert?: Maybe<DirAthleteMedCert>;
  dirAthleteMedCerts: Array<DirAthleteMedCert>;
  dirAthleteMedCertSport?: Maybe<DirAthleteMedCertSport>;
  dirAthleteMedCertSports: Array<DirAthleteMedCertSport>;
  dirAthleteCompetitionResult?: Maybe<DirAthleteCompetitionResult>;
  dirAthleteCompetitionResults: Array<DirAthleteCompetitionResult>;
  dirTrainer?: Maybe<DirTrainer>;
  dirTrainers: Array<DirTrainer>;
  dirTrainerSport?: Maybe<DirTrainerSport>;
  dirTrainerSports: Array<DirTrainerSport>;
  dirTrainerSportOrganization?: Maybe<DirTrainerSportOrganization>;
  dirTrainerSportOrganizations: Array<DirTrainerSportOrganization>;
  dirTrainerSportCategory?: Maybe<DirTrainerSportCategory>;
  dirTrainerSportCategories: Array<DirTrainerSportCategory>;
  dirReferee?: Maybe<DirReferee>;
  dirReferees: Array<DirReferee>;
  dirRefereeSport?: Maybe<DirRefereeSport>;
  dirRefereeSports: Array<DirRefereeSport>;
  dirRefereeSportCategory?: Maybe<DirRefereeSportCategory>;
  dirRefereeSportCategories: Array<DirRefereeSportCategory>;
  dirSportingEvent?: Maybe<DirSportingEvent>;
  dirSportingEvents: Array<DirSportingEvent>;
  quantyRequestStageTwo?: Maybe<QuantyRequestStageTwo>;
  quantyRequestStageTwoes: Array<QuantyRequestStageTwo>;
  dirSportingEventAthleteCallNotification?: Maybe<DirSportingEventAthleteCallNotification>;
  dirSportingEventAthleteCallNotifications: Array<DirSportingEventAthleteCallNotification>;
  dirSportingEventAthleteCallNotificationTeam?: Maybe<DirSportingEventAthleteCallNotificationTeam>;
  dirSportingEventAthleteCallNotificationTeams: Array<DirSportingEventAthleteCallNotificationTeam>;
  dirSportingEventCountry?: Maybe<DirSportingEventCountry>;
  dirSportingEventCountries: Array<DirSportingEventCountry>;
  dirSportingEventSport?: Maybe<DirSportingEventSport>;
  dirSportingEventSports: Array<DirSportingEventSport>;
  dirSportingEventAgeGroup?: Maybe<DirSportingEventAgeGroup>;
  dirSportingEventAgeGroups: Array<DirSportingEventAgeGroup>;
  dirSportingEventStatus?: Maybe<DirSportingEventStatus>;
  dirSportingEventStatuses: Array<DirSportingEventStatus>;
  dirSportingEventStatusHistory?: Maybe<DirSportingEventStatusHistory>;
  dirSportingEventStatusHistories: Array<DirSportingEventStatusHistory>;
  dirSportingEventExtendedStatus?: Maybe<DirSportingEventExtendedStatus>;
  dirSportingEventExtendedStatuses: Array<DirSportingEventExtendedStatus>;
  dirSportingEventExtendedStatusValue?: Maybe<DirSportingEventExtendedStatusValue>;
  dirSportingEventExtendedStatusValues: Array<DirSportingEventExtendedStatusValue>;
  dirSportingEventProgramType?: Maybe<DirSportingEventProgramType>;
  dirSportingEventProgramTypes: Array<DirSportingEventProgramType>;
  dirGenderAndAgeGroups?: Maybe<DirGenderAndAgeGroups>;
  dirGenderAndAgeGroupses: Array<DirGenderAndAgeGroups>;
  dirSportingEventReferee?: Maybe<DirSportingEventReferee>;
  dirSportingEventReferees: Array<DirSportingEventReferee>;
  dirSportingEventRefereeStatus?: Maybe<DirSportingEventRefereeStatus>;
  dirSportingEventRefereeStatuses: Array<DirSportingEventRefereeStatus>;
  dirSportingEventRefereeStatusValue?: Maybe<DirSportingEventRefereeStatusValue>;
  dirSportingEventRefereeStatusValues: Array<DirSportingEventRefereeStatusValue>;
  dirSportingEventCompetitionCalendar?: Maybe<DirSportingEventCompetitionCalendar>;
  dirSportingEventCompetitionCalendars: Array<DirSportingEventCompetitionCalendar>;
  dirSportingEventCost?: Maybe<DirSportingEventCost>;
  dirSportingEventCosts: Array<DirSportingEventCost>;
  dirSportingEventQuota?: Maybe<DirSportingEventQuota>;
  dirSportingEventQuotas: Array<DirSportingEventQuota>;
  dirSportingEventQuantity?: Maybe<DirSportingEventQuantity>;
  dirSportingEventQuantities: Array<DirSportingEventQuantity>;
  dirSportingEventPointDistribution?: Maybe<DirSportingEventPointDistribution>;
  dirSportingEventPointDistributions: Array<DirSportingEventPointDistribution>;
  dirSportingEventPointDistributionPlace?: Maybe<DirSportingEventPointDistributionPlace>;
  dirSportingEventPointDistributionPlaces: Array<DirSportingEventPointDistributionPlace>;
  dirSportingEventRequest?: Maybe<DirSportingEventRequest>;
  dirSportingEventRequests: Array<DirSportingEventRequest>;
  dirSportingEventRequestType?: Maybe<DirSportingEventRequestType>;
  dirSportingEventRequestTypes: Array<DirSportingEventRequestType>;
  dirSportingEventRequestStatus?: Maybe<DirSportingEventRequestStatus>;
  dirSportingEventRequestStatuses: Array<DirSportingEventRequestStatus>;
  dirSportingEventRequestStatusValue?: Maybe<DirSportingEventRequestStatusValue>;
  dirSportingEventRequestStatusValues: Array<DirSportingEventRequestStatusValue>;
  dirNationalTeam?: Maybe<DirNationalTeam>;
  dirNationalTeams: Array<DirNationalTeam>;
  dirNationalTeamEmailRecipient?: Maybe<DirNationalTeamEmailRecipient>;
  dirNationalTeamEmailRecipients: Array<DirNationalTeamEmailRecipient>;
  dirNationalTeamStatus?: Maybe<DirNationalTeamStatus>;
  dirNationalTeamStatuses: Array<DirNationalTeamStatus>;
  dirNationalTeamStatusHistory?: Maybe<DirNationalTeamStatusHistory>;
  dirNationalTeamStatusHistories: Array<DirNationalTeamStatusHistory>;
  dirNationalTeamExtendedStatus?: Maybe<DirNationalTeamExtendedStatus>;
  dirNationalTeamExtendedStatuses: Array<DirNationalTeamExtendedStatus>;
  dirNationalTeamTrainer?: Maybe<DirNationalTeamTrainer>;
  dirNationalTeamTrainers: Array<DirNationalTeamTrainer>;
  dirNationalTeamAthleteGroup?: Maybe<DirNationalTeamAthleteGroup>;
  dirNationalTeamAthleteGroups: Array<DirNationalTeamAthleteGroup>;
  dirNationalTeamAthlete?: Maybe<DirNationalTeamAthlete>;
  dirNationalTeamAthletes: Array<DirNationalTeamAthlete>;
  dirNationalTeamMember?: Maybe<DirNationalTeamMember>;
  dirNationalTeamMembers: Array<DirNationalTeamMember>;
  dirCalendar?: Maybe<DirCalendar>;
  dirCalendars: Array<DirCalendar>;
  quotaForStageTwoBySport?: Maybe<QuotaForStageTwoBySport>;
  quotaForStageTwoBySports: Array<QuotaForStageTwoBySport>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwo>;
  reqPartFromStageTwoes: Array<ReqPartFromStageTwo>;
  venueCompetition?: Maybe<VenueCompetition>;
  venueCompetitions: Array<VenueCompetition>;
  venueCompApplication?: Maybe<VenueCompApplication>;
  venueCompApplications: Array<VenueCompApplication>;
  dirCalendarType?: Maybe<DirCalendarType>;
  dirCalendarTypes: Array<DirCalendarType>;
  dirCalendarSignedReport?: Maybe<DirCalendarSignedReport>;
  dirCalendarSignedReports: Array<DirCalendarSignedReport>;
  dirDocument?: Maybe<DirDocument>;
  dirDocuments: Array<DirDocument>;
  addressType?: Maybe<AddressType>;
  addressTypes: Array<AddressType>;
  taskStatus?: Maybe<TaskStatus>;
  taskStatuses: Array<TaskStatus>;
  file?: Maybe<File>;
  files: Array<File>;
  usersCount: Scalars['Int'];
  userRolesCount: Scalars['Int'];
  regularUsersCount: Scalars['Int'];
  systemUsersCount: Scalars['Int'];
  regionalMinsportProfilesCount: Scalars['Int'];
  regionalSchoolProfilesCount: Scalars['Int'];
  notificationProtocolsCount: Scalars['Int'];
  regionalAdministratorProfilesCount: Scalars['Int'];
  roivSpecialistProfilesCount: Scalars['Int'];
  fcpsrSportingEventOrganizerProfilesCount: Scalars['Int'];
  dirSportingEventRegulationStatusValuesCount: Scalars['Int'];
  dirSportingEventRegulationStatusesCount: Scalars['Int'];
  dirRefusedRegionsCount: Scalars['Int'];
  dirReturnedRegionsCount: Scalars['Int'];
  dirSportEventRegulationsesCount: Scalars['Int'];
  gskRefereeProfilesCount: Scalars['Int'];
  userSupportRequestsCount: Scalars['Int'];
  userSupportRequestTypesCount: Scalars['Int'];
  fieldHistoriesCount: Scalars['Int'];
  archivesCount: Scalars['Int'];
  classifiersCount: Scalars['Int'];
  classifierValuesCount: Scalars['Int'];
  dirRegionsCount: Scalars['Int'];
  dirFederalDistrictsCount: Scalars['Int'];
  dirForeignCitiesCount: Scalars['Int'];
  dirCountriesCount: Scalars['Int'];
  dirOrganizationsCount: Scalars['Int'];
  dirOrganizationSportsCount: Scalars['Int'];
  dirOrganizationAddressesCount: Scalars['Int'];
  dirOrganizationPhonesCount: Scalars['Int'];
  dirOrganizationEmailsCount: Scalars['Int'];
  dirPersonsCount: Scalars['Int'];
  dirPersonPhonesCount: Scalars['Int'];
  dirPersonConfirmStatusesCount: Scalars['Int'];
  dirPersonAdditionalRegionsCount: Scalars['Int'];
  dirPersonMergeTasksCount: Scalars['Int'];
  dirPersonAddressesCount: Scalars['Int'];
  dirSportsCount: Scalars['Int'];
  dirSportDisciplinesCount: Scalars['Int'];
  dirSportDisciplineGroupsCount: Scalars['Int'];
  dirSportAgeGroupsCount: Scalars['Int'];
  dirSportAgeGroupParticipationRequirementsesCount: Scalars['Int'];
  dirSportTrainingStagesCount: Scalars['Int'];
  dirSportBaseRegionsCount: Scalars['Int'];
  dirAthletesCount: Scalars['Int'];
  dirAthleteSportsCount: Scalars['Int'];
  dirAthleteSportOrganizationsCount: Scalars['Int'];
  dirAthleteSportRankAndAwardsCount: Scalars['Int'];
  dirAthleteMedCertsCount: Scalars['Int'];
  dirAthleteMedCertSportsCount: Scalars['Int'];
  dirAthleteCompetitionResultsCount: Scalars['Int'];
  dirTrainersCount: Scalars['Int'];
  dirTrainerSportsCount: Scalars['Int'];
  dirTrainerSportOrganizationsCount: Scalars['Int'];
  dirTrainerSportCategoriesCount: Scalars['Int'];
  dirRefereesCount: Scalars['Int'];
  dirRefereeSportsCount: Scalars['Int'];
  dirRefereeSportCategoriesCount: Scalars['Int'];
  dirSportingEventsCount: Scalars['Int'];
  quantyRequestStageTwoesCount: Scalars['Int'];
  dirSportingEventAthleteCallNotificationsCount: Scalars['Int'];
  dirSportingEventAthleteCallNotificationTeamsCount: Scalars['Int'];
  dirSportingEventCountriesCount: Scalars['Int'];
  dirSportingEventSportsCount: Scalars['Int'];
  dirSportingEventAgeGroupsCount: Scalars['Int'];
  dirSportingEventStatusesCount: Scalars['Int'];
  dirSportingEventStatusHistoriesCount: Scalars['Int'];
  dirSportingEventExtendedStatusesCount: Scalars['Int'];
  dirSportingEventExtendedStatusValuesCount: Scalars['Int'];
  dirSportingEventProgramTypesCount: Scalars['Int'];
  dirGenderAndAgeGroupsesCount: Scalars['Int'];
  dirSportingEventRefereesCount: Scalars['Int'];
  dirSportingEventRefereeStatusesCount: Scalars['Int'];
  dirSportingEventRefereeStatusValuesCount: Scalars['Int'];
  dirSportingEventCompetitionCalendarsCount: Scalars['Int'];
  dirSportingEventCostsCount: Scalars['Int'];
  dirSportingEventQuotasCount: Scalars['Int'];
  dirSportingEventQuantitiesCount: Scalars['Int'];
  dirSportingEventPointDistributionsCount: Scalars['Int'];
  dirSportingEventPointDistributionPlacesCount: Scalars['Int'];
  dirSportingEventRequestsCount: Scalars['Int'];
  dirSportingEventRequestTypesCount: Scalars['Int'];
  dirSportingEventRequestStatusesCount: Scalars['Int'];
  dirSportingEventRequestStatusValuesCount: Scalars['Int'];
  dirNationalTeamsCount: Scalars['Int'];
  dirNationalTeamEmailRecipientsCount: Scalars['Int'];
  dirNationalTeamStatusesCount: Scalars['Int'];
  dirNationalTeamStatusHistoriesCount: Scalars['Int'];
  dirNationalTeamExtendedStatusesCount: Scalars['Int'];
  dirNationalTeamTrainersCount: Scalars['Int'];
  dirNationalTeamAthleteGroupsCount: Scalars['Int'];
  dirNationalTeamAthletesCount: Scalars['Int'];
  dirNationalTeamMembersCount: Scalars['Int'];
  dirCalendarsCount: Scalars['Int'];
  quotaForStageTwoBySportsCount: Scalars['Int'];
  reqPartFromStageTwoesCount: Scalars['Int'];
  venueCompetitionsCount: Scalars['Int'];
  venueCompApplicationsCount: Scalars['Int'];
  dirCalendarTypesCount: Scalars['Int'];
  dirCalendarSignedReportsCount: Scalars['Int'];
  dirDocumentsCount: Scalars['Int'];
  addressTypesCount: Scalars['Int'];
  taskStatusesCount: Scalars['Int'];
  filesCount: Scalars['Int'];
  /** Полнотекстовый поиск */
  usersSimilar: Array<SimilarUser>;
  /** Полнотекстовый поиск */
  dirPersonsSimilar: Array<SimilarPerson>;
  /** Полнотекстовый поиск */
  dirOrganizationsSimilar: Array<SimilarOrganization>;
  /** Полнотекстовый поиск */
  dirDocumentsSimilar: Array<SimilarDocument>;
  /** Полнотекстовый поиск */
  dirSportingEventsSimilar: Array<SimilarSportingEvent>;
  /** Сервис получения информации об организации по ОГРН */
  suggestOrganizations: Array<OrganizationSearchResultType>;
  /** Сервис подсказок по адресам */
  suggestAddresses: Array<AddressSearchResultType>;
  /** Сервис проверки Медицинской справки Спортсмена */
  publicAthleteMedCert?: Maybe<PublicAthleteMedCert>;
  /** Отчеты по Видам спорта */
  dirSportsReport: DirSportReport;
  /** Отчеты по Календарям */
  dirCalendarsReport: DirCalendarReport;
  /** Отчеты по Регионам */
  dirRegionsReport: DirRegionReport;
  /** Отчеты по Спортсменам */
  dirAthletesReport: DirAthleteReport;
  /** Отчеты по Тренерам */
  dirTrainersReport: DirTrainerReport;
  /** Отчеты по Организациям */
  dirOrganizationsReport: DirOrganizationReport;
  /** Отчеты по Спортивным мероприятиям */
  dirSportingEventsReport: DirSportingEventReport;
  /** Отчеты по Спортивным командам */
  dirNationalTeamsReport: DirNationalTeamReport;
  /** Отфильтрованый список Судей */
  dirRefereesReport: DirRefereeReport;
  /** Отфильтрованый список Персон */
  dirPersonsReport: DirPersonReport;
  dirSportingEventProxy?: Maybe<DirSportingEvent>;
  /** Общее количество участников - в разрезе конкретных мероприятий */
  aggregateParticipantsNumberByEvent: SportingEventParticipantsNumberByEvent;
  /** Агрегированные данные о количестве участников в спортивных мероприятиях, объединенных головным мероприятием */
  aggregateParticipantsNumberByParentEvent: SportingEventParticipantsNumberByParentEvent;
  /** Агрегированные данные о количестве участников в спортивных мероприятиях, объединенных головным Календарем */
  aggregateParticipantsNumberByCalendar: SportingEventParticipantsNumberByCalendarType;
  /** Агрегированные данные о возрасте участников в спортивных мероприятиях, объединенных Календарем */
  aggregateParticipantsAgeByCalendar: SportingEventParticipantsAgeByCalendarType;
  /** Агрегированные данные официальная заявки субъекта РФ */
  aggregateParticipantsApplicationByTeamEvents: SportingEventParticipantsApplicationByEventsTeamType;
  /** Агрегированные данные о спортивной квалификации участников в спортивных мероприятиях, объединенных Календарем */
  aggregateParticipantsRankAndAwardByCalendar: SportingEventParticipantsRankAndAwardByCalendarType;
  /** Агрегированные данные о квалификации судей спортивных мероприятиях, объединенных Календарем */
  aggregateEventRefereesCategoryByCalendar: SportingEventRefereesCategoryByCalendarType;
  /** Агрегированные данные о занятых местах спортсменами не архивных сборных команд в спортивном мероприятии в разрезе сборных команд Регионов РФ */
  aggregateEventAthletesByPoints: SportingEventAthletesByPointsType;
  me: Me;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserRoleArgs = {
  where: UserRoleWhereUniqueInput;
};


export type QueryUserRolesArgs = {
  where?: Maybe<UserRoleWhereInput>;
  orderBy?: Maybe<UserRoleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRegularUserArgs = {
  where: RegularUserWhereUniqueInput;
};


export type QueryRegularUsersArgs = {
  where?: Maybe<RegularUserWhereInput>;
  orderBy?: Maybe<RegularUserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySystemUserArgs = {
  where: SystemUserWhereUniqueInput;
};


export type QuerySystemUsersArgs = {
  where?: Maybe<SystemUserWhereInput>;
  orderBy?: Maybe<SystemUserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRegionalMinsportProfileArgs = {
  where: RegionalMinsportProfileWhereUniqueInput;
};


export type QueryRegionalMinsportProfilesArgs = {
  where?: Maybe<RegionalMinsportProfileWhereInput>;
  orderBy?: Maybe<RegionalMinsportProfileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRegionalSchoolProfileArgs = {
  where: RegionalSchoolProfileWhereUniqueInput;
};


export type QueryRegionalSchoolProfilesArgs = {
  where?: Maybe<RegionalSchoolProfileWhereInput>;
  orderBy?: Maybe<RegionalSchoolProfileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryNotificationProtocolArgs = {
  where: NotificationProtocolWhereUniqueInput;
};


export type QueryNotificationProtocolsArgs = {
  where?: Maybe<NotificationProtocolWhereInput>;
  orderBy?: Maybe<NotificationProtocolOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRegionalAdministratorProfileArgs = {
  where: RegionalAdministratorProfileWhereUniqueInput;
};


export type QueryRegionalAdministratorProfilesArgs = {
  where?: Maybe<RegionalAdministratorProfileWhereInput>;
  orderBy?: Maybe<RegionalAdministratorProfileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRoivSpecialistProfileArgs = {
  where: RoivSpecialistProfileWhereUniqueInput;
};


export type QueryRoivSpecialistProfilesArgs = {
  where?: Maybe<RoivSpecialistProfileWhereInput>;
  orderBy?: Maybe<RoivSpecialistProfileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryFcpsrSportingEventOrganizerProfileArgs = {
  where: FcpsrSportingEventOrganizerProfileWhereUniqueInput;
};


export type QueryFcpsrSportingEventOrganizerProfilesArgs = {
  where?: Maybe<FcpsrSportingEventOrganizerProfileWhereInput>;
  orderBy?: Maybe<FcpsrSportingEventOrganizerProfileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventRegulationStatusValueArgs = {
  where: DirSportingEventRegulationStatusValueWhereUniqueInput;
};


export type QueryDirSportingEventRegulationStatusValuesArgs = {
  where?: Maybe<DirSportingEventRegulationStatusValueWhereInput>;
  orderBy?: Maybe<DirSportingEventRegulationStatusValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventRegulationStatusArgs = {
  where: DirSportingEventRegulationStatusWhereUniqueInput;
};


export type QueryDirSportingEventRegulationStatusesArgs = {
  where?: Maybe<DirSportingEventRegulationStatusWhereInput>;
  orderBy?: Maybe<DirSportingEventRegulationStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirRefusedRegionArgs = {
  where: DirRefusedRegionWhereUniqueInput;
};


export type QueryDirRefusedRegionsArgs = {
  where?: Maybe<DirRefusedRegionWhereInput>;
  orderBy?: Maybe<DirRefusedRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirReturnedRegionArgs = {
  where: DirReturnedRegionWhereUniqueInput;
};


export type QueryDirReturnedRegionsArgs = {
  where?: Maybe<DirReturnedRegionWhereInput>;
  orderBy?: Maybe<DirReturnedRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportEventRegulationsArgs = {
  where: DirSportEventRegulationsWhereUniqueInput;
};


export type QueryDirSportEventRegulationsesArgs = {
  where?: Maybe<DirSportEventRegulationsWhereInput>;
  orderBy?: Maybe<DirSportEventRegulationsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGskRefereeProfileArgs = {
  where: GskRefereeProfileWhereUniqueInput;
};


export type QueryGskRefereeProfilesArgs = {
  where?: Maybe<GskRefereeProfileWhereInput>;
  orderBy?: Maybe<GskRefereeProfileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserSupportRequestArgs = {
  where: UserSupportRequestWhereUniqueInput;
};


export type QueryUserSupportRequestsArgs = {
  where?: Maybe<UserSupportRequestWhereInput>;
  orderBy?: Maybe<UserSupportRequestOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserSupportRequestTypeArgs = {
  where: UserSupportRequestTypeWhereUniqueInput;
};


export type QueryUserSupportRequestTypesArgs = {
  where?: Maybe<UserSupportRequestTypeWhereInput>;
  orderBy?: Maybe<UserSupportRequestTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryFieldHistoryArgs = {
  where: FieldHistoryWhereUniqueInput;
};


export type QueryFieldHistoriesArgs = {
  where?: Maybe<FieldHistoryWhereInput>;
  orderBy?: Maybe<FieldHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryArchiveArgs = {
  where: ArchiveWhereUniqueInput;
};


export type QueryArchivesArgs = {
  where?: Maybe<ArchiveWhereInput>;
  orderBy?: Maybe<ArchiveOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryClassifierArgs = {
  where: ClassifierWhereUniqueInput;
};


export type QueryClassifiersArgs = {
  where?: Maybe<ClassifierWhereInput>;
  orderBy?: Maybe<ClassifierOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryClassifierValueArgs = {
  where: ClassifierValueWhereUniqueInput;
};


export type QueryClassifierValuesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirRegionArgs = {
  where: DirRegionWhereUniqueInput;
};


export type QueryDirRegionsArgs = {
  where?: Maybe<DirRegionWhereInput>;
  orderBy?: Maybe<DirRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirFederalDistrictArgs = {
  where: DirFederalDistrictWhereUniqueInput;
};


export type QueryDirFederalDistrictsArgs = {
  where?: Maybe<DirFederalDistrictWhereInput>;
  orderBy?: Maybe<DirFederalDistrictOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirForeignCityArgs = {
  where: DirForeignCityWhereUniqueInput;
};


export type QueryDirForeignCitiesArgs = {
  where?: Maybe<DirForeignCityWhereInput>;
  orderBy?: Maybe<DirForeignCityOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirCountryArgs = {
  where: DirCountryWhereUniqueInput;
};


export type QueryDirCountriesArgs = {
  where?: Maybe<DirCountryWhereInput>;
  orderBy?: Maybe<DirCountryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirOrganizationArgs = {
  where: DirOrganizationWhereUniqueInput;
};


export type QueryDirOrganizationsArgs = {
  where?: Maybe<DirOrganizationWhereInput>;
  orderBy?: Maybe<DirOrganizationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirOrganizationSportArgs = {
  where: DirOrganizationSportWhereUniqueInput;
};


export type QueryDirOrganizationSportsArgs = {
  where?: Maybe<DirOrganizationSportWhereInput>;
  orderBy?: Maybe<DirOrganizationSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirOrganizationAddressArgs = {
  where: DirOrganizationAddressWhereUniqueInput;
};


export type QueryDirOrganizationAddressesArgs = {
  where?: Maybe<DirOrganizationAddressWhereInput>;
  orderBy?: Maybe<DirOrganizationAddressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirOrganizationPhoneArgs = {
  where: DirOrganizationPhoneWhereUniqueInput;
};


export type QueryDirOrganizationPhonesArgs = {
  where?: Maybe<DirOrganizationPhoneWhereInput>;
  orderBy?: Maybe<DirOrganizationPhoneOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirOrganizationEmailArgs = {
  where: DirOrganizationEmailWhereUniqueInput;
};


export type QueryDirOrganizationEmailsArgs = {
  where?: Maybe<DirOrganizationEmailWhereInput>;
  orderBy?: Maybe<DirOrganizationEmailOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirPersonArgs = {
  where: DirPersonWhereUniqueInput;
};


export type QueryDirPersonsArgs = {
  where?: Maybe<DirPersonWhereInput>;
  orderBy?: Maybe<DirPersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirPersonPhoneArgs = {
  where: DirPersonPhoneWhereUniqueInput;
};


export type QueryDirPersonPhonesArgs = {
  where?: Maybe<DirPersonPhoneWhereInput>;
  orderBy?: Maybe<DirPersonPhoneOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirPersonConfirmStatusArgs = {
  where: DirPersonConfirmStatusWhereUniqueInput;
};


export type QueryDirPersonConfirmStatusesArgs = {
  where?: Maybe<DirPersonConfirmStatusWhereInput>;
  orderBy?: Maybe<DirPersonConfirmStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirPersonAdditionalRegionArgs = {
  where: DirPersonAdditionalRegionWhereUniqueInput;
};


export type QueryDirPersonAdditionalRegionsArgs = {
  where?: Maybe<DirPersonAdditionalRegionWhereInput>;
  orderBy?: Maybe<DirPersonAdditionalRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirPersonMergeTaskArgs = {
  where: DirPersonMergeTaskWhereUniqueInput;
};


export type QueryDirPersonMergeTasksArgs = {
  where?: Maybe<DirPersonMergeTaskWhereInput>;
  orderBy?: Maybe<DirPersonMergeTaskOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirPersonAddressArgs = {
  where: DirPersonAddressWhereUniqueInput;
};


export type QueryDirPersonAddressesArgs = {
  where?: Maybe<DirPersonAddressWhereInput>;
  orderBy?: Maybe<DirPersonAddressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportArgs = {
  where: DirSportWhereUniqueInput;
};


export type QueryDirSportsArgs = {
  where?: Maybe<DirSportWhereInput>;
  orderBy?: Maybe<DirSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportDisciplineArgs = {
  where: DirSportDisciplineWhereUniqueInput;
};


export type QueryDirSportDisciplinesArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
  orderBy?: Maybe<DirSportDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportDisciplineGroupArgs = {
  where: DirSportDisciplineGroupWhereUniqueInput;
};


export type QueryDirSportDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportAgeGroupArgs = {
  where: DirSportAgeGroupWhereUniqueInput;
};


export type QueryDirSportAgeGroupsArgs = {
  where?: Maybe<DirSportAgeGroupWhereInput>;
  orderBy?: Maybe<DirSportAgeGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportAgeGroupParticipationRequirementsArgs = {
  where: DirSportAgeGroupParticipationRequirementsWhereUniqueInput;
};


export type QueryDirSportAgeGroupParticipationRequirementsesArgs = {
  where?: Maybe<DirSportAgeGroupParticipationRequirementsWhereInput>;
  orderBy?: Maybe<DirSportAgeGroupParticipationRequirementsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportTrainingStageArgs = {
  where: DirSportTrainingStageWhereUniqueInput;
};


export type QueryDirSportTrainingStagesArgs = {
  where?: Maybe<DirSportTrainingStageWhereInput>;
  orderBy?: Maybe<DirSportTrainingStageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportBaseRegionArgs = {
  where: DirSportBaseRegionWhereUniqueInput;
};


export type QueryDirSportBaseRegionsArgs = {
  where?: Maybe<DirSportBaseRegionWhereInput>;
  orderBy?: Maybe<DirSportBaseRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirAthleteArgs = {
  where: DirAthleteWhereUniqueInput;
};


export type QueryDirAthletesArgs = {
  where?: Maybe<DirAthleteWhereInput>;
  orderBy?: Maybe<DirAthleteOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirAthleteSportArgs = {
  where: DirAthleteSportWhereUniqueInput;
};


export type QueryDirAthleteSportsArgs = {
  where?: Maybe<DirAthleteSportWhereInput>;
  orderBy?: Maybe<DirAthleteSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirAthleteSportOrganizationArgs = {
  where: DirAthleteSportOrganizationWhereUniqueInput;
};


export type QueryDirAthleteSportOrganizationsArgs = {
  where?: Maybe<DirAthleteSportOrganizationWhereInput>;
  orderBy?: Maybe<DirAthleteSportOrganizationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirAthleteSportRankAndAwardArgs = {
  where: DirAthleteSportRankAndAwardWhereUniqueInput;
};


export type QueryDirAthleteSportRankAndAwardsArgs = {
  where?: Maybe<DirAthleteSportRankAndAwardWhereInput>;
  orderBy?: Maybe<DirAthleteSportRankAndAwardOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirAthleteMedCertArgs = {
  where: DirAthleteMedCertWhereUniqueInput;
};


export type QueryDirAthleteMedCertsArgs = {
  where?: Maybe<DirAthleteMedCertWhereInput>;
  orderBy?: Maybe<DirAthleteMedCertOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirAthleteMedCertSportArgs = {
  where: DirAthleteMedCertSportWhereUniqueInput;
};


export type QueryDirAthleteMedCertSportsArgs = {
  where?: Maybe<DirAthleteMedCertSportWhereInput>;
  orderBy?: Maybe<DirAthleteMedCertSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirAthleteCompetitionResultArgs = {
  where: DirAthleteCompetitionResultWhereUniqueInput;
};


export type QueryDirAthleteCompetitionResultsArgs = {
  where?: Maybe<DirAthleteCompetitionResultWhereInput>;
  orderBy?: Maybe<DirAthleteCompetitionResultOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirTrainerArgs = {
  where: DirTrainerWhereUniqueInput;
};


export type QueryDirTrainersArgs = {
  where?: Maybe<DirTrainerWhereInput>;
  orderBy?: Maybe<DirTrainerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirTrainerSportArgs = {
  where: DirTrainerSportWhereUniqueInput;
};


export type QueryDirTrainerSportsArgs = {
  where?: Maybe<DirTrainerSportWhereInput>;
  orderBy?: Maybe<DirTrainerSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirTrainerSportOrganizationArgs = {
  where: DirTrainerSportOrganizationWhereUniqueInput;
};


export type QueryDirTrainerSportOrganizationsArgs = {
  where?: Maybe<DirTrainerSportOrganizationWhereInput>;
  orderBy?: Maybe<DirTrainerSportOrganizationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirTrainerSportCategoryArgs = {
  where: DirTrainerSportCategoryWhereUniqueInput;
};


export type QueryDirTrainerSportCategoriesArgs = {
  where?: Maybe<DirTrainerSportCategoryWhereInput>;
  orderBy?: Maybe<DirTrainerSportCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirRefereeArgs = {
  where: DirRefereeWhereUniqueInput;
};


export type QueryDirRefereesArgs = {
  where?: Maybe<DirRefereeWhereInput>;
  orderBy?: Maybe<DirRefereeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirRefereeSportArgs = {
  where: DirRefereeSportWhereUniqueInput;
};


export type QueryDirRefereeSportsArgs = {
  where?: Maybe<DirRefereeSportWhereInput>;
  orderBy?: Maybe<DirRefereeSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirRefereeSportCategoryArgs = {
  where: DirRefereeSportCategoryWhereUniqueInput;
};


export type QueryDirRefereeSportCategoriesArgs = {
  where?: Maybe<DirRefereeSportCategoryWhereInput>;
  orderBy?: Maybe<DirRefereeSportCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventArgs = {
  where: DirSportingEventWhereUniqueInput;
};


export type QueryDirSportingEventsArgs = {
  where?: Maybe<DirSportingEventWhereInput>;
  orderBy?: Maybe<DirSportingEventOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryQuantyRequestStageTwoArgs = {
  where: QuantyRequestStageTwoWhereUniqueInput;
};


export type QueryQuantyRequestStageTwoesArgs = {
  where?: Maybe<QuantyRequestStageTwoWhereInput>;
  orderBy?: Maybe<QuantyRequestStageTwoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventAthleteCallNotificationArgs = {
  where: DirSportingEventAthleteCallNotificationWhereUniqueInput;
};


export type QueryDirSportingEventAthleteCallNotificationsArgs = {
  where?: Maybe<DirSportingEventAthleteCallNotificationWhereInput>;
  orderBy?: Maybe<DirSportingEventAthleteCallNotificationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventAthleteCallNotificationTeamArgs = {
  where: DirSportingEventAthleteCallNotificationTeamWhereUniqueInput;
};


export type QueryDirSportingEventAthleteCallNotificationTeamsArgs = {
  where?: Maybe<DirSportingEventAthleteCallNotificationTeamWhereInput>;
  orderBy?: Maybe<DirSportingEventAthleteCallNotificationTeamOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventCountryArgs = {
  where: DirSportingEventCountryWhereUniqueInput;
};


export type QueryDirSportingEventCountriesArgs = {
  where?: Maybe<DirSportingEventCountryWhereInput>;
  orderBy?: Maybe<DirSportingEventCountryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventSportArgs = {
  where: DirSportingEventSportWhereUniqueInput;
};


export type QueryDirSportingEventSportsArgs = {
  where?: Maybe<DirSportingEventSportWhereInput>;
  orderBy?: Maybe<DirSportingEventSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventAgeGroupArgs = {
  where: DirSportingEventAgeGroupWhereUniqueInput;
};


export type QueryDirSportingEventAgeGroupsArgs = {
  where?: Maybe<DirSportingEventAgeGroupWhereInput>;
  orderBy?: Maybe<DirSportingEventAgeGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventStatusArgs = {
  where: DirSportingEventStatusWhereUniqueInput;
};


export type QueryDirSportingEventStatusesArgs = {
  where?: Maybe<DirSportingEventStatusWhereInput>;
  orderBy?: Maybe<DirSportingEventStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventStatusHistoryArgs = {
  where: DirSportingEventStatusHistoryWhereUniqueInput;
};


export type QueryDirSportingEventStatusHistoriesArgs = {
  where?: Maybe<DirSportingEventStatusHistoryWhereInput>;
  orderBy?: Maybe<DirSportingEventStatusHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventExtendedStatusArgs = {
  where: DirSportingEventExtendedStatusWhereUniqueInput;
};


export type QueryDirSportingEventExtendedStatusesArgs = {
  where?: Maybe<DirSportingEventExtendedStatusWhereInput>;
  orderBy?: Maybe<DirSportingEventExtendedStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventExtendedStatusValueArgs = {
  where: DirSportingEventExtendedStatusValueWhereUniqueInput;
};


export type QueryDirSportingEventExtendedStatusValuesArgs = {
  where?: Maybe<DirSportingEventExtendedStatusValueWhereInput>;
  orderBy?: Maybe<DirSportingEventExtendedStatusValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventProgramTypeArgs = {
  where: DirSportingEventProgramTypeWhereUniqueInput;
};


export type QueryDirSportingEventProgramTypesArgs = {
  where?: Maybe<DirSportingEventProgramTypeWhereInput>;
  orderBy?: Maybe<DirSportingEventProgramTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirGenderAndAgeGroupsArgs = {
  where: DirGenderAndAgeGroupsWhereUniqueInput;
};


export type QueryDirGenderAndAgeGroupsesArgs = {
  where?: Maybe<DirGenderAndAgeGroupsWhereInput>;
  orderBy?: Maybe<DirGenderAndAgeGroupsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventRefereeArgs = {
  where: DirSportingEventRefereeWhereUniqueInput;
};


export type QueryDirSportingEventRefereesArgs = {
  where?: Maybe<DirSportingEventRefereeWhereInput>;
  orderBy?: Maybe<DirSportingEventRefereeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventRefereeStatusArgs = {
  where: DirSportingEventRefereeStatusWhereUniqueInput;
};


export type QueryDirSportingEventRefereeStatusesArgs = {
  where?: Maybe<DirSportingEventRefereeStatusWhereInput>;
  orderBy?: Maybe<DirSportingEventRefereeStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventRefereeStatusValueArgs = {
  where: DirSportingEventRefereeStatusValueWhereUniqueInput;
};


export type QueryDirSportingEventRefereeStatusValuesArgs = {
  where?: Maybe<DirSportingEventRefereeStatusValueWhereInput>;
  orderBy?: Maybe<DirSportingEventRefereeStatusValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventCompetitionCalendarArgs = {
  where: DirSportingEventCompetitionCalendarWhereUniqueInput;
};


export type QueryDirSportingEventCompetitionCalendarsArgs = {
  where?: Maybe<DirSportingEventCompetitionCalendarWhereInput>;
  orderBy?: Maybe<DirSportingEventCompetitionCalendarOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventCostArgs = {
  where: DirSportingEventCostWhereUniqueInput;
};


export type QueryDirSportingEventCostsArgs = {
  where?: Maybe<DirSportingEventCostWhereInput>;
  orderBy?: Maybe<DirSportingEventCostOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventQuotaArgs = {
  where: DirSportingEventQuotaWhereUniqueInput;
};


export type QueryDirSportingEventQuotasArgs = {
  where?: Maybe<DirSportingEventQuotaWhereInput>;
  orderBy?: Maybe<DirSportingEventQuotaOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventQuantityArgs = {
  where: DirSportingEventQuantityWhereUniqueInput;
};


export type QueryDirSportingEventQuantitiesArgs = {
  where?: Maybe<DirSportingEventQuantityWhereInput>;
  orderBy?: Maybe<DirSportingEventQuantityOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventPointDistributionArgs = {
  where: DirSportingEventPointDistributionWhereUniqueInput;
};


export type QueryDirSportingEventPointDistributionsArgs = {
  where?: Maybe<DirSportingEventPointDistributionWhereInput>;
  orderBy?: Maybe<DirSportingEventPointDistributionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventPointDistributionPlaceArgs = {
  where: DirSportingEventPointDistributionPlaceWhereUniqueInput;
};


export type QueryDirSportingEventPointDistributionPlacesArgs = {
  where?: Maybe<DirSportingEventPointDistributionPlaceWhereInput>;
  orderBy?: Maybe<DirSportingEventPointDistributionPlaceOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventRequestArgs = {
  where: DirSportingEventRequestWhereUniqueInput;
};


export type QueryDirSportingEventRequestsArgs = {
  where?: Maybe<DirSportingEventRequestWhereInput>;
  orderBy?: Maybe<DirSportingEventRequestOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventRequestTypeArgs = {
  where: DirSportingEventRequestTypeWhereUniqueInput;
};


export type QueryDirSportingEventRequestTypesArgs = {
  where?: Maybe<DirSportingEventRequestTypeWhereInput>;
  orderBy?: Maybe<DirSportingEventRequestTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventRequestStatusArgs = {
  where: DirSportingEventRequestStatusWhereUniqueInput;
};


export type QueryDirSportingEventRequestStatusesArgs = {
  where?: Maybe<DirSportingEventRequestStatusWhereInput>;
  orderBy?: Maybe<DirSportingEventRequestStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventRequestStatusValueArgs = {
  where: DirSportingEventRequestStatusValueWhereUniqueInput;
};


export type QueryDirSportingEventRequestStatusValuesArgs = {
  where?: Maybe<DirSportingEventRequestStatusValueWhereInput>;
  orderBy?: Maybe<DirSportingEventRequestStatusValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirNationalTeamArgs = {
  where: DirNationalTeamWhereUniqueInput;
};


export type QueryDirNationalTeamsArgs = {
  where?: Maybe<DirNationalTeamWhereInput>;
  orderBy?: Maybe<DirNationalTeamOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirNationalTeamEmailRecipientArgs = {
  where: DirNationalTeamEmailRecipientWhereUniqueInput;
};


export type QueryDirNationalTeamEmailRecipientsArgs = {
  where?: Maybe<DirNationalTeamEmailRecipientWhereInput>;
  orderBy?: Maybe<DirNationalTeamEmailRecipientOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirNationalTeamStatusArgs = {
  where: DirNationalTeamStatusWhereUniqueInput;
};


export type QueryDirNationalTeamStatusesArgs = {
  where?: Maybe<DirNationalTeamStatusWhereInput>;
  orderBy?: Maybe<DirNationalTeamStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirNationalTeamStatusHistoryArgs = {
  where: DirNationalTeamStatusHistoryWhereUniqueInput;
};


export type QueryDirNationalTeamStatusHistoriesArgs = {
  where?: Maybe<DirNationalTeamStatusHistoryWhereInput>;
  orderBy?: Maybe<DirNationalTeamStatusHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirNationalTeamExtendedStatusArgs = {
  where: DirNationalTeamExtendedStatusWhereUniqueInput;
};


export type QueryDirNationalTeamExtendedStatusesArgs = {
  where?: Maybe<DirNationalTeamExtendedStatusWhereInput>;
  orderBy?: Maybe<DirNationalTeamExtendedStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirNationalTeamTrainerArgs = {
  where: DirNationalTeamTrainerWhereUniqueInput;
};


export type QueryDirNationalTeamTrainersArgs = {
  where?: Maybe<DirNationalTeamTrainerWhereInput>;
  orderBy?: Maybe<DirNationalTeamTrainerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirNationalTeamAthleteGroupArgs = {
  where: DirNationalTeamAthleteGroupWhereUniqueInput;
};


export type QueryDirNationalTeamAthleteGroupsArgs = {
  where?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
  orderBy?: Maybe<DirNationalTeamAthleteGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirNationalTeamAthleteArgs = {
  where: DirNationalTeamAthleteWhereUniqueInput;
};


export type QueryDirNationalTeamAthletesArgs = {
  where?: Maybe<DirNationalTeamAthleteWhereInput>;
  orderBy?: Maybe<DirNationalTeamAthleteOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirNationalTeamMemberArgs = {
  where: DirNationalTeamMemberWhereUniqueInput;
};


export type QueryDirNationalTeamMembersArgs = {
  where?: Maybe<DirNationalTeamMemberWhereInput>;
  orderBy?: Maybe<DirNationalTeamMemberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirCalendarArgs = {
  where: DirCalendarWhereUniqueInput;
};


export type QueryDirCalendarsArgs = {
  where?: Maybe<DirCalendarWhereInput>;
  orderBy?: Maybe<DirCalendarOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryQuotaForStageTwoBySportArgs = {
  where: QuotaForStageTwoBySportWhereUniqueInput;
};


export type QueryQuotaForStageTwoBySportsArgs = {
  where?: Maybe<QuotaForStageTwoBySportWhereInput>;
  orderBy?: Maybe<QuotaForStageTwoBySportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryReqPartFromStageTwoArgs = {
  where: ReqPartFromStageTwoWhereUniqueInput;
};


export type QueryReqPartFromStageTwoesArgs = {
  where?: Maybe<ReqPartFromStageTwoWhereInput>;
  orderBy?: Maybe<ReqPartFromStageTwoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryVenueCompetitionArgs = {
  where: VenueCompetitionWhereUniqueInput;
};


export type QueryVenueCompetitionsArgs = {
  where?: Maybe<VenueCompetitionWhereInput>;
  orderBy?: Maybe<VenueCompetitionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryVenueCompApplicationArgs = {
  where: VenueCompApplicationWhereUniqueInput;
};


export type QueryVenueCompApplicationsArgs = {
  where?: Maybe<VenueCompApplicationWhereInput>;
  orderBy?: Maybe<VenueCompApplicationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirCalendarTypeArgs = {
  where: DirCalendarTypeWhereUniqueInput;
};


export type QueryDirCalendarTypesArgs = {
  where?: Maybe<DirCalendarTypeWhereInput>;
  orderBy?: Maybe<DirCalendarTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirCalendarSignedReportArgs = {
  where: DirCalendarSignedReportWhereUniqueInput;
};


export type QueryDirCalendarSignedReportsArgs = {
  where?: Maybe<DirCalendarSignedReportWhereInput>;
  orderBy?: Maybe<DirCalendarSignedReportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDirDocumentArgs = {
  where: DirDocumentWhereUniqueInput;
};


export type QueryDirDocumentsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAddressTypeArgs = {
  where: AddressTypeWhereUniqueInput;
};


export type QueryAddressTypesArgs = {
  where?: Maybe<AddressTypeWhereInput>;
  orderBy?: Maybe<AddressTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTaskStatusArgs = {
  where: TaskStatusWhereUniqueInput;
};


export type QueryTaskStatusesArgs = {
  where?: Maybe<TaskStatusWhereInput>;
  orderBy?: Maybe<TaskStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryFileArgs = {
  where: FileWhereUniqueInput;
};


export type QueryFilesArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUsersCountArgs = {
  where?: Maybe<UserWhereInput>;
};


export type QueryUserRolesCountArgs = {
  where?: Maybe<UserRoleWhereInput>;
};


export type QueryRegularUsersCountArgs = {
  where?: Maybe<RegularUserWhereInput>;
};


export type QuerySystemUsersCountArgs = {
  where?: Maybe<SystemUserWhereInput>;
};


export type QueryRegionalMinsportProfilesCountArgs = {
  where?: Maybe<RegionalMinsportProfileWhereInput>;
};


export type QueryRegionalSchoolProfilesCountArgs = {
  where?: Maybe<RegionalSchoolProfileWhereInput>;
};


export type QueryNotificationProtocolsCountArgs = {
  where?: Maybe<NotificationProtocolWhereInput>;
};


export type QueryRegionalAdministratorProfilesCountArgs = {
  where?: Maybe<RegionalAdministratorProfileWhereInput>;
};


export type QueryRoivSpecialistProfilesCountArgs = {
  where?: Maybe<RoivSpecialistProfileWhereInput>;
};


export type QueryFcpsrSportingEventOrganizerProfilesCountArgs = {
  where?: Maybe<FcpsrSportingEventOrganizerProfileWhereInput>;
};


export type QueryDirSportingEventRegulationStatusValuesCountArgs = {
  where?: Maybe<DirSportingEventRegulationStatusValueWhereInput>;
};


export type QueryDirSportingEventRegulationStatusesCountArgs = {
  where?: Maybe<DirSportingEventRegulationStatusWhereInput>;
};


export type QueryDirRefusedRegionsCountArgs = {
  where?: Maybe<DirRefusedRegionWhereInput>;
};


export type QueryDirReturnedRegionsCountArgs = {
  where?: Maybe<DirReturnedRegionWhereInput>;
};


export type QueryDirSportEventRegulationsesCountArgs = {
  where?: Maybe<DirSportEventRegulationsWhereInput>;
};


export type QueryGskRefereeProfilesCountArgs = {
  where?: Maybe<GskRefereeProfileWhereInput>;
};


export type QueryUserSupportRequestsCountArgs = {
  where?: Maybe<UserSupportRequestWhereInput>;
};


export type QueryUserSupportRequestTypesCountArgs = {
  where?: Maybe<UserSupportRequestTypeWhereInput>;
};


export type QueryFieldHistoriesCountArgs = {
  where?: Maybe<FieldHistoryWhereInput>;
};


export type QueryArchivesCountArgs = {
  where?: Maybe<ArchiveWhereInput>;
};


export type QueryClassifiersCountArgs = {
  where?: Maybe<ClassifierWhereInput>;
};


export type QueryClassifierValuesCountArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
};


export type QueryDirRegionsCountArgs = {
  where?: Maybe<DirRegionWhereInput>;
};


export type QueryDirFederalDistrictsCountArgs = {
  where?: Maybe<DirFederalDistrictWhereInput>;
};


export type QueryDirForeignCitiesCountArgs = {
  where?: Maybe<DirForeignCityWhereInput>;
};


export type QueryDirCountriesCountArgs = {
  where?: Maybe<DirCountryWhereInput>;
};


export type QueryDirOrganizationsCountArgs = {
  where?: Maybe<DirOrganizationWhereInput>;
};


export type QueryDirOrganizationSportsCountArgs = {
  where?: Maybe<DirOrganizationSportWhereInput>;
};


export type QueryDirOrganizationAddressesCountArgs = {
  where?: Maybe<DirOrganizationAddressWhereInput>;
};


export type QueryDirOrganizationPhonesCountArgs = {
  where?: Maybe<DirOrganizationPhoneWhereInput>;
};


export type QueryDirOrganizationEmailsCountArgs = {
  where?: Maybe<DirOrganizationEmailWhereInput>;
};


export type QueryDirPersonsCountArgs = {
  where?: Maybe<DirPersonWhereInput>;
};


export type QueryDirPersonPhonesCountArgs = {
  where?: Maybe<DirPersonPhoneWhereInput>;
};


export type QueryDirPersonConfirmStatusesCountArgs = {
  where?: Maybe<DirPersonConfirmStatusWhereInput>;
};


export type QueryDirPersonAdditionalRegionsCountArgs = {
  where?: Maybe<DirPersonAdditionalRegionWhereInput>;
};


export type QueryDirPersonMergeTasksCountArgs = {
  where?: Maybe<DirPersonMergeTaskWhereInput>;
};


export type QueryDirPersonAddressesCountArgs = {
  where?: Maybe<DirPersonAddressWhereInput>;
};


export type QueryDirSportsCountArgs = {
  where?: Maybe<DirSportWhereInput>;
};


export type QueryDirSportDisciplinesCountArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
};


export type QueryDirSportDisciplineGroupsCountArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
};


export type QueryDirSportAgeGroupsCountArgs = {
  where?: Maybe<DirSportAgeGroupWhereInput>;
};


export type QueryDirSportAgeGroupParticipationRequirementsesCountArgs = {
  where?: Maybe<DirSportAgeGroupParticipationRequirementsWhereInput>;
};


export type QueryDirSportTrainingStagesCountArgs = {
  where?: Maybe<DirSportTrainingStageWhereInput>;
};


export type QueryDirSportBaseRegionsCountArgs = {
  where?: Maybe<DirSportBaseRegionWhereInput>;
};


export type QueryDirAthletesCountArgs = {
  where?: Maybe<DirAthleteWhereInput>;
};


export type QueryDirAthleteSportsCountArgs = {
  where?: Maybe<DirAthleteSportWhereInput>;
};


export type QueryDirAthleteSportOrganizationsCountArgs = {
  where?: Maybe<DirAthleteSportOrganizationWhereInput>;
};


export type QueryDirAthleteSportRankAndAwardsCountArgs = {
  where?: Maybe<DirAthleteSportRankAndAwardWhereInput>;
};


export type QueryDirAthleteMedCertsCountArgs = {
  where?: Maybe<DirAthleteMedCertWhereInput>;
};


export type QueryDirAthleteMedCertSportsCountArgs = {
  where?: Maybe<DirAthleteMedCertSportWhereInput>;
};


export type QueryDirAthleteCompetitionResultsCountArgs = {
  where?: Maybe<DirAthleteCompetitionResultWhereInput>;
};


export type QueryDirTrainersCountArgs = {
  where?: Maybe<DirTrainerWhereInput>;
};


export type QueryDirTrainerSportsCountArgs = {
  where?: Maybe<DirTrainerSportWhereInput>;
};


export type QueryDirTrainerSportOrganizationsCountArgs = {
  where?: Maybe<DirTrainerSportOrganizationWhereInput>;
};


export type QueryDirTrainerSportCategoriesCountArgs = {
  where?: Maybe<DirTrainerSportCategoryWhereInput>;
};


export type QueryDirRefereesCountArgs = {
  where?: Maybe<DirRefereeWhereInput>;
};


export type QueryDirRefereeSportsCountArgs = {
  where?: Maybe<DirRefereeSportWhereInput>;
};


export type QueryDirRefereeSportCategoriesCountArgs = {
  where?: Maybe<DirRefereeSportCategoryWhereInput>;
};


export type QueryDirSportingEventsCountArgs = {
  where?: Maybe<DirSportingEventWhereInput>;
};


export type QueryQuantyRequestStageTwoesCountArgs = {
  where?: Maybe<QuantyRequestStageTwoWhereInput>;
};


export type QueryDirSportingEventAthleteCallNotificationsCountArgs = {
  where?: Maybe<DirSportingEventAthleteCallNotificationWhereInput>;
};


export type QueryDirSportingEventAthleteCallNotificationTeamsCountArgs = {
  where?: Maybe<DirSportingEventAthleteCallNotificationTeamWhereInput>;
};


export type QueryDirSportingEventCountriesCountArgs = {
  where?: Maybe<DirSportingEventCountryWhereInput>;
};


export type QueryDirSportingEventSportsCountArgs = {
  where?: Maybe<DirSportingEventSportWhereInput>;
};


export type QueryDirSportingEventAgeGroupsCountArgs = {
  where?: Maybe<DirSportingEventAgeGroupWhereInput>;
};


export type QueryDirSportingEventStatusesCountArgs = {
  where?: Maybe<DirSportingEventStatusWhereInput>;
};


export type QueryDirSportingEventStatusHistoriesCountArgs = {
  where?: Maybe<DirSportingEventStatusHistoryWhereInput>;
};


export type QueryDirSportingEventExtendedStatusesCountArgs = {
  where?: Maybe<DirSportingEventExtendedStatusWhereInput>;
};


export type QueryDirSportingEventExtendedStatusValuesCountArgs = {
  where?: Maybe<DirSportingEventExtendedStatusValueWhereInput>;
};


export type QueryDirSportingEventProgramTypesCountArgs = {
  where?: Maybe<DirSportingEventProgramTypeWhereInput>;
};


export type QueryDirGenderAndAgeGroupsesCountArgs = {
  where?: Maybe<DirGenderAndAgeGroupsWhereInput>;
};


export type QueryDirSportingEventRefereesCountArgs = {
  where?: Maybe<DirSportingEventRefereeWhereInput>;
};


export type QueryDirSportingEventRefereeStatusesCountArgs = {
  where?: Maybe<DirSportingEventRefereeStatusWhereInput>;
};


export type QueryDirSportingEventRefereeStatusValuesCountArgs = {
  where?: Maybe<DirSportingEventRefereeStatusValueWhereInput>;
};


export type QueryDirSportingEventCompetitionCalendarsCountArgs = {
  where?: Maybe<DirSportingEventCompetitionCalendarWhereInput>;
};


export type QueryDirSportingEventCostsCountArgs = {
  where?: Maybe<DirSportingEventCostWhereInput>;
};


export type QueryDirSportingEventQuotasCountArgs = {
  where?: Maybe<DirSportingEventQuotaWhereInput>;
};


export type QueryDirSportingEventQuantitiesCountArgs = {
  where?: Maybe<DirSportingEventQuantityWhereInput>;
};


export type QueryDirSportingEventPointDistributionsCountArgs = {
  where?: Maybe<DirSportingEventPointDistributionWhereInput>;
};


export type QueryDirSportingEventPointDistributionPlacesCountArgs = {
  where?: Maybe<DirSportingEventPointDistributionPlaceWhereInput>;
};


export type QueryDirSportingEventRequestsCountArgs = {
  where?: Maybe<DirSportingEventRequestWhereInput>;
};


export type QueryDirSportingEventRequestTypesCountArgs = {
  where?: Maybe<DirSportingEventRequestTypeWhereInput>;
};


export type QueryDirSportingEventRequestStatusesCountArgs = {
  where?: Maybe<DirSportingEventRequestStatusWhereInput>;
};


export type QueryDirSportingEventRequestStatusValuesCountArgs = {
  where?: Maybe<DirSportingEventRequestStatusValueWhereInput>;
};


export type QueryDirNationalTeamsCountArgs = {
  where?: Maybe<DirNationalTeamWhereInput>;
};


export type QueryDirNationalTeamEmailRecipientsCountArgs = {
  where?: Maybe<DirNationalTeamEmailRecipientWhereInput>;
};


export type QueryDirNationalTeamStatusesCountArgs = {
  where?: Maybe<DirNationalTeamStatusWhereInput>;
};


export type QueryDirNationalTeamStatusHistoriesCountArgs = {
  where?: Maybe<DirNationalTeamStatusHistoryWhereInput>;
};


export type QueryDirNationalTeamExtendedStatusesCountArgs = {
  where?: Maybe<DirNationalTeamExtendedStatusWhereInput>;
};


export type QueryDirNationalTeamTrainersCountArgs = {
  where?: Maybe<DirNationalTeamTrainerWhereInput>;
};


export type QueryDirNationalTeamAthleteGroupsCountArgs = {
  where?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
};


export type QueryDirNationalTeamAthletesCountArgs = {
  where?: Maybe<DirNationalTeamAthleteWhereInput>;
};


export type QueryDirNationalTeamMembersCountArgs = {
  where?: Maybe<DirNationalTeamMemberWhereInput>;
};


export type QueryDirCalendarsCountArgs = {
  where?: Maybe<DirCalendarWhereInput>;
};


export type QueryQuotaForStageTwoBySportsCountArgs = {
  where?: Maybe<QuotaForStageTwoBySportWhereInput>;
};


export type QueryReqPartFromStageTwoesCountArgs = {
  where?: Maybe<ReqPartFromStageTwoWhereInput>;
};


export type QueryVenueCompetitionsCountArgs = {
  where?: Maybe<VenueCompetitionWhereInput>;
};


export type QueryVenueCompApplicationsCountArgs = {
  where?: Maybe<VenueCompApplicationWhereInput>;
};


export type QueryDirCalendarTypesCountArgs = {
  where?: Maybe<DirCalendarTypeWhereInput>;
};


export type QueryDirCalendarSignedReportsCountArgs = {
  where?: Maybe<DirCalendarSignedReportWhereInput>;
};


export type QueryDirDocumentsCountArgs = {
  where?: Maybe<DirDocumentWhereInput>;
};


export type QueryAddressTypesCountArgs = {
  where?: Maybe<AddressTypeWhereInput>;
};


export type QueryTaskStatusesCountArgs = {
  where?: Maybe<TaskStatusWhereInput>;
};


export type QueryFilesCountArgs = {
  where?: Maybe<FileWhereInput>;
};


export type QueryUsersSimilarArgs = {
  search: Scalars['String'];
  threshold?: Maybe<Scalars['Float']>;
  where?: Maybe<UserWhereInput>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryDirPersonsSimilarArgs = {
  search: Scalars['String'];
  threshold?: Maybe<Scalars['Float']>;
  where?: Maybe<DirPersonWhereInput>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryDirOrganizationsSimilarArgs = {
  search: Scalars['String'];
  threshold?: Maybe<Scalars['Float']>;
  where?: Maybe<DirOrganizationWhereInput>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryDirDocumentsSimilarArgs = {
  search: Scalars['String'];
  threshold?: Maybe<Scalars['Float']>;
  where?: Maybe<DirDocumentWhereInput>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryDirSportingEventsSimilarArgs = {
  search: Scalars['String'];
  threshold?: Maybe<Scalars['Float']>;
  where?: Maybe<DirSportingEventWhereInput>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QuerySuggestOrganizationsArgs = {
  by: Scalars['String'];
};


export type QuerySuggestAddressesArgs = {
  by: Scalars['String'];
};


export type QueryPublicAthleteMedCertArgs = {
  id: Scalars['UUID'];
};


export type QueryDirSportingEventProxyArgs = {
  event: DirSportingEventWhereUniqueInput;
  status?: Maybe<DirSportingEventStatusWhereUniqueInput>;
};


export type QueryAggregateParticipantsNumberByEventArgs = {
  where: DirSportingEventWhereUniqueInput;
};


export type QueryAggregateParticipantsNumberByParentEventArgs = {
  where: DirSportingEventWhereUniqueInput;
};


export type QueryAggregateParticipantsNumberByCalendarArgs = {
  where: DirCalendarWhereUniqueInput;
};


export type QueryAggregateParticipantsAgeByCalendarArgs = {
  where: DirCalendarWhereUniqueInput;
};


export type QueryAggregateParticipantsApplicationByTeamEventsArgs = {
  where: DirNationalTeamWhereUniqueInput;
};


export type QueryAggregateParticipantsRankAndAwardByCalendarArgs = {
  where: DirCalendarWhereUniqueInput;
};


export type QueryAggregateEventRefereesCategoryByCalendarArgs = {
  where: DirCalendarWhereUniqueInput;
};


export type QueryAggregateEventAthletesByPointsArgs = {
  where: DirSportingEventWhereUniqueInput;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: UserRole;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUser>;
  system?: Maybe<SystemUser>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPerson>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfile>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfile>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfile>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfile>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfile>;
  gskRefereeProfile?: Maybe<GskRefereeProfile>;
  userSupportRequests?: Maybe<Array<UserSupportRequest>>;
  operatorSupportRequests?: Maybe<Array<UserSupportRequest>>;
  /** Токены пользователя */
  tokens: Array<Scalars['String']>;
};


export type UserUserSupportRequestsArgs = {
  where?: Maybe<UserSupportRequestWhereInput>;
  orderBy?: Maybe<UserSupportRequestOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserOperatorSupportRequestsArgs = {
  where?: Maybe<UserSupportRequestWhereInput>;
  orderBy?: Maybe<UserSupportRequestOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserRole = {
  __typename?: 'UserRole';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  description: Scalars['String'];
  canSelect?: Maybe<Scalars['Boolean']>;
};

export type RegularUser = {
  __typename?: 'RegularUser';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  dirOrganization?: Maybe<DirOrganization>;
};

export type DirOrganization = {
  __typename?: 'DirOrganization';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganization>;
  children?: Maybe<Array<DirOrganization>>;
  dirRegion?: Maybe<DirRegion>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<Array<ClassifierValue>>;
  clsDepartmentalAffiliations?: Maybe<Array<ClassifierValue>>;
  isLegalEntity: Scalars['Boolean'];
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<Array<DirSport>>;
  sports?: Maybe<Array<DirOrganizationSport>>;
  addresses?: Maybe<Array<DirOrganizationAddress>>;
  phones?: Maybe<Array<DirOrganizationPhone>>;
  emails?: Maybe<Array<DirOrganizationEmail>>;
  websites: Array<Scalars['String']>;
  isUniversity: Scalars['Boolean'];
};


export type DirOrganizationChildrenArgs = {
  where?: Maybe<DirOrganizationWhereInput>;
  orderBy?: Maybe<DirOrganizationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirOrganizationClsOrganizationCategoriesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirOrganizationClsDepartmentalAffiliationsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirOrganizationDirSportsArgs = {
  where?: Maybe<DirSportWhereInput>;
  orderBy?: Maybe<DirSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirOrganizationSportsArgs = {
  where?: Maybe<DirOrganizationSportWhereInput>;
  orderBy?: Maybe<DirOrganizationSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirOrganizationAddressesArgs = {
  where?: Maybe<DirOrganizationAddressWhereInput>;
  orderBy?: Maybe<DirOrganizationAddressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirOrganizationPhonesArgs = {
  where?: Maybe<DirOrganizationPhoneWhereInput>;
  orderBy?: Maybe<DirOrganizationPhoneOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirOrganizationEmailsArgs = {
  where?: Maybe<DirOrganizationEmailWhereInput>;
  orderBy?: Maybe<DirOrganizationEmailOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type Archive = {
  __typename?: 'Archive';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  reason: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type DirOrganizationWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationWhereInput>;
  children_every?: Maybe<DirOrganizationWhereInput>;
  children_some?: Maybe<DirOrganizationWhereInput>;
  children_none?: Maybe<DirOrganizationWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  clsOrganizationCategories_every?: Maybe<ClassifierValueWhereInput>;
  clsOrganizationCategories_some?: Maybe<ClassifierValueWhereInput>;
  clsOrganizationCategories_none?: Maybe<ClassifierValueWhereInput>;
  clsDepartmentalAffiliations_every?: Maybe<ClassifierValueWhereInput>;
  clsDepartmentalAffiliations_some?: Maybe<ClassifierValueWhereInput>;
  clsDepartmentalAffiliations_none?: Maybe<ClassifierValueWhereInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  isLegalEntity_not?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  ogrn_not?: Maybe<Scalars['String']>;
  ogrn_in?: Maybe<Array<Scalars['String']>>;
  ogrn_not_in?: Maybe<Array<Scalars['String']>>;
  ogrn_lt?: Maybe<Scalars['String']>;
  ogrn_lte?: Maybe<Scalars['String']>;
  ogrn_gt?: Maybe<Scalars['String']>;
  ogrn_gte?: Maybe<Scalars['String']>;
  ogrn_contains?: Maybe<Scalars['String']>;
  ogrn_not_contains?: Maybe<Scalars['String']>;
  ogrn_starts_with?: Maybe<Scalars['String']>;
  ogrn_not_starts_with?: Maybe<Scalars['String']>;
  ogrn_ends_with?: Maybe<Scalars['String']>;
  ogrn_not_ends_with?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  registrationDate_not?: Maybe<Scalars['DateTime']>;
  registrationDate_in?: Maybe<Array<Scalars['DateTime']>>;
  registrationDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  registrationDate_lt?: Maybe<Scalars['DateTime']>;
  registrationDate_lte?: Maybe<Scalars['DateTime']>;
  registrationDate_gt?: Maybe<Scalars['DateTime']>;
  registrationDate_gte?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  inn_not?: Maybe<Scalars['String']>;
  inn_in?: Maybe<Array<Scalars['String']>>;
  inn_not_in?: Maybe<Array<Scalars['String']>>;
  inn_lt?: Maybe<Scalars['String']>;
  inn_lte?: Maybe<Scalars['String']>;
  inn_gt?: Maybe<Scalars['String']>;
  inn_gte?: Maybe<Scalars['String']>;
  inn_contains?: Maybe<Scalars['String']>;
  inn_not_contains?: Maybe<Scalars['String']>;
  inn_starts_with?: Maybe<Scalars['String']>;
  inn_not_starts_with?: Maybe<Scalars['String']>;
  inn_ends_with?: Maybe<Scalars['String']>;
  inn_not_ends_with?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  kpp_not?: Maybe<Scalars['String']>;
  kpp_in?: Maybe<Array<Scalars['String']>>;
  kpp_not_in?: Maybe<Array<Scalars['String']>>;
  kpp_lt?: Maybe<Scalars['String']>;
  kpp_lte?: Maybe<Scalars['String']>;
  kpp_gt?: Maybe<Scalars['String']>;
  kpp_gte?: Maybe<Scalars['String']>;
  kpp_contains?: Maybe<Scalars['String']>;
  kpp_not_contains?: Maybe<Scalars['String']>;
  kpp_starts_with?: Maybe<Scalars['String']>;
  kpp_not_starts_with?: Maybe<Scalars['String']>;
  kpp_ends_with?: Maybe<Scalars['String']>;
  kpp_not_ends_with?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  legalAddress_not?: Maybe<Scalars['String']>;
  legalAddress_in?: Maybe<Array<Scalars['String']>>;
  legalAddress_not_in?: Maybe<Array<Scalars['String']>>;
  legalAddress_lt?: Maybe<Scalars['String']>;
  legalAddress_lte?: Maybe<Scalars['String']>;
  legalAddress_gt?: Maybe<Scalars['String']>;
  legalAddress_gte?: Maybe<Scalars['String']>;
  legalAddress_contains?: Maybe<Scalars['String']>;
  legalAddress_not_contains?: Maybe<Scalars['String']>;
  legalAddress_starts_with?: Maybe<Scalars['String']>;
  legalAddress_not_starts_with?: Maybe<Scalars['String']>;
  legalAddress_ends_with?: Maybe<Scalars['String']>;
  legalAddress_not_ends_with?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  postcode_not?: Maybe<Scalars['String']>;
  postcode_in?: Maybe<Array<Scalars['String']>>;
  postcode_not_in?: Maybe<Array<Scalars['String']>>;
  postcode_lt?: Maybe<Scalars['String']>;
  postcode_lte?: Maybe<Scalars['String']>;
  postcode_gt?: Maybe<Scalars['String']>;
  postcode_gte?: Maybe<Scalars['String']>;
  postcode_contains?: Maybe<Scalars['String']>;
  postcode_not_contains?: Maybe<Scalars['String']>;
  postcode_starts_with?: Maybe<Scalars['String']>;
  postcode_not_starts_with?: Maybe<Scalars['String']>;
  postcode_ends_with?: Maybe<Scalars['String']>;
  postcode_not_ends_with?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headPosition_not?: Maybe<Scalars['String']>;
  headPosition_in?: Maybe<Array<Scalars['String']>>;
  headPosition_not_in?: Maybe<Array<Scalars['String']>>;
  headPosition_lt?: Maybe<Scalars['String']>;
  headPosition_lte?: Maybe<Scalars['String']>;
  headPosition_gt?: Maybe<Scalars['String']>;
  headPosition_gte?: Maybe<Scalars['String']>;
  headPosition_contains?: Maybe<Scalars['String']>;
  headPosition_not_contains?: Maybe<Scalars['String']>;
  headPosition_starts_with?: Maybe<Scalars['String']>;
  headPosition_not_starts_with?: Maybe<Scalars['String']>;
  headPosition_ends_with?: Maybe<Scalars['String']>;
  headPosition_not_ends_with?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  headFullName_not?: Maybe<Scalars['String']>;
  headFullName_in?: Maybe<Array<Scalars['String']>>;
  headFullName_not_in?: Maybe<Array<Scalars['String']>>;
  headFullName_lt?: Maybe<Scalars['String']>;
  headFullName_lte?: Maybe<Scalars['String']>;
  headFullName_gt?: Maybe<Scalars['String']>;
  headFullName_gte?: Maybe<Scalars['String']>;
  headFullName_contains?: Maybe<Scalars['String']>;
  headFullName_not_contains?: Maybe<Scalars['String']>;
  headFullName_starts_with?: Maybe<Scalars['String']>;
  headFullName_not_starts_with?: Maybe<Scalars['String']>;
  headFullName_ends_with?: Maybe<Scalars['String']>;
  headFullName_not_ends_with?: Maybe<Scalars['String']>;
  dirSports_every?: Maybe<DirSportWhereInput>;
  dirSports_some?: Maybe<DirSportWhereInput>;
  dirSports_none?: Maybe<DirSportWhereInput>;
  sports_every?: Maybe<DirOrganizationSportWhereInput>;
  sports_some?: Maybe<DirOrganizationSportWhereInput>;
  sports_none?: Maybe<DirOrganizationSportWhereInput>;
  addresses_every?: Maybe<DirOrganizationAddressWhereInput>;
  addresses_some?: Maybe<DirOrganizationAddressWhereInput>;
  addresses_none?: Maybe<DirOrganizationAddressWhereInput>;
  phones_every?: Maybe<DirOrganizationPhoneWhereInput>;
  phones_some?: Maybe<DirOrganizationPhoneWhereInput>;
  phones_none?: Maybe<DirOrganizationPhoneWhereInput>;
  emails_every?: Maybe<DirOrganizationEmailWhereInput>;
  emails_some?: Maybe<DirOrganizationEmailWhereInput>;
  emails_none?: Maybe<DirOrganizationEmailWhereInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
  isUniversity_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirOrganizationWhereInput>>;
  OR?: Maybe<Array<DirOrganizationWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationWhereInput>>;
};

export type UserWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  password_not?: Maybe<Scalars['String']>;
  password_in?: Maybe<Array<Scalars['String']>>;
  password_not_in?: Maybe<Array<Scalars['String']>>;
  password_lt?: Maybe<Scalars['String']>;
  password_lte?: Maybe<Scalars['String']>;
  password_gt?: Maybe<Scalars['String']>;
  password_gte?: Maybe<Scalars['String']>;
  password_contains?: Maybe<Scalars['String']>;
  password_not_contains?: Maybe<Scalars['String']>;
  password_starts_with?: Maybe<Scalars['String']>;
  password_not_starts_with?: Maybe<Scalars['String']>;
  password_ends_with?: Maybe<Scalars['String']>;
  password_not_ends_with?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleWhereInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserWhereInput>;
  system?: Maybe<SystemUserWhereInput>;
  position?: Maybe<Scalars['String']>;
  position_not?: Maybe<Scalars['String']>;
  position_in?: Maybe<Array<Scalars['String']>>;
  position_not_in?: Maybe<Array<Scalars['String']>>;
  position_lt?: Maybe<Scalars['String']>;
  position_lte?: Maybe<Scalars['String']>;
  position_gt?: Maybe<Scalars['String']>;
  position_gte?: Maybe<Scalars['String']>;
  position_contains?: Maybe<Scalars['String']>;
  position_not_contains?: Maybe<Scalars['String']>;
  position_starts_with?: Maybe<Scalars['String']>;
  position_not_starts_with?: Maybe<Scalars['String']>;
  position_ends_with?: Maybe<Scalars['String']>;
  position_not_ends_with?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonWhereInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileWhereInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileWhereInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileWhereInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileWhereInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileWhereInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileWhereInput>;
  userSupportRequests_every?: Maybe<UserSupportRequestWhereInput>;
  userSupportRequests_some?: Maybe<UserSupportRequestWhereInput>;
  userSupportRequests_none?: Maybe<UserSupportRequestWhereInput>;
  operatorSupportRequests_every?: Maybe<UserSupportRequestWhereInput>;
  operatorSupportRequests_some?: Maybe<UserSupportRequestWhereInput>;
  operatorSupportRequests_none?: Maybe<UserSupportRequestWhereInput>;
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
};

export type UserRoleWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  canSelect?: Maybe<Scalars['Boolean']>;
  canSelect_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<UserRoleWhereInput>>;
  OR?: Maybe<Array<UserRoleWhereInput>>;
  NOT?: Maybe<Array<UserRoleWhereInput>>;
};

export type RegularUserWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  lastname?: Maybe<Scalars['String']>;
  lastname_not?: Maybe<Scalars['String']>;
  lastname_in?: Maybe<Array<Scalars['String']>>;
  lastname_not_in?: Maybe<Array<Scalars['String']>>;
  lastname_lt?: Maybe<Scalars['String']>;
  lastname_lte?: Maybe<Scalars['String']>;
  lastname_gt?: Maybe<Scalars['String']>;
  lastname_gte?: Maybe<Scalars['String']>;
  lastname_contains?: Maybe<Scalars['String']>;
  lastname_not_contains?: Maybe<Scalars['String']>;
  lastname_starts_with?: Maybe<Scalars['String']>;
  lastname_not_starts_with?: Maybe<Scalars['String']>;
  lastname_ends_with?: Maybe<Scalars['String']>;
  lastname_not_ends_with?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  firstname_not?: Maybe<Scalars['String']>;
  firstname_in?: Maybe<Array<Scalars['String']>>;
  firstname_not_in?: Maybe<Array<Scalars['String']>>;
  firstname_lt?: Maybe<Scalars['String']>;
  firstname_lte?: Maybe<Scalars['String']>;
  firstname_gt?: Maybe<Scalars['String']>;
  firstname_gte?: Maybe<Scalars['String']>;
  firstname_contains?: Maybe<Scalars['String']>;
  firstname_not_contains?: Maybe<Scalars['String']>;
  firstname_starts_with?: Maybe<Scalars['String']>;
  firstname_not_starts_with?: Maybe<Scalars['String']>;
  firstname_ends_with?: Maybe<Scalars['String']>;
  firstname_not_ends_with?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  patronymic_not?: Maybe<Scalars['String']>;
  patronymic_in?: Maybe<Array<Scalars['String']>>;
  patronymic_not_in?: Maybe<Array<Scalars['String']>>;
  patronymic_lt?: Maybe<Scalars['String']>;
  patronymic_lte?: Maybe<Scalars['String']>;
  patronymic_gt?: Maybe<Scalars['String']>;
  patronymic_gte?: Maybe<Scalars['String']>;
  patronymic_contains?: Maybe<Scalars['String']>;
  patronymic_not_contains?: Maybe<Scalars['String']>;
  patronymic_starts_with?: Maybe<Scalars['String']>;
  patronymic_not_starts_with?: Maybe<Scalars['String']>;
  patronymic_ends_with?: Maybe<Scalars['String']>;
  patronymic_not_ends_with?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  birthday_not?: Maybe<Scalars['DateTime']>;
  birthday_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_not_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_lt?: Maybe<Scalars['DateTime']>;
  birthday_lte?: Maybe<Scalars['DateTime']>;
  birthday_gt?: Maybe<Scalars['DateTime']>;
  birthday_gte?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  isMale_not?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Scalars['String']>>;
  phone_not_in?: Maybe<Array<Scalars['String']>>;
  phone_lt?: Maybe<Scalars['String']>;
  phone_lte?: Maybe<Scalars['String']>;
  phone_gt?: Maybe<Scalars['String']>;
  phone_gte?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  position_not?: Maybe<Scalars['String']>;
  position_in?: Maybe<Array<Scalars['String']>>;
  position_not_in?: Maybe<Array<Scalars['String']>>;
  position_lt?: Maybe<Scalars['String']>;
  position_lte?: Maybe<Scalars['String']>;
  position_gt?: Maybe<Scalars['String']>;
  position_gte?: Maybe<Scalars['String']>;
  position_contains?: Maybe<Scalars['String']>;
  position_not_contains?: Maybe<Scalars['String']>;
  position_starts_with?: Maybe<Scalars['String']>;
  position_not_starts_with?: Maybe<Scalars['String']>;
  position_ends_with?: Maybe<Scalars['String']>;
  position_not_ends_with?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Scalars['String']>>;
  address_not_in?: Maybe<Array<Scalars['String']>>;
  address_lt?: Maybe<Scalars['String']>;
  address_lte?: Maybe<Scalars['String']>;
  address_gt?: Maybe<Scalars['String']>;
  address_gte?: Maybe<Scalars['String']>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  AND?: Maybe<Array<RegularUserWhereInput>>;
  OR?: Maybe<Array<RegularUserWhereInput>>;
  NOT?: Maybe<Array<RegularUserWhereInput>>;
};

export type SystemUserWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  AND?: Maybe<Array<SystemUserWhereInput>>;
  OR?: Maybe<Array<SystemUserWhereInput>>;
  NOT?: Maybe<Array<SystemUserWhereInput>>;
};

export type DirPersonWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  fieldHistory_every?: Maybe<FieldHistoryWhereInput>;
  fieldHistory_some?: Maybe<FieldHistoryWhereInput>;
  fieldHistory_none?: Maybe<FieldHistoryWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  mergedAt_not?: Maybe<Scalars['DateTime']>;
  mergedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  mergedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  mergedAt_lt?: Maybe<Scalars['DateTime']>;
  mergedAt_lte?: Maybe<Scalars['DateTime']>;
  mergedAt_gt?: Maybe<Scalars['DateTime']>;
  mergedAt_gte?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonWhereInput>;
  duplicates_every?: Maybe<DirPersonWhereInput>;
  duplicates_some?: Maybe<DirPersonWhereInput>;
  duplicates_none?: Maybe<DirPersonWhereInput>;
  userProfile?: Maybe<UserWhereInput>;
  athleteProfile?: Maybe<DirAthleteWhereInput>;
  trainerProfile?: Maybe<DirTrainerWhereInput>;
  refereeProfile?: Maybe<DirRefereeWhereInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusWhereInput>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  lastname_not?: Maybe<Scalars['String']>;
  lastname_in?: Maybe<Array<Scalars['String']>>;
  lastname_not_in?: Maybe<Array<Scalars['String']>>;
  lastname_lt?: Maybe<Scalars['String']>;
  lastname_lte?: Maybe<Scalars['String']>;
  lastname_gt?: Maybe<Scalars['String']>;
  lastname_gte?: Maybe<Scalars['String']>;
  lastname_contains?: Maybe<Scalars['String']>;
  lastname_not_contains?: Maybe<Scalars['String']>;
  lastname_starts_with?: Maybe<Scalars['String']>;
  lastname_not_starts_with?: Maybe<Scalars['String']>;
  lastname_ends_with?: Maybe<Scalars['String']>;
  lastname_not_ends_with?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  firstname_not?: Maybe<Scalars['String']>;
  firstname_in?: Maybe<Array<Scalars['String']>>;
  firstname_not_in?: Maybe<Array<Scalars['String']>>;
  firstname_lt?: Maybe<Scalars['String']>;
  firstname_lte?: Maybe<Scalars['String']>;
  firstname_gt?: Maybe<Scalars['String']>;
  firstname_gte?: Maybe<Scalars['String']>;
  firstname_contains?: Maybe<Scalars['String']>;
  firstname_not_contains?: Maybe<Scalars['String']>;
  firstname_starts_with?: Maybe<Scalars['String']>;
  firstname_not_starts_with?: Maybe<Scalars['String']>;
  firstname_ends_with?: Maybe<Scalars['String']>;
  firstname_not_ends_with?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  patronymic_not?: Maybe<Scalars['String']>;
  patronymic_in?: Maybe<Array<Scalars['String']>>;
  patronymic_not_in?: Maybe<Array<Scalars['String']>>;
  patronymic_lt?: Maybe<Scalars['String']>;
  patronymic_lte?: Maybe<Scalars['String']>;
  patronymic_gt?: Maybe<Scalars['String']>;
  patronymic_gte?: Maybe<Scalars['String']>;
  patronymic_contains?: Maybe<Scalars['String']>;
  patronymic_not_contains?: Maybe<Scalars['String']>;
  patronymic_starts_with?: Maybe<Scalars['String']>;
  patronymic_not_starts_with?: Maybe<Scalars['String']>;
  patronymic_ends_with?: Maybe<Scalars['String']>;
  patronymic_not_ends_with?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  previousLastname_not?: Maybe<Scalars['String']>;
  previousLastname_in?: Maybe<Array<Scalars['String']>>;
  previousLastname_not_in?: Maybe<Array<Scalars['String']>>;
  previousLastname_lt?: Maybe<Scalars['String']>;
  previousLastname_lte?: Maybe<Scalars['String']>;
  previousLastname_gt?: Maybe<Scalars['String']>;
  previousLastname_gte?: Maybe<Scalars['String']>;
  previousLastname_contains?: Maybe<Scalars['String']>;
  previousLastname_not_contains?: Maybe<Scalars['String']>;
  previousLastname_starts_with?: Maybe<Scalars['String']>;
  previousLastname_not_starts_with?: Maybe<Scalars['String']>;
  previousLastname_ends_with?: Maybe<Scalars['String']>;
  previousLastname_not_ends_with?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  birthday_not?: Maybe<Scalars['DateTime']>;
  birthday_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_not_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_lt?: Maybe<Scalars['DateTime']>;
  birthday_lte?: Maybe<Scalars['DateTime']>;
  birthday_gt?: Maybe<Scalars['DateTime']>;
  birthday_gte?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  isMale_not?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  snils_not?: Maybe<Scalars['String']>;
  snils_in?: Maybe<Array<Scalars['String']>>;
  snils_not_in?: Maybe<Array<Scalars['String']>>;
  snils_lt?: Maybe<Scalars['String']>;
  snils_lte?: Maybe<Scalars['String']>;
  snils_gt?: Maybe<Scalars['String']>;
  snils_gte?: Maybe<Scalars['String']>;
  snils_contains?: Maybe<Scalars['String']>;
  snils_not_contains?: Maybe<Scalars['String']>;
  snils_starts_with?: Maybe<Scalars['String']>;
  snils_not_starts_with?: Maybe<Scalars['String']>;
  snils_ends_with?: Maybe<Scalars['String']>;
  snils_not_ends_with?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportSerial_not?: Maybe<Scalars['String']>;
  passportSerial_in?: Maybe<Array<Scalars['String']>>;
  passportSerial_not_in?: Maybe<Array<Scalars['String']>>;
  passportSerial_lt?: Maybe<Scalars['String']>;
  passportSerial_lte?: Maybe<Scalars['String']>;
  passportSerial_gt?: Maybe<Scalars['String']>;
  passportSerial_gte?: Maybe<Scalars['String']>;
  passportSerial_contains?: Maybe<Scalars['String']>;
  passportSerial_not_contains?: Maybe<Scalars['String']>;
  passportSerial_starts_with?: Maybe<Scalars['String']>;
  passportSerial_not_starts_with?: Maybe<Scalars['String']>;
  passportSerial_ends_with?: Maybe<Scalars['String']>;
  passportSerial_not_ends_with?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportNumber_not?: Maybe<Scalars['String']>;
  passportNumber_in?: Maybe<Array<Scalars['String']>>;
  passportNumber_not_in?: Maybe<Array<Scalars['String']>>;
  passportNumber_lt?: Maybe<Scalars['String']>;
  passportNumber_lte?: Maybe<Scalars['String']>;
  passportNumber_gt?: Maybe<Scalars['String']>;
  passportNumber_gte?: Maybe<Scalars['String']>;
  passportNumber_contains?: Maybe<Scalars['String']>;
  passportNumber_not_contains?: Maybe<Scalars['String']>;
  passportNumber_starts_with?: Maybe<Scalars['String']>;
  passportNumber_not_starts_with?: Maybe<Scalars['String']>;
  passportNumber_ends_with?: Maybe<Scalars['String']>;
  passportNumber_not_ends_with?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssuedBy_not?: Maybe<Scalars['String']>;
  passportIssuedBy_in?: Maybe<Array<Scalars['String']>>;
  passportIssuedBy_not_in?: Maybe<Array<Scalars['String']>>;
  passportIssuedBy_lt?: Maybe<Scalars['String']>;
  passportIssuedBy_lte?: Maybe<Scalars['String']>;
  passportIssuedBy_gt?: Maybe<Scalars['String']>;
  passportIssuedBy_gte?: Maybe<Scalars['String']>;
  passportIssuedBy_contains?: Maybe<Scalars['String']>;
  passportIssuedBy_not_contains?: Maybe<Scalars['String']>;
  passportIssuedBy_starts_with?: Maybe<Scalars['String']>;
  passportIssuedBy_not_starts_with?: Maybe<Scalars['String']>;
  passportIssuedBy_ends_with?: Maybe<Scalars['String']>;
  passportIssuedBy_not_ends_with?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  passportIssueDate_not?: Maybe<Scalars['DateTime']>;
  passportIssueDate_in?: Maybe<Array<Scalars['DateTime']>>;
  passportIssueDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  passportIssueDate_lt?: Maybe<Scalars['DateTime']>;
  passportIssueDate_lte?: Maybe<Scalars['DateTime']>;
  passportIssueDate_gt?: Maybe<Scalars['DateTime']>;
  passportIssueDate_gte?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  residenceCity_not?: Maybe<Scalars['String']>;
  residenceCity_in?: Maybe<Array<Scalars['String']>>;
  residenceCity_not_in?: Maybe<Array<Scalars['String']>>;
  residenceCity_lt?: Maybe<Scalars['String']>;
  residenceCity_lte?: Maybe<Scalars['String']>;
  residenceCity_gt?: Maybe<Scalars['String']>;
  residenceCity_gte?: Maybe<Scalars['String']>;
  residenceCity_contains?: Maybe<Scalars['String']>;
  residenceCity_not_contains?: Maybe<Scalars['String']>;
  residenceCity_starts_with?: Maybe<Scalars['String']>;
  residenceCity_not_starts_with?: Maybe<Scalars['String']>;
  residenceCity_ends_with?: Maybe<Scalars['String']>;
  residenceCity_not_ends_with?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueWhereInput>;
  dirCitizenships_every?: Maybe<DirCountryWhereInput>;
  dirCitizenships_some?: Maybe<DirCountryWhereInput>;
  dirCitizenships_none?: Maybe<DirCountryWhereInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  isRussia_not?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  dirForeignCity?: Maybe<DirForeignCityWhereInput>;
  dirCountry?: Maybe<DirCountryWhereInput>;
  phones_every?: Maybe<DirPersonPhoneWhereInput>;
  phones_some?: Maybe<DirPersonPhoneWhereInput>;
  phones_none?: Maybe<DirPersonPhoneWhereInput>;
  additionalRegions_every?: Maybe<DirPersonAdditionalRegionWhereInput>;
  additionalRegions_some?: Maybe<DirPersonAdditionalRegionWhereInput>;
  additionalRegions_none?: Maybe<DirPersonAdditionalRegionWhereInput>;
  mergeTasksAsPrimary_every?: Maybe<DirPersonMergeTaskWhereInput>;
  mergeTasksAsPrimary_some?: Maybe<DirPersonMergeTaskWhereInput>;
  mergeTasksAsPrimary_none?: Maybe<DirPersonMergeTaskWhereInput>;
  mergeTasksAsDuplicate_every?: Maybe<DirPersonMergeTaskWhereInput>;
  mergeTasksAsDuplicate_some?: Maybe<DirPersonMergeTaskWhereInput>;
  mergeTasksAsDuplicate_none?: Maybe<DirPersonMergeTaskWhereInput>;
  addresses_every?: Maybe<DirPersonAddressWhereInput>;
  addresses_some?: Maybe<DirPersonAddressWhereInput>;
  addresses_none?: Maybe<DirPersonAddressWhereInput>;
  nationalTeamEmailRecipients_every?: Maybe<DirNationalTeamEmailRecipientWhereInput>;
  nationalTeamEmailRecipients_some?: Maybe<DirNationalTeamEmailRecipientWhereInput>;
  nationalTeamEmailRecipients_none?: Maybe<DirNationalTeamEmailRecipientWhereInput>;
  doc1?: Maybe<FileWhereInput>;
  doc2?: Maybe<FileWhereInput>;
  doc3?: Maybe<FileWhereInput>;
  bankRequisites?: Maybe<FileWhereInput>;
  AND?: Maybe<Array<DirPersonWhereInput>>;
  OR?: Maybe<Array<DirPersonWhereInput>>;
  NOT?: Maybe<Array<DirPersonWhereInput>>;
};

export type FieldHistoryWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  field?: Maybe<Scalars['String']>;
  field_not?: Maybe<Scalars['String']>;
  field_in?: Maybe<Array<Scalars['String']>>;
  field_not_in?: Maybe<Array<Scalars['String']>>;
  field_lt?: Maybe<Scalars['String']>;
  field_lte?: Maybe<Scalars['String']>;
  field_gt?: Maybe<Scalars['String']>;
  field_gte?: Maybe<Scalars['String']>;
  field_contains?: Maybe<Scalars['String']>;
  field_not_contains?: Maybe<Scalars['String']>;
  field_starts_with?: Maybe<Scalars['String']>;
  field_not_starts_with?: Maybe<Scalars['String']>;
  field_ends_with?: Maybe<Scalars['String']>;
  field_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<FieldHistoryWhereInput>>;
  OR?: Maybe<Array<FieldHistoryWhereInput>>;
  NOT?: Maybe<Array<FieldHistoryWhereInput>>;
};

export type ArchiveWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  reason?: Maybe<Scalars['String']>;
  reason_not?: Maybe<Scalars['String']>;
  reason_in?: Maybe<Array<Scalars['String']>>;
  reason_not_in?: Maybe<Array<Scalars['String']>>;
  reason_lt?: Maybe<Scalars['String']>;
  reason_lte?: Maybe<Scalars['String']>;
  reason_gt?: Maybe<Scalars['String']>;
  reason_gte?: Maybe<Scalars['String']>;
  reason_contains?: Maybe<Scalars['String']>;
  reason_not_contains?: Maybe<Scalars['String']>;
  reason_starts_with?: Maybe<Scalars['String']>;
  reason_not_starts_with?: Maybe<Scalars['String']>;
  reason_ends_with?: Maybe<Scalars['String']>;
  reason_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ArchiveWhereInput>>;
  OR?: Maybe<Array<ArchiveWhereInput>>;
  NOT?: Maybe<Array<ArchiveWhereInput>>;
};

export type DirAthleteWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonWhereInput>;
  dirPersonAgent?: Maybe<DirPersonWhereInput>;
  clsPersonAgent?: Maybe<ClassifierValueWhereInput>;
  sports_every?: Maybe<DirAthleteSportWhereInput>;
  sports_some?: Maybe<DirAthleteSportWhereInput>;
  sports_none?: Maybe<DirAthleteSportWhereInput>;
  medCerts_every?: Maybe<DirAthleteMedCertWhereInput>;
  medCerts_some?: Maybe<DirAthleteMedCertWhereInput>;
  medCerts_none?: Maybe<DirAthleteMedCertWhereInput>;
  competetionResult_every?: Maybe<DirAthleteCompetitionResultWhereInput>;
  competetionResult_some?: Maybe<DirAthleteCompetitionResultWhereInput>;
  competetionResult_none?: Maybe<DirAthleteCompetitionResultWhereInput>;
  AND?: Maybe<Array<DirAthleteWhereInput>>;
  OR?: Maybe<Array<DirAthleteWhereInput>>;
  NOT?: Maybe<Array<DirAthleteWhereInput>>;
};

export type ClassifierValueWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  classifier?: Maybe<ClassifierWhereInput>;
  AND?: Maybe<Array<ClassifierValueWhereInput>>;
  OR?: Maybe<Array<ClassifierValueWhereInput>>;
  NOT?: Maybe<Array<ClassifierValueWhereInput>>;
};

export type ClassifierWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  values_every?: Maybe<ClassifierValueWhereInput>;
  values_some?: Maybe<ClassifierValueWhereInput>;
  values_none?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<ClassifierWhereInput>>;
  OR?: Maybe<Array<ClassifierWhereInput>>;
  NOT?: Maybe<Array<ClassifierWhereInput>>;
};

export type DirAthleteSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportWhereInput>;
  disciplines_every?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_some?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_none?: Maybe<DirSportDisciplineWhereInput>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  dirTrainers_every?: Maybe<DirTrainerWhereInput>;
  dirTrainers_some?: Maybe<DirTrainerWhereInput>;
  dirTrainers_none?: Maybe<DirTrainerWhereInput>;
  organizations_every?: Maybe<DirAthleteSportOrganizationWhereInput>;
  organizations_some?: Maybe<DirAthleteSportOrganizationWhereInput>;
  organizations_none?: Maybe<DirAthleteSportOrganizationWhereInput>;
  ranksAndAwards_every?: Maybe<DirAthleteSportRankAndAwardWhereInput>;
  ranksAndAwards_some?: Maybe<DirAthleteSportRankAndAwardWhereInput>;
  ranksAndAwards_none?: Maybe<DirAthleteSportRankAndAwardWhereInput>;
  role?: Maybe<Scalars['String']>;
  role_not?: Maybe<Scalars['String']>;
  role_in?: Maybe<Array<Scalars['String']>>;
  role_not_in?: Maybe<Array<Scalars['String']>>;
  role_lt?: Maybe<Scalars['String']>;
  role_lte?: Maybe<Scalars['String']>;
  role_gt?: Maybe<Scalars['String']>;
  role_gte?: Maybe<Scalars['String']>;
  role_contains?: Maybe<Scalars['String']>;
  role_not_contains?: Maybe<Scalars['String']>;
  role_starts_with?: Maybe<Scalars['String']>;
  role_not_starts_with?: Maybe<Scalars['String']>;
  role_ends_with?: Maybe<Scalars['String']>;
  role_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirAthleteSportWhereInput>>;
  OR?: Maybe<Array<DirAthleteSportWhereInput>>;
  NOT?: Maybe<Array<DirAthleteSportWhereInput>>;
};

export type DirSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  evsk_not?: Maybe<Scalars['String']>;
  evsk_in?: Maybe<Array<Scalars['String']>>;
  evsk_not_in?: Maybe<Array<Scalars['String']>>;
  evsk_lt?: Maybe<Scalars['String']>;
  evsk_lte?: Maybe<Scalars['String']>;
  evsk_gt?: Maybe<Scalars['String']>;
  evsk_gte?: Maybe<Scalars['String']>;
  evsk_contains?: Maybe<Scalars['String']>;
  evsk_not_contains?: Maybe<Scalars['String']>;
  evsk_starts_with?: Maybe<Scalars['String']>;
  evsk_not_starts_with?: Maybe<Scalars['String']>;
  evsk_ends_with?: Maybe<Scalars['String']>;
  evsk_not_ends_with?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  isDisabled_not?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups_every?: Maybe<ClassifierValueWhereInput>;
  clsSportAndDisciplineGroups_some?: Maybe<ClassifierValueWhereInput>;
  clsSportAndDisciplineGroups_none?: Maybe<ClassifierValueWhereInput>;
  disciplines_every?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_some?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_none?: Maybe<DirSportDisciplineWhereInput>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  ageGroups_every?: Maybe<DirSportAgeGroupWhereInput>;
  ageGroups_some?: Maybe<DirSportAgeGroupWhereInput>;
  ageGroups_none?: Maybe<DirSportAgeGroupWhereInput>;
  clsTrainingStages_every?: Maybe<ClassifierValueWhereInput>;
  clsTrainingStages_some?: Maybe<ClassifierValueWhereInput>;
  clsTrainingStages_none?: Maybe<ClassifierValueWhereInput>;
  clsRanksAndAwards_every?: Maybe<ClassifierValueWhereInput>;
  clsRanksAndAwards_some?: Maybe<ClassifierValueWhereInput>;
  clsRanksAndAwards_none?: Maybe<ClassifierValueWhereInput>;
  clsCategories_every?: Maybe<ClassifierValueWhereInput>;
  clsCategories_some?: Maybe<ClassifierValueWhereInput>;
  clsCategories_none?: Maybe<ClassifierValueWhereInput>;
  trainingStages_every?: Maybe<DirSportTrainingStageWhereInput>;
  trainingStages_some?: Maybe<DirSportTrainingStageWhereInput>;
  trainingStages_none?: Maybe<DirSportTrainingStageWhereInput>;
  baseRegions_every?: Maybe<DirSportBaseRegionWhereInput>;
  baseRegions_some?: Maybe<DirSportBaseRegionWhereInput>;
  baseRegions_none?: Maybe<DirSportBaseRegionWhereInput>;
  AND?: Maybe<Array<DirSportWhereInput>>;
  OR?: Maybe<Array<DirSportWhereInput>>;
  NOT?: Maybe<Array<DirSportWhereInput>>;
};

export type DirSportDisciplineWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_lt?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_contains?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  AND?: Maybe<Array<DirSportDisciplineWhereInput>>;
  OR?: Maybe<Array<DirSportDisciplineWhereInput>>;
  NOT?: Maybe<Array<DirSportDisciplineWhereInput>>;
};

export type DirSportDisciplineGroupWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  disciplines_every?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_some?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_none?: Maybe<DirSportDisciplineWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  AND?: Maybe<Array<DirSportDisciplineGroupWhereInput>>;
  OR?: Maybe<Array<DirSportDisciplineGroupWhereInput>>;
  NOT?: Maybe<Array<DirSportDisciplineGroupWhereInput>>;
};

export type DirSportAgeGroupWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  clsAgeGroup?: Maybe<ClassifierValueWhereInput>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportWhereInput>;
  participationRequirements_every?: Maybe<DirSportAgeGroupParticipationRequirementsWhereInput>;
  participationRequirements_some?: Maybe<DirSportAgeGroupParticipationRequirementsWhereInput>;
  participationRequirements_none?: Maybe<DirSportAgeGroupParticipationRequirementsWhereInput>;
  AND?: Maybe<Array<DirSportAgeGroupWhereInput>>;
  OR?: Maybe<Array<DirSportAgeGroupWhereInput>>;
  NOT?: Maybe<Array<DirSportAgeGroupWhereInput>>;
};

export type DirSportAgeGroupParticipationRequirementsWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  ageGroup?: Maybe<DirSportAgeGroupWhereInput>;
  clsCalendarCategory?: Maybe<ClassifierValueWhereInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<DirSportAgeGroupParticipationRequirementsWhereInput>>;
  OR?: Maybe<Array<DirSportAgeGroupParticipationRequirementsWhereInput>>;
  NOT?: Maybe<Array<DirSportAgeGroupParticipationRequirementsWhereInput>>;
};

export type DirSportTrainingStageWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportWhereInput>;
  clsTrainingStage?: Maybe<ClassifierValueWhereInput>;
  clsRanksAndAwards_every?: Maybe<ClassifierValueWhereInput>;
  clsRanksAndAwards_some?: Maybe<ClassifierValueWhereInput>;
  clsRanksAndAwards_none?: Maybe<ClassifierValueWhereInput>;
  minManAge?: Maybe<Scalars['Int']>;
  minManAge_not?: Maybe<Scalars['Int']>;
  minManAge_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_lt?: Maybe<Scalars['Int']>;
  minManAge_lte?: Maybe<Scalars['Int']>;
  minManAge_gt?: Maybe<Scalars['Int']>;
  minManAge_gte?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  maxManAge_not?: Maybe<Scalars['Int']>;
  maxManAge_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_lt?: Maybe<Scalars['Int']>;
  maxManAge_lte?: Maybe<Scalars['Int']>;
  maxManAge_gt?: Maybe<Scalars['Int']>;
  maxManAge_gte?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  minWomanAge_not?: Maybe<Scalars['Int']>;
  minWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_lt?: Maybe<Scalars['Int']>;
  minWomanAge_lte?: Maybe<Scalars['Int']>;
  minWomanAge_gt?: Maybe<Scalars['Int']>;
  minWomanAge_gte?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge_not?: Maybe<Scalars['Int']>;
  maxWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_lt?: Maybe<Scalars['Int']>;
  maxWomanAge_lte?: Maybe<Scalars['Int']>;
  maxWomanAge_gt?: Maybe<Scalars['Int']>;
  maxWomanAge_gte?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  minGroupSize_not?: Maybe<Scalars['Int']>;
  minGroupSize_in?: Maybe<Array<Scalars['Int']>>;
  minGroupSize_not_in?: Maybe<Array<Scalars['Int']>>;
  minGroupSize_lt?: Maybe<Scalars['Int']>;
  minGroupSize_lte?: Maybe<Scalars['Int']>;
  minGroupSize_gt?: Maybe<Scalars['Int']>;
  minGroupSize_gte?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize_not?: Maybe<Scalars['Int']>;
  maxGroupSize_in?: Maybe<Array<Scalars['Int']>>;
  maxGroupSize_not_in?: Maybe<Array<Scalars['Int']>>;
  maxGroupSize_lt?: Maybe<Scalars['Int']>;
  maxGroupSize_lte?: Maybe<Scalars['Int']>;
  maxGroupSize_gt?: Maybe<Scalars['Int']>;
  maxGroupSize_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportTrainingStageWhereInput>>;
  OR?: Maybe<Array<DirSportTrainingStageWhereInput>>;
  NOT?: Maybe<Array<DirSportTrainingStageWhereInput>>;
};

export type DirSportBaseRegionWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  sport?: Maybe<DirSportWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_not?: Maybe<Scalars['DateTime']>;
  startDate_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_lt?: Maybe<Scalars['DateTime']>;
  startDate_lte?: Maybe<Scalars['DateTime']>;
  startDate_gt?: Maybe<Scalars['DateTime']>;
  startDate_gte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_not?: Maybe<Scalars['DateTime']>;
  endDate_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_lt?: Maybe<Scalars['DateTime']>;
  endDate_lte?: Maybe<Scalars['DateTime']>;
  endDate_gt?: Maybe<Scalars['DateTime']>;
  endDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirSportBaseRegionWhereInput>>;
  OR?: Maybe<Array<DirSportBaseRegionWhereInput>>;
  NOT?: Maybe<Array<DirSportBaseRegionWhereInput>>;
};

export type DirRegionWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_lt?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_contains?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  dirFederalDistrict?: Maybe<DirFederalDistrictWhereInput>;
  roivOfficialEmail?: Maybe<Scalars['String']>;
  roivOfficialEmail_not?: Maybe<Scalars['String']>;
  roivOfficialEmail_in?: Maybe<Array<Scalars['String']>>;
  roivOfficialEmail_not_in?: Maybe<Array<Scalars['String']>>;
  roivOfficialEmail_lt?: Maybe<Scalars['String']>;
  roivOfficialEmail_lte?: Maybe<Scalars['String']>;
  roivOfficialEmail_gt?: Maybe<Scalars['String']>;
  roivOfficialEmail_gte?: Maybe<Scalars['String']>;
  roivOfficialEmail_contains?: Maybe<Scalars['String']>;
  roivOfficialEmail_not_contains?: Maybe<Scalars['String']>;
  roivOfficialEmail_starts_with?: Maybe<Scalars['String']>;
  roivOfficialEmail_not_starts_with?: Maybe<Scalars['String']>;
  roivOfficialEmail_ends_with?: Maybe<Scalars['String']>;
  roivOfficialEmail_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirRegionWhereInput>>;
  OR?: Maybe<Array<DirRegionWhereInput>>;
  NOT?: Maybe<Array<DirRegionWhereInput>>;
};

export type DirFederalDistrictWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  dirRegions_every?: Maybe<DirRegionWhereInput>;
  dirRegions_some?: Maybe<DirRegionWhereInput>;
  dirRegions_none?: Maybe<DirRegionWhereInput>;
  AND?: Maybe<Array<DirFederalDistrictWhereInput>>;
  OR?: Maybe<Array<DirFederalDistrictWhereInput>>;
  NOT?: Maybe<Array<DirFederalDistrictWhereInput>>;
};

export type DirTrainerWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonWhereInput>;
  sports_every?: Maybe<DirTrainerSportWhereInput>;
  sports_some?: Maybe<DirTrainerSportWhereInput>;
  sports_none?: Maybe<DirTrainerSportWhereInput>;
  AND?: Maybe<Array<DirTrainerWhereInput>>;
  OR?: Maybe<Array<DirTrainerWhereInput>>;
  NOT?: Maybe<Array<DirTrainerWhereInput>>;
};

export type DirTrainerSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirTrainer?: Maybe<DirTrainerWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  organizations_every?: Maybe<DirTrainerSportOrganizationWhereInput>;
  organizations_some?: Maybe<DirTrainerSportOrganizationWhereInput>;
  organizations_none?: Maybe<DirTrainerSportOrganizationWhereInput>;
  categories_every?: Maybe<DirTrainerSportCategoryWhereInput>;
  categories_some?: Maybe<DirTrainerSportCategoryWhereInput>;
  categories_none?: Maybe<DirTrainerSportCategoryWhereInput>;
  AND?: Maybe<Array<DirTrainerSportWhereInput>>;
  OR?: Maybe<Array<DirTrainerSportWhereInput>>;
  NOT?: Maybe<Array<DirTrainerSportWhereInput>>;
};

export type DirTrainerSportOrganizationWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  clsPosition?: Maybe<ClassifierValueWhereInput>;
  employmentDate?: Maybe<Scalars['DateTime']>;
  employmentDate_not?: Maybe<Scalars['DateTime']>;
  employmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  employmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  employmentDate_lt?: Maybe<Scalars['DateTime']>;
  employmentDate_lte?: Maybe<Scalars['DateTime']>;
  employmentDate_gt?: Maybe<Scalars['DateTime']>;
  employmentDate_gte?: Maybe<Scalars['DateTime']>;
  dismissalDate?: Maybe<Scalars['DateTime']>;
  dismissalDate_not?: Maybe<Scalars['DateTime']>;
  dismissalDate_in?: Maybe<Array<Scalars['DateTime']>>;
  dismissalDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  dismissalDate_lt?: Maybe<Scalars['DateTime']>;
  dismissalDate_lte?: Maybe<Scalars['DateTime']>;
  dismissalDate_gt?: Maybe<Scalars['DateTime']>;
  dismissalDate_gte?: Maybe<Scalars['DateTime']>;
  sport?: Maybe<DirTrainerSportWhereInput>;
  AND?: Maybe<Array<DirTrainerSportOrganizationWhereInput>>;
  OR?: Maybe<Array<DirTrainerSportOrganizationWhereInput>>;
  NOT?: Maybe<Array<DirTrainerSportOrganizationWhereInput>>;
};

export type DirTrainerSportCategoryWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  clsTrainerCategory?: Maybe<ClassifierValueWhereInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  assignmentDate_not?: Maybe<Scalars['DateTime']>;
  assignmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_lt?: Maybe<Scalars['DateTime']>;
  assignmentDate_lte?: Maybe<Scalars['DateTime']>;
  assignmentDate_gt?: Maybe<Scalars['DateTime']>;
  assignmentDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  isEndless_not?: Maybe<Scalars['Boolean']>;
  sport?: Maybe<DirTrainerSportWhereInput>;
  AND?: Maybe<Array<DirTrainerSportCategoryWhereInput>>;
  OR?: Maybe<Array<DirTrainerSportCategoryWhereInput>>;
  NOT?: Maybe<Array<DirTrainerSportCategoryWhereInput>>;
};

export type DirAthleteSportOrganizationWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  clsTrainingStage?: Maybe<ClassifierValueWhereInput>;
  dirTrainer?: Maybe<DirTrainerWhereInput>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  isSelfTraining_not?: Maybe<Scalars['Boolean']>;
  trainingStart?: Maybe<Scalars['DateTime']>;
  trainingStart_not?: Maybe<Scalars['DateTime']>;
  trainingStart_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStart_not_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStart_lt?: Maybe<Scalars['DateTime']>;
  trainingStart_lte?: Maybe<Scalars['DateTime']>;
  trainingStart_gt?: Maybe<Scalars['DateTime']>;
  trainingStart_gte?: Maybe<Scalars['DateTime']>;
  exclusionDate?: Maybe<Scalars['DateTime']>;
  exclusionDate_not?: Maybe<Scalars['DateTime']>;
  exclusionDate_in?: Maybe<Array<Scalars['DateTime']>>;
  exclusionDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  exclusionDate_lt?: Maybe<Scalars['DateTime']>;
  exclusionDate_lte?: Maybe<Scalars['DateTime']>;
  exclusionDate_gt?: Maybe<Scalars['DateTime']>;
  exclusionDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirAthleteSportOrganizationWhereInput>>;
  OR?: Maybe<Array<DirAthleteSportOrganizationWhereInput>>;
  NOT?: Maybe<Array<DirAthleteSportOrganizationWhereInput>>;
};

export type DirAthleteSportRankAndAwardWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  sport?: Maybe<DirAthleteSportWhereInput>;
  clsRankAndAward?: Maybe<ClassifierValueWhereInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  assignmentDate_not?: Maybe<Scalars['DateTime']>;
  assignmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_lt?: Maybe<Scalars['DateTime']>;
  assignmentDate_lte?: Maybe<Scalars['DateTime']>;
  assignmentDate_gt?: Maybe<Scalars['DateTime']>;
  assignmentDate_gte?: Maybe<Scalars['DateTime']>;
  confirmDate?: Maybe<Scalars['DateTime']>;
  confirmDate_not?: Maybe<Scalars['DateTime']>;
  confirmDate_in?: Maybe<Array<Scalars['DateTime']>>;
  confirmDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  confirmDate_lt?: Maybe<Scalars['DateTime']>;
  confirmDate_lte?: Maybe<Scalars['DateTime']>;
  confirmDate_gt?: Maybe<Scalars['DateTime']>;
  confirmDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  dirDocument?: Maybe<DirDocumentWhereInput>;
  AND?: Maybe<Array<DirAthleteSportRankAndAwardWhereInput>>;
  OR?: Maybe<Array<DirAthleteSportRankAndAwardWhereInput>>;
  NOT?: Maybe<Array<DirAthleteSportRankAndAwardWhereInput>>;
};

export type DirDocumentWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  clsType?: Maybe<ClassifierValueWhereInput>;
  file?: Maybe<FileWhereInput>;
  AND?: Maybe<Array<DirDocumentWhereInput>>;
  OR?: Maybe<Array<DirDocumentWhereInput>>;
  NOT?: Maybe<Array<DirDocumentWhereInput>>;
};

export type FileWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  path_not?: Maybe<Scalars['String']>;
  path_in?: Maybe<Array<Scalars['String']>>;
  path_not_in?: Maybe<Array<Scalars['String']>>;
  path_lt?: Maybe<Scalars['String']>;
  path_lte?: Maybe<Scalars['String']>;
  path_gt?: Maybe<Scalars['String']>;
  path_gte?: Maybe<Scalars['String']>;
  path_contains?: Maybe<Scalars['String']>;
  path_not_contains?: Maybe<Scalars['String']>;
  path_starts_with?: Maybe<Scalars['String']>;
  path_not_starts_with?: Maybe<Scalars['String']>;
  path_ends_with?: Maybe<Scalars['String']>;
  path_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<FileWhereInput>>;
  OR?: Maybe<Array<FileWhereInput>>;
  NOT?: Maybe<Array<FileWhereInput>>;
};

export type DirAthleteMedCertWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAtReal?: Maybe<Scalars['DateTime']>;
  updatedAtReal_not?: Maybe<Scalars['DateTime']>;
  updatedAtReal_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAtReal_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAtReal_lt?: Maybe<Scalars['DateTime']>;
  updatedAtReal_lte?: Maybe<Scalars['DateTime']>;
  updatedAtReal_gt?: Maybe<Scalars['DateTime']>;
  updatedAtReal_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  displayNumber_not?: Maybe<Scalars['String']>;
  displayNumber_in?: Maybe<Array<Scalars['String']>>;
  displayNumber_not_in?: Maybe<Array<Scalars['String']>>;
  displayNumber_lt?: Maybe<Scalars['String']>;
  displayNumber_lte?: Maybe<Scalars['String']>;
  displayNumber_gt?: Maybe<Scalars['String']>;
  displayNumber_gte?: Maybe<Scalars['String']>;
  displayNumber_contains?: Maybe<Scalars['String']>;
  displayNumber_not_contains?: Maybe<Scalars['String']>;
  displayNumber_starts_with?: Maybe<Scalars['String']>;
  displayNumber_not_starts_with?: Maybe<Scalars['String']>;
  displayNumber_ends_with?: Maybe<Scalars['String']>;
  displayNumber_not_ends_with?: Maybe<Scalars['String']>;
  dirAthlete?: Maybe<DirAthleteWhereInput>;
  issueDate?: Maybe<Scalars['DateTime']>;
  issueDate_not?: Maybe<Scalars['DateTime']>;
  issueDate_in?: Maybe<Array<Scalars['DateTime']>>;
  issueDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  issueDate_lt?: Maybe<Scalars['DateTime']>;
  issueDate_lte?: Maybe<Scalars['DateTime']>;
  issueDate_gt?: Maybe<Scalars['DateTime']>;
  issueDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationName_not?: Maybe<Scalars['String']>;
  organizationName_in?: Maybe<Array<Scalars['String']>>;
  organizationName_not_in?: Maybe<Array<Scalars['String']>>;
  organizationName_lt?: Maybe<Scalars['String']>;
  organizationName_lte?: Maybe<Scalars['String']>;
  organizationName_gt?: Maybe<Scalars['String']>;
  organizationName_gte?: Maybe<Scalars['String']>;
  organizationName_contains?: Maybe<Scalars['String']>;
  organizationName_not_contains?: Maybe<Scalars['String']>;
  organizationName_starts_with?: Maybe<Scalars['String']>;
  organizationName_not_starts_with?: Maybe<Scalars['String']>;
  organizationName_ends_with?: Maybe<Scalars['String']>;
  organizationName_not_ends_with?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<Scalars['String']>;
  organizationAddress_not?: Maybe<Scalars['String']>;
  organizationAddress_in?: Maybe<Array<Scalars['String']>>;
  organizationAddress_not_in?: Maybe<Array<Scalars['String']>>;
  organizationAddress_lt?: Maybe<Scalars['String']>;
  organizationAddress_lte?: Maybe<Scalars['String']>;
  organizationAddress_gt?: Maybe<Scalars['String']>;
  organizationAddress_gte?: Maybe<Scalars['String']>;
  organizationAddress_contains?: Maybe<Scalars['String']>;
  organizationAddress_not_contains?: Maybe<Scalars['String']>;
  organizationAddress_starts_with?: Maybe<Scalars['String']>;
  organizationAddress_not_starts_with?: Maybe<Scalars['String']>;
  organizationAddress_ends_with?: Maybe<Scalars['String']>;
  organizationAddress_not_ends_with?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationPhone_not?: Maybe<Scalars['String']>;
  organizationPhone_in?: Maybe<Array<Scalars['String']>>;
  organizationPhone_not_in?: Maybe<Array<Scalars['String']>>;
  organizationPhone_lt?: Maybe<Scalars['String']>;
  organizationPhone_lte?: Maybe<Scalars['String']>;
  organizationPhone_gt?: Maybe<Scalars['String']>;
  organizationPhone_gte?: Maybe<Scalars['String']>;
  organizationPhone_contains?: Maybe<Scalars['String']>;
  organizationPhone_not_contains?: Maybe<Scalars['String']>;
  organizationPhone_starts_with?: Maybe<Scalars['String']>;
  organizationPhone_not_starts_with?: Maybe<Scalars['String']>;
  organizationPhone_ends_with?: Maybe<Scalars['String']>;
  organizationPhone_not_ends_with?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  organizationEmail_not?: Maybe<Scalars['String']>;
  organizationEmail_in?: Maybe<Array<Scalars['String']>>;
  organizationEmail_not_in?: Maybe<Array<Scalars['String']>>;
  organizationEmail_lt?: Maybe<Scalars['String']>;
  organizationEmail_lte?: Maybe<Scalars['String']>;
  organizationEmail_gt?: Maybe<Scalars['String']>;
  organizationEmail_gte?: Maybe<Scalars['String']>;
  organizationEmail_contains?: Maybe<Scalars['String']>;
  organizationEmail_not_contains?: Maybe<Scalars['String']>;
  organizationEmail_starts_with?: Maybe<Scalars['String']>;
  organizationEmail_not_starts_with?: Maybe<Scalars['String']>;
  organizationEmail_ends_with?: Maybe<Scalars['String']>;
  organizationEmail_not_ends_with?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  isValid_not?: Maybe<Scalars['Boolean']>;
  sports_every?: Maybe<DirAthleteMedCertSportWhereInput>;
  sports_some?: Maybe<DirAthleteMedCertSportWhereInput>;
  sports_none?: Maybe<DirAthleteMedCertSportWhereInput>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_not?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_in?: Maybe<Array<Scalars['DateTime']>>;
  physicalExaminationDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  physicalExaminationDate_lt?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_lte?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_gt?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_gte?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
  hasRestrictions_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirAthleteMedCertWhereInput>>;
  OR?: Maybe<Array<DirAthleteMedCertWhereInput>>;
  NOT?: Maybe<Array<DirAthleteMedCertWhereInput>>;
};

export type DirAthleteMedCertSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  medCert?: Maybe<DirAthleteMedCertWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  clsTrainingStage?: Maybe<ClassifierValueWhereInput>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  AND?: Maybe<Array<DirAthleteMedCertSportWhereInput>>;
  OR?: Maybe<Array<DirAthleteMedCertSportWhereInput>>;
  NOT?: Maybe<Array<DirAthleteMedCertSportWhereInput>>;
};

export type DirAthleteCompetitionResultWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  noResultReason?: Maybe<ClassifierValueWhereInput>;
  dirAthlete?: Maybe<DirAthleteWhereInput>;
  sportingEventProgramType?: Maybe<DirSportingEventProgramTypeWhereInput>;
  point?: Maybe<Scalars['Int']>;
  point_not?: Maybe<Scalars['Int']>;
  point_in?: Maybe<Array<Scalars['Int']>>;
  point_not_in?: Maybe<Array<Scalars['Int']>>;
  point_lt?: Maybe<Scalars['Int']>;
  point_lte?: Maybe<Scalars['Int']>;
  point_gt?: Maybe<Scalars['Int']>;
  point_gte?: Maybe<Scalars['Int']>;
  pointTo?: Maybe<Scalars['Int']>;
  pointTo_not?: Maybe<Scalars['Int']>;
  pointTo_in?: Maybe<Array<Scalars['Int']>>;
  pointTo_not_in?: Maybe<Array<Scalars['Int']>>;
  pointTo_lt?: Maybe<Scalars['Int']>;
  pointTo_lte?: Maybe<Scalars['Int']>;
  pointTo_gt?: Maybe<Scalars['Int']>;
  pointTo_gte?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  result_not?: Maybe<Scalars['String']>;
  result_in?: Maybe<Array<Scalars['String']>>;
  result_not_in?: Maybe<Array<Scalars['String']>>;
  result_lt?: Maybe<Scalars['String']>;
  result_lte?: Maybe<Scalars['String']>;
  result_gt?: Maybe<Scalars['String']>;
  result_gte?: Maybe<Scalars['String']>;
  result_contains?: Maybe<Scalars['String']>;
  result_not_contains?: Maybe<Scalars['String']>;
  result_starts_with?: Maybe<Scalars['String']>;
  result_not_starts_with?: Maybe<Scalars['String']>;
  result_ends_with?: Maybe<Scalars['String']>;
  result_not_ends_with?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  score_not?: Maybe<Scalars['String']>;
  score_in?: Maybe<Array<Scalars['String']>>;
  score_not_in?: Maybe<Array<Scalars['String']>>;
  score_lt?: Maybe<Scalars['String']>;
  score_lte?: Maybe<Scalars['String']>;
  score_gt?: Maybe<Scalars['String']>;
  score_gte?: Maybe<Scalars['String']>;
  score_contains?: Maybe<Scalars['String']>;
  score_not_contains?: Maybe<Scalars['String']>;
  score_starts_with?: Maybe<Scalars['String']>;
  score_not_starts_with?: Maybe<Scalars['String']>;
  score_ends_with?: Maybe<Scalars['String']>;
  score_not_ends_with?: Maybe<Scalars['String']>;
  completedRank?: Maybe<Scalars['String']>;
  completedRank_not?: Maybe<Scalars['String']>;
  completedRank_in?: Maybe<Array<Scalars['String']>>;
  completedRank_not_in?: Maybe<Array<Scalars['String']>>;
  completedRank_lt?: Maybe<Scalars['String']>;
  completedRank_lte?: Maybe<Scalars['String']>;
  completedRank_gt?: Maybe<Scalars['String']>;
  completedRank_gte?: Maybe<Scalars['String']>;
  completedRank_contains?: Maybe<Scalars['String']>;
  completedRank_not_contains?: Maybe<Scalars['String']>;
  completedRank_starts_with?: Maybe<Scalars['String']>;
  completedRank_not_starts_with?: Maybe<Scalars['String']>;
  completedRank_ends_with?: Maybe<Scalars['String']>;
  completedRank_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirAthleteCompetitionResultWhereInput>>;
  OR?: Maybe<Array<DirAthleteCompetitionResultWhereInput>>;
  NOT?: Maybe<Array<DirAthleteCompetitionResultWhereInput>>;
};

export type DirSportingEventProgramTypeWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventWhereInput>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  start_not?: Maybe<Scalars['String']>;
  start_in?: Maybe<Array<Scalars['String']>>;
  start_not_in?: Maybe<Array<Scalars['String']>>;
  start_lt?: Maybe<Scalars['String']>;
  start_lte?: Maybe<Scalars['String']>;
  start_gt?: Maybe<Scalars['String']>;
  start_gte?: Maybe<Scalars['String']>;
  start_contains?: Maybe<Scalars['String']>;
  start_not_contains?: Maybe<Scalars['String']>;
  start_starts_with?: Maybe<Scalars['String']>;
  start_not_starts_with?: Maybe<Scalars['String']>;
  start_ends_with?: Maybe<Scalars['String']>;
  start_not_ends_with?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  division_not?: Maybe<Scalars['String']>;
  division_in?: Maybe<Array<Scalars['String']>>;
  division_not_in?: Maybe<Array<Scalars['String']>>;
  division_lt?: Maybe<Scalars['String']>;
  division_lte?: Maybe<Scalars['String']>;
  division_gt?: Maybe<Scalars['String']>;
  division_gte?: Maybe<Scalars['String']>;
  division_contains?: Maybe<Scalars['String']>;
  division_not_contains?: Maybe<Scalars['String']>;
  division_starts_with?: Maybe<Scalars['String']>;
  division_not_starts_with?: Maybe<Scalars['String']>;
  division_ends_with?: Maybe<Scalars['String']>;
  division_not_ends_with?: Maybe<Scalars['String']>;
  clsSportCategory?: Maybe<ClassifierValueWhereInput>;
  resultsDocument?: Maybe<DirDocumentWhereInput>;
  resultDocuments_every?: Maybe<DirDocumentWhereInput>;
  resultDocuments_some?: Maybe<DirDocumentWhereInput>;
  resultDocuments_none?: Maybe<DirDocumentWhereInput>;
  clsAgeGroups_every?: Maybe<ClassifierValueWhereInput>;
  clsAgeGroups_some?: Maybe<ClassifierValueWhereInput>;
  clsAgeGroups_none?: Maybe<ClassifierValueWhereInput>;
  discipline?: Maybe<DirSportDisciplineWhereInput>;
  AND?: Maybe<Array<DirSportingEventProgramTypeWhereInput>>;
  OR?: Maybe<Array<DirSportingEventProgramTypeWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventProgramTypeWhereInput>>;
};

export type DirSportingEventWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber_not?: Maybe<Scalars['Int']>;
  oldRegistryNumber_in?: Maybe<Array<Scalars['Int']>>;
  oldRegistryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  oldRegistryNumber_lt?: Maybe<Scalars['Int']>;
  oldRegistryNumber_lte?: Maybe<Scalars['Int']>;
  oldRegistryNumber_gt?: Maybe<Scalars['Int']>;
  oldRegistryNumber_gte?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification_not?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification_in?: Maybe<Array<Scalars['Int']>>;
  athletesQuotaNotCorrespondingByQualification_not_in?: Maybe<Array<Scalars['Int']>>;
  athletesQuotaNotCorrespondingByQualification_lt?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification_lte?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification_gt?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirNationalTeams_every?: Maybe<DirNationalTeamWhereInput>;
  dirNationalTeams_some?: Maybe<DirNationalTeamWhereInput>;
  dirNationalTeams_none?: Maybe<DirNationalTeamWhereInput>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  venue_not?: Maybe<Scalars['String']>;
  venue_in?: Maybe<Array<Scalars['String']>>;
  venue_not_in?: Maybe<Array<Scalars['String']>>;
  venue_lt?: Maybe<Scalars['String']>;
  venue_lte?: Maybe<Scalars['String']>;
  venue_gt?: Maybe<Scalars['String']>;
  venue_gte?: Maybe<Scalars['String']>;
  venue_contains?: Maybe<Scalars['String']>;
  venue_not_contains?: Maybe<Scalars['String']>;
  venue_starts_with?: Maybe<Scalars['String']>;
  venue_not_starts_with?: Maybe<Scalars['String']>;
  venue_ends_with?: Maybe<Scalars['String']>;
  venue_not_ends_with?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  object_not?: Maybe<Scalars['String']>;
  object_in?: Maybe<Array<Scalars['String']>>;
  object_not_in?: Maybe<Array<Scalars['String']>>;
  object_lt?: Maybe<Scalars['String']>;
  object_lte?: Maybe<Scalars['String']>;
  object_gt?: Maybe<Scalars['String']>;
  object_gte?: Maybe<Scalars['String']>;
  object_contains?: Maybe<Scalars['String']>;
  object_not_contains?: Maybe<Scalars['String']>;
  object_starts_with?: Maybe<Scalars['String']>;
  object_not_starts_with?: Maybe<Scalars['String']>;
  object_ends_with?: Maybe<Scalars['String']>;
  object_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  properNoun_not?: Maybe<Scalars['String']>;
  properNoun_in?: Maybe<Array<Scalars['String']>>;
  properNoun_not_in?: Maybe<Array<Scalars['String']>>;
  properNoun_lt?: Maybe<Scalars['String']>;
  properNoun_lte?: Maybe<Scalars['String']>;
  properNoun_gt?: Maybe<Scalars['String']>;
  properNoun_gte?: Maybe<Scalars['String']>;
  properNoun_contains?: Maybe<Scalars['String']>;
  properNoun_not_contains?: Maybe<Scalars['String']>;
  properNoun_starts_with?: Maybe<Scalars['String']>;
  properNoun_not_starts_with?: Maybe<Scalars['String']>;
  properNoun_ends_with?: Maybe<Scalars['String']>;
  properNoun_not_ends_with?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  ageGroup_not?: Maybe<Scalars['String']>;
  ageGroup_in?: Maybe<Array<Scalars['String']>>;
  ageGroup_not_in?: Maybe<Array<Scalars['String']>>;
  ageGroup_lt?: Maybe<Scalars['String']>;
  ageGroup_lte?: Maybe<Scalars['String']>;
  ageGroup_gt?: Maybe<Scalars['String']>;
  ageGroup_gte?: Maybe<Scalars['String']>;
  ageGroup_contains?: Maybe<Scalars['String']>;
  ageGroup_not_contains?: Maybe<Scalars['String']>;
  ageGroup_starts_with?: Maybe<Scalars['String']>;
  ageGroup_not_starts_with?: Maybe<Scalars['String']>;
  ageGroup_ends_with?: Maybe<Scalars['String']>;
  ageGroup_not_ends_with?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_not?: Maybe<Scalars['DateTime']>;
  startDate_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_lt?: Maybe<Scalars['DateTime']>;
  startDate_lte?: Maybe<Scalars['DateTime']>;
  startDate_gt?: Maybe<Scalars['DateTime']>;
  startDate_gte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_not?: Maybe<Scalars['DateTime']>;
  endDate_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_lt?: Maybe<Scalars['DateTime']>;
  endDate_lte?: Maybe<Scalars['DateTime']>;
  endDate_gt?: Maybe<Scalars['DateTime']>;
  endDate_gte?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  minManAge_not?: Maybe<Scalars['Int']>;
  minManAge_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_lt?: Maybe<Scalars['Int']>;
  minManAge_lte?: Maybe<Scalars['Int']>;
  minManAge_gt?: Maybe<Scalars['Int']>;
  minManAge_gte?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  maxManAge_not?: Maybe<Scalars['Int']>;
  maxManAge_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_lt?: Maybe<Scalars['Int']>;
  maxManAge_lte?: Maybe<Scalars['Int']>;
  maxManAge_gt?: Maybe<Scalars['Int']>;
  maxManAge_gte?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  minManBirthYear_not?: Maybe<Scalars['Int']>;
  minManBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  minManBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  minManBirthYear_lt?: Maybe<Scalars['Int']>;
  minManBirthYear_lte?: Maybe<Scalars['Int']>;
  minManBirthYear_gt?: Maybe<Scalars['Int']>;
  minManBirthYear_gte?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear_not?: Maybe<Scalars['Int']>;
  maxManBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  maxManBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  maxManBirthYear_lt?: Maybe<Scalars['Int']>;
  maxManBirthYear_lte?: Maybe<Scalars['Int']>;
  maxManBirthYear_gt?: Maybe<Scalars['Int']>;
  maxManBirthYear_gte?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  minWomanAge_not?: Maybe<Scalars['Int']>;
  minWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_lt?: Maybe<Scalars['Int']>;
  minWomanAge_lte?: Maybe<Scalars['Int']>;
  minWomanAge_gt?: Maybe<Scalars['Int']>;
  minWomanAge_gte?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge_not?: Maybe<Scalars['Int']>;
  maxWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_lt?: Maybe<Scalars['Int']>;
  maxWomanAge_lte?: Maybe<Scalars['Int']>;
  maxWomanAge_gt?: Maybe<Scalars['Int']>;
  maxWomanAge_gte?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  minWomanBirthYear_not?: Maybe<Scalars['Int']>;
  minWomanBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  minWomanBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  minWomanBirthYear_lt?: Maybe<Scalars['Int']>;
  minWomanBirthYear_lte?: Maybe<Scalars['Int']>;
  minWomanBirthYear_gt?: Maybe<Scalars['Int']>;
  minWomanBirthYear_gte?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_not?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanBirthYear_lt?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_lte?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_gt?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_gte?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  membersQuota_not?: Maybe<Scalars['Int']>;
  membersQuota_in?: Maybe<Array<Scalars['Int']>>;
  membersQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  membersQuota_lt?: Maybe<Scalars['Int']>;
  membersQuota_lte?: Maybe<Scalars['Int']>;
  membersQuota_gt?: Maybe<Scalars['Int']>;
  membersQuota_gte?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  refereesQuota_not?: Maybe<Scalars['Int']>;
  refereesQuota_in?: Maybe<Array<Scalars['Int']>>;
  refereesQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  refereesQuota_lt?: Maybe<Scalars['Int']>;
  refereesQuota_lte?: Maybe<Scalars['Int']>;
  refereesQuota_gt?: Maybe<Scalars['Int']>;
  refereesQuota_gte?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_not?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_in?: Maybe<Array<Scalars['Int']>>;
  nonresidentRefereesQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  nonresidentRefereesQuota_lt?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_lte?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_gt?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_gte?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols_not?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols_in?: Maybe<Array<Scalars['Int']>>;
  daysBeforeStartToAddProtocols_not_in?: Maybe<Array<Scalars['Int']>>;
  daysBeforeStartToAddProtocols_lt?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols_lte?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols_gt?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols_gte?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount_not?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount_in?: Maybe<Array<Scalars['Int']>>;
  athletesTrainersRefereesSpecialistsCount_not_in?: Maybe<Array<Scalars['Int']>>;
  athletesTrainersRefereesSpecialistsCount_lt?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount_lte?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount_gt?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount_gte?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  ekp_not?: Maybe<Scalars['String']>;
  ekp_in?: Maybe<Array<Scalars['String']>>;
  ekp_not_in?: Maybe<Array<Scalars['String']>>;
  ekp_lt?: Maybe<Scalars['String']>;
  ekp_lte?: Maybe<Scalars['String']>;
  ekp_gt?: Maybe<Scalars['String']>;
  ekp_gte?: Maybe<Scalars['String']>;
  ekp_contains?: Maybe<Scalars['String']>;
  ekp_not_contains?: Maybe<Scalars['String']>;
  ekp_starts_with?: Maybe<Scalars['String']>;
  ekp_not_starts_with?: Maybe<Scalars['String']>;
  ekp_ends_with?: Maybe<Scalars['String']>;
  ekp_not_ends_with?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByAge_not?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByAge_in?: Maybe<Array<Scalars['Int']>>;
  athletesQuotaNotCorrespondingByAge_not_in?: Maybe<Array<Scalars['Int']>>;
  athletesQuotaNotCorrespondingByAge_lt?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByAge_lte?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByAge_gt?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByAge_gte?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  federalBudgetFinancing_not?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities_not?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  isRussia_not?: Maybe<Scalars['Boolean']>;
  dirRegions_every?: Maybe<DirRegionWhereInput>;
  dirRegions_some?: Maybe<DirRegionWhereInput>;
  dirRegions_none?: Maybe<DirRegionWhereInput>;
  dirFederalDistricts_every?: Maybe<DirFederalDistrictWhereInput>;
  dirFederalDistricts_some?: Maybe<DirFederalDistrictWhereInput>;
  dirFederalDistricts_none?: Maybe<DirFederalDistrictWhereInput>;
  countries_every?: Maybe<DirSportingEventCountryWhereInput>;
  countries_some?: Maybe<DirSportingEventCountryWhereInput>;
  countries_none?: Maybe<DirSportingEventCountryWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  dirCountry?: Maybe<DirCountryWhereInput>;
  dirForeignCity?: Maybe<DirForeignCityWhereInput>;
  cost?: Maybe<DirSportingEventCostWhereInput>;
  quota?: Maybe<DirSportingEventQuotaWhereInput>;
  quantity?: Maybe<DirSportingEventQuantityWhereInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isImportant_not?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation_not?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant_not?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isAgeRestricted_not?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues_not?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventWhereInput>;
  children_every?: Maybe<DirSportingEventWhereInput>;
  children_some?: Maybe<DirSportingEventWhereInput>;
  children_none?: Maybe<DirSportingEventWhereInput>;
  proxy?: Maybe<DirSportingEventWhereInput>;
  changes_every?: Maybe<DirSportingEventWhereInput>;
  changes_some?: Maybe<DirSportingEventWhereInput>;
  changes_none?: Maybe<DirSportingEventWhereInput>;
  status?: Maybe<DirSportingEventStatusWhereInput>;
  statusHistory_every?: Maybe<DirSportingEventStatusHistoryWhereInput>;
  statusHistory_some?: Maybe<DirSportingEventStatusHistoryWhereInput>;
  statusHistory_none?: Maybe<DirSportingEventStatusHistoryWhereInput>;
  statuses_every?: Maybe<DirSportingEventExtendedStatusWhereInput>;
  statuses_some?: Maybe<DirSportingEventExtendedStatusWhereInput>;
  statuses_none?: Maybe<DirSportingEventExtendedStatusWhereInput>;
  dirCalendar?: Maybe<DirCalendarWhereInput>;
  clsEventCategories_every?: Maybe<ClassifierValueWhereInput>;
  clsEventCategories_some?: Maybe<ClassifierValueWhereInput>;
  clsEventCategories_none?: Maybe<ClassifierValueWhereInput>;
  sports_every?: Maybe<DirSportingEventSportWhereInput>;
  sports_some?: Maybe<DirSportingEventSportWhereInput>;
  sports_none?: Maybe<DirSportingEventSportWhereInput>;
  ageGroups_every?: Maybe<DirSportingEventAgeGroupWhereInput>;
  ageGroups_some?: Maybe<DirSportingEventAgeGroupWhereInput>;
  ageGroups_none?: Maybe<DirSportingEventAgeGroupWhereInput>;
  programTypes_every?: Maybe<DirSportingEventProgramTypeWhereInput>;
  programTypes_some?: Maybe<DirSportingEventProgramTypeWhereInput>;
  programTypes_none?: Maybe<DirSportingEventProgramTypeWhereInput>;
  clsMinRang?: Maybe<ClassifierValueWhereInput>;
  competitionCalendar_every?: Maybe<DirSportingEventCompetitionCalendarWhereInput>;
  competitionCalendar_some?: Maybe<DirSportingEventCompetitionCalendarWhereInput>;
  competitionCalendar_none?: Maybe<DirSportingEventCompetitionCalendarWhereInput>;
  competitionReglaments_every?: Maybe<DirDocumentWhereInput>;
  competitionReglaments_some?: Maybe<DirDocumentWhereInput>;
  competitionReglaments_none?: Maybe<DirDocumentWhereInput>;
  competitionProtocols_every?: Maybe<DirDocumentWhereInput>;
  competitionProtocols_some?: Maybe<DirDocumentWhereInput>;
  competitionProtocols_none?: Maybe<DirDocumentWhereInput>;
  organizers_every?: Maybe<DirOrganizationWhereInput>;
  organizers_some?: Maybe<DirOrganizationWhereInput>;
  organizers_none?: Maybe<DirOrganizationWhereInput>;
  clsDirectionality?: Maybe<ClassifierValueWhereInput>;
  refereeCollegiumDocuments_every?: Maybe<DirDocumentWhereInput>;
  refereeCollegiumDocuments_some?: Maybe<DirDocumentWhereInput>;
  refereeCollegiumDocuments_none?: Maybe<DirDocumentWhereInput>;
  clsSummarizingType?: Maybe<ClassifierValueWhereInput>;
  clsDivisions_every?: Maybe<ClassifierValueWhereInput>;
  clsDivisions_some?: Maybe<ClassifierValueWhereInput>;
  clsDivisions_none?: Maybe<ClassifierValueWhereInput>;
  clsEventStages_every?: Maybe<ClassifierValueWhereInput>;
  clsEventStages_some?: Maybe<ClassifierValueWhereInput>;
  clsEventStages_none?: Maybe<ClassifierValueWhereInput>;
  requests_every?: Maybe<DirSportingEventRequestWhereInput>;
  requests_some?: Maybe<DirSportingEventRequestWhereInput>;
  requests_none?: Maybe<DirSportingEventRequestWhereInput>;
  clsAvailableSportingQualifications_every?: Maybe<ClassifierValueWhereInput>;
  clsAvailableSportingQualifications_some?: Maybe<ClassifierValueWhereInput>;
  clsAvailableSportingQualifications_none?: Maybe<ClassifierValueWhereInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueWhereInput>;
  pointsDistribution_every?: Maybe<DirSportingEventPointDistributionWhereInput>;
  pointsDistribution_some?: Maybe<DirSportingEventPointDistributionWhereInput>;
  pointsDistribution_none?: Maybe<DirSportingEventPointDistributionWhereInput>;
  referees_every?: Maybe<DirSportingEventRefereeWhereInput>;
  referees_some?: Maybe<DirSportingEventRefereeWhereInput>;
  referees_none?: Maybe<DirSportingEventRefereeWhereInput>;
  refereeStatuses_every?: Maybe<DirSportingEventRefereeStatusWhereInput>;
  refereeStatuses_some?: Maybe<DirSportingEventRefereeStatusWhereInput>;
  refereeStatuses_none?: Maybe<DirSportingEventRefereeStatusWhereInput>;
  credentialsCommitteeProtocolDocuments_every?: Maybe<DirDocumentWhereInput>;
  credentialsCommitteeProtocolDocuments_some?: Maybe<DirDocumentWhereInput>;
  credentialsCommitteeProtocolDocuments_none?: Maybe<DirDocumentWhereInput>;
  competitionProtocolFiles_every?: Maybe<FileWhereInput>;
  competitionProtocolFiles_some?: Maybe<FileWhereInput>;
  competitionProtocolFiles_none?: Maybe<FileWhereInput>;
  finalProtocolDocuments_every?: Maybe<DirDocumentWhereInput>;
  finalProtocolDocuments_some?: Maybe<DirDocumentWhereInput>;
  finalProtocolDocuments_none?: Maybe<DirDocumentWhereInput>;
  commissionAdmissionDecisions_every?: Maybe<DirDocumentWhereInput>;
  commissionAdmissionDecisions_some?: Maybe<DirDocumentWhereInput>;
  commissionAdmissionDecisions_none?: Maybe<DirDocumentWhereInput>;
  competitionFinalProtocols_every?: Maybe<DirDocumentWhereInput>;
  competitionFinalProtocols_some?: Maybe<DirDocumentWhereInput>;
  competitionFinalProtocols_none?: Maybe<DirDocumentWhereInput>;
  mainJuryReports_every?: Maybe<DirDocumentWhereInput>;
  mainJuryReports_some?: Maybe<DirDocumentWhereInput>;
  mainJuryReports_none?: Maybe<DirDocumentWhereInput>;
  athleteCallDocuments_every?: Maybe<DirDocumentWhereInput>;
  athleteCallDocuments_some?: Maybe<DirDocumentWhereInput>;
  athleteCallDocuments_none?: Maybe<DirDocumentWhereInput>;
  athleteCallNotifications_every?: Maybe<DirSportingEventAthleteCallNotificationWhereInput>;
  athleteCallNotifications_some?: Maybe<DirSportingEventAthleteCallNotificationWhereInput>;
  athleteCallNotifications_none?: Maybe<DirSportingEventAthleteCallNotificationWhereInput>;
  refereePersonalDataAgreements_every?: Maybe<FileWhereInput>;
  refereePersonalDataAgreements_some?: Maybe<FileWhereInput>;
  refereePersonalDataAgreements_none?: Maybe<FileWhereInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupWhereInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupWhereInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueWhereInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<DirSportingEventWhereInput>>;
  OR?: Maybe<Array<DirSportingEventWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventWhereInput>>;
};

export type DirNationalTeamWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  manTeamQuota_not?: Maybe<Scalars['Int']>;
  manTeamQuota_in?: Maybe<Array<Scalars['Int']>>;
  manTeamQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  manTeamQuota_lt?: Maybe<Scalars['Int']>;
  manTeamQuota_lte?: Maybe<Scalars['Int']>;
  manTeamQuota_gt?: Maybe<Scalars['Int']>;
  manTeamQuota_gte?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota_not?: Maybe<Scalars['Int']>;
  womanTeamQuota_in?: Maybe<Array<Scalars['Int']>>;
  womanTeamQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  womanTeamQuota_lt?: Maybe<Scalars['Int']>;
  womanTeamQuota_lte?: Maybe<Scalars['Int']>;
  womanTeamQuota_gt?: Maybe<Scalars['Int']>;
  womanTeamQuota_gte?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota_not?: Maybe<Scalars['Int']>;
  supportTeamQuota_in?: Maybe<Array<Scalars['Int']>>;
  supportTeamQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  supportTeamQuota_lt?: Maybe<Scalars['Int']>;
  supportTeamQuota_lte?: Maybe<Scalars['Int']>;
  supportTeamQuota_gt?: Maybe<Scalars['Int']>;
  supportTeamQuota_gte?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  athletesCall_not?: Maybe<Scalars['String']>;
  athletesCall_in?: Maybe<Array<Scalars['String']>>;
  athletesCall_not_in?: Maybe<Array<Scalars['String']>>;
  athletesCall_lt?: Maybe<Scalars['String']>;
  athletesCall_lte?: Maybe<Scalars['String']>;
  athletesCall_gt?: Maybe<Scalars['String']>;
  athletesCall_gte?: Maybe<Scalars['String']>;
  athletesCall_contains?: Maybe<Scalars['String']>;
  athletesCall_not_contains?: Maybe<Scalars['String']>;
  athletesCall_starts_with?: Maybe<Scalars['String']>;
  athletesCall_not_starts_with?: Maybe<Scalars['String']>;
  athletesCall_ends_with?: Maybe<Scalars['String']>;
  athletesCall_not_ends_with?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  point_not?: Maybe<Scalars['Int']>;
  point_in?: Maybe<Array<Scalars['Int']>>;
  point_not_in?: Maybe<Array<Scalars['Int']>>;
  point_lt?: Maybe<Scalars['Int']>;
  point_lte?: Maybe<Scalars['Int']>;
  point_gt?: Maybe<Scalars['Int']>;
  point_gte?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  score_not?: Maybe<Scalars['Int']>;
  score_in?: Maybe<Array<Scalars['Int']>>;
  score_not_in?: Maybe<Array<Scalars['Int']>>;
  score_lt?: Maybe<Scalars['Int']>;
  score_lte?: Maybe<Scalars['Int']>;
  score_gt?: Maybe<Scalars['Int']>;
  score_gte?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotified_not?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender_not?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  athleteTotalQuota_not?: Maybe<Scalars['Int']>;
  athleteTotalQuota_in?: Maybe<Array<Scalars['Int']>>;
  athleteTotalQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  athleteTotalQuota_lt?: Maybe<Scalars['Int']>;
  athleteTotalQuota_lte?: Maybe<Scalars['Int']>;
  athleteTotalQuota_gt?: Maybe<Scalars['Int']>;
  athleteTotalQuota_gte?: Maybe<Scalars['Int']>;
  status?: Maybe<DirNationalTeamStatusWhereInput>;
  statusHistory_every?: Maybe<DirNationalTeamStatusHistoryWhereInput>;
  statusHistory_some?: Maybe<DirNationalTeamStatusHistoryWhereInput>;
  statusHistory_none?: Maybe<DirNationalTeamStatusHistoryWhereInput>;
  statuses_every?: Maybe<DirNationalTeamExtendedStatusWhereInput>;
  statuses_some?: Maybe<DirNationalTeamExtendedStatusWhereInput>;
  statuses_none?: Maybe<DirNationalTeamExtendedStatusWhereInput>;
  dirSportingEvent?: Maybe<DirSportingEventWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  trainers_every?: Maybe<DirNationalTeamTrainerWhereInput>;
  trainers_some?: Maybe<DirNationalTeamTrainerWhereInput>;
  trainers_none?: Maybe<DirNationalTeamTrainerWhereInput>;
  athleteGroups_every?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
  athleteGroups_some?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
  athleteGroups_none?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
  specialists_every?: Maybe<DirNationalTeamMemberWhereInput>;
  specialists_some?: Maybe<DirNationalTeamMemberWhereInput>;
  specialists_none?: Maybe<DirNationalTeamMemberWhereInput>;
  medics_every?: Maybe<DirNationalTeamMemberWhereInput>;
  medics_some?: Maybe<DirNationalTeamMemberWhereInput>;
  medics_none?: Maybe<DirNationalTeamMemberWhereInput>;
  delegationHeads_every?: Maybe<DirNationalTeamMemberWhereInput>;
  delegationHeads_some?: Maybe<DirNationalTeamMemberWhereInput>;
  delegationHeads_none?: Maybe<DirNationalTeamMemberWhereInput>;
  emailRecipients_every?: Maybe<DirNationalTeamEmailRecipientWhereInput>;
  emailRecipients_some?: Maybe<DirNationalTeamEmailRecipientWhereInput>;
  emailRecipients_none?: Maybe<DirNationalTeamEmailRecipientWhereInput>;
  files_every?: Maybe<FileWhereInput>;
  files_some?: Maybe<FileWhereInput>;
  files_none?: Maybe<FileWhereInput>;
  university?: Maybe<DirOrganizationWhereInput>;
  refereePersonalDataAgreements_every?: Maybe<FileWhereInput>;
  refereePersonalDataAgreements_some?: Maybe<FileWhereInput>;
  refereePersonalDataAgreements_none?: Maybe<FileWhereInput>;
  arrivalDeparturePlan_every?: Maybe<FileWhereInput>;
  arrivalDeparturePlan_some?: Maybe<FileWhereInput>;
  arrivalDeparturePlan_none?: Maybe<FileWhereInput>;
  preliminaryQuantitativeOrder_every?: Maybe<FileWhereInput>;
  preliminaryQuantitativeOrder_some?: Maybe<FileWhereInput>;
  preliminaryQuantitativeOrder_none?: Maybe<FileWhereInput>;
  AND?: Maybe<Array<DirNationalTeamWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamWhereInput>>;
};

export type DirNationalTeamStatusWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  isDeprecated?: Maybe<Scalars['Boolean']>;
  isDeprecated_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirNationalTeamStatusWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamStatusWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamStatusWhereInput>>;
};

export type DirNationalTeamStatusHistoryWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DirNationalTeamStatusWhereInput>;
  user?: Maybe<UserWhereInput>;
  AND?: Maybe<Array<DirNationalTeamStatusHistoryWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamStatusHistoryWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamStatusHistoryWhereInput>>;
};

export type DirNationalTeamExtendedStatusWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  team?: Maybe<DirNationalTeamWhereInput>;
  value?: Maybe<DirNationalTeamStatusWhereInput>;
  file?: Maybe<FileWhereInput>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirNationalTeamExtendedStatusWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamExtendedStatusWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamExtendedStatusWhereInput>>;
};

export type DirNationalTeamTrainerWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  admissionDate?: Maybe<Scalars['DateTime']>;
  admissionDate_not?: Maybe<Scalars['DateTime']>;
  admissionDate_in?: Maybe<Array<Scalars['DateTime']>>;
  admissionDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  admissionDate_lt?: Maybe<Scalars['DateTime']>;
  admissionDate_lte?: Maybe<Scalars['DateTime']>;
  admissionDate_gt?: Maybe<Scalars['DateTime']>;
  admissionDate_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  team?: Maybe<DirNationalTeamWhereInput>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPrimary_not?: Maybe<Scalars['Boolean']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
  entranceStatus_not?: Maybe<Scalars['Boolean']>;
  dirTrainer?: Maybe<DirTrainerWhereInput>;
  clsPosition?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<DirNationalTeamTrainerWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamTrainerWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamTrainerWhereInput>>;
};

export type DirNationalTeamAthleteGroupWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  team?: Maybe<DirNationalTeamWhereInput>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  clsAgeGroups_every?: Maybe<ClassifierValueWhereInput>;
  clsAgeGroups_some?: Maybe<ClassifierValueWhereInput>;
  clsAgeGroups_none?: Maybe<ClassifierValueWhereInput>;
  disciplines_every?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_some?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_none?: Maybe<DirSportDisciplineWhereInput>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  athletes_every?: Maybe<DirNationalTeamAthleteWhereInput>;
  athletes_some?: Maybe<DirNationalTeamAthleteWhereInput>;
  athletes_none?: Maybe<DirNationalTeamAthleteWhereInput>;
  AND?: Maybe<Array<DirNationalTeamAthleteGroupWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamAthleteGroupWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamAthleteGroupWhereInput>>;
};

export type DirNationalTeamAthleteWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  admissionDate?: Maybe<Scalars['DateTime']>;
  admissionDate_not?: Maybe<Scalars['DateTime']>;
  admissionDate_in?: Maybe<Array<Scalars['DateTime']>>;
  admissionDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  admissionDate_lt?: Maybe<Scalars['DateTime']>;
  admissionDate_lte?: Maybe<Scalars['DateTime']>;
  admissionDate_gt?: Maybe<Scalars['DateTime']>;
  admissionDate_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  group?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
  dirAthlete?: Maybe<DirAthleteWhereInput>;
  bestResult?: Maybe<Scalars['String']>;
  bestResult_not?: Maybe<Scalars['String']>;
  bestResult_in?: Maybe<Array<Scalars['String']>>;
  bestResult_not_in?: Maybe<Array<Scalars['String']>>;
  bestResult_lt?: Maybe<Scalars['String']>;
  bestResult_lte?: Maybe<Scalars['String']>;
  bestResult_gt?: Maybe<Scalars['String']>;
  bestResult_gte?: Maybe<Scalars['String']>;
  bestResult_contains?: Maybe<Scalars['String']>;
  bestResult_not_contains?: Maybe<Scalars['String']>;
  bestResult_starts_with?: Maybe<Scalars['String']>;
  bestResult_not_starts_with?: Maybe<Scalars['String']>;
  bestResult_ends_with?: Maybe<Scalars['String']>;
  bestResult_not_ends_with?: Maybe<Scalars['String']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
  entranceStatus_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirNationalTeamAthleteWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamAthleteWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamAthleteWhereInput>>;
};

export type DirNationalTeamMemberWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  admissionDate?: Maybe<Scalars['DateTime']>;
  admissionDate_not?: Maybe<Scalars['DateTime']>;
  admissionDate_in?: Maybe<Array<Scalars['DateTime']>>;
  admissionDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  admissionDate_lt?: Maybe<Scalars['DateTime']>;
  admissionDate_lte?: Maybe<Scalars['DateTime']>;
  admissionDate_gt?: Maybe<Scalars['DateTime']>;
  admissionDate_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  lastname?: Maybe<Scalars['String']>;
  lastname_not?: Maybe<Scalars['String']>;
  lastname_in?: Maybe<Array<Scalars['String']>>;
  lastname_not_in?: Maybe<Array<Scalars['String']>>;
  lastname_lt?: Maybe<Scalars['String']>;
  lastname_lte?: Maybe<Scalars['String']>;
  lastname_gt?: Maybe<Scalars['String']>;
  lastname_gte?: Maybe<Scalars['String']>;
  lastname_contains?: Maybe<Scalars['String']>;
  lastname_not_contains?: Maybe<Scalars['String']>;
  lastname_starts_with?: Maybe<Scalars['String']>;
  lastname_not_starts_with?: Maybe<Scalars['String']>;
  lastname_ends_with?: Maybe<Scalars['String']>;
  lastname_not_ends_with?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  firstname_not?: Maybe<Scalars['String']>;
  firstname_in?: Maybe<Array<Scalars['String']>>;
  firstname_not_in?: Maybe<Array<Scalars['String']>>;
  firstname_lt?: Maybe<Scalars['String']>;
  firstname_lte?: Maybe<Scalars['String']>;
  firstname_gt?: Maybe<Scalars['String']>;
  firstname_gte?: Maybe<Scalars['String']>;
  firstname_contains?: Maybe<Scalars['String']>;
  firstname_not_contains?: Maybe<Scalars['String']>;
  firstname_starts_with?: Maybe<Scalars['String']>;
  firstname_not_starts_with?: Maybe<Scalars['String']>;
  firstname_ends_with?: Maybe<Scalars['String']>;
  firstname_not_ends_with?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  patronymic_not?: Maybe<Scalars['String']>;
  patronymic_in?: Maybe<Array<Scalars['String']>>;
  patronymic_not_in?: Maybe<Array<Scalars['String']>>;
  patronymic_lt?: Maybe<Scalars['String']>;
  patronymic_lte?: Maybe<Scalars['String']>;
  patronymic_gt?: Maybe<Scalars['String']>;
  patronymic_gte?: Maybe<Scalars['String']>;
  patronymic_contains?: Maybe<Scalars['String']>;
  patronymic_not_contains?: Maybe<Scalars['String']>;
  patronymic_starts_with?: Maybe<Scalars['String']>;
  patronymic_not_starts_with?: Maybe<Scalars['String']>;
  patronymic_ends_with?: Maybe<Scalars['String']>;
  patronymic_not_ends_with?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  birthday_not?: Maybe<Scalars['DateTime']>;
  birthday_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_not_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_lt?: Maybe<Scalars['DateTime']>;
  birthday_lte?: Maybe<Scalars['DateTime']>;
  birthday_gt?: Maybe<Scalars['DateTime']>;
  birthday_gte?: Maybe<Scalars['DateTime']>;
  clsPosition?: Maybe<ClassifierValueWhereInput>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
  entranceStatus_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirNationalTeamMemberWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamMemberWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamMemberWhereInput>>;
};

export type DirNationalTeamEmailRecipientWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotified_not?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
  isNotificationRequired_not?: Maybe<Scalars['Boolean']>;
  team?: Maybe<DirNationalTeamWhereInput>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  dirPerson?: Maybe<DirPersonWhereInput>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirNationalTeamEmailRecipientWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamEmailRecipientWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamEmailRecipientWhereInput>>;
};

export type DirSportingEventCountryWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventWhereInput>;
  dirCountry?: Maybe<DirCountryWhereInput>;
  dirForeignCities_every?: Maybe<DirForeignCityWhereInput>;
  dirForeignCities_some?: Maybe<DirForeignCityWhereInput>;
  dirForeignCities_none?: Maybe<DirForeignCityWhereInput>;
  AND?: Maybe<Array<DirSportingEventCountryWhereInput>>;
  OR?: Maybe<Array<DirSportingEventCountryWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventCountryWhereInput>>;
};

export type DirCountryWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  dirForeignCities_every?: Maybe<DirForeignCityWhereInput>;
  dirForeignCities_some?: Maybe<DirForeignCityWhereInput>;
  dirForeignCities_none?: Maybe<DirForeignCityWhereInput>;
  AND?: Maybe<Array<DirCountryWhereInput>>;
  OR?: Maybe<Array<DirCountryWhereInput>>;
  NOT?: Maybe<Array<DirCountryWhereInput>>;
};

export type DirForeignCityWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  dirCountry?: Maybe<DirCountryWhereInput>;
  AND?: Maybe<Array<DirForeignCityWhereInput>>;
  OR?: Maybe<Array<DirForeignCityWhereInput>>;
  NOT?: Maybe<Array<DirForeignCityWhereInput>>;
};

export type DirSportingEventCostWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUB_not?: Maybe<Scalars['Float']>;
  planRUB_in?: Maybe<Array<Scalars['Float']>>;
  planRUB_not_in?: Maybe<Array<Scalars['Float']>>;
  planRUB_lt?: Maybe<Scalars['Float']>;
  planRUB_lte?: Maybe<Scalars['Float']>;
  planRUB_gt?: Maybe<Scalars['Float']>;
  planRUB_gte?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planRUBperPerson_not?: Maybe<Scalars['Float']>;
  planRUBperPerson_in?: Maybe<Array<Scalars['Float']>>;
  planRUBperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  planRUBperPerson_lt?: Maybe<Scalars['Float']>;
  planRUBperPerson_lte?: Maybe<Scalars['Float']>;
  planRUBperPerson_gt?: Maybe<Scalars['Float']>;
  planRUBperPerson_gte?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSD_not?: Maybe<Scalars['Float']>;
  planUSD_in?: Maybe<Array<Scalars['Float']>>;
  planUSD_not_in?: Maybe<Array<Scalars['Float']>>;
  planUSD_lt?: Maybe<Scalars['Float']>;
  planUSD_lte?: Maybe<Scalars['Float']>;
  planUSD_gt?: Maybe<Scalars['Float']>;
  planUSD_gte?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planUSDperPerson_not?: Maybe<Scalars['Float']>;
  planUSDperPerson_in?: Maybe<Array<Scalars['Float']>>;
  planUSDperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  planUSDperPerson_lt?: Maybe<Scalars['Float']>;
  planUSDperPerson_lte?: Maybe<Scalars['Float']>;
  planUSDperPerson_gt?: Maybe<Scalars['Float']>;
  planUSDperPerson_gte?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  planDate_not?: Maybe<Scalars['DateTime']>;
  planDate_in?: Maybe<Array<Scalars['DateTime']>>;
  planDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  planDate_lt?: Maybe<Scalars['DateTime']>;
  planDate_lte?: Maybe<Scalars['DateTime']>;
  planDate_gt?: Maybe<Scalars['DateTime']>;
  planDate_gte?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUB_not?: Maybe<Scalars['Float']>;
  currentPlanRUB_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanRUB_not_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanRUB_lt?: Maybe<Scalars['Float']>;
  currentPlanRUB_lte?: Maybe<Scalars['Float']>;
  currentPlanRUB_gt?: Maybe<Scalars['Float']>;
  currentPlanRUB_gte?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_not?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanRUBperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanRUBperPerson_lt?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_lte?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_gt?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_gte?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSD_not?: Maybe<Scalars['Float']>;
  currentPlanUSD_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanUSD_not_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanUSD_lt?: Maybe<Scalars['Float']>;
  currentPlanUSD_lte?: Maybe<Scalars['Float']>;
  currentPlanUSD_gt?: Maybe<Scalars['Float']>;
  currentPlanUSD_gte?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_not?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanUSDperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanUSDperPerson_lt?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_lte?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_gt?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_gte?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  currentPlanDate_not?: Maybe<Scalars['DateTime']>;
  currentPlanDate_in?: Maybe<Array<Scalars['DateTime']>>;
  currentPlanDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  currentPlanDate_lt?: Maybe<Scalars['DateTime']>;
  currentPlanDate_lte?: Maybe<Scalars['DateTime']>;
  currentPlanDate_gt?: Maybe<Scalars['DateTime']>;
  currentPlanDate_gte?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUB_not?: Maybe<Scalars['Float']>;
  factRUB_in?: Maybe<Array<Scalars['Float']>>;
  factRUB_not_in?: Maybe<Array<Scalars['Float']>>;
  factRUB_lt?: Maybe<Scalars['Float']>;
  factRUB_lte?: Maybe<Scalars['Float']>;
  factRUB_gt?: Maybe<Scalars['Float']>;
  factRUB_gte?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factRUBperPerson_not?: Maybe<Scalars['Float']>;
  factRUBperPerson_in?: Maybe<Array<Scalars['Float']>>;
  factRUBperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  factRUBperPerson_lt?: Maybe<Scalars['Float']>;
  factRUBperPerson_lte?: Maybe<Scalars['Float']>;
  factRUBperPerson_gt?: Maybe<Scalars['Float']>;
  factRUBperPerson_gte?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSD_not?: Maybe<Scalars['Float']>;
  factUSD_in?: Maybe<Array<Scalars['Float']>>;
  factUSD_not_in?: Maybe<Array<Scalars['Float']>>;
  factUSD_lt?: Maybe<Scalars['Float']>;
  factUSD_lte?: Maybe<Scalars['Float']>;
  factUSD_gt?: Maybe<Scalars['Float']>;
  factUSD_gte?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factUSDperPerson_not?: Maybe<Scalars['Float']>;
  factUSDperPerson_in?: Maybe<Array<Scalars['Float']>>;
  factUSDperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  factUSDperPerson_lt?: Maybe<Scalars['Float']>;
  factUSDperPerson_lte?: Maybe<Scalars['Float']>;
  factUSDperPerson_gt?: Maybe<Scalars['Float']>;
  factUSDperPerson_gte?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  factDate_not?: Maybe<Scalars['DateTime']>;
  factDate_in?: Maybe<Array<Scalars['DateTime']>>;
  factDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  factDate_lt?: Maybe<Scalars['DateTime']>;
  factDate_lte?: Maybe<Scalars['DateTime']>;
  factDate_gt?: Maybe<Scalars['DateTime']>;
  factDate_gte?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
  year_not?: Maybe<Scalars['Int']>;
  year_in?: Maybe<Array<Scalars['Int']>>;
  year_not_in?: Maybe<Array<Scalars['Int']>>;
  year_lt?: Maybe<Scalars['Int']>;
  year_lte?: Maybe<Scalars['Int']>;
  year_gt?: Maybe<Scalars['Int']>;
  year_gte?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  month_not?: Maybe<Scalars['Int']>;
  month_in?: Maybe<Array<Scalars['Int']>>;
  month_not_in?: Maybe<Array<Scalars['Int']>>;
  month_lt?: Maybe<Scalars['Int']>;
  month_lte?: Maybe<Scalars['Int']>;
  month_gt?: Maybe<Scalars['Int']>;
  month_gte?: Maybe<Scalars['Int']>;
  clsFinancingTypes_every?: Maybe<ClassifierValueWhereInput>;
  clsFinancingTypes_some?: Maybe<ClassifierValueWhereInput>;
  clsFinancingTypes_none?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<DirSportingEventCostWhereInput>>;
  OR?: Maybe<Array<DirSportingEventCostWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventCostWhereInput>>;
};

export type DirSportingEventQuotaWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  athletesMan?: Maybe<Scalars['Int']>;
  athletesMan_not?: Maybe<Scalars['Int']>;
  athletesMan_in?: Maybe<Array<Scalars['Int']>>;
  athletesMan_not_in?: Maybe<Array<Scalars['Int']>>;
  athletesMan_lt?: Maybe<Scalars['Int']>;
  athletesMan_lte?: Maybe<Scalars['Int']>;
  athletesMan_gt?: Maybe<Scalars['Int']>;
  athletesMan_gte?: Maybe<Scalars['Int']>;
  athletesWoman?: Maybe<Scalars['Int']>;
  athletesWoman_not?: Maybe<Scalars['Int']>;
  athletesWoman_in?: Maybe<Array<Scalars['Int']>>;
  athletesWoman_not_in?: Maybe<Array<Scalars['Int']>>;
  athletesWoman_lt?: Maybe<Scalars['Int']>;
  athletesWoman_lte?: Maybe<Scalars['Int']>;
  athletesWoman_gt?: Maybe<Scalars['Int']>;
  athletesWoman_gte?: Maybe<Scalars['Int']>;
  trainers?: Maybe<Scalars['Int']>;
  trainers_not?: Maybe<Scalars['Int']>;
  trainers_in?: Maybe<Array<Scalars['Int']>>;
  trainers_not_in?: Maybe<Array<Scalars['Int']>>;
  trainers_lt?: Maybe<Scalars['Int']>;
  trainers_lte?: Maybe<Scalars['Int']>;
  trainers_gt?: Maybe<Scalars['Int']>;
  trainers_gte?: Maybe<Scalars['Int']>;
  referees?: Maybe<Scalars['Int']>;
  referees_not?: Maybe<Scalars['Int']>;
  referees_in?: Maybe<Array<Scalars['Int']>>;
  referees_not_in?: Maybe<Array<Scalars['Int']>>;
  referees_lt?: Maybe<Scalars['Int']>;
  referees_lte?: Maybe<Scalars['Int']>;
  referees_gt?: Maybe<Scalars['Int']>;
  referees_gte?: Maybe<Scalars['Int']>;
  administration?: Maybe<Scalars['Int']>;
  administration_not?: Maybe<Scalars['Int']>;
  administration_in?: Maybe<Array<Scalars['Int']>>;
  administration_not_in?: Maybe<Array<Scalars['Int']>>;
  administration_lt?: Maybe<Scalars['Int']>;
  administration_lte?: Maybe<Scalars['Int']>;
  administration_gt?: Maybe<Scalars['Int']>;
  administration_gte?: Maybe<Scalars['Int']>;
  medicalStaff?: Maybe<Scalars['Int']>;
  medicalStaff_not?: Maybe<Scalars['Int']>;
  medicalStaff_in?: Maybe<Array<Scalars['Int']>>;
  medicalStaff_not_in?: Maybe<Array<Scalars['Int']>>;
  medicalStaff_lt?: Maybe<Scalars['Int']>;
  medicalStaff_lte?: Maybe<Scalars['Int']>;
  medicalStaff_gt?: Maybe<Scalars['Int']>;
  medicalStaff_gte?: Maybe<Scalars['Int']>;
  specialists?: Maybe<Scalars['Int']>;
  specialists_not?: Maybe<Scalars['Int']>;
  specialists_in?: Maybe<Array<Scalars['Int']>>;
  specialists_not_in?: Maybe<Array<Scalars['Int']>>;
  specialists_lt?: Maybe<Scalars['Int']>;
  specialists_lte?: Maybe<Scalars['Int']>;
  specialists_gt?: Maybe<Scalars['Int']>;
  specialists_gte?: Maybe<Scalars['Int']>;
  teams?: Maybe<Scalars['Int']>;
  teams_not?: Maybe<Scalars['Int']>;
  teams_in?: Maybe<Array<Scalars['Int']>>;
  teams_not_in?: Maybe<Array<Scalars['Int']>>;
  teams_lt?: Maybe<Scalars['Int']>;
  teams_lte?: Maybe<Scalars['Int']>;
  teams_gt?: Maybe<Scalars['Int']>;
  teams_gte?: Maybe<Scalars['Int']>;
  clsParticipatingOrgCategories_every?: Maybe<ClassifierValueWhereInput>;
  clsParticipatingOrgCategories_some?: Maybe<ClassifierValueWhereInput>;
  clsParticipatingOrgCategories_none?: Maybe<ClassifierValueWhereInput>;
  clsTeamComposition?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<DirSportingEventQuotaWhereInput>>;
  OR?: Maybe<Array<DirSportingEventQuotaWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventQuotaWhereInput>>;
};

export type DirSportingEventQuantityWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  participantAthletesTotal?: Maybe<Scalars['Int']>;
  participantAthletesTotal_not?: Maybe<Scalars['Int']>;
  participantAthletesTotal_in?: Maybe<Array<Scalars['Int']>>;
  participantAthletesTotal_not_in?: Maybe<Array<Scalars['Int']>>;
  participantAthletesTotal_lt?: Maybe<Scalars['Int']>;
  participantAthletesTotal_lte?: Maybe<Scalars['Int']>;
  participantAthletesTotal_gt?: Maybe<Scalars['Int']>;
  participantAthletesTotal_gte?: Maybe<Scalars['Int']>;
  refereesTotal?: Maybe<Scalars['Int']>;
  refereesTotal_not?: Maybe<Scalars['Int']>;
  refereesTotal_in?: Maybe<Array<Scalars['Int']>>;
  refereesTotal_not_in?: Maybe<Array<Scalars['Int']>>;
  refereesTotal_lt?: Maybe<Scalars['Int']>;
  refereesTotal_lte?: Maybe<Scalars['Int']>;
  refereesTotal_gt?: Maybe<Scalars['Int']>;
  refereesTotal_gte?: Maybe<Scalars['Int']>;
  staffTotal?: Maybe<Scalars['Int']>;
  staffTotal_not?: Maybe<Scalars['Int']>;
  staffTotal_in?: Maybe<Array<Scalars['Int']>>;
  staffTotal_not_in?: Maybe<Array<Scalars['Int']>>;
  staffTotal_lt?: Maybe<Scalars['Int']>;
  staffTotal_lte?: Maybe<Scalars['Int']>;
  staffTotal_gt?: Maybe<Scalars['Int']>;
  staffTotal_gte?: Maybe<Scalars['Int']>;
  audienceTotal?: Maybe<Scalars['Int']>;
  audienceTotal_not?: Maybe<Scalars['Int']>;
  audienceTotal_in?: Maybe<Array<Scalars['Int']>>;
  audienceTotal_not_in?: Maybe<Array<Scalars['Int']>>;
  audienceTotal_lt?: Maybe<Scalars['Int']>;
  audienceTotal_lte?: Maybe<Scalars['Int']>;
  audienceTotal_gt?: Maybe<Scalars['Int']>;
  audienceTotal_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportingEventQuantityWhereInput>>;
  OR?: Maybe<Array<DirSportingEventQuantityWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventQuantityWhereInput>>;
};

export type DirSportingEventStatusWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportingEventStatusWhereInput>>;
  OR?: Maybe<Array<DirSportingEventStatusWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventStatusWhereInput>>;
};

export type DirSportingEventStatusHistoryWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DirSportingEventStatusWhereInput>;
  user?: Maybe<UserWhereInput>;
  AND?: Maybe<Array<DirSportingEventStatusHistoryWhereInput>>;
  OR?: Maybe<Array<DirSportingEventStatusHistoryWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventStatusHistoryWhereInput>>;
};

export type DirSportingEventExtendedStatusWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  event?: Maybe<DirSportingEventWhereInput>;
  value?: Maybe<DirSportingEventExtendedStatusValueWhereInput>;
  file?: Maybe<FileWhereInput>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventExtendedStatusWhereInput>>;
  OR?: Maybe<Array<DirSportingEventExtendedStatusWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventExtendedStatusWhereInput>>;
};

export type DirSportingEventExtendedStatusValueWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportingEventExtendedStatusValueWhereInput>>;
  OR?: Maybe<Array<DirSportingEventExtendedStatusValueWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventExtendedStatusValueWhereInput>>;
};

export type DirCalendarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_not?: Maybe<Scalars['DateTime']>;
  startDate_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_lt?: Maybe<Scalars['DateTime']>;
  startDate_lte?: Maybe<Scalars['DateTime']>;
  startDate_gt?: Maybe<Scalars['DateTime']>;
  startDate_gte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_not?: Maybe<Scalars['DateTime']>;
  endDate_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_lt?: Maybe<Scalars['DateTime']>;
  endDate_lte?: Maybe<Scalars['DateTime']>;
  endDate_gt?: Maybe<Scalars['DateTime']>;
  endDate_gte?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeWhereInput>;
  year?: Maybe<Scalars['Int']>;
  year_not?: Maybe<Scalars['Int']>;
  year_in?: Maybe<Array<Scalars['Int']>>;
  year_not_in?: Maybe<Array<Scalars['Int']>>;
  year_lt?: Maybe<Scalars['Int']>;
  year_lte?: Maybe<Scalars['Int']>;
  year_gt?: Maybe<Scalars['Int']>;
  year_gte?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  ekpMinsportNumber_not?: Maybe<Scalars['String']>;
  ekpMinsportNumber_in?: Maybe<Array<Scalars['String']>>;
  ekpMinsportNumber_not_in?: Maybe<Array<Scalars['String']>>;
  ekpMinsportNumber_lt?: Maybe<Scalars['String']>;
  ekpMinsportNumber_lte?: Maybe<Scalars['String']>;
  ekpMinsportNumber_gt?: Maybe<Scalars['String']>;
  ekpMinsportNumber_gte?: Maybe<Scalars['String']>;
  ekpMinsportNumber_contains?: Maybe<Scalars['String']>;
  ekpMinsportNumber_not_contains?: Maybe<Scalars['String']>;
  ekpMinsportNumber_starts_with?: Maybe<Scalars['String']>;
  ekpMinsportNumber_not_starts_with?: Maybe<Scalars['String']>;
  ekpMinsportNumber_ends_with?: Maybe<Scalars['String']>;
  ekpMinsportNumber_not_ends_with?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueWhereInput>;
  clsSeason?: Maybe<ClassifierValueWhereInput>;
  clsCalendarCategory?: Maybe<ClassifierValueWhereInput>;
  refusedRegions_every?: Maybe<DirRefusedRegionWhereInput>;
  refusedRegions_some?: Maybe<DirRefusedRegionWhereInput>;
  refusedRegions_none?: Maybe<DirRefusedRegionWhereInput>;
  returnedRegions_every?: Maybe<DirReturnedRegionWhereInput>;
  returnedRegions_some?: Maybe<DirReturnedRegionWhereInput>;
  returnedRegions_none?: Maybe<DirReturnedRegionWhereInput>;
  venueCompetition?: Maybe<VenueCompetitionWhereInput>;
  sportCompetitionRegulationDocument?: Maybe<FileWhereInput>;
  dirSportEventRegulations_every?: Maybe<DirSportEventRegulationsWhereInput>;
  dirSportEventRegulations_some?: Maybe<DirSportEventRegulationsWhereInput>;
  dirSportEventRegulations_none?: Maybe<DirSportEventRegulationsWhereInput>;
  roivForNotificationProtocol_every?: Maybe<NotificationProtocolWhereInput>;
  roivForNotificationProtocol_some?: Maybe<NotificationProtocolWhereInput>;
  roivForNotificationProtocol_none?: Maybe<NotificationProtocolWhereInput>;
  regulationChanges_every?: Maybe<FileWhereInput>;
  regulationChanges_some?: Maybe<FileWhereInput>;
  regulationChanges_none?: Maybe<FileWhereInput>;
  stageNumber?: Maybe<ClassifierValueWhereInput>;
  dirSports_every?: Maybe<DirSportWhereInput>;
  dirSports_some?: Maybe<DirSportWhereInput>;
  dirSports_none?: Maybe<DirSportWhereInput>;
  signedReports_every?: Maybe<DirCalendarSignedReportWhereInput>;
  signedReports_some?: Maybe<DirCalendarSignedReportWhereInput>;
  signedReports_none?: Maybe<DirCalendarSignedReportWhereInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  dateAcceptQuotasAndFilesStageTwo_not?: Maybe<Scalars['DateTime']>;
  dateAcceptQuotasAndFilesStageTwo_in?: Maybe<Array<Scalars['DateTime']>>;
  dateAcceptQuotasAndFilesStageTwo_not_in?: Maybe<Array<Scalars['DateTime']>>;
  dateAcceptQuotasAndFilesStageTwo_lt?: Maybe<Scalars['DateTime']>;
  dateAcceptQuotasAndFilesStageTwo_lte?: Maybe<Scalars['DateTime']>;
  dateAcceptQuotasAndFilesStageTwo_gt?: Maybe<Scalars['DateTime']>;
  dateAcceptQuotasAndFilesStageTwo_gte?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo_every?: Maybe<ReqPartFromStageTwoWhereInput>;
  reqPartFromStageTwo_some?: Maybe<ReqPartFromStageTwoWhereInput>;
  reqPartFromStageTwo_none?: Maybe<ReqPartFromStageTwoWhereInput>;
  quotasForStageTwoBySport_every?: Maybe<QuotaForStageTwoBySportWhereInput>;
  quotasForStageTwoBySport_some?: Maybe<QuotaForStageTwoBySportWhereInput>;
  quotasForStageTwoBySport_none?: Maybe<QuotaForStageTwoBySportWhereInput>;
  quantyRequestsStageTwo_every?: Maybe<QuantyRequestStageTwoWhereInput>;
  quantyRequestsStageTwo_some?: Maybe<QuantyRequestStageTwoWhereInput>;
  quantyRequestsStageTwo_none?: Maybe<QuantyRequestStageTwoWhereInput>;
  AND?: Maybe<Array<DirCalendarWhereInput>>;
  OR?: Maybe<Array<DirCalendarWhereInput>>;
  NOT?: Maybe<Array<DirCalendarWhereInput>>;
};

export type DirCalendarTypeWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirCalendarTypeWhereInput>>;
  OR?: Maybe<Array<DirCalendarTypeWhereInput>>;
  NOT?: Maybe<Array<DirCalendarTypeWhereInput>>;
};

export type DirRefusedRegionWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  statement?: Maybe<FileWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  dirCalendar?: Maybe<DirCalendarWhereInput>;
  AND?: Maybe<Array<DirRefusedRegionWhereInput>>;
  OR?: Maybe<Array<DirRefusedRegionWhereInput>>;
  NOT?: Maybe<Array<DirRefusedRegionWhereInput>>;
};

export type DirReturnedRegionWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  statement?: Maybe<FileWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  dirCalendar?: Maybe<DirCalendarWhereInput>;
  AND?: Maybe<Array<DirReturnedRegionWhereInput>>;
  OR?: Maybe<Array<DirReturnedRegionWhereInput>>;
  NOT?: Maybe<Array<DirReturnedRegionWhereInput>>;
};

export type VenueCompetitionWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  dirCalendar?: Maybe<DirCalendarWhereInput>;
  dirDocument?: Maybe<DirDocumentWhereInput>;
  venueCompDecisionDoc?: Maybe<DirDocumentWhereInput>;
  venueCompRegulationDocAdds_every?: Maybe<FileWhereInput>;
  venueCompRegulationDocAdds_some?: Maybe<FileWhereInput>;
  venueCompRegulationDocAdds_none?: Maybe<FileWhereInput>;
  venueCompDecisionDocAdds_every?: Maybe<FileWhereInput>;
  venueCompDecisionDocAdds_some?: Maybe<FileWhereInput>;
  venueCompDecisionDocAdds_none?: Maybe<FileWhereInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_not?: Maybe<Scalars['DateTime']>;
  endDate_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_lt?: Maybe<Scalars['DateTime']>;
  endDate_lte?: Maybe<Scalars['DateTime']>;
  endDate_gt?: Maybe<Scalars['DateTime']>;
  endDate_gte?: Maybe<Scalars['DateTime']>;
  emailSenderFCPSR?: Maybe<UserWhereInput>;
  venueCompApplications_every?: Maybe<VenueCompApplicationWhereInput>;
  venueCompApplications_some?: Maybe<VenueCompApplicationWhereInput>;
  venueCompApplications_none?: Maybe<VenueCompApplicationWhereInput>;
  AND?: Maybe<Array<VenueCompetitionWhereInput>>;
  OR?: Maybe<Array<VenueCompetitionWhereInput>>;
  NOT?: Maybe<Array<VenueCompetitionWhereInput>>;
};

export type VenueCompApplicationWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  venueCompetition?: Maybe<VenueCompetitionWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  number?: Maybe<Scalars['Int']>;
  number_not?: Maybe<Scalars['Int']>;
  number_in?: Maybe<Array<Scalars['Int']>>;
  number_not_in?: Maybe<Array<Scalars['Int']>>;
  number_lt?: Maybe<Scalars['Int']>;
  number_lte?: Maybe<Scalars['Int']>;
  number_gt?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
  applicationFiles_every?: Maybe<FileWhereInput>;
  applicationFiles_some?: Maybe<FileWhereInput>;
  applicationFiles_none?: Maybe<FileWhereInput>;
  notificationSentToFCPSR?: Maybe<Scalars['Boolean']>;
  notificationSentToFCPSR_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<VenueCompApplicationWhereInput>>;
  OR?: Maybe<Array<VenueCompApplicationWhereInput>>;
  NOT?: Maybe<Array<VenueCompApplicationWhereInput>>;
};

export type DirSportEventRegulationsWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  dirCalendar?: Maybe<DirCalendarWhereInput>;
  regulationsDocument?: Maybe<FileWhereInput>;
  regulationsAmendedDocument?: Maybe<FileWhereInput>;
  regulationsAgreedDocument?: Maybe<FileWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  status_every?: Maybe<DirSportingEventRegulationStatusWhereInput>;
  status_some?: Maybe<DirSportingEventRegulationStatusWhereInput>;
  status_none?: Maybe<DirSportingEventRegulationStatusWhereInput>;
  fcpsrAddRegulationsDoc?: Maybe<UserWhereInput>;
  AND?: Maybe<Array<DirSportEventRegulationsWhereInput>>;
  OR?: Maybe<Array<DirSportEventRegulationsWhereInput>>;
  NOT?: Maybe<Array<DirSportEventRegulationsWhereInput>>;
};

export type DirSportingEventRegulationStatusWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  regulation?: Maybe<DirSportEventRegulationsWhereInput>;
  value?: Maybe<DirSportingEventRegulationStatusValueWhereInput>;
  file?: Maybe<FileWhereInput>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventRegulationStatusWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRegulationStatusWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRegulationStatusWhereInput>>;
};

export type DirSportingEventRegulationStatusValueWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportingEventRegulationStatusValueWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRegulationStatusValueWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRegulationStatusValueWhereInput>>;
};

export type NotificationProtocolWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  dirCalendar?: Maybe<DirCalendarWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  forNotificationROIVSpecialists_every?: Maybe<UserWhereInput>;
  forNotificationROIVSpecialists_some?: Maybe<UserWhereInput>;
  forNotificationROIVSpecialists_none?: Maybe<UserWhereInput>;
  AND?: Maybe<Array<NotificationProtocolWhereInput>>;
  OR?: Maybe<Array<NotificationProtocolWhereInput>>;
  NOT?: Maybe<Array<NotificationProtocolWhereInput>>;
};

export type DirCalendarSignedReportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirCalendarSignedReport?: Maybe<DirCalendarWhereInput>;
  dirCalendar?: Maybe<DirCalendarWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  files_every?: Maybe<FileWhereInput>;
  files_some?: Maybe<FileWhereInput>;
  files_none?: Maybe<FileWhereInput>;
  AND?: Maybe<Array<DirCalendarSignedReportWhereInput>>;
  OR?: Maybe<Array<DirCalendarSignedReportWhereInput>>;
  NOT?: Maybe<Array<DirCalendarSignedReportWhereInput>>;
};

export type ReqPartFromStageTwoWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  region?: Maybe<DirRegionWhereInput>;
  dirCalendar?: Maybe<DirCalendarWhereInput>;
  roivSpecialistsForNotification_every?: Maybe<UserWhereInput>;
  roivSpecialistsForNotification_some?: Maybe<UserWhereInput>;
  roivSpecialistsForNotification_none?: Maybe<UserWhereInput>;
  filesROIVStageTwo_every?: Maybe<FileWhereInput>;
  filesROIVStageTwo_some?: Maybe<FileWhereInput>;
  filesROIVStageTwo_none?: Maybe<FileWhereInput>;
  AND?: Maybe<Array<ReqPartFromStageTwoWhereInput>>;
  OR?: Maybe<Array<ReqPartFromStageTwoWhereInput>>;
  NOT?: Maybe<Array<ReqPartFromStageTwoWhereInput>>;
};

export type QuotaForStageTwoBySportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  dirCalendar?: Maybe<DirCalendarWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  quotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaMaleAthlStageTwo_not?: Maybe<Scalars['Int']>;
  quotaMaleAthlStageTwo_in?: Maybe<Array<Scalars['Int']>>;
  quotaMaleAthlStageTwo_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaMaleAthlStageTwo_lt?: Maybe<Scalars['Int']>;
  quotaMaleAthlStageTwo_lte?: Maybe<Scalars['Int']>;
  quotaMaleAthlStageTwo_gt?: Maybe<Scalars['Int']>;
  quotaMaleAthlStageTwo_gte?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo_not?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo_in?: Maybe<Array<Scalars['Int']>>;
  quotaWomenAthlStageTwo_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaWomenAthlStageTwo_lt?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo_lte?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo_gt?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo_gte?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo_not?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo_in?: Maybe<Array<Scalars['Int']>>;
  quotaAccompStageTwo_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaAccompStageTwo_lt?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo_lte?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo_gt?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<QuotaForStageTwoBySportWhereInput>>;
  OR?: Maybe<Array<QuotaForStageTwoBySportWhereInput>>;
  NOT?: Maybe<Array<QuotaForStageTwoBySportWhereInput>>;
};

export type QuantyRequestStageTwoWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  dirCalendar?: Maybe<DirCalendarWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  regionQuotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaMaleAthlStageTwo_not?: Maybe<Scalars['Int']>;
  regionQuotaMaleAthlStageTwo_in?: Maybe<Array<Scalars['Int']>>;
  regionQuotaMaleAthlStageTwo_not_in?: Maybe<Array<Scalars['Int']>>;
  regionQuotaMaleAthlStageTwo_lt?: Maybe<Scalars['Int']>;
  regionQuotaMaleAthlStageTwo_lte?: Maybe<Scalars['Int']>;
  regionQuotaMaleAthlStageTwo_gt?: Maybe<Scalars['Int']>;
  regionQuotaMaleAthlStageTwo_gte?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo_not?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo_in?: Maybe<Array<Scalars['Int']>>;
  regionQuotaWomenAthlStageTwo_not_in?: Maybe<Array<Scalars['Int']>>;
  regionQuotaWomenAthlStageTwo_lt?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo_lte?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo_gt?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo_gte?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo_not?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo_in?: Maybe<Array<Scalars['Int']>>;
  regionQuotaAccompStageTwo_not_in?: Maybe<Array<Scalars['Int']>>;
  regionQuotaAccompStageTwo_lt?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo_lte?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo_gt?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<QuantyRequestStageTwoWhereInput>>;
  OR?: Maybe<Array<QuantyRequestStageTwoWhereInput>>;
  NOT?: Maybe<Array<QuantyRequestStageTwoWhereInput>>;
};

export type DirSportingEventSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  disciplines_every?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_some?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_none?: Maybe<DirSportDisciplineWhereInput>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  AND?: Maybe<Array<DirSportingEventSportWhereInput>>;
  OR?: Maybe<Array<DirSportingEventSportWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventSportWhereInput>>;
};

export type DirSportingEventAgeGroupWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventWhereInput>;
  clsAgeGroup?: Maybe<ClassifierValueWhereInput>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportingEventAgeGroupWhereInput>>;
  OR?: Maybe<Array<DirSportingEventAgeGroupWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventAgeGroupWhereInput>>;
};

export type DirSportingEventCompetitionCalendarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventWhereInput>;
  day?: Maybe<Scalars['DateTime']>;
  day_not?: Maybe<Scalars['DateTime']>;
  day_in?: Maybe<Array<Scalars['DateTime']>>;
  day_not_in?: Maybe<Array<Scalars['DateTime']>>;
  day_lt?: Maybe<Scalars['DateTime']>;
  day_lte?: Maybe<Scalars['DateTime']>;
  day_gt?: Maybe<Scalars['DateTime']>;
  day_gte?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  time_not?: Maybe<Scalars['String']>;
  time_in?: Maybe<Array<Scalars['String']>>;
  time_not_in?: Maybe<Array<Scalars['String']>>;
  time_lt?: Maybe<Scalars['String']>;
  time_lte?: Maybe<Scalars['String']>;
  time_gt?: Maybe<Scalars['String']>;
  time_gte?: Maybe<Scalars['String']>;
  time_contains?: Maybe<Scalars['String']>;
  time_not_contains?: Maybe<Scalars['String']>;
  time_starts_with?: Maybe<Scalars['String']>;
  time_not_starts_with?: Maybe<Scalars['String']>;
  time_ends_with?: Maybe<Scalars['String']>;
  time_not_ends_with?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  members_not?: Maybe<Scalars['String']>;
  members_in?: Maybe<Array<Scalars['String']>>;
  members_not_in?: Maybe<Array<Scalars['String']>>;
  members_lt?: Maybe<Scalars['String']>;
  members_lte?: Maybe<Scalars['String']>;
  members_gt?: Maybe<Scalars['String']>;
  members_gte?: Maybe<Scalars['String']>;
  members_contains?: Maybe<Scalars['String']>;
  members_not_contains?: Maybe<Scalars['String']>;
  members_starts_with?: Maybe<Scalars['String']>;
  members_not_starts_with?: Maybe<Scalars['String']>;
  members_ends_with?: Maybe<Scalars['String']>;
  members_not_ends_with?: Maybe<Scalars['String']>;
  discipline?: Maybe<DirSportDisciplineWhereInput>;
  AND?: Maybe<Array<DirSportingEventCompetitionCalendarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventCompetitionCalendarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventCompetitionCalendarWhereInput>>;
};

export type DirSportingEventRequestWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  event?: Maybe<DirSportingEventWhereInput>;
  orderNumber?: Maybe<Scalars['Int']>;
  orderNumber_not?: Maybe<Scalars['Int']>;
  orderNumber_in?: Maybe<Array<Scalars['Int']>>;
  orderNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  orderNumber_lt?: Maybe<Scalars['Int']>;
  orderNumber_lte?: Maybe<Scalars['Int']>;
  orderNumber_gt?: Maybe<Scalars['Int']>;
  orderNumber_gte?: Maybe<Scalars['Int']>;
  type?: Maybe<DirSportingEventRequestTypeWhereInput>;
  statuses_every?: Maybe<DirSportingEventRequestStatusWhereInput>;
  statuses_some?: Maybe<DirSportingEventRequestStatusWhereInput>;
  statuses_none?: Maybe<DirSportingEventRequestStatusWhereInput>;
  AND?: Maybe<Array<DirSportingEventRequestWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRequestWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRequestWhereInput>>;
};

export type DirSportingEventRequestTypeWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportingEventRequestTypeWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRequestTypeWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRequestTypeWhereInput>>;
};

export type DirSportingEventRequestStatusWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  request?: Maybe<DirSportingEventRequestWhereInput>;
  value?: Maybe<DirSportingEventRequestStatusValueWhereInput>;
  file?: Maybe<FileWhereInput>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventRequestStatusWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRequestStatusWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRequestStatusWhereInput>>;
};

export type DirSportingEventRequestStatusValueWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportingEventRequestStatusValueWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRequestStatusValueWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRequestStatusValueWhereInput>>;
};

export type DirSportingEventPointDistributionWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  event?: Maybe<DirSportingEventWhereInput>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  lastPlacesPoints?: Maybe<Scalars['Int']>;
  lastPlacesPoints_not?: Maybe<Scalars['Int']>;
  lastPlacesPoints_in?: Maybe<Array<Scalars['Int']>>;
  lastPlacesPoints_not_in?: Maybe<Array<Scalars['Int']>>;
  lastPlacesPoints_lt?: Maybe<Scalars['Int']>;
  lastPlacesPoints_lte?: Maybe<Scalars['Int']>;
  lastPlacesPoints_gt?: Maybe<Scalars['Int']>;
  lastPlacesPoints_gte?: Maybe<Scalars['Int']>;
  lastPlacesStart?: Maybe<Scalars['Int']>;
  lastPlacesStart_not?: Maybe<Scalars['Int']>;
  lastPlacesStart_in?: Maybe<Array<Scalars['Int']>>;
  lastPlacesStart_not_in?: Maybe<Array<Scalars['Int']>>;
  lastPlacesStart_lt?: Maybe<Scalars['Int']>;
  lastPlacesStart_lte?: Maybe<Scalars['Int']>;
  lastPlacesStart_gt?: Maybe<Scalars['Int']>;
  lastPlacesStart_gte?: Maybe<Scalars['Int']>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplines_every?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_some?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_none?: Maybe<DirSportDisciplineWhereInput>;
  clsAgeGroup?: Maybe<ClassifierValueWhereInput>;
  places_every?: Maybe<DirSportingEventPointDistributionPlaceWhereInput>;
  places_some?: Maybe<DirSportingEventPointDistributionPlaceWhereInput>;
  places_none?: Maybe<DirSportingEventPointDistributionPlaceWhereInput>;
  AND?: Maybe<Array<DirSportingEventPointDistributionWhereInput>>;
  OR?: Maybe<Array<DirSportingEventPointDistributionWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventPointDistributionWhereInput>>;
};

export type DirSportingEventPointDistributionPlaceWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  pointDistribution?: Maybe<DirSportingEventPointDistributionWhereInput>;
  place?: Maybe<Scalars['Int']>;
  place_not?: Maybe<Scalars['Int']>;
  place_in?: Maybe<Array<Scalars['Int']>>;
  place_not_in?: Maybe<Array<Scalars['Int']>>;
  place_lt?: Maybe<Scalars['Int']>;
  place_lte?: Maybe<Scalars['Int']>;
  place_gt?: Maybe<Scalars['Int']>;
  place_gte?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  points_not?: Maybe<Scalars['Int']>;
  points_in?: Maybe<Array<Scalars['Int']>>;
  points_not_in?: Maybe<Array<Scalars['Int']>>;
  points_lt?: Maybe<Scalars['Int']>;
  points_lte?: Maybe<Scalars['Int']>;
  points_gt?: Maybe<Scalars['Int']>;
  points_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportingEventPointDistributionPlaceWhereInput>>;
  OR?: Maybe<Array<DirSportingEventPointDistributionPlaceWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventPointDistributionPlaceWhereInput>>;
};

export type DirSportingEventRefereeWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventWhereInput>;
  dirReferee?: Maybe<DirRefereeWhereInput>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPrimary_not?: Maybe<Scalars['Boolean']>;
  clsRefereePosition?: Maybe<ClassifierValueWhereInput>;
  isNonresident?: Maybe<Scalars['Boolean']>;
  isNonresident_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventRefereeWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRefereeWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRefereeWhereInput>>;
};

export type DirRefereeWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonWhereInput>;
  sports_every?: Maybe<DirRefereeSportWhereInput>;
  sports_some?: Maybe<DirRefereeSportWhereInput>;
  sports_none?: Maybe<DirRefereeSportWhereInput>;
  AND?: Maybe<Array<DirRefereeWhereInput>>;
  OR?: Maybe<Array<DirRefereeWhereInput>>;
  NOT?: Maybe<Array<DirRefereeWhereInput>>;
};

export type DirRefereeSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirReferee?: Maybe<DirRefereeWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  workExperience?: Maybe<Scalars['Int']>;
  workExperience_not?: Maybe<Scalars['Int']>;
  workExperience_in?: Maybe<Array<Scalars['Int']>>;
  workExperience_not_in?: Maybe<Array<Scalars['Int']>>;
  workExperience_lt?: Maybe<Scalars['Int']>;
  workExperience_lte?: Maybe<Scalars['Int']>;
  workExperience_gt?: Maybe<Scalars['Int']>;
  workExperience_gte?: Maybe<Scalars['Int']>;
  categories_every?: Maybe<DirRefereeSportCategoryWhereInput>;
  categories_some?: Maybe<DirRefereeSportCategoryWhereInput>;
  categories_none?: Maybe<DirRefereeSportCategoryWhereInput>;
  AND?: Maybe<Array<DirRefereeSportWhereInput>>;
  OR?: Maybe<Array<DirRefereeSportWhereInput>>;
  NOT?: Maybe<Array<DirRefereeSportWhereInput>>;
};

export type DirRefereeSportCategoryWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  sport?: Maybe<DirRefereeSportWhereInput>;
  clsRefereeCategory?: Maybe<ClassifierValueWhereInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  assignmentDate_not?: Maybe<Scalars['DateTime']>;
  assignmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_lt?: Maybe<Scalars['DateTime']>;
  assignmentDate_lte?: Maybe<Scalars['DateTime']>;
  assignmentDate_gt?: Maybe<Scalars['DateTime']>;
  assignmentDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  isEndless_not?: Maybe<Scalars['Boolean']>;
  confirmationDate?: Maybe<Scalars['DateTime']>;
  confirmationDate_not?: Maybe<Scalars['DateTime']>;
  confirmationDate_in?: Maybe<Array<Scalars['DateTime']>>;
  confirmationDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  confirmationDate_lt?: Maybe<Scalars['DateTime']>;
  confirmationDate_lte?: Maybe<Scalars['DateTime']>;
  confirmationDate_gt?: Maybe<Scalars['DateTime']>;
  confirmationDate_gte?: Maybe<Scalars['DateTime']>;
  dirDocuments_every?: Maybe<DirDocumentWhereInput>;
  dirDocuments_some?: Maybe<DirDocumentWhereInput>;
  dirDocuments_none?: Maybe<DirDocumentWhereInput>;
  AND?: Maybe<Array<DirRefereeSportCategoryWhereInput>>;
  OR?: Maybe<Array<DirRefereeSportCategoryWhereInput>>;
  NOT?: Maybe<Array<DirRefereeSportCategoryWhereInput>>;
};

export type DirSportingEventRefereeStatusWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  event?: Maybe<DirSportingEventWhereInput>;
  value?: Maybe<DirSportingEventRefereeStatusValueWhereInput>;
  file?: Maybe<FileWhereInput>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventRefereeStatusWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRefereeStatusWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRefereeStatusWhereInput>>;
};

export type DirSportingEventRefereeStatusValueWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportingEventRefereeStatusValueWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRefereeStatusValueWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRefereeStatusValueWhereInput>>;
};

export type DirSportingEventAthleteCallNotificationWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  dirSportingEvent?: Maybe<DirSportingEventWhereInput>;
  document?: Maybe<DirDocumentWhereInput>;
  teams_every?: Maybe<DirSportingEventAthleteCallNotificationTeamWhereInput>;
  teams_some?: Maybe<DirSportingEventAthleteCallNotificationTeamWhereInput>;
  teams_none?: Maybe<DirSportingEventAthleteCallNotificationTeamWhereInput>;
  AND?: Maybe<Array<DirSportingEventAthleteCallNotificationWhereInput>>;
  OR?: Maybe<Array<DirSportingEventAthleteCallNotificationWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventAthleteCallNotificationWhereInput>>;
};

export type DirSportingEventAthleteCallNotificationTeamWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  notification?: Maybe<DirSportingEventAthleteCallNotificationWhereInput>;
  ditNationalTeam?: Maybe<DirNationalTeamWhereInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotified_not?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
  isNotificationRequired_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamWhereInput>>;
  OR?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamWhereInput>>;
};

export type DirPersonConfirmStatusWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirPersonConfirmStatusWhereInput>>;
  OR?: Maybe<Array<DirPersonConfirmStatusWhereInput>>;
  NOT?: Maybe<Array<DirPersonConfirmStatusWhereInput>>;
};

export type DirPersonPhoneWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  countryCode_not?: Maybe<Scalars['String']>;
  countryCode_in?: Maybe<Array<Scalars['String']>>;
  countryCode_not_in?: Maybe<Array<Scalars['String']>>;
  countryCode_lt?: Maybe<Scalars['String']>;
  countryCode_lte?: Maybe<Scalars['String']>;
  countryCode_gt?: Maybe<Scalars['String']>;
  countryCode_gte?: Maybe<Scalars['String']>;
  countryCode_contains?: Maybe<Scalars['String']>;
  countryCode_not_contains?: Maybe<Scalars['String']>;
  countryCode_starts_with?: Maybe<Scalars['String']>;
  countryCode_not_starts_with?: Maybe<Scalars['String']>;
  countryCode_ends_with?: Maybe<Scalars['String']>;
  countryCode_not_ends_with?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  operatorCode_not?: Maybe<Scalars['String']>;
  operatorCode_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_not_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_lt?: Maybe<Scalars['String']>;
  operatorCode_lte?: Maybe<Scalars['String']>;
  operatorCode_gt?: Maybe<Scalars['String']>;
  operatorCode_gte?: Maybe<Scalars['String']>;
  operatorCode_contains?: Maybe<Scalars['String']>;
  operatorCode_not_contains?: Maybe<Scalars['String']>;
  operatorCode_starts_with?: Maybe<Scalars['String']>;
  operatorCode_not_starts_with?: Maybe<Scalars['String']>;
  operatorCode_ends_with?: Maybe<Scalars['String']>;
  operatorCode_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  additionalNumber_not?: Maybe<Scalars['String']>;
  additionalNumber_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_not_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_lt?: Maybe<Scalars['String']>;
  additionalNumber_lte?: Maybe<Scalars['String']>;
  additionalNumber_gt?: Maybe<Scalars['String']>;
  additionalNumber_gte?: Maybe<Scalars['String']>;
  additionalNumber_contains?: Maybe<Scalars['String']>;
  additionalNumber_not_contains?: Maybe<Scalars['String']>;
  additionalNumber_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_not_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_ends_with?: Maybe<Scalars['String']>;
  additionalNumber_not_ends_with?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueWhereInput>;
  dirPerson?: Maybe<DirPersonWhereInput>;
  AND?: Maybe<Array<DirPersonPhoneWhereInput>>;
  OR?: Maybe<Array<DirPersonPhoneWhereInput>>;
  NOT?: Maybe<Array<DirPersonPhoneWhereInput>>;
};

export type DirPersonAdditionalRegionWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  isRussia_not?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  dirForeignCity?: Maybe<DirForeignCityWhereInput>;
  dirCountry?: Maybe<DirCountryWhereInput>;
  dirPerson?: Maybe<DirPersonWhereInput>;
  AND?: Maybe<Array<DirPersonAdditionalRegionWhereInput>>;
  OR?: Maybe<Array<DirPersonAdditionalRegionWhereInput>>;
  NOT?: Maybe<Array<DirPersonAdditionalRegionWhereInput>>;
};

export type DirPersonMergeTaskWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  status?: Maybe<TaskStatusWhereInput>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  primary?: Maybe<DirPersonWhereInput>;
  duplicate?: Maybe<DirPersonWhereInput>;
  AND?: Maybe<Array<DirPersonMergeTaskWhereInput>>;
  OR?: Maybe<Array<DirPersonMergeTaskWhereInput>>;
  NOT?: Maybe<Array<DirPersonMergeTaskWhereInput>>;
};

export type TaskStatusWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<TaskStatusWhereInput>>;
  OR?: Maybe<Array<TaskStatusWhereInput>>;
  NOT?: Maybe<Array<TaskStatusWhereInput>>;
};

export type DirPersonAddressWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonWhereInput>;
  place?: Maybe<Scalars['String']>;
  place_not?: Maybe<Scalars['String']>;
  place_in?: Maybe<Array<Scalars['String']>>;
  place_not_in?: Maybe<Array<Scalars['String']>>;
  place_lt?: Maybe<Scalars['String']>;
  place_lte?: Maybe<Scalars['String']>;
  place_gt?: Maybe<Scalars['String']>;
  place_gte?: Maybe<Scalars['String']>;
  place_contains?: Maybe<Scalars['String']>;
  place_not_contains?: Maybe<Scalars['String']>;
  place_starts_with?: Maybe<Scalars['String']>;
  place_not_starts_with?: Maybe<Scalars['String']>;
  place_ends_with?: Maybe<Scalars['String']>;
  place_not_ends_with?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_not?: Maybe<Scalars['String']>;
  street_in?: Maybe<Array<Scalars['String']>>;
  street_not_in?: Maybe<Array<Scalars['String']>>;
  street_lt?: Maybe<Scalars['String']>;
  street_lte?: Maybe<Scalars['String']>;
  street_gt?: Maybe<Scalars['String']>;
  street_gte?: Maybe<Scalars['String']>;
  street_contains?: Maybe<Scalars['String']>;
  street_not_contains?: Maybe<Scalars['String']>;
  street_starts_with?: Maybe<Scalars['String']>;
  street_not_starts_with?: Maybe<Scalars['String']>;
  street_ends_with?: Maybe<Scalars['String']>;
  street_not_ends_with?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  house_not?: Maybe<Scalars['String']>;
  house_in?: Maybe<Array<Scalars['String']>>;
  house_not_in?: Maybe<Array<Scalars['String']>>;
  house_lt?: Maybe<Scalars['String']>;
  house_lte?: Maybe<Scalars['String']>;
  house_gt?: Maybe<Scalars['String']>;
  house_gte?: Maybe<Scalars['String']>;
  house_contains?: Maybe<Scalars['String']>;
  house_not_contains?: Maybe<Scalars['String']>;
  house_starts_with?: Maybe<Scalars['String']>;
  house_not_starts_with?: Maybe<Scalars['String']>;
  house_ends_with?: Maybe<Scalars['String']>;
  house_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<AddressTypeWhereInput>;
  AND?: Maybe<Array<DirPersonAddressWhereInput>>;
  OR?: Maybe<Array<DirPersonAddressWhereInput>>;
  NOT?: Maybe<Array<DirPersonAddressWhereInput>>;
};

export type AddressTypeWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<AddressTypeWhereInput>>;
  OR?: Maybe<Array<AddressTypeWhereInput>>;
  NOT?: Maybe<Array<AddressTypeWhereInput>>;
};

export type RegionalMinsportProfileWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  dirFederalDistrict?: Maybe<DirFederalDistrictWhereInput>;
  AND?: Maybe<Array<RegionalMinsportProfileWhereInput>>;
  OR?: Maybe<Array<RegionalMinsportProfileWhereInput>>;
  NOT?: Maybe<Array<RegionalMinsportProfileWhereInput>>;
};

export type RegionalSchoolProfileWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  AND?: Maybe<Array<RegionalSchoolProfileWhereInput>>;
  OR?: Maybe<Array<RegionalSchoolProfileWhereInput>>;
  NOT?: Maybe<Array<RegionalSchoolProfileWhereInput>>;
};

export type RegionalAdministratorProfileWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  AND?: Maybe<Array<RegionalAdministratorProfileWhereInput>>;
  OR?: Maybe<Array<RegionalAdministratorProfileWhereInput>>;
  NOT?: Maybe<Array<RegionalAdministratorProfileWhereInput>>;
};

export type RoivSpecialistProfileWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  AND?: Maybe<Array<RoivSpecialistProfileWhereInput>>;
  OR?: Maybe<Array<RoivSpecialistProfileWhereInput>>;
  NOT?: Maybe<Array<RoivSpecialistProfileWhereInput>>;
};

export type FcpsrSportingEventOrganizerProfileWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  sportingEvents_every?: Maybe<DirSportingEventWhereInput>;
  sportingEvents_some?: Maybe<DirSportingEventWhereInput>;
  sportingEvents_none?: Maybe<DirSportingEventWhereInput>;
  dirCalendars_every?: Maybe<DirCalendarWhereInput>;
  dirCalendars_some?: Maybe<DirCalendarWhereInput>;
  dirCalendars_none?: Maybe<DirCalendarWhereInput>;
  dirSports_every?: Maybe<DirSportWhereInput>;
  dirSports_some?: Maybe<DirSportWhereInput>;
  dirSports_none?: Maybe<DirSportWhereInput>;
  AND?: Maybe<Array<FcpsrSportingEventOrganizerProfileWhereInput>>;
  OR?: Maybe<Array<FcpsrSportingEventOrganizerProfileWhereInput>>;
  NOT?: Maybe<Array<FcpsrSportingEventOrganizerProfileWhereInput>>;
};

export type GskRefereeProfileWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  dirSportingEvents_every?: Maybe<DirSportingEventWhereInput>;
  dirSportingEvents_some?: Maybe<DirSportingEventWhereInput>;
  dirSportingEvents_none?: Maybe<DirSportingEventWhereInput>;
  AND?: Maybe<Array<GskRefereeProfileWhereInput>>;
  OR?: Maybe<Array<GskRefereeProfileWhereInput>>;
  NOT?: Maybe<Array<GskRefereeProfileWhereInput>>;
};

export type UserSupportRequestWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  number?: Maybe<Scalars['Int']>;
  number_not?: Maybe<Scalars['Int']>;
  number_in?: Maybe<Array<Scalars['Int']>>;
  number_not_in?: Maybe<Array<Scalars['Int']>>;
  number_lt?: Maybe<Scalars['Int']>;
  number_lte?: Maybe<Scalars['Int']>;
  number_gt?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
  user?: Maybe<UserWhereInput>;
  operator?: Maybe<UserWhereInput>;
  request?: Maybe<Scalars['String']>;
  request_not?: Maybe<Scalars['String']>;
  request_in?: Maybe<Array<Scalars['String']>>;
  request_not_in?: Maybe<Array<Scalars['String']>>;
  request_lt?: Maybe<Scalars['String']>;
  request_lte?: Maybe<Scalars['String']>;
  request_gt?: Maybe<Scalars['String']>;
  request_gte?: Maybe<Scalars['String']>;
  request_contains?: Maybe<Scalars['String']>;
  request_not_contains?: Maybe<Scalars['String']>;
  request_starts_with?: Maybe<Scalars['String']>;
  request_not_starts_with?: Maybe<Scalars['String']>;
  request_ends_with?: Maybe<Scalars['String']>;
  request_not_ends_with?: Maybe<Scalars['String']>;
  requestType?: Maybe<UserSupportRequestTypeWhereInput>;
  file?: Maybe<FileWhereInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotified_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<UserSupportRequestWhereInput>>;
  OR?: Maybe<Array<UserSupportRequestWhereInput>>;
  NOT?: Maybe<Array<UserSupportRequestWhereInput>>;
};

export type UserSupportRequestTypeWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<UserSupportRequestTypeWhereInput>>;
  OR?: Maybe<Array<UserSupportRequestTypeWhereInput>>;
  NOT?: Maybe<Array<UserSupportRequestTypeWhereInput>>;
};

export type DirOrganizationSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  trainingStartDate_not?: Maybe<Scalars['DateTime']>;
  trainingStartDate_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStartDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStartDate_lt?: Maybe<Scalars['DateTime']>;
  trainingStartDate_lte?: Maybe<Scalars['DateTime']>;
  trainingStartDate_gt?: Maybe<Scalars['DateTime']>;
  trainingStartDate_gte?: Maybe<Scalars['DateTime']>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  AND?: Maybe<Array<DirOrganizationSportWhereInput>>;
  OR?: Maybe<Array<DirOrganizationSportWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationSportWhereInput>>;
};

export type DirOrganizationAddressWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  place?: Maybe<Scalars['String']>;
  place_not?: Maybe<Scalars['String']>;
  place_in?: Maybe<Array<Scalars['String']>>;
  place_not_in?: Maybe<Array<Scalars['String']>>;
  place_lt?: Maybe<Scalars['String']>;
  place_lte?: Maybe<Scalars['String']>;
  place_gt?: Maybe<Scalars['String']>;
  place_gte?: Maybe<Scalars['String']>;
  place_contains?: Maybe<Scalars['String']>;
  place_not_contains?: Maybe<Scalars['String']>;
  place_starts_with?: Maybe<Scalars['String']>;
  place_not_starts_with?: Maybe<Scalars['String']>;
  place_ends_with?: Maybe<Scalars['String']>;
  place_not_ends_with?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_not?: Maybe<Scalars['String']>;
  street_in?: Maybe<Array<Scalars['String']>>;
  street_not_in?: Maybe<Array<Scalars['String']>>;
  street_lt?: Maybe<Scalars['String']>;
  street_lte?: Maybe<Scalars['String']>;
  street_gt?: Maybe<Scalars['String']>;
  street_gte?: Maybe<Scalars['String']>;
  street_contains?: Maybe<Scalars['String']>;
  street_not_contains?: Maybe<Scalars['String']>;
  street_starts_with?: Maybe<Scalars['String']>;
  street_not_starts_with?: Maybe<Scalars['String']>;
  street_ends_with?: Maybe<Scalars['String']>;
  street_not_ends_with?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  house_not?: Maybe<Scalars['String']>;
  house_in?: Maybe<Array<Scalars['String']>>;
  house_not_in?: Maybe<Array<Scalars['String']>>;
  house_lt?: Maybe<Scalars['String']>;
  house_lte?: Maybe<Scalars['String']>;
  house_gt?: Maybe<Scalars['String']>;
  house_gte?: Maybe<Scalars['String']>;
  house_contains?: Maybe<Scalars['String']>;
  house_not_contains?: Maybe<Scalars['String']>;
  house_starts_with?: Maybe<Scalars['String']>;
  house_not_starts_with?: Maybe<Scalars['String']>;
  house_ends_with?: Maybe<Scalars['String']>;
  house_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<AddressTypeWhereInput>;
  AND?: Maybe<Array<DirOrganizationAddressWhereInput>>;
  OR?: Maybe<Array<DirOrganizationAddressWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationAddressWhereInput>>;
};

export type DirOrganizationPhoneWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  countryCode?: Maybe<Scalars['String']>;
  countryCode_not?: Maybe<Scalars['String']>;
  countryCode_in?: Maybe<Array<Scalars['String']>>;
  countryCode_not_in?: Maybe<Array<Scalars['String']>>;
  countryCode_lt?: Maybe<Scalars['String']>;
  countryCode_lte?: Maybe<Scalars['String']>;
  countryCode_gt?: Maybe<Scalars['String']>;
  countryCode_gte?: Maybe<Scalars['String']>;
  countryCode_contains?: Maybe<Scalars['String']>;
  countryCode_not_contains?: Maybe<Scalars['String']>;
  countryCode_starts_with?: Maybe<Scalars['String']>;
  countryCode_not_starts_with?: Maybe<Scalars['String']>;
  countryCode_ends_with?: Maybe<Scalars['String']>;
  countryCode_not_ends_with?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  operatorCode_not?: Maybe<Scalars['String']>;
  operatorCode_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_not_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_lt?: Maybe<Scalars['String']>;
  operatorCode_lte?: Maybe<Scalars['String']>;
  operatorCode_gt?: Maybe<Scalars['String']>;
  operatorCode_gte?: Maybe<Scalars['String']>;
  operatorCode_contains?: Maybe<Scalars['String']>;
  operatorCode_not_contains?: Maybe<Scalars['String']>;
  operatorCode_starts_with?: Maybe<Scalars['String']>;
  operatorCode_not_starts_with?: Maybe<Scalars['String']>;
  operatorCode_ends_with?: Maybe<Scalars['String']>;
  operatorCode_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  additionalNumber_not?: Maybe<Scalars['String']>;
  additionalNumber_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_not_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_lt?: Maybe<Scalars['String']>;
  additionalNumber_lte?: Maybe<Scalars['String']>;
  additionalNumber_gt?: Maybe<Scalars['String']>;
  additionalNumber_gte?: Maybe<Scalars['String']>;
  additionalNumber_contains?: Maybe<Scalars['String']>;
  additionalNumber_not_contains?: Maybe<Scalars['String']>;
  additionalNumber_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_not_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_ends_with?: Maybe<Scalars['String']>;
  additionalNumber_not_ends_with?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<DirOrganizationPhoneWhereInput>>;
  OR?: Maybe<Array<DirOrganizationPhoneWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationPhoneWhereInput>>;
};

export type DirOrganizationEmailWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<DirOrganizationEmailWhereInput>>;
  OR?: Maybe<Array<DirOrganizationEmailWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationEmailWhereInput>>;
};

export enum DirOrganizationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IsLegalEntityAsc = 'isLegalEntity_ASC',
  IsLegalEntityDesc = 'isLegalEntity_DESC',
  OgrnAsc = 'ogrn_ASC',
  OgrnDesc = 'ogrn_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  RegistrationDateAsc = 'registrationDate_ASC',
  RegistrationDateDesc = 'registrationDate_DESC',
  InnAsc = 'inn_ASC',
  InnDesc = 'inn_DESC',
  KppAsc = 'kpp_ASC',
  KppDesc = 'kpp_DESC',
  LegalAddressAsc = 'legalAddress_ASC',
  LegalAddressDesc = 'legalAddress_DESC',
  PostcodeAsc = 'postcode_ASC',
  PostcodeDesc = 'postcode_DESC',
  HeadPositionAsc = 'headPosition_ASC',
  HeadPositionDesc = 'headPosition_DESC',
  HeadFullNameAsc = 'headFullName_ASC',
  HeadFullNameDesc = 'headFullName_DESC',
  IsUniversityAsc = 'isUniversity_ASC',
  IsUniversityDesc = 'isUniversity_DESC'
}

export type DirRegion = {
  __typename?: 'DirRegion';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  code: Scalars['String'];
  dirFederalDistrict: DirFederalDistrict;
  roivOfficialEmail?: Maybe<Scalars['String']>;
};

export type DirFederalDistrict = {
  __typename?: 'DirFederalDistrict';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  dirRegions?: Maybe<Array<DirRegion>>;
};


export type DirFederalDistrictDirRegionsArgs = {
  where?: Maybe<DirRegionWhereInput>;
  orderBy?: Maybe<DirRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirRegionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  RoivOfficialEmailAsc = 'roivOfficialEmail_ASC',
  RoivOfficialEmailDesc = 'roivOfficialEmail_DESC'
}

export enum ClassifierValueOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC'
}

export type ClassifierValue = {
  __typename?: 'ClassifierValue';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  classifier: Classifier;
};

export type Classifier = {
  __typename?: 'Classifier';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  values?: Maybe<Array<ClassifierValue>>;
};


export type ClassifierValuesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  EvskAsc = 'evsk_ASC',
  EvskDesc = 'evsk_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  IsDisabledAsc = 'isDisabled_ASC',
  IsDisabledDesc = 'isDisabled_DESC'
}

export type DirSport = {
  __typename?: 'DirSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<Array<ClassifierValue>>;
  disciplines?: Maybe<Array<DirSportDiscipline>>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
  ageGroups?: Maybe<Array<DirSportAgeGroup>>;
  clsTrainingStages?: Maybe<Array<ClassifierValue>>;
  clsRanksAndAwards?: Maybe<Array<ClassifierValue>>;
  clsCategories?: Maybe<Array<ClassifierValue>>;
  trainingStages?: Maybe<Array<DirSportTrainingStage>>;
  baseRegions?: Maybe<Array<DirSportBaseRegion>>;
};


export type DirSportClsSportAndDisciplineGroupsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportDisciplinesArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
  orderBy?: Maybe<DirSportDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportAgeGroupsArgs = {
  where?: Maybe<DirSportAgeGroupWhereInput>;
  orderBy?: Maybe<DirSportAgeGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportClsTrainingStagesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportClsRanksAndAwardsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportClsCategoriesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportTrainingStagesArgs = {
  where?: Maybe<DirSportTrainingStageWhereInput>;
  orderBy?: Maybe<DirSportTrainingStageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportBaseRegionsArgs = {
  where?: Maybe<DirSportBaseRegionWhereInput>;
  orderBy?: Maybe<DirSportBaseRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportDisciplineOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC'
}

export type DirSportDiscipline = {
  __typename?: 'DirSportDiscipline';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
  dirSport?: Maybe<DirSport>;
};


export type DirSportDisciplineDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportDisciplineGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type DirSportDisciplineGroup = {
  __typename?: 'DirSportDisciplineGroup';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  disciplines?: Maybe<Array<DirSportDiscipline>>;
  dirSport?: Maybe<DirSport>;
};


export type DirSportDisciplineGroupDisciplinesArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
  orderBy?: Maybe<DirSportDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportAgeGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  MinAgeAsc = 'minAge_ASC',
  MinAgeDesc = 'minAge_DESC',
  MaxAgeAsc = 'maxAge_ASC',
  MaxAgeDesc = 'maxAge_DESC'
}

export type DirSportAgeGroup = {
  __typename?: 'DirSportAgeGroup';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup: ClassifierValue;
  minAge: Scalars['Int'];
  maxAge?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSport>;
  participationRequirements?: Maybe<Array<DirSportAgeGroupParticipationRequirements>>;
};


export type DirSportAgeGroupParticipationRequirementsArgs = {
  where?: Maybe<DirSportAgeGroupParticipationRequirementsWhereInput>;
  orderBy?: Maybe<DirSportAgeGroupParticipationRequirementsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportAgeGroupParticipationRequirementsOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DirSportAgeGroupParticipationRequirements = {
  __typename?: 'DirSportAgeGroupParticipationRequirements';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  ageGroup: DirSportAgeGroup;
  clsCalendarCategory?: Maybe<ClassifierValue>;
  clsRanksAndAwards?: Maybe<ClassifierValue>;
};

export enum DirSportTrainingStageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  MinManAgeAsc = 'minManAge_ASC',
  MinManAgeDesc = 'minManAge_DESC',
  MaxManAgeAsc = 'maxManAge_ASC',
  MaxManAgeDesc = 'maxManAge_DESC',
  MinWomanAgeAsc = 'minWomanAge_ASC',
  MinWomanAgeDesc = 'minWomanAge_DESC',
  MaxWomanAgeAsc = 'maxWomanAge_ASC',
  MaxWomanAgeDesc = 'maxWomanAge_DESC',
  MinGroupSizeAsc = 'minGroupSize_ASC',
  MinGroupSizeDesc = 'minGroupSize_DESC',
  MaxGroupSizeAsc = 'maxGroupSize_ASC',
  MaxGroupSizeDesc = 'maxGroupSize_DESC'
}

export type DirSportTrainingStage = {
  __typename?: 'DirSportTrainingStage';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSport>;
  clsTrainingStage: ClassifierValue;
  clsRanksAndAwards?: Maybe<Array<ClassifierValue>>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
};


export type DirSportTrainingStageClsRanksAndAwardsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportBaseRegionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC'
}

export type DirSportBaseRegion = {
  __typename?: 'DirSportBaseRegion';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  sport: DirSport;
  dirRegion: DirRegion;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export enum DirOrganizationSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  TrainingStartDateAsc = 'trainingStartDate_ASC',
  TrainingStartDateDesc = 'trainingStartDate_DESC'
}

export type DirOrganizationSport = {
  __typename?: 'DirOrganizationSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganization;
  dirSport: DirSport;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
};


export type DirOrganizationSportDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirOrganizationAddressOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  PlaceAsc = 'place_ASC',
  PlaceDesc = 'place_DESC',
  StreetAsc = 'street_ASC',
  StreetDesc = 'street_DESC',
  HouseAsc = 'house_ASC',
  HouseDesc = 'house_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC'
}

export type DirOrganizationAddress = {
  __typename?: 'DirOrganizationAddress';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganization;
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type: AddressType;
};

export type AddressType = {
  __typename?: 'AddressType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum DirOrganizationPhoneOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  OperatorCodeAsc = 'operatorCode_ASC',
  OperatorCodeDesc = 'operatorCode_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  AdditionalNumberAsc = 'additionalNumber_ASC',
  AdditionalNumberDesc = 'additionalNumber_DESC'
}

export type DirOrganizationPhone = {
  __typename?: 'DirOrganizationPhone';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganization;
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: ClassifierValue;
};

export enum DirOrganizationEmailOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC'
}

export type DirOrganizationEmail = {
  __typename?: 'DirOrganizationEmail';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganization;
  email: Scalars['String'];
  clsContactType: ClassifierValue;
};

export type SystemUser = {
  __typename?: 'SystemUser';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
};

export type DirPerson = {
  __typename?: 'DirPerson';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  fieldHistory?: Maybe<Array<FieldHistory>>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPerson>;
  duplicates?: Maybe<Array<DirPerson>>;
  userProfile?: Maybe<User>;
  athleteProfile?: Maybe<DirAthlete>;
  trainerProfile?: Maybe<DirTrainer>;
  refereeProfile?: Maybe<DirReferee>;
  confirmStatus: DirPersonConfirmStatus;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValue>;
  dirCitizenships?: Maybe<Array<DirCountry>>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegion>;
  dirForeignCity?: Maybe<DirForeignCity>;
  dirCountry?: Maybe<DirCountry>;
  phones?: Maybe<Array<DirPersonPhone>>;
  additionalRegions?: Maybe<Array<DirPersonAdditionalRegion>>;
  mergeTasksAsPrimary?: Maybe<Array<DirPersonMergeTask>>;
  mergeTasksAsDuplicate?: Maybe<Array<DirPersonMergeTask>>;
  addresses?: Maybe<Array<DirPersonAddress>>;
  nationalTeamEmailRecipients?: Maybe<Array<DirNationalTeamEmailRecipient>>;
  doc1?: Maybe<File>;
  doc2?: Maybe<File>;
  doc3?: Maybe<File>;
  bankRequisites?: Maybe<File>;
};


export type DirPersonFieldHistoryArgs = {
  where?: Maybe<FieldHistoryWhereInput>;
  orderBy?: Maybe<FieldHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirPersonDuplicatesArgs = {
  where?: Maybe<DirPersonWhereInput>;
  orderBy?: Maybe<DirPersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirPersonDirCitizenshipsArgs = {
  where?: Maybe<DirCountryWhereInput>;
  orderBy?: Maybe<DirCountryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirPersonPhonesArgs = {
  where?: Maybe<DirPersonPhoneWhereInput>;
  orderBy?: Maybe<DirPersonPhoneOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirPersonAdditionalRegionsArgs = {
  where?: Maybe<DirPersonAdditionalRegionWhereInput>;
  orderBy?: Maybe<DirPersonAdditionalRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirPersonMergeTasksAsPrimaryArgs = {
  where?: Maybe<DirPersonMergeTaskWhereInput>;
  orderBy?: Maybe<DirPersonMergeTaskOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirPersonMergeTasksAsDuplicateArgs = {
  where?: Maybe<DirPersonMergeTaskWhereInput>;
  orderBy?: Maybe<DirPersonMergeTaskOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirPersonAddressesArgs = {
  where?: Maybe<DirPersonAddressWhereInput>;
  orderBy?: Maybe<DirPersonAddressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirPersonNationalTeamEmailRecipientsArgs = {
  where?: Maybe<DirNationalTeamEmailRecipientWhereInput>;
  orderBy?: Maybe<DirNationalTeamEmailRecipientOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum FieldHistoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FieldAsc = 'field_ASC',
  FieldDesc = 'field_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type FieldHistory = {
  __typename?: 'FieldHistory';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  field: Scalars['String'];
  value: Scalars['Json'];
};


export enum DirPersonOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  MergedAtAsc = 'mergedAt_ASC',
  MergedAtDesc = 'mergedAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  LastnameAsc = 'lastname_ASC',
  LastnameDesc = 'lastname_DESC',
  FirstnameAsc = 'firstname_ASC',
  FirstnameDesc = 'firstname_DESC',
  PatronymicAsc = 'patronymic_ASC',
  PatronymicDesc = 'patronymic_DESC',
  PreviousLastnameAsc = 'previousLastname_ASC',
  PreviousLastnameDesc = 'previousLastname_DESC',
  BirthdayAsc = 'birthday_ASC',
  BirthdayDesc = 'birthday_DESC',
  IsMaleAsc = 'isMale_ASC',
  IsMaleDesc = 'isMale_DESC',
  SnilsAsc = 'snils_ASC',
  SnilsDesc = 'snils_DESC',
  PassportSerialAsc = 'passportSerial_ASC',
  PassportSerialDesc = 'passportSerial_DESC',
  PassportNumberAsc = 'passportNumber_ASC',
  PassportNumberDesc = 'passportNumber_DESC',
  PassportIssuedByAsc = 'passportIssuedBy_ASC',
  PassportIssuedByDesc = 'passportIssuedBy_DESC',
  PassportIssueDateAsc = 'passportIssueDate_ASC',
  PassportIssueDateDesc = 'passportIssueDate_DESC',
  ResidenceCityAsc = 'residenceCity_ASC',
  ResidenceCityDesc = 'residenceCity_DESC',
  IsRussiaAsc = 'isRussia_ASC',
  IsRussiaDesc = 'isRussia_DESC'
}

export type DirAthlete = {
  __typename?: 'DirAthlete';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPerson;
  dirPersonAgent?: Maybe<DirPerson>;
  clsPersonAgent?: Maybe<ClassifierValue>;
  sports?: Maybe<Array<DirAthleteSport>>;
  medCerts?: Maybe<Array<DirAthleteMedCert>>;
  competetionResult?: Maybe<Array<DirAthleteCompetitionResult>>;
};


export type DirAthleteSportsArgs = {
  where?: Maybe<DirAthleteSportWhereInput>;
  orderBy?: Maybe<DirAthleteSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirAthleteMedCertsArgs = {
  where?: Maybe<DirAthleteMedCertWhereInput>;
  orderBy?: Maybe<DirAthleteMedCertOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirAthleteCompetetionResultArgs = {
  where?: Maybe<DirAthleteCompetitionResultWhereInput>;
  orderBy?: Maybe<DirAthleteCompetitionResultOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirAthleteSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  RoleAsc = 'role_ASC',
  RoleDesc = 'role_DESC'
}

export type DirAthleteSport = {
  __typename?: 'DirAthleteSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSport;
  disciplines?: Maybe<Array<DirSportDiscipline>>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
  dirTrainers?: Maybe<Array<DirTrainer>>;
  organizations?: Maybe<Array<DirAthleteSportOrganization>>;
  ranksAndAwards?: Maybe<Array<DirAthleteSportRankAndAward>>;
  role?: Maybe<Scalars['String']>;
};


export type DirAthleteSportDisciplinesArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
  orderBy?: Maybe<DirSportDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirAthleteSportDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirAthleteSportDirTrainersArgs = {
  where?: Maybe<DirTrainerWhereInput>;
  orderBy?: Maybe<DirTrainerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirAthleteSportOrganizationsArgs = {
  where?: Maybe<DirAthleteSportOrganizationWhereInput>;
  orderBy?: Maybe<DirAthleteSportOrganizationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirAthleteSportRanksAndAwardsArgs = {
  where?: Maybe<DirAthleteSportRankAndAwardWhereInput>;
  orderBy?: Maybe<DirAthleteSportRankAndAwardOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirTrainerOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC'
}

export type DirTrainer = {
  __typename?: 'DirTrainer';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPerson;
  sports?: Maybe<Array<DirTrainerSport>>;
};


export type DirTrainerSportsArgs = {
  where?: Maybe<DirTrainerSportWhereInput>;
  orderBy?: Maybe<DirTrainerSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirTrainerSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC'
}

export type DirTrainerSport = {
  __typename?: 'DirTrainerSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer: DirTrainer;
  dirSport: DirSport;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
  organizations?: Maybe<Array<DirTrainerSportOrganization>>;
  categories?: Maybe<Array<DirTrainerSportCategory>>;
};


export type DirTrainerSportDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirTrainerSportOrganizationsArgs = {
  where?: Maybe<DirTrainerSportOrganizationWhereInput>;
  orderBy?: Maybe<DirTrainerSportOrganizationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirTrainerSportCategoriesArgs = {
  where?: Maybe<DirTrainerSportCategoryWhereInput>;
  orderBy?: Maybe<DirTrainerSportCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirTrainerSportOrganizationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  EmploymentDateAsc = 'employmentDate_ASC',
  EmploymentDateDesc = 'employmentDate_DESC',
  DismissalDateAsc = 'dismissalDate_ASC',
  DismissalDateDesc = 'dismissalDate_DESC'
}

export type DirTrainerSportOrganization = {
  __typename?: 'DirTrainerSportOrganization';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganization;
  clsPosition: ClassifierValue;
  employmentDate: Scalars['DateTime'];
  dismissalDate?: Maybe<Scalars['DateTime']>;
  sport: DirTrainerSport;
};

export enum DirTrainerSportCategoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  AssignmentDateAsc = 'assignmentDate_ASC',
  AssignmentDateDesc = 'assignmentDate_DESC',
  ValidUntilAsc = 'validUntil_ASC',
  ValidUntilDesc = 'validUntil_DESC',
  IsEndlessAsc = 'isEndless_ASC',
  IsEndlessDesc = 'isEndless_DESC'
}

export type DirTrainerSportCategory = {
  __typename?: 'DirTrainerSportCategory';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainerCategory: ClassifierValue;
  assignmentDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  sport: DirTrainerSport;
};

export enum DirAthleteSportOrganizationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  IsSelfTrainingAsc = 'isSelfTraining_ASC',
  IsSelfTrainingDesc = 'isSelfTraining_DESC',
  TrainingStartAsc = 'trainingStart_ASC',
  TrainingStartDesc = 'trainingStart_DESC',
  ExclusionDateAsc = 'exclusionDate_ASC',
  ExclusionDateDesc = 'exclusionDate_DESC'
}

export type DirAthleteSportOrganization = {
  __typename?: 'DirAthleteSportOrganization';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganization;
  clsTrainingStage: ClassifierValue;
  dirTrainer?: Maybe<DirTrainer>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  trainingStart: Scalars['DateTime'];
  exclusionDate?: Maybe<Scalars['DateTime']>;
};

export enum DirAthleteSportRankAndAwardOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  AssignmentDateAsc = 'assignmentDate_ASC',
  AssignmentDateDesc = 'assignmentDate_DESC',
  ConfirmDateAsc = 'confirmDate_ASC',
  ConfirmDateDesc = 'confirmDate_DESC',
  ValidUntilAsc = 'validUntil_ASC',
  ValidUntilDesc = 'validUntil_DESC'
}

export type DirAthleteSportRankAndAward = {
  __typename?: 'DirAthleteSportRankAndAward';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  sport: DirAthleteSport;
  clsRankAndAward: ClassifierValue;
  assignmentDate: Scalars['DateTime'];
  confirmDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  dirDocument?: Maybe<DirDocument>;
};

export type DirDocument = {
  __typename?: 'DirDocument';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  date: Scalars['DateTime'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  clsType?: Maybe<ClassifierValue>;
  file: File;
};

export type File = {
  __typename?: 'File';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  path: Scalars['String'];
};

export enum DirAthleteMedCertOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UpdatedAtRealAsc = 'updatedAtReal_ASC',
  UpdatedAtRealDesc = 'updatedAtReal_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  DisplayNumberAsc = 'displayNumber_ASC',
  DisplayNumberDesc = 'displayNumber_DESC',
  IssueDateAsc = 'issueDate_ASC',
  IssueDateDesc = 'issueDate_DESC',
  ValidUntilAsc = 'validUntil_ASC',
  ValidUntilDesc = 'validUntil_DESC',
  OrganizationNameAsc = 'organizationName_ASC',
  OrganizationNameDesc = 'organizationName_DESC',
  OrganizationAddressAsc = 'organizationAddress_ASC',
  OrganizationAddressDesc = 'organizationAddress_DESC',
  OrganizationPhoneAsc = 'organizationPhone_ASC',
  OrganizationPhoneDesc = 'organizationPhone_DESC',
  OrganizationEmailAsc = 'organizationEmail_ASC',
  OrganizationEmailDesc = 'organizationEmail_DESC',
  IsValidAsc = 'isValid_ASC',
  IsValidDesc = 'isValid_DESC',
  PhysicalExaminationDateAsc = 'physicalExaminationDate_ASC',
  PhysicalExaminationDateDesc = 'physicalExaminationDate_DESC',
  HasRestrictionsAsc = 'hasRestrictions_ASC',
  HasRestrictionsDesc = 'hasRestrictions_DESC'
}

export type DirAthleteMedCert = {
  __typename?: 'DirAthleteMedCert';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  updatedAtReal?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  dirAthlete: DirAthlete;
  issueDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName: Scalars['String'];
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid: Scalars['Boolean'];
  sports?: Maybe<Array<DirAthleteMedCertSport>>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};


export type DirAthleteMedCertSportsArgs = {
  where?: Maybe<DirAthleteMedCertSportWhereInput>;
  orderBy?: Maybe<DirAthleteMedCertSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirAthleteMedCertSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC'
}

export type DirAthleteMedCertSport = {
  __typename?: 'DirAthleteMedCertSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  medCert: DirAthleteMedCert;
  dirSport: DirSport;
  clsTrainingStage?: Maybe<ClassifierValue>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
};


export type DirAthleteMedCertSportDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirAthleteCompetitionResultOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  PointAsc = 'point_ASC',
  PointDesc = 'point_DESC',
  PointToAsc = 'pointTo_ASC',
  PointToDesc = 'pointTo_DESC',
  ResultAsc = 'result_ASC',
  ResultDesc = 'result_DESC',
  ScoreAsc = 'score_ASC',
  ScoreDesc = 'score_DESC',
  CompletedRankAsc = 'completedRank_ASC',
  CompletedRankDesc = 'completedRank_DESC'
}

export type DirAthleteCompetitionResult = {
  __typename?: 'DirAthleteCompetitionResult';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  noResultReason?: Maybe<ClassifierValue>;
  dirAthlete: DirAthlete;
  sportingEventProgramType: DirSportingEventProgramType;
  point?: Maybe<Scalars['Int']>;
  pointTo?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  completedRank?: Maybe<Scalars['String']>;
};

export type DirSportingEventProgramType = {
  __typename?: 'DirSportingEventProgramType';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEvent;
  minAge: Scalars['Int'];
  maxAge: Scalars['Int'];
  start?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  clsSportCategory?: Maybe<ClassifierValue>;
  resultsDocument?: Maybe<DirDocument>;
  resultDocuments?: Maybe<Array<DirDocument>>;
  clsAgeGroups?: Maybe<Array<ClassifierValue>>;
  discipline: DirSportDiscipline;
};


export type DirSportingEventProgramTypeResultDocumentsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventProgramTypeClsAgeGroupsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEvent = {
  __typename?: 'DirSportingEvent';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<Array<DirNationalTeam>>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<Array<DirRegion>>;
  dirFederalDistricts?: Maybe<Array<DirFederalDistrict>>;
  countries?: Maybe<Array<DirSportingEventCountry>>;
  dirRegion?: Maybe<DirRegion>;
  dirCountry?: Maybe<DirCountry>;
  dirForeignCity?: Maybe<DirForeignCity>;
  cost?: Maybe<DirSportingEventCost>;
  quota?: Maybe<DirSportingEventQuota>;
  quantity?: Maybe<DirSportingEventQuantity>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEvent>;
  children?: Maybe<Array<DirSportingEvent>>;
  proxy?: Maybe<DirSportingEvent>;
  changes?: Maybe<Array<DirSportingEvent>>;
  status: DirSportingEventStatus;
  statusHistory?: Maybe<Array<DirSportingEventStatusHistory>>;
  statuses?: Maybe<Array<DirSportingEventExtendedStatus>>;
  dirCalendar: DirCalendar;
  clsEventCategories?: Maybe<Array<ClassifierValue>>;
  sports?: Maybe<Array<DirSportingEventSport>>;
  ageGroups?: Maybe<Array<DirSportingEventAgeGroup>>;
  programTypes?: Maybe<Array<DirSportingEventProgramType>>;
  clsMinRang?: Maybe<ClassifierValue>;
  competitionCalendar?: Maybe<Array<DirSportingEventCompetitionCalendar>>;
  competitionReglaments?: Maybe<Array<DirDocument>>;
  competitionProtocols?: Maybe<Array<DirDocument>>;
  organizers?: Maybe<Array<DirOrganization>>;
  clsDirectionality?: Maybe<ClassifierValue>;
  refereeCollegiumDocuments?: Maybe<Array<DirDocument>>;
  clsSummarizingType?: Maybe<ClassifierValue>;
  clsDivisions?: Maybe<Array<ClassifierValue>>;
  clsEventStages?: Maybe<Array<ClassifierValue>>;
  requests?: Maybe<Array<DirSportingEventRequest>>;
  clsAvailableSportingQualifications?: Maybe<Array<ClassifierValue>>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValue>;
  pointsDistribution?: Maybe<Array<DirSportingEventPointDistribution>>;
  referees?: Maybe<Array<DirSportingEventReferee>>;
  refereeStatuses?: Maybe<Array<DirSportingEventRefereeStatus>>;
  credentialsCommitteeProtocolDocuments?: Maybe<Array<DirDocument>>;
  competitionProtocolFiles?: Maybe<Array<File>>;
  finalProtocolDocuments?: Maybe<Array<DirDocument>>;
  commissionAdmissionDecisions?: Maybe<Array<DirDocument>>;
  competitionFinalProtocols?: Maybe<Array<DirDocument>>;
  mainJuryReports?: Maybe<Array<DirDocument>>;
  athleteCallDocuments?: Maybe<Array<DirDocument>>;
  athleteCallNotifications?: Maybe<Array<DirSportingEventAthleteCallNotification>>;
  refereePersonalDataAgreements?: Maybe<Array<File>>;
  ageGroupMale?: Maybe<DirSportAgeGroup>;
  ageGroupFemale?: Maybe<DirSportAgeGroup>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValue>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValue>;
};


export type DirSportingEventDirNationalTeamsArgs = {
  where?: Maybe<DirNationalTeamWhereInput>;
  orderBy?: Maybe<DirNationalTeamOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventDirRegionsArgs = {
  where?: Maybe<DirRegionWhereInput>;
  orderBy?: Maybe<DirRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventDirFederalDistrictsArgs = {
  where?: Maybe<DirFederalDistrictWhereInput>;
  orderBy?: Maybe<DirFederalDistrictOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventCountriesArgs = {
  where?: Maybe<DirSportingEventCountryWhereInput>;
  orderBy?: Maybe<DirSportingEventCountryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventChildrenArgs = {
  where?: Maybe<DirSportingEventWhereInput>;
  orderBy?: Maybe<DirSportingEventOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventChangesArgs = {
  where?: Maybe<DirSportingEventWhereInput>;
  orderBy?: Maybe<DirSportingEventOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventStatusHistoryArgs = {
  where?: Maybe<DirSportingEventStatusHistoryWhereInput>;
  orderBy?: Maybe<DirSportingEventStatusHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventStatusesArgs = {
  where?: Maybe<DirSportingEventExtendedStatusWhereInput>;
  orderBy?: Maybe<DirSportingEventExtendedStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventClsEventCategoriesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventSportsArgs = {
  where?: Maybe<DirSportingEventSportWhereInput>;
  orderBy?: Maybe<DirSportingEventSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventAgeGroupsArgs = {
  where?: Maybe<DirSportingEventAgeGroupWhereInput>;
  orderBy?: Maybe<DirSportingEventAgeGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventProgramTypesArgs = {
  where?: Maybe<DirSportingEventProgramTypeWhereInput>;
  orderBy?: Maybe<DirSportingEventProgramTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventCompetitionCalendarArgs = {
  where?: Maybe<DirSportingEventCompetitionCalendarWhereInput>;
  orderBy?: Maybe<DirSportingEventCompetitionCalendarOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventCompetitionReglamentsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventCompetitionProtocolsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventOrganizersArgs = {
  where?: Maybe<DirOrganizationWhereInput>;
  orderBy?: Maybe<DirOrganizationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventRefereeCollegiumDocumentsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventClsDivisionsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventClsEventStagesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventRequestsArgs = {
  where?: Maybe<DirSportingEventRequestWhereInput>;
  orderBy?: Maybe<DirSportingEventRequestOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventClsAvailableSportingQualificationsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventPointsDistributionArgs = {
  where?: Maybe<DirSportingEventPointDistributionWhereInput>;
  orderBy?: Maybe<DirSportingEventPointDistributionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventRefereesArgs = {
  where?: Maybe<DirSportingEventRefereeWhereInput>;
  orderBy?: Maybe<DirSportingEventRefereeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventRefereeStatusesArgs = {
  where?: Maybe<DirSportingEventRefereeStatusWhereInput>;
  orderBy?: Maybe<DirSportingEventRefereeStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventCredentialsCommitteeProtocolDocumentsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventCompetitionProtocolFilesArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventFinalProtocolDocumentsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventCommissionAdmissionDecisionsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventCompetitionFinalProtocolsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventMainJuryReportsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventAthleteCallDocumentsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventAthleteCallNotificationsArgs = {
  where?: Maybe<DirSportingEventAthleteCallNotificationWhereInput>;
  orderBy?: Maybe<DirSportingEventAthleteCallNotificationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventRefereePersonalDataAgreementsArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirNationalTeamOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  ManTeamQuotaAsc = 'manTeamQuota_ASC',
  ManTeamQuotaDesc = 'manTeamQuota_DESC',
  WomanTeamQuotaAsc = 'womanTeamQuota_ASC',
  WomanTeamQuotaDesc = 'womanTeamQuota_DESC',
  SupportTeamQuotaAsc = 'supportTeamQuota_ASC',
  SupportTeamQuotaDesc = 'supportTeamQuota_DESC',
  AthletesCallAsc = 'athletesCall_ASC',
  AthletesCallDesc = 'athletesCall_DESC',
  PointAsc = 'point_ASC',
  PointDesc = 'point_DESC',
  ScoreAsc = 'score_ASC',
  ScoreDesc = 'score_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  IsNotifiedAsc = 'isNotified_ASC',
  IsNotifiedDesc = 'isNotified_DESC',
  AthleteQuotaByGenderAsc = 'athleteQuotaByGender_ASC',
  AthleteQuotaByGenderDesc = 'athleteQuotaByGender_DESC',
  AthleteTotalQuotaAsc = 'athleteTotalQuota_ASC',
  AthleteTotalQuotaDesc = 'athleteTotalQuota_DESC'
}

export type DirNationalTeam = {
  __typename?: 'DirNationalTeam';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota: Scalars['Int'];
  womanTeamQuota: Scalars['Int'];
  supportTeamQuota: Scalars['Int'];
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified: Scalars['Boolean'];
  athleteQuotaByGender: Scalars['Boolean'];
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status: DirNationalTeamStatus;
  statusHistory?: Maybe<Array<DirNationalTeamStatusHistory>>;
  statuses?: Maybe<Array<DirNationalTeamExtendedStatus>>;
  dirSportingEvent: DirSportingEvent;
  dirRegion: DirRegion;
  trainers?: Maybe<Array<DirNationalTeamTrainer>>;
  athleteGroups?: Maybe<Array<DirNationalTeamAthleteGroup>>;
  specialists?: Maybe<Array<DirNationalTeamMember>>;
  medics?: Maybe<Array<DirNationalTeamMember>>;
  delegationHeads?: Maybe<Array<DirNationalTeamMember>>;
  emailRecipients?: Maybe<Array<DirNationalTeamEmailRecipient>>;
  files?: Maybe<Array<File>>;
  university?: Maybe<DirOrganization>;
  refereePersonalDataAgreements?: Maybe<Array<File>>;
  arrivalDeparturePlan?: Maybe<Array<File>>;
  preliminaryQuantitativeOrder?: Maybe<Array<File>>;
};


export type DirNationalTeamStatusHistoryArgs = {
  where?: Maybe<DirNationalTeamStatusHistoryWhereInput>;
  orderBy?: Maybe<DirNationalTeamStatusHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamStatusesArgs = {
  where?: Maybe<DirNationalTeamExtendedStatusWhereInput>;
  orderBy?: Maybe<DirNationalTeamExtendedStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamTrainersArgs = {
  where?: Maybe<DirNationalTeamTrainerWhereInput>;
  orderBy?: Maybe<DirNationalTeamTrainerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamAthleteGroupsArgs = {
  where?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
  orderBy?: Maybe<DirNationalTeamAthleteGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamSpecialistsArgs = {
  where?: Maybe<DirNationalTeamMemberWhereInput>;
  orderBy?: Maybe<DirNationalTeamMemberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamMedicsArgs = {
  where?: Maybe<DirNationalTeamMemberWhereInput>;
  orderBy?: Maybe<DirNationalTeamMemberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamDelegationHeadsArgs = {
  where?: Maybe<DirNationalTeamMemberWhereInput>;
  orderBy?: Maybe<DirNationalTeamMemberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamEmailRecipientsArgs = {
  where?: Maybe<DirNationalTeamEmailRecipientWhereInput>;
  orderBy?: Maybe<DirNationalTeamEmailRecipientOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamFilesArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamRefereePersonalDataAgreementsArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamArrivalDeparturePlanArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamPreliminaryQuantitativeOrderArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamStatus = {
  __typename?: 'DirNationalTeamStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
  isDeprecated?: Maybe<Scalars['Boolean']>;
};

export enum DirNationalTeamStatusHistoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DirNationalTeamStatusHistory = {
  __typename?: 'DirNationalTeamStatusHistory';
  id: Scalars['UUID'];
  date: Scalars['DateTime'];
  status: DirNationalTeamStatus;
  user: User;
};

export enum DirNationalTeamExtendedStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC'
}

export type DirNationalTeamExtendedStatus = {
  __typename?: 'DirNationalTeamExtendedStatus';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  team: DirNationalTeam;
  value: DirNationalTeamStatus;
  file?: Maybe<File>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export enum DirNationalTeamTrainerOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  AdmissionDateAsc = 'admissionDate_ASC',
  AdmissionDateDesc = 'admissionDate_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  IsPrimaryAsc = 'isPrimary_ASC',
  IsPrimaryDesc = 'isPrimary_DESC',
  EntranceStatusAsc = 'entranceStatus_ASC',
  EntranceStatusDesc = 'entranceStatus_DESC'
}

export type DirNationalTeamTrainer = {
  __typename?: 'DirNationalTeamTrainer';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  team: DirNationalTeam;
  isPrimary?: Maybe<Scalars['Boolean']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
  dirTrainer: DirTrainer;
  clsPosition?: Maybe<ClassifierValue>;
};

export enum DirNationalTeamAthleteGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  MinAgeAsc = 'minAge_ASC',
  MinAgeDesc = 'minAge_DESC',
  MaxAgeAsc = 'maxAge_ASC',
  MaxAgeDesc = 'maxAge_DESC'
}

export type DirNationalTeamAthleteGroup = {
  __typename?: 'DirNationalTeamAthleteGroup';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  team: DirNationalTeam;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<Array<ClassifierValue>>;
  disciplines?: Maybe<Array<DirSportDiscipline>>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
  athletes?: Maybe<Array<DirNationalTeamAthlete>>;
};


export type DirNationalTeamAthleteGroupClsAgeGroupsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamAthleteGroupDisciplinesArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
  orderBy?: Maybe<DirSportDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamAthleteGroupDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirNationalTeamAthleteGroupAthletesArgs = {
  where?: Maybe<DirNationalTeamAthleteWhereInput>;
  orderBy?: Maybe<DirNationalTeamAthleteOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirNationalTeamAthleteOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  AdmissionDateAsc = 'admissionDate_ASC',
  AdmissionDateDesc = 'admissionDate_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  BestResultAsc = 'bestResult_ASC',
  BestResultDesc = 'bestResult_DESC',
  EntranceStatusAsc = 'entranceStatus_ASC',
  EntranceStatusDesc = 'entranceStatus_DESC'
}

export type DirNationalTeamAthlete = {
  __typename?: 'DirNationalTeamAthlete';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  group: DirNationalTeamAthleteGroup;
  dirAthlete: DirAthlete;
  bestResult?: Maybe<Scalars['String']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
};

export enum DirNationalTeamMemberOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  AdmissionDateAsc = 'admissionDate_ASC',
  AdmissionDateDesc = 'admissionDate_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  LastnameAsc = 'lastname_ASC',
  LastnameDesc = 'lastname_DESC',
  FirstnameAsc = 'firstname_ASC',
  FirstnameDesc = 'firstname_DESC',
  PatronymicAsc = 'patronymic_ASC',
  PatronymicDesc = 'patronymic_DESC',
  BirthdayAsc = 'birthday_ASC',
  BirthdayDesc = 'birthday_DESC',
  EntranceStatusAsc = 'entranceStatus_ASC',
  EntranceStatusDesc = 'entranceStatus_DESC'
}

export type DirNationalTeamMember = {
  __typename?: 'DirNationalTeamMember';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic: Scalars['String'];
  birthday: Scalars['DateTime'];
  clsPosition?: Maybe<ClassifierValue>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
};

export enum DirNationalTeamEmailRecipientOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IsNotifiedAsc = 'isNotified_ASC',
  IsNotifiedDesc = 'isNotified_DESC',
  IsNotificationRequiredAsc = 'isNotificationRequired_ASC',
  IsNotificationRequiredDesc = 'isNotificationRequired_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC'
}

export type DirNationalTeamEmailRecipient = {
  __typename?: 'DirNationalTeamEmailRecipient';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  isNotified: Scalars['Boolean'];
  isNotificationRequired: Scalars['Boolean'];
  team: DirNationalTeam;
  email: Scalars['String'];
  dirPerson?: Maybe<DirPerson>;
  comment?: Maybe<Scalars['String']>;
};

export enum FileOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PathAsc = 'path_ASC',
  PathDesc = 'path_DESC'
}

export enum DirFederalDistrictOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC'
}

export enum DirSportingEventCountryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC'
}

export type DirSportingEventCountry = {
  __typename?: 'DirSportingEventCountry';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEvent;
  dirCountry: DirCountry;
  dirForeignCities?: Maybe<Array<DirForeignCity>>;
};


export type DirSportingEventCountryDirForeignCitiesArgs = {
  where?: Maybe<DirForeignCityWhereInput>;
  orderBy?: Maybe<DirForeignCityOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirCountry = {
  __typename?: 'DirCountry';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  dirForeignCities?: Maybe<Array<DirForeignCity>>;
};


export type DirCountryDirForeignCitiesArgs = {
  where?: Maybe<DirForeignCityWhereInput>;
  orderBy?: Maybe<DirForeignCityOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirForeignCityOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC'
}

export type DirForeignCity = {
  __typename?: 'DirForeignCity';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  dirCountry: DirCountry;
};

export type DirSportingEventCost = {
  __typename?: 'DirSportingEventCost';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  clsFinancingTypes?: Maybe<Array<ClassifierValue>>;
};


export type DirSportingEventCostClsFinancingTypesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventQuota = {
  __typename?: 'DirSportingEventQuota';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  athletesMan?: Maybe<Scalars['Int']>;
  athletesWoman?: Maybe<Scalars['Int']>;
  trainers?: Maybe<Scalars['Int']>;
  referees?: Maybe<Scalars['Int']>;
  administration?: Maybe<Scalars['Int']>;
  medicalStaff?: Maybe<Scalars['Int']>;
  specialists?: Maybe<Scalars['Int']>;
  teams?: Maybe<Scalars['Int']>;
  clsParticipatingOrgCategories?: Maybe<Array<ClassifierValue>>;
  clsTeamComposition?: Maybe<ClassifierValue>;
};


export type DirSportingEventQuotaClsParticipatingOrgCategoriesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventQuantity = {
  __typename?: 'DirSportingEventQuantity';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  participantAthletesTotal?: Maybe<Scalars['Int']>;
  refereesTotal?: Maybe<Scalars['Int']>;
  staffTotal?: Maybe<Scalars['Int']>;
  audienceTotal?: Maybe<Scalars['Int']>;
};

export enum DirSportingEventOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  OldRegistryNumberAsc = 'oldRegistryNumber_ASC',
  OldRegistryNumberDesc = 'oldRegistryNumber_DESC',
  AthletesQuotaNotCorrespondingByQualificationAsc = 'athletesQuotaNotCorrespondingByQualification_ASC',
  AthletesQuotaNotCorrespondingByQualificationDesc = 'athletesQuotaNotCorrespondingByQualification_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  VenueAsc = 'venue_ASC',
  VenueDesc = 'venue_DESC',
  ObjectAsc = 'object_ASC',
  ObjectDesc = 'object_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  ProperNounAsc = 'properNoun_ASC',
  ProperNounDesc = 'properNoun_DESC',
  AgeGroupAsc = 'ageGroup_ASC',
  AgeGroupDesc = 'ageGroup_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC',
  MinManAgeAsc = 'minManAge_ASC',
  MinManAgeDesc = 'minManAge_DESC',
  MaxManAgeAsc = 'maxManAge_ASC',
  MaxManAgeDesc = 'maxManAge_DESC',
  MinManBirthYearAsc = 'minManBirthYear_ASC',
  MinManBirthYearDesc = 'minManBirthYear_DESC',
  MaxManBirthYearAsc = 'maxManBirthYear_ASC',
  MaxManBirthYearDesc = 'maxManBirthYear_DESC',
  MinWomanAgeAsc = 'minWomanAge_ASC',
  MinWomanAgeDesc = 'minWomanAge_DESC',
  MaxWomanAgeAsc = 'maxWomanAge_ASC',
  MaxWomanAgeDesc = 'maxWomanAge_DESC',
  MinWomanBirthYearAsc = 'minWomanBirthYear_ASC',
  MinWomanBirthYearDesc = 'minWomanBirthYear_DESC',
  MaxWomanBirthYearAsc = 'maxWomanBirthYear_ASC',
  MaxWomanBirthYearDesc = 'maxWomanBirthYear_DESC',
  MembersQuotaAsc = 'membersQuota_ASC',
  MembersQuotaDesc = 'membersQuota_DESC',
  RefereesQuotaAsc = 'refereesQuota_ASC',
  RefereesQuotaDesc = 'refereesQuota_DESC',
  NonresidentRefereesQuotaAsc = 'nonresidentRefereesQuota_ASC',
  NonresidentRefereesQuotaDesc = 'nonresidentRefereesQuota_DESC',
  DaysBeforeStartToAddProtocolsAsc = 'daysBeforeStartToAddProtocols_ASC',
  DaysBeforeStartToAddProtocolsDesc = 'daysBeforeStartToAddProtocols_DESC',
  AthletesTrainersRefereesSpecialistsCountAsc = 'athletesTrainersRefereesSpecialistsCount_ASC',
  AthletesTrainersRefereesSpecialistsCountDesc = 'athletesTrainersRefereesSpecialistsCount_DESC',
  EkpAsc = 'ekp_ASC',
  EkpDesc = 'ekp_DESC',
  AthletesQuotaNotCorrespondingByAgeAsc = 'athletesQuotaNotCorrespondingByAge_ASC',
  AthletesQuotaNotCorrespondingByAgeDesc = 'athletesQuotaNotCorrespondingByAge_DESC',
  FederalBudgetFinancingAsc = 'federalBudgetFinancing_ASC',
  FederalBudgetFinancingDesc = 'federalBudgetFinancing_DESC',
  IncludingTrainingActivitiesAsc = 'includingTrainingActivities_ASC',
  IncludingTrainingActivitiesDesc = 'includingTrainingActivities_DESC',
  IsRussiaAsc = 'isRussia_ASC',
  IsRussiaDesc = 'isRussia_DESC',
  IsImportantAsc = 'isImportant_ASC',
  IsImportantDesc = 'isImportant_DESC',
  IsOlympicGamesPreparationAsc = 'isOlympicGamesPreparation_ASC',
  IsOlympicGamesPreparationDesc = 'isOlympicGamesPreparation_DESC',
  IsOrganizerOrParticipantAsc = 'isOrganizerOrParticipant_ASC',
  IsOrganizerOrParticipantDesc = 'isOrganizerOrParticipant_DESC',
  IsAgeRestrictedAsc = 'isAgeRestricted_ASC',
  IsAgeRestrictedDesc = 'isAgeRestricted_DESC',
  IsSportingQualificationRangeOrSpecificValuesAsc = 'isSportingQualificationRangeOrSpecificValues_ASC',
  IsSportingQualificationRangeOrSpecificValuesDesc = 'isSportingQualificationRangeOrSpecificValues_DESC'
}

export type DirSportingEventStatus = {
  __typename?: 'DirSportingEventStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum DirSportingEventStatusHistoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DirSportingEventStatusHistory = {
  __typename?: 'DirSportingEventStatusHistory';
  id: Scalars['UUID'];
  date: Scalars['DateTime'];
  status: DirSportingEventStatus;
  user: User;
};

export enum DirSportingEventExtendedStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC'
}

export type DirSportingEventExtendedStatus = {
  __typename?: 'DirSportingEventExtendedStatus';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  event: DirSportingEvent;
  value: DirSportingEventExtendedStatusValue;
  file?: Maybe<File>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventExtendedStatusValue = {
  __typename?: 'DirSportingEventExtendedStatusValue';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type DirCalendar = {
  __typename?: 'DirCalendar';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarType>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValue>;
  clsSeason?: Maybe<ClassifierValue>;
  clsCalendarCategory?: Maybe<ClassifierValue>;
  refusedRegions?: Maybe<Array<DirRefusedRegion>>;
  returnedRegions?: Maybe<Array<DirReturnedRegion>>;
  venueCompetition?: Maybe<VenueCompetition>;
  sportCompetitionRegulationDocument?: Maybe<File>;
  dirSportEventRegulations?: Maybe<Array<DirSportEventRegulations>>;
  roivForNotificationProtocol?: Maybe<Array<NotificationProtocol>>;
  regulationChanges?: Maybe<Array<File>>;
  stageNumber?: Maybe<ClassifierValue>;
  dirSports?: Maybe<Array<DirSport>>;
  signedReports?: Maybe<Array<DirCalendarSignedReport>>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<Array<ReqPartFromStageTwo>>;
  quotasForStageTwoBySport?: Maybe<Array<QuotaForStageTwoBySport>>;
  quantyRequestsStageTwo?: Maybe<Array<QuantyRequestStageTwo>>;
};


export type DirCalendarRefusedRegionsArgs = {
  where?: Maybe<DirRefusedRegionWhereInput>;
  orderBy?: Maybe<DirRefusedRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirCalendarReturnedRegionsArgs = {
  where?: Maybe<DirReturnedRegionWhereInput>;
  orderBy?: Maybe<DirReturnedRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirCalendarDirSportEventRegulationsArgs = {
  where?: Maybe<DirSportEventRegulationsWhereInput>;
  orderBy?: Maybe<DirSportEventRegulationsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirCalendarRoivForNotificationProtocolArgs = {
  where?: Maybe<NotificationProtocolWhereInput>;
  orderBy?: Maybe<NotificationProtocolOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirCalendarRegulationChangesArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirCalendarDirSportsArgs = {
  where?: Maybe<DirSportWhereInput>;
  orderBy?: Maybe<DirSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirCalendarSignedReportsArgs = {
  where?: Maybe<DirCalendarSignedReportWhereInput>;
  orderBy?: Maybe<DirCalendarSignedReportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirCalendarReqPartFromStageTwoArgs = {
  where?: Maybe<ReqPartFromStageTwoWhereInput>;
  orderBy?: Maybe<ReqPartFromStageTwoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirCalendarQuotasForStageTwoBySportArgs = {
  where?: Maybe<QuotaForStageTwoBySportWhereInput>;
  orderBy?: Maybe<QuotaForStageTwoBySportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirCalendarQuantyRequestsStageTwoArgs = {
  where?: Maybe<QuantyRequestStageTwoWhereInput>;
  orderBy?: Maybe<QuantyRequestStageTwoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirCalendarType = {
  __typename?: 'DirCalendarType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum DirRefusedRegionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC'
}

export type DirRefusedRegion = {
  __typename?: 'DirRefusedRegion';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  comment?: Maybe<Scalars['String']>;
  statement?: Maybe<File>;
  dirRegion?: Maybe<DirRegion>;
  dirCalendar: DirCalendar;
};

export enum DirReturnedRegionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC'
}

export type DirReturnedRegion = {
  __typename?: 'DirReturnedRegion';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  statement?: Maybe<File>;
  dirRegion?: Maybe<DirRegion>;
  dirCalendar: DirCalendar;
};

export type VenueCompetition = {
  __typename?: 'VenueCompetition';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  dirCalendar: DirCalendar;
  dirDocument?: Maybe<DirDocument>;
  venueCompDecisionDoc?: Maybe<DirDocument>;
  venueCompRegulationDocAdds?: Maybe<Array<File>>;
  venueCompDecisionDocAdds?: Maybe<Array<File>>;
  endDate?: Maybe<Scalars['DateTime']>;
  emailSenderFCPSR?: Maybe<User>;
  venueCompApplications?: Maybe<Array<VenueCompApplication>>;
};


export type VenueCompetitionVenueCompRegulationDocAddsArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type VenueCompetitionVenueCompDecisionDocAddsArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type VenueCompetitionVenueCompApplicationsArgs = {
  where?: Maybe<VenueCompApplicationWhereInput>;
  orderBy?: Maybe<VenueCompApplicationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum VenueCompApplicationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  NotificationSentToFcpsrAsc = 'notificationSentToFCPSR_ASC',
  NotificationSentToFcpsrDesc = 'notificationSentToFCPSR_DESC'
}

export type VenueCompApplication = {
  __typename?: 'VenueCompApplication';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  venueCompetition: VenueCompetition;
  dirRegion: DirRegion;
  number: Scalars['Int'];
  applicationFiles?: Maybe<Array<File>>;
  notificationSentToFCPSR: Scalars['Boolean'];
};


export type VenueCompApplicationApplicationFilesArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportEventRegulationsOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC'
}

export type DirSportEventRegulations = {
  __typename?: 'DirSportEventRegulations';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirCalendar?: Maybe<DirCalendar>;
  regulationsDocument?: Maybe<File>;
  regulationsAmendedDocument?: Maybe<File>;
  regulationsAgreedDocument?: Maybe<File>;
  archive?: Maybe<Archive>;
  dirSport?: Maybe<DirSport>;
  status?: Maybe<Array<DirSportingEventRegulationStatus>>;
  fcpsrAddRegulationsDoc?: Maybe<User>;
};


export type DirSportEventRegulationsStatusArgs = {
  where?: Maybe<DirSportingEventRegulationStatusWhereInput>;
  orderBy?: Maybe<DirSportingEventRegulationStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportingEventRegulationStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC'
}

export type DirSportingEventRegulationStatus = {
  __typename?: 'DirSportingEventRegulationStatus';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  regulation?: Maybe<DirSportEventRegulations>;
  value: DirSportingEventRegulationStatusValue;
  file?: Maybe<File>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRegulationStatusValue = {
  __typename?: 'DirSportingEventRegulationStatusValue';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum NotificationProtocolOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC'
}

export type NotificationProtocol = {
  __typename?: 'NotificationProtocol';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirCalendar: DirCalendar;
  dirRegion: DirRegion;
  dirSport: DirSport;
  forNotificationROIVSpecialists?: Maybe<Array<User>>;
};


export type NotificationProtocolForNotificationRoivSpecialistsArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum UserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PasswordAsc = 'password_ASC',
  PasswordDesc = 'password_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC'
}

export enum DirCalendarSignedReportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC'
}

export type DirCalendarSignedReport = {
  __typename?: 'DirCalendarSignedReport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirCalendarSignedReport: DirCalendar;
  dirCalendar: DirCalendar;
  dirRegion?: Maybe<DirRegion>;
  files?: Maybe<Array<File>>;
};


export type DirCalendarSignedReportFilesArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum ReqPartFromStageTwoOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ReqPartFromStageTwo = {
  __typename?: 'ReqPartFromStageTwo';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  region: DirRegion;
  dirCalendar: DirCalendar;
  roivSpecialistsForNotification?: Maybe<Array<User>>;
  filesROIVStageTwo?: Maybe<Array<File>>;
};


export type ReqPartFromStageTwoRoivSpecialistsForNotificationArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ReqPartFromStageTwoFilesRoivStageTwoArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum QuotaForStageTwoBySportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  QuotaMaleAthlStageTwoAsc = 'quotaMaleAthlStageTwo_ASC',
  QuotaMaleAthlStageTwoDesc = 'quotaMaleAthlStageTwo_DESC',
  QuotaWomenAthlStageTwoAsc = 'quotaWomenAthlStageTwo_ASC',
  QuotaWomenAthlStageTwoDesc = 'quotaWomenAthlStageTwo_DESC',
  QuotaAccompStageTwoAsc = 'quotaAccompStageTwo_ASC',
  QuotaAccompStageTwoDesc = 'quotaAccompStageTwo_DESC'
}

export type QuotaForStageTwoBySport = {
  __typename?: 'QuotaForStageTwoBySport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  dirCalendar: DirCalendar;
  dirSport: DirSport;
  quotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo?: Maybe<Scalars['Int']>;
};

export enum QuantyRequestStageTwoOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegionQuotaMaleAthlStageTwoAsc = 'regionQuotaMaleAthlStageTwo_ASC',
  RegionQuotaMaleAthlStageTwoDesc = 'regionQuotaMaleAthlStageTwo_DESC',
  RegionQuotaWomenAthlStageTwoAsc = 'regionQuotaWomenAthlStageTwo_ASC',
  RegionQuotaWomenAthlStageTwoDesc = 'regionQuotaWomenAthlStageTwo_DESC',
  RegionQuotaAccompStageTwoAsc = 'regionQuotaAccompStageTwo_ASC',
  RegionQuotaAccompStageTwoDesc = 'regionQuotaAccompStageTwo_DESC'
}

export type QuantyRequestStageTwo = {
  __typename?: 'QuantyRequestStageTwo';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  dirCalendar: DirCalendar;
  dirSport: DirSport;
  dirRegion: DirRegion;
  regionQuotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo?: Maybe<Scalars['Int']>;
};

export enum DirSportingEventSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC'
}

export type DirSportingEventSport = {
  __typename?: 'DirSportingEventSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEvent;
  dirSport: DirSport;
  disciplines?: Maybe<Array<DirSportDiscipline>>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
};


export type DirSportingEventSportDisciplinesArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
  orderBy?: Maybe<DirSportDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventSportDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportingEventAgeGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  MinAgeAsc = 'minAge_ASC',
  MinAgeDesc = 'minAge_DESC',
  MaxAgeAsc = 'maxAge_ASC',
  MaxAgeDesc = 'maxAge_DESC'
}

export type DirSportingEventAgeGroup = {
  __typename?: 'DirSportingEventAgeGroup';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEvent;
  clsAgeGroup?: Maybe<ClassifierValue>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export enum DirSportingEventProgramTypeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  MinAgeAsc = 'minAge_ASC',
  MinAgeDesc = 'minAge_DESC',
  MaxAgeAsc = 'maxAge_ASC',
  MaxAgeDesc = 'maxAge_DESC',
  StartAsc = 'start_ASC',
  StartDesc = 'start_DESC',
  DivisionAsc = 'division_ASC',
  DivisionDesc = 'division_DESC'
}

export enum DirSportingEventCompetitionCalendarOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  DayAsc = 'day_ASC',
  DayDesc = 'day_DESC',
  TimeAsc = 'time_ASC',
  TimeDesc = 'time_DESC',
  MembersAsc = 'members_ASC',
  MembersDesc = 'members_DESC'
}

export type DirSportingEventCompetitionCalendar = {
  __typename?: 'DirSportingEventCompetitionCalendar';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEvent;
  day?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  discipline?: Maybe<DirSportDiscipline>;
};

export enum DirDocumentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC'
}

export enum DirSportingEventRequestOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  OrderNumberAsc = 'orderNumber_ASC',
  OrderNumberDesc = 'orderNumber_DESC'
}

export type DirSportingEventRequest = {
  __typename?: 'DirSportingEventRequest';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  event: DirSportingEvent;
  orderNumber: Scalars['Int'];
  type: DirSportingEventRequestType;
  statuses?: Maybe<Array<DirSportingEventRequestStatus>>;
};


export type DirSportingEventRequestStatusesArgs = {
  where?: Maybe<DirSportingEventRequestStatusWhereInput>;
  orderBy?: Maybe<DirSportingEventRequestStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventRequestType = {
  __typename?: 'DirSportingEventRequestType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum DirSportingEventRequestStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC'
}

export type DirSportingEventRequestStatus = {
  __typename?: 'DirSportingEventRequestStatus';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  request: DirSportingEventRequest;
  value: DirSportingEventRequestStatusValue;
  file?: Maybe<File>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRequestStatusValue = {
  __typename?: 'DirSportingEventRequestStatusValue';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum DirSportingEventPointDistributionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  MinAgeAsc = 'minAge_ASC',
  MinAgeDesc = 'minAge_DESC',
  MaxAgeAsc = 'maxAge_ASC',
  MaxAgeDesc = 'maxAge_DESC',
  LastPlacesPointsAsc = 'lastPlacesPoints_ASC',
  LastPlacesPointsDesc = 'lastPlacesPoints_DESC',
  LastPlacesStartAsc = 'lastPlacesStart_ASC',
  LastPlacesStartDesc = 'lastPlacesStart_DESC'
}

export type DirSportingEventPointDistribution = {
  __typename?: 'DirSportingEventPointDistribution';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  event: DirSportingEvent;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  lastPlacesPoints?: Maybe<Scalars['Int']>;
  lastPlacesStart?: Maybe<Scalars['Int']>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
  disciplines?: Maybe<Array<DirSportDiscipline>>;
  clsAgeGroup?: Maybe<ClassifierValue>;
  places?: Maybe<Array<DirSportingEventPointDistributionPlace>>;
};


export type DirSportingEventPointDistributionDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventPointDistributionDisciplinesArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
  orderBy?: Maybe<DirSportDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DirSportingEventPointDistributionPlacesArgs = {
  where?: Maybe<DirSportingEventPointDistributionPlaceWhereInput>;
  orderBy?: Maybe<DirSportingEventPointDistributionPlaceOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportingEventPointDistributionPlaceOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PlaceAsc = 'place_ASC',
  PlaceDesc = 'place_DESC',
  PointsAsc = 'points_ASC',
  PointsDesc = 'points_DESC'
}

export type DirSportingEventPointDistributionPlace = {
  __typename?: 'DirSportingEventPointDistributionPlace';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  pointDistribution: DirSportingEventPointDistribution;
  place: Scalars['Int'];
  points: Scalars['Int'];
};

export enum DirSportingEventRefereeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  IsPrimaryAsc = 'isPrimary_ASC',
  IsPrimaryDesc = 'isPrimary_DESC',
  IsNonresidentAsc = 'isNonresident_ASC',
  IsNonresidentDesc = 'isNonresident_DESC'
}

export type DirSportingEventReferee = {
  __typename?: 'DirSportingEventReferee';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEvent;
  dirReferee: DirReferee;
  isPrimary: Scalars['Boolean'];
  clsRefereePosition: ClassifierValue;
  isNonresident: Scalars['Boolean'];
};

export type DirReferee = {
  __typename?: 'DirReferee';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPerson;
  sports?: Maybe<Array<DirRefereeSport>>;
};


export type DirRefereeSportsArgs = {
  where?: Maybe<DirRefereeSportWhereInput>;
  orderBy?: Maybe<DirRefereeSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirRefereeSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  WorkExperienceAsc = 'workExperience_ASC',
  WorkExperienceDesc = 'workExperience_DESC'
}

export type DirRefereeSport = {
  __typename?: 'DirRefereeSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee: DirReferee;
  dirSport: DirSport;
  workExperience?: Maybe<Scalars['Int']>;
  categories?: Maybe<Array<DirRefereeSportCategory>>;
};


export type DirRefereeSportCategoriesArgs = {
  where?: Maybe<DirRefereeSportCategoryWhereInput>;
  orderBy?: Maybe<DirRefereeSportCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirRefereeSportCategoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  AssignmentDateAsc = 'assignmentDate_ASC',
  AssignmentDateDesc = 'assignmentDate_DESC',
  ValidUntilAsc = 'validUntil_ASC',
  ValidUntilDesc = 'validUntil_DESC',
  IsEndlessAsc = 'isEndless_ASC',
  IsEndlessDesc = 'isEndless_DESC',
  ConfirmationDateAsc = 'confirmationDate_ASC',
  ConfirmationDateDesc = 'confirmationDate_DESC'
}

export type DirRefereeSportCategory = {
  __typename?: 'DirRefereeSportCategory';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  sport: DirRefereeSport;
  clsRefereeCategory: ClassifierValue;
  assignmentDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  confirmationDate?: Maybe<Scalars['DateTime']>;
  dirDocuments?: Maybe<Array<DirDocument>>;
};


export type DirRefereeSportCategoryDirDocumentsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportingEventRefereeStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC'
}

export type DirSportingEventRefereeStatus = {
  __typename?: 'DirSportingEventRefereeStatus';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  event: DirSportingEvent;
  value: DirSportingEventRefereeStatusValue;
  file?: Maybe<File>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRefereeStatusValue = {
  __typename?: 'DirSportingEventRefereeStatusValue';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum DirSportingEventAthleteCallNotificationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DirSportingEventAthleteCallNotification = {
  __typename?: 'DirSportingEventAthleteCallNotification';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  dirSportingEvent: DirSportingEvent;
  document: DirDocument;
  teams?: Maybe<Array<DirSportingEventAthleteCallNotificationTeam>>;
};


export type DirSportingEventAthleteCallNotificationTeamsArgs = {
  where?: Maybe<DirSportingEventAthleteCallNotificationTeamWhereInput>;
  orderBy?: Maybe<DirSportingEventAthleteCallNotificationTeamOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportingEventAthleteCallNotificationTeamOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IsNotifiedAsc = 'isNotified_ASC',
  IsNotifiedDesc = 'isNotified_DESC',
  IsNotificationRequiredAsc = 'isNotificationRequired_ASC',
  IsNotificationRequiredDesc = 'isNotificationRequired_DESC'
}

export type DirSportingEventAthleteCallNotificationTeam = {
  __typename?: 'DirSportingEventAthleteCallNotificationTeam';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  notification: DirSportingEventAthleteCallNotification;
  ditNationalTeam: DirNationalTeam;
  isNotified: Scalars['Boolean'];
  isNotificationRequired: Scalars['Boolean'];
};

export type DirPersonConfirmStatus = {
  __typename?: 'DirPersonConfirmStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export enum DirCountryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC'
}

export enum DirPersonPhoneOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  OperatorCodeAsc = 'operatorCode_ASC',
  OperatorCodeDesc = 'operatorCode_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  AdditionalNumberAsc = 'additionalNumber_ASC',
  AdditionalNumberDesc = 'additionalNumber_DESC'
}

export type DirPersonPhone = {
  __typename?: 'DirPersonPhone';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: ClassifierValue;
  dirPerson: DirPerson;
};

export enum DirPersonAdditionalRegionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  IsRussiaAsc = 'isRussia_ASC',
  IsRussiaDesc = 'isRussia_DESC'
}

export type DirPersonAdditionalRegion = {
  __typename?: 'DirPersonAdditionalRegion';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegion>;
  dirForeignCity?: Maybe<DirForeignCity>;
  dirCountry?: Maybe<DirCountry>;
  dirPerson: DirPerson;
};

export enum DirPersonMergeTaskOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC'
}

export type DirPersonMergeTask = {
  __typename?: 'DirPersonMergeTask';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  status: TaskStatus;
  comment?: Maybe<Scalars['String']>;
  primary: DirPerson;
  duplicate: DirPerson;
};

export type TaskStatus = {
  __typename?: 'TaskStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export enum DirPersonAddressOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  PlaceAsc = 'place_ASC',
  PlaceDesc = 'place_DESC',
  StreetAsc = 'street_ASC',
  StreetDesc = 'street_DESC',
  HouseAsc = 'house_ASC',
  HouseDesc = 'house_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC'
}

export type DirPersonAddress = {
  __typename?: 'DirPersonAddress';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPerson;
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type: AddressType;
};

export type RegionalMinsportProfile = {
  __typename?: 'RegionalMinsportProfile';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  dirRegion?: Maybe<DirRegion>;
  dirFederalDistrict?: Maybe<DirFederalDistrict>;
};

export type RegionalSchoolProfile = {
  __typename?: 'RegionalSchoolProfile';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirOrganization: DirOrganization;
};

export type RegionalAdministratorProfile = {
  __typename?: 'RegionalAdministratorProfile';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirRegion: DirRegion;
};

export type RoivSpecialistProfile = {
  __typename?: 'RoivSpecialistProfile';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirRegion: DirRegion;
};

export type FcpsrSportingEventOrganizerProfile = {
  __typename?: 'FcpsrSportingEventOrganizerProfile';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  sportingEvents?: Maybe<Array<DirSportingEvent>>;
  dirCalendars?: Maybe<Array<DirCalendar>>;
  dirSports?: Maybe<Array<DirSport>>;
};


export type FcpsrSportingEventOrganizerProfileSportingEventsArgs = {
  where?: Maybe<DirSportingEventWhereInput>;
  orderBy?: Maybe<DirSportingEventOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type FcpsrSportingEventOrganizerProfileDirCalendarsArgs = {
  where?: Maybe<DirCalendarWhereInput>;
  orderBy?: Maybe<DirCalendarOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type FcpsrSportingEventOrganizerProfileDirSportsArgs = {
  where?: Maybe<DirSportWhereInput>;
  orderBy?: Maybe<DirSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirCalendarOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC',
  YearAsc = 'year_ASC',
  YearDesc = 'year_DESC',
  EkpMinsportNumberAsc = 'ekpMinsportNumber_ASC',
  EkpMinsportNumberDesc = 'ekpMinsportNumber_DESC',
  DateAcceptQuotasAndFilesStageTwoAsc = 'dateAcceptQuotasAndFilesStageTwo_ASC',
  DateAcceptQuotasAndFilesStageTwoDesc = 'dateAcceptQuotasAndFilesStageTwo_DESC'
}

export type GskRefereeProfile = {
  __typename?: 'GskRefereeProfile';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirSportingEvents?: Maybe<Array<DirSportingEvent>>;
};


export type GskRefereeProfileDirSportingEventsArgs = {
  where?: Maybe<DirSportingEventWhereInput>;
  orderBy?: Maybe<DirSportingEventOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum UserSupportRequestOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  RequestAsc = 'request_ASC',
  RequestDesc = 'request_DESC',
  IsNotifiedAsc = 'isNotified_ASC',
  IsNotifiedDesc = 'isNotified_DESC'
}

export type UserSupportRequest = {
  __typename?: 'UserSupportRequest';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  number: Scalars['Int'];
  user?: Maybe<User>;
  operator?: Maybe<User>;
  request: Scalars['String'];
  requestType: UserSupportRequestType;
  file?: Maybe<File>;
  isNotified: Scalars['Boolean'];
};

export type UserSupportRequestType = {
  __typename?: 'UserSupportRequestType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UserRoleWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export enum UserRoleOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  CanSelectAsc = 'canSelect_ASC',
  CanSelectDesc = 'canSelect_DESC'
}

export type RegularUserWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  phone?: Maybe<Scalars['String']>;
};

export enum RegularUserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  LastnameAsc = 'lastname_ASC',
  LastnameDesc = 'lastname_DESC',
  FirstnameAsc = 'firstname_ASC',
  FirstnameDesc = 'firstname_DESC',
  PatronymicAsc = 'patronymic_ASC',
  PatronymicDesc = 'patronymic_DESC',
  BirthdayAsc = 'birthday_ASC',
  BirthdayDesc = 'birthday_DESC',
  IsMaleAsc = 'isMale_ASC',
  IsMaleDesc = 'isMale_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC'
}

export type SystemUserWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum SystemUserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RegionalMinsportProfileWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum RegionalMinsportProfileOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RegionalSchoolProfileWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum RegionalSchoolProfileOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC'
}

export type NotificationProtocolWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type RegionalAdministratorProfileWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum RegionalAdministratorProfileOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC'
}

export type RoivSpecialistProfileWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum RoivSpecialistProfileOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC'
}

export type FcpsrSportingEventOrganizerProfileWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum FcpsrSportingEventOrganizerProfileOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC'
}

export type DirSportingEventRegulationStatusValueWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum DirSportingEventRegulationStatusValueOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DirSportingEventRegulationStatusWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirRefusedRegionWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirReturnedRegionWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirSportEventRegulationsWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type GskRefereeProfileWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum GskRefereeProfileOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC'
}

export type UserSupportRequestWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type UserSupportRequestTypeWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum UserSupportRequestTypeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type FieldHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type ArchiveWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum ArchiveOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC'
}

export type ClassifierWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum ClassifierOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type ClassifierValueWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirRegionWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  roivOfficialEmail?: Maybe<Scalars['String']>;
};

export type DirFederalDistrictWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
};

export type DirForeignCityWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirCountryWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
};

export type DirOrganizationWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  ogrn?: Maybe<Scalars['String']>;
};

export type DirOrganizationSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirOrganizationAddressWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirOrganizationPhoneWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirOrganizationEmailWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirPersonWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
};

export type DirPersonPhoneWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirPersonConfirmStatusWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum DirPersonConfirmStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DirPersonAdditionalRegionWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirPersonMergeTaskWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirPersonAddressWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
};

export type DirSportDisciplineWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportDisciplineGroupWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportAgeGroupWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportAgeGroupParticipationRequirementsWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirSportTrainingStageWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportBaseRegionWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export enum DirAthleteOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC'
}

export type DirAthleteSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteSportOrganizationWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteSportRankAndAwardWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteMedCertWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
};

export type DirAthleteMedCertSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteCompetitionResultWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirTrainerWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirTrainerSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirTrainerSportOrganizationWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirTrainerSportCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirRefereeWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export enum DirRefereeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC'
}

export type DirRefereeSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirRefereeSportCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type QuantyRequestStageTwoWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirSportingEventAthleteCallNotificationWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirSportingEventAthleteCallNotificationTeamWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirSportingEventCountryWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventAgeGroupWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventStatusWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum DirSportingEventStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DirSportingEventStatusHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirSportingEventExtendedStatusWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirSportingEventExtendedStatusValueWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum DirSportingEventExtendedStatusValueOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DirSportingEventProgramTypeWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirGenderAndAgeGroupsWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirGenderAndAgeGroups = {
  __typename?: 'DirGenderAndAgeGroups';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  clsAgeGroups: ClassifierValue;
  isMale?: Maybe<Scalars['Boolean']>;
};

export type DirGenderAndAgeGroupsWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  clsAgeGroups?: Maybe<ClassifierValueWhereInput>;
  isMale?: Maybe<Scalars['Boolean']>;
  isMale_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirGenderAndAgeGroupsWhereInput>>;
  OR?: Maybe<Array<DirGenderAndAgeGroupsWhereInput>>;
  NOT?: Maybe<Array<DirGenderAndAgeGroupsWhereInput>>;
};

export enum DirGenderAndAgeGroupsOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IsMaleAsc = 'isMale_ASC',
  IsMaleDesc = 'isMale_DESC'
}

export type DirSportingEventRefereeWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventRefereeStatusWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirSportingEventRefereeStatusValueWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum DirSportingEventRefereeStatusValueOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DirSportingEventCompetitionCalendarWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventCostWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum DirSportingEventCostOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PlanRubAsc = 'planRUB_ASC',
  PlanRubDesc = 'planRUB_DESC',
  PlanRuBperPersonAsc = 'planRUBperPerson_ASC',
  PlanRuBperPersonDesc = 'planRUBperPerson_DESC',
  PlanUsdAsc = 'planUSD_ASC',
  PlanUsdDesc = 'planUSD_DESC',
  PlanUsDperPersonAsc = 'planUSDperPerson_ASC',
  PlanUsDperPersonDesc = 'planUSDperPerson_DESC',
  PlanDateAsc = 'planDate_ASC',
  PlanDateDesc = 'planDate_DESC',
  CurrentPlanRubAsc = 'currentPlanRUB_ASC',
  CurrentPlanRubDesc = 'currentPlanRUB_DESC',
  CurrentPlanRuBperPersonAsc = 'currentPlanRUBperPerson_ASC',
  CurrentPlanRuBperPersonDesc = 'currentPlanRUBperPerson_DESC',
  CurrentPlanUsdAsc = 'currentPlanUSD_ASC',
  CurrentPlanUsdDesc = 'currentPlanUSD_DESC',
  CurrentPlanUsDperPersonAsc = 'currentPlanUSDperPerson_ASC',
  CurrentPlanUsDperPersonDesc = 'currentPlanUSDperPerson_DESC',
  CurrentPlanDateAsc = 'currentPlanDate_ASC',
  CurrentPlanDateDesc = 'currentPlanDate_DESC',
  FactRubAsc = 'factRUB_ASC',
  FactRubDesc = 'factRUB_DESC',
  FactRuBperPersonAsc = 'factRUBperPerson_ASC',
  FactRuBperPersonDesc = 'factRUBperPerson_DESC',
  FactUsdAsc = 'factUSD_ASC',
  FactUsdDesc = 'factUSD_DESC',
  FactUsDperPersonAsc = 'factUSDperPerson_ASC',
  FactUsDperPersonDesc = 'factUSDperPerson_DESC',
  FactDateAsc = 'factDate_ASC',
  FactDateDesc = 'factDate_DESC',
  YearAsc = 'year_ASC',
  YearDesc = 'year_DESC',
  MonthAsc = 'month_ASC',
  MonthDesc = 'month_DESC'
}

export type DirSportingEventQuotaWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum DirSportingEventQuotaOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  AthletesManAsc = 'athletesMan_ASC',
  AthletesManDesc = 'athletesMan_DESC',
  AthletesWomanAsc = 'athletesWoman_ASC',
  AthletesWomanDesc = 'athletesWoman_DESC',
  TrainersAsc = 'trainers_ASC',
  TrainersDesc = 'trainers_DESC',
  RefereesAsc = 'referees_ASC',
  RefereesDesc = 'referees_DESC',
  AdministrationAsc = 'administration_ASC',
  AdministrationDesc = 'administration_DESC',
  MedicalStaffAsc = 'medicalStaff_ASC',
  MedicalStaffDesc = 'medicalStaff_DESC',
  SpecialistsAsc = 'specialists_ASC',
  SpecialistsDesc = 'specialists_DESC',
  TeamsAsc = 'teams_ASC',
  TeamsDesc = 'teams_DESC'
}

export type DirSportingEventQuantityWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum DirSportingEventQuantityOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ParticipantAthletesTotalAsc = 'participantAthletesTotal_ASC',
  ParticipantAthletesTotalDesc = 'participantAthletesTotal_DESC',
  RefereesTotalAsc = 'refereesTotal_ASC',
  RefereesTotalDesc = 'refereesTotal_DESC',
  StaffTotalAsc = 'staffTotal_ASC',
  StaffTotalDesc = 'staffTotal_DESC',
  AudienceTotalAsc = 'audienceTotal_ASC',
  AudienceTotalDesc = 'audienceTotal_DESC'
}

export type DirSportingEventPointDistributionWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirSportingEventPointDistributionPlaceWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirSportingEventRequestWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirSportingEventRequestTypeWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum DirSportingEventRequestTypeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DirSportingEventRequestStatusWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirSportingEventRequestStatusValueWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum DirSportingEventRequestStatusValueOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DirNationalTeamWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamEmailRecipientWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirNationalTeamStatusWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum DirNationalTeamStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IsDeprecatedAsc = 'isDeprecated_ASC',
  IsDeprecatedDesc = 'isDeprecated_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DirNationalTeamStatusHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirNationalTeamExtendedStatusWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirNationalTeamTrainerWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamAthleteGroupWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamAthleteWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamMemberWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirCalendarWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
};

export type QuotaForStageTwoBySportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type ReqPartFromStageTwoWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type VenueCompetitionWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum VenueCompetitionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC'
}

export type VenueCompApplicationWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirCalendarTypeWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum DirCalendarTypeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DirCalendarSignedReportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirDocumentWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type AddressTypeWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum AddressTypeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TaskStatusWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum TaskStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type FileWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  path?: Maybe<Scalars['String']>;
};

export type SimilarUser = {
  __typename?: 'SimilarUser';
  user: User;
  similarity: Scalars['Float'];
};

export type SimilarPerson = {
  __typename?: 'SimilarPerson';
  person: DirPerson;
  similarity: Scalars['Float'];
};

export type SimilarOrganization = {
  __typename?: 'SimilarOrganization';
  organization: DirOrganization;
  similarity: Scalars['Float'];
};

export type SimilarDocument = {
  __typename?: 'SimilarDocument';
  document: DirDocument;
  similarity: Scalars['Float'];
};

export type SimilarSportingEvent = {
  __typename?: 'SimilarSportingEvent';
  sportingEvent: DirSportingEvent;
  similarity: Scalars['Float'];
};

export type OrganizationSearchResultType = {
  __typename?: 'OrganizationSearchResultType';
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
};

export type AddressSearchResultType = {
  __typename?: 'AddressSearchResultType';
  /** Адрес одной строкой (как показывается в списке подсказок) */
  value?: Maybe<Scalars['String']>;
  /** Адрес одной строкой (полный, с индексом) */
  unrestrictedValue?: Maybe<Scalars['String']>;
  /** Индекс */
  postcode?: Maybe<Scalars['String']>;
  /** Страна */
  country?: Maybe<Scalars['String']>;
  /** Регион */
  region?: Maybe<Scalars['String']>;
  /** Район в регионе */
  area?: Maybe<Scalars['String']>;
  /** Город */
  city?: Maybe<Scalars['String']>;
  /** Населенный пункт */
  settlement?: Maybe<Scalars['String']>;
  /** Улица */
  street?: Maybe<Scalars['String']>;
  /** Дом */
  house?: Maybe<Scalars['String']>;
  /** Кв./Офис */
  number?: Maybe<Scalars['String']>;
};

export type PublicAthleteMedCert = {
  __typename?: 'PublicAthleteMedCert';
  athlete: PublicAthleteInfo;
  medCert: PublicMedCertInfo;
};

export type PublicAthleteInfo = {
  __typename?: 'PublicAthleteInfo';
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  archive?: Maybe<Scalars['String']>;
};

export type PublicMedCertInfo = {
  __typename?: 'PublicMedCertInfo';
  issueDate: Scalars['DateTime'];
  validUntil: Scalars['DateTime'];
  organizationName: Scalars['String'];
  sports: Array<Scalars['String']>;
  trainingStage: Scalars['String'];
  isValid: Scalars['Boolean'];
};

export type DirSportReport = {
  __typename?: 'DirSportReport';
  /** Документ для печати в формате "xlsx" */
  xlsx: Scalars['String'];
};


export type DirSportReportXlsxArgs = {
  where?: Maybe<DirSportWhereInput>;
  orderBy?: Maybe<DirSportOrderByInput>;
  first?: Maybe<Scalars['Int']>;
  filter: Scalars['String'];
  registryNumberAlias: Scalars['String'];
  evskAlias: Scalars['String'];
  fullNameAlias: Scalars['String'];
  archiveAlias: Scalars['String'];
};

export type DirCalendarReport = {
  __typename?: 'DirCalendarReport';
  /** Документ для печати в формате "xlsx" */
  xlsx: Scalars['String'];
};


export type DirCalendarReportXlsxArgs = {
  where?: Maybe<DirCalendarWhereInput>;
  orderBy?: Maybe<DirCalendarOrderByInput>;
  first?: Maybe<Scalars['Int']>;
  filter: Scalars['String'];
  registryNumberAlias: Scalars['String'];
  fullNameAlias: Scalars['String'];
  shortNameAlias: Scalars['String'];
  dateRangeAlias: Scalars['String'];
};

export type DirRegionReport = {
  __typename?: 'DirRegionReport';
  /** Документ для печати в формате "xlsx" */
  xlsx: Scalars['String'];
};


export type DirRegionReportXlsxArgs = {
  where?: Maybe<DirRegionWhereInput>;
  orderBy?: Maybe<DirRegionOrderByInput>;
  first?: Maybe<Scalars['Int']>;
  filter: Scalars['String'];
  fullNameAlias: Scalars['String'];
  shortNameAlias: Scalars['String'];
  federalDistrictAlias: Scalars['String'];
  archiveAlias: Scalars['String'];
};

export type DirAthleteReport = {
  __typename?: 'DirAthleteReport';
  /** Документ для печати в формате "xlsx" */
  xlsx: Scalars['String'];
};


export type DirAthleteReportXlsxArgs = {
  where?: Maybe<DirAthleteWhereInput>;
  orderBy?: Maybe<DirAthleteOrderByInput>;
  first?: Maybe<Scalars['Int']>;
  filter: Scalars['String'];
  registryNumberAlias: Scalars['String'];
  fullNameAlias: Scalars['String'];
  birthdayAlias: Scalars['String'];
  genderAlias: Scalars['String'];
  regionAlias: Scalars['String'];
  sportAlias: Scalars['String'];
  organizationAlias: Scalars['String'];
  trainingStagesAlias: Scalars['String'];
  trainersAlias: Scalars['String'];
  archiveAlias: Scalars['String'];
};

export type DirTrainerReport = {
  __typename?: 'DirTrainerReport';
  /** Документ для печати в формате "xlsx" */
  xlsx: Scalars['String'];
};


export type DirTrainerReportXlsxArgs = {
  where?: Maybe<DirTrainerWhereInput>;
  orderBy?: Maybe<DirTrainerOrderByInput>;
  first?: Maybe<Scalars['Int']>;
  filter: Scalars['String'];
  registryNumberAlias: Scalars['String'];
  lastnameAlias: Scalars['String'];
  firstnameAlias: Scalars['String'];
  patronymicAlias: Scalars['String'];
  birthdayAlias: Scalars['String'];
  genderAlias: Scalars['String'];
  regionAlias: Scalars['String'];
  sportsAlias: Scalars['String'];
};

export type DirOrganizationReport = {
  __typename?: 'DirOrganizationReport';
  /** Документ для печати в формате "xlsx" */
  xlsx: Scalars['String'];
};


export type DirOrganizationReportXlsxArgs = {
  where?: Maybe<DirOrganizationWhereInput>;
  orderBy?: Maybe<DirOrganizationOrderByInput>;
  first?: Maybe<Scalars['Int']>;
  filter: Scalars['String'];
  registryNumberAlias: Scalars['String'];
  nameAlias: Scalars['String'];
  ogrnAlias: Scalars['String'];
  archiveAlias: Scalars['String'];
};

export type DirSportingEventReport = {
  __typename?: 'DirSportingEventReport';
  /** Документ для печати в формате "xlsx" */
  xlsx: Scalars['String'];
};


export type DirSportingEventReportXlsxArgs = {
  where?: Maybe<DirSportingEventWhereInput>;
  orderBy?: Maybe<DirSportingEventOrderByInput>;
  first?: Maybe<Scalars['Int']>;
  filter: Scalars['String'];
  registryNumberAlias: Scalars['String'];
  nameAlias: Scalars['String'];
  venueAlias: Scalars['String'];
  eventDateRangeAlias: Scalars['String'];
  sportsAndDisciplinesAlias: Scalars['String'];
  nationalTeamsCountAlias: Scalars['String'];
  athletesCountAlias: Scalars['String'];
  supportTeamCountAlias: Scalars['String'];
  calendarAlias: Scalars['String'];
  statusAlias: Scalars['String'];
};

export type DirNationalTeamReport = {
  __typename?: 'DirNationalTeamReport';
  /** Документ для печати в формате "xlsx" */
  xlsx: Scalars['String'];
};


export type DirNationalTeamReportXlsxArgs = {
  where?: Maybe<DirNationalTeamWhereInput>;
  orderBy?: Maybe<DirNationalTeamOrderByInput>;
  first?: Maybe<Scalars['Int']>;
  filter: Scalars['String'];
  registryNumberAlias: Scalars['String'];
  regionAlias: Scalars['String'];
  sportsAndDisciplinesAlias: Scalars['String'];
  athletesCountAlias: Scalars['String'];
  eventRegistryNumberAlias: Scalars['String'];
  eventNameAlias: Scalars['String'];
  eventDateRangeAlias: Scalars['String'];
  statusAlias: Scalars['String'];
};

export type DirRefereeReport = {
  __typename?: 'DirRefereeReport';
  /** Документ для печати в формате "xlsx" */
  xlsx: Scalars['String'];
};


export type DirRefereeReportXlsxArgs = {
  where?: Maybe<DirRefereeWhereInput>;
  search?: Maybe<Scalars['String']>;
  threshold?: Maybe<Scalars['Float']>;
  orderBy?: Maybe<DirRefereeOrderByInput>;
  first?: Maybe<Scalars['Int']>;
  filter: Scalars['String'];
  registryNumberAlias: Scalars['String'];
  fullNameAlias: Scalars['String'];
  birthdayAlias: Scalars['String'];
  genderAlias: Scalars['String'];
  regionAlias: Scalars['String'];
  sportsAlias: Scalars['String'];
};

export type DirPersonReport = {
  __typename?: 'DirPersonReport';
  /** Документ для печати в формате "xlsx" */
  xlsx: Scalars['String'];
};


export type DirPersonReportXlsxArgs = {
  where?: Maybe<DirPersonWhereInput>;
  orderBy?: Maybe<DirPersonOrderByInput>;
  first?: Maybe<Scalars['Int']>;
  filter: Scalars['String'];
  registryNumberAlias: Scalars['String'];
  firstNameAlias: Scalars['String'];
  lastNameAlias: Scalars['String'];
  patronymicAlias: Scalars['String'];
  birthdayAlias: Scalars['String'];
  genderAlias: Scalars['String'];
};

export type SportingEventParticipantsNumberByEvent = {
  __typename?: 'SportingEventParticipantsNumberByEvent';
  /** Название мероприятия */
  name: Scalars['String'];
  /** Виды спорта */
  sports: Array<Scalars['String']>;
  /** Группы дисциплин */
  disciplineGroups: Array<Scalars['String']>;
  /** Количество субъектов */
  subjectsCount: Scalars['Int'];
  /** Cопровождающих - всего */
  membersCount: Scalars['Int'];
  /** Количество спортсменов - всего */
  athletesCount: Scalars['Int'];
  /** Количество спортсменов - мужчины */
  athletesCountByMan: Scalars['Int'];
  /** Количество спортсменов - женщины */
  athletesCountByWoman: Scalars['Int'];
};

export type SportingEventParticipantsNumberByParentEvent = {
  __typename?: 'SportingEventParticipantsNumberByParentEvent';
  /** Название головного мероприятия */
  name: Scalars['String'];
  /** Информация о дочерних мероприятиях (массив), по каждому дочернему мероприятию данные */
  children: Array<SportingEventParticipantsNumberByEvent>;
};

export type SportingEventParticipantsNumberByCalendarType = {
  __typename?: 'SportingEventParticipantsNumberByCalendarType';
  /** Количество субъектов */
  subjectsCount: Scalars['Int'];
  /** Количество спортсменов - всего */
  athletesCount: Scalars['Int'];
  /** Сопровождающих - всего */
  membersCount: Scalars['Int'];
  /** Количество спортсменов - мужчины */
  athletesCountByMan: Scalars['Int'];
  /** Количество спортсменов - женщины */
  athletesCountByWoman: Scalars['Int'];
  /** Информация о дочерних мероприятиях (массив), по каждому дочернему мероприятию данные */
  events: Array<SportingEventParticipantsNumberByParentEvent>;
};

export type SportingEventParticipantsAgeByCalendarType = {
  __typename?: 'SportingEventParticipantsAgeByCalendarType';
  /** Название календаря */
  calendarName: Scalars['String'];
  /** Фактическое кол-во участников-спортсменов муж+жен в не архивных сборных не архивных мероприятий данного календаря */
  totalAmount: Scalars['Int'];
  /** Все не архивные сборные команды не архивных мероприятий календаря в разрезе годов */
  byYear: Array<SportingEventParticipantsAgeByYearType>;
  /** Не архивные сборные команды в разрезе не архивных мероприятий календаря */
  byEvent: Array<SportingEventParticipantsAgeByEventType>;
};

export type SportingEventParticipantsAgeByYearType = {
  __typename?: 'SportingEventParticipantsAgeByYearType';
  /** Год рождения */
  year: Scalars['Int'];
  /** Возраст */
  amount: Scalars['Int'];
};

export type SportingEventParticipantsAgeByEventType = {
  __typename?: 'SportingEventParticipantsAgeByEventType';
  /** Название мероприятия */
  eventName: Scalars['String'];
  /** Виды спорта */
  sports: Array<Scalars['String']>;
  /** Группы дисциплин */
  disciplineGroups: Array<Scalars['String']>;
  /** Все не архивные сборные команды в разрезе годов */
  byYear: Array<SportingEventParticipantsAgeByYearType>;
};

export type SportingEventParticipantsApplicationByEventsTeamType = {
  __typename?: 'SportingEventParticipantsApplicationByEventsTeamType';
  /** Состав сборной */
  rows: Array<SportingEventParticipantsApplicationByEventsTeamTypeRow>;
};

export type SportingEventParticipantsApplicationByEventsTeamTypeRow = {
  __typename?: 'SportingEventParticipantsApplicationByEventsTeamTypeRow';
  /** № п/п */
  n: Scalars['String'];
  /** Идентификатор */
  id: Scalars['String'];
  /** Фамилия, Имя, Отчество */
  name: Scalars['String'];
  /** Статус (руководитель, спортсмен, тренер, иное) */
  status: Scalars['String'];
  /** Дата рождения */
  birthday: Scalars['String'];
  /** Спортивная квалификация (разряд, звание) */
  qualification: Scalars['String'];
  /** Организация */
  organization: Scalars['String'];
  /** Принадлежность к организации */
  doctorSignature: Scalars['String'];
  /** +поле Статус допуска */
  entranceStatus: Scalars['Boolean'];
  /** {mutation} {uuid} */
  mutateEntranceStatus: Scalars['String'];
  /** Дата допуска */
  admissionDate: Scalars['String'];
  /** Пол */
  isMale: Scalars['Boolean'];
  /** Пол */
  isPrimary: Scalars['Boolean'];
};

export type SportingEventParticipantsRankAndAwardByCalendarType = {
  __typename?: 'SportingEventParticipantsRankAndAwardByCalendarType';
  /** Название календаря */
  calendarName: Scalars['String'];
  /** Итого кол-во участников по всем не архивным мероприятиям календаря */
  totalAmount: Scalars['Int'];
  /** Кол-во участников по всем не архивным мероприятиям календаря без разряда */
  totalAmountWithoutValidRank: Scalars['Int'];
  /** Массив: по всему Календарю массив кол-ва участников всех не архивных сборных не архивных спортивных мероприятий календаря в разрезе Разрядов / званий */
  total: Array<SportingEventParticipantsRankAndAwardTotalType>;
  /** Массив не архивных Спортивных мероприятий Календаря, в каждом мероприятии массив кол-ва участников (фактическое) из не архивных сборных команд в разрезе Разрядов / званий */
  byEvent: Array<SportingEventParticipantsRankAndAwardByEventType>;
};

export type SportingEventParticipantsRankAndAwardTotalType = {
  __typename?: 'SportingEventParticipantsRankAndAwardTotalType';
  /** Краткое название классификатора */
  name: Scalars['String'];
  /** Количество */
  amount: Scalars['Int'];
};

export type SportingEventParticipantsRankAndAwardByEventType = {
  __typename?: 'SportingEventParticipantsRankAndAwardByEventType';
  /** Название мероприятия */
  eventName: Scalars['String'];
  /** Виды спорта */
  sports: Array<Scalars['String']>;
  /** Группы дисциплин */
  disciplineGroups: Array<Scalars['String']>;
  /** Итого кол-во участников по всем не архивным мероприятиям календаря */
  totalAmount: Scalars['Int'];
  /** Кол-во участников по всем не архивным мероприятиям календаря без разряда */
  totalAmountWithoutValidRank: Scalars['Int'];
  /** Все не архивные сборные команды в разрезе годов */
  total: Array<SportingEventParticipantsRankAndAwardTotalType>;
};

export type SportingEventRefereesCategoryByCalendarType = {
  __typename?: 'SportingEventRefereesCategoryByCalendarType';
  /** Название календаря */
  calendarName: Scalars['String'];
  /** Массив не архивных Спортивных мероприятий Календаря, в каждом мероприятии массив кол-ва судей (фактическое) в разрезе Судейских категорий / званий */
  byEvent: Array<SportingEventRefereesCategoryByEventType>;
};

export type SportingEventRefereesCategoryByEventType = {
  __typename?: 'SportingEventRefereesCategoryByEventType';
  /** Название мероприятия */
  eventName: Scalars['String'];
  /** Виды спорта */
  sports: Array<Scalars['String']>;
  /** Группы дисциплин */
  disciplineGroups: Array<Scalars['String']>;
  /** Количество судей - всего */
  totalAmount: Scalars['Int'];
  /** По каждому мероприятию массив кол-ва судей в разрезе Судейских категорий */
  total: Array<SportingEventRefereesCategoryTotalType>;
};

export type SportingEventRefereesCategoryTotalType = {
  __typename?: 'SportingEventRefereesCategoryTotalType';
  /** Краткое название классификатора */
  name: Scalars['String'];
  /** Количество */
  amount: Scalars['Int'];
};

export type SportingEventAthletesByPointsType = {
  __typename?: 'SportingEventAthletesByPointsType';
  /** Массив не архивных Спортивных мероприятий Календаря, в каждом мероприятии массив кол-ва судей (фактическое) в разрезе Судейских категорий / званий */
  regions: Array<SportingEventAthletesByPointsRegionType>;
};

export type SportingEventAthletesByPointsRegionType = {
  __typename?: 'SportingEventAthletesByPointsRegionType';
  /** Название Региона */
  name: Scalars['String'];
  /** Название ВУЗа */
  universityName: Scalars['String'];
  /** ID Региона */
  regionID: Scalars['ID'];
  /** Кол-во спортсменов в сборной */
  athletesNumber: Scalars['Int'];
  /** кол-во золотых медалей */
  totalGold: Scalars['Int'];
  /** кол-во серебряных медалей */
  totalSilver: Scalars['Int'];
  /** кол-во бронзовых медалей */
  totalBronze: Scalars['Int'];
  /** кол-во занятых мест с 4 по 10 */
  total_4_10: Scalars['Int'];
  /** кол-во занятых мест с 11 по 20 */
  total_11_20: Scalars['Int'];
};

export type Me = {
  __typename?: 'Me';
  id: Scalars['ID'];
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  role: UserRole;
  personProfile?: Maybe<DirPerson>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfile>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfile>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfile>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfile>;
  gskRefereeProfile?: Maybe<GskRefereeProfile>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: User;
  updateUser?: Maybe<User>;
  upsertUser: User;
  deleteUser?: Maybe<User>;
  createUserRole: UserRole;
  updateUserRole?: Maybe<UserRole>;
  upsertUserRole: UserRole;
  deleteUserRole?: Maybe<UserRole>;
  createRegularUser: RegularUser;
  updateRegularUser?: Maybe<RegularUser>;
  upsertRegularUser: RegularUser;
  deleteRegularUser?: Maybe<RegularUser>;
  createSystemUser: SystemUser;
  updateSystemUser?: Maybe<SystemUser>;
  upsertSystemUser: SystemUser;
  deleteSystemUser?: Maybe<SystemUser>;
  createRegionalMinsportProfile: RegionalMinsportProfile;
  updateRegionalMinsportProfile?: Maybe<RegionalMinsportProfile>;
  upsertRegionalMinsportProfile: RegionalMinsportProfile;
  deleteRegionalMinsportProfile?: Maybe<RegionalMinsportProfile>;
  createRegionalSchoolProfile: RegionalSchoolProfile;
  updateRegionalSchoolProfile?: Maybe<RegionalSchoolProfile>;
  upsertRegionalSchoolProfile: RegionalSchoolProfile;
  deleteRegionalSchoolProfile?: Maybe<RegionalSchoolProfile>;
  createNotificationProtocol: NotificationProtocol;
  updateNotificationProtocol?: Maybe<NotificationProtocol>;
  upsertNotificationProtocol: NotificationProtocol;
  deleteNotificationProtocol?: Maybe<NotificationProtocol>;
  createRegionalAdministratorProfile: RegionalAdministratorProfile;
  updateRegionalAdministratorProfile?: Maybe<RegionalAdministratorProfile>;
  upsertRegionalAdministratorProfile: RegionalAdministratorProfile;
  deleteRegionalAdministratorProfile?: Maybe<RegionalAdministratorProfile>;
  createRoivSpecialistProfile: RoivSpecialistProfile;
  updateRoivSpecialistProfile?: Maybe<RoivSpecialistProfile>;
  upsertRoivSpecialistProfile: RoivSpecialistProfile;
  deleteRoivSpecialistProfile?: Maybe<RoivSpecialistProfile>;
  createFcpsrSportingEventOrganizerProfile: FcpsrSportingEventOrganizerProfile;
  updateFcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfile>;
  upsertFcpsrSportingEventOrganizerProfile: FcpsrSportingEventOrganizerProfile;
  deleteFcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfile>;
  createDirSportingEventRegulationStatusValue: DirSportingEventRegulationStatusValue;
  updateDirSportingEventRegulationStatusValue?: Maybe<DirSportingEventRegulationStatusValue>;
  upsertDirSportingEventRegulationStatusValue: DirSportingEventRegulationStatusValue;
  deleteDirSportingEventRegulationStatusValue?: Maybe<DirSportingEventRegulationStatusValue>;
  createDirSportingEventRegulationStatus: DirSportingEventRegulationStatus;
  updateDirSportingEventRegulationStatus?: Maybe<DirSportingEventRegulationStatus>;
  upsertDirSportingEventRegulationStatus: DirSportingEventRegulationStatus;
  deleteDirSportingEventRegulationStatus?: Maybe<DirSportingEventRegulationStatus>;
  createDirRefusedRegion: DirRefusedRegion;
  updateDirRefusedRegion?: Maybe<DirRefusedRegion>;
  upsertDirRefusedRegion: DirRefusedRegion;
  deleteDirRefusedRegion?: Maybe<DirRefusedRegion>;
  createDirReturnedRegion: DirReturnedRegion;
  updateDirReturnedRegion?: Maybe<DirReturnedRegion>;
  upsertDirReturnedRegion: DirReturnedRegion;
  deleteDirReturnedRegion?: Maybe<DirReturnedRegion>;
  createDirSportEventRegulations: DirSportEventRegulations;
  updateDirSportEventRegulations?: Maybe<DirSportEventRegulations>;
  upsertDirSportEventRegulations: DirSportEventRegulations;
  deleteDirSportEventRegulations?: Maybe<DirSportEventRegulations>;
  createGskRefereeProfile: GskRefereeProfile;
  updateGskRefereeProfile?: Maybe<GskRefereeProfile>;
  upsertGskRefereeProfile: GskRefereeProfile;
  deleteGskRefereeProfile?: Maybe<GskRefereeProfile>;
  createUserSupportRequest: UserSupportRequest;
  updateUserSupportRequest?: Maybe<UserSupportRequest>;
  upsertUserSupportRequest: UserSupportRequest;
  deleteUserSupportRequest?: Maybe<UserSupportRequest>;
  createUserSupportRequestType: UserSupportRequestType;
  updateUserSupportRequestType?: Maybe<UserSupportRequestType>;
  upsertUserSupportRequestType: UserSupportRequestType;
  deleteUserSupportRequestType?: Maybe<UserSupportRequestType>;
  createFieldHistory: FieldHistory;
  updateFieldHistory?: Maybe<FieldHistory>;
  upsertFieldHistory: FieldHistory;
  deleteFieldHistory?: Maybe<FieldHistory>;
  createArchive: Archive;
  updateArchive?: Maybe<Archive>;
  upsertArchive: Archive;
  deleteArchive?: Maybe<Archive>;
  createClassifier: Classifier;
  updateClassifier?: Maybe<Classifier>;
  upsertClassifier: Classifier;
  deleteClassifier?: Maybe<Classifier>;
  createClassifierValue: ClassifierValue;
  updateClassifierValue?: Maybe<ClassifierValue>;
  upsertClassifierValue: ClassifierValue;
  deleteClassifierValue?: Maybe<ClassifierValue>;
  createDirRegion: DirRegion;
  updateDirRegion?: Maybe<DirRegion>;
  upsertDirRegion: DirRegion;
  deleteDirRegion?: Maybe<DirRegion>;
  createDirFederalDistrict: DirFederalDistrict;
  updateDirFederalDistrict?: Maybe<DirFederalDistrict>;
  upsertDirFederalDistrict: DirFederalDistrict;
  deleteDirFederalDistrict?: Maybe<DirFederalDistrict>;
  createDirForeignCity: DirForeignCity;
  updateDirForeignCity?: Maybe<DirForeignCity>;
  upsertDirForeignCity: DirForeignCity;
  deleteDirForeignCity?: Maybe<DirForeignCity>;
  createDirCountry: DirCountry;
  updateDirCountry?: Maybe<DirCountry>;
  upsertDirCountry: DirCountry;
  deleteDirCountry?: Maybe<DirCountry>;
  createDirOrganization: DirOrganization;
  updateDirOrganization?: Maybe<DirOrganization>;
  upsertDirOrganization: DirOrganization;
  deleteDirOrganization?: Maybe<DirOrganization>;
  createDirOrganizationSport: DirOrganizationSport;
  updateDirOrganizationSport?: Maybe<DirOrganizationSport>;
  upsertDirOrganizationSport: DirOrganizationSport;
  deleteDirOrganizationSport?: Maybe<DirOrganizationSport>;
  createDirOrganizationAddress: DirOrganizationAddress;
  updateDirOrganizationAddress?: Maybe<DirOrganizationAddress>;
  upsertDirOrganizationAddress: DirOrganizationAddress;
  deleteDirOrganizationAddress?: Maybe<DirOrganizationAddress>;
  createDirOrganizationPhone: DirOrganizationPhone;
  updateDirOrganizationPhone?: Maybe<DirOrganizationPhone>;
  upsertDirOrganizationPhone: DirOrganizationPhone;
  deleteDirOrganizationPhone?: Maybe<DirOrganizationPhone>;
  createDirOrganizationEmail: DirOrganizationEmail;
  updateDirOrganizationEmail?: Maybe<DirOrganizationEmail>;
  upsertDirOrganizationEmail: DirOrganizationEmail;
  deleteDirOrganizationEmail?: Maybe<DirOrganizationEmail>;
  createDirPerson: DirPerson;
  updateDirPerson?: Maybe<DirPerson>;
  upsertDirPerson: DirPerson;
  deleteDirPerson?: Maybe<DirPerson>;
  createDirPersonPhone: DirPersonPhone;
  updateDirPersonPhone?: Maybe<DirPersonPhone>;
  upsertDirPersonPhone: DirPersonPhone;
  deleteDirPersonPhone?: Maybe<DirPersonPhone>;
  createDirPersonConfirmStatus: DirPersonConfirmStatus;
  updateDirPersonConfirmStatus?: Maybe<DirPersonConfirmStatus>;
  upsertDirPersonConfirmStatus: DirPersonConfirmStatus;
  deleteDirPersonConfirmStatus?: Maybe<DirPersonConfirmStatus>;
  createDirPersonAdditionalRegion: DirPersonAdditionalRegion;
  updateDirPersonAdditionalRegion?: Maybe<DirPersonAdditionalRegion>;
  upsertDirPersonAdditionalRegion: DirPersonAdditionalRegion;
  deleteDirPersonAdditionalRegion?: Maybe<DirPersonAdditionalRegion>;
  createDirPersonMergeTask: DirPersonMergeTask;
  updateDirPersonMergeTask?: Maybe<DirPersonMergeTask>;
  upsertDirPersonMergeTask: DirPersonMergeTask;
  deleteDirPersonMergeTask?: Maybe<DirPersonMergeTask>;
  createDirPersonAddress: DirPersonAddress;
  updateDirPersonAddress?: Maybe<DirPersonAddress>;
  upsertDirPersonAddress: DirPersonAddress;
  deleteDirPersonAddress?: Maybe<DirPersonAddress>;
  createDirSport: DirSport;
  updateDirSport?: Maybe<DirSport>;
  upsertDirSport: DirSport;
  deleteDirSport?: Maybe<DirSport>;
  createDirSportDiscipline: DirSportDiscipline;
  updateDirSportDiscipline?: Maybe<DirSportDiscipline>;
  upsertDirSportDiscipline: DirSportDiscipline;
  deleteDirSportDiscipline?: Maybe<DirSportDiscipline>;
  createDirSportDisciplineGroup: DirSportDisciplineGroup;
  updateDirSportDisciplineGroup?: Maybe<DirSportDisciplineGroup>;
  upsertDirSportDisciplineGroup: DirSportDisciplineGroup;
  deleteDirSportDisciplineGroup?: Maybe<DirSportDisciplineGroup>;
  createDirSportAgeGroup: DirSportAgeGroup;
  updateDirSportAgeGroup?: Maybe<DirSportAgeGroup>;
  upsertDirSportAgeGroup: DirSportAgeGroup;
  deleteDirSportAgeGroup?: Maybe<DirSportAgeGroup>;
  createDirSportAgeGroupParticipationRequirements: DirSportAgeGroupParticipationRequirements;
  updateDirSportAgeGroupParticipationRequirements?: Maybe<DirSportAgeGroupParticipationRequirements>;
  upsertDirSportAgeGroupParticipationRequirements: DirSportAgeGroupParticipationRequirements;
  deleteDirSportAgeGroupParticipationRequirements?: Maybe<DirSportAgeGroupParticipationRequirements>;
  createDirSportTrainingStage: DirSportTrainingStage;
  updateDirSportTrainingStage?: Maybe<DirSportTrainingStage>;
  upsertDirSportTrainingStage: DirSportTrainingStage;
  deleteDirSportTrainingStage?: Maybe<DirSportTrainingStage>;
  createDirSportBaseRegion: DirSportBaseRegion;
  updateDirSportBaseRegion?: Maybe<DirSportBaseRegion>;
  upsertDirSportBaseRegion: DirSportBaseRegion;
  deleteDirSportBaseRegion?: Maybe<DirSportBaseRegion>;
  createDirAthlete: DirAthlete;
  updateDirAthlete?: Maybe<DirAthlete>;
  upsertDirAthlete: DirAthlete;
  deleteDirAthlete?: Maybe<DirAthlete>;
  createDirAthleteSport: DirAthleteSport;
  updateDirAthleteSport?: Maybe<DirAthleteSport>;
  upsertDirAthleteSport: DirAthleteSport;
  deleteDirAthleteSport?: Maybe<DirAthleteSport>;
  createDirAthleteSportOrganization: DirAthleteSportOrganization;
  updateDirAthleteSportOrganization?: Maybe<DirAthleteSportOrganization>;
  upsertDirAthleteSportOrganization: DirAthleteSportOrganization;
  deleteDirAthleteSportOrganization?: Maybe<DirAthleteSportOrganization>;
  createDirAthleteSportRankAndAward: DirAthleteSportRankAndAward;
  updateDirAthleteSportRankAndAward?: Maybe<DirAthleteSportRankAndAward>;
  upsertDirAthleteSportRankAndAward: DirAthleteSportRankAndAward;
  deleteDirAthleteSportRankAndAward?: Maybe<DirAthleteSportRankAndAward>;
  createDirAthleteMedCert: DirAthleteMedCert;
  updateDirAthleteMedCert?: Maybe<DirAthleteMedCert>;
  upsertDirAthleteMedCert: DirAthleteMedCert;
  deleteDirAthleteMedCert?: Maybe<DirAthleteMedCert>;
  createDirAthleteMedCertSport: DirAthleteMedCertSport;
  updateDirAthleteMedCertSport?: Maybe<DirAthleteMedCertSport>;
  upsertDirAthleteMedCertSport: DirAthleteMedCertSport;
  deleteDirAthleteMedCertSport?: Maybe<DirAthleteMedCertSport>;
  createDirAthleteCompetitionResult: DirAthleteCompetitionResult;
  updateDirAthleteCompetitionResult?: Maybe<DirAthleteCompetitionResult>;
  upsertDirAthleteCompetitionResult: DirAthleteCompetitionResult;
  deleteDirAthleteCompetitionResult?: Maybe<DirAthleteCompetitionResult>;
  createDirTrainer: DirTrainer;
  updateDirTrainer?: Maybe<DirTrainer>;
  upsertDirTrainer: DirTrainer;
  deleteDirTrainer?: Maybe<DirTrainer>;
  createDirTrainerSport: DirTrainerSport;
  updateDirTrainerSport?: Maybe<DirTrainerSport>;
  upsertDirTrainerSport: DirTrainerSport;
  deleteDirTrainerSport?: Maybe<DirTrainerSport>;
  createDirTrainerSportOrganization: DirTrainerSportOrganization;
  updateDirTrainerSportOrganization?: Maybe<DirTrainerSportOrganization>;
  upsertDirTrainerSportOrganization: DirTrainerSportOrganization;
  deleteDirTrainerSportOrganization?: Maybe<DirTrainerSportOrganization>;
  createDirTrainerSportCategory: DirTrainerSportCategory;
  updateDirTrainerSportCategory?: Maybe<DirTrainerSportCategory>;
  upsertDirTrainerSportCategory: DirTrainerSportCategory;
  deleteDirTrainerSportCategory?: Maybe<DirTrainerSportCategory>;
  createDirReferee: DirReferee;
  updateDirReferee?: Maybe<DirReferee>;
  upsertDirReferee: DirReferee;
  deleteDirReferee?: Maybe<DirReferee>;
  createDirRefereeSport: DirRefereeSport;
  updateDirRefereeSport?: Maybe<DirRefereeSport>;
  upsertDirRefereeSport: DirRefereeSport;
  deleteDirRefereeSport?: Maybe<DirRefereeSport>;
  createDirRefereeSportCategory: DirRefereeSportCategory;
  updateDirRefereeSportCategory?: Maybe<DirRefereeSportCategory>;
  upsertDirRefereeSportCategory: DirRefereeSportCategory;
  deleteDirRefereeSportCategory?: Maybe<DirRefereeSportCategory>;
  createDirSportingEvent: DirSportingEvent;
  updateDirSportingEvent?: Maybe<DirSportingEvent>;
  upsertDirSportingEvent: DirSportingEvent;
  deleteDirSportingEvent?: Maybe<DirSportingEvent>;
  createQuantyRequestStageTwo: QuantyRequestStageTwo;
  updateQuantyRequestStageTwo?: Maybe<QuantyRequestStageTwo>;
  upsertQuantyRequestStageTwo: QuantyRequestStageTwo;
  deleteQuantyRequestStageTwo?: Maybe<QuantyRequestStageTwo>;
  createDirSportingEventAthleteCallNotification: DirSportingEventAthleteCallNotification;
  updateDirSportingEventAthleteCallNotification?: Maybe<DirSportingEventAthleteCallNotification>;
  upsertDirSportingEventAthleteCallNotification: DirSportingEventAthleteCallNotification;
  deleteDirSportingEventAthleteCallNotification?: Maybe<DirSportingEventAthleteCallNotification>;
  createDirSportingEventAthleteCallNotificationTeam: DirSportingEventAthleteCallNotificationTeam;
  updateDirSportingEventAthleteCallNotificationTeam?: Maybe<DirSportingEventAthleteCallNotificationTeam>;
  upsertDirSportingEventAthleteCallNotificationTeam: DirSportingEventAthleteCallNotificationTeam;
  deleteDirSportingEventAthleteCallNotificationTeam?: Maybe<DirSportingEventAthleteCallNotificationTeam>;
  createDirSportingEventCountry: DirSportingEventCountry;
  updateDirSportingEventCountry?: Maybe<DirSportingEventCountry>;
  upsertDirSportingEventCountry: DirSportingEventCountry;
  deleteDirSportingEventCountry?: Maybe<DirSportingEventCountry>;
  createDirSportingEventSport: DirSportingEventSport;
  updateDirSportingEventSport?: Maybe<DirSportingEventSport>;
  upsertDirSportingEventSport: DirSportingEventSport;
  deleteDirSportingEventSport?: Maybe<DirSportingEventSport>;
  createDirSportingEventAgeGroup: DirSportingEventAgeGroup;
  updateDirSportingEventAgeGroup?: Maybe<DirSportingEventAgeGroup>;
  upsertDirSportingEventAgeGroup: DirSportingEventAgeGroup;
  deleteDirSportingEventAgeGroup?: Maybe<DirSportingEventAgeGroup>;
  createDirSportingEventStatus: DirSportingEventStatus;
  updateDirSportingEventStatus?: Maybe<DirSportingEventStatus>;
  upsertDirSportingEventStatus: DirSportingEventStatus;
  deleteDirSportingEventStatus?: Maybe<DirSportingEventStatus>;
  createDirSportingEventStatusHistory: DirSportingEventStatusHistory;
  updateDirSportingEventStatusHistory?: Maybe<DirSportingEventStatusHistory>;
  upsertDirSportingEventStatusHistory: DirSportingEventStatusHistory;
  deleteDirSportingEventStatusHistory?: Maybe<DirSportingEventStatusHistory>;
  createDirSportingEventExtendedStatus: DirSportingEventExtendedStatus;
  updateDirSportingEventExtendedStatus?: Maybe<DirSportingEventExtendedStatus>;
  upsertDirSportingEventExtendedStatus: DirSportingEventExtendedStatus;
  deleteDirSportingEventExtendedStatus?: Maybe<DirSportingEventExtendedStatus>;
  createDirSportingEventExtendedStatusValue: DirSportingEventExtendedStatusValue;
  updateDirSportingEventExtendedStatusValue?: Maybe<DirSportingEventExtendedStatusValue>;
  upsertDirSportingEventExtendedStatusValue: DirSportingEventExtendedStatusValue;
  deleteDirSportingEventExtendedStatusValue?: Maybe<DirSportingEventExtendedStatusValue>;
  createDirSportingEventProgramType: DirSportingEventProgramType;
  updateDirSportingEventProgramType?: Maybe<DirSportingEventProgramType>;
  upsertDirSportingEventProgramType: DirSportingEventProgramType;
  deleteDirSportingEventProgramType?: Maybe<DirSportingEventProgramType>;
  createDirGenderAndAgeGroups: DirGenderAndAgeGroups;
  updateDirGenderAndAgeGroups?: Maybe<DirGenderAndAgeGroups>;
  upsertDirGenderAndAgeGroups: DirGenderAndAgeGroups;
  deleteDirGenderAndAgeGroups?: Maybe<DirGenderAndAgeGroups>;
  createDirSportingEventReferee: DirSportingEventReferee;
  updateDirSportingEventReferee?: Maybe<DirSportingEventReferee>;
  upsertDirSportingEventReferee: DirSportingEventReferee;
  deleteDirSportingEventReferee?: Maybe<DirSportingEventReferee>;
  createDirSportingEventRefereeStatus: DirSportingEventRefereeStatus;
  updateDirSportingEventRefereeStatus?: Maybe<DirSportingEventRefereeStatus>;
  upsertDirSportingEventRefereeStatus: DirSportingEventRefereeStatus;
  deleteDirSportingEventRefereeStatus?: Maybe<DirSportingEventRefereeStatus>;
  createDirSportingEventRefereeStatusValue: DirSportingEventRefereeStatusValue;
  updateDirSportingEventRefereeStatusValue?: Maybe<DirSportingEventRefereeStatusValue>;
  upsertDirSportingEventRefereeStatusValue: DirSportingEventRefereeStatusValue;
  deleteDirSportingEventRefereeStatusValue?: Maybe<DirSportingEventRefereeStatusValue>;
  createDirSportingEventCompetitionCalendar: DirSportingEventCompetitionCalendar;
  updateDirSportingEventCompetitionCalendar?: Maybe<DirSportingEventCompetitionCalendar>;
  upsertDirSportingEventCompetitionCalendar: DirSportingEventCompetitionCalendar;
  deleteDirSportingEventCompetitionCalendar?: Maybe<DirSportingEventCompetitionCalendar>;
  createDirSportingEventCost: DirSportingEventCost;
  updateDirSportingEventCost?: Maybe<DirSportingEventCost>;
  upsertDirSportingEventCost: DirSportingEventCost;
  deleteDirSportingEventCost?: Maybe<DirSportingEventCost>;
  createDirSportingEventQuota: DirSportingEventQuota;
  updateDirSportingEventQuota?: Maybe<DirSportingEventQuota>;
  upsertDirSportingEventQuota: DirSportingEventQuota;
  deleteDirSportingEventQuota?: Maybe<DirSportingEventQuota>;
  createDirSportingEventQuantity: DirSportingEventQuantity;
  updateDirSportingEventQuantity?: Maybe<DirSportingEventQuantity>;
  upsertDirSportingEventQuantity: DirSportingEventQuantity;
  deleteDirSportingEventQuantity?: Maybe<DirSportingEventQuantity>;
  createDirSportingEventPointDistribution: DirSportingEventPointDistribution;
  updateDirSportingEventPointDistribution?: Maybe<DirSportingEventPointDistribution>;
  upsertDirSportingEventPointDistribution: DirSportingEventPointDistribution;
  deleteDirSportingEventPointDistribution?: Maybe<DirSportingEventPointDistribution>;
  createDirSportingEventPointDistributionPlace: DirSportingEventPointDistributionPlace;
  updateDirSportingEventPointDistributionPlace?: Maybe<DirSportingEventPointDistributionPlace>;
  upsertDirSportingEventPointDistributionPlace: DirSportingEventPointDistributionPlace;
  deleteDirSportingEventPointDistributionPlace?: Maybe<DirSportingEventPointDistributionPlace>;
  createDirSportingEventRequest: DirSportingEventRequest;
  updateDirSportingEventRequest?: Maybe<DirSportingEventRequest>;
  upsertDirSportingEventRequest: DirSportingEventRequest;
  deleteDirSportingEventRequest?: Maybe<DirSportingEventRequest>;
  createDirSportingEventRequestType: DirSportingEventRequestType;
  updateDirSportingEventRequestType?: Maybe<DirSportingEventRequestType>;
  upsertDirSportingEventRequestType: DirSportingEventRequestType;
  deleteDirSportingEventRequestType?: Maybe<DirSportingEventRequestType>;
  createDirSportingEventRequestStatus: DirSportingEventRequestStatus;
  updateDirSportingEventRequestStatus?: Maybe<DirSportingEventRequestStatus>;
  upsertDirSportingEventRequestStatus: DirSportingEventRequestStatus;
  deleteDirSportingEventRequestStatus?: Maybe<DirSportingEventRequestStatus>;
  createDirSportingEventRequestStatusValue: DirSportingEventRequestStatusValue;
  updateDirSportingEventRequestStatusValue?: Maybe<DirSportingEventRequestStatusValue>;
  upsertDirSportingEventRequestStatusValue: DirSportingEventRequestStatusValue;
  deleteDirSportingEventRequestStatusValue?: Maybe<DirSportingEventRequestStatusValue>;
  createDirNationalTeam: DirNationalTeam;
  updateDirNationalTeam?: Maybe<DirNationalTeam>;
  upsertDirNationalTeam: DirNationalTeam;
  deleteDirNationalTeam?: Maybe<DirNationalTeam>;
  createDirNationalTeamEmailRecipient: DirNationalTeamEmailRecipient;
  updateDirNationalTeamEmailRecipient?: Maybe<DirNationalTeamEmailRecipient>;
  upsertDirNationalTeamEmailRecipient: DirNationalTeamEmailRecipient;
  deleteDirNationalTeamEmailRecipient?: Maybe<DirNationalTeamEmailRecipient>;
  createDirNationalTeamStatus: DirNationalTeamStatus;
  updateDirNationalTeamStatus?: Maybe<DirNationalTeamStatus>;
  upsertDirNationalTeamStatus: DirNationalTeamStatus;
  deleteDirNationalTeamStatus?: Maybe<DirNationalTeamStatus>;
  createDirNationalTeamStatusHistory: DirNationalTeamStatusHistory;
  updateDirNationalTeamStatusHistory?: Maybe<DirNationalTeamStatusHistory>;
  upsertDirNationalTeamStatusHistory: DirNationalTeamStatusHistory;
  deleteDirNationalTeamStatusHistory?: Maybe<DirNationalTeamStatusHistory>;
  createDirNationalTeamExtendedStatus: DirNationalTeamExtendedStatus;
  updateDirNationalTeamExtendedStatus?: Maybe<DirNationalTeamExtendedStatus>;
  upsertDirNationalTeamExtendedStatus: DirNationalTeamExtendedStatus;
  deleteDirNationalTeamExtendedStatus?: Maybe<DirNationalTeamExtendedStatus>;
  createDirNationalTeamTrainer: DirNationalTeamTrainer;
  updateDirNationalTeamTrainer?: Maybe<DirNationalTeamTrainer>;
  upsertDirNationalTeamTrainer: DirNationalTeamTrainer;
  deleteDirNationalTeamTrainer?: Maybe<DirNationalTeamTrainer>;
  createDirNationalTeamAthleteGroup: DirNationalTeamAthleteGroup;
  updateDirNationalTeamAthleteGroup?: Maybe<DirNationalTeamAthleteGroup>;
  upsertDirNationalTeamAthleteGroup: DirNationalTeamAthleteGroup;
  deleteDirNationalTeamAthleteGroup?: Maybe<DirNationalTeamAthleteGroup>;
  createDirNationalTeamAthlete: DirNationalTeamAthlete;
  updateDirNationalTeamAthlete?: Maybe<DirNationalTeamAthlete>;
  upsertDirNationalTeamAthlete: DirNationalTeamAthlete;
  deleteDirNationalTeamAthlete?: Maybe<DirNationalTeamAthlete>;
  createDirNationalTeamMember: DirNationalTeamMember;
  updateDirNationalTeamMember?: Maybe<DirNationalTeamMember>;
  upsertDirNationalTeamMember: DirNationalTeamMember;
  deleteDirNationalTeamMember?: Maybe<DirNationalTeamMember>;
  createDirCalendar: DirCalendar;
  updateDirCalendar?: Maybe<DirCalendar>;
  upsertDirCalendar: DirCalendar;
  deleteDirCalendar?: Maybe<DirCalendar>;
  createQuotaForStageTwoBySport: QuotaForStageTwoBySport;
  updateQuotaForStageTwoBySport?: Maybe<QuotaForStageTwoBySport>;
  upsertQuotaForStageTwoBySport: QuotaForStageTwoBySport;
  deleteQuotaForStageTwoBySport?: Maybe<QuotaForStageTwoBySport>;
  createReqPartFromStageTwo: ReqPartFromStageTwo;
  updateReqPartFromStageTwo?: Maybe<ReqPartFromStageTwo>;
  upsertReqPartFromStageTwo: ReqPartFromStageTwo;
  deleteReqPartFromStageTwo?: Maybe<ReqPartFromStageTwo>;
  createVenueCompetition: VenueCompetition;
  updateVenueCompetition?: Maybe<VenueCompetition>;
  upsertVenueCompetition: VenueCompetition;
  deleteVenueCompetition?: Maybe<VenueCompetition>;
  createVenueCompApplication: VenueCompApplication;
  updateVenueCompApplication?: Maybe<VenueCompApplication>;
  upsertVenueCompApplication: VenueCompApplication;
  deleteVenueCompApplication?: Maybe<VenueCompApplication>;
  createDirCalendarType: DirCalendarType;
  updateDirCalendarType?: Maybe<DirCalendarType>;
  upsertDirCalendarType: DirCalendarType;
  deleteDirCalendarType?: Maybe<DirCalendarType>;
  createDirCalendarSignedReport: DirCalendarSignedReport;
  updateDirCalendarSignedReport?: Maybe<DirCalendarSignedReport>;
  upsertDirCalendarSignedReport: DirCalendarSignedReport;
  deleteDirCalendarSignedReport?: Maybe<DirCalendarSignedReport>;
  createDirDocument: DirDocument;
  updateDirDocument?: Maybe<DirDocument>;
  upsertDirDocument: DirDocument;
  deleteDirDocument?: Maybe<DirDocument>;
  createAddressType: AddressType;
  updateAddressType?: Maybe<AddressType>;
  upsertAddressType: AddressType;
  deleteAddressType?: Maybe<AddressType>;
  createTaskStatus: TaskStatus;
  updateTaskStatus?: Maybe<TaskStatus>;
  upsertTaskStatus: TaskStatus;
  deleteTaskStatus?: Maybe<TaskStatus>;
  createFile: File;
  updateFile?: Maybe<File>;
  upsertFile: File;
  deleteFile?: Maybe<File>;
  /** Регистрация нового пользователя */
  signup: AuthPayload;
  /** Вход */
  signin: AuthPayload;
  /** Сброс пароля */
  publicResetPassword: Scalars['Boolean'];
  /** Активация пользователя */
  activateUser: User;
  /** Деактивация пользователя */
  deactivateUser: User;
  /** Смена роли пользователя */
  changeUserRole: User;
  /** Смена email пользователя */
  changeUserEmail: User;
  /** Смена пароля пользователя */
  changePassword: User;
  /** Сброс пароля пользователя */
  resetUserPassword: User;
  /** Удаление аккаунта пользователя */
  deleteUserAccount: Scalars['Boolean'];
  /** Отзыв токена пользователя */
  revokeToken: User;
  /** Отзыв всех токенов пользователя */
  revokeAllTokens: User;
  /** Объединение персон */
  mergePerson?: Maybe<DirPerson>;
  /** Создать задание объединения Персон */
  openMergePersonTask: DirPersonMergeTask;
  /** Выполнить и закрыть задание объединения Персон */
  closeMergePersonTask: DirPersonMergeTask;
  /** Отменить задание объединения Персон */
  cancelMergePersonTask: DirPersonMergeTask;
  /** Упольнение Тренера и перевод Спортсменов на самоподготовку */
  trainerDismissalWithAthletesSelfTraining: Scalars['Boolean'];
  /** Упольнение Тренера и отчисление Спортсменов */
  trainerDismissalWithAthletesExpel: Scalars['Boolean'];
  /** Упольнение Тренера и перевод Спортсменов другому Тренеру */
  trainerDismissalWithAthletesAssignToAnotherTrainer: Scalars['Boolean'];
  /** Исключение Спортсменов */
  athletesExpel: Scalars['Boolean'];
  /** Исключение Спортсменов с переводом к другому Тренеру */
  athletesExpelWithMoveToAnotherTrainer: Scalars['Boolean'];
  /** Исключение Спортсменов с переводом на другой Этап подготовки */
  athletesExpelWithMoveToAnotherTrainingStage: Scalars['Boolean'];
  /** Исключение Спортсменов с переводом на самоподготовку */
  athletesExpelWithMoveToSelfTraining: Scalars['Boolean'];
  /** Загрузка файла */
  uploadFile?: Maybe<File>;
  /** Возвращает клон */
  cloneDirSportingEvent: DirSportingEvent;
  /** Возвращает объединенное мероприятие */
  restoreDirSportingEvent: DirSportingEvent;
  updateDirNationalTeamExtendedStatusThenNotify: DirNationalTeamExtendedStatus;
  updateDirSportsThenNotify: Scalars['Boolean'];
  positionVenueCompetitionNotify: Scalars['Boolean'];
  decisionVenueCompetitionNotify: Scalars['Boolean'];
  agreementVenueCompetitionNotify: Scalars['Boolean'];
  updateDirSportEventRegulationsThenNotify: Scalars['Boolean'];
  roivForNotification: Scalars['Boolean'];
  notificationStageTwo: Scalars['Boolean'];
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertUserArgs = {
  where: UserWhereUniqueInput;
  create: UserCreateInput;
  update: UserUpdateInput;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationCreateUserRoleArgs = {
  data: UserRoleCreateInput;
};


export type MutationUpdateUserRoleArgs = {
  data: UserRoleUpdateInput;
  where: UserRoleWhereUniqueInput;
};


export type MutationUpsertUserRoleArgs = {
  where: UserRoleWhereUniqueInput;
  create: UserRoleCreateInput;
  update: UserRoleUpdateInput;
};


export type MutationDeleteUserRoleArgs = {
  where: UserRoleWhereUniqueInput;
};


export type MutationCreateRegularUserArgs = {
  data: RegularUserCreateInput;
};


export type MutationUpdateRegularUserArgs = {
  data: RegularUserUpdateInput;
  where: RegularUserWhereUniqueInput;
};


export type MutationUpsertRegularUserArgs = {
  where: RegularUserWhereUniqueInput;
  create: RegularUserCreateInput;
  update: RegularUserUpdateInput;
};


export type MutationDeleteRegularUserArgs = {
  where: RegularUserWhereUniqueInput;
};


export type MutationCreateSystemUserArgs = {
  data: SystemUserCreateInput;
};


export type MutationUpdateSystemUserArgs = {
  data: SystemUserUpdateInput;
  where: SystemUserWhereUniqueInput;
};


export type MutationUpsertSystemUserArgs = {
  where: SystemUserWhereUniqueInput;
  create: SystemUserCreateInput;
  update: SystemUserUpdateInput;
};


export type MutationDeleteSystemUserArgs = {
  where: SystemUserWhereUniqueInput;
};


export type MutationCreateRegionalMinsportProfileArgs = {
  data: RegionalMinsportProfileCreateInput;
};


export type MutationUpdateRegionalMinsportProfileArgs = {
  data: RegionalMinsportProfileUpdateInput;
  where: RegionalMinsportProfileWhereUniqueInput;
};


export type MutationUpsertRegionalMinsportProfileArgs = {
  where: RegionalMinsportProfileWhereUniqueInput;
  create: RegionalMinsportProfileCreateInput;
  update: RegionalMinsportProfileUpdateInput;
};


export type MutationDeleteRegionalMinsportProfileArgs = {
  where: RegionalMinsportProfileWhereUniqueInput;
};


export type MutationCreateRegionalSchoolProfileArgs = {
  data: RegionalSchoolProfileCreateInput;
};


export type MutationUpdateRegionalSchoolProfileArgs = {
  data: RegionalSchoolProfileUpdateInput;
  where: RegionalSchoolProfileWhereUniqueInput;
};


export type MutationUpsertRegionalSchoolProfileArgs = {
  where: RegionalSchoolProfileWhereUniqueInput;
  create: RegionalSchoolProfileCreateInput;
  update: RegionalSchoolProfileUpdateInput;
};


export type MutationDeleteRegionalSchoolProfileArgs = {
  where: RegionalSchoolProfileWhereUniqueInput;
};


export type MutationCreateNotificationProtocolArgs = {
  data: NotificationProtocolCreateInput;
};


export type MutationUpdateNotificationProtocolArgs = {
  data: NotificationProtocolUpdateInput;
  where: NotificationProtocolWhereUniqueInput;
};


export type MutationUpsertNotificationProtocolArgs = {
  where: NotificationProtocolWhereUniqueInput;
  create: NotificationProtocolCreateInput;
  update: NotificationProtocolUpdateInput;
};


export type MutationDeleteNotificationProtocolArgs = {
  where: NotificationProtocolWhereUniqueInput;
};


export type MutationCreateRegionalAdministratorProfileArgs = {
  data: RegionalAdministratorProfileCreateInput;
};


export type MutationUpdateRegionalAdministratorProfileArgs = {
  data: RegionalAdministratorProfileUpdateInput;
  where: RegionalAdministratorProfileWhereUniqueInput;
};


export type MutationUpsertRegionalAdministratorProfileArgs = {
  where: RegionalAdministratorProfileWhereUniqueInput;
  create: RegionalAdministratorProfileCreateInput;
  update: RegionalAdministratorProfileUpdateInput;
};


export type MutationDeleteRegionalAdministratorProfileArgs = {
  where: RegionalAdministratorProfileWhereUniqueInput;
};


export type MutationCreateRoivSpecialistProfileArgs = {
  data: RoivSpecialistProfileCreateInput;
};


export type MutationUpdateRoivSpecialistProfileArgs = {
  data: RoivSpecialistProfileUpdateInput;
  where: RoivSpecialistProfileWhereUniqueInput;
};


export type MutationUpsertRoivSpecialistProfileArgs = {
  where: RoivSpecialistProfileWhereUniqueInput;
  create: RoivSpecialistProfileCreateInput;
  update: RoivSpecialistProfileUpdateInput;
};


export type MutationDeleteRoivSpecialistProfileArgs = {
  where: RoivSpecialistProfileWhereUniqueInput;
};


export type MutationCreateFcpsrSportingEventOrganizerProfileArgs = {
  data: FcpsrSportingEventOrganizerProfileCreateInput;
};


export type MutationUpdateFcpsrSportingEventOrganizerProfileArgs = {
  data: FcpsrSportingEventOrganizerProfileUpdateInput;
  where: FcpsrSportingEventOrganizerProfileWhereUniqueInput;
};


export type MutationUpsertFcpsrSportingEventOrganizerProfileArgs = {
  where: FcpsrSportingEventOrganizerProfileWhereUniqueInput;
  create: FcpsrSportingEventOrganizerProfileCreateInput;
  update: FcpsrSportingEventOrganizerProfileUpdateInput;
};


export type MutationDeleteFcpsrSportingEventOrganizerProfileArgs = {
  where: FcpsrSportingEventOrganizerProfileWhereUniqueInput;
};


export type MutationCreateDirSportingEventRegulationStatusValueArgs = {
  data: DirSportingEventRegulationStatusValueCreateInput;
};


export type MutationUpdateDirSportingEventRegulationStatusValueArgs = {
  data: DirSportingEventRegulationStatusValueUpdateInput;
  where: DirSportingEventRegulationStatusValueWhereUniqueInput;
};


export type MutationUpsertDirSportingEventRegulationStatusValueArgs = {
  where: DirSportingEventRegulationStatusValueWhereUniqueInput;
  create: DirSportingEventRegulationStatusValueCreateInput;
  update: DirSportingEventRegulationStatusValueUpdateInput;
};


export type MutationDeleteDirSportingEventRegulationStatusValueArgs = {
  where: DirSportingEventRegulationStatusValueWhereUniqueInput;
};


export type MutationCreateDirSportingEventRegulationStatusArgs = {
  data: DirSportingEventRegulationStatusCreateInput;
};


export type MutationUpdateDirSportingEventRegulationStatusArgs = {
  data: DirSportingEventRegulationStatusUpdateInput;
  where: DirSportingEventRegulationStatusWhereUniqueInput;
};


export type MutationUpsertDirSportingEventRegulationStatusArgs = {
  where: DirSportingEventRegulationStatusWhereUniqueInput;
  create: DirSportingEventRegulationStatusCreateInput;
  update: DirSportingEventRegulationStatusUpdateInput;
};


export type MutationDeleteDirSportingEventRegulationStatusArgs = {
  where: DirSportingEventRegulationStatusWhereUniqueInput;
};


export type MutationCreateDirRefusedRegionArgs = {
  data: DirRefusedRegionCreateInput;
};


export type MutationUpdateDirRefusedRegionArgs = {
  data: DirRefusedRegionUpdateInput;
  where: DirRefusedRegionWhereUniqueInput;
};


export type MutationUpsertDirRefusedRegionArgs = {
  where: DirRefusedRegionWhereUniqueInput;
  create: DirRefusedRegionCreateInput;
  update: DirRefusedRegionUpdateInput;
};


export type MutationDeleteDirRefusedRegionArgs = {
  where: DirRefusedRegionWhereUniqueInput;
};


export type MutationCreateDirReturnedRegionArgs = {
  data: DirReturnedRegionCreateInput;
};


export type MutationUpdateDirReturnedRegionArgs = {
  data: DirReturnedRegionUpdateInput;
  where: DirReturnedRegionWhereUniqueInput;
};


export type MutationUpsertDirReturnedRegionArgs = {
  where: DirReturnedRegionWhereUniqueInput;
  create: DirReturnedRegionCreateInput;
  update: DirReturnedRegionUpdateInput;
};


export type MutationDeleteDirReturnedRegionArgs = {
  where: DirReturnedRegionWhereUniqueInput;
};


export type MutationCreateDirSportEventRegulationsArgs = {
  data: DirSportEventRegulationsCreateInput;
};


export type MutationUpdateDirSportEventRegulationsArgs = {
  data: DirSportEventRegulationsUpdateInput;
  where: DirSportEventRegulationsWhereUniqueInput;
};


export type MutationUpsertDirSportEventRegulationsArgs = {
  where: DirSportEventRegulationsWhereUniqueInput;
  create: DirSportEventRegulationsCreateInput;
  update: DirSportEventRegulationsUpdateInput;
};


export type MutationDeleteDirSportEventRegulationsArgs = {
  where: DirSportEventRegulationsWhereUniqueInput;
};


export type MutationCreateGskRefereeProfileArgs = {
  data: GskRefereeProfileCreateInput;
};


export type MutationUpdateGskRefereeProfileArgs = {
  data: GskRefereeProfileUpdateInput;
  where: GskRefereeProfileWhereUniqueInput;
};


export type MutationUpsertGskRefereeProfileArgs = {
  where: GskRefereeProfileWhereUniqueInput;
  create: GskRefereeProfileCreateInput;
  update: GskRefereeProfileUpdateInput;
};


export type MutationDeleteGskRefereeProfileArgs = {
  where: GskRefereeProfileWhereUniqueInput;
};


export type MutationCreateUserSupportRequestArgs = {
  data: UserSupportRequestCreateInput;
};


export type MutationUpdateUserSupportRequestArgs = {
  data: UserSupportRequestUpdateInput;
  where: UserSupportRequestWhereUniqueInput;
};


export type MutationUpsertUserSupportRequestArgs = {
  where: UserSupportRequestWhereUniqueInput;
  create: UserSupportRequestCreateInput;
  update: UserSupportRequestUpdateInput;
};


export type MutationDeleteUserSupportRequestArgs = {
  where: UserSupportRequestWhereUniqueInput;
};


export type MutationCreateUserSupportRequestTypeArgs = {
  data: UserSupportRequestTypeCreateInput;
};


export type MutationUpdateUserSupportRequestTypeArgs = {
  data: UserSupportRequestTypeUpdateInput;
  where: UserSupportRequestTypeWhereUniqueInput;
};


export type MutationUpsertUserSupportRequestTypeArgs = {
  where: UserSupportRequestTypeWhereUniqueInput;
  create: UserSupportRequestTypeCreateInput;
  update: UserSupportRequestTypeUpdateInput;
};


export type MutationDeleteUserSupportRequestTypeArgs = {
  where: UserSupportRequestTypeWhereUniqueInput;
};


export type MutationCreateFieldHistoryArgs = {
  data: FieldHistoryCreateInput;
};


export type MutationUpdateFieldHistoryArgs = {
  data: FieldHistoryUpdateInput;
  where: FieldHistoryWhereUniqueInput;
};


export type MutationUpsertFieldHistoryArgs = {
  where: FieldHistoryWhereUniqueInput;
  create: FieldHistoryCreateInput;
  update: FieldHistoryUpdateInput;
};


export type MutationDeleteFieldHistoryArgs = {
  where: FieldHistoryWhereUniqueInput;
};


export type MutationCreateArchiveArgs = {
  data: ArchiveCreateInput;
};


export type MutationUpdateArchiveArgs = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};


export type MutationUpsertArchiveArgs = {
  where: ArchiveWhereUniqueInput;
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
};


export type MutationDeleteArchiveArgs = {
  where: ArchiveWhereUniqueInput;
};


export type MutationCreateClassifierArgs = {
  data: ClassifierCreateInput;
};


export type MutationUpdateClassifierArgs = {
  data: ClassifierUpdateInput;
  where: ClassifierWhereUniqueInput;
};


export type MutationUpsertClassifierArgs = {
  where: ClassifierWhereUniqueInput;
  create: ClassifierCreateInput;
  update: ClassifierUpdateInput;
};


export type MutationDeleteClassifierArgs = {
  where: ClassifierWhereUniqueInput;
};


export type MutationCreateClassifierValueArgs = {
  data: ClassifierValueCreateInput;
};


export type MutationUpdateClassifierValueArgs = {
  data: ClassifierValueUpdateInput;
  where: ClassifierValueWhereUniqueInput;
};


export type MutationUpsertClassifierValueArgs = {
  where: ClassifierValueWhereUniqueInput;
  create: ClassifierValueCreateInput;
  update: ClassifierValueUpdateInput;
};


export type MutationDeleteClassifierValueArgs = {
  where: ClassifierValueWhereUniqueInput;
};


export type MutationCreateDirRegionArgs = {
  data: DirRegionCreateInput;
};


export type MutationUpdateDirRegionArgs = {
  data: DirRegionUpdateInput;
  where: DirRegionWhereUniqueInput;
};


export type MutationUpsertDirRegionArgs = {
  where: DirRegionWhereUniqueInput;
  create: DirRegionCreateInput;
  update: DirRegionUpdateInput;
};


export type MutationDeleteDirRegionArgs = {
  where: DirRegionWhereUniqueInput;
};


export type MutationCreateDirFederalDistrictArgs = {
  data: DirFederalDistrictCreateInput;
};


export type MutationUpdateDirFederalDistrictArgs = {
  data: DirFederalDistrictUpdateInput;
  where: DirFederalDistrictWhereUniqueInput;
};


export type MutationUpsertDirFederalDistrictArgs = {
  where: DirFederalDistrictWhereUniqueInput;
  create: DirFederalDistrictCreateInput;
  update: DirFederalDistrictUpdateInput;
};


export type MutationDeleteDirFederalDistrictArgs = {
  where: DirFederalDistrictWhereUniqueInput;
};


export type MutationCreateDirForeignCityArgs = {
  data: DirForeignCityCreateInput;
};


export type MutationUpdateDirForeignCityArgs = {
  data: DirForeignCityUpdateInput;
  where: DirForeignCityWhereUniqueInput;
};


export type MutationUpsertDirForeignCityArgs = {
  where: DirForeignCityWhereUniqueInput;
  create: DirForeignCityCreateInput;
  update: DirForeignCityUpdateInput;
};


export type MutationDeleteDirForeignCityArgs = {
  where: DirForeignCityWhereUniqueInput;
};


export type MutationCreateDirCountryArgs = {
  data: DirCountryCreateInput;
};


export type MutationUpdateDirCountryArgs = {
  data: DirCountryUpdateInput;
  where: DirCountryWhereUniqueInput;
};


export type MutationUpsertDirCountryArgs = {
  where: DirCountryWhereUniqueInput;
  create: DirCountryCreateInput;
  update: DirCountryUpdateInput;
};


export type MutationDeleteDirCountryArgs = {
  where: DirCountryWhereUniqueInput;
};


export type MutationCreateDirOrganizationArgs = {
  data: DirOrganizationCreateInput;
};


export type MutationUpdateDirOrganizationArgs = {
  data: DirOrganizationUpdateInput;
  where: DirOrganizationWhereUniqueInput;
};


export type MutationUpsertDirOrganizationArgs = {
  where: DirOrganizationWhereUniqueInput;
  create: DirOrganizationCreateInput;
  update: DirOrganizationUpdateInput;
};


export type MutationDeleteDirOrganizationArgs = {
  where: DirOrganizationWhereUniqueInput;
};


export type MutationCreateDirOrganizationSportArgs = {
  data: DirOrganizationSportCreateInput;
};


export type MutationUpdateDirOrganizationSportArgs = {
  data: DirOrganizationSportUpdateInput;
  where: DirOrganizationSportWhereUniqueInput;
};


export type MutationUpsertDirOrganizationSportArgs = {
  where: DirOrganizationSportWhereUniqueInput;
  create: DirOrganizationSportCreateInput;
  update: DirOrganizationSportUpdateInput;
};


export type MutationDeleteDirOrganizationSportArgs = {
  where: DirOrganizationSportWhereUniqueInput;
};


export type MutationCreateDirOrganizationAddressArgs = {
  data: DirOrganizationAddressCreateInput;
};


export type MutationUpdateDirOrganizationAddressArgs = {
  data: DirOrganizationAddressUpdateInput;
  where: DirOrganizationAddressWhereUniqueInput;
};


export type MutationUpsertDirOrganizationAddressArgs = {
  where: DirOrganizationAddressWhereUniqueInput;
  create: DirOrganizationAddressCreateInput;
  update: DirOrganizationAddressUpdateInput;
};


export type MutationDeleteDirOrganizationAddressArgs = {
  where: DirOrganizationAddressWhereUniqueInput;
};


export type MutationCreateDirOrganizationPhoneArgs = {
  data: DirOrganizationPhoneCreateInput;
};


export type MutationUpdateDirOrganizationPhoneArgs = {
  data: DirOrganizationPhoneUpdateInput;
  where: DirOrganizationPhoneWhereUniqueInput;
};


export type MutationUpsertDirOrganizationPhoneArgs = {
  where: DirOrganizationPhoneWhereUniqueInput;
  create: DirOrganizationPhoneCreateInput;
  update: DirOrganizationPhoneUpdateInput;
};


export type MutationDeleteDirOrganizationPhoneArgs = {
  where: DirOrganizationPhoneWhereUniqueInput;
};


export type MutationCreateDirOrganizationEmailArgs = {
  data: DirOrganizationEmailCreateInput;
};


export type MutationUpdateDirOrganizationEmailArgs = {
  data: DirOrganizationEmailUpdateInput;
  where: DirOrganizationEmailWhereUniqueInput;
};


export type MutationUpsertDirOrganizationEmailArgs = {
  where: DirOrganizationEmailWhereUniqueInput;
  create: DirOrganizationEmailCreateInput;
  update: DirOrganizationEmailUpdateInput;
};


export type MutationDeleteDirOrganizationEmailArgs = {
  where: DirOrganizationEmailWhereUniqueInput;
};


export type MutationCreateDirPersonArgs = {
  data: DirPersonCreateInput;
};


export type MutationUpdateDirPersonArgs = {
  data: DirPersonUpdateInput;
  where: DirPersonWhereUniqueInput;
};


export type MutationUpsertDirPersonArgs = {
  where: DirPersonWhereUniqueInput;
  create: DirPersonCreateInput;
  update: DirPersonUpdateInput;
};


export type MutationDeleteDirPersonArgs = {
  where: DirPersonWhereUniqueInput;
};


export type MutationCreateDirPersonPhoneArgs = {
  data: DirPersonPhoneCreateInput;
};


export type MutationUpdateDirPersonPhoneArgs = {
  data: DirPersonPhoneUpdateInput;
  where: DirPersonPhoneWhereUniqueInput;
};


export type MutationUpsertDirPersonPhoneArgs = {
  where: DirPersonPhoneWhereUniqueInput;
  create: DirPersonPhoneCreateInput;
  update: DirPersonPhoneUpdateInput;
};


export type MutationDeleteDirPersonPhoneArgs = {
  where: DirPersonPhoneWhereUniqueInput;
};


export type MutationCreateDirPersonConfirmStatusArgs = {
  data: DirPersonConfirmStatusCreateInput;
};


export type MutationUpdateDirPersonConfirmStatusArgs = {
  data: DirPersonConfirmStatusUpdateInput;
  where: DirPersonConfirmStatusWhereUniqueInput;
};


export type MutationUpsertDirPersonConfirmStatusArgs = {
  where: DirPersonConfirmStatusWhereUniqueInput;
  create: DirPersonConfirmStatusCreateInput;
  update: DirPersonConfirmStatusUpdateInput;
};


export type MutationDeleteDirPersonConfirmStatusArgs = {
  where: DirPersonConfirmStatusWhereUniqueInput;
};


export type MutationCreateDirPersonAdditionalRegionArgs = {
  data: DirPersonAdditionalRegionCreateInput;
};


export type MutationUpdateDirPersonAdditionalRegionArgs = {
  data: DirPersonAdditionalRegionUpdateInput;
  where: DirPersonAdditionalRegionWhereUniqueInput;
};


export type MutationUpsertDirPersonAdditionalRegionArgs = {
  where: DirPersonAdditionalRegionWhereUniqueInput;
  create: DirPersonAdditionalRegionCreateInput;
  update: DirPersonAdditionalRegionUpdateInput;
};


export type MutationDeleteDirPersonAdditionalRegionArgs = {
  where: DirPersonAdditionalRegionWhereUniqueInput;
};


export type MutationCreateDirPersonMergeTaskArgs = {
  data: DirPersonMergeTaskCreateInput;
};


export type MutationUpdateDirPersonMergeTaskArgs = {
  data: DirPersonMergeTaskUpdateInput;
  where: DirPersonMergeTaskWhereUniqueInput;
};


export type MutationUpsertDirPersonMergeTaskArgs = {
  where: DirPersonMergeTaskWhereUniqueInput;
  create: DirPersonMergeTaskCreateInput;
  update: DirPersonMergeTaskUpdateInput;
};


export type MutationDeleteDirPersonMergeTaskArgs = {
  where: DirPersonMergeTaskWhereUniqueInput;
};


export type MutationCreateDirPersonAddressArgs = {
  data: DirPersonAddressCreateInput;
};


export type MutationUpdateDirPersonAddressArgs = {
  data: DirPersonAddressUpdateInput;
  where: DirPersonAddressWhereUniqueInput;
};


export type MutationUpsertDirPersonAddressArgs = {
  where: DirPersonAddressWhereUniqueInput;
  create: DirPersonAddressCreateInput;
  update: DirPersonAddressUpdateInput;
};


export type MutationDeleteDirPersonAddressArgs = {
  where: DirPersonAddressWhereUniqueInput;
};


export type MutationCreateDirSportArgs = {
  data: DirSportCreateInput;
};


export type MutationUpdateDirSportArgs = {
  data: DirSportUpdateInput;
  where: DirSportWhereUniqueInput;
};


export type MutationUpsertDirSportArgs = {
  where: DirSportWhereUniqueInput;
  create: DirSportCreateInput;
  update: DirSportUpdateInput;
};


export type MutationDeleteDirSportArgs = {
  where: DirSportWhereUniqueInput;
};


export type MutationCreateDirSportDisciplineArgs = {
  data: DirSportDisciplineCreateInput;
};


export type MutationUpdateDirSportDisciplineArgs = {
  data: DirSportDisciplineUpdateInput;
  where: DirSportDisciplineWhereUniqueInput;
};


export type MutationUpsertDirSportDisciplineArgs = {
  where: DirSportDisciplineWhereUniqueInput;
  create: DirSportDisciplineCreateInput;
  update: DirSportDisciplineUpdateInput;
};


export type MutationDeleteDirSportDisciplineArgs = {
  where: DirSportDisciplineWhereUniqueInput;
};


export type MutationCreateDirSportDisciplineGroupArgs = {
  data: DirSportDisciplineGroupCreateInput;
};


export type MutationUpdateDirSportDisciplineGroupArgs = {
  data: DirSportDisciplineGroupUpdateInput;
  where: DirSportDisciplineGroupWhereUniqueInput;
};


export type MutationUpsertDirSportDisciplineGroupArgs = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  create: DirSportDisciplineGroupCreateInput;
  update: DirSportDisciplineGroupUpdateInput;
};


export type MutationDeleteDirSportDisciplineGroupArgs = {
  where: DirSportDisciplineGroupWhereUniqueInput;
};


export type MutationCreateDirSportAgeGroupArgs = {
  data: DirSportAgeGroupCreateInput;
};


export type MutationUpdateDirSportAgeGroupArgs = {
  data: DirSportAgeGroupUpdateInput;
  where: DirSportAgeGroupWhereUniqueInput;
};


export type MutationUpsertDirSportAgeGroupArgs = {
  where: DirSportAgeGroupWhereUniqueInput;
  create: DirSportAgeGroupCreateInput;
  update: DirSportAgeGroupUpdateInput;
};


export type MutationDeleteDirSportAgeGroupArgs = {
  where: DirSportAgeGroupWhereUniqueInput;
};


export type MutationCreateDirSportAgeGroupParticipationRequirementsArgs = {
  data: DirSportAgeGroupParticipationRequirementsCreateInput;
};


export type MutationUpdateDirSportAgeGroupParticipationRequirementsArgs = {
  data: DirSportAgeGroupParticipationRequirementsUpdateInput;
  where: DirSportAgeGroupParticipationRequirementsWhereUniqueInput;
};


export type MutationUpsertDirSportAgeGroupParticipationRequirementsArgs = {
  where: DirSportAgeGroupParticipationRequirementsWhereUniqueInput;
  create: DirSportAgeGroupParticipationRequirementsCreateInput;
  update: DirSportAgeGroupParticipationRequirementsUpdateInput;
};


export type MutationDeleteDirSportAgeGroupParticipationRequirementsArgs = {
  where: DirSportAgeGroupParticipationRequirementsWhereUniqueInput;
};


export type MutationCreateDirSportTrainingStageArgs = {
  data: DirSportTrainingStageCreateInput;
};


export type MutationUpdateDirSportTrainingStageArgs = {
  data: DirSportTrainingStageUpdateInput;
  where: DirSportTrainingStageWhereUniqueInput;
};


export type MutationUpsertDirSportTrainingStageArgs = {
  where: DirSportTrainingStageWhereUniqueInput;
  create: DirSportTrainingStageCreateInput;
  update: DirSportTrainingStageUpdateInput;
};


export type MutationDeleteDirSportTrainingStageArgs = {
  where: DirSportTrainingStageWhereUniqueInput;
};


export type MutationCreateDirSportBaseRegionArgs = {
  data: DirSportBaseRegionCreateInput;
};


export type MutationUpdateDirSportBaseRegionArgs = {
  data: DirSportBaseRegionUpdateInput;
  where: DirSportBaseRegionWhereUniqueInput;
};


export type MutationUpsertDirSportBaseRegionArgs = {
  where: DirSportBaseRegionWhereUniqueInput;
  create: DirSportBaseRegionCreateInput;
  update: DirSportBaseRegionUpdateInput;
};


export type MutationDeleteDirSportBaseRegionArgs = {
  where: DirSportBaseRegionWhereUniqueInput;
};


export type MutationCreateDirAthleteArgs = {
  data: DirAthleteCreateInput;
};


export type MutationUpdateDirAthleteArgs = {
  data: DirAthleteUpdateInput;
  where: DirAthleteWhereUniqueInput;
};


export type MutationUpsertDirAthleteArgs = {
  where: DirAthleteWhereUniqueInput;
  create: DirAthleteCreateInput;
  update: DirAthleteUpdateInput;
};


export type MutationDeleteDirAthleteArgs = {
  where: DirAthleteWhereUniqueInput;
};


export type MutationCreateDirAthleteSportArgs = {
  data: DirAthleteSportCreateInput;
};


export type MutationUpdateDirAthleteSportArgs = {
  data: DirAthleteSportUpdateInput;
  where: DirAthleteSportWhereUniqueInput;
};


export type MutationUpsertDirAthleteSportArgs = {
  where: DirAthleteSportWhereUniqueInput;
  create: DirAthleteSportCreateInput;
  update: DirAthleteSportUpdateInput;
};


export type MutationDeleteDirAthleteSportArgs = {
  where: DirAthleteSportWhereUniqueInput;
};


export type MutationCreateDirAthleteSportOrganizationArgs = {
  data: DirAthleteSportOrganizationCreateInput;
};


export type MutationUpdateDirAthleteSportOrganizationArgs = {
  data: DirAthleteSportOrganizationUpdateInput;
  where: DirAthleteSportOrganizationWhereUniqueInput;
};


export type MutationUpsertDirAthleteSportOrganizationArgs = {
  where: DirAthleteSportOrganizationWhereUniqueInput;
  create: DirAthleteSportOrganizationCreateInput;
  update: DirAthleteSportOrganizationUpdateInput;
};


export type MutationDeleteDirAthleteSportOrganizationArgs = {
  where: DirAthleteSportOrganizationWhereUniqueInput;
};


export type MutationCreateDirAthleteSportRankAndAwardArgs = {
  data: DirAthleteSportRankAndAwardCreateInput;
};


export type MutationUpdateDirAthleteSportRankAndAwardArgs = {
  data: DirAthleteSportRankAndAwardUpdateInput;
  where: DirAthleteSportRankAndAwardWhereUniqueInput;
};


export type MutationUpsertDirAthleteSportRankAndAwardArgs = {
  where: DirAthleteSportRankAndAwardWhereUniqueInput;
  create: DirAthleteSportRankAndAwardCreateInput;
  update: DirAthleteSportRankAndAwardUpdateInput;
};


export type MutationDeleteDirAthleteSportRankAndAwardArgs = {
  where: DirAthleteSportRankAndAwardWhereUniqueInput;
};


export type MutationCreateDirAthleteMedCertArgs = {
  data: DirAthleteMedCertCreateInput;
};


export type MutationUpdateDirAthleteMedCertArgs = {
  data: DirAthleteMedCertUpdateInput;
  where: DirAthleteMedCertWhereUniqueInput;
};


export type MutationUpsertDirAthleteMedCertArgs = {
  where: DirAthleteMedCertWhereUniqueInput;
  create: DirAthleteMedCertCreateInput;
  update: DirAthleteMedCertUpdateInput;
};


export type MutationDeleteDirAthleteMedCertArgs = {
  where: DirAthleteMedCertWhereUniqueInput;
};


export type MutationCreateDirAthleteMedCertSportArgs = {
  data: DirAthleteMedCertSportCreateInput;
};


export type MutationUpdateDirAthleteMedCertSportArgs = {
  data: DirAthleteMedCertSportUpdateInput;
  where: DirAthleteMedCertSportWhereUniqueInput;
};


export type MutationUpsertDirAthleteMedCertSportArgs = {
  where: DirAthleteMedCertSportWhereUniqueInput;
  create: DirAthleteMedCertSportCreateInput;
  update: DirAthleteMedCertSportUpdateInput;
};


export type MutationDeleteDirAthleteMedCertSportArgs = {
  where: DirAthleteMedCertSportWhereUniqueInput;
};


export type MutationCreateDirAthleteCompetitionResultArgs = {
  data: DirAthleteCompetitionResultCreateInput;
};


export type MutationUpdateDirAthleteCompetitionResultArgs = {
  data: DirAthleteCompetitionResultUpdateInput;
  where: DirAthleteCompetitionResultWhereUniqueInput;
};


export type MutationUpsertDirAthleteCompetitionResultArgs = {
  where: DirAthleteCompetitionResultWhereUniqueInput;
  create: DirAthleteCompetitionResultCreateInput;
  update: DirAthleteCompetitionResultUpdateInput;
};


export type MutationDeleteDirAthleteCompetitionResultArgs = {
  where: DirAthleteCompetitionResultWhereUniqueInput;
};


export type MutationCreateDirTrainerArgs = {
  data: DirTrainerCreateInput;
};


export type MutationUpdateDirTrainerArgs = {
  data: DirTrainerUpdateInput;
  where: DirTrainerWhereUniqueInput;
};


export type MutationUpsertDirTrainerArgs = {
  where: DirTrainerWhereUniqueInput;
  create: DirTrainerCreateInput;
  update: DirTrainerUpdateInput;
};


export type MutationDeleteDirTrainerArgs = {
  where: DirTrainerWhereUniqueInput;
};


export type MutationCreateDirTrainerSportArgs = {
  data: DirTrainerSportCreateInput;
};


export type MutationUpdateDirTrainerSportArgs = {
  data: DirTrainerSportUpdateInput;
  where: DirTrainerSportWhereUniqueInput;
};


export type MutationUpsertDirTrainerSportArgs = {
  where: DirTrainerSportWhereUniqueInput;
  create: DirTrainerSportCreateInput;
  update: DirTrainerSportUpdateInput;
};


export type MutationDeleteDirTrainerSportArgs = {
  where: DirTrainerSportWhereUniqueInput;
};


export type MutationCreateDirTrainerSportOrganizationArgs = {
  data: DirTrainerSportOrganizationCreateInput;
};


export type MutationUpdateDirTrainerSportOrganizationArgs = {
  data: DirTrainerSportOrganizationUpdateInput;
  where: DirTrainerSportOrganizationWhereUniqueInput;
};


export type MutationUpsertDirTrainerSportOrganizationArgs = {
  where: DirTrainerSportOrganizationWhereUniqueInput;
  create: DirTrainerSportOrganizationCreateInput;
  update: DirTrainerSportOrganizationUpdateInput;
};


export type MutationDeleteDirTrainerSportOrganizationArgs = {
  where: DirTrainerSportOrganizationWhereUniqueInput;
};


export type MutationCreateDirTrainerSportCategoryArgs = {
  data: DirTrainerSportCategoryCreateInput;
};


export type MutationUpdateDirTrainerSportCategoryArgs = {
  data: DirTrainerSportCategoryUpdateInput;
  where: DirTrainerSportCategoryWhereUniqueInput;
};


export type MutationUpsertDirTrainerSportCategoryArgs = {
  where: DirTrainerSportCategoryWhereUniqueInput;
  create: DirTrainerSportCategoryCreateInput;
  update: DirTrainerSportCategoryUpdateInput;
};


export type MutationDeleteDirTrainerSportCategoryArgs = {
  where: DirTrainerSportCategoryWhereUniqueInput;
};


export type MutationCreateDirRefereeArgs = {
  data: DirRefereeCreateInput;
};


export type MutationUpdateDirRefereeArgs = {
  data: DirRefereeUpdateInput;
  where: DirRefereeWhereUniqueInput;
};


export type MutationUpsertDirRefereeArgs = {
  where: DirRefereeWhereUniqueInput;
  create: DirRefereeCreateInput;
  update: DirRefereeUpdateInput;
};


export type MutationDeleteDirRefereeArgs = {
  where: DirRefereeWhereUniqueInput;
};


export type MutationCreateDirRefereeSportArgs = {
  data: DirRefereeSportCreateInput;
};


export type MutationUpdateDirRefereeSportArgs = {
  data: DirRefereeSportUpdateInput;
  where: DirRefereeSportWhereUniqueInput;
};


export type MutationUpsertDirRefereeSportArgs = {
  where: DirRefereeSportWhereUniqueInput;
  create: DirRefereeSportCreateInput;
  update: DirRefereeSportUpdateInput;
};


export type MutationDeleteDirRefereeSportArgs = {
  where: DirRefereeSportWhereUniqueInput;
};


export type MutationCreateDirRefereeSportCategoryArgs = {
  data: DirRefereeSportCategoryCreateInput;
};


export type MutationUpdateDirRefereeSportCategoryArgs = {
  data: DirRefereeSportCategoryUpdateInput;
  where: DirRefereeSportCategoryWhereUniqueInput;
};


export type MutationUpsertDirRefereeSportCategoryArgs = {
  where: DirRefereeSportCategoryWhereUniqueInput;
  create: DirRefereeSportCategoryCreateInput;
  update: DirRefereeSportCategoryUpdateInput;
};


export type MutationDeleteDirRefereeSportCategoryArgs = {
  where: DirRefereeSportCategoryWhereUniqueInput;
};


export type MutationCreateDirSportingEventArgs = {
  data: DirSportingEventCreateInput;
};


export type MutationUpdateDirSportingEventArgs = {
  data: DirSportingEventUpdateInput;
  where: DirSportingEventWhereUniqueInput;
};


export type MutationUpsertDirSportingEventArgs = {
  where: DirSportingEventWhereUniqueInput;
  create: DirSportingEventCreateInput;
  update: DirSportingEventUpdateInput;
};


export type MutationDeleteDirSportingEventArgs = {
  where: DirSportingEventWhereUniqueInput;
};


export type MutationCreateQuantyRequestStageTwoArgs = {
  data: QuantyRequestStageTwoCreateInput;
};


export type MutationUpdateQuantyRequestStageTwoArgs = {
  data: QuantyRequestStageTwoUpdateInput;
  where: QuantyRequestStageTwoWhereUniqueInput;
};


export type MutationUpsertQuantyRequestStageTwoArgs = {
  where: QuantyRequestStageTwoWhereUniqueInput;
  create: QuantyRequestStageTwoCreateInput;
  update: QuantyRequestStageTwoUpdateInput;
};


export type MutationDeleteQuantyRequestStageTwoArgs = {
  where: QuantyRequestStageTwoWhereUniqueInput;
};


export type MutationCreateDirSportingEventAthleteCallNotificationArgs = {
  data: DirSportingEventAthleteCallNotificationCreateInput;
};


export type MutationUpdateDirSportingEventAthleteCallNotificationArgs = {
  data: DirSportingEventAthleteCallNotificationUpdateInput;
  where: DirSportingEventAthleteCallNotificationWhereUniqueInput;
};


export type MutationUpsertDirSportingEventAthleteCallNotificationArgs = {
  where: DirSportingEventAthleteCallNotificationWhereUniqueInput;
  create: DirSportingEventAthleteCallNotificationCreateInput;
  update: DirSportingEventAthleteCallNotificationUpdateInput;
};


export type MutationDeleteDirSportingEventAthleteCallNotificationArgs = {
  where: DirSportingEventAthleteCallNotificationWhereUniqueInput;
};


export type MutationCreateDirSportingEventAthleteCallNotificationTeamArgs = {
  data: DirSportingEventAthleteCallNotificationTeamCreateInput;
};


export type MutationUpdateDirSportingEventAthleteCallNotificationTeamArgs = {
  data: DirSportingEventAthleteCallNotificationTeamUpdateInput;
  where: DirSportingEventAthleteCallNotificationTeamWhereUniqueInput;
};


export type MutationUpsertDirSportingEventAthleteCallNotificationTeamArgs = {
  where: DirSportingEventAthleteCallNotificationTeamWhereUniqueInput;
  create: DirSportingEventAthleteCallNotificationTeamCreateInput;
  update: DirSportingEventAthleteCallNotificationTeamUpdateInput;
};


export type MutationDeleteDirSportingEventAthleteCallNotificationTeamArgs = {
  where: DirSportingEventAthleteCallNotificationTeamWhereUniqueInput;
};


export type MutationCreateDirSportingEventCountryArgs = {
  data: DirSportingEventCountryCreateInput;
};


export type MutationUpdateDirSportingEventCountryArgs = {
  data: DirSportingEventCountryUpdateInput;
  where: DirSportingEventCountryWhereUniqueInput;
};


export type MutationUpsertDirSportingEventCountryArgs = {
  where: DirSportingEventCountryWhereUniqueInput;
  create: DirSportingEventCountryCreateInput;
  update: DirSportingEventCountryUpdateInput;
};


export type MutationDeleteDirSportingEventCountryArgs = {
  where: DirSportingEventCountryWhereUniqueInput;
};


export type MutationCreateDirSportingEventSportArgs = {
  data: DirSportingEventSportCreateInput;
};


export type MutationUpdateDirSportingEventSportArgs = {
  data: DirSportingEventSportUpdateInput;
  where: DirSportingEventSportWhereUniqueInput;
};


export type MutationUpsertDirSportingEventSportArgs = {
  where: DirSportingEventSportWhereUniqueInput;
  create: DirSportingEventSportCreateInput;
  update: DirSportingEventSportUpdateInput;
};


export type MutationDeleteDirSportingEventSportArgs = {
  where: DirSportingEventSportWhereUniqueInput;
};


export type MutationCreateDirSportingEventAgeGroupArgs = {
  data: DirSportingEventAgeGroupCreateInput;
};


export type MutationUpdateDirSportingEventAgeGroupArgs = {
  data: DirSportingEventAgeGroupUpdateInput;
  where: DirSportingEventAgeGroupWhereUniqueInput;
};


export type MutationUpsertDirSportingEventAgeGroupArgs = {
  where: DirSportingEventAgeGroupWhereUniqueInput;
  create: DirSportingEventAgeGroupCreateInput;
  update: DirSportingEventAgeGroupUpdateInput;
};


export type MutationDeleteDirSportingEventAgeGroupArgs = {
  where: DirSportingEventAgeGroupWhereUniqueInput;
};


export type MutationCreateDirSportingEventStatusArgs = {
  data: DirSportingEventStatusCreateInput;
};


export type MutationUpdateDirSportingEventStatusArgs = {
  data: DirSportingEventStatusUpdateInput;
  where: DirSportingEventStatusWhereUniqueInput;
};


export type MutationUpsertDirSportingEventStatusArgs = {
  where: DirSportingEventStatusWhereUniqueInput;
  create: DirSportingEventStatusCreateInput;
  update: DirSportingEventStatusUpdateInput;
};


export type MutationDeleteDirSportingEventStatusArgs = {
  where: DirSportingEventStatusWhereUniqueInput;
};


export type MutationCreateDirSportingEventStatusHistoryArgs = {
  data: DirSportingEventStatusHistoryCreateInput;
};


export type MutationUpdateDirSportingEventStatusHistoryArgs = {
  data: DirSportingEventStatusHistoryUpdateInput;
  where: DirSportingEventStatusHistoryWhereUniqueInput;
};


export type MutationUpsertDirSportingEventStatusHistoryArgs = {
  where: DirSportingEventStatusHistoryWhereUniqueInput;
  create: DirSportingEventStatusHistoryCreateInput;
  update: DirSportingEventStatusHistoryUpdateInput;
};


export type MutationDeleteDirSportingEventStatusHistoryArgs = {
  where: DirSportingEventStatusHistoryWhereUniqueInput;
};


export type MutationCreateDirSportingEventExtendedStatusArgs = {
  data: DirSportingEventExtendedStatusCreateInput;
};


export type MutationUpdateDirSportingEventExtendedStatusArgs = {
  data: DirSportingEventExtendedStatusUpdateInput;
  where: DirSportingEventExtendedStatusWhereUniqueInput;
};


export type MutationUpsertDirSportingEventExtendedStatusArgs = {
  where: DirSportingEventExtendedStatusWhereUniqueInput;
  create: DirSportingEventExtendedStatusCreateInput;
  update: DirSportingEventExtendedStatusUpdateInput;
};


export type MutationDeleteDirSportingEventExtendedStatusArgs = {
  where: DirSportingEventExtendedStatusWhereUniqueInput;
};


export type MutationCreateDirSportingEventExtendedStatusValueArgs = {
  data: DirSportingEventExtendedStatusValueCreateInput;
};


export type MutationUpdateDirSportingEventExtendedStatusValueArgs = {
  data: DirSportingEventExtendedStatusValueUpdateInput;
  where: DirSportingEventExtendedStatusValueWhereUniqueInput;
};


export type MutationUpsertDirSportingEventExtendedStatusValueArgs = {
  where: DirSportingEventExtendedStatusValueWhereUniqueInput;
  create: DirSportingEventExtendedStatusValueCreateInput;
  update: DirSportingEventExtendedStatusValueUpdateInput;
};


export type MutationDeleteDirSportingEventExtendedStatusValueArgs = {
  where: DirSportingEventExtendedStatusValueWhereUniqueInput;
};


export type MutationCreateDirSportingEventProgramTypeArgs = {
  data: DirSportingEventProgramTypeCreateInput;
};


export type MutationUpdateDirSportingEventProgramTypeArgs = {
  data: DirSportingEventProgramTypeUpdateInput;
  where: DirSportingEventProgramTypeWhereUniqueInput;
};


export type MutationUpsertDirSportingEventProgramTypeArgs = {
  where: DirSportingEventProgramTypeWhereUniqueInput;
  create: DirSportingEventProgramTypeCreateInput;
  update: DirSportingEventProgramTypeUpdateInput;
};


export type MutationDeleteDirSportingEventProgramTypeArgs = {
  where: DirSportingEventProgramTypeWhereUniqueInput;
};


export type MutationCreateDirGenderAndAgeGroupsArgs = {
  data: DirGenderAndAgeGroupsCreateInput;
};


export type MutationUpdateDirGenderAndAgeGroupsArgs = {
  data: DirGenderAndAgeGroupsUpdateInput;
  where: DirGenderAndAgeGroupsWhereUniqueInput;
};


export type MutationUpsertDirGenderAndAgeGroupsArgs = {
  where: DirGenderAndAgeGroupsWhereUniqueInput;
  create: DirGenderAndAgeGroupsCreateInput;
  update: DirGenderAndAgeGroupsUpdateInput;
};


export type MutationDeleteDirGenderAndAgeGroupsArgs = {
  where: DirGenderAndAgeGroupsWhereUniqueInput;
};


export type MutationCreateDirSportingEventRefereeArgs = {
  data: DirSportingEventRefereeCreateInput;
};


export type MutationUpdateDirSportingEventRefereeArgs = {
  data: DirSportingEventRefereeUpdateInput;
  where: DirSportingEventRefereeWhereUniqueInput;
};


export type MutationUpsertDirSportingEventRefereeArgs = {
  where: DirSportingEventRefereeWhereUniqueInput;
  create: DirSportingEventRefereeCreateInput;
  update: DirSportingEventRefereeUpdateInput;
};


export type MutationDeleteDirSportingEventRefereeArgs = {
  where: DirSportingEventRefereeWhereUniqueInput;
};


export type MutationCreateDirSportingEventRefereeStatusArgs = {
  data: DirSportingEventRefereeStatusCreateInput;
};


export type MutationUpdateDirSportingEventRefereeStatusArgs = {
  data: DirSportingEventRefereeStatusUpdateInput;
  where: DirSportingEventRefereeStatusWhereUniqueInput;
};


export type MutationUpsertDirSportingEventRefereeStatusArgs = {
  where: DirSportingEventRefereeStatusWhereUniqueInput;
  create: DirSportingEventRefereeStatusCreateInput;
  update: DirSportingEventRefereeStatusUpdateInput;
};


export type MutationDeleteDirSportingEventRefereeStatusArgs = {
  where: DirSportingEventRefereeStatusWhereUniqueInput;
};


export type MutationCreateDirSportingEventRefereeStatusValueArgs = {
  data: DirSportingEventRefereeStatusValueCreateInput;
};


export type MutationUpdateDirSportingEventRefereeStatusValueArgs = {
  data: DirSportingEventRefereeStatusValueUpdateInput;
  where: DirSportingEventRefereeStatusValueWhereUniqueInput;
};


export type MutationUpsertDirSportingEventRefereeStatusValueArgs = {
  where: DirSportingEventRefereeStatusValueWhereUniqueInput;
  create: DirSportingEventRefereeStatusValueCreateInput;
  update: DirSportingEventRefereeStatusValueUpdateInput;
};


export type MutationDeleteDirSportingEventRefereeStatusValueArgs = {
  where: DirSportingEventRefereeStatusValueWhereUniqueInput;
};


export type MutationCreateDirSportingEventCompetitionCalendarArgs = {
  data: DirSportingEventCompetitionCalendarCreateInput;
};


export type MutationUpdateDirSportingEventCompetitionCalendarArgs = {
  data: DirSportingEventCompetitionCalendarUpdateInput;
  where: DirSportingEventCompetitionCalendarWhereUniqueInput;
};


export type MutationUpsertDirSportingEventCompetitionCalendarArgs = {
  where: DirSportingEventCompetitionCalendarWhereUniqueInput;
  create: DirSportingEventCompetitionCalendarCreateInput;
  update: DirSportingEventCompetitionCalendarUpdateInput;
};


export type MutationDeleteDirSportingEventCompetitionCalendarArgs = {
  where: DirSportingEventCompetitionCalendarWhereUniqueInput;
};


export type MutationCreateDirSportingEventCostArgs = {
  data: DirSportingEventCostCreateInput;
};


export type MutationUpdateDirSportingEventCostArgs = {
  data: DirSportingEventCostUpdateInput;
  where: DirSportingEventCostWhereUniqueInput;
};


export type MutationUpsertDirSportingEventCostArgs = {
  where: DirSportingEventCostWhereUniqueInput;
  create: DirSportingEventCostCreateInput;
  update: DirSportingEventCostUpdateInput;
};


export type MutationDeleteDirSportingEventCostArgs = {
  where: DirSportingEventCostWhereUniqueInput;
};


export type MutationCreateDirSportingEventQuotaArgs = {
  data: DirSportingEventQuotaCreateInput;
};


export type MutationUpdateDirSportingEventQuotaArgs = {
  data: DirSportingEventQuotaUpdateInput;
  where: DirSportingEventQuotaWhereUniqueInput;
};


export type MutationUpsertDirSportingEventQuotaArgs = {
  where: DirSportingEventQuotaWhereUniqueInput;
  create: DirSportingEventQuotaCreateInput;
  update: DirSportingEventQuotaUpdateInput;
};


export type MutationDeleteDirSportingEventQuotaArgs = {
  where: DirSportingEventQuotaWhereUniqueInput;
};


export type MutationCreateDirSportingEventQuantityArgs = {
  data: DirSportingEventQuantityCreateInput;
};


export type MutationUpdateDirSportingEventQuantityArgs = {
  data: DirSportingEventQuantityUpdateInput;
  where: DirSportingEventQuantityWhereUniqueInput;
};


export type MutationUpsertDirSportingEventQuantityArgs = {
  where: DirSportingEventQuantityWhereUniqueInput;
  create: DirSportingEventQuantityCreateInput;
  update: DirSportingEventQuantityUpdateInput;
};


export type MutationDeleteDirSportingEventQuantityArgs = {
  where: DirSportingEventQuantityWhereUniqueInput;
};


export type MutationCreateDirSportingEventPointDistributionArgs = {
  data: DirSportingEventPointDistributionCreateInput;
};


export type MutationUpdateDirSportingEventPointDistributionArgs = {
  data: DirSportingEventPointDistributionUpdateInput;
  where: DirSportingEventPointDistributionWhereUniqueInput;
};


export type MutationUpsertDirSportingEventPointDistributionArgs = {
  where: DirSportingEventPointDistributionWhereUniqueInput;
  create: DirSportingEventPointDistributionCreateInput;
  update: DirSportingEventPointDistributionUpdateInput;
};


export type MutationDeleteDirSportingEventPointDistributionArgs = {
  where: DirSportingEventPointDistributionWhereUniqueInput;
};


export type MutationCreateDirSportingEventPointDistributionPlaceArgs = {
  data: DirSportingEventPointDistributionPlaceCreateInput;
};


export type MutationUpdateDirSportingEventPointDistributionPlaceArgs = {
  data: DirSportingEventPointDistributionPlaceUpdateInput;
  where: DirSportingEventPointDistributionPlaceWhereUniqueInput;
};


export type MutationUpsertDirSportingEventPointDistributionPlaceArgs = {
  where: DirSportingEventPointDistributionPlaceWhereUniqueInput;
  create: DirSportingEventPointDistributionPlaceCreateInput;
  update: DirSportingEventPointDistributionPlaceUpdateInput;
};


export type MutationDeleteDirSportingEventPointDistributionPlaceArgs = {
  where: DirSportingEventPointDistributionPlaceWhereUniqueInput;
};


export type MutationCreateDirSportingEventRequestArgs = {
  data: DirSportingEventRequestCreateInput;
};


export type MutationUpdateDirSportingEventRequestArgs = {
  data: DirSportingEventRequestUpdateInput;
  where: DirSportingEventRequestWhereUniqueInput;
};


export type MutationUpsertDirSportingEventRequestArgs = {
  where: DirSportingEventRequestWhereUniqueInput;
  create: DirSportingEventRequestCreateInput;
  update: DirSportingEventRequestUpdateInput;
};


export type MutationDeleteDirSportingEventRequestArgs = {
  where: DirSportingEventRequestWhereUniqueInput;
};


export type MutationCreateDirSportingEventRequestTypeArgs = {
  data: DirSportingEventRequestTypeCreateInput;
};


export type MutationUpdateDirSportingEventRequestTypeArgs = {
  data: DirSportingEventRequestTypeUpdateInput;
  where: DirSportingEventRequestTypeWhereUniqueInput;
};


export type MutationUpsertDirSportingEventRequestTypeArgs = {
  where: DirSportingEventRequestTypeWhereUniqueInput;
  create: DirSportingEventRequestTypeCreateInput;
  update: DirSportingEventRequestTypeUpdateInput;
};


export type MutationDeleteDirSportingEventRequestTypeArgs = {
  where: DirSportingEventRequestTypeWhereUniqueInput;
};


export type MutationCreateDirSportingEventRequestStatusArgs = {
  data: DirSportingEventRequestStatusCreateInput;
};


export type MutationUpdateDirSportingEventRequestStatusArgs = {
  data: DirSportingEventRequestStatusUpdateInput;
  where: DirSportingEventRequestStatusWhereUniqueInput;
};


export type MutationUpsertDirSportingEventRequestStatusArgs = {
  where: DirSportingEventRequestStatusWhereUniqueInput;
  create: DirSportingEventRequestStatusCreateInput;
  update: DirSportingEventRequestStatusUpdateInput;
};


export type MutationDeleteDirSportingEventRequestStatusArgs = {
  where: DirSportingEventRequestStatusWhereUniqueInput;
};


export type MutationCreateDirSportingEventRequestStatusValueArgs = {
  data: DirSportingEventRequestStatusValueCreateInput;
};


export type MutationUpdateDirSportingEventRequestStatusValueArgs = {
  data: DirSportingEventRequestStatusValueUpdateInput;
  where: DirSportingEventRequestStatusValueWhereUniqueInput;
};


export type MutationUpsertDirSportingEventRequestStatusValueArgs = {
  where: DirSportingEventRequestStatusValueWhereUniqueInput;
  create: DirSportingEventRequestStatusValueCreateInput;
  update: DirSportingEventRequestStatusValueUpdateInput;
};


export type MutationDeleteDirSportingEventRequestStatusValueArgs = {
  where: DirSportingEventRequestStatusValueWhereUniqueInput;
};


export type MutationCreateDirNationalTeamArgs = {
  data: DirNationalTeamCreateInput;
};


export type MutationUpdateDirNationalTeamArgs = {
  data: DirNationalTeamUpdateInput;
  where: DirNationalTeamWhereUniqueInput;
};


export type MutationUpsertDirNationalTeamArgs = {
  where: DirNationalTeamWhereUniqueInput;
  create: DirNationalTeamCreateInput;
  update: DirNationalTeamUpdateInput;
};


export type MutationDeleteDirNationalTeamArgs = {
  where: DirNationalTeamWhereUniqueInput;
};


export type MutationCreateDirNationalTeamEmailRecipientArgs = {
  data: DirNationalTeamEmailRecipientCreateInput;
};


export type MutationUpdateDirNationalTeamEmailRecipientArgs = {
  data: DirNationalTeamEmailRecipientUpdateInput;
  where: DirNationalTeamEmailRecipientWhereUniqueInput;
};


export type MutationUpsertDirNationalTeamEmailRecipientArgs = {
  where: DirNationalTeamEmailRecipientWhereUniqueInput;
  create: DirNationalTeamEmailRecipientCreateInput;
  update: DirNationalTeamEmailRecipientUpdateInput;
};


export type MutationDeleteDirNationalTeamEmailRecipientArgs = {
  where: DirNationalTeamEmailRecipientWhereUniqueInput;
};


export type MutationCreateDirNationalTeamStatusArgs = {
  data: DirNationalTeamStatusCreateInput;
};


export type MutationUpdateDirNationalTeamStatusArgs = {
  data: DirNationalTeamStatusUpdateInput;
  where: DirNationalTeamStatusWhereUniqueInput;
};


export type MutationUpsertDirNationalTeamStatusArgs = {
  where: DirNationalTeamStatusWhereUniqueInput;
  create: DirNationalTeamStatusCreateInput;
  update: DirNationalTeamStatusUpdateInput;
};


export type MutationDeleteDirNationalTeamStatusArgs = {
  where: DirNationalTeamStatusWhereUniqueInput;
};


export type MutationCreateDirNationalTeamStatusHistoryArgs = {
  data: DirNationalTeamStatusHistoryCreateInput;
};


export type MutationUpdateDirNationalTeamStatusHistoryArgs = {
  data: DirNationalTeamStatusHistoryUpdateInput;
  where: DirNationalTeamStatusHistoryWhereUniqueInput;
};


export type MutationUpsertDirNationalTeamStatusHistoryArgs = {
  where: DirNationalTeamStatusHistoryWhereUniqueInput;
  create: DirNationalTeamStatusHistoryCreateInput;
  update: DirNationalTeamStatusHistoryUpdateInput;
};


export type MutationDeleteDirNationalTeamStatusHistoryArgs = {
  where: DirNationalTeamStatusHistoryWhereUniqueInput;
};


export type MutationCreateDirNationalTeamExtendedStatusArgs = {
  data: DirNationalTeamExtendedStatusCreateInput;
};


export type MutationUpdateDirNationalTeamExtendedStatusArgs = {
  data: DirNationalTeamExtendedStatusUpdateInput;
  where: DirNationalTeamExtendedStatusWhereUniqueInput;
};


export type MutationUpsertDirNationalTeamExtendedStatusArgs = {
  where: DirNationalTeamExtendedStatusWhereUniqueInput;
  create: DirNationalTeamExtendedStatusCreateInput;
  update: DirNationalTeamExtendedStatusUpdateInput;
};


export type MutationDeleteDirNationalTeamExtendedStatusArgs = {
  where: DirNationalTeamExtendedStatusWhereUniqueInput;
};


export type MutationCreateDirNationalTeamTrainerArgs = {
  data: DirNationalTeamTrainerCreateInput;
};


export type MutationUpdateDirNationalTeamTrainerArgs = {
  data: DirNationalTeamTrainerUpdateInput;
  where: DirNationalTeamTrainerWhereUniqueInput;
};


export type MutationUpsertDirNationalTeamTrainerArgs = {
  where: DirNationalTeamTrainerWhereUniqueInput;
  create: DirNationalTeamTrainerCreateInput;
  update: DirNationalTeamTrainerUpdateInput;
};


export type MutationDeleteDirNationalTeamTrainerArgs = {
  where: DirNationalTeamTrainerWhereUniqueInput;
};


export type MutationCreateDirNationalTeamAthleteGroupArgs = {
  data: DirNationalTeamAthleteGroupCreateInput;
};


export type MutationUpdateDirNationalTeamAthleteGroupArgs = {
  data: DirNationalTeamAthleteGroupUpdateInput;
  where: DirNationalTeamAthleteGroupWhereUniqueInput;
};


export type MutationUpsertDirNationalTeamAthleteGroupArgs = {
  where: DirNationalTeamAthleteGroupWhereUniqueInput;
  create: DirNationalTeamAthleteGroupCreateInput;
  update: DirNationalTeamAthleteGroupUpdateInput;
};


export type MutationDeleteDirNationalTeamAthleteGroupArgs = {
  where: DirNationalTeamAthleteGroupWhereUniqueInput;
};


export type MutationCreateDirNationalTeamAthleteArgs = {
  data: DirNationalTeamAthleteCreateInput;
};


export type MutationUpdateDirNationalTeamAthleteArgs = {
  data: DirNationalTeamAthleteUpdateInput;
  where: DirNationalTeamAthleteWhereUniqueInput;
};


export type MutationUpsertDirNationalTeamAthleteArgs = {
  where: DirNationalTeamAthleteWhereUniqueInput;
  create: DirNationalTeamAthleteCreateInput;
  update: DirNationalTeamAthleteUpdateInput;
};


export type MutationDeleteDirNationalTeamAthleteArgs = {
  where: DirNationalTeamAthleteWhereUniqueInput;
};


export type MutationCreateDirNationalTeamMemberArgs = {
  data: DirNationalTeamMemberCreateInput;
};


export type MutationUpdateDirNationalTeamMemberArgs = {
  data: DirNationalTeamMemberUpdateInput;
  where: DirNationalTeamMemberWhereUniqueInput;
};


export type MutationUpsertDirNationalTeamMemberArgs = {
  where: DirNationalTeamMemberWhereUniqueInput;
  create: DirNationalTeamMemberCreateInput;
  update: DirNationalTeamMemberUpdateInput;
};


export type MutationDeleteDirNationalTeamMemberArgs = {
  where: DirNationalTeamMemberWhereUniqueInput;
};


export type MutationCreateDirCalendarArgs = {
  data: DirCalendarCreateInput;
};


export type MutationUpdateDirCalendarArgs = {
  data: DirCalendarUpdateInput;
  where: DirCalendarWhereUniqueInput;
};


export type MutationUpsertDirCalendarArgs = {
  where: DirCalendarWhereUniqueInput;
  create: DirCalendarCreateInput;
  update: DirCalendarUpdateInput;
};


export type MutationDeleteDirCalendarArgs = {
  where: DirCalendarWhereUniqueInput;
};


export type MutationCreateQuotaForStageTwoBySportArgs = {
  data: QuotaForStageTwoBySportCreateInput;
};


export type MutationUpdateQuotaForStageTwoBySportArgs = {
  data: QuotaForStageTwoBySportUpdateInput;
  where: QuotaForStageTwoBySportWhereUniqueInput;
};


export type MutationUpsertQuotaForStageTwoBySportArgs = {
  where: QuotaForStageTwoBySportWhereUniqueInput;
  create: QuotaForStageTwoBySportCreateInput;
  update: QuotaForStageTwoBySportUpdateInput;
};


export type MutationDeleteQuotaForStageTwoBySportArgs = {
  where: QuotaForStageTwoBySportWhereUniqueInput;
};


export type MutationCreateReqPartFromStageTwoArgs = {
  data: ReqPartFromStageTwoCreateInput;
};


export type MutationUpdateReqPartFromStageTwoArgs = {
  data: ReqPartFromStageTwoUpdateInput;
  where: ReqPartFromStageTwoWhereUniqueInput;
};


export type MutationUpsertReqPartFromStageTwoArgs = {
  where: ReqPartFromStageTwoWhereUniqueInput;
  create: ReqPartFromStageTwoCreateInput;
  update: ReqPartFromStageTwoUpdateInput;
};


export type MutationDeleteReqPartFromStageTwoArgs = {
  where: ReqPartFromStageTwoWhereUniqueInput;
};


export type MutationCreateVenueCompetitionArgs = {
  data: VenueCompetitionCreateInput;
};


export type MutationUpdateVenueCompetitionArgs = {
  data: VenueCompetitionUpdateInput;
  where: VenueCompetitionWhereUniqueInput;
};


export type MutationUpsertVenueCompetitionArgs = {
  where: VenueCompetitionWhereUniqueInput;
  create: VenueCompetitionCreateInput;
  update: VenueCompetitionUpdateInput;
};


export type MutationDeleteVenueCompetitionArgs = {
  where: VenueCompetitionWhereUniqueInput;
};


export type MutationCreateVenueCompApplicationArgs = {
  data: VenueCompApplicationCreateInput;
};


export type MutationUpdateVenueCompApplicationArgs = {
  data: VenueCompApplicationUpdateInput;
  where: VenueCompApplicationWhereUniqueInput;
};


export type MutationUpsertVenueCompApplicationArgs = {
  where: VenueCompApplicationWhereUniqueInput;
  create: VenueCompApplicationCreateInput;
  update: VenueCompApplicationUpdateInput;
};


export type MutationDeleteVenueCompApplicationArgs = {
  where: VenueCompApplicationWhereUniqueInput;
};


export type MutationCreateDirCalendarTypeArgs = {
  data: DirCalendarTypeCreateInput;
};


export type MutationUpdateDirCalendarTypeArgs = {
  data: DirCalendarTypeUpdateInput;
  where: DirCalendarTypeWhereUniqueInput;
};


export type MutationUpsertDirCalendarTypeArgs = {
  where: DirCalendarTypeWhereUniqueInput;
  create: DirCalendarTypeCreateInput;
  update: DirCalendarTypeUpdateInput;
};


export type MutationDeleteDirCalendarTypeArgs = {
  where: DirCalendarTypeWhereUniqueInput;
};


export type MutationCreateDirCalendarSignedReportArgs = {
  data: DirCalendarSignedReportCreateInput;
};


export type MutationUpdateDirCalendarSignedReportArgs = {
  data: DirCalendarSignedReportUpdateInput;
  where: DirCalendarSignedReportWhereUniqueInput;
};


export type MutationUpsertDirCalendarSignedReportArgs = {
  where: DirCalendarSignedReportWhereUniqueInput;
  create: DirCalendarSignedReportCreateInput;
  update: DirCalendarSignedReportUpdateInput;
};


export type MutationDeleteDirCalendarSignedReportArgs = {
  where: DirCalendarSignedReportWhereUniqueInput;
};


export type MutationCreateDirDocumentArgs = {
  data: DirDocumentCreateInput;
};


export type MutationUpdateDirDocumentArgs = {
  data: DirDocumentUpdateInput;
  where: DirDocumentWhereUniqueInput;
};


export type MutationUpsertDirDocumentArgs = {
  where: DirDocumentWhereUniqueInput;
  create: DirDocumentCreateInput;
  update: DirDocumentUpdateInput;
};


export type MutationDeleteDirDocumentArgs = {
  where: DirDocumentWhereUniqueInput;
};


export type MutationCreateAddressTypeArgs = {
  data: AddressTypeCreateInput;
};


export type MutationUpdateAddressTypeArgs = {
  data: AddressTypeUpdateInput;
  where: AddressTypeWhereUniqueInput;
};


export type MutationUpsertAddressTypeArgs = {
  where: AddressTypeWhereUniqueInput;
  create: AddressTypeCreateInput;
  update: AddressTypeUpdateInput;
};


export type MutationDeleteAddressTypeArgs = {
  where: AddressTypeWhereUniqueInput;
};


export type MutationCreateTaskStatusArgs = {
  data: TaskStatusCreateInput;
};


export type MutationUpdateTaskStatusArgs = {
  data: TaskStatusUpdateInput;
  where: TaskStatusWhereUniqueInput;
};


export type MutationUpsertTaskStatusArgs = {
  where: TaskStatusWhereUniqueInput;
  create: TaskStatusCreateInput;
  update: TaskStatusUpdateInput;
};


export type MutationDeleteTaskStatusArgs = {
  where: TaskStatusWhereUniqueInput;
};


export type MutationCreateFileArgs = {
  data: FileCreateInput;
};


export type MutationUpdateFileArgs = {
  data: FileUpdateInput;
  where: FileWhereUniqueInput;
};


export type MutationUpsertFileArgs = {
  where: FileWhereUniqueInput;
  create: FileCreateInput;
  update: FileUpdateInput;
};


export type MutationDeleteFileArgs = {
  where: FileWhereUniqueInput;
};


export type MutationSignupArgs = {
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: Scalars['String'];
  password: Scalars['String'];
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileCreateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileCreateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileCreateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileCreateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileCreateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileCreateOneInput>;
};


export type MutationSigninArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationPublicResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationActivateUserArgs = {
  userID: Scalars['String'];
};


export type MutationDeactivateUserArgs = {
  userID: Scalars['String'];
};


export type MutationChangeUserRoleArgs = {
  userID: Scalars['String'];
  role: Scalars['String'];
};


export type MutationChangeUserEmailArgs = {
  userID: Scalars['String'];
  email: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  oldPass: Scalars['String'];
  newPass: Scalars['String'];
};


export type MutationResetUserPasswordArgs = {
  userID: Scalars['String'];
};


export type MutationDeleteUserAccountArgs = {
  userID: Scalars['String'];
};


export type MutationRevokeTokenArgs = {
  userID: Scalars['String'];
  token: Scalars['String'];
};


export type MutationRevokeAllTokensArgs = {
  userID: Scalars['String'];
};


export type MutationMergePersonArgs = {
  primary: DirPersonWhereUniqueInput;
  duplicate: DirPersonWhereUniqueInput;
};


export type MutationOpenMergePersonTaskArgs = {
  primary: DirPersonWhereUniqueInput;
  duplicate: DirPersonWhereUniqueInput;
  comment?: Maybe<Scalars['String']>;
};


export type MutationCloseMergePersonTaskArgs = {
  id: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};


export type MutationCancelMergePersonTaskArgs = {
  id: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};


export type MutationTrainerDismissalWithAthletesSelfTrainingArgs = {
  trainerID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
};


export type MutationTrainerDismissalWithAthletesExpelArgs = {
  trainerID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
};


export type MutationTrainerDismissalWithAthletesAssignToAnotherTrainerArgs = {
  fromTrainerID: Scalars['UUID'];
  toTrainerID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
};


export type MutationAthletesExpelArgs = {
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  trainerID?: Maybe<Scalars['UUID']>;
  trainingStageID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
};


export type MutationAthletesExpelWithMoveToAnotherTrainerArgs = {
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  trainingStageID: Scalars['UUID'];
  fromTrainerID?: Maybe<Scalars['UUID']>;
  toTrainerID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
};


export type MutationAthletesExpelWithMoveToAnotherTrainingStageArgs = {
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  trainerID?: Maybe<Scalars['UUID']>;
  fromTrainingStageID: Scalars['UUID'];
  toTrainingStageID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
};


export type MutationAthletesExpelWithMoveToSelfTrainingArgs = {
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  trainerID: Scalars['UUID'];
  trainingStageID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
};


export type MutationUploadFileArgs = {
  group?: Scalars['String'];
  file: Scalars['Upload'];
};


export type MutationCloneDirSportingEventArgs = {
  where: DirSportingEventWhereUniqueInput;
};


export type MutationRestoreDirSportingEventArgs = {
  from: DirSportingEventWhereUniqueInput;
  to: DirSportingEventWhereUniqueInput;
};


export type MutationUpdateDirNationalTeamExtendedStatusThenNotifyArgs = {
  where: DirNationalTeamExtendedStatusWhereUniqueInput;
  data: DirNationalTeamExtendedStatusUpdateInput;
};


export type MutationUpdateDirSportsThenNotifyArgs = {
  email?: Maybe<Scalars['String']>;
  data: Scalars['String'];
  sportName: Scalars['String'];
  calendarName: Scalars['String'];
  sportId: Scalars['String'];
  calendarId: Scalars['String'];
};


export type MutationPositionVenueCompetitionNotifyArgs = {
  venueCompetitionId: Scalars['String'];
};


export type MutationDecisionVenueCompetitionNotifyArgs = {
  venueCompetitionId: Scalars['String'];
};


export type MutationAgreementVenueCompetitionNotifyArgs = {
  venueCompApplicationId: Scalars['String'];
};


export type MutationUpdateDirSportEventRegulationsThenNotifyArgs = {
  type: Scalars['String'];
  sportName: Scalars['String'];
  calendarName: Scalars['String'];
  sportId: Scalars['String'];
  calendarId: Scalars['String'];
};


export type MutationNotificationStageTwoArgs = {
  email: Scalars['String'];
  calendarId: Scalars['String'];
};

export type UserCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserCreateOneWithoutUpdatedByInput>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: UserRoleCreateOneInput;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserCreateOneInput>;
  system?: Maybe<SystemUserCreateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonCreateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileCreateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileCreateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileCreateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileCreateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileCreateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileCreateOneInput>;
  userSupportRequests?: Maybe<UserSupportRequestCreateManyWithoutUserInput>;
  operatorSupportRequests?: Maybe<UserSupportRequestCreateManyWithoutOperatorInput>;
};

export type UserCreateOneWithoutCreatedByInput = {
  create?: Maybe<UserCreateWithoutCreatedByInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateWithoutCreatedByInput = {
  id?: Maybe<Scalars['UUID']>;
  updatedBy?: Maybe<UserCreateOneWithoutUpdatedByInput>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: UserRoleCreateOneInput;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserCreateOneInput>;
  system?: Maybe<SystemUserCreateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonCreateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileCreateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileCreateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileCreateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileCreateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileCreateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileCreateOneInput>;
  userSupportRequests?: Maybe<UserSupportRequestCreateManyWithoutUserInput>;
  operatorSupportRequests?: Maybe<UserSupportRequestCreateManyWithoutOperatorInput>;
};

export type UserCreateOneWithoutUpdatedByInput = {
  create?: Maybe<UserCreateWithoutUpdatedByInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateWithoutUpdatedByInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneWithoutCreatedByInput>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: UserRoleCreateOneInput;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserCreateOneInput>;
  system?: Maybe<SystemUserCreateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonCreateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileCreateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileCreateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileCreateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileCreateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileCreateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileCreateOneInput>;
  userSupportRequests?: Maybe<UserSupportRequestCreateManyWithoutUserInput>;
  operatorSupportRequests?: Maybe<UserSupportRequestCreateManyWithoutOperatorInput>;
};

export type UserRoleCreateOneInput = {
  create?: Maybe<UserRoleCreateInput>;
  connect?: Maybe<UserRoleWhereUniqueInput>;
};

export type UserRoleCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  description: Scalars['String'];
  canSelect?: Maybe<Scalars['Boolean']>;
};

export type RegularUserCreateOneInput = {
  create?: Maybe<RegularUserCreateInput>;
  connect?: Maybe<RegularUserWhereUniqueInput>;
};

export type RegularUserCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  dirOrganization?: Maybe<DirOrganizationCreateOneInput>;
};

export type UserCreateOneInput = {
  create?: Maybe<UserCreateInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type DirOrganizationCreateOneInput = {
  create?: Maybe<DirOrganizationCreateInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationCreateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationCreateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  sports?: Maybe<DirOrganizationSportCreateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressCreateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneCreateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type ArchiveCreateOneInput = {
  create?: Maybe<ArchiveCreateInput>;
  connect?: Maybe<ArchiveWhereUniqueInput>;
};

export type ArchiveCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  reason: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type DirOrganizationCreateOneWithoutChildrenInput = {
  create?: Maybe<DirOrganizationCreateWithoutChildrenInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationCreateWithoutChildrenInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationCreateOneWithoutChildrenInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  sports?: Maybe<DirOrganizationSportCreateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressCreateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneCreateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type DirRegionCreateOneInput = {
  create?: Maybe<DirRegionCreateInput>;
  connect?: Maybe<DirRegionWhereUniqueInput>;
};

export type DirRegionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  code: Scalars['String'];
  dirFederalDistrict: DirFederalDistrictCreateOneWithoutDirRegionsInput;
  roivOfficialEmail?: Maybe<Scalars['String']>;
};

export type DirFederalDistrictCreateOneWithoutDirRegionsInput = {
  create?: Maybe<DirFederalDistrictCreateWithoutDirRegionsInput>;
  connect?: Maybe<DirFederalDistrictWhereUniqueInput>;
};

export type DirFederalDistrictCreateWithoutDirRegionsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
};

export type ClassifierValueCreateManyInput = {
  create?: Maybe<Array<ClassifierValueCreateInput>>;
  connect?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
};

export type ClassifierValueCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  classifier: ClassifierCreateOneWithoutValuesInput;
};

export type ClassifierCreateOneWithoutValuesInput = {
  create?: Maybe<ClassifierCreateWithoutValuesInput>;
  connect?: Maybe<ClassifierWhereUniqueInput>;
};

export type ClassifierCreateWithoutValuesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type DirSportCreateManyInput = {
  create?: Maybe<Array<DirSportCreateInput>>;
  connect?: Maybe<Array<DirSportWhereUniqueInput>>;
};

export type DirSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupCreateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueCreateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  clsCategories?: Maybe<ClassifierValueCreateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageCreateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionCreateManyWithoutSportInput>;
};

export type DirSportDisciplineCreateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportDisciplineCreateWithoutDirSportInput>>;
  connect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
};

export type DirSportDisciplineCreateWithoutDirSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDisciplinesInput>;
};

export type DirSportDisciplineGroupCreateManyWithoutDisciplinesInput = {
  create?: Maybe<Array<DirSportDisciplineGroupCreateWithoutDisciplinesInput>>;
  connect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
};

export type DirSportDisciplineGroupCreateWithoutDisciplinesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  dirSport?: Maybe<DirSportCreateOneWithoutDisciplineGroupsInput>;
};

export type DirSportCreateOneWithoutDisciplineGroupsInput = {
  create?: Maybe<DirSportCreateWithoutDisciplineGroupsInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportCreateWithoutDisciplineGroupsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupCreateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueCreateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  clsCategories?: Maybe<ClassifierValueCreateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageCreateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionCreateManyWithoutSportInput>;
};

export type DirSportAgeGroupCreateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportAgeGroupCreateWithoutDirSportInput>>;
  connect?: Maybe<Array<DirSportAgeGroupWhereUniqueInput>>;
};

export type DirSportAgeGroupCreateWithoutDirSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup: ClassifierValueCreateOneInput;
  minAge: Scalars['Int'];
  maxAge?: Maybe<Scalars['Int']>;
  participationRequirements?: Maybe<DirSportAgeGroupParticipationRequirementsCreateManyWithoutAgeGroupInput>;
};

export type ClassifierValueCreateOneInput = {
  create?: Maybe<ClassifierValueCreateInput>;
  connect?: Maybe<ClassifierValueWhereUniqueInput>;
};

export type DirSportAgeGroupParticipationRequirementsCreateManyWithoutAgeGroupInput = {
  create?: Maybe<Array<DirSportAgeGroupParticipationRequirementsCreateWithoutAgeGroupInput>>;
  connect?: Maybe<Array<DirSportAgeGroupParticipationRequirementsWhereUniqueInput>>;
};

export type DirSportAgeGroupParticipationRequirementsCreateWithoutAgeGroupInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueCreateOneInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportTrainingStageCreateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportTrainingStageCreateWithoutDirSportInput>>;
  connect?: Maybe<Array<DirSportTrainingStageWhereUniqueInput>>;
};

export type DirSportTrainingStageCreateWithoutDirSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainingStage: ClassifierValueCreateOneInput;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
};

export type DirSportBaseRegionCreateManyWithoutSportInput = {
  create?: Maybe<Array<DirSportBaseRegionCreateWithoutSportInput>>;
  connect?: Maybe<Array<DirSportBaseRegionWhereUniqueInput>>;
};

export type DirSportBaseRegionCreateWithoutSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirRegion: DirRegionCreateOneInput;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DirSportDisciplineGroupCreateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportDisciplineGroupCreateWithoutDirSportInput>>;
  connect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
};

export type DirSportDisciplineGroupCreateWithoutDirSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDisciplineGroupsInput>;
};

export type DirSportDisciplineCreateManyWithoutDisciplineGroupsInput = {
  create?: Maybe<Array<DirSportDisciplineCreateWithoutDisciplineGroupsInput>>;
  connect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
};

export type DirSportDisciplineCreateWithoutDisciplineGroupsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  dirSport?: Maybe<DirSportCreateOneWithoutDisciplinesInput>;
};

export type DirSportCreateOneWithoutDisciplinesInput = {
  create?: Maybe<DirSportCreateWithoutDisciplinesInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportCreateWithoutDisciplinesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupCreateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueCreateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  clsCategories?: Maybe<ClassifierValueCreateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageCreateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionCreateManyWithoutSportInput>;
};

export type DirOrganizationSportCreateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationSportCreateWithoutDirOrganizationInput>>;
  connect?: Maybe<Array<DirOrganizationSportWhereUniqueInput>>;
};

export type DirOrganizationSportCreateWithoutDirOrganizationInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirSportCreateOneInput = {
  create?: Maybe<DirSportCreateInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportDisciplineGroupCreateManyInput = {
  create?: Maybe<Array<DirSportDisciplineGroupCreateInput>>;
  connect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
};

export type DirSportDisciplineGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDisciplineGroupsInput>;
  dirSport?: Maybe<DirSportCreateOneWithoutDisciplineGroupsInput>;
};

export type DirOrganizationAddressCreateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationAddressCreateWithoutDirOrganizationInput>>;
  connect?: Maybe<Array<DirOrganizationAddressWhereUniqueInput>>;
};

export type DirOrganizationAddressCreateWithoutDirOrganizationInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type: AddressTypeCreateOneInput;
};

export type AddressTypeCreateOneInput = {
  create?: Maybe<AddressTypeCreateInput>;
  connect?: Maybe<AddressTypeWhereUniqueInput>;
};

export type AddressTypeCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type DirOrganizationPhoneCreateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationPhoneCreateWithoutDirOrganizationInput>>;
  connect?: Maybe<Array<DirOrganizationPhoneWhereUniqueInput>>;
};

export type DirOrganizationPhoneCreateWithoutDirOrganizationInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: ClassifierValueCreateOneInput;
};

export type DirOrganizationEmailCreateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationEmailCreateWithoutDirOrganizationInput>>;
  connect?: Maybe<Array<DirOrganizationEmailWhereUniqueInput>>;
};

export type DirOrganizationEmailCreateWithoutDirOrganizationInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  clsContactType: ClassifierValueCreateOneInput;
};

export type DirOrganizationCreatewebsitesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type DirOrganizationCreateManyWithoutParentInput = {
  create?: Maybe<Array<DirOrganizationCreateWithoutParentInput>>;
  connect?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
};

export type DirOrganizationCreateWithoutParentInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  children?: Maybe<DirOrganizationCreateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  sports?: Maybe<DirOrganizationSportCreateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressCreateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneCreateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type SystemUserCreateOneInput = {
  create?: Maybe<SystemUserCreateInput>;
  connect?: Maybe<SystemUserWhereUniqueInput>;
};

export type SystemUserCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
};

export type DirPersonCreateOneWithoutUserProfileInput = {
  create?: Maybe<DirPersonCreateWithoutUserProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutUserProfileInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileCreateOneInput>;
  doc2?: Maybe<FileCreateOneInput>;
  doc3?: Maybe<FileCreateOneInput>;
  bankRequisites?: Maybe<FileCreateOneInput>;
};

export type FieldHistoryCreateManyInput = {
  create?: Maybe<Array<FieldHistoryCreateInput>>;
  connect?: Maybe<Array<FieldHistoryWhereUniqueInput>>;
};

export type FieldHistoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  field: Scalars['String'];
  value: Scalars['Json'];
};

export type DirPersonCreateOneWithoutDuplicatesInput = {
  create?: Maybe<DirPersonCreateWithoutDuplicatesInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutDuplicatesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileCreateOneInput>;
  doc2?: Maybe<FileCreateOneInput>;
  doc3?: Maybe<FileCreateOneInput>;
  bankRequisites?: Maybe<FileCreateOneInput>;
};

export type UserCreateOneWithoutPersonProfileInput = {
  create?: Maybe<UserCreateWithoutPersonProfileInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateWithoutPersonProfileInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserCreateOneWithoutUpdatedByInput>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: UserRoleCreateOneInput;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserCreateOneInput>;
  system?: Maybe<SystemUserCreateOneInput>;
  position?: Maybe<Scalars['String']>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileCreateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileCreateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileCreateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileCreateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileCreateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileCreateOneInput>;
  userSupportRequests?: Maybe<UserSupportRequestCreateManyWithoutUserInput>;
  operatorSupportRequests?: Maybe<UserSupportRequestCreateManyWithoutOperatorInput>;
};

export type RegionalMinsportProfileCreateOneInput = {
  create?: Maybe<RegionalMinsportProfileCreateInput>;
  connect?: Maybe<RegionalMinsportProfileWhereUniqueInput>;
};

export type RegionalMinsportProfileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirFederalDistrict?: Maybe<DirFederalDistrictCreateOneInput>;
};

export type DirFederalDistrictCreateOneInput = {
  create?: Maybe<DirFederalDistrictCreateInput>;
  connect?: Maybe<DirFederalDistrictWhereUniqueInput>;
};

export type DirFederalDistrictCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  dirRegions?: Maybe<DirRegionCreateManyWithoutDirFederalDistrictInput>;
};

export type DirRegionCreateManyWithoutDirFederalDistrictInput = {
  create?: Maybe<Array<DirRegionCreateWithoutDirFederalDistrictInput>>;
  connect?: Maybe<Array<DirRegionWhereUniqueInput>>;
};

export type DirRegionCreateWithoutDirFederalDistrictInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  code: Scalars['String'];
  roivOfficialEmail?: Maybe<Scalars['String']>;
};

export type RegionalSchoolProfileCreateOneInput = {
  create?: Maybe<RegionalSchoolProfileCreateInput>;
  connect?: Maybe<RegionalSchoolProfileWhereUniqueInput>;
};

export type RegionalSchoolProfileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirOrganization: DirOrganizationCreateOneInput;
};

export type RegionalAdministratorProfileCreateOneInput = {
  create?: Maybe<RegionalAdministratorProfileCreateInput>;
  connect?: Maybe<RegionalAdministratorProfileWhereUniqueInput>;
};

export type RegionalAdministratorProfileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirRegion: DirRegionCreateOneInput;
};

export type RoivSpecialistProfileCreateOneInput = {
  create?: Maybe<RoivSpecialistProfileCreateInput>;
  connect?: Maybe<RoivSpecialistProfileWhereUniqueInput>;
};

export type RoivSpecialistProfileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirRegion: DirRegionCreateOneInput;
};

export type FcpsrSportingEventOrganizerProfileCreateOneInput = {
  create?: Maybe<FcpsrSportingEventOrganizerProfileCreateInput>;
  connect?: Maybe<FcpsrSportingEventOrganizerProfileWhereUniqueInput>;
};

export type FcpsrSportingEventOrganizerProfileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  sportingEvents?: Maybe<DirSportingEventCreateManyInput>;
  dirCalendars?: Maybe<DirCalendarCreateManyInput>;
  dirSports?: Maybe<DirSportCreateManyInput>;
};

export type DirSportingEventCreateManyInput = {
  create?: Maybe<Array<DirSportingEventCreateInput>>;
  connect?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
};

export type DirSportingEventCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirNationalTeamCreateManyWithoutDirSportingEventInput = {
  create?: Maybe<Array<DirNationalTeamCreateWithoutDirSportingEventInput>>;
  connect?: Maybe<Array<DirNationalTeamWhereUniqueInput>>;
};

export type DirNationalTeamCreateWithoutDirSportingEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota: Scalars['Int'];
  womanTeamQuota: Scalars['Int'];
  supportTeamQuota: Scalars['Int'];
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status: DirNationalTeamStatusCreateOneInput;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirNationalTeamExtendedStatusCreateManyWithoutTeamInput>;
  dirRegion: DirRegionCreateOneInput;
  trainers?: Maybe<DirNationalTeamTrainerCreateManyWithoutTeamInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupCreateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberCreateManyInput>;
  medics?: Maybe<DirNationalTeamMemberCreateManyInput>;
  delegationHeads?: Maybe<DirNationalTeamMemberCreateManyInput>;
  emailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutTeamInput>;
  files?: Maybe<FileCreateManyInput>;
  university?: Maybe<DirOrganizationCreateOneInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  arrivalDeparturePlan?: Maybe<FileCreateManyInput>;
  preliminaryQuantitativeOrder?: Maybe<FileCreateManyInput>;
};

export type DirNationalTeamStatusCreateOneInput = {
  create?: Maybe<DirNationalTeamStatusCreateInput>;
  connect?: Maybe<DirNationalTeamStatusWhereUniqueInput>;
};

export type DirNationalTeamStatusCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  isDeprecated?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamStatusHistoryCreateManyInput = {
  create?: Maybe<Array<DirNationalTeamStatusHistoryCreateInput>>;
  connect?: Maybe<Array<DirNationalTeamStatusHistoryWhereUniqueInput>>;
};

export type DirNationalTeamStatusHistoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  date: Scalars['DateTime'];
  status: DirNationalTeamStatusCreateOneInput;
  user: UserCreateOneInput;
};

export type DirNationalTeamExtendedStatusCreateManyWithoutTeamInput = {
  create?: Maybe<Array<DirNationalTeamExtendedStatusCreateWithoutTeamInput>>;
  connect?: Maybe<Array<DirNationalTeamExtendedStatusWhereUniqueInput>>;
};

export type DirNationalTeamExtendedStatusCreateWithoutTeamInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  value: DirNationalTeamStatusCreateOneInput;
  file?: Maybe<FileCreateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type FileCreateOneInput = {
  create?: Maybe<FileCreateInput>;
  connect?: Maybe<FileWhereUniqueInput>;
};

export type FileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  path: Scalars['String'];
};

export type DirNationalTeamTrainerCreateManyWithoutTeamInput = {
  create?: Maybe<Array<DirNationalTeamTrainerCreateWithoutTeamInput>>;
  connect?: Maybe<Array<DirNationalTeamTrainerWhereUniqueInput>>;
};

export type DirNationalTeamTrainerCreateWithoutTeamInput = {
  id?: Maybe<Scalars['UUID']>;
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
  dirTrainer: DirTrainerCreateOneInput;
  clsPosition?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirTrainerCreateOneInput = {
  create?: Maybe<DirTrainerCreateInput>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirTrainerCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutTrainerProfileInput;
  sports?: Maybe<DirTrainerSportCreateManyWithoutDirTrainerInput>;
};

export type DirPersonCreateOneWithoutTrainerProfileInput = {
  create?: Maybe<DirPersonCreateWithoutTrainerProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutTrainerProfileInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileCreateOneInput>;
  doc2?: Maybe<FileCreateOneInput>;
  doc3?: Maybe<FileCreateOneInput>;
  bankRequisites?: Maybe<FileCreateOneInput>;
};

export type DirPersonCreateManyWithoutPrimaryInput = {
  create?: Maybe<Array<DirPersonCreateWithoutPrimaryInput>>;
  connect?: Maybe<Array<DirPersonWhereUniqueInput>>;
};

export type DirPersonCreateWithoutPrimaryInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileCreateOneInput>;
  doc2?: Maybe<FileCreateOneInput>;
  doc3?: Maybe<FileCreateOneInput>;
  bankRequisites?: Maybe<FileCreateOneInput>;
};

export type DirAthleteCreateOneWithoutDirPersonInput = {
  create?: Maybe<DirAthleteCreateWithoutDirPersonInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteCreateWithoutDirPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPersonAgent?: Maybe<DirPersonCreateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueCreateOneInput>;
  sports?: Maybe<DirAthleteSportCreateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertCreateManyWithoutDirAthleteInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultCreateManyWithoutDirAthleteInput>;
};

export type DirPersonCreateOneInput = {
  create?: Maybe<DirPersonCreateInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileCreateOneInput>;
  doc2?: Maybe<FileCreateOneInput>;
  doc3?: Maybe<FileCreateOneInput>;
  bankRequisites?: Maybe<FileCreateOneInput>;
};

export type DirTrainerCreateOneWithoutDirPersonInput = {
  create?: Maybe<DirTrainerCreateWithoutDirPersonInput>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirTrainerCreateWithoutDirPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sports?: Maybe<DirTrainerSportCreateManyWithoutDirTrainerInput>;
};

export type DirTrainerSportCreateManyWithoutDirTrainerInput = {
  create?: Maybe<Array<DirTrainerSportCreateWithoutDirTrainerInput>>;
  connect?: Maybe<Array<DirTrainerSportWhereUniqueInput>>;
};

export type DirTrainerSportCreateWithoutDirTrainerInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  organizations?: Maybe<DirTrainerSportOrganizationCreateManyWithoutSportInput>;
  categories?: Maybe<DirTrainerSportCategoryCreateManyWithoutSportInput>;
};

export type DirTrainerSportOrganizationCreateManyWithoutSportInput = {
  create?: Maybe<Array<DirTrainerSportOrganizationCreateWithoutSportInput>>;
  connect?: Maybe<Array<DirTrainerSportOrganizationWhereUniqueInput>>;
};

export type DirTrainerSportOrganizationCreateWithoutSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneInput;
  clsPosition: ClassifierValueCreateOneInput;
  employmentDate: Scalars['DateTime'];
  dismissalDate?: Maybe<Scalars['DateTime']>;
};

export type DirTrainerSportCategoryCreateManyWithoutSportInput = {
  create?: Maybe<Array<DirTrainerSportCategoryCreateWithoutSportInput>>;
  connect?: Maybe<Array<DirTrainerSportCategoryWhereUniqueInput>>;
};

export type DirTrainerSportCategoryCreateWithoutSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainerCategory: ClassifierValueCreateOneInput;
  assignmentDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
};

export type DirRefereeCreateOneWithoutDirPersonInput = {
  create?: Maybe<DirRefereeCreateWithoutDirPersonInput>;
  connect?: Maybe<DirRefereeWhereUniqueInput>;
};

export type DirRefereeCreateWithoutDirPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sports?: Maybe<DirRefereeSportCreateManyWithoutDirRefereeInput>;
};

export type DirRefereeSportCreateManyWithoutDirRefereeInput = {
  create?: Maybe<Array<DirRefereeSportCreateWithoutDirRefereeInput>>;
  connect?: Maybe<Array<DirRefereeSportWhereUniqueInput>>;
};

export type DirRefereeSportCreateWithoutDirRefereeInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  workExperience?: Maybe<Scalars['Int']>;
  categories?: Maybe<DirRefereeSportCategoryCreateManyWithoutSportInput>;
};

export type DirRefereeSportCategoryCreateManyWithoutSportInput = {
  create?: Maybe<Array<DirRefereeSportCategoryCreateWithoutSportInput>>;
  connect?: Maybe<Array<DirRefereeSportCategoryWhereUniqueInput>>;
};

export type DirRefereeSportCategoryCreateWithoutSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsRefereeCategory: ClassifierValueCreateOneInput;
  assignmentDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  confirmationDate?: Maybe<Scalars['DateTime']>;
  dirDocuments?: Maybe<DirDocumentCreateManyInput>;
};

export type DirDocumentCreateManyInput = {
  create?: Maybe<Array<DirDocumentCreateInput>>;
  connect?: Maybe<Array<DirDocumentWhereUniqueInput>>;
};

export type DirDocumentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  date: Scalars['DateTime'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  clsType?: Maybe<ClassifierValueCreateOneInput>;
  file: FileCreateOneInput;
};

export type DirPersonConfirmStatusCreateOneInput = {
  create?: Maybe<DirPersonConfirmStatusCreateInput>;
  connect?: Maybe<DirPersonConfirmStatusWhereUniqueInput>;
};

export type DirPersonConfirmStatusCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type DirCountryCreateManyInput = {
  create?: Maybe<Array<DirCountryCreateInput>>;
  connect?: Maybe<Array<DirCountryWhereUniqueInput>>;
};

export type DirCountryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  dirForeignCities?: Maybe<DirForeignCityCreateManyWithoutDirCountryInput>;
};

export type DirForeignCityCreateManyWithoutDirCountryInput = {
  create?: Maybe<Array<DirForeignCityCreateWithoutDirCountryInput>>;
  connect?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
};

export type DirForeignCityCreateWithoutDirCountryInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
};

export type DirForeignCityCreateOneInput = {
  create?: Maybe<DirForeignCityCreateInput>;
  connect?: Maybe<DirForeignCityWhereUniqueInput>;
};

export type DirForeignCityCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  dirCountry: DirCountryCreateOneWithoutDirForeignCitiesInput;
};

export type DirCountryCreateOneWithoutDirForeignCitiesInput = {
  create?: Maybe<DirCountryCreateWithoutDirForeignCitiesInput>;
  connect?: Maybe<DirCountryWhereUniqueInput>;
};

export type DirCountryCreateWithoutDirForeignCitiesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
};

export type DirCountryCreateOneInput = {
  create?: Maybe<DirCountryCreateInput>;
  connect?: Maybe<DirCountryWhereUniqueInput>;
};

export type DirPersonPhoneCreateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirPersonPhoneCreateWithoutDirPersonInput>>;
  connect?: Maybe<Array<DirPersonPhoneWhereUniqueInput>>;
};

export type DirPersonPhoneCreateWithoutDirPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: ClassifierValueCreateOneInput;
};

export type DirPersonAdditionalRegionCreateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirPersonAdditionalRegionCreateWithoutDirPersonInput>>;
  connect?: Maybe<Array<DirPersonAdditionalRegionWhereUniqueInput>>;
};

export type DirPersonAdditionalRegionCreateWithoutDirPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
};

export type DirPersonMergeTaskCreateManyWithoutPrimaryInput = {
  create?: Maybe<Array<DirPersonMergeTaskCreateWithoutPrimaryInput>>;
  connect?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
};

export type DirPersonMergeTaskCreateWithoutPrimaryInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  status: TaskStatusCreateOneInput;
  comment?: Maybe<Scalars['String']>;
  duplicate: DirPersonCreateOneWithoutMergeTasksAsDuplicateInput;
};

export type TaskStatusCreateOneInput = {
  create?: Maybe<TaskStatusCreateInput>;
  connect?: Maybe<TaskStatusWhereUniqueInput>;
};

export type TaskStatusCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type DirPersonCreateOneWithoutMergeTasksAsDuplicateInput = {
  create?: Maybe<DirPersonCreateWithoutMergeTasksAsDuplicateInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutMergeTasksAsDuplicateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileCreateOneInput>;
  doc2?: Maybe<FileCreateOneInput>;
  doc3?: Maybe<FileCreateOneInput>;
  bankRequisites?: Maybe<FileCreateOneInput>;
};

export type DirPersonAddressCreateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirPersonAddressCreateWithoutDirPersonInput>>;
  connect?: Maybe<Array<DirPersonAddressWhereUniqueInput>>;
};

export type DirPersonAddressCreateWithoutDirPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type: AddressTypeCreateOneInput;
};

export type DirNationalTeamEmailRecipientCreateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirNationalTeamEmailRecipientCreateWithoutDirPersonInput>>;
  connect?: Maybe<Array<DirNationalTeamEmailRecipientWhereUniqueInput>>;
};

export type DirNationalTeamEmailRecipientCreateWithoutDirPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
  team: DirNationalTeamCreateOneWithoutEmailRecipientsInput;
  email: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export type DirNationalTeamCreateOneWithoutEmailRecipientsInput = {
  create?: Maybe<DirNationalTeamCreateWithoutEmailRecipientsInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamCreateWithoutEmailRecipientsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota: Scalars['Int'];
  womanTeamQuota: Scalars['Int'];
  supportTeamQuota: Scalars['Int'];
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status: DirNationalTeamStatusCreateOneInput;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirNationalTeamExtendedStatusCreateManyWithoutTeamInput>;
  dirSportingEvent: DirSportingEventCreateOneWithoutDirNationalTeamsInput;
  dirRegion: DirRegionCreateOneInput;
  trainers?: Maybe<DirNationalTeamTrainerCreateManyWithoutTeamInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupCreateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberCreateManyInput>;
  medics?: Maybe<DirNationalTeamMemberCreateManyInput>;
  delegationHeads?: Maybe<DirNationalTeamMemberCreateManyInput>;
  files?: Maybe<FileCreateManyInput>;
  university?: Maybe<DirOrganizationCreateOneInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  arrivalDeparturePlan?: Maybe<FileCreateManyInput>;
  preliminaryQuantitativeOrder?: Maybe<FileCreateManyInput>;
};

export type DirSportingEventCreateOneWithoutDirNationalTeamsInput = {
  create?: Maybe<DirSportingEventCreateWithoutDirNationalTeamsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutDirNationalTeamsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirRegionCreateManyInput = {
  create?: Maybe<Array<DirRegionCreateInput>>;
  connect?: Maybe<Array<DirRegionWhereUniqueInput>>;
};

export type DirFederalDistrictCreateManyInput = {
  create?: Maybe<Array<DirFederalDistrictCreateInput>>;
  connect?: Maybe<Array<DirFederalDistrictWhereUniqueInput>>;
};

export type DirSportingEventCountryCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventCountryCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventCountryWhereUniqueInput>>;
};

export type DirSportingEventCountryCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirCountry: DirCountryCreateOneInput;
  dirForeignCities?: Maybe<DirForeignCityCreateManyInput>;
};

export type DirForeignCityCreateManyInput = {
  create?: Maybe<Array<DirForeignCityCreateInput>>;
  connect?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
};

export type DirSportingEventCostCreateOneInput = {
  create?: Maybe<DirSportingEventCostCreateInput>;
  connect?: Maybe<DirSportingEventCostWhereUniqueInput>;
};

export type DirSportingEventCostCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  clsFinancingTypes?: Maybe<ClassifierValueCreateManyInput>;
};

export type DirSportingEventQuotaCreateOneInput = {
  create?: Maybe<DirSportingEventQuotaCreateInput>;
  connect?: Maybe<DirSportingEventQuotaWhereUniqueInput>;
};

export type DirSportingEventQuotaCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  athletesMan?: Maybe<Scalars['Int']>;
  athletesWoman?: Maybe<Scalars['Int']>;
  trainers?: Maybe<Scalars['Int']>;
  referees?: Maybe<Scalars['Int']>;
  administration?: Maybe<Scalars['Int']>;
  medicalStaff?: Maybe<Scalars['Int']>;
  specialists?: Maybe<Scalars['Int']>;
  teams?: Maybe<Scalars['Int']>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsTeamComposition?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventQuantityCreateOneInput = {
  create?: Maybe<DirSportingEventQuantityCreateInput>;
  connect?: Maybe<DirSportingEventQuantityWhereUniqueInput>;
};

export type DirSportingEventQuantityCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  participantAthletesTotal?: Maybe<Scalars['Int']>;
  refereesTotal?: Maybe<Scalars['Int']>;
  staffTotal?: Maybe<Scalars['Int']>;
  audienceTotal?: Maybe<Scalars['Int']>;
};

export type DirSportingEventCreateOneWithoutChildrenInput = {
  create?: Maybe<DirSportingEventCreateWithoutChildrenInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutChildrenInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventCreateOneWithoutChangesInput = {
  create?: Maybe<DirSportingEventCreateWithoutChangesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutChangesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventCreateManyWithoutParentInput = {
  create?: Maybe<Array<DirSportingEventCreateWithoutParentInput>>;
  connect?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
};

export type DirSportingEventCreateWithoutParentInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventCreateManyWithoutProxyInput = {
  create?: Maybe<Array<DirSportingEventCreateWithoutProxyInput>>;
  connect?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
};

export type DirSportingEventCreateWithoutProxyInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventStatusCreateOneInput = {
  create?: Maybe<DirSportingEventStatusCreateInput>;
  connect?: Maybe<DirSportingEventStatusWhereUniqueInput>;
};

export type DirSportingEventStatusCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type DirSportingEventStatusHistoryCreateManyInput = {
  create?: Maybe<Array<DirSportingEventStatusHistoryCreateInput>>;
  connect?: Maybe<Array<DirSportingEventStatusHistoryWhereUniqueInput>>;
};

export type DirSportingEventStatusHistoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  date: Scalars['DateTime'];
  status: DirSportingEventStatusCreateOneInput;
  user: UserCreateOneInput;
};

export type DirSportingEventExtendedStatusCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventExtendedStatusCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventExtendedStatusWhereUniqueInput>>;
};

export type DirSportingEventExtendedStatusCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  value: DirSportingEventExtendedStatusValueCreateOneInput;
  file?: Maybe<FileCreateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventExtendedStatusValueCreateOneInput = {
  create?: Maybe<DirSportingEventExtendedStatusValueCreateInput>;
  connect?: Maybe<DirSportingEventExtendedStatusValueWhereUniqueInput>;
};

export type DirSportingEventExtendedStatusValueCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type DirCalendarCreateOneInput = {
  create?: Maybe<DirCalendarCreateInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeCreateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueCreateOneInput>;
  clsSeason?: Maybe<ClassifierValueCreateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueCreateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionCreateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionCreateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionCreateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileCreateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsCreateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolCreateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileCreateManyInput>;
  stageNumber?: Maybe<ClassifierValueCreateOneInput>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportCreateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoCreateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportCreateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoCreateManyWithoutDirCalendarInput>;
};

export type DirCalendarTypeCreateOneInput = {
  create?: Maybe<DirCalendarTypeCreateInput>;
  connect?: Maybe<DirCalendarTypeWhereUniqueInput>;
};

export type DirCalendarTypeCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type DirRefusedRegionCreateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<DirRefusedRegionCreateWithoutDirCalendarInput>>;
  connect?: Maybe<Array<DirRefusedRegionWhereUniqueInput>>;
};

export type DirRefusedRegionCreateWithoutDirCalendarInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  comment?: Maybe<Scalars['String']>;
  statement?: Maybe<FileCreateOneInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
};

export type DirReturnedRegionCreateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<DirReturnedRegionCreateWithoutDirCalendarInput>>;
  connect?: Maybe<Array<DirReturnedRegionWhereUniqueInput>>;
};

export type DirReturnedRegionCreateWithoutDirCalendarInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  statement?: Maybe<FileCreateOneInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
};

export type VenueCompetitionCreateOneWithoutDirCalendarInput = {
  create?: Maybe<VenueCompetitionCreateWithoutDirCalendarInput>;
  connect?: Maybe<VenueCompetitionWhereUniqueInput>;
};

export type VenueCompetitionCreateWithoutDirCalendarInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  dirDocument?: Maybe<DirDocumentCreateOneInput>;
  venueCompDecisionDoc?: Maybe<DirDocumentCreateOneInput>;
  venueCompRegulationDocAdds?: Maybe<FileCreateManyInput>;
  venueCompDecisionDocAdds?: Maybe<FileCreateManyInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  emailSenderFCPSR?: Maybe<UserCreateOneInput>;
  venueCompApplications?: Maybe<VenueCompApplicationCreateManyWithoutVenueCompetitionInput>;
};

export type DirDocumentCreateOneInput = {
  create?: Maybe<DirDocumentCreateInput>;
  connect?: Maybe<DirDocumentWhereUniqueInput>;
};

export type FileCreateManyInput = {
  create?: Maybe<Array<FileCreateInput>>;
  connect?: Maybe<Array<FileWhereUniqueInput>>;
};

export type VenueCompApplicationCreateManyWithoutVenueCompetitionInput = {
  create?: Maybe<Array<VenueCompApplicationCreateWithoutVenueCompetitionInput>>;
  connect?: Maybe<Array<VenueCompApplicationWhereUniqueInput>>;
};

export type VenueCompApplicationCreateWithoutVenueCompetitionInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  dirRegion: DirRegionCreateOneInput;
  number?: Maybe<Scalars['Int']>;
  applicationFiles?: Maybe<FileCreateManyInput>;
  notificationSentToFCPSR: Scalars['Boolean'];
};

export type DirSportEventRegulationsCreateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<DirSportEventRegulationsCreateWithoutDirCalendarInput>>;
  connect?: Maybe<Array<DirSportEventRegulationsWhereUniqueInput>>;
};

export type DirSportEventRegulationsCreateWithoutDirCalendarInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  regulationsDocument?: Maybe<FileCreateOneInput>;
  regulationsAmendedDocument?: Maybe<FileCreateOneInput>;
  regulationsAgreedDocument?: Maybe<FileCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  dirSport?: Maybe<DirSportCreateOneInput>;
  status?: Maybe<DirSportingEventRegulationStatusCreateManyWithoutRegulationInput>;
  fcpsrAddRegulationsDoc?: Maybe<UserCreateOneInput>;
};

export type DirSportingEventRegulationStatusCreateManyWithoutRegulationInput = {
  create?: Maybe<Array<DirSportingEventRegulationStatusCreateWithoutRegulationInput>>;
  connect?: Maybe<Array<DirSportingEventRegulationStatusWhereUniqueInput>>;
};

export type DirSportingEventRegulationStatusCreateWithoutRegulationInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  value: DirSportingEventRegulationStatusValueCreateOneInput;
  file?: Maybe<FileCreateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRegulationStatusValueCreateOneInput = {
  create?: Maybe<DirSportingEventRegulationStatusValueCreateInput>;
  connect?: Maybe<DirSportingEventRegulationStatusValueWhereUniqueInput>;
};

export type DirSportingEventRegulationStatusValueCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type NotificationProtocolCreateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<NotificationProtocolCreateWithoutDirCalendarInput>>;
  connect?: Maybe<Array<NotificationProtocolWhereUniqueInput>>;
};

export type NotificationProtocolCreateWithoutDirCalendarInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirRegion: DirRegionCreateOneInput;
  dirSport: DirSportCreateOneInput;
  forNotificationROIVSpecialists?: Maybe<UserCreateManyInput>;
};

export type UserCreateManyInput = {
  create?: Maybe<Array<UserCreateInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type DirCalendarSignedReportCreateManyWithoutDirCalendarSignedReportInput = {
  create?: Maybe<Array<DirCalendarSignedReportCreateWithoutDirCalendarSignedReportInput>>;
  connect?: Maybe<Array<DirCalendarSignedReportWhereUniqueInput>>;
};

export type DirCalendarSignedReportCreateWithoutDirCalendarSignedReportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirCalendar: DirCalendarCreateOneInput;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  files?: Maybe<FileCreateManyInput>;
};

export type ReqPartFromStageTwoCreateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<ReqPartFromStageTwoCreateWithoutDirCalendarInput>>;
  connect?: Maybe<Array<ReqPartFromStageTwoWhereUniqueInput>>;
};

export type ReqPartFromStageTwoCreateWithoutDirCalendarInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  region: DirRegionCreateOneInput;
  roivSpecialistsForNotification?: Maybe<UserCreateManyInput>;
  filesROIVStageTwo?: Maybe<FileCreateManyInput>;
};

export type QuotaForStageTwoBySportCreateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<QuotaForStageTwoBySportCreateWithoutDirCalendarInput>>;
  connect?: Maybe<Array<QuotaForStageTwoBySportWhereUniqueInput>>;
};

export type QuotaForStageTwoBySportCreateWithoutDirCalendarInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  dirSport: DirSportCreateOneInput;
  quotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo?: Maybe<Scalars['Int']>;
};

export type QuantyRequestStageTwoCreateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<QuantyRequestStageTwoCreateWithoutDirCalendarInput>>;
  connect?: Maybe<Array<QuantyRequestStageTwoWhereUniqueInput>>;
};

export type QuantyRequestStageTwoCreateWithoutDirCalendarInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  dirSport: DirSportCreateOneInput;
  dirRegion: DirRegionCreateOneInput;
  regionQuotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo?: Maybe<Scalars['Int']>;
};

export type DirSportingEventSportCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventSportCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventSportWhereUniqueInput>>;
};

export type DirSportingEventSportCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirSportDisciplineCreateManyInput = {
  create?: Maybe<Array<DirSportDisciplineCreateInput>>;
  connect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
};

export type DirSportDisciplineCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDisciplinesInput>;
  dirSport?: Maybe<DirSportCreateOneWithoutDisciplinesInput>;
};

export type DirSportingEventAgeGroupCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventAgeGroupCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventAgeGroupWhereUniqueInput>>;
};

export type DirSportingEventAgeGroupCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup?: Maybe<ClassifierValueCreateOneInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirSportingEventProgramTypeCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventProgramTypeCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventProgramTypeWhereUniqueInput>>;
};

export type DirSportingEventProgramTypeCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  minAge: Scalars['Int'];
  maxAge: Scalars['Int'];
  start?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  clsSportCategory?: Maybe<ClassifierValueCreateOneInput>;
  resultsDocument?: Maybe<DirDocumentCreateOneInput>;
  resultDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsAgeGroups?: Maybe<ClassifierValueCreateManyInput>;
  discipline: DirSportDisciplineCreateOneInput;
};

export type DirSportDisciplineCreateOneInput = {
  create?: Maybe<DirSportDisciplineCreateInput>;
  connect?: Maybe<DirSportDisciplineWhereUniqueInput>;
};

export type DirSportingEventCompetitionCalendarCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventCompetitionCalendarCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventCompetitionCalendarWhereUniqueInput>>;
};

export type DirSportingEventCompetitionCalendarCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  discipline?: Maybe<DirSportDisciplineCreateOneInput>;
};

export type DirOrganizationCreateManyInput = {
  create?: Maybe<Array<DirOrganizationCreateInput>>;
  connect?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
};

export type DirSportingEventRequestCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventRequestCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventRequestWhereUniqueInput>>;
};

export type DirSportingEventRequestCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  orderNumber?: Maybe<Scalars['Int']>;
  type: DirSportingEventRequestTypeCreateOneInput;
  statuses?: Maybe<DirSportingEventRequestStatusCreateManyWithoutRequestInput>;
};

export type DirSportingEventRequestTypeCreateOneInput = {
  create?: Maybe<DirSportingEventRequestTypeCreateInput>;
  connect?: Maybe<DirSportingEventRequestTypeWhereUniqueInput>;
};

export type DirSportingEventRequestTypeCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type DirSportingEventRequestStatusCreateManyWithoutRequestInput = {
  create?: Maybe<Array<DirSportingEventRequestStatusCreateWithoutRequestInput>>;
  connect?: Maybe<Array<DirSportingEventRequestStatusWhereUniqueInput>>;
};

export type DirSportingEventRequestStatusCreateWithoutRequestInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  value: DirSportingEventRequestStatusValueCreateOneInput;
  file?: Maybe<FileCreateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRequestStatusValueCreateOneInput = {
  create?: Maybe<DirSportingEventRequestStatusValueCreateInput>;
  connect?: Maybe<DirSportingEventRequestStatusValueWhereUniqueInput>;
};

export type DirSportingEventRequestStatusValueCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type DirSportingEventPointDistributionCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventPointDistributionCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventPointDistributionWhereUniqueInput>>;
};

export type DirSportingEventPointDistributionCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  lastPlacesPoints?: Maybe<Scalars['Int']>;
  lastPlacesStart?: Maybe<Scalars['Int']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  clsAgeGroup?: Maybe<ClassifierValueCreateOneInput>;
  places?: Maybe<DirSportingEventPointDistributionPlaceCreateManyWithoutPointDistributionInput>;
};

export type DirSportingEventPointDistributionPlaceCreateManyWithoutPointDistributionInput = {
  create?: Maybe<Array<DirSportingEventPointDistributionPlaceCreateWithoutPointDistributionInput>>;
  connect?: Maybe<Array<DirSportingEventPointDistributionPlaceWhereUniqueInput>>;
};

export type DirSportingEventPointDistributionPlaceCreateWithoutPointDistributionInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  place: Scalars['Int'];
  points: Scalars['Int'];
};

export type DirSportingEventRefereeCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventRefereeCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventRefereeWhereUniqueInput>>;
};

export type DirSportingEventRefereeCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee: DirRefereeCreateOneInput;
  isPrimary?: Maybe<Scalars['Boolean']>;
  clsRefereePosition: ClassifierValueCreateOneInput;
  isNonresident?: Maybe<Scalars['Boolean']>;
};

export type DirRefereeCreateOneInput = {
  create?: Maybe<DirRefereeCreateInput>;
  connect?: Maybe<DirRefereeWhereUniqueInput>;
};

export type DirRefereeCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutRefereeProfileInput;
  sports?: Maybe<DirRefereeSportCreateManyWithoutDirRefereeInput>;
};

export type DirPersonCreateOneWithoutRefereeProfileInput = {
  create?: Maybe<DirPersonCreateWithoutRefereeProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutRefereeProfileInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileCreateOneInput>;
  doc2?: Maybe<FileCreateOneInput>;
  doc3?: Maybe<FileCreateOneInput>;
  bankRequisites?: Maybe<FileCreateOneInput>;
};

export type DirPersonMergeTaskCreateManyWithoutDuplicateInput = {
  create?: Maybe<Array<DirPersonMergeTaskCreateWithoutDuplicateInput>>;
  connect?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
};

export type DirPersonMergeTaskCreateWithoutDuplicateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  status: TaskStatusCreateOneInput;
  comment?: Maybe<Scalars['String']>;
  primary: DirPersonCreateOneWithoutMergeTasksAsPrimaryInput;
};

export type DirPersonCreateOneWithoutMergeTasksAsPrimaryInput = {
  create?: Maybe<DirPersonCreateWithoutMergeTasksAsPrimaryInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutMergeTasksAsPrimaryInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileCreateOneInput>;
  doc2?: Maybe<FileCreateOneInput>;
  doc3?: Maybe<FileCreateOneInput>;
  bankRequisites?: Maybe<FileCreateOneInput>;
};

export type DirSportingEventRefereeStatusCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventRefereeStatusCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventRefereeStatusWhereUniqueInput>>;
};

export type DirSportingEventRefereeStatusCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  value: DirSportingEventRefereeStatusValueCreateOneInput;
  file?: Maybe<FileCreateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRefereeStatusValueCreateOneInput = {
  create?: Maybe<DirSportingEventRefereeStatusValueCreateInput>;
  connect?: Maybe<DirSportingEventRefereeStatusValueWhereUniqueInput>;
};

export type DirSportingEventRefereeStatusValueCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput = {
  create?: Maybe<Array<DirSportingEventAthleteCallNotificationCreateWithoutDirSportingEventInput>>;
  connect?: Maybe<Array<DirSportingEventAthleteCallNotificationWhereUniqueInput>>;
};

export type DirSportingEventAthleteCallNotificationCreateWithoutDirSportingEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  document: DirDocumentCreateOneInput;
  teams?: Maybe<DirSportingEventAthleteCallNotificationTeamCreateManyWithoutNotificationInput>;
};

export type DirSportingEventAthleteCallNotificationTeamCreateManyWithoutNotificationInput = {
  create?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamCreateWithoutNotificationInput>>;
  connect?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamWhereUniqueInput>>;
};

export type DirSportingEventAthleteCallNotificationTeamCreateWithoutNotificationInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  ditNationalTeam: DirNationalTeamCreateOneInput;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamCreateOneInput = {
  create?: Maybe<DirNationalTeamCreateInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota: Scalars['Int'];
  womanTeamQuota: Scalars['Int'];
  supportTeamQuota: Scalars['Int'];
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status: DirNationalTeamStatusCreateOneInput;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirNationalTeamExtendedStatusCreateManyWithoutTeamInput>;
  dirSportingEvent: DirSportingEventCreateOneWithoutDirNationalTeamsInput;
  dirRegion: DirRegionCreateOneInput;
  trainers?: Maybe<DirNationalTeamTrainerCreateManyWithoutTeamInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupCreateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberCreateManyInput>;
  medics?: Maybe<DirNationalTeamMemberCreateManyInput>;
  delegationHeads?: Maybe<DirNationalTeamMemberCreateManyInput>;
  emailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutTeamInput>;
  files?: Maybe<FileCreateManyInput>;
  university?: Maybe<DirOrganizationCreateOneInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  arrivalDeparturePlan?: Maybe<FileCreateManyInput>;
  preliminaryQuantitativeOrder?: Maybe<FileCreateManyInput>;
};

export type DirNationalTeamAthleteGroupCreateManyWithoutTeamInput = {
  create?: Maybe<Array<DirNationalTeamAthleteGroupCreateWithoutTeamInput>>;
  connect?: Maybe<Array<DirNationalTeamAthleteGroupWhereUniqueInput>>;
};

export type DirNationalTeamAthleteGroupCreateWithoutTeamInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  athletes?: Maybe<DirNationalTeamAthleteCreateManyWithoutGroupInput>;
};

export type DirNationalTeamAthleteCreateManyWithoutGroupInput = {
  create?: Maybe<Array<DirNationalTeamAthleteCreateWithoutGroupInput>>;
  connect?: Maybe<Array<DirNationalTeamAthleteWhereUniqueInput>>;
};

export type DirNationalTeamAthleteCreateWithoutGroupInput = {
  id?: Maybe<Scalars['UUID']>;
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirAthlete: DirAthleteCreateOneInput;
  bestResult?: Maybe<Scalars['String']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteCreateOneInput = {
  create?: Maybe<DirAthleteCreateInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutAthleteProfileInput;
  dirPersonAgent?: Maybe<DirPersonCreateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueCreateOneInput>;
  sports?: Maybe<DirAthleteSportCreateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertCreateManyWithoutDirAthleteInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultCreateManyWithoutDirAthleteInput>;
};

export type DirPersonCreateOneWithoutAthleteProfileInput = {
  create?: Maybe<DirPersonCreateWithoutAthleteProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutAthleteProfileInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileCreateOneInput>;
  doc2?: Maybe<FileCreateOneInput>;
  doc3?: Maybe<FileCreateOneInput>;
  bankRequisites?: Maybe<FileCreateOneInput>;
};

export type DirAthleteSportCreateManyInput = {
  create?: Maybe<Array<DirAthleteSportCreateInput>>;
  connect?: Maybe<Array<DirAthleteSportWhereUniqueInput>>;
};

export type DirAthleteSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  dirTrainers?: Maybe<DirTrainerCreateManyInput>;
  organizations?: Maybe<DirAthleteSportOrganizationCreateManyInput>;
  ranksAndAwards?: Maybe<DirAthleteSportRankAndAwardCreateManyWithoutSportInput>;
  role?: Maybe<Scalars['String']>;
};

export type DirTrainerCreateManyInput = {
  create?: Maybe<Array<DirTrainerCreateInput>>;
  connect?: Maybe<Array<DirTrainerWhereUniqueInput>>;
};

export type DirAthleteSportOrganizationCreateManyInput = {
  create?: Maybe<Array<DirAthleteSportOrganizationCreateInput>>;
  connect?: Maybe<Array<DirAthleteSportOrganizationWhereUniqueInput>>;
};

export type DirAthleteSportOrganizationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneInput;
  clsTrainingStage: ClassifierValueCreateOneInput;
  dirTrainer?: Maybe<DirTrainerCreateOneInput>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  trainingStart: Scalars['DateTime'];
  exclusionDate?: Maybe<Scalars['DateTime']>;
};

export type DirAthleteSportRankAndAwardCreateManyWithoutSportInput = {
  create?: Maybe<Array<DirAthleteSportRankAndAwardCreateWithoutSportInput>>;
  connect?: Maybe<Array<DirAthleteSportRankAndAwardWhereUniqueInput>>;
};

export type DirAthleteSportRankAndAwardCreateWithoutSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsRankAndAward: ClassifierValueCreateOneInput;
  assignmentDate: Scalars['DateTime'];
  confirmDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  dirDocument?: Maybe<DirDocumentCreateOneInput>;
};

export type DirAthleteMedCertCreateManyWithoutDirAthleteInput = {
  create?: Maybe<Array<DirAthleteMedCertCreateWithoutDirAthleteInput>>;
  connect?: Maybe<Array<DirAthleteMedCertWhereUniqueInput>>;
};

export type DirAthleteMedCertCreateWithoutDirAthleteInput = {
  id?: Maybe<Scalars['UUID']>;
  updatedAtReal?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  issueDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName: Scalars['String'];
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  sports?: Maybe<DirAthleteMedCertSportCreateManyWithoutMedCertInput>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteMedCertSportCreateManyWithoutMedCertInput = {
  create?: Maybe<Array<DirAthleteMedCertSportCreateWithoutMedCertInput>>;
  connect?: Maybe<Array<DirAthleteMedCertSportWhereUniqueInput>>;
};

export type DirAthleteMedCertSportCreateWithoutMedCertInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  clsTrainingStage?: Maybe<ClassifierValueCreateOneInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirAthleteCompetitionResultCreateManyWithoutDirAthleteInput = {
  create?: Maybe<Array<DirAthleteCompetitionResultCreateWithoutDirAthleteInput>>;
  connect?: Maybe<Array<DirAthleteCompetitionResultWhereUniqueInput>>;
};

export type DirAthleteCompetitionResultCreateWithoutDirAthleteInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  noResultReason?: Maybe<ClassifierValueCreateOneInput>;
  sportingEventProgramType: DirSportingEventProgramTypeCreateOneInput;
  point?: Maybe<Scalars['Int']>;
  pointTo?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  completedRank?: Maybe<Scalars['String']>;
};

export type DirSportingEventProgramTypeCreateOneInput = {
  create?: Maybe<DirSportingEventProgramTypeCreateInput>;
  connect?: Maybe<DirSportingEventProgramTypeWhereUniqueInput>;
};

export type DirSportingEventProgramTypeCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEventCreateOneWithoutProgramTypesInput;
  minAge: Scalars['Int'];
  maxAge: Scalars['Int'];
  start?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  clsSportCategory?: Maybe<ClassifierValueCreateOneInput>;
  resultsDocument?: Maybe<DirDocumentCreateOneInput>;
  resultDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsAgeGroups?: Maybe<ClassifierValueCreateManyInput>;
  discipline: DirSportDisciplineCreateOneInput;
};

export type DirSportingEventCreateOneWithoutProgramTypesInput = {
  create?: Maybe<DirSportingEventCreateWithoutProgramTypesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutProgramTypesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportAgeGroupCreateOneInput = {
  create?: Maybe<DirSportAgeGroupCreateInput>;
  connect?: Maybe<DirSportAgeGroupWhereUniqueInput>;
};

export type DirSportAgeGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup: ClassifierValueCreateOneInput;
  minAge: Scalars['Int'];
  maxAge?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportCreateOneWithoutAgeGroupsInput>;
  participationRequirements?: Maybe<DirSportAgeGroupParticipationRequirementsCreateManyWithoutAgeGroupInput>;
};

export type DirSportCreateOneWithoutAgeGroupsInput = {
  create?: Maybe<DirSportCreateWithoutAgeGroupsInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportCreateWithoutAgeGroupsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueCreateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  clsCategories?: Maybe<ClassifierValueCreateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageCreateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionCreateManyWithoutSportInput>;
};

export type DirNationalTeamMemberCreateManyInput = {
  create?: Maybe<Array<DirNationalTeamMemberCreateInput>>;
  connect?: Maybe<Array<DirNationalTeamMemberWhereUniqueInput>>;
};

export type DirNationalTeamMemberCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic: Scalars['String'];
  birthday: Scalars['DateTime'];
  clsPosition?: Maybe<ClassifierValueCreateOneInput>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamEmailRecipientCreateManyWithoutTeamInput = {
  create?: Maybe<Array<DirNationalTeamEmailRecipientCreateWithoutTeamInput>>;
  connect?: Maybe<Array<DirNationalTeamEmailRecipientWhereUniqueInput>>;
};

export type DirNationalTeamEmailRecipientCreateWithoutTeamInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  dirPerson?: Maybe<DirPersonCreateOneWithoutNationalTeamEmailRecipientsInput>;
  comment?: Maybe<Scalars['String']>;
};

export type DirPersonCreateOneWithoutNationalTeamEmailRecipientsInput = {
  create?: Maybe<DirPersonCreateWithoutNationalTeamEmailRecipientsInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutNationalTeamEmailRecipientsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileCreateOneInput>;
  doc2?: Maybe<FileCreateOneInput>;
  doc3?: Maybe<FileCreateOneInput>;
  bankRequisites?: Maybe<FileCreateOneInput>;
};

export type DirCalendarCreateManyInput = {
  create?: Maybe<Array<DirCalendarCreateInput>>;
  connect?: Maybe<Array<DirCalendarWhereUniqueInput>>;
};

export type GskRefereeProfileCreateOneInput = {
  create?: Maybe<GskRefereeProfileCreateInput>;
  connect?: Maybe<GskRefereeProfileWhereUniqueInput>;
};

export type GskRefereeProfileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirSportingEvents?: Maybe<DirSportingEventCreateManyInput>;
};

export type UserSupportRequestCreateManyWithoutUserInput = {
  create?: Maybe<Array<UserSupportRequestCreateWithoutUserInput>>;
  connect?: Maybe<Array<UserSupportRequestWhereUniqueInput>>;
};

export type UserSupportRequestCreateWithoutUserInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  number?: Maybe<Scalars['Int']>;
  operator?: Maybe<UserCreateOneWithoutOperatorSupportRequestsInput>;
  request: Scalars['String'];
  requestType: UserSupportRequestTypeCreateOneInput;
  file?: Maybe<FileCreateOneInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
};

export type UserCreateOneWithoutOperatorSupportRequestsInput = {
  create?: Maybe<UserCreateWithoutOperatorSupportRequestsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateWithoutOperatorSupportRequestsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserCreateOneWithoutUpdatedByInput>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: UserRoleCreateOneInput;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserCreateOneInput>;
  system?: Maybe<SystemUserCreateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonCreateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileCreateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileCreateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileCreateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileCreateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileCreateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileCreateOneInput>;
  userSupportRequests?: Maybe<UserSupportRequestCreateManyWithoutUserInput>;
};

export type UserSupportRequestTypeCreateOneInput = {
  create?: Maybe<UserSupportRequestTypeCreateInput>;
  connect?: Maybe<UserSupportRequestTypeWhereUniqueInput>;
};

export type UserSupportRequestTypeCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type UserSupportRequestCreateManyWithoutOperatorInput = {
  create?: Maybe<Array<UserSupportRequestCreateWithoutOperatorInput>>;
  connect?: Maybe<Array<UserSupportRequestWhereUniqueInput>>;
};

export type UserSupportRequestCreateWithoutOperatorInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  number?: Maybe<Scalars['Int']>;
  user?: Maybe<UserCreateOneWithoutUserSupportRequestsInput>;
  request: Scalars['String'];
  requestType: UserSupportRequestTypeCreateOneInput;
  file?: Maybe<FileCreateOneInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
};

export type UserCreateOneWithoutUserSupportRequestsInput = {
  create?: Maybe<UserCreateWithoutUserSupportRequestsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateWithoutUserSupportRequestsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserCreateOneWithoutUpdatedByInput>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: UserRoleCreateOneInput;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserCreateOneInput>;
  system?: Maybe<SystemUserCreateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonCreateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileCreateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileCreateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileCreateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileCreateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileCreateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileCreateOneInput>;
  operatorSupportRequests?: Maybe<UserSupportRequestCreateManyWithoutOperatorInput>;
};

export type UserUpdateInput = {
  createdBy?: Maybe<UserUpdateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserUpdateOneWithoutUpdatedByInput>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleUpdateOneRequiredInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserUpdateOneInput>;
  system?: Maybe<SystemUserUpdateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonUpdateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileUpdateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileUpdateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileUpdateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileUpdateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileUpdateOneInput>;
  userSupportRequests?: Maybe<UserSupportRequestUpdateManyWithoutUserInput>;
  operatorSupportRequests?: Maybe<UserSupportRequestUpdateManyWithoutOperatorInput>;
};

export type UserUpdateOneWithoutCreatedByInput = {
  create?: Maybe<UserCreateWithoutCreatedByInput>;
  update?: Maybe<UserUpdateWithoutCreatedByDataInput>;
  upsert?: Maybe<UserUpsertWithoutCreatedByInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateWithoutCreatedByDataInput = {
  updatedBy?: Maybe<UserUpdateOneWithoutUpdatedByInput>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleUpdateOneRequiredInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserUpdateOneInput>;
  system?: Maybe<SystemUserUpdateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonUpdateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileUpdateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileUpdateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileUpdateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileUpdateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileUpdateOneInput>;
  userSupportRequests?: Maybe<UserSupportRequestUpdateManyWithoutUserInput>;
  operatorSupportRequests?: Maybe<UserSupportRequestUpdateManyWithoutOperatorInput>;
};

export type UserUpdateOneWithoutUpdatedByInput = {
  create?: Maybe<UserCreateWithoutUpdatedByInput>;
  update?: Maybe<UserUpdateWithoutUpdatedByDataInput>;
  upsert?: Maybe<UserUpsertWithoutUpdatedByInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateWithoutUpdatedByDataInput = {
  createdBy?: Maybe<UserUpdateOneWithoutCreatedByInput>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleUpdateOneRequiredInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserUpdateOneInput>;
  system?: Maybe<SystemUserUpdateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonUpdateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileUpdateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileUpdateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileUpdateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileUpdateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileUpdateOneInput>;
  userSupportRequests?: Maybe<UserSupportRequestUpdateManyWithoutUserInput>;
  operatorSupportRequests?: Maybe<UserSupportRequestUpdateManyWithoutOperatorInput>;
};

export type UserRoleUpdateOneRequiredInput = {
  create?: Maybe<UserRoleCreateInput>;
  update?: Maybe<UserRoleUpdateDataInput>;
  upsert?: Maybe<UserRoleUpsertNestedInput>;
  connect?: Maybe<UserRoleWhereUniqueInput>;
};

export type UserRoleUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  canSelect?: Maybe<Scalars['Boolean']>;
};

export type UserRoleUpsertNestedInput = {
  update: UserRoleUpdateDataInput;
  create: UserRoleCreateInput;
};

export type RegularUserUpdateOneInput = {
  create?: Maybe<RegularUserCreateInput>;
  update?: Maybe<RegularUserUpdateDataInput>;
  upsert?: Maybe<RegularUserUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<RegularUserWhereUniqueInput>;
};

export type RegularUserUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneInput>;
};

export type UserUpdateOneInput = {
  create?: Maybe<UserCreateInput>;
  update?: Maybe<UserUpdateDataInput>;
  upsert?: Maybe<UserUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserUpdateOneWithoutUpdatedByInput>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleUpdateOneRequiredInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserUpdateOneInput>;
  system?: Maybe<SystemUserUpdateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonUpdateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileUpdateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileUpdateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileUpdateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileUpdateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileUpdateOneInput>;
  userSupportRequests?: Maybe<UserSupportRequestUpdateManyWithoutUserInput>;
  operatorSupportRequests?: Maybe<UserSupportRequestUpdateManyWithoutOperatorInput>;
};

export type SystemUserUpdateOneInput = {
  create?: Maybe<SystemUserCreateInput>;
  update?: Maybe<SystemUserUpdateDataInput>;
  upsert?: Maybe<SystemUserUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SystemUserWhereUniqueInput>;
};

export type SystemUserUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
};

export type SystemUserUpsertNestedInput = {
  update: SystemUserUpdateDataInput;
  create: SystemUserCreateInput;
};

export type DirPersonUpdateOneWithoutUserProfileInput = {
  create?: Maybe<DirPersonCreateWithoutUserProfileInput>;
  update?: Maybe<DirPersonUpdateWithoutUserProfileDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutUserProfileInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutUserProfileDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type FieldHistoryUpdateManyInput = {
  create?: Maybe<Array<FieldHistoryCreateInput>>;
  update?: Maybe<Array<FieldHistoryUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<FieldHistoryUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<FieldHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<FieldHistoryWhereUniqueInput>>;
  set?: Maybe<Array<FieldHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<FieldHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FieldHistoryScalarWhereInput>>;
  updateMany?: Maybe<Array<FieldHistoryUpdateManyWithWhereNestedInput>>;
};

export type FieldHistoryUpdateWithWhereUniqueNestedInput = {
  where: FieldHistoryWhereUniqueInput;
  data: FieldHistoryUpdateDataInput;
};

export type FieldHistoryUpdateDataInput = {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
};

export type FieldHistoryUpsertWithWhereUniqueNestedInput = {
  where: FieldHistoryWhereUniqueInput;
  update: FieldHistoryUpdateDataInput;
  create: FieldHistoryCreateInput;
};

export type FieldHistoryScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  field?: Maybe<Scalars['String']>;
  field_not?: Maybe<Scalars['String']>;
  field_in?: Maybe<Array<Scalars['String']>>;
  field_not_in?: Maybe<Array<Scalars['String']>>;
  field_lt?: Maybe<Scalars['String']>;
  field_lte?: Maybe<Scalars['String']>;
  field_gt?: Maybe<Scalars['String']>;
  field_gte?: Maybe<Scalars['String']>;
  field_contains?: Maybe<Scalars['String']>;
  field_not_contains?: Maybe<Scalars['String']>;
  field_starts_with?: Maybe<Scalars['String']>;
  field_not_starts_with?: Maybe<Scalars['String']>;
  field_ends_with?: Maybe<Scalars['String']>;
  field_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<FieldHistoryScalarWhereInput>>;
  OR?: Maybe<Array<FieldHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<FieldHistoryScalarWhereInput>>;
};

export type FieldHistoryUpdateManyWithWhereNestedInput = {
  where: FieldHistoryScalarWhereInput;
  data: FieldHistoryUpdateManyDataInput;
};

export type FieldHistoryUpdateManyDataInput = {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
};

export type ArchiveUpdateOneInput = {
  create?: Maybe<ArchiveCreateInput>;
  update?: Maybe<ArchiveUpdateDataInput>;
  upsert?: Maybe<ArchiveUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ArchiveWhereUniqueInput>;
};

export type ArchiveUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  reason?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type ArchiveUpsertNestedInput = {
  update: ArchiveUpdateDataInput;
  create: ArchiveCreateInput;
};

export type DirPersonUpdateOneWithoutDuplicatesInput = {
  create?: Maybe<DirPersonCreateWithoutDuplicatesInput>;
  update?: Maybe<DirPersonUpdateWithoutDuplicatesDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutDuplicatesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutDuplicatesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type UserUpdateOneWithoutPersonProfileInput = {
  create?: Maybe<UserCreateWithoutPersonProfileInput>;
  update?: Maybe<UserUpdateWithoutPersonProfileDataInput>;
  upsert?: Maybe<UserUpsertWithoutPersonProfileInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateWithoutPersonProfileDataInput = {
  createdBy?: Maybe<UserUpdateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserUpdateOneWithoutUpdatedByInput>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleUpdateOneRequiredInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserUpdateOneInput>;
  system?: Maybe<SystemUserUpdateOneInput>;
  position?: Maybe<Scalars['String']>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileUpdateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileUpdateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileUpdateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileUpdateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileUpdateOneInput>;
  userSupportRequests?: Maybe<UserSupportRequestUpdateManyWithoutUserInput>;
  operatorSupportRequests?: Maybe<UserSupportRequestUpdateManyWithoutOperatorInput>;
};

export type RegionalMinsportProfileUpdateOneInput = {
  create?: Maybe<RegionalMinsportProfileCreateInput>;
  update?: Maybe<RegionalMinsportProfileUpdateDataInput>;
  upsert?: Maybe<RegionalMinsportProfileUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<RegionalMinsportProfileWhereUniqueInput>;
};

export type RegionalMinsportProfileUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirFederalDistrict?: Maybe<DirFederalDistrictUpdateOneInput>;
};

export type DirRegionUpdateOneInput = {
  create?: Maybe<DirRegionCreateInput>;
  update?: Maybe<DirRegionUpdateDataInput>;
  upsert?: Maybe<DirRegionUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirRegionWhereUniqueInput>;
};

export type DirRegionUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  dirFederalDistrict?: Maybe<DirFederalDistrictUpdateOneRequiredWithoutDirRegionsInput>;
  roivOfficialEmail?: Maybe<Scalars['String']>;
};

export type DirFederalDistrictUpdateOneRequiredWithoutDirRegionsInput = {
  create?: Maybe<DirFederalDistrictCreateWithoutDirRegionsInput>;
  update?: Maybe<DirFederalDistrictUpdateWithoutDirRegionsDataInput>;
  upsert?: Maybe<DirFederalDistrictUpsertWithoutDirRegionsInput>;
  connect?: Maybe<DirFederalDistrictWhereUniqueInput>;
};

export type DirFederalDistrictUpdateWithoutDirRegionsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type DirFederalDistrictUpsertWithoutDirRegionsInput = {
  update: DirFederalDistrictUpdateWithoutDirRegionsDataInput;
  create: DirFederalDistrictCreateWithoutDirRegionsInput;
};

export type DirRegionUpsertNestedInput = {
  update: DirRegionUpdateDataInput;
  create: DirRegionCreateInput;
};

export type DirFederalDistrictUpdateOneInput = {
  create?: Maybe<DirFederalDistrictCreateInput>;
  update?: Maybe<DirFederalDistrictUpdateDataInput>;
  upsert?: Maybe<DirFederalDistrictUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirFederalDistrictWhereUniqueInput>;
};

export type DirFederalDistrictUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  dirRegions?: Maybe<DirRegionUpdateManyWithoutDirFederalDistrictInput>;
};

export type DirRegionUpdateManyWithoutDirFederalDistrictInput = {
  create?: Maybe<Array<DirRegionCreateWithoutDirFederalDistrictInput>>;
  delete?: Maybe<Array<DirRegionWhereUniqueInput>>;
  connect?: Maybe<Array<DirRegionWhereUniqueInput>>;
  set?: Maybe<Array<DirRegionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirRegionWhereUniqueInput>>;
  update?: Maybe<Array<DirRegionUpdateWithWhereUniqueWithoutDirFederalDistrictInput>>;
  upsert?: Maybe<Array<DirRegionUpsertWithWhereUniqueWithoutDirFederalDistrictInput>>;
  deleteMany?: Maybe<Array<DirRegionScalarWhereInput>>;
  updateMany?: Maybe<Array<DirRegionUpdateManyWithWhereNestedInput>>;
};

export type DirRegionUpdateWithWhereUniqueWithoutDirFederalDistrictInput = {
  where: DirRegionWhereUniqueInput;
  data: DirRegionUpdateWithoutDirFederalDistrictDataInput;
};

export type DirRegionUpdateWithoutDirFederalDistrictDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  roivOfficialEmail?: Maybe<Scalars['String']>;
};

export type DirRegionUpsertWithWhereUniqueWithoutDirFederalDistrictInput = {
  where: DirRegionWhereUniqueInput;
  update: DirRegionUpdateWithoutDirFederalDistrictDataInput;
  create: DirRegionCreateWithoutDirFederalDistrictInput;
};

export type DirRegionScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_lt?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_contains?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  roivOfficialEmail?: Maybe<Scalars['String']>;
  roivOfficialEmail_not?: Maybe<Scalars['String']>;
  roivOfficialEmail_in?: Maybe<Array<Scalars['String']>>;
  roivOfficialEmail_not_in?: Maybe<Array<Scalars['String']>>;
  roivOfficialEmail_lt?: Maybe<Scalars['String']>;
  roivOfficialEmail_lte?: Maybe<Scalars['String']>;
  roivOfficialEmail_gt?: Maybe<Scalars['String']>;
  roivOfficialEmail_gte?: Maybe<Scalars['String']>;
  roivOfficialEmail_contains?: Maybe<Scalars['String']>;
  roivOfficialEmail_not_contains?: Maybe<Scalars['String']>;
  roivOfficialEmail_starts_with?: Maybe<Scalars['String']>;
  roivOfficialEmail_not_starts_with?: Maybe<Scalars['String']>;
  roivOfficialEmail_ends_with?: Maybe<Scalars['String']>;
  roivOfficialEmail_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirRegionScalarWhereInput>>;
  OR?: Maybe<Array<DirRegionScalarWhereInput>>;
  NOT?: Maybe<Array<DirRegionScalarWhereInput>>;
};

export type DirRegionUpdateManyWithWhereNestedInput = {
  where: DirRegionScalarWhereInput;
  data: DirRegionUpdateManyDataInput;
};

export type DirRegionUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  roivOfficialEmail?: Maybe<Scalars['String']>;
};

export type DirFederalDistrictUpsertNestedInput = {
  update: DirFederalDistrictUpdateDataInput;
  create: DirFederalDistrictCreateInput;
};

export type RegionalMinsportProfileUpsertNestedInput = {
  update: RegionalMinsportProfileUpdateDataInput;
  create: RegionalMinsportProfileCreateInput;
};

export type RegionalSchoolProfileUpdateOneInput = {
  create?: Maybe<RegionalSchoolProfileCreateInput>;
  update?: Maybe<RegionalSchoolProfileUpdateDataInput>;
  upsert?: Maybe<RegionalSchoolProfileUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<RegionalSchoolProfileWhereUniqueInput>;
};

export type RegionalSchoolProfileUpdateDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredInput>;
};

export type DirOrganizationUpdateOneRequiredInput = {
  create?: Maybe<DirOrganizationCreateInput>;
  update?: Maybe<DirOrganizationUpdateDataInput>;
  upsert?: Maybe<DirOrganizationUpsertNestedInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type DirOrganizationUpdateOneWithoutChildrenInput = {
  create?: Maybe<DirOrganizationCreateWithoutChildrenInput>;
  update?: Maybe<DirOrganizationUpdateWithoutChildrenDataInput>;
  upsert?: Maybe<DirOrganizationUpsertWithoutChildrenInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationUpdateWithoutChildrenDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type ClassifierValueUpdateManyInput = {
  create?: Maybe<Array<ClassifierValueCreateInput>>;
  update?: Maybe<Array<ClassifierValueUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<ClassifierValueUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  connect?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  set?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClassifierValueScalarWhereInput>>;
  updateMany?: Maybe<Array<ClassifierValueUpdateManyWithWhereNestedInput>>;
};

export type ClassifierValueUpdateWithWhereUniqueNestedInput = {
  where: ClassifierValueWhereUniqueInput;
  data: ClassifierValueUpdateDataInput;
};

export type ClassifierValueUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  classifier?: Maybe<ClassifierUpdateOneRequiredWithoutValuesInput>;
};

export type ClassifierUpdateOneRequiredWithoutValuesInput = {
  create?: Maybe<ClassifierCreateWithoutValuesInput>;
  update?: Maybe<ClassifierUpdateWithoutValuesDataInput>;
  upsert?: Maybe<ClassifierUpsertWithoutValuesInput>;
  connect?: Maybe<ClassifierWhereUniqueInput>;
};

export type ClassifierUpdateWithoutValuesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ClassifierUpsertWithoutValuesInput = {
  update: ClassifierUpdateWithoutValuesDataInput;
  create: ClassifierCreateWithoutValuesInput;
};

export type ClassifierValueUpsertWithWhereUniqueNestedInput = {
  where: ClassifierValueWhereUniqueInput;
  update: ClassifierValueUpdateDataInput;
  create: ClassifierValueCreateInput;
};

export type ClassifierValueScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<ClassifierValueScalarWhereInput>>;
  OR?: Maybe<Array<ClassifierValueScalarWhereInput>>;
  NOT?: Maybe<Array<ClassifierValueScalarWhereInput>>;
};

export type ClassifierValueUpdateManyWithWhereNestedInput = {
  where: ClassifierValueScalarWhereInput;
  data: ClassifierValueUpdateManyDataInput;
};

export type ClassifierValueUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type DirSportUpdateManyInput = {
  create?: Maybe<Array<DirSportCreateInput>>;
  update?: Maybe<Array<DirSportUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirSportUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportWhereUniqueInput>>;
  set?: Maybe<Array<DirSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportUpdateManyWithWhereNestedInput>>;
};

export type DirSportUpdateWithWhereUniqueNestedInput = {
  where: DirSportWhereUniqueInput;
  data: DirSportUpdateDataInput;
};

export type DirSportUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageUpdateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionUpdateManyWithoutSportInput>;
};

export type DirSportDisciplineUpdateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportDisciplineCreateWithoutDirSportInput>>;
  delete?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  set?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  update?: Maybe<Array<DirSportDisciplineUpdateWithWhereUniqueWithoutDirSportInput>>;
  upsert?: Maybe<Array<DirSportDisciplineUpsertWithWhereUniqueWithoutDirSportInput>>;
  deleteMany?: Maybe<Array<DirSportDisciplineScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportDisciplineUpdateManyWithWhereNestedInput>>;
};

export type DirSportDisciplineUpdateWithWhereUniqueWithoutDirSportInput = {
  where: DirSportDisciplineWhereUniqueInput;
  data: DirSportDisciplineUpdateWithoutDirSportDataInput;
};

export type DirSportDisciplineUpdateWithoutDirSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDisciplinesInput>;
};

export type DirSportDisciplineGroupUpdateManyWithoutDisciplinesInput = {
  create?: Maybe<Array<DirSportDisciplineGroupCreateWithoutDisciplinesInput>>;
  delete?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  set?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  update?: Maybe<Array<DirSportDisciplineGroupUpdateWithWhereUniqueWithoutDisciplinesInput>>;
  upsert?: Maybe<Array<DirSportDisciplineGroupUpsertWithWhereUniqueWithoutDisciplinesInput>>;
  deleteMany?: Maybe<Array<DirSportDisciplineGroupScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportDisciplineGroupUpdateManyWithWhereNestedInput>>;
};

export type DirSportDisciplineGroupUpdateWithWhereUniqueWithoutDisciplinesInput = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  data: DirSportDisciplineGroupUpdateWithoutDisciplinesDataInput;
};

export type DirSportDisciplineGroupUpdateWithoutDisciplinesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  dirSport?: Maybe<DirSportUpdateOneWithoutDisciplineGroupsInput>;
};

export type DirSportUpdateOneWithoutDisciplineGroupsInput = {
  create?: Maybe<DirSportCreateWithoutDisciplineGroupsInput>;
  update?: Maybe<DirSportUpdateWithoutDisciplineGroupsDataInput>;
  upsert?: Maybe<DirSportUpsertWithoutDisciplineGroupsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportUpdateWithoutDisciplineGroupsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageUpdateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionUpdateManyWithoutSportInput>;
};

export type DirSportAgeGroupUpdateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportAgeGroupCreateWithoutDirSportInput>>;
  delete?: Maybe<Array<DirSportAgeGroupWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportAgeGroupWhereUniqueInput>>;
  set?: Maybe<Array<DirSportAgeGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportAgeGroupWhereUniqueInput>>;
  update?: Maybe<Array<DirSportAgeGroupUpdateWithWhereUniqueWithoutDirSportInput>>;
  upsert?: Maybe<Array<DirSportAgeGroupUpsertWithWhereUniqueWithoutDirSportInput>>;
  deleteMany?: Maybe<Array<DirSportAgeGroupScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportAgeGroupUpdateManyWithWhereNestedInput>>;
};

export type DirSportAgeGroupUpdateWithWhereUniqueWithoutDirSportInput = {
  where: DirSportAgeGroupWhereUniqueInput;
  data: DirSportAgeGroupUpdateWithoutDirSportDataInput;
};

export type DirSportAgeGroupUpdateWithoutDirSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  participationRequirements?: Maybe<DirSportAgeGroupParticipationRequirementsUpdateManyWithoutAgeGroupInput>;
};

export type ClassifierValueUpdateOneRequiredInput = {
  create?: Maybe<ClassifierValueCreateInput>;
  update?: Maybe<ClassifierValueUpdateDataInput>;
  upsert?: Maybe<ClassifierValueUpsertNestedInput>;
  connect?: Maybe<ClassifierValueWhereUniqueInput>;
};

export type ClassifierValueUpsertNestedInput = {
  update: ClassifierValueUpdateDataInput;
  create: ClassifierValueCreateInput;
};

export type DirSportAgeGroupParticipationRequirementsUpdateManyWithoutAgeGroupInput = {
  create?: Maybe<Array<DirSportAgeGroupParticipationRequirementsCreateWithoutAgeGroupInput>>;
  delete?: Maybe<Array<DirSportAgeGroupParticipationRequirementsWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportAgeGroupParticipationRequirementsWhereUniqueInput>>;
  set?: Maybe<Array<DirSportAgeGroupParticipationRequirementsWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportAgeGroupParticipationRequirementsWhereUniqueInput>>;
  update?: Maybe<Array<DirSportAgeGroupParticipationRequirementsUpdateWithWhereUniqueWithoutAgeGroupInput>>;
  upsert?: Maybe<Array<DirSportAgeGroupParticipationRequirementsUpsertWithWhereUniqueWithoutAgeGroupInput>>;
  deleteMany?: Maybe<Array<DirSportAgeGroupParticipationRequirementsScalarWhereInput>>;
};

export type DirSportAgeGroupParticipationRequirementsUpdateWithWhereUniqueWithoutAgeGroupInput = {
  where: DirSportAgeGroupParticipationRequirementsWhereUniqueInput;
  data: DirSportAgeGroupParticipationRequirementsUpdateWithoutAgeGroupDataInput;
};

export type DirSportAgeGroupParticipationRequirementsUpdateWithoutAgeGroupDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueUpdateOneInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateOneInput>;
};

export type ClassifierValueUpdateOneInput = {
  create?: Maybe<ClassifierValueCreateInput>;
  update?: Maybe<ClassifierValueUpdateDataInput>;
  upsert?: Maybe<ClassifierValueUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClassifierValueWhereUniqueInput>;
};

export type DirSportAgeGroupParticipationRequirementsUpsertWithWhereUniqueWithoutAgeGroupInput = {
  where: DirSportAgeGroupParticipationRequirementsWhereUniqueInput;
  update: DirSportAgeGroupParticipationRequirementsUpdateWithoutAgeGroupDataInput;
  create: DirSportAgeGroupParticipationRequirementsCreateWithoutAgeGroupInput;
};

export type DirSportAgeGroupParticipationRequirementsScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirSportAgeGroupParticipationRequirementsScalarWhereInput>>;
  OR?: Maybe<Array<DirSportAgeGroupParticipationRequirementsScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportAgeGroupParticipationRequirementsScalarWhereInput>>;
};

export type DirSportAgeGroupUpsertWithWhereUniqueWithoutDirSportInput = {
  where: DirSportAgeGroupWhereUniqueInput;
  update: DirSportAgeGroupUpdateWithoutDirSportDataInput;
  create: DirSportAgeGroupCreateWithoutDirSportInput;
};

export type DirSportAgeGroupScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportAgeGroupScalarWhereInput>>;
  OR?: Maybe<Array<DirSportAgeGroupScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportAgeGroupScalarWhereInput>>;
};

export type DirSportAgeGroupUpdateManyWithWhereNestedInput = {
  where: DirSportAgeGroupScalarWhereInput;
  data: DirSportAgeGroupUpdateManyDataInput;
};

export type DirSportAgeGroupUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirSportTrainingStageUpdateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportTrainingStageCreateWithoutDirSportInput>>;
  delete?: Maybe<Array<DirSportTrainingStageWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportTrainingStageWhereUniqueInput>>;
  set?: Maybe<Array<DirSportTrainingStageWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportTrainingStageWhereUniqueInput>>;
  update?: Maybe<Array<DirSportTrainingStageUpdateWithWhereUniqueWithoutDirSportInput>>;
  upsert?: Maybe<Array<DirSportTrainingStageUpsertWithWhereUniqueWithoutDirSportInput>>;
  deleteMany?: Maybe<Array<DirSportTrainingStageScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportTrainingStageUpdateManyWithWhereNestedInput>>;
};

export type DirSportTrainingStageUpdateWithWhereUniqueWithoutDirSportInput = {
  where: DirSportTrainingStageWhereUniqueInput;
  data: DirSportTrainingStageUpdateWithoutDirSportDataInput;
};

export type DirSportTrainingStageUpdateWithoutDirSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainingStage?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
};

export type DirSportTrainingStageUpsertWithWhereUniqueWithoutDirSportInput = {
  where: DirSportTrainingStageWhereUniqueInput;
  update: DirSportTrainingStageUpdateWithoutDirSportDataInput;
  create: DirSportTrainingStageCreateWithoutDirSportInput;
};

export type DirSportTrainingStageScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  minManAge?: Maybe<Scalars['Int']>;
  minManAge_not?: Maybe<Scalars['Int']>;
  minManAge_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_lt?: Maybe<Scalars['Int']>;
  minManAge_lte?: Maybe<Scalars['Int']>;
  minManAge_gt?: Maybe<Scalars['Int']>;
  minManAge_gte?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  maxManAge_not?: Maybe<Scalars['Int']>;
  maxManAge_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_lt?: Maybe<Scalars['Int']>;
  maxManAge_lte?: Maybe<Scalars['Int']>;
  maxManAge_gt?: Maybe<Scalars['Int']>;
  maxManAge_gte?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  minWomanAge_not?: Maybe<Scalars['Int']>;
  minWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_lt?: Maybe<Scalars['Int']>;
  minWomanAge_lte?: Maybe<Scalars['Int']>;
  minWomanAge_gt?: Maybe<Scalars['Int']>;
  minWomanAge_gte?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge_not?: Maybe<Scalars['Int']>;
  maxWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_lt?: Maybe<Scalars['Int']>;
  maxWomanAge_lte?: Maybe<Scalars['Int']>;
  maxWomanAge_gt?: Maybe<Scalars['Int']>;
  maxWomanAge_gte?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  minGroupSize_not?: Maybe<Scalars['Int']>;
  minGroupSize_in?: Maybe<Array<Scalars['Int']>>;
  minGroupSize_not_in?: Maybe<Array<Scalars['Int']>>;
  minGroupSize_lt?: Maybe<Scalars['Int']>;
  minGroupSize_lte?: Maybe<Scalars['Int']>;
  minGroupSize_gt?: Maybe<Scalars['Int']>;
  minGroupSize_gte?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize_not?: Maybe<Scalars['Int']>;
  maxGroupSize_in?: Maybe<Array<Scalars['Int']>>;
  maxGroupSize_not_in?: Maybe<Array<Scalars['Int']>>;
  maxGroupSize_lt?: Maybe<Scalars['Int']>;
  maxGroupSize_lte?: Maybe<Scalars['Int']>;
  maxGroupSize_gt?: Maybe<Scalars['Int']>;
  maxGroupSize_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportTrainingStageScalarWhereInput>>;
  OR?: Maybe<Array<DirSportTrainingStageScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportTrainingStageScalarWhereInput>>;
};

export type DirSportTrainingStageUpdateManyWithWhereNestedInput = {
  where: DirSportTrainingStageScalarWhereInput;
  data: DirSportTrainingStageUpdateManyDataInput;
};

export type DirSportTrainingStageUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
};

export type DirSportBaseRegionUpdateManyWithoutSportInput = {
  create?: Maybe<Array<DirSportBaseRegionCreateWithoutSportInput>>;
  delete?: Maybe<Array<DirSportBaseRegionWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportBaseRegionWhereUniqueInput>>;
  set?: Maybe<Array<DirSportBaseRegionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportBaseRegionWhereUniqueInput>>;
  update?: Maybe<Array<DirSportBaseRegionUpdateWithWhereUniqueWithoutSportInput>>;
  upsert?: Maybe<Array<DirSportBaseRegionUpsertWithWhereUniqueWithoutSportInput>>;
  deleteMany?: Maybe<Array<DirSportBaseRegionScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportBaseRegionUpdateManyWithWhereNestedInput>>;
};

export type DirSportBaseRegionUpdateWithWhereUniqueWithoutSportInput = {
  where: DirSportBaseRegionWhereUniqueInput;
  data: DirSportBaseRegionUpdateWithoutSportDataInput;
};

export type DirSportBaseRegionUpdateWithoutSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DirRegionUpdateOneRequiredInput = {
  create?: Maybe<DirRegionCreateInput>;
  update?: Maybe<DirRegionUpdateDataInput>;
  upsert?: Maybe<DirRegionUpsertNestedInput>;
  connect?: Maybe<DirRegionWhereUniqueInput>;
};

export type DirSportBaseRegionUpsertWithWhereUniqueWithoutSportInput = {
  where: DirSportBaseRegionWhereUniqueInput;
  update: DirSportBaseRegionUpdateWithoutSportDataInput;
  create: DirSportBaseRegionCreateWithoutSportInput;
};

export type DirSportBaseRegionScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_not?: Maybe<Scalars['DateTime']>;
  startDate_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_lt?: Maybe<Scalars['DateTime']>;
  startDate_lte?: Maybe<Scalars['DateTime']>;
  startDate_gt?: Maybe<Scalars['DateTime']>;
  startDate_gte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_not?: Maybe<Scalars['DateTime']>;
  endDate_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_lt?: Maybe<Scalars['DateTime']>;
  endDate_lte?: Maybe<Scalars['DateTime']>;
  endDate_gt?: Maybe<Scalars['DateTime']>;
  endDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirSportBaseRegionScalarWhereInput>>;
  OR?: Maybe<Array<DirSportBaseRegionScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportBaseRegionScalarWhereInput>>;
};

export type DirSportBaseRegionUpdateManyWithWhereNestedInput = {
  where: DirSportBaseRegionScalarWhereInput;
  data: DirSportBaseRegionUpdateManyDataInput;
};

export type DirSportBaseRegionUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DirSportUpsertWithoutDisciplineGroupsInput = {
  update: DirSportUpdateWithoutDisciplineGroupsDataInput;
  create: DirSportCreateWithoutDisciplineGroupsInput;
};

export type DirSportDisciplineGroupUpsertWithWhereUniqueWithoutDisciplinesInput = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  update: DirSportDisciplineGroupUpdateWithoutDisciplinesDataInput;
  create: DirSportDisciplineGroupCreateWithoutDisciplinesInput;
};

export type DirSportDisciplineGroupScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportDisciplineGroupScalarWhereInput>>;
  OR?: Maybe<Array<DirSportDisciplineGroupScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportDisciplineGroupScalarWhereInput>>;
};

export type DirSportDisciplineGroupUpdateManyWithWhereNestedInput = {
  where: DirSportDisciplineGroupScalarWhereInput;
  data: DirSportDisciplineGroupUpdateManyDataInput;
};

export type DirSportDisciplineGroupUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DirSportDisciplineUpsertWithWhereUniqueWithoutDirSportInput = {
  where: DirSportDisciplineWhereUniqueInput;
  update: DirSportDisciplineUpdateWithoutDirSportDataInput;
  create: DirSportDisciplineCreateWithoutDirSportInput;
};

export type DirSportDisciplineScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_lt?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_contains?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportDisciplineScalarWhereInput>>;
  OR?: Maybe<Array<DirSportDisciplineScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportDisciplineScalarWhereInput>>;
};

export type DirSportDisciplineUpdateManyWithWhereNestedInput = {
  where: DirSportDisciplineScalarWhereInput;
  data: DirSportDisciplineUpdateManyDataInput;
};

export type DirSportDisciplineUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type DirSportDisciplineGroupUpdateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportDisciplineGroupCreateWithoutDirSportInput>>;
  delete?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  set?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  update?: Maybe<Array<DirSportDisciplineGroupUpdateWithWhereUniqueWithoutDirSportInput>>;
  upsert?: Maybe<Array<DirSportDisciplineGroupUpsertWithWhereUniqueWithoutDirSportInput>>;
  deleteMany?: Maybe<Array<DirSportDisciplineGroupScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportDisciplineGroupUpdateManyWithWhereNestedInput>>;
};

export type DirSportDisciplineGroupUpdateWithWhereUniqueWithoutDirSportInput = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  data: DirSportDisciplineGroupUpdateWithoutDirSportDataInput;
};

export type DirSportDisciplineGroupUpdateWithoutDirSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDisciplineGroupsInput>;
};

export type DirSportDisciplineUpdateManyWithoutDisciplineGroupsInput = {
  create?: Maybe<Array<DirSportDisciplineCreateWithoutDisciplineGroupsInput>>;
  delete?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  set?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  update?: Maybe<Array<DirSportDisciplineUpdateWithWhereUniqueWithoutDisciplineGroupsInput>>;
  upsert?: Maybe<Array<DirSportDisciplineUpsertWithWhereUniqueWithoutDisciplineGroupsInput>>;
  deleteMany?: Maybe<Array<DirSportDisciplineScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportDisciplineUpdateManyWithWhereNestedInput>>;
};

export type DirSportDisciplineUpdateWithWhereUniqueWithoutDisciplineGroupsInput = {
  where: DirSportDisciplineWhereUniqueInput;
  data: DirSportDisciplineUpdateWithoutDisciplineGroupsDataInput;
};

export type DirSportDisciplineUpdateWithoutDisciplineGroupsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  dirSport?: Maybe<DirSportUpdateOneWithoutDisciplinesInput>;
};

export type DirSportUpdateOneWithoutDisciplinesInput = {
  create?: Maybe<DirSportCreateWithoutDisciplinesInput>;
  update?: Maybe<DirSportUpdateWithoutDisciplinesDataInput>;
  upsert?: Maybe<DirSportUpsertWithoutDisciplinesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportUpdateWithoutDisciplinesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageUpdateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionUpdateManyWithoutSportInput>;
};

export type DirSportUpsertWithoutDisciplinesInput = {
  update: DirSportUpdateWithoutDisciplinesDataInput;
  create: DirSportCreateWithoutDisciplinesInput;
};

export type DirSportDisciplineUpsertWithWhereUniqueWithoutDisciplineGroupsInput = {
  where: DirSportDisciplineWhereUniqueInput;
  update: DirSportDisciplineUpdateWithoutDisciplineGroupsDataInput;
  create: DirSportDisciplineCreateWithoutDisciplineGroupsInput;
};

export type DirSportDisciplineGroupUpsertWithWhereUniqueWithoutDirSportInput = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  update: DirSportDisciplineGroupUpdateWithoutDirSportDataInput;
  create: DirSportDisciplineGroupCreateWithoutDirSportInput;
};

export type DirSportUpsertWithWhereUniqueNestedInput = {
  where: DirSportWhereUniqueInput;
  update: DirSportUpdateDataInput;
  create: DirSportCreateInput;
};

export type DirSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  evsk_not?: Maybe<Scalars['String']>;
  evsk_in?: Maybe<Array<Scalars['String']>>;
  evsk_not_in?: Maybe<Array<Scalars['String']>>;
  evsk_lt?: Maybe<Scalars['String']>;
  evsk_lte?: Maybe<Scalars['String']>;
  evsk_gt?: Maybe<Scalars['String']>;
  evsk_gte?: Maybe<Scalars['String']>;
  evsk_contains?: Maybe<Scalars['String']>;
  evsk_not_contains?: Maybe<Scalars['String']>;
  evsk_starts_with?: Maybe<Scalars['String']>;
  evsk_not_starts_with?: Maybe<Scalars['String']>;
  evsk_ends_with?: Maybe<Scalars['String']>;
  evsk_not_ends_with?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  isDisabled_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportScalarWhereInput>>;
  OR?: Maybe<Array<DirSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportScalarWhereInput>>;
};

export type DirSportUpdateManyWithWhereNestedInput = {
  where: DirSportScalarWhereInput;
  data: DirSportUpdateManyDataInput;
};

export type DirSportUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type DirOrganizationSportUpdateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationSportCreateWithoutDirOrganizationInput>>;
  delete?: Maybe<Array<DirOrganizationSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirOrganizationSportWhereUniqueInput>>;
  set?: Maybe<Array<DirOrganizationSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirOrganizationSportWhereUniqueInput>>;
  update?: Maybe<Array<DirOrganizationSportUpdateWithWhereUniqueWithoutDirOrganizationInput>>;
  upsert?: Maybe<Array<DirOrganizationSportUpsertWithWhereUniqueWithoutDirOrganizationInput>>;
  deleteMany?: Maybe<Array<DirOrganizationSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirOrganizationSportUpdateManyWithWhereNestedInput>>;
};

export type DirOrganizationSportUpdateWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationSportWhereUniqueInput;
  data: DirOrganizationSportUpdateWithoutDirOrganizationDataInput;
};

export type DirOrganizationSportUpdateWithoutDirOrganizationDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirSportUpdateOneRequiredInput = {
  create?: Maybe<DirSportCreateInput>;
  update?: Maybe<DirSportUpdateDataInput>;
  upsert?: Maybe<DirSportUpsertNestedInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportUpsertNestedInput = {
  update: DirSportUpdateDataInput;
  create: DirSportCreateInput;
};

export type DirSportDisciplineGroupUpdateManyInput = {
  create?: Maybe<Array<DirSportDisciplineGroupCreateInput>>;
  update?: Maybe<Array<DirSportDisciplineGroupUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirSportDisciplineGroupUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  set?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirSportDisciplineGroupScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportDisciplineGroupUpdateManyWithWhereNestedInput>>;
};

export type DirSportDisciplineGroupUpdateWithWhereUniqueNestedInput = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  data: DirSportDisciplineGroupUpdateDataInput;
};

export type DirSportDisciplineGroupUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDisciplineGroupsInput>;
  dirSport?: Maybe<DirSportUpdateOneWithoutDisciplineGroupsInput>;
};

export type DirSportDisciplineGroupUpsertWithWhereUniqueNestedInput = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  update: DirSportDisciplineGroupUpdateDataInput;
  create: DirSportDisciplineGroupCreateInput;
};

export type DirOrganizationSportUpsertWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationSportWhereUniqueInput;
  update: DirOrganizationSportUpdateWithoutDirOrganizationDataInput;
  create: DirOrganizationSportCreateWithoutDirOrganizationInput;
};

export type DirOrganizationSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  trainingStartDate_not?: Maybe<Scalars['DateTime']>;
  trainingStartDate_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStartDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStartDate_lt?: Maybe<Scalars['DateTime']>;
  trainingStartDate_lte?: Maybe<Scalars['DateTime']>;
  trainingStartDate_gt?: Maybe<Scalars['DateTime']>;
  trainingStartDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirOrganizationSportScalarWhereInput>>;
  OR?: Maybe<Array<DirOrganizationSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationSportScalarWhereInput>>;
};

export type DirOrganizationSportUpdateManyWithWhereNestedInput = {
  where: DirOrganizationSportScalarWhereInput;
  data: DirOrganizationSportUpdateManyDataInput;
};

export type DirOrganizationSportUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
};

export type DirOrganizationAddressUpdateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationAddressCreateWithoutDirOrganizationInput>>;
  delete?: Maybe<Array<DirOrganizationAddressWhereUniqueInput>>;
  connect?: Maybe<Array<DirOrganizationAddressWhereUniqueInput>>;
  set?: Maybe<Array<DirOrganizationAddressWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirOrganizationAddressWhereUniqueInput>>;
  update?: Maybe<Array<DirOrganizationAddressUpdateWithWhereUniqueWithoutDirOrganizationInput>>;
  upsert?: Maybe<Array<DirOrganizationAddressUpsertWithWhereUniqueWithoutDirOrganizationInput>>;
  deleteMany?: Maybe<Array<DirOrganizationAddressScalarWhereInput>>;
  updateMany?: Maybe<Array<DirOrganizationAddressUpdateManyWithWhereNestedInput>>;
};

export type DirOrganizationAddressUpdateWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationAddressWhereUniqueInput;
  data: DirOrganizationAddressUpdateWithoutDirOrganizationDataInput;
};

export type DirOrganizationAddressUpdateWithoutDirOrganizationDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type?: Maybe<AddressTypeUpdateOneRequiredInput>;
};

export type AddressTypeUpdateOneRequiredInput = {
  create?: Maybe<AddressTypeCreateInput>;
  update?: Maybe<AddressTypeUpdateDataInput>;
  upsert?: Maybe<AddressTypeUpsertNestedInput>;
  connect?: Maybe<AddressTypeWhereUniqueInput>;
};

export type AddressTypeUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type AddressTypeUpsertNestedInput = {
  update: AddressTypeUpdateDataInput;
  create: AddressTypeCreateInput;
};

export type DirOrganizationAddressUpsertWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationAddressWhereUniqueInput;
  update: DirOrganizationAddressUpdateWithoutDirOrganizationDataInput;
  create: DirOrganizationAddressCreateWithoutDirOrganizationInput;
};

export type DirOrganizationAddressScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  place_not?: Maybe<Scalars['String']>;
  place_in?: Maybe<Array<Scalars['String']>>;
  place_not_in?: Maybe<Array<Scalars['String']>>;
  place_lt?: Maybe<Scalars['String']>;
  place_lte?: Maybe<Scalars['String']>;
  place_gt?: Maybe<Scalars['String']>;
  place_gte?: Maybe<Scalars['String']>;
  place_contains?: Maybe<Scalars['String']>;
  place_not_contains?: Maybe<Scalars['String']>;
  place_starts_with?: Maybe<Scalars['String']>;
  place_not_starts_with?: Maybe<Scalars['String']>;
  place_ends_with?: Maybe<Scalars['String']>;
  place_not_ends_with?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_not?: Maybe<Scalars['String']>;
  street_in?: Maybe<Array<Scalars['String']>>;
  street_not_in?: Maybe<Array<Scalars['String']>>;
  street_lt?: Maybe<Scalars['String']>;
  street_lte?: Maybe<Scalars['String']>;
  street_gt?: Maybe<Scalars['String']>;
  street_gte?: Maybe<Scalars['String']>;
  street_contains?: Maybe<Scalars['String']>;
  street_not_contains?: Maybe<Scalars['String']>;
  street_starts_with?: Maybe<Scalars['String']>;
  street_not_starts_with?: Maybe<Scalars['String']>;
  street_ends_with?: Maybe<Scalars['String']>;
  street_not_ends_with?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  house_not?: Maybe<Scalars['String']>;
  house_in?: Maybe<Array<Scalars['String']>>;
  house_not_in?: Maybe<Array<Scalars['String']>>;
  house_lt?: Maybe<Scalars['String']>;
  house_lte?: Maybe<Scalars['String']>;
  house_gt?: Maybe<Scalars['String']>;
  house_gte?: Maybe<Scalars['String']>;
  house_contains?: Maybe<Scalars['String']>;
  house_not_contains?: Maybe<Scalars['String']>;
  house_starts_with?: Maybe<Scalars['String']>;
  house_not_starts_with?: Maybe<Scalars['String']>;
  house_ends_with?: Maybe<Scalars['String']>;
  house_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirOrganizationAddressScalarWhereInput>>;
  OR?: Maybe<Array<DirOrganizationAddressScalarWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationAddressScalarWhereInput>>;
};

export type DirOrganizationAddressUpdateManyWithWhereNestedInput = {
  where: DirOrganizationAddressScalarWhereInput;
  data: DirOrganizationAddressUpdateManyDataInput;
};

export type DirOrganizationAddressUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationPhoneCreateWithoutDirOrganizationInput>>;
  delete?: Maybe<Array<DirOrganizationPhoneWhereUniqueInput>>;
  connect?: Maybe<Array<DirOrganizationPhoneWhereUniqueInput>>;
  set?: Maybe<Array<DirOrganizationPhoneWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirOrganizationPhoneWhereUniqueInput>>;
  update?: Maybe<Array<DirOrganizationPhoneUpdateWithWhereUniqueWithoutDirOrganizationInput>>;
  upsert?: Maybe<Array<DirOrganizationPhoneUpsertWithWhereUniqueWithoutDirOrganizationInput>>;
  deleteMany?: Maybe<Array<DirOrganizationPhoneScalarWhereInput>>;
  updateMany?: Maybe<Array<DirOrganizationPhoneUpdateManyWithWhereNestedInput>>;
};

export type DirOrganizationPhoneUpdateWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationPhoneWhereUniqueInput;
  data: DirOrganizationPhoneUpdateWithoutDirOrganizationDataInput;
};

export type DirOrganizationPhoneUpdateWithoutDirOrganizationDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueUpdateOneRequiredInput>;
};

export type DirOrganizationPhoneUpsertWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationPhoneWhereUniqueInput;
  update: DirOrganizationPhoneUpdateWithoutDirOrganizationDataInput;
  create: DirOrganizationPhoneCreateWithoutDirOrganizationInput;
};

export type DirOrganizationPhoneScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  countryCode_not?: Maybe<Scalars['String']>;
  countryCode_in?: Maybe<Array<Scalars['String']>>;
  countryCode_not_in?: Maybe<Array<Scalars['String']>>;
  countryCode_lt?: Maybe<Scalars['String']>;
  countryCode_lte?: Maybe<Scalars['String']>;
  countryCode_gt?: Maybe<Scalars['String']>;
  countryCode_gte?: Maybe<Scalars['String']>;
  countryCode_contains?: Maybe<Scalars['String']>;
  countryCode_not_contains?: Maybe<Scalars['String']>;
  countryCode_starts_with?: Maybe<Scalars['String']>;
  countryCode_not_starts_with?: Maybe<Scalars['String']>;
  countryCode_ends_with?: Maybe<Scalars['String']>;
  countryCode_not_ends_with?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  operatorCode_not?: Maybe<Scalars['String']>;
  operatorCode_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_not_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_lt?: Maybe<Scalars['String']>;
  operatorCode_lte?: Maybe<Scalars['String']>;
  operatorCode_gt?: Maybe<Scalars['String']>;
  operatorCode_gte?: Maybe<Scalars['String']>;
  operatorCode_contains?: Maybe<Scalars['String']>;
  operatorCode_not_contains?: Maybe<Scalars['String']>;
  operatorCode_starts_with?: Maybe<Scalars['String']>;
  operatorCode_not_starts_with?: Maybe<Scalars['String']>;
  operatorCode_ends_with?: Maybe<Scalars['String']>;
  operatorCode_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  additionalNumber_not?: Maybe<Scalars['String']>;
  additionalNumber_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_not_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_lt?: Maybe<Scalars['String']>;
  additionalNumber_lte?: Maybe<Scalars['String']>;
  additionalNumber_gt?: Maybe<Scalars['String']>;
  additionalNumber_gte?: Maybe<Scalars['String']>;
  additionalNumber_contains?: Maybe<Scalars['String']>;
  additionalNumber_not_contains?: Maybe<Scalars['String']>;
  additionalNumber_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_not_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_ends_with?: Maybe<Scalars['String']>;
  additionalNumber_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirOrganizationPhoneScalarWhereInput>>;
  OR?: Maybe<Array<DirOrganizationPhoneScalarWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationPhoneScalarWhereInput>>;
};

export type DirOrganizationPhoneUpdateManyWithWhereNestedInput = {
  where: DirOrganizationPhoneScalarWhereInput;
  data: DirOrganizationPhoneUpdateManyDataInput;
};

export type DirOrganizationPhoneUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
};

export type DirOrganizationEmailUpdateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationEmailCreateWithoutDirOrganizationInput>>;
  delete?: Maybe<Array<DirOrganizationEmailWhereUniqueInput>>;
  connect?: Maybe<Array<DirOrganizationEmailWhereUniqueInput>>;
  set?: Maybe<Array<DirOrganizationEmailWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirOrganizationEmailWhereUniqueInput>>;
  update?: Maybe<Array<DirOrganizationEmailUpdateWithWhereUniqueWithoutDirOrganizationInput>>;
  upsert?: Maybe<Array<DirOrganizationEmailUpsertWithWhereUniqueWithoutDirOrganizationInput>>;
  deleteMany?: Maybe<Array<DirOrganizationEmailScalarWhereInput>>;
  updateMany?: Maybe<Array<DirOrganizationEmailUpdateManyWithWhereNestedInput>>;
};

export type DirOrganizationEmailUpdateWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationEmailWhereUniqueInput;
  data: DirOrganizationEmailUpdateWithoutDirOrganizationDataInput;
};

export type DirOrganizationEmailUpdateWithoutDirOrganizationDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueUpdateOneRequiredInput>;
};

export type DirOrganizationEmailUpsertWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationEmailWhereUniqueInput;
  update: DirOrganizationEmailUpdateWithoutDirOrganizationDataInput;
  create: DirOrganizationEmailCreateWithoutDirOrganizationInput;
};

export type DirOrganizationEmailScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirOrganizationEmailScalarWhereInput>>;
  OR?: Maybe<Array<DirOrganizationEmailScalarWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationEmailScalarWhereInput>>;
};

export type DirOrganizationEmailUpdateManyWithWhereNestedInput = {
  where: DirOrganizationEmailScalarWhereInput;
  data: DirOrganizationEmailUpdateManyDataInput;
};

export type DirOrganizationEmailUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
};

export type DirOrganizationUpdatewebsitesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type DirOrganizationUpsertWithoutChildrenInput = {
  update: DirOrganizationUpdateWithoutChildrenDataInput;
  create: DirOrganizationCreateWithoutChildrenInput;
};

export type DirOrganizationUpdateManyWithoutParentInput = {
  create?: Maybe<Array<DirOrganizationCreateWithoutParentInput>>;
  delete?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
  connect?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
  set?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
  update?: Maybe<Array<DirOrganizationUpdateWithWhereUniqueWithoutParentInput>>;
  upsert?: Maybe<Array<DirOrganizationUpsertWithWhereUniqueWithoutParentInput>>;
  deleteMany?: Maybe<Array<DirOrganizationScalarWhereInput>>;
  updateMany?: Maybe<Array<DirOrganizationUpdateManyWithWhereNestedInput>>;
};

export type DirOrganizationUpdateWithWhereUniqueWithoutParentInput = {
  where: DirOrganizationWhereUniqueInput;
  data: DirOrganizationUpdateWithoutParentDataInput;
};

export type DirOrganizationUpdateWithoutParentDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type DirOrganizationUpsertWithWhereUniqueWithoutParentInput = {
  where: DirOrganizationWhereUniqueInput;
  update: DirOrganizationUpdateWithoutParentDataInput;
  create: DirOrganizationCreateWithoutParentInput;
};

export type DirOrganizationScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  isLegalEntity_not?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  ogrn_not?: Maybe<Scalars['String']>;
  ogrn_in?: Maybe<Array<Scalars['String']>>;
  ogrn_not_in?: Maybe<Array<Scalars['String']>>;
  ogrn_lt?: Maybe<Scalars['String']>;
  ogrn_lte?: Maybe<Scalars['String']>;
  ogrn_gt?: Maybe<Scalars['String']>;
  ogrn_gte?: Maybe<Scalars['String']>;
  ogrn_contains?: Maybe<Scalars['String']>;
  ogrn_not_contains?: Maybe<Scalars['String']>;
  ogrn_starts_with?: Maybe<Scalars['String']>;
  ogrn_not_starts_with?: Maybe<Scalars['String']>;
  ogrn_ends_with?: Maybe<Scalars['String']>;
  ogrn_not_ends_with?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  registrationDate_not?: Maybe<Scalars['DateTime']>;
  registrationDate_in?: Maybe<Array<Scalars['DateTime']>>;
  registrationDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  registrationDate_lt?: Maybe<Scalars['DateTime']>;
  registrationDate_lte?: Maybe<Scalars['DateTime']>;
  registrationDate_gt?: Maybe<Scalars['DateTime']>;
  registrationDate_gte?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  inn_not?: Maybe<Scalars['String']>;
  inn_in?: Maybe<Array<Scalars['String']>>;
  inn_not_in?: Maybe<Array<Scalars['String']>>;
  inn_lt?: Maybe<Scalars['String']>;
  inn_lte?: Maybe<Scalars['String']>;
  inn_gt?: Maybe<Scalars['String']>;
  inn_gte?: Maybe<Scalars['String']>;
  inn_contains?: Maybe<Scalars['String']>;
  inn_not_contains?: Maybe<Scalars['String']>;
  inn_starts_with?: Maybe<Scalars['String']>;
  inn_not_starts_with?: Maybe<Scalars['String']>;
  inn_ends_with?: Maybe<Scalars['String']>;
  inn_not_ends_with?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  kpp_not?: Maybe<Scalars['String']>;
  kpp_in?: Maybe<Array<Scalars['String']>>;
  kpp_not_in?: Maybe<Array<Scalars['String']>>;
  kpp_lt?: Maybe<Scalars['String']>;
  kpp_lte?: Maybe<Scalars['String']>;
  kpp_gt?: Maybe<Scalars['String']>;
  kpp_gte?: Maybe<Scalars['String']>;
  kpp_contains?: Maybe<Scalars['String']>;
  kpp_not_contains?: Maybe<Scalars['String']>;
  kpp_starts_with?: Maybe<Scalars['String']>;
  kpp_not_starts_with?: Maybe<Scalars['String']>;
  kpp_ends_with?: Maybe<Scalars['String']>;
  kpp_not_ends_with?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  legalAddress_not?: Maybe<Scalars['String']>;
  legalAddress_in?: Maybe<Array<Scalars['String']>>;
  legalAddress_not_in?: Maybe<Array<Scalars['String']>>;
  legalAddress_lt?: Maybe<Scalars['String']>;
  legalAddress_lte?: Maybe<Scalars['String']>;
  legalAddress_gt?: Maybe<Scalars['String']>;
  legalAddress_gte?: Maybe<Scalars['String']>;
  legalAddress_contains?: Maybe<Scalars['String']>;
  legalAddress_not_contains?: Maybe<Scalars['String']>;
  legalAddress_starts_with?: Maybe<Scalars['String']>;
  legalAddress_not_starts_with?: Maybe<Scalars['String']>;
  legalAddress_ends_with?: Maybe<Scalars['String']>;
  legalAddress_not_ends_with?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  postcode_not?: Maybe<Scalars['String']>;
  postcode_in?: Maybe<Array<Scalars['String']>>;
  postcode_not_in?: Maybe<Array<Scalars['String']>>;
  postcode_lt?: Maybe<Scalars['String']>;
  postcode_lte?: Maybe<Scalars['String']>;
  postcode_gt?: Maybe<Scalars['String']>;
  postcode_gte?: Maybe<Scalars['String']>;
  postcode_contains?: Maybe<Scalars['String']>;
  postcode_not_contains?: Maybe<Scalars['String']>;
  postcode_starts_with?: Maybe<Scalars['String']>;
  postcode_not_starts_with?: Maybe<Scalars['String']>;
  postcode_ends_with?: Maybe<Scalars['String']>;
  postcode_not_ends_with?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headPosition_not?: Maybe<Scalars['String']>;
  headPosition_in?: Maybe<Array<Scalars['String']>>;
  headPosition_not_in?: Maybe<Array<Scalars['String']>>;
  headPosition_lt?: Maybe<Scalars['String']>;
  headPosition_lte?: Maybe<Scalars['String']>;
  headPosition_gt?: Maybe<Scalars['String']>;
  headPosition_gte?: Maybe<Scalars['String']>;
  headPosition_contains?: Maybe<Scalars['String']>;
  headPosition_not_contains?: Maybe<Scalars['String']>;
  headPosition_starts_with?: Maybe<Scalars['String']>;
  headPosition_not_starts_with?: Maybe<Scalars['String']>;
  headPosition_ends_with?: Maybe<Scalars['String']>;
  headPosition_not_ends_with?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  headFullName_not?: Maybe<Scalars['String']>;
  headFullName_in?: Maybe<Array<Scalars['String']>>;
  headFullName_not_in?: Maybe<Array<Scalars['String']>>;
  headFullName_lt?: Maybe<Scalars['String']>;
  headFullName_lte?: Maybe<Scalars['String']>;
  headFullName_gt?: Maybe<Scalars['String']>;
  headFullName_gte?: Maybe<Scalars['String']>;
  headFullName_contains?: Maybe<Scalars['String']>;
  headFullName_not_contains?: Maybe<Scalars['String']>;
  headFullName_starts_with?: Maybe<Scalars['String']>;
  headFullName_not_starts_with?: Maybe<Scalars['String']>;
  headFullName_ends_with?: Maybe<Scalars['String']>;
  headFullName_not_ends_with?: Maybe<Scalars['String']>;
  isUniversity?: Maybe<Scalars['Boolean']>;
  isUniversity_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirOrganizationScalarWhereInput>>;
  OR?: Maybe<Array<DirOrganizationScalarWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationScalarWhereInput>>;
};

export type DirOrganizationUpdateManyWithWhereNestedInput = {
  where: DirOrganizationScalarWhereInput;
  data: DirOrganizationUpdateManyDataInput;
};

export type DirOrganizationUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type DirOrganizationUpsertNestedInput = {
  update: DirOrganizationUpdateDataInput;
  create: DirOrganizationCreateInput;
};

export type RegionalSchoolProfileUpsertNestedInput = {
  update: RegionalSchoolProfileUpdateDataInput;
  create: RegionalSchoolProfileCreateInput;
};

export type RegionalAdministratorProfileUpdateOneInput = {
  create?: Maybe<RegionalAdministratorProfileCreateInput>;
  update?: Maybe<RegionalAdministratorProfileUpdateDataInput>;
  upsert?: Maybe<RegionalAdministratorProfileUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<RegionalAdministratorProfileWhereUniqueInput>;
};

export type RegionalAdministratorProfileUpdateDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
};

export type RegionalAdministratorProfileUpsertNestedInput = {
  update: RegionalAdministratorProfileUpdateDataInput;
  create: RegionalAdministratorProfileCreateInput;
};

export type RoivSpecialistProfileUpdateOneInput = {
  create?: Maybe<RoivSpecialistProfileCreateInput>;
  update?: Maybe<RoivSpecialistProfileUpdateDataInput>;
  upsert?: Maybe<RoivSpecialistProfileUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<RoivSpecialistProfileWhereUniqueInput>;
};

export type RoivSpecialistProfileUpdateDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
};

export type RoivSpecialistProfileUpsertNestedInput = {
  update: RoivSpecialistProfileUpdateDataInput;
  create: RoivSpecialistProfileCreateInput;
};

export type FcpsrSportingEventOrganizerProfileUpdateOneInput = {
  create?: Maybe<FcpsrSportingEventOrganizerProfileCreateInput>;
  update?: Maybe<FcpsrSportingEventOrganizerProfileUpdateDataInput>;
  upsert?: Maybe<FcpsrSportingEventOrganizerProfileUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FcpsrSportingEventOrganizerProfileWhereUniqueInput>;
};

export type FcpsrSportingEventOrganizerProfileUpdateDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  sportingEvents?: Maybe<DirSportingEventUpdateManyInput>;
  dirCalendars?: Maybe<DirCalendarUpdateManyInput>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
};

export type DirSportingEventUpdateManyInput = {
  create?: Maybe<Array<DirSportingEventCreateInput>>;
  update?: Maybe<Array<DirSportingEventUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirSportingEventUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirSportingEventScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventUpdateWithWhereUniqueNestedInput = {
  where: DirSportingEventWhereUniqueInput;
  data: DirSportingEventUpdateDataInput;
};

export type DirSportingEventUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirNationalTeamUpdateManyWithoutDirSportingEventInput = {
  create?: Maybe<Array<DirNationalTeamCreateWithoutDirSportingEventInput>>;
  delete?: Maybe<Array<DirNationalTeamWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamWhereUniqueInput>>;
  update?: Maybe<Array<DirNationalTeamUpdateWithWhereUniqueWithoutDirSportingEventInput>>;
  upsert?: Maybe<Array<DirNationalTeamUpsertWithWhereUniqueWithoutDirSportingEventInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamUpdateWithWhereUniqueWithoutDirSportingEventInput = {
  where: DirNationalTeamWhereUniqueInput;
  data: DirNationalTeamUpdateWithoutDirSportingEventDataInput;
};

export type DirNationalTeamUpdateWithoutDirSportingEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirNationalTeamExtendedStatusUpdateManyWithoutTeamInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  trainers?: Maybe<DirNationalTeamTrainerUpdateManyWithoutTeamInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupUpdateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  medics?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  delegationHeads?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  emailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutTeamInput>;
  files?: Maybe<FileUpdateManyInput>;
  university?: Maybe<DirOrganizationUpdateOneInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  arrivalDeparturePlan?: Maybe<FileUpdateManyInput>;
  preliminaryQuantitativeOrder?: Maybe<FileUpdateManyInput>;
};

export type DirNationalTeamStatusUpdateOneRequiredInput = {
  create?: Maybe<DirNationalTeamStatusCreateInput>;
  update?: Maybe<DirNationalTeamStatusUpdateDataInput>;
  upsert?: Maybe<DirNationalTeamStatusUpsertNestedInput>;
  connect?: Maybe<DirNationalTeamStatusWhereUniqueInput>;
};

export type DirNationalTeamStatusUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  isDeprecated?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamStatusUpsertNestedInput = {
  update: DirNationalTeamStatusUpdateDataInput;
  create: DirNationalTeamStatusCreateInput;
};

export type DirNationalTeamStatusHistoryUpdateManyInput = {
  create?: Maybe<Array<DirNationalTeamStatusHistoryCreateInput>>;
  update?: Maybe<Array<DirNationalTeamStatusHistoryUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirNationalTeamStatusHistoryUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirNationalTeamStatusHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamStatusHistoryWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamStatusHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamStatusHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamStatusHistoryScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamStatusHistoryUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamStatusHistoryUpdateWithWhereUniqueNestedInput = {
  where: DirNationalTeamStatusHistoryWhereUniqueInput;
  data: DirNationalTeamStatusHistoryUpdateDataInput;
};

export type DirNationalTeamStatusHistoryUpdateDataInput = {
  date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  user?: Maybe<UserUpdateOneRequiredInput>;
};

export type UserUpdateOneRequiredInput = {
  create?: Maybe<UserCreateInput>;
  update?: Maybe<UserUpdateDataInput>;
  upsert?: Maybe<UserUpsertNestedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpsertNestedInput = {
  update: UserUpdateDataInput;
  create: UserCreateInput;
};

export type DirNationalTeamStatusHistoryUpsertWithWhereUniqueNestedInput = {
  where: DirNationalTeamStatusHistoryWhereUniqueInput;
  update: DirNationalTeamStatusHistoryUpdateDataInput;
  create: DirNationalTeamStatusHistoryCreateInput;
};

export type DirNationalTeamStatusHistoryScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirNationalTeamStatusHistoryScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamStatusHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamStatusHistoryScalarWhereInput>>;
};

export type DirNationalTeamStatusHistoryUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamStatusHistoryScalarWhereInput;
  data: DirNationalTeamStatusHistoryUpdateManyDataInput;
};

export type DirNationalTeamStatusHistoryUpdateManyDataInput = {
  date?: Maybe<Scalars['DateTime']>;
};

export type DirNationalTeamExtendedStatusUpdateManyWithoutTeamInput = {
  create?: Maybe<Array<DirNationalTeamExtendedStatusCreateWithoutTeamInput>>;
  delete?: Maybe<Array<DirNationalTeamExtendedStatusWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamExtendedStatusWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamExtendedStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamExtendedStatusWhereUniqueInput>>;
  update?: Maybe<Array<DirNationalTeamExtendedStatusUpdateWithWhereUniqueWithoutTeamInput>>;
  upsert?: Maybe<Array<DirNationalTeamExtendedStatusUpsertWithWhereUniqueWithoutTeamInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamExtendedStatusScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamExtendedStatusUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamExtendedStatusUpdateWithWhereUniqueWithoutTeamInput = {
  where: DirNationalTeamExtendedStatusWhereUniqueInput;
  data: DirNationalTeamExtendedStatusUpdateWithoutTeamDataInput;
};

export type DirNationalTeamExtendedStatusUpdateWithoutTeamDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  value?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  file?: Maybe<FileUpdateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type FileUpdateOneInput = {
  create?: Maybe<FileCreateInput>;
  update?: Maybe<FileUpdateDataInput>;
  upsert?: Maybe<FileUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FileWhereUniqueInput>;
};

export type FileUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type FileUpsertNestedInput = {
  update: FileUpdateDataInput;
  create: FileCreateInput;
};

export type DirNationalTeamExtendedStatusUpsertWithWhereUniqueWithoutTeamInput = {
  where: DirNationalTeamExtendedStatusWhereUniqueInput;
  update: DirNationalTeamExtendedStatusUpdateWithoutTeamDataInput;
  create: DirNationalTeamExtendedStatusCreateWithoutTeamInput;
};

export type DirNationalTeamExtendedStatusScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirNationalTeamExtendedStatusScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamExtendedStatusScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamExtendedStatusScalarWhereInput>>;
};

export type DirNationalTeamExtendedStatusUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamExtendedStatusScalarWhereInput;
  data: DirNationalTeamExtendedStatusUpdateManyDataInput;
};

export type DirNationalTeamExtendedStatusUpdateManyDataInput = {
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamTrainerUpdateManyWithoutTeamInput = {
  create?: Maybe<Array<DirNationalTeamTrainerCreateWithoutTeamInput>>;
  delete?: Maybe<Array<DirNationalTeamTrainerWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamTrainerWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamTrainerWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamTrainerWhereUniqueInput>>;
  update?: Maybe<Array<DirNationalTeamTrainerUpdateWithWhereUniqueWithoutTeamInput>>;
  upsert?: Maybe<Array<DirNationalTeamTrainerUpsertWithWhereUniqueWithoutTeamInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamTrainerScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamTrainerUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamTrainerUpdateWithWhereUniqueWithoutTeamInput = {
  where: DirNationalTeamTrainerWhereUniqueInput;
  data: DirNationalTeamTrainerUpdateWithoutTeamDataInput;
};

export type DirNationalTeamTrainerUpdateWithoutTeamDataInput = {
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
  dirTrainer?: Maybe<DirTrainerUpdateOneRequiredInput>;
  clsPosition?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirTrainerUpdateOneRequiredInput = {
  create?: Maybe<DirTrainerCreateInput>;
  update?: Maybe<DirTrainerUpdateDataInput>;
  upsert?: Maybe<DirTrainerUpsertNestedInput>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirTrainerUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutTrainerProfileInput>;
  sports?: Maybe<DirTrainerSportUpdateManyWithoutDirTrainerInput>;
};

export type DirPersonUpdateOneRequiredWithoutTrainerProfileInput = {
  create?: Maybe<DirPersonCreateWithoutTrainerProfileInput>;
  update?: Maybe<DirPersonUpdateWithoutTrainerProfileDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutTrainerProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutTrainerProfileDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type DirPersonUpdateManyWithoutPrimaryInput = {
  create?: Maybe<Array<DirPersonCreateWithoutPrimaryInput>>;
  delete?: Maybe<Array<DirPersonWhereUniqueInput>>;
  connect?: Maybe<Array<DirPersonWhereUniqueInput>>;
  set?: Maybe<Array<DirPersonWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirPersonWhereUniqueInput>>;
  update?: Maybe<Array<DirPersonUpdateWithWhereUniqueWithoutPrimaryInput>>;
  upsert?: Maybe<Array<DirPersonUpsertWithWhereUniqueWithoutPrimaryInput>>;
  deleteMany?: Maybe<Array<DirPersonScalarWhereInput>>;
  updateMany?: Maybe<Array<DirPersonUpdateManyWithWhereNestedInput>>;
};

export type DirPersonUpdateWithWhereUniqueWithoutPrimaryInput = {
  where: DirPersonWhereUniqueInput;
  data: DirPersonUpdateWithoutPrimaryDataInput;
};

export type DirPersonUpdateWithoutPrimaryDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type DirAthleteUpdateOneWithoutDirPersonInput = {
  create?: Maybe<DirAthleteCreateWithoutDirPersonInput>;
  update?: Maybe<DirAthleteUpdateWithoutDirPersonDataInput>;
  upsert?: Maybe<DirAthleteUpsertWithoutDirPersonInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteUpdateWithoutDirPersonDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPersonAgent?: Maybe<DirPersonUpdateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueUpdateOneInput>;
  sports?: Maybe<DirAthleteSportUpdateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertUpdateManyWithoutDirAthleteInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultUpdateManyWithoutDirAthleteInput>;
};

export type DirPersonUpdateOneInput = {
  create?: Maybe<DirPersonCreateInput>;
  update?: Maybe<DirPersonUpdateDataInput>;
  upsert?: Maybe<DirPersonUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type DirTrainerUpdateOneWithoutDirPersonInput = {
  create?: Maybe<DirTrainerCreateWithoutDirPersonInput>;
  update?: Maybe<DirTrainerUpdateWithoutDirPersonDataInput>;
  upsert?: Maybe<DirTrainerUpsertWithoutDirPersonInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirTrainerUpdateWithoutDirPersonDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sports?: Maybe<DirTrainerSportUpdateManyWithoutDirTrainerInput>;
};

export type DirTrainerSportUpdateManyWithoutDirTrainerInput = {
  create?: Maybe<Array<DirTrainerSportCreateWithoutDirTrainerInput>>;
  delete?: Maybe<Array<DirTrainerSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirTrainerSportWhereUniqueInput>>;
  set?: Maybe<Array<DirTrainerSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirTrainerSportWhereUniqueInput>>;
  update?: Maybe<Array<DirTrainerSportUpdateWithWhereUniqueWithoutDirTrainerInput>>;
  upsert?: Maybe<Array<DirTrainerSportUpsertWithWhereUniqueWithoutDirTrainerInput>>;
  deleteMany?: Maybe<Array<DirTrainerSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirTrainerSportUpdateManyWithWhereNestedInput>>;
};

export type DirTrainerSportUpdateWithWhereUniqueWithoutDirTrainerInput = {
  where: DirTrainerSportWhereUniqueInput;
  data: DirTrainerSportUpdateWithoutDirTrainerDataInput;
};

export type DirTrainerSportUpdateWithoutDirTrainerDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  organizations?: Maybe<DirTrainerSportOrganizationUpdateManyWithoutSportInput>;
  categories?: Maybe<DirTrainerSportCategoryUpdateManyWithoutSportInput>;
};

export type DirTrainerSportOrganizationUpdateManyWithoutSportInput = {
  create?: Maybe<Array<DirTrainerSportOrganizationCreateWithoutSportInput>>;
  delete?: Maybe<Array<DirTrainerSportOrganizationWhereUniqueInput>>;
  connect?: Maybe<Array<DirTrainerSportOrganizationWhereUniqueInput>>;
  set?: Maybe<Array<DirTrainerSportOrganizationWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirTrainerSportOrganizationWhereUniqueInput>>;
  update?: Maybe<Array<DirTrainerSportOrganizationUpdateWithWhereUniqueWithoutSportInput>>;
  upsert?: Maybe<Array<DirTrainerSportOrganizationUpsertWithWhereUniqueWithoutSportInput>>;
  deleteMany?: Maybe<Array<DirTrainerSportOrganizationScalarWhereInput>>;
  updateMany?: Maybe<Array<DirTrainerSportOrganizationUpdateManyWithWhereNestedInput>>;
};

export type DirTrainerSportOrganizationUpdateWithWhereUniqueWithoutSportInput = {
  where: DirTrainerSportOrganizationWhereUniqueInput;
  data: DirTrainerSportOrganizationUpdateWithoutSportDataInput;
};

export type DirTrainerSportOrganizationUpdateWithoutSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredInput>;
  clsPosition?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  employmentDate?: Maybe<Scalars['DateTime']>;
  dismissalDate?: Maybe<Scalars['DateTime']>;
};

export type DirTrainerSportOrganizationUpsertWithWhereUniqueWithoutSportInput = {
  where: DirTrainerSportOrganizationWhereUniqueInput;
  update: DirTrainerSportOrganizationUpdateWithoutSportDataInput;
  create: DirTrainerSportOrganizationCreateWithoutSportInput;
};

export type DirTrainerSportOrganizationScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  employmentDate?: Maybe<Scalars['DateTime']>;
  employmentDate_not?: Maybe<Scalars['DateTime']>;
  employmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  employmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  employmentDate_lt?: Maybe<Scalars['DateTime']>;
  employmentDate_lte?: Maybe<Scalars['DateTime']>;
  employmentDate_gt?: Maybe<Scalars['DateTime']>;
  employmentDate_gte?: Maybe<Scalars['DateTime']>;
  dismissalDate?: Maybe<Scalars['DateTime']>;
  dismissalDate_not?: Maybe<Scalars['DateTime']>;
  dismissalDate_in?: Maybe<Array<Scalars['DateTime']>>;
  dismissalDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  dismissalDate_lt?: Maybe<Scalars['DateTime']>;
  dismissalDate_lte?: Maybe<Scalars['DateTime']>;
  dismissalDate_gt?: Maybe<Scalars['DateTime']>;
  dismissalDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirTrainerSportOrganizationScalarWhereInput>>;
  OR?: Maybe<Array<DirTrainerSportOrganizationScalarWhereInput>>;
  NOT?: Maybe<Array<DirTrainerSportOrganizationScalarWhereInput>>;
};

export type DirTrainerSportOrganizationUpdateManyWithWhereNestedInput = {
  where: DirTrainerSportOrganizationScalarWhereInput;
  data: DirTrainerSportOrganizationUpdateManyDataInput;
};

export type DirTrainerSportOrganizationUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  employmentDate?: Maybe<Scalars['DateTime']>;
  dismissalDate?: Maybe<Scalars['DateTime']>;
};

export type DirTrainerSportCategoryUpdateManyWithoutSportInput = {
  create?: Maybe<Array<DirTrainerSportCategoryCreateWithoutSportInput>>;
  delete?: Maybe<Array<DirTrainerSportCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<DirTrainerSportCategoryWhereUniqueInput>>;
  set?: Maybe<Array<DirTrainerSportCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirTrainerSportCategoryWhereUniqueInput>>;
  update?: Maybe<Array<DirTrainerSportCategoryUpdateWithWhereUniqueWithoutSportInput>>;
  upsert?: Maybe<Array<DirTrainerSportCategoryUpsertWithWhereUniqueWithoutSportInput>>;
  deleteMany?: Maybe<Array<DirTrainerSportCategoryScalarWhereInput>>;
  updateMany?: Maybe<Array<DirTrainerSportCategoryUpdateManyWithWhereNestedInput>>;
};

export type DirTrainerSportCategoryUpdateWithWhereUniqueWithoutSportInput = {
  where: DirTrainerSportCategoryWhereUniqueInput;
  data: DirTrainerSportCategoryUpdateWithoutSportDataInput;
};

export type DirTrainerSportCategoryUpdateWithoutSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainerCategory?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
};

export type DirTrainerSportCategoryUpsertWithWhereUniqueWithoutSportInput = {
  where: DirTrainerSportCategoryWhereUniqueInput;
  update: DirTrainerSportCategoryUpdateWithoutSportDataInput;
  create: DirTrainerSportCategoryCreateWithoutSportInput;
};

export type DirTrainerSportCategoryScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  assignmentDate_not?: Maybe<Scalars['DateTime']>;
  assignmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_lt?: Maybe<Scalars['DateTime']>;
  assignmentDate_lte?: Maybe<Scalars['DateTime']>;
  assignmentDate_gt?: Maybe<Scalars['DateTime']>;
  assignmentDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  isEndless_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirTrainerSportCategoryScalarWhereInput>>;
  OR?: Maybe<Array<DirTrainerSportCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<DirTrainerSportCategoryScalarWhereInput>>;
};

export type DirTrainerSportCategoryUpdateManyWithWhereNestedInput = {
  where: DirTrainerSportCategoryScalarWhereInput;
  data: DirTrainerSportCategoryUpdateManyDataInput;
};

export type DirTrainerSportCategoryUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
};

export type DirTrainerSportUpsertWithWhereUniqueWithoutDirTrainerInput = {
  where: DirTrainerSportWhereUniqueInput;
  update: DirTrainerSportUpdateWithoutDirTrainerDataInput;
  create: DirTrainerSportCreateWithoutDirTrainerInput;
};

export type DirTrainerSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirTrainerSportScalarWhereInput>>;
  OR?: Maybe<Array<DirTrainerSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirTrainerSportScalarWhereInput>>;
};

export type DirTrainerSportUpdateManyWithWhereNestedInput = {
  where: DirTrainerSportScalarWhereInput;
  data: DirTrainerSportUpdateManyDataInput;
};

export type DirTrainerSportUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirTrainerUpsertWithoutDirPersonInput = {
  update: DirTrainerUpdateWithoutDirPersonDataInput;
  create: DirTrainerCreateWithoutDirPersonInput;
};

export type DirRefereeUpdateOneWithoutDirPersonInput = {
  create?: Maybe<DirRefereeCreateWithoutDirPersonInput>;
  update?: Maybe<DirRefereeUpdateWithoutDirPersonDataInput>;
  upsert?: Maybe<DirRefereeUpsertWithoutDirPersonInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirRefereeWhereUniqueInput>;
};

export type DirRefereeUpdateWithoutDirPersonDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sports?: Maybe<DirRefereeSportUpdateManyWithoutDirRefereeInput>;
};

export type DirRefereeSportUpdateManyWithoutDirRefereeInput = {
  create?: Maybe<Array<DirRefereeSportCreateWithoutDirRefereeInput>>;
  delete?: Maybe<Array<DirRefereeSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirRefereeSportWhereUniqueInput>>;
  set?: Maybe<Array<DirRefereeSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirRefereeSportWhereUniqueInput>>;
  update?: Maybe<Array<DirRefereeSportUpdateWithWhereUniqueWithoutDirRefereeInput>>;
  upsert?: Maybe<Array<DirRefereeSportUpsertWithWhereUniqueWithoutDirRefereeInput>>;
  deleteMany?: Maybe<Array<DirRefereeSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirRefereeSportUpdateManyWithWhereNestedInput>>;
};

export type DirRefereeSportUpdateWithWhereUniqueWithoutDirRefereeInput = {
  where: DirRefereeSportWhereUniqueInput;
  data: DirRefereeSportUpdateWithoutDirRefereeDataInput;
};

export type DirRefereeSportUpdateWithoutDirRefereeDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  workExperience?: Maybe<Scalars['Int']>;
  categories?: Maybe<DirRefereeSportCategoryUpdateManyWithoutSportInput>;
};

export type DirRefereeSportCategoryUpdateManyWithoutSportInput = {
  create?: Maybe<Array<DirRefereeSportCategoryCreateWithoutSportInput>>;
  delete?: Maybe<Array<DirRefereeSportCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<DirRefereeSportCategoryWhereUniqueInput>>;
  set?: Maybe<Array<DirRefereeSportCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirRefereeSportCategoryWhereUniqueInput>>;
  update?: Maybe<Array<DirRefereeSportCategoryUpdateWithWhereUniqueWithoutSportInput>>;
  upsert?: Maybe<Array<DirRefereeSportCategoryUpsertWithWhereUniqueWithoutSportInput>>;
  deleteMany?: Maybe<Array<DirRefereeSportCategoryScalarWhereInput>>;
  updateMany?: Maybe<Array<DirRefereeSportCategoryUpdateManyWithWhereNestedInput>>;
};

export type DirRefereeSportCategoryUpdateWithWhereUniqueWithoutSportInput = {
  where: DirRefereeSportCategoryWhereUniqueInput;
  data: DirRefereeSportCategoryUpdateWithoutSportDataInput;
};

export type DirRefereeSportCategoryUpdateWithoutSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsRefereeCategory?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  confirmationDate?: Maybe<Scalars['DateTime']>;
  dirDocuments?: Maybe<DirDocumentUpdateManyInput>;
};

export type DirDocumentUpdateManyInput = {
  create?: Maybe<Array<DirDocumentCreateInput>>;
  update?: Maybe<Array<DirDocumentUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirDocumentUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirDocumentWhereUniqueInput>>;
  connect?: Maybe<Array<DirDocumentWhereUniqueInput>>;
  set?: Maybe<Array<DirDocumentWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirDocumentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirDocumentScalarWhereInput>>;
  updateMany?: Maybe<Array<DirDocumentUpdateManyWithWhereNestedInput>>;
};

export type DirDocumentUpdateWithWhereUniqueNestedInput = {
  where: DirDocumentWhereUniqueInput;
  data: DirDocumentUpdateDataInput;
};

export type DirDocumentUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  clsType?: Maybe<ClassifierValueUpdateOneInput>;
  file?: Maybe<FileUpdateOneRequiredInput>;
};

export type FileUpdateOneRequiredInput = {
  create?: Maybe<FileCreateInput>;
  update?: Maybe<FileUpdateDataInput>;
  upsert?: Maybe<FileUpsertNestedInput>;
  connect?: Maybe<FileWhereUniqueInput>;
};

export type DirDocumentUpsertWithWhereUniqueNestedInput = {
  where: DirDocumentWhereUniqueInput;
  update: DirDocumentUpdateDataInput;
  create: DirDocumentCreateInput;
};

export type DirDocumentScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirDocumentScalarWhereInput>>;
  OR?: Maybe<Array<DirDocumentScalarWhereInput>>;
  NOT?: Maybe<Array<DirDocumentScalarWhereInput>>;
};

export type DirDocumentUpdateManyWithWhereNestedInput = {
  where: DirDocumentScalarWhereInput;
  data: DirDocumentUpdateManyDataInput;
};

export type DirDocumentUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type DirRefereeSportCategoryUpsertWithWhereUniqueWithoutSportInput = {
  where: DirRefereeSportCategoryWhereUniqueInput;
  update: DirRefereeSportCategoryUpdateWithoutSportDataInput;
  create: DirRefereeSportCategoryCreateWithoutSportInput;
};

export type DirRefereeSportCategoryScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  assignmentDate_not?: Maybe<Scalars['DateTime']>;
  assignmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_lt?: Maybe<Scalars['DateTime']>;
  assignmentDate_lte?: Maybe<Scalars['DateTime']>;
  assignmentDate_gt?: Maybe<Scalars['DateTime']>;
  assignmentDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  isEndless_not?: Maybe<Scalars['Boolean']>;
  confirmationDate?: Maybe<Scalars['DateTime']>;
  confirmationDate_not?: Maybe<Scalars['DateTime']>;
  confirmationDate_in?: Maybe<Array<Scalars['DateTime']>>;
  confirmationDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  confirmationDate_lt?: Maybe<Scalars['DateTime']>;
  confirmationDate_lte?: Maybe<Scalars['DateTime']>;
  confirmationDate_gt?: Maybe<Scalars['DateTime']>;
  confirmationDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirRefereeSportCategoryScalarWhereInput>>;
  OR?: Maybe<Array<DirRefereeSportCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<DirRefereeSportCategoryScalarWhereInput>>;
};

export type DirRefereeSportCategoryUpdateManyWithWhereNestedInput = {
  where: DirRefereeSportCategoryScalarWhereInput;
  data: DirRefereeSportCategoryUpdateManyDataInput;
};

export type DirRefereeSportCategoryUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  confirmationDate?: Maybe<Scalars['DateTime']>;
};

export type DirRefereeSportUpsertWithWhereUniqueWithoutDirRefereeInput = {
  where: DirRefereeSportWhereUniqueInput;
  update: DirRefereeSportUpdateWithoutDirRefereeDataInput;
  create: DirRefereeSportCreateWithoutDirRefereeInput;
};

export type DirRefereeSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  workExperience?: Maybe<Scalars['Int']>;
  workExperience_not?: Maybe<Scalars['Int']>;
  workExperience_in?: Maybe<Array<Scalars['Int']>>;
  workExperience_not_in?: Maybe<Array<Scalars['Int']>>;
  workExperience_lt?: Maybe<Scalars['Int']>;
  workExperience_lte?: Maybe<Scalars['Int']>;
  workExperience_gt?: Maybe<Scalars['Int']>;
  workExperience_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirRefereeSportScalarWhereInput>>;
  OR?: Maybe<Array<DirRefereeSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirRefereeSportScalarWhereInput>>;
};

export type DirRefereeSportUpdateManyWithWhereNestedInput = {
  where: DirRefereeSportScalarWhereInput;
  data: DirRefereeSportUpdateManyDataInput;
};

export type DirRefereeSportUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  workExperience?: Maybe<Scalars['Int']>;
};

export type DirRefereeUpsertWithoutDirPersonInput = {
  update: DirRefereeUpdateWithoutDirPersonDataInput;
  create: DirRefereeCreateWithoutDirPersonInput;
};

export type DirPersonConfirmStatusUpdateOneRequiredInput = {
  create?: Maybe<DirPersonConfirmStatusCreateInput>;
  update?: Maybe<DirPersonConfirmStatusUpdateDataInput>;
  upsert?: Maybe<DirPersonConfirmStatusUpsertNestedInput>;
  connect?: Maybe<DirPersonConfirmStatusWhereUniqueInput>;
};

export type DirPersonConfirmStatusUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type DirPersonConfirmStatusUpsertNestedInput = {
  update: DirPersonConfirmStatusUpdateDataInput;
  create: DirPersonConfirmStatusCreateInput;
};

export type DirCountryUpdateManyInput = {
  create?: Maybe<Array<DirCountryCreateInput>>;
  update?: Maybe<Array<DirCountryUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirCountryUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirCountryWhereUniqueInput>>;
  connect?: Maybe<Array<DirCountryWhereUniqueInput>>;
  set?: Maybe<Array<DirCountryWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirCountryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirCountryScalarWhereInput>>;
  updateMany?: Maybe<Array<DirCountryUpdateManyWithWhereNestedInput>>;
};

export type DirCountryUpdateWithWhereUniqueNestedInput = {
  where: DirCountryWhereUniqueInput;
  data: DirCountryUpdateDataInput;
};

export type DirCountryUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  dirForeignCities?: Maybe<DirForeignCityUpdateManyWithoutDirCountryInput>;
};

export type DirForeignCityUpdateManyWithoutDirCountryInput = {
  create?: Maybe<Array<DirForeignCityCreateWithoutDirCountryInput>>;
  delete?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
  connect?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
  set?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
  update?: Maybe<Array<DirForeignCityUpdateWithWhereUniqueWithoutDirCountryInput>>;
  upsert?: Maybe<Array<DirForeignCityUpsertWithWhereUniqueWithoutDirCountryInput>>;
  deleteMany?: Maybe<Array<DirForeignCityScalarWhereInput>>;
  updateMany?: Maybe<Array<DirForeignCityUpdateManyWithWhereNestedInput>>;
};

export type DirForeignCityUpdateWithWhereUniqueWithoutDirCountryInput = {
  where: DirForeignCityWhereUniqueInput;
  data: DirForeignCityUpdateWithoutDirCountryDataInput;
};

export type DirForeignCityUpdateWithoutDirCountryDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type DirForeignCityUpsertWithWhereUniqueWithoutDirCountryInput = {
  where: DirForeignCityWhereUniqueInput;
  update: DirForeignCityUpdateWithoutDirCountryDataInput;
  create: DirForeignCityCreateWithoutDirCountryInput;
};

export type DirForeignCityScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirForeignCityScalarWhereInput>>;
  OR?: Maybe<Array<DirForeignCityScalarWhereInput>>;
  NOT?: Maybe<Array<DirForeignCityScalarWhereInput>>;
};

export type DirForeignCityUpdateManyWithWhereNestedInput = {
  where: DirForeignCityScalarWhereInput;
  data: DirForeignCityUpdateManyDataInput;
};

export type DirForeignCityUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type DirCountryUpsertWithWhereUniqueNestedInput = {
  where: DirCountryWhereUniqueInput;
  update: DirCountryUpdateDataInput;
  create: DirCountryCreateInput;
};

export type DirCountryScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirCountryScalarWhereInput>>;
  OR?: Maybe<Array<DirCountryScalarWhereInput>>;
  NOT?: Maybe<Array<DirCountryScalarWhereInput>>;
};

export type DirCountryUpdateManyWithWhereNestedInput = {
  where: DirCountryScalarWhereInput;
  data: DirCountryUpdateManyDataInput;
};

export type DirCountryUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type DirForeignCityUpdateOneInput = {
  create?: Maybe<DirForeignCityCreateInput>;
  update?: Maybe<DirForeignCityUpdateDataInput>;
  upsert?: Maybe<DirForeignCityUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirForeignCityWhereUniqueInput>;
};

export type DirForeignCityUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  dirCountry?: Maybe<DirCountryUpdateOneRequiredWithoutDirForeignCitiesInput>;
};

export type DirCountryUpdateOneRequiredWithoutDirForeignCitiesInput = {
  create?: Maybe<DirCountryCreateWithoutDirForeignCitiesInput>;
  update?: Maybe<DirCountryUpdateWithoutDirForeignCitiesDataInput>;
  upsert?: Maybe<DirCountryUpsertWithoutDirForeignCitiesInput>;
  connect?: Maybe<DirCountryWhereUniqueInput>;
};

export type DirCountryUpdateWithoutDirForeignCitiesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type DirCountryUpsertWithoutDirForeignCitiesInput = {
  update: DirCountryUpdateWithoutDirForeignCitiesDataInput;
  create: DirCountryCreateWithoutDirForeignCitiesInput;
};

export type DirForeignCityUpsertNestedInput = {
  update: DirForeignCityUpdateDataInput;
  create: DirForeignCityCreateInput;
};

export type DirCountryUpdateOneInput = {
  create?: Maybe<DirCountryCreateInput>;
  update?: Maybe<DirCountryUpdateDataInput>;
  upsert?: Maybe<DirCountryUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirCountryWhereUniqueInput>;
};

export type DirCountryUpsertNestedInput = {
  update: DirCountryUpdateDataInput;
  create: DirCountryCreateInput;
};

export type DirPersonPhoneUpdateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirPersonPhoneCreateWithoutDirPersonInput>>;
  delete?: Maybe<Array<DirPersonPhoneWhereUniqueInput>>;
  connect?: Maybe<Array<DirPersonPhoneWhereUniqueInput>>;
  set?: Maybe<Array<DirPersonPhoneWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirPersonPhoneWhereUniqueInput>>;
  update?: Maybe<Array<DirPersonPhoneUpdateWithWhereUniqueWithoutDirPersonInput>>;
  upsert?: Maybe<Array<DirPersonPhoneUpsertWithWhereUniqueWithoutDirPersonInput>>;
  deleteMany?: Maybe<Array<DirPersonPhoneScalarWhereInput>>;
  updateMany?: Maybe<Array<DirPersonPhoneUpdateManyWithWhereNestedInput>>;
};

export type DirPersonPhoneUpdateWithWhereUniqueWithoutDirPersonInput = {
  where: DirPersonPhoneWhereUniqueInput;
  data: DirPersonPhoneUpdateWithoutDirPersonDataInput;
};

export type DirPersonPhoneUpdateWithoutDirPersonDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueUpdateOneRequiredInput>;
};

export type DirPersonPhoneUpsertWithWhereUniqueWithoutDirPersonInput = {
  where: DirPersonPhoneWhereUniqueInput;
  update: DirPersonPhoneUpdateWithoutDirPersonDataInput;
  create: DirPersonPhoneCreateWithoutDirPersonInput;
};

export type DirPersonPhoneScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  countryCode_not?: Maybe<Scalars['String']>;
  countryCode_in?: Maybe<Array<Scalars['String']>>;
  countryCode_not_in?: Maybe<Array<Scalars['String']>>;
  countryCode_lt?: Maybe<Scalars['String']>;
  countryCode_lte?: Maybe<Scalars['String']>;
  countryCode_gt?: Maybe<Scalars['String']>;
  countryCode_gte?: Maybe<Scalars['String']>;
  countryCode_contains?: Maybe<Scalars['String']>;
  countryCode_not_contains?: Maybe<Scalars['String']>;
  countryCode_starts_with?: Maybe<Scalars['String']>;
  countryCode_not_starts_with?: Maybe<Scalars['String']>;
  countryCode_ends_with?: Maybe<Scalars['String']>;
  countryCode_not_ends_with?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  operatorCode_not?: Maybe<Scalars['String']>;
  operatorCode_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_not_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_lt?: Maybe<Scalars['String']>;
  operatorCode_lte?: Maybe<Scalars['String']>;
  operatorCode_gt?: Maybe<Scalars['String']>;
  operatorCode_gte?: Maybe<Scalars['String']>;
  operatorCode_contains?: Maybe<Scalars['String']>;
  operatorCode_not_contains?: Maybe<Scalars['String']>;
  operatorCode_starts_with?: Maybe<Scalars['String']>;
  operatorCode_not_starts_with?: Maybe<Scalars['String']>;
  operatorCode_ends_with?: Maybe<Scalars['String']>;
  operatorCode_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  additionalNumber_not?: Maybe<Scalars['String']>;
  additionalNumber_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_not_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_lt?: Maybe<Scalars['String']>;
  additionalNumber_lte?: Maybe<Scalars['String']>;
  additionalNumber_gt?: Maybe<Scalars['String']>;
  additionalNumber_gte?: Maybe<Scalars['String']>;
  additionalNumber_contains?: Maybe<Scalars['String']>;
  additionalNumber_not_contains?: Maybe<Scalars['String']>;
  additionalNumber_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_not_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_ends_with?: Maybe<Scalars['String']>;
  additionalNumber_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirPersonPhoneScalarWhereInput>>;
  OR?: Maybe<Array<DirPersonPhoneScalarWhereInput>>;
  NOT?: Maybe<Array<DirPersonPhoneScalarWhereInput>>;
};

export type DirPersonPhoneUpdateManyWithWhereNestedInput = {
  where: DirPersonPhoneScalarWhereInput;
  data: DirPersonPhoneUpdateManyDataInput;
};

export type DirPersonPhoneUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
};

export type DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirPersonAdditionalRegionCreateWithoutDirPersonInput>>;
  delete?: Maybe<Array<DirPersonAdditionalRegionWhereUniqueInput>>;
  connect?: Maybe<Array<DirPersonAdditionalRegionWhereUniqueInput>>;
  set?: Maybe<Array<DirPersonAdditionalRegionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirPersonAdditionalRegionWhereUniqueInput>>;
  update?: Maybe<Array<DirPersonAdditionalRegionUpdateWithWhereUniqueWithoutDirPersonInput>>;
  upsert?: Maybe<Array<DirPersonAdditionalRegionUpsertWithWhereUniqueWithoutDirPersonInput>>;
  deleteMany?: Maybe<Array<DirPersonAdditionalRegionScalarWhereInput>>;
  updateMany?: Maybe<Array<DirPersonAdditionalRegionUpdateManyWithWhereNestedInput>>;
};

export type DirPersonAdditionalRegionUpdateWithWhereUniqueWithoutDirPersonInput = {
  where: DirPersonAdditionalRegionWhereUniqueInput;
  data: DirPersonAdditionalRegionUpdateWithoutDirPersonDataInput;
};

export type DirPersonAdditionalRegionUpdateWithoutDirPersonDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
};

export type DirPersonAdditionalRegionUpsertWithWhereUniqueWithoutDirPersonInput = {
  where: DirPersonAdditionalRegionWhereUniqueInput;
  update: DirPersonAdditionalRegionUpdateWithoutDirPersonDataInput;
  create: DirPersonAdditionalRegionCreateWithoutDirPersonInput;
};

export type DirPersonAdditionalRegionScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  isRussia_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirPersonAdditionalRegionScalarWhereInput>>;
  OR?: Maybe<Array<DirPersonAdditionalRegionScalarWhereInput>>;
  NOT?: Maybe<Array<DirPersonAdditionalRegionScalarWhereInput>>;
};

export type DirPersonAdditionalRegionUpdateManyWithWhereNestedInput = {
  where: DirPersonAdditionalRegionScalarWhereInput;
  data: DirPersonAdditionalRegionUpdateManyDataInput;
};

export type DirPersonAdditionalRegionUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
};

export type DirPersonMergeTaskUpdateManyWithoutPrimaryInput = {
  create?: Maybe<Array<DirPersonMergeTaskCreateWithoutPrimaryInput>>;
  delete?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  connect?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  set?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  update?: Maybe<Array<DirPersonMergeTaskUpdateWithWhereUniqueWithoutPrimaryInput>>;
  upsert?: Maybe<Array<DirPersonMergeTaskUpsertWithWhereUniqueWithoutPrimaryInput>>;
  deleteMany?: Maybe<Array<DirPersonMergeTaskScalarWhereInput>>;
  updateMany?: Maybe<Array<DirPersonMergeTaskUpdateManyWithWhereNestedInput>>;
};

export type DirPersonMergeTaskUpdateWithWhereUniqueWithoutPrimaryInput = {
  where: DirPersonMergeTaskWhereUniqueInput;
  data: DirPersonMergeTaskUpdateWithoutPrimaryDataInput;
};

export type DirPersonMergeTaskUpdateWithoutPrimaryDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  status?: Maybe<TaskStatusUpdateOneRequiredInput>;
  comment?: Maybe<Scalars['String']>;
  duplicate?: Maybe<DirPersonUpdateOneRequiredWithoutMergeTasksAsDuplicateInput>;
};

export type TaskStatusUpdateOneRequiredInput = {
  create?: Maybe<TaskStatusCreateInput>;
  update?: Maybe<TaskStatusUpdateDataInput>;
  upsert?: Maybe<TaskStatusUpsertNestedInput>;
  connect?: Maybe<TaskStatusWhereUniqueInput>;
};

export type TaskStatusUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type TaskStatusUpsertNestedInput = {
  update: TaskStatusUpdateDataInput;
  create: TaskStatusCreateInput;
};

export type DirPersonUpdateOneRequiredWithoutMergeTasksAsDuplicateInput = {
  create?: Maybe<DirPersonCreateWithoutMergeTasksAsDuplicateInput>;
  update?: Maybe<DirPersonUpdateWithoutMergeTasksAsDuplicateDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutMergeTasksAsDuplicateInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutMergeTasksAsDuplicateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type DirPersonAddressUpdateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirPersonAddressCreateWithoutDirPersonInput>>;
  delete?: Maybe<Array<DirPersonAddressWhereUniqueInput>>;
  connect?: Maybe<Array<DirPersonAddressWhereUniqueInput>>;
  set?: Maybe<Array<DirPersonAddressWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirPersonAddressWhereUniqueInput>>;
  update?: Maybe<Array<DirPersonAddressUpdateWithWhereUniqueWithoutDirPersonInput>>;
  upsert?: Maybe<Array<DirPersonAddressUpsertWithWhereUniqueWithoutDirPersonInput>>;
  deleteMany?: Maybe<Array<DirPersonAddressScalarWhereInput>>;
  updateMany?: Maybe<Array<DirPersonAddressUpdateManyWithWhereNestedInput>>;
};

export type DirPersonAddressUpdateWithWhereUniqueWithoutDirPersonInput = {
  where: DirPersonAddressWhereUniqueInput;
  data: DirPersonAddressUpdateWithoutDirPersonDataInput;
};

export type DirPersonAddressUpdateWithoutDirPersonDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type?: Maybe<AddressTypeUpdateOneRequiredInput>;
};

export type DirPersonAddressUpsertWithWhereUniqueWithoutDirPersonInput = {
  where: DirPersonAddressWhereUniqueInput;
  update: DirPersonAddressUpdateWithoutDirPersonDataInput;
  create: DirPersonAddressCreateWithoutDirPersonInput;
};

export type DirPersonAddressScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  place_not?: Maybe<Scalars['String']>;
  place_in?: Maybe<Array<Scalars['String']>>;
  place_not_in?: Maybe<Array<Scalars['String']>>;
  place_lt?: Maybe<Scalars['String']>;
  place_lte?: Maybe<Scalars['String']>;
  place_gt?: Maybe<Scalars['String']>;
  place_gte?: Maybe<Scalars['String']>;
  place_contains?: Maybe<Scalars['String']>;
  place_not_contains?: Maybe<Scalars['String']>;
  place_starts_with?: Maybe<Scalars['String']>;
  place_not_starts_with?: Maybe<Scalars['String']>;
  place_ends_with?: Maybe<Scalars['String']>;
  place_not_ends_with?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_not?: Maybe<Scalars['String']>;
  street_in?: Maybe<Array<Scalars['String']>>;
  street_not_in?: Maybe<Array<Scalars['String']>>;
  street_lt?: Maybe<Scalars['String']>;
  street_lte?: Maybe<Scalars['String']>;
  street_gt?: Maybe<Scalars['String']>;
  street_gte?: Maybe<Scalars['String']>;
  street_contains?: Maybe<Scalars['String']>;
  street_not_contains?: Maybe<Scalars['String']>;
  street_starts_with?: Maybe<Scalars['String']>;
  street_not_starts_with?: Maybe<Scalars['String']>;
  street_ends_with?: Maybe<Scalars['String']>;
  street_not_ends_with?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  house_not?: Maybe<Scalars['String']>;
  house_in?: Maybe<Array<Scalars['String']>>;
  house_not_in?: Maybe<Array<Scalars['String']>>;
  house_lt?: Maybe<Scalars['String']>;
  house_lte?: Maybe<Scalars['String']>;
  house_gt?: Maybe<Scalars['String']>;
  house_gte?: Maybe<Scalars['String']>;
  house_contains?: Maybe<Scalars['String']>;
  house_not_contains?: Maybe<Scalars['String']>;
  house_starts_with?: Maybe<Scalars['String']>;
  house_not_starts_with?: Maybe<Scalars['String']>;
  house_ends_with?: Maybe<Scalars['String']>;
  house_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirPersonAddressScalarWhereInput>>;
  OR?: Maybe<Array<DirPersonAddressScalarWhereInput>>;
  NOT?: Maybe<Array<DirPersonAddressScalarWhereInput>>;
};

export type DirPersonAddressUpdateManyWithWhereNestedInput = {
  where: DirPersonAddressScalarWhereInput;
  data: DirPersonAddressUpdateManyDataInput;
};

export type DirPersonAddressUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirNationalTeamEmailRecipientCreateWithoutDirPersonInput>>;
  delete?: Maybe<Array<DirNationalTeamEmailRecipientWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamEmailRecipientWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamEmailRecipientWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamEmailRecipientWhereUniqueInput>>;
  update?: Maybe<Array<DirNationalTeamEmailRecipientUpdateWithWhereUniqueWithoutDirPersonInput>>;
  upsert?: Maybe<Array<DirNationalTeamEmailRecipientUpsertWithWhereUniqueWithoutDirPersonInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamEmailRecipientScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamEmailRecipientUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamEmailRecipientUpdateWithWhereUniqueWithoutDirPersonInput = {
  where: DirNationalTeamEmailRecipientWhereUniqueInput;
  data: DirNationalTeamEmailRecipientUpdateWithoutDirPersonDataInput;
};

export type DirNationalTeamEmailRecipientUpdateWithoutDirPersonDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
  team?: Maybe<DirNationalTeamUpdateOneRequiredWithoutEmailRecipientsInput>;
  email?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type DirNationalTeamUpdateOneRequiredWithoutEmailRecipientsInput = {
  create?: Maybe<DirNationalTeamCreateWithoutEmailRecipientsInput>;
  update?: Maybe<DirNationalTeamUpdateWithoutEmailRecipientsDataInput>;
  upsert?: Maybe<DirNationalTeamUpsertWithoutEmailRecipientsInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamUpdateWithoutEmailRecipientsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirNationalTeamExtendedStatusUpdateManyWithoutTeamInput>;
  dirSportingEvent?: Maybe<DirSportingEventUpdateOneRequiredWithoutDirNationalTeamsInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  trainers?: Maybe<DirNationalTeamTrainerUpdateManyWithoutTeamInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupUpdateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  medics?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  delegationHeads?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  files?: Maybe<FileUpdateManyInput>;
  university?: Maybe<DirOrganizationUpdateOneInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  arrivalDeparturePlan?: Maybe<FileUpdateManyInput>;
  preliminaryQuantitativeOrder?: Maybe<FileUpdateManyInput>;
};

export type DirSportingEventUpdateOneRequiredWithoutDirNationalTeamsInput = {
  create?: Maybe<DirSportingEventCreateWithoutDirNationalTeamsInput>;
  update?: Maybe<DirSportingEventUpdateWithoutDirNationalTeamsDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutDirNationalTeamsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutDirNationalTeamsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirRegionUpdateManyInput = {
  create?: Maybe<Array<DirRegionCreateInput>>;
  update?: Maybe<Array<DirRegionUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirRegionUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirRegionWhereUniqueInput>>;
  connect?: Maybe<Array<DirRegionWhereUniqueInput>>;
  set?: Maybe<Array<DirRegionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirRegionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirRegionScalarWhereInput>>;
  updateMany?: Maybe<Array<DirRegionUpdateManyWithWhereNestedInput>>;
};

export type DirRegionUpdateWithWhereUniqueNestedInput = {
  where: DirRegionWhereUniqueInput;
  data: DirRegionUpdateDataInput;
};

export type DirRegionUpsertWithWhereUniqueNestedInput = {
  where: DirRegionWhereUniqueInput;
  update: DirRegionUpdateDataInput;
  create: DirRegionCreateInput;
};

export type DirFederalDistrictUpdateManyInput = {
  create?: Maybe<Array<DirFederalDistrictCreateInput>>;
  update?: Maybe<Array<DirFederalDistrictUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirFederalDistrictUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirFederalDistrictWhereUniqueInput>>;
  connect?: Maybe<Array<DirFederalDistrictWhereUniqueInput>>;
  set?: Maybe<Array<DirFederalDistrictWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirFederalDistrictWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirFederalDistrictScalarWhereInput>>;
  updateMany?: Maybe<Array<DirFederalDistrictUpdateManyWithWhereNestedInput>>;
};

export type DirFederalDistrictUpdateWithWhereUniqueNestedInput = {
  where: DirFederalDistrictWhereUniqueInput;
  data: DirFederalDistrictUpdateDataInput;
};

export type DirFederalDistrictUpsertWithWhereUniqueNestedInput = {
  where: DirFederalDistrictWhereUniqueInput;
  update: DirFederalDistrictUpdateDataInput;
  create: DirFederalDistrictCreateInput;
};

export type DirFederalDistrictScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirFederalDistrictScalarWhereInput>>;
  OR?: Maybe<Array<DirFederalDistrictScalarWhereInput>>;
  NOT?: Maybe<Array<DirFederalDistrictScalarWhereInput>>;
};

export type DirFederalDistrictUpdateManyWithWhereNestedInput = {
  where: DirFederalDistrictScalarWhereInput;
  data: DirFederalDistrictUpdateManyDataInput;
};

export type DirFederalDistrictUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type DirSportingEventCountryUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventCountryCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventCountryWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventCountryWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventCountryWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventCountryWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventCountryUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventCountryUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventCountryScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventCountryUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventCountryUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventCountryWhereUniqueInput;
  data: DirSportingEventCountryUpdateWithoutEventDataInput;
};

export type DirSportingEventCountryUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirCountry?: Maybe<DirCountryUpdateOneRequiredInput>;
  dirForeignCities?: Maybe<DirForeignCityUpdateManyInput>;
};

export type DirCountryUpdateOneRequiredInput = {
  create?: Maybe<DirCountryCreateInput>;
  update?: Maybe<DirCountryUpdateDataInput>;
  upsert?: Maybe<DirCountryUpsertNestedInput>;
  connect?: Maybe<DirCountryWhereUniqueInput>;
};

export type DirForeignCityUpdateManyInput = {
  create?: Maybe<Array<DirForeignCityCreateInput>>;
  update?: Maybe<Array<DirForeignCityUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirForeignCityUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
  connect?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
  set?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirForeignCityScalarWhereInput>>;
  updateMany?: Maybe<Array<DirForeignCityUpdateManyWithWhereNestedInput>>;
};

export type DirForeignCityUpdateWithWhereUniqueNestedInput = {
  where: DirForeignCityWhereUniqueInput;
  data: DirForeignCityUpdateDataInput;
};

export type DirForeignCityUpsertWithWhereUniqueNestedInput = {
  where: DirForeignCityWhereUniqueInput;
  update: DirForeignCityUpdateDataInput;
  create: DirForeignCityCreateInput;
};

export type DirSportingEventCountryUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventCountryWhereUniqueInput;
  update: DirSportingEventCountryUpdateWithoutEventDataInput;
  create: DirSportingEventCountryCreateWithoutEventInput;
};

export type DirSportingEventCountryScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportingEventCountryScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventCountryScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventCountryScalarWhereInput>>;
};

export type DirSportingEventCountryUpdateManyWithWhereNestedInput = {
  where: DirSportingEventCountryScalarWhereInput;
  data: DirSportingEventCountryUpdateManyDataInput;
};

export type DirSportingEventCountryUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventCostUpdateOneInput = {
  create?: Maybe<DirSportingEventCostCreateInput>;
  update?: Maybe<DirSportingEventCostUpdateDataInput>;
  upsert?: Maybe<DirSportingEventCostUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportingEventCostWhereUniqueInput>;
};

export type DirSportingEventCostUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  clsFinancingTypes?: Maybe<ClassifierValueUpdateManyInput>;
};

export type DirSportingEventCostUpsertNestedInput = {
  update: DirSportingEventCostUpdateDataInput;
  create: DirSportingEventCostCreateInput;
};

export type DirSportingEventQuotaUpdateOneInput = {
  create?: Maybe<DirSportingEventQuotaCreateInput>;
  update?: Maybe<DirSportingEventQuotaUpdateDataInput>;
  upsert?: Maybe<DirSportingEventQuotaUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportingEventQuotaWhereUniqueInput>;
};

export type DirSportingEventQuotaUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  athletesMan?: Maybe<Scalars['Int']>;
  athletesWoman?: Maybe<Scalars['Int']>;
  trainers?: Maybe<Scalars['Int']>;
  referees?: Maybe<Scalars['Int']>;
  administration?: Maybe<Scalars['Int']>;
  medicalStaff?: Maybe<Scalars['Int']>;
  specialists?: Maybe<Scalars['Int']>;
  teams?: Maybe<Scalars['Int']>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsTeamComposition?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventQuotaUpsertNestedInput = {
  update: DirSportingEventQuotaUpdateDataInput;
  create: DirSportingEventQuotaCreateInput;
};

export type DirSportingEventQuantityUpdateOneInput = {
  create?: Maybe<DirSportingEventQuantityCreateInput>;
  update?: Maybe<DirSportingEventQuantityUpdateDataInput>;
  upsert?: Maybe<DirSportingEventQuantityUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportingEventQuantityWhereUniqueInput>;
};

export type DirSportingEventQuantityUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  participantAthletesTotal?: Maybe<Scalars['Int']>;
  refereesTotal?: Maybe<Scalars['Int']>;
  staffTotal?: Maybe<Scalars['Int']>;
  audienceTotal?: Maybe<Scalars['Int']>;
};

export type DirSportingEventQuantityUpsertNestedInput = {
  update: DirSportingEventQuantityUpdateDataInput;
  create: DirSportingEventQuantityCreateInput;
};

export type DirSportingEventUpdateOneWithoutChildrenInput = {
  create?: Maybe<DirSportingEventCreateWithoutChildrenInput>;
  update?: Maybe<DirSportingEventUpdateWithoutChildrenDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutChildrenInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutChildrenDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventUpdateOneWithoutChangesInput = {
  create?: Maybe<DirSportingEventCreateWithoutChangesInput>;
  update?: Maybe<DirSportingEventUpdateWithoutChangesDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutChangesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutChangesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventUpdateManyWithoutParentInput = {
  create?: Maybe<Array<DirSportingEventCreateWithoutParentInput>>;
  delete?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventUpdateWithWhereUniqueWithoutParentInput>>;
  upsert?: Maybe<Array<DirSportingEventUpsertWithWhereUniqueWithoutParentInput>>;
  deleteMany?: Maybe<Array<DirSportingEventScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventUpdateWithWhereUniqueWithoutParentInput = {
  where: DirSportingEventWhereUniqueInput;
  data: DirSportingEventUpdateWithoutParentDataInput;
};

export type DirSportingEventUpdateWithoutParentDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventUpdateManyWithoutProxyInput = {
  create?: Maybe<Array<DirSportingEventCreateWithoutProxyInput>>;
  delete?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventUpdateWithWhereUniqueWithoutProxyInput>>;
  upsert?: Maybe<Array<DirSportingEventUpsertWithWhereUniqueWithoutProxyInput>>;
  deleteMany?: Maybe<Array<DirSportingEventScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventUpdateWithWhereUniqueWithoutProxyInput = {
  where: DirSportingEventWhereUniqueInput;
  data: DirSportingEventUpdateWithoutProxyDataInput;
};

export type DirSportingEventUpdateWithoutProxyDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventStatusUpdateOneRequiredInput = {
  create?: Maybe<DirSportingEventStatusCreateInput>;
  update?: Maybe<DirSportingEventStatusUpdateDataInput>;
  upsert?: Maybe<DirSportingEventStatusUpsertNestedInput>;
  connect?: Maybe<DirSportingEventStatusWhereUniqueInput>;
};

export type DirSportingEventStatusUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventStatusUpsertNestedInput = {
  update: DirSportingEventStatusUpdateDataInput;
  create: DirSportingEventStatusCreateInput;
};

export type DirSportingEventStatusHistoryUpdateManyInput = {
  create?: Maybe<Array<DirSportingEventStatusHistoryCreateInput>>;
  update?: Maybe<Array<DirSportingEventStatusHistoryUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirSportingEventStatusHistoryUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirSportingEventStatusHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventStatusHistoryWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventStatusHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventStatusHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirSportingEventStatusHistoryScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventStatusHistoryUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventStatusHistoryUpdateWithWhereUniqueNestedInput = {
  where: DirSportingEventStatusHistoryWhereUniqueInput;
  data: DirSportingEventStatusHistoryUpdateDataInput;
};

export type DirSportingEventStatusHistoryUpdateDataInput = {
  date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  user?: Maybe<UserUpdateOneRequiredInput>;
};

export type DirSportingEventStatusHistoryUpsertWithWhereUniqueNestedInput = {
  where: DirSportingEventStatusHistoryWhereUniqueInput;
  update: DirSportingEventStatusHistoryUpdateDataInput;
  create: DirSportingEventStatusHistoryCreateInput;
};

export type DirSportingEventStatusHistoryScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirSportingEventStatusHistoryScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventStatusHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventStatusHistoryScalarWhereInput>>;
};

export type DirSportingEventStatusHistoryUpdateManyWithWhereNestedInput = {
  where: DirSportingEventStatusHistoryScalarWhereInput;
  data: DirSportingEventStatusHistoryUpdateManyDataInput;
};

export type DirSportingEventStatusHistoryUpdateManyDataInput = {
  date?: Maybe<Scalars['DateTime']>;
};

export type DirSportingEventExtendedStatusUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventExtendedStatusCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventExtendedStatusWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventExtendedStatusWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventExtendedStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventExtendedStatusWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventExtendedStatusUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventExtendedStatusUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventExtendedStatusScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventExtendedStatusUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventExtendedStatusUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventExtendedStatusWhereUniqueInput;
  data: DirSportingEventExtendedStatusUpdateWithoutEventDataInput;
};

export type DirSportingEventExtendedStatusUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  value?: Maybe<DirSportingEventExtendedStatusValueUpdateOneRequiredInput>;
  file?: Maybe<FileUpdateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventExtendedStatusValueUpdateOneRequiredInput = {
  create?: Maybe<DirSportingEventExtendedStatusValueCreateInput>;
  update?: Maybe<DirSportingEventExtendedStatusValueUpdateDataInput>;
  upsert?: Maybe<DirSportingEventExtendedStatusValueUpsertNestedInput>;
  connect?: Maybe<DirSportingEventExtendedStatusValueWhereUniqueInput>;
};

export type DirSportingEventExtendedStatusValueUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventExtendedStatusValueUpsertNestedInput = {
  update: DirSportingEventExtendedStatusValueUpdateDataInput;
  create: DirSportingEventExtendedStatusValueCreateInput;
};

export type DirSportingEventExtendedStatusUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventExtendedStatusWhereUniqueInput;
  update: DirSportingEventExtendedStatusUpdateWithoutEventDataInput;
  create: DirSportingEventExtendedStatusCreateWithoutEventInput;
};

export type DirSportingEventExtendedStatusScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventExtendedStatusScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventExtendedStatusScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventExtendedStatusScalarWhereInput>>;
};

export type DirSportingEventExtendedStatusUpdateManyWithWhereNestedInput = {
  where: DirSportingEventExtendedStatusScalarWhereInput;
  data: DirSportingEventExtendedStatusUpdateManyDataInput;
};

export type DirSportingEventExtendedStatusUpdateManyDataInput = {
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirCalendarUpdateOneRequiredInput = {
  create?: Maybe<DirCalendarCreateInput>;
  update?: Maybe<DirCalendarUpdateDataInput>;
  upsert?: Maybe<DirCalendarUpsertNestedInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeUpdateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueUpdateOneInput>;
  clsSeason?: Maybe<ClassifierValueUpdateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueUpdateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionUpdateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionUpdateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionUpdateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileUpdateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsUpdateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolUpdateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileUpdateManyInput>;
  stageNumber?: Maybe<ClassifierValueUpdateOneInput>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportUpdateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoUpdateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportUpdateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoUpdateManyWithoutDirCalendarInput>;
};

export type DirCalendarTypeUpdateOneInput = {
  create?: Maybe<DirCalendarTypeCreateInput>;
  update?: Maybe<DirCalendarTypeUpdateDataInput>;
  upsert?: Maybe<DirCalendarTypeUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirCalendarTypeWhereUniqueInput>;
};

export type DirCalendarTypeUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirCalendarTypeUpsertNestedInput = {
  update: DirCalendarTypeUpdateDataInput;
  create: DirCalendarTypeCreateInput;
};

export type DirRefusedRegionUpdateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<DirRefusedRegionCreateWithoutDirCalendarInput>>;
  delete?: Maybe<Array<DirRefusedRegionWhereUniqueInput>>;
  connect?: Maybe<Array<DirRefusedRegionWhereUniqueInput>>;
  set?: Maybe<Array<DirRefusedRegionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirRefusedRegionWhereUniqueInput>>;
  update?: Maybe<Array<DirRefusedRegionUpdateWithWhereUniqueWithoutDirCalendarInput>>;
  upsert?: Maybe<Array<DirRefusedRegionUpsertWithWhereUniqueWithoutDirCalendarInput>>;
  deleteMany?: Maybe<Array<DirRefusedRegionScalarWhereInput>>;
  updateMany?: Maybe<Array<DirRefusedRegionUpdateManyWithWhereNestedInput>>;
};

export type DirRefusedRegionUpdateWithWhereUniqueWithoutDirCalendarInput = {
  where: DirRefusedRegionWhereUniqueInput;
  data: DirRefusedRegionUpdateWithoutDirCalendarDataInput;
};

export type DirRefusedRegionUpdateWithoutDirCalendarDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  comment?: Maybe<Scalars['String']>;
  statement?: Maybe<FileUpdateOneInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
};

export type DirRefusedRegionUpsertWithWhereUniqueWithoutDirCalendarInput = {
  where: DirRefusedRegionWhereUniqueInput;
  update: DirRefusedRegionUpdateWithoutDirCalendarDataInput;
  create: DirRefusedRegionCreateWithoutDirCalendarInput;
};

export type DirRefusedRegionScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirRefusedRegionScalarWhereInput>>;
  OR?: Maybe<Array<DirRefusedRegionScalarWhereInput>>;
  NOT?: Maybe<Array<DirRefusedRegionScalarWhereInput>>;
};

export type DirRefusedRegionUpdateManyWithWhereNestedInput = {
  where: DirRefusedRegionScalarWhereInput;
  data: DirRefusedRegionUpdateManyDataInput;
};

export type DirRefusedRegionUpdateManyDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  comment?: Maybe<Scalars['String']>;
};

export type DirReturnedRegionUpdateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<DirReturnedRegionCreateWithoutDirCalendarInput>>;
  delete?: Maybe<Array<DirReturnedRegionWhereUniqueInput>>;
  connect?: Maybe<Array<DirReturnedRegionWhereUniqueInput>>;
  set?: Maybe<Array<DirReturnedRegionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirReturnedRegionWhereUniqueInput>>;
  update?: Maybe<Array<DirReturnedRegionUpdateWithWhereUniqueWithoutDirCalendarInput>>;
  upsert?: Maybe<Array<DirReturnedRegionUpsertWithWhereUniqueWithoutDirCalendarInput>>;
  deleteMany?: Maybe<Array<DirReturnedRegionScalarWhereInput>>;
  updateMany?: Maybe<Array<DirReturnedRegionUpdateManyWithWhereNestedInput>>;
};

export type DirReturnedRegionUpdateWithWhereUniqueWithoutDirCalendarInput = {
  where: DirReturnedRegionWhereUniqueInput;
  data: DirReturnedRegionUpdateWithoutDirCalendarDataInput;
};

export type DirReturnedRegionUpdateWithoutDirCalendarDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  statement?: Maybe<FileUpdateOneInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
};

export type DirReturnedRegionUpsertWithWhereUniqueWithoutDirCalendarInput = {
  where: DirReturnedRegionWhereUniqueInput;
  update: DirReturnedRegionUpdateWithoutDirCalendarDataInput;
  create: DirReturnedRegionCreateWithoutDirCalendarInput;
};

export type DirReturnedRegionScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirReturnedRegionScalarWhereInput>>;
  OR?: Maybe<Array<DirReturnedRegionScalarWhereInput>>;
  NOT?: Maybe<Array<DirReturnedRegionScalarWhereInput>>;
};

export type DirReturnedRegionUpdateManyWithWhereNestedInput = {
  where: DirReturnedRegionScalarWhereInput;
  data: DirReturnedRegionUpdateManyDataInput;
};

export type DirReturnedRegionUpdateManyDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
};

export type VenueCompetitionUpdateOneWithoutDirCalendarInput = {
  create?: Maybe<VenueCompetitionCreateWithoutDirCalendarInput>;
  update?: Maybe<VenueCompetitionUpdateWithoutDirCalendarDataInput>;
  upsert?: Maybe<VenueCompetitionUpsertWithoutDirCalendarInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VenueCompetitionWhereUniqueInput>;
};

export type VenueCompetitionUpdateWithoutDirCalendarDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirDocument?: Maybe<DirDocumentUpdateOneInput>;
  venueCompDecisionDoc?: Maybe<DirDocumentUpdateOneInput>;
  venueCompRegulationDocAdds?: Maybe<FileUpdateManyInput>;
  venueCompDecisionDocAdds?: Maybe<FileUpdateManyInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  emailSenderFCPSR?: Maybe<UserUpdateOneInput>;
  venueCompApplications?: Maybe<VenueCompApplicationUpdateManyWithoutVenueCompetitionInput>;
};

export type DirDocumentUpdateOneInput = {
  create?: Maybe<DirDocumentCreateInput>;
  update?: Maybe<DirDocumentUpdateDataInput>;
  upsert?: Maybe<DirDocumentUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirDocumentWhereUniqueInput>;
};

export type DirDocumentUpsertNestedInput = {
  update: DirDocumentUpdateDataInput;
  create: DirDocumentCreateInput;
};

export type FileUpdateManyInput = {
  create?: Maybe<Array<FileCreateInput>>;
  update?: Maybe<Array<FileUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<FileUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<FileWhereUniqueInput>>;
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  set?: Maybe<Array<FileWhereUniqueInput>>;
  disconnect?: Maybe<Array<FileWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FileScalarWhereInput>>;
  updateMany?: Maybe<Array<FileUpdateManyWithWhereNestedInput>>;
};

export type FileUpdateWithWhereUniqueNestedInput = {
  where: FileWhereUniqueInput;
  data: FileUpdateDataInput;
};

export type FileUpsertWithWhereUniqueNestedInput = {
  where: FileWhereUniqueInput;
  update: FileUpdateDataInput;
  create: FileCreateInput;
};

export type FileScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  path_not?: Maybe<Scalars['String']>;
  path_in?: Maybe<Array<Scalars['String']>>;
  path_not_in?: Maybe<Array<Scalars['String']>>;
  path_lt?: Maybe<Scalars['String']>;
  path_lte?: Maybe<Scalars['String']>;
  path_gt?: Maybe<Scalars['String']>;
  path_gte?: Maybe<Scalars['String']>;
  path_contains?: Maybe<Scalars['String']>;
  path_not_contains?: Maybe<Scalars['String']>;
  path_starts_with?: Maybe<Scalars['String']>;
  path_not_starts_with?: Maybe<Scalars['String']>;
  path_ends_with?: Maybe<Scalars['String']>;
  path_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<FileScalarWhereInput>>;
  OR?: Maybe<Array<FileScalarWhereInput>>;
  NOT?: Maybe<Array<FileScalarWhereInput>>;
};

export type FileUpdateManyWithWhereNestedInput = {
  where: FileScalarWhereInput;
  data: FileUpdateManyDataInput;
};

export type FileUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type VenueCompApplicationUpdateManyWithoutVenueCompetitionInput = {
  create?: Maybe<Array<VenueCompApplicationCreateWithoutVenueCompetitionInput>>;
  delete?: Maybe<Array<VenueCompApplicationWhereUniqueInput>>;
  connect?: Maybe<Array<VenueCompApplicationWhereUniqueInput>>;
  set?: Maybe<Array<VenueCompApplicationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VenueCompApplicationWhereUniqueInput>>;
  update?: Maybe<Array<VenueCompApplicationUpdateWithWhereUniqueWithoutVenueCompetitionInput>>;
  upsert?: Maybe<Array<VenueCompApplicationUpsertWithWhereUniqueWithoutVenueCompetitionInput>>;
  deleteMany?: Maybe<Array<VenueCompApplicationScalarWhereInput>>;
  updateMany?: Maybe<Array<VenueCompApplicationUpdateManyWithWhereNestedInput>>;
};

export type VenueCompApplicationUpdateWithWhereUniqueWithoutVenueCompetitionInput = {
  where: VenueCompApplicationWhereUniqueInput;
  data: VenueCompApplicationUpdateWithoutVenueCompetitionDataInput;
};

export type VenueCompApplicationUpdateWithoutVenueCompetitionDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  number?: Maybe<Scalars['Int']>;
  applicationFiles?: Maybe<FileUpdateManyInput>;
  notificationSentToFCPSR?: Maybe<Scalars['Boolean']>;
};

export type VenueCompApplicationUpsertWithWhereUniqueWithoutVenueCompetitionInput = {
  where: VenueCompApplicationWhereUniqueInput;
  update: VenueCompApplicationUpdateWithoutVenueCompetitionDataInput;
  create: VenueCompApplicationCreateWithoutVenueCompetitionInput;
};

export type VenueCompApplicationScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['Int']>;
  number_not?: Maybe<Scalars['Int']>;
  number_in?: Maybe<Array<Scalars['Int']>>;
  number_not_in?: Maybe<Array<Scalars['Int']>>;
  number_lt?: Maybe<Scalars['Int']>;
  number_lte?: Maybe<Scalars['Int']>;
  number_gt?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
  notificationSentToFCPSR?: Maybe<Scalars['Boolean']>;
  notificationSentToFCPSR_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<VenueCompApplicationScalarWhereInput>>;
  OR?: Maybe<Array<VenueCompApplicationScalarWhereInput>>;
  NOT?: Maybe<Array<VenueCompApplicationScalarWhereInput>>;
};

export type VenueCompApplicationUpdateManyWithWhereNestedInput = {
  where: VenueCompApplicationScalarWhereInput;
  data: VenueCompApplicationUpdateManyDataInput;
};

export type VenueCompApplicationUpdateManyDataInput = {
  number?: Maybe<Scalars['Int']>;
  notificationSentToFCPSR?: Maybe<Scalars['Boolean']>;
};

export type VenueCompetitionUpsertWithoutDirCalendarInput = {
  update: VenueCompetitionUpdateWithoutDirCalendarDataInput;
  create: VenueCompetitionCreateWithoutDirCalendarInput;
};

export type DirSportEventRegulationsUpdateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<DirSportEventRegulationsCreateWithoutDirCalendarInput>>;
  delete?: Maybe<Array<DirSportEventRegulationsWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportEventRegulationsWhereUniqueInput>>;
  set?: Maybe<Array<DirSportEventRegulationsWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportEventRegulationsWhereUniqueInput>>;
  update?: Maybe<Array<DirSportEventRegulationsUpdateWithWhereUniqueWithoutDirCalendarInput>>;
  upsert?: Maybe<Array<DirSportEventRegulationsUpsertWithWhereUniqueWithoutDirCalendarInput>>;
  deleteMany?: Maybe<Array<DirSportEventRegulationsScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportEventRegulationsUpdateManyWithWhereNestedInput>>;
};

export type DirSportEventRegulationsUpdateWithWhereUniqueWithoutDirCalendarInput = {
  where: DirSportEventRegulationsWhereUniqueInput;
  data: DirSportEventRegulationsUpdateWithoutDirCalendarDataInput;
};

export type DirSportEventRegulationsUpdateWithoutDirCalendarDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  regulationsDocument?: Maybe<FileUpdateOneInput>;
  regulationsAmendedDocument?: Maybe<FileUpdateOneInput>;
  regulationsAgreedDocument?: Maybe<FileUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  dirSport?: Maybe<DirSportUpdateOneInput>;
  status?: Maybe<DirSportingEventRegulationStatusUpdateManyWithoutRegulationInput>;
  fcpsrAddRegulationsDoc?: Maybe<UserUpdateOneInput>;
};

export type DirSportUpdateOneInput = {
  create?: Maybe<DirSportCreateInput>;
  update?: Maybe<DirSportUpdateDataInput>;
  upsert?: Maybe<DirSportUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportingEventRegulationStatusUpdateManyWithoutRegulationInput = {
  create?: Maybe<Array<DirSportingEventRegulationStatusCreateWithoutRegulationInput>>;
  delete?: Maybe<Array<DirSportingEventRegulationStatusWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventRegulationStatusWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventRegulationStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventRegulationStatusWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventRegulationStatusUpdateWithWhereUniqueWithoutRegulationInput>>;
  upsert?: Maybe<Array<DirSportingEventRegulationStatusUpsertWithWhereUniqueWithoutRegulationInput>>;
  deleteMany?: Maybe<Array<DirSportingEventRegulationStatusScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventRegulationStatusUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventRegulationStatusUpdateWithWhereUniqueWithoutRegulationInput = {
  where: DirSportingEventRegulationStatusWhereUniqueInput;
  data: DirSportingEventRegulationStatusUpdateWithoutRegulationDataInput;
};

export type DirSportingEventRegulationStatusUpdateWithoutRegulationDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  value?: Maybe<DirSportingEventRegulationStatusValueUpdateOneRequiredInput>;
  file?: Maybe<FileUpdateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRegulationStatusValueUpdateOneRequiredInput = {
  create?: Maybe<DirSportingEventRegulationStatusValueCreateInput>;
  update?: Maybe<DirSportingEventRegulationStatusValueUpdateDataInput>;
  upsert?: Maybe<DirSportingEventRegulationStatusValueUpsertNestedInput>;
  connect?: Maybe<DirSportingEventRegulationStatusValueWhereUniqueInput>;
};

export type DirSportingEventRegulationStatusValueUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventRegulationStatusValueUpsertNestedInput = {
  update: DirSportingEventRegulationStatusValueUpdateDataInput;
  create: DirSportingEventRegulationStatusValueCreateInput;
};

export type DirSportingEventRegulationStatusUpsertWithWhereUniqueWithoutRegulationInput = {
  where: DirSportingEventRegulationStatusWhereUniqueInput;
  update: DirSportingEventRegulationStatusUpdateWithoutRegulationDataInput;
  create: DirSportingEventRegulationStatusCreateWithoutRegulationInput;
};

export type DirSportingEventRegulationStatusScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventRegulationStatusScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRegulationStatusScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRegulationStatusScalarWhereInput>>;
};

export type DirSportingEventRegulationStatusUpdateManyWithWhereNestedInput = {
  where: DirSportingEventRegulationStatusScalarWhereInput;
  data: DirSportingEventRegulationStatusUpdateManyDataInput;
};

export type DirSportingEventRegulationStatusUpdateManyDataInput = {
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportEventRegulationsUpsertWithWhereUniqueWithoutDirCalendarInput = {
  where: DirSportEventRegulationsWhereUniqueInput;
  update: DirSportEventRegulationsUpdateWithoutDirCalendarDataInput;
  create: DirSportEventRegulationsCreateWithoutDirCalendarInput;
};

export type DirSportEventRegulationsScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirSportEventRegulationsScalarWhereInput>>;
  OR?: Maybe<Array<DirSportEventRegulationsScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportEventRegulationsScalarWhereInput>>;
};

export type DirSportEventRegulationsUpdateManyWithWhereNestedInput = {
  where: DirSportEventRegulationsScalarWhereInput;
  data: DirSportEventRegulationsUpdateManyDataInput;
};

export type DirSportEventRegulationsUpdateManyDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
};

export type NotificationProtocolUpdateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<NotificationProtocolCreateWithoutDirCalendarInput>>;
  delete?: Maybe<Array<NotificationProtocolWhereUniqueInput>>;
  connect?: Maybe<Array<NotificationProtocolWhereUniqueInput>>;
  set?: Maybe<Array<NotificationProtocolWhereUniqueInput>>;
  disconnect?: Maybe<Array<NotificationProtocolWhereUniqueInput>>;
  update?: Maybe<Array<NotificationProtocolUpdateWithWhereUniqueWithoutDirCalendarInput>>;
  upsert?: Maybe<Array<NotificationProtocolUpsertWithWhereUniqueWithoutDirCalendarInput>>;
  deleteMany?: Maybe<Array<NotificationProtocolScalarWhereInput>>;
  updateMany?: Maybe<Array<NotificationProtocolUpdateManyWithWhereNestedInput>>;
};

export type NotificationProtocolUpdateWithWhereUniqueWithoutDirCalendarInput = {
  where: NotificationProtocolWhereUniqueInput;
  data: NotificationProtocolUpdateWithoutDirCalendarDataInput;
};

export type NotificationProtocolUpdateWithoutDirCalendarDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  forNotificationROIVSpecialists?: Maybe<UserUpdateManyInput>;
};

export type UserUpdateManyInput = {
  create?: Maybe<Array<UserCreateInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereNestedInput>>;
};

export type UserUpdateWithWhereUniqueNestedInput = {
  where: UserWhereUniqueInput;
  data: UserUpdateDataInput;
};

export type UserUpsertWithWhereUniqueNestedInput = {
  where: UserWhereUniqueInput;
  update: UserUpdateDataInput;
  create: UserCreateInput;
};

export type UserScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  password_not?: Maybe<Scalars['String']>;
  password_in?: Maybe<Array<Scalars['String']>>;
  password_not_in?: Maybe<Array<Scalars['String']>>;
  password_lt?: Maybe<Scalars['String']>;
  password_lte?: Maybe<Scalars['String']>;
  password_gt?: Maybe<Scalars['String']>;
  password_gte?: Maybe<Scalars['String']>;
  password_contains?: Maybe<Scalars['String']>;
  password_not_contains?: Maybe<Scalars['String']>;
  password_starts_with?: Maybe<Scalars['String']>;
  password_not_starts_with?: Maybe<Scalars['String']>;
  password_ends_with?: Maybe<Scalars['String']>;
  password_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['String']>;
  position_not?: Maybe<Scalars['String']>;
  position_in?: Maybe<Array<Scalars['String']>>;
  position_not_in?: Maybe<Array<Scalars['String']>>;
  position_lt?: Maybe<Scalars['String']>;
  position_lte?: Maybe<Scalars['String']>;
  position_gt?: Maybe<Scalars['String']>;
  position_gte?: Maybe<Scalars['String']>;
  position_contains?: Maybe<Scalars['String']>;
  position_not_contains?: Maybe<Scalars['String']>;
  position_starts_with?: Maybe<Scalars['String']>;
  position_not_starts_with?: Maybe<Scalars['String']>;
  position_ends_with?: Maybe<Scalars['String']>;
  position_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<UserScalarWhereInput>>;
  OR?: Maybe<Array<UserScalarWhereInput>>;
  NOT?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithWhereNestedInput = {
  where: UserScalarWhereInput;
  data: UserUpdateManyDataInput;
};

export type UserUpdateManyDataInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['String']>;
};

export type NotificationProtocolUpsertWithWhereUniqueWithoutDirCalendarInput = {
  where: NotificationProtocolWhereUniqueInput;
  update: NotificationProtocolUpdateWithoutDirCalendarDataInput;
  create: NotificationProtocolCreateWithoutDirCalendarInput;
};

export type NotificationProtocolScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<NotificationProtocolScalarWhereInput>>;
  OR?: Maybe<Array<NotificationProtocolScalarWhereInput>>;
  NOT?: Maybe<Array<NotificationProtocolScalarWhereInput>>;
};

export type NotificationProtocolUpdateManyWithWhereNestedInput = {
  where: NotificationProtocolScalarWhereInput;
  data: NotificationProtocolUpdateManyDataInput;
};

export type NotificationProtocolUpdateManyDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
};

export type DirCalendarSignedReportUpdateManyWithoutDirCalendarSignedReportInput = {
  create?: Maybe<Array<DirCalendarSignedReportCreateWithoutDirCalendarSignedReportInput>>;
  delete?: Maybe<Array<DirCalendarSignedReportWhereUniqueInput>>;
  connect?: Maybe<Array<DirCalendarSignedReportWhereUniqueInput>>;
  set?: Maybe<Array<DirCalendarSignedReportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirCalendarSignedReportWhereUniqueInput>>;
  update?: Maybe<Array<DirCalendarSignedReportUpdateWithWhereUniqueWithoutDirCalendarSignedReportInput>>;
  upsert?: Maybe<Array<DirCalendarSignedReportUpsertWithWhereUniqueWithoutDirCalendarSignedReportInput>>;
  deleteMany?: Maybe<Array<DirCalendarSignedReportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirCalendarSignedReportUpdateManyWithWhereNestedInput>>;
};

export type DirCalendarSignedReportUpdateWithWhereUniqueWithoutDirCalendarSignedReportInput = {
  where: DirCalendarSignedReportWhereUniqueInput;
  data: DirCalendarSignedReportUpdateWithoutDirCalendarSignedReportDataInput;
};

export type DirCalendarSignedReportUpdateWithoutDirCalendarSignedReportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  files?: Maybe<FileUpdateManyInput>;
};

export type DirCalendarSignedReportUpsertWithWhereUniqueWithoutDirCalendarSignedReportInput = {
  where: DirCalendarSignedReportWhereUniqueInput;
  update: DirCalendarSignedReportUpdateWithoutDirCalendarSignedReportDataInput;
  create: DirCalendarSignedReportCreateWithoutDirCalendarSignedReportInput;
};

export type DirCalendarSignedReportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirCalendarSignedReportScalarWhereInput>>;
  OR?: Maybe<Array<DirCalendarSignedReportScalarWhereInput>>;
  NOT?: Maybe<Array<DirCalendarSignedReportScalarWhereInput>>;
};

export type DirCalendarSignedReportUpdateManyWithWhereNestedInput = {
  where: DirCalendarSignedReportScalarWhereInput;
  data: DirCalendarSignedReportUpdateManyDataInput;
};

export type DirCalendarSignedReportUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
};

export type ReqPartFromStageTwoUpdateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<ReqPartFromStageTwoCreateWithoutDirCalendarInput>>;
  delete?: Maybe<Array<ReqPartFromStageTwoWhereUniqueInput>>;
  connect?: Maybe<Array<ReqPartFromStageTwoWhereUniqueInput>>;
  set?: Maybe<Array<ReqPartFromStageTwoWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReqPartFromStageTwoWhereUniqueInput>>;
  update?: Maybe<Array<ReqPartFromStageTwoUpdateWithWhereUniqueWithoutDirCalendarInput>>;
  upsert?: Maybe<Array<ReqPartFromStageTwoUpsertWithWhereUniqueWithoutDirCalendarInput>>;
  deleteMany?: Maybe<Array<ReqPartFromStageTwoScalarWhereInput>>;
};

export type ReqPartFromStageTwoUpdateWithWhereUniqueWithoutDirCalendarInput = {
  where: ReqPartFromStageTwoWhereUniqueInput;
  data: ReqPartFromStageTwoUpdateWithoutDirCalendarDataInput;
};

export type ReqPartFromStageTwoUpdateWithoutDirCalendarDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  region?: Maybe<DirRegionUpdateOneRequiredInput>;
  roivSpecialistsForNotification?: Maybe<UserUpdateManyInput>;
  filesROIVStageTwo?: Maybe<FileUpdateManyInput>;
};

export type ReqPartFromStageTwoUpsertWithWhereUniqueWithoutDirCalendarInput = {
  where: ReqPartFromStageTwoWhereUniqueInput;
  update: ReqPartFromStageTwoUpdateWithoutDirCalendarDataInput;
  create: ReqPartFromStageTwoCreateWithoutDirCalendarInput;
};

export type ReqPartFromStageTwoScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ReqPartFromStageTwoScalarWhereInput>>;
  OR?: Maybe<Array<ReqPartFromStageTwoScalarWhereInput>>;
  NOT?: Maybe<Array<ReqPartFromStageTwoScalarWhereInput>>;
};

export type QuotaForStageTwoBySportUpdateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<QuotaForStageTwoBySportCreateWithoutDirCalendarInput>>;
  delete?: Maybe<Array<QuotaForStageTwoBySportWhereUniqueInput>>;
  connect?: Maybe<Array<QuotaForStageTwoBySportWhereUniqueInput>>;
  set?: Maybe<Array<QuotaForStageTwoBySportWhereUniqueInput>>;
  disconnect?: Maybe<Array<QuotaForStageTwoBySportWhereUniqueInput>>;
  update?: Maybe<Array<QuotaForStageTwoBySportUpdateWithWhereUniqueWithoutDirCalendarInput>>;
  upsert?: Maybe<Array<QuotaForStageTwoBySportUpsertWithWhereUniqueWithoutDirCalendarInput>>;
  deleteMany?: Maybe<Array<QuotaForStageTwoBySportScalarWhereInput>>;
  updateMany?: Maybe<Array<QuotaForStageTwoBySportUpdateManyWithWhereNestedInput>>;
};

export type QuotaForStageTwoBySportUpdateWithWhereUniqueWithoutDirCalendarInput = {
  where: QuotaForStageTwoBySportWhereUniqueInput;
  data: QuotaForStageTwoBySportUpdateWithoutDirCalendarDataInput;
};

export type QuotaForStageTwoBySportUpdateWithoutDirCalendarDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  quotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo?: Maybe<Scalars['Int']>;
};

export type QuotaForStageTwoBySportUpsertWithWhereUniqueWithoutDirCalendarInput = {
  where: QuotaForStageTwoBySportWhereUniqueInput;
  update: QuotaForStageTwoBySportUpdateWithoutDirCalendarDataInput;
  create: QuotaForStageTwoBySportCreateWithoutDirCalendarInput;
};

export type QuotaForStageTwoBySportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  quotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaMaleAthlStageTwo_not?: Maybe<Scalars['Int']>;
  quotaMaleAthlStageTwo_in?: Maybe<Array<Scalars['Int']>>;
  quotaMaleAthlStageTwo_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaMaleAthlStageTwo_lt?: Maybe<Scalars['Int']>;
  quotaMaleAthlStageTwo_lte?: Maybe<Scalars['Int']>;
  quotaMaleAthlStageTwo_gt?: Maybe<Scalars['Int']>;
  quotaMaleAthlStageTwo_gte?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo_not?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo_in?: Maybe<Array<Scalars['Int']>>;
  quotaWomenAthlStageTwo_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaWomenAthlStageTwo_lt?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo_lte?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo_gt?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo_gte?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo_not?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo_in?: Maybe<Array<Scalars['Int']>>;
  quotaAccompStageTwo_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaAccompStageTwo_lt?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo_lte?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo_gt?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<QuotaForStageTwoBySportScalarWhereInput>>;
  OR?: Maybe<Array<QuotaForStageTwoBySportScalarWhereInput>>;
  NOT?: Maybe<Array<QuotaForStageTwoBySportScalarWhereInput>>;
};

export type QuotaForStageTwoBySportUpdateManyWithWhereNestedInput = {
  where: QuotaForStageTwoBySportScalarWhereInput;
  data: QuotaForStageTwoBySportUpdateManyDataInput;
};

export type QuotaForStageTwoBySportUpdateManyDataInput = {
  quotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo?: Maybe<Scalars['Int']>;
};

export type QuantyRequestStageTwoUpdateManyWithoutDirCalendarInput = {
  create?: Maybe<Array<QuantyRequestStageTwoCreateWithoutDirCalendarInput>>;
  delete?: Maybe<Array<QuantyRequestStageTwoWhereUniqueInput>>;
  connect?: Maybe<Array<QuantyRequestStageTwoWhereUniqueInput>>;
  set?: Maybe<Array<QuantyRequestStageTwoWhereUniqueInput>>;
  disconnect?: Maybe<Array<QuantyRequestStageTwoWhereUniqueInput>>;
  update?: Maybe<Array<QuantyRequestStageTwoUpdateWithWhereUniqueWithoutDirCalendarInput>>;
  upsert?: Maybe<Array<QuantyRequestStageTwoUpsertWithWhereUniqueWithoutDirCalendarInput>>;
  deleteMany?: Maybe<Array<QuantyRequestStageTwoScalarWhereInput>>;
  updateMany?: Maybe<Array<QuantyRequestStageTwoUpdateManyWithWhereNestedInput>>;
};

export type QuantyRequestStageTwoUpdateWithWhereUniqueWithoutDirCalendarInput = {
  where: QuantyRequestStageTwoWhereUniqueInput;
  data: QuantyRequestStageTwoUpdateWithoutDirCalendarDataInput;
};

export type QuantyRequestStageTwoUpdateWithoutDirCalendarDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  regionQuotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo?: Maybe<Scalars['Int']>;
};

export type QuantyRequestStageTwoUpsertWithWhereUniqueWithoutDirCalendarInput = {
  where: QuantyRequestStageTwoWhereUniqueInput;
  update: QuantyRequestStageTwoUpdateWithoutDirCalendarDataInput;
  create: QuantyRequestStageTwoCreateWithoutDirCalendarInput;
};

export type QuantyRequestStageTwoScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  regionQuotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaMaleAthlStageTwo_not?: Maybe<Scalars['Int']>;
  regionQuotaMaleAthlStageTwo_in?: Maybe<Array<Scalars['Int']>>;
  regionQuotaMaleAthlStageTwo_not_in?: Maybe<Array<Scalars['Int']>>;
  regionQuotaMaleAthlStageTwo_lt?: Maybe<Scalars['Int']>;
  regionQuotaMaleAthlStageTwo_lte?: Maybe<Scalars['Int']>;
  regionQuotaMaleAthlStageTwo_gt?: Maybe<Scalars['Int']>;
  regionQuotaMaleAthlStageTwo_gte?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo_not?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo_in?: Maybe<Array<Scalars['Int']>>;
  regionQuotaWomenAthlStageTwo_not_in?: Maybe<Array<Scalars['Int']>>;
  regionQuotaWomenAthlStageTwo_lt?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo_lte?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo_gt?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo_gte?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo_not?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo_in?: Maybe<Array<Scalars['Int']>>;
  regionQuotaAccompStageTwo_not_in?: Maybe<Array<Scalars['Int']>>;
  regionQuotaAccompStageTwo_lt?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo_lte?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo_gt?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<QuantyRequestStageTwoScalarWhereInput>>;
  OR?: Maybe<Array<QuantyRequestStageTwoScalarWhereInput>>;
  NOT?: Maybe<Array<QuantyRequestStageTwoScalarWhereInput>>;
};

export type QuantyRequestStageTwoUpdateManyWithWhereNestedInput = {
  where: QuantyRequestStageTwoScalarWhereInput;
  data: QuantyRequestStageTwoUpdateManyDataInput;
};

export type QuantyRequestStageTwoUpdateManyDataInput = {
  regionQuotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo?: Maybe<Scalars['Int']>;
};

export type DirCalendarUpsertNestedInput = {
  update: DirCalendarUpdateDataInput;
  create: DirCalendarCreateInput;
};

export type DirSportingEventSportUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventSportCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventSportWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventSportWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventSportUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventSportUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventSportUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventSportUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventSportWhereUniqueInput;
  data: DirSportingEventSportUpdateWithoutEventDataInput;
};

export type DirSportingEventSportUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirSportDisciplineUpdateManyInput = {
  create?: Maybe<Array<DirSportDisciplineCreateInput>>;
  update?: Maybe<Array<DirSportDisciplineUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirSportDisciplineUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  set?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirSportDisciplineScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportDisciplineUpdateManyWithWhereNestedInput>>;
};

export type DirSportDisciplineUpdateWithWhereUniqueNestedInput = {
  where: DirSportDisciplineWhereUniqueInput;
  data: DirSportDisciplineUpdateDataInput;
};

export type DirSportDisciplineUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDisciplinesInput>;
  dirSport?: Maybe<DirSportUpdateOneWithoutDisciplinesInput>;
};

export type DirSportDisciplineUpsertWithWhereUniqueNestedInput = {
  where: DirSportDisciplineWhereUniqueInput;
  update: DirSportDisciplineUpdateDataInput;
  create: DirSportDisciplineCreateInput;
};

export type DirSportingEventSportUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventSportWhereUniqueInput;
  update: DirSportingEventSportUpdateWithoutEventDataInput;
  create: DirSportingEventSportCreateWithoutEventInput;
};

export type DirSportingEventSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportingEventSportScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventSportScalarWhereInput>>;
};

export type DirSportingEventSportUpdateManyWithWhereNestedInput = {
  where: DirSportingEventSportScalarWhereInput;
  data: DirSportingEventSportUpdateManyDataInput;
};

export type DirSportingEventSportUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventAgeGroupUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventAgeGroupCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventAgeGroupWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventAgeGroupWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventAgeGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventAgeGroupWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventAgeGroupUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventAgeGroupUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventAgeGroupScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventAgeGroupUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventAgeGroupUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventAgeGroupWhereUniqueInput;
  data: DirSportingEventAgeGroupUpdateWithoutEventDataInput;
};

export type DirSportingEventAgeGroupUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup?: Maybe<ClassifierValueUpdateOneInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirSportingEventAgeGroupUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventAgeGroupWhereUniqueInput;
  update: DirSportingEventAgeGroupUpdateWithoutEventDataInput;
  create: DirSportingEventAgeGroupCreateWithoutEventInput;
};

export type DirSportingEventAgeGroupScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportingEventAgeGroupScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventAgeGroupScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventAgeGroupScalarWhereInput>>;
};

export type DirSportingEventAgeGroupUpdateManyWithWhereNestedInput = {
  where: DirSportingEventAgeGroupScalarWhereInput;
  data: DirSportingEventAgeGroupUpdateManyDataInput;
};

export type DirSportingEventAgeGroupUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirSportingEventProgramTypeUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventProgramTypeCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventProgramTypeWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventProgramTypeWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventProgramTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventProgramTypeWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventProgramTypeUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventProgramTypeUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventProgramTypeScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventProgramTypeUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventProgramTypeUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventProgramTypeWhereUniqueInput;
  data: DirSportingEventProgramTypeUpdateWithoutEventDataInput;
};

export type DirSportingEventProgramTypeUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  clsSportCategory?: Maybe<ClassifierValueUpdateOneInput>;
  resultsDocument?: Maybe<DirDocumentUpdateOneInput>;
  resultDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsAgeGroups?: Maybe<ClassifierValueUpdateManyInput>;
  discipline?: Maybe<DirSportDisciplineUpdateOneRequiredInput>;
};

export type DirSportDisciplineUpdateOneRequiredInput = {
  create?: Maybe<DirSportDisciplineCreateInput>;
  update?: Maybe<DirSportDisciplineUpdateDataInput>;
  upsert?: Maybe<DirSportDisciplineUpsertNestedInput>;
  connect?: Maybe<DirSportDisciplineWhereUniqueInput>;
};

export type DirSportDisciplineUpsertNestedInput = {
  update: DirSportDisciplineUpdateDataInput;
  create: DirSportDisciplineCreateInput;
};

export type DirSportingEventProgramTypeUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventProgramTypeWhereUniqueInput;
  update: DirSportingEventProgramTypeUpdateWithoutEventDataInput;
  create: DirSportingEventProgramTypeCreateWithoutEventInput;
};

export type DirSportingEventProgramTypeScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  start_not?: Maybe<Scalars['String']>;
  start_in?: Maybe<Array<Scalars['String']>>;
  start_not_in?: Maybe<Array<Scalars['String']>>;
  start_lt?: Maybe<Scalars['String']>;
  start_lte?: Maybe<Scalars['String']>;
  start_gt?: Maybe<Scalars['String']>;
  start_gte?: Maybe<Scalars['String']>;
  start_contains?: Maybe<Scalars['String']>;
  start_not_contains?: Maybe<Scalars['String']>;
  start_starts_with?: Maybe<Scalars['String']>;
  start_not_starts_with?: Maybe<Scalars['String']>;
  start_ends_with?: Maybe<Scalars['String']>;
  start_not_ends_with?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  division_not?: Maybe<Scalars['String']>;
  division_in?: Maybe<Array<Scalars['String']>>;
  division_not_in?: Maybe<Array<Scalars['String']>>;
  division_lt?: Maybe<Scalars['String']>;
  division_lte?: Maybe<Scalars['String']>;
  division_gt?: Maybe<Scalars['String']>;
  division_gte?: Maybe<Scalars['String']>;
  division_contains?: Maybe<Scalars['String']>;
  division_not_contains?: Maybe<Scalars['String']>;
  division_starts_with?: Maybe<Scalars['String']>;
  division_not_starts_with?: Maybe<Scalars['String']>;
  division_ends_with?: Maybe<Scalars['String']>;
  division_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportingEventProgramTypeScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventProgramTypeScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventProgramTypeScalarWhereInput>>;
};

export type DirSportingEventProgramTypeUpdateManyWithWhereNestedInput = {
  where: DirSportingEventProgramTypeScalarWhereInput;
  data: DirSportingEventProgramTypeUpdateManyDataInput;
};

export type DirSportingEventProgramTypeUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
};

export type DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventCompetitionCalendarCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventCompetitionCalendarWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventCompetitionCalendarWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventCompetitionCalendarWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventCompetitionCalendarWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventCompetitionCalendarUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventCompetitionCalendarUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventCompetitionCalendarScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventCompetitionCalendarUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventCompetitionCalendarUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventCompetitionCalendarWhereUniqueInput;
  data: DirSportingEventCompetitionCalendarUpdateWithoutEventDataInput;
};

export type DirSportingEventCompetitionCalendarUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  discipline?: Maybe<DirSportDisciplineUpdateOneInput>;
};

export type DirSportDisciplineUpdateOneInput = {
  create?: Maybe<DirSportDisciplineCreateInput>;
  update?: Maybe<DirSportDisciplineUpdateDataInput>;
  upsert?: Maybe<DirSportDisciplineUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportDisciplineWhereUniqueInput>;
};

export type DirSportingEventCompetitionCalendarUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventCompetitionCalendarWhereUniqueInput;
  update: DirSportingEventCompetitionCalendarUpdateWithoutEventDataInput;
  create: DirSportingEventCompetitionCalendarCreateWithoutEventInput;
};

export type DirSportingEventCompetitionCalendarScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['DateTime']>;
  day_not?: Maybe<Scalars['DateTime']>;
  day_in?: Maybe<Array<Scalars['DateTime']>>;
  day_not_in?: Maybe<Array<Scalars['DateTime']>>;
  day_lt?: Maybe<Scalars['DateTime']>;
  day_lte?: Maybe<Scalars['DateTime']>;
  day_gt?: Maybe<Scalars['DateTime']>;
  day_gte?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  time_not?: Maybe<Scalars['String']>;
  time_in?: Maybe<Array<Scalars['String']>>;
  time_not_in?: Maybe<Array<Scalars['String']>>;
  time_lt?: Maybe<Scalars['String']>;
  time_lte?: Maybe<Scalars['String']>;
  time_gt?: Maybe<Scalars['String']>;
  time_gte?: Maybe<Scalars['String']>;
  time_contains?: Maybe<Scalars['String']>;
  time_not_contains?: Maybe<Scalars['String']>;
  time_starts_with?: Maybe<Scalars['String']>;
  time_not_starts_with?: Maybe<Scalars['String']>;
  time_ends_with?: Maybe<Scalars['String']>;
  time_not_ends_with?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  members_not?: Maybe<Scalars['String']>;
  members_in?: Maybe<Array<Scalars['String']>>;
  members_not_in?: Maybe<Array<Scalars['String']>>;
  members_lt?: Maybe<Scalars['String']>;
  members_lte?: Maybe<Scalars['String']>;
  members_gt?: Maybe<Scalars['String']>;
  members_gte?: Maybe<Scalars['String']>;
  members_contains?: Maybe<Scalars['String']>;
  members_not_contains?: Maybe<Scalars['String']>;
  members_starts_with?: Maybe<Scalars['String']>;
  members_not_starts_with?: Maybe<Scalars['String']>;
  members_ends_with?: Maybe<Scalars['String']>;
  members_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportingEventCompetitionCalendarScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventCompetitionCalendarScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventCompetitionCalendarScalarWhereInput>>;
};

export type DirSportingEventCompetitionCalendarUpdateManyWithWhereNestedInput = {
  where: DirSportingEventCompetitionCalendarScalarWhereInput;
  data: DirSportingEventCompetitionCalendarUpdateManyDataInput;
};

export type DirSportingEventCompetitionCalendarUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
};

export type DirOrganizationUpdateManyInput = {
  create?: Maybe<Array<DirOrganizationCreateInput>>;
  update?: Maybe<Array<DirOrganizationUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirOrganizationUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
  connect?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
  set?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirOrganizationScalarWhereInput>>;
  updateMany?: Maybe<Array<DirOrganizationUpdateManyWithWhereNestedInput>>;
};

export type DirOrganizationUpdateWithWhereUniqueNestedInput = {
  where: DirOrganizationWhereUniqueInput;
  data: DirOrganizationUpdateDataInput;
};

export type DirOrganizationUpsertWithWhereUniqueNestedInput = {
  where: DirOrganizationWhereUniqueInput;
  update: DirOrganizationUpdateDataInput;
  create: DirOrganizationCreateInput;
};

export type DirSportingEventRequestUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventRequestCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventRequestWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventRequestWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventRequestWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventRequestUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventRequestUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventRequestScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventRequestUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventRequestUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventRequestWhereUniqueInput;
  data: DirSportingEventRequestUpdateWithoutEventDataInput;
};

export type DirSportingEventRequestUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  orderNumber?: Maybe<Scalars['Int']>;
  type?: Maybe<DirSportingEventRequestTypeUpdateOneRequiredInput>;
  statuses?: Maybe<DirSportingEventRequestStatusUpdateManyWithoutRequestInput>;
};

export type DirSportingEventRequestTypeUpdateOneRequiredInput = {
  create?: Maybe<DirSportingEventRequestTypeCreateInput>;
  update?: Maybe<DirSportingEventRequestTypeUpdateDataInput>;
  upsert?: Maybe<DirSportingEventRequestTypeUpsertNestedInput>;
  connect?: Maybe<DirSportingEventRequestTypeWhereUniqueInput>;
};

export type DirSportingEventRequestTypeUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventRequestTypeUpsertNestedInput = {
  update: DirSportingEventRequestTypeUpdateDataInput;
  create: DirSportingEventRequestTypeCreateInput;
};

export type DirSportingEventRequestStatusUpdateManyWithoutRequestInput = {
  create?: Maybe<Array<DirSportingEventRequestStatusCreateWithoutRequestInput>>;
  delete?: Maybe<Array<DirSportingEventRequestStatusWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventRequestStatusWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventRequestStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventRequestStatusWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventRequestStatusUpdateWithWhereUniqueWithoutRequestInput>>;
  upsert?: Maybe<Array<DirSportingEventRequestStatusUpsertWithWhereUniqueWithoutRequestInput>>;
  deleteMany?: Maybe<Array<DirSportingEventRequestStatusScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventRequestStatusUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventRequestStatusUpdateWithWhereUniqueWithoutRequestInput = {
  where: DirSportingEventRequestStatusWhereUniqueInput;
  data: DirSportingEventRequestStatusUpdateWithoutRequestDataInput;
};

export type DirSportingEventRequestStatusUpdateWithoutRequestDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  value?: Maybe<DirSportingEventRequestStatusValueUpdateOneRequiredInput>;
  file?: Maybe<FileUpdateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRequestStatusValueUpdateOneRequiredInput = {
  create?: Maybe<DirSportingEventRequestStatusValueCreateInput>;
  update?: Maybe<DirSportingEventRequestStatusValueUpdateDataInput>;
  upsert?: Maybe<DirSportingEventRequestStatusValueUpsertNestedInput>;
  connect?: Maybe<DirSportingEventRequestStatusValueWhereUniqueInput>;
};

export type DirSportingEventRequestStatusValueUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventRequestStatusValueUpsertNestedInput = {
  update: DirSportingEventRequestStatusValueUpdateDataInput;
  create: DirSportingEventRequestStatusValueCreateInput;
};

export type DirSportingEventRequestStatusUpsertWithWhereUniqueWithoutRequestInput = {
  where: DirSportingEventRequestStatusWhereUniqueInput;
  update: DirSportingEventRequestStatusUpdateWithoutRequestDataInput;
  create: DirSportingEventRequestStatusCreateWithoutRequestInput;
};

export type DirSportingEventRequestStatusScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventRequestStatusScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRequestStatusScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRequestStatusScalarWhereInput>>;
};

export type DirSportingEventRequestStatusUpdateManyWithWhereNestedInput = {
  where: DirSportingEventRequestStatusScalarWhereInput;
  data: DirSportingEventRequestStatusUpdateManyDataInput;
};

export type DirSportingEventRequestStatusUpdateManyDataInput = {
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRequestUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventRequestWhereUniqueInput;
  update: DirSportingEventRequestUpdateWithoutEventDataInput;
  create: DirSportingEventRequestCreateWithoutEventInput;
};

export type DirSportingEventRequestScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  orderNumber?: Maybe<Scalars['Int']>;
  orderNumber_not?: Maybe<Scalars['Int']>;
  orderNumber_in?: Maybe<Array<Scalars['Int']>>;
  orderNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  orderNumber_lt?: Maybe<Scalars['Int']>;
  orderNumber_lte?: Maybe<Scalars['Int']>;
  orderNumber_gt?: Maybe<Scalars['Int']>;
  orderNumber_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportingEventRequestScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRequestScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRequestScalarWhereInput>>;
};

export type DirSportingEventRequestUpdateManyWithWhereNestedInput = {
  where: DirSportingEventRequestScalarWhereInput;
  data: DirSportingEventRequestUpdateManyDataInput;
};

export type DirSportingEventRequestUpdateManyDataInput = {
  orderNumber?: Maybe<Scalars['Int']>;
};

export type DirSportingEventPointDistributionUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventPointDistributionCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventPointDistributionWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventPointDistributionWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventPointDistributionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventPointDistributionWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventPointDistributionUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventPointDistributionUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventPointDistributionScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventPointDistributionUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventPointDistributionUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventPointDistributionWhereUniqueInput;
  data: DirSportingEventPointDistributionUpdateWithoutEventDataInput;
};

export type DirSportingEventPointDistributionUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  lastPlacesPoints?: Maybe<Scalars['Int']>;
  lastPlacesStart?: Maybe<Scalars['Int']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  clsAgeGroup?: Maybe<ClassifierValueUpdateOneInput>;
  places?: Maybe<DirSportingEventPointDistributionPlaceUpdateManyWithoutPointDistributionInput>;
};

export type DirSportingEventPointDistributionPlaceUpdateManyWithoutPointDistributionInput = {
  create?: Maybe<Array<DirSportingEventPointDistributionPlaceCreateWithoutPointDistributionInput>>;
  delete?: Maybe<Array<DirSportingEventPointDistributionPlaceWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventPointDistributionPlaceWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventPointDistributionPlaceWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventPointDistributionPlaceWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventPointDistributionPlaceUpdateWithWhereUniqueWithoutPointDistributionInput>>;
  upsert?: Maybe<Array<DirSportingEventPointDistributionPlaceUpsertWithWhereUniqueWithoutPointDistributionInput>>;
  deleteMany?: Maybe<Array<DirSportingEventPointDistributionPlaceScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventPointDistributionPlaceUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventPointDistributionPlaceUpdateWithWhereUniqueWithoutPointDistributionInput = {
  where: DirSportingEventPointDistributionPlaceWhereUniqueInput;
  data: DirSportingEventPointDistributionPlaceUpdateWithoutPointDistributionDataInput;
};

export type DirSportingEventPointDistributionPlaceUpdateWithoutPointDistributionDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  place?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
};

export type DirSportingEventPointDistributionPlaceUpsertWithWhereUniqueWithoutPointDistributionInput = {
  where: DirSportingEventPointDistributionPlaceWhereUniqueInput;
  update: DirSportingEventPointDistributionPlaceUpdateWithoutPointDistributionDataInput;
  create: DirSportingEventPointDistributionPlaceCreateWithoutPointDistributionInput;
};

export type DirSportingEventPointDistributionPlaceScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  place?: Maybe<Scalars['Int']>;
  place_not?: Maybe<Scalars['Int']>;
  place_in?: Maybe<Array<Scalars['Int']>>;
  place_not_in?: Maybe<Array<Scalars['Int']>>;
  place_lt?: Maybe<Scalars['Int']>;
  place_lte?: Maybe<Scalars['Int']>;
  place_gt?: Maybe<Scalars['Int']>;
  place_gte?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  points_not?: Maybe<Scalars['Int']>;
  points_in?: Maybe<Array<Scalars['Int']>>;
  points_not_in?: Maybe<Array<Scalars['Int']>>;
  points_lt?: Maybe<Scalars['Int']>;
  points_lte?: Maybe<Scalars['Int']>;
  points_gt?: Maybe<Scalars['Int']>;
  points_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportingEventPointDistributionPlaceScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventPointDistributionPlaceScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventPointDistributionPlaceScalarWhereInput>>;
};

export type DirSportingEventPointDistributionPlaceUpdateManyWithWhereNestedInput = {
  where: DirSportingEventPointDistributionPlaceScalarWhereInput;
  data: DirSportingEventPointDistributionPlaceUpdateManyDataInput;
};

export type DirSportingEventPointDistributionPlaceUpdateManyDataInput = {
  place?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
};

export type DirSportingEventPointDistributionUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventPointDistributionWhereUniqueInput;
  update: DirSportingEventPointDistributionUpdateWithoutEventDataInput;
  create: DirSportingEventPointDistributionCreateWithoutEventInput;
};

export type DirSportingEventPointDistributionScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  lastPlacesPoints?: Maybe<Scalars['Int']>;
  lastPlacesPoints_not?: Maybe<Scalars['Int']>;
  lastPlacesPoints_in?: Maybe<Array<Scalars['Int']>>;
  lastPlacesPoints_not_in?: Maybe<Array<Scalars['Int']>>;
  lastPlacesPoints_lt?: Maybe<Scalars['Int']>;
  lastPlacesPoints_lte?: Maybe<Scalars['Int']>;
  lastPlacesPoints_gt?: Maybe<Scalars['Int']>;
  lastPlacesPoints_gte?: Maybe<Scalars['Int']>;
  lastPlacesStart?: Maybe<Scalars['Int']>;
  lastPlacesStart_not?: Maybe<Scalars['Int']>;
  lastPlacesStart_in?: Maybe<Array<Scalars['Int']>>;
  lastPlacesStart_not_in?: Maybe<Array<Scalars['Int']>>;
  lastPlacesStart_lt?: Maybe<Scalars['Int']>;
  lastPlacesStart_lte?: Maybe<Scalars['Int']>;
  lastPlacesStart_gt?: Maybe<Scalars['Int']>;
  lastPlacesStart_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportingEventPointDistributionScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventPointDistributionScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventPointDistributionScalarWhereInput>>;
};

export type DirSportingEventPointDistributionUpdateManyWithWhereNestedInput = {
  where: DirSportingEventPointDistributionScalarWhereInput;
  data: DirSportingEventPointDistributionUpdateManyDataInput;
};

export type DirSportingEventPointDistributionUpdateManyDataInput = {
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  lastPlacesPoints?: Maybe<Scalars['Int']>;
  lastPlacesStart?: Maybe<Scalars['Int']>;
};

export type DirSportingEventRefereeUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventRefereeCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventRefereeWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventRefereeWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventRefereeWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventRefereeWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventRefereeUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventRefereeUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventRefereeScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventRefereeUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventRefereeUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventRefereeWhereUniqueInput;
  data: DirSportingEventRefereeUpdateWithoutEventDataInput;
};

export type DirSportingEventRefereeUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee?: Maybe<DirRefereeUpdateOneRequiredInput>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  clsRefereePosition?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  isNonresident?: Maybe<Scalars['Boolean']>;
};

export type DirRefereeUpdateOneRequiredInput = {
  create?: Maybe<DirRefereeCreateInput>;
  update?: Maybe<DirRefereeUpdateDataInput>;
  upsert?: Maybe<DirRefereeUpsertNestedInput>;
  connect?: Maybe<DirRefereeWhereUniqueInput>;
};

export type DirRefereeUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutRefereeProfileInput>;
  sports?: Maybe<DirRefereeSportUpdateManyWithoutDirRefereeInput>;
};

export type DirPersonUpdateOneRequiredWithoutRefereeProfileInput = {
  create?: Maybe<DirPersonCreateWithoutRefereeProfileInput>;
  update?: Maybe<DirPersonUpdateWithoutRefereeProfileDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutRefereeProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutRefereeProfileDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type DirPersonMergeTaskUpdateManyWithoutDuplicateInput = {
  create?: Maybe<Array<DirPersonMergeTaskCreateWithoutDuplicateInput>>;
  delete?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  connect?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  set?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  update?: Maybe<Array<DirPersonMergeTaskUpdateWithWhereUniqueWithoutDuplicateInput>>;
  upsert?: Maybe<Array<DirPersonMergeTaskUpsertWithWhereUniqueWithoutDuplicateInput>>;
  deleteMany?: Maybe<Array<DirPersonMergeTaskScalarWhereInput>>;
  updateMany?: Maybe<Array<DirPersonMergeTaskUpdateManyWithWhereNestedInput>>;
};

export type DirPersonMergeTaskUpdateWithWhereUniqueWithoutDuplicateInput = {
  where: DirPersonMergeTaskWhereUniqueInput;
  data: DirPersonMergeTaskUpdateWithoutDuplicateDataInput;
};

export type DirPersonMergeTaskUpdateWithoutDuplicateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  status?: Maybe<TaskStatusUpdateOneRequiredInput>;
  comment?: Maybe<Scalars['String']>;
  primary?: Maybe<DirPersonUpdateOneRequiredWithoutMergeTasksAsPrimaryInput>;
};

export type DirPersonUpdateOneRequiredWithoutMergeTasksAsPrimaryInput = {
  create?: Maybe<DirPersonCreateWithoutMergeTasksAsPrimaryInput>;
  update?: Maybe<DirPersonUpdateWithoutMergeTasksAsPrimaryDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutMergeTasksAsPrimaryInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutMergeTasksAsPrimaryDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type DirPersonUpsertWithoutMergeTasksAsPrimaryInput = {
  update: DirPersonUpdateWithoutMergeTasksAsPrimaryDataInput;
  create: DirPersonCreateWithoutMergeTasksAsPrimaryInput;
};

export type DirPersonMergeTaskUpsertWithWhereUniqueWithoutDuplicateInput = {
  where: DirPersonMergeTaskWhereUniqueInput;
  update: DirPersonMergeTaskUpdateWithoutDuplicateDataInput;
  create: DirPersonMergeTaskCreateWithoutDuplicateInput;
};

export type DirPersonMergeTaskScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirPersonMergeTaskScalarWhereInput>>;
  OR?: Maybe<Array<DirPersonMergeTaskScalarWhereInput>>;
  NOT?: Maybe<Array<DirPersonMergeTaskScalarWhereInput>>;
};

export type DirPersonMergeTaskUpdateManyWithWhereNestedInput = {
  where: DirPersonMergeTaskScalarWhereInput;
  data: DirPersonMergeTaskUpdateManyDataInput;
};

export type DirPersonMergeTaskUpdateManyDataInput = {
  comment?: Maybe<Scalars['String']>;
};

export type DirPersonUpsertWithoutRefereeProfileInput = {
  update: DirPersonUpdateWithoutRefereeProfileDataInput;
  create: DirPersonCreateWithoutRefereeProfileInput;
};

export type DirRefereeUpsertNestedInput = {
  update: DirRefereeUpdateDataInput;
  create: DirRefereeCreateInput;
};

export type DirSportingEventRefereeUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventRefereeWhereUniqueInput;
  update: DirSportingEventRefereeUpdateWithoutEventDataInput;
  create: DirSportingEventRefereeCreateWithoutEventInput;
};

export type DirSportingEventRefereeScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPrimary_not?: Maybe<Scalars['Boolean']>;
  isNonresident?: Maybe<Scalars['Boolean']>;
  isNonresident_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventRefereeScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRefereeScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRefereeScalarWhereInput>>;
};

export type DirSportingEventRefereeUpdateManyWithWhereNestedInput = {
  where: DirSportingEventRefereeScalarWhereInput;
  data: DirSportingEventRefereeUpdateManyDataInput;
};

export type DirSportingEventRefereeUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isNonresident?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRefereeStatusUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventRefereeStatusCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventRefereeStatusWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventRefereeStatusWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventRefereeStatusWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventRefereeStatusWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventRefereeStatusUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventRefereeStatusUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventRefereeStatusScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventRefereeStatusUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventRefereeStatusUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventRefereeStatusWhereUniqueInput;
  data: DirSportingEventRefereeStatusUpdateWithoutEventDataInput;
};

export type DirSportingEventRefereeStatusUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  value?: Maybe<DirSportingEventRefereeStatusValueUpdateOneRequiredInput>;
  file?: Maybe<FileUpdateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRefereeStatusValueUpdateOneRequiredInput = {
  create?: Maybe<DirSportingEventRefereeStatusValueCreateInput>;
  update?: Maybe<DirSportingEventRefereeStatusValueUpdateDataInput>;
  upsert?: Maybe<DirSportingEventRefereeStatusValueUpsertNestedInput>;
  connect?: Maybe<DirSportingEventRefereeStatusValueWhereUniqueInput>;
};

export type DirSportingEventRefereeStatusValueUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventRefereeStatusValueUpsertNestedInput = {
  update: DirSportingEventRefereeStatusValueUpdateDataInput;
  create: DirSportingEventRefereeStatusValueCreateInput;
};

export type DirSportingEventRefereeStatusUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventRefereeStatusWhereUniqueInput;
  update: DirSportingEventRefereeStatusUpdateWithoutEventDataInput;
  create: DirSportingEventRefereeStatusCreateWithoutEventInput;
};

export type DirSportingEventRefereeStatusScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventRefereeStatusScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRefereeStatusScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRefereeStatusScalarWhereInput>>;
};

export type DirSportingEventRefereeStatusUpdateManyWithWhereNestedInput = {
  where: DirSportingEventRefereeStatusScalarWhereInput;
  data: DirSportingEventRefereeStatusUpdateManyDataInput;
};

export type DirSportingEventRefereeStatusUpdateManyDataInput = {
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput = {
  create?: Maybe<Array<DirSportingEventAthleteCallNotificationCreateWithoutDirSportingEventInput>>;
  delete?: Maybe<Array<DirSportingEventAthleteCallNotificationWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventAthleteCallNotificationWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventAthleteCallNotificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventAthleteCallNotificationWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventAthleteCallNotificationUpdateWithWhereUniqueWithoutDirSportingEventInput>>;
  upsert?: Maybe<Array<DirSportingEventAthleteCallNotificationUpsertWithWhereUniqueWithoutDirSportingEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventAthleteCallNotificationScalarWhereInput>>;
};

export type DirSportingEventAthleteCallNotificationUpdateWithWhereUniqueWithoutDirSportingEventInput = {
  where: DirSportingEventAthleteCallNotificationWhereUniqueInput;
  data: DirSportingEventAthleteCallNotificationUpdateWithoutDirSportingEventDataInput;
};

export type DirSportingEventAthleteCallNotificationUpdateWithoutDirSportingEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  document?: Maybe<DirDocumentUpdateOneRequiredInput>;
  teams?: Maybe<DirSportingEventAthleteCallNotificationTeamUpdateManyWithoutNotificationInput>;
};

export type DirDocumentUpdateOneRequiredInput = {
  create?: Maybe<DirDocumentCreateInput>;
  update?: Maybe<DirDocumentUpdateDataInput>;
  upsert?: Maybe<DirDocumentUpsertNestedInput>;
  connect?: Maybe<DirDocumentWhereUniqueInput>;
};

export type DirSportingEventAthleteCallNotificationTeamUpdateManyWithoutNotificationInput = {
  create?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamCreateWithoutNotificationInput>>;
  delete?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamUpdateWithWhereUniqueWithoutNotificationInput>>;
  upsert?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamUpsertWithWhereUniqueWithoutNotificationInput>>;
  deleteMany?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventAthleteCallNotificationTeamUpdateWithWhereUniqueWithoutNotificationInput = {
  where: DirSportingEventAthleteCallNotificationTeamWhereUniqueInput;
  data: DirSportingEventAthleteCallNotificationTeamUpdateWithoutNotificationDataInput;
};

export type DirSportingEventAthleteCallNotificationTeamUpdateWithoutNotificationDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  ditNationalTeam?: Maybe<DirNationalTeamUpdateOneRequiredInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamUpdateOneRequiredInput = {
  create?: Maybe<DirNationalTeamCreateInput>;
  update?: Maybe<DirNationalTeamUpdateDataInput>;
  upsert?: Maybe<DirNationalTeamUpsertNestedInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirNationalTeamExtendedStatusUpdateManyWithoutTeamInput>;
  dirSportingEvent?: Maybe<DirSportingEventUpdateOneRequiredWithoutDirNationalTeamsInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  trainers?: Maybe<DirNationalTeamTrainerUpdateManyWithoutTeamInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupUpdateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  medics?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  delegationHeads?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  emailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutTeamInput>;
  files?: Maybe<FileUpdateManyInput>;
  university?: Maybe<DirOrganizationUpdateOneInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  arrivalDeparturePlan?: Maybe<FileUpdateManyInput>;
  preliminaryQuantitativeOrder?: Maybe<FileUpdateManyInput>;
};

export type DirNationalTeamAthleteGroupUpdateManyWithoutTeamInput = {
  create?: Maybe<Array<DirNationalTeamAthleteGroupCreateWithoutTeamInput>>;
  delete?: Maybe<Array<DirNationalTeamAthleteGroupWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamAthleteGroupWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamAthleteGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamAthleteGroupWhereUniqueInput>>;
  update?: Maybe<Array<DirNationalTeamAthleteGroupUpdateWithWhereUniqueWithoutTeamInput>>;
  upsert?: Maybe<Array<DirNationalTeamAthleteGroupUpsertWithWhereUniqueWithoutTeamInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamAthleteGroupScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamAthleteGroupUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamAthleteGroupUpdateWithWhereUniqueWithoutTeamInput = {
  where: DirNationalTeamAthleteGroupWhereUniqueInput;
  data: DirNationalTeamAthleteGroupUpdateWithoutTeamDataInput;
};

export type DirNationalTeamAthleteGroupUpdateWithoutTeamDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  athletes?: Maybe<DirNationalTeamAthleteUpdateManyWithoutGroupInput>;
};

export type DirNationalTeamAthleteUpdateManyWithoutGroupInput = {
  create?: Maybe<Array<DirNationalTeamAthleteCreateWithoutGroupInput>>;
  delete?: Maybe<Array<DirNationalTeamAthleteWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamAthleteWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamAthleteWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamAthleteWhereUniqueInput>>;
  update?: Maybe<Array<DirNationalTeamAthleteUpdateWithWhereUniqueWithoutGroupInput>>;
  upsert?: Maybe<Array<DirNationalTeamAthleteUpsertWithWhereUniqueWithoutGroupInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamAthleteScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamAthleteUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamAthleteUpdateWithWhereUniqueWithoutGroupInput = {
  where: DirNationalTeamAthleteWhereUniqueInput;
  data: DirNationalTeamAthleteUpdateWithoutGroupDataInput;
};

export type DirNationalTeamAthleteUpdateWithoutGroupDataInput = {
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirAthlete?: Maybe<DirAthleteUpdateOneRequiredInput>;
  bestResult?: Maybe<Scalars['String']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteUpdateOneRequiredInput = {
  create?: Maybe<DirAthleteCreateInput>;
  update?: Maybe<DirAthleteUpdateDataInput>;
  upsert?: Maybe<DirAthleteUpsertNestedInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutAthleteProfileInput>;
  dirPersonAgent?: Maybe<DirPersonUpdateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueUpdateOneInput>;
  sports?: Maybe<DirAthleteSportUpdateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertUpdateManyWithoutDirAthleteInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultUpdateManyWithoutDirAthleteInput>;
};

export type DirPersonUpdateOneRequiredWithoutAthleteProfileInput = {
  create?: Maybe<DirPersonCreateWithoutAthleteProfileInput>;
  update?: Maybe<DirPersonUpdateWithoutAthleteProfileDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutAthleteProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutAthleteProfileDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type DirPersonUpsertWithoutAthleteProfileInput = {
  update: DirPersonUpdateWithoutAthleteProfileDataInput;
  create: DirPersonCreateWithoutAthleteProfileInput;
};

export type DirAthleteSportUpdateManyInput = {
  create?: Maybe<Array<DirAthleteSportCreateInput>>;
  update?: Maybe<Array<DirAthleteSportUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirAthleteSportUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirAthleteSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirAthleteSportWhereUniqueInput>>;
  set?: Maybe<Array<DirAthleteSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirAthleteSportWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirAthleteSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirAthleteSportUpdateManyWithWhereNestedInput>>;
};

export type DirAthleteSportUpdateWithWhereUniqueNestedInput = {
  where: DirAthleteSportWhereUniqueInput;
  data: DirAthleteSportUpdateDataInput;
};

export type DirAthleteSportUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  dirTrainers?: Maybe<DirTrainerUpdateManyInput>;
  organizations?: Maybe<DirAthleteSportOrganizationUpdateManyInput>;
  ranksAndAwards?: Maybe<DirAthleteSportRankAndAwardUpdateManyWithoutSportInput>;
  role?: Maybe<Scalars['String']>;
};

export type DirTrainerUpdateManyInput = {
  create?: Maybe<Array<DirTrainerCreateInput>>;
  update?: Maybe<Array<DirTrainerUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirTrainerUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirTrainerWhereUniqueInput>>;
  connect?: Maybe<Array<DirTrainerWhereUniqueInput>>;
  set?: Maybe<Array<DirTrainerWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirTrainerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirTrainerScalarWhereInput>>;
  updateMany?: Maybe<Array<DirTrainerUpdateManyWithWhereNestedInput>>;
};

export type DirTrainerUpdateWithWhereUniqueNestedInput = {
  where: DirTrainerWhereUniqueInput;
  data: DirTrainerUpdateDataInput;
};

export type DirTrainerUpsertWithWhereUniqueNestedInput = {
  where: DirTrainerWhereUniqueInput;
  update: DirTrainerUpdateDataInput;
  create: DirTrainerCreateInput;
};

export type DirTrainerScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirTrainerScalarWhereInput>>;
  OR?: Maybe<Array<DirTrainerScalarWhereInput>>;
  NOT?: Maybe<Array<DirTrainerScalarWhereInput>>;
};

export type DirTrainerUpdateManyWithWhereNestedInput = {
  where: DirTrainerScalarWhereInput;
  data: DirTrainerUpdateManyDataInput;
};

export type DirTrainerUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteSportOrganizationUpdateManyInput = {
  create?: Maybe<Array<DirAthleteSportOrganizationCreateInput>>;
  update?: Maybe<Array<DirAthleteSportOrganizationUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirAthleteSportOrganizationUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirAthleteSportOrganizationWhereUniqueInput>>;
  connect?: Maybe<Array<DirAthleteSportOrganizationWhereUniqueInput>>;
  set?: Maybe<Array<DirAthleteSportOrganizationWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirAthleteSportOrganizationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirAthleteSportOrganizationScalarWhereInput>>;
  updateMany?: Maybe<Array<DirAthleteSportOrganizationUpdateManyWithWhereNestedInput>>;
};

export type DirAthleteSportOrganizationUpdateWithWhereUniqueNestedInput = {
  where: DirAthleteSportOrganizationWhereUniqueInput;
  data: DirAthleteSportOrganizationUpdateDataInput;
};

export type DirAthleteSportOrganizationUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredInput>;
  clsTrainingStage?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  dirTrainer?: Maybe<DirTrainerUpdateOneInput>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  trainingStart?: Maybe<Scalars['DateTime']>;
  exclusionDate?: Maybe<Scalars['DateTime']>;
};

export type DirTrainerUpdateOneInput = {
  create?: Maybe<DirTrainerCreateInput>;
  update?: Maybe<DirTrainerUpdateDataInput>;
  upsert?: Maybe<DirTrainerUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirTrainerUpsertNestedInput = {
  update: DirTrainerUpdateDataInput;
  create: DirTrainerCreateInput;
};

export type DirAthleteSportOrganizationUpsertWithWhereUniqueNestedInput = {
  where: DirAthleteSportOrganizationWhereUniqueInput;
  update: DirAthleteSportOrganizationUpdateDataInput;
  create: DirAthleteSportOrganizationCreateInput;
};

export type DirAthleteSportOrganizationScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  isSelfTraining_not?: Maybe<Scalars['Boolean']>;
  trainingStart?: Maybe<Scalars['DateTime']>;
  trainingStart_not?: Maybe<Scalars['DateTime']>;
  trainingStart_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStart_not_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStart_lt?: Maybe<Scalars['DateTime']>;
  trainingStart_lte?: Maybe<Scalars['DateTime']>;
  trainingStart_gt?: Maybe<Scalars['DateTime']>;
  trainingStart_gte?: Maybe<Scalars['DateTime']>;
  exclusionDate?: Maybe<Scalars['DateTime']>;
  exclusionDate_not?: Maybe<Scalars['DateTime']>;
  exclusionDate_in?: Maybe<Array<Scalars['DateTime']>>;
  exclusionDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  exclusionDate_lt?: Maybe<Scalars['DateTime']>;
  exclusionDate_lte?: Maybe<Scalars['DateTime']>;
  exclusionDate_gt?: Maybe<Scalars['DateTime']>;
  exclusionDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirAthleteSportOrganizationScalarWhereInput>>;
  OR?: Maybe<Array<DirAthleteSportOrganizationScalarWhereInput>>;
  NOT?: Maybe<Array<DirAthleteSportOrganizationScalarWhereInput>>;
};

export type DirAthleteSportOrganizationUpdateManyWithWhereNestedInput = {
  where: DirAthleteSportOrganizationScalarWhereInput;
  data: DirAthleteSportOrganizationUpdateManyDataInput;
};

export type DirAthleteSportOrganizationUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  trainingStart?: Maybe<Scalars['DateTime']>;
  exclusionDate?: Maybe<Scalars['DateTime']>;
};

export type DirAthleteSportRankAndAwardUpdateManyWithoutSportInput = {
  create?: Maybe<Array<DirAthleteSportRankAndAwardCreateWithoutSportInput>>;
  delete?: Maybe<Array<DirAthleteSportRankAndAwardWhereUniqueInput>>;
  connect?: Maybe<Array<DirAthleteSportRankAndAwardWhereUniqueInput>>;
  set?: Maybe<Array<DirAthleteSportRankAndAwardWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirAthleteSportRankAndAwardWhereUniqueInput>>;
  update?: Maybe<Array<DirAthleteSportRankAndAwardUpdateWithWhereUniqueWithoutSportInput>>;
  upsert?: Maybe<Array<DirAthleteSportRankAndAwardUpsertWithWhereUniqueWithoutSportInput>>;
  deleteMany?: Maybe<Array<DirAthleteSportRankAndAwardScalarWhereInput>>;
  updateMany?: Maybe<Array<DirAthleteSportRankAndAwardUpdateManyWithWhereNestedInput>>;
};

export type DirAthleteSportRankAndAwardUpdateWithWhereUniqueWithoutSportInput = {
  where: DirAthleteSportRankAndAwardWhereUniqueInput;
  data: DirAthleteSportRankAndAwardUpdateWithoutSportDataInput;
};

export type DirAthleteSportRankAndAwardUpdateWithoutSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsRankAndAward?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  confirmDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  dirDocument?: Maybe<DirDocumentUpdateOneInput>;
};

export type DirAthleteSportRankAndAwardUpsertWithWhereUniqueWithoutSportInput = {
  where: DirAthleteSportRankAndAwardWhereUniqueInput;
  update: DirAthleteSportRankAndAwardUpdateWithoutSportDataInput;
  create: DirAthleteSportRankAndAwardCreateWithoutSportInput;
};

export type DirAthleteSportRankAndAwardScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  assignmentDate_not?: Maybe<Scalars['DateTime']>;
  assignmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_lt?: Maybe<Scalars['DateTime']>;
  assignmentDate_lte?: Maybe<Scalars['DateTime']>;
  assignmentDate_gt?: Maybe<Scalars['DateTime']>;
  assignmentDate_gte?: Maybe<Scalars['DateTime']>;
  confirmDate?: Maybe<Scalars['DateTime']>;
  confirmDate_not?: Maybe<Scalars['DateTime']>;
  confirmDate_in?: Maybe<Array<Scalars['DateTime']>>;
  confirmDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  confirmDate_lt?: Maybe<Scalars['DateTime']>;
  confirmDate_lte?: Maybe<Scalars['DateTime']>;
  confirmDate_gt?: Maybe<Scalars['DateTime']>;
  confirmDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirAthleteSportRankAndAwardScalarWhereInput>>;
  OR?: Maybe<Array<DirAthleteSportRankAndAwardScalarWhereInput>>;
  NOT?: Maybe<Array<DirAthleteSportRankAndAwardScalarWhereInput>>;
};

export type DirAthleteSportRankAndAwardUpdateManyWithWhereNestedInput = {
  where: DirAthleteSportRankAndAwardScalarWhereInput;
  data: DirAthleteSportRankAndAwardUpdateManyDataInput;
};

export type DirAthleteSportRankAndAwardUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  confirmDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type DirAthleteSportUpsertWithWhereUniqueNestedInput = {
  where: DirAthleteSportWhereUniqueInput;
  update: DirAthleteSportUpdateDataInput;
  create: DirAthleteSportCreateInput;
};

export type DirAthleteSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  role_not?: Maybe<Scalars['String']>;
  role_in?: Maybe<Array<Scalars['String']>>;
  role_not_in?: Maybe<Array<Scalars['String']>>;
  role_lt?: Maybe<Scalars['String']>;
  role_lte?: Maybe<Scalars['String']>;
  role_gt?: Maybe<Scalars['String']>;
  role_gte?: Maybe<Scalars['String']>;
  role_contains?: Maybe<Scalars['String']>;
  role_not_contains?: Maybe<Scalars['String']>;
  role_starts_with?: Maybe<Scalars['String']>;
  role_not_starts_with?: Maybe<Scalars['String']>;
  role_ends_with?: Maybe<Scalars['String']>;
  role_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirAthleteSportScalarWhereInput>>;
  OR?: Maybe<Array<DirAthleteSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirAthleteSportScalarWhereInput>>;
};

export type DirAthleteSportUpdateManyWithWhereNestedInput = {
  where: DirAthleteSportScalarWhereInput;
  data: DirAthleteSportUpdateManyDataInput;
};

export type DirAthleteSportUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
};

export type DirAthleteMedCertUpdateManyWithoutDirAthleteInput = {
  create?: Maybe<Array<DirAthleteMedCertCreateWithoutDirAthleteInput>>;
  delete?: Maybe<Array<DirAthleteMedCertWhereUniqueInput>>;
  connect?: Maybe<Array<DirAthleteMedCertWhereUniqueInput>>;
  set?: Maybe<Array<DirAthleteMedCertWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirAthleteMedCertWhereUniqueInput>>;
  update?: Maybe<Array<DirAthleteMedCertUpdateWithWhereUniqueWithoutDirAthleteInput>>;
  upsert?: Maybe<Array<DirAthleteMedCertUpsertWithWhereUniqueWithoutDirAthleteInput>>;
  deleteMany?: Maybe<Array<DirAthleteMedCertScalarWhereInput>>;
  updateMany?: Maybe<Array<DirAthleteMedCertUpdateManyWithWhereNestedInput>>;
};

export type DirAthleteMedCertUpdateWithWhereUniqueWithoutDirAthleteInput = {
  where: DirAthleteMedCertWhereUniqueInput;
  data: DirAthleteMedCertUpdateWithoutDirAthleteDataInput;
};

export type DirAthleteMedCertUpdateWithoutDirAthleteDataInput = {
  updatedAtReal?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  sports?: Maybe<DirAthleteMedCertSportUpdateManyWithoutMedCertInput>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteMedCertSportUpdateManyWithoutMedCertInput = {
  create?: Maybe<Array<DirAthleteMedCertSportCreateWithoutMedCertInput>>;
  delete?: Maybe<Array<DirAthleteMedCertSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirAthleteMedCertSportWhereUniqueInput>>;
  set?: Maybe<Array<DirAthleteMedCertSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirAthleteMedCertSportWhereUniqueInput>>;
  update?: Maybe<Array<DirAthleteMedCertSportUpdateWithWhereUniqueWithoutMedCertInput>>;
  upsert?: Maybe<Array<DirAthleteMedCertSportUpsertWithWhereUniqueWithoutMedCertInput>>;
  deleteMany?: Maybe<Array<DirAthleteMedCertSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirAthleteMedCertSportUpdateManyWithWhereNestedInput>>;
};

export type DirAthleteMedCertSportUpdateWithWhereUniqueWithoutMedCertInput = {
  where: DirAthleteMedCertSportWhereUniqueInput;
  data: DirAthleteMedCertSportUpdateWithoutMedCertDataInput;
};

export type DirAthleteMedCertSportUpdateWithoutMedCertDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  clsTrainingStage?: Maybe<ClassifierValueUpdateOneInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirAthleteMedCertSportUpsertWithWhereUniqueWithoutMedCertInput = {
  where: DirAthleteMedCertSportWhereUniqueInput;
  update: DirAthleteMedCertSportUpdateWithoutMedCertDataInput;
  create: DirAthleteMedCertSportCreateWithoutMedCertInput;
};

export type DirAthleteMedCertSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirAthleteMedCertSportScalarWhereInput>>;
  OR?: Maybe<Array<DirAthleteMedCertSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirAthleteMedCertSportScalarWhereInput>>;
};

export type DirAthleteMedCertSportUpdateManyWithWhereNestedInput = {
  where: DirAthleteMedCertSportScalarWhereInput;
  data: DirAthleteMedCertSportUpdateManyDataInput;
};

export type DirAthleteMedCertSportUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteMedCertUpsertWithWhereUniqueWithoutDirAthleteInput = {
  where: DirAthleteMedCertWhereUniqueInput;
  update: DirAthleteMedCertUpdateWithoutDirAthleteDataInput;
  create: DirAthleteMedCertCreateWithoutDirAthleteInput;
};

export type DirAthleteMedCertScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAtReal?: Maybe<Scalars['DateTime']>;
  updatedAtReal_not?: Maybe<Scalars['DateTime']>;
  updatedAtReal_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAtReal_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAtReal_lt?: Maybe<Scalars['DateTime']>;
  updatedAtReal_lte?: Maybe<Scalars['DateTime']>;
  updatedAtReal_gt?: Maybe<Scalars['DateTime']>;
  updatedAtReal_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  displayNumber_not?: Maybe<Scalars['String']>;
  displayNumber_in?: Maybe<Array<Scalars['String']>>;
  displayNumber_not_in?: Maybe<Array<Scalars['String']>>;
  displayNumber_lt?: Maybe<Scalars['String']>;
  displayNumber_lte?: Maybe<Scalars['String']>;
  displayNumber_gt?: Maybe<Scalars['String']>;
  displayNumber_gte?: Maybe<Scalars['String']>;
  displayNumber_contains?: Maybe<Scalars['String']>;
  displayNumber_not_contains?: Maybe<Scalars['String']>;
  displayNumber_starts_with?: Maybe<Scalars['String']>;
  displayNumber_not_starts_with?: Maybe<Scalars['String']>;
  displayNumber_ends_with?: Maybe<Scalars['String']>;
  displayNumber_not_ends_with?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  issueDate_not?: Maybe<Scalars['DateTime']>;
  issueDate_in?: Maybe<Array<Scalars['DateTime']>>;
  issueDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  issueDate_lt?: Maybe<Scalars['DateTime']>;
  issueDate_lte?: Maybe<Scalars['DateTime']>;
  issueDate_gt?: Maybe<Scalars['DateTime']>;
  issueDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationName_not?: Maybe<Scalars['String']>;
  organizationName_in?: Maybe<Array<Scalars['String']>>;
  organizationName_not_in?: Maybe<Array<Scalars['String']>>;
  organizationName_lt?: Maybe<Scalars['String']>;
  organizationName_lte?: Maybe<Scalars['String']>;
  organizationName_gt?: Maybe<Scalars['String']>;
  organizationName_gte?: Maybe<Scalars['String']>;
  organizationName_contains?: Maybe<Scalars['String']>;
  organizationName_not_contains?: Maybe<Scalars['String']>;
  organizationName_starts_with?: Maybe<Scalars['String']>;
  organizationName_not_starts_with?: Maybe<Scalars['String']>;
  organizationName_ends_with?: Maybe<Scalars['String']>;
  organizationName_not_ends_with?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<Scalars['String']>;
  organizationAddress_not?: Maybe<Scalars['String']>;
  organizationAddress_in?: Maybe<Array<Scalars['String']>>;
  organizationAddress_not_in?: Maybe<Array<Scalars['String']>>;
  organizationAddress_lt?: Maybe<Scalars['String']>;
  organizationAddress_lte?: Maybe<Scalars['String']>;
  organizationAddress_gt?: Maybe<Scalars['String']>;
  organizationAddress_gte?: Maybe<Scalars['String']>;
  organizationAddress_contains?: Maybe<Scalars['String']>;
  organizationAddress_not_contains?: Maybe<Scalars['String']>;
  organizationAddress_starts_with?: Maybe<Scalars['String']>;
  organizationAddress_not_starts_with?: Maybe<Scalars['String']>;
  organizationAddress_ends_with?: Maybe<Scalars['String']>;
  organizationAddress_not_ends_with?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationPhone_not?: Maybe<Scalars['String']>;
  organizationPhone_in?: Maybe<Array<Scalars['String']>>;
  organizationPhone_not_in?: Maybe<Array<Scalars['String']>>;
  organizationPhone_lt?: Maybe<Scalars['String']>;
  organizationPhone_lte?: Maybe<Scalars['String']>;
  organizationPhone_gt?: Maybe<Scalars['String']>;
  organizationPhone_gte?: Maybe<Scalars['String']>;
  organizationPhone_contains?: Maybe<Scalars['String']>;
  organizationPhone_not_contains?: Maybe<Scalars['String']>;
  organizationPhone_starts_with?: Maybe<Scalars['String']>;
  organizationPhone_not_starts_with?: Maybe<Scalars['String']>;
  organizationPhone_ends_with?: Maybe<Scalars['String']>;
  organizationPhone_not_ends_with?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  organizationEmail_not?: Maybe<Scalars['String']>;
  organizationEmail_in?: Maybe<Array<Scalars['String']>>;
  organizationEmail_not_in?: Maybe<Array<Scalars['String']>>;
  organizationEmail_lt?: Maybe<Scalars['String']>;
  organizationEmail_lte?: Maybe<Scalars['String']>;
  organizationEmail_gt?: Maybe<Scalars['String']>;
  organizationEmail_gte?: Maybe<Scalars['String']>;
  organizationEmail_contains?: Maybe<Scalars['String']>;
  organizationEmail_not_contains?: Maybe<Scalars['String']>;
  organizationEmail_starts_with?: Maybe<Scalars['String']>;
  organizationEmail_not_starts_with?: Maybe<Scalars['String']>;
  organizationEmail_ends_with?: Maybe<Scalars['String']>;
  organizationEmail_not_ends_with?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  isValid_not?: Maybe<Scalars['Boolean']>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_not?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_in?: Maybe<Array<Scalars['DateTime']>>;
  physicalExaminationDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  physicalExaminationDate_lt?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_lte?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_gt?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_gte?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
  hasRestrictions_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirAthleteMedCertScalarWhereInput>>;
  OR?: Maybe<Array<DirAthleteMedCertScalarWhereInput>>;
  NOT?: Maybe<Array<DirAthleteMedCertScalarWhereInput>>;
};

export type DirAthleteMedCertUpdateManyWithWhereNestedInput = {
  where: DirAthleteMedCertScalarWhereInput;
  data: DirAthleteMedCertUpdateManyDataInput;
};

export type DirAthleteMedCertUpdateManyDataInput = {
  updatedAtReal?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteCompetitionResultUpdateManyWithoutDirAthleteInput = {
  create?: Maybe<Array<DirAthleteCompetitionResultCreateWithoutDirAthleteInput>>;
  delete?: Maybe<Array<DirAthleteCompetitionResultWhereUniqueInput>>;
  connect?: Maybe<Array<DirAthleteCompetitionResultWhereUniqueInput>>;
  set?: Maybe<Array<DirAthleteCompetitionResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirAthleteCompetitionResultWhereUniqueInput>>;
  update?: Maybe<Array<DirAthleteCompetitionResultUpdateWithWhereUniqueWithoutDirAthleteInput>>;
  upsert?: Maybe<Array<DirAthleteCompetitionResultUpsertWithWhereUniqueWithoutDirAthleteInput>>;
  deleteMany?: Maybe<Array<DirAthleteCompetitionResultScalarWhereInput>>;
  updateMany?: Maybe<Array<DirAthleteCompetitionResultUpdateManyWithWhereNestedInput>>;
};

export type DirAthleteCompetitionResultUpdateWithWhereUniqueWithoutDirAthleteInput = {
  where: DirAthleteCompetitionResultWhereUniqueInput;
  data: DirAthleteCompetitionResultUpdateWithoutDirAthleteDataInput;
};

export type DirAthleteCompetitionResultUpdateWithoutDirAthleteDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  noResultReason?: Maybe<ClassifierValueUpdateOneInput>;
  sportingEventProgramType?: Maybe<DirSportingEventProgramTypeUpdateOneRequiredInput>;
  point?: Maybe<Scalars['Int']>;
  pointTo?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  completedRank?: Maybe<Scalars['String']>;
};

export type DirSportingEventProgramTypeUpdateOneRequiredInput = {
  create?: Maybe<DirSportingEventProgramTypeCreateInput>;
  update?: Maybe<DirSportingEventProgramTypeUpdateDataInput>;
  upsert?: Maybe<DirSportingEventProgramTypeUpsertNestedInput>;
  connect?: Maybe<DirSportingEventProgramTypeWhereUniqueInput>;
};

export type DirSportingEventProgramTypeUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutProgramTypesInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  clsSportCategory?: Maybe<ClassifierValueUpdateOneInput>;
  resultsDocument?: Maybe<DirDocumentUpdateOneInput>;
  resultDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsAgeGroups?: Maybe<ClassifierValueUpdateManyInput>;
  discipline?: Maybe<DirSportDisciplineUpdateOneRequiredInput>;
};

export type DirSportingEventUpdateOneRequiredWithoutProgramTypesInput = {
  create?: Maybe<DirSportingEventCreateWithoutProgramTypesInput>;
  update?: Maybe<DirSportingEventUpdateWithoutProgramTypesDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutProgramTypesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutProgramTypesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportAgeGroupUpdateOneInput = {
  create?: Maybe<DirSportAgeGroupCreateInput>;
  update?: Maybe<DirSportAgeGroupUpdateDataInput>;
  upsert?: Maybe<DirSportAgeGroupUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportAgeGroupWhereUniqueInput>;
};

export type DirSportAgeGroupUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneWithoutAgeGroupsInput>;
  participationRequirements?: Maybe<DirSportAgeGroupParticipationRequirementsUpdateManyWithoutAgeGroupInput>;
};

export type DirSportUpdateOneWithoutAgeGroupsInput = {
  create?: Maybe<DirSportCreateWithoutAgeGroupsInput>;
  update?: Maybe<DirSportUpdateWithoutAgeGroupsDataInput>;
  upsert?: Maybe<DirSportUpsertWithoutAgeGroupsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportUpdateWithoutAgeGroupsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageUpdateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionUpdateManyWithoutSportInput>;
};

export type DirSportUpsertWithoutAgeGroupsInput = {
  update: DirSportUpdateWithoutAgeGroupsDataInput;
  create: DirSportCreateWithoutAgeGroupsInput;
};

export type DirSportAgeGroupUpsertNestedInput = {
  update: DirSportAgeGroupUpdateDataInput;
  create: DirSportAgeGroupCreateInput;
};

export type DirSportingEventUpsertWithoutProgramTypesInput = {
  update: DirSportingEventUpdateWithoutProgramTypesDataInput;
  create: DirSportingEventCreateWithoutProgramTypesInput;
};

export type DirSportingEventProgramTypeUpsertNestedInput = {
  update: DirSportingEventProgramTypeUpdateDataInput;
  create: DirSportingEventProgramTypeCreateInput;
};

export type DirAthleteCompetitionResultUpsertWithWhereUniqueWithoutDirAthleteInput = {
  where: DirAthleteCompetitionResultWhereUniqueInput;
  update: DirAthleteCompetitionResultUpdateWithoutDirAthleteDataInput;
  create: DirAthleteCompetitionResultCreateWithoutDirAthleteInput;
};

export type DirAthleteCompetitionResultScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['Int']>;
  point_not?: Maybe<Scalars['Int']>;
  point_in?: Maybe<Array<Scalars['Int']>>;
  point_not_in?: Maybe<Array<Scalars['Int']>>;
  point_lt?: Maybe<Scalars['Int']>;
  point_lte?: Maybe<Scalars['Int']>;
  point_gt?: Maybe<Scalars['Int']>;
  point_gte?: Maybe<Scalars['Int']>;
  pointTo?: Maybe<Scalars['Int']>;
  pointTo_not?: Maybe<Scalars['Int']>;
  pointTo_in?: Maybe<Array<Scalars['Int']>>;
  pointTo_not_in?: Maybe<Array<Scalars['Int']>>;
  pointTo_lt?: Maybe<Scalars['Int']>;
  pointTo_lte?: Maybe<Scalars['Int']>;
  pointTo_gt?: Maybe<Scalars['Int']>;
  pointTo_gte?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  result_not?: Maybe<Scalars['String']>;
  result_in?: Maybe<Array<Scalars['String']>>;
  result_not_in?: Maybe<Array<Scalars['String']>>;
  result_lt?: Maybe<Scalars['String']>;
  result_lte?: Maybe<Scalars['String']>;
  result_gt?: Maybe<Scalars['String']>;
  result_gte?: Maybe<Scalars['String']>;
  result_contains?: Maybe<Scalars['String']>;
  result_not_contains?: Maybe<Scalars['String']>;
  result_starts_with?: Maybe<Scalars['String']>;
  result_not_starts_with?: Maybe<Scalars['String']>;
  result_ends_with?: Maybe<Scalars['String']>;
  result_not_ends_with?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  score_not?: Maybe<Scalars['String']>;
  score_in?: Maybe<Array<Scalars['String']>>;
  score_not_in?: Maybe<Array<Scalars['String']>>;
  score_lt?: Maybe<Scalars['String']>;
  score_lte?: Maybe<Scalars['String']>;
  score_gt?: Maybe<Scalars['String']>;
  score_gte?: Maybe<Scalars['String']>;
  score_contains?: Maybe<Scalars['String']>;
  score_not_contains?: Maybe<Scalars['String']>;
  score_starts_with?: Maybe<Scalars['String']>;
  score_not_starts_with?: Maybe<Scalars['String']>;
  score_ends_with?: Maybe<Scalars['String']>;
  score_not_ends_with?: Maybe<Scalars['String']>;
  completedRank?: Maybe<Scalars['String']>;
  completedRank_not?: Maybe<Scalars['String']>;
  completedRank_in?: Maybe<Array<Scalars['String']>>;
  completedRank_not_in?: Maybe<Array<Scalars['String']>>;
  completedRank_lt?: Maybe<Scalars['String']>;
  completedRank_lte?: Maybe<Scalars['String']>;
  completedRank_gt?: Maybe<Scalars['String']>;
  completedRank_gte?: Maybe<Scalars['String']>;
  completedRank_contains?: Maybe<Scalars['String']>;
  completedRank_not_contains?: Maybe<Scalars['String']>;
  completedRank_starts_with?: Maybe<Scalars['String']>;
  completedRank_not_starts_with?: Maybe<Scalars['String']>;
  completedRank_ends_with?: Maybe<Scalars['String']>;
  completedRank_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirAthleteCompetitionResultScalarWhereInput>>;
  OR?: Maybe<Array<DirAthleteCompetitionResultScalarWhereInput>>;
  NOT?: Maybe<Array<DirAthleteCompetitionResultScalarWhereInput>>;
};

export type DirAthleteCompetitionResultUpdateManyWithWhereNestedInput = {
  where: DirAthleteCompetitionResultScalarWhereInput;
  data: DirAthleteCompetitionResultUpdateManyDataInput;
};

export type DirAthleteCompetitionResultUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['Int']>;
  pointTo?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  completedRank?: Maybe<Scalars['String']>;
};

export type DirAthleteUpsertNestedInput = {
  update: DirAthleteUpdateDataInput;
  create: DirAthleteCreateInput;
};

export type DirNationalTeamAthleteUpsertWithWhereUniqueWithoutGroupInput = {
  where: DirNationalTeamAthleteWhereUniqueInput;
  update: DirNationalTeamAthleteUpdateWithoutGroupDataInput;
  create: DirNationalTeamAthleteCreateWithoutGroupInput;
};

export type DirNationalTeamAthleteScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  admissionDate?: Maybe<Scalars['DateTime']>;
  admissionDate_not?: Maybe<Scalars['DateTime']>;
  admissionDate_in?: Maybe<Array<Scalars['DateTime']>>;
  admissionDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  admissionDate_lt?: Maybe<Scalars['DateTime']>;
  admissionDate_lte?: Maybe<Scalars['DateTime']>;
  admissionDate_gt?: Maybe<Scalars['DateTime']>;
  admissionDate_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  bestResult?: Maybe<Scalars['String']>;
  bestResult_not?: Maybe<Scalars['String']>;
  bestResult_in?: Maybe<Array<Scalars['String']>>;
  bestResult_not_in?: Maybe<Array<Scalars['String']>>;
  bestResult_lt?: Maybe<Scalars['String']>;
  bestResult_lte?: Maybe<Scalars['String']>;
  bestResult_gt?: Maybe<Scalars['String']>;
  bestResult_gte?: Maybe<Scalars['String']>;
  bestResult_contains?: Maybe<Scalars['String']>;
  bestResult_not_contains?: Maybe<Scalars['String']>;
  bestResult_starts_with?: Maybe<Scalars['String']>;
  bestResult_not_starts_with?: Maybe<Scalars['String']>;
  bestResult_ends_with?: Maybe<Scalars['String']>;
  bestResult_not_ends_with?: Maybe<Scalars['String']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
  entranceStatus_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirNationalTeamAthleteScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamAthleteScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamAthleteScalarWhereInput>>;
};

export type DirNationalTeamAthleteUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamAthleteScalarWhereInput;
  data: DirNationalTeamAthleteUpdateManyDataInput;
};

export type DirNationalTeamAthleteUpdateManyDataInput = {
  admissionDate?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  bestResult?: Maybe<Scalars['String']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamAthleteGroupUpsertWithWhereUniqueWithoutTeamInput = {
  where: DirNationalTeamAthleteGroupWhereUniqueInput;
  update: DirNationalTeamAthleteGroupUpdateWithoutTeamDataInput;
  create: DirNationalTeamAthleteGroupCreateWithoutTeamInput;
};

export type DirNationalTeamAthleteGroupScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirNationalTeamAthleteGroupScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamAthleteGroupScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamAthleteGroupScalarWhereInput>>;
};

export type DirNationalTeamAthleteGroupUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamAthleteGroupScalarWhereInput;
  data: DirNationalTeamAthleteGroupUpdateManyDataInput;
};

export type DirNationalTeamAthleteGroupUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamMemberUpdateManyInput = {
  create?: Maybe<Array<DirNationalTeamMemberCreateInput>>;
  update?: Maybe<Array<DirNationalTeamMemberUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirNationalTeamMemberUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirNationalTeamMemberWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamMemberWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamMemberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamMemberScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamMemberUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamMemberUpdateWithWhereUniqueNestedInput = {
  where: DirNationalTeamMemberWhereUniqueInput;
  data: DirNationalTeamMemberUpdateDataInput;
};

export type DirNationalTeamMemberUpdateDataInput = {
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  clsPosition?: Maybe<ClassifierValueUpdateOneInput>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamMemberUpsertWithWhereUniqueNestedInput = {
  where: DirNationalTeamMemberWhereUniqueInput;
  update: DirNationalTeamMemberUpdateDataInput;
  create: DirNationalTeamMemberCreateInput;
};

export type DirNationalTeamMemberScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  admissionDate?: Maybe<Scalars['DateTime']>;
  admissionDate_not?: Maybe<Scalars['DateTime']>;
  admissionDate_in?: Maybe<Array<Scalars['DateTime']>>;
  admissionDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  admissionDate_lt?: Maybe<Scalars['DateTime']>;
  admissionDate_lte?: Maybe<Scalars['DateTime']>;
  admissionDate_gt?: Maybe<Scalars['DateTime']>;
  admissionDate_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  lastname?: Maybe<Scalars['String']>;
  lastname_not?: Maybe<Scalars['String']>;
  lastname_in?: Maybe<Array<Scalars['String']>>;
  lastname_not_in?: Maybe<Array<Scalars['String']>>;
  lastname_lt?: Maybe<Scalars['String']>;
  lastname_lte?: Maybe<Scalars['String']>;
  lastname_gt?: Maybe<Scalars['String']>;
  lastname_gte?: Maybe<Scalars['String']>;
  lastname_contains?: Maybe<Scalars['String']>;
  lastname_not_contains?: Maybe<Scalars['String']>;
  lastname_starts_with?: Maybe<Scalars['String']>;
  lastname_not_starts_with?: Maybe<Scalars['String']>;
  lastname_ends_with?: Maybe<Scalars['String']>;
  lastname_not_ends_with?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  firstname_not?: Maybe<Scalars['String']>;
  firstname_in?: Maybe<Array<Scalars['String']>>;
  firstname_not_in?: Maybe<Array<Scalars['String']>>;
  firstname_lt?: Maybe<Scalars['String']>;
  firstname_lte?: Maybe<Scalars['String']>;
  firstname_gt?: Maybe<Scalars['String']>;
  firstname_gte?: Maybe<Scalars['String']>;
  firstname_contains?: Maybe<Scalars['String']>;
  firstname_not_contains?: Maybe<Scalars['String']>;
  firstname_starts_with?: Maybe<Scalars['String']>;
  firstname_not_starts_with?: Maybe<Scalars['String']>;
  firstname_ends_with?: Maybe<Scalars['String']>;
  firstname_not_ends_with?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  patronymic_not?: Maybe<Scalars['String']>;
  patronymic_in?: Maybe<Array<Scalars['String']>>;
  patronymic_not_in?: Maybe<Array<Scalars['String']>>;
  patronymic_lt?: Maybe<Scalars['String']>;
  patronymic_lte?: Maybe<Scalars['String']>;
  patronymic_gt?: Maybe<Scalars['String']>;
  patronymic_gte?: Maybe<Scalars['String']>;
  patronymic_contains?: Maybe<Scalars['String']>;
  patronymic_not_contains?: Maybe<Scalars['String']>;
  patronymic_starts_with?: Maybe<Scalars['String']>;
  patronymic_not_starts_with?: Maybe<Scalars['String']>;
  patronymic_ends_with?: Maybe<Scalars['String']>;
  patronymic_not_ends_with?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  birthday_not?: Maybe<Scalars['DateTime']>;
  birthday_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_not_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_lt?: Maybe<Scalars['DateTime']>;
  birthday_lte?: Maybe<Scalars['DateTime']>;
  birthday_gt?: Maybe<Scalars['DateTime']>;
  birthday_gte?: Maybe<Scalars['DateTime']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
  entranceStatus_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirNationalTeamMemberScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamMemberScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamMemberScalarWhereInput>>;
};

export type DirNationalTeamMemberUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamMemberScalarWhereInput;
  data: DirNationalTeamMemberUpdateManyDataInput;
};

export type DirNationalTeamMemberUpdateManyDataInput = {
  admissionDate?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamEmailRecipientUpdateManyWithoutTeamInput = {
  create?: Maybe<Array<DirNationalTeamEmailRecipientCreateWithoutTeamInput>>;
  delete?: Maybe<Array<DirNationalTeamEmailRecipientWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamEmailRecipientWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamEmailRecipientWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamEmailRecipientWhereUniqueInput>>;
  update?: Maybe<Array<DirNationalTeamEmailRecipientUpdateWithWhereUniqueWithoutTeamInput>>;
  upsert?: Maybe<Array<DirNationalTeamEmailRecipientUpsertWithWhereUniqueWithoutTeamInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamEmailRecipientScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamEmailRecipientUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamEmailRecipientUpdateWithWhereUniqueWithoutTeamInput = {
  where: DirNationalTeamEmailRecipientWhereUniqueInput;
  data: DirNationalTeamEmailRecipientUpdateWithoutTeamDataInput;
};

export type DirNationalTeamEmailRecipientUpdateWithoutTeamDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  dirPerson?: Maybe<DirPersonUpdateOneWithoutNationalTeamEmailRecipientsInput>;
  comment?: Maybe<Scalars['String']>;
};

export type DirPersonUpdateOneWithoutNationalTeamEmailRecipientsInput = {
  create?: Maybe<DirPersonCreateWithoutNationalTeamEmailRecipientsInput>;
  update?: Maybe<DirPersonUpdateWithoutNationalTeamEmailRecipientsDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutNationalTeamEmailRecipientsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutNationalTeamEmailRecipientsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type DirPersonUpsertWithoutNationalTeamEmailRecipientsInput = {
  update: DirPersonUpdateWithoutNationalTeamEmailRecipientsDataInput;
  create: DirPersonCreateWithoutNationalTeamEmailRecipientsInput;
};

export type DirNationalTeamEmailRecipientUpsertWithWhereUniqueWithoutTeamInput = {
  where: DirNationalTeamEmailRecipientWhereUniqueInput;
  update: DirNationalTeamEmailRecipientUpdateWithoutTeamDataInput;
  create: DirNationalTeamEmailRecipientCreateWithoutTeamInput;
};

export type DirNationalTeamEmailRecipientScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotified_not?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
  isNotificationRequired_not?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirNationalTeamEmailRecipientScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamEmailRecipientScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamEmailRecipientScalarWhereInput>>;
};

export type DirNationalTeamEmailRecipientUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamEmailRecipientScalarWhereInput;
  data: DirNationalTeamEmailRecipientUpdateManyDataInput;
};

export type DirNationalTeamEmailRecipientUpdateManyDataInput = {
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type DirOrganizationUpdateOneInput = {
  create?: Maybe<DirOrganizationCreateInput>;
  update?: Maybe<DirOrganizationUpdateDataInput>;
  upsert?: Maybe<DirOrganizationUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirNationalTeamUpsertNestedInput = {
  update: DirNationalTeamUpdateDataInput;
  create: DirNationalTeamCreateInput;
};

export type DirSportingEventAthleteCallNotificationTeamUpsertWithWhereUniqueWithoutNotificationInput = {
  where: DirSportingEventAthleteCallNotificationTeamWhereUniqueInput;
  update: DirSportingEventAthleteCallNotificationTeamUpdateWithoutNotificationDataInput;
  create: DirSportingEventAthleteCallNotificationTeamCreateWithoutNotificationInput;
};

export type DirSportingEventAthleteCallNotificationTeamScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotified_not?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
  isNotificationRequired_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventAthleteCallNotificationTeamScalarWhereInput>>;
};

export type DirSportingEventAthleteCallNotificationTeamUpdateManyWithWhereNestedInput = {
  where: DirSportingEventAthleteCallNotificationTeamScalarWhereInput;
  data: DirSportingEventAthleteCallNotificationTeamUpdateManyDataInput;
};

export type DirSportingEventAthleteCallNotificationTeamUpdateManyDataInput = {
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventAthleteCallNotificationUpsertWithWhereUniqueWithoutDirSportingEventInput = {
  where: DirSportingEventAthleteCallNotificationWhereUniqueInput;
  update: DirSportingEventAthleteCallNotificationUpdateWithoutDirSportingEventDataInput;
  create: DirSportingEventAthleteCallNotificationCreateWithoutDirSportingEventInput;
};

export type DirSportingEventAthleteCallNotificationScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirSportingEventAthleteCallNotificationScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventAthleteCallNotificationScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventAthleteCallNotificationScalarWhereInput>>;
};

export type DirSportingEventUpsertWithWhereUniqueWithoutProxyInput = {
  where: DirSportingEventWhereUniqueInput;
  update: DirSportingEventUpdateWithoutProxyDataInput;
  create: DirSportingEventCreateWithoutProxyInput;
};

export type DirSportingEventScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber_not?: Maybe<Scalars['Int']>;
  oldRegistryNumber_in?: Maybe<Array<Scalars['Int']>>;
  oldRegistryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  oldRegistryNumber_lt?: Maybe<Scalars['Int']>;
  oldRegistryNumber_lte?: Maybe<Scalars['Int']>;
  oldRegistryNumber_gt?: Maybe<Scalars['Int']>;
  oldRegistryNumber_gte?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification_not?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification_in?: Maybe<Array<Scalars['Int']>>;
  athletesQuotaNotCorrespondingByQualification_not_in?: Maybe<Array<Scalars['Int']>>;
  athletesQuotaNotCorrespondingByQualification_lt?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification_lte?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification_gt?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  venue_not?: Maybe<Scalars['String']>;
  venue_in?: Maybe<Array<Scalars['String']>>;
  venue_not_in?: Maybe<Array<Scalars['String']>>;
  venue_lt?: Maybe<Scalars['String']>;
  venue_lte?: Maybe<Scalars['String']>;
  venue_gt?: Maybe<Scalars['String']>;
  venue_gte?: Maybe<Scalars['String']>;
  venue_contains?: Maybe<Scalars['String']>;
  venue_not_contains?: Maybe<Scalars['String']>;
  venue_starts_with?: Maybe<Scalars['String']>;
  venue_not_starts_with?: Maybe<Scalars['String']>;
  venue_ends_with?: Maybe<Scalars['String']>;
  venue_not_ends_with?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  object_not?: Maybe<Scalars['String']>;
  object_in?: Maybe<Array<Scalars['String']>>;
  object_not_in?: Maybe<Array<Scalars['String']>>;
  object_lt?: Maybe<Scalars['String']>;
  object_lte?: Maybe<Scalars['String']>;
  object_gt?: Maybe<Scalars['String']>;
  object_gte?: Maybe<Scalars['String']>;
  object_contains?: Maybe<Scalars['String']>;
  object_not_contains?: Maybe<Scalars['String']>;
  object_starts_with?: Maybe<Scalars['String']>;
  object_not_starts_with?: Maybe<Scalars['String']>;
  object_ends_with?: Maybe<Scalars['String']>;
  object_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  properNoun_not?: Maybe<Scalars['String']>;
  properNoun_in?: Maybe<Array<Scalars['String']>>;
  properNoun_not_in?: Maybe<Array<Scalars['String']>>;
  properNoun_lt?: Maybe<Scalars['String']>;
  properNoun_lte?: Maybe<Scalars['String']>;
  properNoun_gt?: Maybe<Scalars['String']>;
  properNoun_gte?: Maybe<Scalars['String']>;
  properNoun_contains?: Maybe<Scalars['String']>;
  properNoun_not_contains?: Maybe<Scalars['String']>;
  properNoun_starts_with?: Maybe<Scalars['String']>;
  properNoun_not_starts_with?: Maybe<Scalars['String']>;
  properNoun_ends_with?: Maybe<Scalars['String']>;
  properNoun_not_ends_with?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  ageGroup_not?: Maybe<Scalars['String']>;
  ageGroup_in?: Maybe<Array<Scalars['String']>>;
  ageGroup_not_in?: Maybe<Array<Scalars['String']>>;
  ageGroup_lt?: Maybe<Scalars['String']>;
  ageGroup_lte?: Maybe<Scalars['String']>;
  ageGroup_gt?: Maybe<Scalars['String']>;
  ageGroup_gte?: Maybe<Scalars['String']>;
  ageGroup_contains?: Maybe<Scalars['String']>;
  ageGroup_not_contains?: Maybe<Scalars['String']>;
  ageGroup_starts_with?: Maybe<Scalars['String']>;
  ageGroup_not_starts_with?: Maybe<Scalars['String']>;
  ageGroup_ends_with?: Maybe<Scalars['String']>;
  ageGroup_not_ends_with?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_not?: Maybe<Scalars['DateTime']>;
  startDate_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_lt?: Maybe<Scalars['DateTime']>;
  startDate_lte?: Maybe<Scalars['DateTime']>;
  startDate_gt?: Maybe<Scalars['DateTime']>;
  startDate_gte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_not?: Maybe<Scalars['DateTime']>;
  endDate_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_lt?: Maybe<Scalars['DateTime']>;
  endDate_lte?: Maybe<Scalars['DateTime']>;
  endDate_gt?: Maybe<Scalars['DateTime']>;
  endDate_gte?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  minManAge_not?: Maybe<Scalars['Int']>;
  minManAge_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_lt?: Maybe<Scalars['Int']>;
  minManAge_lte?: Maybe<Scalars['Int']>;
  minManAge_gt?: Maybe<Scalars['Int']>;
  minManAge_gte?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  maxManAge_not?: Maybe<Scalars['Int']>;
  maxManAge_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_lt?: Maybe<Scalars['Int']>;
  maxManAge_lte?: Maybe<Scalars['Int']>;
  maxManAge_gt?: Maybe<Scalars['Int']>;
  maxManAge_gte?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  minManBirthYear_not?: Maybe<Scalars['Int']>;
  minManBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  minManBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  minManBirthYear_lt?: Maybe<Scalars['Int']>;
  minManBirthYear_lte?: Maybe<Scalars['Int']>;
  minManBirthYear_gt?: Maybe<Scalars['Int']>;
  minManBirthYear_gte?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear_not?: Maybe<Scalars['Int']>;
  maxManBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  maxManBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  maxManBirthYear_lt?: Maybe<Scalars['Int']>;
  maxManBirthYear_lte?: Maybe<Scalars['Int']>;
  maxManBirthYear_gt?: Maybe<Scalars['Int']>;
  maxManBirthYear_gte?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  minWomanAge_not?: Maybe<Scalars['Int']>;
  minWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_lt?: Maybe<Scalars['Int']>;
  minWomanAge_lte?: Maybe<Scalars['Int']>;
  minWomanAge_gt?: Maybe<Scalars['Int']>;
  minWomanAge_gte?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge_not?: Maybe<Scalars['Int']>;
  maxWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_lt?: Maybe<Scalars['Int']>;
  maxWomanAge_lte?: Maybe<Scalars['Int']>;
  maxWomanAge_gt?: Maybe<Scalars['Int']>;
  maxWomanAge_gte?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  minWomanBirthYear_not?: Maybe<Scalars['Int']>;
  minWomanBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  minWomanBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  minWomanBirthYear_lt?: Maybe<Scalars['Int']>;
  minWomanBirthYear_lte?: Maybe<Scalars['Int']>;
  minWomanBirthYear_gt?: Maybe<Scalars['Int']>;
  minWomanBirthYear_gte?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_not?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanBirthYear_lt?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_lte?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_gt?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_gte?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  membersQuota_not?: Maybe<Scalars['Int']>;
  membersQuota_in?: Maybe<Array<Scalars['Int']>>;
  membersQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  membersQuota_lt?: Maybe<Scalars['Int']>;
  membersQuota_lte?: Maybe<Scalars['Int']>;
  membersQuota_gt?: Maybe<Scalars['Int']>;
  membersQuota_gte?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  refereesQuota_not?: Maybe<Scalars['Int']>;
  refereesQuota_in?: Maybe<Array<Scalars['Int']>>;
  refereesQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  refereesQuota_lt?: Maybe<Scalars['Int']>;
  refereesQuota_lte?: Maybe<Scalars['Int']>;
  refereesQuota_gt?: Maybe<Scalars['Int']>;
  refereesQuota_gte?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_not?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_in?: Maybe<Array<Scalars['Int']>>;
  nonresidentRefereesQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  nonresidentRefereesQuota_lt?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_lte?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_gt?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_gte?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols_not?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols_in?: Maybe<Array<Scalars['Int']>>;
  daysBeforeStartToAddProtocols_not_in?: Maybe<Array<Scalars['Int']>>;
  daysBeforeStartToAddProtocols_lt?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols_lte?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols_gt?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols_gte?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount_not?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount_in?: Maybe<Array<Scalars['Int']>>;
  athletesTrainersRefereesSpecialistsCount_not_in?: Maybe<Array<Scalars['Int']>>;
  athletesTrainersRefereesSpecialistsCount_lt?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount_lte?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount_gt?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount_gte?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  ekp_not?: Maybe<Scalars['String']>;
  ekp_in?: Maybe<Array<Scalars['String']>>;
  ekp_not_in?: Maybe<Array<Scalars['String']>>;
  ekp_lt?: Maybe<Scalars['String']>;
  ekp_lte?: Maybe<Scalars['String']>;
  ekp_gt?: Maybe<Scalars['String']>;
  ekp_gte?: Maybe<Scalars['String']>;
  ekp_contains?: Maybe<Scalars['String']>;
  ekp_not_contains?: Maybe<Scalars['String']>;
  ekp_starts_with?: Maybe<Scalars['String']>;
  ekp_not_starts_with?: Maybe<Scalars['String']>;
  ekp_ends_with?: Maybe<Scalars['String']>;
  ekp_not_ends_with?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByAge_not?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByAge_in?: Maybe<Array<Scalars['Int']>>;
  athletesQuotaNotCorrespondingByAge_not_in?: Maybe<Array<Scalars['Int']>>;
  athletesQuotaNotCorrespondingByAge_lt?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByAge_lte?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByAge_gt?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByAge_gte?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  federalBudgetFinancing_not?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities_not?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  isRussia_not?: Maybe<Scalars['Boolean']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isImportant_not?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation_not?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant_not?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isAgeRestricted_not?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventScalarWhereInput>>;
};

export type DirSportingEventUpdateManyWithWhereNestedInput = {
  where: DirSportingEventScalarWhereInput;
  data: DirSportingEventUpdateManyDataInput;
};

export type DirSportingEventUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventUpsertWithWhereUniqueWithoutParentInput = {
  where: DirSportingEventWhereUniqueInput;
  update: DirSportingEventUpdateWithoutParentDataInput;
  create: DirSportingEventCreateWithoutParentInput;
};

export type DirSportingEventUpsertWithoutChangesInput = {
  update: DirSportingEventUpdateWithoutChangesDataInput;
  create: DirSportingEventCreateWithoutChangesInput;
};

export type DirSportingEventUpsertWithoutChildrenInput = {
  update: DirSportingEventUpdateWithoutChildrenDataInput;
  create: DirSportingEventCreateWithoutChildrenInput;
};

export type DirSportingEventUpsertWithoutDirNationalTeamsInput = {
  update: DirSportingEventUpdateWithoutDirNationalTeamsDataInput;
  create: DirSportingEventCreateWithoutDirNationalTeamsInput;
};

export type DirNationalTeamUpsertWithoutEmailRecipientsInput = {
  update: DirNationalTeamUpdateWithoutEmailRecipientsDataInput;
  create: DirNationalTeamCreateWithoutEmailRecipientsInput;
};

export type DirNationalTeamEmailRecipientUpsertWithWhereUniqueWithoutDirPersonInput = {
  where: DirNationalTeamEmailRecipientWhereUniqueInput;
  update: DirNationalTeamEmailRecipientUpdateWithoutDirPersonDataInput;
  create: DirNationalTeamEmailRecipientCreateWithoutDirPersonInput;
};

export type DirPersonUpsertWithoutMergeTasksAsDuplicateInput = {
  update: DirPersonUpdateWithoutMergeTasksAsDuplicateDataInput;
  create: DirPersonCreateWithoutMergeTasksAsDuplicateInput;
};

export type DirPersonMergeTaskUpsertWithWhereUniqueWithoutPrimaryInput = {
  where: DirPersonMergeTaskWhereUniqueInput;
  update: DirPersonMergeTaskUpdateWithoutPrimaryDataInput;
  create: DirPersonMergeTaskCreateWithoutPrimaryInput;
};

export type DirPersonUpsertNestedInput = {
  update: DirPersonUpdateDataInput;
  create: DirPersonCreateInput;
};

export type DirAthleteUpsertWithoutDirPersonInput = {
  update: DirAthleteUpdateWithoutDirPersonDataInput;
  create: DirAthleteCreateWithoutDirPersonInput;
};

export type DirPersonUpsertWithWhereUniqueWithoutPrimaryInput = {
  where: DirPersonWhereUniqueInput;
  update: DirPersonUpdateWithoutPrimaryDataInput;
  create: DirPersonCreateWithoutPrimaryInput;
};

export type DirPersonScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  mergedAt_not?: Maybe<Scalars['DateTime']>;
  mergedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  mergedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  mergedAt_lt?: Maybe<Scalars['DateTime']>;
  mergedAt_lte?: Maybe<Scalars['DateTime']>;
  mergedAt_gt?: Maybe<Scalars['DateTime']>;
  mergedAt_gte?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  lastname_not?: Maybe<Scalars['String']>;
  lastname_in?: Maybe<Array<Scalars['String']>>;
  lastname_not_in?: Maybe<Array<Scalars['String']>>;
  lastname_lt?: Maybe<Scalars['String']>;
  lastname_lte?: Maybe<Scalars['String']>;
  lastname_gt?: Maybe<Scalars['String']>;
  lastname_gte?: Maybe<Scalars['String']>;
  lastname_contains?: Maybe<Scalars['String']>;
  lastname_not_contains?: Maybe<Scalars['String']>;
  lastname_starts_with?: Maybe<Scalars['String']>;
  lastname_not_starts_with?: Maybe<Scalars['String']>;
  lastname_ends_with?: Maybe<Scalars['String']>;
  lastname_not_ends_with?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  firstname_not?: Maybe<Scalars['String']>;
  firstname_in?: Maybe<Array<Scalars['String']>>;
  firstname_not_in?: Maybe<Array<Scalars['String']>>;
  firstname_lt?: Maybe<Scalars['String']>;
  firstname_lte?: Maybe<Scalars['String']>;
  firstname_gt?: Maybe<Scalars['String']>;
  firstname_gte?: Maybe<Scalars['String']>;
  firstname_contains?: Maybe<Scalars['String']>;
  firstname_not_contains?: Maybe<Scalars['String']>;
  firstname_starts_with?: Maybe<Scalars['String']>;
  firstname_not_starts_with?: Maybe<Scalars['String']>;
  firstname_ends_with?: Maybe<Scalars['String']>;
  firstname_not_ends_with?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  patronymic_not?: Maybe<Scalars['String']>;
  patronymic_in?: Maybe<Array<Scalars['String']>>;
  patronymic_not_in?: Maybe<Array<Scalars['String']>>;
  patronymic_lt?: Maybe<Scalars['String']>;
  patronymic_lte?: Maybe<Scalars['String']>;
  patronymic_gt?: Maybe<Scalars['String']>;
  patronymic_gte?: Maybe<Scalars['String']>;
  patronymic_contains?: Maybe<Scalars['String']>;
  patronymic_not_contains?: Maybe<Scalars['String']>;
  patronymic_starts_with?: Maybe<Scalars['String']>;
  patronymic_not_starts_with?: Maybe<Scalars['String']>;
  patronymic_ends_with?: Maybe<Scalars['String']>;
  patronymic_not_ends_with?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  previousLastname_not?: Maybe<Scalars['String']>;
  previousLastname_in?: Maybe<Array<Scalars['String']>>;
  previousLastname_not_in?: Maybe<Array<Scalars['String']>>;
  previousLastname_lt?: Maybe<Scalars['String']>;
  previousLastname_lte?: Maybe<Scalars['String']>;
  previousLastname_gt?: Maybe<Scalars['String']>;
  previousLastname_gte?: Maybe<Scalars['String']>;
  previousLastname_contains?: Maybe<Scalars['String']>;
  previousLastname_not_contains?: Maybe<Scalars['String']>;
  previousLastname_starts_with?: Maybe<Scalars['String']>;
  previousLastname_not_starts_with?: Maybe<Scalars['String']>;
  previousLastname_ends_with?: Maybe<Scalars['String']>;
  previousLastname_not_ends_with?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  birthday_not?: Maybe<Scalars['DateTime']>;
  birthday_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_not_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_lt?: Maybe<Scalars['DateTime']>;
  birthday_lte?: Maybe<Scalars['DateTime']>;
  birthday_gt?: Maybe<Scalars['DateTime']>;
  birthday_gte?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  isMale_not?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  snils_not?: Maybe<Scalars['String']>;
  snils_in?: Maybe<Array<Scalars['String']>>;
  snils_not_in?: Maybe<Array<Scalars['String']>>;
  snils_lt?: Maybe<Scalars['String']>;
  snils_lte?: Maybe<Scalars['String']>;
  snils_gt?: Maybe<Scalars['String']>;
  snils_gte?: Maybe<Scalars['String']>;
  snils_contains?: Maybe<Scalars['String']>;
  snils_not_contains?: Maybe<Scalars['String']>;
  snils_starts_with?: Maybe<Scalars['String']>;
  snils_not_starts_with?: Maybe<Scalars['String']>;
  snils_ends_with?: Maybe<Scalars['String']>;
  snils_not_ends_with?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportSerial_not?: Maybe<Scalars['String']>;
  passportSerial_in?: Maybe<Array<Scalars['String']>>;
  passportSerial_not_in?: Maybe<Array<Scalars['String']>>;
  passportSerial_lt?: Maybe<Scalars['String']>;
  passportSerial_lte?: Maybe<Scalars['String']>;
  passportSerial_gt?: Maybe<Scalars['String']>;
  passportSerial_gte?: Maybe<Scalars['String']>;
  passportSerial_contains?: Maybe<Scalars['String']>;
  passportSerial_not_contains?: Maybe<Scalars['String']>;
  passportSerial_starts_with?: Maybe<Scalars['String']>;
  passportSerial_not_starts_with?: Maybe<Scalars['String']>;
  passportSerial_ends_with?: Maybe<Scalars['String']>;
  passportSerial_not_ends_with?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportNumber_not?: Maybe<Scalars['String']>;
  passportNumber_in?: Maybe<Array<Scalars['String']>>;
  passportNumber_not_in?: Maybe<Array<Scalars['String']>>;
  passportNumber_lt?: Maybe<Scalars['String']>;
  passportNumber_lte?: Maybe<Scalars['String']>;
  passportNumber_gt?: Maybe<Scalars['String']>;
  passportNumber_gte?: Maybe<Scalars['String']>;
  passportNumber_contains?: Maybe<Scalars['String']>;
  passportNumber_not_contains?: Maybe<Scalars['String']>;
  passportNumber_starts_with?: Maybe<Scalars['String']>;
  passportNumber_not_starts_with?: Maybe<Scalars['String']>;
  passportNumber_ends_with?: Maybe<Scalars['String']>;
  passportNumber_not_ends_with?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssuedBy_not?: Maybe<Scalars['String']>;
  passportIssuedBy_in?: Maybe<Array<Scalars['String']>>;
  passportIssuedBy_not_in?: Maybe<Array<Scalars['String']>>;
  passportIssuedBy_lt?: Maybe<Scalars['String']>;
  passportIssuedBy_lte?: Maybe<Scalars['String']>;
  passportIssuedBy_gt?: Maybe<Scalars['String']>;
  passportIssuedBy_gte?: Maybe<Scalars['String']>;
  passportIssuedBy_contains?: Maybe<Scalars['String']>;
  passportIssuedBy_not_contains?: Maybe<Scalars['String']>;
  passportIssuedBy_starts_with?: Maybe<Scalars['String']>;
  passportIssuedBy_not_starts_with?: Maybe<Scalars['String']>;
  passportIssuedBy_ends_with?: Maybe<Scalars['String']>;
  passportIssuedBy_not_ends_with?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  passportIssueDate_not?: Maybe<Scalars['DateTime']>;
  passportIssueDate_in?: Maybe<Array<Scalars['DateTime']>>;
  passportIssueDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  passportIssueDate_lt?: Maybe<Scalars['DateTime']>;
  passportIssueDate_lte?: Maybe<Scalars['DateTime']>;
  passportIssueDate_gt?: Maybe<Scalars['DateTime']>;
  passportIssueDate_gte?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  residenceCity_not?: Maybe<Scalars['String']>;
  residenceCity_in?: Maybe<Array<Scalars['String']>>;
  residenceCity_not_in?: Maybe<Array<Scalars['String']>>;
  residenceCity_lt?: Maybe<Scalars['String']>;
  residenceCity_lte?: Maybe<Scalars['String']>;
  residenceCity_gt?: Maybe<Scalars['String']>;
  residenceCity_gte?: Maybe<Scalars['String']>;
  residenceCity_contains?: Maybe<Scalars['String']>;
  residenceCity_not_contains?: Maybe<Scalars['String']>;
  residenceCity_starts_with?: Maybe<Scalars['String']>;
  residenceCity_not_starts_with?: Maybe<Scalars['String']>;
  residenceCity_ends_with?: Maybe<Scalars['String']>;
  residenceCity_not_ends_with?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  isRussia_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirPersonScalarWhereInput>>;
  OR?: Maybe<Array<DirPersonScalarWhereInput>>;
  NOT?: Maybe<Array<DirPersonScalarWhereInput>>;
};

export type DirPersonUpdateManyWithWhereNestedInput = {
  where: DirPersonScalarWhereInput;
  data: DirPersonUpdateManyDataInput;
};

export type DirPersonUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
};

export type DirPersonUpsertWithoutTrainerProfileInput = {
  update: DirPersonUpdateWithoutTrainerProfileDataInput;
  create: DirPersonCreateWithoutTrainerProfileInput;
};

export type DirNationalTeamTrainerUpsertWithWhereUniqueWithoutTeamInput = {
  where: DirNationalTeamTrainerWhereUniqueInput;
  update: DirNationalTeamTrainerUpdateWithoutTeamDataInput;
  create: DirNationalTeamTrainerCreateWithoutTeamInput;
};

export type DirNationalTeamTrainerScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  admissionDate?: Maybe<Scalars['DateTime']>;
  admissionDate_not?: Maybe<Scalars['DateTime']>;
  admissionDate_in?: Maybe<Array<Scalars['DateTime']>>;
  admissionDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  admissionDate_lt?: Maybe<Scalars['DateTime']>;
  admissionDate_lte?: Maybe<Scalars['DateTime']>;
  admissionDate_gt?: Maybe<Scalars['DateTime']>;
  admissionDate_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPrimary_not?: Maybe<Scalars['Boolean']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
  entranceStatus_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirNationalTeamTrainerScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamTrainerScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamTrainerScalarWhereInput>>;
};

export type DirNationalTeamTrainerUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamTrainerScalarWhereInput;
  data: DirNationalTeamTrainerUpdateManyDataInput;
};

export type DirNationalTeamTrainerUpdateManyDataInput = {
  admissionDate?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamUpsertWithWhereUniqueWithoutDirSportingEventInput = {
  where: DirNationalTeamWhereUniqueInput;
  update: DirNationalTeamUpdateWithoutDirSportingEventDataInput;
  create: DirNationalTeamCreateWithoutDirSportingEventInput;
};

export type DirNationalTeamScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  manTeamQuota_not?: Maybe<Scalars['Int']>;
  manTeamQuota_in?: Maybe<Array<Scalars['Int']>>;
  manTeamQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  manTeamQuota_lt?: Maybe<Scalars['Int']>;
  manTeamQuota_lte?: Maybe<Scalars['Int']>;
  manTeamQuota_gt?: Maybe<Scalars['Int']>;
  manTeamQuota_gte?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota_not?: Maybe<Scalars['Int']>;
  womanTeamQuota_in?: Maybe<Array<Scalars['Int']>>;
  womanTeamQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  womanTeamQuota_lt?: Maybe<Scalars['Int']>;
  womanTeamQuota_lte?: Maybe<Scalars['Int']>;
  womanTeamQuota_gt?: Maybe<Scalars['Int']>;
  womanTeamQuota_gte?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota_not?: Maybe<Scalars['Int']>;
  supportTeamQuota_in?: Maybe<Array<Scalars['Int']>>;
  supportTeamQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  supportTeamQuota_lt?: Maybe<Scalars['Int']>;
  supportTeamQuota_lte?: Maybe<Scalars['Int']>;
  supportTeamQuota_gt?: Maybe<Scalars['Int']>;
  supportTeamQuota_gte?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  athletesCall_not?: Maybe<Scalars['String']>;
  athletesCall_in?: Maybe<Array<Scalars['String']>>;
  athletesCall_not_in?: Maybe<Array<Scalars['String']>>;
  athletesCall_lt?: Maybe<Scalars['String']>;
  athletesCall_lte?: Maybe<Scalars['String']>;
  athletesCall_gt?: Maybe<Scalars['String']>;
  athletesCall_gte?: Maybe<Scalars['String']>;
  athletesCall_contains?: Maybe<Scalars['String']>;
  athletesCall_not_contains?: Maybe<Scalars['String']>;
  athletesCall_starts_with?: Maybe<Scalars['String']>;
  athletesCall_not_starts_with?: Maybe<Scalars['String']>;
  athletesCall_ends_with?: Maybe<Scalars['String']>;
  athletesCall_not_ends_with?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  point_not?: Maybe<Scalars['Int']>;
  point_in?: Maybe<Array<Scalars['Int']>>;
  point_not_in?: Maybe<Array<Scalars['Int']>>;
  point_lt?: Maybe<Scalars['Int']>;
  point_lte?: Maybe<Scalars['Int']>;
  point_gt?: Maybe<Scalars['Int']>;
  point_gte?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  score_not?: Maybe<Scalars['Int']>;
  score_in?: Maybe<Array<Scalars['Int']>>;
  score_not_in?: Maybe<Array<Scalars['Int']>>;
  score_lt?: Maybe<Scalars['Int']>;
  score_lte?: Maybe<Scalars['Int']>;
  score_gt?: Maybe<Scalars['Int']>;
  score_gte?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotified_not?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender_not?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  athleteTotalQuota_not?: Maybe<Scalars['Int']>;
  athleteTotalQuota_in?: Maybe<Array<Scalars['Int']>>;
  athleteTotalQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  athleteTotalQuota_lt?: Maybe<Scalars['Int']>;
  athleteTotalQuota_lte?: Maybe<Scalars['Int']>;
  athleteTotalQuota_gt?: Maybe<Scalars['Int']>;
  athleteTotalQuota_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirNationalTeamScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamScalarWhereInput>>;
};

export type DirNationalTeamUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamScalarWhereInput;
  data: DirNationalTeamUpdateManyDataInput;
};

export type DirNationalTeamUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
};

export type DirSportingEventUpsertWithWhereUniqueNestedInput = {
  where: DirSportingEventWhereUniqueInput;
  update: DirSportingEventUpdateDataInput;
  create: DirSportingEventCreateInput;
};

export type DirCalendarUpdateManyInput = {
  create?: Maybe<Array<DirCalendarCreateInput>>;
  update?: Maybe<Array<DirCalendarUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirCalendarUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirCalendarWhereUniqueInput>>;
  connect?: Maybe<Array<DirCalendarWhereUniqueInput>>;
  set?: Maybe<Array<DirCalendarWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirCalendarWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirCalendarScalarWhereInput>>;
  updateMany?: Maybe<Array<DirCalendarUpdateManyWithWhereNestedInput>>;
};

export type DirCalendarUpdateWithWhereUniqueNestedInput = {
  where: DirCalendarWhereUniqueInput;
  data: DirCalendarUpdateDataInput;
};

export type DirCalendarUpsertWithWhereUniqueNestedInput = {
  where: DirCalendarWhereUniqueInput;
  update: DirCalendarUpdateDataInput;
  create: DirCalendarCreateInput;
};

export type DirCalendarScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_not?: Maybe<Scalars['DateTime']>;
  startDate_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_lt?: Maybe<Scalars['DateTime']>;
  startDate_lte?: Maybe<Scalars['DateTime']>;
  startDate_gt?: Maybe<Scalars['DateTime']>;
  startDate_gte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_not?: Maybe<Scalars['DateTime']>;
  endDate_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_lt?: Maybe<Scalars['DateTime']>;
  endDate_lte?: Maybe<Scalars['DateTime']>;
  endDate_gt?: Maybe<Scalars['DateTime']>;
  endDate_gte?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
  year_not?: Maybe<Scalars['Int']>;
  year_in?: Maybe<Array<Scalars['Int']>>;
  year_not_in?: Maybe<Array<Scalars['Int']>>;
  year_lt?: Maybe<Scalars['Int']>;
  year_lte?: Maybe<Scalars['Int']>;
  year_gt?: Maybe<Scalars['Int']>;
  year_gte?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  ekpMinsportNumber_not?: Maybe<Scalars['String']>;
  ekpMinsportNumber_in?: Maybe<Array<Scalars['String']>>;
  ekpMinsportNumber_not_in?: Maybe<Array<Scalars['String']>>;
  ekpMinsportNumber_lt?: Maybe<Scalars['String']>;
  ekpMinsportNumber_lte?: Maybe<Scalars['String']>;
  ekpMinsportNumber_gt?: Maybe<Scalars['String']>;
  ekpMinsportNumber_gte?: Maybe<Scalars['String']>;
  ekpMinsportNumber_contains?: Maybe<Scalars['String']>;
  ekpMinsportNumber_not_contains?: Maybe<Scalars['String']>;
  ekpMinsportNumber_starts_with?: Maybe<Scalars['String']>;
  ekpMinsportNumber_not_starts_with?: Maybe<Scalars['String']>;
  ekpMinsportNumber_ends_with?: Maybe<Scalars['String']>;
  ekpMinsportNumber_not_ends_with?: Maybe<Scalars['String']>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  dateAcceptQuotasAndFilesStageTwo_not?: Maybe<Scalars['DateTime']>;
  dateAcceptQuotasAndFilesStageTwo_in?: Maybe<Array<Scalars['DateTime']>>;
  dateAcceptQuotasAndFilesStageTwo_not_in?: Maybe<Array<Scalars['DateTime']>>;
  dateAcceptQuotasAndFilesStageTwo_lt?: Maybe<Scalars['DateTime']>;
  dateAcceptQuotasAndFilesStageTwo_lte?: Maybe<Scalars['DateTime']>;
  dateAcceptQuotasAndFilesStageTwo_gt?: Maybe<Scalars['DateTime']>;
  dateAcceptQuotasAndFilesStageTwo_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirCalendarScalarWhereInput>>;
  OR?: Maybe<Array<DirCalendarScalarWhereInput>>;
  NOT?: Maybe<Array<DirCalendarScalarWhereInput>>;
};

export type DirCalendarUpdateManyWithWhereNestedInput = {
  where: DirCalendarScalarWhereInput;
  data: DirCalendarUpdateManyDataInput;
};

export type DirCalendarUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
};

export type FcpsrSportingEventOrganizerProfileUpsertNestedInput = {
  update: FcpsrSportingEventOrganizerProfileUpdateDataInput;
  create: FcpsrSportingEventOrganizerProfileCreateInput;
};

export type GskRefereeProfileUpdateOneInput = {
  create?: Maybe<GskRefereeProfileCreateInput>;
  update?: Maybe<GskRefereeProfileUpdateDataInput>;
  upsert?: Maybe<GskRefereeProfileUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<GskRefereeProfileWhereUniqueInput>;
};

export type GskRefereeProfileUpdateDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirSportingEvents?: Maybe<DirSportingEventUpdateManyInput>;
};

export type GskRefereeProfileUpsertNestedInput = {
  update: GskRefereeProfileUpdateDataInput;
  create: GskRefereeProfileCreateInput;
};

export type UserSupportRequestUpdateManyWithoutUserInput = {
  create?: Maybe<Array<UserSupportRequestCreateWithoutUserInput>>;
  delete?: Maybe<Array<UserSupportRequestWhereUniqueInput>>;
  connect?: Maybe<Array<UserSupportRequestWhereUniqueInput>>;
  set?: Maybe<Array<UserSupportRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserSupportRequestWhereUniqueInput>>;
  update?: Maybe<Array<UserSupportRequestUpdateWithWhereUniqueWithoutUserInput>>;
  upsert?: Maybe<Array<UserSupportRequestUpsertWithWhereUniqueWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserSupportRequestScalarWhereInput>>;
  updateMany?: Maybe<Array<UserSupportRequestUpdateManyWithWhereNestedInput>>;
};

export type UserSupportRequestUpdateWithWhereUniqueWithoutUserInput = {
  where: UserSupportRequestWhereUniqueInput;
  data: UserSupportRequestUpdateWithoutUserDataInput;
};

export type UserSupportRequestUpdateWithoutUserDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  number?: Maybe<Scalars['Int']>;
  operator?: Maybe<UserUpdateOneWithoutOperatorSupportRequestsInput>;
  request?: Maybe<Scalars['String']>;
  requestType?: Maybe<UserSupportRequestTypeUpdateOneRequiredInput>;
  file?: Maybe<FileUpdateOneInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
};

export type UserUpdateOneWithoutOperatorSupportRequestsInput = {
  create?: Maybe<UserCreateWithoutOperatorSupportRequestsInput>;
  update?: Maybe<UserUpdateWithoutOperatorSupportRequestsDataInput>;
  upsert?: Maybe<UserUpsertWithoutOperatorSupportRequestsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateWithoutOperatorSupportRequestsDataInput = {
  createdBy?: Maybe<UserUpdateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserUpdateOneWithoutUpdatedByInput>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleUpdateOneRequiredInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserUpdateOneInput>;
  system?: Maybe<SystemUserUpdateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonUpdateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileUpdateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileUpdateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileUpdateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileUpdateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileUpdateOneInput>;
  userSupportRequests?: Maybe<UserSupportRequestUpdateManyWithoutUserInput>;
};

export type UserUpsertWithoutOperatorSupportRequestsInput = {
  update: UserUpdateWithoutOperatorSupportRequestsDataInput;
  create: UserCreateWithoutOperatorSupportRequestsInput;
};

export type UserSupportRequestTypeUpdateOneRequiredInput = {
  create?: Maybe<UserSupportRequestTypeCreateInput>;
  update?: Maybe<UserSupportRequestTypeUpdateDataInput>;
  upsert?: Maybe<UserSupportRequestTypeUpsertNestedInput>;
  connect?: Maybe<UserSupportRequestTypeWhereUniqueInput>;
};

export type UserSupportRequestTypeUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type UserSupportRequestTypeUpsertNestedInput = {
  update: UserSupportRequestTypeUpdateDataInput;
  create: UserSupportRequestTypeCreateInput;
};

export type UserSupportRequestUpsertWithWhereUniqueWithoutUserInput = {
  where: UserSupportRequestWhereUniqueInput;
  update: UserSupportRequestUpdateWithoutUserDataInput;
  create: UserSupportRequestCreateWithoutUserInput;
};

export type UserSupportRequestScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['Int']>;
  number_not?: Maybe<Scalars['Int']>;
  number_in?: Maybe<Array<Scalars['Int']>>;
  number_not_in?: Maybe<Array<Scalars['Int']>>;
  number_lt?: Maybe<Scalars['Int']>;
  number_lte?: Maybe<Scalars['Int']>;
  number_gt?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
  request?: Maybe<Scalars['String']>;
  request_not?: Maybe<Scalars['String']>;
  request_in?: Maybe<Array<Scalars['String']>>;
  request_not_in?: Maybe<Array<Scalars['String']>>;
  request_lt?: Maybe<Scalars['String']>;
  request_lte?: Maybe<Scalars['String']>;
  request_gt?: Maybe<Scalars['String']>;
  request_gte?: Maybe<Scalars['String']>;
  request_contains?: Maybe<Scalars['String']>;
  request_not_contains?: Maybe<Scalars['String']>;
  request_starts_with?: Maybe<Scalars['String']>;
  request_not_starts_with?: Maybe<Scalars['String']>;
  request_ends_with?: Maybe<Scalars['String']>;
  request_not_ends_with?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotified_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<UserSupportRequestScalarWhereInput>>;
  OR?: Maybe<Array<UserSupportRequestScalarWhereInput>>;
  NOT?: Maybe<Array<UserSupportRequestScalarWhereInput>>;
};

export type UserSupportRequestUpdateManyWithWhereNestedInput = {
  where: UserSupportRequestScalarWhereInput;
  data: UserSupportRequestUpdateManyDataInput;
};

export type UserSupportRequestUpdateManyDataInput = {
  number?: Maybe<Scalars['Int']>;
  request?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
};

export type UserSupportRequestUpdateManyWithoutOperatorInput = {
  create?: Maybe<Array<UserSupportRequestCreateWithoutOperatorInput>>;
  delete?: Maybe<Array<UserSupportRequestWhereUniqueInput>>;
  connect?: Maybe<Array<UserSupportRequestWhereUniqueInput>>;
  set?: Maybe<Array<UserSupportRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserSupportRequestWhereUniqueInput>>;
  update?: Maybe<Array<UserSupportRequestUpdateWithWhereUniqueWithoutOperatorInput>>;
  upsert?: Maybe<Array<UserSupportRequestUpsertWithWhereUniqueWithoutOperatorInput>>;
  deleteMany?: Maybe<Array<UserSupportRequestScalarWhereInput>>;
  updateMany?: Maybe<Array<UserSupportRequestUpdateManyWithWhereNestedInput>>;
};

export type UserSupportRequestUpdateWithWhereUniqueWithoutOperatorInput = {
  where: UserSupportRequestWhereUniqueInput;
  data: UserSupportRequestUpdateWithoutOperatorDataInput;
};

export type UserSupportRequestUpdateWithoutOperatorDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  number?: Maybe<Scalars['Int']>;
  user?: Maybe<UserUpdateOneWithoutUserSupportRequestsInput>;
  request?: Maybe<Scalars['String']>;
  requestType?: Maybe<UserSupportRequestTypeUpdateOneRequiredInput>;
  file?: Maybe<FileUpdateOneInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
};

export type UserUpdateOneWithoutUserSupportRequestsInput = {
  create?: Maybe<UserCreateWithoutUserSupportRequestsInput>;
  update?: Maybe<UserUpdateWithoutUserSupportRequestsDataInput>;
  upsert?: Maybe<UserUpsertWithoutUserSupportRequestsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateWithoutUserSupportRequestsDataInput = {
  createdBy?: Maybe<UserUpdateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserUpdateOneWithoutUpdatedByInput>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleUpdateOneRequiredInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserUpdateOneInput>;
  system?: Maybe<SystemUserUpdateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonUpdateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileUpdateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileUpdateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileUpdateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileUpdateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileUpdateOneInput>;
  operatorSupportRequests?: Maybe<UserSupportRequestUpdateManyWithoutOperatorInput>;
};

export type UserUpsertWithoutUserSupportRequestsInput = {
  update: UserUpdateWithoutUserSupportRequestsDataInput;
  create: UserCreateWithoutUserSupportRequestsInput;
};

export type UserSupportRequestUpsertWithWhereUniqueWithoutOperatorInput = {
  where: UserSupportRequestWhereUniqueInput;
  update: UserSupportRequestUpdateWithoutOperatorDataInput;
  create: UserSupportRequestCreateWithoutOperatorInput;
};

export type UserUpsertWithoutPersonProfileInput = {
  update: UserUpdateWithoutPersonProfileDataInput;
  create: UserCreateWithoutPersonProfileInput;
};

export type DirPersonUpsertWithoutDuplicatesInput = {
  update: DirPersonUpdateWithoutDuplicatesDataInput;
  create: DirPersonCreateWithoutDuplicatesInput;
};

export type DirPersonUpsertWithoutUserProfileInput = {
  update: DirPersonUpdateWithoutUserProfileDataInput;
  create: DirPersonCreateWithoutUserProfileInput;
};

export type RegularUserUpsertNestedInput = {
  update: RegularUserUpdateDataInput;
  create: RegularUserCreateInput;
};

export type UserUpsertWithoutUpdatedByInput = {
  update: UserUpdateWithoutUpdatedByDataInput;
  create: UserCreateWithoutUpdatedByInput;
};

export type UserUpsertWithoutCreatedByInput = {
  update: UserUpdateWithoutCreatedByDataInput;
  create: UserCreateWithoutCreatedByInput;
};

export type UserRoleUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  canSelect?: Maybe<Scalars['Boolean']>;
};

export type RegularUserUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneInput>;
};

export type SystemUserUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
};

export type RegionalMinsportProfileUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirFederalDistrict?: Maybe<DirFederalDistrictUpdateOneInput>;
};

export type RegionalSchoolProfileUpdateInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredInput>;
};

export type NotificationProtocolCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirCalendar: DirCalendarCreateOneWithoutRoivForNotificationProtocolInput;
  dirRegion: DirRegionCreateOneInput;
  dirSport: DirSportCreateOneInput;
  forNotificationROIVSpecialists?: Maybe<UserCreateManyInput>;
};

export type DirCalendarCreateOneWithoutRoivForNotificationProtocolInput = {
  create?: Maybe<DirCalendarCreateWithoutRoivForNotificationProtocolInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarCreateWithoutRoivForNotificationProtocolInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeCreateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueCreateOneInput>;
  clsSeason?: Maybe<ClassifierValueCreateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueCreateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionCreateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionCreateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionCreateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileCreateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsCreateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileCreateManyInput>;
  stageNumber?: Maybe<ClassifierValueCreateOneInput>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportCreateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoCreateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportCreateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoCreateManyWithoutDirCalendarInput>;
};

export type NotificationProtocolUpdateInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredWithoutRoivForNotificationProtocolInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  forNotificationROIVSpecialists?: Maybe<UserUpdateManyInput>;
};

export type DirCalendarUpdateOneRequiredWithoutRoivForNotificationProtocolInput = {
  create?: Maybe<DirCalendarCreateWithoutRoivForNotificationProtocolInput>;
  update?: Maybe<DirCalendarUpdateWithoutRoivForNotificationProtocolDataInput>;
  upsert?: Maybe<DirCalendarUpsertWithoutRoivForNotificationProtocolInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarUpdateWithoutRoivForNotificationProtocolDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeUpdateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueUpdateOneInput>;
  clsSeason?: Maybe<ClassifierValueUpdateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueUpdateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionUpdateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionUpdateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionUpdateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileUpdateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsUpdateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileUpdateManyInput>;
  stageNumber?: Maybe<ClassifierValueUpdateOneInput>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportUpdateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoUpdateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportUpdateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoUpdateManyWithoutDirCalendarInput>;
};

export type DirCalendarUpsertWithoutRoivForNotificationProtocolInput = {
  update: DirCalendarUpdateWithoutRoivForNotificationProtocolDataInput;
  create: DirCalendarCreateWithoutRoivForNotificationProtocolInput;
};

export type RegionalAdministratorProfileUpdateInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
};

export type RoivSpecialistProfileUpdateInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
};

export type FcpsrSportingEventOrganizerProfileUpdateInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  sportingEvents?: Maybe<DirSportingEventUpdateManyInput>;
  dirCalendars?: Maybe<DirCalendarUpdateManyInput>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
};

export type DirSportingEventRegulationStatusValueUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventRegulationStatusCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  regulation?: Maybe<DirSportEventRegulationsCreateOneWithoutStatusInput>;
  value: DirSportingEventRegulationStatusValueCreateOneInput;
  file?: Maybe<FileCreateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportEventRegulationsCreateOneWithoutStatusInput = {
  create?: Maybe<DirSportEventRegulationsCreateWithoutStatusInput>;
  connect?: Maybe<DirSportEventRegulationsWhereUniqueInput>;
};

export type DirSportEventRegulationsCreateWithoutStatusInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirCalendar?: Maybe<DirCalendarCreateOneWithoutDirSportEventRegulationsInput>;
  regulationsDocument?: Maybe<FileCreateOneInput>;
  regulationsAmendedDocument?: Maybe<FileCreateOneInput>;
  regulationsAgreedDocument?: Maybe<FileCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  dirSport?: Maybe<DirSportCreateOneInput>;
  fcpsrAddRegulationsDoc?: Maybe<UserCreateOneInput>;
};

export type DirCalendarCreateOneWithoutDirSportEventRegulationsInput = {
  create?: Maybe<DirCalendarCreateWithoutDirSportEventRegulationsInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarCreateWithoutDirSportEventRegulationsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeCreateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueCreateOneInput>;
  clsSeason?: Maybe<ClassifierValueCreateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueCreateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionCreateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionCreateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionCreateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileCreateOneInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolCreateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileCreateManyInput>;
  stageNumber?: Maybe<ClassifierValueCreateOneInput>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportCreateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoCreateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportCreateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoCreateManyWithoutDirCalendarInput>;
};

export type DirSportingEventRegulationStatusUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  regulation?: Maybe<DirSportEventRegulationsUpdateOneWithoutStatusInput>;
  value?: Maybe<DirSportingEventRegulationStatusValueUpdateOneRequiredInput>;
  file?: Maybe<FileUpdateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportEventRegulationsUpdateOneWithoutStatusInput = {
  create?: Maybe<DirSportEventRegulationsCreateWithoutStatusInput>;
  update?: Maybe<DirSportEventRegulationsUpdateWithoutStatusDataInput>;
  upsert?: Maybe<DirSportEventRegulationsUpsertWithoutStatusInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportEventRegulationsWhereUniqueInput>;
};

export type DirSportEventRegulationsUpdateWithoutStatusDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirCalendar?: Maybe<DirCalendarUpdateOneWithoutDirSportEventRegulationsInput>;
  regulationsDocument?: Maybe<FileUpdateOneInput>;
  regulationsAmendedDocument?: Maybe<FileUpdateOneInput>;
  regulationsAgreedDocument?: Maybe<FileUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  dirSport?: Maybe<DirSportUpdateOneInput>;
  fcpsrAddRegulationsDoc?: Maybe<UserUpdateOneInput>;
};

export type DirCalendarUpdateOneWithoutDirSportEventRegulationsInput = {
  create?: Maybe<DirCalendarCreateWithoutDirSportEventRegulationsInput>;
  update?: Maybe<DirCalendarUpdateWithoutDirSportEventRegulationsDataInput>;
  upsert?: Maybe<DirCalendarUpsertWithoutDirSportEventRegulationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarUpdateWithoutDirSportEventRegulationsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeUpdateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueUpdateOneInput>;
  clsSeason?: Maybe<ClassifierValueUpdateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueUpdateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionUpdateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionUpdateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionUpdateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileUpdateOneInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolUpdateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileUpdateManyInput>;
  stageNumber?: Maybe<ClassifierValueUpdateOneInput>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportUpdateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoUpdateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportUpdateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoUpdateManyWithoutDirCalendarInput>;
};

export type DirCalendarUpsertWithoutDirSportEventRegulationsInput = {
  update: DirCalendarUpdateWithoutDirSportEventRegulationsDataInput;
  create: DirCalendarCreateWithoutDirSportEventRegulationsInput;
};

export type DirSportEventRegulationsUpsertWithoutStatusInput = {
  update: DirSportEventRegulationsUpdateWithoutStatusDataInput;
  create: DirSportEventRegulationsCreateWithoutStatusInput;
};

export type DirRefusedRegionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  comment?: Maybe<Scalars['String']>;
  statement?: Maybe<FileCreateOneInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCalendar: DirCalendarCreateOneWithoutRefusedRegionsInput;
};

export type DirCalendarCreateOneWithoutRefusedRegionsInput = {
  create?: Maybe<DirCalendarCreateWithoutRefusedRegionsInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarCreateWithoutRefusedRegionsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeCreateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueCreateOneInput>;
  clsSeason?: Maybe<ClassifierValueCreateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueCreateOneInput>;
  returnedRegions?: Maybe<DirReturnedRegionCreateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionCreateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileCreateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsCreateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolCreateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileCreateManyInput>;
  stageNumber?: Maybe<ClassifierValueCreateOneInput>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportCreateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoCreateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportCreateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoCreateManyWithoutDirCalendarInput>;
};

export type DirRefusedRegionUpdateInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  comment?: Maybe<Scalars['String']>;
  statement?: Maybe<FileUpdateOneInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredWithoutRefusedRegionsInput>;
};

export type DirCalendarUpdateOneRequiredWithoutRefusedRegionsInput = {
  create?: Maybe<DirCalendarCreateWithoutRefusedRegionsInput>;
  update?: Maybe<DirCalendarUpdateWithoutRefusedRegionsDataInput>;
  upsert?: Maybe<DirCalendarUpsertWithoutRefusedRegionsInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarUpdateWithoutRefusedRegionsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeUpdateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueUpdateOneInput>;
  clsSeason?: Maybe<ClassifierValueUpdateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueUpdateOneInput>;
  returnedRegions?: Maybe<DirReturnedRegionUpdateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionUpdateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileUpdateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsUpdateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolUpdateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileUpdateManyInput>;
  stageNumber?: Maybe<ClassifierValueUpdateOneInput>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportUpdateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoUpdateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportUpdateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoUpdateManyWithoutDirCalendarInput>;
};

export type DirCalendarUpsertWithoutRefusedRegionsInput = {
  update: DirCalendarUpdateWithoutRefusedRegionsDataInput;
  create: DirCalendarCreateWithoutRefusedRegionsInput;
};

export type DirReturnedRegionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  statement?: Maybe<FileCreateOneInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCalendar: DirCalendarCreateOneWithoutReturnedRegionsInput;
};

export type DirCalendarCreateOneWithoutReturnedRegionsInput = {
  create?: Maybe<DirCalendarCreateWithoutReturnedRegionsInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarCreateWithoutReturnedRegionsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeCreateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueCreateOneInput>;
  clsSeason?: Maybe<ClassifierValueCreateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueCreateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionCreateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionCreateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileCreateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsCreateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolCreateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileCreateManyInput>;
  stageNumber?: Maybe<ClassifierValueCreateOneInput>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportCreateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoCreateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportCreateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoCreateManyWithoutDirCalendarInput>;
};

export type DirReturnedRegionUpdateInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  statement?: Maybe<FileUpdateOneInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredWithoutReturnedRegionsInput>;
};

export type DirCalendarUpdateOneRequiredWithoutReturnedRegionsInput = {
  create?: Maybe<DirCalendarCreateWithoutReturnedRegionsInput>;
  update?: Maybe<DirCalendarUpdateWithoutReturnedRegionsDataInput>;
  upsert?: Maybe<DirCalendarUpsertWithoutReturnedRegionsInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarUpdateWithoutReturnedRegionsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeUpdateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueUpdateOneInput>;
  clsSeason?: Maybe<ClassifierValueUpdateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueUpdateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionUpdateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionUpdateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileUpdateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsUpdateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolUpdateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileUpdateManyInput>;
  stageNumber?: Maybe<ClassifierValueUpdateOneInput>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportUpdateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoUpdateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportUpdateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoUpdateManyWithoutDirCalendarInput>;
};

export type DirCalendarUpsertWithoutReturnedRegionsInput = {
  update: DirCalendarUpdateWithoutReturnedRegionsDataInput;
  create: DirCalendarCreateWithoutReturnedRegionsInput;
};

export type DirSportEventRegulationsCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirCalendar?: Maybe<DirCalendarCreateOneWithoutDirSportEventRegulationsInput>;
  regulationsDocument?: Maybe<FileCreateOneInput>;
  regulationsAmendedDocument?: Maybe<FileCreateOneInput>;
  regulationsAgreedDocument?: Maybe<FileCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  dirSport?: Maybe<DirSportCreateOneInput>;
  status?: Maybe<DirSportingEventRegulationStatusCreateManyWithoutRegulationInput>;
  fcpsrAddRegulationsDoc?: Maybe<UserCreateOneInput>;
};

export type DirSportEventRegulationsUpdateInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirCalendar?: Maybe<DirCalendarUpdateOneWithoutDirSportEventRegulationsInput>;
  regulationsDocument?: Maybe<FileUpdateOneInput>;
  regulationsAmendedDocument?: Maybe<FileUpdateOneInput>;
  regulationsAgreedDocument?: Maybe<FileUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  dirSport?: Maybe<DirSportUpdateOneInput>;
  status?: Maybe<DirSportingEventRegulationStatusUpdateManyWithoutRegulationInput>;
  fcpsrAddRegulationsDoc?: Maybe<UserUpdateOneInput>;
};

export type GskRefereeProfileUpdateInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirSportingEvents?: Maybe<DirSportingEventUpdateManyInput>;
};

export type UserSupportRequestCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  number?: Maybe<Scalars['Int']>;
  user?: Maybe<UserCreateOneWithoutUserSupportRequestsInput>;
  operator?: Maybe<UserCreateOneWithoutOperatorSupportRequestsInput>;
  request: Scalars['String'];
  requestType: UserSupportRequestTypeCreateOneInput;
  file?: Maybe<FileCreateOneInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
};

export type UserSupportRequestUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  number?: Maybe<Scalars['Int']>;
  user?: Maybe<UserUpdateOneWithoutUserSupportRequestsInput>;
  operator?: Maybe<UserUpdateOneWithoutOperatorSupportRequestsInput>;
  request?: Maybe<Scalars['String']>;
  requestType?: Maybe<UserSupportRequestTypeUpdateOneRequiredInput>;
  file?: Maybe<FileUpdateOneInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
};

export type UserSupportRequestTypeUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type FieldHistoryUpdateInput = {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
};

export type ArchiveUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  reason?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type ClassifierCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  values?: Maybe<ClassifierValueCreateManyWithoutClassifierInput>;
};

export type ClassifierValueCreateManyWithoutClassifierInput = {
  create?: Maybe<Array<ClassifierValueCreateWithoutClassifierInput>>;
  connect?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
};

export type ClassifierValueCreateWithoutClassifierInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type ClassifierUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  values?: Maybe<ClassifierValueUpdateManyWithoutClassifierInput>;
};

export type ClassifierValueUpdateManyWithoutClassifierInput = {
  create?: Maybe<Array<ClassifierValueCreateWithoutClassifierInput>>;
  delete?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  connect?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  set?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  update?: Maybe<Array<ClassifierValueUpdateWithWhereUniqueWithoutClassifierInput>>;
  upsert?: Maybe<Array<ClassifierValueUpsertWithWhereUniqueWithoutClassifierInput>>;
  deleteMany?: Maybe<Array<ClassifierValueScalarWhereInput>>;
  updateMany?: Maybe<Array<ClassifierValueUpdateManyWithWhereNestedInput>>;
};

export type ClassifierValueUpdateWithWhereUniqueWithoutClassifierInput = {
  where: ClassifierValueWhereUniqueInput;
  data: ClassifierValueUpdateWithoutClassifierDataInput;
};

export type ClassifierValueUpdateWithoutClassifierDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type ClassifierValueUpsertWithWhereUniqueWithoutClassifierInput = {
  where: ClassifierValueWhereUniqueInput;
  update: ClassifierValueUpdateWithoutClassifierDataInput;
  create: ClassifierValueCreateWithoutClassifierInput;
};

export type ClassifierValueUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  classifier?: Maybe<ClassifierUpdateOneRequiredWithoutValuesInput>;
};

export type DirRegionUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  dirFederalDistrict?: Maybe<DirFederalDistrictUpdateOneRequiredWithoutDirRegionsInput>;
  roivOfficialEmail?: Maybe<Scalars['String']>;
};

export type DirFederalDistrictUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  dirRegions?: Maybe<DirRegionUpdateManyWithoutDirFederalDistrictInput>;
};

export type DirForeignCityUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  dirCountry?: Maybe<DirCountryUpdateOneRequiredWithoutDirForeignCitiesInput>;
};

export type DirCountryUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  dirForeignCities?: Maybe<DirForeignCityUpdateManyWithoutDirCountryInput>;
};

export type DirOrganizationUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type DirOrganizationSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirOrganizationCreateOneWithoutSportsInput = {
  create?: Maybe<DirOrganizationCreateWithoutSportsInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationCreateWithoutSportsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationCreateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationCreateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  addresses?: Maybe<DirOrganizationAddressCreateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneCreateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type DirOrganizationSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirOrganizationUpdateOneRequiredWithoutSportsInput = {
  create?: Maybe<DirOrganizationCreateWithoutSportsInput>;
  update?: Maybe<DirOrganizationUpdateWithoutSportsDataInput>;
  upsert?: Maybe<DirOrganizationUpsertWithoutSportsInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationUpdateWithoutSportsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type DirOrganizationUpsertWithoutSportsInput = {
  update: DirOrganizationUpdateWithoutSportsDataInput;
  create: DirOrganizationCreateWithoutSportsInput;
};

export type DirOrganizationAddressCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneWithoutAddressesInput;
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type: AddressTypeCreateOneInput;
};

export type DirOrganizationCreateOneWithoutAddressesInput = {
  create?: Maybe<DirOrganizationCreateWithoutAddressesInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationCreateWithoutAddressesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationCreateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationCreateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  sports?: Maybe<DirOrganizationSportCreateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneCreateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type DirOrganizationAddressUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredWithoutAddressesInput>;
  place?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type?: Maybe<AddressTypeUpdateOneRequiredInput>;
};

export type DirOrganizationUpdateOneRequiredWithoutAddressesInput = {
  create?: Maybe<DirOrganizationCreateWithoutAddressesInput>;
  update?: Maybe<DirOrganizationUpdateWithoutAddressesDataInput>;
  upsert?: Maybe<DirOrganizationUpsertWithoutAddressesInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationUpdateWithoutAddressesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type DirOrganizationUpsertWithoutAddressesInput = {
  update: DirOrganizationUpdateWithoutAddressesDataInput;
  create: DirOrganizationCreateWithoutAddressesInput;
};

export type DirOrganizationPhoneCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneWithoutPhonesInput;
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: ClassifierValueCreateOneInput;
};

export type DirOrganizationCreateOneWithoutPhonesInput = {
  create?: Maybe<DirOrganizationCreateWithoutPhonesInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationCreateWithoutPhonesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationCreateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationCreateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  sports?: Maybe<DirOrganizationSportCreateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressCreateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type DirOrganizationPhoneUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredWithoutPhonesInput>;
  countryCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueUpdateOneRequiredInput>;
};

export type DirOrganizationUpdateOneRequiredWithoutPhonesInput = {
  create?: Maybe<DirOrganizationCreateWithoutPhonesInput>;
  update?: Maybe<DirOrganizationUpdateWithoutPhonesDataInput>;
  upsert?: Maybe<DirOrganizationUpsertWithoutPhonesInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationUpdateWithoutPhonesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type DirOrganizationUpsertWithoutPhonesInput = {
  update: DirOrganizationUpdateWithoutPhonesDataInput;
  create: DirOrganizationCreateWithoutPhonesInput;
};

export type DirOrganizationEmailCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneWithoutEmailsInput;
  email: Scalars['String'];
  clsContactType: ClassifierValueCreateOneInput;
};

export type DirOrganizationCreateOneWithoutEmailsInput = {
  create?: Maybe<DirOrganizationCreateWithoutEmailsInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationCreateWithoutEmailsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationCreateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationCreateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  sports?: Maybe<DirOrganizationSportCreateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressCreateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type DirOrganizationEmailUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredWithoutEmailsInput>;
  email?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueUpdateOneRequiredInput>;
};

export type DirOrganizationUpdateOneRequiredWithoutEmailsInput = {
  create?: Maybe<DirOrganizationCreateWithoutEmailsInput>;
  update?: Maybe<DirOrganizationUpdateWithoutEmailsDataInput>;
  upsert?: Maybe<DirOrganizationUpsertWithoutEmailsInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationUpdateWithoutEmailsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
  isUniversity?: Maybe<Scalars['Boolean']>;
};

export type DirOrganizationUpsertWithoutEmailsInput = {
  update: DirOrganizationUpdateWithoutEmailsDataInput;
  create: DirOrganizationCreateWithoutEmailsInput;
};

export type DirPersonUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type DirPersonPhoneCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: ClassifierValueCreateOneInput;
  dirPerson: DirPersonCreateOneWithoutPhonesInput;
};

export type DirPersonCreateOneWithoutPhonesInput = {
  create?: Maybe<DirPersonCreateWithoutPhonesInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutPhonesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileCreateOneInput>;
  doc2?: Maybe<FileCreateOneInput>;
  doc3?: Maybe<FileCreateOneInput>;
  bankRequisites?: Maybe<FileCreateOneInput>;
};

export type DirPersonPhoneUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutPhonesInput>;
};

export type DirPersonUpdateOneRequiredWithoutPhonesInput = {
  create?: Maybe<DirPersonCreateWithoutPhonesInput>;
  update?: Maybe<DirPersonUpdateWithoutPhonesDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutPhonesInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutPhonesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type DirPersonUpsertWithoutPhonesInput = {
  update: DirPersonUpdateWithoutPhonesDataInput;
  create: DirPersonCreateWithoutPhonesInput;
};

export type DirPersonConfirmStatusUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type DirPersonAdditionalRegionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirPerson: DirPersonCreateOneWithoutAdditionalRegionsInput;
};

export type DirPersonCreateOneWithoutAdditionalRegionsInput = {
  create?: Maybe<DirPersonCreateWithoutAdditionalRegionsInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutAdditionalRegionsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileCreateOneInput>;
  doc2?: Maybe<FileCreateOneInput>;
  doc3?: Maybe<FileCreateOneInput>;
  bankRequisites?: Maybe<FileCreateOneInput>;
};

export type DirPersonAdditionalRegionUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutAdditionalRegionsInput>;
};

export type DirPersonUpdateOneRequiredWithoutAdditionalRegionsInput = {
  create?: Maybe<DirPersonCreateWithoutAdditionalRegionsInput>;
  update?: Maybe<DirPersonUpdateWithoutAdditionalRegionsDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutAdditionalRegionsInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutAdditionalRegionsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type DirPersonUpsertWithoutAdditionalRegionsInput = {
  update: DirPersonUpdateWithoutAdditionalRegionsDataInput;
  create: DirPersonCreateWithoutAdditionalRegionsInput;
};

export type DirPersonMergeTaskCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  status: TaskStatusCreateOneInput;
  comment?: Maybe<Scalars['String']>;
  primary: DirPersonCreateOneWithoutMergeTasksAsPrimaryInput;
  duplicate: DirPersonCreateOneWithoutMergeTasksAsDuplicateInput;
};

export type DirPersonMergeTaskUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  status?: Maybe<TaskStatusUpdateOneRequiredInput>;
  comment?: Maybe<Scalars['String']>;
  primary?: Maybe<DirPersonUpdateOneRequiredWithoutMergeTasksAsPrimaryInput>;
  duplicate?: Maybe<DirPersonUpdateOneRequiredWithoutMergeTasksAsDuplicateInput>;
};

export type DirPersonAddressCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutAddressesInput;
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type: AddressTypeCreateOneInput;
};

export type DirPersonCreateOneWithoutAddressesInput = {
  create?: Maybe<DirPersonCreateWithoutAddressesInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutAddressesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileCreateOneInput>;
  doc2?: Maybe<FileCreateOneInput>;
  doc3?: Maybe<FileCreateOneInput>;
  bankRequisites?: Maybe<FileCreateOneInput>;
};

export type DirPersonAddressUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutAddressesInput>;
  place?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type?: Maybe<AddressTypeUpdateOneRequiredInput>;
};

export type DirPersonUpdateOneRequiredWithoutAddressesInput = {
  create?: Maybe<DirPersonCreateWithoutAddressesInput>;
  update?: Maybe<DirPersonUpdateWithoutAddressesDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutAddressesInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutAddressesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  residenceCity?: Maybe<Scalars['String']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  nationalTeamEmailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutDirPersonInput>;
  doc1?: Maybe<FileUpdateOneInput>;
  doc2?: Maybe<FileUpdateOneInput>;
  doc3?: Maybe<FileUpdateOneInput>;
  bankRequisites?: Maybe<FileUpdateOneInput>;
};

export type DirPersonUpsertWithoutAddressesInput = {
  update: DirPersonUpdateWithoutAddressesDataInput;
  create: DirPersonCreateWithoutAddressesInput;
};

export type DirSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageUpdateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionUpdateManyWithoutSportInput>;
};

export type DirSportDisciplineUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDisciplinesInput>;
  dirSport?: Maybe<DirSportUpdateOneWithoutDisciplinesInput>;
};

export type DirSportDisciplineGroupUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDisciplineGroupsInput>;
  dirSport?: Maybe<DirSportUpdateOneWithoutDisciplineGroupsInput>;
};

export type DirSportAgeGroupUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneWithoutAgeGroupsInput>;
  participationRequirements?: Maybe<DirSportAgeGroupParticipationRequirementsUpdateManyWithoutAgeGroupInput>;
};

export type DirSportAgeGroupParticipationRequirementsCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  ageGroup: DirSportAgeGroupCreateOneWithoutParticipationRequirementsInput;
  clsCalendarCategory?: Maybe<ClassifierValueCreateOneInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportAgeGroupCreateOneWithoutParticipationRequirementsInput = {
  create?: Maybe<DirSportAgeGroupCreateWithoutParticipationRequirementsInput>;
  connect?: Maybe<DirSportAgeGroupWhereUniqueInput>;
};

export type DirSportAgeGroupCreateWithoutParticipationRequirementsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup: ClassifierValueCreateOneInput;
  minAge: Scalars['Int'];
  maxAge?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportCreateOneWithoutAgeGroupsInput>;
};

export type DirSportAgeGroupParticipationRequirementsUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  ageGroup?: Maybe<DirSportAgeGroupUpdateOneRequiredWithoutParticipationRequirementsInput>;
  clsCalendarCategory?: Maybe<ClassifierValueUpdateOneInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportAgeGroupUpdateOneRequiredWithoutParticipationRequirementsInput = {
  create?: Maybe<DirSportAgeGroupCreateWithoutParticipationRequirementsInput>;
  update?: Maybe<DirSportAgeGroupUpdateWithoutParticipationRequirementsDataInput>;
  upsert?: Maybe<DirSportAgeGroupUpsertWithoutParticipationRequirementsInput>;
  connect?: Maybe<DirSportAgeGroupWhereUniqueInput>;
};

export type DirSportAgeGroupUpdateWithoutParticipationRequirementsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneWithoutAgeGroupsInput>;
};

export type DirSportAgeGroupUpsertWithoutParticipationRequirementsInput = {
  update: DirSportAgeGroupUpdateWithoutParticipationRequirementsDataInput;
  create: DirSportAgeGroupCreateWithoutParticipationRequirementsInput;
};

export type DirSportTrainingStageCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportCreateOneWithoutTrainingStagesInput>;
  clsTrainingStage: ClassifierValueCreateOneInput;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
};

export type DirSportCreateOneWithoutTrainingStagesInput = {
  create?: Maybe<DirSportCreateWithoutTrainingStagesInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportCreateWithoutTrainingStagesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupCreateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueCreateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  clsCategories?: Maybe<ClassifierValueCreateManyInput>;
  baseRegions?: Maybe<DirSportBaseRegionCreateManyWithoutSportInput>;
};

export type DirSportTrainingStageUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneWithoutTrainingStagesInput>;
  clsTrainingStage?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
};

export type DirSportUpdateOneWithoutTrainingStagesInput = {
  create?: Maybe<DirSportCreateWithoutTrainingStagesInput>;
  update?: Maybe<DirSportUpdateWithoutTrainingStagesDataInput>;
  upsert?: Maybe<DirSportUpsertWithoutTrainingStagesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportUpdateWithoutTrainingStagesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  baseRegions?: Maybe<DirSportBaseRegionUpdateManyWithoutSportInput>;
};

export type DirSportUpsertWithoutTrainingStagesInput = {
  update: DirSportUpdateWithoutTrainingStagesDataInput;
  create: DirSportCreateWithoutTrainingStagesInput;
};

export type DirSportBaseRegionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sport: DirSportCreateOneWithoutBaseRegionsInput;
  dirRegion: DirRegionCreateOneInput;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DirSportCreateOneWithoutBaseRegionsInput = {
  create?: Maybe<DirSportCreateWithoutBaseRegionsInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportCreateWithoutBaseRegionsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupCreateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueCreateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  clsCategories?: Maybe<ClassifierValueCreateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageCreateManyWithoutDirSportInput>;
};

export type DirSportBaseRegionUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sport?: Maybe<DirSportUpdateOneRequiredWithoutBaseRegionsInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DirSportUpdateOneRequiredWithoutBaseRegionsInput = {
  create?: Maybe<DirSportCreateWithoutBaseRegionsInput>;
  update?: Maybe<DirSportUpdateWithoutBaseRegionsDataInput>;
  upsert?: Maybe<DirSportUpsertWithoutBaseRegionsInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportUpdateWithoutBaseRegionsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageUpdateManyWithoutDirSportInput>;
};

export type DirSportUpsertWithoutBaseRegionsInput = {
  update: DirSportUpdateWithoutBaseRegionsDataInput;
  create: DirSportCreateWithoutBaseRegionsInput;
};

export type DirAthleteUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutAthleteProfileInput>;
  dirPersonAgent?: Maybe<DirPersonUpdateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueUpdateOneInput>;
  sports?: Maybe<DirAthleteSportUpdateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertUpdateManyWithoutDirAthleteInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultUpdateManyWithoutDirAthleteInput>;
};

export type DirAthleteSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  dirTrainers?: Maybe<DirTrainerUpdateManyInput>;
  organizations?: Maybe<DirAthleteSportOrganizationUpdateManyInput>;
  ranksAndAwards?: Maybe<DirAthleteSportRankAndAwardUpdateManyWithoutSportInput>;
  role?: Maybe<Scalars['String']>;
};

export type DirAthleteSportOrganizationUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredInput>;
  clsTrainingStage?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  dirTrainer?: Maybe<DirTrainerUpdateOneInput>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  trainingStart?: Maybe<Scalars['DateTime']>;
  exclusionDate?: Maybe<Scalars['DateTime']>;
};

export type DirAthleteSportRankAndAwardCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sport: DirAthleteSportCreateOneWithoutRanksAndAwardsInput;
  clsRankAndAward: ClassifierValueCreateOneInput;
  assignmentDate: Scalars['DateTime'];
  confirmDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  dirDocument?: Maybe<DirDocumentCreateOneInput>;
};

export type DirAthleteSportCreateOneWithoutRanksAndAwardsInput = {
  create?: Maybe<DirAthleteSportCreateWithoutRanksAndAwardsInput>;
  connect?: Maybe<DirAthleteSportWhereUniqueInput>;
};

export type DirAthleteSportCreateWithoutRanksAndAwardsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  dirTrainers?: Maybe<DirTrainerCreateManyInput>;
  organizations?: Maybe<DirAthleteSportOrganizationCreateManyInput>;
  role?: Maybe<Scalars['String']>;
};

export type DirAthleteSportRankAndAwardUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sport?: Maybe<DirAthleteSportUpdateOneRequiredWithoutRanksAndAwardsInput>;
  clsRankAndAward?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  confirmDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  dirDocument?: Maybe<DirDocumentUpdateOneInput>;
};

export type DirAthleteSportUpdateOneRequiredWithoutRanksAndAwardsInput = {
  create?: Maybe<DirAthleteSportCreateWithoutRanksAndAwardsInput>;
  update?: Maybe<DirAthleteSportUpdateWithoutRanksAndAwardsDataInput>;
  upsert?: Maybe<DirAthleteSportUpsertWithoutRanksAndAwardsInput>;
  connect?: Maybe<DirAthleteSportWhereUniqueInput>;
};

export type DirAthleteSportUpdateWithoutRanksAndAwardsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  dirTrainers?: Maybe<DirTrainerUpdateManyInput>;
  organizations?: Maybe<DirAthleteSportOrganizationUpdateManyInput>;
  role?: Maybe<Scalars['String']>;
};

export type DirAthleteSportUpsertWithoutRanksAndAwardsInput = {
  update: DirAthleteSportUpdateWithoutRanksAndAwardsDataInput;
  create: DirAthleteSportCreateWithoutRanksAndAwardsInput;
};

export type DirAthleteMedCertCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  updatedAtReal?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  dirAthlete: DirAthleteCreateOneWithoutMedCertsInput;
  issueDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName: Scalars['String'];
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  sports?: Maybe<DirAthleteMedCertSportCreateManyWithoutMedCertInput>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteCreateOneWithoutMedCertsInput = {
  create?: Maybe<DirAthleteCreateWithoutMedCertsInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteCreateWithoutMedCertsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutAthleteProfileInput;
  dirPersonAgent?: Maybe<DirPersonCreateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueCreateOneInput>;
  sports?: Maybe<DirAthleteSportCreateManyInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultCreateManyWithoutDirAthleteInput>;
};

export type DirAthleteMedCertUpdateInput = {
  updatedAtReal?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  dirAthlete?: Maybe<DirAthleteUpdateOneRequiredWithoutMedCertsInput>;
  issueDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  sports?: Maybe<DirAthleteMedCertSportUpdateManyWithoutMedCertInput>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteUpdateOneRequiredWithoutMedCertsInput = {
  create?: Maybe<DirAthleteCreateWithoutMedCertsInput>;
  update?: Maybe<DirAthleteUpdateWithoutMedCertsDataInput>;
  upsert?: Maybe<DirAthleteUpsertWithoutMedCertsInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteUpdateWithoutMedCertsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutAthleteProfileInput>;
  dirPersonAgent?: Maybe<DirPersonUpdateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueUpdateOneInput>;
  sports?: Maybe<DirAthleteSportUpdateManyInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultUpdateManyWithoutDirAthleteInput>;
};

export type DirAthleteUpsertWithoutMedCertsInput = {
  update: DirAthleteUpdateWithoutMedCertsDataInput;
  create: DirAthleteCreateWithoutMedCertsInput;
};

export type DirAthleteMedCertSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  medCert: DirAthleteMedCertCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  clsTrainingStage?: Maybe<ClassifierValueCreateOneInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirAthleteMedCertCreateOneWithoutSportsInput = {
  create?: Maybe<DirAthleteMedCertCreateWithoutSportsInput>;
  connect?: Maybe<DirAthleteMedCertWhereUniqueInput>;
};

export type DirAthleteMedCertCreateWithoutSportsInput = {
  id?: Maybe<Scalars['UUID']>;
  updatedAtReal?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  dirAthlete: DirAthleteCreateOneWithoutMedCertsInput;
  issueDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName: Scalars['String'];
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteMedCertSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  medCert?: Maybe<DirAthleteMedCertUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  clsTrainingStage?: Maybe<ClassifierValueUpdateOneInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirAthleteMedCertUpdateOneRequiredWithoutSportsInput = {
  create?: Maybe<DirAthleteMedCertCreateWithoutSportsInput>;
  update?: Maybe<DirAthleteMedCertUpdateWithoutSportsDataInput>;
  upsert?: Maybe<DirAthleteMedCertUpsertWithoutSportsInput>;
  connect?: Maybe<DirAthleteMedCertWhereUniqueInput>;
};

export type DirAthleteMedCertUpdateWithoutSportsDataInput = {
  updatedAtReal?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  dirAthlete?: Maybe<DirAthleteUpdateOneRequiredWithoutMedCertsInput>;
  issueDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteMedCertUpsertWithoutSportsInput = {
  update: DirAthleteMedCertUpdateWithoutSportsDataInput;
  create: DirAthleteMedCertCreateWithoutSportsInput;
};

export type DirAthleteCompetitionResultCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  noResultReason?: Maybe<ClassifierValueCreateOneInput>;
  dirAthlete: DirAthleteCreateOneWithoutCompetetionResultInput;
  sportingEventProgramType: DirSportingEventProgramTypeCreateOneInput;
  point?: Maybe<Scalars['Int']>;
  pointTo?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  completedRank?: Maybe<Scalars['String']>;
};

export type DirAthleteCreateOneWithoutCompetetionResultInput = {
  create?: Maybe<DirAthleteCreateWithoutCompetetionResultInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteCreateWithoutCompetetionResultInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutAthleteProfileInput;
  dirPersonAgent?: Maybe<DirPersonCreateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueCreateOneInput>;
  sports?: Maybe<DirAthleteSportCreateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertCreateManyWithoutDirAthleteInput>;
};

export type DirAthleteCompetitionResultUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  noResultReason?: Maybe<ClassifierValueUpdateOneInput>;
  dirAthlete?: Maybe<DirAthleteUpdateOneRequiredWithoutCompetetionResultInput>;
  sportingEventProgramType?: Maybe<DirSportingEventProgramTypeUpdateOneRequiredInput>;
  point?: Maybe<Scalars['Int']>;
  pointTo?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  completedRank?: Maybe<Scalars['String']>;
};

export type DirAthleteUpdateOneRequiredWithoutCompetetionResultInput = {
  create?: Maybe<DirAthleteCreateWithoutCompetetionResultInput>;
  update?: Maybe<DirAthleteUpdateWithoutCompetetionResultDataInput>;
  upsert?: Maybe<DirAthleteUpsertWithoutCompetetionResultInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteUpdateWithoutCompetetionResultDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutAthleteProfileInput>;
  dirPersonAgent?: Maybe<DirPersonUpdateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueUpdateOneInput>;
  sports?: Maybe<DirAthleteSportUpdateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertUpdateManyWithoutDirAthleteInput>;
};

export type DirAthleteUpsertWithoutCompetetionResultInput = {
  update: DirAthleteUpdateWithoutCompetetionResultDataInput;
  create: DirAthleteCreateWithoutCompetetionResultInput;
};

export type DirTrainerUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutTrainerProfileInput>;
  sports?: Maybe<DirTrainerSportUpdateManyWithoutDirTrainerInput>;
};

export type DirTrainerSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer: DirTrainerCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  organizations?: Maybe<DirTrainerSportOrganizationCreateManyWithoutSportInput>;
  categories?: Maybe<DirTrainerSportCategoryCreateManyWithoutSportInput>;
};

export type DirTrainerCreateOneWithoutSportsInput = {
  create?: Maybe<DirTrainerCreateWithoutSportsInput>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirTrainerCreateWithoutSportsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutTrainerProfileInput;
};

export type DirTrainerSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer?: Maybe<DirTrainerUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  organizations?: Maybe<DirTrainerSportOrganizationUpdateManyWithoutSportInput>;
  categories?: Maybe<DirTrainerSportCategoryUpdateManyWithoutSportInput>;
};

export type DirTrainerUpdateOneRequiredWithoutSportsInput = {
  create?: Maybe<DirTrainerCreateWithoutSportsInput>;
  update?: Maybe<DirTrainerUpdateWithoutSportsDataInput>;
  upsert?: Maybe<DirTrainerUpsertWithoutSportsInput>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirTrainerUpdateWithoutSportsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutTrainerProfileInput>;
};

export type DirTrainerUpsertWithoutSportsInput = {
  update: DirTrainerUpdateWithoutSportsDataInput;
  create: DirTrainerCreateWithoutSportsInput;
};

export type DirTrainerSportOrganizationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneInput;
  clsPosition: ClassifierValueCreateOneInput;
  employmentDate: Scalars['DateTime'];
  dismissalDate?: Maybe<Scalars['DateTime']>;
  sport: DirTrainerSportCreateOneWithoutOrganizationsInput;
};

export type DirTrainerSportCreateOneWithoutOrganizationsInput = {
  create?: Maybe<DirTrainerSportCreateWithoutOrganizationsInput>;
  connect?: Maybe<DirTrainerSportWhereUniqueInput>;
};

export type DirTrainerSportCreateWithoutOrganizationsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer: DirTrainerCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  categories?: Maybe<DirTrainerSportCategoryCreateManyWithoutSportInput>;
};

export type DirTrainerSportOrganizationUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredInput>;
  clsPosition?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  employmentDate?: Maybe<Scalars['DateTime']>;
  dismissalDate?: Maybe<Scalars['DateTime']>;
  sport?: Maybe<DirTrainerSportUpdateOneRequiredWithoutOrganizationsInput>;
};

export type DirTrainerSportUpdateOneRequiredWithoutOrganizationsInput = {
  create?: Maybe<DirTrainerSportCreateWithoutOrganizationsInput>;
  update?: Maybe<DirTrainerSportUpdateWithoutOrganizationsDataInput>;
  upsert?: Maybe<DirTrainerSportUpsertWithoutOrganizationsInput>;
  connect?: Maybe<DirTrainerSportWhereUniqueInput>;
};

export type DirTrainerSportUpdateWithoutOrganizationsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer?: Maybe<DirTrainerUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  categories?: Maybe<DirTrainerSportCategoryUpdateManyWithoutSportInput>;
};

export type DirTrainerSportUpsertWithoutOrganizationsInput = {
  update: DirTrainerSportUpdateWithoutOrganizationsDataInput;
  create: DirTrainerSportCreateWithoutOrganizationsInput;
};

export type DirTrainerSportCategoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainerCategory: ClassifierValueCreateOneInput;
  assignmentDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  sport: DirTrainerSportCreateOneWithoutCategoriesInput;
};

export type DirTrainerSportCreateOneWithoutCategoriesInput = {
  create?: Maybe<DirTrainerSportCreateWithoutCategoriesInput>;
  connect?: Maybe<DirTrainerSportWhereUniqueInput>;
};

export type DirTrainerSportCreateWithoutCategoriesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer: DirTrainerCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  organizations?: Maybe<DirTrainerSportOrganizationCreateManyWithoutSportInput>;
};

export type DirTrainerSportCategoryUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainerCategory?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  sport?: Maybe<DirTrainerSportUpdateOneRequiredWithoutCategoriesInput>;
};

export type DirTrainerSportUpdateOneRequiredWithoutCategoriesInput = {
  create?: Maybe<DirTrainerSportCreateWithoutCategoriesInput>;
  update?: Maybe<DirTrainerSportUpdateWithoutCategoriesDataInput>;
  upsert?: Maybe<DirTrainerSportUpsertWithoutCategoriesInput>;
  connect?: Maybe<DirTrainerSportWhereUniqueInput>;
};

export type DirTrainerSportUpdateWithoutCategoriesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer?: Maybe<DirTrainerUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  organizations?: Maybe<DirTrainerSportOrganizationUpdateManyWithoutSportInput>;
};

export type DirTrainerSportUpsertWithoutCategoriesInput = {
  update: DirTrainerSportUpdateWithoutCategoriesDataInput;
  create: DirTrainerSportCreateWithoutCategoriesInput;
};

export type DirRefereeUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutRefereeProfileInput>;
  sports?: Maybe<DirRefereeSportUpdateManyWithoutDirRefereeInput>;
};

export type DirRefereeSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee: DirRefereeCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  workExperience?: Maybe<Scalars['Int']>;
  categories?: Maybe<DirRefereeSportCategoryCreateManyWithoutSportInput>;
};

export type DirRefereeCreateOneWithoutSportsInput = {
  create?: Maybe<DirRefereeCreateWithoutSportsInput>;
  connect?: Maybe<DirRefereeWhereUniqueInput>;
};

export type DirRefereeCreateWithoutSportsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutRefereeProfileInput;
};

export type DirRefereeSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee?: Maybe<DirRefereeUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  workExperience?: Maybe<Scalars['Int']>;
  categories?: Maybe<DirRefereeSportCategoryUpdateManyWithoutSportInput>;
};

export type DirRefereeUpdateOneRequiredWithoutSportsInput = {
  create?: Maybe<DirRefereeCreateWithoutSportsInput>;
  update?: Maybe<DirRefereeUpdateWithoutSportsDataInput>;
  upsert?: Maybe<DirRefereeUpsertWithoutSportsInput>;
  connect?: Maybe<DirRefereeWhereUniqueInput>;
};

export type DirRefereeUpdateWithoutSportsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutRefereeProfileInput>;
};

export type DirRefereeUpsertWithoutSportsInput = {
  update: DirRefereeUpdateWithoutSportsDataInput;
  create: DirRefereeCreateWithoutSportsInput;
};

export type DirRefereeSportCategoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sport: DirRefereeSportCreateOneWithoutCategoriesInput;
  clsRefereeCategory: ClassifierValueCreateOneInput;
  assignmentDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  confirmationDate?: Maybe<Scalars['DateTime']>;
  dirDocuments?: Maybe<DirDocumentCreateManyInput>;
};

export type DirRefereeSportCreateOneWithoutCategoriesInput = {
  create?: Maybe<DirRefereeSportCreateWithoutCategoriesInput>;
  connect?: Maybe<DirRefereeSportWhereUniqueInput>;
};

export type DirRefereeSportCreateWithoutCategoriesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee: DirRefereeCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  workExperience?: Maybe<Scalars['Int']>;
};

export type DirRefereeSportCategoryUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sport?: Maybe<DirRefereeSportUpdateOneRequiredWithoutCategoriesInput>;
  clsRefereeCategory?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  confirmationDate?: Maybe<Scalars['DateTime']>;
  dirDocuments?: Maybe<DirDocumentUpdateManyInput>;
};

export type DirRefereeSportUpdateOneRequiredWithoutCategoriesInput = {
  create?: Maybe<DirRefereeSportCreateWithoutCategoriesInput>;
  update?: Maybe<DirRefereeSportUpdateWithoutCategoriesDataInput>;
  upsert?: Maybe<DirRefereeSportUpsertWithoutCategoriesInput>;
  connect?: Maybe<DirRefereeSportWhereUniqueInput>;
};

export type DirRefereeSportUpdateWithoutCategoriesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee?: Maybe<DirRefereeUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  workExperience?: Maybe<Scalars['Int']>;
};

export type DirRefereeSportUpsertWithoutCategoriesInput = {
  update: DirRefereeSportUpdateWithoutCategoriesDataInput;
  create: DirRefereeSportCreateWithoutCategoriesInput;
};

export type DirSportingEventUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type QuantyRequestStageTwoCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  dirCalendar: DirCalendarCreateOneWithoutQuantyRequestsStageTwoInput;
  dirSport: DirSportCreateOneInput;
  dirRegion: DirRegionCreateOneInput;
  regionQuotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo?: Maybe<Scalars['Int']>;
};

export type DirCalendarCreateOneWithoutQuantyRequestsStageTwoInput = {
  create?: Maybe<DirCalendarCreateWithoutQuantyRequestsStageTwoInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarCreateWithoutQuantyRequestsStageTwoInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeCreateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueCreateOneInput>;
  clsSeason?: Maybe<ClassifierValueCreateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueCreateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionCreateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionCreateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionCreateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileCreateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsCreateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolCreateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileCreateManyInput>;
  stageNumber?: Maybe<ClassifierValueCreateOneInput>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportCreateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoCreateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportCreateManyWithoutDirCalendarInput>;
};

export type QuantyRequestStageTwoUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredWithoutQuantyRequestsStageTwoInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  regionQuotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  regionQuotaAccompStageTwo?: Maybe<Scalars['Int']>;
};

export type DirCalendarUpdateOneRequiredWithoutQuantyRequestsStageTwoInput = {
  create?: Maybe<DirCalendarCreateWithoutQuantyRequestsStageTwoInput>;
  update?: Maybe<DirCalendarUpdateWithoutQuantyRequestsStageTwoDataInput>;
  upsert?: Maybe<DirCalendarUpsertWithoutQuantyRequestsStageTwoInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarUpdateWithoutQuantyRequestsStageTwoDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeUpdateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueUpdateOneInput>;
  clsSeason?: Maybe<ClassifierValueUpdateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueUpdateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionUpdateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionUpdateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionUpdateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileUpdateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsUpdateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolUpdateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileUpdateManyInput>;
  stageNumber?: Maybe<ClassifierValueUpdateOneInput>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportUpdateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoUpdateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportUpdateManyWithoutDirCalendarInput>;
};

export type DirCalendarUpsertWithoutQuantyRequestsStageTwoInput = {
  update: DirCalendarUpdateWithoutQuantyRequestsStageTwoDataInput;
  create: DirCalendarCreateWithoutQuantyRequestsStageTwoInput;
};

export type DirSportingEventAthleteCallNotificationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  dirSportingEvent: DirSportingEventCreateOneWithoutAthleteCallNotificationsInput;
  document: DirDocumentCreateOneInput;
  teams?: Maybe<DirSportingEventAthleteCallNotificationTeamCreateManyWithoutNotificationInput>;
};

export type DirSportingEventCreateOneWithoutAthleteCallNotificationsInput = {
  create?: Maybe<DirSportingEventCreateWithoutAthleteCallNotificationsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutAthleteCallNotificationsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventAthleteCallNotificationUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirSportingEvent?: Maybe<DirSportingEventUpdateOneRequiredWithoutAthleteCallNotificationsInput>;
  document?: Maybe<DirDocumentUpdateOneRequiredInput>;
  teams?: Maybe<DirSportingEventAthleteCallNotificationTeamUpdateManyWithoutNotificationInput>;
};

export type DirSportingEventUpdateOneRequiredWithoutAthleteCallNotificationsInput = {
  create?: Maybe<DirSportingEventCreateWithoutAthleteCallNotificationsInput>;
  update?: Maybe<DirSportingEventUpdateWithoutAthleteCallNotificationsDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutAthleteCallNotificationsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutAthleteCallNotificationsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventUpsertWithoutAthleteCallNotificationsInput = {
  update: DirSportingEventUpdateWithoutAthleteCallNotificationsDataInput;
  create: DirSportingEventCreateWithoutAthleteCallNotificationsInput;
};

export type DirSportingEventAthleteCallNotificationTeamCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  notification: DirSportingEventAthleteCallNotificationCreateOneWithoutTeamsInput;
  ditNationalTeam: DirNationalTeamCreateOneInput;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventAthleteCallNotificationCreateOneWithoutTeamsInput = {
  create?: Maybe<DirSportingEventAthleteCallNotificationCreateWithoutTeamsInput>;
  connect?: Maybe<DirSportingEventAthleteCallNotificationWhereUniqueInput>;
};

export type DirSportingEventAthleteCallNotificationCreateWithoutTeamsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  dirSportingEvent: DirSportingEventCreateOneWithoutAthleteCallNotificationsInput;
  document: DirDocumentCreateOneInput;
};

export type DirSportingEventAthleteCallNotificationTeamUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  notification?: Maybe<DirSportingEventAthleteCallNotificationUpdateOneRequiredWithoutTeamsInput>;
  ditNationalTeam?: Maybe<DirNationalTeamUpdateOneRequiredInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventAthleteCallNotificationUpdateOneRequiredWithoutTeamsInput = {
  create?: Maybe<DirSportingEventAthleteCallNotificationCreateWithoutTeamsInput>;
  update?: Maybe<DirSportingEventAthleteCallNotificationUpdateWithoutTeamsDataInput>;
  upsert?: Maybe<DirSportingEventAthleteCallNotificationUpsertWithoutTeamsInput>;
  connect?: Maybe<DirSportingEventAthleteCallNotificationWhereUniqueInput>;
};

export type DirSportingEventAthleteCallNotificationUpdateWithoutTeamsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirSportingEvent?: Maybe<DirSportingEventUpdateOneRequiredWithoutAthleteCallNotificationsInput>;
  document?: Maybe<DirDocumentUpdateOneRequiredInput>;
};

export type DirSportingEventAthleteCallNotificationUpsertWithoutTeamsInput = {
  update: DirSportingEventAthleteCallNotificationUpdateWithoutTeamsDataInput;
  create: DirSportingEventAthleteCallNotificationCreateWithoutTeamsInput;
};

export type DirSportingEventCountryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEventCreateOneWithoutCountriesInput;
  dirCountry: DirCountryCreateOneInput;
  dirForeignCities?: Maybe<DirForeignCityCreateManyInput>;
};

export type DirSportingEventCreateOneWithoutCountriesInput = {
  create?: Maybe<DirSportingEventCreateWithoutCountriesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutCountriesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventCountryUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutCountriesInput>;
  dirCountry?: Maybe<DirCountryUpdateOneRequiredInput>;
  dirForeignCities?: Maybe<DirForeignCityUpdateManyInput>;
};

export type DirSportingEventUpdateOneRequiredWithoutCountriesInput = {
  create?: Maybe<DirSportingEventCreateWithoutCountriesInput>;
  update?: Maybe<DirSportingEventUpdateWithoutCountriesDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutCountriesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutCountriesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventUpsertWithoutCountriesInput = {
  update: DirSportingEventUpdateWithoutCountriesDataInput;
  create: DirSportingEventCreateWithoutCountriesInput;
};

export type DirSportingEventSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEventCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirSportingEventCreateOneWithoutSportsInput = {
  create?: Maybe<DirSportingEventCreateWithoutSportsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutSportsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirSportingEventUpdateOneRequiredWithoutSportsInput = {
  create?: Maybe<DirSportingEventCreateWithoutSportsInput>;
  update?: Maybe<DirSportingEventUpdateWithoutSportsDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutSportsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutSportsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventUpsertWithoutSportsInput = {
  update: DirSportingEventUpdateWithoutSportsDataInput;
  create: DirSportingEventCreateWithoutSportsInput;
};

export type DirSportingEventAgeGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEventCreateOneWithoutAgeGroupsInput;
  clsAgeGroup?: Maybe<ClassifierValueCreateOneInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirSportingEventCreateOneWithoutAgeGroupsInput = {
  create?: Maybe<DirSportingEventCreateWithoutAgeGroupsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutAgeGroupsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventAgeGroupUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutAgeGroupsInput>;
  clsAgeGroup?: Maybe<ClassifierValueUpdateOneInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirSportingEventUpdateOneRequiredWithoutAgeGroupsInput = {
  create?: Maybe<DirSportingEventCreateWithoutAgeGroupsInput>;
  update?: Maybe<DirSportingEventUpdateWithoutAgeGroupsDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutAgeGroupsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutAgeGroupsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventUpsertWithoutAgeGroupsInput = {
  update: DirSportingEventUpdateWithoutAgeGroupsDataInput;
  create: DirSportingEventCreateWithoutAgeGroupsInput;
};

export type DirSportingEventStatusUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventStatusHistoryUpdateInput = {
  date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  user?: Maybe<UserUpdateOneRequiredInput>;
};

export type DirSportingEventExtendedStatusCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  event: DirSportingEventCreateOneWithoutStatusesInput;
  value: DirSportingEventExtendedStatusValueCreateOneInput;
  file?: Maybe<FileCreateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventCreateOneWithoutStatusesInput = {
  create?: Maybe<DirSportingEventCreateWithoutStatusesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutStatusesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventExtendedStatusUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutStatusesInput>;
  value?: Maybe<DirSportingEventExtendedStatusValueUpdateOneRequiredInput>;
  file?: Maybe<FileUpdateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventUpdateOneRequiredWithoutStatusesInput = {
  create?: Maybe<DirSportingEventCreateWithoutStatusesInput>;
  update?: Maybe<DirSportingEventUpdateWithoutStatusesDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutStatusesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutStatusesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventUpsertWithoutStatusesInput = {
  update: DirSportingEventUpdateWithoutStatusesDataInput;
  create: DirSportingEventCreateWithoutStatusesInput;
};

export type DirSportingEventExtendedStatusValueUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventProgramTypeUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutProgramTypesInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  clsSportCategory?: Maybe<ClassifierValueUpdateOneInput>;
  resultsDocument?: Maybe<DirDocumentUpdateOneInput>;
  resultDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsAgeGroups?: Maybe<ClassifierValueUpdateManyInput>;
  discipline?: Maybe<DirSportDisciplineUpdateOneRequiredInput>;
};

export type DirGenderAndAgeGroupsCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  clsAgeGroups: ClassifierValueCreateOneInput;
  isMale?: Maybe<Scalars['Boolean']>;
};

export type DirGenderAndAgeGroupsUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  clsAgeGroups?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  isMale?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRefereeCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEventCreateOneWithoutRefereesInput;
  dirReferee: DirRefereeCreateOneInput;
  isPrimary?: Maybe<Scalars['Boolean']>;
  clsRefereePosition: ClassifierValueCreateOneInput;
  isNonresident?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventCreateOneWithoutRefereesInput = {
  create?: Maybe<DirSportingEventCreateWithoutRefereesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutRefereesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventRefereeUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutRefereesInput>;
  dirReferee?: Maybe<DirRefereeUpdateOneRequiredInput>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  clsRefereePosition?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  isNonresident?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventUpdateOneRequiredWithoutRefereesInput = {
  create?: Maybe<DirSportingEventCreateWithoutRefereesInput>;
  update?: Maybe<DirSportingEventUpdateWithoutRefereesDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutRefereesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutRefereesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventUpsertWithoutRefereesInput = {
  update: DirSportingEventUpdateWithoutRefereesDataInput;
  create: DirSportingEventCreateWithoutRefereesInput;
};

export type DirSportingEventRefereeStatusCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  event: DirSportingEventCreateOneWithoutRefereeStatusesInput;
  value: DirSportingEventRefereeStatusValueCreateOneInput;
  file?: Maybe<FileCreateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventCreateOneWithoutRefereeStatusesInput = {
  create?: Maybe<DirSportingEventCreateWithoutRefereeStatusesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutRefereeStatusesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventRefereeStatusUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutRefereeStatusesInput>;
  value?: Maybe<DirSportingEventRefereeStatusValueUpdateOneRequiredInput>;
  file?: Maybe<FileUpdateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventUpdateOneRequiredWithoutRefereeStatusesInput = {
  create?: Maybe<DirSportingEventCreateWithoutRefereeStatusesInput>;
  update?: Maybe<DirSportingEventUpdateWithoutRefereeStatusesDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutRefereeStatusesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutRefereeStatusesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventUpsertWithoutRefereeStatusesInput = {
  update: DirSportingEventUpdateWithoutRefereeStatusesDataInput;
  create: DirSportingEventCreateWithoutRefereeStatusesInput;
};

export type DirSportingEventRefereeStatusValueUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventCompetitionCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEventCreateOneWithoutCompetitionCalendarInput;
  day?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  discipline?: Maybe<DirSportDisciplineCreateOneInput>;
};

export type DirSportingEventCreateOneWithoutCompetitionCalendarInput = {
  create?: Maybe<DirSportingEventCreateWithoutCompetitionCalendarInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutCompetitionCalendarInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventCompetitionCalendarUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutCompetitionCalendarInput>;
  day?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  discipline?: Maybe<DirSportDisciplineUpdateOneInput>;
};

export type DirSportingEventUpdateOneRequiredWithoutCompetitionCalendarInput = {
  create?: Maybe<DirSportingEventCreateWithoutCompetitionCalendarInput>;
  update?: Maybe<DirSportingEventUpdateWithoutCompetitionCalendarDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutCompetitionCalendarInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutCompetitionCalendarDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventUpsertWithoutCompetitionCalendarInput = {
  update: DirSportingEventUpdateWithoutCompetitionCalendarDataInput;
  create: DirSportingEventCreateWithoutCompetitionCalendarInput;
};

export type DirSportingEventCostUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  clsFinancingTypes?: Maybe<ClassifierValueUpdateManyInput>;
};

export type DirSportingEventQuotaUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  athletesMan?: Maybe<Scalars['Int']>;
  athletesWoman?: Maybe<Scalars['Int']>;
  trainers?: Maybe<Scalars['Int']>;
  referees?: Maybe<Scalars['Int']>;
  administration?: Maybe<Scalars['Int']>;
  medicalStaff?: Maybe<Scalars['Int']>;
  specialists?: Maybe<Scalars['Int']>;
  teams?: Maybe<Scalars['Int']>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsTeamComposition?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventQuantityUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  participantAthletesTotal?: Maybe<Scalars['Int']>;
  refereesTotal?: Maybe<Scalars['Int']>;
  staffTotal?: Maybe<Scalars['Int']>;
  audienceTotal?: Maybe<Scalars['Int']>;
};

export type DirSportingEventPointDistributionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  event: DirSportingEventCreateOneWithoutPointsDistributionInput;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  lastPlacesPoints?: Maybe<Scalars['Int']>;
  lastPlacesStart?: Maybe<Scalars['Int']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  clsAgeGroup?: Maybe<ClassifierValueCreateOneInput>;
  places?: Maybe<DirSportingEventPointDistributionPlaceCreateManyWithoutPointDistributionInput>;
};

export type DirSportingEventCreateOneWithoutPointsDistributionInput = {
  create?: Maybe<DirSportingEventCreateWithoutPointsDistributionInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutPointsDistributionInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  requests?: Maybe<DirSportingEventRequestCreateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventPointDistributionUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutPointsDistributionInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  lastPlacesPoints?: Maybe<Scalars['Int']>;
  lastPlacesStart?: Maybe<Scalars['Int']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  clsAgeGroup?: Maybe<ClassifierValueUpdateOneInput>;
  places?: Maybe<DirSportingEventPointDistributionPlaceUpdateManyWithoutPointDistributionInput>;
};

export type DirSportingEventUpdateOneRequiredWithoutPointsDistributionInput = {
  create?: Maybe<DirSportingEventCreateWithoutPointsDistributionInput>;
  update?: Maybe<DirSportingEventUpdateWithoutPointsDistributionDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutPointsDistributionInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutPointsDistributionDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  requests?: Maybe<DirSportingEventRequestUpdateManyWithoutEventInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventUpsertWithoutPointsDistributionInput = {
  update: DirSportingEventUpdateWithoutPointsDistributionDataInput;
  create: DirSportingEventCreateWithoutPointsDistributionInput;
};

export type DirSportingEventPointDistributionPlaceCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  pointDistribution: DirSportingEventPointDistributionCreateOneWithoutPlacesInput;
  place: Scalars['Int'];
  points: Scalars['Int'];
};

export type DirSportingEventPointDistributionCreateOneWithoutPlacesInput = {
  create?: Maybe<DirSportingEventPointDistributionCreateWithoutPlacesInput>;
  connect?: Maybe<DirSportingEventPointDistributionWhereUniqueInput>;
};

export type DirSportingEventPointDistributionCreateWithoutPlacesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  event: DirSportingEventCreateOneWithoutPointsDistributionInput;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  lastPlacesPoints?: Maybe<Scalars['Int']>;
  lastPlacesStart?: Maybe<Scalars['Int']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  clsAgeGroup?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventPointDistributionPlaceUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  pointDistribution?: Maybe<DirSportingEventPointDistributionUpdateOneRequiredWithoutPlacesInput>;
  place?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
};

export type DirSportingEventPointDistributionUpdateOneRequiredWithoutPlacesInput = {
  create?: Maybe<DirSportingEventPointDistributionCreateWithoutPlacesInput>;
  update?: Maybe<DirSportingEventPointDistributionUpdateWithoutPlacesDataInput>;
  upsert?: Maybe<DirSportingEventPointDistributionUpsertWithoutPlacesInput>;
  connect?: Maybe<DirSportingEventPointDistributionWhereUniqueInput>;
};

export type DirSportingEventPointDistributionUpdateWithoutPlacesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutPointsDistributionInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  lastPlacesPoints?: Maybe<Scalars['Int']>;
  lastPlacesStart?: Maybe<Scalars['Int']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  clsAgeGroup?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventPointDistributionUpsertWithoutPlacesInput = {
  update: DirSportingEventPointDistributionUpdateWithoutPlacesDataInput;
  create: DirSportingEventPointDistributionCreateWithoutPlacesInput;
};

export type DirSportingEventRequestCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  event: DirSportingEventCreateOneWithoutRequestsInput;
  orderNumber?: Maybe<Scalars['Int']>;
  type: DirSportingEventRequestTypeCreateOneInput;
  statuses?: Maybe<DirSportingEventRequestStatusCreateManyWithoutRequestInput>;
};

export type DirSportingEventCreateOneWithoutRequestsInput = {
  create?: Maybe<DirSportingEventCreateWithoutRequestsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutRequestsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionCreateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictCreateManyInput>;
  countries?: Maybe<DirSportingEventCountryCreateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  cost?: Maybe<DirSportingEventCostCreateOneInput>;
  quota?: Maybe<DirSportingEventQuotaCreateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityCreateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventCreateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventCreateManyWithoutProxyInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusCreateManyWithoutEventInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizers?: Maybe<DirOrganizationCreateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueCreateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueCreateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusCreateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocolFiles?: Maybe<FileCreateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentCreateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentCreateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentCreateManyInput>;
  mainJuryReports?: Maybe<DirDocumentCreateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentCreateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationCreateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupCreateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupCreateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventRequestUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutRequestsInput>;
  orderNumber?: Maybe<Scalars['Int']>;
  type?: Maybe<DirSportingEventRequestTypeUpdateOneRequiredInput>;
  statuses?: Maybe<DirSportingEventRequestStatusUpdateManyWithoutRequestInput>;
};

export type DirSportingEventUpdateOneRequiredWithoutRequestsInput = {
  create?: Maybe<DirSportingEventCreateWithoutRequestsInput>;
  update?: Maybe<DirSportingEventUpdateWithoutRequestsDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutRequestsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutRequestsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  oldRegistryNumber?: Maybe<Scalars['Int']>;
  athletesQuotaNotCorrespondingByQualification?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  properNoun?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  daysBeforeStartToAddProtocols?: Maybe<Scalars['Int']>;
  athletesTrainersRefereesSpecialistsCount?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  athletesQuotaNotCorrespondingByAge?: Maybe<Scalars['Int']>;
  federalBudgetFinancing?: Maybe<Scalars['Boolean']>;
  includingTrainingActivities?: Maybe<Scalars['Boolean']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegions?: Maybe<DirRegionUpdateManyInput>;
  dirFederalDistricts?: Maybe<DirFederalDistrictUpdateManyInput>;
  countries?: Maybe<DirSportingEventCountryUpdateManyWithoutEventInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  cost?: Maybe<DirSportingEventCostUpdateOneInput>;
  quota?: Maybe<DirSportingEventQuotaUpdateOneInput>;
  quantity?: Maybe<DirSportingEventQuantityUpdateOneInput>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  isSportingQualificationRangeOrSpecificValues?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  proxy?: Maybe<DirSportingEventUpdateOneWithoutChangesInput>;
  changes?: Maybe<DirSportingEventUpdateManyWithoutProxyInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirSportingEventExtendedStatusUpdateManyWithoutEventInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizers?: Maybe<DirOrganizationUpdateManyInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsAvailableSportingQualifications?: Maybe<ClassifierValueUpdateManyInput>;
  clsSportingQualificationNotGreater?: Maybe<ClassifierValueUpdateOneInput>;
  pointsDistribution?: Maybe<DirSportingEventPointDistributionUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  refereeStatuses?: Maybe<DirSportingEventRefereeStatusUpdateManyWithoutEventInput>;
  credentialsCommitteeProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocolFiles?: Maybe<FileUpdateManyInput>;
  finalProtocolDocuments?: Maybe<DirDocumentUpdateManyInput>;
  commissionAdmissionDecisions?: Maybe<DirDocumentUpdateManyInput>;
  competitionFinalProtocols?: Maybe<DirDocumentUpdateManyInput>;
  mainJuryReports?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallDocuments?: Maybe<DirDocumentUpdateManyInput>;
  athleteCallNotifications?: Maybe<DirSportingEventAthleteCallNotificationUpdateManyWithoutDirSportingEventInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  ageGroupMale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  ageGroupFemale?: Maybe<DirSportAgeGroupUpdateOneInput>;
  sportQualificationMaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
  sportQualificationFemaleNotLower?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirSportingEventUpsertWithoutRequestsInput = {
  update: DirSportingEventUpdateWithoutRequestsDataInput;
  create: DirSportingEventCreateWithoutRequestsInput;
};

export type DirSportingEventRequestTypeUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventRequestStatusCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  request: DirSportingEventRequestCreateOneWithoutStatusesInput;
  value: DirSportingEventRequestStatusValueCreateOneInput;
  file?: Maybe<FileCreateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRequestCreateOneWithoutStatusesInput = {
  create?: Maybe<DirSportingEventRequestCreateWithoutStatusesInput>;
  connect?: Maybe<DirSportingEventRequestWhereUniqueInput>;
};

export type DirSportingEventRequestCreateWithoutStatusesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  event: DirSportingEventCreateOneWithoutRequestsInput;
  orderNumber?: Maybe<Scalars['Int']>;
  type: DirSportingEventRequestTypeCreateOneInput;
};

export type DirSportingEventRequestStatusUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  request?: Maybe<DirSportingEventRequestUpdateOneRequiredWithoutStatusesInput>;
  value?: Maybe<DirSportingEventRequestStatusValueUpdateOneRequiredInput>;
  file?: Maybe<FileUpdateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventRequestUpdateOneRequiredWithoutStatusesInput = {
  create?: Maybe<DirSportingEventRequestCreateWithoutStatusesInput>;
  update?: Maybe<DirSportingEventRequestUpdateWithoutStatusesDataInput>;
  upsert?: Maybe<DirSportingEventRequestUpsertWithoutStatusesInput>;
  connect?: Maybe<DirSportingEventRequestWhereUniqueInput>;
};

export type DirSportingEventRequestUpdateWithoutStatusesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutRequestsInput>;
  orderNumber?: Maybe<Scalars['Int']>;
  type?: Maybe<DirSportingEventRequestTypeUpdateOneRequiredInput>;
};

export type DirSportingEventRequestUpsertWithoutStatusesInput = {
  update: DirSportingEventRequestUpdateWithoutStatusesDataInput;
  create: DirSportingEventRequestCreateWithoutStatusesInput;
};

export type DirSportingEventRequestStatusValueUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirNationalTeamUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirNationalTeamExtendedStatusUpdateManyWithoutTeamInput>;
  dirSportingEvent?: Maybe<DirSportingEventUpdateOneRequiredWithoutDirNationalTeamsInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  trainers?: Maybe<DirNationalTeamTrainerUpdateManyWithoutTeamInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupUpdateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  medics?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  delegationHeads?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  emailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutTeamInput>;
  files?: Maybe<FileUpdateManyInput>;
  university?: Maybe<DirOrganizationUpdateOneInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  arrivalDeparturePlan?: Maybe<FileUpdateManyInput>;
  preliminaryQuantitativeOrder?: Maybe<FileUpdateManyInput>;
};

export type DirNationalTeamEmailRecipientCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
  team: DirNationalTeamCreateOneWithoutEmailRecipientsInput;
  email: Scalars['String'];
  dirPerson?: Maybe<DirPersonCreateOneWithoutNationalTeamEmailRecipientsInput>;
  comment?: Maybe<Scalars['String']>;
};

export type DirNationalTeamEmailRecipientUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotificationRequired?: Maybe<Scalars['Boolean']>;
  team?: Maybe<DirNationalTeamUpdateOneRequiredWithoutEmailRecipientsInput>;
  email?: Maybe<Scalars['String']>;
  dirPerson?: Maybe<DirPersonUpdateOneWithoutNationalTeamEmailRecipientsInput>;
  comment?: Maybe<Scalars['String']>;
};

export type DirNationalTeamStatusUpdateInput = {
  name?: Maybe<Scalars['String']>;
  isDeprecated?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamStatusHistoryUpdateInput = {
  date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  user?: Maybe<UserUpdateOneRequiredInput>;
};

export type DirNationalTeamExtendedStatusCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  team: DirNationalTeamCreateOneWithoutStatusesInput;
  value: DirNationalTeamStatusCreateOneInput;
  file?: Maybe<FileCreateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamCreateOneWithoutStatusesInput = {
  create?: Maybe<DirNationalTeamCreateWithoutStatusesInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamCreateWithoutStatusesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota: Scalars['Int'];
  womanTeamQuota: Scalars['Int'];
  supportTeamQuota: Scalars['Int'];
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status: DirNationalTeamStatusCreateOneInput;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryCreateManyInput>;
  dirSportingEvent: DirSportingEventCreateOneWithoutDirNationalTeamsInput;
  dirRegion: DirRegionCreateOneInput;
  trainers?: Maybe<DirNationalTeamTrainerCreateManyWithoutTeamInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupCreateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberCreateManyInput>;
  medics?: Maybe<DirNationalTeamMemberCreateManyInput>;
  delegationHeads?: Maybe<DirNationalTeamMemberCreateManyInput>;
  emailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutTeamInput>;
  files?: Maybe<FileCreateManyInput>;
  university?: Maybe<DirOrganizationCreateOneInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  arrivalDeparturePlan?: Maybe<FileCreateManyInput>;
  preliminaryQuantitativeOrder?: Maybe<FileCreateManyInput>;
};

export type DirNationalTeamExtendedStatusUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  team?: Maybe<DirNationalTeamUpdateOneRequiredWithoutStatusesInput>;
  value?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  file?: Maybe<FileUpdateOneInput>;
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamUpdateOneRequiredWithoutStatusesInput = {
  create?: Maybe<DirNationalTeamCreateWithoutStatusesInput>;
  update?: Maybe<DirNationalTeamUpdateWithoutStatusesDataInput>;
  upsert?: Maybe<DirNationalTeamUpsertWithoutStatusesInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamUpdateWithoutStatusesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryUpdateManyInput>;
  dirSportingEvent?: Maybe<DirSportingEventUpdateOneRequiredWithoutDirNationalTeamsInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  trainers?: Maybe<DirNationalTeamTrainerUpdateManyWithoutTeamInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupUpdateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  medics?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  delegationHeads?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  emailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutTeamInput>;
  files?: Maybe<FileUpdateManyInput>;
  university?: Maybe<DirOrganizationUpdateOneInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  arrivalDeparturePlan?: Maybe<FileUpdateManyInput>;
  preliminaryQuantitativeOrder?: Maybe<FileUpdateManyInput>;
};

export type DirNationalTeamUpsertWithoutStatusesInput = {
  update: DirNationalTeamUpdateWithoutStatusesDataInput;
  create: DirNationalTeamCreateWithoutStatusesInput;
};

export type DirNationalTeamTrainerCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  team: DirNationalTeamCreateOneWithoutTrainersInput;
  isPrimary?: Maybe<Scalars['Boolean']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
  dirTrainer: DirTrainerCreateOneInput;
  clsPosition?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirNationalTeamCreateOneWithoutTrainersInput = {
  create?: Maybe<DirNationalTeamCreateWithoutTrainersInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamCreateWithoutTrainersInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota: Scalars['Int'];
  womanTeamQuota: Scalars['Int'];
  supportTeamQuota: Scalars['Int'];
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status: DirNationalTeamStatusCreateOneInput;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirNationalTeamExtendedStatusCreateManyWithoutTeamInput>;
  dirSportingEvent: DirSportingEventCreateOneWithoutDirNationalTeamsInput;
  dirRegion: DirRegionCreateOneInput;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupCreateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberCreateManyInput>;
  medics?: Maybe<DirNationalTeamMemberCreateManyInput>;
  delegationHeads?: Maybe<DirNationalTeamMemberCreateManyInput>;
  emailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutTeamInput>;
  files?: Maybe<FileCreateManyInput>;
  university?: Maybe<DirOrganizationCreateOneInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  arrivalDeparturePlan?: Maybe<FileCreateManyInput>;
  preliminaryQuantitativeOrder?: Maybe<FileCreateManyInput>;
};

export type DirNationalTeamTrainerUpdateInput = {
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  team?: Maybe<DirNationalTeamUpdateOneRequiredWithoutTrainersInput>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
  dirTrainer?: Maybe<DirTrainerUpdateOneRequiredInput>;
  clsPosition?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirNationalTeamUpdateOneRequiredWithoutTrainersInput = {
  create?: Maybe<DirNationalTeamCreateWithoutTrainersInput>;
  update?: Maybe<DirNationalTeamUpdateWithoutTrainersDataInput>;
  upsert?: Maybe<DirNationalTeamUpsertWithoutTrainersInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamUpdateWithoutTrainersDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirNationalTeamExtendedStatusUpdateManyWithoutTeamInput>;
  dirSportingEvent?: Maybe<DirSportingEventUpdateOneRequiredWithoutDirNationalTeamsInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupUpdateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  medics?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  delegationHeads?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  emailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutTeamInput>;
  files?: Maybe<FileUpdateManyInput>;
  university?: Maybe<DirOrganizationUpdateOneInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  arrivalDeparturePlan?: Maybe<FileUpdateManyInput>;
  preliminaryQuantitativeOrder?: Maybe<FileUpdateManyInput>;
};

export type DirNationalTeamUpsertWithoutTrainersInput = {
  update: DirNationalTeamUpdateWithoutTrainersDataInput;
  create: DirNationalTeamCreateWithoutTrainersInput;
};

export type DirNationalTeamAthleteGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  team: DirNationalTeamCreateOneWithoutAthleteGroupsInput;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  athletes?: Maybe<DirNationalTeamAthleteCreateManyWithoutGroupInput>;
};

export type DirNationalTeamCreateOneWithoutAthleteGroupsInput = {
  create?: Maybe<DirNationalTeamCreateWithoutAthleteGroupsInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamCreateWithoutAthleteGroupsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota: Scalars['Int'];
  womanTeamQuota: Scalars['Int'];
  supportTeamQuota: Scalars['Int'];
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status: DirNationalTeamStatusCreateOneInput;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryCreateManyInput>;
  statuses?: Maybe<DirNationalTeamExtendedStatusCreateManyWithoutTeamInput>;
  dirSportingEvent: DirSportingEventCreateOneWithoutDirNationalTeamsInput;
  dirRegion: DirRegionCreateOneInput;
  trainers?: Maybe<DirNationalTeamTrainerCreateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberCreateManyInput>;
  medics?: Maybe<DirNationalTeamMemberCreateManyInput>;
  delegationHeads?: Maybe<DirNationalTeamMemberCreateManyInput>;
  emailRecipients?: Maybe<DirNationalTeamEmailRecipientCreateManyWithoutTeamInput>;
  files?: Maybe<FileCreateManyInput>;
  university?: Maybe<DirOrganizationCreateOneInput>;
  refereePersonalDataAgreements?: Maybe<FileCreateManyInput>;
  arrivalDeparturePlan?: Maybe<FileCreateManyInput>;
  preliminaryQuantitativeOrder?: Maybe<FileCreateManyInput>;
};

export type DirNationalTeamAthleteGroupUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  team?: Maybe<DirNationalTeamUpdateOneRequiredWithoutAthleteGroupsInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  athletes?: Maybe<DirNationalTeamAthleteUpdateManyWithoutGroupInput>;
};

export type DirNationalTeamUpdateOneRequiredWithoutAthleteGroupsInput = {
  create?: Maybe<DirNationalTeamCreateWithoutAthleteGroupsInput>;
  update?: Maybe<DirNationalTeamUpdateWithoutAthleteGroupsDataInput>;
  upsert?: Maybe<DirNationalTeamUpsertWithoutAthleteGroupsInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamUpdateWithoutAthleteGroupsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  athleteQuotaByGender?: Maybe<Scalars['Boolean']>;
  athleteTotalQuota?: Maybe<Scalars['Int']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryUpdateManyInput>;
  statuses?: Maybe<DirNationalTeamExtendedStatusUpdateManyWithoutTeamInput>;
  dirSportingEvent?: Maybe<DirSportingEventUpdateOneRequiredWithoutDirNationalTeamsInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  trainers?: Maybe<DirNationalTeamTrainerUpdateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  medics?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  delegationHeads?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  emailRecipients?: Maybe<DirNationalTeamEmailRecipientUpdateManyWithoutTeamInput>;
  files?: Maybe<FileUpdateManyInput>;
  university?: Maybe<DirOrganizationUpdateOneInput>;
  refereePersonalDataAgreements?: Maybe<FileUpdateManyInput>;
  arrivalDeparturePlan?: Maybe<FileUpdateManyInput>;
  preliminaryQuantitativeOrder?: Maybe<FileUpdateManyInput>;
};

export type DirNationalTeamUpsertWithoutAthleteGroupsInput = {
  update: DirNationalTeamUpdateWithoutAthleteGroupsDataInput;
  create: DirNationalTeamCreateWithoutAthleteGroupsInput;
};

export type DirNationalTeamAthleteCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  group: DirNationalTeamAthleteGroupCreateOneWithoutAthletesInput;
  dirAthlete: DirAthleteCreateOneInput;
  bestResult?: Maybe<Scalars['String']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamAthleteGroupCreateOneWithoutAthletesInput = {
  create?: Maybe<DirNationalTeamAthleteGroupCreateWithoutAthletesInput>;
  connect?: Maybe<DirNationalTeamAthleteGroupWhereUniqueInput>;
};

export type DirNationalTeamAthleteGroupCreateWithoutAthletesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  team: DirNationalTeamCreateOneWithoutAthleteGroupsInput;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirNationalTeamAthleteUpdateInput = {
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  group?: Maybe<DirNationalTeamAthleteGroupUpdateOneRequiredWithoutAthletesInput>;
  dirAthlete?: Maybe<DirAthleteUpdateOneRequiredInput>;
  bestResult?: Maybe<Scalars['String']>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
};

export type DirNationalTeamAthleteGroupUpdateOneRequiredWithoutAthletesInput = {
  create?: Maybe<DirNationalTeamAthleteGroupCreateWithoutAthletesInput>;
  update?: Maybe<DirNationalTeamAthleteGroupUpdateWithoutAthletesDataInput>;
  upsert?: Maybe<DirNationalTeamAthleteGroupUpsertWithoutAthletesInput>;
  connect?: Maybe<DirNationalTeamAthleteGroupWhereUniqueInput>;
};

export type DirNationalTeamAthleteGroupUpdateWithoutAthletesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  team?: Maybe<DirNationalTeamUpdateOneRequiredWithoutAthleteGroupsInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirNationalTeamAthleteGroupUpsertWithoutAthletesInput = {
  update: DirNationalTeamAthleteGroupUpdateWithoutAthletesDataInput;
  create: DirNationalTeamAthleteGroupCreateWithoutAthletesInput;
};

export type DirNationalTeamMemberUpdateInput = {
  admissionDate?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  clsPosition?: Maybe<ClassifierValueUpdateOneInput>;
  entranceStatus?: Maybe<Scalars['Boolean']>;
};

export type DirCalendarUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeUpdateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueUpdateOneInput>;
  clsSeason?: Maybe<ClassifierValueUpdateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueUpdateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionUpdateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionUpdateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionUpdateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileUpdateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsUpdateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolUpdateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileUpdateManyInput>;
  stageNumber?: Maybe<ClassifierValueUpdateOneInput>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportUpdateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoUpdateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportUpdateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoUpdateManyWithoutDirCalendarInput>;
};

export type QuotaForStageTwoBySportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  dirCalendar: DirCalendarCreateOneWithoutQuotasForStageTwoBySportInput;
  dirSport: DirSportCreateOneInput;
  quotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo?: Maybe<Scalars['Int']>;
};

export type DirCalendarCreateOneWithoutQuotasForStageTwoBySportInput = {
  create?: Maybe<DirCalendarCreateWithoutQuotasForStageTwoBySportInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarCreateWithoutQuotasForStageTwoBySportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeCreateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueCreateOneInput>;
  clsSeason?: Maybe<ClassifierValueCreateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueCreateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionCreateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionCreateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionCreateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileCreateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsCreateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolCreateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileCreateManyInput>;
  stageNumber?: Maybe<ClassifierValueCreateOneInput>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportCreateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoCreateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoCreateManyWithoutDirCalendarInput>;
};

export type QuotaForStageTwoBySportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredWithoutQuotasForStageTwoBySportInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  quotaMaleAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaWomenAthlStageTwo?: Maybe<Scalars['Int']>;
  quotaAccompStageTwo?: Maybe<Scalars['Int']>;
};

export type DirCalendarUpdateOneRequiredWithoutQuotasForStageTwoBySportInput = {
  create?: Maybe<DirCalendarCreateWithoutQuotasForStageTwoBySportInput>;
  update?: Maybe<DirCalendarUpdateWithoutQuotasForStageTwoBySportDataInput>;
  upsert?: Maybe<DirCalendarUpsertWithoutQuotasForStageTwoBySportInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarUpdateWithoutQuotasForStageTwoBySportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeUpdateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueUpdateOneInput>;
  clsSeason?: Maybe<ClassifierValueUpdateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueUpdateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionUpdateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionUpdateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionUpdateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileUpdateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsUpdateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolUpdateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileUpdateManyInput>;
  stageNumber?: Maybe<ClassifierValueUpdateOneInput>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportUpdateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoUpdateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoUpdateManyWithoutDirCalendarInput>;
};

export type DirCalendarUpsertWithoutQuotasForStageTwoBySportInput = {
  update: DirCalendarUpdateWithoutQuotasForStageTwoBySportDataInput;
  create: DirCalendarCreateWithoutQuotasForStageTwoBySportInput;
};

export type ReqPartFromStageTwoCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  region: DirRegionCreateOneInput;
  dirCalendar: DirCalendarCreateOneWithoutReqPartFromStageTwoInput;
  roivSpecialistsForNotification?: Maybe<UserCreateManyInput>;
  filesROIVStageTwo?: Maybe<FileCreateManyInput>;
};

export type DirCalendarCreateOneWithoutReqPartFromStageTwoInput = {
  create?: Maybe<DirCalendarCreateWithoutReqPartFromStageTwoInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarCreateWithoutReqPartFromStageTwoInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeCreateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueCreateOneInput>;
  clsSeason?: Maybe<ClassifierValueCreateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueCreateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionCreateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionCreateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionCreateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileCreateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsCreateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolCreateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileCreateManyInput>;
  stageNumber?: Maybe<ClassifierValueCreateOneInput>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportCreateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportCreateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoCreateManyWithoutDirCalendarInput>;
};

export type ReqPartFromStageTwoUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  region?: Maybe<DirRegionUpdateOneRequiredInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredWithoutReqPartFromStageTwoInput>;
  roivSpecialistsForNotification?: Maybe<UserUpdateManyInput>;
  filesROIVStageTwo?: Maybe<FileUpdateManyInput>;
};

export type DirCalendarUpdateOneRequiredWithoutReqPartFromStageTwoInput = {
  create?: Maybe<DirCalendarCreateWithoutReqPartFromStageTwoInput>;
  update?: Maybe<DirCalendarUpdateWithoutReqPartFromStageTwoDataInput>;
  upsert?: Maybe<DirCalendarUpsertWithoutReqPartFromStageTwoInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarUpdateWithoutReqPartFromStageTwoDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeUpdateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueUpdateOneInput>;
  clsSeason?: Maybe<ClassifierValueUpdateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueUpdateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionUpdateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionUpdateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionUpdateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileUpdateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsUpdateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolUpdateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileUpdateManyInput>;
  stageNumber?: Maybe<ClassifierValueUpdateOneInput>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportUpdateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportUpdateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoUpdateManyWithoutDirCalendarInput>;
};

export type DirCalendarUpsertWithoutReqPartFromStageTwoInput = {
  update: DirCalendarUpdateWithoutReqPartFromStageTwoDataInput;
  create: DirCalendarCreateWithoutReqPartFromStageTwoInput;
};

export type VenueCompetitionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  dirCalendar: DirCalendarCreateOneWithoutVenueCompetitionInput;
  dirDocument?: Maybe<DirDocumentCreateOneInput>;
  venueCompDecisionDoc?: Maybe<DirDocumentCreateOneInput>;
  venueCompRegulationDocAdds?: Maybe<FileCreateManyInput>;
  venueCompDecisionDocAdds?: Maybe<FileCreateManyInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  emailSenderFCPSR?: Maybe<UserCreateOneInput>;
  venueCompApplications?: Maybe<VenueCompApplicationCreateManyWithoutVenueCompetitionInput>;
};

export type DirCalendarCreateOneWithoutVenueCompetitionInput = {
  create?: Maybe<DirCalendarCreateWithoutVenueCompetitionInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarCreateWithoutVenueCompetitionInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeCreateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueCreateOneInput>;
  clsSeason?: Maybe<ClassifierValueCreateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueCreateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionCreateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionCreateManyWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileCreateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsCreateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolCreateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileCreateManyInput>;
  stageNumber?: Maybe<ClassifierValueCreateOneInput>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportCreateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoCreateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportCreateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoCreateManyWithoutDirCalendarInput>;
};

export type VenueCompetitionUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredWithoutVenueCompetitionInput>;
  dirDocument?: Maybe<DirDocumentUpdateOneInput>;
  venueCompDecisionDoc?: Maybe<DirDocumentUpdateOneInput>;
  venueCompRegulationDocAdds?: Maybe<FileUpdateManyInput>;
  venueCompDecisionDocAdds?: Maybe<FileUpdateManyInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  emailSenderFCPSR?: Maybe<UserUpdateOneInput>;
  venueCompApplications?: Maybe<VenueCompApplicationUpdateManyWithoutVenueCompetitionInput>;
};

export type DirCalendarUpdateOneRequiredWithoutVenueCompetitionInput = {
  create?: Maybe<DirCalendarCreateWithoutVenueCompetitionInput>;
  update?: Maybe<DirCalendarUpdateWithoutVenueCompetitionDataInput>;
  upsert?: Maybe<DirCalendarUpsertWithoutVenueCompetitionInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarUpdateWithoutVenueCompetitionDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeUpdateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueUpdateOneInput>;
  clsSeason?: Maybe<ClassifierValueUpdateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueUpdateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionUpdateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionUpdateManyWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileUpdateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsUpdateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolUpdateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileUpdateManyInput>;
  stageNumber?: Maybe<ClassifierValueUpdateOneInput>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  signedReports?: Maybe<DirCalendarSignedReportUpdateManyWithoutDirCalendarSignedReportInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoUpdateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportUpdateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoUpdateManyWithoutDirCalendarInput>;
};

export type DirCalendarUpsertWithoutVenueCompetitionInput = {
  update: DirCalendarUpdateWithoutVenueCompetitionDataInput;
  create: DirCalendarCreateWithoutVenueCompetitionInput;
};

export type VenueCompApplicationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  venueCompetition: VenueCompetitionCreateOneWithoutVenueCompApplicationsInput;
  dirRegion: DirRegionCreateOneInput;
  number?: Maybe<Scalars['Int']>;
  applicationFiles?: Maybe<FileCreateManyInput>;
  notificationSentToFCPSR: Scalars['Boolean'];
};

export type VenueCompetitionCreateOneWithoutVenueCompApplicationsInput = {
  create?: Maybe<VenueCompetitionCreateWithoutVenueCompApplicationsInput>;
  connect?: Maybe<VenueCompetitionWhereUniqueInput>;
};

export type VenueCompetitionCreateWithoutVenueCompApplicationsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  dirCalendar: DirCalendarCreateOneWithoutVenueCompetitionInput;
  dirDocument?: Maybe<DirDocumentCreateOneInput>;
  venueCompDecisionDoc?: Maybe<DirDocumentCreateOneInput>;
  venueCompRegulationDocAdds?: Maybe<FileCreateManyInput>;
  venueCompDecisionDocAdds?: Maybe<FileCreateManyInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  emailSenderFCPSR?: Maybe<UserCreateOneInput>;
};

export type VenueCompApplicationUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  venueCompetition?: Maybe<VenueCompetitionUpdateOneRequiredWithoutVenueCompApplicationsInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  number?: Maybe<Scalars['Int']>;
  applicationFiles?: Maybe<FileUpdateManyInput>;
  notificationSentToFCPSR?: Maybe<Scalars['Boolean']>;
};

export type VenueCompetitionUpdateOneRequiredWithoutVenueCompApplicationsInput = {
  create?: Maybe<VenueCompetitionCreateWithoutVenueCompApplicationsInput>;
  update?: Maybe<VenueCompetitionUpdateWithoutVenueCompApplicationsDataInput>;
  upsert?: Maybe<VenueCompetitionUpsertWithoutVenueCompApplicationsInput>;
  connect?: Maybe<VenueCompetitionWhereUniqueInput>;
};

export type VenueCompetitionUpdateWithoutVenueCompApplicationsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredWithoutVenueCompetitionInput>;
  dirDocument?: Maybe<DirDocumentUpdateOneInput>;
  venueCompDecisionDoc?: Maybe<DirDocumentUpdateOneInput>;
  venueCompRegulationDocAdds?: Maybe<FileUpdateManyInput>;
  venueCompDecisionDocAdds?: Maybe<FileUpdateManyInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  emailSenderFCPSR?: Maybe<UserUpdateOneInput>;
};

export type VenueCompetitionUpsertWithoutVenueCompApplicationsInput = {
  update: VenueCompetitionUpdateWithoutVenueCompApplicationsDataInput;
  create: VenueCompetitionCreateWithoutVenueCompApplicationsInput;
};

export type DirCalendarTypeUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirCalendarSignedReportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirCalendarSignedReport: DirCalendarCreateOneWithoutSignedReportsInput;
  dirCalendar: DirCalendarCreateOneInput;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  files?: Maybe<FileCreateManyInput>;
};

export type DirCalendarCreateOneWithoutSignedReportsInput = {
  create?: Maybe<DirCalendarCreateWithoutSignedReportsInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarCreateWithoutSignedReportsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeCreateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueCreateOneInput>;
  clsSeason?: Maybe<ClassifierValueCreateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueCreateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionCreateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionCreateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionCreateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileCreateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsCreateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolCreateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileCreateManyInput>;
  stageNumber?: Maybe<ClassifierValueCreateOneInput>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoCreateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportCreateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoCreateManyWithoutDirCalendarInput>;
};

export type DirCalendarSignedReportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirCalendarSignedReport?: Maybe<DirCalendarUpdateOneRequiredWithoutSignedReportsInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  files?: Maybe<FileUpdateManyInput>;
};

export type DirCalendarUpdateOneRequiredWithoutSignedReportsInput = {
  create?: Maybe<DirCalendarCreateWithoutSignedReportsInput>;
  update?: Maybe<DirCalendarUpdateWithoutSignedReportsDataInput>;
  upsert?: Maybe<DirCalendarUpsertWithoutSignedReportsInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarUpdateWithoutSignedReportsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DirCalendarTypeUpdateOneInput>;
  year?: Maybe<Scalars['Int']>;
  ekpMinsportNumber?: Maybe<Scalars['String']>;
  clsEkpPart?: Maybe<ClassifierValueUpdateOneInput>;
  clsSeason?: Maybe<ClassifierValueUpdateOneInput>;
  clsCalendarCategory?: Maybe<ClassifierValueUpdateOneInput>;
  refusedRegions?: Maybe<DirRefusedRegionUpdateManyWithoutDirCalendarInput>;
  returnedRegions?: Maybe<DirReturnedRegionUpdateManyWithoutDirCalendarInput>;
  venueCompetition?: Maybe<VenueCompetitionUpdateOneWithoutDirCalendarInput>;
  sportCompetitionRegulationDocument?: Maybe<FileUpdateOneInput>;
  dirSportEventRegulations?: Maybe<DirSportEventRegulationsUpdateManyWithoutDirCalendarInput>;
  roivForNotificationProtocol?: Maybe<NotificationProtocolUpdateManyWithoutDirCalendarInput>;
  regulationChanges?: Maybe<FileUpdateManyInput>;
  stageNumber?: Maybe<ClassifierValueUpdateOneInput>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  dateAcceptQuotasAndFilesStageTwo?: Maybe<Scalars['DateTime']>;
  reqPartFromStageTwo?: Maybe<ReqPartFromStageTwoUpdateManyWithoutDirCalendarInput>;
  quotasForStageTwoBySport?: Maybe<QuotaForStageTwoBySportUpdateManyWithoutDirCalendarInput>;
  quantyRequestsStageTwo?: Maybe<QuantyRequestStageTwoUpdateManyWithoutDirCalendarInput>;
};

export type DirCalendarUpsertWithoutSignedReportsInput = {
  update: DirCalendarUpdateWithoutSignedReportsDataInput;
  create: DirCalendarCreateWithoutSignedReportsInput;
};

export type DirDocumentUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  clsType?: Maybe<ClassifierValueUpdateOneInput>;
  file?: Maybe<FileUpdateOneRequiredInput>;
};

export type AddressTypeUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type TaskStatusUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type FileUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token: Scalars['String'];
  user: User;
};


export type PublicAthleteMedCerts = {
  __typename?: 'PublicAthleteMedCerts';
  athlete: PublicAthleteInfo;
  medCerts: Array<PublicMedCertInfo>;
};

export type ArchiveStatusFragment = (
  { __typename?: 'Archive' }
  & Pick<Archive, 'id' | 'reason' | 'date' | 'comment'>
);

export type AthletesExpelMutationVariables = Exact<{
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  trainerID?: Maybe<Scalars['UUID']>;
  trainingStageID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
}>;


export type AthletesExpelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'athletesExpel'>
);

export type AthletesExpelWithMoveToAnotherTrainerMutationVariables = Exact<{
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  fromTrainerID?: Maybe<Scalars['UUID']>;
  toTrainerID: Scalars['UUID'];
  trainingStageID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
}>;


export type AthletesExpelWithMoveToAnotherTrainerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'athletesExpelWithMoveToAnotherTrainer'>
);

export type AthletesExpelWithMoveToAnotherTrainingStageMutationVariables = Exact<{
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  fromTrainingStageID: Scalars['UUID'];
  toTrainingStageID: Scalars['UUID'];
  trainerID?: Maybe<Scalars['UUID']>;
  exclusionDate: Scalars['DateTime'];
}>;


export type AthletesExpelWithMoveToAnotherTrainingStageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'athletesExpelWithMoveToAnotherTrainingStage'>
);

export type AthletesExpelWithMoveToSelfTrainingMutationVariables = Exact<{
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  trainerID: Scalars['UUID'];
  trainingStageID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
}>;


export type AthletesExpelWithMoveToSelfTrainingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'athletesExpelWithMoveToSelfTraining'>
);

export type UpdateDirAthleteMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirAthleteUpdateInput;
}>;


export type UpdateDirAthleteMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthlete?: Maybe<(
    { __typename?: 'DirAthlete' }
    & AthleteFrFragment
  )> }
);

export type CreateAthleteMutationVariables = Exact<{
  idPerson: Scalars['UUID'];
  sportType: Scalars['UUID'];
}>;


export type CreateAthleteMutation = (
  { __typename?: 'Mutation' }
  & { createDirAthlete: (
    { __typename?: 'DirAthlete' }
    & AthleteFrFragment
  ) }
);

export type AthleteConnectDisciplineToSportMutationVariables = Exact<{
  idSport: Scalars['UUID'];
  idDiscipline?: Maybe<Scalars['UUID']>;
}>;


export type AthleteConnectDisciplineToSportMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthleteSport?: Maybe<(
    { __typename?: 'DirAthleteSport' }
    & { disciplines?: Maybe<Array<(
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
    )>> }
  )> }
);

export type AthleteDisconnectDisciplineToSportMutationVariables = Exact<{
  idSport: Scalars['UUID'];
  idDiscipline?: Maybe<Scalars['UUID']>;
}>;


export type AthleteDisconnectDisciplineToSportMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthleteSport?: Maybe<(
    { __typename?: 'DirAthleteSport' }
    & { disciplines?: Maybe<Array<(
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
    )>> }
  )> }
);

export type AthleteConnectDisciplineGroupToSportMutationVariables = Exact<{
  idSport: Scalars['UUID'];
  idDisciplineGroup?: Maybe<Scalars['UUID']>;
}>;


export type AthleteConnectDisciplineGroupToSportMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthleteSport?: Maybe<(
    { __typename?: 'DirAthleteSport' }
    & { disciplineGroups?: Maybe<Array<(
      { __typename?: 'DirSportDisciplineGroup' }
      & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
    )>> }
  )> }
);

export type AthleteDisconnectDisciplineGroupFromSportMutationVariables = Exact<{
  idSport: Scalars['UUID'];
  idDisciplineGroup?: Maybe<Scalars['UUID']>;
}>;


export type AthleteDisconnectDisciplineGroupFromSportMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthleteSport?: Maybe<(
    { __typename?: 'DirAthleteSport' }
    & { disciplineGroups?: Maybe<Array<(
      { __typename?: 'DirSportDisciplineGroup' }
      & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
    )>> }
  )> }
);

export type UpdateAthleteSportMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirAthleteSportUpdateInput;
}>;


export type UpdateAthleteSportMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthleteSport?: Maybe<(
    { __typename?: 'DirAthleteSport' }
    & Pick<DirAthleteSport, 'id'>
  )> }
);

export type AthleteUpdateAmpluaToSportMutationVariables = Exact<{
  idSport: Scalars['UUID'];
  amplua?: Maybe<Scalars['String']>;
}>;


export type AthleteUpdateAmpluaToSportMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthleteSport?: Maybe<(
    { __typename?: 'DirAthleteSport' }
    & Pick<DirAthleteSport, 'id'>
  )> }
);

export type AddTypeSportsToAthleteMutationVariables = Exact<{
  id: Scalars['UUID'];
  sportId: Scalars['UUID'];
}>;


export type AddTypeSportsToAthleteMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthlete?: Maybe<(
    { __typename?: 'DirAthlete' }
    & AthleteFrFragment
  )> }
);

export type TypeSportAthleteArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type TypeSportAthleteArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthleteSport?: Maybe<(
    { __typename?: 'DirAthleteSport' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type TypeSportAthleteUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TypeSportAthleteUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthleteSport?: Maybe<(
    { __typename?: 'DirAthleteSport' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type AddAthleteOrganizationMutationVariables = Exact<{
  idSport: Scalars['UUID'];
  idDirOrganization: Scalars['UUID'];
  idDirTrainer?: Maybe<DirTrainerCreateOneInput>;
  clsTrainingStage: Scalars['UUID'];
  trainingStart: Scalars['DateTime'];
  isSelfTraining?: Maybe<Scalars['Boolean']>;
}>;


export type AddAthleteOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthleteSport?: Maybe<(
    { __typename?: 'DirAthleteSport' }
    & Pick<DirAthleteSport, 'id'>
  )> }
);

export type UpdateAthleteOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  idDirOrganization?: Maybe<Scalars['UUID']>;
  idDirTrainer?: Maybe<DirTrainerUpdateOneInput>;
  clsTrainingStage?: Maybe<Scalars['UUID']>;
  trainingStart?: Maybe<Scalars['DateTime']>;
  exclusionDate?: Maybe<Scalars['DateTime']>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateAthleteOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthleteSportOrganization?: Maybe<(
    { __typename?: 'DirAthleteSportOrganization' }
    & Pick<DirAthleteSportOrganization, 'id'>
  )> }
);

export type DeleteAthleteOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
}>;


export type DeleteAthleteOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthleteSportOrganization?: Maybe<(
    { __typename?: 'DirAthleteSportOrganization' }
    & Pick<DirAthleteSportOrganization, 'id'>
  )> }
);

export type AthleteRankAndAwardArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type AthleteRankAndAwardArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthleteSportRankAndAward?: Maybe<(
    { __typename?: 'DirAthleteSportRankAndAward' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type AthleteRankAndAwardUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AthleteRankAndAwardUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirAthleteSportRankAndAward?: Maybe<(
    { __typename?: 'DirAthleteSportRankAndAward' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type AthleteFrFragment = (
  { __typename?: 'DirAthlete' }
  & Pick<DirAthlete, 'id'>
  & { archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )>, medCerts?: Maybe<Array<(
    { __typename?: 'DirAthleteMedCert' }
    & Pick<DirAthleteMedCert, 'id' | 'issueDate' | 'displayNumber' | 'organizationName' | 'organizationPhone' | 'organizationEmail' | 'organizationAddress' | 'physicalExaminationDate' | 'hasRestrictions' | 'validUntil' | 'isValid' | 'deletedAt'>
    & { sports?: Maybe<Array<(
      { __typename?: 'DirAthleteMedCertSport' }
      & { dirSport: (
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'fullName'>
        & { disciplineGroups?: Maybe<Array<(
          { __typename?: 'DirSportDisciplineGroup' }
          & Pick<DirSportDisciplineGroup, 'name'>
        )>> }
      ), clsTrainingStage?: Maybe<(
        { __typename?: 'ClassifierValue' }
        & Pick<ClassifierValue, 'shortName'>
      )>, disciplineGroups?: Maybe<Array<(
        { __typename?: 'DirSportDisciplineGroup' }
        & Pick<DirSportDisciplineGroup, 'name'>
      )>> }
    )>> }
  )>>, dirPerson: (
    { __typename?: 'DirPerson' }
    & { additionalRegions?: Maybe<Array<(
      { __typename?: 'DirPersonAdditionalRegion' }
      & Pick<DirPersonAdditionalRegion, 'id'>
      & { dirRegion?: Maybe<(
        { __typename?: 'DirRegion' }
        & Pick<DirRegion, 'id'>
      )>, dirForeignCity?: Maybe<(
        { __typename?: 'DirForeignCity' }
        & Pick<DirForeignCity, 'id'>
      )> }
    )>> }
    & PersonFrFragment
  ), sports?: Maybe<Array<(
    { __typename?: 'DirAthleteSport' }
    & Pick<DirAthleteSport, 'id' | 'role'>
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )>, dirSport: (
      { __typename?: 'DirSport' }
      & { value: DirSport['id'], label: DirSport['fullName'] }
    ), ranksAndAwards?: Maybe<Array<(
      { __typename?: 'DirAthleteSportRankAndAward' }
      & Pick<DirAthleteSportRankAndAward, 'id' | 'assignmentDate' | 'confirmDate' | 'validUntil'>
      & { clsRankAndAward: (
        { __typename?: 'ClassifierValue' }
        & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
      ), archive?: Maybe<(
        { __typename?: 'Archive' }
        & ArchiveStatusFragment
      )> }
    )>>, disciplines?: Maybe<Array<(
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
    )>>, disciplineGroups?: Maybe<Array<(
      { __typename?: 'DirSportDisciplineGroup' }
      & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
    )>>, organizations?: Maybe<Array<(
      { __typename?: 'DirAthleteSportOrganization' }
      & Pick<DirAthleteSportOrganization, 'id' | 'isSelfTraining' | 'trainingStart' | 'exclusionDate'>
      & { dirOrganization: (
        { __typename?: 'DirOrganization' }
        & { value: DirOrganization['id'], label: DirOrganization['name'] }
      ), dirTrainer?: Maybe<(
        { __typename?: 'DirTrainer' }
        & { value: DirTrainer['id'] }
        & { dirPerson: (
          { __typename?: 'DirPerson' }
          & Pick<DirPerson, 'id' | 'firstname' | 'patronymic' | 'lastname' | 'birthday'>
          & { trainerProfile?: Maybe<(
            { __typename?: 'DirTrainer' }
            & Pick<DirTrainer, 'id'>
          )> }
        ), sports?: Maybe<Array<(
          { __typename?: 'DirTrainerSport' }
          & { organizations?: Maybe<Array<(
            { __typename?: 'DirTrainerSportOrganization' }
            & Pick<DirTrainerSportOrganization, 'dismissalDate'>
            & { dirOrganization: (
              { __typename?: 'DirOrganization' }
              & Pick<DirOrganization, 'id'>
            ) }
          )>> }
        )>> }
      )>, clsTrainingStage: (
        { __typename?: 'ClassifierValue' }
        & Pick<ClassifierValue, 'shortName'>
        & { value: ClassifierValue['id'], label: ClassifierValue['shortName'] }
      ) }
    )>>, dirTrainers?: Maybe<Array<(
      { __typename?: 'DirTrainer' }
      & Pick<DirTrainer, 'id'>
      & { dirPerson: (
        { __typename?: 'DirPerson' }
        & Pick<DirPerson, 'firstname' | 'lastname' | 'patronymic' | 'registryNumber' | 'birthday'>
      ) }
    )>> }
  )>> }
);

export type AthleteListFrFragment = (
  { __typename?: 'DirAthlete' }
  & Pick<DirAthlete, 'id'>
  & { archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )>, dirPerson: (
    { __typename?: 'DirPerson' }
    & PersonFrFragment
  ), sports?: Maybe<Array<(
    { __typename?: 'DirAthleteSport' }
    & Pick<DirAthleteSport, 'id'>
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )>, dirSport: (
      { __typename?: 'DirSport' }
      & Pick<DirSport, 'id' | 'fullName'>
    ), ranksAndAwards?: Maybe<Array<(
      { __typename?: 'DirAthleteSportRankAndAward' }
      & Pick<DirAthleteSportRankAndAward, 'id' | 'assignmentDate' | 'confirmDate' | 'validUntil'>
      & { clsRankAndAward: (
        { __typename?: 'ClassifierValue' }
        & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
      ) }
    )>>, organizations?: Maybe<Array<(
      { __typename?: 'DirAthleteSportOrganization' }
      & Pick<DirAthleteSportOrganization, 'isSelfTraining' | 'trainingStart' | 'exclusionDate'>
      & { dirOrganization: (
        { __typename?: 'DirOrganization' }
        & { value: DirOrganization['id'], label: DirOrganization['name'] }
      ), dirTrainer?: Maybe<(
        { __typename?: 'DirTrainer' }
        & { dirPerson: (
          { __typename?: 'DirPerson' }
          & Pick<DirPerson, 'id' | 'firstname' | 'patronymic' | 'lastname'>
        ), sports?: Maybe<Array<(
          { __typename?: 'DirTrainerSport' }
          & { organizations?: Maybe<Array<(
            { __typename?: 'DirTrainerSportOrganization' }
            & Pick<DirTrainerSportOrganization, 'dismissalDate'>
            & { dirOrganization: (
              { __typename?: 'DirOrganization' }
              & Pick<DirOrganization, 'id'>
            ) }
          )>> }
        )>> }
      )>, clsTrainingStage: (
        { __typename?: 'ClassifierValue' }
        & Pick<ClassifierValue, 'shortName'>
      ) }
    )>> }
  )>> }
);

export type AthleteSportInfoFragment = (
  { __typename?: 'DirAthleteSport' }
  & Pick<DirAthleteSport, 'id' | 'role'>
  & { archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )>, dirSport: (
    { __typename?: 'DirSport' }
    & { value: DirSport['id'], label: DirSport['fullName'] }
  ), ranksAndAwards?: Maybe<Array<(
    { __typename?: 'DirAthleteSportRankAndAward' }
    & Pick<DirAthleteSportRankAndAward, 'id' | 'assignmentDate' | 'confirmDate' | 'validUntil'>
    & { clsRankAndAward: (
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    ), archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )>>, disciplines?: Maybe<Array<(
    { __typename?: 'DirSportDiscipline' }
    & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
  )>>, disciplineGroups?: Maybe<Array<(
    { __typename?: 'DirSportDisciplineGroup' }
    & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
  )>>, organizations?: Maybe<Array<(
    { __typename?: 'DirAthleteSportOrganization' }
    & Pick<DirAthleteSportOrganization, 'id' | 'isSelfTraining' | 'trainingStart' | 'exclusionDate'>
    & { dirOrganization: (
      { __typename?: 'DirOrganization' }
      & { value: DirOrganization['id'], label: DirOrganization['name'] }
    ), dirTrainer?: Maybe<(
      { __typename?: 'DirTrainer' }
      & { value: DirTrainer['id'] }
      & { dirPerson: (
        { __typename?: 'DirPerson' }
        & Pick<DirPerson, 'id' | 'firstname' | 'patronymic' | 'lastname' | 'birthday'>
        & { trainerProfile?: Maybe<(
          { __typename?: 'DirTrainer' }
          & Pick<DirTrainer, 'id'>
        )> }
      ), sports?: Maybe<Array<(
        { __typename?: 'DirTrainerSport' }
        & { organizations?: Maybe<Array<(
          { __typename?: 'DirTrainerSportOrganization' }
          & Pick<DirTrainerSportOrganization, 'dismissalDate'>
          & { dirOrganization: (
            { __typename?: 'DirOrganization' }
            & Pick<DirOrganization, 'id'>
          ) }
        )>> }
      )>> }
    )>, clsTrainingStage: (
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'shortName'>
      & { value: ClassifierValue['id'], label: ClassifierValue['shortName'] }
    ) }
  )>>, dirTrainers?: Maybe<Array<(
    { __typename?: 'DirTrainer' }
    & Pick<DirTrainer, 'id'>
    & { dirPerson: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'firstname' | 'lastname' | 'patronymic' | 'registryNumber' | 'birthday'>
    ) }
  )>> }
);

export type AthletesQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirAthleteWhereInput>;
}>;


export type AthletesQuery = (
  { __typename?: 'Query' }
  & { dirAthletes: Array<(
    { __typename?: 'DirAthlete' }
    & AthleteListFrFragment
  )> }
);

export type AthleteSportsQueryVariables = Exact<{
  id: Scalars['UUID'];
  filter?: Maybe<DirAthleteSportWhereInput>;
}>;


export type AthleteSportsQuery = (
  { __typename?: 'Query' }
  & { dirAthlete?: Maybe<(
    { __typename?: 'DirAthlete' }
    & { sports?: Maybe<Array<(
      { __typename?: 'DirAthleteSport' }
      & AthleteSportInfoFragment
    )>> }
  )> }
);

export type AthletesCountQueryVariables = Exact<{
  filter?: Maybe<DirAthleteWhereInput>;
}>;


export type AthletesCountQuery = (
  { __typename?: 'Query' }
  & { count: Query['dirAthletesCount'] }
);

export type AthleteQueryVariables = Exact<{
  filter: DirAthleteWhereUniqueInput;
}>;


export type AthleteQuery = (
  { __typename?: 'Query' }
  & { dirAthlete?: Maybe<(
    { __typename?: 'DirAthlete' }
    & AthleteFrFragment
  )> }
);

export type AthletesFilteredQueryVariables = Exact<{
  filter?: Maybe<DirAthleteWhereInput>;
}>;


export type AthletesFilteredQuery = (
  { __typename?: 'Query' }
  & { dirAthletes: Array<(
    { __typename?: 'DirAthlete' }
    & AthleteFrFragment
  )> }
);

export type AthleteCompetitionQueryVariables = Exact<{
  id: Scalars['UUID'];
  sportId?: Maybe<Scalars['UUID']>;
}>;


export type AthleteCompetitionQuery = (
  { __typename?: 'Query' }
  & { dirAthlete?: Maybe<(
    { __typename?: 'DirAthlete' }
    & { athleteId: DirAthlete['id'] }
    & { competetionResult?: Maybe<Array<(
      { __typename?: 'DirAthleteCompetitionResult' }
      & Pick<DirAthleteCompetitionResult, 'id' | 'result' | 'point' | 'pointTo' | 'score'>
      & { sportingEventProgramType: (
        { __typename?: 'DirSportingEventProgramType' }
        & Pick<DirSportingEventProgramType, 'id' | 'start' | 'minAge' | 'maxAge'>
        & { event: (
          { __typename?: 'DirSportingEvent' }
          & Pick<DirSportingEvent, 'id' | 'name' | 'venue' | 'startDate' | 'endDate'>
          & { clsEventCategories?: Maybe<Array<(
            { __typename?: 'ClassifierValue' }
            & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
          )>> }
        ), clsAgeGroups?: Maybe<Array<(
          { __typename?: 'ClassifierValue' }
          & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
        )>>, discipline: (
          { __typename?: 'DirSportDiscipline' }
          & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
        ) }
      ) }
    )>> }
  )> }
);

export type AthleteCompetitionsArrayQueryVariables = Exact<{
  filter?: Maybe<DirAthleteCompetitionResultWhereInput>;
}>;


export type AthleteCompetitionsArrayQuery = (
  { __typename?: 'Query' }
  & { dirAthleteCompetitionResults: Array<(
    { __typename?: 'DirAthleteCompetitionResult' }
    & Pick<DirAthleteCompetitionResult, 'id' | 'point' | 'pointTo' | 'result' | 'completedRank'>
    & { dirAthlete: (
      { __typename?: 'DirAthlete' }
      & Pick<DirAthlete, 'id'>
      & { dirPerson: (
        { __typename?: 'DirPerson' }
        & Pick<DirPerson, 'id' | 'registryNumber' | 'firstname' | 'lastname' | 'patronymic' | 'birthday'>
        & { dirRegion?: Maybe<(
          { __typename?: 'DirRegion' }
          & Pick<DirRegion, 'id' | 'fullName'>
        )>, dirForeignCity?: Maybe<(
          { __typename?: 'DirForeignCity' }
          & Pick<DirForeignCity, 'id' | 'fullName'>
        )>, dirCountry?: Maybe<(
          { __typename?: 'DirCountry' }
          & Pick<DirCountry, 'id' | 'fullName'>
        )> }
      ) }
    ), sportingEventProgramType: (
      { __typename?: 'DirSportingEventProgramType' }
      & Pick<DirSportingEventProgramType, 'id' | 'start' | 'minAge' | 'maxAge'>
      & { resultsDocument?: Maybe<(
        { __typename?: 'DirDocument' }
        & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'path'>
        ) }
      )>, event: (
        { __typename?: 'DirSportingEvent' }
        & Pick<DirSportingEvent, 'id'>
      ), clsAgeGroups?: Maybe<Array<(
        { __typename?: 'ClassifierValue' }
        & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
      )>>, discipline: (
        { __typename?: 'DirSportDiscipline' }
        & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
      ) }
    ) }
  )> }
);

export type AthleteTeamsQueryVariables = Exact<{
  id: Scalars['UUID'];
  sportId: Scalars['UUID'];
}>;


export type AthleteTeamsQuery = (
  { __typename?: 'Query' }
  & { dirNationalTeams: Array<(
    { __typename?: 'DirNationalTeam' }
    & Pick<DirNationalTeam, 'id'>
    & { dirSportingEvent: (
      { __typename?: 'DirSportingEvent' }
      & Pick<DirSportingEvent, 'id' | 'name' | 'startDate' | 'endDate'>
    ), dirRegion: (
      { __typename?: 'DirRegion' }
      & Pick<DirRegion, 'id' | 'fullName'>
    ) }
  )> }
);

export type AthletesCountByTrainerIdInOrganizationQueryVariables = Exact<{
  id: Scalars['UUID'];
  orgID: Scalars['UUID'];
}>;


export type AthletesCountByTrainerIdInOrganizationQuery = (
  { __typename?: 'Query' }
  & { count: Query['dirAthletesCount'] }
);

export type SimilarAthletesQueryVariables = Exact<{
  sportsId?: Maybe<Array<Scalars['UUID']>>;
  deniedAthletes?: Maybe<Array<Scalars['UUID']>>;
  search: Scalars['String'];
  minBday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  maxBday?: Maybe<Scalars['DateTime']>;
}>;


export type SimilarAthletesQuery = (
  { __typename?: 'Query' }
  & { dirPersonsSimilar: Array<(
    { __typename?: 'SimilarPerson' }
    & { person: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'registryNumber' | 'birthday'>
      & { athleteProfile?: Maybe<(
        { __typename?: 'DirAthlete' }
        & Pick<DirAthlete, 'id'>
      )>, dirRegion?: Maybe<(
        { __typename?: 'DirRegion' }
        & Pick<DirRegion, 'id' | 'fullName'>
      )>, dirCountry?: Maybe<(
        { __typename?: 'DirCountry' }
        & Pick<DirCountry, 'id' | 'fullName'>
      )>, dirForeignCity?: Maybe<(
        { __typename?: 'DirForeignCity' }
        & Pick<DirForeignCity, 'id' | 'fullName'>
      )> }
    ) }
  )> }
);

export type AthletesMiniQueryVariables = Exact<{
  filter?: Maybe<DirAthleteWhereInput>;
}>;


export type AthletesMiniQuery = (
  { __typename?: 'Query' }
  & { dirAthletes: Array<(
    { __typename?: 'DirAthlete' }
    & Pick<DirAthlete, 'id'>
    & { dirPerson: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'registryNumber' | 'birthday'>
      & { dirRegion?: Maybe<(
        { __typename?: 'DirRegion' }
        & Pick<DirRegion, 'id' | 'fullName'>
      )>, dirCountry?: Maybe<(
        { __typename?: 'DirCountry' }
        & Pick<DirCountry, 'id' | 'fullName'>
      )>, dirForeignCity?: Maybe<(
        { __typename?: 'DirForeignCity' }
        & Pick<DirForeignCity, 'id' | 'fullName'>
      )> }
    ) }
  )> }
);

export type AthletesSimilarQueryVariables = Exact<{
  name: Scalars['String'];
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirAthleteWhereInput>;
}>;


export type AthletesSimilarQuery = (
  { __typename?: 'Query' }
  & { dirPersonsSimilar: Array<(
    { __typename?: 'SimilarPerson' }
    & Pick<SimilarPerson, 'similarity'>
    & { person: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id'>
      & { athleteProfile?: Maybe<(
        { __typename?: 'DirAthlete' }
        & AthleteListFrFragment
      )> }
    ) }
  )> }
);

export type AthletesSimilarCountQueryVariables = Exact<{
  name: Scalars['String'];
  filter?: Maybe<DirAthleteWhereInput>;
}>;


export type AthletesSimilarCountQuery = (
  { __typename?: 'Query' }
  & { dirPersonsSimilar: Array<(
    { __typename?: 'SimilarPerson' }
    & Pick<SimilarPerson, 'similarity'>
    & { person: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id'>
    ) }
  )> }
);

export type AthletesMedCertQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AthletesMedCertQuery = (
  { __typename?: 'Query' }
  & { dirAthleteMedCerts: Array<(
    { __typename?: 'DirAthleteMedCert' }
    & Pick<DirAthleteMedCert, 'id' | 'issueDate' | 'displayNumber' | 'organizationName' | 'organizationPhone' | 'organizationEmail' | 'organizationAddress' | 'physicalExaminationDate' | 'hasRestrictions' | 'validUntil' | 'isValid' | 'deletedAt'>
    & { sports?: Maybe<Array<(
      { __typename?: 'DirAthleteMedCertSport' }
      & { dirSport: (
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'fullName'>
        & { disciplineGroups?: Maybe<Array<(
          { __typename?: 'DirSportDisciplineGroup' }
          & Pick<DirSportDisciplineGroup, 'name'>
        )>> }
      ), clsTrainingStage?: Maybe<(
        { __typename?: 'ClassifierValue' }
        & Pick<ClassifierValue, 'shortName'>
      )>, disciplineGroups?: Maybe<Array<(
        { __typename?: 'DirSportDisciplineGroup' }
        & Pick<DirSportDisciplineGroup, 'name'>
      )>> }
    )>> }
  )> }
);

export type CreateCalendarMutationVariables = Exact<{
  data: DirCalendarCreateInput;
}>;


export type CreateCalendarMutation = (
  { __typename?: 'Mutation' }
  & { createDirCalendar: (
    { __typename?: 'DirCalendar' }
    & CalendarFragment
  ) }
);

export type UpdateCalendarMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirCalendarUpdateInput;
}>;


export type UpdateCalendarMutation = (
  { __typename?: 'Mutation' }
  & { updateDirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & CalendarFragment
  )> }
);

export type CalendarArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type CalendarArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type CalendarUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CalendarUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type CreateDirRefusedRegionMutationVariables = Exact<{
  data: DirRefusedRegionCreateInput;
}>;


export type CreateDirRefusedRegionMutation = (
  { __typename?: 'Mutation' }
  & { createDirRefusedRegion: (
    { __typename?: 'DirRefusedRegion' }
    & Pick<DirRefusedRegion, 'id'>
  ) }
);

export type UpdateDirRefusedRegionMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirRefusedRegionUpdateInput;
}>;


export type UpdateDirRefusedRegionMutation = (
  { __typename?: 'Mutation' }
  & { updateDirRefusedRegion?: Maybe<(
    { __typename?: 'DirRefusedRegion' }
    & Pick<DirRefusedRegion, 'id'>
  )> }
);

export type DeleteDirRefusedRegionMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteDirRefusedRegionMutation = (
  { __typename?: 'Mutation' }
  & { deleteDirRefusedRegion?: Maybe<(
    { __typename?: 'DirRefusedRegion' }
    & Pick<DirRefusedRegion, 'id'>
  )> }
);

export type CreateDirReturnedRegionMutationVariables = Exact<{
  data: DirReturnedRegionCreateInput;
}>;


export type CreateDirReturnedRegionMutation = (
  { __typename?: 'Mutation' }
  & { createDirReturnedRegion: (
    { __typename?: 'DirReturnedRegion' }
    & Pick<DirReturnedRegion, 'id'>
  ) }
);

export type DeleteDirReturnedRegionMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteDirReturnedRegionMutation = (
  { __typename?: 'Mutation' }
  & { deleteDirReturnedRegion?: Maybe<(
    { __typename?: 'DirReturnedRegion' }
    & Pick<DirReturnedRegion, 'id'>
  )> }
);

export type UpdateDirReturnedRegionMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirReturnedRegionUpdateInput;
}>;


export type UpdateDirReturnedRegionMutation = (
  { __typename?: 'Mutation' }
  & { updateDirReturnedRegion?: Maybe<(
    { __typename?: 'DirReturnedRegion' }
    & Pick<DirReturnedRegion, 'id'>
  )> }
);

export type InitiateMailingMutationVariables = Exact<{
  calendarId: Scalars['String'];
  email: Scalars['String'];
}>;


export type InitiateMailingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'notificationStageTwo'>
);

export type CalendarFragment = (
  { __typename?: 'DirCalendar' }
  & Pick<DirCalendar, 'id' | 'registryNumber' | 'fullName' | 'shortName' | 'order' | 'year' | 'ekpMinsportNumber'>
  & { venueCompetition?: Maybe<(
    { __typename?: 'VenueCompetition' }
    & Pick<VenueCompetition, 'id' | 'createdAt' | 'endDate'>
    & { venueCompApplications?: Maybe<Array<(
      { __typename?: 'VenueCompApplication' }
      & Pick<VenueCompApplication, 'number' | 'notificationSentToFCPSR'>
      & { dirRegion: (
        { __typename?: 'DirRegion' }
        & Pick<DirRegion, 'fullName'>
      ) }
    )>>, dirDocument?: Maybe<(
      { __typename?: 'DirDocument' }
      & Pick<DirDocument, 'id' | 'number' | 'date' | 'title'>
      & { clsType?: Maybe<(
        { __typename?: 'ClassifierValue' }
        & { name: ClassifierValue['fullName'] }
      )>, file: (
        { __typename?: 'File' }
        & Pick<File, 'path'>
      ) }
    )>, emailSenderFCPSR?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
      & { regular?: Maybe<(
        { __typename?: 'RegularUser' }
        & Pick<RegularUser, 'lastname' | 'firstname' | 'patronymic'>
      )> }
    )>, venueCompRegulationDocAdds?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path' | 'name'>
    )>>, venueCompDecisionDocAdds?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path' | 'name'>
    )>>, venueCompDecisionDoc?: Maybe<(
      { __typename?: 'DirDocument' }
      & Pick<DirDocument, 'id' | 'number' | 'date' | 'title'>
      & { clsType?: Maybe<(
        { __typename?: 'ClassifierValue' }
        & { name: ClassifierValue['fullName'] }
      )>, file: (
        { __typename?: 'File' }
        & Pick<File, 'path'>
      ) }
    )> }
  )>, clsEkpPart?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & { value: ClassifierValue['registryNumber'], label: ClassifierValue['fullName'] }
  )>, clsSeason?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & { value: ClassifierValue['registryNumber'], label: ClassifierValue['fullName'] }
  )>, clsCalendarCategory?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & { value: ClassifierValue['registryNumber'], label: ClassifierValue['fullName'] }
  )>, sportCompetitionRegulationDocument?: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'name' | 'path' | 'id'>
    & { date: File['createdAt'] }
  )>, regulationChanges?: Maybe<Array<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'name' | 'path'>
  )>>, type?: Maybe<(
    { __typename?: 'DirCalendarType' }
    & { label: DirCalendarType['name'], value: DirCalendarType['id'] }
  )>, stageNumber?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber'>
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  )>, dirSports?: Maybe<Array<(
    { __typename?: 'DirSport' }
    & { label: DirSport['fullName'], value: DirSport['id'] }
  )>>, archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )> }
);

export type CalendarEventFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'name' | 'startDate' | 'endDate' | 'membersQuota' | 'daysBeforeStartToAddProtocols' | 'ekp' | 'venue'>
  & { sports?: Maybe<Array<(
    { __typename?: 'DirSportingEventSport' }
    & { dirSport: (
      { __typename?: 'DirSport' }
      & Pick<DirSport, 'fullName' | 'id'>
    ) }
  )>>, archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )> }
);

export type CalendarEventSportFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id'>
  & { sports?: Maybe<Array<(
    { __typename?: 'DirSportingEventSport' }
    & { dirSport: (
      { __typename?: 'DirSport' }
      & Pick<DirSport, 'fullName' | 'id'>
    ) }
  )>> }
);

export type EventDistrictFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id'>
  & { dirFederalDistricts?: Maybe<Array<(
    { __typename?: 'DirFederalDistrict' }
    & Pick<DirFederalDistrict, 'id' | 'fullName'>
  )>> }
);

export type CalendarSportFragment = (
  { __typename?: 'DirCalendar' }
  & Pick<DirCalendar, 'id' | 'fullName' | 'dateAcceptQuotasAndFilesStageTwo'>
  & { dirSports?: Maybe<Array<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id' | 'fullName'>
  )>> }
);

export type CalendarReportEventFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'name' | 'venue' | 'startDate' | 'endDate' | 'ekp' | 'membersQuota'>
  & { archive?: Maybe<(
    { __typename?: 'Archive' }
    & Pick<Archive, 'id'>
  )>, dirRegion?: Maybe<(
    { __typename?: 'DirRegion' }
    & Pick<DirRegion, 'id' | 'fullName'>
  )>, dirCalendar: (
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'fullName' | 'ekpMinsportNumber'>
  ), clsEventStages?: Maybe<Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'id' | 'fullName'>
  )>>, sports?: Maybe<Array<(
    { __typename?: 'DirSportingEventSport' }
    & Pick<DirSportingEventSport, 'id'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & { label: DirSport['fullName'], value: DirSport['id'] }
    ) }
  )>>, programTypes?: Maybe<Array<(
    { __typename?: 'DirSportingEventProgramType' }
    & Pick<DirSportingEventProgramType, 'id'>
    & { clsAgeGroups?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & { id: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>> }
  )>> }
);

export type CalendarEventByPeriodFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'name' | 'venue' | 'startDate' | 'endDate' | 'ekp'>
  & { sports?: Maybe<Array<(
    { __typename?: 'DirSportingEventSport' }
    & Pick<DirSportingEventSport, 'id'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & Pick<DirSport, 'id' | 'fullName' | 'shortName'>
      & { label: DirSport['fullName'] }
    ) }
  )>>, clsEventStages?: Maybe<Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'id' | 'fullName'>
  )>>, dirCalendar: (
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id'>
    & { stageNumber?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'id' | 'fullName'>
    )> }
  ) }
);

export type CalendarQuantyRequestStageTwoFragment = (
  { __typename?: 'QuantyRequestStageTwo' }
  & Pick<QuantyRequestStageTwo, 'id' | 'regionQuotaAccompStageTwo' | 'regionQuotaMaleAthlStageTwo' | 'regionQuotaWomenAthlStageTwo'>
  & { dirSport: (
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id' | 'fullName'>
  ), dirRegion: (
    { __typename?: 'DirRegion' }
    & Pick<DirRegion, 'id' | 'fullName'>
  ) }
);

export type FederalDistrictPortionFragment = (
  { __typename?: 'DirFederalDistrict' }
  & Pick<DirFederalDistrict, 'id' | 'fullName' | 'shortName'>
  & { dirRegions?: Maybe<Array<(
    { __typename?: 'DirRegion' }
    & Pick<DirRegion, 'id' | 'fullName' | 'shortName'>
  )>> }
);

export type CalendarEventByMonthFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'startDate' | 'endDate'>
);

export type CalendarsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<DirCalendarWhereInput>;
}>;


export type CalendarsQuery = (
  { __typename?: 'Query' }
  & { count: Query['dirCalendarsCount'] }
  & { dirCalendars: Array<(
    { __typename?: 'DirCalendar' }
    & CalendarFragment
  )> }
);

export type CalendarQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CalendarQuery = (
  { __typename?: 'Query' }
  & { dirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & CalendarFragment
  )> }
);

export type CalendarTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type CalendarTypesQuery = (
  { __typename?: 'Query' }
  & { dirCalendarTypes: Array<(
    { __typename?: 'DirCalendarType' }
    & { label: DirCalendarType['name'], value: DirCalendarType['id'] }
  )> }
);

export type EventListByPeriodQueryVariables = Exact<{
  periodFilter: DirSportingEventWhereInput;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type EventListByPeriodQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & CalendarEventByPeriodFragment
  )> }
);

export type EventListByMonthQueryVariables = Exact<{
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  calendarFilter: DirCalendarWhereInput;
}>;


export type EventListByMonthQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & CalendarEventByMonthFragment
  )> }
);

export type FindCalendarsQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type FindCalendarsQuery = (
  { __typename?: 'Query' }
  & { dirCalendars: Array<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'shortName'>
    & { value: DirCalendar['id'], label: DirCalendar['fullName'] }
  )> }
);

export type CalendarsSportsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CalendarsSportsQuery = (
  { __typename?: 'Query' }
  & { dirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'fullName'>
    & CalendarSportFragment
  )> }
);

export type GetVenueByRegionQueryVariables = Exact<{
  idRegion: Scalars['UUID'];
  id: Scalars['UUID'];
}>;


export type GetVenueByRegionQuery = (
  { __typename?: 'Query' }
  & { venueCompApplications: Array<(
    { __typename?: 'VenueCompApplication' }
    & Pick<VenueCompApplication, 'number' | 'id' | 'notificationSentToFCPSR'>
  )> }
);

export type GetVenueApplicationByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetVenueApplicationByIdQuery = (
  { __typename?: 'Query' }
  & { venueCompApplication?: Maybe<(
    { __typename?: 'VenueCompApplication' }
    & Pick<VenueCompApplication, 'number' | 'id' | 'notificationSentToFCPSR'>
    & { applicationFiles?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'path'>
    )>> }
  )> }
);

export type GetActiveRegionsQueryVariables = Exact<{
  filter?: Maybe<DirRegionWhereInput>;
}>;


export type GetActiveRegionsQuery = (
  { __typename?: 'Query' }
  & { dirRegions: Array<(
    { __typename?: 'DirRegion' }
    & Pick<DirRegion, 'id' | 'fullName'>
  )> }
);

export type CalendarsSportsWithEventsQueryVariables = Exact<{
  id: Scalars['UUID'];
  stageRegistryNumber: Scalars['Int'];
}>;


export type CalendarsSportsWithEventsQuery = (
  { __typename?: 'Query' }
  & { dirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'ekpMinsportNumber'>
    & CalendarSportFragment
  )>, dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & CalendarEventSportFragment
  )> }
);

export type CalendarSummaryInformationOnParticipantNumberQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CalendarSummaryInformationOnParticipantNumberQuery = (
  { __typename?: 'Query' }
  & { quantyRequestStageTwoes: Array<(
    { __typename?: 'QuantyRequestStageTwo' }
    & CalendarQuantyRequestStageTwoFragment
  )>, dirFederalDistricts: Array<(
    { __typename?: 'DirFederalDistrict' }
    & FederalDistrictPortionFragment
  )> }
);

export type CalendarRegionSummaryInformationOnParticipantNumberQueryVariables = Exact<{
  id: Scalars['UUID'];
  regionId: Scalars['UUID'];
}>;


export type CalendarRegionSummaryInformationOnParticipantNumberQuery = (
  { __typename?: 'Query' }
  & { quantyRequestStageTwoes: Array<(
    { __typename?: 'QuantyRequestStageTwo' }
    & CalendarQuantyRequestStageTwoFragment
  )> }
);

export type CalendarEtapeQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CalendarEtapeQuery = (
  { __typename?: 'Query' }
  & { dirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id'>
    & { stageNumber?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'id' | 'fullName'>
    )> }
  )> }
);

export type CalendarEventsQueryVariables = Exact<{
  id: Scalars['UUID'];
  regionId?: Maybe<Scalars['UUID']>;
  sportId: Scalars['UUID'];
  stageRegistryNumber: Scalars['Int'];
}>;


export type CalendarEventsQuery = (
  { __typename?: 'Query' }
  & { dirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id' | 'ekpMinsportNumber'>
  )>, dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & CalendarEventFragment
  )> }
);

export type CalendarEventsWithoutRegionsQueryVariables = Exact<{
  id: Scalars['UUID'];
  registryNumber?: Maybe<Scalars['Int']>;
  sportId: Scalars['UUID'];
  stageRegistryNumber: Scalars['Int'];
}>;


export type CalendarEventsWithoutRegionsQuery = (
  { __typename?: 'Query' }
  & { dirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id' | 'ekpMinsportNumber'>
  )>, dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & CalendarEventFragment
  )> }
);

export type CalendarEventSportsQueryVariables = Exact<{
  id: Scalars['UUID'];
  stageRegistryNumber: Scalars['Int'];
}>;


export type CalendarEventSportsQuery = (
  { __typename?: 'Query' }
  & { dirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id' | 'ekpMinsportNumber'>
  )>, dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & CalendarEventSportFragment
  )> }
);

export type CalendarEventDistrictsQueryVariables = Exact<{
  id: Scalars['UUID'];
  stageRegistryNumber: Scalars['Int'];
  sportId: Scalars['UUID'];
}>;


export type CalendarEventDistrictsQuery = (
  { __typename?: 'Query' }
  & { dirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id' | 'ekpMinsportNumber'>
  )>, dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & EventDistrictFragment
  )> }
);

export type CalendarStageQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CalendarStageQuery = (
  { __typename?: 'Query' }
  & { dirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id' | 'ekpMinsportNumber' | 'fullName'>
    & { clsCalendarCategory?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['registryNumber'], label: ClassifierValue['fullName'] }
    )>, stageNumber?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'id' | 'fullName' | 'registryNumber'>
    )> }
  )> }
);

export type CalendarSignedReportsQueryVariables = Exact<{
  id: Scalars['UUID'];
  regionId: Scalars['UUID'];
}>;


export type CalendarSignedReportsQuery = (
  { __typename?: 'Query' }
  & { dirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id'>
    & { signedReports?: Maybe<Array<(
      { __typename?: 'DirCalendarSignedReport' }
      & Pick<DirCalendarSignedReport, 'id'>
      & { files?: Maybe<Array<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'name' | 'path'>
      )>> }
    )>> }
  )> }
);

export type CalendarReportEventsQueryVariables = Exact<{
  filter?: Maybe<DirSportingEventWhereInput>;
}>;


export type CalendarReportEventsQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & CalendarReportEventFragment
  )> }
);

export type UpdateNotificationProtocolMutationVariables = Exact<{
  id?: Maybe<Scalars['UUID']>;
  data: NotificationProtocolUpdateInput;
}>;


export type UpdateNotificationProtocolMutation = (
  { __typename?: 'Mutation' }
  & { updateNotificationProtocol?: Maybe<(
    { __typename?: 'NotificationProtocol' }
    & Pick<NotificationProtocol, 'id'>
  )> }
);

export type CreateNotificationProtocolMutationVariables = Exact<{
  data: NotificationProtocolCreateInput;
}>;


export type CreateNotificationProtocolMutation = (
  { __typename?: 'Mutation' }
  & { createNotificationProtocol: (
    { __typename?: 'NotificationProtocol' }
    & Pick<NotificationProtocol, 'id'>
  ) }
);

export type DeleteNotificationProtocolMutationVariables = Exact<{
  id?: Maybe<Scalars['UUID']>;
}>;


export type DeleteNotificationProtocolMutation = (
  { __typename?: 'Mutation' }
  & { deleteNotificationProtocol?: Maybe<(
    { __typename?: 'NotificationProtocol' }
    & Pick<NotificationProtocol, 'id'>
  )> }
);

export type NotificationProtocolQueryVariables = Exact<{
  dirCalendar: Scalars['UUID'];
  dirSport: Scalars['UUID'];
  dirRegion: Scalars['UUID'];
}>;


export type NotificationProtocolQuery = (
  { __typename?: 'Query' }
  & { notificationProtocols: Array<(
    { __typename?: 'NotificationProtocol' }
    & Pick<NotificationProtocol, 'id'>
    & { forNotificationROIVSpecialists?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'email' | 'id'>
      & { regular?: Maybe<(
        { __typename?: 'RegularUser' }
        & Pick<RegularUser, 'firstname' | 'lastname' | 'patronymic'>
      )> }
    )>> }
  )> }
);

export type CreateCalendarRegulationMutationVariables = Exact<{
  data: DirSportEventRegulationsCreateInput;
}>;


export type CreateCalendarRegulationMutation = (
  { __typename?: 'Mutation' }
  & { createDirSportEventRegulations: (
    { __typename?: 'DirSportEventRegulations' }
    & Pick<DirSportEventRegulations, 'id'>
  ) }
);

export type UpdateCalendarRegulationMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirSportEventRegulationsUpdateInput;
}>;


export type UpdateCalendarRegulationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportEventRegulations?: Maybe<(
    { __typename?: 'DirSportEventRegulations' }
    & Pick<DirSportEventRegulations, 'id'>
  )> }
);

export type UpdateCalendarRegulationsDocumentMutationVariables = Exact<{
  id: Scalars['UUID'];
  regulationId: Scalars['UUID'];
  fileId: Scalars['UUID'];
}>;


export type UpdateCalendarRegulationsDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateDirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id'>
  )> }
);

export type DeleteCalendarRegulationsDocumentMutationVariables = Exact<{
  id: Scalars['UUID'];
  regulationId: Scalars['UUID'];
}>;


export type DeleteCalendarRegulationsDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateDirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id'>
  )> }
);

export type UpdateCalendarRegulationsAmendedDocumentMutationVariables = Exact<{
  id: Scalars['UUID'];
  regulationId: Scalars['UUID'];
  fileId: Scalars['UUID'];
}>;


export type UpdateCalendarRegulationsAmendedDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateDirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id'>
  )> }
);

export type DeleteCalendarRegulationsAmendedDocumentMutationVariables = Exact<{
  id: Scalars['UUID'];
  regulationId: Scalars['UUID'];
}>;


export type DeleteCalendarRegulationsAmendedDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateDirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id'>
  )> }
);

export type UpdateCalendarRegulationsAgreedDocumentMutationVariables = Exact<{
  id: Scalars['UUID'];
  regulationId: Scalars['UUID'];
  fileId: Scalars['UUID'];
}>;


export type UpdateCalendarRegulationsAgreedDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateDirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id'>
  )> }
);

export type DeleteCalendarRegulationsAgreedDocumentMutationVariables = Exact<{
  id: Scalars['UUID'];
  regulationId: Scalars['UUID'];
}>;


export type DeleteCalendarRegulationsAgreedDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateDirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id'>
  )> }
);

export type CalendarRegulationsNotifyMutationVariables = Exact<{
  calendarName: Scalars['String'];
  calendarId: Scalars['String'];
  sportName: Scalars['String'];
  sportId: Scalars['String'];
  notificationTypeNumber: Scalars['String'];
}>;


export type CalendarRegulationsNotifyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDirSportsThenNotify'>
);

export type RegulationAddUserRegulationsDocMutationVariables = Exact<{
  idRegulation: Scalars['UUID'];
  idUser: Scalars['UUID'];
}>;


export type RegulationAddUserRegulationsDocMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportEventRegulations?: Maybe<(
    { __typename?: 'DirSportEventRegulations' }
    & Pick<DirSportEventRegulations, 'id'>
  )> }
);

export type RegulationStatusValuesFragment = (
  { __typename?: 'DirSportingEventRegulationStatusValue' }
  & { value: DirSportingEventRegulationStatusValue['id'], label: DirSportingEventRegulationStatusValue['name'] }
);

export type RegulationStatusFragment = (
  { __typename?: 'DirSportingEventRegulationStatus' }
  & Pick<DirSportingEventRegulationStatus, 'id' | 'createdAt' | 'comment' | 'isActive'>
  & { value: (
    { __typename?: 'DirSportingEventRegulationStatusValue' }
    & RegulationStatusValuesFragment
  ), createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email'>
    & { regular?: Maybe<(
      { __typename?: 'RegularUser' }
      & Pick<RegularUser, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'phone' | 'address'>
      & { dirOrganization?: Maybe<(
        { __typename?: 'DirOrganization' }
        & Pick<DirOrganization, 'id' | 'name' | 'legalAddress'>
      )> }
    )> }
  )>, file?: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'name' | 'path'>
  )> }
);

export type CalendarRegulationStatusesQueryVariables = Exact<{
  filter?: Maybe<DirSportingEventRegulationStatusWhereInput>;
}>;


export type CalendarRegulationStatusesQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventRegulationStatuses: Array<(
    { __typename?: 'DirSportingEventRegulationStatus' }
    & RegulationStatusFragment
  )> }
);

export type CalendarRegulationsSportsQueryVariables = Exact<{
  id: Scalars['UUID'];
  sportId: Scalars['UUID'];
}>;


export type CalendarRegulationsSportsQuery = (
  { __typename?: 'Query' }
  & { dirSportEventRegulationses: Array<(
    { __typename?: 'DirSportEventRegulations' }
    & Pick<DirSportEventRegulations, 'id'>
    & { dirSport?: Maybe<(
      { __typename?: 'DirSport' }
      & Pick<DirSport, 'id' | 'fullName'>
    )>, regulationsDocument?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'path'>
    )>, fcpsrAddRegulationsDoc?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
      & { personProfile?: Maybe<(
        { __typename?: 'DirPerson' }
        & Pick<DirPerson, 'lastname' | 'patronymic' | 'firstname' | 'email'>
      )>, regular?: Maybe<(
        { __typename?: 'RegularUser' }
        & Pick<RegularUser, 'lastname' | 'patronymic' | 'firstname'>
      )> }
    )>, regulationsAmendedDocument?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'path'>
    )>, regulationsAgreedDocument?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'path'>
    )>, status?: Maybe<Array<(
      { __typename?: 'DirSportingEventRegulationStatus' }
      & RegulationStatusFragment
    )>> }
  )> }
);

export type CalendarRegulationStatusValuesQueryVariables = Exact<{
  filter?: Maybe<DirSportingEventRegulationStatusValueWhereInput>;
}>;


export type CalendarRegulationStatusValuesQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventRegulationStatusValues: Array<(
    { __typename?: 'DirSportingEventRegulationStatusValue' }
    & RegulationStatusValuesFragment
  )> }
);

export type CalendarRegulationsDocumentQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CalendarRegulationsDocumentQuery = (
  { __typename?: 'Query' }
  & { dirSportEventRegulations?: Maybe<(
    { __typename?: 'DirSportEventRegulations' }
    & Pick<DirSportEventRegulations, 'id'>
    & { regulationsDocument?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path' | 'name'>
    )> }
  )> }
);

export type CalendarRegulationsAmendedDocumentQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CalendarRegulationsAmendedDocumentQuery = (
  { __typename?: 'Query' }
  & { dirSportEventRegulations?: Maybe<(
    { __typename?: 'DirSportEventRegulations' }
    & Pick<DirSportEventRegulations, 'id'>
    & { regulationsAmendedDocument?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path' | 'name'>
    )> }
  )> }
);

export type CalendarRegulationsAgreedDocumentQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CalendarRegulationsAgreedDocumentQuery = (
  { __typename?: 'Query' }
  & { dirSportEventRegulations?: Maybe<(
    { __typename?: 'DirSportEventRegulations' }
    & Pick<DirSportEventRegulations, 'id'>
    & { regulationsAgreedDocument?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path' | 'name'>
    )> }
  )> }
);

export type CalendarGRegulationsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CalendarGRegulationsQuery = (
  { __typename?: 'Query' }
  & { dirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id'>
    & { sportCompetitionRegulationDocument?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path' | 'id'>
      & { date: File['createdAt'] }
    )>, dirSportEventRegulations?: Maybe<Array<(
      { __typename?: 'DirSportEventRegulations' }
      & Pick<DirSportEventRegulations, 'id'>
      & { dirSport?: Maybe<(
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id' | 'fullName'>
      )>, regulationsDocument?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'name' | 'path'>
      )>, regulationsAmendedDocument?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'name' | 'path'>
      )>, regulationsAgreedDocument?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'name' | 'path'>
      )>, status?: Maybe<Array<(
        { __typename?: 'DirSportingEventRegulationStatus' }
        & RegulationStatusFragment
      )>> }
    )>> }
  )> }
);

export type AddQuotaForStageTwoBySportsMutationVariables = Exact<{
  data: QuotaForStageTwoBySportCreateInput;
}>;


export type AddQuotaForStageTwoBySportsMutation = (
  { __typename?: 'Mutation' }
  & { createQuotaForStageTwoBySport: (
    { __typename?: 'QuotaForStageTwoBySport' }
    & Pick<QuotaForStageTwoBySport, 'id'>
  ) }
);

export type UpdateQuotaForStageTwoBySportsMutationVariables = Exact<{
  data: QuotaForStageTwoBySportUpdateInput;
  idQuota: Scalars['UUID'];
}>;


export type UpdateQuotaForStageTwoBySportsMutation = (
  { __typename?: 'Mutation' }
  & { updateQuotaForStageTwoBySport?: Maybe<(
    { __typename?: 'QuotaForStageTwoBySport' }
    & Pick<QuotaForStageTwoBySport, 'id'>
  )> }
);

export type DeleteQuotaForStageTwoBySportsMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteQuotaForStageTwoBySportsMutation = (
  { __typename?: 'Mutation' }
  & { deleteQuotaForStageTwoBySport?: Maybe<(
    { __typename?: 'QuotaForStageTwoBySport' }
    & Pick<QuotaForStageTwoBySport, 'id'>
  )> }
);

export type UpdateDateQuoteRequestApplicationMutationVariables = Exact<{
  id: Scalars['UUID'];
  date?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateDateQuoteRequestApplicationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'dateAcceptQuotasAndFilesStageTwo'>
  )> }
);

export type UpdateQuoteByRegionMutationVariables = Exact<{
  id?: Maybe<Scalars['UUID']>;
  data: QuantyRequestStageTwoUpdateInput;
}>;


export type UpdateQuoteByRegionMutation = (
  { __typename?: 'Mutation' }
  & { updateQuantyRequestStageTwo?: Maybe<(
    { __typename?: 'QuantyRequestStageTwo' }
    & Pick<QuantyRequestStageTwo, 'id'>
  )> }
);

export type CreateQuoteByRegionMutationVariables = Exact<{
  data: QuantyRequestStageTwoCreateInput;
}>;


export type CreateQuoteByRegionMutation = (
  { __typename?: 'Mutation' }
  & { createQuantyRequestStageTwo: (
    { __typename?: 'QuantyRequestStageTwo' }
    & Pick<QuantyRequestStageTwo, 'id'>
  ) }
);

export type CreateReqPartFromStageTwoMutationVariables = Exact<{
  data: ReqPartFromStageTwoCreateInput;
}>;


export type CreateReqPartFromStageTwoMutation = (
  { __typename?: 'Mutation' }
  & { createReqPartFromStageTwo: (
    { __typename?: 'ReqPartFromStageTwo' }
    & Pick<ReqPartFromStageTwo, 'id'>
  ) }
);

export type UpdateReqPartFromStageTwoMutationVariables = Exact<{
  data: ReqPartFromStageTwoUpdateInput;
  where: ReqPartFromStageTwoWhereUniqueInput;
}>;


export type UpdateReqPartFromStageTwoMutation = (
  { __typename?: 'Mutation' }
  & { updateReqPartFromStageTwo?: Maybe<(
    { __typename?: 'ReqPartFromStageTwo' }
    & Pick<ReqPartFromStageTwo, 'id'>
  )> }
);

export type DeleteReqPartFromStageTwoMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteReqPartFromStageTwoMutation = (
  { __typename?: 'Mutation' }
  & { deleteReqPartFromStageTwo?: Maybe<(
    { __typename?: 'ReqPartFromStageTwo' }
    & Pick<ReqPartFromStageTwo, 'id'>
  )> }
);

export type FetchListSportsRequestApplicationQueryVariables = Exact<{
  IdCalendar: Scalars['UUID'];
}>;


export type FetchListSportsRequestApplicationQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & { sports?: Maybe<Array<(
      { __typename?: 'DirSportingEventSport' }
      & Pick<DirSportingEventSport, 'id'>
      & { dirSport: (
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id' | 'fullName'>
      ) }
    )>> }
  )>, quotaForStageTwoBySports: Array<(
    { __typename?: 'QuotaForStageTwoBySport' }
    & Pick<QuotaForStageTwoBySport, 'quotaAccompStageTwo' | 'quotaMaleAthlStageTwo' | 'quotaWomenAthlStageTwo' | 'id'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & Pick<DirSport, 'id'>
    ) }
  )> }
);

export type GetDateQuoteRequestApplicationQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetDateQuoteRequestApplicationQuery = (
  { __typename?: 'Query' }
  & { dirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'dateAcceptQuotasAndFilesStageTwo'>
  )> }
);

export type GetDataForQuoteByDirRegionQueryVariables = Exact<{
  idCalendar: Scalars['UUID'];
  idRegion?: Maybe<Scalars['UUID']>;
}>;


export type GetDataForQuoteByDirRegionQuery = (
  { __typename?: 'Query' }
  & { quantyRequestStageTwoes: Array<(
    { __typename?: 'QuantyRequestStageTwo' }
    & Pick<QuantyRequestStageTwo, 'id' | 'regionQuotaWomenAthlStageTwo' | 'regionQuotaMaleAthlStageTwo' | 'regionQuotaAccompStageTwo'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & Pick<DirSport, 'id' | 'fullName'>
    ) }
  )> }
);

export type GetReqPartFromStageTwoesQueryVariables = Exact<{
  where?: Maybe<ReqPartFromStageTwoWhereInput>;
}>;


export type GetReqPartFromStageTwoesQuery = (
  { __typename?: 'Query' }
  & { reqPartFromStageTwoes: Array<(
    { __typename?: 'ReqPartFromStageTwo' }
    & Pick<ReqPartFromStageTwo, 'id'>
    & { region: (
      { __typename?: 'DirRegion' }
      & Pick<DirRegion, 'id'>
    ), roivSpecialistsForNotification?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
      & { regular?: Maybe<(
        { __typename?: 'RegularUser' }
        & Pick<RegularUser, 'firstname' | 'lastname' | 'patronymic'>
      )> }
    )>>, filesROIVStageTwo?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'path'>
    )>> }
  )> }
);

export type ValidationEditableStepOneQueryVariables = Exact<{
  id: Scalars['UUID'];
  idSport: Scalars['UUID'];
}>;


export type ValidationEditableStepOneQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id' | 'daysBeforeStartToAddProtocols'>
    & { clsEventStages?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'fullName' | 'shortName'>
    )>> }
  )> }
);

export type ValidationEditableEventBlockQueryVariables = Exact<{
  id: Scalars['UUID'];
  idSport: Scalars['UUID'];
  dateValid: Scalars['DateTime'];
}>;


export type ValidationEditableEventBlockQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
    & { clsEventStages?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'fullName' | 'shortName'>
    )>> }
  )> }
);

export type VenueAddDirDocumentMutationVariables = Exact<{
  idCalendar?: Maybe<Scalars['UUID']>;
  data: DirCalendarUpdateInput;
}>;


export type VenueAddDirDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateDirCalendar?: Maybe<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id'>
  )> }
);

export type CreateVenueCompetitionMutationVariables = Exact<{
  data: VenueCompetitionCreateInput;
}>;


export type CreateVenueCompetitionMutation = (
  { __typename?: 'Mutation' }
  & { createVenueCompetition: (
    { __typename?: 'VenueCompetition' }
    & Pick<VenueCompetition, 'id'>
  ) }
);

export type UpdateVenueCompetitionMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: VenueCompetitionUpdateInput;
}>;


export type UpdateVenueCompetitionMutation = (
  { __typename?: 'Mutation' }
  & { updateVenueCompetition?: Maybe<(
    { __typename?: 'VenueCompetition' }
    & Pick<VenueCompetition, 'id'>
  )> }
);

export type DeleteVenueCompetitionMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteVenueCompetitionMutation = (
  { __typename?: 'Mutation' }
  & { deleteVenueCompetition?: Maybe<(
    { __typename?: 'VenueCompetition' }
    & Pick<VenueCompetition, 'id'>
  )> }
);

export type CreateVenueCompApplicationMutationVariables = Exact<{
  dirRegion: Scalars['UUID'];
  compId: Scalars['UUID'];
  number?: Maybe<Scalars['Int']>;
  notificationSentToFCPSR: Scalars['Boolean'];
}>;


export type CreateVenueCompApplicationMutation = (
  { __typename?: 'Mutation' }
  & { createVenueCompApplication: (
    { __typename?: 'VenueCompApplication' }
    & Pick<VenueCompApplication, 'id'>
  ) }
);

export type UpdateVenueCompApplicationMutationVariables = Exact<{
  compAppId: Scalars['UUID'];
  data: VenueCompApplicationUpdateInput;
}>;


export type UpdateVenueCompApplicationMutation = (
  { __typename?: 'Mutation' }
  & { updateVenueCompApplication?: Maybe<(
    { __typename?: 'VenueCompApplication' }
    & Pick<VenueCompApplication, 'id'>
  )> }
);

export type DeleteVenueCompApplicationMutationVariables = Exact<{
  compAppId: Scalars['UUID'];
}>;


export type DeleteVenueCompApplicationMutation = (
  { __typename?: 'Mutation' }
  & { deleteVenueCompApplication?: Maybe<(
    { __typename?: 'VenueCompApplication' }
    & Pick<VenueCompApplication, 'id'>
  )> }
);

export type PositionVenueCompetitionNotifyMutationVariables = Exact<{
  venueCompetitionId: Scalars['String'];
}>;


export type PositionVenueCompetitionNotifyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'positionVenueCompetitionNotify'>
);

export type DecisionVenueCompetitionNotifyMutationVariables = Exact<{
  venueCompetitionId: Scalars['String'];
}>;


export type DecisionVenueCompetitionNotifyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'decisionVenueCompetitionNotify'>
);

export type AgreementVenueCompetitionNotifyMutationVariables = Exact<{
  venueCompApplicationId: Scalars['String'];
}>;


export type AgreementVenueCompetitionNotifyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'agreementVenueCompetitionNotify'>
);

export type CreateGenderAndAgeGroupMutationVariables = Exact<{
  data: DirGenderAndAgeGroupsCreateInput;
}>;


export type CreateGenderAndAgeGroupMutation = (
  { __typename?: 'Mutation' }
  & { createDirGenderAndAgeGroups: (
    { __typename?: 'DirGenderAndAgeGroups' }
    & Pick<DirGenderAndAgeGroups, 'id'>
  ) }
);

export type UpdateGenderAndAgeGroupMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirGenderAndAgeGroupsUpdateInput;
}>;


export type UpdateGenderAndAgeGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateDirGenderAndAgeGroups?: Maybe<(
    { __typename?: 'DirGenderAndAgeGroups' }
    & Pick<DirGenderAndAgeGroups, 'id'>
  )> }
);

export type CreateClassifierMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateClassifierMutation = (
  { __typename?: 'Mutation' }
  & { createClassifier: (
    { __typename?: 'Classifier' }
    & ClassifierFragment
  ) }
);

export type UpdateClassifierMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type UpdateClassifierMutation = (
  { __typename?: 'Mutation' }
  & { updateClassifier?: Maybe<(
    { __typename?: 'Classifier' }
    & ClassifierFragment
  )> }
);

export type CreateClassifierValueMutationVariables = Exact<{
  id: Scalars['UUID'];
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
}>;


export type CreateClassifierValueMutation = (
  { __typename?: 'Mutation' }
  & { createClassifierValue: (
    { __typename?: 'ClassifierValue' }
    & ClsValueFragment
  ) }
);

export type UpdateClassifierValueMutationVariables = Exact<{
  id: Scalars['UUID'];
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
}>;


export type UpdateClassifierValueMutation = (
  { __typename?: 'Mutation' }
  & { updateClassifierValue?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & ClsValueFragment
  )> }
);

export type ClassifierArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type ClassifierArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateClassifier?: Maybe<(
    { __typename?: 'Classifier' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type ClassifierUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ClassifierUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateClassifier?: Maybe<(
    { __typename?: 'Classifier' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type ClassifierValueArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type ClassifierValueArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateClassifierValue?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type ClassifierValueUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ClassifierValueUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateClassifierValue?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type ClassifierFragment = (
  { __typename?: 'Classifier' }
  & Pick<Classifier, 'id' | 'name' | 'registryNumber'>
  & { archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )> }
);

export type ClsValueFragment = (
  { __typename?: 'ClassifierValue' }
  & Pick<ClassifierValue, 'fullName' | 'shortName' | 'id' | 'registryNumber' | 'order'>
  & { archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )> }
);

export type UniqueValidationClassifierValueQueryVariables = Exact<{
  fullName: Scalars['String'];
}>;


export type UniqueValidationClassifierValueQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & ClsValueFragment
  )> }
);

export type UniqueValidationClassifierQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type UniqueValidationClassifierQuery = (
  { __typename?: 'Query' }
  & { classifiers: Array<(
    { __typename?: 'Classifier' }
    & ClassifierFragment
  )> }
);

export type ClassifierByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ClassifierByIdQuery = (
  { __typename?: 'Query' }
  & { classifier?: Maybe<(
    { __typename?: 'Classifier' }
    & { values?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & ClsValueFragment
    )>> }
    & ClassifierFragment
  )> }
);

export type ClassifiersQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<ClassifierWhereInput>;
}>;


export type ClassifiersQuery = (
  { __typename?: 'Query' }
  & { classifiers: Array<(
    { __typename?: 'Classifier' }
    & ClassifierFragment
  )> }
);

export type ClassifierValueFragment = (
  { __typename?: 'ClassifierValue' }
  & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
);

export type ClsTrainingStagesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsTrainingStagesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsAddressTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsAddressTypesQuery = (
  { __typename?: 'Query' }
  & { addressTypes: Array<(
    { __typename?: 'AddressType' }
    & { value: AddressType['id'], label: AddressType['name'] }
  )> }
);

export type ClsAgeGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsAgeGroupsQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type GenderAndAgeGroupsQueryVariables = Exact<{
  filter?: Maybe<DirGenderAndAgeGroupsWhereInput>;
}>;


export type GenderAndAgeGroupsQuery = (
  { __typename?: 'Query' }
  & { dirGenderAndAgeGroupses: Array<(
    { __typename?: 'DirGenderAndAgeGroups' }
    & Pick<DirGenderAndAgeGroups, 'id' | 'isMale'>
    & { clsAgeGroups: (
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'id'>
    ) }
  )> }
);

export type ClsArchiveIssuesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsArchiveIssuesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & ClassifierValueFragment
  )> }
);

export type ClsCalendarCategoryQueryVariables = Exact<{
  excludeStages?: Maybe<Array<Scalars['UUID']>>;
}>;


export type ClsCalendarCategoryQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], shortLabel: ClassifierValue['shortName'], value: ClassifierValue['registryNumber'] }
  )> }
);

export type ClsCalendarCategoryIdQueryVariables = Exact<{
  excludeStages?: Maybe<Array<Scalars['UUID']>>;
}>;


export type ClsCalendarCategoryIdQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], shortLabel: ClassifierValue['shortName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsCalendarStagesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsCalendarStagesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber'>
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsDepartmentAffiliationQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsDepartmentAffiliationQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsDirectionalityQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsDirectionalityQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['registryNumber'] }
  )> }
);

export type ClsDivisionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsDivisionsQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsDocumentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsDocumentTypesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'shortName' | 'registryNumber'>
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsDocumentRTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsDocumentRTypesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'shortName'>
    & { label: ClassifierValue['fullName'], value: ClassifierValue['registryNumber'] }
  )> }
);

export type ClsEventCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsEventCategoriesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber'>
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsEventStagesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsEventStagesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'fullName' | 'registryNumber'>
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsFamilyStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsFamilyStatusQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsFinancingTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsFinancingTypesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsGroupTypeSportsAndDisciplinesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsGroupTypeSportsAndDisciplinesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsLevelQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsLevelQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsOrganizationCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsOrganizationCategoriesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsPartEkpQueryVariables = Exact<{
  excludeStages?: Maybe<Array<Scalars['UUID']>>;
}>;


export type ClsPartEkpQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], shortLabel: ClassifierValue['shortName'], value: ClassifierValue['registryNumber'] }
  )> }
);

export type ClsPositionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsPositionsQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsRanksAndTitlesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsRanksAndTitlesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'order'>
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsRefereeCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsRefereeCategoriesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsRefereesPositionsQueryVariables = Exact<{
  filter?: Maybe<ClassifierValueWhereInput>;
}>;


export type ClsRefereesPositionsQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber'>
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsSeasonQueryVariables = Exact<{
  excludeStages?: Maybe<Array<Scalars['UUID']>>;
}>;


export type ClsSeasonQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], shortLabel: ClassifierValue['shortName'], value: ClassifierValue['registryNumber'] }
  )> }
);

export type ClsSportCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsSportCategoriesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsSportingEventsRequestStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsSportingEventsRequestStatusQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventRequestStatusValues: Array<(
    { __typename?: 'DirSportingEventRequestStatusValue' }
    & { value: DirSportingEventRequestStatusValue['id'], label: DirSportingEventRequestStatusValue['name'] }
  )> }
);

export type ClsSportingEventsCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsSportingEventsCategoriesQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber' | 'fullName'>
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsStagePreparingQueryVariables = Exact<{
  excludeStages?: Maybe<Array<Scalars['UUID']>>;
}>;


export type ClsStagePreparingQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], shortLabel: ClassifierValue['shortName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsSummarizingTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsSummarizingTypeQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['registryNumber'] }
  )> }
);

export type ClsTeamCompositionQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsTeamCompositionQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type ClsTypeContactQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsTypeContactQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile?: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id'>
  )> }
);

export type CreateDocumentMutationVariables = Exact<{
  data: DirDocumentCreateInput;
}>;


export type CreateDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createDirDocument: (
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id'>
  ) }
);

export type UpdateDocumentMutationVariables = Exact<{
  data: DirDocumentUpdateInput;
  id: Scalars['UUID'];
}>;


export type UpdateDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateDirDocument?: Maybe<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id'>
  )> }
);

export type DocumentArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type DocumentArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirDocument?: Maybe<(
    { __typename?: 'DirDocument' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type DocumentUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DocumentUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirDocument?: Maybe<(
    { __typename?: 'DirDocument' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type DocumentFragment = (
  { __typename?: 'DirDocument' }
  & Pick<DirDocument, 'id' | 'date' | 'number' | 'title'>
  & { clsType?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  )>, file: (
    { __typename?: 'File' }
    & Pick<File, 'id' | 'name' | 'path'>
  ), archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )> }
);

export type DocumentsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirDocumentWhereInput>;
}>;


export type DocumentsQuery = (
  { __typename?: 'Query' }
  & { dirDocuments: Array<(
    { __typename?: 'DirDocument' }
    & DocumentFragment
  )> }
);

export type DocumentsCountQueryVariables = Exact<{
  filter?: Maybe<DirDocumentWhereInput>;
}>;


export type DocumentsCountQuery = (
  { __typename?: 'Query' }
  & { count: Query['dirDocumentsCount'] }
);

export type DocumentByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DocumentByIdQuery = (
  { __typename?: 'Query' }
  & { dirDocument?: Maybe<(
    { __typename?: 'DirDocument' }
    & DocumentFragment
  )> }
);

export type FindDocumentsByTitleQueryVariables = Exact<{
  search: Scalars['String'];
  bannedId?: Maybe<Array<Scalars['UUID']>>;
}>;


export type FindDocumentsByTitleQuery = (
  { __typename?: 'Query' }
  & { dirDocuments: Array<(
    { __typename?: 'DirDocument' }
    & { label: DirDocument['title'], value: DirDocument['id'] }
  )> }
);

export type SimilarDocumentsQueryVariables = Exact<{
  search: Scalars['String'];
  filter?: Maybe<DirDocumentWhereInput>;
}>;


export type SimilarDocumentsQuery = (
  { __typename?: 'Query' }
  & { dirDocumentsSimilar: Array<(
    { __typename?: 'SimilarDocument' }
    & Pick<SimilarDocument, 'similarity'>
    & { document: (
      { __typename?: 'DirDocument' }
      & { value: DirDocument['id'], label: DirDocument['title'] }
    ) }
  )> }
);

export type UpdateTrainerMutationVariables = Exact<{
  id: Scalars['UUID'];
  idPerson: Scalars['UUID'];
}>;


export type UpdateTrainerMutation = (
  { __typename?: 'Mutation' }
  & { updateDirTrainer?: Maybe<(
    { __typename?: 'DirTrainer' }
    & TrainerFrFragment
  )> }
);

export type CreateTrainerMutationVariables = Exact<{
  idPerson: Scalars['UUID'];
}>;


export type CreateTrainerMutation = (
  { __typename?: 'Mutation' }
  & { createDirTrainer: (
    { __typename?: 'DirTrainer' }
    & TrainerFrFragment
  ) }
);

export type AddTypeSportsToTrainerMutationVariables = Exact<{
  id: Scalars['UUID'];
  sportId: Scalars['UUID'];
}>;


export type AddTypeSportsToTrainerMutation = (
  { __typename?: 'Mutation' }
  & { updateDirTrainer?: Maybe<(
    { __typename?: 'DirTrainer' }
    & TrainerFrFragment
  )> }
);

export type TypeSportTrainerArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type TypeSportTrainerArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirTrainerSport?: Maybe<(
    { __typename?: 'DirTrainerSport' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type TypeSportTrainerUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TypeSportTrainerUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirTrainerSport?: Maybe<(
    { __typename?: 'DirTrainerSport' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type CreateTrainerOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  clsPosition: Scalars['UUID'];
  idDirOrganization: Scalars['UUID'];
  employmentDate: Scalars['DateTime'];
  dismissalDate?: Maybe<Scalars['DateTime']>;
}>;


export type CreateTrainerOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirTrainerSport?: Maybe<(
    { __typename?: 'DirTrainerSport' }
    & Pick<DirTrainerSport, 'id'>
  )> }
);

export type UpdateTrainerOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  clsPosition: Scalars['UUID'];
  idDirOrganization: Scalars['UUID'];
  employmentDate: Scalars['DateTime'];
  dismissalDate?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateTrainerOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirTrainerSportOrganization?: Maybe<(
    { __typename?: 'DirTrainerSportOrganization' }
    & Pick<DirTrainerSportOrganization, 'id'>
  )> }
);

export type FireTrainerWithAthleteSelfTrainMutationVariables = Exact<{
  trainerID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
}>;


export type FireTrainerWithAthleteSelfTrainMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'trainerDismissalWithAthletesSelfTraining'>
);

export type FireTrainerWithAthleteExpelMutationVariables = Exact<{
  trainerID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
}>;


export type FireTrainerWithAthleteExpelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'trainerDismissalWithAthletesExpel'>
);

export type FireTrainerWithAthleteAssignToAnotherTrainerMutationVariables = Exact<{
  fromTrainerID: Scalars['UUID'];
  toTrainerID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
}>;


export type FireTrainerWithAthleteAssignToAnotherTrainerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'trainerDismissalWithAthletesAssignToAnotherTrainer'>
);

export type FireTrainerMutationVariables = Exact<{
  id: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
}>;


export type FireTrainerMutation = (
  { __typename?: 'Mutation' }
  & { updateDirTrainerSportOrganization?: Maybe<(
    { __typename?: 'DirTrainerSportOrganization' }
    & Pick<DirTrainerSportOrganization, 'id'>
  )> }
);

export type AnalyticsEventTotalCountUsersQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AnalyticsEventTotalCountUsersQuery = (
  { __typename?: 'Query' }
  & { aggregateParticipantsNumberByCalendar: (
    { __typename?: 'SportingEventParticipantsNumberByCalendarType' }
    & Pick<SportingEventParticipantsNumberByCalendarType, 'subjectsCount' | 'athletesCountByMan' | 'athletesCountByWoman' | 'athletesCount' | 'membersCount'>
    & { events: Array<(
      { __typename?: 'SportingEventParticipantsNumberByParentEvent' }
      & Pick<SportingEventParticipantsNumberByParentEvent, 'name'>
      & { children: Array<(
        { __typename?: 'SportingEventParticipantsNumberByEvent' }
        & Pick<SportingEventParticipantsNumberByEvent, 'name' | 'sports' | 'disciplineGroups' | 'subjectsCount' | 'athletesCountByMan' | 'athletesCountByWoman' | 'athletesCount' | 'membersCount'>
      )> }
    )> }
  ) }
);

export type AnalyticsEventAgeUsersQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AnalyticsEventAgeUsersQuery = (
  { __typename?: 'Query' }
  & { aggregateParticipantsAgeByCalendar: (
    { __typename?: 'SportingEventParticipantsAgeByCalendarType' }
    & Pick<SportingEventParticipantsAgeByCalendarType, 'calendarName' | 'totalAmount'>
    & { byYear: Array<(
      { __typename?: 'SportingEventParticipantsAgeByYearType' }
      & Pick<SportingEventParticipantsAgeByYearType, 'year' | 'amount'>
    )>, byEvent: Array<(
      { __typename?: 'SportingEventParticipantsAgeByEventType' }
      & Pick<SportingEventParticipantsAgeByEventType, 'eventName' | 'sports' | 'disciplineGroups'>
      & { byYear: Array<(
        { __typename?: 'SportingEventParticipantsAgeByYearType' }
        & Pick<SportingEventParticipantsAgeByYearType, 'year' | 'amount'>
      )> }
    )> }
  ) }
);

export type ParticipantsRankAndAwardByCalendarQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ParticipantsRankAndAwardByCalendarQuery = (
  { __typename?: 'Query' }
  & { aggregateParticipantsRankAndAwardByCalendar: (
    { __typename?: 'SportingEventParticipantsRankAndAwardByCalendarType' }
    & Pick<SportingEventParticipantsRankAndAwardByCalendarType, 'calendarName' | 'totalAmountWithoutValidRank' | 'totalAmount'>
    & { total: Array<(
      { __typename?: 'SportingEventParticipantsRankAndAwardTotalType' }
      & Pick<SportingEventParticipantsRankAndAwardTotalType, 'name' | 'amount'>
    )>, byEvent: Array<(
      { __typename?: 'SportingEventParticipantsRankAndAwardByEventType' }
      & Pick<SportingEventParticipantsRankAndAwardByEventType, 'eventName' | 'sports' | 'disciplineGroups' | 'totalAmountWithoutValidRank' | 'totalAmount'>
      & { total: Array<(
        { __typename?: 'SportingEventParticipantsRankAndAwardTotalType' }
        & Pick<SportingEventParticipantsRankAndAwardTotalType, 'name' | 'amount'>
      )> }
    )> }
  ) }
);

export type AggregateEventRefereesCategoryByCalendarQueryVariables = Exact<{
  id?: Maybe<Scalars['UUID']>;
}>;


export type AggregateEventRefereesCategoryByCalendarQuery = (
  { __typename?: 'Query' }
  & { aggregateEventRefereesCategoryByCalendar: (
    { __typename?: 'SportingEventRefereesCategoryByCalendarType' }
    & Pick<SportingEventRefereesCategoryByCalendarType, 'calendarName'>
    & { byEvent: Array<(
      { __typename?: 'SportingEventRefereesCategoryByEventType' }
      & Pick<SportingEventRefereesCategoryByEventType, 'eventName' | 'sports' | 'disciplineGroups' | 'totalAmount'>
      & { total: Array<(
        { __typename?: 'SportingEventRefereesCategoryTotalType' }
        & Pick<SportingEventRefereesCategoryTotalType, 'name' | 'amount'>
      )> }
    )> }
  ) }
);

export type AggregateEventAthletesByPointsQueryVariables = Exact<{
  id?: Maybe<Scalars['UUID']>;
}>;


export type AggregateEventAthletesByPointsQuery = (
  { __typename?: 'Query' }
  & { aggregateEventAthletesByPoints: (
    { __typename?: 'SportingEventAthletesByPointsType' }
    & { regions: Array<(
      { __typename?: 'SportingEventAthletesByPointsRegionType' }
      & Pick<SportingEventAthletesByPointsRegionType, 'name' | 'universityName' | 'athletesNumber' | 'regionID' | 'totalGold' | 'totalSilver' | 'totalBronze' | 'total_4_10' | 'total_11_20'>
    )> }
  ) }
);

export type FederalDistrictFragment = (
  { __typename?: 'DirFederalDistrict' }
  & Pick<DirFederalDistrict, 'id' | 'fullName' | 'shortName'>
  & { archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )> }
);

export type ForeignCityByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ForeignCityByIdQuery = (
  { __typename?: 'Query' }
  & { dirForeignCity?: Maybe<(
    { __typename?: 'DirForeignCity' }
    & ForeignCityFragment
  )> }
);

export type FederalDistrictsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type FederalDistrictsQuery = (
  { __typename?: 'Query' }
  & { dirFederalDistricts: Array<(
    { __typename?: 'DirFederalDistrict' }
    & FederalDistrictFragment
  )> }
);

export type ForeignCityArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type ForeignCityArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirForeignCity?: Maybe<(
    { __typename?: 'DirForeignCity' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type ForeignCityUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ForeignCityUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirForeignCity?: Maybe<(
    { __typename?: 'DirForeignCity' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type ForeignCityUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  countryId?: Maybe<DirCountryUpdateOneRequiredWithoutDirForeignCitiesInput>;
}>;


export type ForeignCityUpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirForeignCity?: Maybe<(
    { __typename?: 'DirForeignCity' }
    & ForeignCityFragment
  )> }
);

export type ForeignCityCreateMutationVariables = Exact<{
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  countryId: DirCountryCreateOneWithoutDirForeignCitiesInput;
}>;


export type ForeignCityCreateMutation = (
  { __typename?: 'Mutation' }
  & { createDirForeignCity: (
    { __typename?: 'DirForeignCity' }
    & ForeignCityFragment
  ) }
);

export type ForeignCityFragment = (
  { __typename?: 'DirForeignCity' }
  & Pick<DirForeignCity, 'id' | 'fullName' | 'shortName'>
  & { dirCountry: (
    { __typename?: 'DirCountry' }
    & { value: DirCountry['id'], label: DirCountry['fullName'] }
  ), archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )> }
);

export type ForeignCitiesQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirForeignCityWhereInput>;
}>;


export type ForeignCitiesQuery = (
  { __typename?: 'Query' }
  & { dirForeignCities: Array<(
    { __typename?: 'DirForeignCity' }
    & ForeignCityFragment
  )> }
);

export type ForeignCitiesListQueryVariables = Exact<{
  filter?: Maybe<DirForeignCityWhereInput>;
}>;


export type ForeignCitiesListQuery = (
  { __typename?: 'Query' }
  & { dirForeignCities: Array<(
    { __typename?: 'DirForeignCity' }
    & { value: DirForeignCity['id'], label: DirForeignCity['fullName'] }
    & { country: (
      { __typename?: 'DirCountry' }
      & Pick<DirCountry, 'id'>
    ) }
  )> }
);

export type SuggestAddressesQueryVariables = Exact<{
  by: Scalars['String'];
}>;


export type SuggestAddressesQuery = (
  { __typename?: 'Query' }
  & { suggestAddresses: Array<(
    { __typename?: 'AddressSearchResultType' }
    & Pick<AddressSearchResultType, 'value' | 'unrestrictedValue' | 'postcode' | 'country' | 'region' | 'area' | 'city' | 'settlement' | 'street' | 'house' | 'number'>
  )> }
);

export type CountriesFetchQueryVariables = Exact<{
  filter?: Maybe<DirCountryWhereInput>;
}>;


export type CountriesFetchQuery = (
  { __typename?: 'Query' }
  & { dirCountries: Array<(
    { __typename?: 'DirCountry' }
    & { value: DirCountry['id'], label: DirCountry['fullName'] }
  )> }
);

export type FederalDistrictsFetchQueryVariables = Exact<{ [key: string]: never; }>;


export type FederalDistrictsFetchQuery = (
  { __typename?: 'Query' }
  & { dirFederalDistricts: Array<(
    { __typename?: 'DirFederalDistrict' }
    & { value: DirFederalDistrict['id'], label: DirFederalDistrict['fullName'] }
  )> }
);

export type GenerateXlsFileQueryVariables = Exact<{
  filterString: Scalars['String'];
  filter: DirSportWhereInput;
  first: Scalars['Int'];
}>;


export type GenerateXlsFileQuery = (
  { __typename?: 'Query' }
  & { dirSportsReport: (
    { __typename?: 'DirSportReport' }
    & Pick<DirSportReport, 'xlsx'>
  ) }
);

export type GenerateAthletesXlsFileQueryVariables = Exact<{
  filterString: Scalars['String'];
  filter: DirAthleteWhereInput;
  first: Scalars['Int'];
}>;


export type GenerateAthletesXlsFileQuery = (
  { __typename?: 'Query' }
  & { dirAthletesReport: (
    { __typename?: 'DirAthleteReport' }
    & Pick<DirAthleteReport, 'xlsx'>
  ) }
);

export type GenerateTrainersXlsFileQueryVariables = Exact<{
  filterString: Scalars['String'];
  filter: DirTrainerWhereInput;
  first: Scalars['Int'];
}>;


export type GenerateTrainersXlsFileQuery = (
  { __typename?: 'Query' }
  & { dirTrainersReport: (
    { __typename?: 'DirTrainerReport' }
    & Pick<DirTrainerReport, 'xlsx'>
  ) }
);

export type GenerateOrganizationXlsFileQueryVariables = Exact<{
  filterString: Scalars['String'];
  filter: DirOrganizationWhereInput;
  first: Scalars['Int'];
}>;


export type GenerateOrganizationXlsFileQuery = (
  { __typename?: 'Query' }
  & { dirOrganizationsReport: (
    { __typename?: 'DirOrganizationReport' }
    & Pick<DirOrganizationReport, 'xlsx'>
  ) }
);

export type GenerateSportingEventsXlsFileQueryVariables = Exact<{
  filterString: Scalars['String'];
  filter: DirSportingEventWhereInput;
  first: Scalars['Int'];
}>;


export type GenerateSportingEventsXlsFileQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventsReport: (
    { __typename?: 'DirSportingEventReport' }
    & Pick<DirSportingEventReport, 'xlsx'>
  ) }
);

export type GenerateNationalTeamsXlsFileQueryVariables = Exact<{
  filterString: Scalars['String'];
  filter: DirNationalTeamWhereInput;
  first: Scalars['Int'];
}>;


export type GenerateNationalTeamsXlsFileQuery = (
  { __typename?: 'Query' }
  & { dirNationalTeamsReport: (
    { __typename?: 'DirNationalTeamReport' }
    & Pick<DirNationalTeamReport, 'xlsx'>
  ) }
);

export type GeneratePersonsXlsFileQueryVariables = Exact<{
  filterString: Scalars['String'];
  filter: DirPersonWhereInput;
  first: Scalars['Int'];
}>;


export type GeneratePersonsXlsFileQuery = (
  { __typename?: 'Query' }
  & { dirPersonsReport: (
    { __typename?: 'DirPersonReport' }
    & Pick<DirPersonReport, 'xlsx'>
  ) }
);

export type GenerateRefereeXlsFileQueryVariables = Exact<{
  filterString: Scalars['String'];
  filter: DirRefereeWhereInput;
  search: Scalars['String'];
  first: Scalars['Int'];
}>;


export type GenerateRefereeXlsFileQuery = (
  { __typename?: 'Query' }
  & { dirRefereesReport: (
    { __typename?: 'DirRefereeReport' }
    & Pick<DirRefereeReport, 'xlsx'>
  ) }
);

export type GenerateRegionXlsFileQueryVariables = Exact<{
  filterString: Scalars['String'];
  filter: DirRegionWhereInput;
  first: Scalars['Int'];
}>;


export type GenerateRegionXlsFileQuery = (
  { __typename?: 'Query' }
  & { dirRegionsReport: (
    { __typename?: 'DirRegionReport' }
    & Pick<DirRegionReport, 'xlsx'>
  ) }
);

export type GenerateCalendarXlsFileQueryVariables = Exact<{
  filterString: Scalars['String'];
  filter: DirCalendarWhereInput;
  first: Scalars['Int'];
}>;


export type GenerateCalendarXlsFileQuery = (
  { __typename?: 'Query' }
  & { dirCalendarsReport: (
    { __typename?: 'DirCalendarReport' }
    & Pick<DirCalendarReport, 'xlsx'>
  ) }
);

export type UserRoleQueryVariables = Exact<{
  filter?: Maybe<UserRoleWhereInput>;
}>;


export type UserRoleQuery = (
  { __typename?: 'Query' }
  & { userRoles: Array<(
    { __typename?: 'UserRole' }
    & { value: UserRole['name'], label: UserRole['description'] }
  )> }
);

export type ValidationTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type ValidationTokenQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type UpdateNationalTeamMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirNationalTeamUpdateInput;
}>;


export type UpdateNationalTeamMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type NationalTeamArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type NationalTeamUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type NationalTeamUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type NationalTeamAddTrainerMutationVariables = Exact<{
  id: Scalars['UUID'];
  trainerId: Scalars['UUID'];
}>;


export type NationalTeamAddTrainerMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamDeleteTrainerMutationVariables = Exact<{
  id: Scalars['UUID'];
  trainerId: Scalars['UUID'];
}>;


export type NationalTeamDeleteTrainerMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamAddSpecialistMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  patronymic: Scalars['String'];
  birthday: Scalars['DateTime'];
}>;


export type NationalTeamAddSpecialistMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamDeleteSpecialistMutationVariables = Exact<{
  id: Scalars['UUID'];
  specId: Scalars['UUID'];
}>;


export type NationalTeamDeleteSpecialistMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamAddMedicMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  patronymic: Scalars['String'];
  birthday: Scalars['DateTime'];
}>;


export type NationalTeamAddMedicMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamDeleteMedicMutationVariables = Exact<{
  id: Scalars['UUID'];
  medId: Scalars['UUID'];
}>;


export type NationalTeamDeleteMedicMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamAddDelegationHeadMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  patronymic: Scalars['String'];
  birthday: Scalars['DateTime'];
}>;


export type NationalTeamAddDelegationHeadMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamDeleteDelegationHeadMutationVariables = Exact<{
  id: Scalars['UUID'];
  headId: Scalars['UUID'];
}>;


export type NationalTeamDeleteDelegationHeadMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamAddAthleteGroupMutationVariables = Exact<{
  id: Scalars['UUID'];
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  ageGroupsId?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  disciplineGroupsId?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
}>;


export type NationalTeamAddAthleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamUpdateAthleteGroupMutationVariables = Exact<{
  id: Scalars['UUID'];
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['UUID']>;
  ageGroupsId?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  disciplineGroupsId?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
}>;


export type NationalTeamUpdateAthleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamDeleteAthleteGroupMutationVariables = Exact<{
  id: Scalars['UUID'];
  groupId?: Maybe<Scalars['UUID']>;
}>;


export type NationalTeamDeleteAthleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamAddAthleteMutationVariables = Exact<{
  id: Scalars['UUID'];
  groupId?: Maybe<Scalars['UUID']>;
  athleteId?: Maybe<Scalars['UUID']>;
  bestResult?: Maybe<Scalars['String']>;
}>;


export type NationalTeamAddAthleteMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamDeleteAthleteMutationVariables = Exact<{
  id: Scalars['UUID'];
  groupId?: Maybe<Scalars['UUID']>;
  athleteId?: Maybe<Scalars['UUID']>;
}>;


export type NationalTeamDeleteAthleteMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type CreateTeamStatusMutationVariables = Exact<{
  id: Scalars['UUID'];
  data?: Maybe<Array<DirNationalTeamExtendedStatusCreateWithoutTeamInput>>;
}>;


export type CreateTeamStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & Pick<DirNationalTeam, 'id'>
  )> }
);

export type SwitchTrainerPrimaryMutationVariables = Exact<{
  id: Scalars['UUID'];
  isPrimary?: Maybe<Scalars['Boolean']>;
}>;


export type SwitchTrainerPrimaryMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeamTrainer?: Maybe<(
    { __typename?: 'DirNationalTeamTrainer' }
    & Pick<DirNationalTeamTrainer, 'id'>
  )> }
);

export type UpdateDirNationalTeamFilesMutationVariables = Exact<{
  id: Scalars['UUID'];
  files: Scalars['UUID'];
}>;


export type UpdateDirNationalTeamFilesMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & { files?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'path'>
    )>> }
  )> }
);

export type UpdateDirNationalTeamFilesRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
  files: Scalars['UUID'];
}>;


export type UpdateDirNationalTeamFilesRemoveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & { files?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'path'>
    )>> }
  )> }
);

export type UpdateDirNationalTeamAthleteMutationVariables = Exact<{
  id: Scalars['UUID'];
  entranceStatus: Scalars['Boolean'];
  admissionDate?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateDirNationalTeamAthleteMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeamAthlete?: Maybe<(
    { __typename?: 'DirNationalTeamAthlete' }
    & Pick<DirNationalTeamAthlete, 'id' | 'entranceStatus'>
  )> }
);

export type UpdateDirNationalTeamTrainerMutationVariables = Exact<{
  id: Scalars['UUID'];
  entranceStatus: Scalars['Boolean'];
  admissionDate?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateDirNationalTeamTrainerMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeamTrainer?: Maybe<(
    { __typename?: 'DirNationalTeamTrainer' }
    & Pick<DirNationalTeamTrainer, 'id' | 'entranceStatus'>
  )> }
);

export type UpdateDirNationalTeamMemberMutationVariables = Exact<{
  id: Scalars['UUID'];
  entranceStatus: Scalars['Boolean'];
  admissionDate?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateDirNationalTeamMemberMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeamMember?: Maybe<(
    { __typename?: 'DirNationalTeamMember' }
    & Pick<DirNationalTeamMember, 'id' | 'entranceStatus'>
  )> }
);

export type UpdateDirNationalTeamAthleteAdmissionDateMutationVariables = Exact<{
  id: Scalars['UUID'];
  admissionDate: Scalars['DateTime'];
}>;


export type UpdateDirNationalTeamAthleteAdmissionDateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeamAthlete?: Maybe<(
    { __typename?: 'DirNationalTeamAthlete' }
    & Pick<DirNationalTeamAthlete, 'id' | 'admissionDate'>
  )> }
);

export type UpdateDirNationalTeamTrainerAdmissionDateMutationVariables = Exact<{
  id: Scalars['UUID'];
  admissionDate: Scalars['DateTime'];
}>;


export type UpdateDirNationalTeamTrainerAdmissionDateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeamTrainer?: Maybe<(
    { __typename?: 'DirNationalTeamTrainer' }
    & Pick<DirNationalTeamTrainer, 'id' | 'admissionDate'>
  )> }
);

export type UpdateDirNationalTeamMemberAdmissionDateMutationVariables = Exact<{
  id: Scalars['UUID'];
  admissionDate: Scalars['DateTime'];
}>;


export type UpdateDirNationalTeamMemberAdmissionDateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeamMember?: Maybe<(
    { __typename?: 'DirNationalTeamMember' }
    & Pick<DirNationalTeamMember, 'id' | 'admissionDate'>
  )> }
);

export type SendNotificationUpdateStatusMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SendNotificationUpdateStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeamExtendedStatusThenNotify: (
    { __typename?: 'DirNationalTeamExtendedStatus' }
    & Pick<DirNationalTeamExtendedStatus, 'isActive'>
  ) }
);

export type NationalTeamFragment = (
  { __typename?: 'DirNationalTeam' }
  & Pick<DirNationalTeam, 'id' | 'registryNumber' | 'comment' | 'fullName' | 'supportTeamQuota' | 'manTeamQuota' | 'womanTeamQuota' | 'athleteQuotaByGender' | 'athleteTotalQuota' | 'score'>
  & { files?: Maybe<Array<(
    { __typename?: 'File' }
    & Pick<File, 'name' | 'path' | 'id'>
  )>>, trainers?: Maybe<Array<(
    { __typename?: 'DirNationalTeamTrainer' }
    & Pick<DirNationalTeamTrainer, 'id' | 'isPrimary'>
    & { dirTrainer: (
      { __typename?: 'DirTrainer' }
      & Pick<DirTrainer, 'id'>
      & { dirPerson: (
        { __typename?: 'DirPerson' }
        & Pick<DirPerson, 'id' | 'registryNumber' | 'firstname' | 'lastname' | 'patronymic' | 'birthday'>
      ) }
    ) }
  )>>, specialists?: Maybe<Array<(
    { __typename?: 'DirNationalTeamMember' }
    & Pick<DirNationalTeamMember, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'birthday'>
  )>>, delegationHeads?: Maybe<Array<(
    { __typename?: 'DirNationalTeamMember' }
    & Pick<DirNationalTeamMember, 'id' | 'lastname' | 'firstname' | 'patronymic' | 'birthday'>
  )>>, medics?: Maybe<Array<(
    { __typename?: 'DirNationalTeamMember' }
    & Pick<DirNationalTeamMember, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'birthday'>
  )>>, athleteGroups?: Maybe<Array<(
    { __typename?: 'DirNationalTeamAthleteGroup' }
    & Pick<DirNationalTeamAthleteGroup, 'id' | 'minAge' | 'maxAge'>
    & { clsAgeGroups?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'id' | 'fullName'>
    )>>, disciplines?: Maybe<Array<(
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
      & { dirSport?: Maybe<(
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id' | 'fullName'>
      )> }
    )>>, disciplineGroups?: Maybe<Array<(
      { __typename?: 'DirSportDisciplineGroup' }
      & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
      & { dirSport?: Maybe<(
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id' | 'fullName'>
      )>, disciplines?: Maybe<Array<(
        { __typename?: 'DirSportDiscipline' }
        & Pick<DirSportDiscipline, 'id' | 'name'>
      )>> }
    )>>, athletes?: Maybe<Array<(
      { __typename?: 'DirNationalTeamAthlete' }
      & Pick<DirNationalTeamAthlete, 'id' | 'bestResult' | 'createdAt'>
      & { dirAthlete: (
        { __typename?: 'DirAthlete' }
        & Pick<DirAthlete, 'id'>
        & { dirPerson: (
          { __typename?: 'DirPerson' }
          & Pick<DirPerson, 'registryNumber' | 'firstname' | 'lastname' | 'patronymic' | 'birthday' | 'isMale'>
        ), sports?: Maybe<Array<(
          { __typename?: 'DirAthleteSport' }
          & Pick<DirAthleteSport, 'id'>
          & { disciplines?: Maybe<Array<(
            { __typename?: 'DirSportDiscipline' }
            & Pick<DirSportDiscipline, 'id'>
          )>>, disciplineGroups?: Maybe<Array<(
            { __typename?: 'DirSportDisciplineGroup' }
            & Pick<DirSportDisciplineGroup, 'id'>
          )>>, dirSport: (
            { __typename?: 'DirSport' }
            & Pick<DirSport, 'id'>
          ), organizations?: Maybe<Array<(
            { __typename?: 'DirAthleteSportOrganization' }
            & Pick<DirAthleteSportOrganization, 'id'>
            & { dirOrganization: (
              { __typename?: 'DirOrganization' }
              & Pick<DirOrganization, 'id' | 'name' | 'fullName'>
            ) }
          )>>, ranksAndAwards?: Maybe<Array<(
            { __typename?: 'DirAthleteSportRankAndAward' }
            & Pick<DirAthleteSportRankAndAward, 'id' | 'validUntil'>
            & { clsRankAndAward: (
              { __typename?: 'ClassifierValue' }
              & Pick<ClassifierValue, 'id' | 'shortName' | 'fullName' | 'order'>
            ) }
          )>> }
        )>>, competetionResult?: Maybe<Array<(
          { __typename?: 'DirAthleteCompetitionResult' }
          & Pick<DirAthleteCompetitionResult, 'id' | 'point' | 'result'>
          & { sportingEventProgramType: (
            { __typename?: 'DirSportingEventProgramType' }
            & Pick<DirSportingEventProgramType, 'id' | 'start' | 'minAge' | 'maxAge'>
            & { discipline: (
              { __typename?: 'DirSportDiscipline' }
              & Pick<DirSportDiscipline, 'id' | 'name'>
            ) }
          ) }
        )>> }
      ) }
    )>> }
  )>>, dirRegion: (
    { __typename?: 'DirRegion' }
    & Pick<DirRegion, 'id' | 'fullName'>
  ), university?: Maybe<(
    { __typename?: 'DirOrganization' }
    & Pick<DirOrganization, 'id' | 'fullName' | 'name'>
  )>, statuses?: Maybe<Array<(
    { __typename?: 'DirNationalTeamExtendedStatus' }
    & Pick<DirNationalTeamExtendedStatus, 'createdAt' | 'comment' | 'isActive'>
    & { value: (
      { __typename?: 'DirNationalTeamStatus' }
      & Pick<DirNationalTeamStatus, 'id' | 'name'>
    ), createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email'>
      & { regular?: Maybe<(
        { __typename?: 'RegularUser' }
        & Pick<RegularUser, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'phone' | 'address'>
        & { dirOrganization?: Maybe<(
          { __typename?: 'DirOrganization' }
          & Pick<DirOrganization, 'id' | 'name' | 'legalAddress'>
        )> }
      )> }
    )>, file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'path'>
    )> }
  )>>, status: (
    { __typename?: 'DirNationalTeamStatus' }
    & Pick<DirNationalTeamStatus, 'id' | 'name'>
  ), statusHistory?: Maybe<Array<(
    { __typename?: 'DirNationalTeamStatusHistory' }
    & Pick<DirNationalTeamStatusHistory, 'id' | 'date'>
    & { status: (
      { __typename?: 'DirNationalTeamStatus' }
      & Pick<DirNationalTeamStatus, 'name'>
    ), user: (
      { __typename?: 'User' }
      & Pick<User, 'name' | 'email'>
    ) }
  )>>, dirSportingEvent: (
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id' | 'startDate' | 'endDate' | 'name' | 'registryNumber' | 'minManBirthYear' | 'maxManBirthYear' | 'minWomanBirthYear' | 'maxWomanBirthYear' | 'athletesQuotaNotCorrespondingByAge' | 'athletesQuotaNotCorrespondingByQualification'>
    & { sportQualificationFemaleNotLower?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'order' | 'registryNumber' | 'fullName' | 'id'>
    )>, sportQualificationMaleNotLower?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'order' | 'registryNumber' | 'fullName' | 'id'>
    )>, clsMinRang?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'order' | 'registryNumber' | 'fullName' | 'id'>
    )>, clsSportingQualificationNotGreater?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'order' | 'registryNumber' | 'fullName' | 'id'>
    )>, programTypes?: Maybe<Array<(
      { __typename?: 'DirSportingEventProgramType' }
      & Pick<DirSportingEventProgramType, 'id'>
      & { clsAgeGroups?: Maybe<Array<(
        { __typename?: 'ClassifierValue' }
        & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
      )>> }
    )>>, sports?: Maybe<Array<(
      { __typename?: 'DirSportingEventSport' }
      & Pick<DirSportingEventSport, 'id'>
      & { dirSport: (
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id' | 'fullName'>
      ), disciplines?: Maybe<Array<(
        { __typename?: 'DirSportDiscipline' }
        & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
      )>>, disciplineGroups?: Maybe<Array<(
        { __typename?: 'DirSportDisciplineGroup' }
        & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
      )>> }
    )>>, dirCalendar: (
      { __typename?: 'DirCalendar' }
      & Pick<DirCalendar, 'id' | 'fullName'>
      & { sportCompetitionRegulationDocument?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'path' | 'name'>
      )>, regulationChanges?: Maybe<Array<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'name' | 'path'>
      )>> }
    ) }
  ), archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )> }
);

export type NationalTeamTableFragment = (
  { __typename?: 'DirNationalTeam' }
  & Pick<DirNationalTeam, 'id' | 'registryNumber'>
  & { dirRegion: (
    { __typename?: 'DirRegion' }
    & Pick<DirRegion, 'id' | 'fullName'>
  ), statuses?: Maybe<Array<(
    { __typename?: 'DirNationalTeamExtendedStatus' }
    & Pick<DirNationalTeamExtendedStatus, 'isActive'>
    & { value: (
      { __typename?: 'DirNationalTeamStatus' }
      & Pick<DirNationalTeamStatus, 'id' | 'name'>
    ) }
  )>>, dirSportingEvent: (
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id' | 'startDate' | 'endDate' | 'name' | 'registryNumber'>
    & { sports?: Maybe<Array<(
      { __typename?: 'DirSportingEventSport' }
      & Pick<DirSportingEventSport, 'id'>
      & { dirSport: (
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id' | 'fullName'>
      ) }
    )>> }
  ), archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )> }
);

export type AthletesInTeamQueryVariables = Exact<{
  eventId: Scalars['UUID'];
  athletesIdArray?: Maybe<Array<Scalars['UUID']>>;
}>;


export type AthletesInTeamQuery = (
  { __typename?: 'Query' }
  & { dirNationalTeams: Array<(
    { __typename?: 'DirNationalTeam' }
    & { teamId: DirNationalTeam['id'] }
    & { athleteGroups?: Maybe<Array<(
      { __typename?: 'DirNationalTeamAthleteGroup' }
      & Pick<DirNationalTeamAthleteGroup, 'id'>
      & { athletes?: Maybe<Array<(
        { __typename?: 'DirNationalTeamAthlete' }
        & Pick<DirNationalTeamAthlete, 'id'>
        & { dirAthlete: (
          { __typename?: 'DirAthlete' }
          & Pick<DirAthlete, 'id'>
          & { person: (
            { __typename?: 'DirPerson' }
            & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'registryNumber' | 'birthday'>
          ) }
        ) }
      )>>, clsAgeGroups?: Maybe<Array<(
        { __typename?: 'ClassifierValue' }
        & Pick<ClassifierValue, 'id'>
      )>> }
    )>> }
  )> }
);

export type AthleteTeamListQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AthleteTeamListQuery = (
  { __typename?: 'Query' }
  & { dirNationalTeams: Array<(
    { __typename?: 'DirNationalTeam' }
    & Pick<DirNationalTeam, 'id'>
    & { athleteGroups?: Maybe<Array<(
      { __typename?: 'DirNationalTeamAthleteGroup' }
      & Pick<DirNationalTeamAthleteGroup, 'id'>
      & { athletes?: Maybe<Array<(
        { __typename?: 'DirNationalTeamAthlete' }
        & Pick<DirNationalTeamAthlete, 'id'>
        & { dirAthlete: (
          { __typename?: 'DirAthlete' }
          & Pick<DirAthlete, 'id'>
        ) }
      )>> }
    )>> }
  )> }
);

export type NationalTeamsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirNationalTeamWhereInput>;
}>;


export type NationalTeamsQuery = (
  { __typename?: 'Query' }
  & { dirNationalTeams: Array<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamTableFragment
  )> }
);

export type NationalTeamsCountQueryVariables = Exact<{
  filter?: Maybe<DirNationalTeamWhereInput>;
}>;


export type NationalTeamsCountQuery = (
  { __typename?: 'Query' }
  & { count: Query['dirNationalTeamsCount'] }
);

export type NationalTeamQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type NationalTeamQuery = (
  { __typename?: 'Query' }
  & { dirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & NationalTeamFragment
  )> }
);

export type NationalTeamPeopleQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type NationalTeamPeopleQuery = (
  { __typename?: 'Query' }
  & { aggregateParticipantsApplicationByTeamEvents: (
    { __typename?: 'SportingEventParticipantsApplicationByEventsTeamType' }
    & { rows: Array<(
      { __typename?: 'SportingEventParticipantsApplicationByEventsTeamTypeRow' }
      & Pick<SportingEventParticipantsApplicationByEventsTeamTypeRow, 'n' | 'id' | 'name' | 'status' | 'birthday' | 'isMale' | 'qualification' | 'organization' | 'entranceStatus' | 'admissionDate' | 'mutateEntranceStatus'>
    )> }
  ) }
);

export type NationalTeamStatusesQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type NationalTeamStatusesQuery = (
  { __typename?: 'Query' }
  & { dirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & Pick<DirNationalTeam, 'id'>
    & { statuses?: Maybe<Array<(
      { __typename?: 'DirNationalTeamExtendedStatus' }
      & Pick<DirNationalTeamExtendedStatus, 'id' | 'isActive'>
    )>> }
  )> }
);

export type UploadNationalTeamsAgreementsDocMutationVariables = Exact<{
  id: Scalars['UUID'];
  idFile: Scalars['UUID'];
}>;


export type UploadNationalTeamsAgreementsDocMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & { refereePersonalDataAgreements?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path' | 'id'>
    )>> }
  )> }
);

export type DeleteNationalTeamsAgreementsDocMutationVariables = Exact<{
  id: Scalars['UUID'];
  idFile: Scalars['UUID'];
}>;


export type DeleteNationalTeamsAgreementsDocMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & { refereePersonalDataAgreements?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path' | 'id'>
    )>> }
  )> }
);

export type GetListNationalTeamAgreementsDocsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetListNationalTeamAgreementsDocsQuery = (
  { __typename?: 'Query' }
  & { dirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & { refereePersonalDataAgreements?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path' | 'id'>
    )>> }
  )> }
);

export type UploadNationalTeamsDeparturePlanMutationVariables = Exact<{
  id: Scalars['UUID'];
  idFile: Scalars['UUID'];
}>;


export type UploadNationalTeamsDeparturePlanMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & { arrivalDeparturePlan?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path' | 'id'>
    )>> }
  )> }
);

export type DeleteNationalTeamsDeparturePlanMutationVariables = Exact<{
  id: Scalars['UUID'];
  idFile: Scalars['UUID'];
}>;


export type DeleteNationalTeamsDeparturePlanMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & { arrivalDeparturePlan?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path' | 'id'>
    )>> }
  )> }
);

export type GetListNationalTeamDeparturePlanQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetListNationalTeamDeparturePlanQuery = (
  { __typename?: 'Query' }
  & { dirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & { arrivalDeparturePlan?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path' | 'id'>
    )>> }
  )> }
);

export type UploadNationalTeamsQuantitativeOrderMutationVariables = Exact<{
  id: Scalars['UUID'];
  idFile: Scalars['UUID'];
}>;


export type UploadNationalTeamsQuantitativeOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & { preliminaryQuantitativeOrder?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path' | 'id'>
    )>> }
  )> }
);

export type DeleteNationalTeamsQuantitativeOrderMutationVariables = Exact<{
  id: Scalars['UUID'];
  idFile: Scalars['UUID'];
}>;


export type DeleteNationalTeamsQuantitativeOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateDirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & { preliminaryQuantitativeOrder?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path' | 'id'>
    )>> }
  )> }
);

export type GetListNationalTeamQuantitativeOrderQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetListNationalTeamQuantitativeOrderQuery = (
  { __typename?: 'Query' }
  & { dirNationalTeam?: Maybe<(
    { __typename?: 'DirNationalTeam' }
    & { preliminaryQuantitativeOrder?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path' | 'id'>
    )>> }
  )> }
);

export type UpdateOrganizationSitesMutationVariables = Exact<{
  id: Scalars['UUID'];
  sites?: Maybe<Array<Scalars['String']>>;
}>;


export type UpdateOrganizationSitesMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type OrganizationSitesQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type OrganizationSitesQuery = (
  { __typename?: 'Query' }
  & { dirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & Pick<DirOrganization, 'id' | 'websites'>
  )> }
);

export type CreateOrganizationMutationVariables = Exact<{
  data: DirOrganizationCreateInput;
}>;


export type CreateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { createDirOrganization: (
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  ) }
);

export type SimpleUpdateOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirOrganizationUpdateInput;
}>;


export type SimpleUpdateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type AddEmailToOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  email: Scalars['String'];
  type: Scalars['UUID'];
}>;


export type AddEmailToOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type UpdateOrganizationEmailMutationVariables = Exact<{
  id: Scalars['UUID'];
  email: Scalars['String'];
  type: Scalars['UUID'];
  emailId: Scalars['UUID'];
}>;


export type UpdateOrganizationEmailMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type DeleteEmailFromOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  emailId: Scalars['UUID'];
}>;


export type DeleteEmailFromOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type AddAddressToOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  type: Scalars['Int'];
}>;


export type AddAddressToOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type UpdateOrganizationAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  type: Scalars['Int'];
  addressId: Scalars['UUID'];
}>;


export type UpdateOrganizationAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type DeleteAddressFromOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  addressId: Scalars['UUID'];
}>;


export type DeleteAddressFromOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type AddPhoneToOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber: Scalars['String'];
  type: Scalars['UUID'];
}>;


export type AddPhoneToOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type UpdateOrganizationPhoneMutationVariables = Exact<{
  id: Scalars['UUID'];
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber: Scalars['String'];
  type: Scalars['UUID'];
  phoneId: Scalars['UUID'];
}>;


export type UpdateOrganizationPhoneMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type DeletePhoneFromOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  phoneId: Scalars['UUID'];
}>;


export type DeletePhoneFromOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirOrganizationUpdateInput;
}>;


export type UpdateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type UpdateOrganizationLegalInfoMutationVariables = Exact<{
  id: Scalars['UUID'];
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  ogrn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  isLegacy: Scalars['Boolean'];
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
}>;


export type UpdateOrganizationLegalInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationLegalInfoFrFragment
  )> }
);

export type OrganizationRemoveTypeSportMutationVariables = Exact<{
  id: Scalars['UUID'];
  idSport: Scalars['UUID'];
}>;


export type OrganizationRemoveTypeSportMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationTypeSportsFrFragment
  )> }
);

export type UpdateOrganizationSportMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirOrganizationSportUpdateInput;
}>;


export type UpdateOrganizationSportMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganizationSport?: Maybe<(
    { __typename?: 'DirOrganizationSport' }
    & Pick<DirOrganizationSport, 'id'>
  )> }
);

export type OrganizationCreateTypeSportMutationVariables = Exact<{
  id: Scalars['UUID'];
  idSport: Scalars['UUID'];
}>;


export type OrganizationCreateTypeSportMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationTypeSportsFrFragment
  )> }
);

export type OrganizationRemoveDeparmentAffiliationMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;


export type OrganizationRemoveDeparmentAffiliationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationDepartmentAffiliationFrFragment
  )> }
);

export type OrganizationCreateDeparmentAffiliationMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;


export type OrganizationCreateDeparmentAffiliationMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationDepartmentAffiliationFrFragment
  )> }
);

export type UpdateOrganizationParentMutationVariables = Exact<{
  id: Scalars['UUID'];
  parentId: Scalars['UUID'];
}>;


export type UpdateOrganizationParentMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationDepartmentAffiliationFrFragment
  )> }
);

export type AddOrganizationChildrenMutationVariables = Exact<{
  id: Scalars['UUID'];
  childID: Scalars['UUID'];
}>;


export type AddOrganizationChildrenMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationDepartmentAffiliationFrFragment
  )> }
);

export type RemoveOrganizationParentMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RemoveOrganizationParentMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationDepartmentAffiliationFrFragment
  )> }
);

export type RemoveOrganizationChildrenMutationVariables = Exact<{
  id: Scalars['UUID'];
  childID: Scalars['UUID'];
}>;


export type RemoveOrganizationChildrenMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationDepartmentAffiliationFrFragment
  )> }
);

export type OrganizationArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type OrganizationArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type OrganizationUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type OrganizationUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type OrganizationTypeSportArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type OrganizationTypeSportArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganizationSport?: Maybe<(
    { __typename?: 'DirOrganizationSport' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type OrganizationTypeSportUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type OrganizationTypeSportUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirOrganizationSport?: Maybe<(
    { __typename?: 'DirOrganizationSport' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type OrganizationFrFragment = (
  { __typename?: 'DirOrganization' }
  & Pick<DirOrganization, 'id' | 'registryNumber' | 'name' | 'ogrn' | 'isUniversity' | 'isLegalEntity' | 'websites'>
  & { dirRegion?: Maybe<(
    { __typename?: 'DirRegion' }
    & Pick<DirRegion, 'shortName'>
    & { value: DirRegion['id'], label: DirRegion['fullName'] }
  )>, sports?: Maybe<Array<(
    { __typename?: 'DirOrganizationSport' }
    & Pick<DirOrganizationSport, 'id' | 'trainingStartDate'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & { label: DirSport['fullName'], value: DirSport['id'] }
    ), disciplineGroups?: Maybe<Array<(
      { __typename?: 'DirSportDisciplineGroup' }
      & { label: DirSportDisciplineGroup['name'], value: DirSportDisciplineGroup['id'] }
    )>> }
  )>>, dirSports?: Maybe<Array<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id' | 'fullName'>
  )>>, archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )>, clsOrganizationCategories?: Maybe<Array<(
    { __typename?: 'ClassifierValue' }
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  )>>, addresses?: Maybe<Array<(
    { __typename?: 'DirOrganizationAddress' }
    & Pick<DirOrganizationAddress, 'id' | 'place' | 'street' | 'house' | 'number'>
    & { type: (
      { __typename?: 'AddressType' }
      & Pick<AddressType, 'id' | 'name'>
    ) }
  )>>, phones?: Maybe<Array<(
    { __typename?: 'DirOrganizationPhone' }
    & Pick<DirOrganizationPhone, 'id' | 'number' | 'additionalNumber' | 'operatorCode' | 'countryCode'>
    & { clsContactType: (
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'id' | 'fullName'>
    ) }
  )>>, emails?: Maybe<Array<(
    { __typename?: 'DirOrganizationEmail' }
    & Pick<DirOrganizationEmail, 'id' | 'email'>
    & { clsContactType: (
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'id' | 'fullName'>
    ) }
  )>>, parent?: Maybe<(
    { __typename?: 'DirOrganization' }
    & Pick<DirOrganization, 'id' | 'ogrn' | 'name'>
  )>, children?: Maybe<Array<(
    { __typename?: 'DirOrganization' }
    & Pick<DirOrganization, 'id' | 'ogrn' | 'name'>
  )>> }
);

export type OrganizationLegalInfoFrFragment = (
  { __typename?: 'DirOrganization' }
  & Pick<DirOrganization, 'id' | 'name' | 'ogrn' | 'isLegalEntity' | 'fullName' | 'shortName' | 'registrationDate' | 'inn' | 'kpp' | 'legalAddress' | 'postcode' | 'headFullName' | 'headPosition'>
);

export type OrganizationTypeSportsFrFragment = (
  { __typename?: 'DirOrganization' }
  & Pick<DirOrganization, 'id'>
  & { dirSports?: Maybe<Array<(
    { __typename?: 'DirSport' }
    & { value: DirSport['id'], label: DirSport['fullName'] }
  )>> }
);

export type OrganizationDepartmentAffiliationFrFragment = (
  { __typename?: 'DirOrganization' }
  & Pick<DirOrganization, 'id'>
  & { clsDepartmentalAffiliations?: Maybe<Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )>> }
);

export type OrganizationsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirOrganizationWhereInput>;
}>;


export type OrganizationsQuery = (
  { __typename?: 'Query' }
  & { dirOrganizations: Array<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type Organizations2ndQueryVariables = Exact<{
  name: Scalars['String'];
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirOrganizationWhereInput>;
}>;


export type Organizations2ndQuery = (
  { __typename?: 'Query' }
  & { dirOrganizationsSimilar: Array<(
    { __typename?: 'SimilarOrganization' }
    & { organization: (
      { __typename?: 'DirOrganization' }
      & OrganizationFrFragment
    ) }
  )> }
);

export type OrganizationsCountQueryVariables = Exact<{
  filter?: Maybe<DirOrganizationWhereInput>;
}>;


export type OrganizationsCountQuery = (
  { __typename?: 'Query' }
  & { count: Query['dirOrganizationsCount'] }
);

export type OrganizationByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type OrganizationByIdQuery = (
  { __typename?: 'Query' }
  & { dirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationFrFragment
  )> }
);

export type OrganizationByOgrnQueryVariables = Exact<{
  filter?: Maybe<DirOrganizationWhereInput>;
}>;


export type OrganizationByOgrnQuery = (
  { __typename?: 'Query' }
  & { dirOrganizations: Array<(
    { __typename?: 'DirOrganization' }
    & { value: DirOrganization['id'], label: DirOrganization['name'] }
  )> }
);

export type OrganizationLegalInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type OrganizationLegalInfoQuery = (
  { __typename?: 'Query' }
  & { dirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationLegalInfoFrFragment
  )> }
);

export type OrganizationTypeSportsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type OrganizationTypeSportsQuery = (
  { __typename?: 'Query' }
  & { dirOrganizationSports: Array<(
    { __typename?: 'DirOrganizationSport' }
    & Pick<DirOrganizationSport, 'id' | 'trainingStartDate'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & { label: DirSport['fullName'], value: DirSport['id'] }
    ), disciplineGroups?: Maybe<Array<(
      { __typename?: 'DirSportDisciplineGroup' }
      & { label: DirSportDisciplineGroup['name'], value: DirSportDisciplineGroup['id'] }
    )>>, archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type OrganizationDepartmentAffiliationQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type OrganizationDepartmentAffiliationQuery = (
  { __typename?: 'Query' }
  & { dirOrganization?: Maybe<(
    { __typename?: 'DirOrganization' }
    & OrganizationDepartmentAffiliationFrFragment
  )> }
);

export type FindOrganizationsQueryVariables = Exact<{
  search: Scalars['String'];
  filter?: Maybe<DirOrganizationWhereInput>;
}>;


export type FindOrganizationsQuery = (
  { __typename?: 'Query' }
  & { data: Array<(
    { __typename?: 'SimilarOrganization' }
    & { organization: (
      { __typename?: 'DirOrganization' }
      & { value: DirOrganization['id'], label: DirOrganization['name'] }
    ) }
  )> }
);

export type SuggestOrganizationsQueryVariables = Exact<{
  ogrn: Scalars['String'];
}>;


export type SuggestOrganizationsQuery = (
  { __typename?: 'Query' }
  & { suggestOrganizations: Array<(
    { __typename?: 'OrganizationSearchResultType' }
    & Pick<OrganizationSearchResultType, 'fullName' | 'shortName' | 'registrationDate' | 'inn' | 'kpp' | 'legalAddress' | 'postcode' | 'headPosition' | 'headFullName'>
  )> }
);

export type TrainersInOrganizationQueryVariables = Exact<{
  organizationId: Scalars['UUID'];
  sportId: Scalars['UUID'];
  bannedTrainers?: Maybe<Array<Scalars['UUID']>>;
}>;


export type TrainersInOrganizationQuery = (
  { __typename?: 'Query' }
  & { dirTrainers: Array<(
    { __typename?: 'DirTrainer' }
    & Pick<DirTrainer, 'id'>
    & { dirPerson: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id' | 'firstname' | 'patronymic' | 'lastname' | 'birthday'>
    ) }
  )> }
);

export type OrganizationAddressesQueryVariables = Exact<{
  filter?: Maybe<DirOrganizationAddressWhereInput>;
}>;


export type OrganizationAddressesQuery = (
  { __typename?: 'Query' }
  & { dirOrganizationAddresses: Array<(
    { __typename?: 'DirOrganizationAddress' }
    & Pick<DirOrganizationAddress, 'id' | 'place' | 'street' | 'house' | 'number' | 'comment'>
    & { type: (
      { __typename?: 'AddressType' }
      & { value: AddressType['id'], label: AddressType['name'] }
    ) }
  )> }
);

export type OrganizationsTrainersBySportQueryVariables = Exact<{
  sportID?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
}>;


export type OrganizationsTrainersBySportQuery = (
  { __typename?: 'Query' }
  & { dirTrainers: Array<(
    { __typename?: 'DirTrainer' }
    & Pick<DirTrainer, 'id'>
    & { dirPerson: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic'>
    ), sports?: Maybe<Array<(
      { __typename?: 'DirTrainerSport' }
      & Pick<DirTrainerSport, 'id'>
      & { dirSport: (
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id'>
        & { clsTrainingStages?: Maybe<Array<(
          { __typename?: 'ClassifierValue' }
          & Pick<ClassifierValue, 'id' | 'fullName'>
        )>> }
      ), organizations?: Maybe<Array<(
        { __typename?: 'DirTrainerSportOrganization' }
        & Pick<DirTrainerSportOrganization, 'id' | 'employmentDate' | 'dismissalDate'>
        & { clsPosition: (
          { __typename?: 'ClassifierValue' }
          & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
        ), dirOrganization: (
          { __typename?: 'DirOrganization' }
          & { value: DirOrganization['id'], label: DirOrganization['name'] }
        ) }
      )>> }
    )>> }
  )> }
);

export type OrganizationsAthletesBySportQueryVariables = Exact<{
  sportID?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  filter?: Maybe<DirAthleteSportOrganizationWhereInput>;
}>;


export type OrganizationsAthletesBySportQuery = (
  { __typename?: 'Query' }
  & { dirAthletes: Array<(
    { __typename?: 'DirAthlete' }
    & Pick<DirAthlete, 'id'>
    & { dirPerson: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'firstname' | 'lastname' | 'patronymic'>
    ), sports?: Maybe<Array<(
      { __typename?: 'DirAthleteSport' }
      & Pick<DirAthleteSport, 'id'>
      & { dirSport: (
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id'>
      ), organizations?: Maybe<Array<(
        { __typename?: 'DirAthleteSportOrganization' }
        & Pick<DirAthleteSportOrganization, 'id' | 'trainingStart' | 'exclusionDate' | 'isSelfTraining'>
        & { dirTrainer?: Maybe<(
          { __typename?: 'DirTrainer' }
          & Pick<DirTrainer, 'id'>
          & { dirPerson: (
            { __typename?: 'DirPerson' }
            & Pick<DirPerson, 'id' | 'firstname' | 'patronymic' | 'lastname' | 'birthday'>
            & { trainerProfile?: Maybe<(
              { __typename?: 'DirTrainer' }
              & Pick<DirTrainer, 'id'>
            )> }
          ) }
        )>, clsTrainingStage: (
          { __typename?: 'ClassifierValue' }
          & Pick<ClassifierValue, 'id' | 'shortName' | 'fullName'>
        ) }
      )>> }
    )>> }
  )> }
);

export type RemovePersonCitizenshipMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;


export type RemovePersonCitizenshipMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & PersonFrFragment
  )> }
);

export type CreatePersonCitizenshipMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;


export type CreatePersonCitizenshipMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & PersonFrFragment
  )> }
);

export type UpdatePersonMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  regionID?: Maybe<DirRegionUpdateOneInput>;
  countryID?: Maybe<DirCountryUpdateOneInput>;
  foreignCityID?: Maybe<DirForeignCityUpdateOneInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  residenceCity?: Maybe<Scalars['String']>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
}>;


export type UpdatePersonMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & PersonFrFragment
  )> }
);

export type UpdatePersonSnilsMutationVariables = Exact<{
  id: Scalars['UUID'];
  snils: Scalars['String'];
}>;


export type UpdatePersonSnilsMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id'>
  )> }
);

export type UpdatePersonAdditionalRegionsMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput;
}>;


export type UpdatePersonAdditionalRegionsMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id'>
  )> }
);

export type CreatePersonMutationVariables = Exact<{
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  regionID?: Maybe<DirRegionCreateOneInput>;
  countryID?: Maybe<DirCountryCreateOneInput>;
  foreignCityID?: Maybe<DirForeignCityCreateOneInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  residenceCity?: Maybe<Scalars['String']>;
}>;


export type CreatePersonMutation = (
  { __typename?: 'Mutation' }
  & { createDirPerson: (
    { __typename?: 'DirPerson' }
    & PersonFrFragment
  ) }
);

export type UpdatePersonMartialStatusMutationVariables = Exact<{
  id: Scalars['UUID'];
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
}>;


export type UpdatePersonMartialStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & PersonFrFragment
  )> }
);

export type RemovePersonPhoneMutationVariables = Exact<{
  id: Scalars['UUID'];
  date?: Maybe<Scalars['DateTime']>;
}>;


export type RemovePersonPhoneMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPersonPhone?: Maybe<(
    { __typename?: 'DirPersonPhone' }
    & Pick<DirPersonPhone, 'id' | 'deletedAt'>
  )> }
);

export type AddPersonPhoneMutationVariables = Exact<{
  idPerson: Scalars['UUID'];
  countryCode: Scalars['String'];
  number: Scalars['String'];
  operatorCode: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: Scalars['UUID'];
}>;


export type AddPersonPhoneMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & PersonFrFragment
  )> }
);

export type UpdatePersonPhoneMutationVariables = Exact<{
  idPerson: Scalars['UUID'];
  id: Scalars['UUID'];
  countryCode: Scalars['String'];
  number: Scalars['String'];
  operatorCode: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: Scalars['UUID'];
}>;


export type UpdatePersonPhoneMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & PersonFrFragment
  )> }
);

export type PersonArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type PersonArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type PersonUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PersonUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type OpenMergeTaskMutationVariables = Exact<{
  primaryId: Scalars['UUID'];
  duplicateId: Scalars['UUID'];
  comment?: Maybe<Scalars['String']>;
}>;


export type OpenMergeTaskMutation = (
  { __typename?: 'Mutation' }
  & { openMergePersonTask: (
    { __typename?: 'DirPersonMergeTask' }
    & Pick<DirPersonMergeTask, 'id'>
  ) }
);

export type CloseMergeTaskMutationVariables = Exact<{
  taskId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
}>;


export type CloseMergeTaskMutation = (
  { __typename?: 'Mutation' }
  & { closeMergePersonTask: (
    { __typename?: 'DirPersonMergeTask' }
    & Pick<DirPersonMergeTask, 'id'>
  ) }
);

export type CancelMergeTaskMutationVariables = Exact<{
  taskId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
}>;


export type CancelMergeTaskMutation = (
  { __typename?: 'Mutation' }
  & { cancelMergePersonTask: (
    { __typename?: 'DirPersonMergeTask' }
    & Pick<DirPersonMergeTask, 'id'>
  ) }
);

export type DeleteMergeTaskMutationVariables = Exact<{
  taskId: Scalars['UUID'];
}>;


export type DeleteMergeTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteDirPersonMergeTask?: Maybe<(
    { __typename?: 'DirPersonMergeTask' }
    & Pick<DirPersonMergeTask, 'id'>
  )> }
);

export type CreatePersonAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
  place: Scalars['String'];
  street: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  house: Scalars['String'];
  type: Scalars['Int'];
}>;


export type CreatePersonAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id'>
  )> }
);

export type UpdatePersonAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
  place: Scalars['String'];
  street: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  house: Scalars['String'];
  type: Scalars['Int'];
  addressId: Scalars['UUID'];
}>;


export type UpdatePersonAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id'>
  )> }
);

export type DeletePersonAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
  addressId: Scalars['UUID'];
}>;


export type DeletePersonAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id'>
  )> }
);

export type UpdatePersonDocMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirPersonUpdateInput;
}>;


export type UpdatePersonDocMutation = (
  { __typename?: 'Mutation' }
  & { updateDirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id'>
  )> }
);

export type CountryDirValueFragment = (
  { __typename?: 'DirCountry' }
  & { value: DirCountry['id'], label: DirCountry['fullName'] }
);

export type RegionDirValueFragment = (
  { __typename?: 'DirRegion' }
  & { value: DirRegion['id'], label: DirRegion['fullName'] }
);

export type ForeignCityDirValueFragment = (
  { __typename?: 'DirForeignCity' }
  & { value: DirForeignCity['id'], label: DirForeignCity['fullName'] }
);

export type CitizenshipDirValueFragment = (
  { __typename?: 'DirCountry' }
  & { value: DirCountry['id'], label: DirCountry['fullName'] }
);

export type PersonsCountQueryVariables = Exact<{
  filter?: Maybe<DirPersonWhereInput>;
}>;


export type PersonsCountQuery = (
  { __typename?: 'Query' }
  & { count: Query['dirPersonsCount'] }
);

export type PhoneFragment = (
  { __typename?: 'DirPersonPhone' }
  & Pick<DirPersonPhone, 'id' | 'number' | 'deletedAt' | 'countryCode' | 'operatorCode' | 'additionalNumber'>
  & { clsContactType: (
    { __typename?: 'ClassifierValue' }
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  ) }
);

export type PersonFrFragment = (
  { __typename?: 'DirPerson' }
  & Pick<DirPerson, 'id' | 'registryNumber' | 'firstname' | 'lastname' | 'isMale' | 'snils' | 'residenceCity' | 'previousLastname' | 'patronymic' | 'email' | 'birthday' | 'isRussia'>
  & { clsMaritalStatus?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  )>, primary?: Maybe<(
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id' | 'mergedAt' | 'registryNumber' | 'firstname' | 'lastname' | 'patronymic'>
  )>, duplicates?: Maybe<Array<(
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id' | 'mergedAt' | 'registryNumber' | 'firstname' | 'lastname' | 'patronymic'>
  )>>, confirmStatus: (
    { __typename?: 'DirPersonConfirmStatus' }
    & { value: DirPersonConfirmStatus['id'], label: DirPersonConfirmStatus['name'] }
  ), dirCountry?: Maybe<(
    { __typename?: 'DirCountry' }
    & CountryDirValueFragment
  )>, dirForeignCity?: Maybe<(
    { __typename?: 'DirForeignCity' }
    & ForeignCityDirValueFragment
  )>, dirRegion?: Maybe<(
    { __typename?: 'DirRegion' }
    & RegionDirValueFragment
  )>, archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )>, dirCitizenships?: Maybe<Array<(
    { __typename?: 'DirCountry' }
    & Pick<DirCountry, 'id'>
    & CitizenshipDirValueFragment
  )>>, athleteProfile?: Maybe<(
    { __typename?: 'DirAthlete' }
    & Pick<DirAthlete, 'id'>
  )>, trainerProfile?: Maybe<(
    { __typename?: 'DirTrainer' }
    & Pick<DirTrainer, 'id'>
  )>, refereeProfile?: Maybe<(
    { __typename?: 'DirReferee' }
    & Pick<DirReferee, 'id'>
  )>, phones?: Maybe<Array<(
    { __typename?: 'DirPersonPhone' }
    & PhoneFragment
  )>>, additionalRegions?: Maybe<Array<(
    { __typename?: 'DirPersonAdditionalRegion' }
    & Pick<DirPersonAdditionalRegion, 'id'>
    & { dirRegion?: Maybe<(
      { __typename?: 'DirRegion' }
      & { value: DirRegion['id'], label: DirRegion['fullName'] }
    )>, dirForeignCity?: Maybe<(
      { __typename?: 'DirForeignCity' }
      & { value: DirForeignCity['id'], label: DirForeignCity['fullName'] }
    )>, dirCountry?: Maybe<(
      { __typename?: 'DirCountry' }
      & { value: DirCountry['id'], label: DirCountry['fullName'] }
    )> }
  )>> }
);

export type SimilarPersonFragment = (
  { __typename?: 'DirPerson' }
  & Pick<DirPerson, 'id' | 'registryNumber' | 'firstname' | 'patronymic' | 'lastname' | 'birthday' | 'isMale' | 'createdAt' | 'updatedAt'>
  & { primary?: Maybe<(
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id'>
  )>, dirRegion?: Maybe<(
    { __typename?: 'DirRegion' }
    & Pick<DirRegion, 'id' | 'fullName'>
  )>, dirForeignCity?: Maybe<(
    { __typename?: 'DirForeignCity' }
    & Pick<DirForeignCity, 'id' | 'fullName'>
  )>, athleteProfile?: Maybe<(
    { __typename?: 'DirAthlete' }
    & Pick<DirAthlete, 'id'>
    & { sports?: Maybe<Array<(
      { __typename?: 'DirAthleteSport' }
      & Pick<DirAthleteSport, 'id'>
      & { dirSport: (
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id' | 'fullName'>
      ), ranksAndAwards?: Maybe<Array<(
        { __typename?: 'DirAthleteSportRankAndAward' }
        & Pick<DirAthleteSportRankAndAward, 'id' | 'validUntil'>
        & { clsRankAndAward: (
          { __typename?: 'ClassifierValue' }
          & Pick<ClassifierValue, 'id' | 'fullName' | 'order'>
        ) }
      )>> }
    )>> }
  )>, trainerProfile?: Maybe<(
    { __typename?: 'DirTrainer' }
    & Pick<DirTrainer, 'id'>
    & { sports?: Maybe<Array<(
      { __typename?: 'DirTrainerSport' }
      & Pick<DirTrainerSport, 'id'>
      & { dirSport: (
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id' | 'fullName'>
      ) }
    )>> }
  )>, refereeProfile?: Maybe<(
    { __typename?: 'DirReferee' }
    & Pick<DirReferee, 'id'>
    & { sports?: Maybe<Array<(
      { __typename?: 'DirRefereeSport' }
      & Pick<DirRefereeSport, 'id'>
      & { dirSport: (
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id' | 'fullName'>
      ) }
    )>> }
  )> }
);

export type PersonsLookupFragment = (
  { __typename?: 'DirPerson' }
  & Pick<DirPerson, 'id' | 'firstname' | 'patronymic' | 'lastname'>
);

export type MergeTaskFragment = (
  { __typename?: 'DirPersonMergeTask' }
  & Pick<DirPersonMergeTask, 'id' | 'createdAt' | 'updatedAt' | 'comment'>
  & { primary: (
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id' | 'registryNumber' | 'firstname' | 'lastname' | 'patronymic' | 'birthday' | 'isMale' | 'createdAt' | 'updatedAt'>
    & { athleteProfile?: Maybe<(
      { __typename?: 'DirAthlete' }
      & Pick<DirAthlete, 'id'>
    )>, trainerProfile?: Maybe<(
      { __typename?: 'DirTrainer' }
      & Pick<DirTrainer, 'id'>
    )>, refereeProfile?: Maybe<(
      { __typename?: 'DirReferee' }
      & Pick<DirReferee, 'id'>
    )>, dirRegion?: Maybe<(
      { __typename?: 'DirRegion' }
      & Pick<DirRegion, 'id' | 'fullName'>
    )>, dirForeignCity?: Maybe<(
      { __typename?: 'DirForeignCity' }
      & Pick<DirForeignCity, 'id' | 'fullName'>
    )> }
  ), duplicate: (
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id' | 'registryNumber' | 'firstname' | 'lastname' | 'patronymic' | 'birthday' | 'isMale' | 'createdAt' | 'updatedAt'>
    & { athleteProfile?: Maybe<(
      { __typename?: 'DirAthlete' }
      & Pick<DirAthlete, 'id'>
    )>, trainerProfile?: Maybe<(
      { __typename?: 'DirTrainer' }
      & Pick<DirTrainer, 'id'>
    )>, refereeProfile?: Maybe<(
      { __typename?: 'DirReferee' }
      & Pick<DirReferee, 'id'>
    )>, dirRegion?: Maybe<(
      { __typename?: 'DirRegion' }
      & Pick<DirRegion, 'id' | 'fullName'>
    )>, dirForeignCity?: Maybe<(
      { __typename?: 'DirForeignCity' }
      & Pick<DirForeignCity, 'id' | 'fullName'>
    )> }
  ), status: (
    { __typename?: 'TaskStatus' }
    & Pick<TaskStatus, 'id' | 'description'>
  ) }
);

export type PersonMergeTasksFragment = (
  { __typename?: 'DirPerson' }
  & { mergeTasksAsPrimary?: Maybe<Array<(
    { __typename?: 'DirPersonMergeTask' }
    & MergeTaskFragment
  )>>, mergeTasksAsDuplicate?: Maybe<Array<(
    { __typename?: 'DirPersonMergeTask' }
    & MergeTaskFragment
  )>> }
);

export type FindSimiliarPersonQueryVariables = Exact<{
  search: Scalars['String'];
  filter?: Maybe<DirPersonWhereInput>;
  thershold?: Maybe<Scalars['Float']>;
}>;


export type FindSimiliarPersonQuery = (
  { __typename?: 'Query' }
  & { dirPersonsSimilar: Array<(
    { __typename?: 'SimilarPerson' }
    & Pick<SimilarPerson, 'similarity'>
    & { person: (
      { __typename?: 'DirPerson' }
      & SimilarPersonFragment
    ) }
  )> }
);

export type FindRefereeByEventClsPersonQueryVariables = Exact<{
  idEvent: Scalars['UUID'];
  clsId: Scalars['UUID'];
}>;


export type FindRefereeByEventClsPersonQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
    & { referees?: Maybe<Array<(
      { __typename?: 'DirSportingEventReferee' }
      & { clsRefereePosition: (
        { __typename?: 'ClassifierValue' }
        & Pick<ClassifierValue, 'id'>
      ) }
    )>> }
  )> }
);

export type FindRefereeByEventPersonQueryVariables = Exact<{
  idEvent: Scalars['UUID'];
  clsId: Scalars['UUID'];
}>;


export type FindRefereeByEventPersonQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
    & { referees?: Maybe<Array<(
      { __typename?: 'DirSportingEventReferee' }
      & { clsRefereePosition: (
        { __typename?: 'ClassifierValue' }
        & Pick<ClassifierValue, 'id'>
      ) }
    )>> }
  )> }
);

export type FindSimiliarPersonAthletesQueryVariables = Exact<{
  search: Scalars['String'];
  filter?: Maybe<DirPersonWhereInput>;
  s_filter?: Maybe<DirPersonWhereInput>;
}>;


export type FindSimiliarPersonAthletesQuery = (
  { __typename?: 'Query' }
  & { _in: Array<(
    { __typename?: 'SimilarPerson' }
    & Pick<SimilarPerson, 'similarity'>
    & { person: (
      { __typename?: 'DirPerson' }
      & SimilarPersonFragment
    ) }
  )>, _out: Array<(
    { __typename?: 'SimilarPerson' }
    & Pick<SimilarPerson, 'similarity'>
    & { person: (
      { __typename?: 'DirPerson' }
      & SimilarPersonFragment
    ) }
  )> }
);

export type CheckExistEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckExistEmailQuery = (
  { __typename?: 'Query' }
  & { dirPersons: Array<(
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id' | 'email'>
  )> }
);

export type PersonsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirPersonWhereInput>;
}>;


export type PersonsQuery = (
  { __typename?: 'Query' }
  & { dirPersons: Array<(
    { __typename?: 'DirPerson' }
    & PersonFrFragment
  )> }
);

export type PersonByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PersonByIdQuery = (
  { __typename?: 'Query' }
  & { dirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & PersonFrFragment
  )> }
);

export type MergeTasksQueryVariables = Exact<{
  filter?: Maybe<DirPersonMergeTaskWhereInput>;
}>;


export type MergeTasksQuery = (
  { __typename?: 'Query' }
  & { dirPersonMergeTasks: Array<(
    { __typename?: 'DirPersonMergeTask' }
    & MergeTaskFragment
  )> }
);

export type TaskStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type TaskStatusesQuery = (
  { __typename?: 'Query' }
  & { taskStatuses: Array<(
    { __typename?: 'TaskStatus' }
    & Pick<TaskStatus, 'name'>
    & { value: TaskStatus['id'], label: TaskStatus['description'] }
  )> }
);

export type MergeTasksCountQueryVariables = Exact<{
  filter?: Maybe<DirPersonMergeTaskWhereInput>;
}>;


export type MergeTasksCountQuery = (
  { __typename?: 'Query' }
  & { count: Query['dirPersonMergeTasksCount'] }
);

export type PersonAddressesQueryVariables = Exact<{
  filter?: Maybe<DirPersonAddressWhereInput>;
}>;


export type PersonAddressesQuery = (
  { __typename?: 'Query' }
  & { dirPersonAddresses: Array<(
    { __typename?: 'DirPersonAddress' }
    & Pick<DirPersonAddress, 'id' | 'place' | 'street' | 'house' | 'number' | 'comment'>
    & { type: (
      { __typename?: 'AddressType' }
      & { value: AddressType['id'], label: AddressType['name'] }
    ) }
  )> }
);

export type PhonesLookUpQueryVariables = Exact<{
  countryCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
}>;


export type PhonesLookUpQuery = (
  { __typename?: 'Query' }
  & { dirPersonPhones: Array<(
    { __typename?: 'DirPersonPhone' }
    & Pick<DirPersonPhone, 'id'>
  )> }
);

export type PersonsSimilarQueryVariables = Exact<{
  name: Scalars['String'];
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirPersonWhereInput>;
}>;


export type PersonsSimilarQuery = (
  { __typename?: 'Query' }
  & { dirPersonsSimilar: Array<(
    { __typename?: 'SimilarPerson' }
    & Pick<SimilarPerson, 'similarity'>
    & { person: (
      { __typename?: 'DirPerson' }
      & PersonFrFragment
    ) }
  )> }
);

export type PersonSimilarCountQueryVariables = Exact<{
  name: Scalars['String'];
  filter?: Maybe<DirPersonWhereInput>;
}>;


export type PersonSimilarCountQuery = (
  { __typename?: 'Query' }
  & { dirPersonsSimilar: Array<(
    { __typename?: 'SimilarPerson' }
    & Pick<SimilarPerson, 'similarity'>
    & { person: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id'>
    ) }
  )> }
);

export type PersonLookupQueryVariables = Exact<{
  search: Scalars['String'];
  filter?: Maybe<DirPersonWhereInput>;
  threshold: Scalars['Float'];
}>;


export type PersonLookupQuery = (
  { __typename?: 'Query' }
  & { dirPersonsSimilar: Array<(
    { __typename?: 'SimilarPerson' }
    & Pick<SimilarPerson, 'similarity'>
    & { person: (
      { __typename?: 'DirPerson' }
      & PersonsLookupFragment
    ) }
  )> }
);

export type PersonPDocsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PersonPDocsQuery = (
  { __typename?: 'Query' }
  & { dirPerson?: Maybe<(
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id'>
    & { doc1?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path'>
    )>, doc2?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path'>
    )>, doc3?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path'>
    )> }
  )> }
);

export type UpdateRefereeMutationVariables = Exact<{
  id: Scalars['UUID'];
  idPerson: Scalars['UUID'];
}>;


export type UpdateRefereeMutation = (
  { __typename?: 'Mutation' }
  & { updateDirReferee?: Maybe<(
    { __typename?: 'DirReferee' }
    & Pick<DirReferee, 'id'>
  )> }
);

export type CreateRefereeMutationVariables = Exact<{
  idPerson: Scalars['UUID'];
}>;


export type CreateRefereeMutation = (
  { __typename?: 'Mutation' }
  & { createDirReferee: (
    { __typename?: 'DirReferee' }
    & Pick<DirReferee, 'id'>
  ) }
);

export type UpdateRefereeSportMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirRefereeSportUpdateInput;
}>;


export type UpdateRefereeSportMutation = (
  { __typename?: 'Mutation' }
  & { updateDirRefereeSport?: Maybe<(
    { __typename?: 'DirRefereeSport' }
    & Pick<DirRefereeSport, 'id'>
  )> }
);

export type CreateRefereeSportMutationVariables = Exact<{
  id: Scalars['UUID'];
  sportId: Scalars['UUID'];
}>;


export type CreateRefereeSportMutation = (
  { __typename?: 'Mutation' }
  & { updateDirReferee?: Maybe<(
    { __typename?: 'DirReferee' }
    & Pick<DirReferee, 'id'>
  )> }
);

export type RefereeSportArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type RefereeSportArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirRefereeSport?: Maybe<(
    { __typename?: 'DirRefereeSport' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type RefereeSportUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RefereeSportUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirRefereeSport?: Maybe<(
    { __typename?: 'DirRefereeSport' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type UpdateRefereeBankInfoMutationVariables = Exact<{
  id: Scalars['UUID'];
  fileId: Scalars['UUID'];
}>;


export type UpdateRefereeBankInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateDirReferee?: Maybe<(
    { __typename?: 'DirReferee' }
    & Pick<DirReferee, 'id'>
  )> }
);

export type DeleteRefereeBankInfoMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteRefereeBankInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateDirReferee?: Maybe<(
    { __typename?: 'DirReferee' }
    & Pick<DirReferee, 'id'>
  )> }
);

export type RefereeFragment = (
  { __typename?: 'DirReferee' }
  & Pick<DirReferee, 'id'>
  & { dirPerson: (
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id' | 'registryNumber' | 'firstname' | 'lastname' | 'patronymic' | 'birthday' | 'isMale' | 'isRussia' | 'residenceCity'>
    & { dirRegion?: Maybe<(
      { __typename?: 'DirRegion' }
      & Pick<DirRegion, 'id' | 'fullName'>
    )>, dirForeignCity?: Maybe<(
      { __typename?: 'DirForeignCity' }
      & Pick<DirForeignCity, 'id' | 'fullName'>
    )>, dirCountry?: Maybe<(
      { __typename?: 'DirCountry' }
      & Pick<DirCountry, 'id' | 'fullName'>
    )>, bankRequisites?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'path'>
    )> }
  ), sports?: Maybe<Array<(
    { __typename?: 'DirRefereeSport' }
    & Pick<DirRefereeSport, 'id' | 'workExperience'>
    & { categories?: Maybe<Array<(
      { __typename?: 'DirRefereeSportCategory' }
      & Pick<DirRefereeSportCategory, 'id' | 'validUntil' | 'isEndless' | 'assignmentDate' | 'confirmationDate'>
      & { clsRefereeCategory: (
        { __typename?: 'ClassifierValue' }
        & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
      ), dirDocuments?: Maybe<Array<(
        { __typename?: 'DirDocument' }
        & Pick<DirDocument, 'id' | 'number' | 'date' | 'title'>
        & { clsType?: Maybe<(
          { __typename?: 'ClassifierValue' }
          & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
        )>, file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'path'>
        ) }
      )>> }
    )>>, dirSport: (
      { __typename?: 'DirSport' }
      & { value: DirSport['id'], label: DirSport['fullName'] }
    ), archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )>>, archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )> }
);

export type RefereesQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirRefereeWhereInput>;
}>;


export type RefereesQuery = (
  { __typename?: 'Query' }
  & { dirReferees: Array<(
    { __typename?: 'DirReferee' }
    & RefereeFragment
  )> }
);

export type RefereesCountQueryVariables = Exact<{
  filter?: Maybe<DirRefereeWhereInput>;
}>;


export type RefereesCountQuery = (
  { __typename?: 'Query' }
  & { count: Query['dirRefereesCount'] }
);

export type RefereeByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RefereeByIdQuery = (
  { __typename?: 'Query' }
  & { dirReferee?: Maybe<(
    { __typename?: 'DirReferee' }
    & RefereeFragment
  )> }
);

export type RefereeBankInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RefereeBankInfoQuery = (
  { __typename?: 'Query' }
  & { dirReferee?: Maybe<(
    { __typename?: 'DirReferee' }
    & { dirPerson: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id'>
      & { bankRequisites?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'path' | 'name'>
      )> }
    ) }
  )> }
);

export type RefereesSimilarQueryVariables = Exact<{
  name: Scalars['String'];
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirRefereeWhereInput>;
}>;


export type RefereesSimilarQuery = (
  { __typename?: 'Query' }
  & { dirPersonsSimilar: Array<(
    { __typename?: 'SimilarPerson' }
    & Pick<SimilarPerson, 'similarity'>
    & { person: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id'>
      & { refereeProfile?: Maybe<(
        { __typename?: 'DirReferee' }
        & RefereeFragment
      )> }
    ) }
  )> }
);

export type RefereesSimilarCountQueryVariables = Exact<{
  name: Scalars['String'];
  filter?: Maybe<DirRefereeWhereInput>;
}>;


export type RefereesSimilarCountQuery = (
  { __typename?: 'Query' }
  & { dirPersonsSimilar: Array<(
    { __typename?: 'SimilarPerson' }
    & Pick<SimilarPerson, 'similarity'>
    & { person: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id'>
    ) }
  )> }
);

export type RegionArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type RegionArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirRegion?: Maybe<(
    { __typename?: 'DirRegion' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type RegionUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RegionUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirRegion?: Maybe<(
    { __typename?: 'DirRegion' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type RegionUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  code: Scalars['String'];
  roivEmail?: Maybe<Scalars['String']>;
  regionId: DirFederalDistrictUpdateOneRequiredWithoutDirRegionsInput;
}>;


export type RegionUpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirRegion?: Maybe<(
    { __typename?: 'DirRegion' }
    & RegionFragment
  )> }
);

export type RegionCreateMutationVariables = Exact<{
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  code: Scalars['String'];
  regionId: DirFederalDistrictCreateOneWithoutDirRegionsInput;
  roivEmail?: Maybe<Scalars['String']>;
}>;


export type RegionCreateMutation = (
  { __typename?: 'Mutation' }
  & { createDirRegion: (
    { __typename?: 'DirRegion' }
    & RegionFragment
  ) }
);

export type RegionFragment = (
  { __typename?: 'DirRegion' }
  & Pick<DirRegion, 'id' | 'fullName' | 'shortName' | 'code' | 'roivOfficialEmail'>
  & { dirFederalDistrict: (
    { __typename?: 'DirFederalDistrict' }
    & { value: DirFederalDistrict['id'], label: DirFederalDistrict['fullName'] }
  ), archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )> }
);

export type RegionByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RegionByIdQuery = (
  { __typename?: 'Query' }
  & { dirRegion?: Maybe<(
    { __typename?: 'DirRegion' }
    & RegionFragment
  )> }
);

export type ListOrganizationOfOivQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ListOrganizationOfOivQuery = (
  { __typename?: 'Query' }
  & { dirOrganizations: Array<(
    { __typename?: 'DirOrganization' }
    & { value: DirOrganization['id'], label: DirOrganization['name'] }
  )> }
);

export type ListSportsOfBaseRegionsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ListSportsOfBaseRegionsQuery = (
  { __typename?: 'Query' }
  & { dirSports: Array<(
    { __typename?: 'DirSport' }
    & { value: DirSport['id'], label: DirSport['fullName'] }
    & { baseRegions?: Maybe<Array<(
      { __typename?: 'DirSportBaseRegion' }
      & Pick<DirSportBaseRegion, 'id' | 'startDate' | 'endDate'>
      & { dirRegion: (
        { __typename?: 'DirRegion' }
        & Pick<DirRegion, 'id'>
      ) }
    )>> }
  )> }
);

export type RegionsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirRegionWhereInput>;
}>;


export type RegionsQuery = (
  { __typename?: 'Query' }
  & { dirRegions: Array<(
    { __typename?: 'DirRegion' }
    & RegionFragment
  )> }
);

export type RegionsListQueryVariables = Exact<{
  filter?: Maybe<DirRegionWhereInput>;
}>;


export type RegionsListQuery = (
  { __typename?: 'Query' }
  & { dirRegions: Array<(
    { __typename?: 'DirRegion' }
    & { value: DirRegion['id'], label: DirRegion['fullName'] }
  )> }
);

export type AnalyticsSportingEventsByRegionIdQueryVariables = Exact<{
  filter?: Maybe<DirSportingEventWhereInput>;
}>;


export type AnalyticsSportingEventsByRegionIdQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id' | 'name'>
  )> }
);

export type RefusedRegionsByCalendarQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RefusedRegionsByCalendarQuery = (
  { __typename?: 'Query' }
  & { dirRefusedRegions: Array<(
    { __typename?: 'DirRefusedRegion' }
    & Pick<DirRefusedRegion, 'id' | 'comment'>
    & { dirRegion?: Maybe<(
      { __typename?: 'DirRegion' }
      & { value: DirRegion['id'], label: DirRegion['fullName'] }
    )>, statement?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'path' | 'name'>
    )> }
  )>, dirReturnedRegions: Array<(
    { __typename?: 'DirReturnedRegion' }
    & Pick<DirReturnedRegion, 'id'>
    & { dirRegion?: Maybe<(
      { __typename?: 'DirRegion' }
      & { value: DirRegion['id'], label: DirRegion['fullName'] }
    )>, statement?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'path' | 'name'>
    )> }
  )> }
);

export type DisciplineFragment = (
  { __typename?: 'DirSportDiscipline' }
  & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
  & { typeSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
  )> }
);

export type DisciplinesQueryVariables = Exact<{
  filter?: Maybe<DirSportDisciplineWhereInput>;
}>;


export type DisciplinesQuery = (
  { __typename?: 'Query' }
  & { dirSportDisciplines: Array<(
    { __typename?: 'DirSportDiscipline' }
    & DisciplineFragment
  )> }
);

export type GroupDisciplineFragment = (
  { __typename?: 'DirSportDisciplineGroup' }
  & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
  & { typeSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
  )> }
);

export type DisciplineGroupsQueryVariables = Exact<{
  filter?: Maybe<DirSportDisciplineGroupWhereInput>;
}>;


export type DisciplineGroupsQuery = (
  { __typename?: 'Query' }
  & { dirSportDisciplineGroups: Array<(
    { __typename?: 'DirSportDisciplineGroup' }
    & GroupDisciplineFragment
  )> }
);

export type EventsStatusFragment = (
  { __typename?: 'DirSportingEventStatus' }
  & { value: DirSportingEventStatus['id'], label: DirSportingEventStatus['name'] }
);

export type EventsStatusesQueryVariables = Exact<{
  filter?: Maybe<DirSportingEventStatusWhereInput>;
}>;


export type EventsStatusesQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventStatuses: Array<(
    { __typename?: 'DirSportingEventStatus' }
    & EventsStatusFragment
  )> }
);

export type EventsStatusesExtendedQueryVariables = Exact<{
  filter?: Maybe<DirSportingEventExtendedStatusValueWhereInput>;
}>;


export type EventsStatusesExtendedQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventExtendedStatusValues: Array<(
    { __typename?: 'DirSportingEventExtendedStatusValue' }
    & { value: DirSportingEventExtendedStatusValue['id'], label: DirSportingEventExtendedStatusValue['name'] }
  )> }
);

export type RefereeStatusesExtendedQueryVariables = Exact<{
  filter?: Maybe<DirSportingEventRefereeStatusValueWhereInput>;
}>;


export type RefereeStatusesExtendedQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventRefereeStatusValues: Array<(
    { __typename?: 'DirSportingEventRefereeStatusValue' }
    & { value: DirSportingEventRefereeStatusValue['id'], label: DirSportingEventRefereeStatusValue['name'] }
  )> }
);

export type TeamStatusFragment = (
  { __typename?: 'DirNationalTeamStatus' }
  & Pick<DirNationalTeamStatus, 'isDeprecated'>
  & { value: DirNationalTeamStatus['id'], label: DirNationalTeamStatus['name'] }
);

export type TeamStatusesQueryVariables = Exact<{
  filter?: Maybe<DirNationalTeamStatusWhereInput>;
}>;


export type TeamStatusesQuery = (
  { __typename?: 'Query' }
  & { dirNationalTeamStatuses: Array<(
    { __typename?: 'DirNationalTeamStatus' }
    & TeamStatusFragment
  )> }
);

export type DirAthletesSimilarQueryVariables = Exact<{
  search: Scalars['String'];
  sportId?: Maybe<Scalars['UUID']>;
  bannedAthletes?: Maybe<Array<Scalars['UUID']>>;
  minBday?: Maybe<Scalars['DateTime']>;
  maxBday?: Maybe<Scalars['DateTime']>;
}>;


export type DirAthletesSimilarQuery = (
  { __typename?: 'Query' }
  & { dirPersonsSimilar: Array<(
    { __typename?: 'SimilarPerson' }
    & { person: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'registryNumber' | 'firstname' | 'patronymic' | 'lastname' | 'birthday'>
      & { athleteProfile?: Maybe<(
        { __typename?: 'DirAthlete' }
        & Pick<DirAthlete, 'id'>
      )> }
    ) }
  )> }
);

export type DirCalendarsSimilarQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type DirCalendarsSimilarQuery = (
  { __typename?: 'Query' }
  & { dirCalendars: Array<(
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id' | 'fullName' | 'shortName'>
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & Pick<Archive, 'id'>
    )> }
  )> }
);

export type SimilarSportingEventsQueryVariables = Exact<{
  search: Scalars['String'];
  filter?: Maybe<DirSportingEventWhereInput>;
}>;


export type SimilarSportingEventsQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventsSimilar: Array<(
    { __typename?: 'SimilarSportingEvent' }
    & Pick<SimilarSportingEvent, 'similarity'>
    & { sportingEvent: (
      { __typename?: 'DirSportingEvent' }
      & Pick<DirSportingEvent, 'registryNumber'>
      & { value: DirSportingEvent['id'], label: DirSportingEvent['name'] }
    ) }
  )> }
);

export type DirTrainerSimilarQueryVariables = Exact<{
  search: Scalars['String'];
  bannedTrainers?: Maybe<Array<Scalars['UUID']>>;
  sportId?: Maybe<Array<Scalars['UUID']>>;
}>;


export type DirTrainerSimilarQuery = (
  { __typename?: 'Query' }
  & { dirPersonsSimilar: Array<(
    { __typename?: 'SimilarPerson' }
    & { person: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'registryNumber' | 'firstname' | 'patronymic' | 'lastname' | 'birthday'>
      & { trainerProfile?: Maybe<(
        { __typename?: 'DirTrainer' }
        & Pick<DirTrainer, 'id'>
      )> }
    ) }
  )> }
);

export type SportingEventAgeGroupsRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
  idCls: Scalars['UUID'];
}>;


export type SportingEventAgeGroupsRemoveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & AgeGroupEkpFragment
  )> }
);

export type SportingEventAgeGroupsUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idGroup: Scalars['UUID'];
  idCls: Scalars['UUID'];
  minAge: Scalars['Int'];
  maxAge?: Maybe<Scalars['Int']>;
}>;


export type SportingEventAgeGroupsUpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & AgeGroupEkpFragment
  )> }
);

export type SportingEventAgeGroupsCreateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idCls: Scalars['UUID'];
  minAge: Scalars['Int'];
  maxAge?: Maybe<Scalars['Int']>;
}>;


export type SportingEventAgeGroupsCreateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & AgeGroupEkpFragment
  )> }
);

export type AgeGroupFrekpFragment = (
  { __typename?: 'DirSportingEventAgeGroup' }
  & Pick<DirSportingEventAgeGroup, 'id' | 'minAge' | 'maxAge'>
  & { clsAgeGroup?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber' | 'shortName'>
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  )> }
);

export type AgeGroupEkpFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id'>
  & { ageGroups?: Maybe<Array<(
    { __typename?: 'DirSportingEventAgeGroup' }
    & AgeGroupFrekpFragment
  )>> }
);

export type SportingEventAgeGroupsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventAgeGroupsQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'isAgeRestricted' | 'ageGroup'>
    & AgeGroupEkpFragment
  )> }
);

export type SportAgeGroupsQueryVariables = Exact<{
  id?: Maybe<Array<Scalars['UUID']>>;
}>;


export type SportAgeGroupsQuery = (
  { __typename?: 'Query' }
  & { dirSports: Array<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & { ageGroups?: Maybe<Array<(
      { __typename?: 'DirSportAgeGroup' }
      & Pick<DirSportAgeGroup, 'id' | 'minAge' | 'maxAge'>
      & { clsAgeGroup: (
        { __typename?: 'ClassifierValue' }
        & Pick<ClassifierValue, 'registryNumber' | 'shortName'>
        & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
      ) }
    )>> }
  )> }
);

export type SportingEventAnalyticsChildsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventAnalyticsChildsQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id' | 'name'>
    & { statuses?: Maybe<Array<(
      { __typename?: 'DirSportingEventExtendedStatus' }
      & Pick<DirSportingEventExtendedStatus, 'id' | 'isActive'>
      & { value: (
        { __typename?: 'DirSportingEventExtendedStatusValue' }
        & Pick<DirSportingEventExtendedStatusValue, 'id' | 'name'>
      ) }
    )>>, archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )>, children?: Maybe<Array<(
      { __typename?: 'DirSportingEvent' }
      & Pick<DirSportingEvent, 'registryNumber' | 'startDate' | 'endDate'>
      & { value: DirSportingEvent['id'], label: DirSportingEvent['name'] }
      & { dirNationalTeams?: Maybe<Array<(
        { __typename?: 'DirNationalTeam' }
        & Pick<DirNationalTeam, 'id'>
        & { dirRegion: (
          { __typename?: 'DirRegion' }
          & Pick<DirRegion, 'id'>
        ) }
      )>>, sports?: Maybe<Array<(
        { __typename?: 'DirSportingEventSport' }
        & Pick<DirSportingEventSport, 'id'>
        & { dirSport: (
          { __typename?: 'DirSport' }
          & { value: DirSport['id'], label: DirSport['fullName'] }
        ), disciplineGroups?: Maybe<Array<(
          { __typename?: 'DirSportDisciplineGroup' }
          & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
        )>> }
      )>> }
    )>> }
  )> }
);

export type CallAthleteNotifyAddMutationVariables = Exact<{
  id: Scalars['UUID'];
  idDocument: Scalars['UUID'];
  number: Scalars['String'];
  date: Scalars['DateTime'];
  title: Scalars['String'];
}>;


export type CallAthleteNotifyAddMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
  )> }
);

export type CallAthleteNotifyAddFromExistMutationVariables = Exact<{
  id: Scalars['UUID'];
  idDocument: Scalars['UUID'];
}>;


export type CallAthleteNotifyAddFromExistMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
  )> }
);

export type CallAthleteNotifyDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
  idCallNotify: Scalars['UUID'];
}>;


export type CallAthleteNotifyDeleteMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
  )> }
);

export type CallAthleteNotifyUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idCallNotify: Scalars['UUID'];
  teams?: Maybe<DirSportingEventAthleteCallNotificationTeamUpdateManyWithoutNotificationInput>;
}>;


export type CallAthleteNotifyUpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
  )> }
);

export type CallAthleteNotifyFragmentFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'name'>
  & { statuses?: Maybe<Array<(
    { __typename?: 'DirSportingEventExtendedStatus' }
    & Pick<DirSportingEventExtendedStatus, 'id' | 'isActive'>
    & { value: (
      { __typename?: 'DirSportingEventExtendedStatusValue' }
      & Pick<DirSportingEventExtendedStatusValue, 'id' | 'name'>
    ) }
  )>>, dirNationalTeams?: Maybe<Array<(
    { __typename?: 'DirNationalTeam' }
    & Pick<DirNationalTeam, 'id'>
    & { dirRegion: (
      { __typename?: 'DirRegion' }
      & { label: DirRegion['fullName'] }
    ) }
  )>>, athleteCallNotifications?: Maybe<Array<(
    { __typename?: 'DirSportingEventAthleteCallNotification' }
    & Pick<DirSportingEventAthleteCallNotification, 'id'>
    & { document: (
      { __typename?: 'DirDocument' }
      & Pick<DirDocument, 'createdAt' | 'title' | 'date' | 'number'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'name' | 'path'>
      ) }
    ), teams?: Maybe<Array<(
      { __typename?: 'DirSportingEventAthleteCallNotificationTeam' }
      & Pick<DirSportingEventAthleteCallNotificationTeam, 'id' | 'isNotified' | 'isNotificationRequired'>
      & { ditNationalTeam: (
        { __typename?: 'DirNationalTeam' }
        & Pick<DirNationalTeam, 'id'>
        & { dirRegion: (
          { __typename?: 'DirRegion' }
          & { label: DirRegion['fullName'] }
        ) }
      ) }
    )>> }
  )>>, archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )> }
);

export type SportingEventCallAthleteNotifyQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventCallAthleteNotifyQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & CallAthleteNotifyFragmentFragment
  )> }
);

export type CompetitionsResultsFragmentFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'name' | 'venue' | 'object'>
  & { dirCalendar: (
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id' | 'fullName'>
  ), clsEventStages?: Maybe<Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'id' | 'fullName'>
  )>>, sports?: Maybe<Array<(
    { __typename?: 'DirSportingEventSport' }
    & Pick<DirSportingEventSport, 'id'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & Pick<DirSport, 'id' | 'fullName'>
    ) }
  )>>, statuses?: Maybe<Array<(
    { __typename?: 'DirSportingEventExtendedStatus' }
    & Pick<DirSportingEventExtendedStatus, 'id' | 'isActive'>
    & { value: (
      { __typename?: 'DirSportingEventExtendedStatusValue' }
      & Pick<DirSportingEventExtendedStatusValue, 'id' | 'name'>
    ) }
  )>>, archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )>, programTypes?: Maybe<Array<(
    { __typename?: 'DirSportingEventProgramType' }
    & Pick<DirSportingEventProgramType, 'id' | 'minAge' | 'maxAge' | 'start'>
    & { clsSportCategory?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'id' | 'fullName'>
    )>, resultDocuments?: Maybe<Array<(
      { __typename?: 'DirDocument' }
      & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'>
      & { clsType?: Maybe<(
        { __typename?: 'ClassifierValue' }
        & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
      )>, file: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'path'>
      ) }
    )>>, clsAgeGroups?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'shortName'>
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>>, discipline: (
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
    ) }
  )>>, dirNationalTeams?: Maybe<Array<(
    { __typename?: 'DirNationalTeam' }
    & Pick<DirNationalTeam, 'id' | 'email' | 'athletesCall' | 'manTeamQuota' | 'womanTeamQuota' | 'supportTeamQuota' | 'score' | 'point'>
    & { emailRecipients?: Maybe<Array<(
      { __typename?: 'DirNationalTeamEmailRecipient' }
      & { value: DirNationalTeamEmailRecipient['id'], label: DirNationalTeamEmailRecipient['email'] }
    )>>, dirRegion: (
      { __typename?: 'DirRegion' }
      & Pick<DirRegion, 'shortName'>
      & { value: DirRegion['id'], label: DirRegion['fullName'] }
    ), university?: Maybe<(
      { __typename?: 'DirOrganization' }
      & Pick<DirOrganization, 'id' | 'name'>
    )>, statuses?: Maybe<Array<(
      { __typename?: 'DirNationalTeamExtendedStatus' }
      & Pick<DirNationalTeamExtendedStatus, 'isActive'>
      & { value: (
        { __typename?: 'DirNationalTeamStatus' }
        & Pick<DirNationalTeamStatus, 'id' | 'name'>
      ) }
    )>>, status: (
      { __typename?: 'DirNationalTeamStatus' }
      & { value: DirNationalTeamStatus['id'], label: DirNationalTeamStatus['name'] }
    ), athleteGroups?: Maybe<Array<(
      { __typename?: 'DirNationalTeamAthleteGroup' }
      & Pick<DirNationalTeamAthleteGroup, 'id'>
      & { athletes?: Maybe<Array<(
        { __typename?: 'DirNationalTeamAthlete' }
        & Pick<DirNationalTeamAthlete, 'id'>
        & { dirAthlete: (
          { __typename?: 'DirAthlete' }
          & Pick<DirAthlete, 'id'>
          & { dirPerson: (
            { __typename?: 'DirPerson' }
            & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'registryNumber' | 'birthday' | 'isMale'>
          ), competetionResult?: Maybe<Array<(
            { __typename?: 'DirAthleteCompetitionResult' }
            & Pick<DirAthleteCompetitionResult, 'id' | 'point' | 'pointTo' | 'result' | 'score' | 'completedRank'>
            & { sportingEventProgramType: (
              { __typename?: 'DirSportingEventProgramType' }
              & Pick<DirSportingEventProgramType, 'id'>
              & { clsAgeGroups?: Maybe<Array<(
                { __typename?: 'ClassifierValue' }
                & { value: ClassifierValue['id'] }
              )>> }
            ) }
          )>> }
        ) }
      )>> }
    )>> }
  )>>, referees?: Maybe<Array<(
    { __typename?: 'DirSportingEventReferee' }
    & Pick<DirSportingEventReferee, 'id'>
    & { dirReferee: (
      { __typename?: 'DirReferee' }
      & Pick<DirReferee, 'id'>
      & { dirPerson: (
        { __typename?: 'DirPerson' }
        & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic'>
      ) }
    ), clsRefereePosition: (
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'id'>
    ) }
  )>> }
);

export type AthleteCompetitionResultFragment = (
  { __typename?: 'DirAthleteCompetitionResult' }
  & Pick<DirAthleteCompetitionResult, 'id' | 'point' | 'pointTo' | 'result' | 'score' | 'completedRank'>
  & { dirAthlete: (
    { __typename?: 'DirAthlete' }
    & Pick<DirAthlete, 'id'>
    & { dirPerson: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id' | 'registryNumber' | 'firstname' | 'lastname' | 'patronymic' | 'birthday'>
    ) }
  ), sportingEventProgramType: (
    { __typename?: 'DirSportingEventProgramType' }
    & Pick<DirSportingEventProgramType, 'id'>
  ), noResultReason?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'id' | 'fullName'>
  )> }
);

export type AthleteCompetitionsResultsArrayQueryVariables = Exact<{
  filter?: Maybe<DirAthleteCompetitionResultWhereInput>;
}>;


export type AthleteCompetitionsResultsArrayQuery = (
  { __typename?: 'Query' }
  & { dirAthleteCompetitionResults: Array<(
    { __typename?: 'DirAthleteCompetitionResult' }
    & AthleteCompetitionResultFragment
  )> }
);

export type AthleteFullListQueryVariables = Exact<{
  id: Scalars['UUID'];
  programTypeId: Scalars['UUID'];
}>;


export type AthleteFullListQuery = (
  { __typename?: 'Query' }
  & { dirAthleteCompetitionResults: Array<(
    { __typename?: 'DirAthleteCompetitionResult' }
    & Pick<DirAthleteCompetitionResult, 'id'>
    & { dirAthlete: (
      { __typename?: 'DirAthlete' }
      & Pick<DirAthlete, 'id'>
    ) }
  )> }
);

export type CompetitionsUidFullListQueryVariables = Exact<{
  id: Scalars['UUID'];
  programTypeId: Scalars['UUID'];
}>;


export type CompetitionsUidFullListQuery = (
  { __typename?: 'Query' }
  & { dirAthleteCompetitionResults: Array<(
    { __typename?: 'DirAthleteCompetitionResult' }
    & Pick<DirAthleteCompetitionResult, 'id'>
  )> }
);

export type SportingEventCompetitionsResultsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventCompetitionsResultsQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & CompetitionsResultsFragmentFragment
  )> }
);

export type DocumentsFragmentEventFragment = (
  { __typename?: 'DirSportingEvent' }
  & { sports?: Maybe<Array<(
    { __typename?: 'DirSportingEventSport' }
    & Pick<DirSportingEventSport, 'id'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & { value: DirSport['id'], label: DirSport['fullName'] }
    ) }
  )>>, competitionReglaments?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date' | 'createdAt'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>>, competitionProtocols?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date' | 'createdAt'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>>, commissionAdmissionDecisions?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date' | 'createdAt'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>>, competitionFinalProtocols?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date' | 'createdAt'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>>, mainJuryReports?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date' | 'createdAt'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>> }
);

export type SportingEventDocumentsListQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventDocumentsListQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id' | 'name'>
    & { statuses?: Maybe<Array<(
      { __typename?: 'DirSportingEventExtendedStatus' }
      & Pick<DirSportingEventExtendedStatus, 'id' | 'isActive'>
      & { value: (
        { __typename?: 'DirSportingEventExtendedStatusValue' }
        & Pick<DirSportingEventExtendedStatusValue, 'id' | 'name'>
      ) }
    )>> }
    & DocumentsFragmentEventFragment
  )> }
);

export type EventProtocolDocFragmentFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'name'>
  & { statuses?: Maybe<Array<(
    { __typename?: 'DirSportingEventExtendedStatus' }
    & Pick<DirSportingEventExtendedStatus, 'id' | 'isActive'>
    & { value: (
      { __typename?: 'DirSportingEventExtendedStatusValue' }
      & Pick<DirSportingEventExtendedStatusValue, 'id' | 'name'>
    ) }
  )>>, archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )>, credentialsCommitteeProtocolDocuments?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>> }
);

export type SportingEventProtocolDocQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventProtocolDocQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & EventProtocolDocFragmentFragment
  )> }
);

export type MainInfoEventDirRegionUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idRegion: Scalars['UUID'];
}>;


export type MainInfoEventDirRegionUpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & { dirRegion?: Maybe<(
      { __typename?: 'DirRegion' }
      & { value: DirRegion['id'], label: DirRegion['fullName'] }
    )> }
  )> }
);

export type MainInfoEventDirRegionFederalDistinctAddMutationVariables = Exact<{
  id: Scalars['UUID'];
  idFederalRegion: Scalars['UUID'];
}>;


export type MainInfoEventDirRegionFederalDistinctAddMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & { dirFederalDistricts?: Maybe<Array<(
      { __typename?: 'DirFederalDistrict' }
      & { value: DirFederalDistrict['id'], label: DirFederalDistrict['fullName'] }
    )>> }
  )> }
);

export type MainInfoEventDirRegionFederalDistinctRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
  idFederalRegion: Scalars['UUID'];
}>;


export type MainInfoEventDirRegionFederalDistinctRemoveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & { dirFederalDistricts?: Maybe<Array<(
      { __typename?: 'DirFederalDistrict' }
      & { value: DirFederalDistrict['id'], label: DirFederalDistrict['fullName'] }
    )>> }
  )> }
);

export type ClsSportingEventStageQueryVariables = Exact<{ [key: string]: never; }>;


export type ClsSportingEventStageQuery = (
  { __typename?: 'Query' }
  & { classifierValues: Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'fullName' | 'registryNumber'>
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )> }
);

export type MainInfoFragmentFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'ekp' | 'name' | 'venue' | 'object' | 'startDate' | 'endDate' | 'daysBeforeStartToAddProtocols'>
  & { ageGroupMale?: Maybe<(
    { __typename?: 'DirSportAgeGroup' }
    & { clsAgeGroup: (
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'registryNumber'>
    ) }
  )>, ageGroupFemale?: Maybe<(
    { __typename?: 'DirSportAgeGroup' }
    & { clsAgeGroup: (
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'registryNumber'>
    ) }
  )>, sportQualificationMaleNotLower?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber'>
  )>, clsMinRang?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber'>
  )>, sportQualificationFemaleNotLower?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber'>
  )>, clsSportingQualificationNotGreater?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber'>
  )>, dirCalendar: (
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'registryNumber' | 'shortName'>
    & { value: DirCalendar['id'], label: DirCalendar['fullName'] }
  ), dirRegion?: Maybe<(
    { __typename?: 'DirRegion' }
    & { value: DirRegion['id'], label: DirRegion['fullName'] }
  )>, dirFederalDistricts?: Maybe<Array<(
    { __typename?: 'DirFederalDistrict' }
    & { value: DirFederalDistrict['id'], label: DirFederalDistrict['fullName'] }
  )>>, sports?: Maybe<Array<(
    { __typename?: 'DirSportingEventSport' }
    & { dirSport: (
      { __typename?: 'DirSport' }
      & { ageGroups?: Maybe<Array<(
        { __typename?: 'DirSportAgeGroup' }
        & Pick<DirSportAgeGroup, 'minAge' | 'maxAge'>
        & { clsAgeGroup: (
          { __typename?: 'ClassifierValue' }
          & Pick<ClassifierValue, 'fullName'>
        ) }
      )>> }
    ) }
  )>>, clsEventStages?: Maybe<Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber'>
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  )>>, clsEventCategories?: Maybe<Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber' | 'fullName'>
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  )>>, status: (
    { __typename?: 'DirSportingEventStatus' }
    & { label: DirSportingEventStatus['name'], value: DirSportingEventStatus['id'] }
  ), statuses?: Maybe<Array<(
    { __typename?: 'DirSportingEventExtendedStatus' }
    & Pick<DirSportingEventExtendedStatus, 'id' | 'createdAt' | 'comment' | 'isActive'>
    & { value: (
      { __typename?: 'DirSportingEventExtendedStatusValue' }
      & Pick<DirSportingEventExtendedStatusValue, 'id' | 'name'>
    ), createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email'>
      & { regular?: Maybe<(
        { __typename?: 'RegularUser' }
        & Pick<RegularUser, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'phone' | 'address'>
        & { dirOrganization?: Maybe<(
          { __typename?: 'DirOrganization' }
          & Pick<DirOrganization, 'id' | 'name' | 'legalAddress'>
        )> }
      )> }
    )>, file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'path'>
    )> }
  )>>, archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )> }
);

export type SportingEventStartInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventStartInfoQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id' | 'name' | 'daysBeforeStartToAddProtocols'>
    & { referees?: Maybe<Array<(
      { __typename?: 'DirSportingEventReferee' }
      & Pick<DirSportingEventReferee, 'id'>
      & { dirReferee: (
        { __typename?: 'DirReferee' }
        & Pick<DirReferee, 'id'>
        & { dirPerson: (
          { __typename?: 'DirPerson' }
          & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic'>
        ) }
      ) }
    )>>, dirCalendar: (
      { __typename?: 'DirCalendar' }
      & Pick<DirCalendar, 'id'>
      & { clsCalendarCategory?: Maybe<(
        { __typename?: 'ClassifierValue' }
        & { value: ClassifierValue['registryNumber'], label: ClassifierValue['fullName'] }
      )> }
    ), statuses?: Maybe<Array<(
      { __typename?: 'DirSportingEventExtendedStatus' }
      & Pick<DirSportingEventExtendedStatus, 'id' | 'createdAt' | 'comment'>
      & { updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'email'>
        & { regular?: Maybe<(
          { __typename?: 'RegularUser' }
          & Pick<RegularUser, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'phone' | 'address'>
          & { dirOrganization?: Maybe<(
            { __typename?: 'DirOrganization' }
            & Pick<DirOrganization, 'id' | 'name' | 'legalAddress'>
          )> }
        )> }
      )>, value: (
        { __typename?: 'DirSportingEventExtendedStatusValue' }
        & { value: DirSportingEventExtendedStatusValue['id'], label: DirSportingEventExtendedStatusValue['name'] }
      ), file?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'name' | 'path'>
      )> }
    )>>, archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type SportingEventMainInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventMainInfoQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & MainInfoFragmentFragment
  )> }
);

export type UploadRefereeAgreementsDocMutationVariables = Exact<{
  id: Scalars['UUID'];
  idFile: Scalars['UUID'];
}>;


export type UploadRefereeAgreementsDocMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & { refereePersonalDataAgreements?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path' | 'id'>
    )>> }
  )> }
);

export type DeleteRefereeAgreementsDocMutationVariables = Exact<{
  id: Scalars['UUID'];
  idFile: Scalars['UUID'];
}>;


export type DeleteRefereeAgreementsDocMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & { refereePersonalDataAgreements?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path' | 'id'>
    )>> }
  )> }
);

export type GetListRefereeAgreementsDocsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetListRefereeAgreementsDocsQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & { refereePersonalDataAgreements?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'name' | 'path' | 'id'>
    )>> }
  )> }
);

export type RefereesEventFragmentFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'membersQuota' | 'name' | 'refereesQuota' | 'nonresidentRefereesQuota'>
  & { statuses?: Maybe<Array<(
    { __typename?: 'DirSportingEventExtendedStatus' }
    & Pick<DirSportingEventExtendedStatus, 'id' | 'isActive'>
    & { value: (
      { __typename?: 'DirSportingEventExtendedStatusValue' }
      & Pick<DirSportingEventExtendedStatusValue, 'id' | 'name'>
    ) }
  )>>, refereeStatuses?: Maybe<Array<(
    { __typename?: 'DirSportingEventRefereeStatus' }
    & Pick<DirSportingEventRefereeStatus, 'id' | 'createdAt' | 'comment'>
    & { updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email'>
      & { regular?: Maybe<(
        { __typename?: 'RegularUser' }
        & Pick<RegularUser, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'phone' | 'address'>
        & { dirOrganization?: Maybe<(
          { __typename?: 'DirOrganization' }
          & Pick<DirOrganization, 'id' | 'name' | 'legalAddress'>
        )> }
      )> }
    )>, value: (
      { __typename?: 'DirSportingEventRefereeStatusValue' }
      & Pick<DirSportingEventRefereeStatusValue, 'id' | 'name'>
    ), file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'path'>
    )> }
  )>>, referees?: Maybe<Array<(
    { __typename?: 'DirSportingEventReferee' }
    & Pick<DirSportingEventReferee, 'id' | 'isNonresident'>
    & { clsRefereePosition: (
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'shortName'>
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    ), dirReferee: (
      { __typename?: 'DirReferee' }
      & Pick<DirReferee, 'id'>
      & { sports?: Maybe<Array<(
        { __typename?: 'DirRefereeSport' }
        & Pick<DirRefereeSport, 'id'>
        & { dirSport: (
          { __typename?: 'DirSport' }
          & { value: DirSport['id'] }
        ), categories?: Maybe<Array<(
          { __typename?: 'DirRefereeSportCategory' }
          & Pick<DirRefereeSportCategory, 'id' | 'validUntil' | 'isEndless'>
          & { clsRefereeCategory: (
            { __typename?: 'ClassifierValue' }
            & Pick<ClassifierValue, 'id' | 'fullName'>
          ), dirDocuments?: Maybe<Array<(
            { __typename?: 'DirDocument' }
            & Pick<DirDocument, 'id' | 'title'>
            & { clsType?: Maybe<(
              { __typename?: 'ClassifierValue' }
              & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
            )>, file: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'name' | 'path'>
            ) }
          )>> }
        )>> }
      )>>, dirPerson: (
        { __typename?: 'DirPerson' }
        & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'email' | 'residenceCity' | 'isRussia'>
        & { addresses?: Maybe<Array<(
          { __typename?: 'DirPersonAddress' }
          & Pick<DirPersonAddress, 'place'>
        )>>, phones?: Maybe<Array<(
          { __typename?: 'DirPersonPhone' }
          & PhoneFragment
        )>>, dirRegion?: Maybe<(
          { __typename?: 'DirRegion' }
          & Pick<DirRegion, 'id' | 'fullName'>
        )>, dirCountry?: Maybe<(
          { __typename?: 'DirCountry' }
          & Pick<DirCountry, 'id' | 'fullName'>
        )>, dirForeignCity?: Maybe<(
          { __typename?: 'DirForeignCity' }
          & Pick<DirForeignCity, 'id' | 'fullName'>
        )> }
      ) }
    ) }
  )>>, sports?: Maybe<Array<(
    { __typename?: 'DirSportingEventSport' }
    & Pick<DirSportingEventSport, 'id'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & { value: DirSport['id'], label: DirSport['fullName'] }
    ) }
  )>> }
);

export type RefereeListFragment = (
  { __typename?: 'DirSportingEventReferee' }
  & Pick<DirSportingEventReferee, 'id' | 'isNonresident'>
  & { clsRefereePosition: (
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'shortName'>
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  ), dirReferee: (
    { __typename?: 'DirReferee' }
    & Pick<DirReferee, 'id'>
    & { sports?: Maybe<Array<(
      { __typename?: 'DirRefereeSport' }
      & Pick<DirRefereeSport, 'id'>
      & { dirSport: (
        { __typename?: 'DirSport' }
        & { value: DirSport['id'] }
      ), categories?: Maybe<Array<(
        { __typename?: 'DirRefereeSportCategory' }
        & Pick<DirRefereeSportCategory, 'id' | 'validUntil' | 'isEndless'>
        & { clsRefereeCategory: (
          { __typename?: 'ClassifierValue' }
          & Pick<ClassifierValue, 'id' | 'fullName'>
        ), dirDocuments?: Maybe<Array<(
          { __typename?: 'DirDocument' }
          & Pick<DirDocument, 'id' | 'title'>
          & { clsType?: Maybe<(
            { __typename?: 'ClassifierValue' }
            & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
          )>, file: (
            { __typename?: 'File' }
            & Pick<File, 'id' | 'name' | 'path'>
          ) }
        )>> }
      )>> }
    )>>, dirPerson: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'email' | 'residenceCity' | 'isRussia'>
      & { addresses?: Maybe<Array<(
        { __typename?: 'DirPersonAddress' }
        & Pick<DirPersonAddress, 'place'>
      )>>, phones?: Maybe<Array<(
        { __typename?: 'DirPersonPhone' }
        & PhoneFragment
      )>>, dirRegion?: Maybe<(
        { __typename?: 'DirRegion' }
        & Pick<DirRegion, 'id' | 'fullName'>
      )>, dirCountry?: Maybe<(
        { __typename?: 'DirCountry' }
        & Pick<DirCountry, 'id' | 'fullName'>
      )>, dirForeignCity?: Maybe<(
        { __typename?: 'DirForeignCity' }
        & Pick<DirForeignCity, 'id' | 'fullName'>
      )> }
    ) }
  ) }
);

export type SportingEventRefereesListFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'venue' | 'ekp' | 'name' | 'startDate' | 'endDate'>
  & { sports?: Maybe<Array<(
    { __typename?: 'DirSportingEventSport' }
    & Pick<DirSportingEventSport, 'id'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & Pick<DirSport, 'id'>
    ) }
  )>>, referees?: Maybe<Array<(
    { __typename?: 'DirSportingEventReferee' }
    & Pick<DirSportingEventReferee, 'id' | 'isNonresident' | 'isPrimary'>
    & { clsRefereePosition: (
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'id' | 'registryNumber' | 'fullName' | 'shortName'>
    ), dirReferee: (
      { __typename?: 'DirReferee' }
      & Pick<DirReferee, 'id'>
      & { sports?: Maybe<Array<(
        { __typename?: 'DirRefereeSport' }
        & Pick<DirRefereeSport, 'id'>
        & { dirSport: (
          { __typename?: 'DirSport' }
          & Pick<DirSport, 'id'>
        ), categories?: Maybe<Array<(
          { __typename?: 'DirRefereeSportCategory' }
          & Pick<DirRefereeSportCategory, 'id' | 'validUntil' | 'isEndless'>
          & { clsRefereeCategory: (
            { __typename?: 'ClassifierValue' }
            & Pick<ClassifierValue, 'id' | 'fullName' | 'shortName'>
          ) }
        )>> }
      )>>, dirPerson: (
        { __typename?: 'DirPerson' }
        & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'residenceCity' | 'email'>
        & { phones?: Maybe<Array<(
          { __typename?: 'DirPersonPhone' }
          & PhoneFragment
        )>>, dirRegion?: Maybe<(
          { __typename?: 'DirRegion' }
          & Pick<DirRegion, 'id' | 'fullName'>
        )> }
      ) }
    ) }
  )>> }
);

export type SportingEventRefereesQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventRefereesQuery = (
  { __typename?: 'Query' }
  & { SportingEventReferees?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & RefereesEventFragmentFragment
  )> }
);

export type SportingEventRefereesByRegistryNumberQueryVariables = Exact<{
  id: Scalars['UUID'];
  registryNumber: Scalars['Int'];
}>;


export type SportingEventRefereesByRegistryNumberQuery = (
  { __typename?: 'Query' }
  & { SportingEventReferees: Array<(
    { __typename?: 'DirSportingEventReferee' }
    & RefereeListFragment
  )> }
);

export type SportingEventRefereesListQueryVariables = Exact<{
  filter: DirSportingEventWhereUniqueInput;
}>;


export type SportingEventRefereesListQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & SportingEventRefereesListFragment
  )> }
);

export type CreateSportingEventMutationVariables = Exact<{
  data: DirSportingEventCreateInput;
}>;


export type CreateSportingEventMutation = (
  { __typename?: 'Mutation' }
  & { createDirSportingEvent: (
    { __typename?: 'DirSportingEvent' }
    & SportingEventFragment
  ) }
);

export type UpdateSportingEventMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirSportingEventUpdateInput;
}>;


export type UpdateSportingEventMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & SportingEventFragment
  )> }
);

export type SportingEventArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type SportingEventArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type SportingEventUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type CreateSportingEventRequestMutationVariables = Exact<{
  eventId: Scalars['UUID'];
  typeId: Scalars['Int'];
}>;


export type CreateSportingEventRequestMutation = (
  { __typename?: 'Mutation' }
  & { createDirSportingEventRequest: (
    { __typename?: 'DirSportingEventRequest' }
    & Pick<DirSportingEventRequest, 'id'>
  ) }
);

export type CreateSportingEventRequestStatusMutationVariables = Exact<{
  requestId: Scalars['UUID'];
  data?: Maybe<Array<DirSportingEventRequestStatusCreateWithoutRequestInput>>;
}>;


export type CreateSportingEventRequestStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEventRequest?: Maybe<(
    { __typename?: 'DirSportingEventRequest' }
    & Pick<DirSportingEventRequest, 'id'>
  )> }
);

export type CloneSportingEventMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CloneSportingEventMutation = (
  { __typename?: 'Mutation' }
  & { cloneDirSportingEvent: (
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id' | 'name'>
    & { proxy?: Maybe<(
      { __typename?: 'DirSportingEvent' }
      & Pick<DirSportingEvent, 'id' | 'name'>
      & { changes?: Maybe<Array<(
        { __typename?: 'DirSportingEvent' }
        & Pick<DirSportingEvent, 'id' | 'name'>
      )>> }
    )>, changes?: Maybe<Array<(
      { __typename?: 'DirSportingEvent' }
      & Pick<DirSportingEvent, 'id' | 'name'>
    )>> }
  ) }
);

export type MergeSportingEventMutationVariables = Exact<{
  from: Scalars['UUID'];
  to: Scalars['UUID'];
}>;


export type MergeSportingEventMutation = (
  { __typename?: 'Mutation' }
  & { restoreDirSportingEvent: (
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
  ) }
);

export type CreateEventStatusMutationVariables = Exact<{
  id: Scalars['UUID'];
  data?: Maybe<Array<DirSportingEventExtendedStatusCreateWithoutEventInput>>;
}>;


export type CreateEventStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
  )> }
);

export type CreateEventRefereeStatusMutationVariables = Exact<{
  id: Scalars['UUID'];
  data?: Maybe<Array<DirSportingEventRefereeStatusCreateWithoutEventInput>>;
}>;


export type CreateEventRefereeStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
  )> }
);

export type UpdateAgeGroupFemaleEventMutationVariables = Exact<{
  id: Scalars['UUID'];
  data?: Maybe<DirSportAgeGroupUpdateOneInput>;
}>;


export type UpdateAgeGroupFemaleEventMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
  )> }
);

export type UpdateAgeGroupMaleEventMutationVariables = Exact<{
  id: Scalars['UUID'];
  data?: Maybe<DirSportAgeGroupUpdateOneInput>;
}>;


export type UpdateAgeGroupMaleEventMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
  )> }
);

export type DeleteCompetitionResultMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteCompetitionResultMutation = (
  { __typename?: 'Mutation' }
  & { deleteDirAthleteCompetitionResult?: Maybe<(
    { __typename?: 'DirAthleteCompetitionResult' }
    & Pick<DirAthleteCompetitionResult, 'id'>
  )> }
);

export type SportingEventFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'name' | 'properNoun' | 'venue' | 'ekp' | 'object' | 'startDate' | 'endDate' | 'registryNumber' | 'oldRegistryNumber' | 'membersQuota' | 'nonresidentRefereesQuota' | 'refereesQuota' | 'ageGroup' | 'athletesTrainersRefereesSpecialistsCount' | 'minManAge' | 'maxManAge' | 'minManBirthYear' | 'maxManBirthYear' | 'minWomanAge' | 'maxWomanAge' | 'minWomanBirthYear' | 'maxWomanBirthYear' | 'athletesQuotaNotCorrespondingByAge' | 'athletesQuotaNotCorrespondingByQualification' | 'isOrganizerOrParticipant' | 'isAgeRestricted' | 'isSportingQualificationRangeOrSpecificValues' | 'isRussia' | 'isImportant' | 'isOlympicGamesPreparation' | 'federalBudgetFinancing' | 'includingTrainingActivities' | 'comment'>
  & { sportQualificationFemaleNotLower?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'order'>
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )>, sportQualificationMaleNotLower?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'order'>
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )>, ageGroupMale?: Maybe<(
    { __typename?: 'DirSportAgeGroup' }
    & Pick<DirSportAgeGroup, 'id' | 'minAge' | 'maxAge'>
    & { clsAgeGroup: (
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    ) }
  )>, ageGroupFemale?: Maybe<(
    { __typename?: 'DirSportAgeGroup' }
    & Pick<DirSportAgeGroup, 'id' | 'minAge' | 'maxAge'>
    & { clsAgeGroup: (
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    ) }
  )>, dirRegions?: Maybe<Array<(
    { __typename?: 'DirRegion' }
    & { label: DirRegion['fullName'], value: DirRegion['id'] }
  )>>, countries?: Maybe<Array<(
    { __typename?: 'DirSportingEventCountry' }
    & Pick<DirSportingEventCountry, 'id'>
    & { dirCountry: (
      { __typename?: 'DirCountry' }
      & { label: DirCountry['fullName'], value: DirCountry['id'] }
    ), dirForeignCities?: Maybe<Array<(
      { __typename?: 'DirForeignCity' }
      & { label: DirForeignCity['fullName'], value: DirForeignCity['id'] }
      & { country: (
        { __typename?: 'DirCountry' }
        & Pick<DirCountry, 'id'>
      ) }
    )>> }
  )>>, organizers?: Maybe<Array<(
    { __typename?: 'DirOrganization' }
    & Pick<DirOrganization, 'shortName'>
    & { value: DirOrganization['id'], label: DirOrganization['name'] }
  )>>, clsEventCategories?: Maybe<Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber' | 'fullName'>
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  )>>, clsEventStages?: Maybe<Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'registryNumber' | 'fullName'>
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  )>>, clsAvailableSportingQualifications?: Maybe<Array<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'order'>
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  )>>, clsMinRang?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'order'>
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  )>, clsSportingQualificationNotGreater?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & Pick<ClassifierValue, 'order'>
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  )>, quantity?: Maybe<(
    { __typename?: 'DirSportingEventQuantity' }
    & Pick<DirSportingEventQuantity, 'id' | 'participantAthletesTotal' | 'refereesTotal' | 'staffTotal' | 'audienceTotal'>
  )>, cost?: Maybe<(
    { __typename?: 'DirSportingEventCost' }
    & Pick<DirSportingEventCost, 'id' | 'planRUB' | 'planRUBperPerson' | 'currentPlanRUB' | 'currentPlanRUBperPerson' | 'factRUB' | 'factRUBperPerson' | 'planUSD' | 'planUSDperPerson' | 'currentPlanUSD' | 'currentPlanUSDperPerson' | 'factUSD' | 'factUSDperPerson' | 'planDate' | 'currentPlanDate' | 'factDate' | 'year' | 'month'>
    & { clsFinancingTypes?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>> }
  )>, quota?: Maybe<(
    { __typename?: 'DirSportingEventQuota' }
    & Pick<DirSportingEventQuota, 'id' | 'athletesMan' | 'athletesWoman' | 'trainers' | 'referees' | 'administration' | 'medicalStaff' | 'specialists' | 'teams'>
    & { clsTeamComposition?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, clsParticipatingOrgCategories?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>> }
  )>, clsDirectionality?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & { value: ClassifierValue['registryNumber'], label: ClassifierValue['fullName'] }
  )>, clsDivisions?: Maybe<Array<(
    { __typename?: 'ClassifierValue' }
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  )>>, clsSummarizingType?: Maybe<(
    { __typename?: 'ClassifierValue' }
    & { value: ClassifierValue['registryNumber'], label: ClassifierValue['fullName'] }
  )>, parent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'registryNumber' | 'startDate' | 'endDate'>
    & { value: DirSportingEvent['id'], label: DirSportingEvent['name'] }
    & { dirNationalTeams?: Maybe<Array<(
      { __typename?: 'DirNationalTeam' }
      & Pick<DirNationalTeam, 'id'>
      & { dirRegion: (
        { __typename?: 'DirRegion' }
        & Pick<DirRegion, 'id'>
      ) }
    )>>, sports?: Maybe<Array<(
      { __typename?: 'DirSportingEventSport' }
      & Pick<DirSportingEventSport, 'id'>
      & { dirSport: (
        { __typename?: 'DirSport' }
        & { value: DirSport['id'], label: DirSport['fullName'] }
      ), disciplineGroups?: Maybe<Array<(
        { __typename?: 'DirSportDisciplineGroup' }
        & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
      )>> }
    )>> }
  )>, children?: Maybe<Array<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'registryNumber' | 'startDate' | 'endDate'>
    & { value: DirSportingEvent['id'], label: DirSportingEvent['name'] }
    & { dirNationalTeams?: Maybe<Array<(
      { __typename?: 'DirNationalTeam' }
      & Pick<DirNationalTeam, 'id'>
      & { dirRegion: (
        { __typename?: 'DirRegion' }
        & Pick<DirRegion, 'id'>
      ) }
    )>>, sports?: Maybe<Array<(
      { __typename?: 'DirSportingEventSport' }
      & Pick<DirSportingEventSport, 'id'>
      & { dirSport: (
        { __typename?: 'DirSport' }
        & { value: DirSport['id'], label: DirSport['fullName'] }
      ), disciplineGroups?: Maybe<Array<(
        { __typename?: 'DirSportDisciplineGroup' }
        & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
      )>> }
    )>> }
  )>>, archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )>, statuses?: Maybe<Array<(
    { __typename?: 'DirSportingEventExtendedStatus' }
    & Pick<DirSportingEventExtendedStatus, 'createdAt' | 'comment' | 'isActive'>
    & { value: (
      { __typename?: 'DirSportingEventExtendedStatusValue' }
      & Pick<DirSportingEventExtendedStatusValue, 'id' | 'name'>
    ), createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email'>
      & { regular?: Maybe<(
        { __typename?: 'RegularUser' }
        & Pick<RegularUser, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'phone' | 'address'>
        & { dirOrganization?: Maybe<(
          { __typename?: 'DirOrganization' }
          & Pick<DirOrganization, 'id' | 'name' | 'legalAddress'>
        )> }
      )> }
    )>, file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'path'>
    )> }
  )>>, status: (
    { __typename?: 'DirSportingEventStatus' }
    & { value: DirSportingEventStatus['id'], label: DirSportingEventStatus['name'] }
  ), statusHistory?: Maybe<Array<(
    { __typename?: 'DirSportingEventStatusHistory' }
    & Pick<DirSportingEventStatusHistory, 'id' | 'date'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email'>
      & { updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'email'>
        & { regular?: Maybe<(
          { __typename?: 'RegularUser' }
          & Pick<RegularUser, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'phone'>
        )> }
      )> }
    ), status: (
      { __typename?: 'DirSportingEventStatus' }
      & Pick<DirSportingEventStatus, 'name'>
    ) }
  )>>, ageGroups?: Maybe<Array<(
    { __typename?: 'DirSportingEventAgeGroup' }
    & Pick<DirSportingEventAgeGroup, 'id' | 'minAge' | 'maxAge'>
    & { clsAgeGroup?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'shortName'>
      & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
    )> }
  )>>, sports?: Maybe<Array<(
    { __typename?: 'DirSportingEventSport' }
    & Pick<DirSportingEventSport, 'id'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & { value: DirSport['id'], label: DirSport['fullName'] }
      & { ageGroups?: Maybe<Array<(
        { __typename?: 'DirSportAgeGroup' }
        & Pick<DirSportAgeGroup, 'id' | 'minAge' | 'maxAge'>
        & { clsAgeGroup: (
          { __typename?: 'ClassifierValue' }
          & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
        ), participationRequirements?: Maybe<Array<(
          { __typename?: 'DirSportAgeGroupParticipationRequirements' }
          & Pick<DirSportAgeGroupParticipationRequirements, 'id'>
          & { clsCalendarCategory?: Maybe<(
            { __typename?: 'ClassifierValue' }
            & Pick<ClassifierValue, 'fullName' | 'id'>
          )>, clsRanksAndAwards?: Maybe<(
            { __typename?: 'ClassifierValue' }
            & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
          )> }
        )>> }
      )>>, disciplines?: Maybe<Array<(
        { __typename?: 'DirSportDiscipline' }
        & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
        & { typeSport?: Maybe<(
          { __typename?: 'DirSport' }
          & Pick<DirSport, 'id'>
        )> }
      )>> }
    ), disciplines?: Maybe<Array<(
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
      & { typeSport?: Maybe<(
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id'>
      )> }
    )>>, disciplineGroups?: Maybe<Array<(
      { __typename?: 'DirSportDisciplineGroup' }
      & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
      & { typeSport?: Maybe<(
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id'>
      )>, disciplines?: Maybe<Array<(
        { __typename?: 'DirSportDiscipline' }
        & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
      )>> }
    )>> }
  )>>, dirCalendar: (
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'registryNumber' | 'shortName'>
    & { value: DirCalendar['id'], label: DirCalendar['fullName'] }
    & { clsCalendarCategory?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'id' | 'fullName'>
    )> }
  ), competitionCalendar?: Maybe<Array<(
    { __typename?: 'DirSportingEventCompetitionCalendar' }
    & Pick<DirSportingEventCompetitionCalendar, 'id' | 'day' | 'time' | 'members'>
    & { discipline?: Maybe<(
      { __typename?: 'DirSportDiscipline' }
      & Pick<DirSportDiscipline, 'code'>
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
    )> }
  )>>, programTypes?: Maybe<Array<(
    { __typename?: 'DirSportingEventProgramType' }
    & Pick<DirSportingEventProgramType, 'id' | 'minAge' | 'maxAge' | 'start'>
    & { resultsDocument?: Maybe<(
      { __typename?: 'DirDocument' }
      & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'>
      & { clsType?: Maybe<(
        { __typename?: 'ClassifierValue' }
        & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
      )>, file: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'path'>
      ) }
    )>, clsAgeGroups?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'shortName'>
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>>, clsSportCategory?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'shortName'>
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, discipline: (
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
    ) }
  )>>, dirNationalTeams?: Maybe<Array<(
    { __typename?: 'DirNationalTeam' }
    & Pick<DirNationalTeam, 'id' | 'email' | 'athletesCall' | 'manTeamQuota' | 'womanTeamQuota' | 'supportTeamQuota' | 'athleteQuotaByGender' | 'athleteTotalQuota' | 'score' | 'point'>
    & { emailRecipients?: Maybe<Array<(
      { __typename?: 'DirNationalTeamEmailRecipient' }
      & { value: DirNationalTeamEmailRecipient['id'], label: DirNationalTeamEmailRecipient['email'] }
    )>>, dirRegion: (
      { __typename?: 'DirRegion' }
      & Pick<DirRegion, 'shortName'>
      & { value: DirRegion['id'], label: DirRegion['fullName'] }
    ), statuses?: Maybe<Array<(
      { __typename?: 'DirNationalTeamExtendedStatus' }
      & Pick<DirNationalTeamExtendedStatus, 'isActive'>
      & { value: (
        { __typename?: 'DirNationalTeamStatus' }
        & Pick<DirNationalTeamStatus, 'id' | 'name'>
      ) }
    )>>, status: (
      { __typename?: 'DirNationalTeamStatus' }
      & { value: DirNationalTeamStatus['id'], label: DirNationalTeamStatus['name'] }
    ), athleteGroups?: Maybe<Array<(
      { __typename?: 'DirNationalTeamAthleteGroup' }
      & Pick<DirNationalTeamAthleteGroup, 'id'>
      & { athletes?: Maybe<Array<(
        { __typename?: 'DirNationalTeamAthlete' }
        & Pick<DirNationalTeamAthlete, 'id'>
        & { dirAthlete: (
          { __typename?: 'DirAthlete' }
          & Pick<DirAthlete, 'id'>
          & { dirPerson: (
            { __typename?: 'DirPerson' }
            & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'registryNumber' | 'birthday' | 'isMale'>
          ), competetionResult?: Maybe<Array<(
            { __typename?: 'DirAthleteCompetitionResult' }
            & Pick<DirAthleteCompetitionResult, 'id' | 'point' | 'result' | 'completedRank'>
            & { sportingEventProgramType: (
              { __typename?: 'DirSportingEventProgramType' }
              & Pick<DirSportingEventProgramType, 'id'>
              & { clsAgeGroups?: Maybe<Array<(
                { __typename?: 'ClassifierValue' }
                & { value: ClassifierValue['id'] }
              )>> }
            ) }
          )>> }
        ) }
      )>> }
    )>>, specialists?: Maybe<Array<(
      { __typename?: 'DirNationalTeamMember' }
      & Pick<DirNationalTeamMember, 'id'>
    )>>, medics?: Maybe<Array<(
      { __typename?: 'DirNationalTeamMember' }
      & Pick<DirNationalTeamMember, 'id'>
    )>>, trainers?: Maybe<Array<(
      { __typename?: 'DirNationalTeamTrainer' }
      & Pick<DirNationalTeamTrainer, 'id'>
    )>>, delegationHeads?: Maybe<Array<(
      { __typename?: 'DirNationalTeamMember' }
      & Pick<DirNationalTeamMember, 'id'>
    )>>, archive?: Maybe<(
      { __typename?: 'Archive' }
      & Pick<Archive, 'id' | 'comment' | 'reason'>
    )> }
  )>>, competitionReglaments?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>>, finalProtocolDocuments?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>>, competitionProtocols?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>>, commissionAdmissionDecisions?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>>, competitionFinalProtocols?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>>, mainJuryReports?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>>, refereeCollegiumDocuments?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>>, credentialsCommitteeProtocolDocuments?: Maybe<Array<(
    { __typename?: 'DirDocument' }
    & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'>
    & { clsType?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>, file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'path'>
    ) }
  )>>, competitionProtocolFiles?: Maybe<Array<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'name' | 'path'>
  )>>, refereeStatuses?: Maybe<Array<(
    { __typename?: 'DirSportingEventRefereeStatus' }
    & Pick<DirSportingEventRefereeStatus, 'createdAt' | 'comment' | 'isActive'>
    & { value: (
      { __typename?: 'DirSportingEventRefereeStatusValue' }
      & Pick<DirSportingEventRefereeStatusValue, 'id' | 'name'>
    ), createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email'>
      & { regular?: Maybe<(
        { __typename?: 'RegularUser' }
        & Pick<RegularUser, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'phone' | 'address'>
        & { dirOrganization?: Maybe<(
          { __typename?: 'DirOrganization' }
          & Pick<DirOrganization, 'id' | 'name' | 'legalAddress'>
        )> }
      )> }
    )>, file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'path'>
    )> }
  )>>, referees?: Maybe<Array<(
    { __typename?: 'DirSportingEventReferee' }
    & Pick<DirSportingEventReferee, 'id' | 'isPrimary' | 'isNonresident'>
    & { clsRefereePosition: (
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'shortName'>
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    ), dirReferee: (
      { __typename?: 'DirReferee' }
      & Pick<DirReferee, 'id'>
      & { sports?: Maybe<Array<(
        { __typename?: 'DirRefereeSport' }
        & Pick<DirRefereeSport, 'id'>
        & { dirSport: (
          { __typename?: 'DirSport' }
          & { value: DirSport['id'] }
        ), categories?: Maybe<Array<(
          { __typename?: 'DirRefereeSportCategory' }
          & Pick<DirRefereeSportCategory, 'id' | 'validUntil' | 'isEndless'>
          & { clsRefereeCategory: (
            { __typename?: 'ClassifierValue' }
            & Pick<ClassifierValue, 'id' | 'fullName'>
          ), dirDocuments?: Maybe<Array<(
            { __typename?: 'DirDocument' }
            & Pick<DirDocument, 'id' | 'title'>
            & { clsType?: Maybe<(
              { __typename?: 'ClassifierValue' }
              & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
            )>, file: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'name' | 'path'>
            ) }
          )>> }
        )>> }
      )>>, dirPerson: (
        { __typename?: 'DirPerson' }
        & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'email' | 'residenceCity' | 'isRussia'>
        & { addresses?: Maybe<Array<(
          { __typename?: 'DirPersonAddress' }
          & Pick<DirPersonAddress, 'place'>
        )>>, phones?: Maybe<Array<(
          { __typename?: 'DirPersonPhone' }
          & PhoneFragment
        )>>, dirRegion?: Maybe<(
          { __typename?: 'DirRegion' }
          & Pick<DirRegion, 'id' | 'fullName'>
        )>, dirCountry?: Maybe<(
          { __typename?: 'DirCountry' }
          & Pick<DirCountry, 'id' | 'fullName'>
        )>, dirForeignCity?: Maybe<(
          { __typename?: 'DirForeignCity' }
          & Pick<DirForeignCity, 'id' | 'fullName'>
        )> }
      ) }
    ) }
  )>>, requests?: Maybe<Array<(
    { __typename?: 'DirSportingEventRequest' }
    & Pick<DirSportingEventRequest, 'id' | 'createdAt' | 'updatedAt'>
    & { type: (
      { __typename?: 'DirSportingEventRequestType' }
      & Pick<DirSportingEventRequestType, 'id' | 'name'>
    ), statuses?: Maybe<Array<(
      { __typename?: 'DirSportingEventRequestStatus' }
      & Pick<DirSportingEventRequestStatus, 'id' | 'isActive'>
      & { value: (
        { __typename?: 'DirSportingEventRequestStatusValue' }
        & Pick<DirSportingEventRequestStatusValue, 'id' | 'name'>
      ) }
    )>> }
  )>>, changes?: Maybe<Array<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id' | 'name'>
    & { requests?: Maybe<Array<(
      { __typename?: 'DirSportingEventRequest' }
      & Pick<DirSportingEventRequest, 'id' | 'createdAt'>
      & { type: (
        { __typename?: 'DirSportingEventRequestType' }
        & Pick<DirSportingEventRequestType, 'id' | 'name'>
      ), statuses?: Maybe<Array<(
        { __typename?: 'DirSportingEventRequestStatus' }
        & Pick<DirSportingEventRequestStatus, 'id'>
        & { value: (
          { __typename?: 'DirSportingEventRequestStatusValue' }
          & Pick<DirSportingEventRequestStatusValue, 'id' | 'name'>
        ) }
      )>> }
    )>> }
  )>> }
);

export type LightSportingEventFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'registryNumber' | 'oldRegistryNumber' | 'startDate' | 'endDate' | 'ekp' | 'name' | 'venue'>
  & { dirCalendar: (
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id' | 'shortName'>
  ), organizers?: Maybe<Array<(
    { __typename?: 'DirOrganization' }
    & Pick<DirOrganization, 'shortName'>
    & { value: DirOrganization['id'], label: DirOrganization['name'] }
  )>>, status: (
    { __typename?: 'DirSportingEventStatus' }
    & { value: DirSportingEventStatus['id'], label: DirSportingEventStatus['name'] }
  ), statuses?: Maybe<Array<(
    { __typename?: 'DirSportingEventExtendedStatus' }
    & Pick<DirSportingEventExtendedStatus, 'id' | 'isActive'>
    & { value: (
      { __typename?: 'DirSportingEventExtendedStatusValue' }
      & Pick<DirSportingEventExtendedStatusValue, 'id' | 'name'>
    ) }
  )>>, dirNationalTeams?: Maybe<Array<(
    { __typename?: 'DirNationalTeam' }
    & Pick<DirNationalTeam, 'id' | 'manTeamQuota' | 'womanTeamQuota' | 'supportTeamQuota'>
    & { athleteGroups?: Maybe<Array<(
      { __typename?: 'DirNationalTeamAthleteGroup' }
      & Pick<DirNationalTeamAthleteGroup, 'id'>
      & { athletes?: Maybe<Array<(
        { __typename?: 'DirNationalTeamAthlete' }
        & Pick<DirNationalTeamAthlete, 'id'>
      )>> }
    )>>, specialists?: Maybe<Array<(
      { __typename?: 'DirNationalTeamMember' }
      & Pick<DirNationalTeamMember, 'id'>
    )>>, delegationHeads?: Maybe<Array<(
      { __typename?: 'DirNationalTeamMember' }
      & Pick<DirNationalTeamMember, 'id'>
    )>>, medics?: Maybe<Array<(
      { __typename?: 'DirNationalTeamMember' }
      & Pick<DirNationalTeamMember, 'id'>
    )>>, trainers?: Maybe<Array<(
      { __typename?: 'DirNationalTeamTrainer' }
      & Pick<DirNationalTeamTrainer, 'id'>
    )>>, archive?: Maybe<(
      { __typename?: 'Archive' }
      & Pick<Archive, 'id'>
    )> }
  )>>, sports?: Maybe<Array<(
    { __typename?: 'DirSportingEventSport' }
    & Pick<DirSportingEventSport, 'id'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & { value: DirSport['id'], label: DirSport['fullName'] }
    ), disciplines?: Maybe<Array<(
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
    )>>, disciplineGroups?: Maybe<Array<(
      { __typename?: 'DirSportDisciplineGroup' }
      & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
    )>> }
  )>>, requests?: Maybe<Array<(
    { __typename?: 'DirSportingEventRequest' }
    & Pick<DirSportingEventRequest, 'id' | 'createdAt' | 'updatedAt'>
    & { type: (
      { __typename?: 'DirSportingEventRequestType' }
      & Pick<DirSportingEventRequestType, 'id' | 'name'>
    ), statuses?: Maybe<Array<(
      { __typename?: 'DirSportingEventRequestStatus' }
      & Pick<DirSportingEventRequestStatus, 'id' | 'isActive'>
      & { value: (
        { __typename?: 'DirSportingEventRequestStatusValue' }
        & Pick<DirSportingEventRequestStatusValue, 'id' | 'name'>
      ) }
    )>> }
  )>>, changes?: Maybe<Array<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id' | 'name'>
    & { requests?: Maybe<Array<(
      { __typename?: 'DirSportingEventRequest' }
      & Pick<DirSportingEventRequest, 'id' | 'createdAt'>
      & { type: (
        { __typename?: 'DirSportingEventRequestType' }
        & Pick<DirSportingEventRequestType, 'id' | 'name'>
      ), statuses?: Maybe<Array<(
        { __typename?: 'DirSportingEventRequestStatus' }
        & Pick<DirSportingEventRequestStatus, 'id'>
        & { value: (
          { __typename?: 'DirSportingEventRequestStatusValue' }
          & Pick<DirSportingEventRequestStatusValue, 'id' | 'name'>
        ) }
      )>> }
    )>> }
  )>> }
);

export type SportingEventRequestQueryVariables = Exact<{
  filter: DirSportingEventRequestWhereInput;
}>;


export type SportingEventRequestQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventRequests: Array<(
    { __typename?: 'DirSportingEventRequest' }
    & Pick<DirSportingEventRequest, 'id' | 'createdAt' | 'updatedAt' | 'orderNumber'>
    & { type: (
      { __typename?: 'DirSportingEventRequestType' }
      & { value: DirSportingEventRequestType['id'], label: DirSportingEventRequestType['name'] }
    ), event: (
      { __typename?: 'DirSportingEvent' }
      & Pick<DirSportingEvent, 'id' | 'startDate' | 'endDate' | 'venue' | 'isRussia' | 'isOrganizerOrParticipant' | 'isAgeRestricted' | 'registryNumber' | 'name'>
      & { dirRegions?: Maybe<Array<(
        { __typename?: 'DirRegion' }
        & { label: DirRegion['fullName'], value: DirRegion['id'] }
      )>>, countries?: Maybe<Array<(
        { __typename?: 'DirSportingEventCountry' }
        & Pick<DirSportingEventCountry, 'id'>
      )>>, status: (
        { __typename?: 'DirSportingEventStatus' }
        & { label: DirSportingEventStatus['name'], value: DirSportingEventStatus['id'] }
      ), ageGroups?: Maybe<Array<(
        { __typename?: 'DirSportingEventAgeGroup' }
        & Pick<DirSportingEventAgeGroup, 'id' | 'minAge' | 'maxAge'>
        & { clsAgeGroup?: Maybe<(
          { __typename?: 'ClassifierValue' }
          & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
        )> }
      )>>, proxy?: Maybe<(
        { __typename?: 'DirSportingEvent' }
        & Pick<DirSportingEvent, 'id' | 'startDate' | 'endDate' | 'venue' | 'isRussia'>
        & { dirRegions?: Maybe<Array<(
          { __typename?: 'DirRegion' }
          & { label: DirRegion['fullName'], value: DirRegion['id'] }
        )>>, countries?: Maybe<Array<(
          { __typename?: 'DirSportingEventCountry' }
          & Pick<DirSportingEventCountry, 'id'>
        )>> }
      )>, changes?: Maybe<Array<(
        { __typename?: 'DirSportingEvent' }
        & Pick<DirSportingEvent, 'id' | 'name' | 'startDate' | 'endDate' | 'venue' | 'isRussia'>
        & { countries?: Maybe<Array<(
          { __typename?: 'DirSportingEventCountry' }
          & Pick<DirSportingEventCountry, 'id'>
        )>>, dirRegions?: Maybe<Array<(
          { __typename?: 'DirRegion' }
          & { label: DirRegion['fullName'], value: DirRegion['id'] }
        )>>, proxy?: Maybe<(
          { __typename?: 'DirSportingEvent' }
          & Pick<DirSportingEvent, 'id'>
        )>, requests?: Maybe<Array<(
          { __typename?: 'DirSportingEventRequest' }
          & Pick<DirSportingEventRequest, 'id' | 'createdAt' | 'orderNumber'>
          & { type: (
            { __typename?: 'DirSportingEventRequestType' }
            & { value: DirSportingEventRequestType['id'], label: DirSportingEventRequestType['name'] }
          ), statuses?: Maybe<Array<(
            { __typename?: 'DirSportingEventRequestStatus' }
            & Pick<DirSportingEventRequestStatus, 'id' | 'createdAt' | 'comment'>
            & { updatedBy?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'id' | 'name' | 'email'>
              & { regular?: Maybe<(
                { __typename?: 'RegularUser' }
                & Pick<RegularUser, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'phone' | 'address'>
                & { dirOrganization?: Maybe<(
                  { __typename?: 'DirOrganization' }
                  & Pick<DirOrganization, 'id' | 'name' | 'legalAddress'>
                )> }
              )> }
            )>, value: (
              { __typename?: 'DirSportingEventRequestStatusValue' }
              & { value: DirSportingEventRequestStatusValue['id'], label: DirSportingEventRequestStatusValue['name'] }
            ), file?: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'name' | 'path'>
            )> }
          )>> }
        )>> }
      )>> }
    ), statuses?: Maybe<Array<(
      { __typename?: 'DirSportingEventRequestStatus' }
      & Pick<DirSportingEventRequestStatus, 'id' | 'createdAt' | 'comment'>
      & { updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'email'>
        & { regular?: Maybe<(
          { __typename?: 'RegularUser' }
          & Pick<RegularUser, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'phone' | 'address'>
          & { dirOrganization?: Maybe<(
            { __typename?: 'DirOrganization' }
            & Pick<DirOrganization, 'id' | 'name' | 'legalAddress'>
          )> }
        )> }
      )>, value: (
        { __typename?: 'DirSportingEventRequestStatusValue' }
        & { value: DirSportingEventRequestStatusValue['id'], label: DirSportingEventRequestStatusValue['name'] }
      ), file?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'name' | 'path'>
      )> }
    )>> }
  )> }
);

export type SportingEventsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<DirSportingEventWhereInput>;
  orderBy?: Maybe<DirSportingEventOrderByInput>;
}>;


export type SportingEventsQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & LightSportingEventFragment
  )> }
);

export type SportingEventsAthleteDocsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventsAthleteDocsQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & { athleteCallDocuments?: Maybe<Array<(
      { __typename?: 'DirDocument' }
      & Pick<DirDocument, 'title' | 'id' | 'date'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'path'>
      ) }
    )>> }
  )> }
);

export type SportingEvents2ndQueryVariables = Exact<{
  name: Scalars['String'];
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirSportingEventWhereInput>;
}>;


export type SportingEvents2ndQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventsSimilar: Array<(
    { __typename?: 'SimilarSportingEvent' }
    & { sportingEvent: (
      { __typename?: 'DirSportingEvent' }
      & LightSportingEventFragment
    ) }
  )> }
);

export type SportingEventsCountQueryVariables = Exact<{
  filter?: Maybe<DirSportingEventWhereInput>;
}>;


export type SportingEventsCountQuery = (
  { __typename?: 'Query' }
  & { count: Query['dirSportingEventsCount'] }
);

export type SportingEventSportsQueryVariables = Exact<{
  filter?: Maybe<DirSportingEventSportWhereInput>;
}>;


export type SportingEventSportsQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventSports: Array<(
    { __typename?: 'DirSportingEventSport' }
    & Pick<DirSportingEventSport, 'id'>
    & { event: (
      { __typename?: 'DirSportingEvent' }
      & { ageGroupFemale?: Maybe<(
        { __typename?: 'DirSportAgeGroup' }
        & Pick<DirSportAgeGroup, 'id'>
        & { clsAgeGroup: (
          { __typename?: 'ClassifierValue' }
          & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
        ) }
      )>, ageGroupMale?: Maybe<(
        { __typename?: 'DirSportAgeGroup' }
        & Pick<DirSportAgeGroup, 'id'>
        & { clsAgeGroup: (
          { __typename?: 'ClassifierValue' }
          & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
        ) }
      )> }
    ), dirSport: (
      { __typename?: 'DirSport' }
      & { value: DirSport['id'], label: DirSport['fullName'] }
      & { ageGroups?: Maybe<Array<(
        { __typename?: 'DirSportAgeGroup' }
        & Pick<DirSportAgeGroup, 'id' | 'maxAge' | 'minAge'>
        & { clsAgeGroup: (
          { __typename?: 'ClassifierValue' }
          & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
        ) }
      )>> }
    ), disciplines?: Maybe<Array<(
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
    )>>, disciplineGroups?: Maybe<Array<(
      { __typename?: 'DirSportDisciplineGroup' }
      & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
    )>> }
  )> }
);

export type SportingEventSportsDisciplinesGroupQueryVariables = Exact<{
  filter?: Maybe<DirSportingEventSportWhereInput>;
}>;


export type SportingEventSportsDisciplinesGroupQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventSports: Array<(
    { __typename?: 'DirSportingEventSport' }
    & Pick<DirSportingEventSport, 'id'>
    & { disciplineGroups?: Maybe<Array<(
      { __typename?: 'DirSportDisciplineGroup' }
      & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
    )>> }
  )> }
);

export type SportingEventSportsDisciplinesQueryVariables = Exact<{
  filter?: Maybe<DirSportingEventSportWhereInput>;
}>;


export type SportingEventSportsDisciplinesQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventSports: Array<(
    { __typename?: 'DirSportingEventSport' }
    & Pick<DirSportingEventSport, 'id'>
    & { disciplines?: Maybe<Array<(
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
    )>> }
  )> }
);

export type SportingEventQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & { proxy?: Maybe<(
      { __typename?: 'DirSportingEvent' }
      & SportingEventFragment
    )> }
    & SportingEventFragment
  )> }
);

export type SportEventRelativesQueryVariables = Exact<{
  id: Scalars['UUID'];
  parentFilter: DirSportingEventWhereInput;
  childrenFilter: DirSportingEventWhereInput;
  dChildrenFilter?: Maybe<DirSportingEventWhereInput>;
}>;


export type SportEventRelativesQuery = (
  { __typename?: 'Query' }
  & { children: Array<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'registryNumber' | 'startDate' | 'endDate'>
    & { value: DirSportingEvent['id'], label: DirSportingEvent['name'] }
    & { dirNationalTeams?: Maybe<Array<(
      { __typename?: 'DirNationalTeam' }
      & Pick<DirNationalTeam, 'id'>
      & { dirRegion: (
        { __typename?: 'DirRegion' }
        & Pick<DirRegion, 'id'>
      ) }
    )>>, sports?: Maybe<Array<(
      { __typename?: 'DirSportingEventSport' }
      & Pick<DirSportingEventSport, 'id'>
      & { dirSport: (
        { __typename?: 'DirSport' }
        & { value: DirSport['id'], label: DirSport['fullName'] }
      ), disciplineGroups?: Maybe<Array<(
        { __typename?: 'DirSportDisciplineGroup' }
        & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
      )>> }
    )>> }
  )>, dChildren: Array<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'registryNumber' | 'startDate' | 'endDate'>
    & { value: DirSportingEvent['id'], label: DirSportingEvent['name'] }
    & { dirNationalTeams?: Maybe<Array<(
      { __typename?: 'DirNationalTeam' }
      & Pick<DirNationalTeam, 'id'>
      & { dirRegion: (
        { __typename?: 'DirRegion' }
        & Pick<DirRegion, 'id'>
      ) }
    )>>, sports?: Maybe<Array<(
      { __typename?: 'DirSportingEventSport' }
      & Pick<DirSportingEventSport, 'id'>
      & { dirSport: (
        { __typename?: 'DirSport' }
        & { value: DirSport['id'], label: DirSport['fullName'] }
      ), disciplineGroups?: Maybe<Array<(
        { __typename?: 'DirSportDisciplineGroup' }
        & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
      )>> }
    )>> }
  )>, eventParent: Array<(
    { __typename?: 'DirSportingEvent' }
    & { parent?: Maybe<(
      { __typename?: 'DirSportingEvent' }
      & Pick<DirSportingEvent, 'registryNumber' | 'startDate' | 'endDate'>
      & { value: DirSportingEvent['id'], label: DirSportingEvent['name'] }
      & { dirNationalTeams?: Maybe<Array<(
        { __typename?: 'DirNationalTeam' }
        & Pick<DirNationalTeam, 'id'>
        & { dirRegion: (
          { __typename?: 'DirRegion' }
          & Pick<DirRegion, 'id'>
        ) }
      )>>, sports?: Maybe<Array<(
        { __typename?: 'DirSportingEventSport' }
        & Pick<DirSportingEventSport, 'id'>
        & { dirSport: (
          { __typename?: 'DirSport' }
          & { value: DirSport['id'], label: DirSport['fullName'] }
        ), disciplineGroups?: Maybe<Array<(
          { __typename?: 'DirSportDisciplineGroup' }
          & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
        )>> }
      )>> }
    )> }
  )> }
);

export type SportingEventProgramTypesQueryVariables = Exact<{
  filter: DirSportingEventProgramTypeWhereInput;
}>;


export type SportingEventProgramTypesQuery = (
  { __typename?: 'Query' }
  & { dirSportingEventProgramTypes: Array<(
    { __typename?: 'DirSportingEventProgramType' }
    & Pick<DirSportingEventProgramType, 'id' | 'minAge' | 'maxAge' | 'start'>
    & { resultDocuments?: Maybe<Array<(
      { __typename?: 'DirDocument' }
      & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'>
      & { clsType?: Maybe<(
        { __typename?: 'ClassifierValue' }
        & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
      )>, file: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'path'>
      ) }
    )>>, clsAgeGroups?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'shortName'>
      & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
    )>>, discipline: (
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
    ) }
  )> }
);

export type AnalyticsTotalCountUsersQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AnalyticsTotalCountUsersQuery = (
  { __typename?: 'Query' }
  & { aggregateParticipantsNumberByEvent: (
    { __typename?: 'SportingEventParticipantsNumberByEvent' }
    & Pick<SportingEventParticipantsNumberByEvent, 'name' | 'sports' | 'disciplineGroups' | 'subjectsCount' | 'athletesCount' | 'athletesCountByMan' | 'athletesCountByWoman'>
  ) }
);

export type AnalyticsTotalCountUsersParentQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AnalyticsTotalCountUsersParentQuery = (
  { __typename?: 'Query' }
  & { aggregateParticipantsNumberByParentEvent: (
    { __typename?: 'SportingEventParticipantsNumberByParentEvent' }
    & Pick<SportingEventParticipantsNumberByParentEvent, 'name'>
    & { children: Array<(
      { __typename?: 'SportingEventParticipantsNumberByEvent' }
      & Pick<SportingEventParticipantsNumberByEvent, 'name' | 'sports' | 'disciplineGroups' | 'subjectsCount' | 'athletesCount' | 'athletesCountByMan' | 'athletesCountByWoman'>
    )> }
  ) }
);

export type SportingEventChildQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventChildQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & { children?: Maybe<Array<(
      { __typename?: 'DirSportingEvent' }
      & Pick<DirSportingEvent, 'id'>
    )>> }
  )> }
);

export type EventAgeGroupsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type EventAgeGroupsQuery = (
  { __typename?: 'Query' }
  & { EventAgeGroups?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & { ageGroupMale?: Maybe<(
      { __typename?: 'DirSportAgeGroup' }
      & Pick<DirSportAgeGroup, 'id' | 'minAge' | 'maxAge'>
      & { clsAgeGroup: (
        { __typename?: 'ClassifierValue' }
        & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
      ) }
    )>, ageGroupFemale?: Maybe<(
      { __typename?: 'DirSportAgeGroup' }
      & Pick<DirSportAgeGroup, 'id' | 'minAge' | 'maxAge'>
      & { clsAgeGroup: (
        { __typename?: 'ClassifierValue' }
        & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
      ) }
    )> }
  )> }
);

export type FindEventsQueryVariables = Exact<{
  search: Scalars['String'];
  filter?: Maybe<DirSportingEventWhereInput>;
}>;


export type FindEventsQuery = (
  { __typename?: 'Query' }
  & { data: Array<(
    { __typename?: 'SimilarSportingEvent' }
    & { sportingEvent: (
      { __typename?: 'DirSportingEvent' }
      & { value: DirSportingEvent['id'], label: DirSportingEvent['name'] }
    ) }
  )> }
);

export type EventsByRegistryNumberQueryVariables = Exact<{
  filter?: Maybe<DirSportingEventWhereInput>;
}>;


export type EventsByRegistryNumberQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & { value: DirSportingEvent['id'], label: DirSportingEvent['name'] }
  )> }
);

export type EventFoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type EventFoQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
    & { dirFederalDistricts?: Maybe<Array<(
      { __typename?: 'DirFederalDistrict' }
      & Pick<DirFederalDistrict, 'id' | 'fullName'>
    )>> }
  )> }
);

export type ValidateRegionByExistEventQueryVariables = Exact<{
  idCalendar: Scalars['UUID'];
  idRegion: Scalars['UUID'];
}>;


export type ValidateRegionByExistEventQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvents: Array<(
    { __typename?: 'DirSportingEvent' }
    & Pick<DirSportingEvent, 'id'>
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & Pick<Archive, 'id'>
    )> }
  )> }
);

export type EventTeamsListQueryVariables = Exact<{
  filter: DirNationalTeamWhereInput;
}>;


export type EventTeamsListQuery = (
  { __typename?: 'Query' }
  & { dirNationalTeams: Array<(
    { __typename?: 'DirNationalTeam' }
    & Pick<DirNationalTeam, 'id'>
    & { dirRegion: (
      { __typename?: 'DirRegion' }
      & Pick<DirRegion, 'id' | 'fullName'>
    ) }
  )> }
);

export type TeamInfoFragmentFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'membersQuota' | 'name'>
  & { statuses?: Maybe<Array<(
    { __typename?: 'DirSportingEventExtendedStatus' }
    & Pick<DirSportingEventExtendedStatus, 'id' | 'isActive'>
    & { value: (
      { __typename?: 'DirSportingEventExtendedStatusValue' }
      & Pick<DirSportingEventExtendedStatusValue, 'id' | 'name'>
    ) }
  )>>, archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )>, dirCalendar: (
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id'>
    & { clsCalendarCategory?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'id' | 'registryNumber'>
    )> }
  ), dirNationalTeams?: Maybe<Array<(
    { __typename?: 'DirNationalTeam' }
    & Pick<DirNationalTeam, 'id' | 'email' | 'athletesCall' | 'manTeamQuota' | 'athleteQuotaByGender' | 'athleteTotalQuota' | 'womanTeamQuota' | 'supportTeamQuota' | 'score' | 'point'>
    & { emailRecipients?: Maybe<Array<(
      { __typename?: 'DirNationalTeamEmailRecipient' }
      & { value: DirNationalTeamEmailRecipient['id'], label: DirNationalTeamEmailRecipient['email'] }
    )>>, dirRegion: (
      { __typename?: 'DirRegion' }
      & Pick<DirRegion, 'shortName'>
      & { value: DirRegion['id'], label: DirRegion['fullName'] }
    ), statuses?: Maybe<Array<(
      { __typename?: 'DirNationalTeamExtendedStatus' }
      & Pick<DirNationalTeamExtendedStatus, 'isActive'>
      & { value: (
        { __typename?: 'DirNationalTeamStatus' }
        & Pick<DirNationalTeamStatus, 'id' | 'name'>
      ) }
    )>>, status: (
      { __typename?: 'DirNationalTeamStatus' }
      & { value: DirNationalTeamStatus['id'], label: DirNationalTeamStatus['name'] }
    ), university?: Maybe<(
      { __typename?: 'DirOrganization' }
      & { value: DirOrganization['id'], label: DirOrganization['name'] }
    )>, athleteGroups?: Maybe<Array<(
      { __typename?: 'DirNationalTeamAthleteGroup' }
      & Pick<DirNationalTeamAthleteGroup, 'id'>
      & { athletes?: Maybe<Array<(
        { __typename?: 'DirNationalTeamAthlete' }
        & Pick<DirNationalTeamAthlete, 'id' | 'entranceStatus'>
        & { dirAthlete: (
          { __typename?: 'DirAthlete' }
          & Pick<DirAthlete, 'id'>
          & { dirPerson: (
            { __typename?: 'DirPerson' }
            & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'registryNumber' | 'birthday' | 'isMale'>
          ), competetionResult?: Maybe<Array<(
            { __typename?: 'DirAthleteCompetitionResult' }
            & Pick<DirAthleteCompetitionResult, 'id' | 'point' | 'result' | 'completedRank'>
            & { sportingEventProgramType: (
              { __typename?: 'DirSportingEventProgramType' }
              & Pick<DirSportingEventProgramType, 'id'>
              & { clsAgeGroups?: Maybe<Array<(
                { __typename?: 'ClassifierValue' }
                & { value: ClassifierValue['id'] }
              )>> }
            ) }
          )>> }
        ) }
      )>> }
    )>>, specialists?: Maybe<Array<(
      { __typename?: 'DirNationalTeamMember' }
      & Pick<DirNationalTeamMember, 'id' | 'entranceStatus' | 'admissionDate'>
    )>>, medics?: Maybe<Array<(
      { __typename?: 'DirNationalTeamMember' }
      & Pick<DirNationalTeamMember, 'id' | 'entranceStatus' | 'admissionDate'>
    )>>, trainers?: Maybe<Array<(
      { __typename?: 'DirNationalTeamTrainer' }
      & Pick<DirNationalTeamTrainer, 'id' | 'entranceStatus' | 'admissionDate'>
    )>>, delegationHeads?: Maybe<Array<(
      { __typename?: 'DirNationalTeamMember' }
      & Pick<DirNationalTeamMember, 'id' | 'entranceStatus' | 'admissionDate'>
    )>>, archive?: Maybe<(
      { __typename?: 'Archive' }
      & Pick<Archive, 'id' | 'comment' | 'reason'>
    )> }
  )>> }
);

export type UnArchivedTeamInfoFragmentFragment = (
  { __typename?: 'DirSportingEvent' }
  & Pick<DirSportingEvent, 'id' | 'membersQuota' | 'name'>
  & { statuses?: Maybe<Array<(
    { __typename?: 'DirSportingEventExtendedStatus' }
    & Pick<DirSportingEventExtendedStatus, 'id' | 'isActive'>
    & { value: (
      { __typename?: 'DirSportingEventExtendedStatusValue' }
      & Pick<DirSportingEventExtendedStatusValue, 'id' | 'name'>
    ) }
  )>>, archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )>, dirCalendar: (
    { __typename?: 'DirCalendar' }
    & Pick<DirCalendar, 'id'>
    & { clsCalendarCategory?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'id' | 'registryNumber'>
    )> }
  ), dirNationalTeams?: Maybe<Array<(
    { __typename?: 'DirNationalTeam' }
    & Pick<DirNationalTeam, 'id' | 'email' | 'athletesCall' | 'manTeamQuota' | 'athleteQuotaByGender' | 'athleteTotalQuota' | 'womanTeamQuota' | 'supportTeamQuota' | 'score' | 'point'>
    & { emailRecipients?: Maybe<Array<(
      { __typename?: 'DirNationalTeamEmailRecipient' }
      & { value: DirNationalTeamEmailRecipient['id'], label: DirNationalTeamEmailRecipient['email'] }
    )>>, dirRegion: (
      { __typename?: 'DirRegion' }
      & Pick<DirRegion, 'shortName'>
      & { value: DirRegion['id'], label: DirRegion['fullName'] }
    ), statuses?: Maybe<Array<(
      { __typename?: 'DirNationalTeamExtendedStatus' }
      & Pick<DirNationalTeamExtendedStatus, 'isActive'>
      & { value: (
        { __typename?: 'DirNationalTeamStatus' }
        & Pick<DirNationalTeamStatus, 'id' | 'name'>
      ) }
    )>>, status: (
      { __typename?: 'DirNationalTeamStatus' }
      & { value: DirNationalTeamStatus['id'], label: DirNationalTeamStatus['name'] }
    ), university?: Maybe<(
      { __typename?: 'DirOrganization' }
      & { value: DirOrganization['id'], label: DirOrganization['name'] }
    )>, athleteGroups?: Maybe<Array<(
      { __typename?: 'DirNationalTeamAthleteGroup' }
      & Pick<DirNationalTeamAthleteGroup, 'id'>
      & { athletes?: Maybe<Array<(
        { __typename?: 'DirNationalTeamAthlete' }
        & Pick<DirNationalTeamAthlete, 'id' | 'entranceStatus'>
        & { dirAthlete: (
          { __typename?: 'DirAthlete' }
          & Pick<DirAthlete, 'id'>
          & { dirPerson: (
            { __typename?: 'DirPerson' }
            & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic' | 'registryNumber' | 'birthday' | 'isMale'>
          ), competetionResult?: Maybe<Array<(
            { __typename?: 'DirAthleteCompetitionResult' }
            & Pick<DirAthleteCompetitionResult, 'id' | 'point' | 'result' | 'completedRank'>
            & { sportingEventProgramType: (
              { __typename?: 'DirSportingEventProgramType' }
              & Pick<DirSportingEventProgramType, 'id'>
              & { clsAgeGroups?: Maybe<Array<(
                { __typename?: 'ClassifierValue' }
                & { value: ClassifierValue['id'] }
              )>> }
            ) }
          )>> }
        ) }
      )>> }
    )>>, specialists?: Maybe<Array<(
      { __typename?: 'DirNationalTeamMember' }
      & Pick<DirNationalTeamMember, 'id' | 'entranceStatus' | 'admissionDate'>
    )>>, medics?: Maybe<Array<(
      { __typename?: 'DirNationalTeamMember' }
      & Pick<DirNationalTeamMember, 'id' | 'entranceStatus' | 'admissionDate'>
    )>>, trainers?: Maybe<Array<(
      { __typename?: 'DirNationalTeamTrainer' }
      & Pick<DirNationalTeamTrainer, 'id' | 'entranceStatus' | 'admissionDate'>
    )>>, delegationHeads?: Maybe<Array<(
      { __typename?: 'DirNationalTeamMember' }
      & Pick<DirNationalTeamMember, 'id' | 'entranceStatus' | 'admissionDate'>
    )>>, archive?: Maybe<(
      { __typename?: 'Archive' }
      & Pick<Archive, 'id' | 'comment' | 'reason'>
    )> }
  )>> }
);

export type SportingEventTeamInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventTeamInfoQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & TeamInfoFragmentFragment
  )> }
);

export type SportingEventUnArchivedTeamInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SportingEventUnArchivedTeamInfoQuery = (
  { __typename?: 'Query' }
  & { dirSportingEvent?: Maybe<(
    { __typename?: 'DirSportingEvent' }
    & UnArchivedTeamInfoFragmentFragment
  )> }
);

export type UniversityOrganizationsQueryVariables = Exact<{
  regionId: Scalars['UUID'];
}>;


export type UniversityOrganizationsQuery = (
  { __typename?: 'Query' }
  & { dirOrganizations: Array<(
    { __typename?: 'DirOrganization' }
    & { value: DirOrganization['id'], label: DirOrganization['name'] }
  )> }
);

export type CreateUserSupportRequestMutationVariables = Exact<{
  operator_id?: Maybe<UserCreateOneWithoutOperatorSupportRequestsInput>;
  user_id?: Maybe<UserCreateOneWithoutUserSupportRequestsInput>;
  text: Scalars['String'];
  file_id?: Maybe<FileCreateOneInput>;
  request_type: UserSupportRequestTypeCreateOneInput;
}>;


export type CreateUserSupportRequestMutation = (
  { __typename?: 'Mutation' }
  & { createUserSupportRequest: (
    { __typename?: 'UserSupportRequest' }
    & Pick<UserSupportRequest, 'id'>
  ) }
);

export type CreateUserSupportRequestWithoutFileMutationVariables = Exact<{
  operator_id?: Maybe<UserCreateOneWithoutOperatorSupportRequestsInput>;
  user_id?: Maybe<UserCreateOneWithoutUserSupportRequestsInput>;
  text: Scalars['String'];
  request_type: UserSupportRequestTypeCreateOneInput;
}>;


export type CreateUserSupportRequestWithoutFileMutation = (
  { __typename?: 'Mutation' }
  & { createUserSupportRequest: (
    { __typename?: 'UserSupportRequest' }
    & Pick<UserSupportRequest, 'id'>
  ) }
);

export type UserSupportRequestTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSupportRequestTypesQuery = (
  { __typename?: 'Query' }
  & { userSupportRequestTypes: Array<(
    { __typename?: 'UserSupportRequestType' }
    & { value: UserSupportRequestType['id'], label: UserSupportRequestType['name'] }
  )> }
);

export type CreateNewTrainerMutationVariables = Exact<{
  idPerson: Scalars['UUID'];
  sportType: Scalars['UUID'];
}>;


export type CreateNewTrainerMutation = (
  { __typename?: 'Mutation' }
  & { createDirTrainer: (
    { __typename?: 'DirTrainer' }
    & TrainerFrFragment
  ) }
);

export type UpdateTrainerSportMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirTrainerSportUpdateInput;
}>;


export type UpdateTrainerSportMutation = (
  { __typename?: 'Mutation' }
  & { updateDirTrainerSport?: Maybe<(
    { __typename?: 'DirTrainerSport' }
    & Pick<DirTrainerSport, 'id'>
  )> }
);

export type TrainerFrFragment = (
  { __typename?: 'DirTrainer' }
  & Pick<DirTrainer, 'id' | 'registryNumber'>
  & { archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )>, dirPerson: (
    { __typename?: 'DirPerson' }
    & { additionalRegions?: Maybe<Array<(
      { __typename?: 'DirPersonAdditionalRegion' }
      & Pick<DirPersonAdditionalRegion, 'id'>
      & { dirRegion?: Maybe<(
        { __typename?: 'DirRegion' }
        & Pick<DirRegion, 'id' | 'fullName'>
      )> }
    )>>, dirCountry?: Maybe<(
      { __typename?: 'DirCountry' }
      & { label: DirCountry['fullName'] }
    )>, dirForeignCity?: Maybe<(
      { __typename?: 'DirForeignCity' }
      & { label: DirForeignCity['fullName'] }
      & { dirCountry: (
        { __typename?: 'DirCountry' }
        & { label: DirCountry['fullName'] }
      ) }
    )> }
    & PersonFrFragment
  ), sports?: Maybe<Array<(
    { __typename?: 'DirTrainerSport' }
    & Pick<DirTrainerSport, 'id'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & { value: DirSport['id'], label: DirSport['fullName'] }
    ), archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )>, organizations?: Maybe<Array<(
      { __typename?: 'DirTrainerSportOrganization' }
      & Pick<DirTrainerSportOrganization, 'id' | 'employmentDate' | 'dismissalDate'>
      & { clsPosition: (
        { __typename?: 'ClassifierValue' }
        & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
      ), dirOrganization: (
        { __typename?: 'DirOrganization' }
        & { value: DirOrganization['id'], label: DirOrganization['name'] }
      ) }
    )>> }
  )>> }
);

export type TrainerListFragment = (
  { __typename?: 'DirTrainer' }
  & Pick<DirTrainer, 'id' | 'registryNumber'>
  & { archive?: Maybe<(
    { __typename?: 'Archive' }
    & Pick<Archive, 'id'>
  )>, dirPerson: (
    { __typename?: 'DirPerson' }
    & PersonFrFragment
  ), sports?: Maybe<Array<(
    { __typename?: 'DirTrainerSport' }
    & Pick<DirTrainerSport, 'id'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & Pick<DirSport, 'id' | 'fullName'>
    ), organizations?: Maybe<Array<(
      { __typename?: 'DirTrainerSportOrganization' }
      & Pick<DirTrainerSportOrganization, 'id' | 'dismissalDate'>
      & { dirOrganization: (
        { __typename?: 'DirOrganization' }
        & Pick<DirOrganization, 'id' | 'name'>
      ) }
    )>> }
  )>> }
);

export type TrainersQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirTrainerWhereInput>;
}>;


export type TrainersQuery = (
  { __typename?: 'Query' }
  & { dirTrainers: Array<(
    { __typename?: 'DirTrainer' }
    & TrainerListFragment
  )> }
);

export type TrainerTypeSportsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TrainerTypeSportsQuery = (
  { __typename?: 'Query' }
  & { dirTrainerSports: Array<(
    { __typename?: 'DirTrainerSport' }
    & Pick<DirTrainerSport, 'id'>
    & { dirSport: (
      { __typename?: 'DirSport' }
      & { label: DirSport['fullName'], value: DirSport['id'] }
    ), disciplineGroups?: Maybe<Array<(
      { __typename?: 'DirSportDisciplineGroup' }
      & { label: DirSportDisciplineGroup['name'], value: DirSportDisciplineGroup['id'] }
    )>>, archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type TrainersCountQueryVariables = Exact<{
  filter?: Maybe<DirTrainerWhereInput>;
}>;


export type TrainersCountQuery = (
  { __typename?: 'Query' }
  & { count: Query['dirTrainersCount'] }
);

export type TrainerByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TrainerByIdQuery = (
  { __typename?: 'Query' }
  & { dirTrainer?: Maybe<(
    { __typename?: 'DirTrainer' }
    & TrainerFrFragment
  )> }
);

export type TrainersFilteredQueryVariables = Exact<{
  filter?: Maybe<DirTrainerWhereInput>;
}>;


export type TrainersFilteredQuery = (
  { __typename?: 'Query' }
  & { dirTrainers: Array<(
    { __typename?: 'DirTrainer' }
    & TrainerFrFragment
  )> }
);

export type TrainersSimilarQueryVariables = Exact<{
  name: Scalars['String'];
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirTrainerWhereInput>;
}>;


export type TrainersSimilarQuery = (
  { __typename?: 'Query' }
  & { dirPersonsSimilar: Array<(
    { __typename?: 'SimilarPerson' }
    & Pick<SimilarPerson, 'similarity'>
    & { person: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id'>
      & { trainerProfile?: Maybe<(
        { __typename?: 'DirTrainer' }
        & TrainerListFragment
      )> }
    ) }
  )> }
);

export type TrainersSimilarCountQueryVariables = Exact<{
  name: Scalars['String'];
  filter?: Maybe<DirTrainerWhereInput>;
}>;


export type TrainersSimilarCountQuery = (
  { __typename?: 'Query' }
  & { dirPersonsSimilar: Array<(
    { __typename?: 'SimilarPerson' }
    & Pick<SimilarPerson, 'similarity'>
    & { person: (
      { __typename?: 'DirPerson' }
      & Pick<DirPerson, 'id'>
    ) }
  )> }
);

export type TypeSportAgeGroupsRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
  idCls: Scalars['UUID'];
}>;


export type TypeSportAgeGroupsRemoveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & AgeGroupFragment
  )> }
);

export type TypeSportAgeGroupsUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idGroup: Scalars['UUID'];
  idCls: Scalars['UUID'];
  minAge: Scalars['Int'];
  maxAge?: Maybe<Scalars['Int']>;
}>;


export type TypeSportAgeGroupsUpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & AgeGroupFragment
  )> }
);

export type TypeSportAgeGroupsCreateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idCls: Scalars['UUID'];
  minAge: Scalars['Int'];
  maxAge?: Maybe<Scalars['Int']>;
}>;


export type TypeSportAgeGroupsCreateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & AgeGroupFragment
  )> }
);

export type TypeSportAgeGroupsArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type TypeSportAgeGroupsArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportAgeGroup?: Maybe<(
    { __typename?: 'DirSportAgeGroup' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type TypeSportAgeGroupsUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TypeSportAgeGroupsUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportAgeGroup?: Maybe<(
    { __typename?: 'DirSportAgeGroup' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type TypeSportAgeGroupsRequirementsCreateMutationVariables = Exact<{
  idAgeGroup: Scalars['UUID'];
  clsRanksAndAwards?: Maybe<Scalars['UUID']>;
  clsCalendarCategory?: Maybe<Scalars['UUID']>;
}>;


export type TypeSportAgeGroupsRequirementsCreateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportAgeGroup?: Maybe<(
    { __typename?: 'DirSportAgeGroup' }
    & Pick<DirSportAgeGroup, 'id'>
  )> }
);

export type TypeSportAgeGroupsRequirementsDeleteMutationVariables = Exact<{
  idAgeGroup: Scalars['UUID'];
  id: Scalars['UUID'];
}>;


export type TypeSportAgeGroupsRequirementsDeleteMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportAgeGroup?: Maybe<(
    { __typename?: 'DirSportAgeGroup' }
    & Pick<DirSportAgeGroup, 'id'>
  )> }
);

export type AgeGroupFrFragment = (
  { __typename?: 'DirSportAgeGroup' }
  & Pick<DirSportAgeGroup, 'id' | 'minAge' | 'maxAge'>
  & { archive?: Maybe<(
    { __typename?: 'Archive' }
    & ArchiveStatusFragment
  )>, clsAgeGroup: (
    { __typename?: 'ClassifierValue' }
    & { value: ClassifierValue['id'], label: ClassifierValue['fullName'] }
  ), participationRequirements?: Maybe<Array<(
    { __typename?: 'DirSportAgeGroupParticipationRequirements' }
    & Pick<DirSportAgeGroupParticipationRequirements, 'id'>
    & { clsCalendarCategory?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { label: ClassifierValue['fullName'], shortLabel: ClassifierValue['shortName'], value: ClassifierValue['id'] }
    )>, clsRanksAndAwards?: Maybe<(
      { __typename?: 'ClassifierValue' }
      & { label: ClassifierValue['fullName'], shortLabel: ClassifierValue['shortName'], value: ClassifierValue['id'] }
    )> }
  )>> }
);

export type AgeGroupFragment = (
  { __typename?: 'DirSport' }
  & Pick<DirSport, 'id'>
  & { ageGroups?: Maybe<Array<(
    { __typename?: 'DirSportAgeGroup' }
    & AgeGroupFrFragment
  )>> }
);

export type TypeSportAgeGroupsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TypeSportAgeGroupsQuery = (
  { __typename?: 'Query' }
  & { dirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & AgeGroupFragment
  )> }
);

export type RemoveBaseRegionByIdMutationVariables = Exact<{
  id: Scalars['UUID'];
  idBaseRegion: Scalars['UUID'];
}>;


export type RemoveBaseRegionByIdMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & BaseRegionFragment
  )> }
);

export type UpdateBaseRegionByIdMutationVariables = Exact<{
  id: Scalars['UUID'];
  idBaseRegion: Scalars['UUID'];
  idRegion: Scalars['UUID'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateBaseRegionByIdMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & BaseRegionFragment
  )> }
);

export type CreteBaseRegionMutationVariables = Exact<{
  id: Scalars['UUID'];
  idRegion: Scalars['UUID'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type CreteBaseRegionMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & BaseRegionFragment
  )> }
);

export type BaseRegionFragment = (
  { __typename?: 'DirSport' }
  & { baseRegions?: Maybe<Array<(
    { __typename?: 'DirSportBaseRegion' }
    & Pick<DirSportBaseRegion, 'id' | 'startDate' | 'endDate'>
    & { dirRegion: (
      { __typename?: 'DirRegion' }
      & { value: DirRegion['id'], label: DirRegion['shortName'] }
    ) }
  )>> }
);

export type BaseRegionsByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type BaseRegionsByIdQuery = (
  { __typename?: 'Query' }
  & { dirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & BaseRegionFragment
  )> }
);

export type TypeSportDisciplinesUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idDiscipline: Scalars['UUID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
}>;


export type TypeSportDisciplinesUpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & DisciplinesFragment
  )> }
);

export type TypeSportDisciplinesCreateMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
}>;


export type TypeSportDisciplinesCreateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & DisciplinesFragment
  )> }
);

export type TypeSportDisciplinesArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type TypeSportDisciplinesArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportDiscipline?: Maybe<(
    { __typename?: 'DirSportDiscipline' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type TypeSportDisciplinesArchiveRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TypeSportDisciplinesArchiveRemoveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportDiscipline?: Maybe<(
    { __typename?: 'DirSportDiscipline' }
    & Pick<DirSportDiscipline, 'id'>
  )> }
);

export type DisciplinesFragment = (
  { __typename?: 'DirSport' }
  & Pick<DirSport, 'id'>
  & { disciplines?: Maybe<Array<(
    { __typename?: 'DirSportDiscipline' }
    & Pick<DirSportDiscipline, 'id' | 'name' | 'code'>
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )>> }
);

export type TypeSportDisciplinesQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TypeSportDisciplinesQuery = (
  { __typename?: 'Query' }
  & { dirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & DisciplinesFragment
  )> }
);

export type TypeSportDisciplinesDrillQueryVariables = Exact<{
  filter?: Maybe<DirSportDisciplineWhereInput>;
}>;


export type TypeSportDisciplinesDrillQuery = (
  { __typename?: 'Query' }
  & { dirSportDisciplines: Array<(
    { __typename?: 'DirSportDiscipline' }
    & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
  )> }
);

export type MultipleTypeSportDisciplinesDrillQueryVariables = Exact<{
  id?: Maybe<Array<Scalars['UUID']>>;
}>;


export type MultipleTypeSportDisciplinesDrillQuery = (
  { __typename?: 'Query' }
  & { dirSports: Array<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & { disciplines?: Maybe<Array<(
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
      & { typeSport?: Maybe<(
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id'>
      )> }
    )>>, disciplineGroups?: Maybe<Array<(
      { __typename?: 'DirSportDisciplineGroup' }
      & { value: DirSportDisciplineGroup['id'], label: DirSportDisciplineGroup['name'] }
      & { typeSport?: Maybe<(
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id'>
      )> }
    )>> }
  )> }
);

export type GroupTypesSportAndDisciplinesRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;


export type GroupTypesSportAndDisciplinesRemoveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & { clsSportAndDisciplineGroups?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'fullName' | 'order' | 'id'>
    )>> }
  )> }
);

export type GroupTypesSportAndDisciplinesCreateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;


export type GroupTypesSportAndDisciplinesCreateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & { clsSportAndDisciplineGroups?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & Pick<ClassifierValue, 'fullName' | 'order' | 'id'>
    )>> }
  )> }
);

export type GroupTypesSportAndDisciplinesQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GroupTypesSportAndDisciplinesQuery = (
  { __typename?: 'Query' }
  & { dirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & { clsSportAndDisciplineGroups?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & ClassifierValueFragment
    )>> }
  )> }
);

export type TypeSportGroupDisciplinesRenameMutationVariables = Exact<{
  id: Scalars['UUID'];
  idGroup: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type TypeSportGroupDisciplinesRenameMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & DisciplineGroupFragment
  )> }
);

export type TypeSportGroupDisciplinesAssignDisciplineMutationVariables = Exact<{
  id: Scalars['UUID'];
  idGroup: Scalars['UUID'];
  discipline: Scalars['UUID'];
}>;


export type TypeSportGroupDisciplinesAssignDisciplineMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & DisciplineGroupFragment
  )> }
);

export type TypeSportGroupDisciplinesUnAssignDisciplineMutationVariables = Exact<{
  id: Scalars['UUID'];
  idGroup: Scalars['UUID'];
  discipline: Scalars['UUID'];
}>;


export type TypeSportGroupDisciplinesUnAssignDisciplineMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & DisciplineGroupFragment
  )> }
);

export type TypeSportGroupDisciplinesCreateMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type TypeSportGroupDisciplinesCreateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & DisciplineGroupFragment
  )> }
);

export type TypeSportGroupDisciplinesArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type TypeSportGroupDisciplinesArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportDisciplineGroup?: Maybe<(
    { __typename?: 'DirSportDisciplineGroup' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type TypeSportGroupDisciplinesArchiveRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TypeSportGroupDisciplinesArchiveRemoveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportDisciplineGroup?: Maybe<(
    { __typename?: 'DirSportDisciplineGroup' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type DisciplineGroupFragment = (
  { __typename?: 'DirSport' }
  & Pick<DirSport, 'id'>
  & { disciplineGroups?: Maybe<Array<(
    { __typename?: 'DirSportDisciplineGroup' }
    & Pick<DirSportDisciplineGroup, 'id' | 'name'>
    & { disciplines?: Maybe<Array<(
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
    )>>, archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )>>, disciplines?: Maybe<Array<(
    { __typename?: 'DirSportDiscipline' }
    & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
  )>> }
);

export type TypeSportGroupsDisciplinesQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TypeSportGroupsDisciplinesQuery = (
  { __typename?: 'Query' }
  & { dirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & DisciplineGroupFragment
  )> }
);

export type TypeSportsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirSportWhereInput>;
}>;


export type TypeSportsQuery = (
  { __typename?: 'Query' }
  & { dirSports: Array<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'evsk' | 'fullName' | 'registryNumber' | 'id'>
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type TypeSportsCountQueryVariables = Exact<{
  filter?: Maybe<DirSportWhereInput>;
}>;


export type TypeSportsCountQuery = (
  { __typename?: 'Query' }
  & { count: Query['dirSportsCount'] }
);

export type TypeSportCreateMutationVariables = Exact<{
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  evsk: Scalars['String'];
}>;


export type TypeSportCreateMutation = (
  { __typename?: 'Mutation' }
  & { createDirSport: (
    { __typename?: 'DirSport' }
    & MainInfoFragment
  ) }
);

export type TypeSportUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  evsk?: Maybe<Scalars['String']>;
}>;


export type TypeSportUpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & MainInfoFragment
  )> }
);

export type TypeSportArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;


export type TypeSportArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type TypeSportUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TypeSportUnArchiveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & { archive?: Maybe<(
      { __typename?: 'Archive' }
      & ArchiveStatusFragment
    )> }
  )> }
);

export type MainInfoFragment = (
  { __typename?: 'DirSport' }
  & Pick<DirSport, 'id' | 'fullName' | 'shortName' | 'evsk' | 'registryNumber'>
  & { archive?: Maybe<(
    { __typename?: 'Archive' }
    & Pick<Archive, 'comment' | 'id' | 'reason' | 'date'>
  )> }
);

export type FetchTypeSportByIdMainInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type FetchTypeSportByIdMainInfoQuery = (
  { __typename?: 'Query' }
  & { dirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & MainInfoFragment
  )> }
);

export type TypeSportsListQueryVariables = Exact<{ [key: string]: never; }>;


export type TypeSportsListQuery = (
  { __typename?: 'Query' }
  & { dirSports: Array<(
    { __typename?: 'DirSport' }
    & { value: DirSport['id'], label: DirSport['fullName'] }
    & { disciplines?: Maybe<Array<(
      { __typename?: 'DirSportDiscipline' }
      & { value: DirSportDiscipline['id'], label: DirSportDiscipline['name'] }
      & { typeSport?: Maybe<(
        { __typename?: 'DirSport' }
        & Pick<DirSport, 'id'>
      )> }
    )>> }
  )> }
);

export type TypeSportsListSelectQueryVariables = Exact<{
  where?: Maybe<DirSportWhereInput>;
}>;


export type TypeSportsListSelectQuery = (
  { __typename?: 'Query' }
  & { dirSports: Array<(
    { __typename?: 'DirSport' }
    & { value: DirSport['id'], label: DirSport['fullName'] }
  )> }
);

export type TypeSportRanksAndTitlesCreateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;


export type TypeSportRanksAndTitlesCreateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & { clsRanksAndAwards?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
    )>> }
  )> }
);

export type TypeSportRanksAndTitlesRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;


export type TypeSportRanksAndTitlesRemoveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & { clsRanksAndAwards?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
    )>> }
  )> }
);

export type TypeSportRanksAndTitlesQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TypeSportRanksAndTitlesQuery = (
  { __typename?: 'Query' }
  & { dirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & { clsRanksAndAwards?: Maybe<Array<(
      { __typename?: 'ClassifierValue' }
      & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
    )>> }
  )> }
);

export type TypeSportStagePrepareCreateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idStep: Scalars['UUID'];
  idRank?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
}>;


export type TypeSportStagePrepareCreateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & { trainingStages?: Maybe<Array<(
      { __typename?: 'DirSportTrainingStage' }
      & SportTrainingStageFragment
    )>> }
  )> }
);

export type TypeSportStagePrepareUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idStep: Scalars['UUID'];
  idRank?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
}>;


export type TypeSportStagePrepareUpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSportTrainingStage?: Maybe<(
    { __typename?: 'DirSportTrainingStage' }
    & SportTrainingStageFragment
  )> }
);

export type TypeSportStagePrepareRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;


export type TypeSportStagePrepareRemoveMutation = (
  { __typename?: 'Mutation' }
  & { updateDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & { trainingStages?: Maybe<Array<(
      { __typename?: 'DirSportTrainingStage' }
      & SportTrainingStageFragment
    )>> }
  )> }
);

export type SportTrainingStageFragment = (
  { __typename?: 'DirSportTrainingStage' }
  & Pick<DirSportTrainingStage, 'id' | 'minManAge' | 'maxManAge' | 'minWomanAge' | 'maxWomanAge' | 'minGroupSize' | 'maxGroupSize'>
  & { clsTrainingStage: (
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  ), clsRanksAndAwards?: Maybe<Array<(
    { __typename?: 'ClassifierValue' }
    & { label: ClassifierValue['fullName'], value: ClassifierValue['id'] }
  )>> }
);

export type TypeSportStagePreparingQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TypeSportStagePreparingQuery = (
  { __typename?: 'Query' }
  & { dirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
    & { trainingStages?: Maybe<Array<(
      { __typename?: 'DirSportTrainingStage' }
      & SportTrainingStageFragment
    )>> }
  )> }
);

export type RemoveTypeSportMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RemoveTypeSportMutation = (
  { __typename?: 'Mutation' }
  & { deleteDirSport?: Maybe<(
    { __typename?: 'DirSport' }
    & Pick<DirSport, 'id'>
  )> }
);

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RemoveUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: Scalars['String'];
  regional?: Maybe<RegionalMinsportProfileCreateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileCreateOneInput>;
  school?: Maybe<RegionalSchoolProfileCreateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileCreateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileCreateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileCreateOneInput>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { signup: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
    & { user: (
      { __typename?: 'User' }
      & UserFrFragment
    ) }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['UUID'];
  userID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  email: Scalars['String'];
  isActive: Scalars['Boolean'];
  regional?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  regionalAdministratorProfile?: Maybe<RegionalAdministratorProfileUpdateOneInput>;
  school?: Maybe<RegionalSchoolProfileUpdateOneInput>;
  regular?: Maybe<RegularUserUpdateOneInput>;
  roivSpecialistProfile?: Maybe<RoivSpecialistProfileUpdateOneInput>;
  fcpsrSportingEventOrganizerProfile?: Maybe<FcpsrSportingEventOrganizerProfileUpdateOneInput>;
  gskRefereeProfile?: Maybe<GskRefereeProfileUpdateOneInput>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & UserFrFragment
  )>, changeUserRole: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ), changeUserEmail: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type UpdateUserContactInfoMutationVariables = Exact<{
  id: Scalars['UUID'];
  regular?: Maybe<RegularUserUpdateOneInput>;
  personProfile?: Maybe<DirPersonUpdateOneWithoutUserProfileInput>;
}>;


export type UpdateUserContactInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & UserFrFragment
  )> }
);

export type UserFrFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email' | 'isActive'>
  & { role: (
    { __typename?: 'UserRole' }
    & { value: UserRole['name'], label: UserRole['description'] }
  ), personProfile?: Maybe<(
    { __typename?: 'DirPerson' }
    & Pick<DirPerson, 'id'>
    & { dirRegion?: Maybe<(
      { __typename?: 'DirRegion' }
      & { value: DirRegion['id'], label: DirRegion['fullName'] }
    )> }
  )>, regular?: Maybe<(
    { __typename?: 'RegularUser' }
    & Pick<RegularUser, 'address' | 'birthday' | 'firstname' | 'lastname' | 'patronymic' | 'isMale' | 'position' | 'phone'>
    & { dirOrganization?: Maybe<(
      { __typename?: 'DirOrganization' }
      & { value: DirOrganization['id'], label: DirOrganization['name'] }
    )> }
  )> }
);

export type UsersQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<UserWhereInput>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & UserFrFragment
  )> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & { regionalMinsportProfile?: Maybe<(
      { __typename?: 'RegionalMinsportProfile' }
      & Pick<RegionalMinsportProfile, 'id'>
      & { dirRegion?: Maybe<(
        { __typename?: 'DirRegion' }
        & { value: DirRegion['id'], label: DirRegion['fullName'] }
      )>, dirFederalDistrict?: Maybe<(
        { __typename?: 'DirFederalDistrict' }
        & { value: DirFederalDistrict['id'], label: DirFederalDistrict['fullName'] }
      )> }
    )>, regionalAdministratorProfile?: Maybe<(
      { __typename?: 'RegionalAdministratorProfile' }
      & Pick<RegionalAdministratorProfile, 'id'>
      & { dirRegion: (
        { __typename?: 'DirRegion' }
        & { value: DirRegion['id'], label: DirRegion['fullName'] }
      ) }
    )>, roivSpecialistProfile?: Maybe<(
      { __typename?: 'RoivSpecialistProfile' }
      & Pick<RoivSpecialistProfile, 'id'>
      & { dirRegion: (
        { __typename?: 'DirRegion' }
        & { value: DirRegion['id'], label: DirRegion['fullName'] }
      ) }
    )>, regionalSchoolProfile?: Maybe<(
      { __typename?: 'RegionalSchoolProfile' }
      & Pick<RegionalSchoolProfile, 'id'>
      & { dirOrganization: (
        { __typename?: 'DirOrganization' }
        & { value: DirOrganization['id'], label: DirOrganization['name'] }
      ) }
    )>, fcpsrSportingEventOrganizerProfile?: Maybe<(
      { __typename?: 'FcpsrSportingEventOrganizerProfile' }
      & Pick<FcpsrSportingEventOrganizerProfile, 'id'>
      & { dirSports?: Maybe<Array<(
        { __typename?: 'DirSport' }
        & { value: DirSport['id'], label: DirSport['fullName'] }
      )>>, dirCalendars?: Maybe<Array<(
        { __typename?: 'DirCalendar' }
        & { value: DirCalendar['id'], label: DirCalendar['fullName'] }
      )>> }
    )>, gskRefereeProfile?: Maybe<(
      { __typename?: 'GskRefereeProfile' }
      & Pick<GskRefereeProfile, 'id'>
      & { sportingEvents?: Maybe<Array<(
        { __typename?: 'DirSportingEvent' }
        & { value: DirSportingEvent['id'], label: DirSportingEvent['name'] }
      )>> }
    )> }
    & UserFrFragment
  )> }
);

export type UsersRoivQueryVariables = Exact<{
  regionId: Scalars['UUID'];
}>;


export type UsersRoivQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { value: User['email'] }
    & { roivSpecialistProfile?: Maybe<(
      { __typename?: 'RoivSpecialistProfile' }
      & Pick<RoivSpecialistProfile, 'id'>
    )>, regular?: Maybe<(
      { __typename?: 'RegularUser' }
      & Pick<RegularUser, 'id' | 'firstname' | 'lastname' | 'patronymic'>
    )> }
  )> }
);

export type UniversityUsersRoivQueryVariables = Exact<{
  organizationId: Scalars['UUID'];
}>;


export type UniversityUsersRoivQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & { value: User['email'] }
    & { regular?: Maybe<(
      { __typename?: 'RegularUser' }
      & Pick<RegularUser, 'id' | 'firstname' | 'lastname' | 'patronymic'>
    )> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  resetEmail: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'publicResetPassword'>
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { signin: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { regular?: Maybe<(
        { __typename?: 'RegularUser' }
        & Pick<RegularUser, 'id' | 'lastname' | 'firstname' | 'patronymic'>
        & { dirOrganization?: Maybe<(
          { __typename?: 'DirOrganization' }
          & Pick<DirOrganization, 'id'>
          & { sports?: Maybe<Array<(
            { __typename?: 'DirOrganizationSport' }
            & Pick<DirOrganizationSport, 'id'>
            & { dirSport: (
              { __typename?: 'DirSport' }
              & Pick<DirSport, 'id'>
            ) }
          )>>, clsOrganizationCategories?: Maybe<Array<(
            { __typename?: 'ClassifierValue' }
            & Pick<ClassifierValue, 'id' | 'fullName' | 'registryNumber'>
          )>> }
        )> }
      )>, role: (
        { __typename?: 'UserRole' }
        & Pick<UserRole, 'name' | 'description'>
      ), personProfile?: Maybe<(
        { __typename?: 'DirPerson' }
        & { athleteProfile?: Maybe<(
          { __typename?: 'DirAthlete' }
          & Pick<DirAthlete, 'id'>
        )>, trainerProfile?: Maybe<(
          { __typename?: 'DirTrainer' }
          & Pick<DirTrainer, 'id'>
        )>, dirRegion?: Maybe<(
          { __typename?: 'DirRegion' }
          & Pick<DirRegion, 'id'>
        )>, dirForeignCity?: Maybe<(
          { __typename?: 'DirForeignCity' }
          & Pick<DirForeignCity, 'id'>
        )> }
      )>, regionalMinsportProfile?: Maybe<(
        { __typename?: 'RegionalMinsportProfile' }
        & { dirRegion?: Maybe<(
          { __typename?: 'DirRegion' }
          & Pick<DirRegion, 'id'>
        )>, dirFederalDistrict?: Maybe<(
          { __typename?: 'DirFederalDistrict' }
          & Pick<DirFederalDistrict, 'id'>
        )> }
      )>, regionalSchoolProfile?: Maybe<(
        { __typename?: 'RegionalSchoolProfile' }
        & { dirOrganization: (
          { __typename?: 'DirOrganization' }
          & Pick<DirOrganization, 'id'>
          & { clsOrganizationCategories?: Maybe<Array<(
            { __typename?: 'ClassifierValue' }
            & Pick<ClassifierValue, 'registryNumber' | 'fullName'>
          )>>, sports?: Maybe<Array<(
            { __typename?: 'DirOrganizationSport' }
            & Pick<DirOrganizationSport, 'id'>
            & { dirSport: (
              { __typename?: 'DirSport' }
              & Pick<DirSport, 'id'>
            ) }
          )>> }
        ) }
      )>, regionalAdministratorProfile?: Maybe<(
        { __typename?: 'RegionalAdministratorProfile' }
        & { dirRegion: (
          { __typename?: 'DirRegion' }
          & Pick<DirRegion, 'id'>
        ) }
      )>, roivSpecialistProfile?: Maybe<(
        { __typename?: 'RoivSpecialistProfile' }
        & { dirRegion: (
          { __typename?: 'DirRegion' }
          & Pick<DirRegion, 'id'>
        ) }
      )>, fcpsrSportingEventOrganizerProfile?: Maybe<(
        { __typename?: 'FcpsrSportingEventOrganizerProfile' }
        & Pick<FcpsrSportingEventOrganizerProfile, 'id'>
        & { dirSports?: Maybe<Array<(
          { __typename?: 'DirSport' }
          & Pick<DirSport, 'id'>
        )>>, dirCalendars?: Maybe<Array<(
          { __typename?: 'DirCalendar' }
          & Pick<DirCalendar, 'id'>
        )>> }
      )>, gskRefereeProfile?: Maybe<(
        { __typename?: 'GskRefereeProfile' }
        & Pick<GskRefereeProfile, 'id'>
        & { sportingEvents?: Maybe<Array<(
          { __typename?: 'DirSportingEvent' }
          & Pick<DirSportingEvent, 'id'>
        )>> }
      )> }
    ) }
  ) }
);

export type ChangePasswordUserMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordUserMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ) }
);

export const ArchiveStatusFragmentDoc = Apollo.gql`
    fragment ArchiveStatus on Archive {
  id
  reason
  date
  comment
}
    `;
export const CountryDirValueFragmentDoc = Apollo.gql`
    fragment CountryDirValue on DirCountry {
  value: id
  label: fullName
}
    `;
export const ForeignCityDirValueFragmentDoc = Apollo.gql`
    fragment ForeignCityDirValue on DirForeignCity {
  value: id
  label: fullName
}
    `;
export const RegionDirValueFragmentDoc = Apollo.gql`
    fragment RegionDirValue on DirRegion {
  value: id
  label: fullName
}
    `;
export const CitizenshipDirValueFragmentDoc = Apollo.gql`
    fragment CitizenshipDirValue on DirCountry {
  value: id
  label: fullName
}
    `;
export const PhoneFragmentDoc = Apollo.gql`
    fragment Phone on DirPersonPhone {
  id
  number
  deletedAt
  countryCode
  operatorCode
  additionalNumber
  clsContactType {
    value: id
    label: fullName
  }
}
    `;
export const PersonFrFragmentDoc = Apollo.gql`
    fragment PersonFR on DirPerson {
  id
  registryNumber
  firstname
  lastname
  isMale
  snils
  clsMaritalStatus {
    value: id
    label: fullName
  }
  residenceCity
  previousLastname
  patronymic
  email
  birthday
  primary {
    id
    mergedAt
    registryNumber
    firstname
    lastname
    patronymic
  }
  duplicates {
    id
    mergedAt
    registryNumber
    firstname
    lastname
    patronymic
  }
  confirmStatus {
    value: id
    label: name
  }
  isRussia
  dirCountry {
    ...CountryDirValue
  }
  dirForeignCity {
    ...ForeignCityDirValue
  }
  dirRegion {
    ...RegionDirValue
  }
  archive {
    ...ArchiveStatus
  }
  dirCitizenships(orderBy: fullName_ASC) {
    id
    ...CitizenshipDirValue
  }
  athleteProfile {
    id
  }
  trainerProfile {
    id
  }
  refereeProfile {
    id
  }
  phones(where: {deletedAt: null}) {
    ...Phone
  }
  additionalRegions {
    id
    dirRegion {
      value: id
      label: fullName
    }
    dirForeignCity {
      value: id
      label: fullName
    }
    dirCountry {
      value: id
      label: fullName
    }
  }
}
    ${CountryDirValueFragmentDoc}
${ForeignCityDirValueFragmentDoc}
${RegionDirValueFragmentDoc}
${ArchiveStatusFragmentDoc}
${CitizenshipDirValueFragmentDoc}
${PhoneFragmentDoc}`;
export const AthleteFrFragmentDoc = Apollo.gql`
    fragment AthleteFR on DirAthlete {
  id
  archive {
    ...ArchiveStatus
  }
  medCerts {
    id
    issueDate
    displayNumber
    organizationName
    organizationPhone
    organizationEmail
    organizationAddress
    physicalExaminationDate
    hasRestrictions
    sports {
      dirSport {
        fullName
        disciplineGroups {
          name
        }
      }
      clsTrainingStage {
        shortName
      }
      disciplineGroups {
        name
      }
    }
    validUntil
    isValid
    deletedAt
  }
  dirPerson {
    ...PersonFR
    additionalRegions {
      id
      dirRegion {
        id
      }
      dirForeignCity {
        id
      }
    }
  }
  sports {
    id
    archive {
      ...ArchiveStatus
    }
    dirSport {
      value: id
      label: fullName
    }
    ranksAndAwards {
      id
      clsRankAndAward {
        value: id
        label: fullName
      }
      assignmentDate
      confirmDate
      validUntil
      archive {
        ...ArchiveStatus
      }
    }
    disciplines {
      value: id
      label: name
    }
    disciplineGroups {
      value: id
      label: name
    }
    role
    organizations(orderBy: trainingStart_DESC) {
      id
      isSelfTraining
      trainingStart
      exclusionDate
      dirOrganization {
        value: id
        label: name
      }
      dirTrainer {
        value: id
        dirPerson {
          trainerProfile {
            id
          }
          id
          firstname
          patronymic
          lastname
          birthday
        }
        sports {
          organizations {
            dirOrganization {
              id
            }
            dismissalDate
          }
        }
      }
      clsTrainingStage {
        value: id
        label: shortName
        shortName
      }
    }
    dirTrainers {
      id
      dirPerson {
        firstname
        lastname
        patronymic
        registryNumber
        birthday
      }
    }
  }
}
    ${ArchiveStatusFragmentDoc}
${PersonFrFragmentDoc}`;
export const AthleteListFrFragmentDoc = Apollo.gql`
    fragment AthleteListFR on DirAthlete {
  id
  archive {
    ...ArchiveStatus
  }
  dirPerson {
    ...PersonFR
  }
  sports {
    id
    archive {
      ...ArchiveStatus
    }
    dirSport {
      id
      fullName
    }
    ranksAndAwards {
      id
      clsRankAndAward {
        value: id
        label: fullName
      }
      assignmentDate
      confirmDate
      validUntil
    }
    organizations {
      isSelfTraining
      trainingStart
      exclusionDate
      dirOrganization {
        value: id
        label: name
      }
      dirTrainer {
        dirPerson {
          id
          firstname
          patronymic
          lastname
        }
        sports {
          organizations {
            dirOrganization {
              id
            }
            dismissalDate
          }
        }
      }
      clsTrainingStage {
        shortName
      }
    }
  }
}
    ${ArchiveStatusFragmentDoc}
${PersonFrFragmentDoc}`;
export const AthleteSportInfoFragmentDoc = Apollo.gql`
    fragment AthleteSportInfo on DirAthleteSport {
  id
  archive {
    ...ArchiveStatus
  }
  dirSport {
    value: id
    label: fullName
  }
  ranksAndAwards {
    id
    clsRankAndAward {
      value: id
      label: fullName
    }
    assignmentDate
    confirmDate
    validUntil
    archive {
      ...ArchiveStatus
    }
  }
  disciplines {
    value: id
    label: name
  }
  disciplineGroups {
    value: id
    label: name
  }
  role
  organizations(orderBy: trainingStart_DESC) {
    id
    isSelfTraining
    trainingStart
    exclusionDate
    dirOrganization {
      value: id
      label: name
    }
    dirTrainer {
      value: id
      dirPerson {
        trainerProfile {
          id
        }
        id
        firstname
        patronymic
        lastname
        birthday
      }
      sports {
        organizations {
          dirOrganization {
            id
          }
          dismissalDate
        }
      }
    }
    clsTrainingStage {
      value: id
      label: shortName
      shortName
    }
  }
  dirTrainers {
    id
    dirPerson {
      firstname
      lastname
      patronymic
      registryNumber
      birthday
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const CalendarFragmentDoc = Apollo.gql`
    fragment Calendar on DirCalendar {
  id
  registryNumber
  fullName
  shortName
  order
  year
  venueCompetition {
    venueCompApplications(orderBy: id_DESC) {
      dirRegion {
        fullName
      }
      number
      notificationSentToFCPSR
    }
    id
    createdAt
    dirDocument {
      id
      number
      clsType {
        name: fullName
      }
      date
      file {
        path
      }
      title
    }
    endDate
    emailSenderFCPSR {
      email
      regular {
        lastname
        firstname
        patronymic
      }
    }
    venueCompRegulationDocAdds {
      id
      path
      name
    }
    venueCompDecisionDocAdds {
      id
      path
      name
    }
    venueCompDecisionDoc {
      id
      number
      clsType {
        name: fullName
      }
      date
      file {
        path
      }
      title
    }
  }
  clsEkpPart {
    value: registryNumber
    label: fullName
  }
  clsSeason {
    value: registryNumber
    label: fullName
  }
  clsCalendarCategory {
    value: registryNumber
    label: fullName
  }
  ekpMinsportNumber
  sportCompetitionRegulationDocument {
    name
    path
    id
    date: createdAt
  }
  regulationChanges {
    id
    name
    path
  }
  type {
    label: name
    value: id
  }
  stageNumber {
    value: id
    registryNumber
    label: fullName
  }
  dirSports {
    label: fullName
    value: id
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const CalendarEventFragmentDoc = Apollo.gql`
    fragment CalendarEvent on DirSportingEvent {
  id
  name
  startDate
  endDate
  membersQuota
  daysBeforeStartToAddProtocols
  ekp
  venue
  sports {
    dirSport {
      fullName
      id
    }
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const CalendarEventSportFragmentDoc = Apollo.gql`
    fragment CalendarEventSport on DirSportingEvent {
  id
  sports {
    dirSport {
      fullName
      id
    }
  }
}
    `;
export const EventDistrictFragmentDoc = Apollo.gql`
    fragment EventDistrict on DirSportingEvent {
  id
  dirFederalDistricts {
    id
    fullName
  }
}
    `;
export const CalendarSportFragmentDoc = Apollo.gql`
    fragment CalendarSport on DirCalendar {
  id
  fullName
  dateAcceptQuotasAndFilesStageTwo
  dirSports(orderBy: fullName_ASC) {
    id
    fullName
  }
}
    `;
export const CalendarReportEventFragmentDoc = Apollo.gql`
    fragment CalendarReportEvent on DirSportingEvent {
  id
  name
  venue
  startDate
  endDate
  ekp
  archive {
    id
  }
  dirRegion {
    id
    fullName
  }
  dirCalendar {
    fullName
    ekpMinsportNumber
  }
  dirRegion {
    fullName
  }
  clsEventStages {
    id
    fullName
  }
  sports {
    id
    dirSport {
      label: fullName
      value: id
    }
  }
  programTypes {
    id
    clsAgeGroups {
      id: id
      label: fullName
    }
  }
  membersQuota
}
    `;
export const CalendarEventByPeriodFragmentDoc = Apollo.gql`
    fragment CalendarEventByPeriod on DirSportingEvent {
  id
  name
  venue
  startDate
  endDate
  ekp
  sports {
    id
    dirSport {
      id
      fullName
      label: fullName
      shortName
    }
  }
  clsEventStages {
    id
    fullName
  }
  dirCalendar {
    id
    stageNumber {
      id
      fullName
    }
  }
}
    `;
export const CalendarQuantyRequestStageTwoFragmentDoc = Apollo.gql`
    fragment CalendarQuantyRequestStageTwo on QuantyRequestStageTwo {
  id
  dirSport {
    id
    fullName
  }
  dirRegion {
    id
    fullName
  }
  regionQuotaAccompStageTwo
  regionQuotaMaleAthlStageTwo
  regionQuotaWomenAthlStageTwo
}
    `;
export const FederalDistrictPortionFragmentDoc = Apollo.gql`
    fragment FederalDistrictPortion on DirFederalDistrict {
  id
  fullName
  shortName
  dirRegions(where: {archive: null}, orderBy: fullName_ASC) {
    id
    fullName
    shortName
  }
}
    `;
export const CalendarEventByMonthFragmentDoc = Apollo.gql`
    fragment CalendarEventByMonth on DirSportingEvent {
  id
  startDate
  endDate
}
    `;
export const RegulationStatusValuesFragmentDoc = Apollo.gql`
    fragment RegulationStatusValues on DirSportingEventRegulationStatusValue {
  value: id
  label: name
}
    `;
export const RegulationStatusFragmentDoc = Apollo.gql`
    fragment RegulationStatus on DirSportingEventRegulationStatus {
  id
  value {
    ...RegulationStatusValues
  }
  createdAt
  createdBy {
    id
    name
    email
    regular {
      id
      firstname
      lastname
      patronymic
      phone
      address
      dirOrganization {
        id
        name
        legalAddress
      }
    }
  }
  comment
  file {
    id
    name
    path
  }
  isActive
}
    ${RegulationStatusValuesFragmentDoc}`;
export const ClassifierFragmentDoc = Apollo.gql`
    fragment Classifier on Classifier {
  id
  name
  registryNumber
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const ClsValueFragmentDoc = Apollo.gql`
    fragment ClsValue on ClassifierValue {
  fullName
  shortName
  id
  registryNumber
  order
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const ClassifierValueFragmentDoc = Apollo.gql`
    fragment ClassifierValue on ClassifierValue {
  label: fullName
  value: id
}
    `;
export const DocumentFragmentDoc = Apollo.gql`
    fragment Document on DirDocument {
  id
  date
  number
  title
  clsType {
    value: id
    label: fullName
  }
  file {
    id
    name
    path
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const FederalDistrictFragmentDoc = Apollo.gql`
    fragment FederalDistrict on DirFederalDistrict {
  id
  fullName
  shortName
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const ForeignCityFragmentDoc = Apollo.gql`
    fragment ForeignCity on DirForeignCity {
  id
  fullName
  shortName
  dirCountry {
    value: id
    label: fullName
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const NationalTeamFragmentDoc = Apollo.gql`
    fragment NationalTeam on DirNationalTeam {
  id
  registryNumber
  comment
  fullName
  supportTeamQuota
  manTeamQuota
  womanTeamQuota
  athleteQuotaByGender
  athleteTotalQuota
  files {
    name
    path
    id
  }
  trainers {
    id
    isPrimary
    dirTrainer {
      id
      dirPerson {
        id
        registryNumber
        firstname
        lastname
        patronymic
        birthday
      }
    }
  }
  specialists {
    id
    firstname
    lastname
    patronymic
    birthday
  }
  delegationHeads {
    id
    lastname
    firstname
    patronymic
    birthday
  }
  medics {
    id
    firstname
    lastname
    patronymic
    birthday
  }
  score
  athleteGroups {
    id
    minAge
    maxAge
    clsAgeGroups {
      id
      fullName
    }
    disciplines {
      value: id
      label: name
      dirSport {
        id
        fullName
      }
    }
    disciplineGroups {
      value: id
      label: name
      dirSport {
        id
        fullName
      }
      disciplines {
        id
        name
      }
    }
    athletes {
      id
      bestResult
      createdAt
      dirAthlete {
        id
        dirPerson {
          registryNumber
          firstname
          lastname
          patronymic
          birthday
          isMale
        }
        sports {
          id
          disciplines {
            id
          }
          disciplineGroups {
            id
          }
          dirSport {
            id
          }
          organizations(where: {exclusionDate: null}) {
            id
            dirOrganization {
              id
              name
              fullName
            }
          }
          ranksAndAwards(where: {archive: null, clsRankAndAward: {fullName_not: ""}}) {
            id
            clsRankAndAward {
              id
              shortName
              fullName
              order
            }
            validUntil
          }
        }
        competetionResult {
          id
          point
          result
          sportingEventProgramType {
            id
            start
            minAge
            maxAge
            discipline {
              id
              name
            }
          }
        }
      }
    }
  }
  dirRegion {
    id
    fullName
  }
  university {
    id
    fullName
    name
  }
  statuses(orderBy: createdAt_DESC) {
    value {
      id
      name
    }
    createdAt
    createdBy {
      id
      name
      email
      regular {
        id
        firstname
        lastname
        patronymic
        phone
        address
        dirOrganization {
          id
          name
          legalAddress
        }
      }
    }
    comment
    file {
      id
      name
      path
    }
    isActive
  }
  status {
    id
    name
  }
  statusHistory {
    id
    date
    status {
      name
    }
    user {
      name
      email
    }
  }
  dirSportingEvent {
    id
    startDate
    endDate
    name
    registryNumber
    minManBirthYear
    maxManBirthYear
    minWomanBirthYear
    maxWomanBirthYear
    athletesQuotaNotCorrespondingByAge
    athletesQuotaNotCorrespondingByQualification
    sportQualificationFemaleNotLower {
      order
      registryNumber
      fullName
      id
    }
    sportQualificationMaleNotLower {
      order
      registryNumber
      fullName
      id
    }
    clsMinRang {
      order
      registryNumber
      fullName
      id
    }
    clsSportingQualificationNotGreater {
      order
      registryNumber
      fullName
      id
    }
    programTypes {
      id
      clsAgeGroups {
        value: id
        label: fullName
      }
    }
    sports {
      id
      dirSport {
        id
        fullName
      }
      disciplines {
        value: id
        label: name
      }
      disciplineGroups {
        value: id
        label: name
      }
    }
    dirCalendar {
      id
      fullName
      sportCompetitionRegulationDocument {
        path
        name
      }
      regulationChanges {
        id
        name
        path
      }
    }
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const NationalTeamTableFragmentDoc = Apollo.gql`
    fragment NationalTeamTable on DirNationalTeam {
  id
  registryNumber
  dirRegion {
    id
    fullName
  }
  statuses(orderBy: createdAt_DESC) {
    value {
      id
      name
    }
    isActive
  }
  dirSportingEvent {
    id
    startDate
    endDate
    name
    registryNumber
    sports {
      id
      dirSport {
        id
        fullName
      }
    }
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const OrganizationFrFragmentDoc = Apollo.gql`
    fragment OrganizationFR on DirOrganization {
  id
  registryNumber
  name
  ogrn
  isUniversity
  isLegalEntity
  dirRegion {
    value: id
    label: fullName
    shortName
  }
  sports {
    id
    trainingStartDate
    dirSport {
      label: fullName
      value: id
    }
    disciplineGroups {
      label: name
      value: id
    }
  }
  dirSports {
    id
    fullName
  }
  archive {
    ...ArchiveStatus
  }
  clsOrganizationCategories {
    value: id
    label: fullName
  }
  addresses {
    id
    place
    street
    house
    number
    type {
      id
      name
    }
  }
  phones {
    id
    number
    additionalNumber
    operatorCode
    countryCode
    clsContactType {
      id
      fullName
    }
  }
  emails {
    id
    email
    clsContactType {
      id
      fullName
    }
  }
  parent {
    id
    ogrn
    name
  }
  children {
    id
    ogrn
    name
  }
  websites
}
    ${ArchiveStatusFragmentDoc}`;
export const OrganizationLegalInfoFrFragmentDoc = Apollo.gql`
    fragment OrganizationLegalInfoFR on DirOrganization {
  id
  name
  ogrn
  isLegalEntity
  fullName
  shortName
  registrationDate
  inn
  kpp
  legalAddress
  postcode
  headFullName
  headPosition
}
    `;
export const OrganizationTypeSportsFrFragmentDoc = Apollo.gql`
    fragment OrganizationTypeSportsFR on DirOrganization {
  id
  dirSports {
    value: id
    label: fullName
  }
}
    `;
export const OrganizationDepartmentAffiliationFrFragmentDoc = Apollo.gql`
    fragment OrganizationDepartmentAffiliationFR on DirOrganization {
  id
  clsDepartmentalAffiliations(orderBy: fullName_ASC) {
    label: fullName
    value: id
  }
}
    `;
export const SimilarPersonFragmentDoc = Apollo.gql`
    fragment SimilarPerson on DirPerson {
  id
  registryNumber
  firstname
  patronymic
  lastname
  birthday
  isMale
  createdAt
  updatedAt
  primary {
    id
  }
  dirRegion {
    id
    fullName
  }
  dirForeignCity {
    id
    fullName
  }
  athleteProfile {
    id
    sports {
      id
      dirSport {
        id
        fullName
      }
      ranksAndAwards(orderBy: validUntil_DESC, where: {archive: null}) {
        id
        validUntil
        clsRankAndAward {
          id
          fullName
          order
        }
      }
    }
  }
  trainerProfile {
    id
    sports {
      id
      dirSport {
        id
        fullName
      }
    }
  }
  refereeProfile {
    id
    sports {
      id
      dirSport {
        id
        fullName
      }
    }
  }
}
    `;
export const PersonsLookupFragmentDoc = Apollo.gql`
    fragment PersonsLookup on DirPerson {
  id
  firstname
  patronymic
  lastname
}
    `;
export const MergeTaskFragmentDoc = Apollo.gql`
    fragment mergeTask on DirPersonMergeTask {
  id
  createdAt
  updatedAt
  primary {
    id
    registryNumber
    firstname
    lastname
    patronymic
    birthday
    isMale
    createdAt
    updatedAt
    athleteProfile {
      id
    }
    trainerProfile {
      id
    }
    refereeProfile {
      id
    }
    dirRegion {
      id
      fullName
    }
    dirForeignCity {
      id
      fullName
    }
  }
  duplicate {
    id
    registryNumber
    firstname
    lastname
    patronymic
    birthday
    isMale
    createdAt
    updatedAt
    athleteProfile {
      id
    }
    trainerProfile {
      id
    }
    refereeProfile {
      id
    }
    dirRegion {
      id
      fullName
    }
    dirForeignCity {
      id
      fullName
    }
  }
  status {
    id
    description
  }
  comment
}
    `;
export const PersonMergeTasksFragmentDoc = Apollo.gql`
    fragment personMergeTasks on DirPerson {
  mergeTasksAsPrimary {
    ...mergeTask
  }
  mergeTasksAsDuplicate {
    ...mergeTask
  }
}
    ${MergeTaskFragmentDoc}`;
export const RefereeFragmentDoc = Apollo.gql`
    fragment Referee on DirReferee {
  id
  dirPerson {
    id
    registryNumber
    firstname
    lastname
    patronymic
    birthday
    isMale
    isRussia
    residenceCity
    dirRegion {
      id
      fullName
    }
    dirForeignCity {
      id
      fullName
    }
    dirCountry {
      id
      fullName
    }
    bankRequisites {
      id
      name
      path
    }
  }
  sports {
    id
    workExperience
    categories {
      id
      clsRefereeCategory {
        value: id
        label: fullName
      }
      validUntil
      isEndless
      assignmentDate
      confirmationDate
      dirDocuments {
        id
        number
        date
        clsType {
          value: id
          label: fullName
        }
        file {
          id
          path
        }
        title
      }
    }
    dirSport {
      value: id
      label: fullName
    }
    archive {
      ...ArchiveStatus
    }
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const RegionFragmentDoc = Apollo.gql`
    fragment Region on DirRegion {
  id
  fullName
  shortName
  code
  roivOfficialEmail
  dirFederalDistrict {
    value: id
    label: fullName
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const DisciplineFragmentDoc = Apollo.gql`
    fragment Discipline on DirSportDiscipline {
  typeSport: dirSport {
    id
  }
  value: id
  label: name
}
    `;
export const GroupDisciplineFragmentDoc = Apollo.gql`
    fragment GroupDiscipline on DirSportDisciplineGroup {
  typeSport: dirSport {
    id
  }
  value: id
  label: name
}
    `;
export const EventsStatusFragmentDoc = Apollo.gql`
    fragment EventsStatus on DirSportingEventStatus {
  value: id
  label: name
}
    `;
export const TeamStatusFragmentDoc = Apollo.gql`
    fragment TeamStatus on DirNationalTeamStatus {
  value: id
  label: name
  isDeprecated
}
    `;
export const AgeGroupFrekpFragmentDoc = Apollo.gql`
    fragment AgeGroupFREKP on DirSportingEventAgeGroup {
  id
  minAge
  maxAge
  clsAgeGroup {
    registryNumber
    value: id
    label: fullName
    shortName
  }
}
    `;
export const AgeGroupEkpFragmentDoc = Apollo.gql`
    fragment AgeGroupEKP on DirSportingEvent {
  id
  ageGroups {
    ...AgeGroupFREKP
  }
}
    ${AgeGroupFrekpFragmentDoc}`;
export const CallAthleteNotifyFragmentFragmentDoc = Apollo.gql`
    fragment CallAthleteNotifyFragment on DirSportingEvent {
  id
  name
  statuses(orderBy: createdAt_DESC) {
    id
    value {
      id
      name
    }
    isActive
  }
  dirNationalTeams(where: {archive: null}) {
    id
    dirRegion {
      label: fullName
    }
  }
  athleteCallNotifications {
    id
    document {
      createdAt
      title
      date
      number
      file {
        name
        path
      }
    }
    teams {
      id
      ditNationalTeam {
        id
        dirRegion {
          label: fullName
        }
      }
      isNotified
      isNotificationRequired
    }
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const CompetitionsResultsFragmentFragmentDoc = Apollo.gql`
    fragment CompetitionsResultsFragment on DirSportingEvent {
  id
  name
  venue
  object
  dirCalendar {
    id
    fullName
  }
  clsEventStages {
    id
    fullName
  }
  sports {
    id
    dirSport {
      id
      fullName
    }
  }
  statuses(orderBy: createdAt_DESC) {
    id
    value {
      id
      name
    }
    isActive
  }
  archive {
    ...ArchiveStatus
  }
  programTypes {
    id
    minAge
    maxAge
    start
    clsSportCategory {
      id
      fullName
    }
    resultDocuments {
      id
      title
      number
      date
      clsType {
        value: id
        label: fullName
      }
      file {
        id
        path
      }
    }
    clsAgeGroups {
      value: id
      label: fullName
      shortName
    }
    discipline {
      value: id
      label: name
    }
  }
  dirNationalTeams(orderBy: score_ASC) {
    id
    email
    emailRecipients {
      value: id
      label: email
    }
    athletesCall
    dirRegion {
      value: id
      label: fullName
      shortName
    }
    university {
      id
      name
    }
    manTeamQuota
    womanTeamQuota
    supportTeamQuota
    statuses(orderBy: createdAt_DESC) {
      value {
        id
        name
      }
      isActive
    }
    status {
      value: id
      label: name
    }
    score
    point
    athleteGroups {
      id
      athletes {
        id
        dirAthlete {
          id
          dirPerson {
            id
            firstname
            lastname
            patronymic
            registryNumber
            birthday
            isMale
          }
          competetionResult {
            id
            point
            pointTo
            result
            score
            completedRank
            sportingEventProgramType {
              id
              clsAgeGroups {
                value: id
              }
            }
          }
        }
      }
    }
  }
  referees {
    id
    dirReferee {
      id
      dirPerson {
        id
        firstname
        lastname
        patronymic
      }
    }
    clsRefereePosition {
      id
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const AthleteCompetitionResultFragmentDoc = Apollo.gql`
    fragment AthleteCompetitionResult on DirAthleteCompetitionResult {
  id
  point
  pointTo
  result
  score
  completedRank
  dirAthlete {
    id
    dirPerson {
      id
      registryNumber
      firstname
      lastname
      patronymic
      birthday
    }
  }
  sportingEventProgramType {
    id
  }
  noResultReason {
    id
    fullName
  }
}
    `;
export const DocumentsFragmentEventFragmentDoc = Apollo.gql`
    fragment DocumentsFragmentEvent on DirSportingEvent {
  sports {
    id
    dirSport {
      value: id
      label: fullName
    }
  }
  competitionReglaments {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
    createdAt
  }
  competitionProtocols {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
    createdAt
  }
  commissionAdmissionDecisions {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
    createdAt
  }
  competitionFinalProtocols {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
    createdAt
  }
  mainJuryReports {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
    createdAt
  }
}
    `;
export const EventProtocolDocFragmentFragmentDoc = Apollo.gql`
    fragment EventProtocolDocFragment on DirSportingEvent {
  id
  name
  statuses(orderBy: createdAt_DESC) {
    id
    value {
      id
      name
    }
    isActive
  }
  archive {
    ...ArchiveStatus
  }
  credentialsCommitteeProtocolDocuments {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const MainInfoFragmentFragmentDoc = Apollo.gql`
    fragment MainInfoFragment on DirSportingEvent {
  id
  ekp
  name
  venue
  object
  startDate
  endDate
  ageGroupMale {
    clsAgeGroup {
      registryNumber
    }
  }
  ageGroupFemale {
    clsAgeGroup {
      registryNumber
    }
  }
  daysBeforeStartToAddProtocols
  sportQualificationMaleNotLower {
    registryNumber
  }
  clsMinRang {
    registryNumber
  }
  sportQualificationFemaleNotLower {
    registryNumber
  }
  clsSportingQualificationNotGreater {
    registryNumber
  }
  dirCalendar {
    registryNumber
    value: id
    label: fullName
    shortName
  }
  dirRegion {
    value: id
    label: fullName
  }
  dirFederalDistricts {
    value: id
    label: fullName
  }
  sports {
    dirSport {
      ageGroups {
        clsAgeGroup {
          fullName
        }
        minAge
        maxAge
      }
    }
  }
  clsEventStages {
    value: id
    registryNumber
    label: fullName
  }
  clsEventCategories {
    value: id
    registryNumber
    label: fullName
    fullName
  }
  status {
    label: name
    value: id
  }
  statuses(orderBy: createdAt_DESC) {
    id
    value {
      id
      name
    }
    createdAt
    createdBy {
      id
      name
      email
      regular {
        id
        firstname
        lastname
        patronymic
        phone
        address
        dirOrganization {
          id
          name
          legalAddress
        }
      }
    }
    comment
    file {
      id
      name
      path
    }
    isActive
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const RefereesEventFragmentFragmentDoc = Apollo.gql`
    fragment RefereesEventFragment on DirSportingEvent {
  id
  membersQuota
  name
  statuses(orderBy: createdAt_DESC) {
    id
    value {
      id
      name
    }
    isActive
  }
  refereesQuota
  refereeStatuses(orderBy: createdAt_DESC) {
    id
    createdAt
    updatedBy {
      id
      name
      email
      regular {
        id
        firstname
        lastname
        patronymic
        phone
        address
        dirOrganization {
          id
          name
          legalAddress
        }
      }
    }
    comment
    value {
      id
      name
    }
    file {
      id
      name
      path
    }
  }
  nonresidentRefereesQuota
  referees {
    id
    isNonresident
    clsRefereePosition {
      value: id
      label: fullName
      shortName
    }
    dirReferee {
      id
      sports {
        id
        dirSport {
          value: id
        }
        categories(orderBy: validUntil_DESC) {
          id
          validUntil
          isEndless
          clsRefereeCategory {
            id
            fullName
          }
          dirDocuments {
            id
            title
            clsType {
              value: id
              label: fullName
            }
            file {
              id
              name
              path
            }
          }
        }
      }
      dirPerson {
        id
        firstname
        lastname
        patronymic
        email
        residenceCity
        addresses {
          place
        }
        phones {
          ...Phone
        }
        isRussia
        dirRegion {
          id
          fullName
        }
        dirCountry {
          id
          fullName
        }
        dirForeignCity {
          id
          fullName
        }
      }
    }
  }
  sports {
    id
    dirSport {
      value: id
      label: fullName
    }
  }
}
    ${PhoneFragmentDoc}`;
export const RefereeListFragmentDoc = Apollo.gql`
    fragment RefereeList on DirSportingEventReferee {
  id
  isNonresident
  clsRefereePosition {
    value: id
    label: fullName
    shortName
  }
  dirReferee {
    id
    sports {
      id
      dirSport {
        value: id
      }
      categories(orderBy: validUntil_DESC) {
        id
        validUntil
        isEndless
        clsRefereeCategory {
          id
          fullName
        }
        dirDocuments {
          id
          title
          clsType {
            value: id
            label: fullName
          }
          file {
            id
            name
            path
          }
        }
      }
    }
    dirPerson {
      id
      firstname
      lastname
      patronymic
      email
      residenceCity
      addresses {
        place
      }
      phones {
        ...Phone
      }
      isRussia
      dirRegion {
        id
        fullName
      }
      dirCountry {
        id
        fullName
      }
      dirForeignCity {
        id
        fullName
      }
    }
  }
}
    ${PhoneFragmentDoc}`;
export const SportingEventRefereesListFragmentDoc = Apollo.gql`
    fragment SportingEventRefereesList on DirSportingEvent {
  id
  venue
  ekp
  name
  startDate
  endDate
  sports {
    id
    dirSport {
      id
    }
  }
  referees(orderBy: isPrimary_DESC) {
    id
    isNonresident
    isPrimary
    clsRefereePosition {
      id
      registryNumber
      fullName
      shortName
    }
    dirReferee {
      id
      sports {
        id
        dirSport {
          id
        }
        categories(orderBy: validUntil_DESC) {
          id
          validUntil
          isEndless
          clsRefereeCategory {
            id
            fullName
            shortName
          }
        }
      }
      dirPerson {
        id
        firstname
        lastname
        patronymic
        residenceCity
        email
        phones {
          ...Phone
        }
        dirRegion {
          id
          fullName
        }
      }
    }
  }
}
    ${PhoneFragmentDoc}`;
export const SportingEventFragmentDoc = Apollo.gql`
    fragment SportingEvent on DirSportingEvent {
  id
  name
  properNoun
  venue
  ekp
  object
  startDate
  endDate
  registryNumber
  oldRegistryNumber
  membersQuota
  nonresidentRefereesQuota
  refereesQuota
  ageGroup
  athletesTrainersRefereesSpecialistsCount
  minManAge
  maxManAge
  minManBirthYear
  maxManBirthYear
  minWomanAge
  maxWomanAge
  minWomanBirthYear
  maxWomanBirthYear
  athletesQuotaNotCorrespondingByAge
  athletesQuotaNotCorrespondingByQualification
  isOrganizerOrParticipant
  isAgeRestricted
  isSportingQualificationRangeOrSpecificValues
  isRussia
  sportQualificationFemaleNotLower {
    label: fullName
    value: id
    order
  }
  sportQualificationMaleNotLower {
    label: fullName
    value: id
    order
  }
  ageGroupMale {
    id
    clsAgeGroup {
      value: id
      label: fullName
    }
    minAge
    maxAge
  }
  ageGroupFemale {
    id
    clsAgeGroup {
      value: id
      label: fullName
    }
    minAge
    maxAge
  }
  dirRegions(orderBy: fullName_ASC) {
    label: fullName
    value: id
  }
  countries {
    id
    dirCountry {
      label: fullName
      value: id
    }
    dirForeignCities {
      country: dirCountry {
        id
      }
      label: fullName
      value: id
    }
  }
  isImportant
  isOlympicGamesPreparation
  federalBudgetFinancing
  includingTrainingActivities
  organizers {
    value: id
    label: name
    shortName
  }
  clsEventCategories {
    value: id
    registryNumber
    label: fullName
    fullName
  }
  clsEventStages {
    value: id
    registryNumber
    label: fullName
    fullName
  }
  clsAvailableSportingQualifications {
    value: id
    label: fullName
    order
  }
  clsMinRang {
    value: id
    label: fullName
    order
  }
  clsSportingQualificationNotGreater {
    value: id
    label: fullName
    order
  }
  quantity {
    id
    participantAthletesTotal
    refereesTotal
    staffTotal
    audienceTotal
  }
  cost {
    id
    planRUB
    planRUBperPerson
    currentPlanRUB
    currentPlanRUBperPerson
    factRUB
    factRUBperPerson
    planUSD
    planUSDperPerson
    currentPlanUSD
    currentPlanUSDperPerson
    factUSD
    factUSDperPerson
    planDate
    currentPlanDate
    factDate
    year
    month
    clsFinancingTypes {
      value: id
      label: fullName
    }
  }
  quota {
    id
    athletesMan
    athletesWoman
    trainers
    referees
    administration
    medicalStaff
    specialists
    teams
    clsTeamComposition {
      value: id
      label: fullName
    }
    clsParticipatingOrgCategories {
      value: id
      label: fullName
    }
    clsTeamComposition {
      value: id
      label: fullName
    }
  }
  clsDirectionality {
    value: registryNumber
    label: fullName
  }
  clsDivisions {
    value: id
    label: fullName
  }
  clsEventStages {
    value: id
    label: fullName
    fullName
  }
  clsSummarizingType {
    value: registryNumber
    label: fullName
  }
  parent {
    value: id
    registryNumber
    label: name
    startDate
    endDate
    dirNationalTeams {
      id
      dirRegion {
        id
      }
    }
    sports {
      id
      dirSport {
        value: id
        label: fullName
      }
      disciplineGroups(orderBy: name_ASC) {
        value: id
        label: name
      }
    }
  }
  children {
    value: id
    registryNumber
    label: name
    startDate
    endDate
    dirNationalTeams {
      id
      dirRegion {
        id
      }
    }
    sports {
      id
      dirSport {
        value: id
        label: fullName
      }
      disciplineGroups(orderBy: name_ASC) {
        value: id
        label: name
      }
    }
  }
  comment
  archive {
    ...ArchiveStatus
  }
  statuses(orderBy: createdAt_DESC) {
    value {
      id
      name
    }
    createdAt
    createdBy {
      id
      name
      email
      regular {
        id
        firstname
        lastname
        patronymic
        phone
        address
        dirOrganization {
          id
          name
          legalAddress
        }
      }
    }
    comment
    file {
      id
      name
      path
    }
    isActive
  }
  status {
    value: id
    label: name
  }
  statusHistory {
    id
    date
    user {
      id
      name
      email
      updatedBy {
        id
        name
        email
        regular {
          id
          firstname
          lastname
          patronymic
          phone
        }
      }
    }
    status {
      name
    }
  }
  ageGroups {
    id
    minAge
    maxAge
    clsAgeGroup {
      label: fullName
      value: id
      shortName
    }
  }
  sports {
    id
    dirSport {
      value: id
      label: fullName
      ageGroups {
        id
        minAge
        maxAge
        clsAgeGroup {
          label: fullName
          value: id
        }
        participationRequirements {
          id
          clsCalendarCategory {
            fullName
            id
          }
          clsRanksAndAwards {
            value: id
            label: fullName
          }
        }
      }
      disciplines(orderBy: name_ASC) {
        typeSport: dirSport {
          id
        }
        value: id
        label: name
      }
    }
    disciplines(orderBy: name_ASC) {
      typeSport: dirSport {
        id
      }
      value: id
      label: name
    }
    disciplineGroups(orderBy: name_ASC) {
      typeSport: dirSport {
        id
      }
      value: id
      label: name
      disciplines(orderBy: name_ASC) {
        value: id
        label: name
      }
    }
  }
  dirCalendar {
    registryNumber
    value: id
    label: fullName
    shortName
    clsCalendarCategory {
      id
      fullName
    }
  }
  competitionCalendar(orderBy: day_ASC) {
    id
    day
    time
    discipline {
      value: id
      label: name
      code
    }
    members
  }
  programTypes {
    id
    minAge
    maxAge
    start
    resultsDocument {
      id
      title
      number
      date
      clsType {
        value: id
        label: fullName
      }
      file {
        id
        path
      }
    }
    clsAgeGroups {
      value: id
      label: fullName
      shortName
    }
    clsSportCategory {
      value: id
      label: fullName
      shortName
    }
    discipline {
      value: id
      label: name
    }
  }
  dirNationalTeams(orderBy: score_ASC) {
    id
    email
    emailRecipients {
      value: id
      label: email
    }
    athletesCall
    dirRegion {
      value: id
      label: fullName
      shortName
    }
    manTeamQuota
    womanTeamQuota
    supportTeamQuota
    athleteQuotaByGender
    athleteTotalQuota
    statuses(orderBy: createdAt_DESC) {
      value {
        id
        name
      }
      isActive
    }
    status {
      value: id
      label: name
    }
    score
    point
    athleteGroups {
      id
      athletes {
        id
        dirAthlete {
          id
          dirPerson {
            id
            firstname
            lastname
            patronymic
            registryNumber
            birthday
            isMale
          }
          competetionResult {
            id
            point
            result
            completedRank
            sportingEventProgramType {
              id
              clsAgeGroups {
                value: id
              }
            }
          }
        }
      }
    }
    specialists {
      id
    }
    medics {
      id
    }
    trainers {
      id
    }
    delegationHeads {
      id
    }
    archive {
      id
      comment
      reason
    }
  }
  competitionReglaments {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
  }
  finalProtocolDocuments {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
  }
  competitionProtocols {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
  }
  commissionAdmissionDecisions {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
  }
  competitionFinalProtocols {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
  }
  mainJuryReports {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
  }
  refereeCollegiumDocuments {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
  }
  credentialsCommitteeProtocolDocuments {
    id
    title
    number
    date
    clsType {
      value: id
      label: fullName
    }
    file {
      id
      path
    }
  }
  competitionProtocolFiles {
    id
    name
    path
  }
  refereeStatuses(orderBy: createdAt_DESC) {
    value {
      id
      name
    }
    createdAt
    createdBy {
      id
      name
      email
      regular {
        id
        firstname
        lastname
        patronymic
        phone
        address
        dirOrganization {
          id
          name
          legalAddress
        }
      }
    }
    comment
    file {
      id
      name
      path
    }
    isActive
  }
  referees {
    id
    isPrimary
    isNonresident
    clsRefereePosition {
      value: id
      label: fullName
      shortName
    }
    dirReferee {
      id
      sports {
        id
        dirSport {
          value: id
        }
        categories(orderBy: validUntil_DESC) {
          id
          validUntil
          isEndless
          clsRefereeCategory {
            id
            fullName
          }
          dirDocuments {
            id
            title
            clsType {
              value: id
              label: fullName
            }
            file {
              id
              name
              path
            }
          }
        }
      }
      dirPerson {
        id
        firstname
        lastname
        patronymic
        email
        residenceCity
        addresses {
          place
        }
        phones {
          ...Phone
        }
        isRussia
        dirRegion {
          id
          fullName
        }
        dirCountry {
          id
          fullName
        }
        dirForeignCity {
          id
          fullName
        }
      }
    }
  }
  requests(orderBy: createdAt_DESC) {
    id
    createdAt
    updatedAt
    type {
      id
      name
    }
    statuses(orderBy: createdAt_DESC) {
      id
      isActive
      value {
        id
        name
      }
    }
  }
  changes {
    id
    name
    requests(orderBy: createdAt_DESC) {
      id
      createdAt
      type {
        id
        name
      }
      statuses(orderBy: createdAt_DESC) {
        id
        value {
          id
          name
        }
      }
    }
  }
}
    ${ArchiveStatusFragmentDoc}
${PhoneFragmentDoc}`;
export const LightSportingEventFragmentDoc = Apollo.gql`
    fragment LightSportingEvent on DirSportingEvent {
  id
  registryNumber
  oldRegistryNumber
  startDate
  endDate
  ekp
  name
  venue
  dirCalendar {
    id
    shortName
  }
  organizers {
    value: id
    label: name
    shortName
  }
  status {
    value: id
    label: name
  }
  statuses(orderBy: createdAt_DESC) {
    id
    value {
      id
      name
    }
    isActive
  }
  dirNationalTeams(where: {archive: null}, orderBy: score_ASC) {
    id
    manTeamQuota
    womanTeamQuota
    supportTeamQuota
    athleteGroups {
      id
      athletes(where: {entranceStatus_not: false}) {
        id
      }
    }
    specialists(where: {entranceStatus_not: false}) {
      id
    }
    delegationHeads(where: {entranceStatus_not: false}) {
      id
    }
    medics(where: {entranceStatus_not: false}) {
      id
    }
    trainers(where: {entranceStatus_not: false}) {
      id
    }
    archive {
      id
    }
  }
  sports {
    id
    dirSport {
      value: id
      label: fullName
    }
    disciplines(orderBy: name_ASC) {
      value: id
      label: name
    }
    disciplineGroups(orderBy: name_ASC) {
      value: id
      label: name
    }
  }
  requests(orderBy: createdAt_DESC) {
    id
    createdAt
    updatedAt
    type {
      id
      name
    }
    statuses(orderBy: createdAt_DESC) {
      id
      isActive
      value {
        id
        name
      }
    }
  }
  changes {
    id
    name
    requests(orderBy: createdAt_DESC) {
      id
      createdAt
      type {
        id
        name
      }
      statuses(orderBy: createdAt_DESC) {
        id
        value {
          id
          name
        }
      }
    }
  }
}
    `;
export const TeamInfoFragmentFragmentDoc = Apollo.gql`
    fragment TeamInfoFragment on DirSportingEvent {
  id
  membersQuota
  name
  statuses(orderBy: createdAt_DESC) {
    id
    value {
      id
      name
    }
    isActive
  }
  archive {
    ...ArchiveStatus
  }
  dirCalendar {
    id
    clsCalendarCategory {
      id
      registryNumber
    }
  }
  dirNationalTeams(orderBy: score_ASC) {
    id
    email
    emailRecipients {
      value: id
      label: email
    }
    athletesCall
    dirRegion {
      value: id
      label: fullName
      shortName
    }
    manTeamQuota
    athleteQuotaByGender
    athleteTotalQuota
    womanTeamQuota
    supportTeamQuota
    statuses(orderBy: createdAt_DESC) {
      value {
        id
        name
      }
      isActive
    }
    status {
      value: id
      label: name
    }
    university {
      value: id
      label: name
    }
    score
    point
    athleteGroups {
      id
      athletes {
        id
        entranceStatus
        dirAthlete {
          id
          dirPerson {
            id
            firstname
            lastname
            patronymic
            registryNumber
            birthday
            isMale
          }
          competetionResult {
            id
            point
            result
            completedRank
            sportingEventProgramType {
              id
              clsAgeGroups {
                value: id
              }
            }
          }
        }
      }
    }
    specialists {
      id
      entranceStatus
      admissionDate
    }
    medics {
      id
      entranceStatus
      admissionDate
    }
    trainers {
      id
      entranceStatus
      admissionDate
    }
    delegationHeads {
      id
      entranceStatus
      admissionDate
    }
    archive {
      id
      comment
      reason
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const UnArchivedTeamInfoFragmentFragmentDoc = Apollo.gql`
    fragment UnArchivedTeamInfoFragment on DirSportingEvent {
  id
  membersQuota
  name
  statuses(orderBy: createdAt_DESC) {
    id
    value {
      id
      name
    }
    isActive
  }
  archive {
    ...ArchiveStatus
  }
  dirCalendar {
    id
    clsCalendarCategory {
      id
      registryNumber
    }
  }
  dirNationalTeams(orderBy: score_ASC, where: {archive: null}) {
    id
    email
    emailRecipients {
      value: id
      label: email
    }
    athletesCall
    dirRegion {
      value: id
      label: fullName
      shortName
    }
    manTeamQuota
    athleteQuotaByGender
    athleteTotalQuota
    womanTeamQuota
    supportTeamQuota
    statuses(orderBy: createdAt_DESC) {
      value {
        id
        name
      }
      isActive
    }
    status {
      value: id
      label: name
    }
    university {
      value: id
      label: name
    }
    score
    point
    athleteGroups {
      id
      athletes {
        id
        entranceStatus
        dirAthlete {
          id
          dirPerson {
            id
            firstname
            lastname
            patronymic
            registryNumber
            birthday
            isMale
          }
          competetionResult {
            id
            point
            result
            completedRank
            sportingEventProgramType {
              id
              clsAgeGroups {
                value: id
              }
            }
          }
        }
      }
    }
    specialists {
      id
      entranceStatus
      admissionDate
    }
    medics {
      id
      entranceStatus
      admissionDate
    }
    trainers {
      id
      entranceStatus
      admissionDate
    }
    delegationHeads {
      id
      entranceStatus
      admissionDate
    }
    archive {
      id
      comment
      reason
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const TrainerFrFragmentDoc = Apollo.gql`
    fragment TrainerFR on DirTrainer {
  id
  registryNumber
  archive {
    ...ArchiveStatus
  }
  dirPerson {
    ...PersonFR
    additionalRegions {
      id
      dirRegion {
        id
        fullName
      }
    }
    dirCountry {
      label: fullName
    }
    dirForeignCity {
      dirCountry {
        label: fullName
      }
      label: fullName
    }
  }
  sports {
    id
    dirSport {
      value: id
      label: fullName
    }
    archive {
      ...ArchiveStatus
    }
    organizations {
      id
      clsPosition {
        value: id
        label: fullName
      }
      dirOrganization {
        value: id
        label: name
      }
      employmentDate
      dismissalDate
    }
  }
}
    ${ArchiveStatusFragmentDoc}
${PersonFrFragmentDoc}`;
export const TrainerListFragmentDoc = Apollo.gql`
    fragment TrainerList on DirTrainer {
  id
  registryNumber
  archive {
    id
  }
  dirPerson {
    ...PersonFR
  }
  sports {
    id
    dirSport {
      id
      fullName
    }
    organizations {
      id
      dismissalDate
      dirOrganization {
        id
        name
      }
    }
  }
}
    ${PersonFrFragmentDoc}`;
export const AgeGroupFrFragmentDoc = Apollo.gql`
    fragment AgeGroupFR on DirSportAgeGroup {
  id
  minAge
  maxAge
  archive {
    ...ArchiveStatus
  }
  clsAgeGroup {
    value: id
    label: fullName
  }
  participationRequirements {
    id
    clsCalendarCategory {
      label: fullName
      shortLabel: shortName
      value: id
    }
    clsRanksAndAwards {
      label: fullName
      shortLabel: shortName
      value: id
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const AgeGroupFragmentDoc = Apollo.gql`
    fragment AgeGroup on DirSport {
  id
  ageGroups {
    ...AgeGroupFR
  }
}
    ${AgeGroupFrFragmentDoc}`;
export const BaseRegionFragmentDoc = Apollo.gql`
    fragment BaseRegion on DirSport {
  baseRegions {
    id
    dirRegion {
      value: id
      label: shortName
    }
    startDate
    endDate
  }
}
    `;
export const DisciplinesFragmentDoc = Apollo.gql`
    fragment Disciplines on DirSport {
  id
  disciplines(orderBy: name_ASC) {
    id
    name
    code
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const DisciplineGroupFragmentDoc = Apollo.gql`
    fragment DisciplineGroup on DirSport {
  id
  disciplineGroups(orderBy: name_ASC) {
    id
    name
    disciplines(orderBy: name_ASC) {
      value: id
      label: name
    }
    archive {
      ...ArchiveStatus
    }
  }
  disciplines(orderBy: name_ASC) {
    value: id
    label: name
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const MainInfoFragmentDoc = Apollo.gql`
    fragment MainInfo on DirSport {
  id
  fullName
  shortName
  evsk
  registryNumber
  archive {
    comment
    id
    reason
    date
  }
}
    `;
export const SportTrainingStageFragmentDoc = Apollo.gql`
    fragment SportTrainingStage on DirSportTrainingStage {
  id
  clsTrainingStage {
    label: fullName
    value: id
  }
  minManAge
  maxManAge
  minWomanAge
  maxWomanAge
  minGroupSize
  maxGroupSize
  clsRanksAndAwards {
    label: fullName
    value: id
  }
}
    `;
export const UserFrFragmentDoc = Apollo.gql`
    fragment UserFR on User {
  id
  name
  email
  role {
    value: name
    label: description
  }
  personProfile {
    id
    dirRegion {
      value: id
      label: fullName
    }
  }
  isActive
  regular {
    address
    birthday
    firstname
    lastname
    patronymic
    isMale
    position
    phone
    dirOrganization {
      value: id
      label: name
    }
  }
}
    `;
export const AthletesExpelDocument = Apollo.gql`
    mutation AthletesExpel($athletesID: [UUID!]!, $sportID: UUID!, $organizationID: UUID!, $trainerID: UUID, $trainingStageID: UUID!, $exclusionDate: DateTime!) {
  athletesExpel(athletesID: $athletesID, sportID: $sportID, organizationID: $organizationID, trainerID: $trainerID, trainingStageID: $trainingStageID, exclusionDate: $exclusionDate)
}
    `;
export type AthletesExpelMutationFn = Apollo.MutationFunction<AthletesExpelMutation, AthletesExpelMutationVariables>;

/**
 * __useAthletesExpelMutation__
 *
 * To run a mutation, you first call `useAthletesExpelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthletesExpelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athletesExpelMutation, { data, loading, error }] = useAthletesExpelMutation({
 *   variables: {
 *      athletesID: // value for 'athletesID'
 *      sportID: // value for 'sportID'
 *      organizationID: // value for 'organizationID'
 *      trainerID: // value for 'trainerID'
 *      trainingStageID: // value for 'trainingStageID'
 *      exclusionDate: // value for 'exclusionDate'
 *   },
 * });
 */
export function useAthletesExpelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AthletesExpelMutation, AthletesExpelMutationVariables>) {
        return ApolloReactHooks.useMutation<AthletesExpelMutation, AthletesExpelMutationVariables>(AthletesExpelDocument, baseOptions);
      }
export type AthletesExpelMutationHookResult = ReturnType<typeof useAthletesExpelMutation>;
export type AthletesExpelMutationResult = Apollo.MutationResult<AthletesExpelMutation>;
export type AthletesExpelMutationOptions = Apollo.BaseMutationOptions<AthletesExpelMutation, AthletesExpelMutationVariables>;
export const AthletesExpelWithMoveToAnotherTrainerDocument = Apollo.gql`
    mutation AthletesExpelWithMoveToAnotherTrainer($athletesID: [UUID!]!, $sportID: UUID!, $organizationID: UUID!, $fromTrainerID: UUID, $toTrainerID: UUID!, $trainingStageID: UUID!, $exclusionDate: DateTime!) {
  athletesExpelWithMoveToAnotherTrainer(athletesID: $athletesID, sportID: $sportID, organizationID: $organizationID, fromTrainerID: $fromTrainerID, toTrainerID: $toTrainerID, trainingStageID: $trainingStageID, exclusionDate: $exclusionDate)
}
    `;
export type AthletesExpelWithMoveToAnotherTrainerMutationFn = Apollo.MutationFunction<AthletesExpelWithMoveToAnotherTrainerMutation, AthletesExpelWithMoveToAnotherTrainerMutationVariables>;

/**
 * __useAthletesExpelWithMoveToAnotherTrainerMutation__
 *
 * To run a mutation, you first call `useAthletesExpelWithMoveToAnotherTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthletesExpelWithMoveToAnotherTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athletesExpelWithMoveToAnotherTrainerMutation, { data, loading, error }] = useAthletesExpelWithMoveToAnotherTrainerMutation({
 *   variables: {
 *      athletesID: // value for 'athletesID'
 *      sportID: // value for 'sportID'
 *      organizationID: // value for 'organizationID'
 *      fromTrainerID: // value for 'fromTrainerID'
 *      toTrainerID: // value for 'toTrainerID'
 *      trainingStageID: // value for 'trainingStageID'
 *      exclusionDate: // value for 'exclusionDate'
 *   },
 * });
 */
export function useAthletesExpelWithMoveToAnotherTrainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AthletesExpelWithMoveToAnotherTrainerMutation, AthletesExpelWithMoveToAnotherTrainerMutationVariables>) {
        return ApolloReactHooks.useMutation<AthletesExpelWithMoveToAnotherTrainerMutation, AthletesExpelWithMoveToAnotherTrainerMutationVariables>(AthletesExpelWithMoveToAnotherTrainerDocument, baseOptions);
      }
export type AthletesExpelWithMoveToAnotherTrainerMutationHookResult = ReturnType<typeof useAthletesExpelWithMoveToAnotherTrainerMutation>;
export type AthletesExpelWithMoveToAnotherTrainerMutationResult = Apollo.MutationResult<AthletesExpelWithMoveToAnotherTrainerMutation>;
export type AthletesExpelWithMoveToAnotherTrainerMutationOptions = Apollo.BaseMutationOptions<AthletesExpelWithMoveToAnotherTrainerMutation, AthletesExpelWithMoveToAnotherTrainerMutationVariables>;
export const AthletesExpelWithMoveToAnotherTrainingStageDocument = Apollo.gql`
    mutation AthletesExpelWithMoveToAnotherTrainingStage($athletesID: [UUID!]!, $sportID: UUID!, $organizationID: UUID!, $fromTrainingStageID: UUID!, $toTrainingStageID: UUID!, $trainerID: UUID, $exclusionDate: DateTime!) {
  athletesExpelWithMoveToAnotherTrainingStage(athletesID: $athletesID, sportID: $sportID, organizationID: $organizationID, trainerID: $trainerID, fromTrainingStageID: $fromTrainingStageID, toTrainingStageID: $toTrainingStageID, exclusionDate: $exclusionDate)
}
    `;
export type AthletesExpelWithMoveToAnotherTrainingStageMutationFn = Apollo.MutationFunction<AthletesExpelWithMoveToAnotherTrainingStageMutation, AthletesExpelWithMoveToAnotherTrainingStageMutationVariables>;

/**
 * __useAthletesExpelWithMoveToAnotherTrainingStageMutation__
 *
 * To run a mutation, you first call `useAthletesExpelWithMoveToAnotherTrainingStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthletesExpelWithMoveToAnotherTrainingStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athletesExpelWithMoveToAnotherTrainingStageMutation, { data, loading, error }] = useAthletesExpelWithMoveToAnotherTrainingStageMutation({
 *   variables: {
 *      athletesID: // value for 'athletesID'
 *      sportID: // value for 'sportID'
 *      organizationID: // value for 'organizationID'
 *      fromTrainingStageID: // value for 'fromTrainingStageID'
 *      toTrainingStageID: // value for 'toTrainingStageID'
 *      trainerID: // value for 'trainerID'
 *      exclusionDate: // value for 'exclusionDate'
 *   },
 * });
 */
export function useAthletesExpelWithMoveToAnotherTrainingStageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AthletesExpelWithMoveToAnotherTrainingStageMutation, AthletesExpelWithMoveToAnotherTrainingStageMutationVariables>) {
        return ApolloReactHooks.useMutation<AthletesExpelWithMoveToAnotherTrainingStageMutation, AthletesExpelWithMoveToAnotherTrainingStageMutationVariables>(AthletesExpelWithMoveToAnotherTrainingStageDocument, baseOptions);
      }
export type AthletesExpelWithMoveToAnotherTrainingStageMutationHookResult = ReturnType<typeof useAthletesExpelWithMoveToAnotherTrainingStageMutation>;
export type AthletesExpelWithMoveToAnotherTrainingStageMutationResult = Apollo.MutationResult<AthletesExpelWithMoveToAnotherTrainingStageMutation>;
export type AthletesExpelWithMoveToAnotherTrainingStageMutationOptions = Apollo.BaseMutationOptions<AthletesExpelWithMoveToAnotherTrainingStageMutation, AthletesExpelWithMoveToAnotherTrainingStageMutationVariables>;
export const AthletesExpelWithMoveToSelfTrainingDocument = Apollo.gql`
    mutation AthletesExpelWithMoveToSelfTraining($athletesID: [UUID!]!, $sportID: UUID!, $organizationID: UUID!, $trainerID: UUID!, $trainingStageID: UUID!, $exclusionDate: DateTime!) {
  athletesExpelWithMoveToSelfTraining(athletesID: $athletesID, sportID: $sportID, organizationID: $organizationID, trainerID: $trainerID, trainingStageID: $trainingStageID, exclusionDate: $exclusionDate)
}
    `;
export type AthletesExpelWithMoveToSelfTrainingMutationFn = Apollo.MutationFunction<AthletesExpelWithMoveToSelfTrainingMutation, AthletesExpelWithMoveToSelfTrainingMutationVariables>;

/**
 * __useAthletesExpelWithMoveToSelfTrainingMutation__
 *
 * To run a mutation, you first call `useAthletesExpelWithMoveToSelfTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthletesExpelWithMoveToSelfTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athletesExpelWithMoveToSelfTrainingMutation, { data, loading, error }] = useAthletesExpelWithMoveToSelfTrainingMutation({
 *   variables: {
 *      athletesID: // value for 'athletesID'
 *      sportID: // value for 'sportID'
 *      organizationID: // value for 'organizationID'
 *      trainerID: // value for 'trainerID'
 *      trainingStageID: // value for 'trainingStageID'
 *      exclusionDate: // value for 'exclusionDate'
 *   },
 * });
 */
export function useAthletesExpelWithMoveToSelfTrainingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AthletesExpelWithMoveToSelfTrainingMutation, AthletesExpelWithMoveToSelfTrainingMutationVariables>) {
        return ApolloReactHooks.useMutation<AthletesExpelWithMoveToSelfTrainingMutation, AthletesExpelWithMoveToSelfTrainingMutationVariables>(AthletesExpelWithMoveToSelfTrainingDocument, baseOptions);
      }
export type AthletesExpelWithMoveToSelfTrainingMutationHookResult = ReturnType<typeof useAthletesExpelWithMoveToSelfTrainingMutation>;
export type AthletesExpelWithMoveToSelfTrainingMutationResult = Apollo.MutationResult<AthletesExpelWithMoveToSelfTrainingMutation>;
export type AthletesExpelWithMoveToSelfTrainingMutationOptions = Apollo.BaseMutationOptions<AthletesExpelWithMoveToSelfTrainingMutation, AthletesExpelWithMoveToSelfTrainingMutationVariables>;
export const UpdateDirAthleteDocument = Apollo.gql`
    mutation updateDirAthlete($id: UUID!, $data: DirAthleteUpdateInput!) {
  updateDirAthlete(where: {id: $id}, data: $data) {
    ...AthleteFR
  }
}
    ${AthleteFrFragmentDoc}`;
export type UpdateDirAthleteMutationFn = Apollo.MutationFunction<UpdateDirAthleteMutation, UpdateDirAthleteMutationVariables>;

/**
 * __useUpdateDirAthleteMutation__
 *
 * To run a mutation, you first call `useUpdateDirAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirAthleteMutation, { data, loading, error }] = useUpdateDirAthleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDirAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirAthleteMutation, UpdateDirAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirAthleteMutation, UpdateDirAthleteMutationVariables>(UpdateDirAthleteDocument, baseOptions);
      }
export type UpdateDirAthleteMutationHookResult = ReturnType<typeof useUpdateDirAthleteMutation>;
export type UpdateDirAthleteMutationResult = Apollo.MutationResult<UpdateDirAthleteMutation>;
export type UpdateDirAthleteMutationOptions = Apollo.BaseMutationOptions<UpdateDirAthleteMutation, UpdateDirAthleteMutationVariables>;
export const CreateAthleteDocument = Apollo.gql`
    mutation CreateAthlete($idPerson: UUID!, $sportType: UUID!) {
  createDirAthlete(data: {dirPerson: {connect: {id: $idPerson}}, sports: {create: {dirSport: {connect: {id: $sportType}}}}}) {
    ...AthleteFR
  }
}
    ${AthleteFrFragmentDoc}`;
export type CreateAthleteMutationFn = Apollo.MutationFunction<CreateAthleteMutation, CreateAthleteMutationVariables>;

/**
 * __useCreateAthleteMutation__
 *
 * To run a mutation, you first call `useCreateAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAthleteMutation, { data, loading, error }] = useCreateAthleteMutation({
 *   variables: {
 *      idPerson: // value for 'idPerson'
 *      sportType: // value for 'sportType'
 *   },
 * });
 */
export function useCreateAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAthleteMutation, CreateAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAthleteMutation, CreateAthleteMutationVariables>(CreateAthleteDocument, baseOptions);
      }
export type CreateAthleteMutationHookResult = ReturnType<typeof useCreateAthleteMutation>;
export type CreateAthleteMutationResult = Apollo.MutationResult<CreateAthleteMutation>;
export type CreateAthleteMutationOptions = Apollo.BaseMutationOptions<CreateAthleteMutation, CreateAthleteMutationVariables>;
export const AthleteConnectDisciplineToSportDocument = Apollo.gql`
    mutation AthleteConnectDisciplineToSport($idSport: UUID!, $idDiscipline: UUID) {
  updateDirAthleteSport(where: {id: $idSport}, data: {disciplines: {connect: [{id: $idDiscipline}]}}) {
    disciplines {
      value: id
      label: name
    }
  }
}
    `;
export type AthleteConnectDisciplineToSportMutationFn = Apollo.MutationFunction<AthleteConnectDisciplineToSportMutation, AthleteConnectDisciplineToSportMutationVariables>;

/**
 * __useAthleteConnectDisciplineToSportMutation__
 *
 * To run a mutation, you first call `useAthleteConnectDisciplineToSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthleteConnectDisciplineToSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athleteConnectDisciplineToSportMutation, { data, loading, error }] = useAthleteConnectDisciplineToSportMutation({
 *   variables: {
 *      idSport: // value for 'idSport'
 *      idDiscipline: // value for 'idDiscipline'
 *   },
 * });
 */
export function useAthleteConnectDisciplineToSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AthleteConnectDisciplineToSportMutation, AthleteConnectDisciplineToSportMutationVariables>) {
        return ApolloReactHooks.useMutation<AthleteConnectDisciplineToSportMutation, AthleteConnectDisciplineToSportMutationVariables>(AthleteConnectDisciplineToSportDocument, baseOptions);
      }
export type AthleteConnectDisciplineToSportMutationHookResult = ReturnType<typeof useAthleteConnectDisciplineToSportMutation>;
export type AthleteConnectDisciplineToSportMutationResult = Apollo.MutationResult<AthleteConnectDisciplineToSportMutation>;
export type AthleteConnectDisciplineToSportMutationOptions = Apollo.BaseMutationOptions<AthleteConnectDisciplineToSportMutation, AthleteConnectDisciplineToSportMutationVariables>;
export const AthleteDisconnectDisciplineToSportDocument = Apollo.gql`
    mutation AthleteDisconnectDisciplineToSport($idSport: UUID!, $idDiscipline: UUID) {
  updateDirAthleteSport(where: {id: $idSport}, data: {disciplines: {disconnect: [{id: $idDiscipline}]}}) {
    disciplines {
      value: id
      label: name
    }
  }
}
    `;
export type AthleteDisconnectDisciplineToSportMutationFn = Apollo.MutationFunction<AthleteDisconnectDisciplineToSportMutation, AthleteDisconnectDisciplineToSportMutationVariables>;

/**
 * __useAthleteDisconnectDisciplineToSportMutation__
 *
 * To run a mutation, you first call `useAthleteDisconnectDisciplineToSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthleteDisconnectDisciplineToSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athleteDisconnectDisciplineToSportMutation, { data, loading, error }] = useAthleteDisconnectDisciplineToSportMutation({
 *   variables: {
 *      idSport: // value for 'idSport'
 *      idDiscipline: // value for 'idDiscipline'
 *   },
 * });
 */
export function useAthleteDisconnectDisciplineToSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AthleteDisconnectDisciplineToSportMutation, AthleteDisconnectDisciplineToSportMutationVariables>) {
        return ApolloReactHooks.useMutation<AthleteDisconnectDisciplineToSportMutation, AthleteDisconnectDisciplineToSportMutationVariables>(AthleteDisconnectDisciplineToSportDocument, baseOptions);
      }
export type AthleteDisconnectDisciplineToSportMutationHookResult = ReturnType<typeof useAthleteDisconnectDisciplineToSportMutation>;
export type AthleteDisconnectDisciplineToSportMutationResult = Apollo.MutationResult<AthleteDisconnectDisciplineToSportMutation>;
export type AthleteDisconnectDisciplineToSportMutationOptions = Apollo.BaseMutationOptions<AthleteDisconnectDisciplineToSportMutation, AthleteDisconnectDisciplineToSportMutationVariables>;
export const AthleteConnectDisciplineGroupToSportDocument = Apollo.gql`
    mutation AthleteConnectDisciplineGroupToSport($idSport: UUID!, $idDisciplineGroup: UUID) {
  updateDirAthleteSport(where: {id: $idSport}, data: {disciplineGroups: {connect: [{id: $idDisciplineGroup}]}}) {
    disciplineGroups {
      value: id
      label: name
    }
  }
}
    `;
export type AthleteConnectDisciplineGroupToSportMutationFn = Apollo.MutationFunction<AthleteConnectDisciplineGroupToSportMutation, AthleteConnectDisciplineGroupToSportMutationVariables>;

/**
 * __useAthleteConnectDisciplineGroupToSportMutation__
 *
 * To run a mutation, you first call `useAthleteConnectDisciplineGroupToSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthleteConnectDisciplineGroupToSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athleteConnectDisciplineGroupToSportMutation, { data, loading, error }] = useAthleteConnectDisciplineGroupToSportMutation({
 *   variables: {
 *      idSport: // value for 'idSport'
 *      idDisciplineGroup: // value for 'idDisciplineGroup'
 *   },
 * });
 */
export function useAthleteConnectDisciplineGroupToSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AthleteConnectDisciplineGroupToSportMutation, AthleteConnectDisciplineGroupToSportMutationVariables>) {
        return ApolloReactHooks.useMutation<AthleteConnectDisciplineGroupToSportMutation, AthleteConnectDisciplineGroupToSportMutationVariables>(AthleteConnectDisciplineGroupToSportDocument, baseOptions);
      }
export type AthleteConnectDisciplineGroupToSportMutationHookResult = ReturnType<typeof useAthleteConnectDisciplineGroupToSportMutation>;
export type AthleteConnectDisciplineGroupToSportMutationResult = Apollo.MutationResult<AthleteConnectDisciplineGroupToSportMutation>;
export type AthleteConnectDisciplineGroupToSportMutationOptions = Apollo.BaseMutationOptions<AthleteConnectDisciplineGroupToSportMutation, AthleteConnectDisciplineGroupToSportMutationVariables>;
export const AthleteDisconnectDisciplineGroupFromSportDocument = Apollo.gql`
    mutation AthleteDisconnectDisciplineGroupFromSport($idSport: UUID!, $idDisciplineGroup: UUID) {
  updateDirAthleteSport(where: {id: $idSport}, data: {disciplineGroups: {disconnect: [{id: $idDisciplineGroup}]}}) {
    disciplineGroups {
      value: id
      label: name
    }
  }
}
    `;
export type AthleteDisconnectDisciplineGroupFromSportMutationFn = Apollo.MutationFunction<AthleteDisconnectDisciplineGroupFromSportMutation, AthleteDisconnectDisciplineGroupFromSportMutationVariables>;

/**
 * __useAthleteDisconnectDisciplineGroupFromSportMutation__
 *
 * To run a mutation, you first call `useAthleteDisconnectDisciplineGroupFromSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthleteDisconnectDisciplineGroupFromSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athleteDisconnectDisciplineGroupFromSportMutation, { data, loading, error }] = useAthleteDisconnectDisciplineGroupFromSportMutation({
 *   variables: {
 *      idSport: // value for 'idSport'
 *      idDisciplineGroup: // value for 'idDisciplineGroup'
 *   },
 * });
 */
export function useAthleteDisconnectDisciplineGroupFromSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AthleteDisconnectDisciplineGroupFromSportMutation, AthleteDisconnectDisciplineGroupFromSportMutationVariables>) {
        return ApolloReactHooks.useMutation<AthleteDisconnectDisciplineGroupFromSportMutation, AthleteDisconnectDisciplineGroupFromSportMutationVariables>(AthleteDisconnectDisciplineGroupFromSportDocument, baseOptions);
      }
export type AthleteDisconnectDisciplineGroupFromSportMutationHookResult = ReturnType<typeof useAthleteDisconnectDisciplineGroupFromSportMutation>;
export type AthleteDisconnectDisciplineGroupFromSportMutationResult = Apollo.MutationResult<AthleteDisconnectDisciplineGroupFromSportMutation>;
export type AthleteDisconnectDisciplineGroupFromSportMutationOptions = Apollo.BaseMutationOptions<AthleteDisconnectDisciplineGroupFromSportMutation, AthleteDisconnectDisciplineGroupFromSportMutationVariables>;
export const UpdateAthleteSportDocument = Apollo.gql`
    mutation UpdateAthleteSport($id: UUID!, $data: DirAthleteSportUpdateInput!) {
  updateDirAthleteSport(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdateAthleteSportMutationFn = Apollo.MutationFunction<UpdateAthleteSportMutation, UpdateAthleteSportMutationVariables>;

/**
 * __useUpdateAthleteSportMutation__
 *
 * To run a mutation, you first call `useUpdateAthleteSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAthleteSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAthleteSportMutation, { data, loading, error }] = useUpdateAthleteSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAthleteSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAthleteSportMutation, UpdateAthleteSportMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAthleteSportMutation, UpdateAthleteSportMutationVariables>(UpdateAthleteSportDocument, baseOptions);
      }
export type UpdateAthleteSportMutationHookResult = ReturnType<typeof useUpdateAthleteSportMutation>;
export type UpdateAthleteSportMutationResult = Apollo.MutationResult<UpdateAthleteSportMutation>;
export type UpdateAthleteSportMutationOptions = Apollo.BaseMutationOptions<UpdateAthleteSportMutation, UpdateAthleteSportMutationVariables>;
export const AthleteUpdateAmpluaToSportDocument = Apollo.gql`
    mutation AthleteUpdateAmpluaToSport($idSport: UUID!, $amplua: String) {
  updateDirAthleteSport(where: {id: $idSport}, data: {role: $amplua}) {
    id
  }
}
    `;
export type AthleteUpdateAmpluaToSportMutationFn = Apollo.MutationFunction<AthleteUpdateAmpluaToSportMutation, AthleteUpdateAmpluaToSportMutationVariables>;

/**
 * __useAthleteUpdateAmpluaToSportMutation__
 *
 * To run a mutation, you first call `useAthleteUpdateAmpluaToSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthleteUpdateAmpluaToSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athleteUpdateAmpluaToSportMutation, { data, loading, error }] = useAthleteUpdateAmpluaToSportMutation({
 *   variables: {
 *      idSport: // value for 'idSport'
 *      amplua: // value for 'amplua'
 *   },
 * });
 */
export function useAthleteUpdateAmpluaToSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AthleteUpdateAmpluaToSportMutation, AthleteUpdateAmpluaToSportMutationVariables>) {
        return ApolloReactHooks.useMutation<AthleteUpdateAmpluaToSportMutation, AthleteUpdateAmpluaToSportMutationVariables>(AthleteUpdateAmpluaToSportDocument, baseOptions);
      }
export type AthleteUpdateAmpluaToSportMutationHookResult = ReturnType<typeof useAthleteUpdateAmpluaToSportMutation>;
export type AthleteUpdateAmpluaToSportMutationResult = Apollo.MutationResult<AthleteUpdateAmpluaToSportMutation>;
export type AthleteUpdateAmpluaToSportMutationOptions = Apollo.BaseMutationOptions<AthleteUpdateAmpluaToSportMutation, AthleteUpdateAmpluaToSportMutationVariables>;
export const AddTypeSportsToAthleteDocument = Apollo.gql`
    mutation AddTypeSportsToAthlete($id: UUID!, $sportId: UUID!) {
  updateDirAthlete(where: {id: $id}, data: {sports: {create: {dirSport: {connect: {id: $sportId}}}}}) {
    ...AthleteFR
  }
}
    ${AthleteFrFragmentDoc}`;
export type AddTypeSportsToAthleteMutationFn = Apollo.MutationFunction<AddTypeSportsToAthleteMutation, AddTypeSportsToAthleteMutationVariables>;

/**
 * __useAddTypeSportsToAthleteMutation__
 *
 * To run a mutation, you first call `useAddTypeSportsToAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTypeSportsToAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTypeSportsToAthleteMutation, { data, loading, error }] = useAddTypeSportsToAthleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useAddTypeSportsToAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTypeSportsToAthleteMutation, AddTypeSportsToAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTypeSportsToAthleteMutation, AddTypeSportsToAthleteMutationVariables>(AddTypeSportsToAthleteDocument, baseOptions);
      }
export type AddTypeSportsToAthleteMutationHookResult = ReturnType<typeof useAddTypeSportsToAthleteMutation>;
export type AddTypeSportsToAthleteMutationResult = Apollo.MutationResult<AddTypeSportsToAthleteMutation>;
export type AddTypeSportsToAthleteMutationOptions = Apollo.BaseMutationOptions<AddTypeSportsToAthleteMutation, AddTypeSportsToAthleteMutationVariables>;
export const TypeSportAthleteArchiveDocument = Apollo.gql`
    mutation TypeSportAthleteArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirAthleteSport(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportAthleteArchiveMutationFn = Apollo.MutationFunction<TypeSportAthleteArchiveMutation, TypeSportAthleteArchiveMutationVariables>;

/**
 * __useTypeSportAthleteArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportAthleteArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAthleteArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAthleteArchiveMutation, { data, loading, error }] = useTypeSportAthleteArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useTypeSportAthleteArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportAthleteArchiveMutation, TypeSportAthleteArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportAthleteArchiveMutation, TypeSportAthleteArchiveMutationVariables>(TypeSportAthleteArchiveDocument, baseOptions);
      }
export type TypeSportAthleteArchiveMutationHookResult = ReturnType<typeof useTypeSportAthleteArchiveMutation>;
export type TypeSportAthleteArchiveMutationResult = Apollo.MutationResult<TypeSportAthleteArchiveMutation>;
export type TypeSportAthleteArchiveMutationOptions = Apollo.BaseMutationOptions<TypeSportAthleteArchiveMutation, TypeSportAthleteArchiveMutationVariables>;
export const TypeSportAthleteUnArchiveDocument = Apollo.gql`
    mutation TypeSportAthleteUnArchive($id: UUID!) {
  updateDirAthleteSport(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportAthleteUnArchiveMutationFn = Apollo.MutationFunction<TypeSportAthleteUnArchiveMutation, TypeSportAthleteUnArchiveMutationVariables>;

/**
 * __useTypeSportAthleteUnArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportAthleteUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAthleteUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAthleteUnArchiveMutation, { data, loading, error }] = useTypeSportAthleteUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportAthleteUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportAthleteUnArchiveMutation, TypeSportAthleteUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportAthleteUnArchiveMutation, TypeSportAthleteUnArchiveMutationVariables>(TypeSportAthleteUnArchiveDocument, baseOptions);
      }
export type TypeSportAthleteUnArchiveMutationHookResult = ReturnType<typeof useTypeSportAthleteUnArchiveMutation>;
export type TypeSportAthleteUnArchiveMutationResult = Apollo.MutationResult<TypeSportAthleteUnArchiveMutation>;
export type TypeSportAthleteUnArchiveMutationOptions = Apollo.BaseMutationOptions<TypeSportAthleteUnArchiveMutation, TypeSportAthleteUnArchiveMutationVariables>;
export const AddAthleteOrganizationDocument = Apollo.gql`
    mutation AddAthleteOrganization($idSport: UUID!, $idDirOrganization: UUID!, $idDirTrainer: DirTrainerCreateOneInput, $clsTrainingStage: UUID!, $trainingStart: DateTime!, $isSelfTraining: Boolean) {
  updateDirAthleteSport(where: {id: $idSport}, data: {organizations: {create: {dirOrganization: {connect: {id: $idDirOrganization}}, clsTrainingStage: {connect: {id: $clsTrainingStage}}, dirTrainer: $idDirTrainer, isSelfTraining: $isSelfTraining, trainingStart: $trainingStart}}}) {
    id
  }
}
    `;
export type AddAthleteOrganizationMutationFn = Apollo.MutationFunction<AddAthleteOrganizationMutation, AddAthleteOrganizationMutationVariables>;

/**
 * __useAddAthleteOrganizationMutation__
 *
 * To run a mutation, you first call `useAddAthleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthleteOrganizationMutation, { data, loading, error }] = useAddAthleteOrganizationMutation({
 *   variables: {
 *      idSport: // value for 'idSport'
 *      idDirOrganization: // value for 'idDirOrganization'
 *      idDirTrainer: // value for 'idDirTrainer'
 *      clsTrainingStage: // value for 'clsTrainingStage'
 *      trainingStart: // value for 'trainingStart'
 *      isSelfTraining: // value for 'isSelfTraining'
 *   },
 * });
 */
export function useAddAthleteOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAthleteOrganizationMutation, AddAthleteOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAthleteOrganizationMutation, AddAthleteOrganizationMutationVariables>(AddAthleteOrganizationDocument, baseOptions);
      }
export type AddAthleteOrganizationMutationHookResult = ReturnType<typeof useAddAthleteOrganizationMutation>;
export type AddAthleteOrganizationMutationResult = Apollo.MutationResult<AddAthleteOrganizationMutation>;
export type AddAthleteOrganizationMutationOptions = Apollo.BaseMutationOptions<AddAthleteOrganizationMutation, AddAthleteOrganizationMutationVariables>;
export const UpdateAthleteOrganizationDocument = Apollo.gql`
    mutation UpdateAthleteOrganization($id: UUID!, $idDirOrganization: UUID, $idDirTrainer: DirTrainerUpdateOneInput, $clsTrainingStage: UUID, $trainingStart: DateTime, $exclusionDate: DateTime, $isSelfTraining: Boolean) {
  updateDirAthleteSportOrganization(where: {id: $id}, data: {dirOrganization: {connect: {id: $idDirOrganization}}, dirTrainer: $idDirTrainer, clsTrainingStage: {connect: {id: $clsTrainingStage}}, isSelfTraining: $isSelfTraining, trainingStart: $trainingStart, exclusionDate: $exclusionDate}) {
    id
  }
}
    `;
export type UpdateAthleteOrganizationMutationFn = Apollo.MutationFunction<UpdateAthleteOrganizationMutation, UpdateAthleteOrganizationMutationVariables>;

/**
 * __useUpdateAthleteOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateAthleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAthleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAthleteOrganizationMutation, { data, loading, error }] = useUpdateAthleteOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idDirOrganization: // value for 'idDirOrganization'
 *      idDirTrainer: // value for 'idDirTrainer'
 *      clsTrainingStage: // value for 'clsTrainingStage'
 *      trainingStart: // value for 'trainingStart'
 *      exclusionDate: // value for 'exclusionDate'
 *      isSelfTraining: // value for 'isSelfTraining'
 *   },
 * });
 */
export function useUpdateAthleteOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAthleteOrganizationMutation, UpdateAthleteOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAthleteOrganizationMutation, UpdateAthleteOrganizationMutationVariables>(UpdateAthleteOrganizationDocument, baseOptions);
      }
export type UpdateAthleteOrganizationMutationHookResult = ReturnType<typeof useUpdateAthleteOrganizationMutation>;
export type UpdateAthleteOrganizationMutationResult = Apollo.MutationResult<UpdateAthleteOrganizationMutation>;
export type UpdateAthleteOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateAthleteOrganizationMutation, UpdateAthleteOrganizationMutationVariables>;
export const DeleteAthleteOrganizationDocument = Apollo.gql`
    mutation DeleteAthleteOrganization($id: UUID!, $exclusionDate: DateTime!) {
  updateDirAthleteSportOrganization(where: {id: $id}, data: {exclusionDate: $exclusionDate}) {
    id
  }
}
    `;
export type DeleteAthleteOrganizationMutationFn = Apollo.MutationFunction<DeleteAthleteOrganizationMutation, DeleteAthleteOrganizationMutationVariables>;

/**
 * __useDeleteAthleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteAthleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAthleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAthleteOrganizationMutation, { data, loading, error }] = useDeleteAthleteOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      exclusionDate: // value for 'exclusionDate'
 *   },
 * });
 */
export function useDeleteAthleteOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAthleteOrganizationMutation, DeleteAthleteOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAthleteOrganizationMutation, DeleteAthleteOrganizationMutationVariables>(DeleteAthleteOrganizationDocument, baseOptions);
      }
export type DeleteAthleteOrganizationMutationHookResult = ReturnType<typeof useDeleteAthleteOrganizationMutation>;
export type DeleteAthleteOrganizationMutationResult = Apollo.MutationResult<DeleteAthleteOrganizationMutation>;
export type DeleteAthleteOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteAthleteOrganizationMutation, DeleteAthleteOrganizationMutationVariables>;
export const AthleteRankAndAwardArchiveDocument = Apollo.gql`
    mutation AthleteRankAndAwardArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirAthleteSportRankAndAward(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type AthleteRankAndAwardArchiveMutationFn = Apollo.MutationFunction<AthleteRankAndAwardArchiveMutation, AthleteRankAndAwardArchiveMutationVariables>;

/**
 * __useAthleteRankAndAwardArchiveMutation__
 *
 * To run a mutation, you first call `useAthleteRankAndAwardArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthleteRankAndAwardArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athleteRankAndAwardArchiveMutation, { data, loading, error }] = useAthleteRankAndAwardArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useAthleteRankAndAwardArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AthleteRankAndAwardArchiveMutation, AthleteRankAndAwardArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<AthleteRankAndAwardArchiveMutation, AthleteRankAndAwardArchiveMutationVariables>(AthleteRankAndAwardArchiveDocument, baseOptions);
      }
export type AthleteRankAndAwardArchiveMutationHookResult = ReturnType<typeof useAthleteRankAndAwardArchiveMutation>;
export type AthleteRankAndAwardArchiveMutationResult = Apollo.MutationResult<AthleteRankAndAwardArchiveMutation>;
export type AthleteRankAndAwardArchiveMutationOptions = Apollo.BaseMutationOptions<AthleteRankAndAwardArchiveMutation, AthleteRankAndAwardArchiveMutationVariables>;
export const AthleteRankAndAwardUnArchiveDocument = Apollo.gql`
    mutation AthleteRankAndAwardUnArchive($id: UUID!) {
  updateDirAthleteSportRankAndAward(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type AthleteRankAndAwardUnArchiveMutationFn = Apollo.MutationFunction<AthleteRankAndAwardUnArchiveMutation, AthleteRankAndAwardUnArchiveMutationVariables>;

/**
 * __useAthleteRankAndAwardUnArchiveMutation__
 *
 * To run a mutation, you first call `useAthleteRankAndAwardUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthleteRankAndAwardUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athleteRankAndAwardUnArchiveMutation, { data, loading, error }] = useAthleteRankAndAwardUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthleteRankAndAwardUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AthleteRankAndAwardUnArchiveMutation, AthleteRankAndAwardUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<AthleteRankAndAwardUnArchiveMutation, AthleteRankAndAwardUnArchiveMutationVariables>(AthleteRankAndAwardUnArchiveDocument, baseOptions);
      }
export type AthleteRankAndAwardUnArchiveMutationHookResult = ReturnType<typeof useAthleteRankAndAwardUnArchiveMutation>;
export type AthleteRankAndAwardUnArchiveMutationResult = Apollo.MutationResult<AthleteRankAndAwardUnArchiveMutation>;
export type AthleteRankAndAwardUnArchiveMutationOptions = Apollo.BaseMutationOptions<AthleteRankAndAwardUnArchiveMutation, AthleteRankAndAwardUnArchiveMutationVariables>;
export const AthletesDocument = Apollo.gql`
    query Athletes($first: Int!, $skip: Int!, $filter: DirAthleteWhereInput) {
  dirAthletes(first: $first, skip: $skip, where: $filter) {
    ...AthleteListFR
  }
}
    ${AthleteListFrFragmentDoc}`;

/**
 * __useAthletesQuery__
 *
 * To run a query within a React component, call `useAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthletesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesQuery, AthletesQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesQuery, AthletesQueryVariables>(AthletesDocument, baseOptions);
      }
export function useAthletesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesQuery, AthletesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesQuery, AthletesQueryVariables>(AthletesDocument, baseOptions);
        }
export type AthletesQueryHookResult = ReturnType<typeof useAthletesQuery>;
export type AthletesLazyQueryHookResult = ReturnType<typeof useAthletesLazyQuery>;
export type AthletesQueryResult = Apollo.QueryResult<AthletesQuery, AthletesQueryVariables>;
export const AthleteSportsDocument = Apollo.gql`
    query AthleteSports($id: UUID!, $filter: DirAthleteSportWhereInput) {
  dirAthlete(where: {id: $id}) {
    sports(where: $filter) {
      ...AthleteSportInfo
    }
  }
}
    ${AthleteSportInfoFragmentDoc}`;

/**
 * __useAthleteSportsQuery__
 *
 * To run a query within a React component, call `useAthleteSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteSportsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthleteSportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteSportsQuery, AthleteSportsQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteSportsQuery, AthleteSportsQueryVariables>(AthleteSportsDocument, baseOptions);
      }
export function useAthleteSportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteSportsQuery, AthleteSportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteSportsQuery, AthleteSportsQueryVariables>(AthleteSportsDocument, baseOptions);
        }
export type AthleteSportsQueryHookResult = ReturnType<typeof useAthleteSportsQuery>;
export type AthleteSportsLazyQueryHookResult = ReturnType<typeof useAthleteSportsLazyQuery>;
export type AthleteSportsQueryResult = Apollo.QueryResult<AthleteSportsQuery, AthleteSportsQueryVariables>;
export const AthletesCountDocument = Apollo.gql`
    query AthletesCount($filter: DirAthleteWhereInput) {
  count: dirAthletesCount(where: $filter)
}
    `;

/**
 * __useAthletesCountQuery__
 *
 * To run a query within a React component, call `useAthletesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthletesCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesCountQuery, AthletesCountQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesCountQuery, AthletesCountQueryVariables>(AthletesCountDocument, baseOptions);
      }
export function useAthletesCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesCountQuery, AthletesCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesCountQuery, AthletesCountQueryVariables>(AthletesCountDocument, baseOptions);
        }
export type AthletesCountQueryHookResult = ReturnType<typeof useAthletesCountQuery>;
export type AthletesCountLazyQueryHookResult = ReturnType<typeof useAthletesCountLazyQuery>;
export type AthletesCountQueryResult = Apollo.QueryResult<AthletesCountQuery, AthletesCountQueryVariables>;
export const AthleteDocument = Apollo.gql`
    query Athlete($filter: DirAthleteWhereUniqueInput!) {
  dirAthlete(where: $filter) {
    ...AthleteFR
  }
}
    ${AthleteFrFragmentDoc}`;

/**
 * __useAthleteQuery__
 *
 * To run a query within a React component, call `useAthleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthleteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteQuery, AthleteQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteQuery, AthleteQueryVariables>(AthleteDocument, baseOptions);
      }
export function useAthleteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteQuery, AthleteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteQuery, AthleteQueryVariables>(AthleteDocument, baseOptions);
        }
export type AthleteQueryHookResult = ReturnType<typeof useAthleteQuery>;
export type AthleteLazyQueryHookResult = ReturnType<typeof useAthleteLazyQuery>;
export type AthleteQueryResult = Apollo.QueryResult<AthleteQuery, AthleteQueryVariables>;
export const AthletesFilteredDocument = Apollo.gql`
    query AthletesFiltered($filter: DirAthleteWhereInput) {
  dirAthletes(where: $filter) {
    ...AthleteFR
  }
}
    ${AthleteFrFragmentDoc}`;

/**
 * __useAthletesFilteredQuery__
 *
 * To run a query within a React component, call `useAthletesFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesFilteredQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthletesFilteredQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesFilteredQuery, AthletesFilteredQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesFilteredQuery, AthletesFilteredQueryVariables>(AthletesFilteredDocument, baseOptions);
      }
export function useAthletesFilteredLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesFilteredQuery, AthletesFilteredQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesFilteredQuery, AthletesFilteredQueryVariables>(AthletesFilteredDocument, baseOptions);
        }
export type AthletesFilteredQueryHookResult = ReturnType<typeof useAthletesFilteredQuery>;
export type AthletesFilteredLazyQueryHookResult = ReturnType<typeof useAthletesFilteredLazyQuery>;
export type AthletesFilteredQueryResult = Apollo.QueryResult<AthletesFilteredQuery, AthletesFilteredQueryVariables>;
export const AthleteCompetitionDocument = Apollo.gql`
    query AthleteCompetition($id: UUID!, $sportId: UUID) {
  dirAthlete(where: {id: $id}) {
    athleteId: id
    competetionResult(where: {dirAthlete: {sports_some: {dirSport: {id: $sportId}}}}) {
      id
      result
      point
      pointTo
      score
      sportingEventProgramType {
        id
        start
        minAge
        maxAge
        event {
          id
          name
          venue
          startDate
          endDate
          clsEventCategories {
            value: id
            label: fullName
          }
        }
        clsAgeGroups {
          value: id
          label: fullName
        }
        discipline {
          value: id
          label: name
        }
      }
    }
  }
}
    `;

/**
 * __useAthleteCompetitionQuery__
 *
 * To run a query within a React component, call `useAthleteCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteCompetitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useAthleteCompetitionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteCompetitionQuery, AthleteCompetitionQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteCompetitionQuery, AthleteCompetitionQueryVariables>(AthleteCompetitionDocument, baseOptions);
      }
export function useAthleteCompetitionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteCompetitionQuery, AthleteCompetitionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteCompetitionQuery, AthleteCompetitionQueryVariables>(AthleteCompetitionDocument, baseOptions);
        }
export type AthleteCompetitionQueryHookResult = ReturnType<typeof useAthleteCompetitionQuery>;
export type AthleteCompetitionLazyQueryHookResult = ReturnType<typeof useAthleteCompetitionLazyQuery>;
export type AthleteCompetitionQueryResult = Apollo.QueryResult<AthleteCompetitionQuery, AthleteCompetitionQueryVariables>;
export const AthleteCompetitionsArrayDocument = Apollo.gql`
    query AthleteCompetitionsArray($filter: DirAthleteCompetitionResultWhereInput) {
  dirAthleteCompetitionResults(orderBy: point_ASC, where: $filter) {
    id
    point
    pointTo
    result
    completedRank
    dirAthlete {
      id
      dirPerson {
        id
        registryNumber
        firstname
        lastname
        patronymic
        birthday
        dirRegion {
          id
          fullName
        }
        dirForeignCity {
          id
          fullName
        }
        dirCountry {
          id
          fullName
        }
      }
    }
    sportingEventProgramType {
      id
      start
      minAge
      maxAge
      resultsDocument {
        id
        title
        number
        date
        file {
          id
          path
        }
      }
      event {
        id
      }
      clsAgeGroups {
        value: id
        label: fullName
      }
      discipline {
        value: id
        label: name
      }
    }
  }
}
    `;

/**
 * __useAthleteCompetitionsArrayQuery__
 *
 * To run a query within a React component, call `useAthleteCompetitionsArrayQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteCompetitionsArrayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteCompetitionsArrayQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthleteCompetitionsArrayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteCompetitionsArrayQuery, AthleteCompetitionsArrayQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteCompetitionsArrayQuery, AthleteCompetitionsArrayQueryVariables>(AthleteCompetitionsArrayDocument, baseOptions);
      }
export function useAthleteCompetitionsArrayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteCompetitionsArrayQuery, AthleteCompetitionsArrayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteCompetitionsArrayQuery, AthleteCompetitionsArrayQueryVariables>(AthleteCompetitionsArrayDocument, baseOptions);
        }
export type AthleteCompetitionsArrayQueryHookResult = ReturnType<typeof useAthleteCompetitionsArrayQuery>;
export type AthleteCompetitionsArrayLazyQueryHookResult = ReturnType<typeof useAthleteCompetitionsArrayLazyQuery>;
export type AthleteCompetitionsArrayQueryResult = Apollo.QueryResult<AthleteCompetitionsArrayQuery, AthleteCompetitionsArrayQueryVariables>;
export const AthleteTeamsDocument = Apollo.gql`
    query AthleteTeams($id: UUID!, $sportId: UUID!) {
  dirNationalTeams(where: {athleteGroups_some: {athletes_some: {dirAthlete: {id: $id}}}, dirSportingEvent: {sports_some: {dirSport: {id: $sportId}}}}) {
    id
    dirSportingEvent {
      id
      name
      startDate
      endDate
    }
    dirRegion {
      id
      fullName
    }
  }
}
    `;

/**
 * __useAthleteTeamsQuery__
 *
 * To run a query within a React component, call `useAthleteTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteTeamsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useAthleteTeamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteTeamsQuery, AthleteTeamsQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteTeamsQuery, AthleteTeamsQueryVariables>(AthleteTeamsDocument, baseOptions);
      }
export function useAthleteTeamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteTeamsQuery, AthleteTeamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteTeamsQuery, AthleteTeamsQueryVariables>(AthleteTeamsDocument, baseOptions);
        }
export type AthleteTeamsQueryHookResult = ReturnType<typeof useAthleteTeamsQuery>;
export type AthleteTeamsLazyQueryHookResult = ReturnType<typeof useAthleteTeamsLazyQuery>;
export type AthleteTeamsQueryResult = Apollo.QueryResult<AthleteTeamsQuery, AthleteTeamsQueryVariables>;
export const AthletesCountByTrainerIdInOrganizationDocument = Apollo.gql`
    query AthletesCountByTrainerIdInOrganization($id: UUID!, $orgID: UUID!) {
  count: dirAthletesCount(where: {sports_some: {organizations_some: {dirOrganization: {id: $orgID}, dirTrainer: {id: $id}, exclusionDate_in: null}}})
}
    `;

/**
 * __useAthletesCountByTrainerIdInOrganizationQuery__
 *
 * To run a query within a React component, call `useAthletesCountByTrainerIdInOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesCountByTrainerIdInOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesCountByTrainerIdInOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      orgID: // value for 'orgID'
 *   },
 * });
 */
export function useAthletesCountByTrainerIdInOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesCountByTrainerIdInOrganizationQuery, AthletesCountByTrainerIdInOrganizationQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesCountByTrainerIdInOrganizationQuery, AthletesCountByTrainerIdInOrganizationQueryVariables>(AthletesCountByTrainerIdInOrganizationDocument, baseOptions);
      }
export function useAthletesCountByTrainerIdInOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesCountByTrainerIdInOrganizationQuery, AthletesCountByTrainerIdInOrganizationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesCountByTrainerIdInOrganizationQuery, AthletesCountByTrainerIdInOrganizationQueryVariables>(AthletesCountByTrainerIdInOrganizationDocument, baseOptions);
        }
export type AthletesCountByTrainerIdInOrganizationQueryHookResult = ReturnType<typeof useAthletesCountByTrainerIdInOrganizationQuery>;
export type AthletesCountByTrainerIdInOrganizationLazyQueryHookResult = ReturnType<typeof useAthletesCountByTrainerIdInOrganizationLazyQuery>;
export type AthletesCountByTrainerIdInOrganizationQueryResult = Apollo.QueryResult<AthletesCountByTrainerIdInOrganizationQuery, AthletesCountByTrainerIdInOrganizationQueryVariables>;
export const SimilarAthletesDocument = Apollo.gql`
    query SimilarAthletes($sportsId: [UUID!], $deniedAthletes: [UUID!], $search: String!, $minBday: DateTime, $isMale: Boolean, $maxBday: DateTime) {
  dirPersonsSimilar(search: $search, threshold: 0.3, first: 10, where: {isMale: $isMale, birthday_lte: $minBday, birthday_gte: $maxBday, athleteProfile: {id_not_in: $deniedAthletes, sports_some: {dirSport: {id_in: $sportsId}}}}) {
    person {
      id
      firstname
      lastname
      patronymic
      registryNumber
      birthday
      athleteProfile {
        id
      }
      dirRegion {
        id
        fullName
      }
      dirCountry {
        id
        fullName
      }
      dirForeignCity {
        id
        fullName
      }
    }
  }
}
    `;

/**
 * __useSimilarAthletesQuery__
 *
 * To run a query within a React component, call `useSimilarAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarAthletesQuery({
 *   variables: {
 *      sportsId: // value for 'sportsId'
 *      deniedAthletes: // value for 'deniedAthletes'
 *      search: // value for 'search'
 *      minBday: // value for 'minBday'
 *      isMale: // value for 'isMale'
 *      maxBday: // value for 'maxBday'
 *   },
 * });
 */
export function useSimilarAthletesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SimilarAthletesQuery, SimilarAthletesQueryVariables>) {
        return ApolloReactHooks.useQuery<SimilarAthletesQuery, SimilarAthletesQueryVariables>(SimilarAthletesDocument, baseOptions);
      }
export function useSimilarAthletesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimilarAthletesQuery, SimilarAthletesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SimilarAthletesQuery, SimilarAthletesQueryVariables>(SimilarAthletesDocument, baseOptions);
        }
export type SimilarAthletesQueryHookResult = ReturnType<typeof useSimilarAthletesQuery>;
export type SimilarAthletesLazyQueryHookResult = ReturnType<typeof useSimilarAthletesLazyQuery>;
export type SimilarAthletesQueryResult = Apollo.QueryResult<SimilarAthletesQuery, SimilarAthletesQueryVariables>;
export const AthletesMiniDocument = Apollo.gql`
    query AthletesMini($filter: DirAthleteWhereInput) {
  dirAthletes(first: 10, where: $filter) {
    id
    dirPerson {
      id
      firstname
      lastname
      patronymic
      registryNumber
      birthday
      dirRegion {
        id
        fullName
      }
      dirCountry {
        id
        fullName
      }
      dirForeignCity {
        id
        fullName
      }
    }
  }
}
    `;

/**
 * __useAthletesMiniQuery__
 *
 * To run a query within a React component, call `useAthletesMiniQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesMiniQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesMiniQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthletesMiniQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesMiniQuery, AthletesMiniQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesMiniQuery, AthletesMiniQueryVariables>(AthletesMiniDocument, baseOptions);
      }
export function useAthletesMiniLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesMiniQuery, AthletesMiniQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesMiniQuery, AthletesMiniQueryVariables>(AthletesMiniDocument, baseOptions);
        }
export type AthletesMiniQueryHookResult = ReturnType<typeof useAthletesMiniQuery>;
export type AthletesMiniLazyQueryHookResult = ReturnType<typeof useAthletesMiniLazyQuery>;
export type AthletesMiniQueryResult = Apollo.QueryResult<AthletesMiniQuery, AthletesMiniQueryVariables>;
export const AthletesSimilarDocument = Apollo.gql`
    query AthletesSimilar($name: String!, $first: Int!, $skip: Int!, $filter: DirAthleteWhereInput) {
  dirPersonsSimilar(search: $name, where: {athleteProfile: $filter}, first: $first, skip: $skip, threshold: 0.3) {
    similarity
    person {
      id
      athleteProfile {
        ...AthleteListFR
      }
    }
  }
}
    ${AthleteListFrFragmentDoc}`;

/**
 * __useAthletesSimilarQuery__
 *
 * To run a query within a React component, call `useAthletesSimilarQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesSimilarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesSimilarQuery({
 *   variables: {
 *      name: // value for 'name'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthletesSimilarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesSimilarQuery, AthletesSimilarQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesSimilarQuery, AthletesSimilarQueryVariables>(AthletesSimilarDocument, baseOptions);
      }
export function useAthletesSimilarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesSimilarQuery, AthletesSimilarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesSimilarQuery, AthletesSimilarQueryVariables>(AthletesSimilarDocument, baseOptions);
        }
export type AthletesSimilarQueryHookResult = ReturnType<typeof useAthletesSimilarQuery>;
export type AthletesSimilarLazyQueryHookResult = ReturnType<typeof useAthletesSimilarLazyQuery>;
export type AthletesSimilarQueryResult = Apollo.QueryResult<AthletesSimilarQuery, AthletesSimilarQueryVariables>;
export const AthletesSimilarCountDocument = Apollo.gql`
    query AthletesSimilarCount($name: String!, $filter: DirAthleteWhereInput) {
  dirPersonsSimilar(search: $name, where: {athleteProfile: $filter}, threshold: 0.3) {
    similarity
    person {
      id
    }
  }
}
    `;

/**
 * __useAthletesSimilarCountQuery__
 *
 * To run a query within a React component, call `useAthletesSimilarCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesSimilarCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesSimilarCountQuery({
 *   variables: {
 *      name: // value for 'name'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthletesSimilarCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesSimilarCountQuery, AthletesSimilarCountQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesSimilarCountQuery, AthletesSimilarCountQueryVariables>(AthletesSimilarCountDocument, baseOptions);
      }
export function useAthletesSimilarCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesSimilarCountQuery, AthletesSimilarCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesSimilarCountQuery, AthletesSimilarCountQueryVariables>(AthletesSimilarCountDocument, baseOptions);
        }
export type AthletesSimilarCountQueryHookResult = ReturnType<typeof useAthletesSimilarCountQuery>;
export type AthletesSimilarCountLazyQueryHookResult = ReturnType<typeof useAthletesSimilarCountLazyQuery>;
export type AthletesSimilarCountQueryResult = Apollo.QueryResult<AthletesSimilarCountQuery, AthletesSimilarCountQueryVariables>;
export const AthletesMedCertDocument = Apollo.gql`
    query AthletesMedCert($id: UUID!) {
  dirAthleteMedCerts(where: {dirAthlete: {id: $id}}) {
    id
    issueDate
    displayNumber
    organizationName
    organizationPhone
    organizationEmail
    organizationAddress
    physicalExaminationDate
    hasRestrictions
    sports {
      dirSport {
        fullName
        disciplineGroups {
          name
        }
      }
      clsTrainingStage {
        shortName
      }
      disciplineGroups {
        name
      }
    }
    validUntil
    isValid
    deletedAt
  }
}
    `;

/**
 * __useAthletesMedCertQuery__
 *
 * To run a query within a React component, call `useAthletesMedCertQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesMedCertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesMedCertQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthletesMedCertQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesMedCertQuery, AthletesMedCertQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesMedCertQuery, AthletesMedCertQueryVariables>(AthletesMedCertDocument, baseOptions);
      }
export function useAthletesMedCertLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesMedCertQuery, AthletesMedCertQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesMedCertQuery, AthletesMedCertQueryVariables>(AthletesMedCertDocument, baseOptions);
        }
export type AthletesMedCertQueryHookResult = ReturnType<typeof useAthletesMedCertQuery>;
export type AthletesMedCertLazyQueryHookResult = ReturnType<typeof useAthletesMedCertLazyQuery>;
export type AthletesMedCertQueryResult = Apollo.QueryResult<AthletesMedCertQuery, AthletesMedCertQueryVariables>;
export const CreateCalendarDocument = Apollo.gql`
    mutation CreateCalendar($data: DirCalendarCreateInput!) {
  createDirCalendar(data: $data) {
    ...Calendar
  }
}
    ${CalendarFragmentDoc}`;
export type CreateCalendarMutationFn = Apollo.MutationFunction<CreateCalendarMutation, CreateCalendarMutationVariables>;

/**
 * __useCreateCalendarMutation__
 *
 * To run a mutation, you first call `useCreateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarMutation, { data, loading, error }] = useCreateCalendarMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCalendarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCalendarMutation, CreateCalendarMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCalendarMutation, CreateCalendarMutationVariables>(CreateCalendarDocument, baseOptions);
      }
export type CreateCalendarMutationHookResult = ReturnType<typeof useCreateCalendarMutation>;
export type CreateCalendarMutationResult = Apollo.MutationResult<CreateCalendarMutation>;
export type CreateCalendarMutationOptions = Apollo.BaseMutationOptions<CreateCalendarMutation, CreateCalendarMutationVariables>;
export const UpdateCalendarDocument = Apollo.gql`
    mutation UpdateCalendar($id: UUID!, $data: DirCalendarUpdateInput!) {
  updateDirCalendar(data: $data, where: {id: $id}) {
    ...Calendar
  }
}
    ${CalendarFragmentDoc}`;
export type UpdateCalendarMutationFn = Apollo.MutationFunction<UpdateCalendarMutation, UpdateCalendarMutationVariables>;

/**
 * __useUpdateCalendarMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarMutation, { data, loading, error }] = useUpdateCalendarMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCalendarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCalendarMutation, UpdateCalendarMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCalendarMutation, UpdateCalendarMutationVariables>(UpdateCalendarDocument, baseOptions);
      }
export type UpdateCalendarMutationHookResult = ReturnType<typeof useUpdateCalendarMutation>;
export type UpdateCalendarMutationResult = Apollo.MutationResult<UpdateCalendarMutation>;
export type UpdateCalendarMutationOptions = Apollo.BaseMutationOptions<UpdateCalendarMutation, UpdateCalendarMutationVariables>;
export const CalendarArchiveDocument = Apollo.gql`
    mutation CalendarArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirCalendar(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type CalendarArchiveMutationFn = Apollo.MutationFunction<CalendarArchiveMutation, CalendarArchiveMutationVariables>;

/**
 * __useCalendarArchiveMutation__
 *
 * To run a mutation, you first call `useCalendarArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarArchiveMutation, { data, loading, error }] = useCalendarArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useCalendarArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalendarArchiveMutation, CalendarArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<CalendarArchiveMutation, CalendarArchiveMutationVariables>(CalendarArchiveDocument, baseOptions);
      }
export type CalendarArchiveMutationHookResult = ReturnType<typeof useCalendarArchiveMutation>;
export type CalendarArchiveMutationResult = Apollo.MutationResult<CalendarArchiveMutation>;
export type CalendarArchiveMutationOptions = Apollo.BaseMutationOptions<CalendarArchiveMutation, CalendarArchiveMutationVariables>;
export const CalendarUnArchiveDocument = Apollo.gql`
    mutation CalendarUnArchive($id: UUID!) {
  updateDirCalendar(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type CalendarUnArchiveMutationFn = Apollo.MutationFunction<CalendarUnArchiveMutation, CalendarUnArchiveMutationVariables>;

/**
 * __useCalendarUnArchiveMutation__
 *
 * To run a mutation, you first call `useCalendarUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarUnArchiveMutation, { data, loading, error }] = useCalendarUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalendarUnArchiveMutation, CalendarUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<CalendarUnArchiveMutation, CalendarUnArchiveMutationVariables>(CalendarUnArchiveDocument, baseOptions);
      }
export type CalendarUnArchiveMutationHookResult = ReturnType<typeof useCalendarUnArchiveMutation>;
export type CalendarUnArchiveMutationResult = Apollo.MutationResult<CalendarUnArchiveMutation>;
export type CalendarUnArchiveMutationOptions = Apollo.BaseMutationOptions<CalendarUnArchiveMutation, CalendarUnArchiveMutationVariables>;
export const CreateDirRefusedRegionDocument = Apollo.gql`
    mutation createDirRefusedRegion($data: DirRefusedRegionCreateInput!) {
  createDirRefusedRegion(data: $data) {
    id
  }
}
    `;
export type CreateDirRefusedRegionMutationFn = Apollo.MutationFunction<CreateDirRefusedRegionMutation, CreateDirRefusedRegionMutationVariables>;

/**
 * __useCreateDirRefusedRegionMutation__
 *
 * To run a mutation, you first call `useCreateDirRefusedRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirRefusedRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirRefusedRegionMutation, { data, loading, error }] = useCreateDirRefusedRegionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDirRefusedRegionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDirRefusedRegionMutation, CreateDirRefusedRegionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDirRefusedRegionMutation, CreateDirRefusedRegionMutationVariables>(CreateDirRefusedRegionDocument, baseOptions);
      }
export type CreateDirRefusedRegionMutationHookResult = ReturnType<typeof useCreateDirRefusedRegionMutation>;
export type CreateDirRefusedRegionMutationResult = Apollo.MutationResult<CreateDirRefusedRegionMutation>;
export type CreateDirRefusedRegionMutationOptions = Apollo.BaseMutationOptions<CreateDirRefusedRegionMutation, CreateDirRefusedRegionMutationVariables>;
export const UpdateDirRefusedRegionDocument = Apollo.gql`
    mutation updateDirRefusedRegion($id: UUID!, $data: DirRefusedRegionUpdateInput!) {
  updateDirRefusedRegion(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdateDirRefusedRegionMutationFn = Apollo.MutationFunction<UpdateDirRefusedRegionMutation, UpdateDirRefusedRegionMutationVariables>;

/**
 * __useUpdateDirRefusedRegionMutation__
 *
 * To run a mutation, you first call `useUpdateDirRefusedRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirRefusedRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirRefusedRegionMutation, { data, loading, error }] = useUpdateDirRefusedRegionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDirRefusedRegionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirRefusedRegionMutation, UpdateDirRefusedRegionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirRefusedRegionMutation, UpdateDirRefusedRegionMutationVariables>(UpdateDirRefusedRegionDocument, baseOptions);
      }
export type UpdateDirRefusedRegionMutationHookResult = ReturnType<typeof useUpdateDirRefusedRegionMutation>;
export type UpdateDirRefusedRegionMutationResult = Apollo.MutationResult<UpdateDirRefusedRegionMutation>;
export type UpdateDirRefusedRegionMutationOptions = Apollo.BaseMutationOptions<UpdateDirRefusedRegionMutation, UpdateDirRefusedRegionMutationVariables>;
export const DeleteDirRefusedRegionDocument = Apollo.gql`
    mutation deleteDirRefusedRegion($id: UUID!) {
  deleteDirRefusedRegion(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteDirRefusedRegionMutationFn = Apollo.MutationFunction<DeleteDirRefusedRegionMutation, DeleteDirRefusedRegionMutationVariables>;

/**
 * __useDeleteDirRefusedRegionMutation__
 *
 * To run a mutation, you first call `useDeleteDirRefusedRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDirRefusedRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDirRefusedRegionMutation, { data, loading, error }] = useDeleteDirRefusedRegionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDirRefusedRegionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDirRefusedRegionMutation, DeleteDirRefusedRegionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDirRefusedRegionMutation, DeleteDirRefusedRegionMutationVariables>(DeleteDirRefusedRegionDocument, baseOptions);
      }
export type DeleteDirRefusedRegionMutationHookResult = ReturnType<typeof useDeleteDirRefusedRegionMutation>;
export type DeleteDirRefusedRegionMutationResult = Apollo.MutationResult<DeleteDirRefusedRegionMutation>;
export type DeleteDirRefusedRegionMutationOptions = Apollo.BaseMutationOptions<DeleteDirRefusedRegionMutation, DeleteDirRefusedRegionMutationVariables>;
export const CreateDirReturnedRegionDocument = Apollo.gql`
    mutation createDirReturnedRegion($data: DirReturnedRegionCreateInput!) {
  createDirReturnedRegion(data: $data) {
    id
  }
}
    `;
export type CreateDirReturnedRegionMutationFn = Apollo.MutationFunction<CreateDirReturnedRegionMutation, CreateDirReturnedRegionMutationVariables>;

/**
 * __useCreateDirReturnedRegionMutation__
 *
 * To run a mutation, you first call `useCreateDirReturnedRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirReturnedRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirReturnedRegionMutation, { data, loading, error }] = useCreateDirReturnedRegionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDirReturnedRegionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDirReturnedRegionMutation, CreateDirReturnedRegionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDirReturnedRegionMutation, CreateDirReturnedRegionMutationVariables>(CreateDirReturnedRegionDocument, baseOptions);
      }
export type CreateDirReturnedRegionMutationHookResult = ReturnType<typeof useCreateDirReturnedRegionMutation>;
export type CreateDirReturnedRegionMutationResult = Apollo.MutationResult<CreateDirReturnedRegionMutation>;
export type CreateDirReturnedRegionMutationOptions = Apollo.BaseMutationOptions<CreateDirReturnedRegionMutation, CreateDirReturnedRegionMutationVariables>;
export const DeleteDirReturnedRegionDocument = Apollo.gql`
    mutation deleteDirReturnedRegion($id: UUID!) {
  deleteDirReturnedRegion(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteDirReturnedRegionMutationFn = Apollo.MutationFunction<DeleteDirReturnedRegionMutation, DeleteDirReturnedRegionMutationVariables>;

/**
 * __useDeleteDirReturnedRegionMutation__
 *
 * To run a mutation, you first call `useDeleteDirReturnedRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDirReturnedRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDirReturnedRegionMutation, { data, loading, error }] = useDeleteDirReturnedRegionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDirReturnedRegionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDirReturnedRegionMutation, DeleteDirReturnedRegionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDirReturnedRegionMutation, DeleteDirReturnedRegionMutationVariables>(DeleteDirReturnedRegionDocument, baseOptions);
      }
export type DeleteDirReturnedRegionMutationHookResult = ReturnType<typeof useDeleteDirReturnedRegionMutation>;
export type DeleteDirReturnedRegionMutationResult = Apollo.MutationResult<DeleteDirReturnedRegionMutation>;
export type DeleteDirReturnedRegionMutationOptions = Apollo.BaseMutationOptions<DeleteDirReturnedRegionMutation, DeleteDirReturnedRegionMutationVariables>;
export const UpdateDirReturnedRegionDocument = Apollo.gql`
    mutation updateDirReturnedRegion($id: UUID!, $data: DirReturnedRegionUpdateInput!) {
  updateDirReturnedRegion(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdateDirReturnedRegionMutationFn = Apollo.MutationFunction<UpdateDirReturnedRegionMutation, UpdateDirReturnedRegionMutationVariables>;

/**
 * __useUpdateDirReturnedRegionMutation__
 *
 * To run a mutation, you first call `useUpdateDirReturnedRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirReturnedRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirReturnedRegionMutation, { data, loading, error }] = useUpdateDirReturnedRegionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDirReturnedRegionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirReturnedRegionMutation, UpdateDirReturnedRegionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirReturnedRegionMutation, UpdateDirReturnedRegionMutationVariables>(UpdateDirReturnedRegionDocument, baseOptions);
      }
export type UpdateDirReturnedRegionMutationHookResult = ReturnType<typeof useUpdateDirReturnedRegionMutation>;
export type UpdateDirReturnedRegionMutationResult = Apollo.MutationResult<UpdateDirReturnedRegionMutation>;
export type UpdateDirReturnedRegionMutationOptions = Apollo.BaseMutationOptions<UpdateDirReturnedRegionMutation, UpdateDirReturnedRegionMutationVariables>;
export const InitiateMailingDocument = Apollo.gql`
    mutation InitiateMailing($calendarId: String!, $email: String!) {
  notificationStageTwo(email: $email, calendarId: $calendarId)
}
    `;
export type InitiateMailingMutationFn = Apollo.MutationFunction<InitiateMailingMutation, InitiateMailingMutationVariables>;

/**
 * __useInitiateMailingMutation__
 *
 * To run a mutation, you first call `useInitiateMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateMailingMutation, { data, loading, error }] = useInitiateMailingMutation({
 *   variables: {
 *      calendarId: // value for 'calendarId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInitiateMailingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InitiateMailingMutation, InitiateMailingMutationVariables>) {
        return ApolloReactHooks.useMutation<InitiateMailingMutation, InitiateMailingMutationVariables>(InitiateMailingDocument, baseOptions);
      }
export type InitiateMailingMutationHookResult = ReturnType<typeof useInitiateMailingMutation>;
export type InitiateMailingMutationResult = Apollo.MutationResult<InitiateMailingMutation>;
export type InitiateMailingMutationOptions = Apollo.BaseMutationOptions<InitiateMailingMutation, InitiateMailingMutationVariables>;
export const CalendarsDocument = Apollo.gql`
    query Calendars($first: Int, $skip: Int, $filter: DirCalendarWhereInput) {
  dirCalendars(first: $first, skip: $skip, where: $filter, orderBy: year_ASC) {
    ...Calendar
  }
  count: dirCalendarsCount(where: $filter)
}
    ${CalendarFragmentDoc}`;

/**
 * __useCalendarsQuery__
 *
 * To run a query within a React component, call `useCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCalendarsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarsQuery, CalendarsQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarsQuery, CalendarsQueryVariables>(CalendarsDocument, baseOptions);
      }
export function useCalendarsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarsQuery, CalendarsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarsQuery, CalendarsQueryVariables>(CalendarsDocument, baseOptions);
        }
export type CalendarsQueryHookResult = ReturnType<typeof useCalendarsQuery>;
export type CalendarsLazyQueryHookResult = ReturnType<typeof useCalendarsLazyQuery>;
export type CalendarsQueryResult = Apollo.QueryResult<CalendarsQuery, CalendarsQueryVariables>;
export const CalendarDocument = Apollo.gql`
    query Calendar($id: UUID!) {
  dirCalendar(where: {id: $id}) {
    ...Calendar
  }
}
    ${CalendarFragmentDoc}`;

/**
 * __useCalendarQuery__
 *
 * To run a query within a React component, call `useCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarQuery, CalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, baseOptions);
      }
export function useCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarQuery, CalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, baseOptions);
        }
export type CalendarQueryHookResult = ReturnType<typeof useCalendarQuery>;
export type CalendarLazyQueryHookResult = ReturnType<typeof useCalendarLazyQuery>;
export type CalendarQueryResult = Apollo.QueryResult<CalendarQuery, CalendarQueryVariables>;
export const CalendarTypesDocument = Apollo.gql`
    query CalendarTypes {
  dirCalendarTypes {
    label: name
    value: id
  }
}
    `;

/**
 * __useCalendarTypesQuery__
 *
 * To run a query within a React component, call `useCalendarTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalendarTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarTypesQuery, CalendarTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarTypesQuery, CalendarTypesQueryVariables>(CalendarTypesDocument, baseOptions);
      }
export function useCalendarTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarTypesQuery, CalendarTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarTypesQuery, CalendarTypesQueryVariables>(CalendarTypesDocument, baseOptions);
        }
export type CalendarTypesQueryHookResult = ReturnType<typeof useCalendarTypesQuery>;
export type CalendarTypesLazyQueryHookResult = ReturnType<typeof useCalendarTypesLazyQuery>;
export type CalendarTypesQueryResult = Apollo.QueryResult<CalendarTypesQuery, CalendarTypesQueryVariables>;
export const EventListByPeriodDocument = Apollo.gql`
    query EventListByPeriod($periodFilter: DirSportingEventWhereInput!, $first: Int, $skip: Int) {
  dirSportingEvents(where: $periodFilter, first: $first, skip: $skip, orderBy: startDate_ASC) {
    ...CalendarEventByPeriod
  }
}
    ${CalendarEventByPeriodFragmentDoc}`;

/**
 * __useEventListByPeriodQuery__
 *
 * To run a query within a React component, call `useEventListByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventListByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventListByPeriodQuery({
 *   variables: {
 *      periodFilter: // value for 'periodFilter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useEventListByPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventListByPeriodQuery, EventListByPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<EventListByPeriodQuery, EventListByPeriodQueryVariables>(EventListByPeriodDocument, baseOptions);
      }
export function useEventListByPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventListByPeriodQuery, EventListByPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventListByPeriodQuery, EventListByPeriodQueryVariables>(EventListByPeriodDocument, baseOptions);
        }
export type EventListByPeriodQueryHookResult = ReturnType<typeof useEventListByPeriodQuery>;
export type EventListByPeriodLazyQueryHookResult = ReturnType<typeof useEventListByPeriodLazyQuery>;
export type EventListByPeriodQueryResult = Apollo.QueryResult<EventListByPeriodQuery, EventListByPeriodQueryVariables>;
export const EventListByMonthDocument = Apollo.gql`
    query EventListByMonth($start: DateTime, $end: DateTime, $calendarFilter: DirCalendarWhereInput!) {
  dirSportingEvents(where: {endDate_gte: $start, startDate_lte: $end, dirCalendar: $calendarFilter, archive: null, OR: [{clsEventStages_every: {registryNumber_not_in: [100002914]}}, {clsEventStages_some: {registryNumber_not: 100002914}}]}, orderBy: startDate_ASC) {
    ...CalendarEventByMonth
  }
}
    ${CalendarEventByMonthFragmentDoc}`;

/**
 * __useEventListByMonthQuery__
 *
 * To run a query within a React component, call `useEventListByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventListByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventListByMonthQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      calendarFilter: // value for 'calendarFilter'
 *   },
 * });
 */
export function useEventListByMonthQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventListByMonthQuery, EventListByMonthQueryVariables>) {
        return ApolloReactHooks.useQuery<EventListByMonthQuery, EventListByMonthQueryVariables>(EventListByMonthDocument, baseOptions);
      }
export function useEventListByMonthLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventListByMonthQuery, EventListByMonthQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventListByMonthQuery, EventListByMonthQueryVariables>(EventListByMonthDocument, baseOptions);
        }
export type EventListByMonthQueryHookResult = ReturnType<typeof useEventListByMonthQuery>;
export type EventListByMonthLazyQueryHookResult = ReturnType<typeof useEventListByMonthLazyQuery>;
export type EventListByMonthQueryResult = Apollo.QueryResult<EventListByMonthQuery, EventListByMonthQueryVariables>;
export const FindCalendarsDocument = Apollo.gql`
    query FindCalendars($search: String!) {
  dirCalendars(where: {fullName_contains: $search}, first: 10) {
    value: id
    label: fullName
    shortName
  }
}
    `;

/**
 * __useFindCalendarsQuery__
 *
 * To run a query within a React component, call `useFindCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCalendarsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFindCalendarsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindCalendarsQuery, FindCalendarsQueryVariables>) {
        return ApolloReactHooks.useQuery<FindCalendarsQuery, FindCalendarsQueryVariables>(FindCalendarsDocument, baseOptions);
      }
export function useFindCalendarsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindCalendarsQuery, FindCalendarsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindCalendarsQuery, FindCalendarsQueryVariables>(FindCalendarsDocument, baseOptions);
        }
export type FindCalendarsQueryHookResult = ReturnType<typeof useFindCalendarsQuery>;
export type FindCalendarsLazyQueryHookResult = ReturnType<typeof useFindCalendarsLazyQuery>;
export type FindCalendarsQueryResult = Apollo.QueryResult<FindCalendarsQuery, FindCalendarsQueryVariables>;
export const CalendarsSportsDocument = Apollo.gql`
    query CalendarsSports($id: UUID!) {
  dirCalendar(where: {id: $id}) {
    fullName
    ...CalendarSport
  }
}
    ${CalendarSportFragmentDoc}`;

/**
 * __useCalendarsSportsQuery__
 *
 * To run a query within a React component, call `useCalendarsSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarsSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarsSportsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarsSportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarsSportsQuery, CalendarsSportsQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarsSportsQuery, CalendarsSportsQueryVariables>(CalendarsSportsDocument, baseOptions);
      }
export function useCalendarsSportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarsSportsQuery, CalendarsSportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarsSportsQuery, CalendarsSportsQueryVariables>(CalendarsSportsDocument, baseOptions);
        }
export type CalendarsSportsQueryHookResult = ReturnType<typeof useCalendarsSportsQuery>;
export type CalendarsSportsLazyQueryHookResult = ReturnType<typeof useCalendarsSportsLazyQuery>;
export type CalendarsSportsQueryResult = Apollo.QueryResult<CalendarsSportsQuery, CalendarsSportsQueryVariables>;
export const GetVenueByRegionDocument = Apollo.gql`
    query GetVenueByRegion($idRegion: UUID!, $id: UUID!) {
  venueCompApplications(where: {dirRegion: {id: $idRegion}, venueCompetition: {id: $id}}, orderBy: number_ASC) {
    number
    id
    notificationSentToFCPSR
  }
}
    `;

/**
 * __useGetVenueByRegionQuery__
 *
 * To run a query within a React component, call `useGetVenueByRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueByRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVenueByRegionQuery({
 *   variables: {
 *      idRegion: // value for 'idRegion'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVenueByRegionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetVenueByRegionQuery, GetVenueByRegionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetVenueByRegionQuery, GetVenueByRegionQueryVariables>(GetVenueByRegionDocument, baseOptions);
      }
export function useGetVenueByRegionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetVenueByRegionQuery, GetVenueByRegionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetVenueByRegionQuery, GetVenueByRegionQueryVariables>(GetVenueByRegionDocument, baseOptions);
        }
export type GetVenueByRegionQueryHookResult = ReturnType<typeof useGetVenueByRegionQuery>;
export type GetVenueByRegionLazyQueryHookResult = ReturnType<typeof useGetVenueByRegionLazyQuery>;
export type GetVenueByRegionQueryResult = Apollo.QueryResult<GetVenueByRegionQuery, GetVenueByRegionQueryVariables>;
export const GetVenueApplicationByIdDocument = Apollo.gql`
    query GetVenueApplicationById($id: UUID!) {
  venueCompApplication(where: {id: $id}) {
    number
    id
    notificationSentToFCPSR
    applicationFiles {
      id
      name
      path
    }
  }
}
    `;

/**
 * __useGetVenueApplicationByIdQuery__
 *
 * To run a query within a React component, call `useGetVenueApplicationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueApplicationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVenueApplicationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVenueApplicationByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetVenueApplicationByIdQuery, GetVenueApplicationByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetVenueApplicationByIdQuery, GetVenueApplicationByIdQueryVariables>(GetVenueApplicationByIdDocument, baseOptions);
      }
export function useGetVenueApplicationByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetVenueApplicationByIdQuery, GetVenueApplicationByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetVenueApplicationByIdQuery, GetVenueApplicationByIdQueryVariables>(GetVenueApplicationByIdDocument, baseOptions);
        }
export type GetVenueApplicationByIdQueryHookResult = ReturnType<typeof useGetVenueApplicationByIdQuery>;
export type GetVenueApplicationByIdLazyQueryHookResult = ReturnType<typeof useGetVenueApplicationByIdLazyQuery>;
export type GetVenueApplicationByIdQueryResult = Apollo.QueryResult<GetVenueApplicationByIdQuery, GetVenueApplicationByIdQueryVariables>;
export const GetActiveRegionsDocument = Apollo.gql`
    query GetActiveRegions($filter: DirRegionWhereInput) {
  dirRegions(where: $filter, orderBy: fullName_ASC) {
    id
    fullName
  }
}
    `;

/**
 * __useGetActiveRegionsQuery__
 *
 * To run a query within a React component, call `useGetActiveRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveRegionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetActiveRegionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetActiveRegionsQuery, GetActiveRegionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetActiveRegionsQuery, GetActiveRegionsQueryVariables>(GetActiveRegionsDocument, baseOptions);
      }
export function useGetActiveRegionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActiveRegionsQuery, GetActiveRegionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetActiveRegionsQuery, GetActiveRegionsQueryVariables>(GetActiveRegionsDocument, baseOptions);
        }
export type GetActiveRegionsQueryHookResult = ReturnType<typeof useGetActiveRegionsQuery>;
export type GetActiveRegionsLazyQueryHookResult = ReturnType<typeof useGetActiveRegionsLazyQuery>;
export type GetActiveRegionsQueryResult = Apollo.QueryResult<GetActiveRegionsQuery, GetActiveRegionsQueryVariables>;
export const CalendarsSportsWithEventsDocument = Apollo.gql`
    query CalendarsSportsWithEvents($id: UUID!, $stageRegistryNumber: Int!) {
  dirCalendar(where: {id: $id}) {
    ...CalendarSport
    ekpMinsportNumber
  }
  dirSportingEvents(where: {dirCalendar: {id: $id}, clsEventStages_some: {registryNumber: $stageRegistryNumber}}) {
    ...CalendarEventSport
  }
}
    ${CalendarSportFragmentDoc}
${CalendarEventSportFragmentDoc}`;

/**
 * __useCalendarsSportsWithEventsQuery__
 *
 * To run a query within a React component, call `useCalendarsSportsWithEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarsSportsWithEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarsSportsWithEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      stageRegistryNumber: // value for 'stageRegistryNumber'
 *   },
 * });
 */
export function useCalendarsSportsWithEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarsSportsWithEventsQuery, CalendarsSportsWithEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarsSportsWithEventsQuery, CalendarsSportsWithEventsQueryVariables>(CalendarsSportsWithEventsDocument, baseOptions);
      }
export function useCalendarsSportsWithEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarsSportsWithEventsQuery, CalendarsSportsWithEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarsSportsWithEventsQuery, CalendarsSportsWithEventsQueryVariables>(CalendarsSportsWithEventsDocument, baseOptions);
        }
export type CalendarsSportsWithEventsQueryHookResult = ReturnType<typeof useCalendarsSportsWithEventsQuery>;
export type CalendarsSportsWithEventsLazyQueryHookResult = ReturnType<typeof useCalendarsSportsWithEventsLazyQuery>;
export type CalendarsSportsWithEventsQueryResult = Apollo.QueryResult<CalendarsSportsWithEventsQuery, CalendarsSportsWithEventsQueryVariables>;
export const CalendarSummaryInformationOnParticipantNumberDocument = Apollo.gql`
    query CalendarSummaryInformationOnParticipantNumber($id: UUID!) {
  quantyRequestStageTwoes(where: {dirCalendar: {id: $id}}) {
    ...CalendarQuantyRequestStageTwo
  }
  dirFederalDistricts(orderBy: fullName_ASC) {
    ...FederalDistrictPortion
  }
}
    ${CalendarQuantyRequestStageTwoFragmentDoc}
${FederalDistrictPortionFragmentDoc}`;

/**
 * __useCalendarSummaryInformationOnParticipantNumberQuery__
 *
 * To run a query within a React component, call `useCalendarSummaryInformationOnParticipantNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarSummaryInformationOnParticipantNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarSummaryInformationOnParticipantNumberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarSummaryInformationOnParticipantNumberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarSummaryInformationOnParticipantNumberQuery, CalendarSummaryInformationOnParticipantNumberQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarSummaryInformationOnParticipantNumberQuery, CalendarSummaryInformationOnParticipantNumberQueryVariables>(CalendarSummaryInformationOnParticipantNumberDocument, baseOptions);
      }
export function useCalendarSummaryInformationOnParticipantNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarSummaryInformationOnParticipantNumberQuery, CalendarSummaryInformationOnParticipantNumberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarSummaryInformationOnParticipantNumberQuery, CalendarSummaryInformationOnParticipantNumberQueryVariables>(CalendarSummaryInformationOnParticipantNumberDocument, baseOptions);
        }
export type CalendarSummaryInformationOnParticipantNumberQueryHookResult = ReturnType<typeof useCalendarSummaryInformationOnParticipantNumberQuery>;
export type CalendarSummaryInformationOnParticipantNumberLazyQueryHookResult = ReturnType<typeof useCalendarSummaryInformationOnParticipantNumberLazyQuery>;
export type CalendarSummaryInformationOnParticipantNumberQueryResult = Apollo.QueryResult<CalendarSummaryInformationOnParticipantNumberQuery, CalendarSummaryInformationOnParticipantNumberQueryVariables>;
export const CalendarRegionSummaryInformationOnParticipantNumberDocument = Apollo.gql`
    query CalendarRegionSummaryInformationOnParticipantNumber($id: UUID!, $regionId: UUID!) {
  quantyRequestStageTwoes(where: {dirCalendar: {id: $id}, dirRegion: {id: $regionId}}) {
    ...CalendarQuantyRequestStageTwo
  }
}
    ${CalendarQuantyRequestStageTwoFragmentDoc}`;

/**
 * __useCalendarRegionSummaryInformationOnParticipantNumberQuery__
 *
 * To run a query within a React component, call `useCalendarRegionSummaryInformationOnParticipantNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarRegionSummaryInformationOnParticipantNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarRegionSummaryInformationOnParticipantNumberQuery({
 *   variables: {
 *      id: // value for 'id'
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useCalendarRegionSummaryInformationOnParticipantNumberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarRegionSummaryInformationOnParticipantNumberQuery, CalendarRegionSummaryInformationOnParticipantNumberQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarRegionSummaryInformationOnParticipantNumberQuery, CalendarRegionSummaryInformationOnParticipantNumberQueryVariables>(CalendarRegionSummaryInformationOnParticipantNumberDocument, baseOptions);
      }
export function useCalendarRegionSummaryInformationOnParticipantNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarRegionSummaryInformationOnParticipantNumberQuery, CalendarRegionSummaryInformationOnParticipantNumberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarRegionSummaryInformationOnParticipantNumberQuery, CalendarRegionSummaryInformationOnParticipantNumberQueryVariables>(CalendarRegionSummaryInformationOnParticipantNumberDocument, baseOptions);
        }
export type CalendarRegionSummaryInformationOnParticipantNumberQueryHookResult = ReturnType<typeof useCalendarRegionSummaryInformationOnParticipantNumberQuery>;
export type CalendarRegionSummaryInformationOnParticipantNumberLazyQueryHookResult = ReturnType<typeof useCalendarRegionSummaryInformationOnParticipantNumberLazyQuery>;
export type CalendarRegionSummaryInformationOnParticipantNumberQueryResult = Apollo.QueryResult<CalendarRegionSummaryInformationOnParticipantNumberQuery, CalendarRegionSummaryInformationOnParticipantNumberQueryVariables>;
export const CalendarEtapeDocument = Apollo.gql`
    query CalendarEtape($id: UUID!) {
  dirCalendar(where: {id: $id}) {
    id
    stageNumber {
      id
      fullName
    }
  }
}
    `;

/**
 * __useCalendarEtapeQuery__
 *
 * To run a query within a React component, call `useCalendarEtapeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEtapeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEtapeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarEtapeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarEtapeQuery, CalendarEtapeQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarEtapeQuery, CalendarEtapeQueryVariables>(CalendarEtapeDocument, baseOptions);
      }
export function useCalendarEtapeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarEtapeQuery, CalendarEtapeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarEtapeQuery, CalendarEtapeQueryVariables>(CalendarEtapeDocument, baseOptions);
        }
export type CalendarEtapeQueryHookResult = ReturnType<typeof useCalendarEtapeQuery>;
export type CalendarEtapeLazyQueryHookResult = ReturnType<typeof useCalendarEtapeLazyQuery>;
export type CalendarEtapeQueryResult = Apollo.QueryResult<CalendarEtapeQuery, CalendarEtapeQueryVariables>;
export const CalendarEventsDocument = Apollo.gql`
    query CalendarEvents($id: UUID!, $regionId: UUID, $sportId: UUID!, $stageRegistryNumber: Int!) {
  dirCalendar(where: {id: $id}) {
    id
    ekpMinsportNumber
  }
  dirSportingEvents(where: {dirCalendar: {id: $id}, dirRegion: {id: $regionId}, clsEventStages_some: {registryNumber: $stageRegistryNumber}, sports_some: {dirSport: {id: $sportId}}}, orderBy: name_ASC) {
    ...CalendarEvent
  }
}
    ${CalendarEventFragmentDoc}`;

/**
 * __useCalendarEventsQuery__
 *
 * To run a query within a React component, call `useCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      regionId: // value for 'regionId'
 *      sportId: // value for 'sportId'
 *      stageRegistryNumber: // value for 'stageRegistryNumber'
 *   },
 * });
 */
export function useCalendarEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(CalendarEventsDocument, baseOptions);
      }
export function useCalendarEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(CalendarEventsDocument, baseOptions);
        }
export type CalendarEventsQueryHookResult = ReturnType<typeof useCalendarEventsQuery>;
export type CalendarEventsLazyQueryHookResult = ReturnType<typeof useCalendarEventsLazyQuery>;
export type CalendarEventsQueryResult = Apollo.QueryResult<CalendarEventsQuery, CalendarEventsQueryVariables>;
export const CalendarEventsWithoutRegionsDocument = Apollo.gql`
    query CalendarEventsWithoutRegions($id: UUID!, $registryNumber: Int, $sportId: UUID!, $stageRegistryNumber: Int!) {
  dirCalendar(where: {id: $id}) {
    id
    ekpMinsportNumber
  }
  dirSportingEvents(where: {dirCalendar: {id: $id, clsCalendarCategory: {registryNumber: $registryNumber}}, clsEventStages_some: {registryNumber: $stageRegistryNumber}, sports_some: {dirSport: {id: $sportId}}}, orderBy: name_ASC) {
    ...CalendarEvent
  }
}
    ${CalendarEventFragmentDoc}`;

/**
 * __useCalendarEventsWithoutRegionsQuery__
 *
 * To run a query within a React component, call `useCalendarEventsWithoutRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventsWithoutRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventsWithoutRegionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      registryNumber: // value for 'registryNumber'
 *      sportId: // value for 'sportId'
 *      stageRegistryNumber: // value for 'stageRegistryNumber'
 *   },
 * });
 */
export function useCalendarEventsWithoutRegionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarEventsWithoutRegionsQuery, CalendarEventsWithoutRegionsQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarEventsWithoutRegionsQuery, CalendarEventsWithoutRegionsQueryVariables>(CalendarEventsWithoutRegionsDocument, baseOptions);
      }
export function useCalendarEventsWithoutRegionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarEventsWithoutRegionsQuery, CalendarEventsWithoutRegionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarEventsWithoutRegionsQuery, CalendarEventsWithoutRegionsQueryVariables>(CalendarEventsWithoutRegionsDocument, baseOptions);
        }
export type CalendarEventsWithoutRegionsQueryHookResult = ReturnType<typeof useCalendarEventsWithoutRegionsQuery>;
export type CalendarEventsWithoutRegionsLazyQueryHookResult = ReturnType<typeof useCalendarEventsWithoutRegionsLazyQuery>;
export type CalendarEventsWithoutRegionsQueryResult = Apollo.QueryResult<CalendarEventsWithoutRegionsQuery, CalendarEventsWithoutRegionsQueryVariables>;
export const CalendarEventSportsDocument = Apollo.gql`
    query CalendarEventSports($id: UUID!, $stageRegistryNumber: Int!) {
  dirCalendar(where: {id: $id}) {
    id
    ekpMinsportNumber
  }
  dirSportingEvents(where: {dirCalendar: {id: $id}, clsEventStages_some: {registryNumber: $stageRegistryNumber}, archive: null}, orderBy: name_ASC) {
    ...CalendarEventSport
  }
}
    ${CalendarEventSportFragmentDoc}`;

/**
 * __useCalendarEventSportsQuery__
 *
 * To run a query within a React component, call `useCalendarEventSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventSportsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      stageRegistryNumber: // value for 'stageRegistryNumber'
 *   },
 * });
 */
export function useCalendarEventSportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarEventSportsQuery, CalendarEventSportsQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarEventSportsQuery, CalendarEventSportsQueryVariables>(CalendarEventSportsDocument, baseOptions);
      }
export function useCalendarEventSportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarEventSportsQuery, CalendarEventSportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarEventSportsQuery, CalendarEventSportsQueryVariables>(CalendarEventSportsDocument, baseOptions);
        }
export type CalendarEventSportsQueryHookResult = ReturnType<typeof useCalendarEventSportsQuery>;
export type CalendarEventSportsLazyQueryHookResult = ReturnType<typeof useCalendarEventSportsLazyQuery>;
export type CalendarEventSportsQueryResult = Apollo.QueryResult<CalendarEventSportsQuery, CalendarEventSportsQueryVariables>;
export const CalendarEventDistrictsDocument = Apollo.gql`
    query CalendarEventDistricts($id: UUID!, $stageRegistryNumber: Int!, $sportId: UUID!) {
  dirCalendar(where: {id: $id}) {
    id
    ekpMinsportNumber
  }
  dirSportingEvents(where: {dirCalendar: {id: $id}, clsEventStages_some: {registryNumber: $stageRegistryNumber}, sports_some: {dirSport: {id: $sportId}}, archive: null}, orderBy: name_ASC) {
    ...EventDistrict
  }
}
    ${EventDistrictFragmentDoc}`;

/**
 * __useCalendarEventDistrictsQuery__
 *
 * To run a query within a React component, call `useCalendarEventDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventDistrictsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      stageRegistryNumber: // value for 'stageRegistryNumber'
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useCalendarEventDistrictsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarEventDistrictsQuery, CalendarEventDistrictsQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarEventDistrictsQuery, CalendarEventDistrictsQueryVariables>(CalendarEventDistrictsDocument, baseOptions);
      }
export function useCalendarEventDistrictsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarEventDistrictsQuery, CalendarEventDistrictsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarEventDistrictsQuery, CalendarEventDistrictsQueryVariables>(CalendarEventDistrictsDocument, baseOptions);
        }
export type CalendarEventDistrictsQueryHookResult = ReturnType<typeof useCalendarEventDistrictsQuery>;
export type CalendarEventDistrictsLazyQueryHookResult = ReturnType<typeof useCalendarEventDistrictsLazyQuery>;
export type CalendarEventDistrictsQueryResult = Apollo.QueryResult<CalendarEventDistrictsQuery, CalendarEventDistrictsQueryVariables>;
export const CalendarStageDocument = Apollo.gql`
    query CalendarStage($id: UUID!) {
  dirCalendar(where: {id: $id}) {
    id
    ekpMinsportNumber
    fullName
    clsCalendarCategory {
      value: registryNumber
      label: fullName
    }
    stageNumber {
      id
      fullName
      registryNumber
    }
  }
}
    `;

/**
 * __useCalendarStageQuery__
 *
 * To run a query within a React component, call `useCalendarStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarStageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarStageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarStageQuery, CalendarStageQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarStageQuery, CalendarStageQueryVariables>(CalendarStageDocument, baseOptions);
      }
export function useCalendarStageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarStageQuery, CalendarStageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarStageQuery, CalendarStageQueryVariables>(CalendarStageDocument, baseOptions);
        }
export type CalendarStageQueryHookResult = ReturnType<typeof useCalendarStageQuery>;
export type CalendarStageLazyQueryHookResult = ReturnType<typeof useCalendarStageLazyQuery>;
export type CalendarStageQueryResult = Apollo.QueryResult<CalendarStageQuery, CalendarStageQueryVariables>;
export const CalendarSignedReportsDocument = Apollo.gql`
    query CalendarSignedReports($id: UUID!, $regionId: UUID!) {
  dirCalendar(where: {id: $id}) {
    id
    signedReports(where: {dirRegion: {id: $regionId}}) {
      id
      files {
        id
        name
        path
      }
    }
  }
}
    `;

/**
 * __useCalendarSignedReportsQuery__
 *
 * To run a query within a React component, call `useCalendarSignedReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarSignedReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarSignedReportsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useCalendarSignedReportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarSignedReportsQuery, CalendarSignedReportsQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarSignedReportsQuery, CalendarSignedReportsQueryVariables>(CalendarSignedReportsDocument, baseOptions);
      }
export function useCalendarSignedReportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarSignedReportsQuery, CalendarSignedReportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarSignedReportsQuery, CalendarSignedReportsQueryVariables>(CalendarSignedReportsDocument, baseOptions);
        }
export type CalendarSignedReportsQueryHookResult = ReturnType<typeof useCalendarSignedReportsQuery>;
export type CalendarSignedReportsLazyQueryHookResult = ReturnType<typeof useCalendarSignedReportsLazyQuery>;
export type CalendarSignedReportsQueryResult = Apollo.QueryResult<CalendarSignedReportsQuery, CalendarSignedReportsQueryVariables>;
export const CalendarReportEventsDocument = Apollo.gql`
    query CalendarReportEvents($filter: DirSportingEventWhereInput) {
  dirSportingEvents(where: $filter, orderBy: startDate_ASC) {
    ...CalendarReportEvent
  }
}
    ${CalendarReportEventFragmentDoc}`;

/**
 * __useCalendarReportEventsQuery__
 *
 * To run a query within a React component, call `useCalendarReportEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarReportEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarReportEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCalendarReportEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarReportEventsQuery, CalendarReportEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarReportEventsQuery, CalendarReportEventsQueryVariables>(CalendarReportEventsDocument, baseOptions);
      }
export function useCalendarReportEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarReportEventsQuery, CalendarReportEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarReportEventsQuery, CalendarReportEventsQueryVariables>(CalendarReportEventsDocument, baseOptions);
        }
export type CalendarReportEventsQueryHookResult = ReturnType<typeof useCalendarReportEventsQuery>;
export type CalendarReportEventsLazyQueryHookResult = ReturnType<typeof useCalendarReportEventsLazyQuery>;
export type CalendarReportEventsQueryResult = Apollo.QueryResult<CalendarReportEventsQuery, CalendarReportEventsQueryVariables>;
export const UpdateNotificationProtocolDocument = Apollo.gql`
    mutation updateNotificationProtocol($id: UUID, $data: NotificationProtocolUpdateInput!) {
  updateNotificationProtocol(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdateNotificationProtocolMutationFn = Apollo.MutationFunction<UpdateNotificationProtocolMutation, UpdateNotificationProtocolMutationVariables>;

/**
 * __useUpdateNotificationProtocolMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationProtocolMutation, { data, loading, error }] = useUpdateNotificationProtocolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNotificationProtocolMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotificationProtocolMutation, UpdateNotificationProtocolMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNotificationProtocolMutation, UpdateNotificationProtocolMutationVariables>(UpdateNotificationProtocolDocument, baseOptions);
      }
export type UpdateNotificationProtocolMutationHookResult = ReturnType<typeof useUpdateNotificationProtocolMutation>;
export type UpdateNotificationProtocolMutationResult = Apollo.MutationResult<UpdateNotificationProtocolMutation>;
export type UpdateNotificationProtocolMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationProtocolMutation, UpdateNotificationProtocolMutationVariables>;
export const CreateNotificationProtocolDocument = Apollo.gql`
    mutation createNotificationProtocol($data: NotificationProtocolCreateInput!) {
  createNotificationProtocol(data: $data) {
    id
  }
}
    `;
export type CreateNotificationProtocolMutationFn = Apollo.MutationFunction<CreateNotificationProtocolMutation, CreateNotificationProtocolMutationVariables>;

/**
 * __useCreateNotificationProtocolMutation__
 *
 * To run a mutation, you first call `useCreateNotificationProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationProtocolMutation, { data, loading, error }] = useCreateNotificationProtocolMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNotificationProtocolMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNotificationProtocolMutation, CreateNotificationProtocolMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNotificationProtocolMutation, CreateNotificationProtocolMutationVariables>(CreateNotificationProtocolDocument, baseOptions);
      }
export type CreateNotificationProtocolMutationHookResult = ReturnType<typeof useCreateNotificationProtocolMutation>;
export type CreateNotificationProtocolMutationResult = Apollo.MutationResult<CreateNotificationProtocolMutation>;
export type CreateNotificationProtocolMutationOptions = Apollo.BaseMutationOptions<CreateNotificationProtocolMutation, CreateNotificationProtocolMutationVariables>;
export const DeleteNotificationProtocolDocument = Apollo.gql`
    mutation deleteNotificationProtocol($id: UUID) {
  deleteNotificationProtocol(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteNotificationProtocolMutationFn = Apollo.MutationFunction<DeleteNotificationProtocolMutation, DeleteNotificationProtocolMutationVariables>;

/**
 * __useDeleteNotificationProtocolMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationProtocolMutation, { data, loading, error }] = useDeleteNotificationProtocolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationProtocolMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNotificationProtocolMutation, DeleteNotificationProtocolMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNotificationProtocolMutation, DeleteNotificationProtocolMutationVariables>(DeleteNotificationProtocolDocument, baseOptions);
      }
export type DeleteNotificationProtocolMutationHookResult = ReturnType<typeof useDeleteNotificationProtocolMutation>;
export type DeleteNotificationProtocolMutationResult = Apollo.MutationResult<DeleteNotificationProtocolMutation>;
export type DeleteNotificationProtocolMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationProtocolMutation, DeleteNotificationProtocolMutationVariables>;
export const NotificationProtocolDocument = Apollo.gql`
    query NotificationProtocol($dirCalendar: UUID!, $dirSport: UUID!, $dirRegion: UUID!) {
  notificationProtocols(where: {dirCalendar: {id: $dirCalendar}, dirSport: {id: $dirSport}, dirRegion: {id: $dirRegion}}) {
    id
    forNotificationROIVSpecialists {
      regular {
        firstname
        lastname
        patronymic
      }
      email
      id
    }
  }
}
    `;

/**
 * __useNotificationProtocolQuery__
 *
 * To run a query within a React component, call `useNotificationProtocolQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationProtocolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationProtocolQuery({
 *   variables: {
 *      dirCalendar: // value for 'dirCalendar'
 *      dirSport: // value for 'dirSport'
 *      dirRegion: // value for 'dirRegion'
 *   },
 * });
 */
export function useNotificationProtocolQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationProtocolQuery, NotificationProtocolQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationProtocolQuery, NotificationProtocolQueryVariables>(NotificationProtocolDocument, baseOptions);
      }
export function useNotificationProtocolLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationProtocolQuery, NotificationProtocolQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationProtocolQuery, NotificationProtocolQueryVariables>(NotificationProtocolDocument, baseOptions);
        }
export type NotificationProtocolQueryHookResult = ReturnType<typeof useNotificationProtocolQuery>;
export type NotificationProtocolLazyQueryHookResult = ReturnType<typeof useNotificationProtocolLazyQuery>;
export type NotificationProtocolQueryResult = Apollo.QueryResult<NotificationProtocolQuery, NotificationProtocolQueryVariables>;
export const CreateCalendarRegulationDocument = Apollo.gql`
    mutation CreateCalendarRegulation($data: DirSportEventRegulationsCreateInput!) {
  createDirSportEventRegulations(data: $data) {
    id
  }
}
    `;
export type CreateCalendarRegulationMutationFn = Apollo.MutationFunction<CreateCalendarRegulationMutation, CreateCalendarRegulationMutationVariables>;

/**
 * __useCreateCalendarRegulationMutation__
 *
 * To run a mutation, you first call `useCreateCalendarRegulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarRegulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarRegulationMutation, { data, loading, error }] = useCreateCalendarRegulationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCalendarRegulationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCalendarRegulationMutation, CreateCalendarRegulationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCalendarRegulationMutation, CreateCalendarRegulationMutationVariables>(CreateCalendarRegulationDocument, baseOptions);
      }
export type CreateCalendarRegulationMutationHookResult = ReturnType<typeof useCreateCalendarRegulationMutation>;
export type CreateCalendarRegulationMutationResult = Apollo.MutationResult<CreateCalendarRegulationMutation>;
export type CreateCalendarRegulationMutationOptions = Apollo.BaseMutationOptions<CreateCalendarRegulationMutation, CreateCalendarRegulationMutationVariables>;
export const UpdateCalendarRegulationDocument = Apollo.gql`
    mutation UpdateCalendarRegulation($id: UUID!, $data: DirSportEventRegulationsUpdateInput!) {
  updateDirSportEventRegulations(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdateCalendarRegulationMutationFn = Apollo.MutationFunction<UpdateCalendarRegulationMutation, UpdateCalendarRegulationMutationVariables>;

/**
 * __useUpdateCalendarRegulationMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarRegulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarRegulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarRegulationMutation, { data, loading, error }] = useUpdateCalendarRegulationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCalendarRegulationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCalendarRegulationMutation, UpdateCalendarRegulationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCalendarRegulationMutation, UpdateCalendarRegulationMutationVariables>(UpdateCalendarRegulationDocument, baseOptions);
      }
export type UpdateCalendarRegulationMutationHookResult = ReturnType<typeof useUpdateCalendarRegulationMutation>;
export type UpdateCalendarRegulationMutationResult = Apollo.MutationResult<UpdateCalendarRegulationMutation>;
export type UpdateCalendarRegulationMutationOptions = Apollo.BaseMutationOptions<UpdateCalendarRegulationMutation, UpdateCalendarRegulationMutationVariables>;
export const UpdateCalendarRegulationsDocumentDocument = Apollo.gql`
    mutation UpdateCalendarRegulationsDocument($id: UUID!, $regulationId: UUID!, $fileId: UUID!) {
  updateDirCalendar(where: {id: $id}, data: {dirSportEventRegulations: {update: {where: {id: $regulationId}, data: {regulationsDocument: {connect: {id: $fileId}}}}}}) {
    id
  }
}
    `;
export type UpdateCalendarRegulationsDocumentMutationFn = Apollo.MutationFunction<UpdateCalendarRegulationsDocumentMutation, UpdateCalendarRegulationsDocumentMutationVariables>;

/**
 * __useUpdateCalendarRegulationsDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarRegulationsDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarRegulationsDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarRegulationsDocumentMutation, { data, loading, error }] = useUpdateCalendarRegulationsDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      regulationId: // value for 'regulationId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useUpdateCalendarRegulationsDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCalendarRegulationsDocumentMutation, UpdateCalendarRegulationsDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCalendarRegulationsDocumentMutation, UpdateCalendarRegulationsDocumentMutationVariables>(UpdateCalendarRegulationsDocumentDocument, baseOptions);
      }
export type UpdateCalendarRegulationsDocumentMutationHookResult = ReturnType<typeof useUpdateCalendarRegulationsDocumentMutation>;
export type UpdateCalendarRegulationsDocumentMutationResult = Apollo.MutationResult<UpdateCalendarRegulationsDocumentMutation>;
export type UpdateCalendarRegulationsDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateCalendarRegulationsDocumentMutation, UpdateCalendarRegulationsDocumentMutationVariables>;
export const DeleteCalendarRegulationsDocumentDocument = Apollo.gql`
    mutation DeleteCalendarRegulationsDocument($id: UUID!, $regulationId: UUID!) {
  updateDirCalendar(where: {id: $id}, data: {dirSportEventRegulations: {update: {where: {id: $regulationId}, data: {regulationsDocument: {disconnect: true}}}}}) {
    id
  }
}
    `;
export type DeleteCalendarRegulationsDocumentMutationFn = Apollo.MutationFunction<DeleteCalendarRegulationsDocumentMutation, DeleteCalendarRegulationsDocumentMutationVariables>;

/**
 * __useDeleteCalendarRegulationsDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarRegulationsDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarRegulationsDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarRegulationsDocumentMutation, { data, loading, error }] = useDeleteCalendarRegulationsDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      regulationId: // value for 'regulationId'
 *   },
 * });
 */
export function useDeleteCalendarRegulationsDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCalendarRegulationsDocumentMutation, DeleteCalendarRegulationsDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCalendarRegulationsDocumentMutation, DeleteCalendarRegulationsDocumentMutationVariables>(DeleteCalendarRegulationsDocumentDocument, baseOptions);
      }
export type DeleteCalendarRegulationsDocumentMutationHookResult = ReturnType<typeof useDeleteCalendarRegulationsDocumentMutation>;
export type DeleteCalendarRegulationsDocumentMutationResult = Apollo.MutationResult<DeleteCalendarRegulationsDocumentMutation>;
export type DeleteCalendarRegulationsDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteCalendarRegulationsDocumentMutation, DeleteCalendarRegulationsDocumentMutationVariables>;
export const UpdateCalendarRegulationsAmendedDocumentDocument = Apollo.gql`
    mutation UpdateCalendarRegulationsAmendedDocument($id: UUID!, $regulationId: UUID!, $fileId: UUID!) {
  updateDirCalendar(where: {id: $id}, data: {dirSportEventRegulations: {update: {where: {id: $regulationId}, data: {regulationsAmendedDocument: {connect: {id: $fileId}}}}}}) {
    id
  }
}
    `;
export type UpdateCalendarRegulationsAmendedDocumentMutationFn = Apollo.MutationFunction<UpdateCalendarRegulationsAmendedDocumentMutation, UpdateCalendarRegulationsAmendedDocumentMutationVariables>;

/**
 * __useUpdateCalendarRegulationsAmendedDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarRegulationsAmendedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarRegulationsAmendedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarRegulationsAmendedDocumentMutation, { data, loading, error }] = useUpdateCalendarRegulationsAmendedDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      regulationId: // value for 'regulationId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useUpdateCalendarRegulationsAmendedDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCalendarRegulationsAmendedDocumentMutation, UpdateCalendarRegulationsAmendedDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCalendarRegulationsAmendedDocumentMutation, UpdateCalendarRegulationsAmendedDocumentMutationVariables>(UpdateCalendarRegulationsAmendedDocumentDocument, baseOptions);
      }
export type UpdateCalendarRegulationsAmendedDocumentMutationHookResult = ReturnType<typeof useUpdateCalendarRegulationsAmendedDocumentMutation>;
export type UpdateCalendarRegulationsAmendedDocumentMutationResult = Apollo.MutationResult<UpdateCalendarRegulationsAmendedDocumentMutation>;
export type UpdateCalendarRegulationsAmendedDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateCalendarRegulationsAmendedDocumentMutation, UpdateCalendarRegulationsAmendedDocumentMutationVariables>;
export const DeleteCalendarRegulationsAmendedDocumentDocument = Apollo.gql`
    mutation DeleteCalendarRegulationsAmendedDocument($id: UUID!, $regulationId: UUID!) {
  updateDirCalendar(where: {id: $id}, data: {dirSportEventRegulations: {update: {where: {id: $regulationId}, data: {regulationsAmendedDocument: {disconnect: true}}}}}) {
    id
  }
}
    `;
export type DeleteCalendarRegulationsAmendedDocumentMutationFn = Apollo.MutationFunction<DeleteCalendarRegulationsAmendedDocumentMutation, DeleteCalendarRegulationsAmendedDocumentMutationVariables>;

/**
 * __useDeleteCalendarRegulationsAmendedDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarRegulationsAmendedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarRegulationsAmendedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarRegulationsAmendedDocumentMutation, { data, loading, error }] = useDeleteCalendarRegulationsAmendedDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      regulationId: // value for 'regulationId'
 *   },
 * });
 */
export function useDeleteCalendarRegulationsAmendedDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCalendarRegulationsAmendedDocumentMutation, DeleteCalendarRegulationsAmendedDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCalendarRegulationsAmendedDocumentMutation, DeleteCalendarRegulationsAmendedDocumentMutationVariables>(DeleteCalendarRegulationsAmendedDocumentDocument, baseOptions);
      }
export type DeleteCalendarRegulationsAmendedDocumentMutationHookResult = ReturnType<typeof useDeleteCalendarRegulationsAmendedDocumentMutation>;
export type DeleteCalendarRegulationsAmendedDocumentMutationResult = Apollo.MutationResult<DeleteCalendarRegulationsAmendedDocumentMutation>;
export type DeleteCalendarRegulationsAmendedDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteCalendarRegulationsAmendedDocumentMutation, DeleteCalendarRegulationsAmendedDocumentMutationVariables>;
export const UpdateCalendarRegulationsAgreedDocumentDocument = Apollo.gql`
    mutation UpdateCalendarRegulationsAgreedDocument($id: UUID!, $regulationId: UUID!, $fileId: UUID!) {
  updateDirCalendar(where: {id: $id}, data: {dirSportEventRegulations: {update: {where: {id: $regulationId}, data: {regulationsAgreedDocument: {connect: {id: $fileId}}}}}}) {
    id
  }
}
    `;
export type UpdateCalendarRegulationsAgreedDocumentMutationFn = Apollo.MutationFunction<UpdateCalendarRegulationsAgreedDocumentMutation, UpdateCalendarRegulationsAgreedDocumentMutationVariables>;

/**
 * __useUpdateCalendarRegulationsAgreedDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarRegulationsAgreedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarRegulationsAgreedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarRegulationsAgreedDocumentMutation, { data, loading, error }] = useUpdateCalendarRegulationsAgreedDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      regulationId: // value for 'regulationId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useUpdateCalendarRegulationsAgreedDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCalendarRegulationsAgreedDocumentMutation, UpdateCalendarRegulationsAgreedDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCalendarRegulationsAgreedDocumentMutation, UpdateCalendarRegulationsAgreedDocumentMutationVariables>(UpdateCalendarRegulationsAgreedDocumentDocument, baseOptions);
      }
export type UpdateCalendarRegulationsAgreedDocumentMutationHookResult = ReturnType<typeof useUpdateCalendarRegulationsAgreedDocumentMutation>;
export type UpdateCalendarRegulationsAgreedDocumentMutationResult = Apollo.MutationResult<UpdateCalendarRegulationsAgreedDocumentMutation>;
export type UpdateCalendarRegulationsAgreedDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateCalendarRegulationsAgreedDocumentMutation, UpdateCalendarRegulationsAgreedDocumentMutationVariables>;
export const DeleteCalendarRegulationsAgreedDocumentDocument = Apollo.gql`
    mutation DeleteCalendarRegulationsAgreedDocument($id: UUID!, $regulationId: UUID!) {
  updateDirCalendar(where: {id: $id}, data: {dirSportEventRegulations: {update: {where: {id: $regulationId}, data: {regulationsAgreedDocument: {disconnect: true}}}}}) {
    id
  }
}
    `;
export type DeleteCalendarRegulationsAgreedDocumentMutationFn = Apollo.MutationFunction<DeleteCalendarRegulationsAgreedDocumentMutation, DeleteCalendarRegulationsAgreedDocumentMutationVariables>;

/**
 * __useDeleteCalendarRegulationsAgreedDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarRegulationsAgreedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarRegulationsAgreedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarRegulationsAgreedDocumentMutation, { data, loading, error }] = useDeleteCalendarRegulationsAgreedDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      regulationId: // value for 'regulationId'
 *   },
 * });
 */
export function useDeleteCalendarRegulationsAgreedDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCalendarRegulationsAgreedDocumentMutation, DeleteCalendarRegulationsAgreedDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCalendarRegulationsAgreedDocumentMutation, DeleteCalendarRegulationsAgreedDocumentMutationVariables>(DeleteCalendarRegulationsAgreedDocumentDocument, baseOptions);
      }
export type DeleteCalendarRegulationsAgreedDocumentMutationHookResult = ReturnType<typeof useDeleteCalendarRegulationsAgreedDocumentMutation>;
export type DeleteCalendarRegulationsAgreedDocumentMutationResult = Apollo.MutationResult<DeleteCalendarRegulationsAgreedDocumentMutation>;
export type DeleteCalendarRegulationsAgreedDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteCalendarRegulationsAgreedDocumentMutation, DeleteCalendarRegulationsAgreedDocumentMutationVariables>;
export const CalendarRegulationsNotifyDocument = Apollo.gql`
    mutation CalendarRegulationsNotify($calendarName: String!, $calendarId: String!, $sportName: String!, $sportId: String!, $notificationTypeNumber: String!) {
  updateDirSportsThenNotify(data: $notificationTypeNumber, calendarName: $calendarName, sportName: $sportName, calendarId: $calendarId, sportId: $sportId)
}
    `;
export type CalendarRegulationsNotifyMutationFn = Apollo.MutationFunction<CalendarRegulationsNotifyMutation, CalendarRegulationsNotifyMutationVariables>;

/**
 * __useCalendarRegulationsNotifyMutation__
 *
 * To run a mutation, you first call `useCalendarRegulationsNotifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarRegulationsNotifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarRegulationsNotifyMutation, { data, loading, error }] = useCalendarRegulationsNotifyMutation({
 *   variables: {
 *      calendarName: // value for 'calendarName'
 *      calendarId: // value for 'calendarId'
 *      sportName: // value for 'sportName'
 *      sportId: // value for 'sportId'
 *      notificationTypeNumber: // value for 'notificationTypeNumber'
 *   },
 * });
 */
export function useCalendarRegulationsNotifyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalendarRegulationsNotifyMutation, CalendarRegulationsNotifyMutationVariables>) {
        return ApolloReactHooks.useMutation<CalendarRegulationsNotifyMutation, CalendarRegulationsNotifyMutationVariables>(CalendarRegulationsNotifyDocument, baseOptions);
      }
export type CalendarRegulationsNotifyMutationHookResult = ReturnType<typeof useCalendarRegulationsNotifyMutation>;
export type CalendarRegulationsNotifyMutationResult = Apollo.MutationResult<CalendarRegulationsNotifyMutation>;
export type CalendarRegulationsNotifyMutationOptions = Apollo.BaseMutationOptions<CalendarRegulationsNotifyMutation, CalendarRegulationsNotifyMutationVariables>;
export const RegulationAddUserRegulationsDocDocument = Apollo.gql`
    mutation RegulationAddUserRegulationsDoc($idRegulation: UUID!, $idUser: UUID!) {
  updateDirSportEventRegulations(data: {fcpsrAddRegulationsDoc: {connect: {id: $idUser}}}, where: {id: $idRegulation}) {
    id
  }
}
    `;
export type RegulationAddUserRegulationsDocMutationFn = Apollo.MutationFunction<RegulationAddUserRegulationsDocMutation, RegulationAddUserRegulationsDocMutationVariables>;

/**
 * __useRegulationAddUserRegulationsDocMutation__
 *
 * To run a mutation, you first call `useRegulationAddUserRegulationsDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegulationAddUserRegulationsDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regulationAddUserRegulationsDocMutation, { data, loading, error }] = useRegulationAddUserRegulationsDocMutation({
 *   variables: {
 *      idRegulation: // value for 'idRegulation'
 *      idUser: // value for 'idUser'
 *   },
 * });
 */
export function useRegulationAddUserRegulationsDocMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegulationAddUserRegulationsDocMutation, RegulationAddUserRegulationsDocMutationVariables>) {
        return ApolloReactHooks.useMutation<RegulationAddUserRegulationsDocMutation, RegulationAddUserRegulationsDocMutationVariables>(RegulationAddUserRegulationsDocDocument, baseOptions);
      }
export type RegulationAddUserRegulationsDocMutationHookResult = ReturnType<typeof useRegulationAddUserRegulationsDocMutation>;
export type RegulationAddUserRegulationsDocMutationResult = Apollo.MutationResult<RegulationAddUserRegulationsDocMutation>;
export type RegulationAddUserRegulationsDocMutationOptions = Apollo.BaseMutationOptions<RegulationAddUserRegulationsDocMutation, RegulationAddUserRegulationsDocMutationVariables>;
export const CalendarRegulationStatusesDocument = Apollo.gql`
    query CalendarRegulationStatuses($filter: DirSportingEventRegulationStatusWhereInput) {
  dirSportingEventRegulationStatuses(where: $filter, orderBy: createdAt_DESC) {
    ...RegulationStatus
  }
}
    ${RegulationStatusFragmentDoc}`;

/**
 * __useCalendarRegulationStatusesQuery__
 *
 * To run a query within a React component, call `useCalendarRegulationStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarRegulationStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarRegulationStatusesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCalendarRegulationStatusesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarRegulationStatusesQuery, CalendarRegulationStatusesQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarRegulationStatusesQuery, CalendarRegulationStatusesQueryVariables>(CalendarRegulationStatusesDocument, baseOptions);
      }
export function useCalendarRegulationStatusesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarRegulationStatusesQuery, CalendarRegulationStatusesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarRegulationStatusesQuery, CalendarRegulationStatusesQueryVariables>(CalendarRegulationStatusesDocument, baseOptions);
        }
export type CalendarRegulationStatusesQueryHookResult = ReturnType<typeof useCalendarRegulationStatusesQuery>;
export type CalendarRegulationStatusesLazyQueryHookResult = ReturnType<typeof useCalendarRegulationStatusesLazyQuery>;
export type CalendarRegulationStatusesQueryResult = Apollo.QueryResult<CalendarRegulationStatusesQuery, CalendarRegulationStatusesQueryVariables>;
export const CalendarRegulationsSportsDocument = Apollo.gql`
    query CalendarRegulationsSports($id: UUID!, $sportId: UUID!) {
  dirSportEventRegulationses(where: {dirCalendar: {id: $id}, dirSport: {id: $sportId}}) {
    id
    dirSport {
      id
      fullName
    }
    regulationsDocument {
      id
      name
      path
    }
    fcpsrAddRegulationsDoc {
      personProfile {
        lastname
        patronymic
        firstname
        email
      }
      regular {
        lastname
        patronymic
        firstname
      }
      email
    }
    regulationsAmendedDocument {
      id
      name
      path
    }
    regulationsAgreedDocument {
      id
      name
      path
    }
    status(orderBy: createdAt_DESC) {
      ...RegulationStatus
    }
  }
}
    ${RegulationStatusFragmentDoc}`;

/**
 * __useCalendarRegulationsSportsQuery__
 *
 * To run a query within a React component, call `useCalendarRegulationsSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarRegulationsSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarRegulationsSportsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useCalendarRegulationsSportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarRegulationsSportsQuery, CalendarRegulationsSportsQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarRegulationsSportsQuery, CalendarRegulationsSportsQueryVariables>(CalendarRegulationsSportsDocument, baseOptions);
      }
export function useCalendarRegulationsSportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarRegulationsSportsQuery, CalendarRegulationsSportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarRegulationsSportsQuery, CalendarRegulationsSportsQueryVariables>(CalendarRegulationsSportsDocument, baseOptions);
        }
export type CalendarRegulationsSportsQueryHookResult = ReturnType<typeof useCalendarRegulationsSportsQuery>;
export type CalendarRegulationsSportsLazyQueryHookResult = ReturnType<typeof useCalendarRegulationsSportsLazyQuery>;
export type CalendarRegulationsSportsQueryResult = Apollo.QueryResult<CalendarRegulationsSportsQuery, CalendarRegulationsSportsQueryVariables>;
export const CalendarRegulationStatusValuesDocument = Apollo.gql`
    query CalendarRegulationStatusValues($filter: DirSportingEventRegulationStatusValueWhereInput) {
  dirSportingEventRegulationStatusValues(where: $filter) {
    ...RegulationStatusValues
  }
}
    ${RegulationStatusValuesFragmentDoc}`;

/**
 * __useCalendarRegulationStatusValuesQuery__
 *
 * To run a query within a React component, call `useCalendarRegulationStatusValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarRegulationStatusValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarRegulationStatusValuesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCalendarRegulationStatusValuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarRegulationStatusValuesQuery, CalendarRegulationStatusValuesQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarRegulationStatusValuesQuery, CalendarRegulationStatusValuesQueryVariables>(CalendarRegulationStatusValuesDocument, baseOptions);
      }
export function useCalendarRegulationStatusValuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarRegulationStatusValuesQuery, CalendarRegulationStatusValuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarRegulationStatusValuesQuery, CalendarRegulationStatusValuesQueryVariables>(CalendarRegulationStatusValuesDocument, baseOptions);
        }
export type CalendarRegulationStatusValuesQueryHookResult = ReturnType<typeof useCalendarRegulationStatusValuesQuery>;
export type CalendarRegulationStatusValuesLazyQueryHookResult = ReturnType<typeof useCalendarRegulationStatusValuesLazyQuery>;
export type CalendarRegulationStatusValuesQueryResult = Apollo.QueryResult<CalendarRegulationStatusValuesQuery, CalendarRegulationStatusValuesQueryVariables>;
export const CalendarRegulationsDocumentDocument = Apollo.gql`
    query CalendarRegulationsDocument($id: UUID!) {
  dirSportEventRegulations(where: {id: $id}) {
    id
    regulationsDocument {
      id
      path
      name
    }
  }
}
    `;

/**
 * __useCalendarRegulationsDocumentQuery__
 *
 * To run a query within a React component, call `useCalendarRegulationsDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarRegulationsDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarRegulationsDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarRegulationsDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarRegulationsDocumentQuery, CalendarRegulationsDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarRegulationsDocumentQuery, CalendarRegulationsDocumentQueryVariables>(CalendarRegulationsDocumentDocument, baseOptions);
      }
export function useCalendarRegulationsDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarRegulationsDocumentQuery, CalendarRegulationsDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarRegulationsDocumentQuery, CalendarRegulationsDocumentQueryVariables>(CalendarRegulationsDocumentDocument, baseOptions);
        }
export type CalendarRegulationsDocumentQueryHookResult = ReturnType<typeof useCalendarRegulationsDocumentQuery>;
export type CalendarRegulationsDocumentLazyQueryHookResult = ReturnType<typeof useCalendarRegulationsDocumentLazyQuery>;
export type CalendarRegulationsDocumentQueryResult = Apollo.QueryResult<CalendarRegulationsDocumentQuery, CalendarRegulationsDocumentQueryVariables>;
export const CalendarRegulationsAmendedDocumentDocument = Apollo.gql`
    query CalendarRegulationsAmendedDocument($id: UUID!) {
  dirSportEventRegulations(where: {id: $id}) {
    id
    regulationsAmendedDocument {
      id
      path
      name
    }
  }
}
    `;

/**
 * __useCalendarRegulationsAmendedDocumentQuery__
 *
 * To run a query within a React component, call `useCalendarRegulationsAmendedDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarRegulationsAmendedDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarRegulationsAmendedDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarRegulationsAmendedDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarRegulationsAmendedDocumentQuery, CalendarRegulationsAmendedDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarRegulationsAmendedDocumentQuery, CalendarRegulationsAmendedDocumentQueryVariables>(CalendarRegulationsAmendedDocumentDocument, baseOptions);
      }
export function useCalendarRegulationsAmendedDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarRegulationsAmendedDocumentQuery, CalendarRegulationsAmendedDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarRegulationsAmendedDocumentQuery, CalendarRegulationsAmendedDocumentQueryVariables>(CalendarRegulationsAmendedDocumentDocument, baseOptions);
        }
export type CalendarRegulationsAmendedDocumentQueryHookResult = ReturnType<typeof useCalendarRegulationsAmendedDocumentQuery>;
export type CalendarRegulationsAmendedDocumentLazyQueryHookResult = ReturnType<typeof useCalendarRegulationsAmendedDocumentLazyQuery>;
export type CalendarRegulationsAmendedDocumentQueryResult = Apollo.QueryResult<CalendarRegulationsAmendedDocumentQuery, CalendarRegulationsAmendedDocumentQueryVariables>;
export const CalendarRegulationsAgreedDocumentDocument = Apollo.gql`
    query CalendarRegulationsAgreedDocument($id: UUID!) {
  dirSportEventRegulations(where: {id: $id}) {
    id
    regulationsAgreedDocument {
      id
      path
      name
    }
  }
}
    `;

/**
 * __useCalendarRegulationsAgreedDocumentQuery__
 *
 * To run a query within a React component, call `useCalendarRegulationsAgreedDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarRegulationsAgreedDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarRegulationsAgreedDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarRegulationsAgreedDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarRegulationsAgreedDocumentQuery, CalendarRegulationsAgreedDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarRegulationsAgreedDocumentQuery, CalendarRegulationsAgreedDocumentQueryVariables>(CalendarRegulationsAgreedDocumentDocument, baseOptions);
      }
export function useCalendarRegulationsAgreedDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarRegulationsAgreedDocumentQuery, CalendarRegulationsAgreedDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarRegulationsAgreedDocumentQuery, CalendarRegulationsAgreedDocumentQueryVariables>(CalendarRegulationsAgreedDocumentDocument, baseOptions);
        }
export type CalendarRegulationsAgreedDocumentQueryHookResult = ReturnType<typeof useCalendarRegulationsAgreedDocumentQuery>;
export type CalendarRegulationsAgreedDocumentLazyQueryHookResult = ReturnType<typeof useCalendarRegulationsAgreedDocumentLazyQuery>;
export type CalendarRegulationsAgreedDocumentQueryResult = Apollo.QueryResult<CalendarRegulationsAgreedDocumentQuery, CalendarRegulationsAgreedDocumentQueryVariables>;
export const CalendarGRegulationsDocument = Apollo.gql`
    query CalendarGRegulations($id: UUID!) {
  dirCalendar(where: {id: $id}) {
    id
    sportCompetitionRegulationDocument {
      name
      path
      id
      date: createdAt
    }
    dirSportEventRegulations(orderBy: createdAt_ASC) {
      id
      dirSport {
        id
        fullName
      }
      regulationsDocument {
        id
        name
        path
      }
      regulationsAmendedDocument {
        id
        name
        path
      }
      regulationsAgreedDocument {
        id
        name
        path
      }
      status(orderBy: createdAt_DESC) {
        ...RegulationStatus
      }
    }
  }
}
    ${RegulationStatusFragmentDoc}`;

/**
 * __useCalendarGRegulationsQuery__
 *
 * To run a query within a React component, call `useCalendarGRegulationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarGRegulationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarGRegulationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarGRegulationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarGRegulationsQuery, CalendarGRegulationsQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarGRegulationsQuery, CalendarGRegulationsQueryVariables>(CalendarGRegulationsDocument, baseOptions);
      }
export function useCalendarGRegulationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarGRegulationsQuery, CalendarGRegulationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarGRegulationsQuery, CalendarGRegulationsQueryVariables>(CalendarGRegulationsDocument, baseOptions);
        }
export type CalendarGRegulationsQueryHookResult = ReturnType<typeof useCalendarGRegulationsQuery>;
export type CalendarGRegulationsLazyQueryHookResult = ReturnType<typeof useCalendarGRegulationsLazyQuery>;
export type CalendarGRegulationsQueryResult = Apollo.QueryResult<CalendarGRegulationsQuery, CalendarGRegulationsQueryVariables>;
export const AddQuotaForStageTwoBySportsDocument = Apollo.gql`
    mutation AddQuotaForStageTwoBySports($data: QuotaForStageTwoBySportCreateInput!) {
  createQuotaForStageTwoBySport(data: $data) {
    id
  }
}
    `;
export type AddQuotaForStageTwoBySportsMutationFn = Apollo.MutationFunction<AddQuotaForStageTwoBySportsMutation, AddQuotaForStageTwoBySportsMutationVariables>;

/**
 * __useAddQuotaForStageTwoBySportsMutation__
 *
 * To run a mutation, you first call `useAddQuotaForStageTwoBySportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuotaForStageTwoBySportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuotaForStageTwoBySportsMutation, { data, loading, error }] = useAddQuotaForStageTwoBySportsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddQuotaForStageTwoBySportsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddQuotaForStageTwoBySportsMutation, AddQuotaForStageTwoBySportsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddQuotaForStageTwoBySportsMutation, AddQuotaForStageTwoBySportsMutationVariables>(AddQuotaForStageTwoBySportsDocument, baseOptions);
      }
export type AddQuotaForStageTwoBySportsMutationHookResult = ReturnType<typeof useAddQuotaForStageTwoBySportsMutation>;
export type AddQuotaForStageTwoBySportsMutationResult = Apollo.MutationResult<AddQuotaForStageTwoBySportsMutation>;
export type AddQuotaForStageTwoBySportsMutationOptions = Apollo.BaseMutationOptions<AddQuotaForStageTwoBySportsMutation, AddQuotaForStageTwoBySportsMutationVariables>;
export const UpdateQuotaForStageTwoBySportsDocument = Apollo.gql`
    mutation UpdateQuotaForStageTwoBySports($data: QuotaForStageTwoBySportUpdateInput!, $idQuota: UUID!) {
  updateQuotaForStageTwoBySport(where: {id: $idQuota}, data: $data) {
    id
  }
}
    `;
export type UpdateQuotaForStageTwoBySportsMutationFn = Apollo.MutationFunction<UpdateQuotaForStageTwoBySportsMutation, UpdateQuotaForStageTwoBySportsMutationVariables>;

/**
 * __useUpdateQuotaForStageTwoBySportsMutation__
 *
 * To run a mutation, you first call `useUpdateQuotaForStageTwoBySportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuotaForStageTwoBySportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuotaForStageTwoBySportsMutation, { data, loading, error }] = useUpdateQuotaForStageTwoBySportsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      idQuota: // value for 'idQuota'
 *   },
 * });
 */
export function useUpdateQuotaForStageTwoBySportsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateQuotaForStageTwoBySportsMutation, UpdateQuotaForStageTwoBySportsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateQuotaForStageTwoBySportsMutation, UpdateQuotaForStageTwoBySportsMutationVariables>(UpdateQuotaForStageTwoBySportsDocument, baseOptions);
      }
export type UpdateQuotaForStageTwoBySportsMutationHookResult = ReturnType<typeof useUpdateQuotaForStageTwoBySportsMutation>;
export type UpdateQuotaForStageTwoBySportsMutationResult = Apollo.MutationResult<UpdateQuotaForStageTwoBySportsMutation>;
export type UpdateQuotaForStageTwoBySportsMutationOptions = Apollo.BaseMutationOptions<UpdateQuotaForStageTwoBySportsMutation, UpdateQuotaForStageTwoBySportsMutationVariables>;
export const DeleteQuotaForStageTwoBySportsDocument = Apollo.gql`
    mutation DeleteQuotaForStageTwoBySports($id: UUID!) {
  deleteQuotaForStageTwoBySport(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteQuotaForStageTwoBySportsMutationFn = Apollo.MutationFunction<DeleteQuotaForStageTwoBySportsMutation, DeleteQuotaForStageTwoBySportsMutationVariables>;

/**
 * __useDeleteQuotaForStageTwoBySportsMutation__
 *
 * To run a mutation, you first call `useDeleteQuotaForStageTwoBySportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuotaForStageTwoBySportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuotaForStageTwoBySportsMutation, { data, loading, error }] = useDeleteQuotaForStageTwoBySportsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuotaForStageTwoBySportsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteQuotaForStageTwoBySportsMutation, DeleteQuotaForStageTwoBySportsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteQuotaForStageTwoBySportsMutation, DeleteQuotaForStageTwoBySportsMutationVariables>(DeleteQuotaForStageTwoBySportsDocument, baseOptions);
      }
export type DeleteQuotaForStageTwoBySportsMutationHookResult = ReturnType<typeof useDeleteQuotaForStageTwoBySportsMutation>;
export type DeleteQuotaForStageTwoBySportsMutationResult = Apollo.MutationResult<DeleteQuotaForStageTwoBySportsMutation>;
export type DeleteQuotaForStageTwoBySportsMutationOptions = Apollo.BaseMutationOptions<DeleteQuotaForStageTwoBySportsMutation, DeleteQuotaForStageTwoBySportsMutationVariables>;
export const UpdateDateQuoteRequestApplicationDocument = Apollo.gql`
    mutation UpdateDateQuoteRequestApplication($id: UUID!, $date: DateTime) {
  updateDirCalendar(data: {dateAcceptQuotasAndFilesStageTwo: $date}, where: {id: $id}) {
    dateAcceptQuotasAndFilesStageTwo
  }
}
    `;
export type UpdateDateQuoteRequestApplicationMutationFn = Apollo.MutationFunction<UpdateDateQuoteRequestApplicationMutation, UpdateDateQuoteRequestApplicationMutationVariables>;

/**
 * __useUpdateDateQuoteRequestApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateDateQuoteRequestApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDateQuoteRequestApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDateQuoteRequestApplicationMutation, { data, loading, error }] = useUpdateDateQuoteRequestApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdateDateQuoteRequestApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDateQuoteRequestApplicationMutation, UpdateDateQuoteRequestApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDateQuoteRequestApplicationMutation, UpdateDateQuoteRequestApplicationMutationVariables>(UpdateDateQuoteRequestApplicationDocument, baseOptions);
      }
export type UpdateDateQuoteRequestApplicationMutationHookResult = ReturnType<typeof useUpdateDateQuoteRequestApplicationMutation>;
export type UpdateDateQuoteRequestApplicationMutationResult = Apollo.MutationResult<UpdateDateQuoteRequestApplicationMutation>;
export type UpdateDateQuoteRequestApplicationMutationOptions = Apollo.BaseMutationOptions<UpdateDateQuoteRequestApplicationMutation, UpdateDateQuoteRequestApplicationMutationVariables>;
export const UpdateQuoteByRegionDocument = Apollo.gql`
    mutation UpdateQuoteByRegion($id: UUID, $data: QuantyRequestStageTwoUpdateInput!) {
  updateQuantyRequestStageTwo(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdateQuoteByRegionMutationFn = Apollo.MutationFunction<UpdateQuoteByRegionMutation, UpdateQuoteByRegionMutationVariables>;

/**
 * __useUpdateQuoteByRegionMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteByRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteByRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteByRegionMutation, { data, loading, error }] = useUpdateQuoteByRegionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateQuoteByRegionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateQuoteByRegionMutation, UpdateQuoteByRegionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateQuoteByRegionMutation, UpdateQuoteByRegionMutationVariables>(UpdateQuoteByRegionDocument, baseOptions);
      }
export type UpdateQuoteByRegionMutationHookResult = ReturnType<typeof useUpdateQuoteByRegionMutation>;
export type UpdateQuoteByRegionMutationResult = Apollo.MutationResult<UpdateQuoteByRegionMutation>;
export type UpdateQuoteByRegionMutationOptions = Apollo.BaseMutationOptions<UpdateQuoteByRegionMutation, UpdateQuoteByRegionMutationVariables>;
export const CreateQuoteByRegionDocument = Apollo.gql`
    mutation CreateQuoteByRegion($data: QuantyRequestStageTwoCreateInput!) {
  createQuantyRequestStageTwo(data: $data) {
    id
  }
}
    `;
export type CreateQuoteByRegionMutationFn = Apollo.MutationFunction<CreateQuoteByRegionMutation, CreateQuoteByRegionMutationVariables>;

/**
 * __useCreateQuoteByRegionMutation__
 *
 * To run a mutation, you first call `useCreateQuoteByRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteByRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteByRegionMutation, { data, loading, error }] = useCreateQuoteByRegionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateQuoteByRegionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateQuoteByRegionMutation, CreateQuoteByRegionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateQuoteByRegionMutation, CreateQuoteByRegionMutationVariables>(CreateQuoteByRegionDocument, baseOptions);
      }
export type CreateQuoteByRegionMutationHookResult = ReturnType<typeof useCreateQuoteByRegionMutation>;
export type CreateQuoteByRegionMutationResult = Apollo.MutationResult<CreateQuoteByRegionMutation>;
export type CreateQuoteByRegionMutationOptions = Apollo.BaseMutationOptions<CreateQuoteByRegionMutation, CreateQuoteByRegionMutationVariables>;
export const CreateReqPartFromStageTwoDocument = Apollo.gql`
    mutation createReqPartFromStageTwo($data: ReqPartFromStageTwoCreateInput!) {
  createReqPartFromStageTwo(data: $data) {
    id
  }
}
    `;
export type CreateReqPartFromStageTwoMutationFn = Apollo.MutationFunction<CreateReqPartFromStageTwoMutation, CreateReqPartFromStageTwoMutationVariables>;

/**
 * __useCreateReqPartFromStageTwoMutation__
 *
 * To run a mutation, you first call `useCreateReqPartFromStageTwoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReqPartFromStageTwoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReqPartFromStageTwoMutation, { data, loading, error }] = useCreateReqPartFromStageTwoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReqPartFromStageTwoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReqPartFromStageTwoMutation, CreateReqPartFromStageTwoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReqPartFromStageTwoMutation, CreateReqPartFromStageTwoMutationVariables>(CreateReqPartFromStageTwoDocument, baseOptions);
      }
export type CreateReqPartFromStageTwoMutationHookResult = ReturnType<typeof useCreateReqPartFromStageTwoMutation>;
export type CreateReqPartFromStageTwoMutationResult = Apollo.MutationResult<CreateReqPartFromStageTwoMutation>;
export type CreateReqPartFromStageTwoMutationOptions = Apollo.BaseMutationOptions<CreateReqPartFromStageTwoMutation, CreateReqPartFromStageTwoMutationVariables>;
export const UpdateReqPartFromStageTwoDocument = Apollo.gql`
    mutation updateReqPartFromStageTwo($data: ReqPartFromStageTwoUpdateInput!, $where: ReqPartFromStageTwoWhereUniqueInput!) {
  updateReqPartFromStageTwo(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateReqPartFromStageTwoMutationFn = Apollo.MutationFunction<UpdateReqPartFromStageTwoMutation, UpdateReqPartFromStageTwoMutationVariables>;

/**
 * __useUpdateReqPartFromStageTwoMutation__
 *
 * To run a mutation, you first call `useUpdateReqPartFromStageTwoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReqPartFromStageTwoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReqPartFromStageTwoMutation, { data, loading, error }] = useUpdateReqPartFromStageTwoMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateReqPartFromStageTwoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReqPartFromStageTwoMutation, UpdateReqPartFromStageTwoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateReqPartFromStageTwoMutation, UpdateReqPartFromStageTwoMutationVariables>(UpdateReqPartFromStageTwoDocument, baseOptions);
      }
export type UpdateReqPartFromStageTwoMutationHookResult = ReturnType<typeof useUpdateReqPartFromStageTwoMutation>;
export type UpdateReqPartFromStageTwoMutationResult = Apollo.MutationResult<UpdateReqPartFromStageTwoMutation>;
export type UpdateReqPartFromStageTwoMutationOptions = Apollo.BaseMutationOptions<UpdateReqPartFromStageTwoMutation, UpdateReqPartFromStageTwoMutationVariables>;
export const DeleteReqPartFromStageTwoDocument = Apollo.gql`
    mutation deleteReqPartFromStageTwo($id: UUID!) {
  deleteReqPartFromStageTwo(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteReqPartFromStageTwoMutationFn = Apollo.MutationFunction<DeleteReqPartFromStageTwoMutation, DeleteReqPartFromStageTwoMutationVariables>;

/**
 * __useDeleteReqPartFromStageTwoMutation__
 *
 * To run a mutation, you first call `useDeleteReqPartFromStageTwoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReqPartFromStageTwoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReqPartFromStageTwoMutation, { data, loading, error }] = useDeleteReqPartFromStageTwoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReqPartFromStageTwoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteReqPartFromStageTwoMutation, DeleteReqPartFromStageTwoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteReqPartFromStageTwoMutation, DeleteReqPartFromStageTwoMutationVariables>(DeleteReqPartFromStageTwoDocument, baseOptions);
      }
export type DeleteReqPartFromStageTwoMutationHookResult = ReturnType<typeof useDeleteReqPartFromStageTwoMutation>;
export type DeleteReqPartFromStageTwoMutationResult = Apollo.MutationResult<DeleteReqPartFromStageTwoMutation>;
export type DeleteReqPartFromStageTwoMutationOptions = Apollo.BaseMutationOptions<DeleteReqPartFromStageTwoMutation, DeleteReqPartFromStageTwoMutationVariables>;
export const FetchListSportsRequestApplicationDocument = Apollo.gql`
    query FetchListSportsRequestApplication($IdCalendar: UUID!) {
  dirSportingEvents(where: {archive: null, dirCalendar: {id: $IdCalendar}, clsEventStages_every: {registryNumber: 100002922}}) {
    sports {
      id
      dirSport {
        id
        fullName
      }
    }
  }
  quotaForStageTwoBySports(where: {dirCalendar: {id: $IdCalendar}}) {
    quotaAccompStageTwo
    quotaMaleAthlStageTwo
    quotaWomenAthlStageTwo
    dirSport {
      id
    }
    id
  }
}
    `;

/**
 * __useFetchListSportsRequestApplicationQuery__
 *
 * To run a query within a React component, call `useFetchListSportsRequestApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchListSportsRequestApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchListSportsRequestApplicationQuery({
 *   variables: {
 *      IdCalendar: // value for 'IdCalendar'
 *   },
 * });
 */
export function useFetchListSportsRequestApplicationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchListSportsRequestApplicationQuery, FetchListSportsRequestApplicationQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchListSportsRequestApplicationQuery, FetchListSportsRequestApplicationQueryVariables>(FetchListSportsRequestApplicationDocument, baseOptions);
      }
export function useFetchListSportsRequestApplicationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchListSportsRequestApplicationQuery, FetchListSportsRequestApplicationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchListSportsRequestApplicationQuery, FetchListSportsRequestApplicationQueryVariables>(FetchListSportsRequestApplicationDocument, baseOptions);
        }
export type FetchListSportsRequestApplicationQueryHookResult = ReturnType<typeof useFetchListSportsRequestApplicationQuery>;
export type FetchListSportsRequestApplicationLazyQueryHookResult = ReturnType<typeof useFetchListSportsRequestApplicationLazyQuery>;
export type FetchListSportsRequestApplicationQueryResult = Apollo.QueryResult<FetchListSportsRequestApplicationQuery, FetchListSportsRequestApplicationQueryVariables>;
export const GetDateQuoteRequestApplicationDocument = Apollo.gql`
    query GetDateQuoteRequestApplication($id: UUID!) {
  dirCalendar(where: {id: $id}) {
    dateAcceptQuotasAndFilesStageTwo
  }
}
    `;

/**
 * __useGetDateQuoteRequestApplicationQuery__
 *
 * To run a query within a React component, call `useGetDateQuoteRequestApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDateQuoteRequestApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDateQuoteRequestApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDateQuoteRequestApplicationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDateQuoteRequestApplicationQuery, GetDateQuoteRequestApplicationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDateQuoteRequestApplicationQuery, GetDateQuoteRequestApplicationQueryVariables>(GetDateQuoteRequestApplicationDocument, baseOptions);
      }
export function useGetDateQuoteRequestApplicationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDateQuoteRequestApplicationQuery, GetDateQuoteRequestApplicationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDateQuoteRequestApplicationQuery, GetDateQuoteRequestApplicationQueryVariables>(GetDateQuoteRequestApplicationDocument, baseOptions);
        }
export type GetDateQuoteRequestApplicationQueryHookResult = ReturnType<typeof useGetDateQuoteRequestApplicationQuery>;
export type GetDateQuoteRequestApplicationLazyQueryHookResult = ReturnType<typeof useGetDateQuoteRequestApplicationLazyQuery>;
export type GetDateQuoteRequestApplicationQueryResult = Apollo.QueryResult<GetDateQuoteRequestApplicationQuery, GetDateQuoteRequestApplicationQueryVariables>;
export const GetDataForQuoteByDirRegionDocument = Apollo.gql`
    query GetDataForQuoteByDirRegion($idCalendar: UUID!, $idRegion: UUID) {
  quantyRequestStageTwoes(where: {dirRegion: {id: $idRegion}, dirCalendar: {id: $idCalendar}}) {
    id
    regionQuotaWomenAthlStageTwo
    regionQuotaMaleAthlStageTwo
    regionQuotaAccompStageTwo
    dirSport {
      id
      fullName
    }
  }
}
    `;

/**
 * __useGetDataForQuoteByDirRegionQuery__
 *
 * To run a query within a React component, call `useGetDataForQuoteByDirRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForQuoteByDirRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForQuoteByDirRegionQuery({
 *   variables: {
 *      idCalendar: // value for 'idCalendar'
 *      idRegion: // value for 'idRegion'
 *   },
 * });
 */
export function useGetDataForQuoteByDirRegionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDataForQuoteByDirRegionQuery, GetDataForQuoteByDirRegionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDataForQuoteByDirRegionQuery, GetDataForQuoteByDirRegionQueryVariables>(GetDataForQuoteByDirRegionDocument, baseOptions);
      }
export function useGetDataForQuoteByDirRegionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDataForQuoteByDirRegionQuery, GetDataForQuoteByDirRegionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDataForQuoteByDirRegionQuery, GetDataForQuoteByDirRegionQueryVariables>(GetDataForQuoteByDirRegionDocument, baseOptions);
        }
export type GetDataForQuoteByDirRegionQueryHookResult = ReturnType<typeof useGetDataForQuoteByDirRegionQuery>;
export type GetDataForQuoteByDirRegionLazyQueryHookResult = ReturnType<typeof useGetDataForQuoteByDirRegionLazyQuery>;
export type GetDataForQuoteByDirRegionQueryResult = Apollo.QueryResult<GetDataForQuoteByDirRegionQuery, GetDataForQuoteByDirRegionQueryVariables>;
export const GetReqPartFromStageTwoesDocument = Apollo.gql`
    query GetReqPartFromStageTwoes($where: ReqPartFromStageTwoWhereInput) {
  reqPartFromStageTwoes(where: $where) {
    id
    region {
      id
    }
    roivSpecialistsForNotification {
      id
      email
      regular {
        firstname
        lastname
        patronymic
      }
    }
    filesROIVStageTwo {
      id
      name
      path
    }
  }
}
    `;

/**
 * __useGetReqPartFromStageTwoesQuery__
 *
 * To run a query within a React component, call `useGetReqPartFromStageTwoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReqPartFromStageTwoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReqPartFromStageTwoesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetReqPartFromStageTwoesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReqPartFromStageTwoesQuery, GetReqPartFromStageTwoesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReqPartFromStageTwoesQuery, GetReqPartFromStageTwoesQueryVariables>(GetReqPartFromStageTwoesDocument, baseOptions);
      }
export function useGetReqPartFromStageTwoesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReqPartFromStageTwoesQuery, GetReqPartFromStageTwoesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReqPartFromStageTwoesQuery, GetReqPartFromStageTwoesQueryVariables>(GetReqPartFromStageTwoesDocument, baseOptions);
        }
export type GetReqPartFromStageTwoesQueryHookResult = ReturnType<typeof useGetReqPartFromStageTwoesQuery>;
export type GetReqPartFromStageTwoesLazyQueryHookResult = ReturnType<typeof useGetReqPartFromStageTwoesLazyQuery>;
export type GetReqPartFromStageTwoesQueryResult = Apollo.QueryResult<GetReqPartFromStageTwoesQuery, GetReqPartFromStageTwoesQueryVariables>;
export const ValidationEditableStepOneDocument = Apollo.gql`
    query ValidationEditableStepOne($id: UUID!, $idSport: UUID!) {
  dirSportingEvents(where: {dirCalendar: {id: $id}, sports_some: {dirSport: {id: $idSport}}, daysBeforeStartToAddProtocols_gt: 0, AND: {OR: [{clsEventStages_some: {registryNumber: 100003458}}, {clsEventStages_some: {registryNumber: 100003441}}]}}) {
    id
    daysBeforeStartToAddProtocols
    clsEventStages {
      fullName
      shortName
    }
  }
}
    `;

/**
 * __useValidationEditableStepOneQuery__
 *
 * To run a query within a React component, call `useValidationEditableStepOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidationEditableStepOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidationEditableStepOneQuery({
 *   variables: {
 *      id: // value for 'id'
 *      idSport: // value for 'idSport'
 *   },
 * });
 */
export function useValidationEditableStepOneQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidationEditableStepOneQuery, ValidationEditableStepOneQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidationEditableStepOneQuery, ValidationEditableStepOneQueryVariables>(ValidationEditableStepOneDocument, baseOptions);
      }
export function useValidationEditableStepOneLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidationEditableStepOneQuery, ValidationEditableStepOneQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidationEditableStepOneQuery, ValidationEditableStepOneQueryVariables>(ValidationEditableStepOneDocument, baseOptions);
        }
export type ValidationEditableStepOneQueryHookResult = ReturnType<typeof useValidationEditableStepOneQuery>;
export type ValidationEditableStepOneLazyQueryHookResult = ReturnType<typeof useValidationEditableStepOneLazyQuery>;
export type ValidationEditableStepOneQueryResult = Apollo.QueryResult<ValidationEditableStepOneQuery, ValidationEditableStepOneQueryVariables>;
export const ValidationEditableEventBlockDocument = Apollo.gql`
    query ValidationEditableEventBlock($id: UUID!, $idSport: UUID!, $dateValid: DateTime!) {
  dirSportingEvents(where: {dirCalendar: {id: $id}, sports_some: {dirSport: {id: $idSport}}, startDate_lt: $dateValid, AND: {OR: [{clsEventStages_some: {registryNumber: 100003458}}, {clsEventStages_some: {registryNumber: 100003441}}]}}) {
    id
    clsEventStages {
      fullName
      shortName
    }
  }
}
    `;

/**
 * __useValidationEditableEventBlockQuery__
 *
 * To run a query within a React component, call `useValidationEditableEventBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidationEditableEventBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidationEditableEventBlockQuery({
 *   variables: {
 *      id: // value for 'id'
 *      idSport: // value for 'idSport'
 *      dateValid: // value for 'dateValid'
 *   },
 * });
 */
export function useValidationEditableEventBlockQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidationEditableEventBlockQuery, ValidationEditableEventBlockQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidationEditableEventBlockQuery, ValidationEditableEventBlockQueryVariables>(ValidationEditableEventBlockDocument, baseOptions);
      }
export function useValidationEditableEventBlockLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidationEditableEventBlockQuery, ValidationEditableEventBlockQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidationEditableEventBlockQuery, ValidationEditableEventBlockQueryVariables>(ValidationEditableEventBlockDocument, baseOptions);
        }
export type ValidationEditableEventBlockQueryHookResult = ReturnType<typeof useValidationEditableEventBlockQuery>;
export type ValidationEditableEventBlockLazyQueryHookResult = ReturnType<typeof useValidationEditableEventBlockLazyQuery>;
export type ValidationEditableEventBlockQueryResult = Apollo.QueryResult<ValidationEditableEventBlockQuery, ValidationEditableEventBlockQueryVariables>;
export const VenueAddDirDocumentDocument = Apollo.gql`
    mutation VenueAddDirDocument($idCalendar: UUID, $data: DirCalendarUpdateInput!) {
  updateDirCalendar(where: {id: $idCalendar}, data: $data) {
    id
  }
}
    `;
export type VenueAddDirDocumentMutationFn = Apollo.MutationFunction<VenueAddDirDocumentMutation, VenueAddDirDocumentMutationVariables>;

/**
 * __useVenueAddDirDocumentMutation__
 *
 * To run a mutation, you first call `useVenueAddDirDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVenueAddDirDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [venueAddDirDocumentMutation, { data, loading, error }] = useVenueAddDirDocumentMutation({
 *   variables: {
 *      idCalendar: // value for 'idCalendar'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVenueAddDirDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VenueAddDirDocumentMutation, VenueAddDirDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<VenueAddDirDocumentMutation, VenueAddDirDocumentMutationVariables>(VenueAddDirDocumentDocument, baseOptions);
      }
export type VenueAddDirDocumentMutationHookResult = ReturnType<typeof useVenueAddDirDocumentMutation>;
export type VenueAddDirDocumentMutationResult = Apollo.MutationResult<VenueAddDirDocumentMutation>;
export type VenueAddDirDocumentMutationOptions = Apollo.BaseMutationOptions<VenueAddDirDocumentMutation, VenueAddDirDocumentMutationVariables>;
export const CreateVenueCompetitionDocument = Apollo.gql`
    mutation CreateVenueCompetition($data: VenueCompetitionCreateInput!) {
  createVenueCompetition(data: $data) {
    id
  }
}
    `;
export type CreateVenueCompetitionMutationFn = Apollo.MutationFunction<CreateVenueCompetitionMutation, CreateVenueCompetitionMutationVariables>;

/**
 * __useCreateVenueCompetitionMutation__
 *
 * To run a mutation, you first call `useCreateVenueCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVenueCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVenueCompetitionMutation, { data, loading, error }] = useCreateVenueCompetitionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVenueCompetitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateVenueCompetitionMutation, CreateVenueCompetitionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateVenueCompetitionMutation, CreateVenueCompetitionMutationVariables>(CreateVenueCompetitionDocument, baseOptions);
      }
export type CreateVenueCompetitionMutationHookResult = ReturnType<typeof useCreateVenueCompetitionMutation>;
export type CreateVenueCompetitionMutationResult = Apollo.MutationResult<CreateVenueCompetitionMutation>;
export type CreateVenueCompetitionMutationOptions = Apollo.BaseMutationOptions<CreateVenueCompetitionMutation, CreateVenueCompetitionMutationVariables>;
export const UpdateVenueCompetitionDocument = Apollo.gql`
    mutation UpdateVenueCompetition($id: UUID!, $data: VenueCompetitionUpdateInput!) {
  updateVenueCompetition(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdateVenueCompetitionMutationFn = Apollo.MutationFunction<UpdateVenueCompetitionMutation, UpdateVenueCompetitionMutationVariables>;

/**
 * __useUpdateVenueCompetitionMutation__
 *
 * To run a mutation, you first call `useUpdateVenueCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVenueCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVenueCompetitionMutation, { data, loading, error }] = useUpdateVenueCompetitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVenueCompetitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVenueCompetitionMutation, UpdateVenueCompetitionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateVenueCompetitionMutation, UpdateVenueCompetitionMutationVariables>(UpdateVenueCompetitionDocument, baseOptions);
      }
export type UpdateVenueCompetitionMutationHookResult = ReturnType<typeof useUpdateVenueCompetitionMutation>;
export type UpdateVenueCompetitionMutationResult = Apollo.MutationResult<UpdateVenueCompetitionMutation>;
export type UpdateVenueCompetitionMutationOptions = Apollo.BaseMutationOptions<UpdateVenueCompetitionMutation, UpdateVenueCompetitionMutationVariables>;
export const DeleteVenueCompetitionDocument = Apollo.gql`
    mutation DeleteVenueCompetition($id: UUID!) {
  deleteVenueCompetition(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteVenueCompetitionMutationFn = Apollo.MutationFunction<DeleteVenueCompetitionMutation, DeleteVenueCompetitionMutationVariables>;

/**
 * __useDeleteVenueCompetitionMutation__
 *
 * To run a mutation, you first call `useDeleteVenueCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVenueCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVenueCompetitionMutation, { data, loading, error }] = useDeleteVenueCompetitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVenueCompetitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVenueCompetitionMutation, DeleteVenueCompetitionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteVenueCompetitionMutation, DeleteVenueCompetitionMutationVariables>(DeleteVenueCompetitionDocument, baseOptions);
      }
export type DeleteVenueCompetitionMutationHookResult = ReturnType<typeof useDeleteVenueCompetitionMutation>;
export type DeleteVenueCompetitionMutationResult = Apollo.MutationResult<DeleteVenueCompetitionMutation>;
export type DeleteVenueCompetitionMutationOptions = Apollo.BaseMutationOptions<DeleteVenueCompetitionMutation, DeleteVenueCompetitionMutationVariables>;
export const CreateVenueCompApplicationDocument = Apollo.gql`
    mutation CreateVenueCompApplication($dirRegion: UUID!, $compId: UUID!, $number: Int, $notificationSentToFCPSR: Boolean!) {
  createVenueCompApplication(data: {dirRegion: {connect: {id: $dirRegion}}, number: $number, notificationSentToFCPSR: $notificationSentToFCPSR, venueCompetition: {connect: {id: $compId}}}) {
    id
  }
}
    `;
export type CreateVenueCompApplicationMutationFn = Apollo.MutationFunction<CreateVenueCompApplicationMutation, CreateVenueCompApplicationMutationVariables>;

/**
 * __useCreateVenueCompApplicationMutation__
 *
 * To run a mutation, you first call `useCreateVenueCompApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVenueCompApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVenueCompApplicationMutation, { data, loading, error }] = useCreateVenueCompApplicationMutation({
 *   variables: {
 *      dirRegion: // value for 'dirRegion'
 *      compId: // value for 'compId'
 *      number: // value for 'number'
 *      notificationSentToFCPSR: // value for 'notificationSentToFCPSR'
 *   },
 * });
 */
export function useCreateVenueCompApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateVenueCompApplicationMutation, CreateVenueCompApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateVenueCompApplicationMutation, CreateVenueCompApplicationMutationVariables>(CreateVenueCompApplicationDocument, baseOptions);
      }
export type CreateVenueCompApplicationMutationHookResult = ReturnType<typeof useCreateVenueCompApplicationMutation>;
export type CreateVenueCompApplicationMutationResult = Apollo.MutationResult<CreateVenueCompApplicationMutation>;
export type CreateVenueCompApplicationMutationOptions = Apollo.BaseMutationOptions<CreateVenueCompApplicationMutation, CreateVenueCompApplicationMutationVariables>;
export const UpdateVenueCompApplicationDocument = Apollo.gql`
    mutation UpdateVenueCompApplication($compAppId: UUID!, $data: VenueCompApplicationUpdateInput!) {
  updateVenueCompApplication(data: $data, where: {id: $compAppId}) {
    id
  }
}
    `;
export type UpdateVenueCompApplicationMutationFn = Apollo.MutationFunction<UpdateVenueCompApplicationMutation, UpdateVenueCompApplicationMutationVariables>;

/**
 * __useUpdateVenueCompApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateVenueCompApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVenueCompApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVenueCompApplicationMutation, { data, loading, error }] = useUpdateVenueCompApplicationMutation({
 *   variables: {
 *      compAppId: // value for 'compAppId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVenueCompApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVenueCompApplicationMutation, UpdateVenueCompApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateVenueCompApplicationMutation, UpdateVenueCompApplicationMutationVariables>(UpdateVenueCompApplicationDocument, baseOptions);
      }
export type UpdateVenueCompApplicationMutationHookResult = ReturnType<typeof useUpdateVenueCompApplicationMutation>;
export type UpdateVenueCompApplicationMutationResult = Apollo.MutationResult<UpdateVenueCompApplicationMutation>;
export type UpdateVenueCompApplicationMutationOptions = Apollo.BaseMutationOptions<UpdateVenueCompApplicationMutation, UpdateVenueCompApplicationMutationVariables>;
export const DeleteVenueCompApplicationDocument = Apollo.gql`
    mutation deleteVenueCompApplication($compAppId: UUID!) {
  deleteVenueCompApplication(where: {id: $compAppId}) {
    id
  }
}
    `;
export type DeleteVenueCompApplicationMutationFn = Apollo.MutationFunction<DeleteVenueCompApplicationMutation, DeleteVenueCompApplicationMutationVariables>;

/**
 * __useDeleteVenueCompApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteVenueCompApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVenueCompApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVenueCompApplicationMutation, { data, loading, error }] = useDeleteVenueCompApplicationMutation({
 *   variables: {
 *      compAppId: // value for 'compAppId'
 *   },
 * });
 */
export function useDeleteVenueCompApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVenueCompApplicationMutation, DeleteVenueCompApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteVenueCompApplicationMutation, DeleteVenueCompApplicationMutationVariables>(DeleteVenueCompApplicationDocument, baseOptions);
      }
export type DeleteVenueCompApplicationMutationHookResult = ReturnType<typeof useDeleteVenueCompApplicationMutation>;
export type DeleteVenueCompApplicationMutationResult = Apollo.MutationResult<DeleteVenueCompApplicationMutation>;
export type DeleteVenueCompApplicationMutationOptions = Apollo.BaseMutationOptions<DeleteVenueCompApplicationMutation, DeleteVenueCompApplicationMutationVariables>;
export const PositionVenueCompetitionNotifyDocument = Apollo.gql`
    mutation PositionVenueCompetitionNotify($venueCompetitionId: String!) {
  positionVenueCompetitionNotify(venueCompetitionId: $venueCompetitionId)
}
    `;
export type PositionVenueCompetitionNotifyMutationFn = Apollo.MutationFunction<PositionVenueCompetitionNotifyMutation, PositionVenueCompetitionNotifyMutationVariables>;

/**
 * __usePositionVenueCompetitionNotifyMutation__
 *
 * To run a mutation, you first call `usePositionVenueCompetitionNotifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePositionVenueCompetitionNotifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [positionVenueCompetitionNotifyMutation, { data, loading, error }] = usePositionVenueCompetitionNotifyMutation({
 *   variables: {
 *      venueCompetitionId: // value for 'venueCompetitionId'
 *   },
 * });
 */
export function usePositionVenueCompetitionNotifyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PositionVenueCompetitionNotifyMutation, PositionVenueCompetitionNotifyMutationVariables>) {
        return ApolloReactHooks.useMutation<PositionVenueCompetitionNotifyMutation, PositionVenueCompetitionNotifyMutationVariables>(PositionVenueCompetitionNotifyDocument, baseOptions);
      }
export type PositionVenueCompetitionNotifyMutationHookResult = ReturnType<typeof usePositionVenueCompetitionNotifyMutation>;
export type PositionVenueCompetitionNotifyMutationResult = Apollo.MutationResult<PositionVenueCompetitionNotifyMutation>;
export type PositionVenueCompetitionNotifyMutationOptions = Apollo.BaseMutationOptions<PositionVenueCompetitionNotifyMutation, PositionVenueCompetitionNotifyMutationVariables>;
export const DecisionVenueCompetitionNotifyDocument = Apollo.gql`
    mutation DecisionVenueCompetitionNotify($venueCompetitionId: String!) {
  decisionVenueCompetitionNotify(venueCompetitionId: $venueCompetitionId)
}
    `;
export type DecisionVenueCompetitionNotifyMutationFn = Apollo.MutationFunction<DecisionVenueCompetitionNotifyMutation, DecisionVenueCompetitionNotifyMutationVariables>;

/**
 * __useDecisionVenueCompetitionNotifyMutation__
 *
 * To run a mutation, you first call `useDecisionVenueCompetitionNotifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDecisionVenueCompetitionNotifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [decisionVenueCompetitionNotifyMutation, { data, loading, error }] = useDecisionVenueCompetitionNotifyMutation({
 *   variables: {
 *      venueCompetitionId: // value for 'venueCompetitionId'
 *   },
 * });
 */
export function useDecisionVenueCompetitionNotifyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DecisionVenueCompetitionNotifyMutation, DecisionVenueCompetitionNotifyMutationVariables>) {
        return ApolloReactHooks.useMutation<DecisionVenueCompetitionNotifyMutation, DecisionVenueCompetitionNotifyMutationVariables>(DecisionVenueCompetitionNotifyDocument, baseOptions);
      }
export type DecisionVenueCompetitionNotifyMutationHookResult = ReturnType<typeof useDecisionVenueCompetitionNotifyMutation>;
export type DecisionVenueCompetitionNotifyMutationResult = Apollo.MutationResult<DecisionVenueCompetitionNotifyMutation>;
export type DecisionVenueCompetitionNotifyMutationOptions = Apollo.BaseMutationOptions<DecisionVenueCompetitionNotifyMutation, DecisionVenueCompetitionNotifyMutationVariables>;
export const AgreementVenueCompetitionNotifyDocument = Apollo.gql`
    mutation AgreementVenueCompetitionNotify($venueCompApplicationId: String!) {
  agreementVenueCompetitionNotify(venueCompApplicationId: $venueCompApplicationId)
}
    `;
export type AgreementVenueCompetitionNotifyMutationFn = Apollo.MutationFunction<AgreementVenueCompetitionNotifyMutation, AgreementVenueCompetitionNotifyMutationVariables>;

/**
 * __useAgreementVenueCompetitionNotifyMutation__
 *
 * To run a mutation, you first call `useAgreementVenueCompetitionNotifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgreementVenueCompetitionNotifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agreementVenueCompetitionNotifyMutation, { data, loading, error }] = useAgreementVenueCompetitionNotifyMutation({
 *   variables: {
 *      venueCompApplicationId: // value for 'venueCompApplicationId'
 *   },
 * });
 */
export function useAgreementVenueCompetitionNotifyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AgreementVenueCompetitionNotifyMutation, AgreementVenueCompetitionNotifyMutationVariables>) {
        return ApolloReactHooks.useMutation<AgreementVenueCompetitionNotifyMutation, AgreementVenueCompetitionNotifyMutationVariables>(AgreementVenueCompetitionNotifyDocument, baseOptions);
      }
export type AgreementVenueCompetitionNotifyMutationHookResult = ReturnType<typeof useAgreementVenueCompetitionNotifyMutation>;
export type AgreementVenueCompetitionNotifyMutationResult = Apollo.MutationResult<AgreementVenueCompetitionNotifyMutation>;
export type AgreementVenueCompetitionNotifyMutationOptions = Apollo.BaseMutationOptions<AgreementVenueCompetitionNotifyMutation, AgreementVenueCompetitionNotifyMutationVariables>;
export const CreateGenderAndAgeGroupDocument = Apollo.gql`
    mutation CreateGenderAndAgeGroup($data: DirGenderAndAgeGroupsCreateInput!) {
  createDirGenderAndAgeGroups(data: $data) {
    id
  }
}
    `;
export type CreateGenderAndAgeGroupMutationFn = Apollo.MutationFunction<CreateGenderAndAgeGroupMutation, CreateGenderAndAgeGroupMutationVariables>;

/**
 * __useCreateGenderAndAgeGroupMutation__
 *
 * To run a mutation, you first call `useCreateGenderAndAgeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGenderAndAgeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGenderAndAgeGroupMutation, { data, loading, error }] = useCreateGenderAndAgeGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateGenderAndAgeGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGenderAndAgeGroupMutation, CreateGenderAndAgeGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGenderAndAgeGroupMutation, CreateGenderAndAgeGroupMutationVariables>(CreateGenderAndAgeGroupDocument, baseOptions);
      }
export type CreateGenderAndAgeGroupMutationHookResult = ReturnType<typeof useCreateGenderAndAgeGroupMutation>;
export type CreateGenderAndAgeGroupMutationResult = Apollo.MutationResult<CreateGenderAndAgeGroupMutation>;
export type CreateGenderAndAgeGroupMutationOptions = Apollo.BaseMutationOptions<CreateGenderAndAgeGroupMutation, CreateGenderAndAgeGroupMutationVariables>;
export const UpdateGenderAndAgeGroupDocument = Apollo.gql`
    mutation UpdateGenderAndAgeGroup($id: UUID!, $data: DirGenderAndAgeGroupsUpdateInput!) {
  updateDirGenderAndAgeGroups(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdateGenderAndAgeGroupMutationFn = Apollo.MutationFunction<UpdateGenderAndAgeGroupMutation, UpdateGenderAndAgeGroupMutationVariables>;

/**
 * __useUpdateGenderAndAgeGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGenderAndAgeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGenderAndAgeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGenderAndAgeGroupMutation, { data, loading, error }] = useUpdateGenderAndAgeGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateGenderAndAgeGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGenderAndAgeGroupMutation, UpdateGenderAndAgeGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGenderAndAgeGroupMutation, UpdateGenderAndAgeGroupMutationVariables>(UpdateGenderAndAgeGroupDocument, baseOptions);
      }
export type UpdateGenderAndAgeGroupMutationHookResult = ReturnType<typeof useUpdateGenderAndAgeGroupMutation>;
export type UpdateGenderAndAgeGroupMutationResult = Apollo.MutationResult<UpdateGenderAndAgeGroupMutation>;
export type UpdateGenderAndAgeGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGenderAndAgeGroupMutation, UpdateGenderAndAgeGroupMutationVariables>;
export const CreateClassifierDocument = Apollo.gql`
    mutation CreateClassifier($name: String!) {
  createClassifier(data: {name: $name}) {
    ...Classifier
  }
}
    ${ClassifierFragmentDoc}`;
export type CreateClassifierMutationFn = Apollo.MutationFunction<CreateClassifierMutation, CreateClassifierMutationVariables>;

/**
 * __useCreateClassifierMutation__
 *
 * To run a mutation, you first call `useCreateClassifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassifierMutation, { data, loading, error }] = useCreateClassifierMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateClassifierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClassifierMutation, CreateClassifierMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateClassifierMutation, CreateClassifierMutationVariables>(CreateClassifierDocument, baseOptions);
      }
export type CreateClassifierMutationHookResult = ReturnType<typeof useCreateClassifierMutation>;
export type CreateClassifierMutationResult = Apollo.MutationResult<CreateClassifierMutation>;
export type CreateClassifierMutationOptions = Apollo.BaseMutationOptions<CreateClassifierMutation, CreateClassifierMutationVariables>;
export const UpdateClassifierDocument = Apollo.gql`
    mutation UpdateClassifier($id: UUID!, $name: String!) {
  updateClassifier(where: {id: $id}, data: {name: $name}) {
    ...Classifier
  }
}
    ${ClassifierFragmentDoc}`;
export type UpdateClassifierMutationFn = Apollo.MutationFunction<UpdateClassifierMutation, UpdateClassifierMutationVariables>;

/**
 * __useUpdateClassifierMutation__
 *
 * To run a mutation, you first call `useUpdateClassifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassifierMutation, { data, loading, error }] = useUpdateClassifierMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateClassifierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClassifierMutation, UpdateClassifierMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClassifierMutation, UpdateClassifierMutationVariables>(UpdateClassifierDocument, baseOptions);
      }
export type UpdateClassifierMutationHookResult = ReturnType<typeof useUpdateClassifierMutation>;
export type UpdateClassifierMutationResult = Apollo.MutationResult<UpdateClassifierMutation>;
export type UpdateClassifierMutationOptions = Apollo.BaseMutationOptions<UpdateClassifierMutation, UpdateClassifierMutationVariables>;
export const CreateClassifierValueDocument = Apollo.gql`
    mutation CreateClassifierValue($id: UUID!, $fullName: String!, $shortName: String!, $order: Int) {
  createClassifierValue(data: {classifier: {connect: {id: $id}}, shortName: $shortName, fullName: $fullName, order: $order}) {
    ...ClsValue
  }
}
    ${ClsValueFragmentDoc}`;
export type CreateClassifierValueMutationFn = Apollo.MutationFunction<CreateClassifierValueMutation, CreateClassifierValueMutationVariables>;

/**
 * __useCreateClassifierValueMutation__
 *
 * To run a mutation, you first call `useCreateClassifierValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassifierValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassifierValueMutation, { data, loading, error }] = useCreateClassifierValueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateClassifierValueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClassifierValueMutation, CreateClassifierValueMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateClassifierValueMutation, CreateClassifierValueMutationVariables>(CreateClassifierValueDocument, baseOptions);
      }
export type CreateClassifierValueMutationHookResult = ReturnType<typeof useCreateClassifierValueMutation>;
export type CreateClassifierValueMutationResult = Apollo.MutationResult<CreateClassifierValueMutation>;
export type CreateClassifierValueMutationOptions = Apollo.BaseMutationOptions<CreateClassifierValueMutation, CreateClassifierValueMutationVariables>;
export const UpdateClassifierValueDocument = Apollo.gql`
    mutation UpdateClassifierValue($id: UUID!, $fullName: String!, $shortName: String!, $order: Int) {
  updateClassifierValue(where: {id: $id}, data: {shortName: $shortName, fullName: $fullName, order: $order}) {
    ...ClsValue
  }
}
    ${ClsValueFragmentDoc}`;
export type UpdateClassifierValueMutationFn = Apollo.MutationFunction<UpdateClassifierValueMutation, UpdateClassifierValueMutationVariables>;

/**
 * __useUpdateClassifierValueMutation__
 *
 * To run a mutation, you first call `useUpdateClassifierValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassifierValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassifierValueMutation, { data, loading, error }] = useUpdateClassifierValueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateClassifierValueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClassifierValueMutation, UpdateClassifierValueMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClassifierValueMutation, UpdateClassifierValueMutationVariables>(UpdateClassifierValueDocument, baseOptions);
      }
export type UpdateClassifierValueMutationHookResult = ReturnType<typeof useUpdateClassifierValueMutation>;
export type UpdateClassifierValueMutationResult = Apollo.MutationResult<UpdateClassifierValueMutation>;
export type UpdateClassifierValueMutationOptions = Apollo.BaseMutationOptions<UpdateClassifierValueMutation, UpdateClassifierValueMutationVariables>;
export const ClassifierArchiveDocument = Apollo.gql`
    mutation ClassifierArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateClassifier(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type ClassifierArchiveMutationFn = Apollo.MutationFunction<ClassifierArchiveMutation, ClassifierArchiveMutationVariables>;

/**
 * __useClassifierArchiveMutation__
 *
 * To run a mutation, you first call `useClassifierArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassifierArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classifierArchiveMutation, { data, loading, error }] = useClassifierArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useClassifierArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClassifierArchiveMutation, ClassifierArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<ClassifierArchiveMutation, ClassifierArchiveMutationVariables>(ClassifierArchiveDocument, baseOptions);
      }
export type ClassifierArchiveMutationHookResult = ReturnType<typeof useClassifierArchiveMutation>;
export type ClassifierArchiveMutationResult = Apollo.MutationResult<ClassifierArchiveMutation>;
export type ClassifierArchiveMutationOptions = Apollo.BaseMutationOptions<ClassifierArchiveMutation, ClassifierArchiveMutationVariables>;
export const ClassifierUnArchiveDocument = Apollo.gql`
    mutation ClassifierUnArchive($id: UUID!) {
  updateClassifier(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type ClassifierUnArchiveMutationFn = Apollo.MutationFunction<ClassifierUnArchiveMutation, ClassifierUnArchiveMutationVariables>;

/**
 * __useClassifierUnArchiveMutation__
 *
 * To run a mutation, you first call `useClassifierUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassifierUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classifierUnArchiveMutation, { data, loading, error }] = useClassifierUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassifierUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClassifierUnArchiveMutation, ClassifierUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<ClassifierUnArchiveMutation, ClassifierUnArchiveMutationVariables>(ClassifierUnArchiveDocument, baseOptions);
      }
export type ClassifierUnArchiveMutationHookResult = ReturnType<typeof useClassifierUnArchiveMutation>;
export type ClassifierUnArchiveMutationResult = Apollo.MutationResult<ClassifierUnArchiveMutation>;
export type ClassifierUnArchiveMutationOptions = Apollo.BaseMutationOptions<ClassifierUnArchiveMutation, ClassifierUnArchiveMutationVariables>;
export const ClassifierValueArchiveDocument = Apollo.gql`
    mutation ClassifierValueArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateClassifierValue(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type ClassifierValueArchiveMutationFn = Apollo.MutationFunction<ClassifierValueArchiveMutation, ClassifierValueArchiveMutationVariables>;

/**
 * __useClassifierValueArchiveMutation__
 *
 * To run a mutation, you first call `useClassifierValueArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassifierValueArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classifierValueArchiveMutation, { data, loading, error }] = useClassifierValueArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useClassifierValueArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClassifierValueArchiveMutation, ClassifierValueArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<ClassifierValueArchiveMutation, ClassifierValueArchiveMutationVariables>(ClassifierValueArchiveDocument, baseOptions);
      }
export type ClassifierValueArchiveMutationHookResult = ReturnType<typeof useClassifierValueArchiveMutation>;
export type ClassifierValueArchiveMutationResult = Apollo.MutationResult<ClassifierValueArchiveMutation>;
export type ClassifierValueArchiveMutationOptions = Apollo.BaseMutationOptions<ClassifierValueArchiveMutation, ClassifierValueArchiveMutationVariables>;
export const ClassifierValueUnArchiveDocument = Apollo.gql`
    mutation ClassifierValueUnArchive($id: UUID!) {
  updateClassifierValue(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type ClassifierValueUnArchiveMutationFn = Apollo.MutationFunction<ClassifierValueUnArchiveMutation, ClassifierValueUnArchiveMutationVariables>;

/**
 * __useClassifierValueUnArchiveMutation__
 *
 * To run a mutation, you first call `useClassifierValueUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassifierValueUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classifierValueUnArchiveMutation, { data, loading, error }] = useClassifierValueUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassifierValueUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClassifierValueUnArchiveMutation, ClassifierValueUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<ClassifierValueUnArchiveMutation, ClassifierValueUnArchiveMutationVariables>(ClassifierValueUnArchiveDocument, baseOptions);
      }
export type ClassifierValueUnArchiveMutationHookResult = ReturnType<typeof useClassifierValueUnArchiveMutation>;
export type ClassifierValueUnArchiveMutationResult = Apollo.MutationResult<ClassifierValueUnArchiveMutation>;
export type ClassifierValueUnArchiveMutationOptions = Apollo.BaseMutationOptions<ClassifierValueUnArchiveMutation, ClassifierValueUnArchiveMutationVariables>;
export const UniqueValidationClassifierValueDocument = Apollo.gql`
    query UniqueValidationClassifierValue($fullName: String!) {
  classifierValues(where: {fullName: $fullName}) {
    ...ClsValue
  }
}
    ${ClsValueFragmentDoc}`;

/**
 * __useUniqueValidationClassifierValueQuery__
 *
 * To run a query within a React component, call `useUniqueValidationClassifierValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniqueValidationClassifierValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniqueValidationClassifierValueQuery({
 *   variables: {
 *      fullName: // value for 'fullName'
 *   },
 * });
 */
export function useUniqueValidationClassifierValueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UniqueValidationClassifierValueQuery, UniqueValidationClassifierValueQueryVariables>) {
        return ApolloReactHooks.useQuery<UniqueValidationClassifierValueQuery, UniqueValidationClassifierValueQueryVariables>(UniqueValidationClassifierValueDocument, baseOptions);
      }
export function useUniqueValidationClassifierValueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UniqueValidationClassifierValueQuery, UniqueValidationClassifierValueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UniqueValidationClassifierValueQuery, UniqueValidationClassifierValueQueryVariables>(UniqueValidationClassifierValueDocument, baseOptions);
        }
export type UniqueValidationClassifierValueQueryHookResult = ReturnType<typeof useUniqueValidationClassifierValueQuery>;
export type UniqueValidationClassifierValueLazyQueryHookResult = ReturnType<typeof useUniqueValidationClassifierValueLazyQuery>;
export type UniqueValidationClassifierValueQueryResult = Apollo.QueryResult<UniqueValidationClassifierValueQuery, UniqueValidationClassifierValueQueryVariables>;
export const UniqueValidationClassifierDocument = Apollo.gql`
    query UniqueValidationClassifier($name: String!) {
  classifiers(where: {name: $name}) {
    ...Classifier
  }
}
    ${ClassifierFragmentDoc}`;

/**
 * __useUniqueValidationClassifierQuery__
 *
 * To run a query within a React component, call `useUniqueValidationClassifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniqueValidationClassifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniqueValidationClassifierQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUniqueValidationClassifierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UniqueValidationClassifierQuery, UniqueValidationClassifierQueryVariables>) {
        return ApolloReactHooks.useQuery<UniqueValidationClassifierQuery, UniqueValidationClassifierQueryVariables>(UniqueValidationClassifierDocument, baseOptions);
      }
export function useUniqueValidationClassifierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UniqueValidationClassifierQuery, UniqueValidationClassifierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UniqueValidationClassifierQuery, UniqueValidationClassifierQueryVariables>(UniqueValidationClassifierDocument, baseOptions);
        }
export type UniqueValidationClassifierQueryHookResult = ReturnType<typeof useUniqueValidationClassifierQuery>;
export type UniqueValidationClassifierLazyQueryHookResult = ReturnType<typeof useUniqueValidationClassifierLazyQuery>;
export type UniqueValidationClassifierQueryResult = Apollo.QueryResult<UniqueValidationClassifierQuery, UniqueValidationClassifierQueryVariables>;
export const ClassifierByIdDocument = Apollo.gql`
    query ClassifierById($id: UUID!) {
  classifier(where: {id: $id}) {
    ...Classifier
    values(orderBy: fullName_ASC) {
      ...ClsValue
    }
  }
}
    ${ClassifierFragmentDoc}
${ClsValueFragmentDoc}`;

/**
 * __useClassifierByIdQuery__
 *
 * To run a query within a React component, call `useClassifierByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassifierByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassifierByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassifierByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClassifierByIdQuery, ClassifierByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ClassifierByIdQuery, ClassifierByIdQueryVariables>(ClassifierByIdDocument, baseOptions);
      }
export function useClassifierByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClassifierByIdQuery, ClassifierByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClassifierByIdQuery, ClassifierByIdQueryVariables>(ClassifierByIdDocument, baseOptions);
        }
export type ClassifierByIdQueryHookResult = ReturnType<typeof useClassifierByIdQuery>;
export type ClassifierByIdLazyQueryHookResult = ReturnType<typeof useClassifierByIdLazyQuery>;
export type ClassifierByIdQueryResult = Apollo.QueryResult<ClassifierByIdQuery, ClassifierByIdQueryVariables>;
export const ClassifiersDocument = Apollo.gql`
    query Classifiers($first: Int!, $skip: Int!, $filter: ClassifierWhereInput) {
  classifiers(orderBy: name_ASC, first: $first, skip: $skip, where: $filter) {
    ...Classifier
  }
}
    ${ClassifierFragmentDoc}`;

/**
 * __useClassifiersQuery__
 *
 * To run a query within a React component, call `useClassifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassifiersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useClassifiersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClassifiersQuery, ClassifiersQueryVariables>) {
        return ApolloReactHooks.useQuery<ClassifiersQuery, ClassifiersQueryVariables>(ClassifiersDocument, baseOptions);
      }
export function useClassifiersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClassifiersQuery, ClassifiersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClassifiersQuery, ClassifiersQueryVariables>(ClassifiersDocument, baseOptions);
        }
export type ClassifiersQueryHookResult = ReturnType<typeof useClassifiersQuery>;
export type ClassifiersLazyQueryHookResult = ReturnType<typeof useClassifiersLazyQuery>;
export type ClassifiersQueryResult = Apollo.QueryResult<ClassifiersQuery, ClassifiersQueryVariables>;
export const ClsTrainingStagesDocument = Apollo.gql`
    query clsTrainingStages {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Этапы подготовки"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsTrainingStagesQuery__
 *
 * To run a query within a React component, call `useClsTrainingStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsTrainingStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsTrainingStagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsTrainingStagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsTrainingStagesQuery, ClsTrainingStagesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsTrainingStagesQuery, ClsTrainingStagesQueryVariables>(ClsTrainingStagesDocument, baseOptions);
      }
export function useClsTrainingStagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsTrainingStagesQuery, ClsTrainingStagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsTrainingStagesQuery, ClsTrainingStagesQueryVariables>(ClsTrainingStagesDocument, baseOptions);
        }
export type ClsTrainingStagesQueryHookResult = ReturnType<typeof useClsTrainingStagesQuery>;
export type ClsTrainingStagesLazyQueryHookResult = ReturnType<typeof useClsTrainingStagesLazyQuery>;
export type ClsTrainingStagesQueryResult = Apollo.QueryResult<ClsTrainingStagesQuery, ClsTrainingStagesQueryVariables>;
export const ClsAddressTypesDocument = Apollo.gql`
    query clsAddressTypes {
  addressTypes {
    value: id
    label: name
  }
}
    `;

/**
 * __useClsAddressTypesQuery__
 *
 * To run a query within a React component, call `useClsAddressTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsAddressTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsAddressTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsAddressTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsAddressTypesQuery, ClsAddressTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsAddressTypesQuery, ClsAddressTypesQueryVariables>(ClsAddressTypesDocument, baseOptions);
      }
export function useClsAddressTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsAddressTypesQuery, ClsAddressTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsAddressTypesQuery, ClsAddressTypesQueryVariables>(ClsAddressTypesDocument, baseOptions);
        }
export type ClsAddressTypesQueryHookResult = ReturnType<typeof useClsAddressTypesQuery>;
export type ClsAddressTypesLazyQueryHookResult = ReturnType<typeof useClsAddressTypesLazyQuery>;
export type ClsAddressTypesQueryResult = Apollo.QueryResult<ClsAddressTypesQuery, ClsAddressTypesQueryVariables>;
export const ClsAgeGroupsDocument = Apollo.gql`
    query clsAgeGroups {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Половозрастные группы"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsAgeGroupsQuery__
 *
 * To run a query within a React component, call `useClsAgeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsAgeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsAgeGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsAgeGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsAgeGroupsQuery, ClsAgeGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsAgeGroupsQuery, ClsAgeGroupsQueryVariables>(ClsAgeGroupsDocument, baseOptions);
      }
export function useClsAgeGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsAgeGroupsQuery, ClsAgeGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsAgeGroupsQuery, ClsAgeGroupsQueryVariables>(ClsAgeGroupsDocument, baseOptions);
        }
export type ClsAgeGroupsQueryHookResult = ReturnType<typeof useClsAgeGroupsQuery>;
export type ClsAgeGroupsLazyQueryHookResult = ReturnType<typeof useClsAgeGroupsLazyQuery>;
export type ClsAgeGroupsQueryResult = Apollo.QueryResult<ClsAgeGroupsQuery, ClsAgeGroupsQueryVariables>;
export const GenderAndAgeGroupsDocument = Apollo.gql`
    query genderAndAgeGroups($filter: DirGenderAndAgeGroupsWhereInput) {
  dirGenderAndAgeGroupses(where: $filter) {
    id
    isMale
    clsAgeGroups {
      id
    }
  }
}
    `;

/**
 * __useGenderAndAgeGroupsQuery__
 *
 * To run a query within a React component, call `useGenderAndAgeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenderAndAgeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenderAndAgeGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGenderAndAgeGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenderAndAgeGroupsQuery, GenderAndAgeGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<GenderAndAgeGroupsQuery, GenderAndAgeGroupsQueryVariables>(GenderAndAgeGroupsDocument, baseOptions);
      }
export function useGenderAndAgeGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenderAndAgeGroupsQuery, GenderAndAgeGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenderAndAgeGroupsQuery, GenderAndAgeGroupsQueryVariables>(GenderAndAgeGroupsDocument, baseOptions);
        }
export type GenderAndAgeGroupsQueryHookResult = ReturnType<typeof useGenderAndAgeGroupsQuery>;
export type GenderAndAgeGroupsLazyQueryHookResult = ReturnType<typeof useGenderAndAgeGroupsLazyQuery>;
export type GenderAndAgeGroupsQueryResult = Apollo.QueryResult<GenderAndAgeGroupsQuery, GenderAndAgeGroupsQueryVariables>;
export const ClsArchiveIssuesDocument = Apollo.gql`
    query clsArchiveIssues {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Причина помещения в архив"}}) {
    ...ClassifierValue
  }
}
    ${ClassifierValueFragmentDoc}`;

/**
 * __useClsArchiveIssuesQuery__
 *
 * To run a query within a React component, call `useClsArchiveIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsArchiveIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsArchiveIssuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsArchiveIssuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsArchiveIssuesQuery, ClsArchiveIssuesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsArchiveIssuesQuery, ClsArchiveIssuesQueryVariables>(ClsArchiveIssuesDocument, baseOptions);
      }
export function useClsArchiveIssuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsArchiveIssuesQuery, ClsArchiveIssuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsArchiveIssuesQuery, ClsArchiveIssuesQueryVariables>(ClsArchiveIssuesDocument, baseOptions);
        }
export type ClsArchiveIssuesQueryHookResult = ReturnType<typeof useClsArchiveIssuesQuery>;
export type ClsArchiveIssuesLazyQueryHookResult = ReturnType<typeof useClsArchiveIssuesLazyQuery>;
export type ClsArchiveIssuesQueryResult = Apollo.QueryResult<ClsArchiveIssuesQuery, ClsArchiveIssuesQueryVariables>;
export const ClsCalendarCategoryDocument = Apollo.gql`
    query clsCalendarCategory($excludeStages: [UUID!]) {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Категория календаря"}, id_not_in: $excludeStages}) {
    label: fullName
    shortLabel: shortName
    value: registryNumber
  }
}
    `;

/**
 * __useClsCalendarCategoryQuery__
 *
 * To run a query within a React component, call `useClsCalendarCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsCalendarCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsCalendarCategoryQuery({
 *   variables: {
 *      excludeStages: // value for 'excludeStages'
 *   },
 * });
 */
export function useClsCalendarCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsCalendarCategoryQuery, ClsCalendarCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsCalendarCategoryQuery, ClsCalendarCategoryQueryVariables>(ClsCalendarCategoryDocument, baseOptions);
      }
export function useClsCalendarCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsCalendarCategoryQuery, ClsCalendarCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsCalendarCategoryQuery, ClsCalendarCategoryQueryVariables>(ClsCalendarCategoryDocument, baseOptions);
        }
export type ClsCalendarCategoryQueryHookResult = ReturnType<typeof useClsCalendarCategoryQuery>;
export type ClsCalendarCategoryLazyQueryHookResult = ReturnType<typeof useClsCalendarCategoryLazyQuery>;
export type ClsCalendarCategoryQueryResult = Apollo.QueryResult<ClsCalendarCategoryQuery, ClsCalendarCategoryQueryVariables>;
export const ClsCalendarCategoryIdDocument = Apollo.gql`
    query clsCalendarCategoryId($excludeStages: [UUID!]) {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Категория календаря"}, id_not_in: $excludeStages}) {
    label: fullName
    shortLabel: shortName
    value: id
  }
}
    `;

/**
 * __useClsCalendarCategoryIdQuery__
 *
 * To run a query within a React component, call `useClsCalendarCategoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsCalendarCategoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsCalendarCategoryIdQuery({
 *   variables: {
 *      excludeStages: // value for 'excludeStages'
 *   },
 * });
 */
export function useClsCalendarCategoryIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsCalendarCategoryIdQuery, ClsCalendarCategoryIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsCalendarCategoryIdQuery, ClsCalendarCategoryIdQueryVariables>(ClsCalendarCategoryIdDocument, baseOptions);
      }
export function useClsCalendarCategoryIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsCalendarCategoryIdQuery, ClsCalendarCategoryIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsCalendarCategoryIdQuery, ClsCalendarCategoryIdQueryVariables>(ClsCalendarCategoryIdDocument, baseOptions);
        }
export type ClsCalendarCategoryIdQueryHookResult = ReturnType<typeof useClsCalendarCategoryIdQuery>;
export type ClsCalendarCategoryIdLazyQueryHookResult = ReturnType<typeof useClsCalendarCategoryIdLazyQuery>;
export type ClsCalendarCategoryIdQueryResult = Apollo.QueryResult<ClsCalendarCategoryIdQuery, ClsCalendarCategoryIdQueryVariables>;
export const ClsCalendarStagesDocument = Apollo.gql`
    query clsCalendarStages {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Количество этапов календаря"}}) {
    label: fullName
    registryNumber
    value: id
  }
}
    `;

/**
 * __useClsCalendarStagesQuery__
 *
 * To run a query within a React component, call `useClsCalendarStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsCalendarStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsCalendarStagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsCalendarStagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsCalendarStagesQuery, ClsCalendarStagesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsCalendarStagesQuery, ClsCalendarStagesQueryVariables>(ClsCalendarStagesDocument, baseOptions);
      }
export function useClsCalendarStagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsCalendarStagesQuery, ClsCalendarStagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsCalendarStagesQuery, ClsCalendarStagesQueryVariables>(ClsCalendarStagesDocument, baseOptions);
        }
export type ClsCalendarStagesQueryHookResult = ReturnType<typeof useClsCalendarStagesQuery>;
export type ClsCalendarStagesLazyQueryHookResult = ReturnType<typeof useClsCalendarStagesLazyQuery>;
export type ClsCalendarStagesQueryResult = Apollo.QueryResult<ClsCalendarStagesQuery, ClsCalendarStagesQueryVariables>;
export const ClsDepartmentAffiliationDocument = Apollo.gql`
    query clsDepartmentAffiliation {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Ведомственная принадлежность организации"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsDepartmentAffiliationQuery__
 *
 * To run a query within a React component, call `useClsDepartmentAffiliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsDepartmentAffiliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsDepartmentAffiliationQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsDepartmentAffiliationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsDepartmentAffiliationQuery, ClsDepartmentAffiliationQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsDepartmentAffiliationQuery, ClsDepartmentAffiliationQueryVariables>(ClsDepartmentAffiliationDocument, baseOptions);
      }
export function useClsDepartmentAffiliationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsDepartmentAffiliationQuery, ClsDepartmentAffiliationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsDepartmentAffiliationQuery, ClsDepartmentAffiliationQueryVariables>(ClsDepartmentAffiliationDocument, baseOptions);
        }
export type ClsDepartmentAffiliationQueryHookResult = ReturnType<typeof useClsDepartmentAffiliationQuery>;
export type ClsDepartmentAffiliationLazyQueryHookResult = ReturnType<typeof useClsDepartmentAffiliationLazyQuery>;
export type ClsDepartmentAffiliationQueryResult = Apollo.QueryResult<ClsDepartmentAffiliationQuery, ClsDepartmentAffiliationQueryVariables>;
export const ClsDirectionalityDocument = Apollo.gql`
    query clsDirectionality {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {registryNumber: 100002781}}) {
    label: fullName
    value: registryNumber
  }
}
    `;

/**
 * __useClsDirectionalityQuery__
 *
 * To run a query within a React component, call `useClsDirectionalityQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsDirectionalityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsDirectionalityQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsDirectionalityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsDirectionalityQuery, ClsDirectionalityQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsDirectionalityQuery, ClsDirectionalityQueryVariables>(ClsDirectionalityDocument, baseOptions);
      }
export function useClsDirectionalityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsDirectionalityQuery, ClsDirectionalityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsDirectionalityQuery, ClsDirectionalityQueryVariables>(ClsDirectionalityDocument, baseOptions);
        }
export type ClsDirectionalityQueryHookResult = ReturnType<typeof useClsDirectionalityQuery>;
export type ClsDirectionalityLazyQueryHookResult = ReturnType<typeof useClsDirectionalityLazyQuery>;
export type ClsDirectionalityQueryResult = Apollo.QueryResult<ClsDirectionalityQuery, ClsDirectionalityQueryVariables>;
export const ClsDivisionsDocument = Apollo.gql`
    query clsDivisions {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {registryNumber: 100002864}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsDivisionsQuery__
 *
 * To run a query within a React component, call `useClsDivisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsDivisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsDivisionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsDivisionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsDivisionsQuery, ClsDivisionsQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsDivisionsQuery, ClsDivisionsQueryVariables>(ClsDivisionsDocument, baseOptions);
      }
export function useClsDivisionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsDivisionsQuery, ClsDivisionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsDivisionsQuery, ClsDivisionsQueryVariables>(ClsDivisionsDocument, baseOptions);
        }
export type ClsDivisionsQueryHookResult = ReturnType<typeof useClsDivisionsQuery>;
export type ClsDivisionsLazyQueryHookResult = ReturnType<typeof useClsDivisionsLazyQuery>;
export type ClsDivisionsQueryResult = Apollo.QueryResult<ClsDivisionsQuery, ClsDivisionsQueryVariables>;
export const ClsDocumentTypesDocument = Apollo.gql`
    query clsDocumentTypes {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Тип документа"}}) {
    label: fullName
    shortName
    registryNumber
    value: id
  }
}
    `;

/**
 * __useClsDocumentTypesQuery__
 *
 * To run a query within a React component, call `useClsDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsDocumentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsDocumentTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsDocumentTypesQuery, ClsDocumentTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsDocumentTypesQuery, ClsDocumentTypesQueryVariables>(ClsDocumentTypesDocument, baseOptions);
      }
export function useClsDocumentTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsDocumentTypesQuery, ClsDocumentTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsDocumentTypesQuery, ClsDocumentTypesQueryVariables>(ClsDocumentTypesDocument, baseOptions);
        }
export type ClsDocumentTypesQueryHookResult = ReturnType<typeof useClsDocumentTypesQuery>;
export type ClsDocumentTypesLazyQueryHookResult = ReturnType<typeof useClsDocumentTypesLazyQuery>;
export type ClsDocumentTypesQueryResult = Apollo.QueryResult<ClsDocumentTypesQuery, ClsDocumentTypesQueryVariables>;
export const ClsDocumentRTypesDocument = Apollo.gql`
    query clsDocumentRTypes {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Тип документа"}}) {
    label: fullName
    shortName
    value: registryNumber
  }
}
    `;

/**
 * __useClsDocumentRTypesQuery__
 *
 * To run a query within a React component, call `useClsDocumentRTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsDocumentRTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsDocumentRTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsDocumentRTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsDocumentRTypesQuery, ClsDocumentRTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsDocumentRTypesQuery, ClsDocumentRTypesQueryVariables>(ClsDocumentRTypesDocument, baseOptions);
      }
export function useClsDocumentRTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsDocumentRTypesQuery, ClsDocumentRTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsDocumentRTypesQuery, ClsDocumentRTypesQueryVariables>(ClsDocumentRTypesDocument, baseOptions);
        }
export type ClsDocumentRTypesQueryHookResult = ReturnType<typeof useClsDocumentRTypesQuery>;
export type ClsDocumentRTypesLazyQueryHookResult = ReturnType<typeof useClsDocumentRTypesLazyQuery>;
export type ClsDocumentRTypesQueryResult = Apollo.QueryResult<ClsDocumentRTypesQuery, ClsDocumentRTypesQueryVariables>;
export const ClsEventCategoriesDocument = Apollo.gql`
    query clsEventCategories {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Статусы ЕКП"}}) {
    label: fullName
    registryNumber
    value: id
  }
}
    `;

/**
 * __useClsEventCategoriesQuery__
 *
 * To run a query within a React component, call `useClsEventCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsEventCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsEventCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsEventCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsEventCategoriesQuery, ClsEventCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsEventCategoriesQuery, ClsEventCategoriesQueryVariables>(ClsEventCategoriesDocument, baseOptions);
      }
export function useClsEventCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsEventCategoriesQuery, ClsEventCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsEventCategoriesQuery, ClsEventCategoriesQueryVariables>(ClsEventCategoriesDocument, baseOptions);
        }
export type ClsEventCategoriesQueryHookResult = ReturnType<typeof useClsEventCategoriesQuery>;
export type ClsEventCategoriesLazyQueryHookResult = ReturnType<typeof useClsEventCategoriesLazyQuery>;
export type ClsEventCategoriesQueryResult = Apollo.QueryResult<ClsEventCategoriesQuery, ClsEventCategoriesQueryVariables>;
export const ClsEventStagesDocument = Apollo.gql`
    query clsEventStages {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Этапы спортмероприятия"}}) {
    label: fullName
    value: id
    fullName
    registryNumber
  }
}
    `;

/**
 * __useClsEventStagesQuery__
 *
 * To run a query within a React component, call `useClsEventStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsEventStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsEventStagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsEventStagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsEventStagesQuery, ClsEventStagesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsEventStagesQuery, ClsEventStagesQueryVariables>(ClsEventStagesDocument, baseOptions);
      }
export function useClsEventStagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsEventStagesQuery, ClsEventStagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsEventStagesQuery, ClsEventStagesQueryVariables>(ClsEventStagesDocument, baseOptions);
        }
export type ClsEventStagesQueryHookResult = ReturnType<typeof useClsEventStagesQuery>;
export type ClsEventStagesLazyQueryHookResult = ReturnType<typeof useClsEventStagesLazyQuery>;
export type ClsEventStagesQueryResult = Apollo.QueryResult<ClsEventStagesQuery, ClsEventStagesQueryVariables>;
export const ClsFamilyStatusDocument = Apollo.gql`
    query clsFamilyStatus {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Семейное положение"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsFamilyStatusQuery__
 *
 * To run a query within a React component, call `useClsFamilyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsFamilyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsFamilyStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsFamilyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsFamilyStatusQuery, ClsFamilyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsFamilyStatusQuery, ClsFamilyStatusQueryVariables>(ClsFamilyStatusDocument, baseOptions);
      }
export function useClsFamilyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsFamilyStatusQuery, ClsFamilyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsFamilyStatusQuery, ClsFamilyStatusQueryVariables>(ClsFamilyStatusDocument, baseOptions);
        }
export type ClsFamilyStatusQueryHookResult = ReturnType<typeof useClsFamilyStatusQuery>;
export type ClsFamilyStatusLazyQueryHookResult = ReturnType<typeof useClsFamilyStatusLazyQuery>;
export type ClsFamilyStatusQueryResult = Apollo.QueryResult<ClsFamilyStatusQuery, ClsFamilyStatusQueryVariables>;
export const ClsFinancingTypesDocument = Apollo.gql`
    query clsFinancingTypes {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Тип финансирования"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsFinancingTypesQuery__
 *
 * To run a query within a React component, call `useClsFinancingTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsFinancingTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsFinancingTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsFinancingTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsFinancingTypesQuery, ClsFinancingTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsFinancingTypesQuery, ClsFinancingTypesQueryVariables>(ClsFinancingTypesDocument, baseOptions);
      }
export function useClsFinancingTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsFinancingTypesQuery, ClsFinancingTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsFinancingTypesQuery, ClsFinancingTypesQueryVariables>(ClsFinancingTypesDocument, baseOptions);
        }
export type ClsFinancingTypesQueryHookResult = ReturnType<typeof useClsFinancingTypesQuery>;
export type ClsFinancingTypesLazyQueryHookResult = ReturnType<typeof useClsFinancingTypesLazyQuery>;
export type ClsFinancingTypesQueryResult = Apollo.QueryResult<ClsFinancingTypesQuery, ClsFinancingTypesQueryVariables>;
export const ClsGroupTypeSportsAndDisciplinesDocument = Apollo.gql`
    query clsGroupTypeSportsAndDisciplines {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Группа видов спорта и дисциплин"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsGroupTypeSportsAndDisciplinesQuery__
 *
 * To run a query within a React component, call `useClsGroupTypeSportsAndDisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsGroupTypeSportsAndDisciplinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsGroupTypeSportsAndDisciplinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsGroupTypeSportsAndDisciplinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsGroupTypeSportsAndDisciplinesQuery, ClsGroupTypeSportsAndDisciplinesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsGroupTypeSportsAndDisciplinesQuery, ClsGroupTypeSportsAndDisciplinesQueryVariables>(ClsGroupTypeSportsAndDisciplinesDocument, baseOptions);
      }
export function useClsGroupTypeSportsAndDisciplinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsGroupTypeSportsAndDisciplinesQuery, ClsGroupTypeSportsAndDisciplinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsGroupTypeSportsAndDisciplinesQuery, ClsGroupTypeSportsAndDisciplinesQueryVariables>(ClsGroupTypeSportsAndDisciplinesDocument, baseOptions);
        }
export type ClsGroupTypeSportsAndDisciplinesQueryHookResult = ReturnType<typeof useClsGroupTypeSportsAndDisciplinesQuery>;
export type ClsGroupTypeSportsAndDisciplinesLazyQueryHookResult = ReturnType<typeof useClsGroupTypeSportsAndDisciplinesLazyQuery>;
export type ClsGroupTypeSportsAndDisciplinesQueryResult = Apollo.QueryResult<ClsGroupTypeSportsAndDisciplinesQuery, ClsGroupTypeSportsAndDisciplinesQueryVariables>;
export const ClsLevelDocument = Apollo.gql`
    query clsLevel {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Категория спортмероприятия"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsLevelQuery__
 *
 * To run a query within a React component, call `useClsLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsLevelQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsLevelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsLevelQuery, ClsLevelQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsLevelQuery, ClsLevelQueryVariables>(ClsLevelDocument, baseOptions);
      }
export function useClsLevelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsLevelQuery, ClsLevelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsLevelQuery, ClsLevelQueryVariables>(ClsLevelDocument, baseOptions);
        }
export type ClsLevelQueryHookResult = ReturnType<typeof useClsLevelQuery>;
export type ClsLevelLazyQueryHookResult = ReturnType<typeof useClsLevelLazyQuery>;
export type ClsLevelQueryResult = Apollo.QueryResult<ClsLevelQuery, ClsLevelQueryVariables>;
export const ClsOrganizationCategoriesDocument = Apollo.gql`
    query clsOrganizationCategories {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Категория организации"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsOrganizationCategoriesQuery__
 *
 * To run a query within a React component, call `useClsOrganizationCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsOrganizationCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsOrganizationCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsOrganizationCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsOrganizationCategoriesQuery, ClsOrganizationCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsOrganizationCategoriesQuery, ClsOrganizationCategoriesQueryVariables>(ClsOrganizationCategoriesDocument, baseOptions);
      }
export function useClsOrganizationCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsOrganizationCategoriesQuery, ClsOrganizationCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsOrganizationCategoriesQuery, ClsOrganizationCategoriesQueryVariables>(ClsOrganizationCategoriesDocument, baseOptions);
        }
export type ClsOrganizationCategoriesQueryHookResult = ReturnType<typeof useClsOrganizationCategoriesQuery>;
export type ClsOrganizationCategoriesLazyQueryHookResult = ReturnType<typeof useClsOrganizationCategoriesLazyQuery>;
export type ClsOrganizationCategoriesQueryResult = Apollo.QueryResult<ClsOrganizationCategoriesQuery, ClsOrganizationCategoriesQueryVariables>;
export const ClsPartEkpDocument = Apollo.gql`
    query clsPartEKP($excludeStages: [UUID!]) {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Часть ЕКП"}, id_not_in: $excludeStages}) {
    label: fullName
    shortLabel: shortName
    value: registryNumber
  }
}
    `;

/**
 * __useClsPartEkpQuery__
 *
 * To run a query within a React component, call `useClsPartEkpQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsPartEkpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsPartEkpQuery({
 *   variables: {
 *      excludeStages: // value for 'excludeStages'
 *   },
 * });
 */
export function useClsPartEkpQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsPartEkpQuery, ClsPartEkpQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsPartEkpQuery, ClsPartEkpQueryVariables>(ClsPartEkpDocument, baseOptions);
      }
export function useClsPartEkpLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsPartEkpQuery, ClsPartEkpQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsPartEkpQuery, ClsPartEkpQueryVariables>(ClsPartEkpDocument, baseOptions);
        }
export type ClsPartEkpQueryHookResult = ReturnType<typeof useClsPartEkpQuery>;
export type ClsPartEkpLazyQueryHookResult = ReturnType<typeof useClsPartEkpLazyQuery>;
export type ClsPartEkpQueryResult = Apollo.QueryResult<ClsPartEkpQuery, ClsPartEkpQueryVariables>;
export const ClsPositionsDocument = Apollo.gql`
    query clsPositions {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Должность"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsPositionsQuery__
 *
 * To run a query within a React component, call `useClsPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsPositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsPositionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsPositionsQuery, ClsPositionsQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsPositionsQuery, ClsPositionsQueryVariables>(ClsPositionsDocument, baseOptions);
      }
export function useClsPositionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsPositionsQuery, ClsPositionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsPositionsQuery, ClsPositionsQueryVariables>(ClsPositionsDocument, baseOptions);
        }
export type ClsPositionsQueryHookResult = ReturnType<typeof useClsPositionsQuery>;
export type ClsPositionsLazyQueryHookResult = ReturnType<typeof useClsPositionsLazyQuery>;
export type ClsPositionsQueryResult = Apollo.QueryResult<ClsPositionsQuery, ClsPositionsQueryVariables>;
export const ClsRanksAndTitlesDocument = Apollo.gql`
    query clsRanksAndTitles {
  classifierValues(orderBy: order_ASC, where: {classifier: {name: "Разряды и звания"}}) {
    label: fullName
    value: id
    order
  }
}
    `;

/**
 * __useClsRanksAndTitlesQuery__
 *
 * To run a query within a React component, call `useClsRanksAndTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsRanksAndTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsRanksAndTitlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsRanksAndTitlesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsRanksAndTitlesQuery, ClsRanksAndTitlesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsRanksAndTitlesQuery, ClsRanksAndTitlesQueryVariables>(ClsRanksAndTitlesDocument, baseOptions);
      }
export function useClsRanksAndTitlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsRanksAndTitlesQuery, ClsRanksAndTitlesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsRanksAndTitlesQuery, ClsRanksAndTitlesQueryVariables>(ClsRanksAndTitlesDocument, baseOptions);
        }
export type ClsRanksAndTitlesQueryHookResult = ReturnType<typeof useClsRanksAndTitlesQuery>;
export type ClsRanksAndTitlesLazyQueryHookResult = ReturnType<typeof useClsRanksAndTitlesLazyQuery>;
export type ClsRanksAndTitlesQueryResult = Apollo.QueryResult<ClsRanksAndTitlesQuery, ClsRanksAndTitlesQueryVariables>;
export const ClsRefereeCategoriesDocument = Apollo.gql`
    query clsRefereeCategories {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {id: "4db3912a-bf7f-4496-8d63-a1499933ab1c"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsRefereeCategoriesQuery__
 *
 * To run a query within a React component, call `useClsRefereeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsRefereeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsRefereeCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsRefereeCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsRefereeCategoriesQuery, ClsRefereeCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsRefereeCategoriesQuery, ClsRefereeCategoriesQueryVariables>(ClsRefereeCategoriesDocument, baseOptions);
      }
export function useClsRefereeCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsRefereeCategoriesQuery, ClsRefereeCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsRefereeCategoriesQuery, ClsRefereeCategoriesQueryVariables>(ClsRefereeCategoriesDocument, baseOptions);
        }
export type ClsRefereeCategoriesQueryHookResult = ReturnType<typeof useClsRefereeCategoriesQuery>;
export type ClsRefereeCategoriesLazyQueryHookResult = ReturnType<typeof useClsRefereeCategoriesLazyQuery>;
export type ClsRefereeCategoriesQueryResult = Apollo.QueryResult<ClsRefereeCategoriesQuery, ClsRefereeCategoriesQueryVariables>;
export const ClsRefereesPositionsDocument = Apollo.gql`
    query clsRefereesPositions($filter: ClassifierValueWhereInput) {
  classifierValues(orderBy: fullName_ASC, where: $filter) {
    label: fullName
    value: id
    registryNumber
  }
}
    `;

/**
 * __useClsRefereesPositionsQuery__
 *
 * To run a query within a React component, call `useClsRefereesPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsRefereesPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsRefereesPositionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useClsRefereesPositionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsRefereesPositionsQuery, ClsRefereesPositionsQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsRefereesPositionsQuery, ClsRefereesPositionsQueryVariables>(ClsRefereesPositionsDocument, baseOptions);
      }
export function useClsRefereesPositionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsRefereesPositionsQuery, ClsRefereesPositionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsRefereesPositionsQuery, ClsRefereesPositionsQueryVariables>(ClsRefereesPositionsDocument, baseOptions);
        }
export type ClsRefereesPositionsQueryHookResult = ReturnType<typeof useClsRefereesPositionsQuery>;
export type ClsRefereesPositionsLazyQueryHookResult = ReturnType<typeof useClsRefereesPositionsLazyQuery>;
export type ClsRefereesPositionsQueryResult = Apollo.QueryResult<ClsRefereesPositionsQuery, ClsRefereesPositionsQueryVariables>;
export const ClsSeasonDocument = Apollo.gql`
    query clsSeason($excludeStages: [UUID!]) {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Сезон"}, id_not_in: $excludeStages}) {
    label: fullName
    shortLabel: shortName
    value: registryNumber
  }
}
    `;

/**
 * __useClsSeasonQuery__
 *
 * To run a query within a React component, call `useClsSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsSeasonQuery({
 *   variables: {
 *      excludeStages: // value for 'excludeStages'
 *   },
 * });
 */
export function useClsSeasonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsSeasonQuery, ClsSeasonQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsSeasonQuery, ClsSeasonQueryVariables>(ClsSeasonDocument, baseOptions);
      }
export function useClsSeasonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsSeasonQuery, ClsSeasonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsSeasonQuery, ClsSeasonQueryVariables>(ClsSeasonDocument, baseOptions);
        }
export type ClsSeasonQueryHookResult = ReturnType<typeof useClsSeasonQuery>;
export type ClsSeasonLazyQueryHookResult = ReturnType<typeof useClsSeasonLazyQuery>;
export type ClsSeasonQueryResult = Apollo.QueryResult<ClsSeasonQuery, ClsSeasonQueryVariables>;
export const ClsSportCategoriesDocument = Apollo.gql`
    query clsSportCategories {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Категории видов спорта"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsSportCategoriesQuery__
 *
 * To run a query within a React component, call `useClsSportCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsSportCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsSportCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsSportCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsSportCategoriesQuery, ClsSportCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsSportCategoriesQuery, ClsSportCategoriesQueryVariables>(ClsSportCategoriesDocument, baseOptions);
      }
export function useClsSportCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsSportCategoriesQuery, ClsSportCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsSportCategoriesQuery, ClsSportCategoriesQueryVariables>(ClsSportCategoriesDocument, baseOptions);
        }
export type ClsSportCategoriesQueryHookResult = ReturnType<typeof useClsSportCategoriesQuery>;
export type ClsSportCategoriesLazyQueryHookResult = ReturnType<typeof useClsSportCategoriesLazyQuery>;
export type ClsSportCategoriesQueryResult = Apollo.QueryResult<ClsSportCategoriesQuery, ClsSportCategoriesQueryVariables>;
export const ClsSportingEventsRequestStatusDocument = Apollo.gql`
    query clsSportingEventsRequestStatus {
  dirSportingEventRequestStatusValues {
    value: id
    label: name
  }
}
    `;

/**
 * __useClsSportingEventsRequestStatusQuery__
 *
 * To run a query within a React component, call `useClsSportingEventsRequestStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsSportingEventsRequestStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsSportingEventsRequestStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsSportingEventsRequestStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsSportingEventsRequestStatusQuery, ClsSportingEventsRequestStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsSportingEventsRequestStatusQuery, ClsSportingEventsRequestStatusQueryVariables>(ClsSportingEventsRequestStatusDocument, baseOptions);
      }
export function useClsSportingEventsRequestStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsSportingEventsRequestStatusQuery, ClsSportingEventsRequestStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsSportingEventsRequestStatusQuery, ClsSportingEventsRequestStatusQueryVariables>(ClsSportingEventsRequestStatusDocument, baseOptions);
        }
export type ClsSportingEventsRequestStatusQueryHookResult = ReturnType<typeof useClsSportingEventsRequestStatusQuery>;
export type ClsSportingEventsRequestStatusLazyQueryHookResult = ReturnType<typeof useClsSportingEventsRequestStatusLazyQuery>;
export type ClsSportingEventsRequestStatusQueryResult = Apollo.QueryResult<ClsSportingEventsRequestStatusQuery, ClsSportingEventsRequestStatusQueryVariables>;
export const ClsSportingEventsCategoriesDocument = Apollo.gql`
    query clsSportingEventsCategories {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {registryNumber: 100002534}}) {
    label: fullName
    registryNumber
    value: id
    fullName
  }
}
    `;

/**
 * __useClsSportingEventsCategoriesQuery__
 *
 * To run a query within a React component, call `useClsSportingEventsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsSportingEventsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsSportingEventsCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsSportingEventsCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsSportingEventsCategoriesQuery, ClsSportingEventsCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsSportingEventsCategoriesQuery, ClsSportingEventsCategoriesQueryVariables>(ClsSportingEventsCategoriesDocument, baseOptions);
      }
export function useClsSportingEventsCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsSportingEventsCategoriesQuery, ClsSportingEventsCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsSportingEventsCategoriesQuery, ClsSportingEventsCategoriesQueryVariables>(ClsSportingEventsCategoriesDocument, baseOptions);
        }
export type ClsSportingEventsCategoriesQueryHookResult = ReturnType<typeof useClsSportingEventsCategoriesQuery>;
export type ClsSportingEventsCategoriesLazyQueryHookResult = ReturnType<typeof useClsSportingEventsCategoriesLazyQuery>;
export type ClsSportingEventsCategoriesQueryResult = Apollo.QueryResult<ClsSportingEventsCategoriesQuery, ClsSportingEventsCategoriesQueryVariables>;
export const ClsStagePreparingDocument = Apollo.gql`
    query clsStagePreparing($excludeStages: [UUID!]) {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Этапы подготовки"}, id_not_in: $excludeStages}) {
    label: fullName
    shortLabel: shortName
    value: id
  }
}
    `;

/**
 * __useClsStagePreparingQuery__
 *
 * To run a query within a React component, call `useClsStagePreparingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsStagePreparingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsStagePreparingQuery({
 *   variables: {
 *      excludeStages: // value for 'excludeStages'
 *   },
 * });
 */
export function useClsStagePreparingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsStagePreparingQuery, ClsStagePreparingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsStagePreparingQuery, ClsStagePreparingQueryVariables>(ClsStagePreparingDocument, baseOptions);
      }
export function useClsStagePreparingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsStagePreparingQuery, ClsStagePreparingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsStagePreparingQuery, ClsStagePreparingQueryVariables>(ClsStagePreparingDocument, baseOptions);
        }
export type ClsStagePreparingQueryHookResult = ReturnType<typeof useClsStagePreparingQuery>;
export type ClsStagePreparingLazyQueryHookResult = ReturnType<typeof useClsStagePreparingLazyQuery>;
export type ClsStagePreparingQueryResult = Apollo.QueryResult<ClsStagePreparingQuery, ClsStagePreparingQueryVariables>;
export const ClsSummarizingTypeDocument = Apollo.gql`
    query clsSummarizingType {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Характер подведения итогов"}}) {
    label: fullName
    value: registryNumber
  }
}
    `;

/**
 * __useClsSummarizingTypeQuery__
 *
 * To run a query within a React component, call `useClsSummarizingTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsSummarizingTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsSummarizingTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsSummarizingTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsSummarizingTypeQuery, ClsSummarizingTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsSummarizingTypeQuery, ClsSummarizingTypeQueryVariables>(ClsSummarizingTypeDocument, baseOptions);
      }
export function useClsSummarizingTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsSummarizingTypeQuery, ClsSummarizingTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsSummarizingTypeQuery, ClsSummarizingTypeQueryVariables>(ClsSummarizingTypeDocument, baseOptions);
        }
export type ClsSummarizingTypeQueryHookResult = ReturnType<typeof useClsSummarizingTypeQuery>;
export type ClsSummarizingTypeLazyQueryHookResult = ReturnType<typeof useClsSummarizingTypeLazyQuery>;
export type ClsSummarizingTypeQueryResult = Apollo.QueryResult<ClsSummarizingTypeQuery, ClsSummarizingTypeQueryVariables>;
export const ClsTeamCompositionDocument = Apollo.gql`
    query clsTeamComposition {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Состав команды"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsTeamCompositionQuery__
 *
 * To run a query within a React component, call `useClsTeamCompositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsTeamCompositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsTeamCompositionQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsTeamCompositionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsTeamCompositionQuery, ClsTeamCompositionQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsTeamCompositionQuery, ClsTeamCompositionQueryVariables>(ClsTeamCompositionDocument, baseOptions);
      }
export function useClsTeamCompositionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsTeamCompositionQuery, ClsTeamCompositionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsTeamCompositionQuery, ClsTeamCompositionQueryVariables>(ClsTeamCompositionDocument, baseOptions);
        }
export type ClsTeamCompositionQueryHookResult = ReturnType<typeof useClsTeamCompositionQuery>;
export type ClsTeamCompositionLazyQueryHookResult = ReturnType<typeof useClsTeamCompositionLazyQuery>;
export type ClsTeamCompositionQueryResult = Apollo.QueryResult<ClsTeamCompositionQuery, ClsTeamCompositionQueryVariables>;
export const ClsTypeContactDocument = Apollo.gql`
    query clsTypeContact {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Тип контакта"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsTypeContactQuery__
 *
 * To run a query within a React component, call `useClsTypeContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsTypeContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsTypeContactQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsTypeContactQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsTypeContactQuery, ClsTypeContactQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsTypeContactQuery, ClsTypeContactQueryVariables>(ClsTypeContactDocument, baseOptions);
      }
export function useClsTypeContactLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsTypeContactQuery, ClsTypeContactQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsTypeContactQuery, ClsTypeContactQueryVariables>(ClsTypeContactDocument, baseOptions);
        }
export type ClsTypeContactQueryHookResult = ReturnType<typeof useClsTypeContactQuery>;
export type ClsTypeContactLazyQueryHookResult = ReturnType<typeof useClsTypeContactLazyQuery>;
export type ClsTypeContactQueryResult = Apollo.QueryResult<ClsTypeContactQuery, ClsTypeContactQueryVariables>;
export const UploadFileDocument = Apollo.gql`
    mutation UploadFile($file: Upload!) {
  uploadFile(file: $file) {
    id
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, baseOptions);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const CreateDocumentDocument = Apollo.gql`
    mutation CreateDocument($data: DirDocumentCreateInput!) {
  createDirDocument(data: $data) {
    id
  }
}
    `;
export type CreateDocumentMutationFn = Apollo.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(CreateDocumentDocument, baseOptions);
      }
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const UpdateDocumentDocument = Apollo.gql`
    mutation UpdateDocument($data: DirDocumentUpdateInput!, $id: UUID!) {
  updateDirDocument(data: $data, where: {id: $id}) {
    id
  }
}
    `;
export type UpdateDocumentMutationFn = Apollo.MutationFunction<UpdateDocumentMutation, UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(UpdateDocumentDocument, baseOptions);
      }
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>;
export const DocumentArchiveDocument = Apollo.gql`
    mutation DocumentArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirDocument(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type DocumentArchiveMutationFn = Apollo.MutationFunction<DocumentArchiveMutation, DocumentArchiveMutationVariables>;

/**
 * __useDocumentArchiveMutation__
 *
 * To run a mutation, you first call `useDocumentArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentArchiveMutation, { data, loading, error }] = useDocumentArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useDocumentArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentArchiveMutation, DocumentArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentArchiveMutation, DocumentArchiveMutationVariables>(DocumentArchiveDocument, baseOptions);
      }
export type DocumentArchiveMutationHookResult = ReturnType<typeof useDocumentArchiveMutation>;
export type DocumentArchiveMutationResult = Apollo.MutationResult<DocumentArchiveMutation>;
export type DocumentArchiveMutationOptions = Apollo.BaseMutationOptions<DocumentArchiveMutation, DocumentArchiveMutationVariables>;
export const DocumentUnArchiveDocument = Apollo.gql`
    mutation DocumentUnArchive($id: UUID!) {
  updateDirDocument(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type DocumentUnArchiveMutationFn = Apollo.MutationFunction<DocumentUnArchiveMutation, DocumentUnArchiveMutationVariables>;

/**
 * __useDocumentUnArchiveMutation__
 *
 * To run a mutation, you first call `useDocumentUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentUnArchiveMutation, { data, loading, error }] = useDocumentUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentUnArchiveMutation, DocumentUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentUnArchiveMutation, DocumentUnArchiveMutationVariables>(DocumentUnArchiveDocument, baseOptions);
      }
export type DocumentUnArchiveMutationHookResult = ReturnType<typeof useDocumentUnArchiveMutation>;
export type DocumentUnArchiveMutationResult = Apollo.MutationResult<DocumentUnArchiveMutation>;
export type DocumentUnArchiveMutationOptions = Apollo.BaseMutationOptions<DocumentUnArchiveMutation, DocumentUnArchiveMutationVariables>;
export const DocumentsDocument = Apollo.gql`
    query Documents($first: Int!, $skip: Int!, $filter: DirDocumentWhereInput) {
  dirDocuments(first: $first, skip: $skip, where: $filter) {
    ...Document
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDocumentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, baseOptions);
      }
export function useDocumentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, baseOptions);
        }
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export type DocumentsQueryResult = Apollo.QueryResult<DocumentsQuery, DocumentsQueryVariables>;
export const DocumentsCountDocument = Apollo.gql`
    query DocumentsCount($filter: DirDocumentWhereInput) {
  count: dirDocumentsCount(where: $filter)
}
    `;

/**
 * __useDocumentsCountQuery__
 *
 * To run a query within a React component, call `useDocumentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDocumentsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentsCountQuery, DocumentsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentsCountQuery, DocumentsCountQueryVariables>(DocumentsCountDocument, baseOptions);
      }
export function useDocumentsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentsCountQuery, DocumentsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentsCountQuery, DocumentsCountQueryVariables>(DocumentsCountDocument, baseOptions);
        }
export type DocumentsCountQueryHookResult = ReturnType<typeof useDocumentsCountQuery>;
export type DocumentsCountLazyQueryHookResult = ReturnType<typeof useDocumentsCountLazyQuery>;
export type DocumentsCountQueryResult = Apollo.QueryResult<DocumentsCountQuery, DocumentsCountQueryVariables>;
export const DocumentByIdDocument = Apollo.gql`
    query DocumentById($id: UUID!) {
  dirDocument(where: {id: $id}) {
    ...Document
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __useDocumentByIdQuery__
 *
 * To run a query within a React component, call `useDocumentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentByIdQuery, DocumentByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentByIdQuery, DocumentByIdQueryVariables>(DocumentByIdDocument, baseOptions);
      }
export function useDocumentByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentByIdQuery, DocumentByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentByIdQuery, DocumentByIdQueryVariables>(DocumentByIdDocument, baseOptions);
        }
export type DocumentByIdQueryHookResult = ReturnType<typeof useDocumentByIdQuery>;
export type DocumentByIdLazyQueryHookResult = ReturnType<typeof useDocumentByIdLazyQuery>;
export type DocumentByIdQueryResult = Apollo.QueryResult<DocumentByIdQuery, DocumentByIdQueryVariables>;
export const FindDocumentsByTitleDocument = Apollo.gql`
    query FindDocumentsByTitle($search: String!, $bannedId: [UUID!]) {
  dirDocuments(where: {title_contains: $search, id_not_in: $bannedId}) {
    label: title
    value: id
  }
}
    `;

/**
 * __useFindDocumentsByTitleQuery__
 *
 * To run a query within a React component, call `useFindDocumentsByTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDocumentsByTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDocumentsByTitleQuery({
 *   variables: {
 *      search: // value for 'search'
 *      bannedId: // value for 'bannedId'
 *   },
 * });
 */
export function useFindDocumentsByTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindDocumentsByTitleQuery, FindDocumentsByTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<FindDocumentsByTitleQuery, FindDocumentsByTitleQueryVariables>(FindDocumentsByTitleDocument, baseOptions);
      }
export function useFindDocumentsByTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindDocumentsByTitleQuery, FindDocumentsByTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindDocumentsByTitleQuery, FindDocumentsByTitleQueryVariables>(FindDocumentsByTitleDocument, baseOptions);
        }
export type FindDocumentsByTitleQueryHookResult = ReturnType<typeof useFindDocumentsByTitleQuery>;
export type FindDocumentsByTitleLazyQueryHookResult = ReturnType<typeof useFindDocumentsByTitleLazyQuery>;
export type FindDocumentsByTitleQueryResult = Apollo.QueryResult<FindDocumentsByTitleQuery, FindDocumentsByTitleQueryVariables>;
export const SimilarDocumentsDocument = Apollo.gql`
    query SimilarDocuments($search: String!, $filter: DirDocumentWhereInput) {
  dirDocumentsSimilar(search: $search, where: $filter, first: 10) {
    similarity
    document {
      value: id
      label: title
    }
  }
}
    `;

/**
 * __useSimilarDocumentsQuery__
 *
 * To run a query within a React component, call `useSimilarDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarDocumentsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSimilarDocumentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SimilarDocumentsQuery, SimilarDocumentsQueryVariables>) {
        return ApolloReactHooks.useQuery<SimilarDocumentsQuery, SimilarDocumentsQueryVariables>(SimilarDocumentsDocument, baseOptions);
      }
export function useSimilarDocumentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimilarDocumentsQuery, SimilarDocumentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SimilarDocumentsQuery, SimilarDocumentsQueryVariables>(SimilarDocumentsDocument, baseOptions);
        }
export type SimilarDocumentsQueryHookResult = ReturnType<typeof useSimilarDocumentsQuery>;
export type SimilarDocumentsLazyQueryHookResult = ReturnType<typeof useSimilarDocumentsLazyQuery>;
export type SimilarDocumentsQueryResult = Apollo.QueryResult<SimilarDocumentsQuery, SimilarDocumentsQueryVariables>;
export const UpdateTrainerDocument = Apollo.gql`
    mutation UpdateTrainer($id: UUID!, $idPerson: UUID!) {
  updateDirTrainer(where: {id: $id}, data: {dirPerson: {connect: {id: $idPerson}}}) {
    ...TrainerFR
  }
}
    ${TrainerFrFragmentDoc}`;
export type UpdateTrainerMutationFn = Apollo.MutationFunction<UpdateTrainerMutation, UpdateTrainerMutationVariables>;

/**
 * __useUpdateTrainerMutation__
 *
 * To run a mutation, you first call `useUpdateTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainerMutation, { data, loading, error }] = useUpdateTrainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idPerson: // value for 'idPerson'
 *   },
 * });
 */
export function useUpdateTrainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTrainerMutation, UpdateTrainerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTrainerMutation, UpdateTrainerMutationVariables>(UpdateTrainerDocument, baseOptions);
      }
export type UpdateTrainerMutationHookResult = ReturnType<typeof useUpdateTrainerMutation>;
export type UpdateTrainerMutationResult = Apollo.MutationResult<UpdateTrainerMutation>;
export type UpdateTrainerMutationOptions = Apollo.BaseMutationOptions<UpdateTrainerMutation, UpdateTrainerMutationVariables>;
export const CreateTrainerDocument = Apollo.gql`
    mutation CreateTrainer($idPerson: UUID!) {
  createDirTrainer(data: {dirPerson: {connect: {id: $idPerson}}}) {
    ...TrainerFR
  }
}
    ${TrainerFrFragmentDoc}`;
export type CreateTrainerMutationFn = Apollo.MutationFunction<CreateTrainerMutation, CreateTrainerMutationVariables>;

/**
 * __useCreateTrainerMutation__
 *
 * To run a mutation, you first call `useCreateTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainerMutation, { data, loading, error }] = useCreateTrainerMutation({
 *   variables: {
 *      idPerson: // value for 'idPerson'
 *   },
 * });
 */
export function useCreateTrainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTrainerMutation, CreateTrainerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTrainerMutation, CreateTrainerMutationVariables>(CreateTrainerDocument, baseOptions);
      }
export type CreateTrainerMutationHookResult = ReturnType<typeof useCreateTrainerMutation>;
export type CreateTrainerMutationResult = Apollo.MutationResult<CreateTrainerMutation>;
export type CreateTrainerMutationOptions = Apollo.BaseMutationOptions<CreateTrainerMutation, CreateTrainerMutationVariables>;
export const AddTypeSportsToTrainerDocument = Apollo.gql`
    mutation AddTypeSportsToTrainer($id: UUID!, $sportId: UUID!) {
  updateDirTrainer(where: {id: $id}, data: {sports: {create: {dirSport: {connect: {id: $sportId}}}}}) {
    ...TrainerFR
  }
}
    ${TrainerFrFragmentDoc}`;
export type AddTypeSportsToTrainerMutationFn = Apollo.MutationFunction<AddTypeSportsToTrainerMutation, AddTypeSportsToTrainerMutationVariables>;

/**
 * __useAddTypeSportsToTrainerMutation__
 *
 * To run a mutation, you first call `useAddTypeSportsToTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTypeSportsToTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTypeSportsToTrainerMutation, { data, loading, error }] = useAddTypeSportsToTrainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useAddTypeSportsToTrainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTypeSportsToTrainerMutation, AddTypeSportsToTrainerMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTypeSportsToTrainerMutation, AddTypeSportsToTrainerMutationVariables>(AddTypeSportsToTrainerDocument, baseOptions);
      }
export type AddTypeSportsToTrainerMutationHookResult = ReturnType<typeof useAddTypeSportsToTrainerMutation>;
export type AddTypeSportsToTrainerMutationResult = Apollo.MutationResult<AddTypeSportsToTrainerMutation>;
export type AddTypeSportsToTrainerMutationOptions = Apollo.BaseMutationOptions<AddTypeSportsToTrainerMutation, AddTypeSportsToTrainerMutationVariables>;
export const TypeSportTrainerArchiveDocument = Apollo.gql`
    mutation TypeSportTrainerArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirTrainerSport(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportTrainerArchiveMutationFn = Apollo.MutationFunction<TypeSportTrainerArchiveMutation, TypeSportTrainerArchiveMutationVariables>;

/**
 * __useTypeSportTrainerArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportTrainerArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportTrainerArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportTrainerArchiveMutation, { data, loading, error }] = useTypeSportTrainerArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useTypeSportTrainerArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportTrainerArchiveMutation, TypeSportTrainerArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportTrainerArchiveMutation, TypeSportTrainerArchiveMutationVariables>(TypeSportTrainerArchiveDocument, baseOptions);
      }
export type TypeSportTrainerArchiveMutationHookResult = ReturnType<typeof useTypeSportTrainerArchiveMutation>;
export type TypeSportTrainerArchiveMutationResult = Apollo.MutationResult<TypeSportTrainerArchiveMutation>;
export type TypeSportTrainerArchiveMutationOptions = Apollo.BaseMutationOptions<TypeSportTrainerArchiveMutation, TypeSportTrainerArchiveMutationVariables>;
export const TypeSportTrainerUnArchiveDocument = Apollo.gql`
    mutation TypeSportTrainerUnArchive($id: UUID!) {
  updateDirTrainerSport(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportTrainerUnArchiveMutationFn = Apollo.MutationFunction<TypeSportTrainerUnArchiveMutation, TypeSportTrainerUnArchiveMutationVariables>;

/**
 * __useTypeSportTrainerUnArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportTrainerUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportTrainerUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportTrainerUnArchiveMutation, { data, loading, error }] = useTypeSportTrainerUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportTrainerUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportTrainerUnArchiveMutation, TypeSportTrainerUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportTrainerUnArchiveMutation, TypeSportTrainerUnArchiveMutationVariables>(TypeSportTrainerUnArchiveDocument, baseOptions);
      }
export type TypeSportTrainerUnArchiveMutationHookResult = ReturnType<typeof useTypeSportTrainerUnArchiveMutation>;
export type TypeSportTrainerUnArchiveMutationResult = Apollo.MutationResult<TypeSportTrainerUnArchiveMutation>;
export type TypeSportTrainerUnArchiveMutationOptions = Apollo.BaseMutationOptions<TypeSportTrainerUnArchiveMutation, TypeSportTrainerUnArchiveMutationVariables>;
export const CreateTrainerOrganizationDocument = Apollo.gql`
    mutation CreateTrainerOrganization($id: UUID!, $clsPosition: UUID!, $idDirOrganization: UUID!, $employmentDate: DateTime!, $dismissalDate: DateTime) {
  updateDirTrainerSport(where: {id: $id}, data: {organizations: {create: {clsPosition: {connect: {id: $clsPosition}}, dirOrganization: {connect: {id: $idDirOrganization}}, employmentDate: $employmentDate, dismissalDate: $dismissalDate}}}) {
    id
  }
}
    `;
export type CreateTrainerOrganizationMutationFn = Apollo.MutationFunction<CreateTrainerOrganizationMutation, CreateTrainerOrganizationMutationVariables>;

/**
 * __useCreateTrainerOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateTrainerOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainerOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainerOrganizationMutation, { data, loading, error }] = useCreateTrainerOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clsPosition: // value for 'clsPosition'
 *      idDirOrganization: // value for 'idDirOrganization'
 *      employmentDate: // value for 'employmentDate'
 *      dismissalDate: // value for 'dismissalDate'
 *   },
 * });
 */
export function useCreateTrainerOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTrainerOrganizationMutation, CreateTrainerOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTrainerOrganizationMutation, CreateTrainerOrganizationMutationVariables>(CreateTrainerOrganizationDocument, baseOptions);
      }
export type CreateTrainerOrganizationMutationHookResult = ReturnType<typeof useCreateTrainerOrganizationMutation>;
export type CreateTrainerOrganizationMutationResult = Apollo.MutationResult<CreateTrainerOrganizationMutation>;
export type CreateTrainerOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateTrainerOrganizationMutation, CreateTrainerOrganizationMutationVariables>;
export const UpdateTrainerOrganizationDocument = Apollo.gql`
    mutation UpdateTrainerOrganization($id: UUID!, $clsPosition: UUID!, $idDirOrganization: UUID!, $employmentDate: DateTime!, $dismissalDate: DateTime) {
  updateDirTrainerSportOrganization(where: {id: $id}, data: {clsPosition: {connect: {id: $clsPosition}}, dirOrganization: {connect: {id: $idDirOrganization}}, employmentDate: $employmentDate, dismissalDate: $dismissalDate}) {
    id
  }
}
    `;
export type UpdateTrainerOrganizationMutationFn = Apollo.MutationFunction<UpdateTrainerOrganizationMutation, UpdateTrainerOrganizationMutationVariables>;

/**
 * __useUpdateTrainerOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateTrainerOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainerOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainerOrganizationMutation, { data, loading, error }] = useUpdateTrainerOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clsPosition: // value for 'clsPosition'
 *      idDirOrganization: // value for 'idDirOrganization'
 *      employmentDate: // value for 'employmentDate'
 *      dismissalDate: // value for 'dismissalDate'
 *   },
 * });
 */
export function useUpdateTrainerOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTrainerOrganizationMutation, UpdateTrainerOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTrainerOrganizationMutation, UpdateTrainerOrganizationMutationVariables>(UpdateTrainerOrganizationDocument, baseOptions);
      }
export type UpdateTrainerOrganizationMutationHookResult = ReturnType<typeof useUpdateTrainerOrganizationMutation>;
export type UpdateTrainerOrganizationMutationResult = Apollo.MutationResult<UpdateTrainerOrganizationMutation>;
export type UpdateTrainerOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateTrainerOrganizationMutation, UpdateTrainerOrganizationMutationVariables>;
export const FireTrainerWithAthleteSelfTrainDocument = Apollo.gql`
    mutation fireTrainerWithAthleteSelfTrain($trainerID: UUID!, $organizationID: UUID!, $dismissalDate: DateTime!) {
  trainerDismissalWithAthletesSelfTraining(trainerID: $trainerID, organizationID: $organizationID, dismissalDate: $dismissalDate)
}
    `;
export type FireTrainerWithAthleteSelfTrainMutationFn = Apollo.MutationFunction<FireTrainerWithAthleteSelfTrainMutation, FireTrainerWithAthleteSelfTrainMutationVariables>;

/**
 * __useFireTrainerWithAthleteSelfTrainMutation__
 *
 * To run a mutation, you first call `useFireTrainerWithAthleteSelfTrainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFireTrainerWithAthleteSelfTrainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fireTrainerWithAthleteSelfTrainMutation, { data, loading, error }] = useFireTrainerWithAthleteSelfTrainMutation({
 *   variables: {
 *      trainerID: // value for 'trainerID'
 *      organizationID: // value for 'organizationID'
 *      dismissalDate: // value for 'dismissalDate'
 *   },
 * });
 */
export function useFireTrainerWithAthleteSelfTrainMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FireTrainerWithAthleteSelfTrainMutation, FireTrainerWithAthleteSelfTrainMutationVariables>) {
        return ApolloReactHooks.useMutation<FireTrainerWithAthleteSelfTrainMutation, FireTrainerWithAthleteSelfTrainMutationVariables>(FireTrainerWithAthleteSelfTrainDocument, baseOptions);
      }
export type FireTrainerWithAthleteSelfTrainMutationHookResult = ReturnType<typeof useFireTrainerWithAthleteSelfTrainMutation>;
export type FireTrainerWithAthleteSelfTrainMutationResult = Apollo.MutationResult<FireTrainerWithAthleteSelfTrainMutation>;
export type FireTrainerWithAthleteSelfTrainMutationOptions = Apollo.BaseMutationOptions<FireTrainerWithAthleteSelfTrainMutation, FireTrainerWithAthleteSelfTrainMutationVariables>;
export const FireTrainerWithAthleteExpelDocument = Apollo.gql`
    mutation fireTrainerWithAthleteExpel($trainerID: UUID!, $organizationID: UUID!, $dismissalDate: DateTime!) {
  trainerDismissalWithAthletesExpel(trainerID: $trainerID, organizationID: $organizationID, dismissalDate: $dismissalDate)
}
    `;
export type FireTrainerWithAthleteExpelMutationFn = Apollo.MutationFunction<FireTrainerWithAthleteExpelMutation, FireTrainerWithAthleteExpelMutationVariables>;

/**
 * __useFireTrainerWithAthleteExpelMutation__
 *
 * To run a mutation, you first call `useFireTrainerWithAthleteExpelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFireTrainerWithAthleteExpelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fireTrainerWithAthleteExpelMutation, { data, loading, error }] = useFireTrainerWithAthleteExpelMutation({
 *   variables: {
 *      trainerID: // value for 'trainerID'
 *      organizationID: // value for 'organizationID'
 *      dismissalDate: // value for 'dismissalDate'
 *   },
 * });
 */
export function useFireTrainerWithAthleteExpelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FireTrainerWithAthleteExpelMutation, FireTrainerWithAthleteExpelMutationVariables>) {
        return ApolloReactHooks.useMutation<FireTrainerWithAthleteExpelMutation, FireTrainerWithAthleteExpelMutationVariables>(FireTrainerWithAthleteExpelDocument, baseOptions);
      }
export type FireTrainerWithAthleteExpelMutationHookResult = ReturnType<typeof useFireTrainerWithAthleteExpelMutation>;
export type FireTrainerWithAthleteExpelMutationResult = Apollo.MutationResult<FireTrainerWithAthleteExpelMutation>;
export type FireTrainerWithAthleteExpelMutationOptions = Apollo.BaseMutationOptions<FireTrainerWithAthleteExpelMutation, FireTrainerWithAthleteExpelMutationVariables>;
export const FireTrainerWithAthleteAssignToAnotherTrainerDocument = Apollo.gql`
    mutation fireTrainerWithAthleteAssignToAnotherTrainer($fromTrainerID: UUID!, $toTrainerID: UUID!, $organizationID: UUID!, $dismissalDate: DateTime!) {
  trainerDismissalWithAthletesAssignToAnotherTrainer(fromTrainerID: $fromTrainerID, toTrainerID: $toTrainerID, organizationID: $organizationID, dismissalDate: $dismissalDate)
}
    `;
export type FireTrainerWithAthleteAssignToAnotherTrainerMutationFn = Apollo.MutationFunction<FireTrainerWithAthleteAssignToAnotherTrainerMutation, FireTrainerWithAthleteAssignToAnotherTrainerMutationVariables>;

/**
 * __useFireTrainerWithAthleteAssignToAnotherTrainerMutation__
 *
 * To run a mutation, you first call `useFireTrainerWithAthleteAssignToAnotherTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFireTrainerWithAthleteAssignToAnotherTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fireTrainerWithAthleteAssignToAnotherTrainerMutation, { data, loading, error }] = useFireTrainerWithAthleteAssignToAnotherTrainerMutation({
 *   variables: {
 *      fromTrainerID: // value for 'fromTrainerID'
 *      toTrainerID: // value for 'toTrainerID'
 *      organizationID: // value for 'organizationID'
 *      dismissalDate: // value for 'dismissalDate'
 *   },
 * });
 */
export function useFireTrainerWithAthleteAssignToAnotherTrainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FireTrainerWithAthleteAssignToAnotherTrainerMutation, FireTrainerWithAthleteAssignToAnotherTrainerMutationVariables>) {
        return ApolloReactHooks.useMutation<FireTrainerWithAthleteAssignToAnotherTrainerMutation, FireTrainerWithAthleteAssignToAnotherTrainerMutationVariables>(FireTrainerWithAthleteAssignToAnotherTrainerDocument, baseOptions);
      }
export type FireTrainerWithAthleteAssignToAnotherTrainerMutationHookResult = ReturnType<typeof useFireTrainerWithAthleteAssignToAnotherTrainerMutation>;
export type FireTrainerWithAthleteAssignToAnotherTrainerMutationResult = Apollo.MutationResult<FireTrainerWithAthleteAssignToAnotherTrainerMutation>;
export type FireTrainerWithAthleteAssignToAnotherTrainerMutationOptions = Apollo.BaseMutationOptions<FireTrainerWithAthleteAssignToAnotherTrainerMutation, FireTrainerWithAthleteAssignToAnotherTrainerMutationVariables>;
export const FireTrainerDocument = Apollo.gql`
    mutation FireTrainer($id: UUID!, $dismissalDate: DateTime!) {
  updateDirTrainerSportOrganization(where: {id: $id}, data: {dismissalDate: $dismissalDate}) {
    id
  }
}
    `;
export type FireTrainerMutationFn = Apollo.MutationFunction<FireTrainerMutation, FireTrainerMutationVariables>;

/**
 * __useFireTrainerMutation__
 *
 * To run a mutation, you first call `useFireTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFireTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fireTrainerMutation, { data, loading, error }] = useFireTrainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dismissalDate: // value for 'dismissalDate'
 *   },
 * });
 */
export function useFireTrainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FireTrainerMutation, FireTrainerMutationVariables>) {
        return ApolloReactHooks.useMutation<FireTrainerMutation, FireTrainerMutationVariables>(FireTrainerDocument, baseOptions);
      }
export type FireTrainerMutationHookResult = ReturnType<typeof useFireTrainerMutation>;
export type FireTrainerMutationResult = Apollo.MutationResult<FireTrainerMutation>;
export type FireTrainerMutationOptions = Apollo.BaseMutationOptions<FireTrainerMutation, FireTrainerMutationVariables>;
export const AnalyticsEventTotalCountUsersDocument = Apollo.gql`
    query AnalyticsEventTotalCountUsers($id: UUID!) {
  aggregateParticipantsNumberByCalendar(where: {id: $id}) {
    subjectsCount
    athletesCountByMan
    athletesCountByWoman
    athletesCount
    membersCount
    events {
      name
      children {
        name
        sports
        disciplineGroups
        subjectsCount
        athletesCountByMan
        athletesCountByWoman
        athletesCount
        membersCount
      }
    }
  }
}
    `;

/**
 * __useAnalyticsEventTotalCountUsersQuery__
 *
 * To run a query within a React component, call `useAnalyticsEventTotalCountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsEventTotalCountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsEventTotalCountUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnalyticsEventTotalCountUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AnalyticsEventTotalCountUsersQuery, AnalyticsEventTotalCountUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<AnalyticsEventTotalCountUsersQuery, AnalyticsEventTotalCountUsersQueryVariables>(AnalyticsEventTotalCountUsersDocument, baseOptions);
      }
export function useAnalyticsEventTotalCountUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnalyticsEventTotalCountUsersQuery, AnalyticsEventTotalCountUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AnalyticsEventTotalCountUsersQuery, AnalyticsEventTotalCountUsersQueryVariables>(AnalyticsEventTotalCountUsersDocument, baseOptions);
        }
export type AnalyticsEventTotalCountUsersQueryHookResult = ReturnType<typeof useAnalyticsEventTotalCountUsersQuery>;
export type AnalyticsEventTotalCountUsersLazyQueryHookResult = ReturnType<typeof useAnalyticsEventTotalCountUsersLazyQuery>;
export type AnalyticsEventTotalCountUsersQueryResult = Apollo.QueryResult<AnalyticsEventTotalCountUsersQuery, AnalyticsEventTotalCountUsersQueryVariables>;
export const AnalyticsEventAgeUsersDocument = Apollo.gql`
    query AnalyticsEventAgeUsers($id: UUID!) {
  aggregateParticipantsAgeByCalendar(where: {id: $id}) {
    calendarName
    totalAmount
    byYear {
      year
      amount
    }
    byEvent {
      eventName
      sports
      disciplineGroups
      byYear {
        year
        amount
      }
    }
  }
}
    `;

/**
 * __useAnalyticsEventAgeUsersQuery__
 *
 * To run a query within a React component, call `useAnalyticsEventAgeUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsEventAgeUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsEventAgeUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnalyticsEventAgeUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AnalyticsEventAgeUsersQuery, AnalyticsEventAgeUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<AnalyticsEventAgeUsersQuery, AnalyticsEventAgeUsersQueryVariables>(AnalyticsEventAgeUsersDocument, baseOptions);
      }
export function useAnalyticsEventAgeUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnalyticsEventAgeUsersQuery, AnalyticsEventAgeUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AnalyticsEventAgeUsersQuery, AnalyticsEventAgeUsersQueryVariables>(AnalyticsEventAgeUsersDocument, baseOptions);
        }
export type AnalyticsEventAgeUsersQueryHookResult = ReturnType<typeof useAnalyticsEventAgeUsersQuery>;
export type AnalyticsEventAgeUsersLazyQueryHookResult = ReturnType<typeof useAnalyticsEventAgeUsersLazyQuery>;
export type AnalyticsEventAgeUsersQueryResult = Apollo.QueryResult<AnalyticsEventAgeUsersQuery, AnalyticsEventAgeUsersQueryVariables>;
export const ParticipantsRankAndAwardByCalendarDocument = Apollo.gql`
    query ParticipantsRankAndAwardByCalendar($id: UUID!) {
  aggregateParticipantsRankAndAwardByCalendar(where: {id: $id}) {
    calendarName
    totalAmountWithoutValidRank
    totalAmount
    total {
      name
      amount
    }
    byEvent {
      eventName
      sports
      disciplineGroups
      totalAmountWithoutValidRank
      totalAmount
      total {
        name
        amount
      }
    }
  }
}
    `;

/**
 * __useParticipantsRankAndAwardByCalendarQuery__
 *
 * To run a query within a React component, call `useParticipantsRankAndAwardByCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantsRankAndAwardByCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantsRankAndAwardByCalendarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useParticipantsRankAndAwardByCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParticipantsRankAndAwardByCalendarQuery, ParticipantsRankAndAwardByCalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<ParticipantsRankAndAwardByCalendarQuery, ParticipantsRankAndAwardByCalendarQueryVariables>(ParticipantsRankAndAwardByCalendarDocument, baseOptions);
      }
export function useParticipantsRankAndAwardByCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParticipantsRankAndAwardByCalendarQuery, ParticipantsRankAndAwardByCalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParticipantsRankAndAwardByCalendarQuery, ParticipantsRankAndAwardByCalendarQueryVariables>(ParticipantsRankAndAwardByCalendarDocument, baseOptions);
        }
export type ParticipantsRankAndAwardByCalendarQueryHookResult = ReturnType<typeof useParticipantsRankAndAwardByCalendarQuery>;
export type ParticipantsRankAndAwardByCalendarLazyQueryHookResult = ReturnType<typeof useParticipantsRankAndAwardByCalendarLazyQuery>;
export type ParticipantsRankAndAwardByCalendarQueryResult = Apollo.QueryResult<ParticipantsRankAndAwardByCalendarQuery, ParticipantsRankAndAwardByCalendarQueryVariables>;
export const AggregateEventRefereesCategoryByCalendarDocument = Apollo.gql`
    query AggregateEventRefereesCategoryByCalendar($id: UUID) {
  aggregateEventRefereesCategoryByCalendar(where: {id: $id}) {
    calendarName
    byEvent {
      eventName
      sports
      disciplineGroups
      totalAmount
      total {
        name
        amount
      }
    }
  }
}
    `;

/**
 * __useAggregateEventRefereesCategoryByCalendarQuery__
 *
 * To run a query within a React component, call `useAggregateEventRefereesCategoryByCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateEventRefereesCategoryByCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateEventRefereesCategoryByCalendarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAggregateEventRefereesCategoryByCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AggregateEventRefereesCategoryByCalendarQuery, AggregateEventRefereesCategoryByCalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<AggregateEventRefereesCategoryByCalendarQuery, AggregateEventRefereesCategoryByCalendarQueryVariables>(AggregateEventRefereesCategoryByCalendarDocument, baseOptions);
      }
export function useAggregateEventRefereesCategoryByCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AggregateEventRefereesCategoryByCalendarQuery, AggregateEventRefereesCategoryByCalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AggregateEventRefereesCategoryByCalendarQuery, AggregateEventRefereesCategoryByCalendarQueryVariables>(AggregateEventRefereesCategoryByCalendarDocument, baseOptions);
        }
export type AggregateEventRefereesCategoryByCalendarQueryHookResult = ReturnType<typeof useAggregateEventRefereesCategoryByCalendarQuery>;
export type AggregateEventRefereesCategoryByCalendarLazyQueryHookResult = ReturnType<typeof useAggregateEventRefereesCategoryByCalendarLazyQuery>;
export type AggregateEventRefereesCategoryByCalendarQueryResult = Apollo.QueryResult<AggregateEventRefereesCategoryByCalendarQuery, AggregateEventRefereesCategoryByCalendarQueryVariables>;
export const AggregateEventAthletesByPointsDocument = Apollo.gql`
    query AggregateEventAthletesByPoints($id: UUID) {
  aggregateEventAthletesByPoints(where: {id: $id}) {
    regions {
      name
      universityName
      athletesNumber
      regionID
      totalGold
      totalSilver
      totalBronze
      total_4_10
      total_11_20
    }
  }
}
    `;

/**
 * __useAggregateEventAthletesByPointsQuery__
 *
 * To run a query within a React component, call `useAggregateEventAthletesByPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateEventAthletesByPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateEventAthletesByPointsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAggregateEventAthletesByPointsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AggregateEventAthletesByPointsQuery, AggregateEventAthletesByPointsQueryVariables>) {
        return ApolloReactHooks.useQuery<AggregateEventAthletesByPointsQuery, AggregateEventAthletesByPointsQueryVariables>(AggregateEventAthletesByPointsDocument, baseOptions);
      }
export function useAggregateEventAthletesByPointsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AggregateEventAthletesByPointsQuery, AggregateEventAthletesByPointsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AggregateEventAthletesByPointsQuery, AggregateEventAthletesByPointsQueryVariables>(AggregateEventAthletesByPointsDocument, baseOptions);
        }
export type AggregateEventAthletesByPointsQueryHookResult = ReturnType<typeof useAggregateEventAthletesByPointsQuery>;
export type AggregateEventAthletesByPointsLazyQueryHookResult = ReturnType<typeof useAggregateEventAthletesByPointsLazyQuery>;
export type AggregateEventAthletesByPointsQueryResult = Apollo.QueryResult<AggregateEventAthletesByPointsQuery, AggregateEventAthletesByPointsQueryVariables>;
export const ForeignCityByIdDocument = Apollo.gql`
    query ForeignCityById($id: UUID!) {
  dirForeignCity(where: {id: $id}) {
    ...ForeignCity
  }
}
    ${ForeignCityFragmentDoc}`;

/**
 * __useForeignCityByIdQuery__
 *
 * To run a query within a React component, call `useForeignCityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useForeignCityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForeignCityByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForeignCityByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ForeignCityByIdQuery, ForeignCityByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ForeignCityByIdQuery, ForeignCityByIdQueryVariables>(ForeignCityByIdDocument, baseOptions);
      }
export function useForeignCityByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ForeignCityByIdQuery, ForeignCityByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ForeignCityByIdQuery, ForeignCityByIdQueryVariables>(ForeignCityByIdDocument, baseOptions);
        }
export type ForeignCityByIdQueryHookResult = ReturnType<typeof useForeignCityByIdQuery>;
export type ForeignCityByIdLazyQueryHookResult = ReturnType<typeof useForeignCityByIdLazyQuery>;
export type ForeignCityByIdQueryResult = Apollo.QueryResult<ForeignCityByIdQuery, ForeignCityByIdQueryVariables>;
export const FederalDistrictsDocument = Apollo.gql`
    query FederalDistricts($first: Int!, $skip: Int!) {
  dirFederalDistricts(first: $first, skip: $skip) {
    ...FederalDistrict
  }
}
    ${FederalDistrictFragmentDoc}`;

/**
 * __useFederalDistrictsQuery__
 *
 * To run a query within a React component, call `useFederalDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFederalDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFederalDistrictsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useFederalDistrictsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FederalDistrictsQuery, FederalDistrictsQueryVariables>) {
        return ApolloReactHooks.useQuery<FederalDistrictsQuery, FederalDistrictsQueryVariables>(FederalDistrictsDocument, baseOptions);
      }
export function useFederalDistrictsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FederalDistrictsQuery, FederalDistrictsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FederalDistrictsQuery, FederalDistrictsQueryVariables>(FederalDistrictsDocument, baseOptions);
        }
export type FederalDistrictsQueryHookResult = ReturnType<typeof useFederalDistrictsQuery>;
export type FederalDistrictsLazyQueryHookResult = ReturnType<typeof useFederalDistrictsLazyQuery>;
export type FederalDistrictsQueryResult = Apollo.QueryResult<FederalDistrictsQuery, FederalDistrictsQueryVariables>;
export const ForeignCityArchiveDocument = Apollo.gql`
    mutation ForeignCityArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirForeignCity(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type ForeignCityArchiveMutationFn = Apollo.MutationFunction<ForeignCityArchiveMutation, ForeignCityArchiveMutationVariables>;

/**
 * __useForeignCityArchiveMutation__
 *
 * To run a mutation, you first call `useForeignCityArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForeignCityArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foreignCityArchiveMutation, { data, loading, error }] = useForeignCityArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useForeignCityArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForeignCityArchiveMutation, ForeignCityArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<ForeignCityArchiveMutation, ForeignCityArchiveMutationVariables>(ForeignCityArchiveDocument, baseOptions);
      }
export type ForeignCityArchiveMutationHookResult = ReturnType<typeof useForeignCityArchiveMutation>;
export type ForeignCityArchiveMutationResult = Apollo.MutationResult<ForeignCityArchiveMutation>;
export type ForeignCityArchiveMutationOptions = Apollo.BaseMutationOptions<ForeignCityArchiveMutation, ForeignCityArchiveMutationVariables>;
export const ForeignCityUnArchiveDocument = Apollo.gql`
    mutation ForeignCityUnArchive($id: UUID!) {
  updateDirForeignCity(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type ForeignCityUnArchiveMutationFn = Apollo.MutationFunction<ForeignCityUnArchiveMutation, ForeignCityUnArchiveMutationVariables>;

/**
 * __useForeignCityUnArchiveMutation__
 *
 * To run a mutation, you first call `useForeignCityUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForeignCityUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foreignCityUnArchiveMutation, { data, loading, error }] = useForeignCityUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForeignCityUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForeignCityUnArchiveMutation, ForeignCityUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<ForeignCityUnArchiveMutation, ForeignCityUnArchiveMutationVariables>(ForeignCityUnArchiveDocument, baseOptions);
      }
export type ForeignCityUnArchiveMutationHookResult = ReturnType<typeof useForeignCityUnArchiveMutation>;
export type ForeignCityUnArchiveMutationResult = Apollo.MutationResult<ForeignCityUnArchiveMutation>;
export type ForeignCityUnArchiveMutationOptions = Apollo.BaseMutationOptions<ForeignCityUnArchiveMutation, ForeignCityUnArchiveMutationVariables>;
export const ForeignCityUpdateDocument = Apollo.gql`
    mutation ForeignCityUpdate($id: UUID!, $fullName: String!, $shortName: String!, $countryId: DirCountryUpdateOneRequiredWithoutDirForeignCitiesInput) {
  updateDirForeignCity(where: {id: $id}, data: {fullName: $fullName, shortName: $shortName, dirCountry: $countryId}) {
    ...ForeignCity
  }
}
    ${ForeignCityFragmentDoc}`;
export type ForeignCityUpdateMutationFn = Apollo.MutationFunction<ForeignCityUpdateMutation, ForeignCityUpdateMutationVariables>;

/**
 * __useForeignCityUpdateMutation__
 *
 * To run a mutation, you first call `useForeignCityUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForeignCityUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foreignCityUpdateMutation, { data, loading, error }] = useForeignCityUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useForeignCityUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForeignCityUpdateMutation, ForeignCityUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ForeignCityUpdateMutation, ForeignCityUpdateMutationVariables>(ForeignCityUpdateDocument, baseOptions);
      }
export type ForeignCityUpdateMutationHookResult = ReturnType<typeof useForeignCityUpdateMutation>;
export type ForeignCityUpdateMutationResult = Apollo.MutationResult<ForeignCityUpdateMutation>;
export type ForeignCityUpdateMutationOptions = Apollo.BaseMutationOptions<ForeignCityUpdateMutation, ForeignCityUpdateMutationVariables>;
export const ForeignCityCreateDocument = Apollo.gql`
    mutation ForeignCityCreate($fullName: String!, $shortName: String!, $countryId: DirCountryCreateOneWithoutDirForeignCitiesInput!) {
  createDirForeignCity(data: {fullName: $fullName, shortName: $shortName, dirCountry: $countryId}) {
    ...ForeignCity
  }
}
    ${ForeignCityFragmentDoc}`;
export type ForeignCityCreateMutationFn = Apollo.MutationFunction<ForeignCityCreateMutation, ForeignCityCreateMutationVariables>;

/**
 * __useForeignCityCreateMutation__
 *
 * To run a mutation, you first call `useForeignCityCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForeignCityCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foreignCityCreateMutation, { data, loading, error }] = useForeignCityCreateMutation({
 *   variables: {
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useForeignCityCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForeignCityCreateMutation, ForeignCityCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ForeignCityCreateMutation, ForeignCityCreateMutationVariables>(ForeignCityCreateDocument, baseOptions);
      }
export type ForeignCityCreateMutationHookResult = ReturnType<typeof useForeignCityCreateMutation>;
export type ForeignCityCreateMutationResult = Apollo.MutationResult<ForeignCityCreateMutation>;
export type ForeignCityCreateMutationOptions = Apollo.BaseMutationOptions<ForeignCityCreateMutation, ForeignCityCreateMutationVariables>;
export const ForeignCitiesDocument = Apollo.gql`
    query ForeignCities($first: Int!, $skip: Int!, $filter: DirForeignCityWhereInput) {
  dirForeignCities(first: $first, skip: $skip, where: $filter) {
    ...ForeignCity
  }
}
    ${ForeignCityFragmentDoc}`;

/**
 * __useForeignCitiesQuery__
 *
 * To run a query within a React component, call `useForeignCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useForeignCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForeignCitiesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useForeignCitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ForeignCitiesQuery, ForeignCitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<ForeignCitiesQuery, ForeignCitiesQueryVariables>(ForeignCitiesDocument, baseOptions);
      }
export function useForeignCitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ForeignCitiesQuery, ForeignCitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ForeignCitiesQuery, ForeignCitiesQueryVariables>(ForeignCitiesDocument, baseOptions);
        }
export type ForeignCitiesQueryHookResult = ReturnType<typeof useForeignCitiesQuery>;
export type ForeignCitiesLazyQueryHookResult = ReturnType<typeof useForeignCitiesLazyQuery>;
export type ForeignCitiesQueryResult = Apollo.QueryResult<ForeignCitiesQuery, ForeignCitiesQueryVariables>;
export const ForeignCitiesListDocument = Apollo.gql`
    query ForeignCitiesList($filter: DirForeignCityWhereInput) {
  dirForeignCities(orderBy: fullName_ASC, where: $filter) {
    country: dirCountry {
      id
    }
    value: id
    label: fullName
  }
}
    `;

/**
 * __useForeignCitiesListQuery__
 *
 * To run a query within a React component, call `useForeignCitiesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useForeignCitiesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForeignCitiesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useForeignCitiesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ForeignCitiesListQuery, ForeignCitiesListQueryVariables>) {
        return ApolloReactHooks.useQuery<ForeignCitiesListQuery, ForeignCitiesListQueryVariables>(ForeignCitiesListDocument, baseOptions);
      }
export function useForeignCitiesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ForeignCitiesListQuery, ForeignCitiesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ForeignCitiesListQuery, ForeignCitiesListQueryVariables>(ForeignCitiesListDocument, baseOptions);
        }
export type ForeignCitiesListQueryHookResult = ReturnType<typeof useForeignCitiesListQuery>;
export type ForeignCitiesListLazyQueryHookResult = ReturnType<typeof useForeignCitiesListLazyQuery>;
export type ForeignCitiesListQueryResult = Apollo.QueryResult<ForeignCitiesListQuery, ForeignCitiesListQueryVariables>;
export const SuggestAddressesDocument = Apollo.gql`
    query suggestAddresses($by: String!) {
  suggestAddresses(by: $by) {
    value
    unrestrictedValue
    postcode
    country
    region
    area
    city
    settlement
    street
    house
    number
  }
}
    `;

/**
 * __useSuggestAddressesQuery__
 *
 * To run a query within a React component, call `useSuggestAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestAddressesQuery({
 *   variables: {
 *      by: // value for 'by'
 *   },
 * });
 */
export function useSuggestAddressesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SuggestAddressesQuery, SuggestAddressesQueryVariables>) {
        return ApolloReactHooks.useQuery<SuggestAddressesQuery, SuggestAddressesQueryVariables>(SuggestAddressesDocument, baseOptions);
      }
export function useSuggestAddressesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SuggestAddressesQuery, SuggestAddressesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SuggestAddressesQuery, SuggestAddressesQueryVariables>(SuggestAddressesDocument, baseOptions);
        }
export type SuggestAddressesQueryHookResult = ReturnType<typeof useSuggestAddressesQuery>;
export type SuggestAddressesLazyQueryHookResult = ReturnType<typeof useSuggestAddressesLazyQuery>;
export type SuggestAddressesQueryResult = Apollo.QueryResult<SuggestAddressesQuery, SuggestAddressesQueryVariables>;
export const CountriesFetchDocument = Apollo.gql`
    query CountriesFetch($filter: DirCountryWhereInput) {
  dirCountries(where: $filter, orderBy: fullName_ASC) {
    value: id
    label: fullName
  }
}
    `;

/**
 * __useCountriesFetchQuery__
 *
 * To run a query within a React component, call `useCountriesFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesFetchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountriesFetchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountriesFetchQuery, CountriesFetchQueryVariables>) {
        return ApolloReactHooks.useQuery<CountriesFetchQuery, CountriesFetchQueryVariables>(CountriesFetchDocument, baseOptions);
      }
export function useCountriesFetchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountriesFetchQuery, CountriesFetchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountriesFetchQuery, CountriesFetchQueryVariables>(CountriesFetchDocument, baseOptions);
        }
export type CountriesFetchQueryHookResult = ReturnType<typeof useCountriesFetchQuery>;
export type CountriesFetchLazyQueryHookResult = ReturnType<typeof useCountriesFetchLazyQuery>;
export type CountriesFetchQueryResult = Apollo.QueryResult<CountriesFetchQuery, CountriesFetchQueryVariables>;
export const FederalDistrictsFetchDocument = Apollo.gql`
    query FederalDistrictsFetch {
  dirFederalDistricts {
    value: id
    label: fullName
  }
}
    `;

/**
 * __useFederalDistrictsFetchQuery__
 *
 * To run a query within a React component, call `useFederalDistrictsFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFederalDistrictsFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFederalDistrictsFetchQuery({
 *   variables: {
 *   },
 * });
 */
export function useFederalDistrictsFetchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FederalDistrictsFetchQuery, FederalDistrictsFetchQueryVariables>) {
        return ApolloReactHooks.useQuery<FederalDistrictsFetchQuery, FederalDistrictsFetchQueryVariables>(FederalDistrictsFetchDocument, baseOptions);
      }
export function useFederalDistrictsFetchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FederalDistrictsFetchQuery, FederalDistrictsFetchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FederalDistrictsFetchQuery, FederalDistrictsFetchQueryVariables>(FederalDistrictsFetchDocument, baseOptions);
        }
export type FederalDistrictsFetchQueryHookResult = ReturnType<typeof useFederalDistrictsFetchQuery>;
export type FederalDistrictsFetchLazyQueryHookResult = ReturnType<typeof useFederalDistrictsFetchLazyQuery>;
export type FederalDistrictsFetchQueryResult = Apollo.QueryResult<FederalDistrictsFetchQuery, FederalDistrictsFetchQueryVariables>;
export const GenerateXlsFileDocument = Apollo.gql`
    query generateXlsFile($filterString: String!, $filter: DirSportWhereInput!, $first: Int!) {
  dirSportsReport {
    xlsx(where: $filter, orderBy: registryNumber_DESC, first: $first, filter: $filterString, registryNumberAlias: "Идентификатор вида спорта", evskAlias: "Номер ЕВСК", fullNameAlias: "Название", archiveAlias: "В архиве")
  }
}
    `;

/**
 * __useGenerateXlsFileQuery__
 *
 * To run a query within a React component, call `useGenerateXlsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateXlsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateXlsFileQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGenerateXlsFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerateXlsFileQuery, GenerateXlsFileQueryVariables>) {
        return ApolloReactHooks.useQuery<GenerateXlsFileQuery, GenerateXlsFileQueryVariables>(GenerateXlsFileDocument, baseOptions);
      }
export function useGenerateXlsFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerateXlsFileQuery, GenerateXlsFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenerateXlsFileQuery, GenerateXlsFileQueryVariables>(GenerateXlsFileDocument, baseOptions);
        }
export type GenerateXlsFileQueryHookResult = ReturnType<typeof useGenerateXlsFileQuery>;
export type GenerateXlsFileLazyQueryHookResult = ReturnType<typeof useGenerateXlsFileLazyQuery>;
export type GenerateXlsFileQueryResult = Apollo.QueryResult<GenerateXlsFileQuery, GenerateXlsFileQueryVariables>;
export const GenerateAthletesXlsFileDocument = Apollo.gql`
    query generateAthletesXlsFile($filterString: String!, $filter: DirAthleteWhereInput!, $first: Int!) {
  dirAthletesReport {
    xlsx(where: $filter, orderBy: registryNumber_DESC, first: $first, filter: $filterString, registryNumberAlias: "Реестровый номер", fullNameAlias: "Фамилия Имя Отчество", birthdayAlias: "Дата рождения", genderAlias: "Пол", regionAlias: "Регион основной", sportAlias: "Вид спорта", organizationAlias: "Организация", trainingStagesAlias: "Этапы подготовки", trainersAlias: "Тренеры", archiveAlias: "В архиве")
  }
}
    `;

/**
 * __useGenerateAthletesXlsFileQuery__
 *
 * To run a query within a React component, call `useGenerateAthletesXlsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateAthletesXlsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateAthletesXlsFileQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGenerateAthletesXlsFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerateAthletesXlsFileQuery, GenerateAthletesXlsFileQueryVariables>) {
        return ApolloReactHooks.useQuery<GenerateAthletesXlsFileQuery, GenerateAthletesXlsFileQueryVariables>(GenerateAthletesXlsFileDocument, baseOptions);
      }
export function useGenerateAthletesXlsFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerateAthletesXlsFileQuery, GenerateAthletesXlsFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenerateAthletesXlsFileQuery, GenerateAthletesXlsFileQueryVariables>(GenerateAthletesXlsFileDocument, baseOptions);
        }
export type GenerateAthletesXlsFileQueryHookResult = ReturnType<typeof useGenerateAthletesXlsFileQuery>;
export type GenerateAthletesXlsFileLazyQueryHookResult = ReturnType<typeof useGenerateAthletesXlsFileLazyQuery>;
export type GenerateAthletesXlsFileQueryResult = Apollo.QueryResult<GenerateAthletesXlsFileQuery, GenerateAthletesXlsFileQueryVariables>;
export const GenerateTrainersXlsFileDocument = Apollo.gql`
    query generateTrainersXlsFile($filterString: String!, $filter: DirTrainerWhereInput!, $first: Int!) {
  dirTrainersReport {
    xlsx(where: $filter, orderBy: registryNumber_DESC, first: $first, filter: $filterString, registryNumberAlias: "Реестровый номер", lastnameAlias: "Фамилия", firstnameAlias: "Имя", patronymicAlias: "Отчество", birthdayAlias: "Дата рождения", genderAlias: "Пол", regionAlias: "Регион основной", sportsAlias: "Виды спорта")
  }
}
    `;

/**
 * __useGenerateTrainersXlsFileQuery__
 *
 * To run a query within a React component, call `useGenerateTrainersXlsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateTrainersXlsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateTrainersXlsFileQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGenerateTrainersXlsFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerateTrainersXlsFileQuery, GenerateTrainersXlsFileQueryVariables>) {
        return ApolloReactHooks.useQuery<GenerateTrainersXlsFileQuery, GenerateTrainersXlsFileQueryVariables>(GenerateTrainersXlsFileDocument, baseOptions);
      }
export function useGenerateTrainersXlsFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerateTrainersXlsFileQuery, GenerateTrainersXlsFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenerateTrainersXlsFileQuery, GenerateTrainersXlsFileQueryVariables>(GenerateTrainersXlsFileDocument, baseOptions);
        }
export type GenerateTrainersXlsFileQueryHookResult = ReturnType<typeof useGenerateTrainersXlsFileQuery>;
export type GenerateTrainersXlsFileLazyQueryHookResult = ReturnType<typeof useGenerateTrainersXlsFileLazyQuery>;
export type GenerateTrainersXlsFileQueryResult = Apollo.QueryResult<GenerateTrainersXlsFileQuery, GenerateTrainersXlsFileQueryVariables>;
export const GenerateOrganizationXlsFileDocument = Apollo.gql`
    query generateOrganizationXlsFile($filterString: String!, $filter: DirOrganizationWhereInput!, $first: Int!) {
  dirOrganizationsReport {
    xlsx(where: $filter, orderBy: registryNumber_DESC, first: $first, filter: $filterString, registryNumberAlias: "Реестровый номер", nameAlias: "Название", ogrnAlias: "ОГРН", archiveAlias: "В архиве")
  }
}
    `;

/**
 * __useGenerateOrganizationXlsFileQuery__
 *
 * To run a query within a React component, call `useGenerateOrganizationXlsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateOrganizationXlsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateOrganizationXlsFileQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGenerateOrganizationXlsFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerateOrganizationXlsFileQuery, GenerateOrganizationXlsFileQueryVariables>) {
        return ApolloReactHooks.useQuery<GenerateOrganizationXlsFileQuery, GenerateOrganizationXlsFileQueryVariables>(GenerateOrganizationXlsFileDocument, baseOptions);
      }
export function useGenerateOrganizationXlsFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerateOrganizationXlsFileQuery, GenerateOrganizationXlsFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenerateOrganizationXlsFileQuery, GenerateOrganizationXlsFileQueryVariables>(GenerateOrganizationXlsFileDocument, baseOptions);
        }
export type GenerateOrganizationXlsFileQueryHookResult = ReturnType<typeof useGenerateOrganizationXlsFileQuery>;
export type GenerateOrganizationXlsFileLazyQueryHookResult = ReturnType<typeof useGenerateOrganizationXlsFileLazyQuery>;
export type GenerateOrganizationXlsFileQueryResult = Apollo.QueryResult<GenerateOrganizationXlsFileQuery, GenerateOrganizationXlsFileQueryVariables>;
export const GenerateSportingEventsXlsFileDocument = Apollo.gql`
    query generateSportingEventsXlsFile($filterString: String!, $filter: DirSportingEventWhereInput!, $first: Int!) {
  dirSportingEventsReport {
    xlsx(where: $filter, orderBy: registryNumber_DESC, first: $first, filter: $filterString, registryNumberAlias: "Реестровый номер", nameAlias: "Название мероприятия", venueAlias: "Место проведения", eventDateRangeAlias: "Период проведения", sportsAndDisciplinesAlias: "Виды спорта / дисциплины", nationalTeamsCountAlias: "Сборных команд", athletesCountAlias: "Спортсменов", supportTeamCountAlias: "Сопровожд", calendarAlias: "Календарь", statusAlias: "Статус согласования")
  }
}
    `;

/**
 * __useGenerateSportingEventsXlsFileQuery__
 *
 * To run a query within a React component, call `useGenerateSportingEventsXlsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateSportingEventsXlsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateSportingEventsXlsFileQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGenerateSportingEventsXlsFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerateSportingEventsXlsFileQuery, GenerateSportingEventsXlsFileQueryVariables>) {
        return ApolloReactHooks.useQuery<GenerateSportingEventsXlsFileQuery, GenerateSportingEventsXlsFileQueryVariables>(GenerateSportingEventsXlsFileDocument, baseOptions);
      }
export function useGenerateSportingEventsXlsFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerateSportingEventsXlsFileQuery, GenerateSportingEventsXlsFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenerateSportingEventsXlsFileQuery, GenerateSportingEventsXlsFileQueryVariables>(GenerateSportingEventsXlsFileDocument, baseOptions);
        }
export type GenerateSportingEventsXlsFileQueryHookResult = ReturnType<typeof useGenerateSportingEventsXlsFileQuery>;
export type GenerateSportingEventsXlsFileLazyQueryHookResult = ReturnType<typeof useGenerateSportingEventsXlsFileLazyQuery>;
export type GenerateSportingEventsXlsFileQueryResult = Apollo.QueryResult<GenerateSportingEventsXlsFileQuery, GenerateSportingEventsXlsFileQueryVariables>;
export const GenerateNationalTeamsXlsFileDocument = Apollo.gql`
    query generateNationalTeamsXlsFile($filterString: String!, $filter: DirNationalTeamWhereInput!, $first: Int!) {
  dirNationalTeamsReport {
    xlsx(where: $filter, orderBy: registryNumber_DESC, first: $first, filter: $filterString, registryNumberAlias: "Реестровый номер", regionAlias: "Регион", sportsAndDisciplinesAlias: "Виды спорта / Группы дисциплин", athletesCountAlias: "Кол-во спортсменов", eventRegistryNumberAlias: "Реестровый номер мероприятия", eventNameAlias: "Название мероприятия", eventDateRangeAlias: "Период проведения", statusAlias: "Статус согласования")
  }
}
    `;

/**
 * __useGenerateNationalTeamsXlsFileQuery__
 *
 * To run a query within a React component, call `useGenerateNationalTeamsXlsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateNationalTeamsXlsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateNationalTeamsXlsFileQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGenerateNationalTeamsXlsFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerateNationalTeamsXlsFileQuery, GenerateNationalTeamsXlsFileQueryVariables>) {
        return ApolloReactHooks.useQuery<GenerateNationalTeamsXlsFileQuery, GenerateNationalTeamsXlsFileQueryVariables>(GenerateNationalTeamsXlsFileDocument, baseOptions);
      }
export function useGenerateNationalTeamsXlsFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerateNationalTeamsXlsFileQuery, GenerateNationalTeamsXlsFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenerateNationalTeamsXlsFileQuery, GenerateNationalTeamsXlsFileQueryVariables>(GenerateNationalTeamsXlsFileDocument, baseOptions);
        }
export type GenerateNationalTeamsXlsFileQueryHookResult = ReturnType<typeof useGenerateNationalTeamsXlsFileQuery>;
export type GenerateNationalTeamsXlsFileLazyQueryHookResult = ReturnType<typeof useGenerateNationalTeamsXlsFileLazyQuery>;
export type GenerateNationalTeamsXlsFileQueryResult = Apollo.QueryResult<GenerateNationalTeamsXlsFileQuery, GenerateNationalTeamsXlsFileQueryVariables>;
export const GeneratePersonsXlsFileDocument = Apollo.gql`
    query generatePersonsXlsFile($filterString: String!, $filter: DirPersonWhereInput!, $first: Int!) {
  dirPersonsReport {
    xlsx(where: $filter, orderBy: registryNumber_DESC, first: $first, filter: $filterString, registryNumberAlias: "Реестровый номер", firstNameAlias: "Имя", lastNameAlias: "Фамилия", patronymicAlias: "Отчество", birthdayAlias: "ДР", genderAlias: "Пол")
  }
}
    `;

/**
 * __useGeneratePersonsXlsFileQuery__
 *
 * To run a query within a React component, call `useGeneratePersonsXlsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratePersonsXlsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratePersonsXlsFileQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGeneratePersonsXlsFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GeneratePersonsXlsFileQuery, GeneratePersonsXlsFileQueryVariables>) {
        return ApolloReactHooks.useQuery<GeneratePersonsXlsFileQuery, GeneratePersonsXlsFileQueryVariables>(GeneratePersonsXlsFileDocument, baseOptions);
      }
export function useGeneratePersonsXlsFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GeneratePersonsXlsFileQuery, GeneratePersonsXlsFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GeneratePersonsXlsFileQuery, GeneratePersonsXlsFileQueryVariables>(GeneratePersonsXlsFileDocument, baseOptions);
        }
export type GeneratePersonsXlsFileQueryHookResult = ReturnType<typeof useGeneratePersonsXlsFileQuery>;
export type GeneratePersonsXlsFileLazyQueryHookResult = ReturnType<typeof useGeneratePersonsXlsFileLazyQuery>;
export type GeneratePersonsXlsFileQueryResult = Apollo.QueryResult<GeneratePersonsXlsFileQuery, GeneratePersonsXlsFileQueryVariables>;
export const GenerateRefereeXlsFileDocument = Apollo.gql`
    query generateRefereeXlsFile($filterString: String!, $filter: DirRefereeWhereInput!, $search: String!, $first: Int!) {
  dirRefereesReport {
    xlsx(where: $filter, search: $search, orderBy: registryNumber_DESC, first: $first, filter: $filterString, registryNumberAlias: "Реестровый номер", fullNameAlias: "ФИО", birthdayAlias: "ДР", genderAlias: "Пол", regionAlias: "Регион основной (страна/ зарубежный город)", sportsAlias: "Виды спорта")
  }
}
    `;

/**
 * __useGenerateRefereeXlsFileQuery__
 *
 * To run a query within a React component, call `useGenerateRefereeXlsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateRefereeXlsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateRefereeXlsFileQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGenerateRefereeXlsFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerateRefereeXlsFileQuery, GenerateRefereeXlsFileQueryVariables>) {
        return ApolloReactHooks.useQuery<GenerateRefereeXlsFileQuery, GenerateRefereeXlsFileQueryVariables>(GenerateRefereeXlsFileDocument, baseOptions);
      }
export function useGenerateRefereeXlsFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerateRefereeXlsFileQuery, GenerateRefereeXlsFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenerateRefereeXlsFileQuery, GenerateRefereeXlsFileQueryVariables>(GenerateRefereeXlsFileDocument, baseOptions);
        }
export type GenerateRefereeXlsFileQueryHookResult = ReturnType<typeof useGenerateRefereeXlsFileQuery>;
export type GenerateRefereeXlsFileLazyQueryHookResult = ReturnType<typeof useGenerateRefereeXlsFileLazyQuery>;
export type GenerateRefereeXlsFileQueryResult = Apollo.QueryResult<GenerateRefereeXlsFileQuery, GenerateRefereeXlsFileQueryVariables>;
export const GenerateRegionXlsFileDocument = Apollo.gql`
    query generateRegionXlsFile($filterString: String!, $filter: DirRegionWhereInput!, $first: Int!) {
  dirRegionsReport {
    xlsx(where: $filter, orderBy: registryNumber_DESC, first: $first, filter: $filterString, fullNameAlias: "Полное название", shortNameAlias: "Краткое название", federalDistrictAlias: "Федеральный округ", archiveAlias: "В архиве")
  }
}
    `;

/**
 * __useGenerateRegionXlsFileQuery__
 *
 * To run a query within a React component, call `useGenerateRegionXlsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateRegionXlsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateRegionXlsFileQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGenerateRegionXlsFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerateRegionXlsFileQuery, GenerateRegionXlsFileQueryVariables>) {
        return ApolloReactHooks.useQuery<GenerateRegionXlsFileQuery, GenerateRegionXlsFileQueryVariables>(GenerateRegionXlsFileDocument, baseOptions);
      }
export function useGenerateRegionXlsFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerateRegionXlsFileQuery, GenerateRegionXlsFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenerateRegionXlsFileQuery, GenerateRegionXlsFileQueryVariables>(GenerateRegionXlsFileDocument, baseOptions);
        }
export type GenerateRegionXlsFileQueryHookResult = ReturnType<typeof useGenerateRegionXlsFileQuery>;
export type GenerateRegionXlsFileLazyQueryHookResult = ReturnType<typeof useGenerateRegionXlsFileLazyQuery>;
export type GenerateRegionXlsFileQueryResult = Apollo.QueryResult<GenerateRegionXlsFileQuery, GenerateRegionXlsFileQueryVariables>;
export const GenerateCalendarXlsFileDocument = Apollo.gql`
    query generateCalendarXlsFile($filterString: String!, $filter: DirCalendarWhereInput!, $first: Int!) {
  dirCalendarsReport {
    xlsx(where: $filter, orderBy: registryNumber_DESC, first: $first, filter: $filterString, registryNumberAlias: "Реестровый номер", fullNameAlias: "Полное название", shortNameAlias: "Краткое название", dateRangeAlias: "Период")
  }
}
    `;

/**
 * __useGenerateCalendarXlsFileQuery__
 *
 * To run a query within a React component, call `useGenerateCalendarXlsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateCalendarXlsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateCalendarXlsFileQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGenerateCalendarXlsFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerateCalendarXlsFileQuery, GenerateCalendarXlsFileQueryVariables>) {
        return ApolloReactHooks.useQuery<GenerateCalendarXlsFileQuery, GenerateCalendarXlsFileQueryVariables>(GenerateCalendarXlsFileDocument, baseOptions);
      }
export function useGenerateCalendarXlsFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerateCalendarXlsFileQuery, GenerateCalendarXlsFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenerateCalendarXlsFileQuery, GenerateCalendarXlsFileQueryVariables>(GenerateCalendarXlsFileDocument, baseOptions);
        }
export type GenerateCalendarXlsFileQueryHookResult = ReturnType<typeof useGenerateCalendarXlsFileQuery>;
export type GenerateCalendarXlsFileLazyQueryHookResult = ReturnType<typeof useGenerateCalendarXlsFileLazyQuery>;
export type GenerateCalendarXlsFileQueryResult = Apollo.QueryResult<GenerateCalendarXlsFileQuery, GenerateCalendarXlsFileQueryVariables>;
export const UserRoleDocument = Apollo.gql`
    query UserRole($filter: UserRoleWhereInput) {
  userRoles(where: $filter, orderBy: description_ASC) {
    value: name
    label: description
  }
}
    `;

/**
 * __useUserRoleQuery__
 *
 * To run a query within a React component, call `useUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUserRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, baseOptions);
      }
export function useUserRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, baseOptions);
        }
export type UserRoleQueryHookResult = ReturnType<typeof useUserRoleQuery>;
export type UserRoleLazyQueryHookResult = ReturnType<typeof useUserRoleLazyQuery>;
export type UserRoleQueryResult = Apollo.QueryResult<UserRoleQuery, UserRoleQueryVariables>;
export const ValidationTokenDocument = Apollo.gql`
    query ValidationToken {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Половозрастные группы"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useValidationTokenQuery__
 *
 * To run a query within a React component, call `useValidationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidationTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useValidationTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidationTokenQuery, ValidationTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidationTokenQuery, ValidationTokenQueryVariables>(ValidationTokenDocument, baseOptions);
      }
export function useValidationTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidationTokenQuery, ValidationTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidationTokenQuery, ValidationTokenQueryVariables>(ValidationTokenDocument, baseOptions);
        }
export type ValidationTokenQueryHookResult = ReturnType<typeof useValidationTokenQuery>;
export type ValidationTokenLazyQueryHookResult = ReturnType<typeof useValidationTokenLazyQuery>;
export type ValidationTokenQueryResult = Apollo.QueryResult<ValidationTokenQuery, ValidationTokenQueryVariables>;
export const UpdateNationalTeamDocument = Apollo.gql`
    mutation UpdateNationalTeam($id: UUID!, $data: DirNationalTeamUpdateInput!) {
  updateDirNationalTeam(data: $data, where: {id: $id}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type UpdateNationalTeamMutationFn = Apollo.MutationFunction<UpdateNationalTeamMutation, UpdateNationalTeamMutationVariables>;

/**
 * __useUpdateNationalTeamMutation__
 *
 * To run a mutation, you first call `useUpdateNationalTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNationalTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNationalTeamMutation, { data, loading, error }] = useUpdateNationalTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNationalTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNationalTeamMutation, UpdateNationalTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNationalTeamMutation, UpdateNationalTeamMutationVariables>(UpdateNationalTeamDocument, baseOptions);
      }
export type UpdateNationalTeamMutationHookResult = ReturnType<typeof useUpdateNationalTeamMutation>;
export type UpdateNationalTeamMutationResult = Apollo.MutationResult<UpdateNationalTeamMutation>;
export type UpdateNationalTeamMutationOptions = Apollo.BaseMutationOptions<UpdateNationalTeamMutation, UpdateNationalTeamMutationVariables>;
export const NationalTeamArchiveDocument = Apollo.gql`
    mutation NationalTeamArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirNationalTeam(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type NationalTeamArchiveMutationFn = Apollo.MutationFunction<NationalTeamArchiveMutation, NationalTeamArchiveMutationVariables>;

/**
 * __useNationalTeamArchiveMutation__
 *
 * To run a mutation, you first call `useNationalTeamArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamArchiveMutation, { data, loading, error }] = useNationalTeamArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useNationalTeamArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamArchiveMutation, NationalTeamArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamArchiveMutation, NationalTeamArchiveMutationVariables>(NationalTeamArchiveDocument, baseOptions);
      }
export type NationalTeamArchiveMutationHookResult = ReturnType<typeof useNationalTeamArchiveMutation>;
export type NationalTeamArchiveMutationResult = Apollo.MutationResult<NationalTeamArchiveMutation>;
export type NationalTeamArchiveMutationOptions = Apollo.BaseMutationOptions<NationalTeamArchiveMutation, NationalTeamArchiveMutationVariables>;
export const NationalTeamUnArchiveDocument = Apollo.gql`
    mutation NationalTeamUnArchive($id: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type NationalTeamUnArchiveMutationFn = Apollo.MutationFunction<NationalTeamUnArchiveMutation, NationalTeamUnArchiveMutationVariables>;

/**
 * __useNationalTeamUnArchiveMutation__
 *
 * To run a mutation, you first call `useNationalTeamUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamUnArchiveMutation, { data, loading, error }] = useNationalTeamUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNationalTeamUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamUnArchiveMutation, NationalTeamUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamUnArchiveMutation, NationalTeamUnArchiveMutationVariables>(NationalTeamUnArchiveDocument, baseOptions);
      }
export type NationalTeamUnArchiveMutationHookResult = ReturnType<typeof useNationalTeamUnArchiveMutation>;
export type NationalTeamUnArchiveMutationResult = Apollo.MutationResult<NationalTeamUnArchiveMutation>;
export type NationalTeamUnArchiveMutationOptions = Apollo.BaseMutationOptions<NationalTeamUnArchiveMutation, NationalTeamUnArchiveMutationVariables>;
export const NationalTeamAddTrainerDocument = Apollo.gql`
    mutation NationalTeamAddTrainer($id: UUID!, $trainerId: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {trainers: {create: {dirTrainer: {connect: {id: $trainerId}}}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamAddTrainerMutationFn = Apollo.MutationFunction<NationalTeamAddTrainerMutation, NationalTeamAddTrainerMutationVariables>;

/**
 * __useNationalTeamAddTrainerMutation__
 *
 * To run a mutation, you first call `useNationalTeamAddTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamAddTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamAddTrainerMutation, { data, loading, error }] = useNationalTeamAddTrainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      trainerId: // value for 'trainerId'
 *   },
 * });
 */
export function useNationalTeamAddTrainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamAddTrainerMutation, NationalTeamAddTrainerMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamAddTrainerMutation, NationalTeamAddTrainerMutationVariables>(NationalTeamAddTrainerDocument, baseOptions);
      }
export type NationalTeamAddTrainerMutationHookResult = ReturnType<typeof useNationalTeamAddTrainerMutation>;
export type NationalTeamAddTrainerMutationResult = Apollo.MutationResult<NationalTeamAddTrainerMutation>;
export type NationalTeamAddTrainerMutationOptions = Apollo.BaseMutationOptions<NationalTeamAddTrainerMutation, NationalTeamAddTrainerMutationVariables>;
export const NationalTeamDeleteTrainerDocument = Apollo.gql`
    mutation NationalTeamDeleteTrainer($id: UUID!, $trainerId: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {trainers: {delete: {id: $trainerId}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamDeleteTrainerMutationFn = Apollo.MutationFunction<NationalTeamDeleteTrainerMutation, NationalTeamDeleteTrainerMutationVariables>;

/**
 * __useNationalTeamDeleteTrainerMutation__
 *
 * To run a mutation, you first call `useNationalTeamDeleteTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamDeleteTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamDeleteTrainerMutation, { data, loading, error }] = useNationalTeamDeleteTrainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      trainerId: // value for 'trainerId'
 *   },
 * });
 */
export function useNationalTeamDeleteTrainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamDeleteTrainerMutation, NationalTeamDeleteTrainerMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamDeleteTrainerMutation, NationalTeamDeleteTrainerMutationVariables>(NationalTeamDeleteTrainerDocument, baseOptions);
      }
export type NationalTeamDeleteTrainerMutationHookResult = ReturnType<typeof useNationalTeamDeleteTrainerMutation>;
export type NationalTeamDeleteTrainerMutationResult = Apollo.MutationResult<NationalTeamDeleteTrainerMutation>;
export type NationalTeamDeleteTrainerMutationOptions = Apollo.BaseMutationOptions<NationalTeamDeleteTrainerMutation, NationalTeamDeleteTrainerMutationVariables>;
export const NationalTeamAddSpecialistDocument = Apollo.gql`
    mutation NationalTeamAddSpecialist($id: UUID!, $firstname: String!, $lastname: String!, $patronymic: String!, $birthday: DateTime!) {
  updateDirNationalTeam(where: {id: $id}, data: {specialists: {create: {firstname: $firstname, lastname: $lastname, patronymic: $patronymic, birthday: $birthday}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamAddSpecialistMutationFn = Apollo.MutationFunction<NationalTeamAddSpecialistMutation, NationalTeamAddSpecialistMutationVariables>;

/**
 * __useNationalTeamAddSpecialistMutation__
 *
 * To run a mutation, you first call `useNationalTeamAddSpecialistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamAddSpecialistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamAddSpecialistMutation, { data, loading, error }] = useNationalTeamAddSpecialistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      patronymic: // value for 'patronymic'
 *      birthday: // value for 'birthday'
 *   },
 * });
 */
export function useNationalTeamAddSpecialistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamAddSpecialistMutation, NationalTeamAddSpecialistMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamAddSpecialistMutation, NationalTeamAddSpecialistMutationVariables>(NationalTeamAddSpecialistDocument, baseOptions);
      }
export type NationalTeamAddSpecialistMutationHookResult = ReturnType<typeof useNationalTeamAddSpecialistMutation>;
export type NationalTeamAddSpecialistMutationResult = Apollo.MutationResult<NationalTeamAddSpecialistMutation>;
export type NationalTeamAddSpecialistMutationOptions = Apollo.BaseMutationOptions<NationalTeamAddSpecialistMutation, NationalTeamAddSpecialistMutationVariables>;
export const NationalTeamDeleteSpecialistDocument = Apollo.gql`
    mutation NationalTeamDeleteSpecialist($id: UUID!, $specId: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {specialists: {delete: {id: $specId}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamDeleteSpecialistMutationFn = Apollo.MutationFunction<NationalTeamDeleteSpecialistMutation, NationalTeamDeleteSpecialistMutationVariables>;

/**
 * __useNationalTeamDeleteSpecialistMutation__
 *
 * To run a mutation, you first call `useNationalTeamDeleteSpecialistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamDeleteSpecialistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamDeleteSpecialistMutation, { data, loading, error }] = useNationalTeamDeleteSpecialistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      specId: // value for 'specId'
 *   },
 * });
 */
export function useNationalTeamDeleteSpecialistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamDeleteSpecialistMutation, NationalTeamDeleteSpecialistMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamDeleteSpecialistMutation, NationalTeamDeleteSpecialistMutationVariables>(NationalTeamDeleteSpecialistDocument, baseOptions);
      }
export type NationalTeamDeleteSpecialistMutationHookResult = ReturnType<typeof useNationalTeamDeleteSpecialistMutation>;
export type NationalTeamDeleteSpecialistMutationResult = Apollo.MutationResult<NationalTeamDeleteSpecialistMutation>;
export type NationalTeamDeleteSpecialistMutationOptions = Apollo.BaseMutationOptions<NationalTeamDeleteSpecialistMutation, NationalTeamDeleteSpecialistMutationVariables>;
export const NationalTeamAddMedicDocument = Apollo.gql`
    mutation NationalTeamAddMedic($id: UUID!, $firstname: String!, $lastname: String!, $patronymic: String!, $birthday: DateTime!) {
  updateDirNationalTeam(where: {id: $id}, data: {medics: {create: {firstname: $firstname, lastname: $lastname, patronymic: $patronymic, birthday: $birthday}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamAddMedicMutationFn = Apollo.MutationFunction<NationalTeamAddMedicMutation, NationalTeamAddMedicMutationVariables>;

/**
 * __useNationalTeamAddMedicMutation__
 *
 * To run a mutation, you first call `useNationalTeamAddMedicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamAddMedicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamAddMedicMutation, { data, loading, error }] = useNationalTeamAddMedicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      patronymic: // value for 'patronymic'
 *      birthday: // value for 'birthday'
 *   },
 * });
 */
export function useNationalTeamAddMedicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamAddMedicMutation, NationalTeamAddMedicMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamAddMedicMutation, NationalTeamAddMedicMutationVariables>(NationalTeamAddMedicDocument, baseOptions);
      }
export type NationalTeamAddMedicMutationHookResult = ReturnType<typeof useNationalTeamAddMedicMutation>;
export type NationalTeamAddMedicMutationResult = Apollo.MutationResult<NationalTeamAddMedicMutation>;
export type NationalTeamAddMedicMutationOptions = Apollo.BaseMutationOptions<NationalTeamAddMedicMutation, NationalTeamAddMedicMutationVariables>;
export const NationalTeamDeleteMedicDocument = Apollo.gql`
    mutation NationalTeamDeleteMedic($id: UUID!, $medId: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {medics: {delete: {id: $medId}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamDeleteMedicMutationFn = Apollo.MutationFunction<NationalTeamDeleteMedicMutation, NationalTeamDeleteMedicMutationVariables>;

/**
 * __useNationalTeamDeleteMedicMutation__
 *
 * To run a mutation, you first call `useNationalTeamDeleteMedicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamDeleteMedicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamDeleteMedicMutation, { data, loading, error }] = useNationalTeamDeleteMedicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      medId: // value for 'medId'
 *   },
 * });
 */
export function useNationalTeamDeleteMedicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamDeleteMedicMutation, NationalTeamDeleteMedicMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamDeleteMedicMutation, NationalTeamDeleteMedicMutationVariables>(NationalTeamDeleteMedicDocument, baseOptions);
      }
export type NationalTeamDeleteMedicMutationHookResult = ReturnType<typeof useNationalTeamDeleteMedicMutation>;
export type NationalTeamDeleteMedicMutationResult = Apollo.MutationResult<NationalTeamDeleteMedicMutation>;
export type NationalTeamDeleteMedicMutationOptions = Apollo.BaseMutationOptions<NationalTeamDeleteMedicMutation, NationalTeamDeleteMedicMutationVariables>;
export const NationalTeamAddDelegationHeadDocument = Apollo.gql`
    mutation NationalTeamAddDelegationHead($id: UUID!, $firstname: String!, $lastname: String!, $patronymic: String!, $birthday: DateTime!) {
  updateDirNationalTeam(where: {id: $id}, data: {delegationHeads: {create: {firstname: $firstname, lastname: $lastname, patronymic: $patronymic, birthday: $birthday}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamAddDelegationHeadMutationFn = Apollo.MutationFunction<NationalTeamAddDelegationHeadMutation, NationalTeamAddDelegationHeadMutationVariables>;

/**
 * __useNationalTeamAddDelegationHeadMutation__
 *
 * To run a mutation, you first call `useNationalTeamAddDelegationHeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamAddDelegationHeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamAddDelegationHeadMutation, { data, loading, error }] = useNationalTeamAddDelegationHeadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      patronymic: // value for 'patronymic'
 *      birthday: // value for 'birthday'
 *   },
 * });
 */
export function useNationalTeamAddDelegationHeadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamAddDelegationHeadMutation, NationalTeamAddDelegationHeadMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamAddDelegationHeadMutation, NationalTeamAddDelegationHeadMutationVariables>(NationalTeamAddDelegationHeadDocument, baseOptions);
      }
export type NationalTeamAddDelegationHeadMutationHookResult = ReturnType<typeof useNationalTeamAddDelegationHeadMutation>;
export type NationalTeamAddDelegationHeadMutationResult = Apollo.MutationResult<NationalTeamAddDelegationHeadMutation>;
export type NationalTeamAddDelegationHeadMutationOptions = Apollo.BaseMutationOptions<NationalTeamAddDelegationHeadMutation, NationalTeamAddDelegationHeadMutationVariables>;
export const NationalTeamDeleteDelegationHeadDocument = Apollo.gql`
    mutation NationalTeamDeleteDelegationHead($id: UUID!, $headId: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {delegationHeads: {delete: {id: $headId}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamDeleteDelegationHeadMutationFn = Apollo.MutationFunction<NationalTeamDeleteDelegationHeadMutation, NationalTeamDeleteDelegationHeadMutationVariables>;

/**
 * __useNationalTeamDeleteDelegationHeadMutation__
 *
 * To run a mutation, you first call `useNationalTeamDeleteDelegationHeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamDeleteDelegationHeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamDeleteDelegationHeadMutation, { data, loading, error }] = useNationalTeamDeleteDelegationHeadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      headId: // value for 'headId'
 *   },
 * });
 */
export function useNationalTeamDeleteDelegationHeadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamDeleteDelegationHeadMutation, NationalTeamDeleteDelegationHeadMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamDeleteDelegationHeadMutation, NationalTeamDeleteDelegationHeadMutationVariables>(NationalTeamDeleteDelegationHeadDocument, baseOptions);
      }
export type NationalTeamDeleteDelegationHeadMutationHookResult = ReturnType<typeof useNationalTeamDeleteDelegationHeadMutation>;
export type NationalTeamDeleteDelegationHeadMutationResult = Apollo.MutationResult<NationalTeamDeleteDelegationHeadMutation>;
export type NationalTeamDeleteDelegationHeadMutationOptions = Apollo.BaseMutationOptions<NationalTeamDeleteDelegationHeadMutation, NationalTeamDeleteDelegationHeadMutationVariables>;
export const NationalTeamAddAthleteGroupDocument = Apollo.gql`
    mutation NationalTeamAddAthleteGroup($id: UUID!, $minAge: Int, $maxAge: Int, $ageGroupsId: [ClassifierValueWhereUniqueInput!], $disciplineGroupsId: [DirSportDisciplineGroupWhereUniqueInput!]) {
  updateDirNationalTeam(where: {id: $id}, data: {athleteGroups: {create: {minAge: $minAge, maxAge: $maxAge, clsAgeGroups: {connect: $ageGroupsId}, disciplineGroups: {connect: $disciplineGroupsId}}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamAddAthleteGroupMutationFn = Apollo.MutationFunction<NationalTeamAddAthleteGroupMutation, NationalTeamAddAthleteGroupMutationVariables>;

/**
 * __useNationalTeamAddAthleteGroupMutation__
 *
 * To run a mutation, you first call `useNationalTeamAddAthleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamAddAthleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamAddAthleteGroupMutation, { data, loading, error }] = useNationalTeamAddAthleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      minAge: // value for 'minAge'
 *      maxAge: // value for 'maxAge'
 *      ageGroupsId: // value for 'ageGroupsId'
 *      disciplineGroupsId: // value for 'disciplineGroupsId'
 *   },
 * });
 */
export function useNationalTeamAddAthleteGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamAddAthleteGroupMutation, NationalTeamAddAthleteGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamAddAthleteGroupMutation, NationalTeamAddAthleteGroupMutationVariables>(NationalTeamAddAthleteGroupDocument, baseOptions);
      }
export type NationalTeamAddAthleteGroupMutationHookResult = ReturnType<typeof useNationalTeamAddAthleteGroupMutation>;
export type NationalTeamAddAthleteGroupMutationResult = Apollo.MutationResult<NationalTeamAddAthleteGroupMutation>;
export type NationalTeamAddAthleteGroupMutationOptions = Apollo.BaseMutationOptions<NationalTeamAddAthleteGroupMutation, NationalTeamAddAthleteGroupMutationVariables>;
export const NationalTeamUpdateAthleteGroupDocument = Apollo.gql`
    mutation NationalTeamUpdateAthleteGroup($id: UUID!, $minAge: Int, $maxAge: Int, $groupId: UUID, $ageGroupsId: [ClassifierValueWhereUniqueInput!], $disciplineGroupsId: [DirSportDisciplineGroupWhereUniqueInput!]) {
  updateDirNationalTeam(where: {id: $id}, data: {athleteGroups: {update: {where: {id: $groupId}, data: {minAge: $minAge, maxAge: $maxAge, clsAgeGroups: {set: $ageGroupsId}, disciplineGroups: {set: $disciplineGroupsId}}}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamUpdateAthleteGroupMutationFn = Apollo.MutationFunction<NationalTeamUpdateAthleteGroupMutation, NationalTeamUpdateAthleteGroupMutationVariables>;

/**
 * __useNationalTeamUpdateAthleteGroupMutation__
 *
 * To run a mutation, you first call `useNationalTeamUpdateAthleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamUpdateAthleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamUpdateAthleteGroupMutation, { data, loading, error }] = useNationalTeamUpdateAthleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      minAge: // value for 'minAge'
 *      maxAge: // value for 'maxAge'
 *      groupId: // value for 'groupId'
 *      ageGroupsId: // value for 'ageGroupsId'
 *      disciplineGroupsId: // value for 'disciplineGroupsId'
 *   },
 * });
 */
export function useNationalTeamUpdateAthleteGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamUpdateAthleteGroupMutation, NationalTeamUpdateAthleteGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamUpdateAthleteGroupMutation, NationalTeamUpdateAthleteGroupMutationVariables>(NationalTeamUpdateAthleteGroupDocument, baseOptions);
      }
export type NationalTeamUpdateAthleteGroupMutationHookResult = ReturnType<typeof useNationalTeamUpdateAthleteGroupMutation>;
export type NationalTeamUpdateAthleteGroupMutationResult = Apollo.MutationResult<NationalTeamUpdateAthleteGroupMutation>;
export type NationalTeamUpdateAthleteGroupMutationOptions = Apollo.BaseMutationOptions<NationalTeamUpdateAthleteGroupMutation, NationalTeamUpdateAthleteGroupMutationVariables>;
export const NationalTeamDeleteAthleteGroupDocument = Apollo.gql`
    mutation NationalTeamDeleteAthleteGroup($id: UUID!, $groupId: UUID) {
  updateDirNationalTeam(where: {id: $id}, data: {athleteGroups: {delete: {id: $groupId}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamDeleteAthleteGroupMutationFn = Apollo.MutationFunction<NationalTeamDeleteAthleteGroupMutation, NationalTeamDeleteAthleteGroupMutationVariables>;

/**
 * __useNationalTeamDeleteAthleteGroupMutation__
 *
 * To run a mutation, you first call `useNationalTeamDeleteAthleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamDeleteAthleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamDeleteAthleteGroupMutation, { data, loading, error }] = useNationalTeamDeleteAthleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useNationalTeamDeleteAthleteGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamDeleteAthleteGroupMutation, NationalTeamDeleteAthleteGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamDeleteAthleteGroupMutation, NationalTeamDeleteAthleteGroupMutationVariables>(NationalTeamDeleteAthleteGroupDocument, baseOptions);
      }
export type NationalTeamDeleteAthleteGroupMutationHookResult = ReturnType<typeof useNationalTeamDeleteAthleteGroupMutation>;
export type NationalTeamDeleteAthleteGroupMutationResult = Apollo.MutationResult<NationalTeamDeleteAthleteGroupMutation>;
export type NationalTeamDeleteAthleteGroupMutationOptions = Apollo.BaseMutationOptions<NationalTeamDeleteAthleteGroupMutation, NationalTeamDeleteAthleteGroupMutationVariables>;
export const NationalTeamAddAthleteDocument = Apollo.gql`
    mutation NationalTeamAddAthlete($id: UUID!, $groupId: UUID, $athleteId: UUID, $bestResult: String) {
  updateDirNationalTeam(where: {id: $id}, data: {athleteGroups: {update: {where: {id: $groupId}, data: {athletes: {create: {dirAthlete: {connect: {id: $athleteId}}, bestResult: $bestResult}}}}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamAddAthleteMutationFn = Apollo.MutationFunction<NationalTeamAddAthleteMutation, NationalTeamAddAthleteMutationVariables>;

/**
 * __useNationalTeamAddAthleteMutation__
 *
 * To run a mutation, you first call `useNationalTeamAddAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamAddAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamAddAthleteMutation, { data, loading, error }] = useNationalTeamAddAthleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      groupId: // value for 'groupId'
 *      athleteId: // value for 'athleteId'
 *      bestResult: // value for 'bestResult'
 *   },
 * });
 */
export function useNationalTeamAddAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamAddAthleteMutation, NationalTeamAddAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamAddAthleteMutation, NationalTeamAddAthleteMutationVariables>(NationalTeamAddAthleteDocument, baseOptions);
      }
export type NationalTeamAddAthleteMutationHookResult = ReturnType<typeof useNationalTeamAddAthleteMutation>;
export type NationalTeamAddAthleteMutationResult = Apollo.MutationResult<NationalTeamAddAthleteMutation>;
export type NationalTeamAddAthleteMutationOptions = Apollo.BaseMutationOptions<NationalTeamAddAthleteMutation, NationalTeamAddAthleteMutationVariables>;
export const NationalTeamDeleteAthleteDocument = Apollo.gql`
    mutation NationalTeamDeleteAthlete($id: UUID!, $groupId: UUID, $athleteId: UUID) {
  updateDirNationalTeam(where: {id: $id}, data: {athleteGroups: {update: {where: {id: $groupId}, data: {athletes: {delete: {id: $athleteId}}}}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamDeleteAthleteMutationFn = Apollo.MutationFunction<NationalTeamDeleteAthleteMutation, NationalTeamDeleteAthleteMutationVariables>;

/**
 * __useNationalTeamDeleteAthleteMutation__
 *
 * To run a mutation, you first call `useNationalTeamDeleteAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamDeleteAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamDeleteAthleteMutation, { data, loading, error }] = useNationalTeamDeleteAthleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      groupId: // value for 'groupId'
 *      athleteId: // value for 'athleteId'
 *   },
 * });
 */
export function useNationalTeamDeleteAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamDeleteAthleteMutation, NationalTeamDeleteAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<NationalTeamDeleteAthleteMutation, NationalTeamDeleteAthleteMutationVariables>(NationalTeamDeleteAthleteDocument, baseOptions);
      }
export type NationalTeamDeleteAthleteMutationHookResult = ReturnType<typeof useNationalTeamDeleteAthleteMutation>;
export type NationalTeamDeleteAthleteMutationResult = Apollo.MutationResult<NationalTeamDeleteAthleteMutation>;
export type NationalTeamDeleteAthleteMutationOptions = Apollo.BaseMutationOptions<NationalTeamDeleteAthleteMutation, NationalTeamDeleteAthleteMutationVariables>;
export const CreateTeamStatusDocument = Apollo.gql`
    mutation CreateTeamStatus($id: UUID!, $data: [DirNationalTeamExtendedStatusCreateWithoutTeamInput!]) {
  updateDirNationalTeam(where: {id: $id}, data: {statuses: {create: $data}}) {
    id
  }
}
    `;
export type CreateTeamStatusMutationFn = Apollo.MutationFunction<CreateTeamStatusMutation, CreateTeamStatusMutationVariables>;

/**
 * __useCreateTeamStatusMutation__
 *
 * To run a mutation, you first call `useCreateTeamStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamStatusMutation, { data, loading, error }] = useCreateTeamStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTeamStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTeamStatusMutation, CreateTeamStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTeamStatusMutation, CreateTeamStatusMutationVariables>(CreateTeamStatusDocument, baseOptions);
      }
export type CreateTeamStatusMutationHookResult = ReturnType<typeof useCreateTeamStatusMutation>;
export type CreateTeamStatusMutationResult = Apollo.MutationResult<CreateTeamStatusMutation>;
export type CreateTeamStatusMutationOptions = Apollo.BaseMutationOptions<CreateTeamStatusMutation, CreateTeamStatusMutationVariables>;
export const SwitchTrainerPrimaryDocument = Apollo.gql`
    mutation SwitchTrainerPrimary($id: UUID!, $isPrimary: Boolean) {
  updateDirNationalTeamTrainer(where: {id: $id}, data: {isPrimary: $isPrimary}) {
    id
  }
}
    `;
export type SwitchTrainerPrimaryMutationFn = Apollo.MutationFunction<SwitchTrainerPrimaryMutation, SwitchTrainerPrimaryMutationVariables>;

/**
 * __useSwitchTrainerPrimaryMutation__
 *
 * To run a mutation, you first call `useSwitchTrainerPrimaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchTrainerPrimaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchTrainerPrimaryMutation, { data, loading, error }] = useSwitchTrainerPrimaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isPrimary: // value for 'isPrimary'
 *   },
 * });
 */
export function useSwitchTrainerPrimaryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SwitchTrainerPrimaryMutation, SwitchTrainerPrimaryMutationVariables>) {
        return ApolloReactHooks.useMutation<SwitchTrainerPrimaryMutation, SwitchTrainerPrimaryMutationVariables>(SwitchTrainerPrimaryDocument, baseOptions);
      }
export type SwitchTrainerPrimaryMutationHookResult = ReturnType<typeof useSwitchTrainerPrimaryMutation>;
export type SwitchTrainerPrimaryMutationResult = Apollo.MutationResult<SwitchTrainerPrimaryMutation>;
export type SwitchTrainerPrimaryMutationOptions = Apollo.BaseMutationOptions<SwitchTrainerPrimaryMutation, SwitchTrainerPrimaryMutationVariables>;
export const UpdateDirNationalTeamFilesDocument = Apollo.gql`
    mutation UpdateDirNationalTeamFiles($id: UUID!, $files: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {files: {connect: {id: $files}}}) {
    files {
      id
      name
      path
    }
  }
}
    `;
export type UpdateDirNationalTeamFilesMutationFn = Apollo.MutationFunction<UpdateDirNationalTeamFilesMutation, UpdateDirNationalTeamFilesMutationVariables>;

/**
 * __useUpdateDirNationalTeamFilesMutation__
 *
 * To run a mutation, you first call `useUpdateDirNationalTeamFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirNationalTeamFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirNationalTeamFilesMutation, { data, loading, error }] = useUpdateDirNationalTeamFilesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUpdateDirNationalTeamFilesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirNationalTeamFilesMutation, UpdateDirNationalTeamFilesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirNationalTeamFilesMutation, UpdateDirNationalTeamFilesMutationVariables>(UpdateDirNationalTeamFilesDocument, baseOptions);
      }
export type UpdateDirNationalTeamFilesMutationHookResult = ReturnType<typeof useUpdateDirNationalTeamFilesMutation>;
export type UpdateDirNationalTeamFilesMutationResult = Apollo.MutationResult<UpdateDirNationalTeamFilesMutation>;
export type UpdateDirNationalTeamFilesMutationOptions = Apollo.BaseMutationOptions<UpdateDirNationalTeamFilesMutation, UpdateDirNationalTeamFilesMutationVariables>;
export const UpdateDirNationalTeamFilesRemoveDocument = Apollo.gql`
    mutation UpdateDirNationalTeamFilesRemove($id: UUID!, $files: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {files: {disconnect: {id: $files}}}) {
    files {
      id
      name
      path
    }
  }
}
    `;
export type UpdateDirNationalTeamFilesRemoveMutationFn = Apollo.MutationFunction<UpdateDirNationalTeamFilesRemoveMutation, UpdateDirNationalTeamFilesRemoveMutationVariables>;

/**
 * __useUpdateDirNationalTeamFilesRemoveMutation__
 *
 * To run a mutation, you first call `useUpdateDirNationalTeamFilesRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirNationalTeamFilesRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirNationalTeamFilesRemoveMutation, { data, loading, error }] = useUpdateDirNationalTeamFilesRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUpdateDirNationalTeamFilesRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirNationalTeamFilesRemoveMutation, UpdateDirNationalTeamFilesRemoveMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirNationalTeamFilesRemoveMutation, UpdateDirNationalTeamFilesRemoveMutationVariables>(UpdateDirNationalTeamFilesRemoveDocument, baseOptions);
      }
export type UpdateDirNationalTeamFilesRemoveMutationHookResult = ReturnType<typeof useUpdateDirNationalTeamFilesRemoveMutation>;
export type UpdateDirNationalTeamFilesRemoveMutationResult = Apollo.MutationResult<UpdateDirNationalTeamFilesRemoveMutation>;
export type UpdateDirNationalTeamFilesRemoveMutationOptions = Apollo.BaseMutationOptions<UpdateDirNationalTeamFilesRemoveMutation, UpdateDirNationalTeamFilesRemoveMutationVariables>;
export const UpdateDirNationalTeamAthleteDocument = Apollo.gql`
    mutation UpdateDirNationalTeamAthlete($id: UUID!, $entranceStatus: Boolean!, $admissionDate: DateTime) {
  updateDirNationalTeamAthlete(where: {id: $id}, data: {entranceStatus: $entranceStatus, admissionDate: $admissionDate}) {
    id
    entranceStatus
  }
}
    `;
export type UpdateDirNationalTeamAthleteMutationFn = Apollo.MutationFunction<UpdateDirNationalTeamAthleteMutation, UpdateDirNationalTeamAthleteMutationVariables>;

/**
 * __useUpdateDirNationalTeamAthleteMutation__
 *
 * To run a mutation, you first call `useUpdateDirNationalTeamAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirNationalTeamAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirNationalTeamAthleteMutation, { data, loading, error }] = useUpdateDirNationalTeamAthleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entranceStatus: // value for 'entranceStatus'
 *      admissionDate: // value for 'admissionDate'
 *   },
 * });
 */
export function useUpdateDirNationalTeamAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirNationalTeamAthleteMutation, UpdateDirNationalTeamAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirNationalTeamAthleteMutation, UpdateDirNationalTeamAthleteMutationVariables>(UpdateDirNationalTeamAthleteDocument, baseOptions);
      }
export type UpdateDirNationalTeamAthleteMutationHookResult = ReturnType<typeof useUpdateDirNationalTeamAthleteMutation>;
export type UpdateDirNationalTeamAthleteMutationResult = Apollo.MutationResult<UpdateDirNationalTeamAthleteMutation>;
export type UpdateDirNationalTeamAthleteMutationOptions = Apollo.BaseMutationOptions<UpdateDirNationalTeamAthleteMutation, UpdateDirNationalTeamAthleteMutationVariables>;
export const UpdateDirNationalTeamTrainerDocument = Apollo.gql`
    mutation UpdateDirNationalTeamTrainer($id: UUID!, $entranceStatus: Boolean!, $admissionDate: DateTime) {
  updateDirNationalTeamTrainer(where: {id: $id}, data: {entranceStatus: $entranceStatus, admissionDate: $admissionDate}) {
    id
    entranceStatus
  }
}
    `;
export type UpdateDirNationalTeamTrainerMutationFn = Apollo.MutationFunction<UpdateDirNationalTeamTrainerMutation, UpdateDirNationalTeamTrainerMutationVariables>;

/**
 * __useUpdateDirNationalTeamTrainerMutation__
 *
 * To run a mutation, you first call `useUpdateDirNationalTeamTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirNationalTeamTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirNationalTeamTrainerMutation, { data, loading, error }] = useUpdateDirNationalTeamTrainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entranceStatus: // value for 'entranceStatus'
 *      admissionDate: // value for 'admissionDate'
 *   },
 * });
 */
export function useUpdateDirNationalTeamTrainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirNationalTeamTrainerMutation, UpdateDirNationalTeamTrainerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirNationalTeamTrainerMutation, UpdateDirNationalTeamTrainerMutationVariables>(UpdateDirNationalTeamTrainerDocument, baseOptions);
      }
export type UpdateDirNationalTeamTrainerMutationHookResult = ReturnType<typeof useUpdateDirNationalTeamTrainerMutation>;
export type UpdateDirNationalTeamTrainerMutationResult = Apollo.MutationResult<UpdateDirNationalTeamTrainerMutation>;
export type UpdateDirNationalTeamTrainerMutationOptions = Apollo.BaseMutationOptions<UpdateDirNationalTeamTrainerMutation, UpdateDirNationalTeamTrainerMutationVariables>;
export const UpdateDirNationalTeamMemberDocument = Apollo.gql`
    mutation UpdateDirNationalTeamMember($id: UUID!, $entranceStatus: Boolean!, $admissionDate: DateTime) {
  updateDirNationalTeamMember(where: {id: $id}, data: {entranceStatus: $entranceStatus, admissionDate: $admissionDate}) {
    id
    entranceStatus
  }
}
    `;
export type UpdateDirNationalTeamMemberMutationFn = Apollo.MutationFunction<UpdateDirNationalTeamMemberMutation, UpdateDirNationalTeamMemberMutationVariables>;

/**
 * __useUpdateDirNationalTeamMemberMutation__
 *
 * To run a mutation, you first call `useUpdateDirNationalTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirNationalTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirNationalTeamMemberMutation, { data, loading, error }] = useUpdateDirNationalTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entranceStatus: // value for 'entranceStatus'
 *      admissionDate: // value for 'admissionDate'
 *   },
 * });
 */
export function useUpdateDirNationalTeamMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirNationalTeamMemberMutation, UpdateDirNationalTeamMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirNationalTeamMemberMutation, UpdateDirNationalTeamMemberMutationVariables>(UpdateDirNationalTeamMemberDocument, baseOptions);
      }
export type UpdateDirNationalTeamMemberMutationHookResult = ReturnType<typeof useUpdateDirNationalTeamMemberMutation>;
export type UpdateDirNationalTeamMemberMutationResult = Apollo.MutationResult<UpdateDirNationalTeamMemberMutation>;
export type UpdateDirNationalTeamMemberMutationOptions = Apollo.BaseMutationOptions<UpdateDirNationalTeamMemberMutation, UpdateDirNationalTeamMemberMutationVariables>;
export const UpdateDirNationalTeamAthleteAdmissionDateDocument = Apollo.gql`
    mutation UpdateDirNationalTeamAthleteAdmissionDate($id: UUID!, $admissionDate: DateTime!) {
  updateDirNationalTeamAthlete(where: {id: $id}, data: {admissionDate: $admissionDate}) {
    id
    admissionDate
  }
}
    `;
export type UpdateDirNationalTeamAthleteAdmissionDateMutationFn = Apollo.MutationFunction<UpdateDirNationalTeamAthleteAdmissionDateMutation, UpdateDirNationalTeamAthleteAdmissionDateMutationVariables>;

/**
 * __useUpdateDirNationalTeamAthleteAdmissionDateMutation__
 *
 * To run a mutation, you first call `useUpdateDirNationalTeamAthleteAdmissionDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirNationalTeamAthleteAdmissionDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirNationalTeamAthleteAdmissionDateMutation, { data, loading, error }] = useUpdateDirNationalTeamAthleteAdmissionDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      admissionDate: // value for 'admissionDate'
 *   },
 * });
 */
export function useUpdateDirNationalTeamAthleteAdmissionDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirNationalTeamAthleteAdmissionDateMutation, UpdateDirNationalTeamAthleteAdmissionDateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirNationalTeamAthleteAdmissionDateMutation, UpdateDirNationalTeamAthleteAdmissionDateMutationVariables>(UpdateDirNationalTeamAthleteAdmissionDateDocument, baseOptions);
      }
export type UpdateDirNationalTeamAthleteAdmissionDateMutationHookResult = ReturnType<typeof useUpdateDirNationalTeamAthleteAdmissionDateMutation>;
export type UpdateDirNationalTeamAthleteAdmissionDateMutationResult = Apollo.MutationResult<UpdateDirNationalTeamAthleteAdmissionDateMutation>;
export type UpdateDirNationalTeamAthleteAdmissionDateMutationOptions = Apollo.BaseMutationOptions<UpdateDirNationalTeamAthleteAdmissionDateMutation, UpdateDirNationalTeamAthleteAdmissionDateMutationVariables>;
export const UpdateDirNationalTeamTrainerAdmissionDateDocument = Apollo.gql`
    mutation UpdateDirNationalTeamTrainerAdmissionDate($id: UUID!, $admissionDate: DateTime!) {
  updateDirNationalTeamTrainer(where: {id: $id}, data: {admissionDate: $admissionDate}) {
    id
    admissionDate
  }
}
    `;
export type UpdateDirNationalTeamTrainerAdmissionDateMutationFn = Apollo.MutationFunction<UpdateDirNationalTeamTrainerAdmissionDateMutation, UpdateDirNationalTeamTrainerAdmissionDateMutationVariables>;

/**
 * __useUpdateDirNationalTeamTrainerAdmissionDateMutation__
 *
 * To run a mutation, you first call `useUpdateDirNationalTeamTrainerAdmissionDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirNationalTeamTrainerAdmissionDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirNationalTeamTrainerAdmissionDateMutation, { data, loading, error }] = useUpdateDirNationalTeamTrainerAdmissionDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      admissionDate: // value for 'admissionDate'
 *   },
 * });
 */
export function useUpdateDirNationalTeamTrainerAdmissionDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirNationalTeamTrainerAdmissionDateMutation, UpdateDirNationalTeamTrainerAdmissionDateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirNationalTeamTrainerAdmissionDateMutation, UpdateDirNationalTeamTrainerAdmissionDateMutationVariables>(UpdateDirNationalTeamTrainerAdmissionDateDocument, baseOptions);
      }
export type UpdateDirNationalTeamTrainerAdmissionDateMutationHookResult = ReturnType<typeof useUpdateDirNationalTeamTrainerAdmissionDateMutation>;
export type UpdateDirNationalTeamTrainerAdmissionDateMutationResult = Apollo.MutationResult<UpdateDirNationalTeamTrainerAdmissionDateMutation>;
export type UpdateDirNationalTeamTrainerAdmissionDateMutationOptions = Apollo.BaseMutationOptions<UpdateDirNationalTeamTrainerAdmissionDateMutation, UpdateDirNationalTeamTrainerAdmissionDateMutationVariables>;
export const UpdateDirNationalTeamMemberAdmissionDateDocument = Apollo.gql`
    mutation UpdateDirNationalTeamMemberAdmissionDate($id: UUID!, $admissionDate: DateTime!) {
  updateDirNationalTeamMember(where: {id: $id}, data: {admissionDate: $admissionDate}) {
    id
    admissionDate
  }
}
    `;
export type UpdateDirNationalTeamMemberAdmissionDateMutationFn = Apollo.MutationFunction<UpdateDirNationalTeamMemberAdmissionDateMutation, UpdateDirNationalTeamMemberAdmissionDateMutationVariables>;

/**
 * __useUpdateDirNationalTeamMemberAdmissionDateMutation__
 *
 * To run a mutation, you first call `useUpdateDirNationalTeamMemberAdmissionDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirNationalTeamMemberAdmissionDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirNationalTeamMemberAdmissionDateMutation, { data, loading, error }] = useUpdateDirNationalTeamMemberAdmissionDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      admissionDate: // value for 'admissionDate'
 *   },
 * });
 */
export function useUpdateDirNationalTeamMemberAdmissionDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirNationalTeamMemberAdmissionDateMutation, UpdateDirNationalTeamMemberAdmissionDateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirNationalTeamMemberAdmissionDateMutation, UpdateDirNationalTeamMemberAdmissionDateMutationVariables>(UpdateDirNationalTeamMemberAdmissionDateDocument, baseOptions);
      }
export type UpdateDirNationalTeamMemberAdmissionDateMutationHookResult = ReturnType<typeof useUpdateDirNationalTeamMemberAdmissionDateMutation>;
export type UpdateDirNationalTeamMemberAdmissionDateMutationResult = Apollo.MutationResult<UpdateDirNationalTeamMemberAdmissionDateMutation>;
export type UpdateDirNationalTeamMemberAdmissionDateMutationOptions = Apollo.BaseMutationOptions<UpdateDirNationalTeamMemberAdmissionDateMutation, UpdateDirNationalTeamMemberAdmissionDateMutationVariables>;
export const SendNotificationUpdateStatusDocument = Apollo.gql`
    mutation SendNotificationUpdateStatus($id: UUID!) {
  updateDirNationalTeamExtendedStatusThenNotify(where: {id: $id}, data: {isActive: true}) {
    isActive
  }
}
    `;
export type SendNotificationUpdateStatusMutationFn = Apollo.MutationFunction<SendNotificationUpdateStatusMutation, SendNotificationUpdateStatusMutationVariables>;

/**
 * __useSendNotificationUpdateStatusMutation__
 *
 * To run a mutation, you first call `useSendNotificationUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNotificationUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNotificationUpdateStatusMutation, { data, loading, error }] = useSendNotificationUpdateStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendNotificationUpdateStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendNotificationUpdateStatusMutation, SendNotificationUpdateStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<SendNotificationUpdateStatusMutation, SendNotificationUpdateStatusMutationVariables>(SendNotificationUpdateStatusDocument, baseOptions);
      }
export type SendNotificationUpdateStatusMutationHookResult = ReturnType<typeof useSendNotificationUpdateStatusMutation>;
export type SendNotificationUpdateStatusMutationResult = Apollo.MutationResult<SendNotificationUpdateStatusMutation>;
export type SendNotificationUpdateStatusMutationOptions = Apollo.BaseMutationOptions<SendNotificationUpdateStatusMutation, SendNotificationUpdateStatusMutationVariables>;
export const AthletesInTeamDocument = Apollo.gql`
    query AthletesInTeam($eventId: UUID!, $athletesIdArray: [UUID!]) {
  dirNationalTeams(where: {dirSportingEvent: {id: $eventId}, athleteGroups_some: {athletes_some: {dirAthlete: {id_in: $athletesIdArray}}}}) {
    teamId: id
    athleteGroups {
      id
      athletes {
        id
        dirAthlete {
          id
          person: dirPerson {
            id
            firstname
            lastname
            patronymic
            registryNumber
            birthday
          }
        }
      }
      clsAgeGroups {
        id
      }
    }
  }
}
    `;

/**
 * __useAthletesInTeamQuery__
 *
 * To run a query within a React component, call `useAthletesInTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesInTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesInTeamQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      athletesIdArray: // value for 'athletesIdArray'
 *   },
 * });
 */
export function useAthletesInTeamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesInTeamQuery, AthletesInTeamQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesInTeamQuery, AthletesInTeamQueryVariables>(AthletesInTeamDocument, baseOptions);
      }
export function useAthletesInTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesInTeamQuery, AthletesInTeamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesInTeamQuery, AthletesInTeamQueryVariables>(AthletesInTeamDocument, baseOptions);
        }
export type AthletesInTeamQueryHookResult = ReturnType<typeof useAthletesInTeamQuery>;
export type AthletesInTeamLazyQueryHookResult = ReturnType<typeof useAthletesInTeamLazyQuery>;
export type AthletesInTeamQueryResult = Apollo.QueryResult<AthletesInTeamQuery, AthletesInTeamQueryVariables>;
export const AthleteTeamListDocument = Apollo.gql`
    query AthleteTeamList($id: UUID!) {
  dirNationalTeams(where: {id: $id}) {
    id
    athleteGroups {
      id
      athletes(where: {entranceStatus_not: false, dirAthlete: {dirPerson: {archive: null}}}) {
        id
        dirAthlete {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useAthleteTeamListQuery__
 *
 * To run a query within a React component, call `useAthleteTeamListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteTeamListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteTeamListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthleteTeamListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteTeamListQuery, AthleteTeamListQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteTeamListQuery, AthleteTeamListQueryVariables>(AthleteTeamListDocument, baseOptions);
      }
export function useAthleteTeamListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteTeamListQuery, AthleteTeamListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteTeamListQuery, AthleteTeamListQueryVariables>(AthleteTeamListDocument, baseOptions);
        }
export type AthleteTeamListQueryHookResult = ReturnType<typeof useAthleteTeamListQuery>;
export type AthleteTeamListLazyQueryHookResult = ReturnType<typeof useAthleteTeamListLazyQuery>;
export type AthleteTeamListQueryResult = Apollo.QueryResult<AthleteTeamListQuery, AthleteTeamListQueryVariables>;
export const NationalTeamsDocument = Apollo.gql`
    query NationalTeams($first: Int!, $skip: Int!, $filter: DirNationalTeamWhereInput) {
  dirNationalTeams(first: $first, skip: $skip, where: $filter) {
    ...NationalTeamTable
  }
}
    ${NationalTeamTableFragmentDoc}`;

/**
 * __useNationalTeamsQuery__
 *
 * To run a query within a React component, call `useNationalTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalTeamsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNationalTeamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NationalTeamsQuery, NationalTeamsQueryVariables>) {
        return ApolloReactHooks.useQuery<NationalTeamsQuery, NationalTeamsQueryVariables>(NationalTeamsDocument, baseOptions);
      }
export function useNationalTeamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NationalTeamsQuery, NationalTeamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NationalTeamsQuery, NationalTeamsQueryVariables>(NationalTeamsDocument, baseOptions);
        }
export type NationalTeamsQueryHookResult = ReturnType<typeof useNationalTeamsQuery>;
export type NationalTeamsLazyQueryHookResult = ReturnType<typeof useNationalTeamsLazyQuery>;
export type NationalTeamsQueryResult = Apollo.QueryResult<NationalTeamsQuery, NationalTeamsQueryVariables>;
export const NationalTeamsCountDocument = Apollo.gql`
    query NationalTeamsCount($filter: DirNationalTeamWhereInput) {
  count: dirNationalTeamsCount(where: $filter)
}
    `;

/**
 * __useNationalTeamsCountQuery__
 *
 * To run a query within a React component, call `useNationalTeamsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalTeamsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNationalTeamsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NationalTeamsCountQuery, NationalTeamsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<NationalTeamsCountQuery, NationalTeamsCountQueryVariables>(NationalTeamsCountDocument, baseOptions);
      }
export function useNationalTeamsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NationalTeamsCountQuery, NationalTeamsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NationalTeamsCountQuery, NationalTeamsCountQueryVariables>(NationalTeamsCountDocument, baseOptions);
        }
export type NationalTeamsCountQueryHookResult = ReturnType<typeof useNationalTeamsCountQuery>;
export type NationalTeamsCountLazyQueryHookResult = ReturnType<typeof useNationalTeamsCountLazyQuery>;
export type NationalTeamsCountQueryResult = Apollo.QueryResult<NationalTeamsCountQuery, NationalTeamsCountQueryVariables>;
export const NationalTeamDocument = Apollo.gql`
    query NationalTeam($id: UUID!) {
  dirNationalTeam(where: {id: $id}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;

/**
 * __useNationalTeamQuery__
 *
 * To run a query within a React component, call `useNationalTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNationalTeamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NationalTeamQuery, NationalTeamQueryVariables>) {
        return ApolloReactHooks.useQuery<NationalTeamQuery, NationalTeamQueryVariables>(NationalTeamDocument, baseOptions);
      }
export function useNationalTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NationalTeamQuery, NationalTeamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NationalTeamQuery, NationalTeamQueryVariables>(NationalTeamDocument, baseOptions);
        }
export type NationalTeamQueryHookResult = ReturnType<typeof useNationalTeamQuery>;
export type NationalTeamLazyQueryHookResult = ReturnType<typeof useNationalTeamLazyQuery>;
export type NationalTeamQueryResult = Apollo.QueryResult<NationalTeamQuery, NationalTeamQueryVariables>;
export const NationalTeamPeopleDocument = Apollo.gql`
    query NationalTeamPeople($id: UUID!) {
  aggregateParticipantsApplicationByTeamEvents(where: {id: $id}) {
    rows {
      n
      id
      name
      status
      birthday
      isMale
      qualification
      organization
      entranceStatus
      admissionDate
      mutateEntranceStatus
    }
  }
}
    `;

/**
 * __useNationalTeamPeopleQuery__
 *
 * To run a query within a React component, call `useNationalTeamPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalTeamPeopleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNationalTeamPeopleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NationalTeamPeopleQuery, NationalTeamPeopleQueryVariables>) {
        return ApolloReactHooks.useQuery<NationalTeamPeopleQuery, NationalTeamPeopleQueryVariables>(NationalTeamPeopleDocument, baseOptions);
      }
export function useNationalTeamPeopleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NationalTeamPeopleQuery, NationalTeamPeopleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NationalTeamPeopleQuery, NationalTeamPeopleQueryVariables>(NationalTeamPeopleDocument, baseOptions);
        }
export type NationalTeamPeopleQueryHookResult = ReturnType<typeof useNationalTeamPeopleQuery>;
export type NationalTeamPeopleLazyQueryHookResult = ReturnType<typeof useNationalTeamPeopleLazyQuery>;
export type NationalTeamPeopleQueryResult = Apollo.QueryResult<NationalTeamPeopleQuery, NationalTeamPeopleQueryVariables>;
export const NationalTeamStatusesDocument = Apollo.gql`
    query NationalTeamStatuses($id: UUID!) {
  dirNationalTeam(where: {id: $id}) {
    id
    statuses(orderBy: createdAt_DESC) {
      id
      isActive
    }
  }
}
    `;

/**
 * __useNationalTeamStatusesQuery__
 *
 * To run a query within a React component, call `useNationalTeamStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalTeamStatusesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNationalTeamStatusesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NationalTeamStatusesQuery, NationalTeamStatusesQueryVariables>) {
        return ApolloReactHooks.useQuery<NationalTeamStatusesQuery, NationalTeamStatusesQueryVariables>(NationalTeamStatusesDocument, baseOptions);
      }
export function useNationalTeamStatusesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NationalTeamStatusesQuery, NationalTeamStatusesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NationalTeamStatusesQuery, NationalTeamStatusesQueryVariables>(NationalTeamStatusesDocument, baseOptions);
        }
export type NationalTeamStatusesQueryHookResult = ReturnType<typeof useNationalTeamStatusesQuery>;
export type NationalTeamStatusesLazyQueryHookResult = ReturnType<typeof useNationalTeamStatusesLazyQuery>;
export type NationalTeamStatusesQueryResult = Apollo.QueryResult<NationalTeamStatusesQuery, NationalTeamStatusesQueryVariables>;
export const UploadNationalTeamsAgreementsDocDocument = Apollo.gql`
    mutation UploadNationalTeamsAgreementsDoc($id: UUID!, $idFile: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {refereePersonalDataAgreements: {connect: {id: $idFile}}}) {
    refereePersonalDataAgreements {
      name
      path
      id
    }
  }
}
    `;
export type UploadNationalTeamsAgreementsDocMutationFn = Apollo.MutationFunction<UploadNationalTeamsAgreementsDocMutation, UploadNationalTeamsAgreementsDocMutationVariables>;

/**
 * __useUploadNationalTeamsAgreementsDocMutation__
 *
 * To run a mutation, you first call `useUploadNationalTeamsAgreementsDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadNationalTeamsAgreementsDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadNationalTeamsAgreementsDocMutation, { data, loading, error }] = useUploadNationalTeamsAgreementsDocMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idFile: // value for 'idFile'
 *   },
 * });
 */
export function useUploadNationalTeamsAgreementsDocMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadNationalTeamsAgreementsDocMutation, UploadNationalTeamsAgreementsDocMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadNationalTeamsAgreementsDocMutation, UploadNationalTeamsAgreementsDocMutationVariables>(UploadNationalTeamsAgreementsDocDocument, baseOptions);
      }
export type UploadNationalTeamsAgreementsDocMutationHookResult = ReturnType<typeof useUploadNationalTeamsAgreementsDocMutation>;
export type UploadNationalTeamsAgreementsDocMutationResult = Apollo.MutationResult<UploadNationalTeamsAgreementsDocMutation>;
export type UploadNationalTeamsAgreementsDocMutationOptions = Apollo.BaseMutationOptions<UploadNationalTeamsAgreementsDocMutation, UploadNationalTeamsAgreementsDocMutationVariables>;
export const DeleteNationalTeamsAgreementsDocDocument = Apollo.gql`
    mutation DeleteNationalTeamsAgreementsDoc($id: UUID!, $idFile: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {refereePersonalDataAgreements: {disconnect: {id: $idFile}}}) {
    refereePersonalDataAgreements {
      name
      path
      id
    }
  }
}
    `;
export type DeleteNationalTeamsAgreementsDocMutationFn = Apollo.MutationFunction<DeleteNationalTeamsAgreementsDocMutation, DeleteNationalTeamsAgreementsDocMutationVariables>;

/**
 * __useDeleteNationalTeamsAgreementsDocMutation__
 *
 * To run a mutation, you first call `useDeleteNationalTeamsAgreementsDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNationalTeamsAgreementsDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNationalTeamsAgreementsDocMutation, { data, loading, error }] = useDeleteNationalTeamsAgreementsDocMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idFile: // value for 'idFile'
 *   },
 * });
 */
export function useDeleteNationalTeamsAgreementsDocMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNationalTeamsAgreementsDocMutation, DeleteNationalTeamsAgreementsDocMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNationalTeamsAgreementsDocMutation, DeleteNationalTeamsAgreementsDocMutationVariables>(DeleteNationalTeamsAgreementsDocDocument, baseOptions);
      }
export type DeleteNationalTeamsAgreementsDocMutationHookResult = ReturnType<typeof useDeleteNationalTeamsAgreementsDocMutation>;
export type DeleteNationalTeamsAgreementsDocMutationResult = Apollo.MutationResult<DeleteNationalTeamsAgreementsDocMutation>;
export type DeleteNationalTeamsAgreementsDocMutationOptions = Apollo.BaseMutationOptions<DeleteNationalTeamsAgreementsDocMutation, DeleteNationalTeamsAgreementsDocMutationVariables>;
export const GetListNationalTeamAgreementsDocsDocument = Apollo.gql`
    query GetListNationalTeamAgreementsDocs($id: UUID!) {
  dirNationalTeam(where: {id: $id}) {
    refereePersonalDataAgreements {
      name
      path
      id
    }
  }
}
    `;

/**
 * __useGetListNationalTeamAgreementsDocsQuery__
 *
 * To run a query within a React component, call `useGetListNationalTeamAgreementsDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListNationalTeamAgreementsDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListNationalTeamAgreementsDocsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetListNationalTeamAgreementsDocsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetListNationalTeamAgreementsDocsQuery, GetListNationalTeamAgreementsDocsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetListNationalTeamAgreementsDocsQuery, GetListNationalTeamAgreementsDocsQueryVariables>(GetListNationalTeamAgreementsDocsDocument, baseOptions);
      }
export function useGetListNationalTeamAgreementsDocsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetListNationalTeamAgreementsDocsQuery, GetListNationalTeamAgreementsDocsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetListNationalTeamAgreementsDocsQuery, GetListNationalTeamAgreementsDocsQueryVariables>(GetListNationalTeamAgreementsDocsDocument, baseOptions);
        }
export type GetListNationalTeamAgreementsDocsQueryHookResult = ReturnType<typeof useGetListNationalTeamAgreementsDocsQuery>;
export type GetListNationalTeamAgreementsDocsLazyQueryHookResult = ReturnType<typeof useGetListNationalTeamAgreementsDocsLazyQuery>;
export type GetListNationalTeamAgreementsDocsQueryResult = Apollo.QueryResult<GetListNationalTeamAgreementsDocsQuery, GetListNationalTeamAgreementsDocsQueryVariables>;
export const UploadNationalTeamsDeparturePlanDocument = Apollo.gql`
    mutation UploadNationalTeamsDeparturePlan($id: UUID!, $idFile: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {arrivalDeparturePlan: {connect: {id: $idFile}}}) {
    arrivalDeparturePlan {
      name
      path
      id
    }
  }
}
    `;
export type UploadNationalTeamsDeparturePlanMutationFn = Apollo.MutationFunction<UploadNationalTeamsDeparturePlanMutation, UploadNationalTeamsDeparturePlanMutationVariables>;

/**
 * __useUploadNationalTeamsDeparturePlanMutation__
 *
 * To run a mutation, you first call `useUploadNationalTeamsDeparturePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadNationalTeamsDeparturePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadNationalTeamsDeparturePlanMutation, { data, loading, error }] = useUploadNationalTeamsDeparturePlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idFile: // value for 'idFile'
 *   },
 * });
 */
export function useUploadNationalTeamsDeparturePlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadNationalTeamsDeparturePlanMutation, UploadNationalTeamsDeparturePlanMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadNationalTeamsDeparturePlanMutation, UploadNationalTeamsDeparturePlanMutationVariables>(UploadNationalTeamsDeparturePlanDocument, baseOptions);
      }
export type UploadNationalTeamsDeparturePlanMutationHookResult = ReturnType<typeof useUploadNationalTeamsDeparturePlanMutation>;
export type UploadNationalTeamsDeparturePlanMutationResult = Apollo.MutationResult<UploadNationalTeamsDeparturePlanMutation>;
export type UploadNationalTeamsDeparturePlanMutationOptions = Apollo.BaseMutationOptions<UploadNationalTeamsDeparturePlanMutation, UploadNationalTeamsDeparturePlanMutationVariables>;
export const DeleteNationalTeamsDeparturePlanDocument = Apollo.gql`
    mutation DeleteNationalTeamsDeparturePlan($id: UUID!, $idFile: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {arrivalDeparturePlan: {disconnect: {id: $idFile}}}) {
    arrivalDeparturePlan {
      name
      path
      id
    }
  }
}
    `;
export type DeleteNationalTeamsDeparturePlanMutationFn = Apollo.MutationFunction<DeleteNationalTeamsDeparturePlanMutation, DeleteNationalTeamsDeparturePlanMutationVariables>;

/**
 * __useDeleteNationalTeamsDeparturePlanMutation__
 *
 * To run a mutation, you first call `useDeleteNationalTeamsDeparturePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNationalTeamsDeparturePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNationalTeamsDeparturePlanMutation, { data, loading, error }] = useDeleteNationalTeamsDeparturePlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idFile: // value for 'idFile'
 *   },
 * });
 */
export function useDeleteNationalTeamsDeparturePlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNationalTeamsDeparturePlanMutation, DeleteNationalTeamsDeparturePlanMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNationalTeamsDeparturePlanMutation, DeleteNationalTeamsDeparturePlanMutationVariables>(DeleteNationalTeamsDeparturePlanDocument, baseOptions);
      }
export type DeleteNationalTeamsDeparturePlanMutationHookResult = ReturnType<typeof useDeleteNationalTeamsDeparturePlanMutation>;
export type DeleteNationalTeamsDeparturePlanMutationResult = Apollo.MutationResult<DeleteNationalTeamsDeparturePlanMutation>;
export type DeleteNationalTeamsDeparturePlanMutationOptions = Apollo.BaseMutationOptions<DeleteNationalTeamsDeparturePlanMutation, DeleteNationalTeamsDeparturePlanMutationVariables>;
export const GetListNationalTeamDeparturePlanDocument = Apollo.gql`
    query GetListNationalTeamDeparturePlan($id: UUID!) {
  dirNationalTeam(where: {id: $id}) {
    arrivalDeparturePlan {
      name
      path
      id
    }
  }
}
    `;

/**
 * __useGetListNationalTeamDeparturePlanQuery__
 *
 * To run a query within a React component, call `useGetListNationalTeamDeparturePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListNationalTeamDeparturePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListNationalTeamDeparturePlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetListNationalTeamDeparturePlanQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetListNationalTeamDeparturePlanQuery, GetListNationalTeamDeparturePlanQueryVariables>) {
        return ApolloReactHooks.useQuery<GetListNationalTeamDeparturePlanQuery, GetListNationalTeamDeparturePlanQueryVariables>(GetListNationalTeamDeparturePlanDocument, baseOptions);
      }
export function useGetListNationalTeamDeparturePlanLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetListNationalTeamDeparturePlanQuery, GetListNationalTeamDeparturePlanQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetListNationalTeamDeparturePlanQuery, GetListNationalTeamDeparturePlanQueryVariables>(GetListNationalTeamDeparturePlanDocument, baseOptions);
        }
export type GetListNationalTeamDeparturePlanQueryHookResult = ReturnType<typeof useGetListNationalTeamDeparturePlanQuery>;
export type GetListNationalTeamDeparturePlanLazyQueryHookResult = ReturnType<typeof useGetListNationalTeamDeparturePlanLazyQuery>;
export type GetListNationalTeamDeparturePlanQueryResult = Apollo.QueryResult<GetListNationalTeamDeparturePlanQuery, GetListNationalTeamDeparturePlanQueryVariables>;
export const UploadNationalTeamsQuantitativeOrderDocument = Apollo.gql`
    mutation UploadNationalTeamsQuantitativeOrder($id: UUID!, $idFile: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {preliminaryQuantitativeOrder: {connect: {id: $idFile}}}) {
    preliminaryQuantitativeOrder {
      name
      path
      id
    }
  }
}
    `;
export type UploadNationalTeamsQuantitativeOrderMutationFn = Apollo.MutationFunction<UploadNationalTeamsQuantitativeOrderMutation, UploadNationalTeamsQuantitativeOrderMutationVariables>;

/**
 * __useUploadNationalTeamsQuantitativeOrderMutation__
 *
 * To run a mutation, you first call `useUploadNationalTeamsQuantitativeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadNationalTeamsQuantitativeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadNationalTeamsQuantitativeOrderMutation, { data, loading, error }] = useUploadNationalTeamsQuantitativeOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idFile: // value for 'idFile'
 *   },
 * });
 */
export function useUploadNationalTeamsQuantitativeOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadNationalTeamsQuantitativeOrderMutation, UploadNationalTeamsQuantitativeOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadNationalTeamsQuantitativeOrderMutation, UploadNationalTeamsQuantitativeOrderMutationVariables>(UploadNationalTeamsQuantitativeOrderDocument, baseOptions);
      }
export type UploadNationalTeamsQuantitativeOrderMutationHookResult = ReturnType<typeof useUploadNationalTeamsQuantitativeOrderMutation>;
export type UploadNationalTeamsQuantitativeOrderMutationResult = Apollo.MutationResult<UploadNationalTeamsQuantitativeOrderMutation>;
export type UploadNationalTeamsQuantitativeOrderMutationOptions = Apollo.BaseMutationOptions<UploadNationalTeamsQuantitativeOrderMutation, UploadNationalTeamsQuantitativeOrderMutationVariables>;
export const DeleteNationalTeamsQuantitativeOrderDocument = Apollo.gql`
    mutation DeleteNationalTeamsQuantitativeOrder($id: UUID!, $idFile: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {preliminaryQuantitativeOrder: {disconnect: {id: $idFile}}}) {
    preliminaryQuantitativeOrder {
      name
      path
      id
    }
  }
}
    `;
export type DeleteNationalTeamsQuantitativeOrderMutationFn = Apollo.MutationFunction<DeleteNationalTeamsQuantitativeOrderMutation, DeleteNationalTeamsQuantitativeOrderMutationVariables>;

/**
 * __useDeleteNationalTeamsQuantitativeOrderMutation__
 *
 * To run a mutation, you first call `useDeleteNationalTeamsQuantitativeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNationalTeamsQuantitativeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNationalTeamsQuantitativeOrderMutation, { data, loading, error }] = useDeleteNationalTeamsQuantitativeOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idFile: // value for 'idFile'
 *   },
 * });
 */
export function useDeleteNationalTeamsQuantitativeOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNationalTeamsQuantitativeOrderMutation, DeleteNationalTeamsQuantitativeOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNationalTeamsQuantitativeOrderMutation, DeleteNationalTeamsQuantitativeOrderMutationVariables>(DeleteNationalTeamsQuantitativeOrderDocument, baseOptions);
      }
export type DeleteNationalTeamsQuantitativeOrderMutationHookResult = ReturnType<typeof useDeleteNationalTeamsQuantitativeOrderMutation>;
export type DeleteNationalTeamsQuantitativeOrderMutationResult = Apollo.MutationResult<DeleteNationalTeamsQuantitativeOrderMutation>;
export type DeleteNationalTeamsQuantitativeOrderMutationOptions = Apollo.BaseMutationOptions<DeleteNationalTeamsQuantitativeOrderMutation, DeleteNationalTeamsQuantitativeOrderMutationVariables>;
export const GetListNationalTeamQuantitativeOrderDocument = Apollo.gql`
    query GetListNationalTeamQuantitativeOrder($id: UUID!) {
  dirNationalTeam(where: {id: $id}) {
    preliminaryQuantitativeOrder {
      name
      path
      id
    }
  }
}
    `;

/**
 * __useGetListNationalTeamQuantitativeOrderQuery__
 *
 * To run a query within a React component, call `useGetListNationalTeamQuantitativeOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListNationalTeamQuantitativeOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListNationalTeamQuantitativeOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetListNationalTeamQuantitativeOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetListNationalTeamQuantitativeOrderQuery, GetListNationalTeamQuantitativeOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetListNationalTeamQuantitativeOrderQuery, GetListNationalTeamQuantitativeOrderQueryVariables>(GetListNationalTeamQuantitativeOrderDocument, baseOptions);
      }
export function useGetListNationalTeamQuantitativeOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetListNationalTeamQuantitativeOrderQuery, GetListNationalTeamQuantitativeOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetListNationalTeamQuantitativeOrderQuery, GetListNationalTeamQuantitativeOrderQueryVariables>(GetListNationalTeamQuantitativeOrderDocument, baseOptions);
        }
export type GetListNationalTeamQuantitativeOrderQueryHookResult = ReturnType<typeof useGetListNationalTeamQuantitativeOrderQuery>;
export type GetListNationalTeamQuantitativeOrderLazyQueryHookResult = ReturnType<typeof useGetListNationalTeamQuantitativeOrderLazyQuery>;
export type GetListNationalTeamQuantitativeOrderQueryResult = Apollo.QueryResult<GetListNationalTeamQuantitativeOrderQuery, GetListNationalTeamQuantitativeOrderQueryVariables>;
export const UpdateOrganizationSitesDocument = Apollo.gql`
    mutation UpdateOrganizationSites($id: UUID!, $sites: [String!]) {
  updateDirOrganization(where: {id: $id}, data: {websites: {set: $sites}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type UpdateOrganizationSitesMutationFn = Apollo.MutationFunction<UpdateOrganizationSitesMutation, UpdateOrganizationSitesMutationVariables>;

/**
 * __useUpdateOrganizationSitesMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSitesMutation, { data, loading, error }] = useUpdateOrganizationSitesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sites: // value for 'sites'
 *   },
 * });
 */
export function useUpdateOrganizationSitesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationSitesMutation, UpdateOrganizationSitesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationSitesMutation, UpdateOrganizationSitesMutationVariables>(UpdateOrganizationSitesDocument, baseOptions);
      }
export type UpdateOrganizationSitesMutationHookResult = ReturnType<typeof useUpdateOrganizationSitesMutation>;
export type UpdateOrganizationSitesMutationResult = Apollo.MutationResult<UpdateOrganizationSitesMutation>;
export type UpdateOrganizationSitesMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationSitesMutation, UpdateOrganizationSitesMutationVariables>;
export const OrganizationSitesDocument = Apollo.gql`
    query OrganizationSites($id: UUID!) {
  dirOrganization(where: {id: $id}) {
    id
    websites
  }
}
    `;

/**
 * __useOrganizationSitesQuery__
 *
 * To run a query within a React component, call `useOrganizationSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSitesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationSitesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationSitesQuery, OrganizationSitesQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationSitesQuery, OrganizationSitesQueryVariables>(OrganizationSitesDocument, baseOptions);
      }
export function useOrganizationSitesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSitesQuery, OrganizationSitesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationSitesQuery, OrganizationSitesQueryVariables>(OrganizationSitesDocument, baseOptions);
        }
export type OrganizationSitesQueryHookResult = ReturnType<typeof useOrganizationSitesQuery>;
export type OrganizationSitesLazyQueryHookResult = ReturnType<typeof useOrganizationSitesLazyQuery>;
export type OrganizationSitesQueryResult = Apollo.QueryResult<OrganizationSitesQuery, OrganizationSitesQueryVariables>;
export const CreateOrganizationDocument = Apollo.gql`
    mutation CreateOrganization($data: DirOrganizationCreateInput!) {
  createDirOrganization(data: $data) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, baseOptions);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const SimpleUpdateOrganizationDocument = Apollo.gql`
    mutation SimpleUpdateOrganization($id: UUID!, $data: DirOrganizationUpdateInput!) {
  updateDirOrganization(where: {id: $id}, data: $data) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type SimpleUpdateOrganizationMutationFn = Apollo.MutationFunction<SimpleUpdateOrganizationMutation, SimpleUpdateOrganizationMutationVariables>;

/**
 * __useSimpleUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useSimpleUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimpleUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simpleUpdateOrganizationMutation, { data, loading, error }] = useSimpleUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSimpleUpdateOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SimpleUpdateOrganizationMutation, SimpleUpdateOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<SimpleUpdateOrganizationMutation, SimpleUpdateOrganizationMutationVariables>(SimpleUpdateOrganizationDocument, baseOptions);
      }
export type SimpleUpdateOrganizationMutationHookResult = ReturnType<typeof useSimpleUpdateOrganizationMutation>;
export type SimpleUpdateOrganizationMutationResult = Apollo.MutationResult<SimpleUpdateOrganizationMutation>;
export type SimpleUpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<SimpleUpdateOrganizationMutation, SimpleUpdateOrganizationMutationVariables>;
export const AddEmailToOrganizationDocument = Apollo.gql`
    mutation AddEmailToOrganization($id: UUID!, $email: String!, $type: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {emails: {create: {email: $email, clsContactType: {connect: {id: $type}}}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type AddEmailToOrganizationMutationFn = Apollo.MutationFunction<AddEmailToOrganizationMutation, AddEmailToOrganizationMutationVariables>;

/**
 * __useAddEmailToOrganizationMutation__
 *
 * To run a mutation, you first call `useAddEmailToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailToOrganizationMutation, { data, loading, error }] = useAddEmailToOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddEmailToOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddEmailToOrganizationMutation, AddEmailToOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<AddEmailToOrganizationMutation, AddEmailToOrganizationMutationVariables>(AddEmailToOrganizationDocument, baseOptions);
      }
export type AddEmailToOrganizationMutationHookResult = ReturnType<typeof useAddEmailToOrganizationMutation>;
export type AddEmailToOrganizationMutationResult = Apollo.MutationResult<AddEmailToOrganizationMutation>;
export type AddEmailToOrganizationMutationOptions = Apollo.BaseMutationOptions<AddEmailToOrganizationMutation, AddEmailToOrganizationMutationVariables>;
export const UpdateOrganizationEmailDocument = Apollo.gql`
    mutation UpdateOrganizationEmail($id: UUID!, $email: String!, $type: UUID!, $emailId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {emails: {update: {where: {id: $emailId}, data: {email: $email, clsContactType: {connect: {id: $type}}}}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type UpdateOrganizationEmailMutationFn = Apollo.MutationFunction<UpdateOrganizationEmailMutation, UpdateOrganizationEmailMutationVariables>;

/**
 * __useUpdateOrganizationEmailMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationEmailMutation, { data, loading, error }] = useUpdateOrganizationEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      type: // value for 'type'
 *      emailId: // value for 'emailId'
 *   },
 * });
 */
export function useUpdateOrganizationEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationEmailMutation, UpdateOrganizationEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationEmailMutation, UpdateOrganizationEmailMutationVariables>(UpdateOrganizationEmailDocument, baseOptions);
      }
export type UpdateOrganizationEmailMutationHookResult = ReturnType<typeof useUpdateOrganizationEmailMutation>;
export type UpdateOrganizationEmailMutationResult = Apollo.MutationResult<UpdateOrganizationEmailMutation>;
export type UpdateOrganizationEmailMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationEmailMutation, UpdateOrganizationEmailMutationVariables>;
export const DeleteEmailFromOrganizationDocument = Apollo.gql`
    mutation deleteEmailFromOrganization($id: UUID!, $emailId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {emails: {delete: {id: $emailId}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type DeleteEmailFromOrganizationMutationFn = Apollo.MutationFunction<DeleteEmailFromOrganizationMutation, DeleteEmailFromOrganizationMutationVariables>;

/**
 * __useDeleteEmailFromOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteEmailFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailFromOrganizationMutation, { data, loading, error }] = useDeleteEmailFromOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      emailId: // value for 'emailId'
 *   },
 * });
 */
export function useDeleteEmailFromOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEmailFromOrganizationMutation, DeleteEmailFromOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEmailFromOrganizationMutation, DeleteEmailFromOrganizationMutationVariables>(DeleteEmailFromOrganizationDocument, baseOptions);
      }
export type DeleteEmailFromOrganizationMutationHookResult = ReturnType<typeof useDeleteEmailFromOrganizationMutation>;
export type DeleteEmailFromOrganizationMutationResult = Apollo.MutationResult<DeleteEmailFromOrganizationMutation>;
export type DeleteEmailFromOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteEmailFromOrganizationMutation, DeleteEmailFromOrganizationMutationVariables>;
export const AddAddressToOrganizationDocument = Apollo.gql`
    mutation AddAddressToOrganization($id: UUID!, $place: String!, $street: String!, $house: String!, $number: String, $type: Int!) {
  updateDirOrganization(where: {id: $id}, data: {addresses: {create: {place: $place, street: $street, house: $house, number: $number, type: {connect: {id: $type}}}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type AddAddressToOrganizationMutationFn = Apollo.MutationFunction<AddAddressToOrganizationMutation, AddAddressToOrganizationMutationVariables>;

/**
 * __useAddAddressToOrganizationMutation__
 *
 * To run a mutation, you first call `useAddAddressToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddressToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddressToOrganizationMutation, { data, loading, error }] = useAddAddressToOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      place: // value for 'place'
 *      street: // value for 'street'
 *      house: // value for 'house'
 *      number: // value for 'number'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddAddressToOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAddressToOrganizationMutation, AddAddressToOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAddressToOrganizationMutation, AddAddressToOrganizationMutationVariables>(AddAddressToOrganizationDocument, baseOptions);
      }
export type AddAddressToOrganizationMutationHookResult = ReturnType<typeof useAddAddressToOrganizationMutation>;
export type AddAddressToOrganizationMutationResult = Apollo.MutationResult<AddAddressToOrganizationMutation>;
export type AddAddressToOrganizationMutationOptions = Apollo.BaseMutationOptions<AddAddressToOrganizationMutation, AddAddressToOrganizationMutationVariables>;
export const UpdateOrganizationAddressDocument = Apollo.gql`
    mutation UpdateOrganizationAddress($id: UUID!, $place: String!, $street: String!, $house: String!, $number: String, $type: Int!, $addressId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {addresses: {update: {where: {id: $addressId}, data: {place: $place, street: $street, house: $house, number: $number, type: {connect: {id: $type}}}}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type UpdateOrganizationAddressMutationFn = Apollo.MutationFunction<UpdateOrganizationAddressMutation, UpdateOrganizationAddressMutationVariables>;

/**
 * __useUpdateOrganizationAddressMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationAddressMutation, { data, loading, error }] = useUpdateOrganizationAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      place: // value for 'place'
 *      street: // value for 'street'
 *      house: // value for 'house'
 *      number: // value for 'number'
 *      type: // value for 'type'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useUpdateOrganizationAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationAddressMutation, UpdateOrganizationAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationAddressMutation, UpdateOrganizationAddressMutationVariables>(UpdateOrganizationAddressDocument, baseOptions);
      }
export type UpdateOrganizationAddressMutationHookResult = ReturnType<typeof useUpdateOrganizationAddressMutation>;
export type UpdateOrganizationAddressMutationResult = Apollo.MutationResult<UpdateOrganizationAddressMutation>;
export type UpdateOrganizationAddressMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationAddressMutation, UpdateOrganizationAddressMutationVariables>;
export const DeleteAddressFromOrganizationDocument = Apollo.gql`
    mutation DeleteAddressFromOrganization($id: UUID!, $addressId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {addresses: {delete: {id: $addressId}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type DeleteAddressFromOrganizationMutationFn = Apollo.MutationFunction<DeleteAddressFromOrganizationMutation, DeleteAddressFromOrganizationMutationVariables>;

/**
 * __useDeleteAddressFromOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteAddressFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressFromOrganizationMutation, { data, loading, error }] = useDeleteAddressFromOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useDeleteAddressFromOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAddressFromOrganizationMutation, DeleteAddressFromOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAddressFromOrganizationMutation, DeleteAddressFromOrganizationMutationVariables>(DeleteAddressFromOrganizationDocument, baseOptions);
      }
export type DeleteAddressFromOrganizationMutationHookResult = ReturnType<typeof useDeleteAddressFromOrganizationMutation>;
export type DeleteAddressFromOrganizationMutationResult = Apollo.MutationResult<DeleteAddressFromOrganizationMutation>;
export type DeleteAddressFromOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteAddressFromOrganizationMutation, DeleteAddressFromOrganizationMutationVariables>;
export const AddPhoneToOrganizationDocument = Apollo.gql`
    mutation AddPhoneToOrganization($id: UUID!, $countryCode: String!, $operatorCode: String!, $number: String!, $additionalNumber: String!, $type: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {phones: {create: {countryCode: $countryCode, operatorCode: $operatorCode, number: $number, additionalNumber: $additionalNumber, clsContactType: {connect: {id: $type}}}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type AddPhoneToOrganizationMutationFn = Apollo.MutationFunction<AddPhoneToOrganizationMutation, AddPhoneToOrganizationMutationVariables>;

/**
 * __useAddPhoneToOrganizationMutation__
 *
 * To run a mutation, you first call `useAddPhoneToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPhoneToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPhoneToOrganizationMutation, { data, loading, error }] = useAddPhoneToOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      countryCode: // value for 'countryCode'
 *      operatorCode: // value for 'operatorCode'
 *      number: // value for 'number'
 *      additionalNumber: // value for 'additionalNumber'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddPhoneToOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPhoneToOrganizationMutation, AddPhoneToOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPhoneToOrganizationMutation, AddPhoneToOrganizationMutationVariables>(AddPhoneToOrganizationDocument, baseOptions);
      }
export type AddPhoneToOrganizationMutationHookResult = ReturnType<typeof useAddPhoneToOrganizationMutation>;
export type AddPhoneToOrganizationMutationResult = Apollo.MutationResult<AddPhoneToOrganizationMutation>;
export type AddPhoneToOrganizationMutationOptions = Apollo.BaseMutationOptions<AddPhoneToOrganizationMutation, AddPhoneToOrganizationMutationVariables>;
export const UpdateOrganizationPhoneDocument = Apollo.gql`
    mutation UpdateOrganizationPhone($id: UUID!, $countryCode: String!, $operatorCode: String!, $number: String!, $additionalNumber: String!, $type: UUID!, $phoneId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {phones: {update: {where: {id: $phoneId}, data: {countryCode: $countryCode, number: $number, operatorCode: $operatorCode, additionalNumber: $additionalNumber, clsContactType: {connect: {id: $type}}}}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type UpdateOrganizationPhoneMutationFn = Apollo.MutationFunction<UpdateOrganizationPhoneMutation, UpdateOrganizationPhoneMutationVariables>;

/**
 * __useUpdateOrganizationPhoneMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationPhoneMutation, { data, loading, error }] = useUpdateOrganizationPhoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      countryCode: // value for 'countryCode'
 *      operatorCode: // value for 'operatorCode'
 *      number: // value for 'number'
 *      additionalNumber: // value for 'additionalNumber'
 *      type: // value for 'type'
 *      phoneId: // value for 'phoneId'
 *   },
 * });
 */
export function useUpdateOrganizationPhoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationPhoneMutation, UpdateOrganizationPhoneMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationPhoneMutation, UpdateOrganizationPhoneMutationVariables>(UpdateOrganizationPhoneDocument, baseOptions);
      }
export type UpdateOrganizationPhoneMutationHookResult = ReturnType<typeof useUpdateOrganizationPhoneMutation>;
export type UpdateOrganizationPhoneMutationResult = Apollo.MutationResult<UpdateOrganizationPhoneMutation>;
export type UpdateOrganizationPhoneMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationPhoneMutation, UpdateOrganizationPhoneMutationVariables>;
export const DeletePhoneFromOrganizationDocument = Apollo.gql`
    mutation DeletePhoneFromOrganization($id: UUID!, $phoneId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {phones: {delete: {id: $phoneId}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type DeletePhoneFromOrganizationMutationFn = Apollo.MutationFunction<DeletePhoneFromOrganizationMutation, DeletePhoneFromOrganizationMutationVariables>;

/**
 * __useDeletePhoneFromOrganizationMutation__
 *
 * To run a mutation, you first call `useDeletePhoneFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhoneFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhoneFromOrganizationMutation, { data, loading, error }] = useDeletePhoneFromOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      phoneId: // value for 'phoneId'
 *   },
 * });
 */
export function useDeletePhoneFromOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePhoneFromOrganizationMutation, DeletePhoneFromOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePhoneFromOrganizationMutation, DeletePhoneFromOrganizationMutationVariables>(DeletePhoneFromOrganizationDocument, baseOptions);
      }
export type DeletePhoneFromOrganizationMutationHookResult = ReturnType<typeof useDeletePhoneFromOrganizationMutation>;
export type DeletePhoneFromOrganizationMutationResult = Apollo.MutationResult<DeletePhoneFromOrganizationMutation>;
export type DeletePhoneFromOrganizationMutationOptions = Apollo.BaseMutationOptions<DeletePhoneFromOrganizationMutation, DeletePhoneFromOrganizationMutationVariables>;
export const UpdateOrganizationDocument = Apollo.gql`
    mutation UpdateOrganization($id: UUID!, $data: DirOrganizationUpdateInput!) {
  updateDirOrganization(where: {id: $id}, data: $data) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, baseOptions);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const UpdateOrganizationLegalInfoDocument = Apollo.gql`
    mutation UpdateOrganizationLegalInfo($id: UUID!, $fullName: String, $shortName: String, $registrationDate: DateTime, $inn: String, $ogrn: String, $kpp: String, $isLegacy: Boolean!, $legalAddress: String, $postcode: String, $headFullName: String, $headPosition: String) {
  updateDirOrganization(where: {id: $id}, data: {fullName: $fullName, shortName: $shortName, registrationDate: $registrationDate, inn: $inn, kpp: $kpp, ogrn: $ogrn, isLegalEntity: $isLegacy, legalAddress: $legalAddress, postcode: $postcode, headFullName: $headFullName, headPosition: $headPosition}) {
    ...OrganizationLegalInfoFR
  }
}
    ${OrganizationLegalInfoFrFragmentDoc}`;
export type UpdateOrganizationLegalInfoMutationFn = Apollo.MutationFunction<UpdateOrganizationLegalInfoMutation, UpdateOrganizationLegalInfoMutationVariables>;

/**
 * __useUpdateOrganizationLegalInfoMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationLegalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationLegalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationLegalInfoMutation, { data, loading, error }] = useUpdateOrganizationLegalInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      registrationDate: // value for 'registrationDate'
 *      inn: // value for 'inn'
 *      ogrn: // value for 'ogrn'
 *      kpp: // value for 'kpp'
 *      isLegacy: // value for 'isLegacy'
 *      legalAddress: // value for 'legalAddress'
 *      postcode: // value for 'postcode'
 *      headFullName: // value for 'headFullName'
 *      headPosition: // value for 'headPosition'
 *   },
 * });
 */
export function useUpdateOrganizationLegalInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationLegalInfoMutation, UpdateOrganizationLegalInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationLegalInfoMutation, UpdateOrganizationLegalInfoMutationVariables>(UpdateOrganizationLegalInfoDocument, baseOptions);
      }
export type UpdateOrganizationLegalInfoMutationHookResult = ReturnType<typeof useUpdateOrganizationLegalInfoMutation>;
export type UpdateOrganizationLegalInfoMutationResult = Apollo.MutationResult<UpdateOrganizationLegalInfoMutation>;
export type UpdateOrganizationLegalInfoMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationLegalInfoMutation, UpdateOrganizationLegalInfoMutationVariables>;
export const OrganizationRemoveTypeSportDocument = Apollo.gql`
    mutation OrganizationRemoveTypeSport($id: UUID!, $idSport: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {dirSports: {disconnect: {id: $idSport}}}) {
    ...OrganizationTypeSportsFR
  }
}
    ${OrganizationTypeSportsFrFragmentDoc}`;
export type OrganizationRemoveTypeSportMutationFn = Apollo.MutationFunction<OrganizationRemoveTypeSportMutation, OrganizationRemoveTypeSportMutationVariables>;

/**
 * __useOrganizationRemoveTypeSportMutation__
 *
 * To run a mutation, you first call `useOrganizationRemoveTypeSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRemoveTypeSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationRemoveTypeSportMutation, { data, loading, error }] = useOrganizationRemoveTypeSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idSport: // value for 'idSport'
 *   },
 * });
 */
export function useOrganizationRemoveTypeSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationRemoveTypeSportMutation, OrganizationRemoveTypeSportMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationRemoveTypeSportMutation, OrganizationRemoveTypeSportMutationVariables>(OrganizationRemoveTypeSportDocument, baseOptions);
      }
export type OrganizationRemoveTypeSportMutationHookResult = ReturnType<typeof useOrganizationRemoveTypeSportMutation>;
export type OrganizationRemoveTypeSportMutationResult = Apollo.MutationResult<OrganizationRemoveTypeSportMutation>;
export type OrganizationRemoveTypeSportMutationOptions = Apollo.BaseMutationOptions<OrganizationRemoveTypeSportMutation, OrganizationRemoveTypeSportMutationVariables>;
export const UpdateOrganizationSportDocument = Apollo.gql`
    mutation UpdateOrganizationSport($id: UUID!, $data: DirOrganizationSportUpdateInput!) {
  updateDirOrganizationSport(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdateOrganizationSportMutationFn = Apollo.MutationFunction<UpdateOrganizationSportMutation, UpdateOrganizationSportMutationVariables>;

/**
 * __useUpdateOrganizationSportMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSportMutation, { data, loading, error }] = useUpdateOrganizationSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrganizationSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationSportMutation, UpdateOrganizationSportMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationSportMutation, UpdateOrganizationSportMutationVariables>(UpdateOrganizationSportDocument, baseOptions);
      }
export type UpdateOrganizationSportMutationHookResult = ReturnType<typeof useUpdateOrganizationSportMutation>;
export type UpdateOrganizationSportMutationResult = Apollo.MutationResult<UpdateOrganizationSportMutation>;
export type UpdateOrganizationSportMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationSportMutation, UpdateOrganizationSportMutationVariables>;
export const OrganizationCreateTypeSportDocument = Apollo.gql`
    mutation OrganizationCreateTypeSport($id: UUID!, $idSport: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {sports: {create: [{dirSport: {connect: {id: $idSport}}}]}}) {
    ...OrganizationTypeSportsFR
  }
}
    ${OrganizationTypeSportsFrFragmentDoc}`;
export type OrganizationCreateTypeSportMutationFn = Apollo.MutationFunction<OrganizationCreateTypeSportMutation, OrganizationCreateTypeSportMutationVariables>;

/**
 * __useOrganizationCreateTypeSportMutation__
 *
 * To run a mutation, you first call `useOrganizationCreateTypeSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreateTypeSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreateTypeSportMutation, { data, loading, error }] = useOrganizationCreateTypeSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idSport: // value for 'idSport'
 *   },
 * });
 */
export function useOrganizationCreateTypeSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationCreateTypeSportMutation, OrganizationCreateTypeSportMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationCreateTypeSportMutation, OrganizationCreateTypeSportMutationVariables>(OrganizationCreateTypeSportDocument, baseOptions);
      }
export type OrganizationCreateTypeSportMutationHookResult = ReturnType<typeof useOrganizationCreateTypeSportMutation>;
export type OrganizationCreateTypeSportMutationResult = Apollo.MutationResult<OrganizationCreateTypeSportMutation>;
export type OrganizationCreateTypeSportMutationOptions = Apollo.BaseMutationOptions<OrganizationCreateTypeSportMutation, OrganizationCreateTypeSportMutationVariables>;
export const OrganizationRemoveDeparmentAffiliationDocument = Apollo.gql`
    mutation OrganizationRemoveDeparmentAffiliation($id: UUID!, $idItem: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {clsDepartmentalAffiliations: {disconnect: {id: $idItem}}}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;
export type OrganizationRemoveDeparmentAffiliationMutationFn = Apollo.MutationFunction<OrganizationRemoveDeparmentAffiliationMutation, OrganizationRemoveDeparmentAffiliationMutationVariables>;

/**
 * __useOrganizationRemoveDeparmentAffiliationMutation__
 *
 * To run a mutation, you first call `useOrganizationRemoveDeparmentAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRemoveDeparmentAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationRemoveDeparmentAffiliationMutation, { data, loading, error }] = useOrganizationRemoveDeparmentAffiliationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useOrganizationRemoveDeparmentAffiliationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationRemoveDeparmentAffiliationMutation, OrganizationRemoveDeparmentAffiliationMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationRemoveDeparmentAffiliationMutation, OrganizationRemoveDeparmentAffiliationMutationVariables>(OrganizationRemoveDeparmentAffiliationDocument, baseOptions);
      }
export type OrganizationRemoveDeparmentAffiliationMutationHookResult = ReturnType<typeof useOrganizationRemoveDeparmentAffiliationMutation>;
export type OrganizationRemoveDeparmentAffiliationMutationResult = Apollo.MutationResult<OrganizationRemoveDeparmentAffiliationMutation>;
export type OrganizationRemoveDeparmentAffiliationMutationOptions = Apollo.BaseMutationOptions<OrganizationRemoveDeparmentAffiliationMutation, OrganizationRemoveDeparmentAffiliationMutationVariables>;
export const OrganizationCreateDeparmentAffiliationDocument = Apollo.gql`
    mutation OrganizationCreateDeparmentAffiliation($id: UUID!, $idItem: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {clsDepartmentalAffiliations: {connect: {id: $idItem}}}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;
export type OrganizationCreateDeparmentAffiliationMutationFn = Apollo.MutationFunction<OrganizationCreateDeparmentAffiliationMutation, OrganizationCreateDeparmentAffiliationMutationVariables>;

/**
 * __useOrganizationCreateDeparmentAffiliationMutation__
 *
 * To run a mutation, you first call `useOrganizationCreateDeparmentAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreateDeparmentAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreateDeparmentAffiliationMutation, { data, loading, error }] = useOrganizationCreateDeparmentAffiliationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useOrganizationCreateDeparmentAffiliationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationCreateDeparmentAffiliationMutation, OrganizationCreateDeparmentAffiliationMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationCreateDeparmentAffiliationMutation, OrganizationCreateDeparmentAffiliationMutationVariables>(OrganizationCreateDeparmentAffiliationDocument, baseOptions);
      }
export type OrganizationCreateDeparmentAffiliationMutationHookResult = ReturnType<typeof useOrganizationCreateDeparmentAffiliationMutation>;
export type OrganizationCreateDeparmentAffiliationMutationResult = Apollo.MutationResult<OrganizationCreateDeparmentAffiliationMutation>;
export type OrganizationCreateDeparmentAffiliationMutationOptions = Apollo.BaseMutationOptions<OrganizationCreateDeparmentAffiliationMutation, OrganizationCreateDeparmentAffiliationMutationVariables>;
export const UpdateOrganizationParentDocument = Apollo.gql`
    mutation UpdateOrganizationParent($id: UUID!, $parentId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {parent: {connect: {id: $parentId}}}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;
export type UpdateOrganizationParentMutationFn = Apollo.MutationFunction<UpdateOrganizationParentMutation, UpdateOrganizationParentMutationVariables>;

/**
 * __useUpdateOrganizationParentMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationParentMutation, { data, loading, error }] = useUpdateOrganizationParentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useUpdateOrganizationParentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationParentMutation, UpdateOrganizationParentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationParentMutation, UpdateOrganizationParentMutationVariables>(UpdateOrganizationParentDocument, baseOptions);
      }
export type UpdateOrganizationParentMutationHookResult = ReturnType<typeof useUpdateOrganizationParentMutation>;
export type UpdateOrganizationParentMutationResult = Apollo.MutationResult<UpdateOrganizationParentMutation>;
export type UpdateOrganizationParentMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationParentMutation, UpdateOrganizationParentMutationVariables>;
export const AddOrganizationChildrenDocument = Apollo.gql`
    mutation AddOrganizationChildren($id: UUID!, $childID: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {children: {connect: {id: $childID}}}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;
export type AddOrganizationChildrenMutationFn = Apollo.MutationFunction<AddOrganizationChildrenMutation, AddOrganizationChildrenMutationVariables>;

/**
 * __useAddOrganizationChildrenMutation__
 *
 * To run a mutation, you first call `useAddOrganizationChildrenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationChildrenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationChildrenMutation, { data, loading, error }] = useAddOrganizationChildrenMutation({
 *   variables: {
 *      id: // value for 'id'
 *      childID: // value for 'childID'
 *   },
 * });
 */
export function useAddOrganizationChildrenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddOrganizationChildrenMutation, AddOrganizationChildrenMutationVariables>) {
        return ApolloReactHooks.useMutation<AddOrganizationChildrenMutation, AddOrganizationChildrenMutationVariables>(AddOrganizationChildrenDocument, baseOptions);
      }
export type AddOrganizationChildrenMutationHookResult = ReturnType<typeof useAddOrganizationChildrenMutation>;
export type AddOrganizationChildrenMutationResult = Apollo.MutationResult<AddOrganizationChildrenMutation>;
export type AddOrganizationChildrenMutationOptions = Apollo.BaseMutationOptions<AddOrganizationChildrenMutation, AddOrganizationChildrenMutationVariables>;
export const RemoveOrganizationParentDocument = Apollo.gql`
    mutation RemoveOrganizationParent($id: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {parent: {disconnect: true}}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;
export type RemoveOrganizationParentMutationFn = Apollo.MutationFunction<RemoveOrganizationParentMutation, RemoveOrganizationParentMutationVariables>;

/**
 * __useRemoveOrganizationParentMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationParentMutation, { data, loading, error }] = useRemoveOrganizationParentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOrganizationParentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveOrganizationParentMutation, RemoveOrganizationParentMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveOrganizationParentMutation, RemoveOrganizationParentMutationVariables>(RemoveOrganizationParentDocument, baseOptions);
      }
export type RemoveOrganizationParentMutationHookResult = ReturnType<typeof useRemoveOrganizationParentMutation>;
export type RemoveOrganizationParentMutationResult = Apollo.MutationResult<RemoveOrganizationParentMutation>;
export type RemoveOrganizationParentMutationOptions = Apollo.BaseMutationOptions<RemoveOrganizationParentMutation, RemoveOrganizationParentMutationVariables>;
export const RemoveOrganizationChildrenDocument = Apollo.gql`
    mutation RemoveOrganizationChildren($id: UUID!, $childID: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {children: {disconnect: {id: $childID}}}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;
export type RemoveOrganizationChildrenMutationFn = Apollo.MutationFunction<RemoveOrganizationChildrenMutation, RemoveOrganizationChildrenMutationVariables>;

/**
 * __useRemoveOrganizationChildrenMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationChildrenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationChildrenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationChildrenMutation, { data, loading, error }] = useRemoveOrganizationChildrenMutation({
 *   variables: {
 *      id: // value for 'id'
 *      childID: // value for 'childID'
 *   },
 * });
 */
export function useRemoveOrganizationChildrenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveOrganizationChildrenMutation, RemoveOrganizationChildrenMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveOrganizationChildrenMutation, RemoveOrganizationChildrenMutationVariables>(RemoveOrganizationChildrenDocument, baseOptions);
      }
export type RemoveOrganizationChildrenMutationHookResult = ReturnType<typeof useRemoveOrganizationChildrenMutation>;
export type RemoveOrganizationChildrenMutationResult = Apollo.MutationResult<RemoveOrganizationChildrenMutation>;
export type RemoveOrganizationChildrenMutationOptions = Apollo.BaseMutationOptions<RemoveOrganizationChildrenMutation, RemoveOrganizationChildrenMutationVariables>;
export const OrganizationArchiveDocument = Apollo.gql`
    mutation OrganizationArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirOrganization(where: {id: $id}, data: {archive: $archive, ogrn: null}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type OrganizationArchiveMutationFn = Apollo.MutationFunction<OrganizationArchiveMutation, OrganizationArchiveMutationVariables>;

/**
 * __useOrganizationArchiveMutation__
 *
 * To run a mutation, you first call `useOrganizationArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationArchiveMutation, { data, loading, error }] = useOrganizationArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useOrganizationArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationArchiveMutation, OrganizationArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationArchiveMutation, OrganizationArchiveMutationVariables>(OrganizationArchiveDocument, baseOptions);
      }
export type OrganizationArchiveMutationHookResult = ReturnType<typeof useOrganizationArchiveMutation>;
export type OrganizationArchiveMutationResult = Apollo.MutationResult<OrganizationArchiveMutation>;
export type OrganizationArchiveMutationOptions = Apollo.BaseMutationOptions<OrganizationArchiveMutation, OrganizationArchiveMutationVariables>;
export const OrganizationUnArchiveDocument = Apollo.gql`
    mutation OrganizationUnArchive($id: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type OrganizationUnArchiveMutationFn = Apollo.MutationFunction<OrganizationUnArchiveMutation, OrganizationUnArchiveMutationVariables>;

/**
 * __useOrganizationUnArchiveMutation__
 *
 * To run a mutation, you first call `useOrganizationUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationUnArchiveMutation, { data, loading, error }] = useOrganizationUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationUnArchiveMutation, OrganizationUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationUnArchiveMutation, OrganizationUnArchiveMutationVariables>(OrganizationUnArchiveDocument, baseOptions);
      }
export type OrganizationUnArchiveMutationHookResult = ReturnType<typeof useOrganizationUnArchiveMutation>;
export type OrganizationUnArchiveMutationResult = Apollo.MutationResult<OrganizationUnArchiveMutation>;
export type OrganizationUnArchiveMutationOptions = Apollo.BaseMutationOptions<OrganizationUnArchiveMutation, OrganizationUnArchiveMutationVariables>;
export const OrganizationTypeSportArchiveDocument = Apollo.gql`
    mutation OrganizationTypeSportArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirOrganizationSport(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type OrganizationTypeSportArchiveMutationFn = Apollo.MutationFunction<OrganizationTypeSportArchiveMutation, OrganizationTypeSportArchiveMutationVariables>;

/**
 * __useOrganizationTypeSportArchiveMutation__
 *
 * To run a mutation, you first call `useOrganizationTypeSportArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTypeSportArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationTypeSportArchiveMutation, { data, loading, error }] = useOrganizationTypeSportArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useOrganizationTypeSportArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationTypeSportArchiveMutation, OrganizationTypeSportArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationTypeSportArchiveMutation, OrganizationTypeSportArchiveMutationVariables>(OrganizationTypeSportArchiveDocument, baseOptions);
      }
export type OrganizationTypeSportArchiveMutationHookResult = ReturnType<typeof useOrganizationTypeSportArchiveMutation>;
export type OrganizationTypeSportArchiveMutationResult = Apollo.MutationResult<OrganizationTypeSportArchiveMutation>;
export type OrganizationTypeSportArchiveMutationOptions = Apollo.BaseMutationOptions<OrganizationTypeSportArchiveMutation, OrganizationTypeSportArchiveMutationVariables>;
export const OrganizationTypeSportUnArchiveDocument = Apollo.gql`
    mutation OrganizationTypeSportUnArchive($id: UUID!) {
  updateDirOrganizationSport(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type OrganizationTypeSportUnArchiveMutationFn = Apollo.MutationFunction<OrganizationTypeSportUnArchiveMutation, OrganizationTypeSportUnArchiveMutationVariables>;

/**
 * __useOrganizationTypeSportUnArchiveMutation__
 *
 * To run a mutation, you first call `useOrganizationTypeSportUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTypeSportUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationTypeSportUnArchiveMutation, { data, loading, error }] = useOrganizationTypeSportUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationTypeSportUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationTypeSportUnArchiveMutation, OrganizationTypeSportUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationTypeSportUnArchiveMutation, OrganizationTypeSportUnArchiveMutationVariables>(OrganizationTypeSportUnArchiveDocument, baseOptions);
      }
export type OrganizationTypeSportUnArchiveMutationHookResult = ReturnType<typeof useOrganizationTypeSportUnArchiveMutation>;
export type OrganizationTypeSportUnArchiveMutationResult = Apollo.MutationResult<OrganizationTypeSportUnArchiveMutation>;
export type OrganizationTypeSportUnArchiveMutationOptions = Apollo.BaseMutationOptions<OrganizationTypeSportUnArchiveMutation, OrganizationTypeSportUnArchiveMutationVariables>;
export const OrganizationsDocument = Apollo.gql`
    query Organizations($first: Int!, $skip: Int!, $filter: DirOrganizationWhereInput) {
  dirOrganizations(first: $first, skip: $skip, where: $filter) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, baseOptions);
      }
export function useOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, baseOptions);
        }
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsQueryResult = Apollo.QueryResult<OrganizationsQuery, OrganizationsQueryVariables>;
export const Organizations2ndDocument = Apollo.gql`
    query Organizations2nd($name: String!, $first: Int!, $skip: Int!, $filter: DirOrganizationWhereInput) {
  dirOrganizationsSimilar(search: $name, first: $first, skip: $skip, where: $filter) {
    organization {
      ...OrganizationFR
    }
  }
}
    ${OrganizationFrFragmentDoc}`;

/**
 * __useOrganizations2ndQuery__
 *
 * To run a query within a React component, call `useOrganizations2ndQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizations2ndQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizations2ndQuery({
 *   variables: {
 *      name: // value for 'name'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizations2ndQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Organizations2ndQuery, Organizations2ndQueryVariables>) {
        return ApolloReactHooks.useQuery<Organizations2ndQuery, Organizations2ndQueryVariables>(Organizations2ndDocument, baseOptions);
      }
export function useOrganizations2ndLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Organizations2ndQuery, Organizations2ndQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Organizations2ndQuery, Organizations2ndQueryVariables>(Organizations2ndDocument, baseOptions);
        }
export type Organizations2ndQueryHookResult = ReturnType<typeof useOrganizations2ndQuery>;
export type Organizations2ndLazyQueryHookResult = ReturnType<typeof useOrganizations2ndLazyQuery>;
export type Organizations2ndQueryResult = Apollo.QueryResult<Organizations2ndQuery, Organizations2ndQueryVariables>;
export const OrganizationsCountDocument = Apollo.gql`
    query OrganizationsCount($filter: DirOrganizationWhereInput) {
  count: dirOrganizationsCount(where: $filter)
}
    `;

/**
 * __useOrganizationsCountQuery__
 *
 * To run a query within a React component, call `useOrganizationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationsCountQuery, OrganizationsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationsCountQuery, OrganizationsCountQueryVariables>(OrganizationsCountDocument, baseOptions);
      }
export function useOrganizationsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationsCountQuery, OrganizationsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationsCountQuery, OrganizationsCountQueryVariables>(OrganizationsCountDocument, baseOptions);
        }
export type OrganizationsCountQueryHookResult = ReturnType<typeof useOrganizationsCountQuery>;
export type OrganizationsCountLazyQueryHookResult = ReturnType<typeof useOrganizationsCountLazyQuery>;
export type OrganizationsCountQueryResult = Apollo.QueryResult<OrganizationsCountQuery, OrganizationsCountQueryVariables>;
export const OrganizationByIdDocument = Apollo.gql`
    query OrganizationById($id: UUID!) {
  dirOrganization(where: {id: $id}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;

/**
 * __useOrganizationByIdQuery__
 *
 * To run a query within a React component, call `useOrganizationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationByIdQuery, OrganizationByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationByIdQuery, OrganizationByIdQueryVariables>(OrganizationByIdDocument, baseOptions);
      }
export function useOrganizationByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationByIdQuery, OrganizationByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationByIdQuery, OrganizationByIdQueryVariables>(OrganizationByIdDocument, baseOptions);
        }
export type OrganizationByIdQueryHookResult = ReturnType<typeof useOrganizationByIdQuery>;
export type OrganizationByIdLazyQueryHookResult = ReturnType<typeof useOrganizationByIdLazyQuery>;
export type OrganizationByIdQueryResult = Apollo.QueryResult<OrganizationByIdQuery, OrganizationByIdQueryVariables>;
export const OrganizationByOgrnDocument = Apollo.gql`
    query OrganizationByOgrn($filter: DirOrganizationWhereInput) {
  dirOrganizations(first: 10, skip: 0, where: $filter) {
    value: id
    label: name
  }
}
    `;

/**
 * __useOrganizationByOgrnQuery__
 *
 * To run a query within a React component, call `useOrganizationByOgrnQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationByOgrnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationByOgrnQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationByOgrnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationByOgrnQuery, OrganizationByOgrnQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationByOgrnQuery, OrganizationByOgrnQueryVariables>(OrganizationByOgrnDocument, baseOptions);
      }
export function useOrganizationByOgrnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationByOgrnQuery, OrganizationByOgrnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationByOgrnQuery, OrganizationByOgrnQueryVariables>(OrganizationByOgrnDocument, baseOptions);
        }
export type OrganizationByOgrnQueryHookResult = ReturnType<typeof useOrganizationByOgrnQuery>;
export type OrganizationByOgrnLazyQueryHookResult = ReturnType<typeof useOrganizationByOgrnLazyQuery>;
export type OrganizationByOgrnQueryResult = Apollo.QueryResult<OrganizationByOgrnQuery, OrganizationByOgrnQueryVariables>;
export const OrganizationLegalInfoDocument = Apollo.gql`
    query OrganizationLegalInfo($id: UUID!) {
  dirOrganization(where: {id: $id}) {
    ...OrganizationLegalInfoFR
  }
}
    ${OrganizationLegalInfoFrFragmentDoc}`;

/**
 * __useOrganizationLegalInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationLegalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationLegalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationLegalInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationLegalInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationLegalInfoQuery, OrganizationLegalInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationLegalInfoQuery, OrganizationLegalInfoQueryVariables>(OrganizationLegalInfoDocument, baseOptions);
      }
export function useOrganizationLegalInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationLegalInfoQuery, OrganizationLegalInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationLegalInfoQuery, OrganizationLegalInfoQueryVariables>(OrganizationLegalInfoDocument, baseOptions);
        }
export type OrganizationLegalInfoQueryHookResult = ReturnType<typeof useOrganizationLegalInfoQuery>;
export type OrganizationLegalInfoLazyQueryHookResult = ReturnType<typeof useOrganizationLegalInfoLazyQuery>;
export type OrganizationLegalInfoQueryResult = Apollo.QueryResult<OrganizationLegalInfoQuery, OrganizationLegalInfoQueryVariables>;
export const OrganizationTypeSportsDocument = Apollo.gql`
    query OrganizationTypeSports($id: UUID!) {
  dirOrganizationSports(where: {dirOrganization: {id: $id}}) {
    id
    trainingStartDate
    dirSport {
      label: fullName
      value: id
    }
    disciplineGroups {
      label: name
      value: id
    }
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;

/**
 * __useOrganizationTypeSportsQuery__
 *
 * To run a query within a React component, call `useOrganizationTypeSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTypeSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTypeSportsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationTypeSportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationTypeSportsQuery, OrganizationTypeSportsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationTypeSportsQuery, OrganizationTypeSportsQueryVariables>(OrganizationTypeSportsDocument, baseOptions);
      }
export function useOrganizationTypeSportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationTypeSportsQuery, OrganizationTypeSportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationTypeSportsQuery, OrganizationTypeSportsQueryVariables>(OrganizationTypeSportsDocument, baseOptions);
        }
export type OrganizationTypeSportsQueryHookResult = ReturnType<typeof useOrganizationTypeSportsQuery>;
export type OrganizationTypeSportsLazyQueryHookResult = ReturnType<typeof useOrganizationTypeSportsLazyQuery>;
export type OrganizationTypeSportsQueryResult = Apollo.QueryResult<OrganizationTypeSportsQuery, OrganizationTypeSportsQueryVariables>;
export const OrganizationDepartmentAffiliationDocument = Apollo.gql`
    query OrganizationDepartmentAffiliation($id: UUID!) {
  dirOrganization(where: {id: $id}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;

/**
 * __useOrganizationDepartmentAffiliationQuery__
 *
 * To run a query within a React component, call `useOrganizationDepartmentAffiliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDepartmentAffiliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDepartmentAffiliationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationDepartmentAffiliationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationDepartmentAffiliationQuery, OrganizationDepartmentAffiliationQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationDepartmentAffiliationQuery, OrganizationDepartmentAffiliationQueryVariables>(OrganizationDepartmentAffiliationDocument, baseOptions);
      }
export function useOrganizationDepartmentAffiliationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationDepartmentAffiliationQuery, OrganizationDepartmentAffiliationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationDepartmentAffiliationQuery, OrganizationDepartmentAffiliationQueryVariables>(OrganizationDepartmentAffiliationDocument, baseOptions);
        }
export type OrganizationDepartmentAffiliationQueryHookResult = ReturnType<typeof useOrganizationDepartmentAffiliationQuery>;
export type OrganizationDepartmentAffiliationLazyQueryHookResult = ReturnType<typeof useOrganizationDepartmentAffiliationLazyQuery>;
export type OrganizationDepartmentAffiliationQueryResult = Apollo.QueryResult<OrganizationDepartmentAffiliationQuery, OrganizationDepartmentAffiliationQueryVariables>;
export const FindOrganizationsDocument = Apollo.gql`
    query FindOrganizations($search: String!, $filter: DirOrganizationWhereInput) {
  data: dirOrganizationsSimilar(search: $search, where: $filter, threshold: 0.1) {
    organization {
      value: id
      label: name
    }
  }
}
    `;

/**
 * __useFindOrganizationsQuery__
 *
 * To run a query within a React component, call `useFindOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrganizationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindOrganizationsQuery, FindOrganizationsQueryVariables>) {
        return ApolloReactHooks.useQuery<FindOrganizationsQuery, FindOrganizationsQueryVariables>(FindOrganizationsDocument, baseOptions);
      }
export function useFindOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindOrganizationsQuery, FindOrganizationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindOrganizationsQuery, FindOrganizationsQueryVariables>(FindOrganizationsDocument, baseOptions);
        }
export type FindOrganizationsQueryHookResult = ReturnType<typeof useFindOrganizationsQuery>;
export type FindOrganizationsLazyQueryHookResult = ReturnType<typeof useFindOrganizationsLazyQuery>;
export type FindOrganizationsQueryResult = Apollo.QueryResult<FindOrganizationsQuery, FindOrganizationsQueryVariables>;
export const SuggestOrganizationsDocument = Apollo.gql`
    query suggestOrganizations($ogrn: String!) {
  suggestOrganizations(by: $ogrn) {
    fullName
    shortName
    registrationDate
    inn
    kpp
    legalAddress
    postcode
    headPosition
    headFullName
  }
}
    `;

/**
 * __useSuggestOrganizationsQuery__
 *
 * To run a query within a React component, call `useSuggestOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestOrganizationsQuery({
 *   variables: {
 *      ogrn: // value for 'ogrn'
 *   },
 * });
 */
export function useSuggestOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SuggestOrganizationsQuery, SuggestOrganizationsQueryVariables>) {
        return ApolloReactHooks.useQuery<SuggestOrganizationsQuery, SuggestOrganizationsQueryVariables>(SuggestOrganizationsDocument, baseOptions);
      }
export function useSuggestOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SuggestOrganizationsQuery, SuggestOrganizationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SuggestOrganizationsQuery, SuggestOrganizationsQueryVariables>(SuggestOrganizationsDocument, baseOptions);
        }
export type SuggestOrganizationsQueryHookResult = ReturnType<typeof useSuggestOrganizationsQuery>;
export type SuggestOrganizationsLazyQueryHookResult = ReturnType<typeof useSuggestOrganizationsLazyQuery>;
export type SuggestOrganizationsQueryResult = Apollo.QueryResult<SuggestOrganizationsQuery, SuggestOrganizationsQueryVariables>;
export const TrainersInOrganizationDocument = Apollo.gql`
    query TrainersInOrganization($organizationId: UUID!, $sportId: UUID!, $bannedTrainers: [UUID!]) {
  dirTrainers(where: {id_not_in: $bannedTrainers, sports_some: {dirSport: {id: $sportId}, organizations_some: {dirOrganization: {id: $organizationId}, dismissalDate: null}}}) {
    id
    dirPerson {
      id
      firstname
      patronymic
      lastname
      birthday
    }
  }
}
    `;

/**
 * __useTrainersInOrganizationQuery__
 *
 * To run a query within a React component, call `useTrainersInOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainersInOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainersInOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      sportId: // value for 'sportId'
 *      bannedTrainers: // value for 'bannedTrainers'
 *   },
 * });
 */
export function useTrainersInOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainersInOrganizationQuery, TrainersInOrganizationQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainersInOrganizationQuery, TrainersInOrganizationQueryVariables>(TrainersInOrganizationDocument, baseOptions);
      }
export function useTrainersInOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainersInOrganizationQuery, TrainersInOrganizationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainersInOrganizationQuery, TrainersInOrganizationQueryVariables>(TrainersInOrganizationDocument, baseOptions);
        }
export type TrainersInOrganizationQueryHookResult = ReturnType<typeof useTrainersInOrganizationQuery>;
export type TrainersInOrganizationLazyQueryHookResult = ReturnType<typeof useTrainersInOrganizationLazyQuery>;
export type TrainersInOrganizationQueryResult = Apollo.QueryResult<TrainersInOrganizationQuery, TrainersInOrganizationQueryVariables>;
export const OrganizationAddressesDocument = Apollo.gql`
    query OrganizationAddresses($filter: DirOrganizationAddressWhereInput) {
  dirOrganizationAddresses(where: $filter, orderBy: createdAt_ASC) {
    id
    place
    street
    house
    number
    type {
      value: id
      label: name
    }
    comment
  }
}
    `;

/**
 * __useOrganizationAddressesQuery__
 *
 * To run a query within a React component, call `useOrganizationAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAddressesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationAddressesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationAddressesQuery, OrganizationAddressesQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationAddressesQuery, OrganizationAddressesQueryVariables>(OrganizationAddressesDocument, baseOptions);
      }
export function useOrganizationAddressesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationAddressesQuery, OrganizationAddressesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationAddressesQuery, OrganizationAddressesQueryVariables>(OrganizationAddressesDocument, baseOptions);
        }
export type OrganizationAddressesQueryHookResult = ReturnType<typeof useOrganizationAddressesQuery>;
export type OrganizationAddressesLazyQueryHookResult = ReturnType<typeof useOrganizationAddressesLazyQuery>;
export type OrganizationAddressesQueryResult = Apollo.QueryResult<OrganizationAddressesQuery, OrganizationAddressesQueryVariables>;
export const OrganizationsTrainersBySportDocument = Apollo.gql`
    query OrganizationsTrainersBySport($sportID: UUID, $id: UUID) {
  dirTrainers(where: {sports_some: {dirSport: {id: $sportID}, organizations_some: {dirOrganization: {id: $id}}}}) {
    id
    dirPerson {
      id
      firstname
      lastname
      patronymic
    }
    sports {
      id
      dirSport {
        id
        clsTrainingStages {
          id
          fullName
        }
      }
      organizations(orderBy: dismissalDate_DESC) {
        id
        clsPosition {
          value: id
          label: fullName
        }
        dirOrganization {
          value: id
          label: name
        }
        employmentDate
        dismissalDate
      }
    }
  }
}
    `;

/**
 * __useOrganizationsTrainersBySportQuery__
 *
 * To run a query within a React component, call `useOrganizationsTrainersBySportQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsTrainersBySportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsTrainersBySportQuery({
 *   variables: {
 *      sportID: // value for 'sportID'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationsTrainersBySportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationsTrainersBySportQuery, OrganizationsTrainersBySportQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationsTrainersBySportQuery, OrganizationsTrainersBySportQueryVariables>(OrganizationsTrainersBySportDocument, baseOptions);
      }
export function useOrganizationsTrainersBySportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationsTrainersBySportQuery, OrganizationsTrainersBySportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationsTrainersBySportQuery, OrganizationsTrainersBySportQueryVariables>(OrganizationsTrainersBySportDocument, baseOptions);
        }
export type OrganizationsTrainersBySportQueryHookResult = ReturnType<typeof useOrganizationsTrainersBySportQuery>;
export type OrganizationsTrainersBySportLazyQueryHookResult = ReturnType<typeof useOrganizationsTrainersBySportLazyQuery>;
export type OrganizationsTrainersBySportQueryResult = Apollo.QueryResult<OrganizationsTrainersBySportQuery, OrganizationsTrainersBySportQueryVariables>;
export const OrganizationsAthletesBySportDocument = Apollo.gql`
    query OrganizationsAthletesBySport($sportID: UUID, $id: UUID, $filter: DirAthleteSportOrganizationWhereInput) {
  dirAthletes(where: {sports_some: {dirSport: {id: $sportID}, organizations_some: {dirOrganization: {id: $id}}}}) {
    id
    dirPerson {
      firstname
      lastname
      patronymic
    }
    sports(where: {dirSport: {id: $sportID}}) {
      id
      dirSport {
        id
      }
      organizations(where: $filter, orderBy: exclusionDate_DESC) {
        id
        trainingStart
        exclusionDate
        dirTrainer {
          id
          dirPerson {
            trainerProfile {
              id
            }
            id
            firstname
            patronymic
            lastname
            birthday
          }
        }
        clsTrainingStage {
          id
          shortName
          fullName
        }
        isSelfTraining
      }
    }
  }
}
    `;

/**
 * __useOrganizationsAthletesBySportQuery__
 *
 * To run a query within a React component, call `useOrganizationsAthletesBySportQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsAthletesBySportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsAthletesBySportQuery({
 *   variables: {
 *      sportID: // value for 'sportID'
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationsAthletesBySportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationsAthletesBySportQuery, OrganizationsAthletesBySportQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationsAthletesBySportQuery, OrganizationsAthletesBySportQueryVariables>(OrganizationsAthletesBySportDocument, baseOptions);
      }
export function useOrganizationsAthletesBySportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationsAthletesBySportQuery, OrganizationsAthletesBySportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationsAthletesBySportQuery, OrganizationsAthletesBySportQueryVariables>(OrganizationsAthletesBySportDocument, baseOptions);
        }
export type OrganizationsAthletesBySportQueryHookResult = ReturnType<typeof useOrganizationsAthletesBySportQuery>;
export type OrganizationsAthletesBySportLazyQueryHookResult = ReturnType<typeof useOrganizationsAthletesBySportLazyQuery>;
export type OrganizationsAthletesBySportQueryResult = Apollo.QueryResult<OrganizationsAthletesBySportQuery, OrganizationsAthletesBySportQueryVariables>;
export const RemovePersonCitizenshipDocument = Apollo.gql`
    mutation RemovePersonCitizenship($id: UUID!, $idItem: UUID!) {
  updateDirPerson(where: {id: $id}, data: {dirCitizenships: {disconnect: {id: $idItem}}}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type RemovePersonCitizenshipMutationFn = Apollo.MutationFunction<RemovePersonCitizenshipMutation, RemovePersonCitizenshipMutationVariables>;

/**
 * __useRemovePersonCitizenshipMutation__
 *
 * To run a mutation, you first call `useRemovePersonCitizenshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePersonCitizenshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePersonCitizenshipMutation, { data, loading, error }] = useRemovePersonCitizenshipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useRemovePersonCitizenshipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePersonCitizenshipMutation, RemovePersonCitizenshipMutationVariables>) {
        return ApolloReactHooks.useMutation<RemovePersonCitizenshipMutation, RemovePersonCitizenshipMutationVariables>(RemovePersonCitizenshipDocument, baseOptions);
      }
export type RemovePersonCitizenshipMutationHookResult = ReturnType<typeof useRemovePersonCitizenshipMutation>;
export type RemovePersonCitizenshipMutationResult = Apollo.MutationResult<RemovePersonCitizenshipMutation>;
export type RemovePersonCitizenshipMutationOptions = Apollo.BaseMutationOptions<RemovePersonCitizenshipMutation, RemovePersonCitizenshipMutationVariables>;
export const CreatePersonCitizenshipDocument = Apollo.gql`
    mutation CreatePersonCitizenship($id: UUID!, $idItem: UUID!) {
  updateDirPerson(where: {id: $id}, data: {dirCitizenships: {connect: {id: $idItem}}}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type CreatePersonCitizenshipMutationFn = Apollo.MutationFunction<CreatePersonCitizenshipMutation, CreatePersonCitizenshipMutationVariables>;

/**
 * __useCreatePersonCitizenshipMutation__
 *
 * To run a mutation, you first call `useCreatePersonCitizenshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonCitizenshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonCitizenshipMutation, { data, loading, error }] = useCreatePersonCitizenshipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useCreatePersonCitizenshipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePersonCitizenshipMutation, CreatePersonCitizenshipMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePersonCitizenshipMutation, CreatePersonCitizenshipMutationVariables>(CreatePersonCitizenshipDocument, baseOptions);
      }
export type CreatePersonCitizenshipMutationHookResult = ReturnType<typeof useCreatePersonCitizenshipMutation>;
export type CreatePersonCitizenshipMutationResult = Apollo.MutationResult<CreatePersonCitizenshipMutation>;
export type CreatePersonCitizenshipMutationOptions = Apollo.BaseMutationOptions<CreatePersonCitizenshipMutation, CreatePersonCitizenshipMutationVariables>;
export const UpdatePersonDocument = Apollo.gql`
    mutation UpdatePerson($id: UUID!, $firstname: String!, $lastname: String!, $patronymic: String, $previousLastname: String, $birthday: DateTime!, $isMale: Boolean!, $regionID: DirRegionUpdateOneInput, $countryID: DirCountryUpdateOneInput, $foreignCityID: DirForeignCityUpdateOneInput, $isRussia: Boolean, $email: String, $residenceCity: String, $confirmStatus: DirPersonConfirmStatusUpdateOneRequiredInput) {
  updateDirPerson(where: {id: $id}, data: {firstname: $firstname, lastname: $lastname, patronymic: $patronymic, previousLastname: $previousLastname, birthday: $birthday, email: $email, isMale: $isMale, dirRegion: $regionID, dirCountry: $countryID, dirForeignCity: $foreignCityID, isRussia: $isRussia, confirmStatus: $confirmStatus, residenceCity: $residenceCity}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type UpdatePersonMutationFn = Apollo.MutationFunction<UpdatePersonMutation, UpdatePersonMutationVariables>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      patronymic: // value for 'patronymic'
 *      previousLastname: // value for 'previousLastname'
 *      birthday: // value for 'birthday'
 *      isMale: // value for 'isMale'
 *      regionID: // value for 'regionID'
 *      countryID: // value for 'countryID'
 *      foreignCityID: // value for 'foreignCityID'
 *      isRussia: // value for 'isRussia'
 *      email: // value for 'email'
 *      residenceCity: // value for 'residenceCity'
 *      confirmStatus: // value for 'confirmStatus'
 *   },
 * });
 */
export function useUpdatePersonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePersonMutation, UpdatePersonMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(UpdatePersonDocument, baseOptions);
      }
export type UpdatePersonMutationHookResult = ReturnType<typeof useUpdatePersonMutation>;
export type UpdatePersonMutationResult = Apollo.MutationResult<UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<UpdatePersonMutation, UpdatePersonMutationVariables>;
export const UpdatePersonSnilsDocument = Apollo.gql`
    mutation UpdatePersonSnils($id: UUID!, $snils: String!) {
  updateDirPerson(where: {id: $id}, data: {snils: $snils}) {
    id
  }
}
    `;
export type UpdatePersonSnilsMutationFn = Apollo.MutationFunction<UpdatePersonSnilsMutation, UpdatePersonSnilsMutationVariables>;

/**
 * __useUpdatePersonSnilsMutation__
 *
 * To run a mutation, you first call `useUpdatePersonSnilsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonSnilsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonSnilsMutation, { data, loading, error }] = useUpdatePersonSnilsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      snils: // value for 'snils'
 *   },
 * });
 */
export function useUpdatePersonSnilsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePersonSnilsMutation, UpdatePersonSnilsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePersonSnilsMutation, UpdatePersonSnilsMutationVariables>(UpdatePersonSnilsDocument, baseOptions);
      }
export type UpdatePersonSnilsMutationHookResult = ReturnType<typeof useUpdatePersonSnilsMutation>;
export type UpdatePersonSnilsMutationResult = Apollo.MutationResult<UpdatePersonSnilsMutation>;
export type UpdatePersonSnilsMutationOptions = Apollo.BaseMutationOptions<UpdatePersonSnilsMutation, UpdatePersonSnilsMutationVariables>;
export const UpdatePersonAdditionalRegionsDocument = Apollo.gql`
    mutation UpdatePersonAdditionalRegions($id: UUID!, $data: DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput!) {
  updateDirPerson(where: {id: $id}, data: {additionalRegions: $data}) {
    id
  }
}
    `;
export type UpdatePersonAdditionalRegionsMutationFn = Apollo.MutationFunction<UpdatePersonAdditionalRegionsMutation, UpdatePersonAdditionalRegionsMutationVariables>;

/**
 * __useUpdatePersonAdditionalRegionsMutation__
 *
 * To run a mutation, you first call `useUpdatePersonAdditionalRegionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonAdditionalRegionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonAdditionalRegionsMutation, { data, loading, error }] = useUpdatePersonAdditionalRegionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePersonAdditionalRegionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePersonAdditionalRegionsMutation, UpdatePersonAdditionalRegionsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePersonAdditionalRegionsMutation, UpdatePersonAdditionalRegionsMutationVariables>(UpdatePersonAdditionalRegionsDocument, baseOptions);
      }
export type UpdatePersonAdditionalRegionsMutationHookResult = ReturnType<typeof useUpdatePersonAdditionalRegionsMutation>;
export type UpdatePersonAdditionalRegionsMutationResult = Apollo.MutationResult<UpdatePersonAdditionalRegionsMutation>;
export type UpdatePersonAdditionalRegionsMutationOptions = Apollo.BaseMutationOptions<UpdatePersonAdditionalRegionsMutation, UpdatePersonAdditionalRegionsMutationVariables>;
export const CreatePersonDocument = Apollo.gql`
    mutation createPerson($firstname: String!, $lastname: String!, $patronymic: String, $previousLastname: String, $birthday: DateTime!, $isMale: Boolean!, $regionID: DirRegionCreateOneInput, $countryID: DirCountryCreateOneInput, $foreignCityID: DirForeignCityCreateOneInput, $confirmStatus: DirPersonConfirmStatusCreateOneInput!, $email: String, $residenceCity: String) {
  createDirPerson(data: {firstname: $firstname, lastname: $lastname, patronymic: $patronymic, previousLastname: $previousLastname, birthday: $birthday, isMale: $isMale, email: $email, dirRegion: $regionID, dirCountry: $countryID, dirForeignCity: $foreignCityID, confirmStatus: $confirmStatus, residenceCity: $residenceCity}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type CreatePersonMutationFn = Apollo.MutationFunction<CreatePersonMutation, CreatePersonMutationVariables>;

/**
 * __useCreatePersonMutation__
 *
 * To run a mutation, you first call `useCreatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonMutation, { data, loading, error }] = useCreatePersonMutation({
 *   variables: {
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      patronymic: // value for 'patronymic'
 *      previousLastname: // value for 'previousLastname'
 *      birthday: // value for 'birthday'
 *      isMale: // value for 'isMale'
 *      regionID: // value for 'regionID'
 *      countryID: // value for 'countryID'
 *      foreignCityID: // value for 'foreignCityID'
 *      confirmStatus: // value for 'confirmStatus'
 *      email: // value for 'email'
 *      residenceCity: // value for 'residenceCity'
 *   },
 * });
 */
export function useCreatePersonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePersonMutation, CreatePersonMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePersonMutation, CreatePersonMutationVariables>(CreatePersonDocument, baseOptions);
      }
export type CreatePersonMutationHookResult = ReturnType<typeof useCreatePersonMutation>;
export type CreatePersonMutationResult = Apollo.MutationResult<CreatePersonMutation>;
export type CreatePersonMutationOptions = Apollo.BaseMutationOptions<CreatePersonMutation, CreatePersonMutationVariables>;
export const UpdatePersonMartialStatusDocument = Apollo.gql`
    mutation UpdatePersonMartialStatus($id: UUID!, $clsMaritalStatus: ClassifierValueUpdateOneInput) {
  updateDirPerson(where: {id: $id}, data: {clsMaritalStatus: $clsMaritalStatus}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type UpdatePersonMartialStatusMutationFn = Apollo.MutationFunction<UpdatePersonMartialStatusMutation, UpdatePersonMartialStatusMutationVariables>;

/**
 * __useUpdatePersonMartialStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMartialStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMartialStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMartialStatusMutation, { data, loading, error }] = useUpdatePersonMartialStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clsMaritalStatus: // value for 'clsMaritalStatus'
 *   },
 * });
 */
export function useUpdatePersonMartialStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePersonMartialStatusMutation, UpdatePersonMartialStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePersonMartialStatusMutation, UpdatePersonMartialStatusMutationVariables>(UpdatePersonMartialStatusDocument, baseOptions);
      }
export type UpdatePersonMartialStatusMutationHookResult = ReturnType<typeof useUpdatePersonMartialStatusMutation>;
export type UpdatePersonMartialStatusMutationResult = Apollo.MutationResult<UpdatePersonMartialStatusMutation>;
export type UpdatePersonMartialStatusMutationOptions = Apollo.BaseMutationOptions<UpdatePersonMartialStatusMutation, UpdatePersonMartialStatusMutationVariables>;
export const RemovePersonPhoneDocument = Apollo.gql`
    mutation RemovePersonPhone($id: UUID!, $date: DateTime) {
  updateDirPersonPhone(where: {id: $id}, data: {deletedAt: $date}) {
    id
    deletedAt
  }
}
    `;
export type RemovePersonPhoneMutationFn = Apollo.MutationFunction<RemovePersonPhoneMutation, RemovePersonPhoneMutationVariables>;

/**
 * __useRemovePersonPhoneMutation__
 *
 * To run a mutation, you first call `useRemovePersonPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePersonPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePersonPhoneMutation, { data, loading, error }] = useRemovePersonPhoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useRemovePersonPhoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePersonPhoneMutation, RemovePersonPhoneMutationVariables>) {
        return ApolloReactHooks.useMutation<RemovePersonPhoneMutation, RemovePersonPhoneMutationVariables>(RemovePersonPhoneDocument, baseOptions);
      }
export type RemovePersonPhoneMutationHookResult = ReturnType<typeof useRemovePersonPhoneMutation>;
export type RemovePersonPhoneMutationResult = Apollo.MutationResult<RemovePersonPhoneMutation>;
export type RemovePersonPhoneMutationOptions = Apollo.BaseMutationOptions<RemovePersonPhoneMutation, RemovePersonPhoneMutationVariables>;
export const AddPersonPhoneDocument = Apollo.gql`
    mutation AddPersonPhone($idPerson: UUID!, $countryCode: String!, $number: String!, $operatorCode: String!, $additionalNumber: String, $clsContactType: UUID!) {
  updateDirPerson(where: {id: $idPerson}, data: {phones: {create: {countryCode: $countryCode, operatorCode: $operatorCode, additionalNumber: $additionalNumber, number: $number, clsContactType: {connect: {id: $clsContactType}}}}}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type AddPersonPhoneMutationFn = Apollo.MutationFunction<AddPersonPhoneMutation, AddPersonPhoneMutationVariables>;

/**
 * __useAddPersonPhoneMutation__
 *
 * To run a mutation, you first call `useAddPersonPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonPhoneMutation, { data, loading, error }] = useAddPersonPhoneMutation({
 *   variables: {
 *      idPerson: // value for 'idPerson'
 *      countryCode: // value for 'countryCode'
 *      number: // value for 'number'
 *      operatorCode: // value for 'operatorCode'
 *      additionalNumber: // value for 'additionalNumber'
 *      clsContactType: // value for 'clsContactType'
 *   },
 * });
 */
export function useAddPersonPhoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPersonPhoneMutation, AddPersonPhoneMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPersonPhoneMutation, AddPersonPhoneMutationVariables>(AddPersonPhoneDocument, baseOptions);
      }
export type AddPersonPhoneMutationHookResult = ReturnType<typeof useAddPersonPhoneMutation>;
export type AddPersonPhoneMutationResult = Apollo.MutationResult<AddPersonPhoneMutation>;
export type AddPersonPhoneMutationOptions = Apollo.BaseMutationOptions<AddPersonPhoneMutation, AddPersonPhoneMutationVariables>;
export const UpdatePersonPhoneDocument = Apollo.gql`
    mutation UpdatePersonPhone($idPerson: UUID!, $id: UUID!, $countryCode: String!, $number: String!, $operatorCode: String!, $additionalNumber: String, $clsContactType: UUID!) {
  updateDirPerson(where: {id: $idPerson}, data: {phones: {update: {where: {id: $id}, data: {countryCode: $countryCode, operatorCode: $operatorCode, additionalNumber: $additionalNumber, number: $number, clsContactType: {connect: {id: $clsContactType}}}}}}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type UpdatePersonPhoneMutationFn = Apollo.MutationFunction<UpdatePersonPhoneMutation, UpdatePersonPhoneMutationVariables>;

/**
 * __useUpdatePersonPhoneMutation__
 *
 * To run a mutation, you first call `useUpdatePersonPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonPhoneMutation, { data, loading, error }] = useUpdatePersonPhoneMutation({
 *   variables: {
 *      idPerson: // value for 'idPerson'
 *      id: // value for 'id'
 *      countryCode: // value for 'countryCode'
 *      number: // value for 'number'
 *      operatorCode: // value for 'operatorCode'
 *      additionalNumber: // value for 'additionalNumber'
 *      clsContactType: // value for 'clsContactType'
 *   },
 * });
 */
export function useUpdatePersonPhoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePersonPhoneMutation, UpdatePersonPhoneMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePersonPhoneMutation, UpdatePersonPhoneMutationVariables>(UpdatePersonPhoneDocument, baseOptions);
      }
export type UpdatePersonPhoneMutationHookResult = ReturnType<typeof useUpdatePersonPhoneMutation>;
export type UpdatePersonPhoneMutationResult = Apollo.MutationResult<UpdatePersonPhoneMutation>;
export type UpdatePersonPhoneMutationOptions = Apollo.BaseMutationOptions<UpdatePersonPhoneMutation, UpdatePersonPhoneMutationVariables>;
export const PersonArchiveDocument = Apollo.gql`
    mutation PersonArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirPerson(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type PersonArchiveMutationFn = Apollo.MutationFunction<PersonArchiveMutation, PersonArchiveMutationVariables>;

/**
 * __usePersonArchiveMutation__
 *
 * To run a mutation, you first call `usePersonArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personArchiveMutation, { data, loading, error }] = usePersonArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function usePersonArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonArchiveMutation, PersonArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonArchiveMutation, PersonArchiveMutationVariables>(PersonArchiveDocument, baseOptions);
      }
export type PersonArchiveMutationHookResult = ReturnType<typeof usePersonArchiveMutation>;
export type PersonArchiveMutationResult = Apollo.MutationResult<PersonArchiveMutation>;
export type PersonArchiveMutationOptions = Apollo.BaseMutationOptions<PersonArchiveMutation, PersonArchiveMutationVariables>;
export const PersonUnArchiveDocument = Apollo.gql`
    mutation PersonUnArchive($id: UUID!) {
  updateDirPerson(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type PersonUnArchiveMutationFn = Apollo.MutationFunction<PersonUnArchiveMutation, PersonUnArchiveMutationVariables>;

/**
 * __usePersonUnArchiveMutation__
 *
 * To run a mutation, you first call `usePersonUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personUnArchiveMutation, { data, loading, error }] = usePersonUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonUnArchiveMutation, PersonUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonUnArchiveMutation, PersonUnArchiveMutationVariables>(PersonUnArchiveDocument, baseOptions);
      }
export type PersonUnArchiveMutationHookResult = ReturnType<typeof usePersonUnArchiveMutation>;
export type PersonUnArchiveMutationResult = Apollo.MutationResult<PersonUnArchiveMutation>;
export type PersonUnArchiveMutationOptions = Apollo.BaseMutationOptions<PersonUnArchiveMutation, PersonUnArchiveMutationVariables>;
export const OpenMergeTaskDocument = Apollo.gql`
    mutation OpenMergeTask($primaryId: UUID!, $duplicateId: UUID!, $comment: String) {
  openMergePersonTask(primary: {id: $primaryId}, duplicate: {id: $duplicateId}, comment: $comment) {
    id
  }
}
    `;
export type OpenMergeTaskMutationFn = Apollo.MutationFunction<OpenMergeTaskMutation, OpenMergeTaskMutationVariables>;

/**
 * __useOpenMergeTaskMutation__
 *
 * To run a mutation, you first call `useOpenMergeTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenMergeTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openMergeTaskMutation, { data, loading, error }] = useOpenMergeTaskMutation({
 *   variables: {
 *      primaryId: // value for 'primaryId'
 *      duplicateId: // value for 'duplicateId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useOpenMergeTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OpenMergeTaskMutation, OpenMergeTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<OpenMergeTaskMutation, OpenMergeTaskMutationVariables>(OpenMergeTaskDocument, baseOptions);
      }
export type OpenMergeTaskMutationHookResult = ReturnType<typeof useOpenMergeTaskMutation>;
export type OpenMergeTaskMutationResult = Apollo.MutationResult<OpenMergeTaskMutation>;
export type OpenMergeTaskMutationOptions = Apollo.BaseMutationOptions<OpenMergeTaskMutation, OpenMergeTaskMutationVariables>;
export const CloseMergeTaskDocument = Apollo.gql`
    mutation CloseMergeTask($taskId: String!, $comment: String) {
  closeMergePersonTask(id: $taskId, comment: $comment) {
    id
  }
}
    `;
export type CloseMergeTaskMutationFn = Apollo.MutationFunction<CloseMergeTaskMutation, CloseMergeTaskMutationVariables>;

/**
 * __useCloseMergeTaskMutation__
 *
 * To run a mutation, you first call `useCloseMergeTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseMergeTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeMergeTaskMutation, { data, loading, error }] = useCloseMergeTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCloseMergeTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CloseMergeTaskMutation, CloseMergeTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<CloseMergeTaskMutation, CloseMergeTaskMutationVariables>(CloseMergeTaskDocument, baseOptions);
      }
export type CloseMergeTaskMutationHookResult = ReturnType<typeof useCloseMergeTaskMutation>;
export type CloseMergeTaskMutationResult = Apollo.MutationResult<CloseMergeTaskMutation>;
export type CloseMergeTaskMutationOptions = Apollo.BaseMutationOptions<CloseMergeTaskMutation, CloseMergeTaskMutationVariables>;
export const CancelMergeTaskDocument = Apollo.gql`
    mutation CancelMergeTask($taskId: String!, $comment: String) {
  cancelMergePersonTask(id: $taskId, comment: $comment) {
    id
  }
}
    `;
export type CancelMergeTaskMutationFn = Apollo.MutationFunction<CancelMergeTaskMutation, CancelMergeTaskMutationVariables>;

/**
 * __useCancelMergeTaskMutation__
 *
 * To run a mutation, you first call `useCancelMergeTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMergeTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMergeTaskMutation, { data, loading, error }] = useCancelMergeTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCancelMergeTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelMergeTaskMutation, CancelMergeTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelMergeTaskMutation, CancelMergeTaskMutationVariables>(CancelMergeTaskDocument, baseOptions);
      }
export type CancelMergeTaskMutationHookResult = ReturnType<typeof useCancelMergeTaskMutation>;
export type CancelMergeTaskMutationResult = Apollo.MutationResult<CancelMergeTaskMutation>;
export type CancelMergeTaskMutationOptions = Apollo.BaseMutationOptions<CancelMergeTaskMutation, CancelMergeTaskMutationVariables>;
export const DeleteMergeTaskDocument = Apollo.gql`
    mutation DeleteMergeTask($taskId: UUID!) {
  deleteDirPersonMergeTask(where: {id: $taskId}) {
    id
  }
}
    `;
export type DeleteMergeTaskMutationFn = Apollo.MutationFunction<DeleteMergeTaskMutation, DeleteMergeTaskMutationVariables>;

/**
 * __useDeleteMergeTaskMutation__
 *
 * To run a mutation, you first call `useDeleteMergeTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMergeTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMergeTaskMutation, { data, loading, error }] = useDeleteMergeTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteMergeTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMergeTaskMutation, DeleteMergeTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMergeTaskMutation, DeleteMergeTaskMutationVariables>(DeleteMergeTaskDocument, baseOptions);
      }
export type DeleteMergeTaskMutationHookResult = ReturnType<typeof useDeleteMergeTaskMutation>;
export type DeleteMergeTaskMutationResult = Apollo.MutationResult<DeleteMergeTaskMutation>;
export type DeleteMergeTaskMutationOptions = Apollo.BaseMutationOptions<DeleteMergeTaskMutation, DeleteMergeTaskMutationVariables>;
export const CreatePersonAddressDocument = Apollo.gql`
    mutation CreatePersonAddress($id: UUID!, $place: String!, $street: String!, $number: String, $house: String!, $type: Int!) {
  updateDirPerson(where: {id: $id}, data: {addresses: {create: {place: $place, street: $street, number: $number, house: $house, type: {connect: {id: $type}}}}}) {
    id
  }
}
    `;
export type CreatePersonAddressMutationFn = Apollo.MutationFunction<CreatePersonAddressMutation, CreatePersonAddressMutationVariables>;

/**
 * __useCreatePersonAddressMutation__
 *
 * To run a mutation, you first call `useCreatePersonAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonAddressMutation, { data, loading, error }] = useCreatePersonAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      place: // value for 'place'
 *      street: // value for 'street'
 *      number: // value for 'number'
 *      house: // value for 'house'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreatePersonAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePersonAddressMutation, CreatePersonAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePersonAddressMutation, CreatePersonAddressMutationVariables>(CreatePersonAddressDocument, baseOptions);
      }
export type CreatePersonAddressMutationHookResult = ReturnType<typeof useCreatePersonAddressMutation>;
export type CreatePersonAddressMutationResult = Apollo.MutationResult<CreatePersonAddressMutation>;
export type CreatePersonAddressMutationOptions = Apollo.BaseMutationOptions<CreatePersonAddressMutation, CreatePersonAddressMutationVariables>;
export const UpdatePersonAddressDocument = Apollo.gql`
    mutation UpdatePersonAddress($id: UUID!, $place: String!, $street: String!, $number: String, $house: String!, $type: Int!, $addressId: UUID!) {
  updateDirPerson(where: {id: $id}, data: {addresses: {update: {where: {id: $addressId}, data: {place: $place, street: $street, number: $number, house: $house, type: {connect: {id: $type}}}}}}) {
    id
  }
}
    `;
export type UpdatePersonAddressMutationFn = Apollo.MutationFunction<UpdatePersonAddressMutation, UpdatePersonAddressMutationVariables>;

/**
 * __useUpdatePersonAddressMutation__
 *
 * To run a mutation, you first call `useUpdatePersonAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonAddressMutation, { data, loading, error }] = useUpdatePersonAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      place: // value for 'place'
 *      street: // value for 'street'
 *      number: // value for 'number'
 *      house: // value for 'house'
 *      type: // value for 'type'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useUpdatePersonAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePersonAddressMutation, UpdatePersonAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePersonAddressMutation, UpdatePersonAddressMutationVariables>(UpdatePersonAddressDocument, baseOptions);
      }
export type UpdatePersonAddressMutationHookResult = ReturnType<typeof useUpdatePersonAddressMutation>;
export type UpdatePersonAddressMutationResult = Apollo.MutationResult<UpdatePersonAddressMutation>;
export type UpdatePersonAddressMutationOptions = Apollo.BaseMutationOptions<UpdatePersonAddressMutation, UpdatePersonAddressMutationVariables>;
export const DeletePersonAddressDocument = Apollo.gql`
    mutation DeletePersonAddress($id: UUID!, $addressId: UUID!) {
  updateDirPerson(where: {id: $id}, data: {addresses: {delete: {id: $addressId}}}) {
    id
  }
}
    `;
export type DeletePersonAddressMutationFn = Apollo.MutationFunction<DeletePersonAddressMutation, DeletePersonAddressMutationVariables>;

/**
 * __useDeletePersonAddressMutation__
 *
 * To run a mutation, you first call `useDeletePersonAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonAddressMutation, { data, loading, error }] = useDeletePersonAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useDeletePersonAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePersonAddressMutation, DeletePersonAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePersonAddressMutation, DeletePersonAddressMutationVariables>(DeletePersonAddressDocument, baseOptions);
      }
export type DeletePersonAddressMutationHookResult = ReturnType<typeof useDeletePersonAddressMutation>;
export type DeletePersonAddressMutationResult = Apollo.MutationResult<DeletePersonAddressMutation>;
export type DeletePersonAddressMutationOptions = Apollo.BaseMutationOptions<DeletePersonAddressMutation, DeletePersonAddressMutationVariables>;
export const UpdatePersonDocDocument = Apollo.gql`
    mutation updatePersonDoc($id: UUID!, $data: DirPersonUpdateInput!) {
  updateDirPerson(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdatePersonDocMutationFn = Apollo.MutationFunction<UpdatePersonDocMutation, UpdatePersonDocMutationVariables>;

/**
 * __useUpdatePersonDocMutation__
 *
 * To run a mutation, you first call `useUpdatePersonDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonDocMutation, { data, loading, error }] = useUpdatePersonDocMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePersonDocMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePersonDocMutation, UpdatePersonDocMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePersonDocMutation, UpdatePersonDocMutationVariables>(UpdatePersonDocDocument, baseOptions);
      }
export type UpdatePersonDocMutationHookResult = ReturnType<typeof useUpdatePersonDocMutation>;
export type UpdatePersonDocMutationResult = Apollo.MutationResult<UpdatePersonDocMutation>;
export type UpdatePersonDocMutationOptions = Apollo.BaseMutationOptions<UpdatePersonDocMutation, UpdatePersonDocMutationVariables>;
export const PersonsCountDocument = Apollo.gql`
    query PersonsCount($filter: DirPersonWhereInput) {
  count: dirPersonsCount(where: $filter)
}
    `;

/**
 * __usePersonsCountQuery__
 *
 * To run a query within a React component, call `usePersonsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePersonsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonsCountQuery, PersonsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonsCountQuery, PersonsCountQueryVariables>(PersonsCountDocument, baseOptions);
      }
export function usePersonsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonsCountQuery, PersonsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonsCountQuery, PersonsCountQueryVariables>(PersonsCountDocument, baseOptions);
        }
export type PersonsCountQueryHookResult = ReturnType<typeof usePersonsCountQuery>;
export type PersonsCountLazyQueryHookResult = ReturnType<typeof usePersonsCountLazyQuery>;
export type PersonsCountQueryResult = Apollo.QueryResult<PersonsCountQuery, PersonsCountQueryVariables>;
export const FindSimiliarPersonDocument = Apollo.gql`
    query FindSimiliarPerson($search: String!, $filter: DirPersonWhereInput, $thershold: Float = 0.7) {
  dirPersonsSimilar(search: $search, where: $filter, first: 10, threshold: $thershold) {
    similarity
    person {
      ...SimilarPerson
    }
  }
}
    ${SimilarPersonFragmentDoc}`;

/**
 * __useFindSimiliarPersonQuery__
 *
 * To run a query within a React component, call `useFindSimiliarPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSimiliarPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSimiliarPersonQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *      thershold: // value for 'thershold'
 *   },
 * });
 */
export function useFindSimiliarPersonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindSimiliarPersonQuery, FindSimiliarPersonQueryVariables>) {
        return ApolloReactHooks.useQuery<FindSimiliarPersonQuery, FindSimiliarPersonQueryVariables>(FindSimiliarPersonDocument, baseOptions);
      }
export function useFindSimiliarPersonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindSimiliarPersonQuery, FindSimiliarPersonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindSimiliarPersonQuery, FindSimiliarPersonQueryVariables>(FindSimiliarPersonDocument, baseOptions);
        }
export type FindSimiliarPersonQueryHookResult = ReturnType<typeof useFindSimiliarPersonQuery>;
export type FindSimiliarPersonLazyQueryHookResult = ReturnType<typeof useFindSimiliarPersonLazyQuery>;
export type FindSimiliarPersonQueryResult = Apollo.QueryResult<FindSimiliarPersonQuery, FindSimiliarPersonQueryVariables>;
export const FindRefereeByEventClsPersonDocument = Apollo.gql`
    query FindRefereeByEventClsPerson($idEvent: UUID!, $clsId: UUID!) {
  dirSportingEvent(where: {id: $idEvent}) {
    id
    referees(where: {clsRefereePosition: {id: $clsId}}) {
      clsRefereePosition {
        id
      }
    }
  }
}
    `;

/**
 * __useFindRefereeByEventClsPersonQuery__
 *
 * To run a query within a React component, call `useFindRefereeByEventClsPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRefereeByEventClsPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRefereeByEventClsPersonQuery({
 *   variables: {
 *      idEvent: // value for 'idEvent'
 *      clsId: // value for 'clsId'
 *   },
 * });
 */
export function useFindRefereeByEventClsPersonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindRefereeByEventClsPersonQuery, FindRefereeByEventClsPersonQueryVariables>) {
        return ApolloReactHooks.useQuery<FindRefereeByEventClsPersonQuery, FindRefereeByEventClsPersonQueryVariables>(FindRefereeByEventClsPersonDocument, baseOptions);
      }
export function useFindRefereeByEventClsPersonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindRefereeByEventClsPersonQuery, FindRefereeByEventClsPersonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindRefereeByEventClsPersonQuery, FindRefereeByEventClsPersonQueryVariables>(FindRefereeByEventClsPersonDocument, baseOptions);
        }
export type FindRefereeByEventClsPersonQueryHookResult = ReturnType<typeof useFindRefereeByEventClsPersonQuery>;
export type FindRefereeByEventClsPersonLazyQueryHookResult = ReturnType<typeof useFindRefereeByEventClsPersonLazyQuery>;
export type FindRefereeByEventClsPersonQueryResult = Apollo.QueryResult<FindRefereeByEventClsPersonQuery, FindRefereeByEventClsPersonQueryVariables>;
export const FindRefereeByEventPersonDocument = Apollo.gql`
    query FindRefereeByEventPerson($idEvent: UUID!, $clsId: UUID!) {
  dirSportingEvent(where: {id: $idEvent}) {
    id
    referees(where: {dirReferee: {id: $clsId}}) {
      clsRefereePosition {
        id
      }
    }
  }
}
    `;

/**
 * __useFindRefereeByEventPersonQuery__
 *
 * To run a query within a React component, call `useFindRefereeByEventPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRefereeByEventPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRefereeByEventPersonQuery({
 *   variables: {
 *      idEvent: // value for 'idEvent'
 *      clsId: // value for 'clsId'
 *   },
 * });
 */
export function useFindRefereeByEventPersonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindRefereeByEventPersonQuery, FindRefereeByEventPersonQueryVariables>) {
        return ApolloReactHooks.useQuery<FindRefereeByEventPersonQuery, FindRefereeByEventPersonQueryVariables>(FindRefereeByEventPersonDocument, baseOptions);
      }
export function useFindRefereeByEventPersonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindRefereeByEventPersonQuery, FindRefereeByEventPersonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindRefereeByEventPersonQuery, FindRefereeByEventPersonQueryVariables>(FindRefereeByEventPersonDocument, baseOptions);
        }
export type FindRefereeByEventPersonQueryHookResult = ReturnType<typeof useFindRefereeByEventPersonQuery>;
export type FindRefereeByEventPersonLazyQueryHookResult = ReturnType<typeof useFindRefereeByEventPersonLazyQuery>;
export type FindRefereeByEventPersonQueryResult = Apollo.QueryResult<FindRefereeByEventPersonQuery, FindRefereeByEventPersonQueryVariables>;
export const FindSimiliarPersonAthletesDocument = Apollo.gql`
    query FindSimiliarPersonAthletes($search: String!, $filter: DirPersonWhereInput, $s_filter: DirPersonWhereInput) {
  _in: dirPersonsSimilar(search: $search, where: $filter, first: 10, threshold: 0.3) {
    similarity
    person {
      ...SimilarPerson
    }
  }
  _out: dirPersonsSimilar(search: $search, where: $s_filter, first: 10, threshold: 0.3) {
    similarity
    person {
      ...SimilarPerson
    }
  }
}
    ${SimilarPersonFragmentDoc}`;

/**
 * __useFindSimiliarPersonAthletesQuery__
 *
 * To run a query within a React component, call `useFindSimiliarPersonAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSimiliarPersonAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSimiliarPersonAthletesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *      s_filter: // value for 's_filter'
 *   },
 * });
 */
export function useFindSimiliarPersonAthletesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindSimiliarPersonAthletesQuery, FindSimiliarPersonAthletesQueryVariables>) {
        return ApolloReactHooks.useQuery<FindSimiliarPersonAthletesQuery, FindSimiliarPersonAthletesQueryVariables>(FindSimiliarPersonAthletesDocument, baseOptions);
      }
export function useFindSimiliarPersonAthletesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindSimiliarPersonAthletesQuery, FindSimiliarPersonAthletesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindSimiliarPersonAthletesQuery, FindSimiliarPersonAthletesQueryVariables>(FindSimiliarPersonAthletesDocument, baseOptions);
        }
export type FindSimiliarPersonAthletesQueryHookResult = ReturnType<typeof useFindSimiliarPersonAthletesQuery>;
export type FindSimiliarPersonAthletesLazyQueryHookResult = ReturnType<typeof useFindSimiliarPersonAthletesLazyQuery>;
export type FindSimiliarPersonAthletesQueryResult = Apollo.QueryResult<FindSimiliarPersonAthletesQuery, FindSimiliarPersonAthletesQueryVariables>;
export const CheckExistEmailDocument = Apollo.gql`
    query CheckExistEmail($email: String!) {
  dirPersons(where: {email: $email}) {
    id
    email
  }
}
    `;

/**
 * __useCheckExistEmailQuery__
 *
 * To run a query within a React component, call `useCheckExistEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckExistEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckExistEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckExistEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckExistEmailQuery, CheckExistEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckExistEmailQuery, CheckExistEmailQueryVariables>(CheckExistEmailDocument, baseOptions);
      }
export function useCheckExistEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckExistEmailQuery, CheckExistEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckExistEmailQuery, CheckExistEmailQueryVariables>(CheckExistEmailDocument, baseOptions);
        }
export type CheckExistEmailQueryHookResult = ReturnType<typeof useCheckExistEmailQuery>;
export type CheckExistEmailLazyQueryHookResult = ReturnType<typeof useCheckExistEmailLazyQuery>;
export type CheckExistEmailQueryResult = Apollo.QueryResult<CheckExistEmailQuery, CheckExistEmailQueryVariables>;
export const PersonsDocument = Apollo.gql`
    query Persons($first: Int!, $skip: Int!, $filter: DirPersonWhereInput) {
  dirPersons(first: $first, skip: $skip, where: $filter) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;

/**
 * __usePersonsQuery__
 *
 * To run a query within a React component, call `usePersonsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePersonsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonsQuery, PersonsQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonsQuery, PersonsQueryVariables>(PersonsDocument, baseOptions);
      }
export function usePersonsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonsQuery, PersonsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonsQuery, PersonsQueryVariables>(PersonsDocument, baseOptions);
        }
export type PersonsQueryHookResult = ReturnType<typeof usePersonsQuery>;
export type PersonsLazyQueryHookResult = ReturnType<typeof usePersonsLazyQuery>;
export type PersonsQueryResult = Apollo.QueryResult<PersonsQuery, PersonsQueryVariables>;
export const PersonByIdDocument = Apollo.gql`
    query PersonById($id: UUID!) {
  dirPerson(where: {id: $id}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;

/**
 * __usePersonByIdQuery__
 *
 * To run a query within a React component, call `usePersonByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonByIdQuery, PersonByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonByIdQuery, PersonByIdQueryVariables>(PersonByIdDocument, baseOptions);
      }
export function usePersonByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonByIdQuery, PersonByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonByIdQuery, PersonByIdQueryVariables>(PersonByIdDocument, baseOptions);
        }
export type PersonByIdQueryHookResult = ReturnType<typeof usePersonByIdQuery>;
export type PersonByIdLazyQueryHookResult = ReturnType<typeof usePersonByIdLazyQuery>;
export type PersonByIdQueryResult = Apollo.QueryResult<PersonByIdQuery, PersonByIdQueryVariables>;
export const MergeTasksDocument = Apollo.gql`
    query MergeTasks($filter: DirPersonMergeTaskWhereInput) {
  dirPersonMergeTasks(where: $filter, orderBy: createdAt_DESC) {
    ...mergeTask
  }
}
    ${MergeTaskFragmentDoc}`;

/**
 * __useMergeTasksQuery__
 *
 * To run a query within a React component, call `useMergeTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMergeTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMergeTasksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMergeTasksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MergeTasksQuery, MergeTasksQueryVariables>) {
        return ApolloReactHooks.useQuery<MergeTasksQuery, MergeTasksQueryVariables>(MergeTasksDocument, baseOptions);
      }
export function useMergeTasksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MergeTasksQuery, MergeTasksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MergeTasksQuery, MergeTasksQueryVariables>(MergeTasksDocument, baseOptions);
        }
export type MergeTasksQueryHookResult = ReturnType<typeof useMergeTasksQuery>;
export type MergeTasksLazyQueryHookResult = ReturnType<typeof useMergeTasksLazyQuery>;
export type MergeTasksQueryResult = Apollo.QueryResult<MergeTasksQuery, MergeTasksQueryVariables>;
export const TaskStatusesDocument = Apollo.gql`
    query TaskStatuses {
  taskStatuses {
    value: id
    name
    label: description
  }
}
    `;

/**
 * __useTaskStatusesQuery__
 *
 * To run a query within a React component, call `useTaskStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskStatusesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TaskStatusesQuery, TaskStatusesQueryVariables>) {
        return ApolloReactHooks.useQuery<TaskStatusesQuery, TaskStatusesQueryVariables>(TaskStatusesDocument, baseOptions);
      }
export function useTaskStatusesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskStatusesQuery, TaskStatusesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TaskStatusesQuery, TaskStatusesQueryVariables>(TaskStatusesDocument, baseOptions);
        }
export type TaskStatusesQueryHookResult = ReturnType<typeof useTaskStatusesQuery>;
export type TaskStatusesLazyQueryHookResult = ReturnType<typeof useTaskStatusesLazyQuery>;
export type TaskStatusesQueryResult = Apollo.QueryResult<TaskStatusesQuery, TaskStatusesQueryVariables>;
export const MergeTasksCountDocument = Apollo.gql`
    query MergeTasksCount($filter: DirPersonMergeTaskWhereInput) {
  count: dirPersonMergeTasksCount(where: $filter)
}
    `;

/**
 * __useMergeTasksCountQuery__
 *
 * To run a query within a React component, call `useMergeTasksCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMergeTasksCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMergeTasksCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMergeTasksCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MergeTasksCountQuery, MergeTasksCountQueryVariables>) {
        return ApolloReactHooks.useQuery<MergeTasksCountQuery, MergeTasksCountQueryVariables>(MergeTasksCountDocument, baseOptions);
      }
export function useMergeTasksCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MergeTasksCountQuery, MergeTasksCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MergeTasksCountQuery, MergeTasksCountQueryVariables>(MergeTasksCountDocument, baseOptions);
        }
export type MergeTasksCountQueryHookResult = ReturnType<typeof useMergeTasksCountQuery>;
export type MergeTasksCountLazyQueryHookResult = ReturnType<typeof useMergeTasksCountLazyQuery>;
export type MergeTasksCountQueryResult = Apollo.QueryResult<MergeTasksCountQuery, MergeTasksCountQueryVariables>;
export const PersonAddressesDocument = Apollo.gql`
    query PersonAddresses($filter: DirPersonAddressWhereInput) {
  dirPersonAddresses(where: $filter, orderBy: createdAt_ASC) {
    id
    place
    street
    house
    number
    type {
      value: id
      label: name
    }
    comment
  }
}
    `;

/**
 * __usePersonAddressesQuery__
 *
 * To run a query within a React component, call `usePersonAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonAddressesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePersonAddressesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonAddressesQuery, PersonAddressesQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonAddressesQuery, PersonAddressesQueryVariables>(PersonAddressesDocument, baseOptions);
      }
export function usePersonAddressesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonAddressesQuery, PersonAddressesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonAddressesQuery, PersonAddressesQueryVariables>(PersonAddressesDocument, baseOptions);
        }
export type PersonAddressesQueryHookResult = ReturnType<typeof usePersonAddressesQuery>;
export type PersonAddressesLazyQueryHookResult = ReturnType<typeof usePersonAddressesLazyQuery>;
export type PersonAddressesQueryResult = Apollo.QueryResult<PersonAddressesQuery, PersonAddressesQueryVariables>;
export const PhonesLookUpDocument = Apollo.gql`
    query PhonesLookUp($countryCode: String, $operatorCode: String, $number: String) {
  dirPersonPhones(where: {countryCode: $countryCode, operatorCode: $operatorCode, number: $number}) {
    id
  }
}
    `;

/**
 * __usePhonesLookUpQuery__
 *
 * To run a query within a React component, call `usePhonesLookUpQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhonesLookUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhonesLookUpQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *      operatorCode: // value for 'operatorCode'
 *      number: // value for 'number'
 *   },
 * });
 */
export function usePhonesLookUpQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PhonesLookUpQuery, PhonesLookUpQueryVariables>) {
        return ApolloReactHooks.useQuery<PhonesLookUpQuery, PhonesLookUpQueryVariables>(PhonesLookUpDocument, baseOptions);
      }
export function usePhonesLookUpLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PhonesLookUpQuery, PhonesLookUpQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PhonesLookUpQuery, PhonesLookUpQueryVariables>(PhonesLookUpDocument, baseOptions);
        }
export type PhonesLookUpQueryHookResult = ReturnType<typeof usePhonesLookUpQuery>;
export type PhonesLookUpLazyQueryHookResult = ReturnType<typeof usePhonesLookUpLazyQuery>;
export type PhonesLookUpQueryResult = Apollo.QueryResult<PhonesLookUpQuery, PhonesLookUpQueryVariables>;
export const PersonsSimilarDocument = Apollo.gql`
    query PersonsSimilar($name: String!, $first: Int!, $skip: Int!, $filter: DirPersonWhereInput) {
  dirPersonsSimilar(search: $name, where: $filter, first: $first, skip: $skip, threshold: 0.3) {
    similarity
    person {
      ...PersonFR
    }
  }
}
    ${PersonFrFragmentDoc}`;

/**
 * __usePersonsSimilarQuery__
 *
 * To run a query within a React component, call `usePersonsSimilarQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonsSimilarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonsSimilarQuery({
 *   variables: {
 *      name: // value for 'name'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePersonsSimilarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonsSimilarQuery, PersonsSimilarQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonsSimilarQuery, PersonsSimilarQueryVariables>(PersonsSimilarDocument, baseOptions);
      }
export function usePersonsSimilarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonsSimilarQuery, PersonsSimilarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonsSimilarQuery, PersonsSimilarQueryVariables>(PersonsSimilarDocument, baseOptions);
        }
export type PersonsSimilarQueryHookResult = ReturnType<typeof usePersonsSimilarQuery>;
export type PersonsSimilarLazyQueryHookResult = ReturnType<typeof usePersonsSimilarLazyQuery>;
export type PersonsSimilarQueryResult = Apollo.QueryResult<PersonsSimilarQuery, PersonsSimilarQueryVariables>;
export const PersonSimilarCountDocument = Apollo.gql`
    query PersonSimilarCount($name: String!, $filter: DirPersonWhereInput) {
  dirPersonsSimilar(search: $name, where: $filter, threshold: 0.3) {
    similarity
    person {
      id
    }
  }
}
    `;

/**
 * __usePersonSimilarCountQuery__
 *
 * To run a query within a React component, call `usePersonSimilarCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonSimilarCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonSimilarCountQuery({
 *   variables: {
 *      name: // value for 'name'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePersonSimilarCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonSimilarCountQuery, PersonSimilarCountQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonSimilarCountQuery, PersonSimilarCountQueryVariables>(PersonSimilarCountDocument, baseOptions);
      }
export function usePersonSimilarCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonSimilarCountQuery, PersonSimilarCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonSimilarCountQuery, PersonSimilarCountQueryVariables>(PersonSimilarCountDocument, baseOptions);
        }
export type PersonSimilarCountQueryHookResult = ReturnType<typeof usePersonSimilarCountQuery>;
export type PersonSimilarCountLazyQueryHookResult = ReturnType<typeof usePersonSimilarCountLazyQuery>;
export type PersonSimilarCountQueryResult = Apollo.QueryResult<PersonSimilarCountQuery, PersonSimilarCountQueryVariables>;
export const PersonLookupDocument = Apollo.gql`
    query PersonLookup($search: String!, $filter: DirPersonWhereInput, $threshold: Float!) {
  dirPersonsSimilar(search: $search, where: $filter, first: 10, threshold: $threshold) {
    similarity
    person {
      ...PersonsLookup
    }
  }
}
    ${PersonsLookupFragmentDoc}`;

/**
 * __usePersonLookupQuery__
 *
 * To run a query within a React component, call `usePersonLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *      threshold: // value for 'threshold'
 *   },
 * });
 */
export function usePersonLookupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonLookupQuery, PersonLookupQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonLookupQuery, PersonLookupQueryVariables>(PersonLookupDocument, baseOptions);
      }
export function usePersonLookupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonLookupQuery, PersonLookupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonLookupQuery, PersonLookupQueryVariables>(PersonLookupDocument, baseOptions);
        }
export type PersonLookupQueryHookResult = ReturnType<typeof usePersonLookupQuery>;
export type PersonLookupLazyQueryHookResult = ReturnType<typeof usePersonLookupLazyQuery>;
export type PersonLookupQueryResult = Apollo.QueryResult<PersonLookupQuery, PersonLookupQueryVariables>;
export const PersonPDocsDocument = Apollo.gql`
    query PersonPDocs($id: UUID!) {
  dirPerson(where: {id: $id}) {
    id
    doc1 {
      name
      path
    }
    doc2 {
      name
      path
    }
    doc3 {
      name
      path
    }
  }
}
    `;

/**
 * __usePersonPDocsQuery__
 *
 * To run a query within a React component, call `usePersonPDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonPDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonPDocsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonPDocsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonPDocsQuery, PersonPDocsQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonPDocsQuery, PersonPDocsQueryVariables>(PersonPDocsDocument, baseOptions);
      }
export function usePersonPDocsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonPDocsQuery, PersonPDocsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonPDocsQuery, PersonPDocsQueryVariables>(PersonPDocsDocument, baseOptions);
        }
export type PersonPDocsQueryHookResult = ReturnType<typeof usePersonPDocsQuery>;
export type PersonPDocsLazyQueryHookResult = ReturnType<typeof usePersonPDocsLazyQuery>;
export type PersonPDocsQueryResult = Apollo.QueryResult<PersonPDocsQuery, PersonPDocsQueryVariables>;
export const UpdateRefereeDocument = Apollo.gql`
    mutation UpdateReferee($id: UUID!, $idPerson: UUID!) {
  updateDirReferee(where: {id: $id}, data: {dirPerson: {connect: {id: $idPerson}}}) {
    id
  }
}
    `;
export type UpdateRefereeMutationFn = Apollo.MutationFunction<UpdateRefereeMutation, UpdateRefereeMutationVariables>;

/**
 * __useUpdateRefereeMutation__
 *
 * To run a mutation, you first call `useUpdateRefereeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRefereeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRefereeMutation, { data, loading, error }] = useUpdateRefereeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idPerson: // value for 'idPerson'
 *   },
 * });
 */
export function useUpdateRefereeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRefereeMutation, UpdateRefereeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRefereeMutation, UpdateRefereeMutationVariables>(UpdateRefereeDocument, baseOptions);
      }
export type UpdateRefereeMutationHookResult = ReturnType<typeof useUpdateRefereeMutation>;
export type UpdateRefereeMutationResult = Apollo.MutationResult<UpdateRefereeMutation>;
export type UpdateRefereeMutationOptions = Apollo.BaseMutationOptions<UpdateRefereeMutation, UpdateRefereeMutationVariables>;
export const CreateRefereeDocument = Apollo.gql`
    mutation CreateReferee($idPerson: UUID!) {
  createDirReferee(data: {dirPerson: {connect: {id: $idPerson}}}) {
    id
  }
}
    `;
export type CreateRefereeMutationFn = Apollo.MutationFunction<CreateRefereeMutation, CreateRefereeMutationVariables>;

/**
 * __useCreateRefereeMutation__
 *
 * To run a mutation, you first call `useCreateRefereeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRefereeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRefereeMutation, { data, loading, error }] = useCreateRefereeMutation({
 *   variables: {
 *      idPerson: // value for 'idPerson'
 *   },
 * });
 */
export function useCreateRefereeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRefereeMutation, CreateRefereeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRefereeMutation, CreateRefereeMutationVariables>(CreateRefereeDocument, baseOptions);
      }
export type CreateRefereeMutationHookResult = ReturnType<typeof useCreateRefereeMutation>;
export type CreateRefereeMutationResult = Apollo.MutationResult<CreateRefereeMutation>;
export type CreateRefereeMutationOptions = Apollo.BaseMutationOptions<CreateRefereeMutation, CreateRefereeMutationVariables>;
export const UpdateRefereeSportDocument = Apollo.gql`
    mutation UpdateRefereeSport($id: UUID!, $data: DirRefereeSportUpdateInput!) {
  updateDirRefereeSport(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdateRefereeSportMutationFn = Apollo.MutationFunction<UpdateRefereeSportMutation, UpdateRefereeSportMutationVariables>;

/**
 * __useUpdateRefereeSportMutation__
 *
 * To run a mutation, you first call `useUpdateRefereeSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRefereeSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRefereeSportMutation, { data, loading, error }] = useUpdateRefereeSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRefereeSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRefereeSportMutation, UpdateRefereeSportMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRefereeSportMutation, UpdateRefereeSportMutationVariables>(UpdateRefereeSportDocument, baseOptions);
      }
export type UpdateRefereeSportMutationHookResult = ReturnType<typeof useUpdateRefereeSportMutation>;
export type UpdateRefereeSportMutationResult = Apollo.MutationResult<UpdateRefereeSportMutation>;
export type UpdateRefereeSportMutationOptions = Apollo.BaseMutationOptions<UpdateRefereeSportMutation, UpdateRefereeSportMutationVariables>;
export const CreateRefereeSportDocument = Apollo.gql`
    mutation CreateRefereeSport($id: UUID!, $sportId: UUID!) {
  updateDirReferee(where: {id: $id}, data: {sports: {create: {dirSport: {connect: {id: $sportId}}}}}) {
    id
  }
}
    `;
export type CreateRefereeSportMutationFn = Apollo.MutationFunction<CreateRefereeSportMutation, CreateRefereeSportMutationVariables>;

/**
 * __useCreateRefereeSportMutation__
 *
 * To run a mutation, you first call `useCreateRefereeSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRefereeSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRefereeSportMutation, { data, loading, error }] = useCreateRefereeSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useCreateRefereeSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRefereeSportMutation, CreateRefereeSportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRefereeSportMutation, CreateRefereeSportMutationVariables>(CreateRefereeSportDocument, baseOptions);
      }
export type CreateRefereeSportMutationHookResult = ReturnType<typeof useCreateRefereeSportMutation>;
export type CreateRefereeSportMutationResult = Apollo.MutationResult<CreateRefereeSportMutation>;
export type CreateRefereeSportMutationOptions = Apollo.BaseMutationOptions<CreateRefereeSportMutation, CreateRefereeSportMutationVariables>;
export const RefereeSportArchiveDocument = Apollo.gql`
    mutation RefereeSportArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirRefereeSport(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type RefereeSportArchiveMutationFn = Apollo.MutationFunction<RefereeSportArchiveMutation, RefereeSportArchiveMutationVariables>;

/**
 * __useRefereeSportArchiveMutation__
 *
 * To run a mutation, you first call `useRefereeSportArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefereeSportArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refereeSportArchiveMutation, { data, loading, error }] = useRefereeSportArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useRefereeSportArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefereeSportArchiveMutation, RefereeSportArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<RefereeSportArchiveMutation, RefereeSportArchiveMutationVariables>(RefereeSportArchiveDocument, baseOptions);
      }
export type RefereeSportArchiveMutationHookResult = ReturnType<typeof useRefereeSportArchiveMutation>;
export type RefereeSportArchiveMutationResult = Apollo.MutationResult<RefereeSportArchiveMutation>;
export type RefereeSportArchiveMutationOptions = Apollo.BaseMutationOptions<RefereeSportArchiveMutation, RefereeSportArchiveMutationVariables>;
export const RefereeSportUnArchiveDocument = Apollo.gql`
    mutation RefereeSportUnArchive($id: UUID!) {
  updateDirRefereeSport(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type RefereeSportUnArchiveMutationFn = Apollo.MutationFunction<RefereeSportUnArchiveMutation, RefereeSportUnArchiveMutationVariables>;

/**
 * __useRefereeSportUnArchiveMutation__
 *
 * To run a mutation, you first call `useRefereeSportUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefereeSportUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refereeSportUnArchiveMutation, { data, loading, error }] = useRefereeSportUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefereeSportUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefereeSportUnArchiveMutation, RefereeSportUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<RefereeSportUnArchiveMutation, RefereeSportUnArchiveMutationVariables>(RefereeSportUnArchiveDocument, baseOptions);
      }
export type RefereeSportUnArchiveMutationHookResult = ReturnType<typeof useRefereeSportUnArchiveMutation>;
export type RefereeSportUnArchiveMutationResult = Apollo.MutationResult<RefereeSportUnArchiveMutation>;
export type RefereeSportUnArchiveMutationOptions = Apollo.BaseMutationOptions<RefereeSportUnArchiveMutation, RefereeSportUnArchiveMutationVariables>;
export const UpdateRefereeBankInfoDocument = Apollo.gql`
    mutation UpdateRefereeBankInfo($id: UUID!, $fileId: UUID!) {
  updateDirReferee(where: {id: $id}, data: {dirPerson: {update: {bankRequisites: {connect: {id: $fileId}}}}}) {
    id
  }
}
    `;
export type UpdateRefereeBankInfoMutationFn = Apollo.MutationFunction<UpdateRefereeBankInfoMutation, UpdateRefereeBankInfoMutationVariables>;

/**
 * __useUpdateRefereeBankInfoMutation__
 *
 * To run a mutation, you first call `useUpdateRefereeBankInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRefereeBankInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRefereeBankInfoMutation, { data, loading, error }] = useUpdateRefereeBankInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useUpdateRefereeBankInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRefereeBankInfoMutation, UpdateRefereeBankInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRefereeBankInfoMutation, UpdateRefereeBankInfoMutationVariables>(UpdateRefereeBankInfoDocument, baseOptions);
      }
export type UpdateRefereeBankInfoMutationHookResult = ReturnType<typeof useUpdateRefereeBankInfoMutation>;
export type UpdateRefereeBankInfoMutationResult = Apollo.MutationResult<UpdateRefereeBankInfoMutation>;
export type UpdateRefereeBankInfoMutationOptions = Apollo.BaseMutationOptions<UpdateRefereeBankInfoMutation, UpdateRefereeBankInfoMutationVariables>;
export const DeleteRefereeBankInfoDocument = Apollo.gql`
    mutation DeleteRefereeBankInfo($id: UUID!) {
  updateDirReferee(where: {id: $id}, data: {dirPerson: {update: {bankRequisites: {disconnect: true}}}}) {
    id
  }
}
    `;
export type DeleteRefereeBankInfoMutationFn = Apollo.MutationFunction<DeleteRefereeBankInfoMutation, DeleteRefereeBankInfoMutationVariables>;

/**
 * __useDeleteRefereeBankInfoMutation__
 *
 * To run a mutation, you first call `useDeleteRefereeBankInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRefereeBankInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRefereeBankInfoMutation, { data, loading, error }] = useDeleteRefereeBankInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRefereeBankInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRefereeBankInfoMutation, DeleteRefereeBankInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRefereeBankInfoMutation, DeleteRefereeBankInfoMutationVariables>(DeleteRefereeBankInfoDocument, baseOptions);
      }
export type DeleteRefereeBankInfoMutationHookResult = ReturnType<typeof useDeleteRefereeBankInfoMutation>;
export type DeleteRefereeBankInfoMutationResult = Apollo.MutationResult<DeleteRefereeBankInfoMutation>;
export type DeleteRefereeBankInfoMutationOptions = Apollo.BaseMutationOptions<DeleteRefereeBankInfoMutation, DeleteRefereeBankInfoMutationVariables>;
export const RefereesDocument = Apollo.gql`
    query Referees($first: Int!, $skip: Int!, $filter: DirRefereeWhereInput) {
  dirReferees(first: $first, skip: $skip, where: $filter) {
    ...Referee
  }
}
    ${RefereeFragmentDoc}`;

/**
 * __useRefereesQuery__
 *
 * To run a query within a React component, call `useRefereesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRefereesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefereesQuery, RefereesQueryVariables>) {
        return ApolloReactHooks.useQuery<RefereesQuery, RefereesQueryVariables>(RefereesDocument, baseOptions);
      }
export function useRefereesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereesQuery, RefereesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefereesQuery, RefereesQueryVariables>(RefereesDocument, baseOptions);
        }
export type RefereesQueryHookResult = ReturnType<typeof useRefereesQuery>;
export type RefereesLazyQueryHookResult = ReturnType<typeof useRefereesLazyQuery>;
export type RefereesQueryResult = Apollo.QueryResult<RefereesQuery, RefereesQueryVariables>;
export const RefereesCountDocument = Apollo.gql`
    query RefereesCount($filter: DirRefereeWhereInput) {
  count: dirRefereesCount(where: $filter)
}
    `;

/**
 * __useRefereesCountQuery__
 *
 * To run a query within a React component, call `useRefereesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereesCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRefereesCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefereesCountQuery, RefereesCountQueryVariables>) {
        return ApolloReactHooks.useQuery<RefereesCountQuery, RefereesCountQueryVariables>(RefereesCountDocument, baseOptions);
      }
export function useRefereesCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereesCountQuery, RefereesCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefereesCountQuery, RefereesCountQueryVariables>(RefereesCountDocument, baseOptions);
        }
export type RefereesCountQueryHookResult = ReturnType<typeof useRefereesCountQuery>;
export type RefereesCountLazyQueryHookResult = ReturnType<typeof useRefereesCountLazyQuery>;
export type RefereesCountQueryResult = Apollo.QueryResult<RefereesCountQuery, RefereesCountQueryVariables>;
export const RefereeByIdDocument = Apollo.gql`
    query RefereeById($id: UUID!) {
  dirReferee(where: {id: $id}) {
    ...Referee
  }
}
    ${RefereeFragmentDoc}`;

/**
 * __useRefereeByIdQuery__
 *
 * To run a query within a React component, call `useRefereeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefereeByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefereeByIdQuery, RefereeByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<RefereeByIdQuery, RefereeByIdQueryVariables>(RefereeByIdDocument, baseOptions);
      }
export function useRefereeByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereeByIdQuery, RefereeByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefereeByIdQuery, RefereeByIdQueryVariables>(RefereeByIdDocument, baseOptions);
        }
export type RefereeByIdQueryHookResult = ReturnType<typeof useRefereeByIdQuery>;
export type RefereeByIdLazyQueryHookResult = ReturnType<typeof useRefereeByIdLazyQuery>;
export type RefereeByIdQueryResult = Apollo.QueryResult<RefereeByIdQuery, RefereeByIdQueryVariables>;
export const RefereeBankInfoDocument = Apollo.gql`
    query RefereeBankInfo($id: UUID!) {
  dirReferee(where: {id: $id}) {
    dirPerson {
      id
      bankRequisites {
        id
        path
        name
      }
    }
  }
}
    `;

/**
 * __useRefereeBankInfoQuery__
 *
 * To run a query within a React component, call `useRefereeBankInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereeBankInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereeBankInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefereeBankInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefereeBankInfoQuery, RefereeBankInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<RefereeBankInfoQuery, RefereeBankInfoQueryVariables>(RefereeBankInfoDocument, baseOptions);
      }
export function useRefereeBankInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereeBankInfoQuery, RefereeBankInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefereeBankInfoQuery, RefereeBankInfoQueryVariables>(RefereeBankInfoDocument, baseOptions);
        }
export type RefereeBankInfoQueryHookResult = ReturnType<typeof useRefereeBankInfoQuery>;
export type RefereeBankInfoLazyQueryHookResult = ReturnType<typeof useRefereeBankInfoLazyQuery>;
export type RefereeBankInfoQueryResult = Apollo.QueryResult<RefereeBankInfoQuery, RefereeBankInfoQueryVariables>;
export const RefereesSimilarDocument = Apollo.gql`
    query RefereesSimilar($name: String!, $first: Int!, $skip: Int!, $filter: DirRefereeWhereInput) {
  dirPersonsSimilar(search: $name, where: {refereeProfile: $filter}, first: $first, skip: $skip, threshold: 0.3) {
    similarity
    person {
      id
      refereeProfile {
        ...Referee
      }
    }
  }
}
    ${RefereeFragmentDoc}`;

/**
 * __useRefereesSimilarQuery__
 *
 * To run a query within a React component, call `useRefereesSimilarQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereesSimilarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereesSimilarQuery({
 *   variables: {
 *      name: // value for 'name'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRefereesSimilarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefereesSimilarQuery, RefereesSimilarQueryVariables>) {
        return ApolloReactHooks.useQuery<RefereesSimilarQuery, RefereesSimilarQueryVariables>(RefereesSimilarDocument, baseOptions);
      }
export function useRefereesSimilarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereesSimilarQuery, RefereesSimilarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefereesSimilarQuery, RefereesSimilarQueryVariables>(RefereesSimilarDocument, baseOptions);
        }
export type RefereesSimilarQueryHookResult = ReturnType<typeof useRefereesSimilarQuery>;
export type RefereesSimilarLazyQueryHookResult = ReturnType<typeof useRefereesSimilarLazyQuery>;
export type RefereesSimilarQueryResult = Apollo.QueryResult<RefereesSimilarQuery, RefereesSimilarQueryVariables>;
export const RefereesSimilarCountDocument = Apollo.gql`
    query RefereesSimilarCount($name: String!, $filter: DirRefereeWhereInput) {
  dirPersonsSimilar(search: $name, where: {refereeProfile: $filter}, threshold: 0.3) {
    similarity
    person {
      id
    }
  }
}
    `;

/**
 * __useRefereesSimilarCountQuery__
 *
 * To run a query within a React component, call `useRefereesSimilarCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereesSimilarCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereesSimilarCountQuery({
 *   variables: {
 *      name: // value for 'name'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRefereesSimilarCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefereesSimilarCountQuery, RefereesSimilarCountQueryVariables>) {
        return ApolloReactHooks.useQuery<RefereesSimilarCountQuery, RefereesSimilarCountQueryVariables>(RefereesSimilarCountDocument, baseOptions);
      }
export function useRefereesSimilarCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereesSimilarCountQuery, RefereesSimilarCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefereesSimilarCountQuery, RefereesSimilarCountQueryVariables>(RefereesSimilarCountDocument, baseOptions);
        }
export type RefereesSimilarCountQueryHookResult = ReturnType<typeof useRefereesSimilarCountQuery>;
export type RefereesSimilarCountLazyQueryHookResult = ReturnType<typeof useRefereesSimilarCountLazyQuery>;
export type RefereesSimilarCountQueryResult = Apollo.QueryResult<RefereesSimilarCountQuery, RefereesSimilarCountQueryVariables>;
export const RegionArchiveDocument = Apollo.gql`
    mutation RegionArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirRegion(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type RegionArchiveMutationFn = Apollo.MutationFunction<RegionArchiveMutation, RegionArchiveMutationVariables>;

/**
 * __useRegionArchiveMutation__
 *
 * To run a mutation, you first call `useRegionArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegionArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regionArchiveMutation, { data, loading, error }] = useRegionArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useRegionArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegionArchiveMutation, RegionArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<RegionArchiveMutation, RegionArchiveMutationVariables>(RegionArchiveDocument, baseOptions);
      }
export type RegionArchiveMutationHookResult = ReturnType<typeof useRegionArchiveMutation>;
export type RegionArchiveMutationResult = Apollo.MutationResult<RegionArchiveMutation>;
export type RegionArchiveMutationOptions = Apollo.BaseMutationOptions<RegionArchiveMutation, RegionArchiveMutationVariables>;
export const RegionUnArchiveDocument = Apollo.gql`
    mutation RegionUnArchive($id: UUID!) {
  updateDirRegion(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type RegionUnArchiveMutationFn = Apollo.MutationFunction<RegionUnArchiveMutation, RegionUnArchiveMutationVariables>;

/**
 * __useRegionUnArchiveMutation__
 *
 * To run a mutation, you first call `useRegionUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegionUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regionUnArchiveMutation, { data, loading, error }] = useRegionUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegionUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegionUnArchiveMutation, RegionUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<RegionUnArchiveMutation, RegionUnArchiveMutationVariables>(RegionUnArchiveDocument, baseOptions);
      }
export type RegionUnArchiveMutationHookResult = ReturnType<typeof useRegionUnArchiveMutation>;
export type RegionUnArchiveMutationResult = Apollo.MutationResult<RegionUnArchiveMutation>;
export type RegionUnArchiveMutationOptions = Apollo.BaseMutationOptions<RegionUnArchiveMutation, RegionUnArchiveMutationVariables>;
export const RegionUpdateDocument = Apollo.gql`
    mutation RegionUpdate($id: UUID!, $fullName: String!, $shortName: String!, $code: String!, $roivEmail: String, $regionId: DirFederalDistrictUpdateOneRequiredWithoutDirRegionsInput!) {
  updateDirRegion(where: {id: $id}, data: {fullName: $fullName, shortName: $shortName, code: $code, dirFederalDistrict: $regionId, roivOfficialEmail: $roivEmail}) {
    ...Region
  }
}
    ${RegionFragmentDoc}`;
export type RegionUpdateMutationFn = Apollo.MutationFunction<RegionUpdateMutation, RegionUpdateMutationVariables>;

/**
 * __useRegionUpdateMutation__
 *
 * To run a mutation, you first call `useRegionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regionUpdateMutation, { data, loading, error }] = useRegionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      code: // value for 'code'
 *      roivEmail: // value for 'roivEmail'
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useRegionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegionUpdateMutation, RegionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RegionUpdateMutation, RegionUpdateMutationVariables>(RegionUpdateDocument, baseOptions);
      }
export type RegionUpdateMutationHookResult = ReturnType<typeof useRegionUpdateMutation>;
export type RegionUpdateMutationResult = Apollo.MutationResult<RegionUpdateMutation>;
export type RegionUpdateMutationOptions = Apollo.BaseMutationOptions<RegionUpdateMutation, RegionUpdateMutationVariables>;
export const RegionCreateDocument = Apollo.gql`
    mutation RegionCreate($fullName: String!, $shortName: String!, $code: String!, $regionId: DirFederalDistrictCreateOneWithoutDirRegionsInput!, $roivEmail: String) {
  createDirRegion(data: {fullName: $fullName, shortName: $shortName, code: $code, dirFederalDistrict: $regionId, roivOfficialEmail: $roivEmail}) {
    ...Region
  }
}
    ${RegionFragmentDoc}`;
export type RegionCreateMutationFn = Apollo.MutationFunction<RegionCreateMutation, RegionCreateMutationVariables>;

/**
 * __useRegionCreateMutation__
 *
 * To run a mutation, you first call `useRegionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regionCreateMutation, { data, loading, error }] = useRegionCreateMutation({
 *   variables: {
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      code: // value for 'code'
 *      regionId: // value for 'regionId'
 *      roivEmail: // value for 'roivEmail'
 *   },
 * });
 */
export function useRegionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegionCreateMutation, RegionCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<RegionCreateMutation, RegionCreateMutationVariables>(RegionCreateDocument, baseOptions);
      }
export type RegionCreateMutationHookResult = ReturnType<typeof useRegionCreateMutation>;
export type RegionCreateMutationResult = Apollo.MutationResult<RegionCreateMutation>;
export type RegionCreateMutationOptions = Apollo.BaseMutationOptions<RegionCreateMutation, RegionCreateMutationVariables>;
export const RegionByIdDocument = Apollo.gql`
    query RegionById($id: UUID!) {
  dirRegion(where: {id: $id}) {
    ...Region
  }
}
    ${RegionFragmentDoc}`;

/**
 * __useRegionByIdQuery__
 *
 * To run a query within a React component, call `useRegionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegionByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegionByIdQuery, RegionByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<RegionByIdQuery, RegionByIdQueryVariables>(RegionByIdDocument, baseOptions);
      }
export function useRegionByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegionByIdQuery, RegionByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegionByIdQuery, RegionByIdQueryVariables>(RegionByIdDocument, baseOptions);
        }
export type RegionByIdQueryHookResult = ReturnType<typeof useRegionByIdQuery>;
export type RegionByIdLazyQueryHookResult = ReturnType<typeof useRegionByIdLazyQuery>;
export type RegionByIdQueryResult = Apollo.QueryResult<RegionByIdQuery, RegionByIdQueryVariables>;
export const ListOrganizationOfOivDocument = Apollo.gql`
    query ListOrganizationOfOiv($id: UUID!) {
  dirOrganizations(where: {clsOrganizationCategories_some: {registryNumber: 100002468}, dirRegion: {id: $id}}) {
    value: id
    label: name
  }
}
    `;

/**
 * __useListOrganizationOfOivQuery__
 *
 * To run a query within a React component, call `useListOrganizationOfOivQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationOfOivQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationOfOivQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListOrganizationOfOivQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListOrganizationOfOivQuery, ListOrganizationOfOivQueryVariables>) {
        return ApolloReactHooks.useQuery<ListOrganizationOfOivQuery, ListOrganizationOfOivQueryVariables>(ListOrganizationOfOivDocument, baseOptions);
      }
export function useListOrganizationOfOivLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListOrganizationOfOivQuery, ListOrganizationOfOivQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListOrganizationOfOivQuery, ListOrganizationOfOivQueryVariables>(ListOrganizationOfOivDocument, baseOptions);
        }
export type ListOrganizationOfOivQueryHookResult = ReturnType<typeof useListOrganizationOfOivQuery>;
export type ListOrganizationOfOivLazyQueryHookResult = ReturnType<typeof useListOrganizationOfOivLazyQuery>;
export type ListOrganizationOfOivQueryResult = Apollo.QueryResult<ListOrganizationOfOivQuery, ListOrganizationOfOivQueryVariables>;
export const ListSportsOfBaseRegionsDocument = Apollo.gql`
    query ListSportsOfBaseRegions($id: UUID!) {
  dirSports(where: {baseRegions_some: {dirRegion: {id: $id}}}) {
    value: id
    label: fullName
    baseRegions {
      id
      dirRegion {
        id
      }
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useListSportsOfBaseRegionsQuery__
 *
 * To run a query within a React component, call `useListSportsOfBaseRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSportsOfBaseRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSportsOfBaseRegionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListSportsOfBaseRegionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListSportsOfBaseRegionsQuery, ListSportsOfBaseRegionsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListSportsOfBaseRegionsQuery, ListSportsOfBaseRegionsQueryVariables>(ListSportsOfBaseRegionsDocument, baseOptions);
      }
export function useListSportsOfBaseRegionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListSportsOfBaseRegionsQuery, ListSportsOfBaseRegionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListSportsOfBaseRegionsQuery, ListSportsOfBaseRegionsQueryVariables>(ListSportsOfBaseRegionsDocument, baseOptions);
        }
export type ListSportsOfBaseRegionsQueryHookResult = ReturnType<typeof useListSportsOfBaseRegionsQuery>;
export type ListSportsOfBaseRegionsLazyQueryHookResult = ReturnType<typeof useListSportsOfBaseRegionsLazyQuery>;
export type ListSportsOfBaseRegionsQueryResult = Apollo.QueryResult<ListSportsOfBaseRegionsQuery, ListSportsOfBaseRegionsQueryVariables>;
export const RegionsDocument = Apollo.gql`
    query Regions($first: Int!, $skip: Int!, $filter: DirRegionWhereInput) {
  dirRegions(orderBy: fullName_ASC, first: $first, skip: $skip, where: $filter) {
    ...Region
  }
}
    ${RegionFragmentDoc}`;

/**
 * __useRegionsQuery__
 *
 * To run a query within a React component, call `useRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
        return ApolloReactHooks.useQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, baseOptions);
      }
export function useRegionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, baseOptions);
        }
export type RegionsQueryHookResult = ReturnType<typeof useRegionsQuery>;
export type RegionsLazyQueryHookResult = ReturnType<typeof useRegionsLazyQuery>;
export type RegionsQueryResult = Apollo.QueryResult<RegionsQuery, RegionsQueryVariables>;
export const RegionsListDocument = Apollo.gql`
    query RegionsList($filter: DirRegionWhereInput) {
  dirRegions(orderBy: fullName_ASC, where: $filter) {
    value: id
    label: fullName
  }
}
    `;

/**
 * __useRegionsListQuery__
 *
 * To run a query within a React component, call `useRegionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegionsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegionsListQuery, RegionsListQueryVariables>) {
        return ApolloReactHooks.useQuery<RegionsListQuery, RegionsListQueryVariables>(RegionsListDocument, baseOptions);
      }
export function useRegionsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegionsListQuery, RegionsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegionsListQuery, RegionsListQueryVariables>(RegionsListDocument, baseOptions);
        }
export type RegionsListQueryHookResult = ReturnType<typeof useRegionsListQuery>;
export type RegionsListLazyQueryHookResult = ReturnType<typeof useRegionsListLazyQuery>;
export type RegionsListQueryResult = Apollo.QueryResult<RegionsListQuery, RegionsListQueryVariables>;
export const AnalyticsSportingEventsByRegionIdDocument = Apollo.gql`
    query AnalyticsSportingEventsByRegionId($filter: DirSportingEventWhereInput) {
  dirSportingEvents(where: $filter, orderBy: name_ASC) {
    id
    name
  }
}
    `;

/**
 * __useAnalyticsSportingEventsByRegionIdQuery__
 *
 * To run a query within a React component, call `useAnalyticsSportingEventsByRegionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsSportingEventsByRegionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsSportingEventsByRegionIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAnalyticsSportingEventsByRegionIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AnalyticsSportingEventsByRegionIdQuery, AnalyticsSportingEventsByRegionIdQueryVariables>) {
        return ApolloReactHooks.useQuery<AnalyticsSportingEventsByRegionIdQuery, AnalyticsSportingEventsByRegionIdQueryVariables>(AnalyticsSportingEventsByRegionIdDocument, baseOptions);
      }
export function useAnalyticsSportingEventsByRegionIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnalyticsSportingEventsByRegionIdQuery, AnalyticsSportingEventsByRegionIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AnalyticsSportingEventsByRegionIdQuery, AnalyticsSportingEventsByRegionIdQueryVariables>(AnalyticsSportingEventsByRegionIdDocument, baseOptions);
        }
export type AnalyticsSportingEventsByRegionIdQueryHookResult = ReturnType<typeof useAnalyticsSportingEventsByRegionIdQuery>;
export type AnalyticsSportingEventsByRegionIdLazyQueryHookResult = ReturnType<typeof useAnalyticsSportingEventsByRegionIdLazyQuery>;
export type AnalyticsSportingEventsByRegionIdQueryResult = Apollo.QueryResult<AnalyticsSportingEventsByRegionIdQuery, AnalyticsSportingEventsByRegionIdQueryVariables>;
export const RefusedRegionsByCalendarDocument = Apollo.gql`
    query RefusedRegionsByCalendar($id: UUID!) {
  dirRefusedRegions(where: {dirCalendar: {id: $id}}) {
    id
    dirRegion {
      value: id
      label: fullName
    }
    comment
    statement {
      path
      name
    }
  }
  dirReturnedRegions(where: {dirCalendar: {id: $id}}) {
    id
    dirRegion {
      value: id
      label: fullName
    }
    statement {
      path
      name
    }
  }
}
    `;

/**
 * __useRefusedRegionsByCalendarQuery__
 *
 * To run a query within a React component, call `useRefusedRegionsByCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefusedRegionsByCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefusedRegionsByCalendarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefusedRegionsByCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefusedRegionsByCalendarQuery, RefusedRegionsByCalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<RefusedRegionsByCalendarQuery, RefusedRegionsByCalendarQueryVariables>(RefusedRegionsByCalendarDocument, baseOptions);
      }
export function useRefusedRegionsByCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefusedRegionsByCalendarQuery, RefusedRegionsByCalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefusedRegionsByCalendarQuery, RefusedRegionsByCalendarQueryVariables>(RefusedRegionsByCalendarDocument, baseOptions);
        }
export type RefusedRegionsByCalendarQueryHookResult = ReturnType<typeof useRefusedRegionsByCalendarQuery>;
export type RefusedRegionsByCalendarLazyQueryHookResult = ReturnType<typeof useRefusedRegionsByCalendarLazyQuery>;
export type RefusedRegionsByCalendarQueryResult = Apollo.QueryResult<RefusedRegionsByCalendarQuery, RefusedRegionsByCalendarQueryVariables>;
export const DisciplinesDocument = Apollo.gql`
    query Disciplines($filter: DirSportDisciplineWhereInput) {
  dirSportDisciplines(where: $filter) {
    ...Discipline
  }
}
    ${DisciplineFragmentDoc}`;

/**
 * __useDisciplinesQuery__
 *
 * To run a query within a React component, call `useDisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisciplinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisciplinesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDisciplinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DisciplinesQuery, DisciplinesQueryVariables>) {
        return ApolloReactHooks.useQuery<DisciplinesQuery, DisciplinesQueryVariables>(DisciplinesDocument, baseOptions);
      }
export function useDisciplinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisciplinesQuery, DisciplinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DisciplinesQuery, DisciplinesQueryVariables>(DisciplinesDocument, baseOptions);
        }
export type DisciplinesQueryHookResult = ReturnType<typeof useDisciplinesQuery>;
export type DisciplinesLazyQueryHookResult = ReturnType<typeof useDisciplinesLazyQuery>;
export type DisciplinesQueryResult = Apollo.QueryResult<DisciplinesQuery, DisciplinesQueryVariables>;
export const DisciplineGroupsDocument = Apollo.gql`
    query DisciplineGroups($filter: DirSportDisciplineGroupWhereInput) {
  dirSportDisciplineGroups(where: $filter) {
    ...GroupDiscipline
  }
}
    ${GroupDisciplineFragmentDoc}`;

/**
 * __useDisciplineGroupsQuery__
 *
 * To run a query within a React component, call `useDisciplineGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisciplineGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisciplineGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDisciplineGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DisciplineGroupsQuery, DisciplineGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<DisciplineGroupsQuery, DisciplineGroupsQueryVariables>(DisciplineGroupsDocument, baseOptions);
      }
export function useDisciplineGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisciplineGroupsQuery, DisciplineGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DisciplineGroupsQuery, DisciplineGroupsQueryVariables>(DisciplineGroupsDocument, baseOptions);
        }
export type DisciplineGroupsQueryHookResult = ReturnType<typeof useDisciplineGroupsQuery>;
export type DisciplineGroupsLazyQueryHookResult = ReturnType<typeof useDisciplineGroupsLazyQuery>;
export type DisciplineGroupsQueryResult = Apollo.QueryResult<DisciplineGroupsQuery, DisciplineGroupsQueryVariables>;
export const EventsStatusesDocument = Apollo.gql`
    query EventsStatuses($filter: DirSportingEventStatusWhereInput) {
  dirSportingEventStatuses(where: $filter) {
    ...EventsStatus
  }
}
    ${EventsStatusFragmentDoc}`;

/**
 * __useEventsStatusesQuery__
 *
 * To run a query within a React component, call `useEventsStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsStatusesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEventsStatusesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventsStatusesQuery, EventsStatusesQueryVariables>) {
        return ApolloReactHooks.useQuery<EventsStatusesQuery, EventsStatusesQueryVariables>(EventsStatusesDocument, baseOptions);
      }
export function useEventsStatusesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsStatusesQuery, EventsStatusesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventsStatusesQuery, EventsStatusesQueryVariables>(EventsStatusesDocument, baseOptions);
        }
export type EventsStatusesQueryHookResult = ReturnType<typeof useEventsStatusesQuery>;
export type EventsStatusesLazyQueryHookResult = ReturnType<typeof useEventsStatusesLazyQuery>;
export type EventsStatusesQueryResult = Apollo.QueryResult<EventsStatusesQuery, EventsStatusesQueryVariables>;
export const EventsStatusesExtendedDocument = Apollo.gql`
    query EventsStatusesExtended($filter: DirSportingEventExtendedStatusValueWhereInput) {
  dirSportingEventExtendedStatusValues(where: $filter) {
    value: id
    label: name
  }
}
    `;

/**
 * __useEventsStatusesExtendedQuery__
 *
 * To run a query within a React component, call `useEventsStatusesExtendedQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsStatusesExtendedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsStatusesExtendedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEventsStatusesExtendedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventsStatusesExtendedQuery, EventsStatusesExtendedQueryVariables>) {
        return ApolloReactHooks.useQuery<EventsStatusesExtendedQuery, EventsStatusesExtendedQueryVariables>(EventsStatusesExtendedDocument, baseOptions);
      }
export function useEventsStatusesExtendedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsStatusesExtendedQuery, EventsStatusesExtendedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventsStatusesExtendedQuery, EventsStatusesExtendedQueryVariables>(EventsStatusesExtendedDocument, baseOptions);
        }
export type EventsStatusesExtendedQueryHookResult = ReturnType<typeof useEventsStatusesExtendedQuery>;
export type EventsStatusesExtendedLazyQueryHookResult = ReturnType<typeof useEventsStatusesExtendedLazyQuery>;
export type EventsStatusesExtendedQueryResult = Apollo.QueryResult<EventsStatusesExtendedQuery, EventsStatusesExtendedQueryVariables>;
export const RefereeStatusesExtendedDocument = Apollo.gql`
    query RefereeStatusesExtended($filter: DirSportingEventRefereeStatusValueWhereInput) {
  dirSportingEventRefereeStatusValues(where: $filter) {
    value: id
    label: name
  }
}
    `;

/**
 * __useRefereeStatusesExtendedQuery__
 *
 * To run a query within a React component, call `useRefereeStatusesExtendedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereeStatusesExtendedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereeStatusesExtendedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRefereeStatusesExtendedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefereeStatusesExtendedQuery, RefereeStatusesExtendedQueryVariables>) {
        return ApolloReactHooks.useQuery<RefereeStatusesExtendedQuery, RefereeStatusesExtendedQueryVariables>(RefereeStatusesExtendedDocument, baseOptions);
      }
export function useRefereeStatusesExtendedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereeStatusesExtendedQuery, RefereeStatusesExtendedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefereeStatusesExtendedQuery, RefereeStatusesExtendedQueryVariables>(RefereeStatusesExtendedDocument, baseOptions);
        }
export type RefereeStatusesExtendedQueryHookResult = ReturnType<typeof useRefereeStatusesExtendedQuery>;
export type RefereeStatusesExtendedLazyQueryHookResult = ReturnType<typeof useRefereeStatusesExtendedLazyQuery>;
export type RefereeStatusesExtendedQueryResult = Apollo.QueryResult<RefereeStatusesExtendedQuery, RefereeStatusesExtendedQueryVariables>;
export const TeamStatusesDocument = Apollo.gql`
    query TeamStatuses($filter: DirNationalTeamStatusWhereInput) {
  dirNationalTeamStatuses(where: $filter) {
    ...TeamStatus
  }
}
    ${TeamStatusFragmentDoc}`;

/**
 * __useTeamStatusesQuery__
 *
 * To run a query within a React component, call `useTeamStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamStatusesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamStatusesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamStatusesQuery, TeamStatusesQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamStatusesQuery, TeamStatusesQueryVariables>(TeamStatusesDocument, baseOptions);
      }
export function useTeamStatusesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamStatusesQuery, TeamStatusesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamStatusesQuery, TeamStatusesQueryVariables>(TeamStatusesDocument, baseOptions);
        }
export type TeamStatusesQueryHookResult = ReturnType<typeof useTeamStatusesQuery>;
export type TeamStatusesLazyQueryHookResult = ReturnType<typeof useTeamStatusesLazyQuery>;
export type TeamStatusesQueryResult = Apollo.QueryResult<TeamStatusesQuery, TeamStatusesQueryVariables>;
export const DirAthletesSimilarDocument = Apollo.gql`
    query dirAthletesSimilar($search: String!, $sportId: UUID, $bannedAthletes: [UUID!], $minBday: DateTime, $maxBday: DateTime) {
  dirPersonsSimilar(search: $search, where: {birthday_lte: $minBday, birthday_gte: $maxBday, athleteProfile: {id_not_in: $bannedAthletes, sports_some: {dirSport: {id: $sportId}}}}, threshold: 0.1) {
    person {
      athleteProfile {
        id
      }
      registryNumber
      firstname
      patronymic
      lastname
      birthday
    }
  }
}
    `;

/**
 * __useDirAthletesSimilarQuery__
 *
 * To run a query within a React component, call `useDirAthletesSimilarQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirAthletesSimilarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirAthletesSimilarQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sportId: // value for 'sportId'
 *      bannedAthletes: // value for 'bannedAthletes'
 *      minBday: // value for 'minBday'
 *      maxBday: // value for 'maxBday'
 *   },
 * });
 */
export function useDirAthletesSimilarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DirAthletesSimilarQuery, DirAthletesSimilarQueryVariables>) {
        return ApolloReactHooks.useQuery<DirAthletesSimilarQuery, DirAthletesSimilarQueryVariables>(DirAthletesSimilarDocument, baseOptions);
      }
export function useDirAthletesSimilarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DirAthletesSimilarQuery, DirAthletesSimilarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DirAthletesSimilarQuery, DirAthletesSimilarQueryVariables>(DirAthletesSimilarDocument, baseOptions);
        }
export type DirAthletesSimilarQueryHookResult = ReturnType<typeof useDirAthletesSimilarQuery>;
export type DirAthletesSimilarLazyQueryHookResult = ReturnType<typeof useDirAthletesSimilarLazyQuery>;
export type DirAthletesSimilarQueryResult = Apollo.QueryResult<DirAthletesSimilarQuery, DirAthletesSimilarQueryVariables>;
export const DirCalendarsSimilarDocument = Apollo.gql`
    query dirCalendarsSimilar($search: String!) {
  dirCalendars(where: {fullName_contains: $search, archive: null}, orderBy: fullName_ASC) {
    id
    fullName
    shortName
    archive {
      id
    }
  }
}
    `;

/**
 * __useDirCalendarsSimilarQuery__
 *
 * To run a query within a React component, call `useDirCalendarsSimilarQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirCalendarsSimilarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirCalendarsSimilarQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDirCalendarsSimilarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DirCalendarsSimilarQuery, DirCalendarsSimilarQueryVariables>) {
        return ApolloReactHooks.useQuery<DirCalendarsSimilarQuery, DirCalendarsSimilarQueryVariables>(DirCalendarsSimilarDocument, baseOptions);
      }
export function useDirCalendarsSimilarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DirCalendarsSimilarQuery, DirCalendarsSimilarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DirCalendarsSimilarQuery, DirCalendarsSimilarQueryVariables>(DirCalendarsSimilarDocument, baseOptions);
        }
export type DirCalendarsSimilarQueryHookResult = ReturnType<typeof useDirCalendarsSimilarQuery>;
export type DirCalendarsSimilarLazyQueryHookResult = ReturnType<typeof useDirCalendarsSimilarLazyQuery>;
export type DirCalendarsSimilarQueryResult = Apollo.QueryResult<DirCalendarsSimilarQuery, DirCalendarsSimilarQueryVariables>;
export const SimilarSportingEventsDocument = Apollo.gql`
    query SimilarSportingEvents($search: String!, $filter: DirSportingEventWhereInput) {
  dirSportingEventsSimilar(search: $search, where: $filter) {
    similarity
    sportingEvent {
      value: id
      label: name
      registryNumber
    }
  }
}
    `;

/**
 * __useSimilarSportingEventsQuery__
 *
 * To run a query within a React component, call `useSimilarSportingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarSportingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarSportingEventsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSimilarSportingEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SimilarSportingEventsQuery, SimilarSportingEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<SimilarSportingEventsQuery, SimilarSportingEventsQueryVariables>(SimilarSportingEventsDocument, baseOptions);
      }
export function useSimilarSportingEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimilarSportingEventsQuery, SimilarSportingEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SimilarSportingEventsQuery, SimilarSportingEventsQueryVariables>(SimilarSportingEventsDocument, baseOptions);
        }
export type SimilarSportingEventsQueryHookResult = ReturnType<typeof useSimilarSportingEventsQuery>;
export type SimilarSportingEventsLazyQueryHookResult = ReturnType<typeof useSimilarSportingEventsLazyQuery>;
export type SimilarSportingEventsQueryResult = Apollo.QueryResult<SimilarSportingEventsQuery, SimilarSportingEventsQueryVariables>;
export const DirTrainerSimilarDocument = Apollo.gql`
    query dirTrainerSimilar($search: String!, $bannedTrainers: [UUID!], $sportId: [UUID!]) {
  dirPersonsSimilar(search: $search, where: {archive: null, trainerProfile: {id_not_in: $bannedTrainers, sports_some: {dirSport: {id_in: $sportId}}}}) {
    person {
      trainerProfile {
        id
      }
      registryNumber
      firstname
      patronymic
      lastname
      birthday
    }
  }
}
    `;

/**
 * __useDirTrainerSimilarQuery__
 *
 * To run a query within a React component, call `useDirTrainerSimilarQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirTrainerSimilarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirTrainerSimilarQuery({
 *   variables: {
 *      search: // value for 'search'
 *      bannedTrainers: // value for 'bannedTrainers'
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useDirTrainerSimilarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DirTrainerSimilarQuery, DirTrainerSimilarQueryVariables>) {
        return ApolloReactHooks.useQuery<DirTrainerSimilarQuery, DirTrainerSimilarQueryVariables>(DirTrainerSimilarDocument, baseOptions);
      }
export function useDirTrainerSimilarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DirTrainerSimilarQuery, DirTrainerSimilarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DirTrainerSimilarQuery, DirTrainerSimilarQueryVariables>(DirTrainerSimilarDocument, baseOptions);
        }
export type DirTrainerSimilarQueryHookResult = ReturnType<typeof useDirTrainerSimilarQuery>;
export type DirTrainerSimilarLazyQueryHookResult = ReturnType<typeof useDirTrainerSimilarLazyQuery>;
export type DirTrainerSimilarQueryResult = Apollo.QueryResult<DirTrainerSimilarQuery, DirTrainerSimilarQueryVariables>;
export const SportingEventAgeGroupsRemoveDocument = Apollo.gql`
    mutation SportingEventAgeGroupsRemove($id: UUID!, $idCls: UUID!) {
  updateDirSportingEvent(where: {id: $id}, data: {ageGroups: {delete: {id: $idCls}}}) {
    ...AgeGroupEKP
  }
}
    ${AgeGroupEkpFragmentDoc}`;
export type SportingEventAgeGroupsRemoveMutationFn = Apollo.MutationFunction<SportingEventAgeGroupsRemoveMutation, SportingEventAgeGroupsRemoveMutationVariables>;

/**
 * __useSportingEventAgeGroupsRemoveMutation__
 *
 * To run a mutation, you first call `useSportingEventAgeGroupsRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSportingEventAgeGroupsRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sportingEventAgeGroupsRemoveMutation, { data, loading, error }] = useSportingEventAgeGroupsRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idCls: // value for 'idCls'
 *   },
 * });
 */
export function useSportingEventAgeGroupsRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SportingEventAgeGroupsRemoveMutation, SportingEventAgeGroupsRemoveMutationVariables>) {
        return ApolloReactHooks.useMutation<SportingEventAgeGroupsRemoveMutation, SportingEventAgeGroupsRemoveMutationVariables>(SportingEventAgeGroupsRemoveDocument, baseOptions);
      }
export type SportingEventAgeGroupsRemoveMutationHookResult = ReturnType<typeof useSportingEventAgeGroupsRemoveMutation>;
export type SportingEventAgeGroupsRemoveMutationResult = Apollo.MutationResult<SportingEventAgeGroupsRemoveMutation>;
export type SportingEventAgeGroupsRemoveMutationOptions = Apollo.BaseMutationOptions<SportingEventAgeGroupsRemoveMutation, SportingEventAgeGroupsRemoveMutationVariables>;
export const SportingEventAgeGroupsUpdateDocument = Apollo.gql`
    mutation SportingEventAgeGroupsUpdate($id: UUID!, $idGroup: UUID!, $idCls: UUID!, $minAge: Int!, $maxAge: Int) {
  updateDirSportingEvent(where: {id: $id}, data: {ageGroups: {update: {where: {id: $idGroup}, data: {clsAgeGroup: {connect: {id: $idCls}}, minAge: $minAge, maxAge: $maxAge}}}}) {
    ...AgeGroupEKP
  }
}
    ${AgeGroupEkpFragmentDoc}`;
export type SportingEventAgeGroupsUpdateMutationFn = Apollo.MutationFunction<SportingEventAgeGroupsUpdateMutation, SportingEventAgeGroupsUpdateMutationVariables>;

/**
 * __useSportingEventAgeGroupsUpdateMutation__
 *
 * To run a mutation, you first call `useSportingEventAgeGroupsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSportingEventAgeGroupsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sportingEventAgeGroupsUpdateMutation, { data, loading, error }] = useSportingEventAgeGroupsUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idGroup: // value for 'idGroup'
 *      idCls: // value for 'idCls'
 *      minAge: // value for 'minAge'
 *      maxAge: // value for 'maxAge'
 *   },
 * });
 */
export function useSportingEventAgeGroupsUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SportingEventAgeGroupsUpdateMutation, SportingEventAgeGroupsUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<SportingEventAgeGroupsUpdateMutation, SportingEventAgeGroupsUpdateMutationVariables>(SportingEventAgeGroupsUpdateDocument, baseOptions);
      }
export type SportingEventAgeGroupsUpdateMutationHookResult = ReturnType<typeof useSportingEventAgeGroupsUpdateMutation>;
export type SportingEventAgeGroupsUpdateMutationResult = Apollo.MutationResult<SportingEventAgeGroupsUpdateMutation>;
export type SportingEventAgeGroupsUpdateMutationOptions = Apollo.BaseMutationOptions<SportingEventAgeGroupsUpdateMutation, SportingEventAgeGroupsUpdateMutationVariables>;
export const SportingEventAgeGroupsCreateDocument = Apollo.gql`
    mutation SportingEventAgeGroupsCreate($id: UUID!, $idCls: UUID!, $minAge: Int!, $maxAge: Int) {
  updateDirSportingEvent(where: {id: $id}, data: {ageGroups: {create: {clsAgeGroup: {connect: {id: $idCls}}, minAge: $minAge, maxAge: $maxAge}}}) {
    ...AgeGroupEKP
  }
}
    ${AgeGroupEkpFragmentDoc}`;
export type SportingEventAgeGroupsCreateMutationFn = Apollo.MutationFunction<SportingEventAgeGroupsCreateMutation, SportingEventAgeGroupsCreateMutationVariables>;

/**
 * __useSportingEventAgeGroupsCreateMutation__
 *
 * To run a mutation, you first call `useSportingEventAgeGroupsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSportingEventAgeGroupsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sportingEventAgeGroupsCreateMutation, { data, loading, error }] = useSportingEventAgeGroupsCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idCls: // value for 'idCls'
 *      minAge: // value for 'minAge'
 *      maxAge: // value for 'maxAge'
 *   },
 * });
 */
export function useSportingEventAgeGroupsCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SportingEventAgeGroupsCreateMutation, SportingEventAgeGroupsCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<SportingEventAgeGroupsCreateMutation, SportingEventAgeGroupsCreateMutationVariables>(SportingEventAgeGroupsCreateDocument, baseOptions);
      }
export type SportingEventAgeGroupsCreateMutationHookResult = ReturnType<typeof useSportingEventAgeGroupsCreateMutation>;
export type SportingEventAgeGroupsCreateMutationResult = Apollo.MutationResult<SportingEventAgeGroupsCreateMutation>;
export type SportingEventAgeGroupsCreateMutationOptions = Apollo.BaseMutationOptions<SportingEventAgeGroupsCreateMutation, SportingEventAgeGroupsCreateMutationVariables>;
export const SportingEventAgeGroupsDocument = Apollo.gql`
    query SportingEventAgeGroups($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    isAgeRestricted
    ageGroup
    ...AgeGroupEKP
  }
}
    ${AgeGroupEkpFragmentDoc}`;

/**
 * __useSportingEventAgeGroupsQuery__
 *
 * To run a query within a React component, call `useSportingEventAgeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventAgeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventAgeGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventAgeGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventAgeGroupsQuery, SportingEventAgeGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventAgeGroupsQuery, SportingEventAgeGroupsQueryVariables>(SportingEventAgeGroupsDocument, baseOptions);
      }
export function useSportingEventAgeGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventAgeGroupsQuery, SportingEventAgeGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventAgeGroupsQuery, SportingEventAgeGroupsQueryVariables>(SportingEventAgeGroupsDocument, baseOptions);
        }
export type SportingEventAgeGroupsQueryHookResult = ReturnType<typeof useSportingEventAgeGroupsQuery>;
export type SportingEventAgeGroupsLazyQueryHookResult = ReturnType<typeof useSportingEventAgeGroupsLazyQuery>;
export type SportingEventAgeGroupsQueryResult = Apollo.QueryResult<SportingEventAgeGroupsQuery, SportingEventAgeGroupsQueryVariables>;
export const SportAgeGroupsDocument = Apollo.gql`
    query SportAgeGroups($id: [UUID!]) {
  dirSports(where: {id_in: $id}) {
    id
    ageGroups {
      id
      minAge
      maxAge
      clsAgeGroup {
        registryNumber
        value: id
        label: fullName
        shortName
      }
    }
  }
}
    `;

/**
 * __useSportAgeGroupsQuery__
 *
 * To run a query within a React component, call `useSportAgeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportAgeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportAgeGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportAgeGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportAgeGroupsQuery, SportAgeGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<SportAgeGroupsQuery, SportAgeGroupsQueryVariables>(SportAgeGroupsDocument, baseOptions);
      }
export function useSportAgeGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportAgeGroupsQuery, SportAgeGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportAgeGroupsQuery, SportAgeGroupsQueryVariables>(SportAgeGroupsDocument, baseOptions);
        }
export type SportAgeGroupsQueryHookResult = ReturnType<typeof useSportAgeGroupsQuery>;
export type SportAgeGroupsLazyQueryHookResult = ReturnType<typeof useSportAgeGroupsLazyQuery>;
export type SportAgeGroupsQueryResult = Apollo.QueryResult<SportAgeGroupsQuery, SportAgeGroupsQueryVariables>;
export const SportingEventAnalyticsChildsDocument = Apollo.gql`
    query SportingEventAnalyticsChilds($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    id
    name
    statuses(orderBy: createdAt_DESC) {
      id
      value {
        id
        name
      }
      isActive
    }
    archive {
      ...ArchiveStatus
    }
    children {
      value: id
      registryNumber
      label: name
      startDate
      endDate
      dirNationalTeams {
        id
        dirRegion {
          id
        }
      }
      sports {
        id
        dirSport {
          value: id
          label: fullName
        }
        disciplineGroups(orderBy: name_ASC) {
          value: id
          label: name
        }
      }
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;

/**
 * __useSportingEventAnalyticsChildsQuery__
 *
 * To run a query within a React component, call `useSportingEventAnalyticsChildsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventAnalyticsChildsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventAnalyticsChildsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventAnalyticsChildsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventAnalyticsChildsQuery, SportingEventAnalyticsChildsQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventAnalyticsChildsQuery, SportingEventAnalyticsChildsQueryVariables>(SportingEventAnalyticsChildsDocument, baseOptions);
      }
export function useSportingEventAnalyticsChildsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventAnalyticsChildsQuery, SportingEventAnalyticsChildsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventAnalyticsChildsQuery, SportingEventAnalyticsChildsQueryVariables>(SportingEventAnalyticsChildsDocument, baseOptions);
        }
export type SportingEventAnalyticsChildsQueryHookResult = ReturnType<typeof useSportingEventAnalyticsChildsQuery>;
export type SportingEventAnalyticsChildsLazyQueryHookResult = ReturnType<typeof useSportingEventAnalyticsChildsLazyQuery>;
export type SportingEventAnalyticsChildsQueryResult = Apollo.QueryResult<SportingEventAnalyticsChildsQuery, SportingEventAnalyticsChildsQueryVariables>;
export const CallAthleteNotifyAddDocument = Apollo.gql`
    mutation CallAthleteNotifyAdd($id: UUID!, $idDocument: UUID!, $number: String!, $date: DateTime!, $title: String!) {
  updateDirSportingEvent(where: {id: $id}, data: {athleteCallNotifications: {create: {document: {create: {file: {connect: {id: $idDocument}}, number: $number, date: $date, title: $title}}}}}) {
    id
  }
}
    `;
export type CallAthleteNotifyAddMutationFn = Apollo.MutationFunction<CallAthleteNotifyAddMutation, CallAthleteNotifyAddMutationVariables>;

/**
 * __useCallAthleteNotifyAddMutation__
 *
 * To run a mutation, you first call `useCallAthleteNotifyAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallAthleteNotifyAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callAthleteNotifyAddMutation, { data, loading, error }] = useCallAthleteNotifyAddMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idDocument: // value for 'idDocument'
 *      number: // value for 'number'
 *      date: // value for 'date'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCallAthleteNotifyAddMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CallAthleteNotifyAddMutation, CallAthleteNotifyAddMutationVariables>) {
        return ApolloReactHooks.useMutation<CallAthleteNotifyAddMutation, CallAthleteNotifyAddMutationVariables>(CallAthleteNotifyAddDocument, baseOptions);
      }
export type CallAthleteNotifyAddMutationHookResult = ReturnType<typeof useCallAthleteNotifyAddMutation>;
export type CallAthleteNotifyAddMutationResult = Apollo.MutationResult<CallAthleteNotifyAddMutation>;
export type CallAthleteNotifyAddMutationOptions = Apollo.BaseMutationOptions<CallAthleteNotifyAddMutation, CallAthleteNotifyAddMutationVariables>;
export const CallAthleteNotifyAddFromExistDocument = Apollo.gql`
    mutation CallAthleteNotifyAddFromExist($id: UUID!, $idDocument: UUID!) {
  updateDirSportingEvent(where: {id: $id}, data: {athleteCallNotifications: {create: {document: {connect: {id: $idDocument}}}}}) {
    id
  }
}
    `;
export type CallAthleteNotifyAddFromExistMutationFn = Apollo.MutationFunction<CallAthleteNotifyAddFromExistMutation, CallAthleteNotifyAddFromExistMutationVariables>;

/**
 * __useCallAthleteNotifyAddFromExistMutation__
 *
 * To run a mutation, you first call `useCallAthleteNotifyAddFromExistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallAthleteNotifyAddFromExistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callAthleteNotifyAddFromExistMutation, { data, loading, error }] = useCallAthleteNotifyAddFromExistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idDocument: // value for 'idDocument'
 *   },
 * });
 */
export function useCallAthleteNotifyAddFromExistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CallAthleteNotifyAddFromExistMutation, CallAthleteNotifyAddFromExistMutationVariables>) {
        return ApolloReactHooks.useMutation<CallAthleteNotifyAddFromExistMutation, CallAthleteNotifyAddFromExistMutationVariables>(CallAthleteNotifyAddFromExistDocument, baseOptions);
      }
export type CallAthleteNotifyAddFromExistMutationHookResult = ReturnType<typeof useCallAthleteNotifyAddFromExistMutation>;
export type CallAthleteNotifyAddFromExistMutationResult = Apollo.MutationResult<CallAthleteNotifyAddFromExistMutation>;
export type CallAthleteNotifyAddFromExistMutationOptions = Apollo.BaseMutationOptions<CallAthleteNotifyAddFromExistMutation, CallAthleteNotifyAddFromExistMutationVariables>;
export const CallAthleteNotifyDeleteDocument = Apollo.gql`
    mutation CallAthleteNotifyDelete($id: UUID!, $idCallNotify: UUID!) {
  updateDirSportingEvent(where: {id: $id}, data: {athleteCallNotifications: {delete: {id: $idCallNotify}}}) {
    id
  }
}
    `;
export type CallAthleteNotifyDeleteMutationFn = Apollo.MutationFunction<CallAthleteNotifyDeleteMutation, CallAthleteNotifyDeleteMutationVariables>;

/**
 * __useCallAthleteNotifyDeleteMutation__
 *
 * To run a mutation, you first call `useCallAthleteNotifyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallAthleteNotifyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callAthleteNotifyDeleteMutation, { data, loading, error }] = useCallAthleteNotifyDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idCallNotify: // value for 'idCallNotify'
 *   },
 * });
 */
export function useCallAthleteNotifyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CallAthleteNotifyDeleteMutation, CallAthleteNotifyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CallAthleteNotifyDeleteMutation, CallAthleteNotifyDeleteMutationVariables>(CallAthleteNotifyDeleteDocument, baseOptions);
      }
export type CallAthleteNotifyDeleteMutationHookResult = ReturnType<typeof useCallAthleteNotifyDeleteMutation>;
export type CallAthleteNotifyDeleteMutationResult = Apollo.MutationResult<CallAthleteNotifyDeleteMutation>;
export type CallAthleteNotifyDeleteMutationOptions = Apollo.BaseMutationOptions<CallAthleteNotifyDeleteMutation, CallAthleteNotifyDeleteMutationVariables>;
export const CallAthleteNotifyUpdateDocument = Apollo.gql`
    mutation CallAthleteNotifyUpdate($id: UUID!, $idCallNotify: UUID!, $teams: DirSportingEventAthleteCallNotificationTeamUpdateManyWithoutNotificationInput) {
  updateDirSportingEvent(where: {id: $id}, data: {athleteCallNotifications: {update: {where: {id: $idCallNotify}, data: {teams: $teams}}}}) {
    id
  }
}
    `;
export type CallAthleteNotifyUpdateMutationFn = Apollo.MutationFunction<CallAthleteNotifyUpdateMutation, CallAthleteNotifyUpdateMutationVariables>;

/**
 * __useCallAthleteNotifyUpdateMutation__
 *
 * To run a mutation, you first call `useCallAthleteNotifyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallAthleteNotifyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callAthleteNotifyUpdateMutation, { data, loading, error }] = useCallAthleteNotifyUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idCallNotify: // value for 'idCallNotify'
 *      teams: // value for 'teams'
 *   },
 * });
 */
export function useCallAthleteNotifyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CallAthleteNotifyUpdateMutation, CallAthleteNotifyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CallAthleteNotifyUpdateMutation, CallAthleteNotifyUpdateMutationVariables>(CallAthleteNotifyUpdateDocument, baseOptions);
      }
export type CallAthleteNotifyUpdateMutationHookResult = ReturnType<typeof useCallAthleteNotifyUpdateMutation>;
export type CallAthleteNotifyUpdateMutationResult = Apollo.MutationResult<CallAthleteNotifyUpdateMutation>;
export type CallAthleteNotifyUpdateMutationOptions = Apollo.BaseMutationOptions<CallAthleteNotifyUpdateMutation, CallAthleteNotifyUpdateMutationVariables>;
export const SportingEventCallAthleteNotifyDocument = Apollo.gql`
    query SportingEventCallAthleteNotify($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    ...CallAthleteNotifyFragment
  }
}
    ${CallAthleteNotifyFragmentFragmentDoc}`;

/**
 * __useSportingEventCallAthleteNotifyQuery__
 *
 * To run a query within a React component, call `useSportingEventCallAthleteNotifyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventCallAthleteNotifyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventCallAthleteNotifyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventCallAthleteNotifyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventCallAthleteNotifyQuery, SportingEventCallAthleteNotifyQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventCallAthleteNotifyQuery, SportingEventCallAthleteNotifyQueryVariables>(SportingEventCallAthleteNotifyDocument, baseOptions);
      }
export function useSportingEventCallAthleteNotifyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventCallAthleteNotifyQuery, SportingEventCallAthleteNotifyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventCallAthleteNotifyQuery, SportingEventCallAthleteNotifyQueryVariables>(SportingEventCallAthleteNotifyDocument, baseOptions);
        }
export type SportingEventCallAthleteNotifyQueryHookResult = ReturnType<typeof useSportingEventCallAthleteNotifyQuery>;
export type SportingEventCallAthleteNotifyLazyQueryHookResult = ReturnType<typeof useSportingEventCallAthleteNotifyLazyQuery>;
export type SportingEventCallAthleteNotifyQueryResult = Apollo.QueryResult<SportingEventCallAthleteNotifyQuery, SportingEventCallAthleteNotifyQueryVariables>;
export const AthleteCompetitionsResultsArrayDocument = Apollo.gql`
    query AthleteCompetitionsResultsArray($filter: DirAthleteCompetitionResultWhereInput) {
  dirAthleteCompetitionResults(orderBy: point_ASC, where: $filter) {
    ...AthleteCompetitionResult
  }
}
    ${AthleteCompetitionResultFragmentDoc}`;

/**
 * __useAthleteCompetitionsResultsArrayQuery__
 *
 * To run a query within a React component, call `useAthleteCompetitionsResultsArrayQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteCompetitionsResultsArrayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteCompetitionsResultsArrayQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthleteCompetitionsResultsArrayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteCompetitionsResultsArrayQuery, AthleteCompetitionsResultsArrayQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteCompetitionsResultsArrayQuery, AthleteCompetitionsResultsArrayQueryVariables>(AthleteCompetitionsResultsArrayDocument, baseOptions);
      }
export function useAthleteCompetitionsResultsArrayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteCompetitionsResultsArrayQuery, AthleteCompetitionsResultsArrayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteCompetitionsResultsArrayQuery, AthleteCompetitionsResultsArrayQueryVariables>(AthleteCompetitionsResultsArrayDocument, baseOptions);
        }
export type AthleteCompetitionsResultsArrayQueryHookResult = ReturnType<typeof useAthleteCompetitionsResultsArrayQuery>;
export type AthleteCompetitionsResultsArrayLazyQueryHookResult = ReturnType<typeof useAthleteCompetitionsResultsArrayLazyQuery>;
export type AthleteCompetitionsResultsArrayQueryResult = Apollo.QueryResult<AthleteCompetitionsResultsArrayQuery, AthleteCompetitionsResultsArrayQueryVariables>;
export const AthleteFullListDocument = Apollo.gql`
    query AthleteFullList($id: UUID!, $programTypeId: UUID!) {
  dirAthleteCompetitionResults(where: {sportingEventProgramType: {id: $programTypeId, event: {id: $id}}, archive: null}) {
    id
    dirAthlete {
      id
    }
  }
}
    `;

/**
 * __useAthleteFullListQuery__
 *
 * To run a query within a React component, call `useAthleteFullListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteFullListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteFullListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      programTypeId: // value for 'programTypeId'
 *   },
 * });
 */
export function useAthleteFullListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteFullListQuery, AthleteFullListQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteFullListQuery, AthleteFullListQueryVariables>(AthleteFullListDocument, baseOptions);
      }
export function useAthleteFullListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteFullListQuery, AthleteFullListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteFullListQuery, AthleteFullListQueryVariables>(AthleteFullListDocument, baseOptions);
        }
export type AthleteFullListQueryHookResult = ReturnType<typeof useAthleteFullListQuery>;
export type AthleteFullListLazyQueryHookResult = ReturnType<typeof useAthleteFullListLazyQuery>;
export type AthleteFullListQueryResult = Apollo.QueryResult<AthleteFullListQuery, AthleteFullListQueryVariables>;
export const CompetitionsUidFullListDocument = Apollo.gql`
    query CompetitionsUIDFullList($id: UUID!, $programTypeId: UUID!) {
  dirAthleteCompetitionResults(where: {sportingEventProgramType: {id: $programTypeId, event: {id: $id}}, archive: null}) {
    id
  }
}
    `;

/**
 * __useCompetitionsUidFullListQuery__
 *
 * To run a query within a React component, call `useCompetitionsUidFullListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetitionsUidFullListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetitionsUidFullListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      programTypeId: // value for 'programTypeId'
 *   },
 * });
 */
export function useCompetitionsUidFullListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompetitionsUidFullListQuery, CompetitionsUidFullListQueryVariables>) {
        return ApolloReactHooks.useQuery<CompetitionsUidFullListQuery, CompetitionsUidFullListQueryVariables>(CompetitionsUidFullListDocument, baseOptions);
      }
export function useCompetitionsUidFullListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompetitionsUidFullListQuery, CompetitionsUidFullListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompetitionsUidFullListQuery, CompetitionsUidFullListQueryVariables>(CompetitionsUidFullListDocument, baseOptions);
        }
export type CompetitionsUidFullListQueryHookResult = ReturnType<typeof useCompetitionsUidFullListQuery>;
export type CompetitionsUidFullListLazyQueryHookResult = ReturnType<typeof useCompetitionsUidFullListLazyQuery>;
export type CompetitionsUidFullListQueryResult = Apollo.QueryResult<CompetitionsUidFullListQuery, CompetitionsUidFullListQueryVariables>;
export const SportingEventCompetitionsResultsDocument = Apollo.gql`
    query SportingEventCompetitionsResults($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    ...CompetitionsResultsFragment
  }
}
    ${CompetitionsResultsFragmentFragmentDoc}`;

/**
 * __useSportingEventCompetitionsResultsQuery__
 *
 * To run a query within a React component, call `useSportingEventCompetitionsResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventCompetitionsResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventCompetitionsResultsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventCompetitionsResultsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventCompetitionsResultsQuery, SportingEventCompetitionsResultsQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventCompetitionsResultsQuery, SportingEventCompetitionsResultsQueryVariables>(SportingEventCompetitionsResultsDocument, baseOptions);
      }
export function useSportingEventCompetitionsResultsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventCompetitionsResultsQuery, SportingEventCompetitionsResultsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventCompetitionsResultsQuery, SportingEventCompetitionsResultsQueryVariables>(SportingEventCompetitionsResultsDocument, baseOptions);
        }
export type SportingEventCompetitionsResultsQueryHookResult = ReturnType<typeof useSportingEventCompetitionsResultsQuery>;
export type SportingEventCompetitionsResultsLazyQueryHookResult = ReturnType<typeof useSportingEventCompetitionsResultsLazyQuery>;
export type SportingEventCompetitionsResultsQueryResult = Apollo.QueryResult<SportingEventCompetitionsResultsQuery, SportingEventCompetitionsResultsQueryVariables>;
export const SportingEventDocumentsListDocument = Apollo.gql`
    query SportingEventDocumentsList($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    id
    name
    statuses(orderBy: createdAt_DESC) {
      id
      value {
        id
        name
      }
      isActive
    }
    ...DocumentsFragmentEvent
  }
}
    ${DocumentsFragmentEventFragmentDoc}`;

/**
 * __useSportingEventDocumentsListQuery__
 *
 * To run a query within a React component, call `useSportingEventDocumentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventDocumentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventDocumentsListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventDocumentsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventDocumentsListQuery, SportingEventDocumentsListQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventDocumentsListQuery, SportingEventDocumentsListQueryVariables>(SportingEventDocumentsListDocument, baseOptions);
      }
export function useSportingEventDocumentsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventDocumentsListQuery, SportingEventDocumentsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventDocumentsListQuery, SportingEventDocumentsListQueryVariables>(SportingEventDocumentsListDocument, baseOptions);
        }
export type SportingEventDocumentsListQueryHookResult = ReturnType<typeof useSportingEventDocumentsListQuery>;
export type SportingEventDocumentsListLazyQueryHookResult = ReturnType<typeof useSportingEventDocumentsListLazyQuery>;
export type SportingEventDocumentsListQueryResult = Apollo.QueryResult<SportingEventDocumentsListQuery, SportingEventDocumentsListQueryVariables>;
export const SportingEventProtocolDocDocument = Apollo.gql`
    query SportingEventProtocolDoc($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    ...EventProtocolDocFragment
  }
}
    ${EventProtocolDocFragmentFragmentDoc}`;

/**
 * __useSportingEventProtocolDocQuery__
 *
 * To run a query within a React component, call `useSportingEventProtocolDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventProtocolDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventProtocolDocQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventProtocolDocQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventProtocolDocQuery, SportingEventProtocolDocQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventProtocolDocQuery, SportingEventProtocolDocQueryVariables>(SportingEventProtocolDocDocument, baseOptions);
      }
export function useSportingEventProtocolDocLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventProtocolDocQuery, SportingEventProtocolDocQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventProtocolDocQuery, SportingEventProtocolDocQueryVariables>(SportingEventProtocolDocDocument, baseOptions);
        }
export type SportingEventProtocolDocQueryHookResult = ReturnType<typeof useSportingEventProtocolDocQuery>;
export type SportingEventProtocolDocLazyQueryHookResult = ReturnType<typeof useSportingEventProtocolDocLazyQuery>;
export type SportingEventProtocolDocQueryResult = Apollo.QueryResult<SportingEventProtocolDocQuery, SportingEventProtocolDocQueryVariables>;
export const MainInfoEventDirRegionUpdateDocument = Apollo.gql`
    mutation MainInfoEventDirRegionUpdate($id: UUID!, $idRegion: UUID!) {
  updateDirSportingEvent(where: {id: $id}, data: {dirRegion: {connect: {id: $idRegion}}}) {
    dirRegion {
      value: id
      label: fullName
    }
  }
}
    `;
export type MainInfoEventDirRegionUpdateMutationFn = Apollo.MutationFunction<MainInfoEventDirRegionUpdateMutation, MainInfoEventDirRegionUpdateMutationVariables>;

/**
 * __useMainInfoEventDirRegionUpdateMutation__
 *
 * To run a mutation, you first call `useMainInfoEventDirRegionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMainInfoEventDirRegionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mainInfoEventDirRegionUpdateMutation, { data, loading, error }] = useMainInfoEventDirRegionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idRegion: // value for 'idRegion'
 *   },
 * });
 */
export function useMainInfoEventDirRegionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MainInfoEventDirRegionUpdateMutation, MainInfoEventDirRegionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<MainInfoEventDirRegionUpdateMutation, MainInfoEventDirRegionUpdateMutationVariables>(MainInfoEventDirRegionUpdateDocument, baseOptions);
      }
export type MainInfoEventDirRegionUpdateMutationHookResult = ReturnType<typeof useMainInfoEventDirRegionUpdateMutation>;
export type MainInfoEventDirRegionUpdateMutationResult = Apollo.MutationResult<MainInfoEventDirRegionUpdateMutation>;
export type MainInfoEventDirRegionUpdateMutationOptions = Apollo.BaseMutationOptions<MainInfoEventDirRegionUpdateMutation, MainInfoEventDirRegionUpdateMutationVariables>;
export const MainInfoEventDirRegionFederalDistinctAddDocument = Apollo.gql`
    mutation MainInfoEventDirRegionFederalDistinctAdd($id: UUID!, $idFederalRegion: UUID!) {
  updateDirSportingEvent(where: {id: $id}, data: {dirFederalDistricts: {connect: {id: $idFederalRegion}}}) {
    dirFederalDistricts {
      value: id
      label: fullName
    }
  }
}
    `;
export type MainInfoEventDirRegionFederalDistinctAddMutationFn = Apollo.MutationFunction<MainInfoEventDirRegionFederalDistinctAddMutation, MainInfoEventDirRegionFederalDistinctAddMutationVariables>;

/**
 * __useMainInfoEventDirRegionFederalDistinctAddMutation__
 *
 * To run a mutation, you first call `useMainInfoEventDirRegionFederalDistinctAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMainInfoEventDirRegionFederalDistinctAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mainInfoEventDirRegionFederalDistinctAddMutation, { data, loading, error }] = useMainInfoEventDirRegionFederalDistinctAddMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idFederalRegion: // value for 'idFederalRegion'
 *   },
 * });
 */
export function useMainInfoEventDirRegionFederalDistinctAddMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MainInfoEventDirRegionFederalDistinctAddMutation, MainInfoEventDirRegionFederalDistinctAddMutationVariables>) {
        return ApolloReactHooks.useMutation<MainInfoEventDirRegionFederalDistinctAddMutation, MainInfoEventDirRegionFederalDistinctAddMutationVariables>(MainInfoEventDirRegionFederalDistinctAddDocument, baseOptions);
      }
export type MainInfoEventDirRegionFederalDistinctAddMutationHookResult = ReturnType<typeof useMainInfoEventDirRegionFederalDistinctAddMutation>;
export type MainInfoEventDirRegionFederalDistinctAddMutationResult = Apollo.MutationResult<MainInfoEventDirRegionFederalDistinctAddMutation>;
export type MainInfoEventDirRegionFederalDistinctAddMutationOptions = Apollo.BaseMutationOptions<MainInfoEventDirRegionFederalDistinctAddMutation, MainInfoEventDirRegionFederalDistinctAddMutationVariables>;
export const MainInfoEventDirRegionFederalDistinctRemoveDocument = Apollo.gql`
    mutation MainInfoEventDirRegionFederalDistinctRemove($id: UUID!, $idFederalRegion: UUID!) {
  updateDirSportingEvent(where: {id: $id}, data: {dirFederalDistricts: {disconnect: {id: $idFederalRegion}}}) {
    dirFederalDistricts {
      value: id
      label: fullName
    }
  }
}
    `;
export type MainInfoEventDirRegionFederalDistinctRemoveMutationFn = Apollo.MutationFunction<MainInfoEventDirRegionFederalDistinctRemoveMutation, MainInfoEventDirRegionFederalDistinctRemoveMutationVariables>;

/**
 * __useMainInfoEventDirRegionFederalDistinctRemoveMutation__
 *
 * To run a mutation, you first call `useMainInfoEventDirRegionFederalDistinctRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMainInfoEventDirRegionFederalDistinctRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mainInfoEventDirRegionFederalDistinctRemoveMutation, { data, loading, error }] = useMainInfoEventDirRegionFederalDistinctRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idFederalRegion: // value for 'idFederalRegion'
 *   },
 * });
 */
export function useMainInfoEventDirRegionFederalDistinctRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MainInfoEventDirRegionFederalDistinctRemoveMutation, MainInfoEventDirRegionFederalDistinctRemoveMutationVariables>) {
        return ApolloReactHooks.useMutation<MainInfoEventDirRegionFederalDistinctRemoveMutation, MainInfoEventDirRegionFederalDistinctRemoveMutationVariables>(MainInfoEventDirRegionFederalDistinctRemoveDocument, baseOptions);
      }
export type MainInfoEventDirRegionFederalDistinctRemoveMutationHookResult = ReturnType<typeof useMainInfoEventDirRegionFederalDistinctRemoveMutation>;
export type MainInfoEventDirRegionFederalDistinctRemoveMutationResult = Apollo.MutationResult<MainInfoEventDirRegionFederalDistinctRemoveMutation>;
export type MainInfoEventDirRegionFederalDistinctRemoveMutationOptions = Apollo.BaseMutationOptions<MainInfoEventDirRegionFederalDistinctRemoveMutation, MainInfoEventDirRegionFederalDistinctRemoveMutationVariables>;
export const ClsSportingEventStageDocument = Apollo.gql`
    query clsSportingEventStage {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Этапы спортмероприятия"}, fullName_in: ["2-й этап", "2-й этап (Финал)", "3-й этап (Финал)"]}) {
    label: fullName
    value: id
    fullName
    registryNumber
  }
}
    `;

/**
 * __useClsSportingEventStageQuery__
 *
 * To run a query within a React component, call `useClsSportingEventStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsSportingEventStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsSportingEventStageQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsSportingEventStageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClsSportingEventStageQuery, ClsSportingEventStageQueryVariables>) {
        return ApolloReactHooks.useQuery<ClsSportingEventStageQuery, ClsSportingEventStageQueryVariables>(ClsSportingEventStageDocument, baseOptions);
      }
export function useClsSportingEventStageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsSportingEventStageQuery, ClsSportingEventStageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClsSportingEventStageQuery, ClsSportingEventStageQueryVariables>(ClsSportingEventStageDocument, baseOptions);
        }
export type ClsSportingEventStageQueryHookResult = ReturnType<typeof useClsSportingEventStageQuery>;
export type ClsSportingEventStageLazyQueryHookResult = ReturnType<typeof useClsSportingEventStageLazyQuery>;
export type ClsSportingEventStageQueryResult = Apollo.QueryResult<ClsSportingEventStageQuery, ClsSportingEventStageQueryVariables>;
export const SportingEventStartInfoDocument = Apollo.gql`
    query SportingEventStartInfo($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    id
    name
    referees(where: {clsRefereePosition: {id: "4e4e7ff9-e7ec-41c1-84f6-e3aea9246cb5"}}) {
      id
      dirReferee {
        id
        dirPerson {
          id
          firstname
          lastname
          patronymic
        }
      }
    }
    daysBeforeStartToAddProtocols
    dirCalendar {
      id
      clsCalendarCategory {
        value: registryNumber
        label: fullName
      }
    }
    statuses(orderBy: createdAt_DESC) {
      id
      createdAt
      updatedBy {
        id
        name
        email
        regular {
          id
          firstname
          lastname
          patronymic
          phone
          address
          dirOrganization {
            id
            name
            legalAddress
          }
        }
      }
      comment
      value {
        value: id
        label: name
      }
      file {
        id
        name
        path
      }
    }
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;

/**
 * __useSportingEventStartInfoQuery__
 *
 * To run a query within a React component, call `useSportingEventStartInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventStartInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventStartInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventStartInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventStartInfoQuery, SportingEventStartInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventStartInfoQuery, SportingEventStartInfoQueryVariables>(SportingEventStartInfoDocument, baseOptions);
      }
export function useSportingEventStartInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventStartInfoQuery, SportingEventStartInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventStartInfoQuery, SportingEventStartInfoQueryVariables>(SportingEventStartInfoDocument, baseOptions);
        }
export type SportingEventStartInfoQueryHookResult = ReturnType<typeof useSportingEventStartInfoQuery>;
export type SportingEventStartInfoLazyQueryHookResult = ReturnType<typeof useSportingEventStartInfoLazyQuery>;
export type SportingEventStartInfoQueryResult = Apollo.QueryResult<SportingEventStartInfoQuery, SportingEventStartInfoQueryVariables>;
export const SportingEventMainInfoDocument = Apollo.gql`
    query SportingEventMainInfo($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    ...MainInfoFragment
  }
}
    ${MainInfoFragmentFragmentDoc}`;

/**
 * __useSportingEventMainInfoQuery__
 *
 * To run a query within a React component, call `useSportingEventMainInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventMainInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventMainInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventMainInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventMainInfoQuery, SportingEventMainInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventMainInfoQuery, SportingEventMainInfoQueryVariables>(SportingEventMainInfoDocument, baseOptions);
      }
export function useSportingEventMainInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventMainInfoQuery, SportingEventMainInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventMainInfoQuery, SportingEventMainInfoQueryVariables>(SportingEventMainInfoDocument, baseOptions);
        }
export type SportingEventMainInfoQueryHookResult = ReturnType<typeof useSportingEventMainInfoQuery>;
export type SportingEventMainInfoLazyQueryHookResult = ReturnType<typeof useSportingEventMainInfoLazyQuery>;
export type SportingEventMainInfoQueryResult = Apollo.QueryResult<SportingEventMainInfoQuery, SportingEventMainInfoQueryVariables>;
export const UploadRefereeAgreementsDocDocument = Apollo.gql`
    mutation UploadRefereeAgreementsDoc($id: UUID!, $idFile: UUID!) {
  updateDirSportingEvent(where: {id: $id}, data: {refereePersonalDataAgreements: {connect: {id: $idFile}}}) {
    refereePersonalDataAgreements {
      name
      path
      id
    }
  }
}
    `;
export type UploadRefereeAgreementsDocMutationFn = Apollo.MutationFunction<UploadRefereeAgreementsDocMutation, UploadRefereeAgreementsDocMutationVariables>;

/**
 * __useUploadRefereeAgreementsDocMutation__
 *
 * To run a mutation, you first call `useUploadRefereeAgreementsDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadRefereeAgreementsDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadRefereeAgreementsDocMutation, { data, loading, error }] = useUploadRefereeAgreementsDocMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idFile: // value for 'idFile'
 *   },
 * });
 */
export function useUploadRefereeAgreementsDocMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadRefereeAgreementsDocMutation, UploadRefereeAgreementsDocMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadRefereeAgreementsDocMutation, UploadRefereeAgreementsDocMutationVariables>(UploadRefereeAgreementsDocDocument, baseOptions);
      }
export type UploadRefereeAgreementsDocMutationHookResult = ReturnType<typeof useUploadRefereeAgreementsDocMutation>;
export type UploadRefereeAgreementsDocMutationResult = Apollo.MutationResult<UploadRefereeAgreementsDocMutation>;
export type UploadRefereeAgreementsDocMutationOptions = Apollo.BaseMutationOptions<UploadRefereeAgreementsDocMutation, UploadRefereeAgreementsDocMutationVariables>;
export const DeleteRefereeAgreementsDocDocument = Apollo.gql`
    mutation DeleteRefereeAgreementsDoc($id: UUID!, $idFile: UUID!) {
  updateDirSportingEvent(where: {id: $id}, data: {refereePersonalDataAgreements: {disconnect: {id: $idFile}}}) {
    refereePersonalDataAgreements {
      name
      path
      id
    }
  }
}
    `;
export type DeleteRefereeAgreementsDocMutationFn = Apollo.MutationFunction<DeleteRefereeAgreementsDocMutation, DeleteRefereeAgreementsDocMutationVariables>;

/**
 * __useDeleteRefereeAgreementsDocMutation__
 *
 * To run a mutation, you first call `useDeleteRefereeAgreementsDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRefereeAgreementsDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRefereeAgreementsDocMutation, { data, loading, error }] = useDeleteRefereeAgreementsDocMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idFile: // value for 'idFile'
 *   },
 * });
 */
export function useDeleteRefereeAgreementsDocMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRefereeAgreementsDocMutation, DeleteRefereeAgreementsDocMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRefereeAgreementsDocMutation, DeleteRefereeAgreementsDocMutationVariables>(DeleteRefereeAgreementsDocDocument, baseOptions);
      }
export type DeleteRefereeAgreementsDocMutationHookResult = ReturnType<typeof useDeleteRefereeAgreementsDocMutation>;
export type DeleteRefereeAgreementsDocMutationResult = Apollo.MutationResult<DeleteRefereeAgreementsDocMutation>;
export type DeleteRefereeAgreementsDocMutationOptions = Apollo.BaseMutationOptions<DeleteRefereeAgreementsDocMutation, DeleteRefereeAgreementsDocMutationVariables>;
export const GetListRefereeAgreementsDocsDocument = Apollo.gql`
    query GetListRefereeAgreementsDocs($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    refereePersonalDataAgreements {
      name
      path
      id
    }
  }
}
    `;

/**
 * __useGetListRefereeAgreementsDocsQuery__
 *
 * To run a query within a React component, call `useGetListRefereeAgreementsDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListRefereeAgreementsDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListRefereeAgreementsDocsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetListRefereeAgreementsDocsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetListRefereeAgreementsDocsQuery, GetListRefereeAgreementsDocsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetListRefereeAgreementsDocsQuery, GetListRefereeAgreementsDocsQueryVariables>(GetListRefereeAgreementsDocsDocument, baseOptions);
      }
export function useGetListRefereeAgreementsDocsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetListRefereeAgreementsDocsQuery, GetListRefereeAgreementsDocsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetListRefereeAgreementsDocsQuery, GetListRefereeAgreementsDocsQueryVariables>(GetListRefereeAgreementsDocsDocument, baseOptions);
        }
export type GetListRefereeAgreementsDocsQueryHookResult = ReturnType<typeof useGetListRefereeAgreementsDocsQuery>;
export type GetListRefereeAgreementsDocsLazyQueryHookResult = ReturnType<typeof useGetListRefereeAgreementsDocsLazyQuery>;
export type GetListRefereeAgreementsDocsQueryResult = Apollo.QueryResult<GetListRefereeAgreementsDocsQuery, GetListRefereeAgreementsDocsQueryVariables>;
export const SportingEventRefereesDocument = Apollo.gql`
    query SportingEventReferees($id: UUID!) {
  SportingEventReferees: dirSportingEvent(where: {id: $id}) {
    ...RefereesEventFragment
  }
}
    ${RefereesEventFragmentFragmentDoc}`;

/**
 * __useSportingEventRefereesQuery__
 *
 * To run a query within a React component, call `useSportingEventRefereesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventRefereesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventRefereesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventRefereesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventRefereesQuery, SportingEventRefereesQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventRefereesQuery, SportingEventRefereesQueryVariables>(SportingEventRefereesDocument, baseOptions);
      }
export function useSportingEventRefereesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventRefereesQuery, SportingEventRefereesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventRefereesQuery, SportingEventRefereesQueryVariables>(SportingEventRefereesDocument, baseOptions);
        }
export type SportingEventRefereesQueryHookResult = ReturnType<typeof useSportingEventRefereesQuery>;
export type SportingEventRefereesLazyQueryHookResult = ReturnType<typeof useSportingEventRefereesLazyQuery>;
export type SportingEventRefereesQueryResult = Apollo.QueryResult<SportingEventRefereesQuery, SportingEventRefereesQueryVariables>;
export const SportingEventRefereesByRegistryNumberDocument = Apollo.gql`
    query SportingEventRefereesByRegistryNumber($id: UUID!, $registryNumber: Int!) {
  SportingEventReferees: dirSportingEventReferees(where: {event: {id: $id}, clsRefereePosition: {registryNumber: $registryNumber}}) {
    ...RefereeList
  }
}
    ${RefereeListFragmentDoc}`;

/**
 * __useSportingEventRefereesByRegistryNumberQuery__
 *
 * To run a query within a React component, call `useSportingEventRefereesByRegistryNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventRefereesByRegistryNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventRefereesByRegistryNumberQuery({
 *   variables: {
 *      id: // value for 'id'
 *      registryNumber: // value for 'registryNumber'
 *   },
 * });
 */
export function useSportingEventRefereesByRegistryNumberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventRefereesByRegistryNumberQuery, SportingEventRefereesByRegistryNumberQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventRefereesByRegistryNumberQuery, SportingEventRefereesByRegistryNumberQueryVariables>(SportingEventRefereesByRegistryNumberDocument, baseOptions);
      }
export function useSportingEventRefereesByRegistryNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventRefereesByRegistryNumberQuery, SportingEventRefereesByRegistryNumberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventRefereesByRegistryNumberQuery, SportingEventRefereesByRegistryNumberQueryVariables>(SportingEventRefereesByRegistryNumberDocument, baseOptions);
        }
export type SportingEventRefereesByRegistryNumberQueryHookResult = ReturnType<typeof useSportingEventRefereesByRegistryNumberQuery>;
export type SportingEventRefereesByRegistryNumberLazyQueryHookResult = ReturnType<typeof useSportingEventRefereesByRegistryNumberLazyQuery>;
export type SportingEventRefereesByRegistryNumberQueryResult = Apollo.QueryResult<SportingEventRefereesByRegistryNumberQuery, SportingEventRefereesByRegistryNumberQueryVariables>;
export const SportingEventRefereesListDocument = Apollo.gql`
    query SportingEventRefereesList($filter: DirSportingEventWhereUniqueInput!) {
  dirSportingEvent(where: $filter) {
    ...SportingEventRefereesList
  }
}
    ${SportingEventRefereesListFragmentDoc}`;

/**
 * __useSportingEventRefereesListQuery__
 *
 * To run a query within a React component, call `useSportingEventRefereesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventRefereesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventRefereesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSportingEventRefereesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventRefereesListQuery, SportingEventRefereesListQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventRefereesListQuery, SportingEventRefereesListQueryVariables>(SportingEventRefereesListDocument, baseOptions);
      }
export function useSportingEventRefereesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventRefereesListQuery, SportingEventRefereesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventRefereesListQuery, SportingEventRefereesListQueryVariables>(SportingEventRefereesListDocument, baseOptions);
        }
export type SportingEventRefereesListQueryHookResult = ReturnType<typeof useSportingEventRefereesListQuery>;
export type SportingEventRefereesListLazyQueryHookResult = ReturnType<typeof useSportingEventRefereesListLazyQuery>;
export type SportingEventRefereesListQueryResult = Apollo.QueryResult<SportingEventRefereesListQuery, SportingEventRefereesListQueryVariables>;
export const CreateSportingEventDocument = Apollo.gql`
    mutation CreateSportingEvent($data: DirSportingEventCreateInput!) {
  createDirSportingEvent(data: $data) {
    ...SportingEvent
  }
}
    ${SportingEventFragmentDoc}`;
export type CreateSportingEventMutationFn = Apollo.MutationFunction<CreateSportingEventMutation, CreateSportingEventMutationVariables>;

/**
 * __useCreateSportingEventMutation__
 *
 * To run a mutation, you first call `useCreateSportingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSportingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSportingEventMutation, { data, loading, error }] = useCreateSportingEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSportingEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSportingEventMutation, CreateSportingEventMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSportingEventMutation, CreateSportingEventMutationVariables>(CreateSportingEventDocument, baseOptions);
      }
export type CreateSportingEventMutationHookResult = ReturnType<typeof useCreateSportingEventMutation>;
export type CreateSportingEventMutationResult = Apollo.MutationResult<CreateSportingEventMutation>;
export type CreateSportingEventMutationOptions = Apollo.BaseMutationOptions<CreateSportingEventMutation, CreateSportingEventMutationVariables>;
export const UpdateSportingEventDocument = Apollo.gql`
    mutation UpdateSportingEvent($id: UUID!, $data: DirSportingEventUpdateInput!) {
  updateDirSportingEvent(data: $data, where: {id: $id}) {
    ...SportingEvent
  }
}
    ${SportingEventFragmentDoc}`;
export type UpdateSportingEventMutationFn = Apollo.MutationFunction<UpdateSportingEventMutation, UpdateSportingEventMutationVariables>;

/**
 * __useUpdateSportingEventMutation__
 *
 * To run a mutation, you first call `useUpdateSportingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSportingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSportingEventMutation, { data, loading, error }] = useUpdateSportingEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSportingEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSportingEventMutation, UpdateSportingEventMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSportingEventMutation, UpdateSportingEventMutationVariables>(UpdateSportingEventDocument, baseOptions);
      }
export type UpdateSportingEventMutationHookResult = ReturnType<typeof useUpdateSportingEventMutation>;
export type UpdateSportingEventMutationResult = Apollo.MutationResult<UpdateSportingEventMutation>;
export type UpdateSportingEventMutationOptions = Apollo.BaseMutationOptions<UpdateSportingEventMutation, UpdateSportingEventMutationVariables>;
export const SportingEventArchiveDocument = Apollo.gql`
    mutation SportingEventArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirSportingEvent(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type SportingEventArchiveMutationFn = Apollo.MutationFunction<SportingEventArchiveMutation, SportingEventArchiveMutationVariables>;

/**
 * __useSportingEventArchiveMutation__
 *
 * To run a mutation, you first call `useSportingEventArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSportingEventArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sportingEventArchiveMutation, { data, loading, error }] = useSportingEventArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useSportingEventArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SportingEventArchiveMutation, SportingEventArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<SportingEventArchiveMutation, SportingEventArchiveMutationVariables>(SportingEventArchiveDocument, baseOptions);
      }
export type SportingEventArchiveMutationHookResult = ReturnType<typeof useSportingEventArchiveMutation>;
export type SportingEventArchiveMutationResult = Apollo.MutationResult<SportingEventArchiveMutation>;
export type SportingEventArchiveMutationOptions = Apollo.BaseMutationOptions<SportingEventArchiveMutation, SportingEventArchiveMutationVariables>;
export const SportingEventUnArchiveDocument = Apollo.gql`
    mutation SportingEventUnArchive($id: UUID!) {
  updateDirSportingEvent(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type SportingEventUnArchiveMutationFn = Apollo.MutationFunction<SportingEventUnArchiveMutation, SportingEventUnArchiveMutationVariables>;

/**
 * __useSportingEventUnArchiveMutation__
 *
 * To run a mutation, you first call `useSportingEventUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSportingEventUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sportingEventUnArchiveMutation, { data, loading, error }] = useSportingEventUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SportingEventUnArchiveMutation, SportingEventUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<SportingEventUnArchiveMutation, SportingEventUnArchiveMutationVariables>(SportingEventUnArchiveDocument, baseOptions);
      }
export type SportingEventUnArchiveMutationHookResult = ReturnType<typeof useSportingEventUnArchiveMutation>;
export type SportingEventUnArchiveMutationResult = Apollo.MutationResult<SportingEventUnArchiveMutation>;
export type SportingEventUnArchiveMutationOptions = Apollo.BaseMutationOptions<SportingEventUnArchiveMutation, SportingEventUnArchiveMutationVariables>;
export const CreateSportingEventRequestDocument = Apollo.gql`
    mutation CreateSportingEventRequest($eventId: UUID!, $typeId: Int!) {
  createDirSportingEventRequest(data: {event: {connect: {id: $eventId}}, type: {connect: {id: $typeId}}}) {
    id
  }
}
    `;
export type CreateSportingEventRequestMutationFn = Apollo.MutationFunction<CreateSportingEventRequestMutation, CreateSportingEventRequestMutationVariables>;

/**
 * __useCreateSportingEventRequestMutation__
 *
 * To run a mutation, you first call `useCreateSportingEventRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSportingEventRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSportingEventRequestMutation, { data, loading, error }] = useCreateSportingEventRequestMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      typeId: // value for 'typeId'
 *   },
 * });
 */
export function useCreateSportingEventRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSportingEventRequestMutation, CreateSportingEventRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSportingEventRequestMutation, CreateSportingEventRequestMutationVariables>(CreateSportingEventRequestDocument, baseOptions);
      }
export type CreateSportingEventRequestMutationHookResult = ReturnType<typeof useCreateSportingEventRequestMutation>;
export type CreateSportingEventRequestMutationResult = Apollo.MutationResult<CreateSportingEventRequestMutation>;
export type CreateSportingEventRequestMutationOptions = Apollo.BaseMutationOptions<CreateSportingEventRequestMutation, CreateSportingEventRequestMutationVariables>;
export const CreateSportingEventRequestStatusDocument = Apollo.gql`
    mutation CreateSportingEventRequestStatus($requestId: UUID!, $data: [DirSportingEventRequestStatusCreateWithoutRequestInput!]) {
  updateDirSportingEventRequest(where: {id: $requestId}, data: {statuses: {create: $data}}) {
    id
  }
}
    `;
export type CreateSportingEventRequestStatusMutationFn = Apollo.MutationFunction<CreateSportingEventRequestStatusMutation, CreateSportingEventRequestStatusMutationVariables>;

/**
 * __useCreateSportingEventRequestStatusMutation__
 *
 * To run a mutation, you first call `useCreateSportingEventRequestStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSportingEventRequestStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSportingEventRequestStatusMutation, { data, loading, error }] = useCreateSportingEventRequestStatusMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSportingEventRequestStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSportingEventRequestStatusMutation, CreateSportingEventRequestStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSportingEventRequestStatusMutation, CreateSportingEventRequestStatusMutationVariables>(CreateSportingEventRequestStatusDocument, baseOptions);
      }
export type CreateSportingEventRequestStatusMutationHookResult = ReturnType<typeof useCreateSportingEventRequestStatusMutation>;
export type CreateSportingEventRequestStatusMutationResult = Apollo.MutationResult<CreateSportingEventRequestStatusMutation>;
export type CreateSportingEventRequestStatusMutationOptions = Apollo.BaseMutationOptions<CreateSportingEventRequestStatusMutation, CreateSportingEventRequestStatusMutationVariables>;
export const CloneSportingEventDocument = Apollo.gql`
    mutation CloneSportingEvent($id: UUID!) {
  cloneDirSportingEvent(where: {id: $id}) {
    id
    name
    proxy {
      id
      name
      changes {
        id
        name
      }
    }
    changes {
      id
      name
    }
  }
}
    `;
export type CloneSportingEventMutationFn = Apollo.MutationFunction<CloneSportingEventMutation, CloneSportingEventMutationVariables>;

/**
 * __useCloneSportingEventMutation__
 *
 * To run a mutation, you first call `useCloneSportingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneSportingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneSportingEventMutation, { data, loading, error }] = useCloneSportingEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloneSportingEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CloneSportingEventMutation, CloneSportingEventMutationVariables>) {
        return ApolloReactHooks.useMutation<CloneSportingEventMutation, CloneSportingEventMutationVariables>(CloneSportingEventDocument, baseOptions);
      }
export type CloneSportingEventMutationHookResult = ReturnType<typeof useCloneSportingEventMutation>;
export type CloneSportingEventMutationResult = Apollo.MutationResult<CloneSportingEventMutation>;
export type CloneSportingEventMutationOptions = Apollo.BaseMutationOptions<CloneSportingEventMutation, CloneSportingEventMutationVariables>;
export const MergeSportingEventDocument = Apollo.gql`
    mutation MergeSportingEvent($from: UUID!, $to: UUID!) {
  restoreDirSportingEvent(from: {id: $from}, to: {id: $to}) {
    id
  }
}
    `;
export type MergeSportingEventMutationFn = Apollo.MutationFunction<MergeSportingEventMutation, MergeSportingEventMutationVariables>;

/**
 * __useMergeSportingEventMutation__
 *
 * To run a mutation, you first call `useMergeSportingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeSportingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeSportingEventMutation, { data, loading, error }] = useMergeSportingEventMutation({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useMergeSportingEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MergeSportingEventMutation, MergeSportingEventMutationVariables>) {
        return ApolloReactHooks.useMutation<MergeSportingEventMutation, MergeSportingEventMutationVariables>(MergeSportingEventDocument, baseOptions);
      }
export type MergeSportingEventMutationHookResult = ReturnType<typeof useMergeSportingEventMutation>;
export type MergeSportingEventMutationResult = Apollo.MutationResult<MergeSportingEventMutation>;
export type MergeSportingEventMutationOptions = Apollo.BaseMutationOptions<MergeSportingEventMutation, MergeSportingEventMutationVariables>;
export const CreateEventStatusDocument = Apollo.gql`
    mutation CreateEventStatus($id: UUID!, $data: [DirSportingEventExtendedStatusCreateWithoutEventInput!]) {
  updateDirSportingEvent(where: {id: $id}, data: {statuses: {create: $data}}) {
    id
  }
}
    `;
export type CreateEventStatusMutationFn = Apollo.MutationFunction<CreateEventStatusMutation, CreateEventStatusMutationVariables>;

/**
 * __useCreateEventStatusMutation__
 *
 * To run a mutation, you first call `useCreateEventStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventStatusMutation, { data, loading, error }] = useCreateEventStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventStatusMutation, CreateEventStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEventStatusMutation, CreateEventStatusMutationVariables>(CreateEventStatusDocument, baseOptions);
      }
export type CreateEventStatusMutationHookResult = ReturnType<typeof useCreateEventStatusMutation>;
export type CreateEventStatusMutationResult = Apollo.MutationResult<CreateEventStatusMutation>;
export type CreateEventStatusMutationOptions = Apollo.BaseMutationOptions<CreateEventStatusMutation, CreateEventStatusMutationVariables>;
export const CreateEventRefereeStatusDocument = Apollo.gql`
    mutation CreateEventRefereeStatus($id: UUID!, $data: [DirSportingEventRefereeStatusCreateWithoutEventInput!]) {
  updateDirSportingEvent(where: {id: $id}, data: {refereeStatuses: {create: $data}}) {
    id
  }
}
    `;
export type CreateEventRefereeStatusMutationFn = Apollo.MutationFunction<CreateEventRefereeStatusMutation, CreateEventRefereeStatusMutationVariables>;

/**
 * __useCreateEventRefereeStatusMutation__
 *
 * To run a mutation, you first call `useCreateEventRefereeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventRefereeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventRefereeStatusMutation, { data, loading, error }] = useCreateEventRefereeStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventRefereeStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventRefereeStatusMutation, CreateEventRefereeStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEventRefereeStatusMutation, CreateEventRefereeStatusMutationVariables>(CreateEventRefereeStatusDocument, baseOptions);
      }
export type CreateEventRefereeStatusMutationHookResult = ReturnType<typeof useCreateEventRefereeStatusMutation>;
export type CreateEventRefereeStatusMutationResult = Apollo.MutationResult<CreateEventRefereeStatusMutation>;
export type CreateEventRefereeStatusMutationOptions = Apollo.BaseMutationOptions<CreateEventRefereeStatusMutation, CreateEventRefereeStatusMutationVariables>;
export const UpdateAgeGroupFemaleEventDocument = Apollo.gql`
    mutation UpdateAgeGroupFemaleEvent($id: UUID!, $data: DirSportAgeGroupUpdateOneInput) {
  updateDirSportingEvent(where: {id: $id}, data: {ageGroupFemale: $data}) {
    id
  }
}
    `;
export type UpdateAgeGroupFemaleEventMutationFn = Apollo.MutationFunction<UpdateAgeGroupFemaleEventMutation, UpdateAgeGroupFemaleEventMutationVariables>;

/**
 * __useUpdateAgeGroupFemaleEventMutation__
 *
 * To run a mutation, you first call `useUpdateAgeGroupFemaleEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgeGroupFemaleEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgeGroupFemaleEventMutation, { data, loading, error }] = useUpdateAgeGroupFemaleEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAgeGroupFemaleEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAgeGroupFemaleEventMutation, UpdateAgeGroupFemaleEventMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAgeGroupFemaleEventMutation, UpdateAgeGroupFemaleEventMutationVariables>(UpdateAgeGroupFemaleEventDocument, baseOptions);
      }
export type UpdateAgeGroupFemaleEventMutationHookResult = ReturnType<typeof useUpdateAgeGroupFemaleEventMutation>;
export type UpdateAgeGroupFemaleEventMutationResult = Apollo.MutationResult<UpdateAgeGroupFemaleEventMutation>;
export type UpdateAgeGroupFemaleEventMutationOptions = Apollo.BaseMutationOptions<UpdateAgeGroupFemaleEventMutation, UpdateAgeGroupFemaleEventMutationVariables>;
export const UpdateAgeGroupMaleEventDocument = Apollo.gql`
    mutation UpdateAgeGroupMaleEvent($id: UUID!, $data: DirSportAgeGroupUpdateOneInput) {
  updateDirSportingEvent(where: {id: $id}, data: {ageGroupMale: $data}) {
    id
  }
}
    `;
export type UpdateAgeGroupMaleEventMutationFn = Apollo.MutationFunction<UpdateAgeGroupMaleEventMutation, UpdateAgeGroupMaleEventMutationVariables>;

/**
 * __useUpdateAgeGroupMaleEventMutation__
 *
 * To run a mutation, you first call `useUpdateAgeGroupMaleEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgeGroupMaleEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgeGroupMaleEventMutation, { data, loading, error }] = useUpdateAgeGroupMaleEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAgeGroupMaleEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAgeGroupMaleEventMutation, UpdateAgeGroupMaleEventMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAgeGroupMaleEventMutation, UpdateAgeGroupMaleEventMutationVariables>(UpdateAgeGroupMaleEventDocument, baseOptions);
      }
export type UpdateAgeGroupMaleEventMutationHookResult = ReturnType<typeof useUpdateAgeGroupMaleEventMutation>;
export type UpdateAgeGroupMaleEventMutationResult = Apollo.MutationResult<UpdateAgeGroupMaleEventMutation>;
export type UpdateAgeGroupMaleEventMutationOptions = Apollo.BaseMutationOptions<UpdateAgeGroupMaleEventMutation, UpdateAgeGroupMaleEventMutationVariables>;
export const DeleteCompetitionResultDocument = Apollo.gql`
    mutation DeleteCompetitionResult($id: UUID!) {
  deleteDirAthleteCompetitionResult(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteCompetitionResultMutationFn = Apollo.MutationFunction<DeleteCompetitionResultMutation, DeleteCompetitionResultMutationVariables>;

/**
 * __useDeleteCompetitionResultMutation__
 *
 * To run a mutation, you first call `useDeleteCompetitionResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompetitionResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompetitionResultMutation, { data, loading, error }] = useDeleteCompetitionResultMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompetitionResultMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompetitionResultMutation, DeleteCompetitionResultMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompetitionResultMutation, DeleteCompetitionResultMutationVariables>(DeleteCompetitionResultDocument, baseOptions);
      }
export type DeleteCompetitionResultMutationHookResult = ReturnType<typeof useDeleteCompetitionResultMutation>;
export type DeleteCompetitionResultMutationResult = Apollo.MutationResult<DeleteCompetitionResultMutation>;
export type DeleteCompetitionResultMutationOptions = Apollo.BaseMutationOptions<DeleteCompetitionResultMutation, DeleteCompetitionResultMutationVariables>;
export const SportingEventRequestDocument = Apollo.gql`
    query SportingEventRequest($filter: DirSportingEventRequestWhereInput!) {
  dirSportingEventRequests(where: $filter, orderBy: createdAt_DESC) {
    id
    createdAt
    updatedAt
    orderNumber
    type {
      value: id
      label: name
    }
    event {
      id
      startDate
      endDate
      venue
      isRussia
      dirRegions {
        label: fullName
        value: id
      }
      countries {
        id
      }
      isOrganizerOrParticipant
      isAgeRestricted
      registryNumber
      name
      dirRegions {
        label: fullName
        value: id
      }
      status {
        label: name
        value: id
      }
      ageGroups {
        id
        minAge
        maxAge
        clsAgeGroup {
          label: fullName
          value: id
        }
      }
      proxy {
        id
        startDate
        endDate
        venue
        isRussia
        dirRegions {
          label: fullName
          value: id
        }
        countries {
          id
        }
      }
      changes {
        id
        name
        startDate
        endDate
        venue
        isRussia
        countries {
          id
        }
        dirRegions {
          label: fullName
          value: id
        }
        proxy {
          id
        }
        requests {
          id
          createdAt
          orderNumber
          type {
            value: id
            label: name
          }
          statuses(orderBy: createdAt_DESC) {
            id
            createdAt
            updatedBy {
              id
              name
              email
              regular {
                id
                firstname
                lastname
                patronymic
                phone
                address
                dirOrganization {
                  id
                  name
                  legalAddress
                }
              }
            }
            comment
            value {
              value: id
              label: name
            }
            file {
              id
              name
              path
            }
          }
        }
      }
    }
    statuses(orderBy: createdAt_DESC) {
      id
      createdAt
      updatedBy {
        id
        name
        email
        regular {
          id
          firstname
          lastname
          patronymic
          phone
          address
          dirOrganization {
            id
            name
            legalAddress
          }
        }
      }
      comment
      value {
        value: id
        label: name
      }
      file {
        id
        name
        path
      }
    }
  }
}
    `;

/**
 * __useSportingEventRequestQuery__
 *
 * To run a query within a React component, call `useSportingEventRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventRequestQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSportingEventRequestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventRequestQuery, SportingEventRequestQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventRequestQuery, SportingEventRequestQueryVariables>(SportingEventRequestDocument, baseOptions);
      }
export function useSportingEventRequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventRequestQuery, SportingEventRequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventRequestQuery, SportingEventRequestQueryVariables>(SportingEventRequestDocument, baseOptions);
        }
export type SportingEventRequestQueryHookResult = ReturnType<typeof useSportingEventRequestQuery>;
export type SportingEventRequestLazyQueryHookResult = ReturnType<typeof useSportingEventRequestLazyQuery>;
export type SportingEventRequestQueryResult = Apollo.QueryResult<SportingEventRequestQuery, SportingEventRequestQueryVariables>;
export const SportingEventsDocument = Apollo.gql`
    query SportingEvents($first: Int, $skip: Int, $filter: DirSportingEventWhereInput, $orderBy: DirSportingEventOrderByInput) {
  dirSportingEvents(first: $first, skip: $skip, where: $filter, orderBy: $orderBy) {
    ...LightSportingEvent
  }
}
    ${LightSportingEventFragmentDoc}`;

/**
 * __useSportingEventsQuery__
 *
 * To run a query within a React component, call `useSportingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSportingEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventsQuery, SportingEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventsQuery, SportingEventsQueryVariables>(SportingEventsDocument, baseOptions);
      }
export function useSportingEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventsQuery, SportingEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventsQuery, SportingEventsQueryVariables>(SportingEventsDocument, baseOptions);
        }
export type SportingEventsQueryHookResult = ReturnType<typeof useSportingEventsQuery>;
export type SportingEventsLazyQueryHookResult = ReturnType<typeof useSportingEventsLazyQuery>;
export type SportingEventsQueryResult = Apollo.QueryResult<SportingEventsQuery, SportingEventsQueryVariables>;
export const SportingEventsAthleteDocsDocument = Apollo.gql`
    query SportingEventsAthleteDocs($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    athleteCallDocuments {
      title
      id
      date
      file {
        path
      }
    }
  }
}
    `;

/**
 * __useSportingEventsAthleteDocsQuery__
 *
 * To run a query within a React component, call `useSportingEventsAthleteDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventsAthleteDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventsAthleteDocsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventsAthleteDocsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventsAthleteDocsQuery, SportingEventsAthleteDocsQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventsAthleteDocsQuery, SportingEventsAthleteDocsQueryVariables>(SportingEventsAthleteDocsDocument, baseOptions);
      }
export function useSportingEventsAthleteDocsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventsAthleteDocsQuery, SportingEventsAthleteDocsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventsAthleteDocsQuery, SportingEventsAthleteDocsQueryVariables>(SportingEventsAthleteDocsDocument, baseOptions);
        }
export type SportingEventsAthleteDocsQueryHookResult = ReturnType<typeof useSportingEventsAthleteDocsQuery>;
export type SportingEventsAthleteDocsLazyQueryHookResult = ReturnType<typeof useSportingEventsAthleteDocsLazyQuery>;
export type SportingEventsAthleteDocsQueryResult = Apollo.QueryResult<SportingEventsAthleteDocsQuery, SportingEventsAthleteDocsQueryVariables>;
export const SportingEvents2ndDocument = Apollo.gql`
    query SportingEvents2nd($name: String!, $first: Int!, $skip: Int!, $filter: DirSportingEventWhereInput) {
  dirSportingEventsSimilar(search: $name, first: $first, skip: $skip, where: $filter) {
    sportingEvent {
      ...LightSportingEvent
    }
  }
}
    ${LightSportingEventFragmentDoc}`;

/**
 * __useSportingEvents2ndQuery__
 *
 * To run a query within a React component, call `useSportingEvents2ndQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEvents2ndQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEvents2ndQuery({
 *   variables: {
 *      name: // value for 'name'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSportingEvents2ndQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEvents2ndQuery, SportingEvents2ndQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEvents2ndQuery, SportingEvents2ndQueryVariables>(SportingEvents2ndDocument, baseOptions);
      }
export function useSportingEvents2ndLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEvents2ndQuery, SportingEvents2ndQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEvents2ndQuery, SportingEvents2ndQueryVariables>(SportingEvents2ndDocument, baseOptions);
        }
export type SportingEvents2ndQueryHookResult = ReturnType<typeof useSportingEvents2ndQuery>;
export type SportingEvents2ndLazyQueryHookResult = ReturnType<typeof useSportingEvents2ndLazyQuery>;
export type SportingEvents2ndQueryResult = Apollo.QueryResult<SportingEvents2ndQuery, SportingEvents2ndQueryVariables>;
export const SportingEventsCountDocument = Apollo.gql`
    query SportingEventsCount($filter: DirSportingEventWhereInput) {
  count: dirSportingEventsCount(where: $filter)
}
    `;

/**
 * __useSportingEventsCountQuery__
 *
 * To run a query within a React component, call `useSportingEventsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSportingEventsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventsCountQuery, SportingEventsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventsCountQuery, SportingEventsCountQueryVariables>(SportingEventsCountDocument, baseOptions);
      }
export function useSportingEventsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventsCountQuery, SportingEventsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventsCountQuery, SportingEventsCountQueryVariables>(SportingEventsCountDocument, baseOptions);
        }
export type SportingEventsCountQueryHookResult = ReturnType<typeof useSportingEventsCountQuery>;
export type SportingEventsCountLazyQueryHookResult = ReturnType<typeof useSportingEventsCountLazyQuery>;
export type SportingEventsCountQueryResult = Apollo.QueryResult<SportingEventsCountQuery, SportingEventsCountQueryVariables>;
export const SportingEventSportsDocument = Apollo.gql`
    query SportingEventSports($filter: DirSportingEventSportWhereInput) {
  dirSportingEventSports(where: $filter) {
    event {
      ageGroupFemale {
        id
        clsAgeGroup {
          value: id
          label: fullName
        }
      }
      ageGroupMale {
        id
        clsAgeGroup {
          value: id
          label: fullName
        }
      }
    }
    id
    dirSport {
      value: id
      label: fullName
      ageGroups {
        id
        maxAge
        minAge
        clsAgeGroup {
          value: id
          label: fullName
        }
      }
    }
    disciplines(orderBy: name_ASC) {
      value: id
      label: name
    }
    disciplineGroups(orderBy: name_ASC) {
      value: id
      label: name
    }
  }
}
    `;

/**
 * __useSportingEventSportsQuery__
 *
 * To run a query within a React component, call `useSportingEventSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventSportsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSportingEventSportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventSportsQuery, SportingEventSportsQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventSportsQuery, SportingEventSportsQueryVariables>(SportingEventSportsDocument, baseOptions);
      }
export function useSportingEventSportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventSportsQuery, SportingEventSportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventSportsQuery, SportingEventSportsQueryVariables>(SportingEventSportsDocument, baseOptions);
        }
export type SportingEventSportsQueryHookResult = ReturnType<typeof useSportingEventSportsQuery>;
export type SportingEventSportsLazyQueryHookResult = ReturnType<typeof useSportingEventSportsLazyQuery>;
export type SportingEventSportsQueryResult = Apollo.QueryResult<SportingEventSportsQuery, SportingEventSportsQueryVariables>;
export const SportingEventSportsDisciplinesGroupDocument = Apollo.gql`
    query SportingEventSportsDisciplinesGroup($filter: DirSportingEventSportWhereInput) {
  dirSportingEventSports(where: $filter) {
    id
    disciplineGroups(orderBy: name_ASC) {
      value: id
      label: name
    }
  }
}
    `;

/**
 * __useSportingEventSportsDisciplinesGroupQuery__
 *
 * To run a query within a React component, call `useSportingEventSportsDisciplinesGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventSportsDisciplinesGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventSportsDisciplinesGroupQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSportingEventSportsDisciplinesGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventSportsDisciplinesGroupQuery, SportingEventSportsDisciplinesGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventSportsDisciplinesGroupQuery, SportingEventSportsDisciplinesGroupQueryVariables>(SportingEventSportsDisciplinesGroupDocument, baseOptions);
      }
export function useSportingEventSportsDisciplinesGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventSportsDisciplinesGroupQuery, SportingEventSportsDisciplinesGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventSportsDisciplinesGroupQuery, SportingEventSportsDisciplinesGroupQueryVariables>(SportingEventSportsDisciplinesGroupDocument, baseOptions);
        }
export type SportingEventSportsDisciplinesGroupQueryHookResult = ReturnType<typeof useSportingEventSportsDisciplinesGroupQuery>;
export type SportingEventSportsDisciplinesGroupLazyQueryHookResult = ReturnType<typeof useSportingEventSportsDisciplinesGroupLazyQuery>;
export type SportingEventSportsDisciplinesGroupQueryResult = Apollo.QueryResult<SportingEventSportsDisciplinesGroupQuery, SportingEventSportsDisciplinesGroupQueryVariables>;
export const SportingEventSportsDisciplinesDocument = Apollo.gql`
    query SportingEventSportsDisciplines($filter: DirSportingEventSportWhereInput) {
  dirSportingEventSports(where: $filter) {
    id
    disciplines(orderBy: name_ASC) {
      value: id
      label: name
    }
  }
}
    `;

/**
 * __useSportingEventSportsDisciplinesQuery__
 *
 * To run a query within a React component, call `useSportingEventSportsDisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventSportsDisciplinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventSportsDisciplinesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSportingEventSportsDisciplinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventSportsDisciplinesQuery, SportingEventSportsDisciplinesQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventSportsDisciplinesQuery, SportingEventSportsDisciplinesQueryVariables>(SportingEventSportsDisciplinesDocument, baseOptions);
      }
export function useSportingEventSportsDisciplinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventSportsDisciplinesQuery, SportingEventSportsDisciplinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventSportsDisciplinesQuery, SportingEventSportsDisciplinesQueryVariables>(SportingEventSportsDisciplinesDocument, baseOptions);
        }
export type SportingEventSportsDisciplinesQueryHookResult = ReturnType<typeof useSportingEventSportsDisciplinesQuery>;
export type SportingEventSportsDisciplinesLazyQueryHookResult = ReturnType<typeof useSportingEventSportsDisciplinesLazyQuery>;
export type SportingEventSportsDisciplinesQueryResult = Apollo.QueryResult<SportingEventSportsDisciplinesQuery, SportingEventSportsDisciplinesQueryVariables>;
export const SportingEventDocument = Apollo.gql`
    query SportingEvent($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    ...SportingEvent
    proxy {
      ...SportingEvent
    }
  }
}
    ${SportingEventFragmentDoc}`;

/**
 * __useSportingEventQuery__
 *
 * To run a query within a React component, call `useSportingEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventQuery, SportingEventQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventQuery, SportingEventQueryVariables>(SportingEventDocument, baseOptions);
      }
export function useSportingEventLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventQuery, SportingEventQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventQuery, SportingEventQueryVariables>(SportingEventDocument, baseOptions);
        }
export type SportingEventQueryHookResult = ReturnType<typeof useSportingEventQuery>;
export type SportingEventLazyQueryHookResult = ReturnType<typeof useSportingEventLazyQuery>;
export type SportingEventQueryResult = Apollo.QueryResult<SportingEventQuery, SportingEventQueryVariables>;
export const SportEventRelativesDocument = Apollo.gql`
    query SportEventRelatives($id: UUID!, $parentFilter: DirSportingEventWhereInput!, $childrenFilter: DirSportingEventWhereInput!, $dChildrenFilter: DirSportingEventWhereInput) {
  children: dirSportingEvents(where: $childrenFilter) {
    value: id
    registryNumber
    label: name
    startDate
    endDate
    dirNationalTeams {
      id
      dirRegion {
        id
      }
    }
    sports {
      id
      dirSport {
        value: id
        label: fullName
      }
      disciplineGroups(orderBy: name_ASC) {
        value: id
        label: name
      }
    }
  }
  dChildren: dirSportingEvents(where: $dChildrenFilter) {
    value: id
    registryNumber
    label: name
    startDate
    endDate
    dirNationalTeams {
      id
      dirRegion {
        id
      }
    }
    sports {
      id
      dirSport {
        value: id
        label: fullName
      }
      disciplineGroups(orderBy: name_ASC) {
        value: id
        label: name
      }
    }
  }
  eventParent: dirSportingEvents(where: {id: $id, parent: $parentFilter}) {
    parent {
      value: id
      registryNumber
      label: name
      startDate
      endDate
      dirNationalTeams {
        id
        dirRegion {
          id
        }
      }
      sports {
        id
        dirSport {
          value: id
          label: fullName
        }
        disciplineGroups(orderBy: name_ASC) {
          value: id
          label: name
        }
      }
    }
  }
}
    `;

/**
 * __useSportEventRelativesQuery__
 *
 * To run a query within a React component, call `useSportEventRelativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportEventRelativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportEventRelativesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      parentFilter: // value for 'parentFilter'
 *      childrenFilter: // value for 'childrenFilter'
 *      dChildrenFilter: // value for 'dChildrenFilter'
 *   },
 * });
 */
export function useSportEventRelativesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportEventRelativesQuery, SportEventRelativesQueryVariables>) {
        return ApolloReactHooks.useQuery<SportEventRelativesQuery, SportEventRelativesQueryVariables>(SportEventRelativesDocument, baseOptions);
      }
export function useSportEventRelativesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportEventRelativesQuery, SportEventRelativesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportEventRelativesQuery, SportEventRelativesQueryVariables>(SportEventRelativesDocument, baseOptions);
        }
export type SportEventRelativesQueryHookResult = ReturnType<typeof useSportEventRelativesQuery>;
export type SportEventRelativesLazyQueryHookResult = ReturnType<typeof useSportEventRelativesLazyQuery>;
export type SportEventRelativesQueryResult = Apollo.QueryResult<SportEventRelativesQuery, SportEventRelativesQueryVariables>;
export const SportingEventProgramTypesDocument = Apollo.gql`
    query SportingEventProgramTypes($filter: DirSportingEventProgramTypeWhereInput!) {
  dirSportingEventProgramTypes(where: $filter) {
    id
    minAge
    maxAge
    start
    resultDocuments {
      id
      title
      number
      date
      clsType {
        value: id
        label: fullName
      }
      file {
        id
        path
      }
    }
    clsAgeGroups {
      value: id
      label: fullName
      shortName
    }
    discipline {
      value: id
      label: name
    }
  }
}
    `;

/**
 * __useSportingEventProgramTypesQuery__
 *
 * To run a query within a React component, call `useSportingEventProgramTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventProgramTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventProgramTypesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSportingEventProgramTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventProgramTypesQuery, SportingEventProgramTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventProgramTypesQuery, SportingEventProgramTypesQueryVariables>(SportingEventProgramTypesDocument, baseOptions);
      }
export function useSportingEventProgramTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventProgramTypesQuery, SportingEventProgramTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventProgramTypesQuery, SportingEventProgramTypesQueryVariables>(SportingEventProgramTypesDocument, baseOptions);
        }
export type SportingEventProgramTypesQueryHookResult = ReturnType<typeof useSportingEventProgramTypesQuery>;
export type SportingEventProgramTypesLazyQueryHookResult = ReturnType<typeof useSportingEventProgramTypesLazyQuery>;
export type SportingEventProgramTypesQueryResult = Apollo.QueryResult<SportingEventProgramTypesQuery, SportingEventProgramTypesQueryVariables>;
export const AnalyticsTotalCountUsersDocument = Apollo.gql`
    query AnalyticsTotalCountUsers($id: UUID!) {
  aggregateParticipantsNumberByEvent(where: {id: $id}) {
    name
    sports
    disciplineGroups
    subjectsCount
    athletesCount
    athletesCountByMan
    athletesCountByWoman
  }
}
    `;

/**
 * __useAnalyticsTotalCountUsersQuery__
 *
 * To run a query within a React component, call `useAnalyticsTotalCountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsTotalCountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsTotalCountUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnalyticsTotalCountUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AnalyticsTotalCountUsersQuery, AnalyticsTotalCountUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<AnalyticsTotalCountUsersQuery, AnalyticsTotalCountUsersQueryVariables>(AnalyticsTotalCountUsersDocument, baseOptions);
      }
export function useAnalyticsTotalCountUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnalyticsTotalCountUsersQuery, AnalyticsTotalCountUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AnalyticsTotalCountUsersQuery, AnalyticsTotalCountUsersQueryVariables>(AnalyticsTotalCountUsersDocument, baseOptions);
        }
export type AnalyticsTotalCountUsersQueryHookResult = ReturnType<typeof useAnalyticsTotalCountUsersQuery>;
export type AnalyticsTotalCountUsersLazyQueryHookResult = ReturnType<typeof useAnalyticsTotalCountUsersLazyQuery>;
export type AnalyticsTotalCountUsersQueryResult = Apollo.QueryResult<AnalyticsTotalCountUsersQuery, AnalyticsTotalCountUsersQueryVariables>;
export const AnalyticsTotalCountUsersParentDocument = Apollo.gql`
    query AnalyticsTotalCountUsersParent($id: UUID!) {
  aggregateParticipantsNumberByParentEvent(where: {id: $id}) {
    name
    children {
      name
      sports
      disciplineGroups
      subjectsCount
      athletesCount
      athletesCountByMan
      athletesCountByWoman
    }
  }
}
    `;

/**
 * __useAnalyticsTotalCountUsersParentQuery__
 *
 * To run a query within a React component, call `useAnalyticsTotalCountUsersParentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsTotalCountUsersParentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsTotalCountUsersParentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnalyticsTotalCountUsersParentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AnalyticsTotalCountUsersParentQuery, AnalyticsTotalCountUsersParentQueryVariables>) {
        return ApolloReactHooks.useQuery<AnalyticsTotalCountUsersParentQuery, AnalyticsTotalCountUsersParentQueryVariables>(AnalyticsTotalCountUsersParentDocument, baseOptions);
      }
export function useAnalyticsTotalCountUsersParentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnalyticsTotalCountUsersParentQuery, AnalyticsTotalCountUsersParentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AnalyticsTotalCountUsersParentQuery, AnalyticsTotalCountUsersParentQueryVariables>(AnalyticsTotalCountUsersParentDocument, baseOptions);
        }
export type AnalyticsTotalCountUsersParentQueryHookResult = ReturnType<typeof useAnalyticsTotalCountUsersParentQuery>;
export type AnalyticsTotalCountUsersParentLazyQueryHookResult = ReturnType<typeof useAnalyticsTotalCountUsersParentLazyQuery>;
export type AnalyticsTotalCountUsersParentQueryResult = Apollo.QueryResult<AnalyticsTotalCountUsersParentQuery, AnalyticsTotalCountUsersParentQueryVariables>;
export const SportingEventChildDocument = Apollo.gql`
    query SportingEventChild($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    children {
      id
    }
  }
}
    `;

/**
 * __useSportingEventChildQuery__
 *
 * To run a query within a React component, call `useSportingEventChildQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventChildQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventChildQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventChildQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventChildQuery, SportingEventChildQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventChildQuery, SportingEventChildQueryVariables>(SportingEventChildDocument, baseOptions);
      }
export function useSportingEventChildLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventChildQuery, SportingEventChildQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventChildQuery, SportingEventChildQueryVariables>(SportingEventChildDocument, baseOptions);
        }
export type SportingEventChildQueryHookResult = ReturnType<typeof useSportingEventChildQuery>;
export type SportingEventChildLazyQueryHookResult = ReturnType<typeof useSportingEventChildLazyQuery>;
export type SportingEventChildQueryResult = Apollo.QueryResult<SportingEventChildQuery, SportingEventChildQueryVariables>;
export const EventAgeGroupsDocument = Apollo.gql`
    query EventAgeGroups($id: UUID!) {
  EventAgeGroups: dirSportingEvent(where: {id: $id}) {
    ageGroupMale {
      id
      clsAgeGroup {
        value: id
        label: fullName
      }
      minAge
      maxAge
    }
    ageGroupFemale {
      id
      clsAgeGroup {
        value: id
        label: fullName
      }
      minAge
      maxAge
    }
  }
}
    `;

/**
 * __useEventAgeGroupsQuery__
 *
 * To run a query within a React component, call `useEventAgeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventAgeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventAgeGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventAgeGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventAgeGroupsQuery, EventAgeGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<EventAgeGroupsQuery, EventAgeGroupsQueryVariables>(EventAgeGroupsDocument, baseOptions);
      }
export function useEventAgeGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventAgeGroupsQuery, EventAgeGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventAgeGroupsQuery, EventAgeGroupsQueryVariables>(EventAgeGroupsDocument, baseOptions);
        }
export type EventAgeGroupsQueryHookResult = ReturnType<typeof useEventAgeGroupsQuery>;
export type EventAgeGroupsLazyQueryHookResult = ReturnType<typeof useEventAgeGroupsLazyQuery>;
export type EventAgeGroupsQueryResult = Apollo.QueryResult<EventAgeGroupsQuery, EventAgeGroupsQueryVariables>;
export const FindEventsDocument = Apollo.gql`
    query FindEvents($search: String!, $filter: DirSportingEventWhereInput) {
  data: dirSportingEventsSimilar(search: $search, where: $filter, threshold: 0.1) {
    sportingEvent {
      value: id
      label: name
    }
  }
}
    `;

/**
 * __useFindEventsQuery__
 *
 * To run a query within a React component, call `useFindEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEventsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindEventsQuery, FindEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<FindEventsQuery, FindEventsQueryVariables>(FindEventsDocument, baseOptions);
      }
export function useFindEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindEventsQuery, FindEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindEventsQuery, FindEventsQueryVariables>(FindEventsDocument, baseOptions);
        }
export type FindEventsQueryHookResult = ReturnType<typeof useFindEventsQuery>;
export type FindEventsLazyQueryHookResult = ReturnType<typeof useFindEventsLazyQuery>;
export type FindEventsQueryResult = Apollo.QueryResult<FindEventsQuery, FindEventsQueryVariables>;
export const EventsByRegistryNumberDocument = Apollo.gql`
    query EventsByRegistryNumber($filter: DirSportingEventWhereInput) {
  dirSportingEvents(where: $filter) {
    value: id
    label: name
  }
}
    `;

/**
 * __useEventsByRegistryNumberQuery__
 *
 * To run a query within a React component, call `useEventsByRegistryNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsByRegistryNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsByRegistryNumberQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEventsByRegistryNumberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventsByRegistryNumberQuery, EventsByRegistryNumberQueryVariables>) {
        return ApolloReactHooks.useQuery<EventsByRegistryNumberQuery, EventsByRegistryNumberQueryVariables>(EventsByRegistryNumberDocument, baseOptions);
      }
export function useEventsByRegistryNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsByRegistryNumberQuery, EventsByRegistryNumberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventsByRegistryNumberQuery, EventsByRegistryNumberQueryVariables>(EventsByRegistryNumberDocument, baseOptions);
        }
export type EventsByRegistryNumberQueryHookResult = ReturnType<typeof useEventsByRegistryNumberQuery>;
export type EventsByRegistryNumberLazyQueryHookResult = ReturnType<typeof useEventsByRegistryNumberLazyQuery>;
export type EventsByRegistryNumberQueryResult = Apollo.QueryResult<EventsByRegistryNumberQuery, EventsByRegistryNumberQueryVariables>;
export const EventFoDocument = Apollo.gql`
    query EventFO($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    id
    dirFederalDistricts {
      id
      fullName
    }
  }
}
    `;

/**
 * __useEventFoQuery__
 *
 * To run a query within a React component, call `useEventFoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventFoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventFoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventFoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventFoQuery, EventFoQueryVariables>) {
        return ApolloReactHooks.useQuery<EventFoQuery, EventFoQueryVariables>(EventFoDocument, baseOptions);
      }
export function useEventFoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventFoQuery, EventFoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventFoQuery, EventFoQueryVariables>(EventFoDocument, baseOptions);
        }
export type EventFoQueryHookResult = ReturnType<typeof useEventFoQuery>;
export type EventFoLazyQueryHookResult = ReturnType<typeof useEventFoLazyQuery>;
export type EventFoQueryResult = Apollo.QueryResult<EventFoQuery, EventFoQueryVariables>;
export const ValidateRegionByExistEventDocument = Apollo.gql`
    query validateRegionByExistEvent($idCalendar: UUID!, $idRegion: UUID!) {
  dirSportingEvents(where: {archive: null, dirCalendar: {id: $idCalendar}, dirRegion: {id: $idRegion}, clsEventStages_every: {registryNumber: 100002914}}) {
    id
    archive {
      id
    }
  }
}
    `;

/**
 * __useValidateRegionByExistEventQuery__
 *
 * To run a query within a React component, call `useValidateRegionByExistEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateRegionByExistEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateRegionByExistEventQuery({
 *   variables: {
 *      idCalendar: // value for 'idCalendar'
 *      idRegion: // value for 'idRegion'
 *   },
 * });
 */
export function useValidateRegionByExistEventQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidateRegionByExistEventQuery, ValidateRegionByExistEventQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidateRegionByExistEventQuery, ValidateRegionByExistEventQueryVariables>(ValidateRegionByExistEventDocument, baseOptions);
      }
export function useValidateRegionByExistEventLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidateRegionByExistEventQuery, ValidateRegionByExistEventQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidateRegionByExistEventQuery, ValidateRegionByExistEventQueryVariables>(ValidateRegionByExistEventDocument, baseOptions);
        }
export type ValidateRegionByExistEventQueryHookResult = ReturnType<typeof useValidateRegionByExistEventQuery>;
export type ValidateRegionByExistEventLazyQueryHookResult = ReturnType<typeof useValidateRegionByExistEventLazyQuery>;
export type ValidateRegionByExistEventQueryResult = Apollo.QueryResult<ValidateRegionByExistEventQuery, ValidateRegionByExistEventQueryVariables>;
export const EventTeamsListDocument = Apollo.gql`
    query EventTeamsList($filter: DirNationalTeamWhereInput!) {
  dirNationalTeams(where: $filter) {
    id
    dirRegion {
      id
      fullName
    }
  }
}
    `;

/**
 * __useEventTeamsListQuery__
 *
 * To run a query within a React component, call `useEventTeamsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventTeamsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventTeamsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEventTeamsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventTeamsListQuery, EventTeamsListQueryVariables>) {
        return ApolloReactHooks.useQuery<EventTeamsListQuery, EventTeamsListQueryVariables>(EventTeamsListDocument, baseOptions);
      }
export function useEventTeamsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventTeamsListQuery, EventTeamsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventTeamsListQuery, EventTeamsListQueryVariables>(EventTeamsListDocument, baseOptions);
        }
export type EventTeamsListQueryHookResult = ReturnType<typeof useEventTeamsListQuery>;
export type EventTeamsListLazyQueryHookResult = ReturnType<typeof useEventTeamsListLazyQuery>;
export type EventTeamsListQueryResult = Apollo.QueryResult<EventTeamsListQuery, EventTeamsListQueryVariables>;
export const SportingEventTeamInfoDocument = Apollo.gql`
    query SportingEventTeamInfo($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    ...TeamInfoFragment
  }
}
    ${TeamInfoFragmentFragmentDoc}`;

/**
 * __useSportingEventTeamInfoQuery__
 *
 * To run a query within a React component, call `useSportingEventTeamInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventTeamInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventTeamInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventTeamInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventTeamInfoQuery, SportingEventTeamInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventTeamInfoQuery, SportingEventTeamInfoQueryVariables>(SportingEventTeamInfoDocument, baseOptions);
      }
export function useSportingEventTeamInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventTeamInfoQuery, SportingEventTeamInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventTeamInfoQuery, SportingEventTeamInfoQueryVariables>(SportingEventTeamInfoDocument, baseOptions);
        }
export type SportingEventTeamInfoQueryHookResult = ReturnType<typeof useSportingEventTeamInfoQuery>;
export type SportingEventTeamInfoLazyQueryHookResult = ReturnType<typeof useSportingEventTeamInfoLazyQuery>;
export type SportingEventTeamInfoQueryResult = Apollo.QueryResult<SportingEventTeamInfoQuery, SportingEventTeamInfoQueryVariables>;
export const SportingEventUnArchivedTeamInfoDocument = Apollo.gql`
    query SportingEventUnArchivedTeamInfo($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    ...UnArchivedTeamInfoFragment
  }
}
    ${UnArchivedTeamInfoFragmentFragmentDoc}`;

/**
 * __useSportingEventUnArchivedTeamInfoQuery__
 *
 * To run a query within a React component, call `useSportingEventUnArchivedTeamInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventUnArchivedTeamInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventUnArchivedTeamInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventUnArchivedTeamInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventUnArchivedTeamInfoQuery, SportingEventUnArchivedTeamInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<SportingEventUnArchivedTeamInfoQuery, SportingEventUnArchivedTeamInfoQueryVariables>(SportingEventUnArchivedTeamInfoDocument, baseOptions);
      }
export function useSportingEventUnArchivedTeamInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventUnArchivedTeamInfoQuery, SportingEventUnArchivedTeamInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportingEventUnArchivedTeamInfoQuery, SportingEventUnArchivedTeamInfoQueryVariables>(SportingEventUnArchivedTeamInfoDocument, baseOptions);
        }
export type SportingEventUnArchivedTeamInfoQueryHookResult = ReturnType<typeof useSportingEventUnArchivedTeamInfoQuery>;
export type SportingEventUnArchivedTeamInfoLazyQueryHookResult = ReturnType<typeof useSportingEventUnArchivedTeamInfoLazyQuery>;
export type SportingEventUnArchivedTeamInfoQueryResult = Apollo.QueryResult<SportingEventUnArchivedTeamInfoQuery, SportingEventUnArchivedTeamInfoQueryVariables>;
export const UniversityOrganizationsDocument = Apollo.gql`
    query UniversityOrganizations($regionId: UUID!) {
  dirOrganizations(where: {dirRegion: {id: $regionId}, isUniversity: true}) {
    value: id
    label: name
  }
}
    `;

/**
 * __useUniversityOrganizationsQuery__
 *
 * To run a query within a React component, call `useUniversityOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniversityOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniversityOrganizationsQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useUniversityOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UniversityOrganizationsQuery, UniversityOrganizationsQueryVariables>) {
        return ApolloReactHooks.useQuery<UniversityOrganizationsQuery, UniversityOrganizationsQueryVariables>(UniversityOrganizationsDocument, baseOptions);
      }
export function useUniversityOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UniversityOrganizationsQuery, UniversityOrganizationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UniversityOrganizationsQuery, UniversityOrganizationsQueryVariables>(UniversityOrganizationsDocument, baseOptions);
        }
export type UniversityOrganizationsQueryHookResult = ReturnType<typeof useUniversityOrganizationsQuery>;
export type UniversityOrganizationsLazyQueryHookResult = ReturnType<typeof useUniversityOrganizationsLazyQuery>;
export type UniversityOrganizationsQueryResult = Apollo.QueryResult<UniversityOrganizationsQuery, UniversityOrganizationsQueryVariables>;
export const CreateUserSupportRequestDocument = Apollo.gql`
    mutation CreateUserSupportRequest($operator_id: UserCreateOneWithoutOperatorSupportRequestsInput, $user_id: UserCreateOneWithoutUserSupportRequestsInput, $text: String!, $file_id: FileCreateOneInput, $request_type: UserSupportRequestTypeCreateOneInput!) {
  createUserSupportRequest(data: {user: $user_id, operator: $operator_id, request: $text, requestType: $request_type, file: $file_id}) {
    id
  }
}
    `;
export type CreateUserSupportRequestMutationFn = Apollo.MutationFunction<CreateUserSupportRequestMutation, CreateUserSupportRequestMutationVariables>;

/**
 * __useCreateUserSupportRequestMutation__
 *
 * To run a mutation, you first call `useCreateUserSupportRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSupportRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSupportRequestMutation, { data, loading, error }] = useCreateUserSupportRequestMutation({
 *   variables: {
 *      operator_id: // value for 'operator_id'
 *      user_id: // value for 'user_id'
 *      text: // value for 'text'
 *      file_id: // value for 'file_id'
 *      request_type: // value for 'request_type'
 *   },
 * });
 */
export function useCreateUserSupportRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserSupportRequestMutation, CreateUserSupportRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserSupportRequestMutation, CreateUserSupportRequestMutationVariables>(CreateUserSupportRequestDocument, baseOptions);
      }
export type CreateUserSupportRequestMutationHookResult = ReturnType<typeof useCreateUserSupportRequestMutation>;
export type CreateUserSupportRequestMutationResult = Apollo.MutationResult<CreateUserSupportRequestMutation>;
export type CreateUserSupportRequestMutationOptions = Apollo.BaseMutationOptions<CreateUserSupportRequestMutation, CreateUserSupportRequestMutationVariables>;
export const CreateUserSupportRequestWithoutFileDocument = Apollo.gql`
    mutation CreateUserSupportRequestWithoutFile($operator_id: UserCreateOneWithoutOperatorSupportRequestsInput, $user_id: UserCreateOneWithoutUserSupportRequestsInput, $text: String!, $request_type: UserSupportRequestTypeCreateOneInput!) {
  createUserSupportRequest(data: {user: $user_id, operator: $operator_id, request: $text, requestType: $request_type}) {
    id
  }
}
    `;
export type CreateUserSupportRequestWithoutFileMutationFn = Apollo.MutationFunction<CreateUserSupportRequestWithoutFileMutation, CreateUserSupportRequestWithoutFileMutationVariables>;

/**
 * __useCreateUserSupportRequestWithoutFileMutation__
 *
 * To run a mutation, you first call `useCreateUserSupportRequestWithoutFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSupportRequestWithoutFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSupportRequestWithoutFileMutation, { data, loading, error }] = useCreateUserSupportRequestWithoutFileMutation({
 *   variables: {
 *      operator_id: // value for 'operator_id'
 *      user_id: // value for 'user_id'
 *      text: // value for 'text'
 *      request_type: // value for 'request_type'
 *   },
 * });
 */
export function useCreateUserSupportRequestWithoutFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserSupportRequestWithoutFileMutation, CreateUserSupportRequestWithoutFileMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserSupportRequestWithoutFileMutation, CreateUserSupportRequestWithoutFileMutationVariables>(CreateUserSupportRequestWithoutFileDocument, baseOptions);
      }
export type CreateUserSupportRequestWithoutFileMutationHookResult = ReturnType<typeof useCreateUserSupportRequestWithoutFileMutation>;
export type CreateUserSupportRequestWithoutFileMutationResult = Apollo.MutationResult<CreateUserSupportRequestWithoutFileMutation>;
export type CreateUserSupportRequestWithoutFileMutationOptions = Apollo.BaseMutationOptions<CreateUserSupportRequestWithoutFileMutation, CreateUserSupportRequestWithoutFileMutationVariables>;
export const UserSupportRequestTypesDocument = Apollo.gql`
    query UserSupportRequestTypes {
  userSupportRequestTypes {
    value: id
    label: name
  }
}
    `;

/**
 * __useUserSupportRequestTypesQuery__
 *
 * To run a query within a React component, call `useUserSupportRequestTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSupportRequestTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSupportRequestTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSupportRequestTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserSupportRequestTypesQuery, UserSupportRequestTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<UserSupportRequestTypesQuery, UserSupportRequestTypesQueryVariables>(UserSupportRequestTypesDocument, baseOptions);
      }
export function useUserSupportRequestTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSupportRequestTypesQuery, UserSupportRequestTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserSupportRequestTypesQuery, UserSupportRequestTypesQueryVariables>(UserSupportRequestTypesDocument, baseOptions);
        }
export type UserSupportRequestTypesQueryHookResult = ReturnType<typeof useUserSupportRequestTypesQuery>;
export type UserSupportRequestTypesLazyQueryHookResult = ReturnType<typeof useUserSupportRequestTypesLazyQuery>;
export type UserSupportRequestTypesQueryResult = Apollo.QueryResult<UserSupportRequestTypesQuery, UserSupportRequestTypesQueryVariables>;
export const CreateNewTrainerDocument = Apollo.gql`
    mutation CreateNewTrainer($idPerson: UUID!, $sportType: UUID!) {
  createDirTrainer(data: {dirPerson: {connect: {id: $idPerson}}, sports: {create: {dirSport: {connect: {id: $sportType}}}}}) {
    ...TrainerFR
  }
}
    ${TrainerFrFragmentDoc}`;
export type CreateNewTrainerMutationFn = Apollo.MutationFunction<CreateNewTrainerMutation, CreateNewTrainerMutationVariables>;

/**
 * __useCreateNewTrainerMutation__
 *
 * To run a mutation, you first call `useCreateNewTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewTrainerMutation, { data, loading, error }] = useCreateNewTrainerMutation({
 *   variables: {
 *      idPerson: // value for 'idPerson'
 *      sportType: // value for 'sportType'
 *   },
 * });
 */
export function useCreateNewTrainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewTrainerMutation, CreateNewTrainerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNewTrainerMutation, CreateNewTrainerMutationVariables>(CreateNewTrainerDocument, baseOptions);
      }
export type CreateNewTrainerMutationHookResult = ReturnType<typeof useCreateNewTrainerMutation>;
export type CreateNewTrainerMutationResult = Apollo.MutationResult<CreateNewTrainerMutation>;
export type CreateNewTrainerMutationOptions = Apollo.BaseMutationOptions<CreateNewTrainerMutation, CreateNewTrainerMutationVariables>;
export const UpdateTrainerSportDocument = Apollo.gql`
    mutation UpdateTrainerSport($id: UUID!, $data: DirTrainerSportUpdateInput!) {
  updateDirTrainerSport(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdateTrainerSportMutationFn = Apollo.MutationFunction<UpdateTrainerSportMutation, UpdateTrainerSportMutationVariables>;

/**
 * __useUpdateTrainerSportMutation__
 *
 * To run a mutation, you first call `useUpdateTrainerSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainerSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainerSportMutation, { data, loading, error }] = useUpdateTrainerSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTrainerSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTrainerSportMutation, UpdateTrainerSportMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTrainerSportMutation, UpdateTrainerSportMutationVariables>(UpdateTrainerSportDocument, baseOptions);
      }
export type UpdateTrainerSportMutationHookResult = ReturnType<typeof useUpdateTrainerSportMutation>;
export type UpdateTrainerSportMutationResult = Apollo.MutationResult<UpdateTrainerSportMutation>;
export type UpdateTrainerSportMutationOptions = Apollo.BaseMutationOptions<UpdateTrainerSportMutation, UpdateTrainerSportMutationVariables>;
export const TrainersDocument = Apollo.gql`
    query Trainers($first: Int!, $skip: Int!, $filter: DirTrainerWhereInput) {
  dirTrainers(first: $first, skip: $skip, where: $filter) {
    ...TrainerList
  }
}
    ${TrainerListFragmentDoc}`;

/**
 * __useTrainersQuery__
 *
 * To run a query within a React component, call `useTrainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTrainersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainersQuery, TrainersQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainersQuery, TrainersQueryVariables>(TrainersDocument, baseOptions);
      }
export function useTrainersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainersQuery, TrainersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainersQuery, TrainersQueryVariables>(TrainersDocument, baseOptions);
        }
export type TrainersQueryHookResult = ReturnType<typeof useTrainersQuery>;
export type TrainersLazyQueryHookResult = ReturnType<typeof useTrainersLazyQuery>;
export type TrainersQueryResult = Apollo.QueryResult<TrainersQuery, TrainersQueryVariables>;
export const TrainerTypeSportsDocument = Apollo.gql`
    query TrainerTypeSports($id: UUID!) {
  dirTrainerSports(where: {dirTrainer: {id: $id}}) {
    id
    dirSport {
      label: fullName
      value: id
    }
    disciplineGroups {
      label: name
      value: id
    }
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;

/**
 * __useTrainerTypeSportsQuery__
 *
 * To run a query within a React component, call `useTrainerTypeSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainerTypeSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainerTypeSportsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrainerTypeSportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainerTypeSportsQuery, TrainerTypeSportsQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainerTypeSportsQuery, TrainerTypeSportsQueryVariables>(TrainerTypeSportsDocument, baseOptions);
      }
export function useTrainerTypeSportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainerTypeSportsQuery, TrainerTypeSportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainerTypeSportsQuery, TrainerTypeSportsQueryVariables>(TrainerTypeSportsDocument, baseOptions);
        }
export type TrainerTypeSportsQueryHookResult = ReturnType<typeof useTrainerTypeSportsQuery>;
export type TrainerTypeSportsLazyQueryHookResult = ReturnType<typeof useTrainerTypeSportsLazyQuery>;
export type TrainerTypeSportsQueryResult = Apollo.QueryResult<TrainerTypeSportsQuery, TrainerTypeSportsQueryVariables>;
export const TrainersCountDocument = Apollo.gql`
    query TrainersCount($filter: DirTrainerWhereInput) {
  count: dirTrainersCount(where: $filter)
}
    `;

/**
 * __useTrainersCountQuery__
 *
 * To run a query within a React component, call `useTrainersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainersCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTrainersCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainersCountQuery, TrainersCountQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainersCountQuery, TrainersCountQueryVariables>(TrainersCountDocument, baseOptions);
      }
export function useTrainersCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainersCountQuery, TrainersCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainersCountQuery, TrainersCountQueryVariables>(TrainersCountDocument, baseOptions);
        }
export type TrainersCountQueryHookResult = ReturnType<typeof useTrainersCountQuery>;
export type TrainersCountLazyQueryHookResult = ReturnType<typeof useTrainersCountLazyQuery>;
export type TrainersCountQueryResult = Apollo.QueryResult<TrainersCountQuery, TrainersCountQueryVariables>;
export const TrainerByIdDocument = Apollo.gql`
    query TrainerById($id: UUID!) {
  dirTrainer(where: {id: $id}) {
    ...TrainerFR
  }
}
    ${TrainerFrFragmentDoc}`;

/**
 * __useTrainerByIdQuery__
 *
 * To run a query within a React component, call `useTrainerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrainerByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainerByIdQuery, TrainerByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainerByIdQuery, TrainerByIdQueryVariables>(TrainerByIdDocument, baseOptions);
      }
export function useTrainerByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainerByIdQuery, TrainerByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainerByIdQuery, TrainerByIdQueryVariables>(TrainerByIdDocument, baseOptions);
        }
export type TrainerByIdQueryHookResult = ReturnType<typeof useTrainerByIdQuery>;
export type TrainerByIdLazyQueryHookResult = ReturnType<typeof useTrainerByIdLazyQuery>;
export type TrainerByIdQueryResult = Apollo.QueryResult<TrainerByIdQuery, TrainerByIdQueryVariables>;
export const TrainersFilteredDocument = Apollo.gql`
    query TrainersFiltered($filter: DirTrainerWhereInput) {
  dirTrainers(where: $filter) {
    ...TrainerFR
  }
}
    ${TrainerFrFragmentDoc}`;

/**
 * __useTrainersFilteredQuery__
 *
 * To run a query within a React component, call `useTrainersFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainersFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainersFilteredQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTrainersFilteredQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainersFilteredQuery, TrainersFilteredQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainersFilteredQuery, TrainersFilteredQueryVariables>(TrainersFilteredDocument, baseOptions);
      }
export function useTrainersFilteredLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainersFilteredQuery, TrainersFilteredQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainersFilteredQuery, TrainersFilteredQueryVariables>(TrainersFilteredDocument, baseOptions);
        }
export type TrainersFilteredQueryHookResult = ReturnType<typeof useTrainersFilteredQuery>;
export type TrainersFilteredLazyQueryHookResult = ReturnType<typeof useTrainersFilteredLazyQuery>;
export type TrainersFilteredQueryResult = Apollo.QueryResult<TrainersFilteredQuery, TrainersFilteredQueryVariables>;
export const TrainersSimilarDocument = Apollo.gql`
    query TrainersSimilar($name: String!, $first: Int!, $skip: Int!, $filter: DirTrainerWhereInput) {
  dirPersonsSimilar(search: $name, where: {trainerProfile: $filter}, first: $first, skip: $skip, threshold: 0.3) {
    similarity
    person {
      id
      trainerProfile {
        ...TrainerList
      }
    }
  }
}
    ${TrainerListFragmentDoc}`;

/**
 * __useTrainersSimilarQuery__
 *
 * To run a query within a React component, call `useTrainersSimilarQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainersSimilarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainersSimilarQuery({
 *   variables: {
 *      name: // value for 'name'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTrainersSimilarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainersSimilarQuery, TrainersSimilarQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainersSimilarQuery, TrainersSimilarQueryVariables>(TrainersSimilarDocument, baseOptions);
      }
export function useTrainersSimilarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainersSimilarQuery, TrainersSimilarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainersSimilarQuery, TrainersSimilarQueryVariables>(TrainersSimilarDocument, baseOptions);
        }
export type TrainersSimilarQueryHookResult = ReturnType<typeof useTrainersSimilarQuery>;
export type TrainersSimilarLazyQueryHookResult = ReturnType<typeof useTrainersSimilarLazyQuery>;
export type TrainersSimilarQueryResult = Apollo.QueryResult<TrainersSimilarQuery, TrainersSimilarQueryVariables>;
export const TrainersSimilarCountDocument = Apollo.gql`
    query TrainersSimilarCount($name: String!, $filter: DirTrainerWhereInput) {
  dirPersonsSimilar(search: $name, where: {trainerProfile: $filter}, threshold: 0.3) {
    similarity
    person {
      id
    }
  }
}
    `;

/**
 * __useTrainersSimilarCountQuery__
 *
 * To run a query within a React component, call `useTrainersSimilarCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainersSimilarCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainersSimilarCountQuery({
 *   variables: {
 *      name: // value for 'name'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTrainersSimilarCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainersSimilarCountQuery, TrainersSimilarCountQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainersSimilarCountQuery, TrainersSimilarCountQueryVariables>(TrainersSimilarCountDocument, baseOptions);
      }
export function useTrainersSimilarCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainersSimilarCountQuery, TrainersSimilarCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainersSimilarCountQuery, TrainersSimilarCountQueryVariables>(TrainersSimilarCountDocument, baseOptions);
        }
export type TrainersSimilarCountQueryHookResult = ReturnType<typeof useTrainersSimilarCountQuery>;
export type TrainersSimilarCountLazyQueryHookResult = ReturnType<typeof useTrainersSimilarCountLazyQuery>;
export type TrainersSimilarCountQueryResult = Apollo.QueryResult<TrainersSimilarCountQuery, TrainersSimilarCountQueryVariables>;
export const TypeSportAgeGroupsRemoveDocument = Apollo.gql`
    mutation TypeSportAgeGroupsRemove($id: UUID!, $idCls: UUID!) {
  updateDirSport(where: {id: $id}, data: {ageGroups: {delete: {id: $idCls}}}) {
    ...AgeGroup
  }
}
    ${AgeGroupFragmentDoc}`;
export type TypeSportAgeGroupsRemoveMutationFn = Apollo.MutationFunction<TypeSportAgeGroupsRemoveMutation, TypeSportAgeGroupsRemoveMutationVariables>;

/**
 * __useTypeSportAgeGroupsRemoveMutation__
 *
 * To run a mutation, you first call `useTypeSportAgeGroupsRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAgeGroupsRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAgeGroupsRemoveMutation, { data, loading, error }] = useTypeSportAgeGroupsRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idCls: // value for 'idCls'
 *   },
 * });
 */
export function useTypeSportAgeGroupsRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportAgeGroupsRemoveMutation, TypeSportAgeGroupsRemoveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportAgeGroupsRemoveMutation, TypeSportAgeGroupsRemoveMutationVariables>(TypeSportAgeGroupsRemoveDocument, baseOptions);
      }
export type TypeSportAgeGroupsRemoveMutationHookResult = ReturnType<typeof useTypeSportAgeGroupsRemoveMutation>;
export type TypeSportAgeGroupsRemoveMutationResult = Apollo.MutationResult<TypeSportAgeGroupsRemoveMutation>;
export type TypeSportAgeGroupsRemoveMutationOptions = Apollo.BaseMutationOptions<TypeSportAgeGroupsRemoveMutation, TypeSportAgeGroupsRemoveMutationVariables>;
export const TypeSportAgeGroupsUpdateDocument = Apollo.gql`
    mutation TypeSportAgeGroupsUpdate($id: UUID!, $idGroup: UUID!, $idCls: UUID!, $minAge: Int!, $maxAge: Int) {
  updateDirSport(where: {id: $id}, data: {ageGroups: {update: {where: {id: $idGroup}, data: {clsAgeGroup: {connect: {id: $idCls}}, minAge: $minAge, maxAge: $maxAge}}}}) {
    ...AgeGroup
  }
}
    ${AgeGroupFragmentDoc}`;
export type TypeSportAgeGroupsUpdateMutationFn = Apollo.MutationFunction<TypeSportAgeGroupsUpdateMutation, TypeSportAgeGroupsUpdateMutationVariables>;

/**
 * __useTypeSportAgeGroupsUpdateMutation__
 *
 * To run a mutation, you first call `useTypeSportAgeGroupsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAgeGroupsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAgeGroupsUpdateMutation, { data, loading, error }] = useTypeSportAgeGroupsUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idGroup: // value for 'idGroup'
 *      idCls: // value for 'idCls'
 *      minAge: // value for 'minAge'
 *      maxAge: // value for 'maxAge'
 *   },
 * });
 */
export function useTypeSportAgeGroupsUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportAgeGroupsUpdateMutation, TypeSportAgeGroupsUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportAgeGroupsUpdateMutation, TypeSportAgeGroupsUpdateMutationVariables>(TypeSportAgeGroupsUpdateDocument, baseOptions);
      }
export type TypeSportAgeGroupsUpdateMutationHookResult = ReturnType<typeof useTypeSportAgeGroupsUpdateMutation>;
export type TypeSportAgeGroupsUpdateMutationResult = Apollo.MutationResult<TypeSportAgeGroupsUpdateMutation>;
export type TypeSportAgeGroupsUpdateMutationOptions = Apollo.BaseMutationOptions<TypeSportAgeGroupsUpdateMutation, TypeSportAgeGroupsUpdateMutationVariables>;
export const TypeSportAgeGroupsCreateDocument = Apollo.gql`
    mutation TypeSportAgeGroupsCreate($id: UUID!, $idCls: UUID!, $minAge: Int!, $maxAge: Int) {
  updateDirSport(where: {id: $id}, data: {ageGroups: {create: {clsAgeGroup: {connect: {id: $idCls}}, minAge: $minAge, maxAge: $maxAge}}}) {
    ...AgeGroup
  }
}
    ${AgeGroupFragmentDoc}`;
export type TypeSportAgeGroupsCreateMutationFn = Apollo.MutationFunction<TypeSportAgeGroupsCreateMutation, TypeSportAgeGroupsCreateMutationVariables>;

/**
 * __useTypeSportAgeGroupsCreateMutation__
 *
 * To run a mutation, you first call `useTypeSportAgeGroupsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAgeGroupsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAgeGroupsCreateMutation, { data, loading, error }] = useTypeSportAgeGroupsCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idCls: // value for 'idCls'
 *      minAge: // value for 'minAge'
 *      maxAge: // value for 'maxAge'
 *   },
 * });
 */
export function useTypeSportAgeGroupsCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportAgeGroupsCreateMutation, TypeSportAgeGroupsCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportAgeGroupsCreateMutation, TypeSportAgeGroupsCreateMutationVariables>(TypeSportAgeGroupsCreateDocument, baseOptions);
      }
export type TypeSportAgeGroupsCreateMutationHookResult = ReturnType<typeof useTypeSportAgeGroupsCreateMutation>;
export type TypeSportAgeGroupsCreateMutationResult = Apollo.MutationResult<TypeSportAgeGroupsCreateMutation>;
export type TypeSportAgeGroupsCreateMutationOptions = Apollo.BaseMutationOptions<TypeSportAgeGroupsCreateMutation, TypeSportAgeGroupsCreateMutationVariables>;
export const TypeSportAgeGroupsArchiveDocument = Apollo.gql`
    mutation TypeSportAgeGroupsArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirSportAgeGroup(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportAgeGroupsArchiveMutationFn = Apollo.MutationFunction<TypeSportAgeGroupsArchiveMutation, TypeSportAgeGroupsArchiveMutationVariables>;

/**
 * __useTypeSportAgeGroupsArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportAgeGroupsArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAgeGroupsArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAgeGroupsArchiveMutation, { data, loading, error }] = useTypeSportAgeGroupsArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useTypeSportAgeGroupsArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportAgeGroupsArchiveMutation, TypeSportAgeGroupsArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportAgeGroupsArchiveMutation, TypeSportAgeGroupsArchiveMutationVariables>(TypeSportAgeGroupsArchiveDocument, baseOptions);
      }
export type TypeSportAgeGroupsArchiveMutationHookResult = ReturnType<typeof useTypeSportAgeGroupsArchiveMutation>;
export type TypeSportAgeGroupsArchiveMutationResult = Apollo.MutationResult<TypeSportAgeGroupsArchiveMutation>;
export type TypeSportAgeGroupsArchiveMutationOptions = Apollo.BaseMutationOptions<TypeSportAgeGroupsArchiveMutation, TypeSportAgeGroupsArchiveMutationVariables>;
export const TypeSportAgeGroupsUnArchiveDocument = Apollo.gql`
    mutation TypeSportAgeGroupsUnArchive($id: UUID!) {
  updateDirSportAgeGroup(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportAgeGroupsUnArchiveMutationFn = Apollo.MutationFunction<TypeSportAgeGroupsUnArchiveMutation, TypeSportAgeGroupsUnArchiveMutationVariables>;

/**
 * __useTypeSportAgeGroupsUnArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportAgeGroupsUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAgeGroupsUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAgeGroupsUnArchiveMutation, { data, loading, error }] = useTypeSportAgeGroupsUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportAgeGroupsUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportAgeGroupsUnArchiveMutation, TypeSportAgeGroupsUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportAgeGroupsUnArchiveMutation, TypeSportAgeGroupsUnArchiveMutationVariables>(TypeSportAgeGroupsUnArchiveDocument, baseOptions);
      }
export type TypeSportAgeGroupsUnArchiveMutationHookResult = ReturnType<typeof useTypeSportAgeGroupsUnArchiveMutation>;
export type TypeSportAgeGroupsUnArchiveMutationResult = Apollo.MutationResult<TypeSportAgeGroupsUnArchiveMutation>;
export type TypeSportAgeGroupsUnArchiveMutationOptions = Apollo.BaseMutationOptions<TypeSportAgeGroupsUnArchiveMutation, TypeSportAgeGroupsUnArchiveMutationVariables>;
export const TypeSportAgeGroupsRequirementsCreateDocument = Apollo.gql`
    mutation TypeSportAgeGroupsRequirementsCreate($idAgeGroup: UUID!, $clsRanksAndAwards: UUID, $clsCalendarCategory: UUID) {
  updateDirSportAgeGroup(where: {id: $idAgeGroup}, data: {participationRequirements: {create: {clsRanksAndAwards: {connect: {id: $clsRanksAndAwards}}, clsCalendarCategory: {connect: {id: $clsCalendarCategory}}}}}) {
    id
  }
}
    `;
export type TypeSportAgeGroupsRequirementsCreateMutationFn = Apollo.MutationFunction<TypeSportAgeGroupsRequirementsCreateMutation, TypeSportAgeGroupsRequirementsCreateMutationVariables>;

/**
 * __useTypeSportAgeGroupsRequirementsCreateMutation__
 *
 * To run a mutation, you first call `useTypeSportAgeGroupsRequirementsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAgeGroupsRequirementsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAgeGroupsRequirementsCreateMutation, { data, loading, error }] = useTypeSportAgeGroupsRequirementsCreateMutation({
 *   variables: {
 *      idAgeGroup: // value for 'idAgeGroup'
 *      clsRanksAndAwards: // value for 'clsRanksAndAwards'
 *      clsCalendarCategory: // value for 'clsCalendarCategory'
 *   },
 * });
 */
export function useTypeSportAgeGroupsRequirementsCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportAgeGroupsRequirementsCreateMutation, TypeSportAgeGroupsRequirementsCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportAgeGroupsRequirementsCreateMutation, TypeSportAgeGroupsRequirementsCreateMutationVariables>(TypeSportAgeGroupsRequirementsCreateDocument, baseOptions);
      }
export type TypeSportAgeGroupsRequirementsCreateMutationHookResult = ReturnType<typeof useTypeSportAgeGroupsRequirementsCreateMutation>;
export type TypeSportAgeGroupsRequirementsCreateMutationResult = Apollo.MutationResult<TypeSportAgeGroupsRequirementsCreateMutation>;
export type TypeSportAgeGroupsRequirementsCreateMutationOptions = Apollo.BaseMutationOptions<TypeSportAgeGroupsRequirementsCreateMutation, TypeSportAgeGroupsRequirementsCreateMutationVariables>;
export const TypeSportAgeGroupsRequirementsDeleteDocument = Apollo.gql`
    mutation TypeSportAgeGroupsRequirementsDelete($idAgeGroup: UUID!, $id: UUID!) {
  updateDirSportAgeGroup(where: {id: $idAgeGroup}, data: {participationRequirements: {delete: {id: $id}}}) {
    id
  }
}
    `;
export type TypeSportAgeGroupsRequirementsDeleteMutationFn = Apollo.MutationFunction<TypeSportAgeGroupsRequirementsDeleteMutation, TypeSportAgeGroupsRequirementsDeleteMutationVariables>;

/**
 * __useTypeSportAgeGroupsRequirementsDeleteMutation__
 *
 * To run a mutation, you first call `useTypeSportAgeGroupsRequirementsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAgeGroupsRequirementsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAgeGroupsRequirementsDeleteMutation, { data, loading, error }] = useTypeSportAgeGroupsRequirementsDeleteMutation({
 *   variables: {
 *      idAgeGroup: // value for 'idAgeGroup'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportAgeGroupsRequirementsDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportAgeGroupsRequirementsDeleteMutation, TypeSportAgeGroupsRequirementsDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportAgeGroupsRequirementsDeleteMutation, TypeSportAgeGroupsRequirementsDeleteMutationVariables>(TypeSportAgeGroupsRequirementsDeleteDocument, baseOptions);
      }
export type TypeSportAgeGroupsRequirementsDeleteMutationHookResult = ReturnType<typeof useTypeSportAgeGroupsRequirementsDeleteMutation>;
export type TypeSportAgeGroupsRequirementsDeleteMutationResult = Apollo.MutationResult<TypeSportAgeGroupsRequirementsDeleteMutation>;
export type TypeSportAgeGroupsRequirementsDeleteMutationOptions = Apollo.BaseMutationOptions<TypeSportAgeGroupsRequirementsDeleteMutation, TypeSportAgeGroupsRequirementsDeleteMutationVariables>;
export const TypeSportAgeGroupsDocument = Apollo.gql`
    query TypeSportAgeGroups($id: UUID!) {
  dirSport(where: {id: $id}) {
    ...AgeGroup
  }
}
    ${AgeGroupFragmentDoc}`;

/**
 * __useTypeSportAgeGroupsQuery__
 *
 * To run a query within a React component, call `useTypeSportAgeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAgeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportAgeGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportAgeGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportAgeGroupsQuery, TypeSportAgeGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<TypeSportAgeGroupsQuery, TypeSportAgeGroupsQueryVariables>(TypeSportAgeGroupsDocument, baseOptions);
      }
export function useTypeSportAgeGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportAgeGroupsQuery, TypeSportAgeGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TypeSportAgeGroupsQuery, TypeSportAgeGroupsQueryVariables>(TypeSportAgeGroupsDocument, baseOptions);
        }
export type TypeSportAgeGroupsQueryHookResult = ReturnType<typeof useTypeSportAgeGroupsQuery>;
export type TypeSportAgeGroupsLazyQueryHookResult = ReturnType<typeof useTypeSportAgeGroupsLazyQuery>;
export type TypeSportAgeGroupsQueryResult = Apollo.QueryResult<TypeSportAgeGroupsQuery, TypeSportAgeGroupsQueryVariables>;
export const RemoveBaseRegionByIdDocument = Apollo.gql`
    mutation RemoveBaseRegionByID($id: UUID!, $idBaseRegion: UUID!) {
  updateDirSport(where: {id: $id}, data: {baseRegions: {delete: {id: $idBaseRegion}}}) {
    id
    ...BaseRegion
  }
}
    ${BaseRegionFragmentDoc}`;
export type RemoveBaseRegionByIdMutationFn = Apollo.MutationFunction<RemoveBaseRegionByIdMutation, RemoveBaseRegionByIdMutationVariables>;

/**
 * __useRemoveBaseRegionByIdMutation__
 *
 * To run a mutation, you first call `useRemoveBaseRegionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBaseRegionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBaseRegionByIdMutation, { data, loading, error }] = useRemoveBaseRegionByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idBaseRegion: // value for 'idBaseRegion'
 *   },
 * });
 */
export function useRemoveBaseRegionByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveBaseRegionByIdMutation, RemoveBaseRegionByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveBaseRegionByIdMutation, RemoveBaseRegionByIdMutationVariables>(RemoveBaseRegionByIdDocument, baseOptions);
      }
export type RemoveBaseRegionByIdMutationHookResult = ReturnType<typeof useRemoveBaseRegionByIdMutation>;
export type RemoveBaseRegionByIdMutationResult = Apollo.MutationResult<RemoveBaseRegionByIdMutation>;
export type RemoveBaseRegionByIdMutationOptions = Apollo.BaseMutationOptions<RemoveBaseRegionByIdMutation, RemoveBaseRegionByIdMutationVariables>;
export const UpdateBaseRegionByIdDocument = Apollo.gql`
    mutation UpdateBaseRegionByID($id: UUID!, $idBaseRegion: UUID!, $idRegion: UUID!, $startDate: DateTime, $endDate: DateTime) {
  updateDirSport(where: {id: $id}, data: {baseRegions: {update: {where: {id: $idBaseRegion}, data: {dirRegion: {connect: {id: $idRegion}}, startDate: $startDate, endDate: $endDate}}}}) {
    id
    ...BaseRegion
  }
}
    ${BaseRegionFragmentDoc}`;
export type UpdateBaseRegionByIdMutationFn = Apollo.MutationFunction<UpdateBaseRegionByIdMutation, UpdateBaseRegionByIdMutationVariables>;

/**
 * __useUpdateBaseRegionByIdMutation__
 *
 * To run a mutation, you first call `useUpdateBaseRegionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBaseRegionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBaseRegionByIdMutation, { data, loading, error }] = useUpdateBaseRegionByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idBaseRegion: // value for 'idBaseRegion'
 *      idRegion: // value for 'idRegion'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpdateBaseRegionByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBaseRegionByIdMutation, UpdateBaseRegionByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBaseRegionByIdMutation, UpdateBaseRegionByIdMutationVariables>(UpdateBaseRegionByIdDocument, baseOptions);
      }
export type UpdateBaseRegionByIdMutationHookResult = ReturnType<typeof useUpdateBaseRegionByIdMutation>;
export type UpdateBaseRegionByIdMutationResult = Apollo.MutationResult<UpdateBaseRegionByIdMutation>;
export type UpdateBaseRegionByIdMutationOptions = Apollo.BaseMutationOptions<UpdateBaseRegionByIdMutation, UpdateBaseRegionByIdMutationVariables>;
export const CreteBaseRegionDocument = Apollo.gql`
    mutation CreteBaseRegion($id: UUID!, $idRegion: UUID!, $startDate: DateTime, $endDate: DateTime) {
  updateDirSport(where: {id: $id}, data: {baseRegions: {create: {dirRegion: {connect: {id: $idRegion}}, startDate: $startDate, endDate: $endDate}}}) {
    id
    ...BaseRegion
  }
}
    ${BaseRegionFragmentDoc}`;
export type CreteBaseRegionMutationFn = Apollo.MutationFunction<CreteBaseRegionMutation, CreteBaseRegionMutationVariables>;

/**
 * __useCreteBaseRegionMutation__
 *
 * To run a mutation, you first call `useCreteBaseRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreteBaseRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creteBaseRegionMutation, { data, loading, error }] = useCreteBaseRegionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idRegion: // value for 'idRegion'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCreteBaseRegionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreteBaseRegionMutation, CreteBaseRegionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreteBaseRegionMutation, CreteBaseRegionMutationVariables>(CreteBaseRegionDocument, baseOptions);
      }
export type CreteBaseRegionMutationHookResult = ReturnType<typeof useCreteBaseRegionMutation>;
export type CreteBaseRegionMutationResult = Apollo.MutationResult<CreteBaseRegionMutation>;
export type CreteBaseRegionMutationOptions = Apollo.BaseMutationOptions<CreteBaseRegionMutation, CreteBaseRegionMutationVariables>;
export const BaseRegionsByIdDocument = Apollo.gql`
    query BaseRegionsById($id: UUID!) {
  dirSport(where: {id: $id}) {
    id
    ...BaseRegion
  }
}
    ${BaseRegionFragmentDoc}`;

/**
 * __useBaseRegionsByIdQuery__
 *
 * To run a query within a React component, call `useBaseRegionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseRegionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseRegionsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBaseRegionsByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BaseRegionsByIdQuery, BaseRegionsByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<BaseRegionsByIdQuery, BaseRegionsByIdQueryVariables>(BaseRegionsByIdDocument, baseOptions);
      }
export function useBaseRegionsByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BaseRegionsByIdQuery, BaseRegionsByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BaseRegionsByIdQuery, BaseRegionsByIdQueryVariables>(BaseRegionsByIdDocument, baseOptions);
        }
export type BaseRegionsByIdQueryHookResult = ReturnType<typeof useBaseRegionsByIdQuery>;
export type BaseRegionsByIdLazyQueryHookResult = ReturnType<typeof useBaseRegionsByIdLazyQuery>;
export type BaseRegionsByIdQueryResult = Apollo.QueryResult<BaseRegionsByIdQuery, BaseRegionsByIdQueryVariables>;
export const TypeSportDisciplinesUpdateDocument = Apollo.gql`
    mutation TypeSportDisciplinesUpdate($id: UUID!, $idDiscipline: UUID!, $name: String!, $code: String) {
  updateDirSport(where: {id: $id}, data: {disciplines: {update: {where: {id: $idDiscipline}, data: {name: $name, code: $code}}}}) {
    ...Disciplines
  }
}
    ${DisciplinesFragmentDoc}`;
export type TypeSportDisciplinesUpdateMutationFn = Apollo.MutationFunction<TypeSportDisciplinesUpdateMutation, TypeSportDisciplinesUpdateMutationVariables>;

/**
 * __useTypeSportDisciplinesUpdateMutation__
 *
 * To run a mutation, you first call `useTypeSportDisciplinesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportDisciplinesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportDisciplinesUpdateMutation, { data, loading, error }] = useTypeSportDisciplinesUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idDiscipline: // value for 'idDiscipline'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useTypeSportDisciplinesUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportDisciplinesUpdateMutation, TypeSportDisciplinesUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportDisciplinesUpdateMutation, TypeSportDisciplinesUpdateMutationVariables>(TypeSportDisciplinesUpdateDocument, baseOptions);
      }
export type TypeSportDisciplinesUpdateMutationHookResult = ReturnType<typeof useTypeSportDisciplinesUpdateMutation>;
export type TypeSportDisciplinesUpdateMutationResult = Apollo.MutationResult<TypeSportDisciplinesUpdateMutation>;
export type TypeSportDisciplinesUpdateMutationOptions = Apollo.BaseMutationOptions<TypeSportDisciplinesUpdateMutation, TypeSportDisciplinesUpdateMutationVariables>;
export const TypeSportDisciplinesCreateDocument = Apollo.gql`
    mutation TypeSportDisciplinesCreate($id: UUID!, $name: String!, $code: String) {
  updateDirSport(where: {id: $id}, data: {disciplines: {create: {name: $name, code: $code}}}) {
    ...Disciplines
  }
}
    ${DisciplinesFragmentDoc}`;
export type TypeSportDisciplinesCreateMutationFn = Apollo.MutationFunction<TypeSportDisciplinesCreateMutation, TypeSportDisciplinesCreateMutationVariables>;

/**
 * __useTypeSportDisciplinesCreateMutation__
 *
 * To run a mutation, you first call `useTypeSportDisciplinesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportDisciplinesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportDisciplinesCreateMutation, { data, loading, error }] = useTypeSportDisciplinesCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useTypeSportDisciplinesCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportDisciplinesCreateMutation, TypeSportDisciplinesCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportDisciplinesCreateMutation, TypeSportDisciplinesCreateMutationVariables>(TypeSportDisciplinesCreateDocument, baseOptions);
      }
export type TypeSportDisciplinesCreateMutationHookResult = ReturnType<typeof useTypeSportDisciplinesCreateMutation>;
export type TypeSportDisciplinesCreateMutationResult = Apollo.MutationResult<TypeSportDisciplinesCreateMutation>;
export type TypeSportDisciplinesCreateMutationOptions = Apollo.BaseMutationOptions<TypeSportDisciplinesCreateMutation, TypeSportDisciplinesCreateMutationVariables>;
export const TypeSportDisciplinesArchiveDocument = Apollo.gql`
    mutation TypeSportDisciplinesArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirSportDiscipline(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportDisciplinesArchiveMutationFn = Apollo.MutationFunction<TypeSportDisciplinesArchiveMutation, TypeSportDisciplinesArchiveMutationVariables>;

/**
 * __useTypeSportDisciplinesArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportDisciplinesArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportDisciplinesArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportDisciplinesArchiveMutation, { data, loading, error }] = useTypeSportDisciplinesArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useTypeSportDisciplinesArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportDisciplinesArchiveMutation, TypeSportDisciplinesArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportDisciplinesArchiveMutation, TypeSportDisciplinesArchiveMutationVariables>(TypeSportDisciplinesArchiveDocument, baseOptions);
      }
export type TypeSportDisciplinesArchiveMutationHookResult = ReturnType<typeof useTypeSportDisciplinesArchiveMutation>;
export type TypeSportDisciplinesArchiveMutationResult = Apollo.MutationResult<TypeSportDisciplinesArchiveMutation>;
export type TypeSportDisciplinesArchiveMutationOptions = Apollo.BaseMutationOptions<TypeSportDisciplinesArchiveMutation, TypeSportDisciplinesArchiveMutationVariables>;
export const TypeSportDisciplinesArchiveRemoveDocument = Apollo.gql`
    mutation TypeSportDisciplinesArchiveRemove($id: UUID!) {
  updateDirSportDiscipline(where: {id: $id}, data: {archive: {delete: true}}) {
    id
  }
}
    `;
export type TypeSportDisciplinesArchiveRemoveMutationFn = Apollo.MutationFunction<TypeSportDisciplinesArchiveRemoveMutation, TypeSportDisciplinesArchiveRemoveMutationVariables>;

/**
 * __useTypeSportDisciplinesArchiveRemoveMutation__
 *
 * To run a mutation, you first call `useTypeSportDisciplinesArchiveRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportDisciplinesArchiveRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportDisciplinesArchiveRemoveMutation, { data, loading, error }] = useTypeSportDisciplinesArchiveRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportDisciplinesArchiveRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportDisciplinesArchiveRemoveMutation, TypeSportDisciplinesArchiveRemoveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportDisciplinesArchiveRemoveMutation, TypeSportDisciplinesArchiveRemoveMutationVariables>(TypeSportDisciplinesArchiveRemoveDocument, baseOptions);
      }
export type TypeSportDisciplinesArchiveRemoveMutationHookResult = ReturnType<typeof useTypeSportDisciplinesArchiveRemoveMutation>;
export type TypeSportDisciplinesArchiveRemoveMutationResult = Apollo.MutationResult<TypeSportDisciplinesArchiveRemoveMutation>;
export type TypeSportDisciplinesArchiveRemoveMutationOptions = Apollo.BaseMutationOptions<TypeSportDisciplinesArchiveRemoveMutation, TypeSportDisciplinesArchiveRemoveMutationVariables>;
export const TypeSportDisciplinesDocument = Apollo.gql`
    query TypeSportDisciplines($id: UUID!) {
  dirSport(where: {id: $id}) {
    ...Disciplines
  }
}
    ${DisciplinesFragmentDoc}`;

/**
 * __useTypeSportDisciplinesQuery__
 *
 * To run a query within a React component, call `useTypeSportDisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportDisciplinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportDisciplinesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportDisciplinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportDisciplinesQuery, TypeSportDisciplinesQueryVariables>) {
        return ApolloReactHooks.useQuery<TypeSportDisciplinesQuery, TypeSportDisciplinesQueryVariables>(TypeSportDisciplinesDocument, baseOptions);
      }
export function useTypeSportDisciplinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportDisciplinesQuery, TypeSportDisciplinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TypeSportDisciplinesQuery, TypeSportDisciplinesQueryVariables>(TypeSportDisciplinesDocument, baseOptions);
        }
export type TypeSportDisciplinesQueryHookResult = ReturnType<typeof useTypeSportDisciplinesQuery>;
export type TypeSportDisciplinesLazyQueryHookResult = ReturnType<typeof useTypeSportDisciplinesLazyQuery>;
export type TypeSportDisciplinesQueryResult = Apollo.QueryResult<TypeSportDisciplinesQuery, TypeSportDisciplinesQueryVariables>;
export const TypeSportDisciplinesDrillDocument = Apollo.gql`
    query TypeSportDisciplinesDrill($filter: DirSportDisciplineWhereInput) {
  dirSportDisciplines(where: $filter) {
    value: id
    label: name
  }
}
    `;

/**
 * __useTypeSportDisciplinesDrillQuery__
 *
 * To run a query within a React component, call `useTypeSportDisciplinesDrillQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportDisciplinesDrillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportDisciplinesDrillQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTypeSportDisciplinesDrillQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportDisciplinesDrillQuery, TypeSportDisciplinesDrillQueryVariables>) {
        return ApolloReactHooks.useQuery<TypeSportDisciplinesDrillQuery, TypeSportDisciplinesDrillQueryVariables>(TypeSportDisciplinesDrillDocument, baseOptions);
      }
export function useTypeSportDisciplinesDrillLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportDisciplinesDrillQuery, TypeSportDisciplinesDrillQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TypeSportDisciplinesDrillQuery, TypeSportDisciplinesDrillQueryVariables>(TypeSportDisciplinesDrillDocument, baseOptions);
        }
export type TypeSportDisciplinesDrillQueryHookResult = ReturnType<typeof useTypeSportDisciplinesDrillQuery>;
export type TypeSportDisciplinesDrillLazyQueryHookResult = ReturnType<typeof useTypeSportDisciplinesDrillLazyQuery>;
export type TypeSportDisciplinesDrillQueryResult = Apollo.QueryResult<TypeSportDisciplinesDrillQuery, TypeSportDisciplinesDrillQueryVariables>;
export const MultipleTypeSportDisciplinesDrillDocument = Apollo.gql`
    query MultipleTypeSportDisciplinesDrill($id: [UUID!]) {
  dirSports(where: {id_in: $id}) {
    id
    disciplines {
      typeSport: dirSport {
        id
      }
      value: id
      label: name
    }
    disciplineGroups {
      typeSport: dirSport {
        id
      }
      value: id
      label: name
    }
  }
}
    `;

/**
 * __useMultipleTypeSportDisciplinesDrillQuery__
 *
 * To run a query within a React component, call `useMultipleTypeSportDisciplinesDrillQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultipleTypeSportDisciplinesDrillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultipleTypeSportDisciplinesDrillQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMultipleTypeSportDisciplinesDrillQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MultipleTypeSportDisciplinesDrillQuery, MultipleTypeSportDisciplinesDrillQueryVariables>) {
        return ApolloReactHooks.useQuery<MultipleTypeSportDisciplinesDrillQuery, MultipleTypeSportDisciplinesDrillQueryVariables>(MultipleTypeSportDisciplinesDrillDocument, baseOptions);
      }
export function useMultipleTypeSportDisciplinesDrillLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MultipleTypeSportDisciplinesDrillQuery, MultipleTypeSportDisciplinesDrillQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MultipleTypeSportDisciplinesDrillQuery, MultipleTypeSportDisciplinesDrillQueryVariables>(MultipleTypeSportDisciplinesDrillDocument, baseOptions);
        }
export type MultipleTypeSportDisciplinesDrillQueryHookResult = ReturnType<typeof useMultipleTypeSportDisciplinesDrillQuery>;
export type MultipleTypeSportDisciplinesDrillLazyQueryHookResult = ReturnType<typeof useMultipleTypeSportDisciplinesDrillLazyQuery>;
export type MultipleTypeSportDisciplinesDrillQueryResult = Apollo.QueryResult<MultipleTypeSportDisciplinesDrillQuery, MultipleTypeSportDisciplinesDrillQueryVariables>;
export const GroupTypesSportAndDisciplinesRemoveDocument = Apollo.gql`
    mutation GroupTypesSportAndDisciplinesRemove($id: UUID!, $idItem: UUID!) {
  updateDirSport(where: {id: $id}, data: {clsSportAndDisciplineGroups: {disconnect: {id: $idItem}}}) {
    id
    clsSportAndDisciplineGroups(orderBy: fullName_ASC) {
      fullName
      order
      id
    }
  }
}
    `;
export type GroupTypesSportAndDisciplinesRemoveMutationFn = Apollo.MutationFunction<GroupTypesSportAndDisciplinesRemoveMutation, GroupTypesSportAndDisciplinesRemoveMutationVariables>;

/**
 * __useGroupTypesSportAndDisciplinesRemoveMutation__
 *
 * To run a mutation, you first call `useGroupTypesSportAndDisciplinesRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupTypesSportAndDisciplinesRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupTypesSportAndDisciplinesRemoveMutation, { data, loading, error }] = useGroupTypesSportAndDisciplinesRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useGroupTypesSportAndDisciplinesRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GroupTypesSportAndDisciplinesRemoveMutation, GroupTypesSportAndDisciplinesRemoveMutationVariables>) {
        return ApolloReactHooks.useMutation<GroupTypesSportAndDisciplinesRemoveMutation, GroupTypesSportAndDisciplinesRemoveMutationVariables>(GroupTypesSportAndDisciplinesRemoveDocument, baseOptions);
      }
export type GroupTypesSportAndDisciplinesRemoveMutationHookResult = ReturnType<typeof useGroupTypesSportAndDisciplinesRemoveMutation>;
export type GroupTypesSportAndDisciplinesRemoveMutationResult = Apollo.MutationResult<GroupTypesSportAndDisciplinesRemoveMutation>;
export type GroupTypesSportAndDisciplinesRemoveMutationOptions = Apollo.BaseMutationOptions<GroupTypesSportAndDisciplinesRemoveMutation, GroupTypesSportAndDisciplinesRemoveMutationVariables>;
export const GroupTypesSportAndDisciplinesCreateDocument = Apollo.gql`
    mutation GroupTypesSportAndDisciplinesCreate($id: UUID!, $idItem: UUID!) {
  updateDirSport(where: {id: $id}, data: {clsSportAndDisciplineGroups: {connect: {id: $idItem}}}) {
    id
    clsSportAndDisciplineGroups(orderBy: fullName_ASC) {
      fullName
      order
      id
    }
  }
}
    `;
export type GroupTypesSportAndDisciplinesCreateMutationFn = Apollo.MutationFunction<GroupTypesSportAndDisciplinesCreateMutation, GroupTypesSportAndDisciplinesCreateMutationVariables>;

/**
 * __useGroupTypesSportAndDisciplinesCreateMutation__
 *
 * To run a mutation, you first call `useGroupTypesSportAndDisciplinesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupTypesSportAndDisciplinesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupTypesSportAndDisciplinesCreateMutation, { data, loading, error }] = useGroupTypesSportAndDisciplinesCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useGroupTypesSportAndDisciplinesCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GroupTypesSportAndDisciplinesCreateMutation, GroupTypesSportAndDisciplinesCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<GroupTypesSportAndDisciplinesCreateMutation, GroupTypesSportAndDisciplinesCreateMutationVariables>(GroupTypesSportAndDisciplinesCreateDocument, baseOptions);
      }
export type GroupTypesSportAndDisciplinesCreateMutationHookResult = ReturnType<typeof useGroupTypesSportAndDisciplinesCreateMutation>;
export type GroupTypesSportAndDisciplinesCreateMutationResult = Apollo.MutationResult<GroupTypesSportAndDisciplinesCreateMutation>;
export type GroupTypesSportAndDisciplinesCreateMutationOptions = Apollo.BaseMutationOptions<GroupTypesSportAndDisciplinesCreateMutation, GroupTypesSportAndDisciplinesCreateMutationVariables>;
export const GroupTypesSportAndDisciplinesDocument = Apollo.gql`
    query GroupTypesSportAndDisciplines($id: UUID!) {
  dirSport(where: {id: $id}) {
    id
    clsSportAndDisciplineGroups(orderBy: fullName_ASC) {
      ...ClassifierValue
    }
  }
}
    ${ClassifierValueFragmentDoc}`;

/**
 * __useGroupTypesSportAndDisciplinesQuery__
 *
 * To run a query within a React component, call `useGroupTypesSportAndDisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupTypesSportAndDisciplinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupTypesSportAndDisciplinesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupTypesSportAndDisciplinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupTypesSportAndDisciplinesQuery, GroupTypesSportAndDisciplinesQueryVariables>) {
        return ApolloReactHooks.useQuery<GroupTypesSportAndDisciplinesQuery, GroupTypesSportAndDisciplinesQueryVariables>(GroupTypesSportAndDisciplinesDocument, baseOptions);
      }
export function useGroupTypesSportAndDisciplinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupTypesSportAndDisciplinesQuery, GroupTypesSportAndDisciplinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroupTypesSportAndDisciplinesQuery, GroupTypesSportAndDisciplinesQueryVariables>(GroupTypesSportAndDisciplinesDocument, baseOptions);
        }
export type GroupTypesSportAndDisciplinesQueryHookResult = ReturnType<typeof useGroupTypesSportAndDisciplinesQuery>;
export type GroupTypesSportAndDisciplinesLazyQueryHookResult = ReturnType<typeof useGroupTypesSportAndDisciplinesLazyQuery>;
export type GroupTypesSportAndDisciplinesQueryResult = Apollo.QueryResult<GroupTypesSportAndDisciplinesQuery, GroupTypesSportAndDisciplinesQueryVariables>;
export const TypeSportGroupDisciplinesRenameDocument = Apollo.gql`
    mutation TypeSportGroupDisciplinesRename($id: UUID!, $idGroup: UUID!, $name: String!) {
  updateDirSport(where: {id: $id}, data: {disciplineGroups: {update: {where: {id: $idGroup}, data: {name: $name}}}}) {
    ...DisciplineGroup
  }
}
    ${DisciplineGroupFragmentDoc}`;
export type TypeSportGroupDisciplinesRenameMutationFn = Apollo.MutationFunction<TypeSportGroupDisciplinesRenameMutation, TypeSportGroupDisciplinesRenameMutationVariables>;

/**
 * __useTypeSportGroupDisciplinesRenameMutation__
 *
 * To run a mutation, you first call `useTypeSportGroupDisciplinesRenameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportGroupDisciplinesRenameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportGroupDisciplinesRenameMutation, { data, loading, error }] = useTypeSportGroupDisciplinesRenameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idGroup: // value for 'idGroup'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTypeSportGroupDisciplinesRenameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportGroupDisciplinesRenameMutation, TypeSportGroupDisciplinesRenameMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportGroupDisciplinesRenameMutation, TypeSportGroupDisciplinesRenameMutationVariables>(TypeSportGroupDisciplinesRenameDocument, baseOptions);
      }
export type TypeSportGroupDisciplinesRenameMutationHookResult = ReturnType<typeof useTypeSportGroupDisciplinesRenameMutation>;
export type TypeSportGroupDisciplinesRenameMutationResult = Apollo.MutationResult<TypeSportGroupDisciplinesRenameMutation>;
export type TypeSportGroupDisciplinesRenameMutationOptions = Apollo.BaseMutationOptions<TypeSportGroupDisciplinesRenameMutation, TypeSportGroupDisciplinesRenameMutationVariables>;
export const TypeSportGroupDisciplinesAssignDisciplineDocument = Apollo.gql`
    mutation TypeSportGroupDisciplinesAssignDiscipline($id: UUID!, $idGroup: UUID!, $discipline: UUID!) {
  updateDirSport(where: {id: $id}, data: {disciplineGroups: {update: {where: {id: $idGroup}, data: {disciplines: {connect: {id: $discipline}}}}}}) {
    ...DisciplineGroup
  }
}
    ${DisciplineGroupFragmentDoc}`;
export type TypeSportGroupDisciplinesAssignDisciplineMutationFn = Apollo.MutationFunction<TypeSportGroupDisciplinesAssignDisciplineMutation, TypeSportGroupDisciplinesAssignDisciplineMutationVariables>;

/**
 * __useTypeSportGroupDisciplinesAssignDisciplineMutation__
 *
 * To run a mutation, you first call `useTypeSportGroupDisciplinesAssignDisciplineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportGroupDisciplinesAssignDisciplineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportGroupDisciplinesAssignDisciplineMutation, { data, loading, error }] = useTypeSportGroupDisciplinesAssignDisciplineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idGroup: // value for 'idGroup'
 *      discipline: // value for 'discipline'
 *   },
 * });
 */
export function useTypeSportGroupDisciplinesAssignDisciplineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportGroupDisciplinesAssignDisciplineMutation, TypeSportGroupDisciplinesAssignDisciplineMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportGroupDisciplinesAssignDisciplineMutation, TypeSportGroupDisciplinesAssignDisciplineMutationVariables>(TypeSportGroupDisciplinesAssignDisciplineDocument, baseOptions);
      }
export type TypeSportGroupDisciplinesAssignDisciplineMutationHookResult = ReturnType<typeof useTypeSportGroupDisciplinesAssignDisciplineMutation>;
export type TypeSportGroupDisciplinesAssignDisciplineMutationResult = Apollo.MutationResult<TypeSportGroupDisciplinesAssignDisciplineMutation>;
export type TypeSportGroupDisciplinesAssignDisciplineMutationOptions = Apollo.BaseMutationOptions<TypeSportGroupDisciplinesAssignDisciplineMutation, TypeSportGroupDisciplinesAssignDisciplineMutationVariables>;
export const TypeSportGroupDisciplinesUnAssignDisciplineDocument = Apollo.gql`
    mutation TypeSportGroupDisciplinesUnAssignDiscipline($id: UUID!, $idGroup: UUID!, $discipline: UUID!) {
  updateDirSport(where: {id: $id}, data: {disciplineGroups: {update: {where: {id: $idGroup}, data: {disciplines: {disconnect: {id: $discipline}}}}}}) {
    ...DisciplineGroup
  }
}
    ${DisciplineGroupFragmentDoc}`;
export type TypeSportGroupDisciplinesUnAssignDisciplineMutationFn = Apollo.MutationFunction<TypeSportGroupDisciplinesUnAssignDisciplineMutation, TypeSportGroupDisciplinesUnAssignDisciplineMutationVariables>;

/**
 * __useTypeSportGroupDisciplinesUnAssignDisciplineMutation__
 *
 * To run a mutation, you first call `useTypeSportGroupDisciplinesUnAssignDisciplineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportGroupDisciplinesUnAssignDisciplineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportGroupDisciplinesUnAssignDisciplineMutation, { data, loading, error }] = useTypeSportGroupDisciplinesUnAssignDisciplineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idGroup: // value for 'idGroup'
 *      discipline: // value for 'discipline'
 *   },
 * });
 */
export function useTypeSportGroupDisciplinesUnAssignDisciplineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportGroupDisciplinesUnAssignDisciplineMutation, TypeSportGroupDisciplinesUnAssignDisciplineMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportGroupDisciplinesUnAssignDisciplineMutation, TypeSportGroupDisciplinesUnAssignDisciplineMutationVariables>(TypeSportGroupDisciplinesUnAssignDisciplineDocument, baseOptions);
      }
export type TypeSportGroupDisciplinesUnAssignDisciplineMutationHookResult = ReturnType<typeof useTypeSportGroupDisciplinesUnAssignDisciplineMutation>;
export type TypeSportGroupDisciplinesUnAssignDisciplineMutationResult = Apollo.MutationResult<TypeSportGroupDisciplinesUnAssignDisciplineMutation>;
export type TypeSportGroupDisciplinesUnAssignDisciplineMutationOptions = Apollo.BaseMutationOptions<TypeSportGroupDisciplinesUnAssignDisciplineMutation, TypeSportGroupDisciplinesUnAssignDisciplineMutationVariables>;
export const TypeSportGroupDisciplinesCreateDocument = Apollo.gql`
    mutation TypeSportGroupDisciplinesCreate($id: UUID!, $name: String!) {
  updateDirSport(where: {id: $id}, data: {disciplineGroups: {create: {name: $name}}}) {
    ...DisciplineGroup
  }
}
    ${DisciplineGroupFragmentDoc}`;
export type TypeSportGroupDisciplinesCreateMutationFn = Apollo.MutationFunction<TypeSportGroupDisciplinesCreateMutation, TypeSportGroupDisciplinesCreateMutationVariables>;

/**
 * __useTypeSportGroupDisciplinesCreateMutation__
 *
 * To run a mutation, you first call `useTypeSportGroupDisciplinesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportGroupDisciplinesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportGroupDisciplinesCreateMutation, { data, loading, error }] = useTypeSportGroupDisciplinesCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTypeSportGroupDisciplinesCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportGroupDisciplinesCreateMutation, TypeSportGroupDisciplinesCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportGroupDisciplinesCreateMutation, TypeSportGroupDisciplinesCreateMutationVariables>(TypeSportGroupDisciplinesCreateDocument, baseOptions);
      }
export type TypeSportGroupDisciplinesCreateMutationHookResult = ReturnType<typeof useTypeSportGroupDisciplinesCreateMutation>;
export type TypeSportGroupDisciplinesCreateMutationResult = Apollo.MutationResult<TypeSportGroupDisciplinesCreateMutation>;
export type TypeSportGroupDisciplinesCreateMutationOptions = Apollo.BaseMutationOptions<TypeSportGroupDisciplinesCreateMutation, TypeSportGroupDisciplinesCreateMutationVariables>;
export const TypeSportGroupDisciplinesArchiveDocument = Apollo.gql`
    mutation TypeSportGroupDisciplinesArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirSportDisciplineGroup(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportGroupDisciplinesArchiveMutationFn = Apollo.MutationFunction<TypeSportGroupDisciplinesArchiveMutation, TypeSportGroupDisciplinesArchiveMutationVariables>;

/**
 * __useTypeSportGroupDisciplinesArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportGroupDisciplinesArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportGroupDisciplinesArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportGroupDisciplinesArchiveMutation, { data, loading, error }] = useTypeSportGroupDisciplinesArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useTypeSportGroupDisciplinesArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportGroupDisciplinesArchiveMutation, TypeSportGroupDisciplinesArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportGroupDisciplinesArchiveMutation, TypeSportGroupDisciplinesArchiveMutationVariables>(TypeSportGroupDisciplinesArchiveDocument, baseOptions);
      }
export type TypeSportGroupDisciplinesArchiveMutationHookResult = ReturnType<typeof useTypeSportGroupDisciplinesArchiveMutation>;
export type TypeSportGroupDisciplinesArchiveMutationResult = Apollo.MutationResult<TypeSportGroupDisciplinesArchiveMutation>;
export type TypeSportGroupDisciplinesArchiveMutationOptions = Apollo.BaseMutationOptions<TypeSportGroupDisciplinesArchiveMutation, TypeSportGroupDisciplinesArchiveMutationVariables>;
export const TypeSportGroupDisciplinesArchiveRemoveDocument = Apollo.gql`
    mutation TypeSportGroupDisciplinesArchiveRemove($id: UUID!) {
  updateDirSportDisciplineGroup(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportGroupDisciplinesArchiveRemoveMutationFn = Apollo.MutationFunction<TypeSportGroupDisciplinesArchiveRemoveMutation, TypeSportGroupDisciplinesArchiveRemoveMutationVariables>;

/**
 * __useTypeSportGroupDisciplinesArchiveRemoveMutation__
 *
 * To run a mutation, you first call `useTypeSportGroupDisciplinesArchiveRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportGroupDisciplinesArchiveRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportGroupDisciplinesArchiveRemoveMutation, { data, loading, error }] = useTypeSportGroupDisciplinesArchiveRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportGroupDisciplinesArchiveRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportGroupDisciplinesArchiveRemoveMutation, TypeSportGroupDisciplinesArchiveRemoveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportGroupDisciplinesArchiveRemoveMutation, TypeSportGroupDisciplinesArchiveRemoveMutationVariables>(TypeSportGroupDisciplinesArchiveRemoveDocument, baseOptions);
      }
export type TypeSportGroupDisciplinesArchiveRemoveMutationHookResult = ReturnType<typeof useTypeSportGroupDisciplinesArchiveRemoveMutation>;
export type TypeSportGroupDisciplinesArchiveRemoveMutationResult = Apollo.MutationResult<TypeSportGroupDisciplinesArchiveRemoveMutation>;
export type TypeSportGroupDisciplinesArchiveRemoveMutationOptions = Apollo.BaseMutationOptions<TypeSportGroupDisciplinesArchiveRemoveMutation, TypeSportGroupDisciplinesArchiveRemoveMutationVariables>;
export const TypeSportGroupsDisciplinesDocument = Apollo.gql`
    query TypeSportGroupsDisciplines($id: UUID!) {
  dirSport(where: {id: $id}) {
    ...DisciplineGroup
  }
}
    ${DisciplineGroupFragmentDoc}`;

/**
 * __useTypeSportGroupsDisciplinesQuery__
 *
 * To run a query within a React component, call `useTypeSportGroupsDisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportGroupsDisciplinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportGroupsDisciplinesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportGroupsDisciplinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportGroupsDisciplinesQuery, TypeSportGroupsDisciplinesQueryVariables>) {
        return ApolloReactHooks.useQuery<TypeSportGroupsDisciplinesQuery, TypeSportGroupsDisciplinesQueryVariables>(TypeSportGroupsDisciplinesDocument, baseOptions);
      }
export function useTypeSportGroupsDisciplinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportGroupsDisciplinesQuery, TypeSportGroupsDisciplinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TypeSportGroupsDisciplinesQuery, TypeSportGroupsDisciplinesQueryVariables>(TypeSportGroupsDisciplinesDocument, baseOptions);
        }
export type TypeSportGroupsDisciplinesQueryHookResult = ReturnType<typeof useTypeSportGroupsDisciplinesQuery>;
export type TypeSportGroupsDisciplinesLazyQueryHookResult = ReturnType<typeof useTypeSportGroupsDisciplinesLazyQuery>;
export type TypeSportGroupsDisciplinesQueryResult = Apollo.QueryResult<TypeSportGroupsDisciplinesQuery, TypeSportGroupsDisciplinesQueryVariables>;
export const TypeSportsDocument = Apollo.gql`
    query TypeSports($first: Int!, $skip: Int!, $filter: DirSportWhereInput) {
  dirSports(first: $first, skip: $skip, where: $filter) {
    evsk
    fullName
    registryNumber
    id
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;

/**
 * __useTypeSportsQuery__
 *
 * To run a query within a React component, call `useTypeSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTypeSportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportsQuery, TypeSportsQueryVariables>) {
        return ApolloReactHooks.useQuery<TypeSportsQuery, TypeSportsQueryVariables>(TypeSportsDocument, baseOptions);
      }
export function useTypeSportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportsQuery, TypeSportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TypeSportsQuery, TypeSportsQueryVariables>(TypeSportsDocument, baseOptions);
        }
export type TypeSportsQueryHookResult = ReturnType<typeof useTypeSportsQuery>;
export type TypeSportsLazyQueryHookResult = ReturnType<typeof useTypeSportsLazyQuery>;
export type TypeSportsQueryResult = Apollo.QueryResult<TypeSportsQuery, TypeSportsQueryVariables>;
export const TypeSportsCountDocument = Apollo.gql`
    query TypeSportsCount($filter: DirSportWhereInput) {
  count: dirSportsCount(where: $filter)
}
    `;

/**
 * __useTypeSportsCountQuery__
 *
 * To run a query within a React component, call `useTypeSportsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTypeSportsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportsCountQuery, TypeSportsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<TypeSportsCountQuery, TypeSportsCountQueryVariables>(TypeSportsCountDocument, baseOptions);
      }
export function useTypeSportsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportsCountQuery, TypeSportsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TypeSportsCountQuery, TypeSportsCountQueryVariables>(TypeSportsCountDocument, baseOptions);
        }
export type TypeSportsCountQueryHookResult = ReturnType<typeof useTypeSportsCountQuery>;
export type TypeSportsCountLazyQueryHookResult = ReturnType<typeof useTypeSportsCountLazyQuery>;
export type TypeSportsCountQueryResult = Apollo.QueryResult<TypeSportsCountQuery, TypeSportsCountQueryVariables>;
export const TypeSportCreateDocument = Apollo.gql`
    mutation TypeSportCreate($fullName: String!, $shortName: String!, $evsk: String!) {
  createDirSport(data: {fullName: $fullName, shortName: $shortName, evsk: $evsk}) {
    ...MainInfo
  }
}
    ${MainInfoFragmentDoc}`;
export type TypeSportCreateMutationFn = Apollo.MutationFunction<TypeSportCreateMutation, TypeSportCreateMutationVariables>;

/**
 * __useTypeSportCreateMutation__
 *
 * To run a mutation, you first call `useTypeSportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportCreateMutation, { data, loading, error }] = useTypeSportCreateMutation({
 *   variables: {
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      evsk: // value for 'evsk'
 *   },
 * });
 */
export function useTypeSportCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportCreateMutation, TypeSportCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportCreateMutation, TypeSportCreateMutationVariables>(TypeSportCreateDocument, baseOptions);
      }
export type TypeSportCreateMutationHookResult = ReturnType<typeof useTypeSportCreateMutation>;
export type TypeSportCreateMutationResult = Apollo.MutationResult<TypeSportCreateMutation>;
export type TypeSportCreateMutationOptions = Apollo.BaseMutationOptions<TypeSportCreateMutation, TypeSportCreateMutationVariables>;
export const TypeSportUpdateDocument = Apollo.gql`
    mutation TypeSportUpdate($id: UUID!, $fullName: String!, $shortName: String!, $evsk: String) {
  updateDirSport(where: {id: $id}, data: {fullName: $fullName, shortName: $shortName, evsk: $evsk}) {
    ...MainInfo
  }
}
    ${MainInfoFragmentDoc}`;
export type TypeSportUpdateMutationFn = Apollo.MutationFunction<TypeSportUpdateMutation, TypeSportUpdateMutationVariables>;

/**
 * __useTypeSportUpdateMutation__
 *
 * To run a mutation, you first call `useTypeSportUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportUpdateMutation, { data, loading, error }] = useTypeSportUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      evsk: // value for 'evsk'
 *   },
 * });
 */
export function useTypeSportUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportUpdateMutation, TypeSportUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportUpdateMutation, TypeSportUpdateMutationVariables>(TypeSportUpdateDocument, baseOptions);
      }
export type TypeSportUpdateMutationHookResult = ReturnType<typeof useTypeSportUpdateMutation>;
export type TypeSportUpdateMutationResult = Apollo.MutationResult<TypeSportUpdateMutation>;
export type TypeSportUpdateMutationOptions = Apollo.BaseMutationOptions<TypeSportUpdateMutation, TypeSportUpdateMutationVariables>;
export const TypeSportArchiveDocument = Apollo.gql`
    mutation TypeSportArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirSport(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportArchiveMutationFn = Apollo.MutationFunction<TypeSportArchiveMutation, TypeSportArchiveMutationVariables>;

/**
 * __useTypeSportArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportArchiveMutation, { data, loading, error }] = useTypeSportArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useTypeSportArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportArchiveMutation, TypeSportArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportArchiveMutation, TypeSportArchiveMutationVariables>(TypeSportArchiveDocument, baseOptions);
      }
export type TypeSportArchiveMutationHookResult = ReturnType<typeof useTypeSportArchiveMutation>;
export type TypeSportArchiveMutationResult = Apollo.MutationResult<TypeSportArchiveMutation>;
export type TypeSportArchiveMutationOptions = Apollo.BaseMutationOptions<TypeSportArchiveMutation, TypeSportArchiveMutationVariables>;
export const TypeSportUnArchiveDocument = Apollo.gql`
    mutation TypeSportUnArchive($id: UUID!) {
  updateDirSport(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportUnArchiveMutationFn = Apollo.MutationFunction<TypeSportUnArchiveMutation, TypeSportUnArchiveMutationVariables>;

/**
 * __useTypeSportUnArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportUnArchiveMutation, { data, loading, error }] = useTypeSportUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportUnArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportUnArchiveMutation, TypeSportUnArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportUnArchiveMutation, TypeSportUnArchiveMutationVariables>(TypeSportUnArchiveDocument, baseOptions);
      }
export type TypeSportUnArchiveMutationHookResult = ReturnType<typeof useTypeSportUnArchiveMutation>;
export type TypeSportUnArchiveMutationResult = Apollo.MutationResult<TypeSportUnArchiveMutation>;
export type TypeSportUnArchiveMutationOptions = Apollo.BaseMutationOptions<TypeSportUnArchiveMutation, TypeSportUnArchiveMutationVariables>;
export const FetchTypeSportByIdMainInfoDocument = Apollo.gql`
    query FetchTypeSportByIdMainInfo($id: UUID!) {
  dirSport(where: {id: $id}) {
    ...MainInfo
  }
}
    ${MainInfoFragmentDoc}`;

/**
 * __useFetchTypeSportByIdMainInfoQuery__
 *
 * To run a query within a React component, call `useFetchTypeSportByIdMainInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTypeSportByIdMainInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTypeSportByIdMainInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchTypeSportByIdMainInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchTypeSportByIdMainInfoQuery, FetchTypeSportByIdMainInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchTypeSportByIdMainInfoQuery, FetchTypeSportByIdMainInfoQueryVariables>(FetchTypeSportByIdMainInfoDocument, baseOptions);
      }
export function useFetchTypeSportByIdMainInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchTypeSportByIdMainInfoQuery, FetchTypeSportByIdMainInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchTypeSportByIdMainInfoQuery, FetchTypeSportByIdMainInfoQueryVariables>(FetchTypeSportByIdMainInfoDocument, baseOptions);
        }
export type FetchTypeSportByIdMainInfoQueryHookResult = ReturnType<typeof useFetchTypeSportByIdMainInfoQuery>;
export type FetchTypeSportByIdMainInfoLazyQueryHookResult = ReturnType<typeof useFetchTypeSportByIdMainInfoLazyQuery>;
export type FetchTypeSportByIdMainInfoQueryResult = Apollo.QueryResult<FetchTypeSportByIdMainInfoQuery, FetchTypeSportByIdMainInfoQueryVariables>;
export const TypeSportsListDocument = Apollo.gql`
    query TypeSportsList {
  dirSports(orderBy: fullName_ASC) {
    value: id
    label: fullName
    disciplines {
      typeSport: dirSport {
        id
      }
      value: id
      label: name
    }
  }
}
    `;

/**
 * __useTypeSportsListQuery__
 *
 * To run a query within a React component, call `useTypeSportsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTypeSportsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportsListQuery, TypeSportsListQueryVariables>) {
        return ApolloReactHooks.useQuery<TypeSportsListQuery, TypeSportsListQueryVariables>(TypeSportsListDocument, baseOptions);
      }
export function useTypeSportsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportsListQuery, TypeSportsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TypeSportsListQuery, TypeSportsListQueryVariables>(TypeSportsListDocument, baseOptions);
        }
export type TypeSportsListQueryHookResult = ReturnType<typeof useTypeSportsListQuery>;
export type TypeSportsListLazyQueryHookResult = ReturnType<typeof useTypeSportsListLazyQuery>;
export type TypeSportsListQueryResult = Apollo.QueryResult<TypeSportsListQuery, TypeSportsListQueryVariables>;
export const TypeSportsListSelectDocument = Apollo.gql`
    query TypeSportsListSelect($where: DirSportWhereInput) {
  dirSports(orderBy: fullName_ASC, where: $where) {
    value: id
    label: fullName
  }
}
    `;

/**
 * __useTypeSportsListSelectQuery__
 *
 * To run a query within a React component, call `useTypeSportsListSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportsListSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportsListSelectQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTypeSportsListSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportsListSelectQuery, TypeSportsListSelectQueryVariables>) {
        return ApolloReactHooks.useQuery<TypeSportsListSelectQuery, TypeSportsListSelectQueryVariables>(TypeSportsListSelectDocument, baseOptions);
      }
export function useTypeSportsListSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportsListSelectQuery, TypeSportsListSelectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TypeSportsListSelectQuery, TypeSportsListSelectQueryVariables>(TypeSportsListSelectDocument, baseOptions);
        }
export type TypeSportsListSelectQueryHookResult = ReturnType<typeof useTypeSportsListSelectQuery>;
export type TypeSportsListSelectLazyQueryHookResult = ReturnType<typeof useTypeSportsListSelectLazyQuery>;
export type TypeSportsListSelectQueryResult = Apollo.QueryResult<TypeSportsListSelectQuery, TypeSportsListSelectQueryVariables>;
export const TypeSportRanksAndTitlesCreateDocument = Apollo.gql`
    mutation TypeSportRanksAndTitlesCreate($id: UUID!, $idItem: UUID!) {
  updateDirSport(where: {id: $id}, data: {clsRanksAndAwards: {connect: {id: $idItem}}}) {
    id
    clsRanksAndAwards {
      label: fullName
      value: id
    }
  }
}
    `;
export type TypeSportRanksAndTitlesCreateMutationFn = Apollo.MutationFunction<TypeSportRanksAndTitlesCreateMutation, TypeSportRanksAndTitlesCreateMutationVariables>;

/**
 * __useTypeSportRanksAndTitlesCreateMutation__
 *
 * To run a mutation, you first call `useTypeSportRanksAndTitlesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportRanksAndTitlesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportRanksAndTitlesCreateMutation, { data, loading, error }] = useTypeSportRanksAndTitlesCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useTypeSportRanksAndTitlesCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportRanksAndTitlesCreateMutation, TypeSportRanksAndTitlesCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportRanksAndTitlesCreateMutation, TypeSportRanksAndTitlesCreateMutationVariables>(TypeSportRanksAndTitlesCreateDocument, baseOptions);
      }
export type TypeSportRanksAndTitlesCreateMutationHookResult = ReturnType<typeof useTypeSportRanksAndTitlesCreateMutation>;
export type TypeSportRanksAndTitlesCreateMutationResult = Apollo.MutationResult<TypeSportRanksAndTitlesCreateMutation>;
export type TypeSportRanksAndTitlesCreateMutationOptions = Apollo.BaseMutationOptions<TypeSportRanksAndTitlesCreateMutation, TypeSportRanksAndTitlesCreateMutationVariables>;
export const TypeSportRanksAndTitlesRemoveDocument = Apollo.gql`
    mutation TypeSportRanksAndTitlesRemove($id: UUID!, $idItem: UUID!) {
  updateDirSport(where: {id: $id}, data: {clsRanksAndAwards: {disconnect: {id: $idItem}}}) {
    id
    clsRanksAndAwards {
      label: fullName
      value: id
    }
  }
}
    `;
export type TypeSportRanksAndTitlesRemoveMutationFn = Apollo.MutationFunction<TypeSportRanksAndTitlesRemoveMutation, TypeSportRanksAndTitlesRemoveMutationVariables>;

/**
 * __useTypeSportRanksAndTitlesRemoveMutation__
 *
 * To run a mutation, you first call `useTypeSportRanksAndTitlesRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportRanksAndTitlesRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportRanksAndTitlesRemoveMutation, { data, loading, error }] = useTypeSportRanksAndTitlesRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useTypeSportRanksAndTitlesRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportRanksAndTitlesRemoveMutation, TypeSportRanksAndTitlesRemoveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportRanksAndTitlesRemoveMutation, TypeSportRanksAndTitlesRemoveMutationVariables>(TypeSportRanksAndTitlesRemoveDocument, baseOptions);
      }
export type TypeSportRanksAndTitlesRemoveMutationHookResult = ReturnType<typeof useTypeSportRanksAndTitlesRemoveMutation>;
export type TypeSportRanksAndTitlesRemoveMutationResult = Apollo.MutationResult<TypeSportRanksAndTitlesRemoveMutation>;
export type TypeSportRanksAndTitlesRemoveMutationOptions = Apollo.BaseMutationOptions<TypeSportRanksAndTitlesRemoveMutation, TypeSportRanksAndTitlesRemoveMutationVariables>;
export const TypeSportRanksAndTitlesDocument = Apollo.gql`
    query TypeSportRanksAndTitles($id: UUID!) {
  dirSport(where: {id: $id}) {
    id
    clsRanksAndAwards {
      label: fullName
      value: id
    }
  }
}
    `;

/**
 * __useTypeSportRanksAndTitlesQuery__
 *
 * To run a query within a React component, call `useTypeSportRanksAndTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportRanksAndTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportRanksAndTitlesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportRanksAndTitlesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportRanksAndTitlesQuery, TypeSportRanksAndTitlesQueryVariables>) {
        return ApolloReactHooks.useQuery<TypeSportRanksAndTitlesQuery, TypeSportRanksAndTitlesQueryVariables>(TypeSportRanksAndTitlesDocument, baseOptions);
      }
export function useTypeSportRanksAndTitlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportRanksAndTitlesQuery, TypeSportRanksAndTitlesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TypeSportRanksAndTitlesQuery, TypeSportRanksAndTitlesQueryVariables>(TypeSportRanksAndTitlesDocument, baseOptions);
        }
export type TypeSportRanksAndTitlesQueryHookResult = ReturnType<typeof useTypeSportRanksAndTitlesQuery>;
export type TypeSportRanksAndTitlesLazyQueryHookResult = ReturnType<typeof useTypeSportRanksAndTitlesLazyQuery>;
export type TypeSportRanksAndTitlesQueryResult = Apollo.QueryResult<TypeSportRanksAndTitlesQuery, TypeSportRanksAndTitlesQueryVariables>;
export const TypeSportStagePrepareCreateDocument = Apollo.gql`
    mutation TypeSportStagePrepareCreate($id: UUID!, $idStep: UUID!, $idRank: [ClassifierValueWhereUniqueInput!], $minManAge: Int, $maxManAge: Int, $minWomanAge: Int, $maxWomanAge: Int, $minGroupSize: Int, $maxGroupSize: Int) {
  updateDirSport(where: {id: $id}, data: {trainingStages: {create: {clsTrainingStage: {connect: {id: $idStep}}, minManAge: $minManAge, maxManAge: $maxManAge, minWomanAge: $minWomanAge, maxWomanAge: $maxWomanAge, minGroupSize: $minGroupSize, maxGroupSize: $maxGroupSize, clsRanksAndAwards: {connect: $idRank}}}}) {
    id
    trainingStages {
      ...SportTrainingStage
    }
  }
}
    ${SportTrainingStageFragmentDoc}`;
export type TypeSportStagePrepareCreateMutationFn = Apollo.MutationFunction<TypeSportStagePrepareCreateMutation, TypeSportStagePrepareCreateMutationVariables>;

/**
 * __useTypeSportStagePrepareCreateMutation__
 *
 * To run a mutation, you first call `useTypeSportStagePrepareCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportStagePrepareCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportStagePrepareCreateMutation, { data, loading, error }] = useTypeSportStagePrepareCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idStep: // value for 'idStep'
 *      idRank: // value for 'idRank'
 *      minManAge: // value for 'minManAge'
 *      maxManAge: // value for 'maxManAge'
 *      minWomanAge: // value for 'minWomanAge'
 *      maxWomanAge: // value for 'maxWomanAge'
 *      minGroupSize: // value for 'minGroupSize'
 *      maxGroupSize: // value for 'maxGroupSize'
 *   },
 * });
 */
export function useTypeSportStagePrepareCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportStagePrepareCreateMutation, TypeSportStagePrepareCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportStagePrepareCreateMutation, TypeSportStagePrepareCreateMutationVariables>(TypeSportStagePrepareCreateDocument, baseOptions);
      }
export type TypeSportStagePrepareCreateMutationHookResult = ReturnType<typeof useTypeSportStagePrepareCreateMutation>;
export type TypeSportStagePrepareCreateMutationResult = Apollo.MutationResult<TypeSportStagePrepareCreateMutation>;
export type TypeSportStagePrepareCreateMutationOptions = Apollo.BaseMutationOptions<TypeSportStagePrepareCreateMutation, TypeSportStagePrepareCreateMutationVariables>;
export const TypeSportStagePrepareUpdateDocument = Apollo.gql`
    mutation TypeSportStagePrepareUpdate($id: UUID!, $idStep: UUID!, $idRank: [ClassifierValueWhereUniqueInput!], $minManAge: Int, $maxManAge: Int, $minWomanAge: Int, $maxWomanAge: Int, $minGroupSize: Int, $maxGroupSize: Int) {
  updateDirSportTrainingStage(where: {id: $id}, data: {clsTrainingStage: {connect: {id: $idStep}}, minManAge: $minManAge, maxManAge: $maxManAge, minWomanAge: $minWomanAge, maxWomanAge: $maxWomanAge, minGroupSize: $minGroupSize, maxGroupSize: $maxGroupSize, clsRanksAndAwards: {set: $idRank}}) {
    ...SportTrainingStage
  }
}
    ${SportTrainingStageFragmentDoc}`;
export type TypeSportStagePrepareUpdateMutationFn = Apollo.MutationFunction<TypeSportStagePrepareUpdateMutation, TypeSportStagePrepareUpdateMutationVariables>;

/**
 * __useTypeSportStagePrepareUpdateMutation__
 *
 * To run a mutation, you first call `useTypeSportStagePrepareUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportStagePrepareUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportStagePrepareUpdateMutation, { data, loading, error }] = useTypeSportStagePrepareUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idStep: // value for 'idStep'
 *      idRank: // value for 'idRank'
 *      minManAge: // value for 'minManAge'
 *      maxManAge: // value for 'maxManAge'
 *      minWomanAge: // value for 'minWomanAge'
 *      maxWomanAge: // value for 'maxWomanAge'
 *      minGroupSize: // value for 'minGroupSize'
 *      maxGroupSize: // value for 'maxGroupSize'
 *   },
 * });
 */
export function useTypeSportStagePrepareUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportStagePrepareUpdateMutation, TypeSportStagePrepareUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportStagePrepareUpdateMutation, TypeSportStagePrepareUpdateMutationVariables>(TypeSportStagePrepareUpdateDocument, baseOptions);
      }
export type TypeSportStagePrepareUpdateMutationHookResult = ReturnType<typeof useTypeSportStagePrepareUpdateMutation>;
export type TypeSportStagePrepareUpdateMutationResult = Apollo.MutationResult<TypeSportStagePrepareUpdateMutation>;
export type TypeSportStagePrepareUpdateMutationOptions = Apollo.BaseMutationOptions<TypeSportStagePrepareUpdateMutation, TypeSportStagePrepareUpdateMutationVariables>;
export const TypeSportStagePrepareRemoveDocument = Apollo.gql`
    mutation TypeSportStagePrepareRemove($id: UUID!, $idItem: UUID!) {
  updateDirSport(where: {id: $id}, data: {trainingStages: {delete: {id: $idItem}}}) {
    id
    trainingStages {
      ...SportTrainingStage
    }
  }
}
    ${SportTrainingStageFragmentDoc}`;
export type TypeSportStagePrepareRemoveMutationFn = Apollo.MutationFunction<TypeSportStagePrepareRemoveMutation, TypeSportStagePrepareRemoveMutationVariables>;

/**
 * __useTypeSportStagePrepareRemoveMutation__
 *
 * To run a mutation, you first call `useTypeSportStagePrepareRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportStagePrepareRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportStagePrepareRemoveMutation, { data, loading, error }] = useTypeSportStagePrepareRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useTypeSportStagePrepareRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportStagePrepareRemoveMutation, TypeSportStagePrepareRemoveMutationVariables>) {
        return ApolloReactHooks.useMutation<TypeSportStagePrepareRemoveMutation, TypeSportStagePrepareRemoveMutationVariables>(TypeSportStagePrepareRemoveDocument, baseOptions);
      }
export type TypeSportStagePrepareRemoveMutationHookResult = ReturnType<typeof useTypeSportStagePrepareRemoveMutation>;
export type TypeSportStagePrepareRemoveMutationResult = Apollo.MutationResult<TypeSportStagePrepareRemoveMutation>;
export type TypeSportStagePrepareRemoveMutationOptions = Apollo.BaseMutationOptions<TypeSportStagePrepareRemoveMutation, TypeSportStagePrepareRemoveMutationVariables>;
export const TypeSportStagePreparingDocument = Apollo.gql`
    query TypeSportStagePreparing($id: UUID!) {
  dirSport(where: {id: $id}) {
    id
    trainingStages {
      ...SportTrainingStage
    }
  }
}
    ${SportTrainingStageFragmentDoc}`;

/**
 * __useTypeSportStagePreparingQuery__
 *
 * To run a query within a React component, call `useTypeSportStagePreparingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportStagePreparingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportStagePreparingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportStagePreparingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportStagePreparingQuery, TypeSportStagePreparingQueryVariables>) {
        return ApolloReactHooks.useQuery<TypeSportStagePreparingQuery, TypeSportStagePreparingQueryVariables>(TypeSportStagePreparingDocument, baseOptions);
      }
export function useTypeSportStagePreparingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportStagePreparingQuery, TypeSportStagePreparingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TypeSportStagePreparingQuery, TypeSportStagePreparingQueryVariables>(TypeSportStagePreparingDocument, baseOptions);
        }
export type TypeSportStagePreparingQueryHookResult = ReturnType<typeof useTypeSportStagePreparingQuery>;
export type TypeSportStagePreparingLazyQueryHookResult = ReturnType<typeof useTypeSportStagePreparingLazyQuery>;
export type TypeSportStagePreparingQueryResult = Apollo.QueryResult<TypeSportStagePreparingQuery, TypeSportStagePreparingQueryVariables>;
export const RemoveTypeSportDocument = Apollo.gql`
    mutation RemoveTypeSport($id: UUID!) {
  deleteDirSport(where: {id: $id}) {
    id
  }
}
    `;
export type RemoveTypeSportMutationFn = Apollo.MutationFunction<RemoveTypeSportMutation, RemoveTypeSportMutationVariables>;

/**
 * __useRemoveTypeSportMutation__
 *
 * To run a mutation, you first call `useRemoveTypeSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTypeSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTypeSportMutation, { data, loading, error }] = useRemoveTypeSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTypeSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTypeSportMutation, RemoveTypeSportMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTypeSportMutation, RemoveTypeSportMutationVariables>(RemoveTypeSportDocument, baseOptions);
      }
export type RemoveTypeSportMutationHookResult = ReturnType<typeof useRemoveTypeSportMutation>;
export type RemoveTypeSportMutationResult = Apollo.MutationResult<RemoveTypeSportMutation>;
export type RemoveTypeSportMutationOptions = Apollo.BaseMutationOptions<RemoveTypeSportMutation, RemoveTypeSportMutationVariables>;
export const RemoveUserDocument = Apollo.gql`
    mutation RemoveUser($id: UUID!) {
  deleteUser(where: {id: $id}) {
    id
  }
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, baseOptions);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const CreateUserDocument = Apollo.gql`
    mutation CreateUser($name: String, $email: String!, $role: String!, $regional: RegionalMinsportProfileCreateOneInput, $regionalAdministratorProfile: RegionalAdministratorProfileCreateOneInput, $school: RegionalSchoolProfileCreateOneInput, $roivSpecialistProfile: RoivSpecialistProfileCreateOneInput, $fcpsrSportingEventOrganizerProfile: FcpsrSportingEventOrganizerProfileCreateOneInput, $gskRefereeProfile: GskRefereeProfileCreateOneInput) {
  signup(name: $name, email: $email, role: $role, password: "a", regionalMinsportProfile: $regional, regionalSchoolProfile: $school, regionalAdministratorProfile: $regionalAdministratorProfile, roivSpecialistProfile: $roivSpecialistProfile, fcpsrSportingEventOrganizerProfile: $fcpsrSportingEventOrganizerProfile, gskRefereeProfile: $gskRefereeProfile) {
    token
    user {
      ...UserFR
    }
  }
}
    ${UserFrFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *      regional: // value for 'regional'
 *      regionalAdministratorProfile: // value for 'regionalAdministratorProfile'
 *      school: // value for 'school'
 *      roivSpecialistProfile: // value for 'roivSpecialistProfile'
 *      fcpsrSportingEventOrganizerProfile: // value for 'fcpsrSportingEventOrganizerProfile'
 *      gskRefereeProfile: // value for 'gskRefereeProfile'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = Apollo.gql`
    mutation UpdateUser($id: UUID!, $userID: String!, $name: String, $role: String!, $email: String!, $isActive: Boolean!, $regional: RegionalMinsportProfileUpdateOneInput, $regionalAdministratorProfile: RegionalAdministratorProfileUpdateOneInput, $school: RegionalSchoolProfileUpdateOneInput, $regular: RegularUserUpdateOneInput, $roivSpecialistProfile: RoivSpecialistProfileUpdateOneInput, $fcpsrSportingEventOrganizerProfile: FcpsrSportingEventOrganizerProfileUpdateOneInput, $gskRefereeProfile: GskRefereeProfileUpdateOneInput) {
  updateUser(where: {id: $id}, data: {name: $name, regionalMinsportProfile: $regional, regionalSchoolProfile: $school, regionalAdministratorProfile: $regionalAdministratorProfile, regular: $regular, isActive: $isActive, roivSpecialistProfile: $roivSpecialistProfile, fcpsrSportingEventOrganizerProfile: $fcpsrSportingEventOrganizerProfile, gskRefereeProfile: $gskRefereeProfile}) {
    ...UserFR
  }
  changeUserRole(userID: $userID, role: $role) {
    id
  }
  changeUserEmail(userID: $userID, email: $email) {
    id
  }
}
    ${UserFrFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userID: // value for 'userID'
 *      name: // value for 'name'
 *      role: // value for 'role'
 *      email: // value for 'email'
 *      isActive: // value for 'isActive'
 *      regional: // value for 'regional'
 *      regionalAdministratorProfile: // value for 'regionalAdministratorProfile'
 *      school: // value for 'school'
 *      regular: // value for 'regular'
 *      roivSpecialistProfile: // value for 'roivSpecialistProfile'
 *      fcpsrSportingEventOrganizerProfile: // value for 'fcpsrSportingEventOrganizerProfile'
 *      gskRefereeProfile: // value for 'gskRefereeProfile'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserContactInfoDocument = Apollo.gql`
    mutation UpdateUserContactInfo($id: UUID!, $regular: RegularUserUpdateOneInput, $personProfile: DirPersonUpdateOneWithoutUserProfileInput) {
  updateUser(where: {id: $id}, data: {regular: $regular, personProfile: $personProfile}) {
    ...UserFR
  }
}
    ${UserFrFragmentDoc}`;
export type UpdateUserContactInfoMutationFn = Apollo.MutationFunction<UpdateUserContactInfoMutation, UpdateUserContactInfoMutationVariables>;

/**
 * __useUpdateUserContactInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserContactInfoMutation, { data, loading, error }] = useUpdateUserContactInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      regular: // value for 'regular'
 *      personProfile: // value for 'personProfile'
 *   },
 * });
 */
export function useUpdateUserContactInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserContactInfoMutation, UpdateUserContactInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserContactInfoMutation, UpdateUserContactInfoMutationVariables>(UpdateUserContactInfoDocument, baseOptions);
      }
export type UpdateUserContactInfoMutationHookResult = ReturnType<typeof useUpdateUserContactInfoMutation>;
export type UpdateUserContactInfoMutationResult = Apollo.MutationResult<UpdateUserContactInfoMutation>;
export type UpdateUserContactInfoMutationOptions = Apollo.BaseMutationOptions<UpdateUserContactInfoMutation, UpdateUserContactInfoMutationVariables>;
export const UsersDocument = Apollo.gql`
    query Users($first: Int!, $skip: Int!, $filter: UserWhereInput) {
  users(first: $first, skip: $skip, where: $filter) {
    ...UserFR
  }
}
    ${UserFrFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocument = Apollo.gql`
    query User($id: UUID!) {
  user(where: {id: $id}) {
    ...UserFR
    regionalMinsportProfile {
      id
      dirRegion {
        value: id
        label: fullName
      }
      dirFederalDistrict {
        value: id
        label: fullName
      }
    }
    regionalAdministratorProfile {
      id
      dirRegion {
        value: id
        label: fullName
      }
    }
    roivSpecialistProfile {
      id
      dirRegion {
        value: id
        label: fullName
      }
    }
    regionalSchoolProfile {
      id
      dirOrganization {
        value: id
        label: name
      }
    }
    fcpsrSportingEventOrganizerProfile {
      id
      dirSports {
        value: id
        label: fullName
      }
      dirCalendars {
        value: id
        label: fullName
      }
    }
    gskRefereeProfile {
      id
      sportingEvents: dirSportingEvents {
        value: id
        label: name
      }
    }
  }
}
    ${UserFrFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersRoivDocument = Apollo.gql`
    query UsersRoiv($regionId: UUID!) {
  users(where: {roivSpecialistProfile: {dirRegion: {id: $regionId}}, isActive: true}) {
    value: email
    id
    roivSpecialistProfile {
      id
    }
    regular {
      id
      firstname
      lastname
      patronymic
    }
  }
}
    `;

/**
 * __useUsersRoivQuery__
 *
 * To run a query within a React component, call `useUsersRoivQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersRoivQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersRoivQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useUsersRoivQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersRoivQuery, UsersRoivQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersRoivQuery, UsersRoivQueryVariables>(UsersRoivDocument, baseOptions);
      }
export function useUsersRoivLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersRoivQuery, UsersRoivQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersRoivQuery, UsersRoivQueryVariables>(UsersRoivDocument, baseOptions);
        }
export type UsersRoivQueryHookResult = ReturnType<typeof useUsersRoivQuery>;
export type UsersRoivLazyQueryHookResult = ReturnType<typeof useUsersRoivLazyQuery>;
export type UsersRoivQueryResult = Apollo.QueryResult<UsersRoivQuery, UsersRoivQueryVariables>;
export const UniversityUsersRoivDocument = Apollo.gql`
    query UniversityUsersRoiv($organizationId: UUID!) {
  users(where: {role: {OR: [{name_contains: "REGIONAL_SCHOOL"}, {description_contains: "Специалист спортивной организации"}]}, isActive: true, OR: [{regular: {dirOrganization: {id: $organizationId}}}, {regionalSchoolProfile: {dirOrganization: {id: $organizationId}}}]}) {
    value: email
    regular {
      id
      firstname
      lastname
      patronymic
    }
  }
}
    `;

/**
 * __useUniversityUsersRoivQuery__
 *
 * To run a query within a React component, call `useUniversityUsersRoivQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniversityUsersRoivQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniversityUsersRoivQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUniversityUsersRoivQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UniversityUsersRoivQuery, UniversityUsersRoivQueryVariables>) {
        return ApolloReactHooks.useQuery<UniversityUsersRoivQuery, UniversityUsersRoivQueryVariables>(UniversityUsersRoivDocument, baseOptions);
      }
export function useUniversityUsersRoivLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UniversityUsersRoivQuery, UniversityUsersRoivQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UniversityUsersRoivQuery, UniversityUsersRoivQueryVariables>(UniversityUsersRoivDocument, baseOptions);
        }
export type UniversityUsersRoivQueryHookResult = ReturnType<typeof useUniversityUsersRoivQuery>;
export type UniversityUsersRoivLazyQueryHookResult = ReturnType<typeof useUniversityUsersRoivLazyQuery>;
export type UniversityUsersRoivQueryResult = Apollo.QueryResult<UniversityUsersRoivQuery, UniversityUsersRoivQueryVariables>;
export const ResetPasswordDocument = Apollo.gql`
    mutation ResetPassword($resetEmail: String!) {
  publicResetPassword(email: $resetEmail)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetEmail: // value for 'resetEmail'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LoginDocument = Apollo.gql`
    mutation Login($email: String!, $password: String!) {
  signin(email: $email, password: $password) {
    token
    user {
      id
      regular {
        id
        lastname
        firstname
        patronymic
        dirOrganization {
          id
          sports {
            id
            dirSport {
              id
            }
          }
          clsOrganizationCategories {
            id
            fullName
            registryNumber
          }
        }
      }
      role {
        name
        description
      }
      personProfile {
        athleteProfile {
          id
        }
        trainerProfile {
          id
        }
        dirRegion {
          id
        }
        dirForeignCity {
          id
        }
      }
      regionalMinsportProfile {
        dirRegion {
          id
        }
        dirFederalDistrict {
          id
        }
      }
      regionalSchoolProfile {
        dirOrganization {
          id
          clsOrganizationCategories {
            registryNumber
            fullName
          }
          sports {
            id
            dirSport {
              id
            }
          }
        }
      }
      regionalAdministratorProfile {
        dirRegion {
          id
        }
      }
      roivSpecialistProfile {
        dirRegion {
          id
        }
      }
      fcpsrSportingEventOrganizerProfile {
        id
        dirSports {
          id
        }
        dirCalendars {
          id
        }
      }
      gskRefereeProfile {
        id
        sportingEvents: dirSportingEvents {
          id
        }
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ChangePasswordUserDocument = Apollo.gql`
    mutation changePasswordUser($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPass: $oldPassword, newPass: $newPassword) {
    email
  }
}
    `;
export type ChangePasswordUserMutationFn = Apollo.MutationFunction<ChangePasswordUserMutation, ChangePasswordUserMutationVariables>;

/**
 * __useChangePasswordUserMutation__
 *
 * To run a mutation, you first call `useChangePasswordUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordUserMutation, { data, loading, error }] = useChangePasswordUserMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordUserMutation, ChangePasswordUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordUserMutation, ChangePasswordUserMutationVariables>(ChangePasswordUserDocument, baseOptions);
      }
export type ChangePasswordUserMutationHookResult = ReturnType<typeof useChangePasswordUserMutation>;
export type ChangePasswordUserMutationResult = Apollo.MutationResult<ChangePasswordUserMutation>;
export type ChangePasswordUserMutationOptions = Apollo.BaseMutationOptions<ChangePasswordUserMutation, ChangePasswordUserMutationVariables>;