import React, { FC } from 'react';
import { useAthleteCompetitionQuery } from '../../../../api';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import _get from 'lodash/get';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

const CompetitionAccordion: FC<{ id: string; sportId: string }> = ({ id, sportId }) => {
  const classes = useStyles();

  const { data } = useAthleteCompetitionQuery({
    skip: !id,
    variables: {
      id,
      sportId,
    },
  });

  if (data?.dirAthlete?.competetionResult?.length === 0) return null;

  return (
    <Accordion key={id} style={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`org-content`} id={`org-header`}>
        <Typography className={classes.header} color="primary">
          Результаты соревнований
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Назв. мероприятия</TableCell>
                <TableCell>Категория</TableCell>
                <TableCell>с-по</TableCell>
                <TableCell>Место</TableCell>
                <TableCell>Возрастная группа</TableCell>
                <TableCell>Возраст</TableCell>
                <TableCell>Дисциплина</TableCell>
                <TableCell>Старт</TableCell>
                <TableCell>Занятое место</TableCell>
                <TableCell>Результат</TableCell>
                <TableCell>Очки</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.dirAthlete?.competetionResult?.map((result) => (
                <TableRow key={result.id}>
                  <TableCell component="th" scope="row">
                    {result?.sportingEventProgramType?.event?.name || '~'}
                  </TableCell>
                  <TableCell>
                    {_get(result, 'sportingEventProgramType.event.clsEventCategories[0].label') || '~'}
                  </TableCell>
                  <TableCell>
                    {moment(result?.sportingEventProgramType?.event?.startDate).format('DD.MM.YY') || '~'}-
                    {moment(result?.sportingEventProgramType?.event?.endDate).format('DD.MM.YY') || '~'}
                  </TableCell>
                  <TableCell>{result?.sportingEventProgramType?.event?.venue || '~'}</TableCell>
                  <TableCell>{_get(result, 'sportingEventProgramType.clsAgeGroups[0].label') || '~'}</TableCell>
                  <TableCell>
                    {result?.sportingEventProgramType?.minAge || '~'}- {result?.sportingEventProgramType?.maxAge || '~'}
                  </TableCell>
                  <TableCell> {result?.sportingEventProgramType?.discipline?.label || '~'}</TableCell>
                  <TableCell>{result?.sportingEventProgramType?.start || '~'}</TableCell>
                  <TableCell>
                    {result?.point || '~'}
                    {(result?.pointTo && ` - ${result?.pointTo}`) || ''}
                  </TableCell>
                  <TableCell>{result?.result || '~'}</TableCell>
                  <TableCell>{result?.score || '~'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default CompetitionAccordion;
