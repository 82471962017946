import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  DirAthleteSportOrganization,
  DirNationalTeamAthlete,
  Scalars,
  useGenderAndAgeGroupsQuery,
} from '../../../../api';
import { Grid, IconButton, Link, Tooltip } from '@material-ui/core';
import React, { FC, useMemo, useState } from 'react';

import AthleteForm from './AthleteForm';
import ConfirmDialog from '../../../ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { getAthleteFormHint } from '../../../../utils/getAthleteFormHint';
import _ from 'lodash';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

interface GroupAthlete extends DirNationalTeamAthlete {
  name: string;
  registryNumber: number;
  birthday: string;
  rankAndAwards: string;
  organizations: [DirAthleteSportOrganization];
  isMale: boolean;
  b_y: string | number;
  results: [
    {
      id: Scalars['UUID'];
      age: string;
      disciplineName: string;
      start: string;
      point: string;
      result: string;
      bar?: string;
    },
  ];
}

const AthleteGroupsAccordion: FC<{
  readonly?: boolean;
  canAddAthlete?: boolean;
  bannedAthletesFromSearch: Array<String>;
  isShowingResult?: boolean;
  group: any;
  region: String;
  university?: string;
  onAthleteGroupsUpdated: Function;
  id?: Scalars['UUID'];
  onUpdateGroup: () => void;
  onDeleteGroup: () => void;
  onDeleteAthlete: Function;
  athleteIsInRange?: boolean;
  athleteIsInRangeRank?: boolean;
  event: any;
  additionalParams: any;
}> = ({
  readonly,
  group,
  region,
  university,
  onAthleteGroupsUpdated,
  canAddAthlete,
  bannedAthletesFromSearch,
  isShowingResult,
  athleteIsInRange,
  athleteIsInRangeRank,
  id,
  onUpdateGroup,
  onDeleteGroup,
  onDeleteAthlete,
  event,
  additionalParams,
}) => {
  const classes = useStyles();
  const { minManBirthYear, maxManBirthYear, minWomanBirthYear, maxWomanBirthYear } = event;
  const { ageGroup } = group;
  const [deleteItem, setDeleteItem] = useState<any>(null);

  const isBetweenDates = (date: string | number, isMale = false) => {
    const inIntervalYear = (date: any, minYear: any, maxYear: any) => {
      if (!minYear) minYear = 1900;
      if (!maxYear) maxYear = 2200;
      const d = date ? Number(moment(date).format('YYYY')) : 0;
      if (!date || (!minYear && !maxYear)) return false;
      // console.log({ date, minYear, maxYear, d, bool: d <= minYear });
      if (minYear && maxYear) return d >= minYear && d <= maxYear;
    };

    return isMale
      ? inIntervalYear(date, minManBirthYear, maxManBirthYear)
      : inIntervalYear(date, minWomanBirthYear, maxWomanBirthYear);
  };

  const athleteIsInRangeRankFunc = (athlete) => {
    const validateAthlete = getAthleteFormHint(athlete, group?.sportId, event || {});
    return validateAthlete === 'Отсутствует разряд/звание, соответствующий регламенту';
  };
  return (
    <Grid container style={{ width: '100%', marginBottom: 15 }}>
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${group?.title}-content`}
          id={`${group?.title}-header`}
          style={{ backgroundColor: (group?.archive && 'lightgrey') || 'inherit' }}
        >
          <Grid container spacing={2} justify="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.header} color="primary">
                {group?.title}
              </Typography>
            </Grid>
            {!readonly && (
              <Grid item onClick={(e) => e.stopPropagation()}>
                <Tooltip title="изменить" placement="top-start">
                  <IconButton
                    className={classes.btn}
                    color="primary"
                    type="button"
                    onClick={onUpdateGroup}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="удалить" placement="top-start">
                  <IconButton
                    className={classes.btn}
                    type="button"
                    color="secondary"
                    onClick={() => setDeleteItem(group?.id)}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <ConfirmDialog
                  title={`Удаление группы`}
                  text={`Вы уверены что хотите удалить эту группу?`}
                  btnVariant="outlined"
                  open={deleteItem === group?.id}
                  saveBtnText="да"
                  cancelBtnText="отмена"
                  saveBtnColor="secondary"
                  cancelBtnColor="primary"
                  onCancel={() => setDeleteItem(null)}
                  onSave={() => {
                    onDeleteGroup();
                    setDeleteItem(null);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          {!readonly && (
            <Grid container style={{ marginBottom: 25 }}>
              <AthleteForm
                classes={classes}
                group={group}
                bannedAthletesFromSearch={bannedAthletesFromSearch}
                region={region}
                university={university}
                athleteIsInRange={athleteIsInRange}
                athleteIsInRangeRank={athleteIsInRangeRank}
                canAddAthlete={canAddAthlete}
                id={id}
                event={event}
                onAddAthlete={() => onAthleteGroupsUpdated()}
                additionalParams={additionalParams}
              />
            </Grid>
          )}
          {group.athletesInfo?.length > 0 && (
            <TableContainer component={Paper}>
              <Table aria-label="athletes table">
                <TableBody>
                  {group.athletesInfo?.map((athlete, index: number) => {
                    const {
                      dirAthlete,
                      name,
                      id: athleteId,
                      registryNumber,
                      birthday,
                      rankAndAwards,
                      organizations,
                      bestResult,
                      isMale,
                      results,
                      b_y,
                    } = athlete;
                    return (
                      <TableRow key={athleteId}>
                        <TableCell component="th" scope="row">
                          {index + 1}.{` `}
                          <Link
                            style={{ fontWeight: 500, cursor: 'pointer' }}
                            target="_blank"
                            href={`/athlete/${dirAthlete?.id}`}
                          >
                            {name}
                          </Link>
                        </TableCell>
                        <TableCell>{registryNumber}</TableCell>
                        <TableCell>
                          <span>{birthday}</span>
                          <br />
                          {!isBetweenDates(b_y, isMale) && !athleteIsInRangeRankFunc(athlete) && (
                            <span style={{ color: 'red' }}>(Возраст не соотв. треб-ям)</span>
                          )}
                          {athleteIsInRangeRankFunc(athlete) && isBetweenDates(b_y, isMale) && (
                            <span style={{ color: 'red' }}>(Разряд не соотв. треб-ям)</span>
                          )}
                          {athleteIsInRangeRankFunc(athlete) && !isBetweenDates(b_y, isMale) && (
                            <span style={{ color: 'red' }}>(Возраст, разряд не соотв. треб-ям)</span>
                          )}
                        </TableCell>
                        <TableCell>{rankAndAwards}</TableCell>
                        <TableCell>
                          {organizations?.map((o, index) => (
                            <>
                              <Link
                                key={o?.id}
                                style={{ fontWeight: 500, cursor: 'pointer' }}
                                target="_blank"
                                href={`/organization/${o.dirOrganization.id}`}
                              >
                                {o.dirOrganization.name}
                              </Link>
                              {index < organizations?.length - 1 ? ', ' : '.'}
                            </>
                          ))}
                        </TableCell>
                        <TableCell>{bestResult}</TableCell>
                        {isShowingResult && (
                          <React.Fragment>
                            <TableCell>{isMale ? 'Муж.' : 'Жен.'}</TableCell>
                            {results?.length ? (
                              <TableCell colSpan={5}>
                                <Grid container>
                                  {results?.map((result) => (
                                    <Grid item container justify="space-between" key={result?.id}>
                                      <Grid item xs={3}>{`Возраст: ${result?.age}`}</Grid>
                                      <Grid item xs={3}>
                                        {result?.disciplineName}
                                      </Grid>
                                      <Grid item xs={2}>
                                        {result?.start}
                                      </Grid>
                                      <Grid item xs={2}>
                                        {result?.point}
                                      </Grid>
                                      <Grid item xs={2}>
                                        {result?.result}
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </TableCell>
                            ) : (
                              <React.Fragment>
                                <TableCell>{` `}</TableCell>
                                <TableCell>{` `}</TableCell>
                                <TableCell>{` `}</TableCell>
                                <TableCell>{` `}</TableCell>
                                <TableCell>{` `}</TableCell>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                        {!readonly && (
                          <TableCell>
                            <Tooltip title="удалить" placement="top-start">
                              <IconButton
                                className={classes.btn}
                                color="secondary"
                                size="small"
                                onClick={() => setDeleteItem(athleteId)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            <ConfirmDialog
                              title={`Удаление спортсмена`}
                              text={`Вы уверены что хотите удалить этого спортсмена?`}
                              btnVariant="outlined"
                              open={deleteItem === athleteId}
                              saveBtnText="да"
                              cancelBtnText="отмена"
                              saveBtnColor="secondary"
                              cancelBtnColor="primary"
                              onCancel={() => setDeleteItem(null)}
                              onSave={() => {
                                onDeleteAthlete(group.id, athleteId).then(() => setDeleteItem(null));
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default AthleteGroupsAccordion;
